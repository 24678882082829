import axios from 'axios';

export const getDealKpisApi = async ({ customerId, date }) => {
  const response = await axios.get(
    `admin/customer/${customerId}/deal-info`,
    {
      params: {
        date,
      },
    }
  );
  return response.data;
};

export const updateDealKpisApi = async ({ customerId, date, dealKpis }) => {
  const response = await axios.post(
    `admin/customer/${customerId}/deal-info`,
    {
      date,
      dealKpis,
    }
  );
  return response.data;
};

export const getLatestDealKpisApi = async ({ customerId }) => {
  const response = await axios.get(
    `admin/customer/${customerId}/deal-info-latest`
  );
  return response.data;
};
