import { Tag } from '@chakra-ui/react';
import PropTypes from 'prop-types';

function StatusTag({ label, level, ...props }) {
  let bgColor = '';
  switch (level) {
    case 'success':
      bgColor = 'green.200';
      break;
    case 'warning':
      bgColor = 'yellow.300';
      break;
    case 'error':
      bgColor = 'red.main';
      break;
    default:
      bgColor = 'gray.100';
      break;
  }

  return (
    <Tag bg={bgColor} {...props} variant={props.variant || 'solid'}>
      {label}
    </Tag>
  );
}

StatusTag.propTypes = {
  level: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default StatusTag;
