import { Icon } from '@chakra-ui/react';

// Admin Imports
// import DashboardsDefault from 'views/admin/dashboards/default';
// import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
// import DashboardsCarInterface from 'views/admin/dashboards/carInterface';
// import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

// // NFT Imports
// import NFTMarketplace from 'views/admin/nfts/marketplace';
// import NFTPage from 'views/admin/nfts/page';
// import NFTCollection from 'views/admin/nfts/collection';
// import NFTProfile from 'views/admin/nfts/profile';

// // Main Imports
// import AccountBilling from 'views/admin/main/account/billing';
// import AccountApplications from 'views/admin/main/account/application';
// import AccountInvoice from 'views/admin/main/account/invoice';
// import AccountSettings from 'views/admin/main/account/settings';
// import AccountAllCourses from 'views/admin/main/account/courses';
// import AccountCoursePage from 'views/admin/main/account/coursePage';

// import UserNew from 'views/admin/main/users/newUser';
// import UsersOverview from 'views/admin/main/users/overview';
// import UsersReports from 'views/admin/main/users/reports';

// import ProfileSettings from 'views/admin/main/profile/settings';
// import ProfileOverview from 'views/admin/main/profile/overview';
// import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

// import ApplicationsKanban from 'views/admin/main/applications/kanban';
// import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
// import ApplicationsCalendar from 'views/admin/main/applications/calendar';

// import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
// import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
// import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
// import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
// import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
// import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
// import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// // Others
// import OthersNotifications from 'views/admin/main/others/notifications';
// import OthersPricing from 'views/admin/main/others/pricing';
// import OthersError from 'views/admin/main/others/404';
// import Messages from 'views/admin/main/others/messages';

// // Auth Imports
// import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
// import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault.jsx';
// import LockCentered from 'views/auth/lock/LockCentered.jsx';
// import LockDefault from 'views/auth/lock/LockDefault.jsx';
// import SignInCentered from 'views/auth/signIn/SignInCentered.jsx';
// import SignInDefault from 'views/auth/signIn/SignInDefault.jsx';
// import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
// import SignUpDefault from 'views/auth/signUp/SignUpDefault.jsx';
// import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';
// import VerificationDefault from 'views/auth/verification/VerificationDefault.jsx';

import BankingAndFinance from 'pages/Cockpit/BankingAndFinance';
import CashflowModel from 'pages/Cockpit/CashflowModel';
import CockpitHome from 'pages/Cockpit/CockpitHome';
import BankingProfitLoss from 'pages/Cockpit/Customer/Banking/BankingProfitLoss';
import ConnectorList from 'pages/Cockpit/Customer/Connectors/ConnectorList';
import CustomerConfiguration from 'pages/Cockpit/Customer/CustomerConfiguration';
import CustomerOverview from 'pages/Cockpit/Customer/CustomersOverview';
import CustomerSettings from 'pages/Cockpit/Customer/Performance/CustomerSettings';
import Deal from 'pages/Cockpit/Customer/Performance/Deal';
import Financial from 'pages/Cockpit/Customer/Performance/Financial';
import Marketing from 'pages/Cockpit/Customer/Performance/Marketing';
import Sales from 'pages/Cockpit/Customer/Performance/Sales';
import Tearsheet from 'pages/Cockpit/Customer/Performance/Tearsheet/Tearsheet';
import TearsheetOverview from 'pages/Cockpit/Customer/Performance/Tearsheet/TearsheetOverview';
import Underwriting from 'pages/Cockpit/Customer/Performance/Underwriting/Underwriting';
import UnderwritingOverview from 'pages/Cockpit/Customer/Performance/Underwriting/UnderwritingOverview';
import OperationsDeal from 'pages/Cockpit/Operations/Deal';
import DealsOverview from 'pages/Cockpit/Operations/DealsOverview';
import Installment from 'pages/Cockpit/Operations/Installment';
import OperationsCustomerOverview from 'pages/Cockpit/Operations/OperationsCustomerOverview';
import OperationsOverview from 'pages/Cockpit/Operations/OperationsOverview';
import Repayment from 'pages/Cockpit/Operations/Repayment';
import Test from 'pages/Test';
import { BsBank } from 'react-icons/bs';
import { FaChartLine } from 'react-icons/fa';
import { FaSackDollar } from 'react-icons/fa6';
import { LiaUserFriendsSolid } from 'react-icons/lia';
import { SiLooker } from 'react-icons/si';
import { TbBriefcase2 } from 'react-icons/tb';

export const createCockpitRoutes = ({ location }) => {
  const customerId = location.pathname.split('/')[2];

  const isActiveRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createCustomerRoutes = () => {
    return [
      {
        name: 'Performance',
        path: `/customers/:customerId/performance`,
        link: '',
        hide: !isActiveRoute(`/customers/${customerId}`),
        collapse: true,
        isOpen: true,
        items: [
          {
            name: 'Marketing',
            path: `/customers/:customerId/performance/marketing`,
            link: `/customers/${customerId}/performance/marketing`,
            component: <Marketing />,
          },
          {
            name: 'Sales',
            path: `/customers/:customerId/performance/sales`,
            link: `/customers/${customerId}/performance/sales`,
            hasAnchorLinks: true,
            component: <Sales />,
            items: !isActiveRoute('/performance/sales')
              ? null
              : [
                  {
                    name: 'Highlights',
                    link: 'highlights',
                  },
                  {
                    name: 'Cohort Trends',
                    link: 'cohortTrends',
                  },
                  {
                    name: 'Revenue/Cashflow',
                    link: 'revenue',
                  },
                  {
                    name: 'Users',
                    link: 'users',
                  },
                  {
                    name: 'ARPU',
                    link: 'arpu',
                  },
                  {
                    name: 'ROAS',
                    link: 'roas',
                  },
                  {
                    name: 'LTV',
                    link: 'ltv',
                  },
                ],
          },
          {
            name: 'Financials',
            path: `/customers/:customerId/performance/financial`,
            link: `/customers/${customerId}/performance/financial`,
            component: <Financial />,
            hasAnchorLinks: true,
          },
          {
            name: 'Deal',
            path: `/customers/:customerId/performance/deal`,
            link: `/customers/${customerId}/performance/deal`,
            component: <Deal />,
            hasAnchorLinks: true,
            items: !isActiveRoute('/performance/deal')
              ? null
              : [
                  {
                    name: 'Highlights',
                    link: 'highlights',
                  },
                  {
                    name: 'Exposure',
                    link: 'exposure',
                  },
                  {
                    name: 'Funding',
                    link: 'funding',
                  },
                  {
                    name: 'Repayment',
                    link: 'repayment',
                  },
                ],
          },
          {
            name: 'Tearsheet',
            path: `/customers/:customerId/performance/tearsheet`,
            link: `/customers/${customerId}/performance/tearsheet`,
            component: <TearsheetOverview />,
          },
          {
            name: 'Tearsheet',
            path: `/customers/:customerId/performance/tearsheet/:tearsheetId`,
            link: `/customers/${customerId}/performance/tearsheet`,
            component: <Tearsheet />,
            hide: true,
          },
          {
            name: 'Underwriting',
            path: `/customers/:customerId/performance/underwriting`,
            link: `/customers/${customerId}/performance/underwriting`,
            component: <UnderwritingOverview />,
          },
          {
            name: 'Underwriting',
            path: `/customers/:customerId/performance/underwriting/new`,
            link: `/customers/${customerId}/performance/underwriting`,
            component: <Underwriting />,
            hide: true,
          },
          {
            name: 'Underwriting',
            path: `/customers/:customerId/performance/underwriting/:underwritingId`,
            link: `/customers/${customerId}/performance/underwriting`,
            component: <Underwriting />,
            hide: true,
          },
        ],
      },
      {
        name: 'Settings',
        path: `/customers/:customerId/settings`,
        link: `/customers/${customerId}/settings`,
        component: <CustomerSettings />,
      },
      {
        name: 'Configuration',
        path: `/customers/:customerId/configuration`,
        link: `/customers/${customerId}/configuration`,
        hide: !isActiveRoute(`/customers/${customerId}`),
        component: <CustomerConfiguration />,
      },
      {
        name: 'Connectors',
        path: `/customers/:customerId/connectors`,
        link: `/customers/${customerId}/connectors`,
        hide: !isActiveRoute(`/customers/${customerId}`),
        component: <ConnectorList />,
      },
      {
        name: 'Banking P&L',
        path: `/customers/:customerId/banking-profit-loss`,
        link: `/customers/${customerId}/banking-profit-loss`,
        hide: !isActiveRoute(`/customers/${customerId}`),
        component: <BankingProfitLoss />,
      },
    ];
  };

  const createOperationsRoutes = () => {
    return [
      {
        name: 'Installments',
        path: '/operations/:customerId/installment',
        link: `/operations/${customerId}/installment`,
        component: <OperationsCustomerOverview />,
      },
      {
        name: 'Installments',
        path: '/operations/:customerId/installment/new',
        hide: true,
        component: <Installment />,
      },
      {
        name: 'Installments',
        path: '/operations/:customerId/installment/:installmentId',
        hide: true,
        component: <Installment />,
      },
      {
        name: 'Installments',
        path: '/operations/:customerId/repayment',
        hide: true,
        component: <Repayment />,
      },
      {
        name: 'Deals',
        path: '/operations/:customerId/deal',
        link: `/operations/${customerId}/deal`,
        component: <DealsOverview />,
      },
      {
        name: 'Deals',
        path: '/operations/:customerId/deal/new',
        hide: true,
        component: <OperationsDeal />,
      },
      {
        name: 'Deals',
        path: '/operations/:customerId/deal/:dealId',
        hide: true,
        component: <OperationsDeal />,
      },
    ];
  };

  const createPerformanceRoutes = () => {
    const customerId = sessionStorage.getItem('playgroundCustomerId');
    const addCustomerId = (path) => {
      if (!customerId) {
        return path;
      }
      return path + '?customerId=' + customerId;
    };
    return [
      {
        name: 'Marketing',
        path: '/performance/marketing',
        link: addCustomerId('/performance/marketing'),
        component: <Marketing isPlayground={true} />,
      },
      {
        name: 'Sales',
        path: '/performance/sales',
        link: addCustomerId('/performance/sales'),
        component: <Sales isPlayground={true} />,
      },
      {
        name: 'Financials',
        path: '/performance/financials',
        link: addCustomerId('/performance/financials'),
        component: <Financial isPlayground={true} />,
      },
    ];
  };

  const routes = [
    // --- Dashboards ---
    {
      name: 'Performance',
      path: '/performance',
      link: '',
      icon: (
        <Icon as={FaChartLine} width="20px" height="20px" color="inherit" />
      ),
      items: createPerformanceRoutes(),
    },
    {
      name: 'Customers',
      path: '/customers',
      link: '/customers',
      icon: (
        <Icon
          as={LiaUserFriendsSolid}
          width="24px"
          height="24px"
          color="inherit"
          mx={'-2px'}
        />
      ),
      component: <CustomerOverview />,
      items: isActiveRoute(`/customers/${customerId}`)
        ? createCustomerRoutes()
        : null,
    },
    {
      name: 'Cash Flow Model',
      path: '/cashflow-model',
      link: '/cashflow-model',
      collapse: false,
      icon: (
        <Icon as={FaSackDollar} width="20px" height="20px" color="inherit" />
      ),
      component: <CashflowModel />,
    },
    {
      name: 'Asset Tape',
      path: '/operations',
      link: '/operations',
      collapse: false,
      icon: (
        <Icon as={FaSackDollar} width="20px" height="20px" color="inherit" />
      ),
      component: <OperationsOverview />,
      items: isActiveRoute(`/operations/${customerId}`)
        ? createOperationsRoutes()
        : null,
    },
    {
      name: 'Banking & Finance',
      path: '/banking-finance',
      link: '/banking-finance',
      icon: <Icon as={BsBank} width="20px" height="20px" color="inherit" />,
      component: <BankingAndFinance />,
    },
    {
      name: 'Partners Hub',
      path: '/partners-hub',
      link: '/partners-hub',
      icon: (
        <Icon as={TbBriefcase2} width="20px" height="20px" color="inherit" />
      ),
      component: <></>,
    },
    {
      name: 'Data Looker',
      path: '/performance-old',
      link: '/performance-old',
      icon: <Icon as={SiLooker} width="20px" height="20px" color="inherit" />,
      component: <CockpitHome />,
    },
    {
      name: 'Test',
      path: '/test',
      component: <Test />,
      hide: true,
    },
  ];

  return routes;
};
