import { MONEY_TYPE, TOTAL_TYPE } from 'constants/salesConstants';
import _get from 'lodash/get';

const totalTypePropMap = {
  [TOTAL_TYPE.GROSS]: 'gross',
  [TOTAL_TYPE.NET]: 'net',
};

const refundPropMap = {
  true: 'with_refunds',
  false: 'without_refunds',
};

const moneyTypePropMap = {
  [MONEY_TYPE.REVENUE]: 'spread',
  [MONEY_TYPE.CASHFLOW]: '',
};

export const parseRevenueKpi = (obj, settings, prop) => {
  return {
    total: obj[prop]['combined']?.[totalTypePropMap[settings.totalType]],
    annually: obj[prop]['annually']?.[totalTypePropMap[settings.totalType]],
    monthly: obj[prop]['monthly']?.[totalTypePropMap[settings.totalType]],
  };
};

export const parseRevenueUsersKpi = (obj, settings, prop) => {
  return {
    total: obj[prop]['combined'],
    annually: obj[prop]['annually'],
    monthly: obj[prop]['monthly'],
  };
};

export const parseCashflowKpi = (obj, settings, prop) => {
  const totalTypeProp = `${totalTypePropMap[settings.totalType]}_${
    refundPropMap[settings.withRefunds]
  }`;

  return {
    total: obj[prop]['total']?.[totalTypeProp],
    annually: obj[prop]['annually']?.[totalTypeProp],
    monthly: obj[prop]['monthly']?.[totalTypeProp],
  };
};

export const parseCashflowUsersKpi = (obj, settings, prop) => {
  const refundProp = refundPropMap[settings.withRefunds];

  return {
    total: obj[prop]['total']?.[refundProp],
    annually: obj[prop]['annually']?.[refundProp],
    monthly: obj[prop]['monthly']?.[refundProp],
  };
};

export const parseCohortsKpi = (obj, settings, prop) => {
  const kpi = _get(obj, prop);

  const moneyTypeProp = moneyTypePropMap[settings.moneyType];
  const refundProp = refundPropMap[settings.withRefunds];

  let totalPath = `${totalTypePropMap[settings.totalType]}_total`;
  let monthlyPath = `${totalTypePropMap[settings.totalType]}_mrr`;
  let annuallyPath = `${totalTypePropMap[settings.totalType]}_arr`;

  if (moneyTypeProp) {
    totalPath += `_${moneyTypeProp}`;
    annuallyPath += `_${moneyTypeProp}`;
  }

  return {
    total: kpi[totalPath]?.[refundProp] || 0,
    annually: kpi[annuallyPath]?.[refundProp] || 0,
    monthly: kpi[monthlyPath]?.[refundProp] || 0,
  };
};

export const parseKpiData = ({ config, settings, cohortsKpis, moneyTypeKpis }) => {
  let data = {};

  Object.keys(config).forEach((kpi) => {
    const configItem = config[kpi];
    if (configItem.cohortsProp) {
      data[kpi] = parseCohortsKpi(cohortsKpis, settings, configItem.cohortsProp);
    } else if (settings.moneyType == MONEY_TYPE.REVENUE) {
      if (configItem[MONEY_TYPE.REVENUE]) {
        const parseFunc = configItem.isUsers
          ? parseRevenueUsersKpi
          : parseRevenueKpi;

        data[kpi] = parseFunc(
          moneyTypeKpis,
          settings,
          configItem[MONEY_TYPE.REVENUE]
        );
      }
    } else if (settings.moneyType == MONEY_TYPE.CASHFLOW) {
      if (configItem[MONEY_TYPE.CASHFLOW]) {
        const parseFunc = configItem.isUsers
          ? parseCashflowUsersKpi
          : parseCashflowKpi;
        data[kpi] = parseFunc(
          moneyTypeKpis,
          settings,
          configItem[MONEY_TYPE.CASHFLOW]
        );
      }
    } else {
      console.error('No valid config found for kpi', kpi);
    }
  });

  return data;
};
