import { Box } from '@chakra-ui/react';
import {
  currencyFormat,
  percentageFormat
} from 'components/Number';
import KpiCollection from 'components/kpi/KpiCollection';
import KpiExpanded from 'components/kpi/KpiExpanded';
import KpiSectionHeader from 'components/kpi/KpiSectionHeader';
import { MONEY_TYPE } from 'constants/salesConstants';
import { KpiCardExpandContext } from 'context/KpiCardExpandContext';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import CacPredictionChart from './components/CacPredictionChart';
import CohortWidget from './components/CohortWidget';
import SalesSectionCard from './components/SalesSectionCard';
import { COHORT_TYPE } from './constants/cohortsConfig';
import { parseKpiData } from './helpers/kpiParser';

const KPI = {
  DAILY_AVG_7_DAYS: 'dailyAvg7days',
  DAILY_AVG_30_DAYS: 'dailyAvg30days',
  LAST_FULL_DAY: 'lastFullDay',
  CAGR: 'cagr',
  ANNUAL_RUN_RATE_1_DAY: 'annualRunRate1Day',
  ANNUAL_RUN_RATE_7_DAYS: 'annualRunRate7Days',
  ANNUAL_RUN_RATE_30_DAYS: 'annualRunRate30Days',
  TOTAL: 'total',
  MONTH_TO_DATE: 'monthToDate',
};

const KPI_PARSE_CONFIG = {
  [KPI.DAILY_AVG_7_DAYS]: {
    title: 'Daily Avg',
    [MONEY_TYPE.REVENUE]: 'avg_last_7_days',
    [MONEY_TYPE.CASHFLOW]: 'avg_last_7_days',
  },
  [KPI.DAILY_AVG_30_DAYS]: {
    title: 'Daily Avg',
    [MONEY_TYPE.REVENUE]: 'avg_last_30_days',
    [MONEY_TYPE.CASHFLOW]: 'avg_last_30_days',
  },
  [KPI.LAST_FULL_DAY]: {
    title: 'Last Full Day',
    [MONEY_TYPE.REVENUE]: 'last_1_day',
    [MONEY_TYPE.CASHFLOW]: 'last_1_day',
  },
  [KPI.CAGR]: {
    title: 'CAGR',
    [MONEY_TYPE.REVENUE]: 'cagr_6_months',
    [MONEY_TYPE.CASHFLOW]: 'cagr_6_months',
  },
  [KPI.ANNUAL_RUN_RATE_1_DAY]: {
    title: 'Annual Run Rate',
    [MONEY_TYPE.REVENUE]: 'annual_run_rate_1_day',
    [MONEY_TYPE.CASHFLOW]: 'annual_last_1_day',
  },
  [KPI.ANNUAL_RUN_RATE_7_DAYS]: {
    title: 'Annual Run Rate',
    [MONEY_TYPE.REVENUE]: 'annual_run_rate_7_days',
    [MONEY_TYPE.CASHFLOW]: 'annual_last_7_days',
  },
  [KPI.ANNUAL_RUN_RATE_30_DAYS]: {
    title: 'Annual Run Rate',
    [MONEY_TYPE.REVENUE]: 'annual_run_rate_30_days',
    [MONEY_TYPE.CASHFLOW]: 'annual_last_30_days',
  },
  [KPI.TOTAL]: {
    title: 'Total',
    [MONEY_TYPE.CASHFLOW]: 'total_last_30_days',
  },
  [KPI.MONTH_TO_DATE]: {
    title: 'Month to Date',
    [MONEY_TYPE.CASHFLOW]: 'month_to_date',
  },
};

const KPI_CARD = {
  TOTAL: 'total',
  MONTH_TO_DATE: 'monthToDate',
  DAILY_AVG: 'dailyAvg',
  LAST_FULL_DAY: 'lastFullDay',
  ANNUAL_RUN_RATE: 'annualRunRate',
  CAGR: 'cagr',
};

const REVENUE_KPI_CARDS = [
  [KPI_CARD.DAILY_AVG, KPI_CARD.LAST_FULL_DAY, KPI_CARD.CAGR],
  [KPI_CARD.ANNUAL_RUN_RATE],
];

const CASHFLOW_KPI_CARDS = [
  [
    KPI_CARD.TOTAL,
    KPI_CARD.MONTH_TO_DATE,
    KPI_CARD.DAILY_AVG,
    KPI_CARD.LAST_FULL_DAY,
  ],
  [KPI_CARD.ANNUAL_RUN_RATE, KPI_CARD.CAGR],
];

const RevenueCashflow = ({
  settings,
  moneyTypeKpis,
  cohortsKpis,
  compareCohortsKpis,
  cohortsData,
  isSubscription,
}) => {
  const [data, setData] = useState({
    kpiData: null,
    compareKpiData: null,
  });

  useEffect(() => {
    if (moneyTypeKpis && cohortsKpis) {
      const _kpiData = parseKpiData({
        config: KPI_PARSE_CONFIG,
        settings,
        cohortsKpis,
        moneyTypeKpis: moneyTypeKpis.actual,
      });

      const _compareKpiData = parseKpiData({
        config: KPI_PARSE_CONFIG,
        settings,
        cohortsKpis: compareCohortsKpis,
        moneyTypeKpis: moneyTypeKpis.compare,
      });

      setData({
        kpiData: _kpiData,
        compareKpiData: _compareKpiData,
      });
    }
  }, [moneyTypeKpis, cohortsKpis, settings]);

  const createKpiItems = () => {
    const { kpiData, compareKpiData } = data;
    if (!settings || !kpiData) {
      return [];
    }

    const kpiCardConfig =
      settings.moneyType === MONEY_TYPE.REVENUE
        ? REVENUE_KPI_CARDS
        : CASHFLOW_KPI_CARDS;

    const kpiCardMap = kpiCardConfig.reduce((acc, row) => {
      row.forEach((kpiCardKey) => {
        acc[kpiCardKey] = true;
      });
      return acc;
    }, {});

    let kpiConfig = {};
    if (kpiCardMap[KPI_CARD.DAILY_AVG]) {
      kpiConfig[KPI_CARD.DAILY_AVG] = {
        id: KPI_CARD.DAILY_AVG,
        title:
          settings.moneyType === MONEY_TYPE.REVENUE
            ? 'Daily Average MRR'
            : 'Daily Average',
        items: [
          {
            value: kpiData[KPI.DAILY_AVG_7_DAYS].total,
            compareValue: compareKpiData[KPI.DAILY_AVG_7_DAYS].total,
            label: '7 Days',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.DAILY_AVG_7_DAYS].annually}
                compareAnnually={compareKpiData[KPI.DAILY_AVG_7_DAYS].annually}
                monthly={kpiData[KPI.DAILY_AVG_7_DAYS].monthly}
                compareMonthly={compareKpiData[KPI.DAILY_AVG_7_DAYS].monthly}
              />
            ),
          },
          {
            value: kpiData[KPI.DAILY_AVG_30_DAYS].total,
            compareValue: compareKpiData[KPI.DAILY_AVG_30_DAYS].total,
            label: '30 Days',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.DAILY_AVG_30_DAYS].annually}
                compareAnnually={compareKpiData[KPI.DAILY_AVG_30_DAYS].annually}
                monthly={kpiData[KPI.DAILY_AVG_30_DAYS].monthly}
                compareMonthly={compareKpiData[KPI.DAILY_AVG_30_DAYS].monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.LAST_FULL_DAY]) {
      kpiConfig[KPI_CARD.LAST_FULL_DAY] = {
        id: KPI_CARD.LAST_FULL_DAY,
        title:
          settings.moneyType === MONEY_TYPE.REVENUE
            ? 'Last Full Day MRR'
            : 'Last Full Day',
        items: [
          {
            value: kpiData[KPI.LAST_FULL_DAY].total,
            compareValue: compareKpiData[KPI.LAST_FULL_DAY].total,
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.LAST_FULL_DAY].annually}
                compareAnnually={compareKpiData[KPI.LAST_FULL_DAY].annually}
                monthly={kpiData[KPI.LAST_FULL_DAY].monthly}
                compareMonthly={compareKpiData[KPI.LAST_FULL_DAY].monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.CAGR]) {
      kpiConfig[KPI_CARD.CAGR] = {
        id: KPI_CARD.CAGR,
        title: 'CAGR',
        items: [
          {
            value: kpiData[KPI.CAGR].total,
            label: '6 Months',
            format: percentageFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={percentageFormat}
                annually={kpiData[KPI.CAGR].annually}
                monthly={kpiData[KPI.CAGR].monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.ANNUAL_RUN_RATE]) {
      kpiConfig[KPI_CARD.ANNUAL_RUN_RATE] = {
        id: KPI_CARD.ANNUAL_RUN_RATE,
        title: 'Annual Run Rate',
        items: [
          {
            value: kpiData[KPI.ANNUAL_RUN_RATE_1_DAY].total,
            compareValue: compareKpiData[KPI.ANNUAL_RUN_RATE_1_DAY].total,
            label: '1 Day',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.ANNUAL_RUN_RATE_1_DAY].annually}
                compareAnnually={
                  compareKpiData[KPI.ANNUAL_RUN_RATE_1_DAY].annually
                }
                monthly={kpiData[KPI.ANNUAL_RUN_RATE_1_DAY].monthly}
                compareMonthly={
                  compareKpiData[KPI.ANNUAL_RUN_RATE_1_DAY].monthly
                }
              />
            ),
          },
          {
            value: kpiData[KPI.ANNUAL_RUN_RATE_7_DAYS].total,
            compareValue: compareKpiData[KPI.ANNUAL_RUN_RATE_7_DAYS].total,
            label: '7 Days',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.ANNUAL_RUN_RATE_7_DAYS].annually}
                compareAnnually={
                  compareKpiData[KPI.ANNUAL_RUN_RATE_7_DAYS].annually
                }
                monthly={kpiData[KPI.ANNUAL_RUN_RATE_7_DAYS].monthly}
                compareMonthly={
                  compareKpiData[KPI.ANNUAL_RUN_RATE_7_DAYS].monthly
                }
              />
            ),
          },
          {
            value: kpiData[KPI.ANNUAL_RUN_RATE_30_DAYS].total,
            compareValue: compareKpiData[KPI.ANNUAL_RUN_RATE_30_DAYS].total,
            label: '30 Days',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.ANNUAL_RUN_RATE_30_DAYS].annually}
                compareAnnually={
                  compareKpiData[KPI.ANNUAL_RUN_RATE_30_DAYS].annually
                }
                monthly={kpiData[KPI.ANNUAL_RUN_RATE_30_DAYS].monthly}
                compareMonthly={
                  compareKpiData[KPI.ANNUAL_RUN_RATE_30_DAYS].monthly
                }
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.TOTAL]) {
      kpiConfig[KPI_CARD.TOTAL] = {
        id: KPI_CARD.TOTAL,
        title: 'Total',
        items: [
          {
            value: kpiData[KPI.TOTAL].total,
            compareValue: compareKpiData[KPI.TOTAL].total,
            label: 'Last 30 Days',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.TOTAL].annually}
                compareAnnually={compareKpiData[KPI.TOTAL].annually}
                monthly={kpiData[KPI.TOTAL].monthly}
                compareMonthly={compareKpiData[KPI.TOTAL].monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.MONTH_TO_DATE]) {
      kpiConfig[KPI_CARD.MONTH_TO_DATE] = {
        id: KPI_CARD.MONTH_TO_DATE,
        title: 'Month to Date',
        items: [
          {
            value: kpiData[KPI.MONTH_TO_DATE].total,
            compareValue: compareKpiData[KPI.MONTH_TO_DATE].total,
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.MONTH_TO_DATE].annually}
                compareAnnually={compareKpiData[KPI.MONTH_TO_DATE].annually}
                monthly={kpiData[KPI.MONTH_TO_DATE].monthly}
                compareMonthly={compareKpiData[KPI.MONTH_TO_DATE].monthly}
              />
            ),
          },
        ],
      };
    }

    let kpiCards = [];
    kpiCardConfig.forEach((row, rowIndex) => {
      row.forEach((kpiCardKey) => {
        let kpiCard = kpiConfig[kpiCardKey];
        kpiCard.row = rowIndex + 1;
        kpiCards.push(kpiCard);
      });
    });

    return kpiCards;
  };

  const kpis = useMemo(() => createKpiItems(), [data]);

  return (
    <SalesSectionCard
      title={settings?.moneyType == MONEY_TYPE.REVENUE ? 'Revenue' : 'Cashflow'}
    >
      <Box mt={50}>
        <KpiSectionHeader
          cardIds={Object.keys(KPI_CARD).map((key) => KPI_CARD[key])}
          context={KpiCardExpandContext}
          hideCollapse={!isSubscription}
        />
        <KpiCollection kpis={kpis} />
      </Box>
      <Box mt={50}>
        <CohortWidget
          cohortsData={cohortsData}
          cohortConfigKey={COHORT_TYPE.REVENUE}
        />
      </Box>
      <Box mt={50}>
        <CacPredictionChart cohortsData={cohortsData} />
      </Box>
    </SalesSectionCard>
  );
};

RevenueCashflow.propTypes = {
  settings: PropTypes.object,
  moneyTypeKpis: PropTypes.object,
  cohortsKpis: PropTypes.object,
  cohortsData: PropTypes.object,
  isSubscription: PropTypes.bool,
};

export default RevenueCashflow;
