import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { getDealsApi } from 'api/dealApi';
import DataTable from 'components/DataTable';
import Card from 'components/card/Card';
import { CockpitUsersContext } from 'context/CockpitUsersContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const DealsOverview = (props) => {
  const [loading, setLoading] = useState(false);
  const [deals, setDeals] = useState([]);

  const toast = useToast();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { getUserName } = useContext(CockpitUsersContext);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Deal Name',
    }),
    columnHelper.accessor('createdAtMillis', {
      cell: (info) => {
        return moment(info.getValue()).format('MMM DD, YYYY');
      },
      header: 'Created at',
    }),
    columnHelper.accessor('userId', {
      cell: (info) => {
        const userId = info.getValue();
        return getUserName(info.getValue()) || userId;
      },
      header: 'Created by',
    }),
  ];

  useEffect(() => {
    getDeals();
  }, [customerId]);

  const getDeals = async () => {
    setLoading(true);
    try {
      const _deals = await getDealsApi({ customerId });
      setDeals(_deals);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load deals',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box maxW={'container.lg'}>
      <Flex justifyContent={'end'}>
        <Button
          variant={'brand'}
          onClick={() => navigate(`/operations/${customerId}/deal/new`)}
        >
          Create Deal
        </Button>
      </Flex>
      <Card>
        <Heading
          variant={'sectionHeader'}
          borderColor={'twelve.green.300'}
          mb={10}
        >
          Deals
        </Heading>
        <Box mx={-5}>
          <DataTable
            data={deals}
            columns={columns}
            rowHeight={'100px'}
            onRowClick={(row) => {
              navigate(
                `/operations/${customerId}/deal/${row.id}`
              );
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

DealsOverview.propTypes = {};

export default DealsOverview;
