// Chakra imports
import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import logoImg from 'assets/img/twelve-logo.png';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand(props) {
  const { mini, hovered } = props;
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');

  return (
    <Flex alignItems="center" flexDirection="column">
      <Image
        src={logoImg}
        w="195px"
        my="32px"
        display={
          mini === false
            ? 'block'
            : mini === true && hovered === true
            ? 'block'
            : 'none'
        }
      />
      <Text
        display={
          mini === false
            ? 'none'
            : mini === true && hovered === true
            ? 'none'
            : 'block'
        }
        fontSize={'30px'}
        fontWeight="800"
        color={logoColor}
      >
        H
      </Text>
      <HSeparator mb="20px" bg={'twelve.green.300'} />
    </Flex>
  );
}

export default SidebarBrand;
