import { Box, Text } from '@chakra-ui/react';
import { percentageFormat } from 'components/Number';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';

const SpendSourcePieChart = ({ data }) => {
  const getChartData = () => {
    let series = [];
    let labels = [];

    if (data) {
      data.forEach((item) => {
        if (item.percentage) {
          series.push(item.percentage);
          labels.push(item.source);
        }
      });   
    }

    return {
      series,
      options: {
        chart: {
          fontFamily: 'DM Sans',
          offsetX: -30,
        },
        labels,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        colors: ['#4318FF', '#4299E1', '#B794F4', '#2C5282', '#6B46C1', '#3182CE', '#805AD5', '#2C5282', '#553C9A', '#1A365D', '#322659'],
        legend: {
          fontSize: '14px',
          formatter: function (seriesName, opts) {
            return [
              seriesName,
              ' ',
              `<b>${percentageFormat(
                opts.w.globals.series[opts.seriesIndex]
              )}</b>`,
            ];
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return percentageFormat(value, { maximumFractionDigits: 1 });
            },
          },
        },
        noData: {
          text: 'No available data',
        },
      },
    };
  };

  const chartData = useMemo(() => getChartData(), [data]);

  return (
    <Box>
      <Text fontWeight={800} mb={10}>
        Source
      </Text>
      <Chart
        type={'pie'}
        width={'100%'}
        height={'100%'}
        series={chartData.series}
        options={chartData.options}
      />
    </Box>
  );
};

SpendSourcePieChart.propTypes = {
  data: PropTypes.array,
};

export default SpendSourcePieChart;
