import {
  AbsoluteCenter,
  Box,
  Flex,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  deleteTearsheetApi,
  downloadExternalTearsheetApi,
  getTearsheetListApi,
} from 'api/tearsheetApi';
import DataTable from 'components/DataTable';
import Card from 'components/card/Card';
import TwMenu from 'components/menu/Menu';
import { CockpitUsersContext } from 'context/CockpitUsersContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UploadTearsheet from './UploadTearsheet';

const TearsheetOverview = () => {
  const [loading, setLoading] = useState(false);
  const [tearsheets, setTearsheets] = useState([]);

  const { getUserName } = useContext(CockpitUsersContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('date', {
      cell: (info) => {
        return moment(info.getValue()).format('MMM DD, YYYY');
      },
      header: 'Date',
    }),
    columnHelper.accessor('companyRank', {
      cell: (info) => info.getValue(),
      header: 'Risk Score',
    }),
    columnHelper.accessor('userId', {
      cell: (info) => {
        const userId = info.getValue();
        return getUserName(info.getValue()) || userId;
      },
      header: 'PRODUCED BY',
    }),
    columnHelper.display({
      id: 'actions',
      meta: {
        maxWidth: 10,
        align: 'right',
      },
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <TwMenu
            items={[
              {
                label: 'Use for Underwriting Report',
                onClick: (event) => {
                  navigate(
                    `/customers/${customerId}/performance/underwriting/new?tearsheetId=${id}`
                  );
                  event.stopPropagation();
                },
              },
              {
                label: 'Delete',
                onClick: (event) => {
                  onDeleteTearsheet(id);
                  event.stopPropagation();
                },
              },
            ]}
          />
        );
      },
    }),
  ];

  useEffect(() => {
    const init = async () => {
      await getTearsheets();
    };
    init();
  }, [customerId]);

  const getTearsheets = async () => {
    setLoading(true);
    try {
      const tearsheets = await getTearsheetListApi({ customerId });
      setTearsheets(tearsheets);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load tearsheets',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteTearsheet = async (id) => {
    setLoading(true);
    try {
      await deleteTearsheetApi(customerId, id);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete tearsheet',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onDeleteTearsheet = async (id) => {
    await deleteTearsheet(id);
    await getTearsheets();
  };

  const downloadExternalTearsheetFile = async (id) => {
    setLoading(true);
    try {
      await downloadExternalTearsheetApi({
        customerId,
        tearsheetId: id,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to download tearsheet',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box maxW={'container.lg'}>
      <Flex justifyContent={'end'}>
        <UploadTearsheet
          onUploadSuccess={() => {
            getTearsheets();
          }}
        />
      </Flex>
      <Card>
        <Heading
          variant={'sectionHeader'}
          borderColor={'twelve.green.300'}
          mb={10}
        >
          Tearsheets
        </Heading>
        <Box mx={-5}>
          <DataTable
            data={tearsheets}
            columns={columns}
            rowHeight={'100px'}
            onRowClick={async (row) => {
              if (row.isExternal) {
                downloadExternalTearsheetFile(row.id);
                return;
              }
              navigate(
                `/customers/${customerId}/performance/tearsheet/${row.id}`
              );
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

TearsheetOverview.propTypes = {};

export default TearsheetOverview;
