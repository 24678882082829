import axios from 'axios';

export const getMarketingKpisLatestApi = async ({
  customerId,
  date,
  saveInputs,
}) => {
  const response = await axios.post(
    `admin/customer/${customerId}/get-marketing-kpis-latest`,
    {
      date,
      saveInputs,
    }
  );
  return response.data;
};

export const getMarketingKpisApi = async ({ customerId, date, saveInputs }) => {
  const response = await axios.post(
    `admin/customer/${customerId}/get-marketing-kpis`, {
      date,
      saveInputs,
    }
    
  );
  return response.data;
};

export const updateMarketingKpisApi = async ({
  customerId,
  date,
  marketingKpis,
}) => {
  const response = await axios.post(
    `admin/customer/${customerId}/update-marketing-kpis`,
    {
      date,
      marketingKpis,
    }
  );
  return response.data;
};

export const getMarketingSpendDataApi = async ({
  customerId,
  date,
}) => {
  const response = await axios.get(
    `admin/customer/${customerId}/marketing-spend-data`,
    {
      params: {
        date,
      },
    }
  );
  return response.data;
};