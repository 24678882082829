import WhiteBox from '../../components/WhiteBox';

function TwelveHome() {
  return (
      <iframe
        width="100%"
        height="3000px"
        src="https://lookerstudio.google.com/embed/reporting/9fbe0df8-fd50-4487-b36b-32e89f2f3a9b/page/p_djlnagl75c"
        allowfullscreen
          style={{ border: 0, overflow: 'hidden' }}
      ></iframe>
  );
}

export default TwelveHome;
