import { Box, Flex, FormLabel, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const FieldInput = ({ label, id, mb, children, extra, ...rest }) => {
  return (
    <Flex direction="column">
      <FormLabel
        color={'secondaryGray.600'}
        display="flex"
        ms="10px"
        htmlFor={id}
        fontSize="sm"
        fontWeight="bold"
        _hover={{ cursor: 'pointer' }}  
      > 
        {label}
        <Text fontSize="sm" fontWeight="400" ms="2px">
          {extra}
        </Text>
      </FormLabel>
      <Box fontWeight="500" h="44px" maxh="44px">
        {React.cloneElement(children, { id, ...rest })}
      </Box>
    </Flex>
  );
};

FieldInput.propTypes = {
  label: PropTypes.string,
};

export default FieldInput;
