import { Box, Flex, GridItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import KpiCard from './KpiCard';

const KpiCollection = ({ kpis, cardMinH, twoLinesTitle }) => {
  const getCardWidth = (items, override) => {
    if (override) {
      return override;
    }

    if (items.length === 1) {
      return 200;
    }

    if (items.length === 2) {
      return 340;
    }

    if (items.length === 3) {
      return 486;
    }

    return 200;
  };

  const KpiSectionRow = ({ kpis, row }) => {
    if (!kpis.length) {
      return null;
    }

    return (
      <Flex gap={9} mt={9}>
        {kpis.map((kpi, index) => {
          const { id, title, items, tooltip,minW } = kpi;

          return (
            <GridItem key={`row${row}_kpi${index}`}>
              <KpiCard
                id={id}
                minH={cardMinH || 150}
                minW={getCardWidth(items, minW)}
                title={title}
                tooltip={tooltip}
                items={items}
                twoLinesTitle={twoLinesTitle}
              />
            </GridItem>
          );
        })}
      </Flex>
    );
  };

  const numberOfRows = Math.max(...kpis.map((kpi) => kpi.row));

  return (
    <Box>
      {Array.from({ length: numberOfRows }, (_, i) => i + 1).map((row) => (
        <KpiSectionRow
          key={`row${row}`}
          row={row}
          kpis={kpis.filter((kpi) => kpi.row === row)}
        />
      ))}
    </Box>
  );
};

KpiCollection.propTypes = {
  kpis: PropTypes.array.isRequired,
  cardMinH: PropTypes.number,
  twoLinesTitle: PropTypes.bool,
};

export default KpiCollection;
