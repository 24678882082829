import { Text } from '@chakra-ui/react';
import { percentageFormat } from 'components/Number';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const getCompareValue = (value, compareValue) => {
  if (value === null || compareValue === null) {
    return null;
  }

  return (value - compareValue) / Math.abs(compareValue);
};

const getCompareDirection = (value) => {
  return value > 0 ? 1 : value < 0 ? -1 : 0;
};

const getCompareValueFormatted = (value) => {
  return percentageFormat(value, {
    maximumFractionDigits: Math.abs(value) < 0.01 ? 1 : 0,
  });
};

const KpiChangeIndicator = ({
  value,
  compareValue,
  getCustomCompareValue,
  getCustomCompareDirection,
  getCustomCompareValueFormatted,
}) => {
  const [changeIndicator, setChangeIndicator] = useState({
    value: null,
    direction: null, //1 - positive, -1 - negative, 0 - no change
  });

  useEffect(() => {
    const changeValue = getCustomCompareValue
      ? getCustomCompareValue(value, compareValue)
      : getCompareValue(value, compareValue);
    const direction = getCustomCompareDirection
      ? getCustomCompareDirection(changeValue)
      : getCompareDirection(changeValue);
    setChangeIndicator({ value: changeValue, direction });
  }, [value, compareValue]);

  return (
    <Text
      fontSize={12}
      data-compare-value={compareValue}
      color={
        changeIndicator.direction > 0
          ? 'horizonGreen.500'
          : changeIndicator.direction < 0
          ? 'horizonRed.500'
          : 'secondaryGray.600'
      }
    >
      {changeIndicator.value > 0 && '+'}
      {getCustomCompareValueFormatted
        ? getCustomCompareValueFormatted(changeIndicator.value)
        : getCompareValueFormatted(changeIndicator.value)}
    </Text>
  );
};

KpiChangeIndicator.propTypes = {
  value: PropTypes.number.isRequired,
  compareValue: PropTypes.number,
  getCustomCompareValue: PropTypes.func,
  getCustomCompareDirection: PropTypes.func,
  getCustomCompareValueFormatted: PropTypes.func,
};

export default KpiChangeIndicator;
