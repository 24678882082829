import axios from 'axios';

export const getBankingProfitLossApi = async ({ customerId, date }) => {
  const response = await axios.get(
    `/admin/customer/${customerId}/profit-loss-banking`,
    {
      params: {
        date,
      },
    }
  );
  return response.data;
};
