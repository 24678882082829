import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { currencyFormat, percentageFormat } from 'components/Number';
import PropTypes from 'prop-types';

const MaxExposureTableRow = ({ label, row }) => {
  const { actual, factor, max_exposure, next_exposure_ratio } = row;

  return (
    <Tr>
      <Td>{label}</Td>
      <Td>{currencyFormat(actual)}</Td>
      <Td>{factor}</Td>
      <Td>{currencyFormat(max_exposure)}</Td>
      <Td>{percentageFormat(next_exposure_ratio, {maximumFractionDigits: 2})}</Td>
    </Tr>
  );
};

const transformBreakdownData = (data) => {
  let transformedData = {
    actual: {},
    additional_to_limit: {},
    additional_to_limit_with_factor: {},
    effective_limit: {},
    factor: {},
  };

  Object.keys(data).forEach((key) => {
    const row = data[key];
    transformedData.actual[key] = currencyFormat(row.actual);
    transformedData.additional_to_limit[key] = currencyFormat(
      row.additional_to_limit
    );
    transformedData.additional_to_limit_with_factor[key] = currencyFormat(
      row.additional_to_limit_with_factor
    );
    transformedData.effective_limit[key] = currencyFormat(row.effective_limit);
    transformedData.factor[key] = percentageFormat(row.factor);
  });

  return transformedData;
};

const BreakdownTableRow = ({ label, row }) => {
  const {
    annual_run_rate_7_days,
    annual_run_rate_14_days,
    annual_run_rate_30_days,
    annual_run_rate_90_days,
    revenue_last_12_months,
  } = row;

  return (
    <Tr>
      <Td>{label}</Td>
      <Td>{annual_run_rate_7_days}</Td>
      <Td>{annual_run_rate_14_days}</Td>
      <Td>{annual_run_rate_30_days}</Td>
      <Td>{annual_run_rate_90_days}</Td>
      <Td>{revenue_last_12_months}</Td>
    </Tr>
  );
};

const BreakdownTable = ({ data }) => {
  if (!data) {
    return null;
  }

  const transformedData = transformBreakdownData(data);

  return (
    <Table
      variant={'noPadding'}
      sx={{ borderCollapse: 'separate', borderSpacing: '24px 16px' }}
    >
      <Thead>
        <Tr>
          <Th>&nbsp;</Th>
          <Th>ARR(7D)</Th>
          <Th>ARR(14D)</Th>
          <Th>ARR(30D)</Th>
          <Th>ARR(90D)</Th>
          <Th>Revenue Last 12 Months (History)</Th>
        </Tr>
      </Thead>
      <Tbody>
        <BreakdownTableRow
          label={'Actual Revenue'}
          row={transformedData.actual}
        />
        <BreakdownTableRow
          label={'Company Score Factor'}
          row={transformedData.effective_limit}
        />
        <BreakdownTableRow
          label={'Addition to the Limit'}
          row={transformedData.additional_to_limit}
        />
        <BreakdownTableRow label={'Revenue Factor'} row={transformedData.factor} />
        <BreakdownTableRow
          label={'Effective Revenue'}
          row={transformedData.additional_to_limit_with_factor}
        />
      </Tbody>
    </Table>
  );
};

const MaxExposureSection = ({ data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!data) {
    return null;
  }

  const { next_exposure } = data;

  return (
    <>
      <Box>
        <Heading variant={'tearsheetSection'}>Max Exposure</Heading>
        <Table
        w={'max'}
          variant={'noPadding'}
          ml={'-10px'}
          mt={'20px'}
          sx={{ borderCollapse: 'separate', borderSpacing: '20px 2px' }}
        >
          <Thead>
            <Tr>
              <Th>&nbsp;</Th>
              <Th>Actual</Th>
              <Th>Factor</Th>
              <Th>Max Exposure</Th>
              <Th>{`Pct. Next Exposure (${currencyFormat(next_exposure)})`}</Th>
            </Tr>
          </Thead>
          <Tbody>
            <MaxExposureTableRow
              label={'Runoff'}
              row={data.revenue_to_runoff}
            />
            <MaxExposureTableRow
              label={
                <Text>
                  Adjusted Run Rate{' '}
                  <Button
                    variant={'link'}
                    color={'primary.main'}
                    fontSize={'11px'}
                    onClick={onOpen}
                  >
                    (Details)
                  </Button>
                </Text>
              }
              row={data.adjusted_run_rate}
            />
            <MaxExposureTableRow label={'Cash'} row={data.cash} />
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={'max'}>
          <ModalCloseButton />
          <ModalBody>
            <Box px={2} py={4}>
              <BreakdownTable data={data.adjusted_run_rate_breakdown} />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

MaxExposureSection.propTypes = {
  data: PropTypes.object,
};

export default MaxExposureSection;
