import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Flex
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const TwAlert = ({ title, text, status, onClose, duration, ...props }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (duration) {
      const timer = setTimeout(() => {
        setShow(false);
        onClose && onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    onClose && onClose();
  };

  if (!show) {
    return null;
  }

  return (
    <Alert borderRadius="8px" status={status} variant={'subtle'} {...props}>
      <Flex mr={4}>
        <AlertIcon />
        <Flex direction="column" fontWeight={'normal'}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {text && <AlertDescription>{text}</AlertDescription>}
        </Flex>
      </Flex>
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={handleClose}
      />
    </Alert>
  );
};

TwAlert.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func,
  status: PropTypes.string,
  duration: PropTypes.number, //duration is milliseconds
};

export default TwAlert;
