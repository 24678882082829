import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaUpload } from 'react-icons/fa';

const FileInput = ({
  initialFile,
  onSelectFiles,
  allowedFileTypes,
  ...props
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    setSelectedFiles(initialFile ? [initialFile] : []);
  }, [initialFile]);

  const onDrop = (acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
    onSelectFiles(acceptedFiles);
  };

  let dropzoneProps = {
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
  };
  if (allowedFileTypes) {
    dropzoneProps.accept = {};
    allowedFileTypes.forEach((fileType) => {
      dropzoneProps.accept[fileType] = [];
    });
  }

  const { getRootProps, getInputProps, open } = useDropzone(dropzoneProps);

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      <InputGroup>
        <Tooltip label={selectedFiles[0]?.name}>
          <Input
            {...props}
            w={props.w || 200}
            onClick={open}
            pr={10}
            placeholder="Select File"
            value={selectedFiles[0]?.name || ''}
            readOnly
          />
        </Tooltip>
        <InputRightElement>
          <Icon as={FaUpload} />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

FileInput.propTypes = {
  initialFile: PropTypes.object,
  onSelectFiles: PropTypes.func.isRequired,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

export default FileInput;
