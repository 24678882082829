import { Box, Flex, Text } from '@chakra-ui/react';
import {
  numberFormat,
  percentageFormat
} from 'components/Number';
import KpiCollection from 'components/kpi/KpiCollection';
import { MONEY_TYPE } from 'constants/salesConstants';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import CohortWidget from './components/CohortWidget';
import SalesSectionCard from './components/SalesSectionCard';
import UsersCharts from './components/UsersCharts';
import { COHORT_TYPE } from './constants/cohortsConfig';
import { parseKpiData } from './helpers/kpiParser';

const KPI = {
  PAYING_USERS_ANNUALLY_TOTAL: 'payingUsersAnnuallyTotal',
  PAYING_USERS_ANNUALLY_PCT: 'payingUsersAnnuallyPct',
  PAYING_USERS_MONTHLY_TOTAL: 'payingUsersMonthlyTotal',
  PAYING_USERS_MONTHLY_PCT: 'payingUsersMonthlyPct',
};

const KPI_PARSE_CONFIG = {
  [KPI.PAYING_USERS_ANNUALLY_TOTAL]: {
    [MONEY_TYPE.REVENUE]: 'number_of_users',
    [MONEY_TYPE.CASHFLOW]: 'total_last_30_days_users',
    isUsers: true,
  },
  [KPI.PAYING_USERS_ANNUALLY_PCT]: {
    [MONEY_TYPE.REVENUE]: 'percent_of_users',
    [MONEY_TYPE.CASHFLOW]: 'total_last_30_days_users_percent',
    isUsers: true,
  },
  [KPI.PAYING_USERS_MONTHLY_TOTAL]: {
    [MONEY_TYPE.REVENUE]: 'number_of_users',
    [MONEY_TYPE.CASHFLOW]: 'total_last_30_days_users',
    isUsers: true,
  },
  [KPI.PAYING_USERS_MONTHLY_PCT]: {
    [MONEY_TYPE.REVENUE]: 'percent_of_users',
    [MONEY_TYPE.CASHFLOW]: 'total_last_30_days_users_percent',
    isUsers: true,
  },
};

const KPI_CARD = {
  PAYING_USERS_ANNUALLY: 'payingUsersAnnually',
  PAYING_USERS_MONTHLY: 'payingUsersMonthly',
};

const Users = ({
  settings,
  moneyTypeKpis,
  cohortsKpis,
  compareCohortsKpis,
  cohortsData,
  isSubscription,
}) => {
  const [data, setData] = useState({
    kpiData: null,
    compareKpiData: null,
  });

  useEffect(() => {
    if (moneyTypeKpis && cohortsKpis) {
      const _kpiData = parseKpiData({
        config: KPI_PARSE_CONFIG,
        settings,
        cohortsKpis,
        moneyTypeKpis: moneyTypeKpis.actual,
      });

      const _compareKpiData = parseKpiData({
        config: KPI_PARSE_CONFIG,
        settings,
        cohortsKpis: compareCohortsKpis,
        moneyTypeKpis: moneyTypeKpis.compare,
      });

      setData({
        kpiData: _kpiData,
        compareKpiData: _compareKpiData,
      });
    }
  }, [moneyTypeKpis, cohortsKpis, settings]);

  const createKpiItems = () => {
    const { kpiData, compareKpiData } = data;
    if (!settings || !kpiData) {
      return [];
    }

    let kpiCards = [];

    if (isSubscription) {
      kpiCards = [
        {
          id: KPI_CARD.PAYING_USERS_ANNUALLY,
          title: 'Paying Users Annually',
          row: 1,
          items: [
            {
              value: kpiData[KPI.PAYING_USERS_ANNUALLY_TOTAL].annually,
              compareValue:
                compareKpiData[KPI.PAYING_USERS_ANNUALLY_TOTAL].annually,
              label: 'Last 30 Days',
              format: numberFormat,
            },
            {
              value: kpiData[KPI.PAYING_USERS_ANNUALLY_PCT].annually,
              label: 'Last 30 Days',
              format: percentageFormat,
            },
          ],
        },
        {
          id: KPI_CARD.PAYING_USERS_MONTHLY,
          title: 'Paying Users Monthly',
          row: 1,
          items: [
            {
              value: kpiData[KPI.PAYING_USERS_MONTHLY_TOTAL].monthly,
              compareValue:
                compareKpiData[KPI.PAYING_USERS_MONTHLY_TOTAL].monthly,
              label: 'Last 30 Days',
              format: numberFormat,
            },
            {
              value: kpiData[KPI.PAYING_USERS_MONTHLY_PCT].monthly,
              label: 'Last 30 Days',
              format: percentageFormat,
            },
          ],
        },
      ];
    }

    return kpiCards;
  };

  const kpis = useMemo(() => createKpiItems(), [data]);

  return (
    <SalesSectionCard title={'Users'}>
      <Box mt={50}>
        <UsersCharts cohortsData={cohortsData} settings={settings} isSubscription={isSubscription} />
      </Box>
      <Box mt={50}>
        <Flex>
          <Text fontWeight={800}>KPIs</Text>
        </Flex>
        <KpiCollection kpis={kpis} />
      </Box>
      <Box mt={50}>
        <CohortWidget
          cohortsData={cohortsData}
          cohortConfigKey={COHORT_TYPE.USERS}
        />
      </Box>
    </SalesSectionCard>
  );
};

Users.propTypes = {
  settings: PropTypes.object,
  moneyTypeKpis: PropTypes.object,
  cohortsKpis: PropTypes.object,
  compareCohortsKpis: PropTypes.object,
  cohortsData: PropTypes.object,
  isSubscription: PropTypes.bool,
};

export default Users;
