import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

// based on AlertDialog component - https://chakra-ui.com/docs/components/alert-dialog
export function DestructiveDialog({
  header,
  body,
  show,
  onDelete,
  onDialogClose,
  deleteButtonText,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  useEffect(() => {
    if (show) {
      onOpen();
    }
  }, [show]);

  const close = () => {
    onClose();
    onDialogClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={close}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {header}
          </AlertDialogHeader>
          <AlertDialogBody fontSize={'sm'} color={'gray.main'}>
            {body}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button variant={'outline'} ref={cancelRef} onClick={close}>
              Cancel
            </Button>
            <Button
              variant={'red'}
              onClick={() => {
                onDelete();
                close();
              }}
              ml={3}
            >
              {deleteButtonText || 'Delete'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

DestructiveDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  body: PropTypes.string,
  deleteButtonText: PropTypes.string,
  onDialogClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
