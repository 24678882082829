import { Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const BackLink = ({text, path}) => {
  return (
    <Link
      as={NavLink}
      color={'brand.300'}
      fontWeight={500}
      ml={3}
      to={path}
    >
      {' '}
      &lt; {text}
    </Link>
  );
};

BackLink.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
};

export default BackLink;
