import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from 'components/Number';

export const COHORT_TYPE = {
  USERS: 'users',
  REVENUE: 'revenue',
  ARPU: 'arpu',
  LTV: 'ltv',
  ROAS: 'roas',
};

export const COHORTS_CONFIG = {
  [COHORT_TYPE.USERS]: {
    key: 'users',
    cohorts: [
      {
        label: 'Total Users',
        cohortType: 'users',
        formatCell: (value) => {
          return numberFormat(value, { maximumFractionDigits: 0 });
        },
      },
      {
        label: 'Retention Users',
        cohortType: 'userRetention',
        formatCell: (value) => {
          return percentageFormat(value, { maximumFractionDigits: 1 });
        },
      },
    ],
  },
  [COHORT_TYPE.REVENUE]: {
    key: 'revenue',
    cohorts: [
      {
        label: 'Total Revenue',
        cohortType: 'revenue',
        formatCell: (value) => {
          return currencyFormat(value);
        },
      },
      {
        label: 'Retention Revenue',
        cohortType: 'revenueRetention',
        formatCell: (value) => {
          return percentageFormat(value, { maximumFractionDigits: 1 });
        },
      },
    ],
  },
  [COHORT_TYPE.ARPU]: {
    key: 'arpu',
    cohorts: [
      {
        label: 'ARPU',
        cohortType: 'arpu',
        formatCell: (value) => {
          return currencyFormat(value, { maximumFractionDigits: 2 });
        },
      },
      {
        label: 'ARPPU',
        cohortType: 'arppu',
        formatCell: (value) => {
          return currencyFormat(value, { maximumFractionDigits: 2 });
        },
      },
    ],
  },
  [COHORT_TYPE.LTV]: {
    key: 'cumulative',
    cohorts: [
      {
        label: 'ARPU',
        cohortType: 'cumulativeByArpu',
        formatCell: (value) => {
          return currencyFormat(value, { maximumFractionDigits: 1 });
        },
      },
      {
        label: 'ARPPU',
        cohortType: 'cumulativeByArppu',
        formatCell: (value) => {
          return currencyFormat(value, { maximumFractionDigits: 1 });
        },
      },
    ],
  },
  [COHORT_TYPE.ROAS]: {
    key: 'roas',
    cohorts: [
      {
        label: 'ROAS ARPU',
        cohortType: 'roasByArpu',
        formatCell: (value) => {
          return percentageFormat(value, { maximumFractionDigits: 1 });
        },
      },
      {
        label: 'ROAS ARPPU',
        cohortType: 'roasByArppu',
        formatCell: (value) => {
          return percentageFormat(value, { maximumFractionDigits: 1 });
        },
      },
    ],
  },
};
