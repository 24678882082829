import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  calcFinancialKpisApi,
  getFinancialBankAccountsApi,
  getFinancialKpisApi,
  getFinancialKpisLatestApi,
  getFinancialSettingsApi,
  updateFinancialKpisApi,
  updateFinancialSettingsApi,
} from 'api/financialApi';
import 'assets/scss/ScrollLinks.scss';
import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from 'components/Number';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from 'components/NumberInput';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Card from 'components/card/Card';
import FieldInput from 'components/fields/FieldInput';
import SelectInput from 'components/inputs/SelectInput';
import EditKpiCollection from 'components/underwriting/EditKpiCollection';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import DateInputHeader from '../DateInputHeader';
import BankAccounts from './BankAccounts';

const inputsKpisConfig = [
  {
    displayName: 'Total Income',
    row: 1,
    items: [
      {
        kpiProp: 'totalIncome',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'COGS',
    tooltip: "Doesn't include UA",
    row: 1,
    items: [
      {
        kpiProp: 'cogs',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Gross Profit',
    row: 1,
    items: [
      {
        kpiProp: 'grossProfit',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'OPEX',
    tooltip: 'Does include UA',
    row: 1,
    items: [
      {
        kpiProp: 'totalOpex',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Net Profit',
    row: 1,
    items: [
      {
        kpiProp: 'netProfit',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'UA',
    row: 2,
    items: [
      {
        kpiProp: 'ua',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Number of Purchases',
    row: 2,
    items: [
      {
        kpiProp: 'numberOfPurchases',
        format: numberFormat,
        fieldElement: TwNumberInput,
      },
    ],
  },
  {
    displayName: 'Daily Avg Net Sales',
    row: 2,
    items: [
      {
        kpiProp: 'avgDailyNetLastSevenDays',
        label: '7 Days',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Net ARPU',
    row: 2,
    items: [
      {
        kpiProp: 'netArpu',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },

  {
    displayName: 'Assets',
    row: 3,
    items: [
      {
        kpiProp: 'totalAssets',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Cash',
    row: 3,
    items: [
      {
        kpiProp: 'cash',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Inventory',
    row: 3,
    items: [
      {
        kpiProp: 'inventory',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Liabilities',
    row: 3,
    items: [
      {
        kpiProp: 'liability',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Debt',
    row: 4,
    items: [
      {
        kpiProp: 'debt',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Twelve Outstanding debt',
    row: 4,
    items: [
      {
        kpiProp: 'twelveOutstandingDebt',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Equity',
    row: 4,
    items: [
      {
        kpiProp: 'equity',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
];

const calcKpisConfig = [
  {
    displayName: 'Gross Profit Margin',
    tooltip: 'Gross Profit / Total Income',
    row: 1,
    items: [
      {
        kpiProp: 'grossProfitMargin',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: 'Gross Burn',
    tooltip: 'OPEX + COGS',
    row: 1,
    items: [
      {
        kpiProp: 'grossBurn',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Net Burn',
    tooltip: 'Total Income - Gross Burn',
    row: 1,
    items: [
      {
        kpiProp: 'netBurn',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Cumulative revenue to end of Runway',
    tooltip: 'Existing Cohorts Net Sales + Net Sales from new customers',
    row: 1,
    items: [
      {
        kpiProp: 'netSalesToRunway',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Months to Runway',
    tooltip:
      'Number of Months till expenses overtake revenue from new and old cohorts',
    row: 1,
    items: [
      {
        kpiProp: 'monthToRunway',
        label: 'Months',
        format: numberFormat,
        fieldElement: TwNumberInput,
      },
    ],
  },
  {
    displayName: 'Cumulative revenue to end of Runoff',
    tooltip: 'Existing Cohorts Net Sales',
    row: 2,
    items: [
      {
        kpiProp: 'netSalesToRunoff',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Months To Runoff',
    tooltip: (
      <Flex direction="column" gap={2}>
        <Text>Without Existing Customers: (Cash ) / (OPEX - UA)</Text>
        <Text>
          With Existing Customers: (Cash + Cumulative revenue to end of
          Runoff) / (OPEX - UA)
        </Text>
      </Flex>
    ),
    row: 2,
    items: [
      {
        kpiProp: 'monthsToRunoffWithoutCustomers',
        label: 'Without Existing Customers',
        format: numberFormat,
        fieldElement: TwNumberInput,
      },
      {
        kpiProp: 'monthsToRunoffWithCustomers',
        label: 'With Existing Customers',
        format: numberFormat,
        fieldElement: TwNumberInput,
      },
    ],
  },
  {
    displayName: 'Inventory At Msrp',
    tooltip: 'Inventory / Gross profit margin (%)',
    row: 2,
    items: [
      {
        kpiProp: 'inventoryAtMsrp',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Purchased From Existing Inventory',
    tooltip: 'Inventory at MSRP / (Avg. last 3 month Net ARPU)',
    row: 2,
    items: [
      {
        kpiProp: 'purchasedFromExistingInventory',
        format: numberFormat,
        fieldElement: TwNumberInput,
      },
    ],
  },
  {
    displayName: 'Contribution Margin',
    tooltip: '(Gross Profit - UA)/Total Income',
    row: 3,
    items: [
      {
        kpiProp: 'contributionMargin',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: 'Debt To Liquid Assets',
    tooltip: (
      <Flex direction="column" gap={2}>
        <Text>Total: Debt / (Inventory + Cash)</Text>
        <Text>Twelve: (Twelve Debt) / (Inventory + Cash)</Text>
      </Flex>
    ),
    row: 3,
    items: [
      {
        kpiProp: 'debtToLiquidAssetsTotal',
        label: 'Total',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
      {
        kpiProp: 'debtToLiquidAssetsTwelve',
        label: 'Twelve',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: 'Debt To Cash',
    tooltip: (
      <Flex direction="column" gap={2}>
        <Text>Total: Debt / Cash</Text>
        <Text>Twelve: (Twelve Debt) / Cash</Text>
      </Flex>
    ),
    row: 3,
    items: [
      {
        kpiProp: 'debtToCashTotal',
        label: 'Total',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
      {
        kpiProp: 'debtToCashTwelve',
        label: 'Twelve',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: 'Debt To Revenue Run Rate',
    tooltip: '(Avg. 7 days Net Revenue * 365) / Debt',
    row: 4,
    items: [
      {
        kpiProp: 'debtToRevenueRunRateTotal',
        label: 'Total',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: 'Liabilities',
    tooltip: (
      <Flex direction="column" gap={2}>
        <Text>Equity: Liability / Equity</Text>
        <Text>Assets: Liability / Assets</Text>
      </Flex>
    ),

    row: 4,
    items: [
      {
        kpiProp: 'totalLiabilitiesToEquity',
        label: 'Equity',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
      {
        kpiProp: 'totalLiabilitiesToAssets',
        label: 'Assets',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: 'Net Profit Margin',
    tooltip: 'Net Profit / Total Income',
    row: 4,
    items: [
      {
        kpiProp: 'netProfitMargin',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
];

const monthsAggregationOptions = [
  { value: 1, label: '1 Month' },
  { value: 3, label: '3 Months' },
  { value: 6, label: '6 Months' },
  { value: 12, label: '12 Months' },
];

const SectionCard = ({ title, kpis, config, onKpiSave, hideEditButton }) => {
  return (
    <Card>
      <Heading variant={'sectionHeader'} borderColor={'brand.300'}>
        {title}
      </Heading>
      <Box>
        <EditKpiCollection
          kpis={kpis}
          config={config}
          onKpiSave={onKpiSave}
          hideEditButton={hideEditButton}
          hasOriginalValues={true}
        />
      </Box>
    </Card>
  );
};

const Financial = ({ isPlayground }) => {
  const urlParams = useParams();
  let [searchParams] = useSearchParams();

  const customerId = isPlayground
    ? searchParams.get('customerId')
    : urlParams.customerId;

  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(
    moment().subtract(1, 'days').format('YYYY-MM-DD')
  );
  const [monthsAggregation, setMonthsAggregation] = useState(null);
  const [financialKpis, setFinancialKpis] = useState();
  const [bankAccountsData, setBankAccountsData] = useState();

  const toast = useToast();

  useEffect(() => {
    const init = async () => {
      const settings = await getFinancialSettings();
      setMonthsAggregation(settings.monthsAggregation);

      if (customerId) {
        loadData({ monthsAggregation: settings.monthsAggregation });
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (customerId && monthsAggregation) {
      loadData({ monthsAggregation });
    }
  }, [date, customerId]);

  const loadData = async ({ monthsAggregation }) => {
    const getKpis = () => {
      return getFinancialKpis({
        isLatest: false,
        monthsAgg: monthsAggregation,
      });
    };

    try {
      setLoading(true);
      const [_financialKpis, _bankAccountsData] = await Promise.all([
        getKpis(),
        getBankAccountsData(),
      ]);

      setFinancialKpis(_financialKpis);
      setBankAccountsData(_bankAccountsData);
    } finally {
      setLoading(false);
    }
  };

  const getFinancialKpis = async ({ isLatest, monthsAgg }) => {
    const apiFunc = isLatest ? getFinancialKpisLatestApi : getFinancialKpisApi;
    try {
      const _financialKpis = await apiFunc({
        customerId,
        date,
        monthsAgg,
        saveInputs: !isPlayground,
      });
      return _financialKpis;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch financial kpis KPIs',
        status: 'error',
      });
      return null;
    }
  };

  const getBankAccountsData = async () => {
    try {
      const _financialKpis = await getFinancialBankAccountsApi({
        customerId,
        date,
      });
      return _financialKpis;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch financial back accounts data',
        status: 'error',
      });
      return null;
    }
  };

  const updateFinancialKpis = async (_financialKpis) => {
    setLoading(true);
    try {
      await updateFinancialKpisApi({
        customerId,
        date,
        financialKpis: _financialKpis,
      });
      toast({
        title: 'Success',
        description: 'Financial data updated successfully',
        status: 'success',
      });
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to update financial data',
        status: 'error',
      });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const onFetchLatestData = async () => {
    setLoading(true);
    const _financialKpis = await getFinancialKpis({
      isLatest: true,
      monthsAgg: monthsAggregation,
    });
    setFinancialKpis(_financialKpis);
    setLoading(false);
  };

  const getFinancialSettings = async () => {
    setLoading(true);
    try {
      const settings = await getFinancialSettingsApi({ customerId });
      return settings;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to get financial settings',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateFinancialSettings = async (_monthsAggregation) => {
    setLoading(true);
    try {
      await updateFinancialSettingsApi({
        customerId,
        monthsAggregation: _monthsAggregation,
      });
      toast({
        title: 'Success',
        description: 'Financial settings updated successfully',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update financial settings',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const calcFinancialKpis = async (_financialKpis) => {
    setLoading(true);
    try {
      const calculatedKpis = await calcFinancialKpisApi({
        customerId,
        date,
        financialKpis: _financialKpis,
      });
      return calculatedKpis;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to calculate financial KPIs',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onKpiSave = async ({ kpiProp, kpi }) => {
    let _financialKpis = cloneDeep(financialKpis);
    _financialKpis[kpiProp] = kpi;

    const calculatedKpis = await calcFinancialKpis(_financialKpis);

    await updateFinancialKpis(calculatedKpis);
    setFinancialKpis(calculatedKpis);
  };

  const onMonthsAggregationChange = async (value) => {
    setMonthsAggregation(value);
    await updateFinancialSettings(value);
  };

  return (
    <OverlaySpinnerWrapper show={loading} isFixed={true}>
      <Box minW={'container.lg'} maxW={'max'}>
        <DateInputHeader
          date={date}
          onDateChange={setDate}
          showCustomerSelect={isPlayground}
          rightButtonElm={
            <Button variant={'brand'} onClick={onFetchLatestData}>
              Fetch Latest Data
            </Button>
          }
          extraInputs={[
            <FieldInput
              label={'Months Aggregation'}
              id={'monthsAggregation'}
              boxShadow={'box'}
            >
              <SelectInput
                value={monthsAggregation}
                options={monthsAggregationOptions}
                onChange={onMonthsAggregationChange}
                isNumber={true}
                w={200}
              />
            </FieldInput>,
          ]}
        />

        {financialKpis && customerId && (
          <>
            {financialKpis.balanceLastUpdated &&
              financialKpis.plLastUpdated && (
                <Box mb={4}>
                  <Flex gap={1}>
                    <Text>Balance Sheet Last Updated:</Text>
                    <Text fontWeight={800}>
                      {moment(financialKpis.balanceLastUpdated.value).format(
                        'MMMM DD, YYYY'
                      )}
                    </Text>
                  </Flex>
                  <Flex gap={1}>
                    <Text>P&L Last Updated:</Text>
                    <Text fontWeight={800}>
                      {moment(financialKpis.plLastUpdated.value).format(
                        'MMMM DD, YYYY'
                      )}
                    </Text>
                  </Flex>
                </Box>
              )}
            <VStack spacing={50}>
              {bankAccountsData && <BankAccounts data={bankAccountsData} />}
              <SectionCard
                title={'Key Metrics'}
                kpis={financialKpis}
                config={inputsKpisConfig}
                onKpiSave={onKpiSave}
                hideEditButton={isPlayground}
              />
              <SectionCard
                title={'KPI'}
                kpis={financialKpis}
                config={calcKpisConfig}
                onKpiSave={onKpiSave}
                hideEditButton={true}
              />
            </VStack>
          </>
        )}
      </Box>
    </OverlaySpinnerWrapper>
  );
};

Financial.propTypes = {
  isPlayground: PropTypes.bool,
};

export default Financial;
