import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { Number } from 'components/Number';
import PropTypes from 'prop-types';

const DetailsTableRow = ({ title, row }) => {
  if (title) {
    return (
      <Tr>
        <Td colSpan={6} fontWeight={'bold'}>
          <Heading variant={'tearsheetSection'} ml={-3}>
            {title}
          </Heading>
        </Td>
      </Tr>
    );
  }

  const { type, min, max, value, weight, weighted_score } = row;

  return (
    <Tr>
      <Td>{type.replace(/_/g,' ')}</Td>
      <Td>
        <Number value={value || 0} />{' '}
      </Td>
      <Td>
        <Number value={min || 0} />
      </Td>
      <Td>
        <Number value={max || 0} />
      </Td>
      <Td>
        <Number value={weight || 0} />
      </Td>
      <Td>
        <Number value={weighted_score || 0} />
      </Td>
    </Tr>
  );
};

const Kpis = ({ kpis }) => {
  if (!kpis) {
    return null;
  }

  return (
    <SimpleGrid
      gridTemplateColumns={'min-content min-content'}
      spacingX={4}
      spacingY={2}
    >
      {kpis.map((kpi) => (
        <>
          <Text whiteSpace={'nowrap'}>{kpi.type.replace(/_/g,' ')}</Text>
          <Number value={kpi.value} />
        </>
      ))}
    </SimpleGrid>
  );
};

const DetailsTable = ({ managementData, performanceData, stabilityData }) => {
  return (
    <Table
      variant={'noPadding'}
      mx={'-10px'}
      __css={{
        'td, th': {
          padding: '5px 10px',
        },
      }}
    >
      <Thead>
        <Tr>
          <Th>&nbsp;</Th>
          <Th>Value</Th>
          <Th>Min</Th>
          <Th>Max</Th>
          <Th>Weight</Th>
          <Th>Weighted Score</Th>
        </Tr>
      </Thead>
      <Tbody>
        <DetailsTableRow title={'Management'} />
        {managementData.map((row, index) => (
          <DetailsTableRow key={index} row={row} />
        ))}
        <DetailsTableRow title={'Performance'} />
        {performanceData.map((row, index) => (
          <DetailsTableRow key={index} row={row} />
        ))}
        <DetailsTableRow title={'Stability'} />
        {stabilityData.map((row, index) => (
          <DetailsTableRow key={index} row={row} />
        ))}
      </Tbody>
    </Table>
  );
};

const CompanyScoreDetails = ({ scoreData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!scoreData) {
    return null;
  }

  const { components, kpis } = scoreData;

  if (!components || !kpis) {
    return null;
  }

  const data = {
    managementData: components?.management || [],
    performanceData: components?.performance || [],
    stabilityData: components?.stability || [],
  };
  return (
    <>
      <Button
        variant={'link'}
        color={'primary.main'}
        fontSize={'11px'}
        onClick={onOpen}
      >
        (Details)
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={'max'}>
          <ModalCloseButton />
          <ModalHeader>Score Details</ModalHeader>
          <ModalBody>
            <Box w={'max'}>
              <DetailsTable {...data} />
              <Center h={'50px'}>
                <Divider orientation="horizontal" borderColor={'auto'} />
              </Center>
              <Box mt={-4}>
                <Text mt={4} fontWeight={'bold'} fontSize={'20px'}>
                  KPIs
                </Text>
                <Box mt={4}>
                  <Kpis kpis={kpis} />
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

CompanyScoreDetails.propTypes = {
  scoreData: PropTypes.object,
};

export default CompanyScoreDetails;
