import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CohortWidget from './components/CohortWidget';
import SalesSectionCard from './components/SalesSectionCard';
import { COHORT_TYPE } from './constants/cohortsConfig';

const Roas = ({ cohortsData }) => {
  return (
    <SalesSectionCard title={'ROAS (Cumulative)'}>
      <Box mt={50}>
        <CohortWidget
          cohortsData={cohortsData}
          cohortConfigKey={COHORT_TYPE.ROAS}
        />
      </Box>
    </SalesSectionCard>
  );
};

Roas.propTypes = {
  cohortsData: PropTypes.object,
};

export default Roas;
