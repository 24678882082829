import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  Fade,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import RadioCardInput from 'components/inputs/RadioCardInput';
import SelectInput from 'components/inputs/SelectInput';
import {
  COMPARISON_PERIOD_OPTIONS,
  CURRENT_MONTH_TYPE_META,
  MONEY_TYPE_META,
  SUBSCRIPTION_TYPE_META,
  TIME_PERIOD_META,
  TOTAL_TYPE_META,
  TREND_TYPE_META
} from 'constants/salesConstants';
import { generateSelectOptions } from 'helpers/inputHelper';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

import useScrollPosition from '@react-hook/window-scroll';
import { createTearsheetApi } from 'api/tearsheetApi';
import redDotSvg from 'assets/svg/red-dot.svg';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import TwAlert from 'components/alert/Alert';
import DrawerCloseButton from 'components/drawer/DrawerCloseButton';
import { dateFormat } from 'helpers/propTypesHelper';
import { NavLink, useParams } from 'react-router-dom';

const Content = ({
  settings,
  defaultSettings,
  onSettingsChange,
  isSubscription,
  isPlayground,
}) => {
  const mainFields = [
    {
      label: 'Comparison Period',
      name: 'comparisonPeriod',
      element: SelectInput,
      options: COMPARISON_PERIOD_OPTIONS,
      isNumberValue: true,
    },
    {
      label: 'Money Type',
      name: 'moneyType',
      element: RadioCardInput,
      options: generateSelectOptions({ metaObj: MONEY_TYPE_META }),
      hide: !isSubscription,
    },
    {
      label: 'Subscription Type',
      name: 'subscriptionType',
      element: SelectInput,
      options: generateSelectOptions({ metaObj: SUBSCRIPTION_TYPE_META }),
      hide: !isSubscription,
    },
    {
      label: 'Totals',
      name: 'totalType',
      element: RadioCardInput,
      options: generateSelectOptions({ metaObj: TOTAL_TYPE_META }),
    },
    {
      label: 'Include Refund Rate',
      name: 'withRefunds',
      element: Switch,
    },
  ];

  const cohortFields = [
    {
      label: 'Trend Type',
      name: 'trendType',
      element: RadioCardInput,
      options: generateSelectOptions({ metaObj: TREND_TYPE_META }),
    },
    {
      label: 'Time Period',
      name: 'timePeriod',
      element: RadioCardInput,
      options: generateSelectOptions({ metaObj: TIME_PERIOD_META }),
    },
    {
      label: 'Current Month Type',
      name: 'currentMonthType',
      element: SelectInput,
      options: generateSelectOptions({ metaObj: CURRENT_MONTH_TYPE_META }),
    },
    {
      label: 'With Prediction',
      name: 'withPrediction',
      element: Switch,
    },
    {
      label: 'Cohorts Range',
      name: 'cohortsMonthsBack',
      element: SelectInput,
      isNumberValue: true,
      hide: !isPlayground,
      options: Array.from({ length: 32 }, (_, i) => ({
        label: `${(i + 6)} months`,
        value: (i + 6),
      })),
    }
  ];

  const onInputChange = (name, value) => {
    const _settings = {
      ...settings,
      [name]: value,
    };
    onSettingsChange(_settings);
  };

  const renderInputElement = ({ item, value }) => {
    const Element = item.element;
    let attr = { name: item.name };

    if (Element === Switch) {
      attr = {
        ...attr,
        isChecked: value,
        onChange: (e) => {
          onInputChange(item.name, e.target.checked);
        },
      };
    } else if (Element === SelectInput || Element === RadioCardInput) {
      attr = {
        ...attr,
        value,
        isNumber: item.isNumberValue,
        options: item.options || [],
        placeholder: item.placeholder,
        fontWeight: 800,
        onChange: (value) => {
          onInputChange(item.name, value);
        },
      };
    }
    return <Element {...attr}></Element>;
  };

  const renderFields = (fields) => {
    return fields.map((field) => {
      const { label, name, hide } = field;
      if (hide) {
        return null;
      }
      const value = settings[name];
      const showDot = defaultSettings[name] !== value;
      return (
        <Fragment key={name}>
          <GridItem position={'relative'}>
            <Flex alignItems={'center'}>
              <Box position={'absolute'} left={'-10px'}>
                <Fade in={showDot}>
                  <Image src={redDotSvg} />
                </Fade>
              </Box>
              <Text lineHeight={'35px'}>{label}</Text>
            </Flex>
          </GridItem>
          <GridItem>
            <Box verticalAlign={'middle'}>
              <Box position={'relative'}>
                {renderInputElement({ item: field, value })}
              </Box>
            </Box>
          </GridItem>
        </Fragment>
      );
    });
  };

  return (
    <Grid
      templateColumns={'repeat(2, auto)'}
      alignItems={'center'}
      gap={5}
      fontSize={'14px'}
    >
      <GridItem>
        <Text fontWeight={800} fontSize={'md'}>
          Preferences
        </Text>
      </GridItem>
      <GridItem></GridItem>
      {renderFields(mainFields)}
      <GridItem mt={5}>
        <Text fontWeight={500}>Cohort Settings</Text>
      </GridItem>
      <GridItem></GridItem>
      {renderFields(cohortFields)}
    </Grid>
  );
};

const SalesSettings = ({
  loading,
  initialSettings,
  defaultSettings,
  onSettingsApply,
  onSaveAsDefault,
  isSubscription,
  date,
  isPlayground
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [settings, setSettings] = useState(initialSettings);
  const [createTearsheetStatus, setCreateTearsheetStatus] = useState(null);
  const [loadingCreateTearsheet, setLoadingCreateTearsheet] = useState(false);
  const { customerId } = useParams();
  const scrollY = useScrollPosition();
  const [isButtonFixed, setIsButtonFixed] = useState(false);

  useEffect(() => {
    if (initialSettings) {
      setSettings(initialSettings);
    }
  }, [initialSettings]);

  useEffect(() => {
    if (scrollY > 110) {
      setIsButtonFixed(true);
    } else {
      setIsButtonFixed(false);
    }
  }, [scrollY]);

  const onCreateTearsheet = async () => {
    setLoadingCreateTearsheet(true);
    try {
      await createTearsheetApi({ customerId, date });
      setCreateTearsheetStatus('success');
    } catch (error) {
      setCreateTearsheetStatus('warning');
      throw error;
    } finally {
      setLoadingCreateTearsheet(false);
    }
  };

  const getCreateTearsheetStatusText = () => {
    switch (createTearsheetStatus) {
      case 'success':
        return (
          <>
            <b>success!</b>{' '}
            <Link
              as={NavLink}
              _hover={{ color: 'twelve.link' }}
              to={`/customers/${customerId}/performance/tearsheet`}
            >
              Head To Tearsheet are to view
            </Link>
          </>
        );
      case 'warning':
        return (
          <>
            The Tearsheet cannot be produced as some <br /> of the data is
            missing.
            <br />
            <br />
            Please review the KPIs in all segments to make sure they are not
            blank and try again.
          </>
        );
      default:
        return '';
    }
  };

  return (
    <>
      <Box h={10}>
        {!isOpen && (
          <Button
            variant={'brand'}
            onClick={onOpen}
            fontSize={18}
            w={155}
            position={isButtonFixed ? 'fixed' : 'relative'}
            right={isButtonFixed ? '-10px' : 0}
            zIndex={1}
          >
            <Fragment>
              {isButtonFixed && <ChevronLeftIcon w={6} h={6} ml={-3} />}
              Preferences
            </Fragment>
          </Button>
        )}
      </Box>
      <Drawer blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <DrawerContent maxW={{ base: 'calc(100vw - 32px)', md: '427px' }}>
          <DrawerCloseButton onClose={onClose} />
          <DrawerBody>
            <OverlaySpinnerWrapper
              show={loading || loadingCreateTearsheet}
              borderRadius={'16px'}
            >
              <Content
                isSubscription={isSubscription}
                settings={settings}
                defaultSettings={defaultSettings}
                isPlayground={isPlayground}
                onSettingsChange={(_settings) => {
                  setSettings(_settings);
                }}
              />
              <Button
                variant={'link'}
                mt={10}
                onClick={() => {
                  setSettings(defaultSettings);
                }}
              >
                Restore All Default Settings
              </Button>
            </OverlaySpinnerWrapper>
          </DrawerBody>
          <DrawerFooter display={'block'} pb={8}>
            <Button
              variant={'brand'}
              w={'full'}
              fontSize={'14px'}
              onClick={() => {
                onSettingsApply(settings);
              }}
            >
              APPLY PREFERENCES TO SALES PAGE
            </Button>
            {!isPlayground && (
              <HStack spacing={4} mt={4}>
                <Button
                  variant={'outline'}
                  w={'full'}
                  fontSize={'14px'}
                  onClick={() => onSaveAsDefault(settings)}
                >
                  SAVE AS DEFAULT
                </Button>
                <Button
                  variant={'outline'}
                  w={'full'}
                  fontSize={'14px'}
                  onClick={onCreateTearsheet}
                >
                  CREATE TEARSHEET
                </Button>
              </HStack>
            )}
            <Box h={130} mt={5}>
              <Fade in={!!createTearsheetStatus}>
                {createTearsheetStatus && (
                  <TwAlert
                    status={createTearsheetStatus}
                    text={getCreateTearsheetStatusText()}
                    onClose={() => setCreateTearsheetStatus(null)}
                    fontSize={14}
                    duration={5000}
                  />
                )}
              </Fade>
            </Box>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

SalesSettings.propTypes = {
  loading: PropTypes.bool,
  onSettingsApply: PropTypes.func.isRequired,
  onSaveAsDefault: PropTypes.func.isRequired,
  initialSettings: PropTypes.shape({
    comparisonPeriod: PropTypes.number.isRequired,
    moneyType: PropTypes.string.isRequired,
    subscriptionType: PropTypes.string.isRequired,
    totalType: PropTypes.string.isRequired,
    withRefunds: PropTypes.bool.isRequired,
    trendType: PropTypes.string.isRequired,
    timePeriod: PropTypes.string.isRequired,
    currentMonthType: PropTypes.string.isRequired,
    withPrediction: PropTypes.bool.isRequired,
  }),
  defaultSettings: PropTypes.shape({
    comparisonPeriod: PropTypes.number.isRequired,
    moneyType: PropTypes.string.isRequired,
    subscriptionType: PropTypes.string.isRequired,
    totalType: PropTypes.string.isRequired,
    withRefunds: PropTypes.bool.isRequired,
    trendType: PropTypes.string.isRequired,
    timePeriod: PropTypes.string.isRequired,
    currentMonthType: PropTypes.string.isRequired,
    withPrediction: PropTypes.bool.isRequired,
  }),
  isSubscription: PropTypes.bool,
  date: dateFormat,
  isPlayground: PropTypes.bool,
};

export default SalesSettings;
