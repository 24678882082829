import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import DrawerCloseButton from 'components/drawer/DrawerCloseButton';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

const FlexRow = ({ label, signPrefix, element, ...props }) => {
  return (
    <Flex justifyContent={'space-between'} align={'center'} {...props}>
      <Text>{label}</Text>
      <Flex gap={'5px'} alignItems={'center'} justifyContent={'flex-end'}>
        <Text>{signPrefix}</Text>
        <Box w={200}>{element}</Box>
      </Flex>
    </Flex>
  );
};

const EditKpiDrawer = ({
  isOpen,
  onClose,
  kpiItem,
  onSave,
  showOriginalValue,
}) => {
  const [newValue, setNewValue] = useState();
  const [newComments, setNewComments] = useState();
  const firstFieldRef = useRef();

  const onApplyClick = () => {
    onSave({
      kpiProp: kpiItem.kpiProp,
      newValue,
      originalValue: kpiItem.originalValue,
      newComments,
    });
    onDrawerClose();
  };

  const onDrawerClose = () => {
    setNewValue(null);
    setNewComments('');
    onClose();
  };

  if (!kpiItem) {
    return null;
  }

  const {
    kpiName,
    originalValue,
    lastOverrideDate,
    fieldElement,
    userName,
    isManual,
    format,
    signPrefix,
    comments,
  } = kpiItem;

  const lastOverrideValue = isManual ? kpiItem.value : null;

  const Field = fieldElement;

  return (
    <Drawer
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onDrawerClose}
      initialFocusRef={firstFieldRef}
    >
      <DrawerContent maxW={{ base: 'calc(100vw - 32px)', md: '427px' }}>
        <DrawerCloseButton onClose={onClose} />
        <DrawerBody>
          <Text fontWeight={800}>Edit Field</Text>
          <Text fontSize={14} fontWeight={800} mt={2}>
            {kpiName} {kpiItem.kpiLabel && `(${kpiItem.kpiLabel})`}
          </Text>
          <form onSubmit={onApplyClick}>
            <VStack spacing={5} align={'stretch'} fontSize={'sm'} mt={10}>
              {showOriginalValue && (
                <FlexRow
                  label={'Original'}
                  signPrefix={signPrefix}
                  element={
                    <Box ml={1}>
                      {originalValue ? format(originalValue) : 'N/A'}
                    </Box>
                  }
                />
              )}
              <FlexRow
                label={'Last Override'}
                signPrefix={signPrefix}
                element={
                  <Box ml={1}>
                    {lastOverrideValue ? format(lastOverrideValue) : 'N/A'}
                  </Box>
                }
              />
              <FlexRow
                label={'Change to'}
                signPrefix={signPrefix}
                element={
                  <Field
                    value={newValue}
                    onChange={setNewValue}
                    ref={firstFieldRef}
                  />
                }
              />
              <FlexRow
                align={'start'}
                label={'Comments'}
                element={
                  <Textarea
                    value={newComments}
                    onChange={(event) => setNewComments(event.target.value)}
                  />
                }
              />
              <FlexRow
                element={
                  <Button type={'submit'} w={'full'} variant={'brand'}>
                    APPLY CHANGES
                  </Button>
                }
              ></FlexRow>
            </VStack>
          </form>
          {lastOverrideValue && (
            <Box mt={'54px'}>
              <Text fontWeight={800}>Last Override</Text>
              <Box fontSize={'sm'}>
                <Text mt={4}>
                  {moment(lastOverrideDate).format('MMM DD, YYYY HH:mm')}
                </Text>
                <Text mt={4}>{userName}</Text>
                <Text mt={2} whiteSpace={'pre-line'}>
                  {comments}
                </Text>
              </Box>
            </Box>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

EditKpiDrawer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showOriginalValue: PropTypes.bool,
  kpiItem: PropTypes.shape({
    kpiProp: PropTypes.string,
    value: PropTypes.any,
    comments: PropTypes.string,
    isManual: PropTypes.bool,
    userName: PropTypes.string,
    lastOverrideDate: PropTypes.number,
    kpiName: PropTypes.string,
    kpiLabel: PropTypes.string,
    format: PropTypes.func,
    signPrefix: PropTypes.string,
    fieldElement: PropTypes.elementType,
  }),
};

export default EditKpiDrawer;
