import { Navigate, Route, Routes } from 'react-router-dom';

import CockpitCustomerProvider from 'context/CockpitCustomerContext';
import AdminLayout from 'layouts/admin';
import OnBoardingLayout from '../layouts/OnBoardingLayout';
import { ProtectedRoute } from './RedirectRoutes';

function CockpitApp({ loginComponent, isAuthenticated }) {
  if (!isAuthenticated) {
    return (
      <Routes>
        <Route element={<OnBoardingLayout />}>
          <Route path="/" index element={loginComponent} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <OnBoardingLayout />
            </ProtectedRoute>
          }
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <CockpitCustomerProvider>
            {' '}
            <AdminLayout />
          </CockpitCustomerProvider>
        }
      />
      <Route path="/" element={<Navigate to="/customers" replace />} />
    </Routes>
  );
}

export default CockpitApp;
