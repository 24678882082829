import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  deleteUnderwritingApi,
  getUnderwritingListApi,
} from 'api/underwritingApi';
import DataTable from 'components/DataTable';
import Card from 'components/card/Card';
import TwMenu from 'components/menu/Menu';
import { CockpitUsersContext } from 'context/CockpitUsersContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const UnderwritingOverview = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const { customerId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();

  const { getUserName } = useContext(CockpitUsersContext);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('createdAt', {
      cell: (info) =>
        info.getValue() === null
          ? 'N/A'
          : <Text whiteSpace={'nowrap'}>{moment(info.getValue()).format('MMM DD, YYYY')}</Text>,
      header: 'Date of Report',
    }),
    columnHelper.accessor('tearsheetDate', {
      cell: (info) =>
        info.getValue() === null
          ? 'N/A'
          : <Text whiteSpace={'nowrap'}>{moment(info.getValue()).format('MMM DD, YYYY')}</Text>,
      header: 'Tearsheet Date',
    }),
    columnHelper.accessor('producedUserId', {
      cell: (info) => {
        const userId = info.getValue();
        return getUserName(info.getValue()) || userId;
      },
      header: 'PRODUCED BY',
    }),
    columnHelper.display({
      id: 'actions',
      meta: {
        maxWidth: 10,
        align: 'right',
      },
      cell: (info) => {
        const { id, approvedAt } = info.row.original;
        if (!!approvedAt) {
          return null;
        }

        return (
          <TwMenu
            items={[
              {
                label: 'Delete',
                onClick: (event) => {
                  onDeleteUnderwriting(id);
                  event.stopPropagation();
                },
              },
            ]}
          />
        );
      },
    }),
  ];

  useEffect(() => {
    const init = async () => {
      await getUnderwritingList();
    };
    init();
  }, [customerId]);

  const getUnderwritingList = async () => {
    setLoading(true);
    try {
      const _list = await getUnderwritingListApi({ customerId });
      setList(_list);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to get underwriting list',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const deleteUnderwriting = async (id) => {
    setLoading(true);
    try {
      await deleteUnderwritingApi({ customerId, underwritingId: id });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete underwriting',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onDeleteUnderwriting = async (id) => {
    await deleteUnderwriting(id);
    await getUnderwritingList();
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box maxW={'container.lg'}>
      <Flex justifyContent={'end'}>
        <Button
          variant={'brand'}
          onClick={() =>
            navigate(`/customers/${customerId}/performance/underwriting/new`)
          }
        >
          Create Report
        </Button>
      </Flex>
      <Card>
        <Heading
          variant={'sectionHeader'}
          borderColor={'twelve.green.300'}
          mb={10}
        >
          Underwriting Report
        </Heading>
        <Box mx={-5}>
          <DataTable
            data={list}
            columns={columns}
            rowHeight={'100px'}
            onRowClick={(row) => {
              navigate(
                `/customers/${customerId}/performance/underwriting/${row.id}`
              );
            }}
          />
        </Box>
      </Card>
    </Box>
  );
};

UnderwritingOverview.propTypes = {};

export default UnderwritingOverview;
