import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  createRepaymentAllocationApi,
  getRemainingInstallmentsApi,
} from 'api/dealApi';
import { TwDatePicker } from 'components/DatePicker';
import { Currency, currencyFormat } from 'components/Number';
import { CurrencyNumberInput } from 'components/NumberInput';
import Card from 'components/card/Card';
import SelectInput from 'components/inputs/SelectInput';
import BackLink from 'components/links/BackLink';
import { Decimal } from 'decimal.js';
import { useTwelveBankAccounts } from 'hooks/useTwelveBankAccounts';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Repayment = () => {
  const [loading, setLoading] = useState(true);
  const [bankAccountId, setBankAccountId] = useState('');
  const [transferDate, setTransferDate] = useState(null);
  const [repaymentAmount, setRepaymentAmount] = useState(null);
  const [allocations, setAllocations] = useState(null);
  const { bankAccountOptions, isTwelveBankAccountsLoading } =
    useTwelveBankAccounts();

  const { customerId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    getRemainingInstallments();
  }, []);

  const getRemainingInstallments = async () => {
    try {
      let _allocations = await getRemainingInstallmentsApi({
        customerId,
      });

      _allocations = _allocations.sort((a, b) =>
        moment(a.transferDate).diff(moment(b.transferDate))
      );

      setAllocations(_allocations);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to get remaining installments',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createRepaymentAllocation = async () => {
    setLoading(true);
    try {
      await createRepaymentAllocationApi({
        customerId,
        bankAccountId,
        transferDate,
        allocations,
      });
      navigate(`/operations/${customerId}/installment`);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to create repayment allocation',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const isValid = () => {
    if (!bankAccountId || !repaymentAmount) {
      return false;
    }

    const sum = getSumOfAllocations();
    return sum.equals(new Decimal(repaymentAmount));
  };

  if (loading || isTwelveBankAccountsLoading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  const getSumOfAllocations = () => {
    return Decimal.sum(...allocations.map((a) => a.amount || 0));
  };

  return (
    <Box minW={1087} maxW={'max'} fontSize={14} mt={-10}>
      <BackLink
        text={'Back to installments table'}
        path={`/operations/${customerId}/installment`}
      />
      <Card mt={'100px'}>
        <Heading
          variant={'sectionHeader'}
          borderColor={'twelve.green.300'}
          mb={10}
        >
          Repayment
        </Heading>
        <SimpleGrid
          w={'max'}
          gridTemplateColumns={'min-content min-content min-content'}
          whiteSpace={'nowrap'}
          justifyContent={'space-between'}
          spacingY={4}
          spacingX={10}
          alignItems={'center'}
          fontSize={'sm'}
        >
          <Fragment>
            <Text>Bank Account</Text>
            <Flex justifyContent={'flex-end'}>
              <SelectInput
                value={bankAccountId}
                options={bankAccountOptions}
                placeholder={'Select bank account'}
                onChange={setBankAccountId}
                w={200}
              />
            </Flex>
            <Box></Box>
          </Fragment>
          <Fragment>
            <Text>Transfer Date</Text>
            <Flex justifyContent={'flex-end'}>
              <Box w={200}>
                <TwDatePicker value={transferDate} onChange={setTransferDate} />
              </Box>
            </Flex>
            <Box></Box>
          </Fragment>
          <Fragment>
            <Text>Repayment Amount</Text>
            <CurrencyNumberInput
              value={repaymentAmount}
              onChange={setRepaymentAmount}
              showPrefix={true}
              w={200}
            />
            {repaymentAmount ? (
              <Text fontWeight={800}>
                <Currency
                  value={new Decimal(repaymentAmount).minus(
                    getSumOfAllocations()
                  )}
                  maximumFractionDigits={2}
                />
              </Text>
            ) : (
              <Box></Box>
            )}
          </Fragment>
          <Fragment>
            <Text mt={5}>INSTALLMENTS</Text>
            <Box></Box>
            <Text mt={5}>REMAINING</Text>
          </Fragment>
          {allocations?.map((installment, index) => (
            <Fragment key={installment.id}>
              <Text>
                {moment(installment.transferDate).format('MMM DD, YYYY')}&nbsp;
                {`(${installment.installmentNumber}, ${currencyFormat(
                  installment.purchasePrice,
                  { maximumFractionDigits: 2 }
                )})`}
              </Text>

              <CurrencyNumberInput
                w={200}
                showPrefix={true}
                value={allocations[index].amount}
                onChange={(value) => {
                  const _allocations = [...allocations];

                  //do not allow values greater than total left
                  if (value > _allocations[index].totalLeft) {
                    value = _allocations[index].totalLeft;
                  } else {
                    _allocations[index].amount = value;
                    setAllocations(_allocations);
                  }
                }}
              />
              <Text>
                <Currency
                  value={installment.totalLeft}
                  maximumFractionDigits={2}
                />
              </Text>
            </Fragment>
          ))}
        </SimpleGrid>
        <Flex justify={'end'} mt={10}>
          <Button
            type={'submit'}
            variant={'brand'}
            w={200}
            isDisabled={!isValid()}
            onClick={createRepaymentAllocation}
          >
            Apply
          </Button>
        </Flex>
      </Card>
    </Box>
  );
};

Repayment.propTypes = {};

export default Repayment;
