import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CohortWidget from './components/CohortWidget';
import SalesSectionCard from './components/SalesSectionCard';
import { COHORT_TYPE } from './constants/cohortsConfig';

const Arpu = ({ cohortsData }) => {
  return (
    <SalesSectionCard title={'ARPU'}>
      <Box mt={50}>
        <CohortWidget
          cohortsData={cohortsData}
          cohortConfigKey={COHORT_TYPE.ARPU}
        />
      </Box>
    </SalesSectionCard>
  );
};

Arpu.propTypes = {
  cohortsData: PropTypes.object,
};

export default Arpu;
