import axios from 'axios';

export const calcCashflowModel = async (data) => {
  const response = await axios.post(`/admin/cashflow-model-calc`, data);
  return response.data;
};

export const updateCashflowModelDefaultsSettingsApi = async ({customerId, settings}) => {
  const response = await axios.post(
    `/admin/cashflow-model/customer/${customerId}/default-settings`,
    settings
  );
  return response.data;
};

export const getCashflowModelDefaultsSettingsApi = async (customerId) => {
  const response = await axios.get(`/admin/cashflow-model/customer/${customerId}/default-settings`);
  return response.data;
}
