export const toScientificNotation = (num) => {
  if (num === 0) {
    return '0';
  }

  let exponent = Math.floor(Math.log10(Math.abs(num)));
  let mantissa = num / Math.pow(10, exponent);

  // Formatting to remove trailing zeros
  let formattedMantissa = parseFloat(mantissa.toFixed(2));

  return {
    exponent,
    mantissa: formattedMantissa,
  }
};
