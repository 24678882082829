import { createContext, useEffect } from 'react';

import { useToast } from '@chakra-ui/react';
import { getCustomerApi } from 'api/customerApi';
import { getCustomerBusinessInfoApi } from 'api/underwritingApi';
import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

export const CockpitCustomerContext = createContext();

const CockpitCustomerProvider = ({ children }) => {
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const path = location.pathname.split('/');
    let _customerId =  ['customers', 'operations'].includes(path[1]) ? path[2] : null;
    if (searchParams.get('customerId')) {
      _customerId = searchParams.get('customerId');
    }
    setCustomerId(_customerId);
  }, [location]);

  useEffect(() => {
    const init = async () => {
      if (customerId) {
        await loadData();
      } else {
        setLoading(false);
        setCustomer(null);
      }
    };

    init();
  }, [customerId]);

  const loadData = async () => {
    setLoading(true);
    const promises = [getCustomer(), getBusinessInfo()];
    try {
      let [_customer, _businessInfo] = await Promise.all(promises);
      if (_businessInfo) {
        _customer.model = _businessInfo.model;
      }
      setCustomer(_customer);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getCustomer = async () => {
    try {
      const _customer = await getCustomerApi({ customerId });
      return _customer;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load customer.',
        status: 'error',
      });
      throw error;
    }
  };

  const getBusinessInfo = async () => {
    try {
      const _businessInfo = await getCustomerBusinessInfoApi({ customerId });
      return _businessInfo;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load business info',
        status: 'error',
      });
      throw error;
    }
  };

  const updateBusinessModel = async (model) => {
    setCustomer((prev) => ({ ...prev, model }));
  };

  return (
    <CockpitCustomerContext.Provider
      value={{
        customer,
        updateBusinessModel,
        isCustomerLoading: loading,
      }}
    >
      {children}
    </CockpitCustomerContext.Provider>
  );
};

export default CockpitCustomerProvider;
