import axios from 'axios';

export const getFinancialSettingsApi = async ({ customerId }) => {
  const response = await axios.get(
    `admin/customer/${customerId}/financial-settings`
  );
  return response.data;
};

export const updateFinancialSettingsApi = async ({
  customerId,
  monthsAggregation,
}) => {
  const response = await axios.post(
    `admin/customer/${customerId}/financial-settings`,
    {
      monthsAggregation,
    }
  );
  return response.data;
};

export const getFinancialKpisApi = async ({
  customerId,
  date,
  monthsAgg,
  saveInputs,
}) => {
  const response = await axios.post(
    `admin/customer/${customerId}/get-financial-kpis`,
    {
      date,
      monthsAgg,
      saveInputs,
    }
  );
  return response.data;
};

export const updateFinancialKpisApi = async ({
  customerId,
  date,
  financialKpis,
}) => {
  const response = await axios.post(
    `admin/customer/${customerId}/update-financial-kpis`,
    {
      date,
      financialKpis,
    }
  );
  return response.data;
};

export const getFinancialKpisLatestApi = async ({
  customerId,
  date,
  monthsAgg,
  saveInputs,
}) => {
  const response = await axios.post(
    `admin/customer/${customerId}/get-financial-kpis-latest`,
    {
      date,
      monthsAgg,
      saveInputs,
    }
  );
  return response.data;
};
export const calcFinancialKpisApi = async ({
  customerId,
  date,
  financialKpis,
}) => {
  const response = await axios.post(
    `admin/customer/${customerId}/calc-financial-kpis`,
    {
      date,
      financialKpis,
    }
  );
  return response.data;
};

export const getFinancialBankAccountsApi = async ({ customerId, date }) => {
  const response = await axios.get(
    `admin/customer/${customerId}/financial-bank-accounts`,
    {
      params: {
        date,
      },
    }
  );
  return response.data;
};
