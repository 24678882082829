import { getAllCustomersApi } from 'api/adminApi';
import SelectInput from 'components/inputs/SelectInput';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const CustomerSelect = ({ isPlayground, onChange, customerId }) => {
  const [options, setOptions] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    isPlayground ? sessionStorage.getItem('playgroundCustomerId') : null
  );

  let [searchParams, setSearchParams] = useSearchParams({
    customerId: selectedCustomerId,
  });

  useEffect(() => {
    const init = async () => {
      try {
        const customers = await getAllCustomersApi();

        setOptions(
          customers.map((customer) => ({
            label: customer.companyName,
            value: customer.id,
          }))
        );
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (!isPlayground) {
      setSelectedCustomerId(customerId);
    }
  }, [customerId]);

  const onCustomerChange = (customerId) => {
    if (isPlayground) {
      sessionStorage.setItem('playgroundCustomerId', customerId);
      const params = new URLSearchParams(searchParams);
      params.set('customerId', customerId);
      setSearchParams(params);
    }

    if (onChange) {
      onChange(customerId);
    }

    setSelectedCustomerId(customerId);
  };

  return (
    <SelectInput
      options={options}
      value={selectedCustomerId}
      enableSearch={true}
      onChange={onCustomerChange}
      placeholder={'-- Select Customer --'}
    />
  );
};

CustomerSelect.propTypes = {
  keepInSessionStorage: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CustomerSelect;
