export const cohortsMockData = {
  rows: [
    {
      cohort_date: {
        year: 2023,
        month: 7,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 3346,
        with_refunds: 3346,
      },
      first_period_arr_users: {
        without_refunds: 263,
        with_refunds: 263,
      },
      first_period_arr_spread_users: {
        without_refunds: 241,
        with_refunds: 241,
      },
      first_period_total_users: {
        without_refunds: 3575,
        with_refunds: 3575,
      },
      first_period_total_spread_users: {
        without_refunds: 3557,
        with_refunds: 3557,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2052,
                  with_refunds: 2052,
                },
                arr_users: {
                  without_refunds: 172,
                  with_refunds: 172,
                },
                arr_spread_users: {
                  without_refunds: 159,
                  with_refunds: 159,
                },
                total_users: {
                  without_refunds: 2224,
                  with_refunds: 2224,
                },
                total_spread_users: {
                  without_refunds: 2211,
                  with_refunds: 2211,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6133,
                  with_refunds: 0.6133,
                },
                arr_users: {
                  without_refunds: 0.654,
                  with_refunds: 0.654,
                },
                arr_spread_users: {
                  without_refunds: 0.6598,
                  with_refunds: 0.6598,
                },
                total_users: {
                  without_refunds: 0.6221,
                  with_refunds: 0.6221,
                },
                total_spread_users: {
                  without_refunds: 0.6216,
                  with_refunds: 0.6216,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 20851.15,
                  with_refunds: 20313.22,
                },
                gross_mrr: {
                  without_refunds: 27753.14,
                  with_refunds: 27087.39,
                },
                net_arr: {
                  without_refunds: 12252.2,
                  with_refunds: 11504.14,
                },
                gross_arr: {
                  without_refunds: 19154.82,
                  with_refunds: 17925.73,
                },
                net_arr_spread: {
                  without_refunds: 940.41,
                  with_refunds: 940.41,
                },
                gross_arr_spread: {
                  without_refunds: 1464.69,
                  with_refunds: 1464.69,
                },
                net_total: {
                  without_refunds: 33103.36,
                  with_refunds: 31817.36,
                },
                gross_total: {
                  without_refunds: 46907.96,
                  with_refunds: 45013.12,
                },
                net_total_spread: {
                  without_refunds: 21791.57,
                  with_refunds: 21253.63,
                },
                gross_total_spread: {
                  without_refunds: 29217.83,
                  with_refunds: 28552.07,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.23,
                  with_refunds: 6.07,
                },
                gross_mrr: {
                  without_refunds: 8.29,
                  with_refunds: 8.1,
                },
                net_arr: {
                  without_refunds: 46.59,
                  with_refunds: 43.74,
                },
                gross_arr: {
                  without_refunds: 72.83,
                  with_refunds: 68.16,
                },
                net_arr_spread: {
                  without_refunds: 3.9,
                  with_refunds: 3.9,
                },
                gross_arr_spread: {
                  without_refunds: 6.08,
                  with_refunds: 6.08,
                },
                net_total: {
                  without_refunds: 9.26,
                  with_refunds: 8.9,
                },
                gross_total: {
                  without_refunds: 13.12,
                  with_refunds: 12.59,
                },
                net_total_spread: {
                  without_refunds: 6.13,
                  with_refunds: 5.98,
                },
                gross_total_spread: {
                  without_refunds: 8.21,
                  with_refunds: 8.03,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.16,
                  with_refunds: 9.9,
                },
                gross_mrr: {
                  without_refunds: 13.52,
                  with_refunds: 13.2,
                },
                net_arr: {
                  without_refunds: 71.23,
                  with_refunds: 66.88,
                },
                gross_arr: {
                  without_refunds: 111.37,
                  with_refunds: 104.22,
                },
                net_arr_spread: {
                  without_refunds: 5.91,
                  with_refunds: 5.91,
                },
                gross_arr_spread: {
                  without_refunds: 9.21,
                  with_refunds: 9.21,
                },
                net_total: {
                  without_refunds: 14.88,
                  with_refunds: 14.31,
                },
                gross_total: {
                  without_refunds: 21.09,
                  with_refunds: 20.24,
                },
                net_total_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.61,
                },
                gross_total_spread: {
                  without_refunds: 13.21,
                  with_refunds: 12.91,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 6.23,
                    with_refunds: 6.07,
                  },
                  gross_mrr: {
                    without_refunds: 8.29,
                    with_refunds: 8.1,
                  },
                  net_arr: {
                    without_refunds: 46.59,
                    with_refunds: 43.74,
                  },
                  gross_arr: {
                    without_refunds: 72.83,
                    with_refunds: 68.16,
                  },
                  net_arr_spread: {
                    without_refunds: 3.9,
                    with_refunds: 3.9,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.08,
                    with_refunds: 6.08,
                  },
                  net_total: {
                    without_refunds: 9.26,
                    with_refunds: 8.9,
                  },
                  gross_total: {
                    without_refunds: 13.12,
                    with_refunds: 12.59,
                  },
                  net_total_spread: {
                    without_refunds: 6.13,
                    with_refunds: 5.98,
                  },
                  gross_total_spread: {
                    without_refunds: 8.21,
                    with_refunds: 8.03,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.16,
                    with_refunds: 9.9,
                  },
                  gross_mrr: {
                    without_refunds: 13.52,
                    with_refunds: 13.2,
                  },
                  net_arr: {
                    without_refunds: 71.23,
                    with_refunds: 66.88,
                  },
                  gross_arr: {
                    without_refunds: 111.37,
                    with_refunds: 104.22,
                  },
                  net_arr_spread: {
                    without_refunds: 5.91,
                    with_refunds: 5.91,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.21,
                    with_refunds: 9.21,
                  },
                  net_total: {
                    without_refunds: 14.88,
                    with_refunds: 14.31,
                  },
                  gross_total: {
                    without_refunds: 21.09,
                    with_refunds: 20.24,
                  },
                  net_total_spread: {
                    without_refunds: 9.86,
                    with_refunds: 9.61,
                  },
                  gross_total_spread: {
                    without_refunds: 13.21,
                    with_refunds: 12.91,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1418,
                    with_refunds: 0.1381,
                  },
                  gross_mrr: {
                    without_refunds: 0.1887,
                    with_refunds: 0.1842,
                  },
                  net_arr: {
                    without_refunds: 1.06,
                    with_refunds: 0.9953,
                  },
                  gross_arr: {
                    without_refunds: 1.6572,
                    with_refunds: 1.5508,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0888,
                    with_refunds: 0.0888,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1383,
                    with_refunds: 0.1383,
                  },
                  net_total: {
                    without_refunds: 0.2107,
                    with_refunds: 0.2025,
                  },
                  gross_total: {
                    without_refunds: 0.2985,
                    with_refunds: 0.2865,
                  },
                  net_total_spread: {
                    without_refunds: 0.1394,
                    with_refunds: 0.136,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1869,
                    with_refunds: 0.1826,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2312,
                    with_refunds: 0.2252,
                  },
                  gross_mrr: {
                    without_refunds: 0.3077,
                    with_refunds: 0.3004,
                  },
                  net_arr: {
                    without_refunds: 1.6208,
                    with_refunds: 1.5218,
                  },
                  gross_arr: {
                    without_refunds: 2.5339,
                    with_refunds: 2.3713,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1346,
                    with_refunds: 0.1346,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2096,
                    with_refunds: 0.2096,
                  },
                  net_total: {
                    without_refunds: 0.3387,
                    with_refunds: 0.3255,
                  },
                  gross_total: {
                    without_refunds: 0.4799,
                    with_refunds: 0.4605,
                  },
                  net_total_spread: {
                    without_refunds: 0.2243,
                    with_refunds: 0.2187,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3007,
                    with_refunds: 0.2938,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2504,
                  with_refunds: 2518,
                },
                arr_users: {
                  without_refunds: 50,
                  with_refunds: 53,
                },
                arr_spread_users: {
                  without_refunds: 206,
                  with_refunds: 206,
                },
                total_users: {
                  without_refunds: 2554,
                  with_refunds: 2571,
                },
                total_spread_users: {
                  without_refunds: 2710,
                  with_refunds: 2724,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7484,
                  with_refunds: 0.7525,
                },
                arr_users: {
                  without_refunds: 0.1901,
                  with_refunds: 0.2015,
                },
                arr_spread_users: {
                  without_refunds: 0.8548,
                  with_refunds: 0.8548,
                },
                total_users: {
                  without_refunds: 0.7144,
                  with_refunds: 0.7192,
                },
                total_spread_users: {
                  without_refunds: 0.7619,
                  with_refunds: 0.7658,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.2203,
                  with_refunds: 1.2271,
                },
                arr_users: {
                  without_refunds: 0.2907,
                  with_refunds: 0.3081,
                },
                arr_spread_users: {
                  without_refunds: 1.2956,
                  with_refunds: 1.2956,
                },
                total_users: {
                  without_refunds: 1.1484,
                  with_refunds: 1.156,
                },
                total_spread_users: {
                  without_refunds: 1.2257,
                  with_refunds: 1.232,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 27123.06,
                  with_refunds: 26282.62,
                },
                gross_mrr: {
                  without_refunds: 32770.02,
                  with_refunds: 31747.71,
                },
                net_arr: {
                  without_refunds: 3616.87,
                  with_refunds: 3182.31,
                },
                gross_arr: {
                  without_refunds: 5588.36,
                  with_refunds: 4908.08,
                },
                net_arr_spread: {
                  without_refunds: 1223.71,
                  with_refunds: 1223.71,
                },
                gross_arr_spread: {
                  without_refunds: 1902.56,
                  with_refunds: 1902.56,
                },
                net_total: {
                  without_refunds: 30739.93,
                  with_refunds: 29464.93,
                },
                gross_total: {
                  without_refunds: 38358.38,
                  with_refunds: 36655.79,
                },
                net_total_spread: {
                  without_refunds: 28346.77,
                  with_refunds: 27506.33,
                },
                gross_total_spread: {
                  without_refunds: 34672.58,
                  with_refunds: 33650.27,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2939,
                },
                gross_mrr: {
                  without_refunds: 1.1808,
                  with_refunds: 1.172,
                },
                net_arr: {
                  without_refunds: 0.2952,
                  with_refunds: 0.2766,
                },
                gross_arr: {
                  without_refunds: 0.2917,
                  with_refunds: 0.2738,
                },
                net_arr_spread: {
                  without_refunds: 1.3012,
                  with_refunds: 1.3012,
                },
                gross_arr_spread: {
                  without_refunds: 1.299,
                  with_refunds: 1.299,
                },
                net_total: {
                  without_refunds: 0.9286,
                  with_refunds: 0.9261,
                },
                gross_total: {
                  without_refunds: 0.8177,
                  with_refunds: 0.8143,
                },
                net_total_spread: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2942,
                },
                gross_total_spread: {
                  without_refunds: 1.1867,
                  with_refunds: 1.1786,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2939,
                },
                gross_mrr: {
                  without_refunds: 1.1808,
                  with_refunds: 1.172,
                },
                net_arr: {
                  without_refunds: 0.2952,
                  with_refunds: 0.2766,
                },
                gross_arr: {
                  without_refunds: 0.2917,
                  with_refunds: 0.2738,
                },
                net_arr_spread: {
                  without_refunds: 1.3012,
                  with_refunds: 1.3012,
                },
                gross_arr_spread: {
                  without_refunds: 1.299,
                  with_refunds: 1.299,
                },
                net_total: {
                  without_refunds: 0.9286,
                  with_refunds: 0.9261,
                },
                gross_total: {
                  without_refunds: 0.8177,
                  with_refunds: 0.8143,
                },
                net_total_spread: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2942,
                },
                gross_total_spread: {
                  without_refunds: 1.1867,
                  with_refunds: 1.1786,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.11,
                  with_refunds: 7.85,
                },
                gross_mrr: {
                  without_refunds: 9.79,
                  with_refunds: 9.49,
                },
                net_arr: {
                  without_refunds: 13.75,
                  with_refunds: 12.1,
                },
                gross_arr: {
                  without_refunds: 21.25,
                  with_refunds: 18.66,
                },
                net_arr_spread: {
                  without_refunds: 5.08,
                  with_refunds: 5.08,
                },
                gross_arr_spread: {
                  without_refunds: 7.89,
                  with_refunds: 7.89,
                },
                net_total: {
                  without_refunds: 8.6,
                  with_refunds: 8.24,
                },
                gross_total: {
                  without_refunds: 10.73,
                  with_refunds: 10.25,
                },
                net_total_spread: {
                  without_refunds: 7.97,
                  with_refunds: 7.73,
                },
                gross_total_spread: {
                  without_refunds: 9.75,
                  with_refunds: 9.46,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.83,
                  with_refunds: 10.44,
                },
                gross_mrr: {
                  without_refunds: 13.09,
                  with_refunds: 12.61,
                },
                net_arr: {
                  without_refunds: 72.34,
                  with_refunds: 60.04,
                },
                gross_arr: {
                  without_refunds: 111.77,
                  with_refunds: 92.61,
                },
                net_arr_spread: {
                  without_refunds: 5.94,
                  with_refunds: 5.94,
                },
                gross_arr_spread: {
                  without_refunds: 9.24,
                  with_refunds: 9.24,
                },
                net_total: {
                  without_refunds: 12.04,
                  with_refunds: 11.46,
                },
                gross_total: {
                  without_refunds: 15.02,
                  with_refunds: 14.26,
                },
                net_total_spread: {
                  without_refunds: 10.46,
                  with_refunds: 10.1,
                },
                gross_total_spread: {
                  without_refunds: 12.79,
                  with_refunds: 12.35,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 14.34,
                    with_refunds: 13.93,
                  },
                  gross_mrr: {
                    without_refunds: 18.09,
                    with_refunds: 17.58,
                  },
                  net_arr: {
                    without_refunds: 60.34,
                    with_refunds: 55.84,
                  },
                  gross_arr: {
                    without_refunds: 94.08,
                    with_refunds: 86.82,
                  },
                  net_arr_spread: {
                    without_refunds: 8.98,
                    with_refunds: 8.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.97,
                    with_refunds: 13.97,
                  },
                  net_total: {
                    without_refunds: 17.86,
                    with_refunds: 17.14,
                  },
                  gross_total: {
                    without_refunds: 23.85,
                    with_refunds: 22.84,
                  },
                  net_total_spread: {
                    without_refunds: 14.1,
                    with_refunds: 13.71,
                  },
                  gross_total_spread: {
                    without_refunds: 17.96,
                    with_refunds: 17.49,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.99,
                    with_refunds: 20.34,
                  },
                  gross_mrr: {
                    without_refunds: 26.61,
                    with_refunds: 25.81,
                  },
                  net_arr: {
                    without_refunds: 143.57,
                    with_refunds: 126.93,
                  },
                  gross_arr: {
                    without_refunds: 223.13,
                    with_refunds: 196.82,
                  },
                  net_arr_spread: {
                    without_refunds: 11.85,
                    with_refunds: 11.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 18.45,
                    with_refunds: 18.45,
                  },
                  net_total: {
                    without_refunds: 26.92,
                    with_refunds: 25.77,
                  },
                  gross_total: {
                    without_refunds: 36.11,
                    with_refunds: 34.5,
                  },
                  net_total_spread: {
                    without_refunds: 20.32,
                    with_refunds: 19.71,
                  },
                  gross_total_spread: {
                    without_refunds: 26.01,
                    with_refunds: 25.27,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3262,
                    with_refunds: 0.3169,
                  },
                  gross_mrr: {
                    without_refunds: 0.4116,
                    with_refunds: 0.4001,
                  },
                  net_arr: {
                    without_refunds: 1.3729,
                    with_refunds: 1.2706,
                  },
                  gross_arr: {
                    without_refunds: 2.1406,
                    with_refunds: 1.9754,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2043,
                    with_refunds: 0.2043,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3179,
                    with_refunds: 0.3179,
                  },
                  net_total: {
                    without_refunds: 0.4063,
                    with_refunds: 0.39,
                  },
                  gross_total: {
                    without_refunds: 0.5427,
                    with_refunds: 0.5198,
                  },
                  net_total_spread: {
                    without_refunds: 0.3207,
                    with_refunds: 0.3119,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4087,
                    with_refunds: 0.3979,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4777,
                    with_refunds: 0.4627,
                  },
                  gross_mrr: {
                    without_refunds: 0.6055,
                    with_refunds: 0.5872,
                  },
                  net_arr: {
                    without_refunds: 3.2667,
                    with_refunds: 2.888,
                  },
                  gross_arr: {
                    without_refunds: 5.077,
                    with_refunds: 4.4784,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2697,
                    with_refunds: 0.2697,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4197,
                    with_refunds: 0.4197,
                  },
                  net_total: {
                    without_refunds: 0.6125,
                    with_refunds: 0.5863,
                  },
                  gross_total: {
                    without_refunds: 0.8216,
                    with_refunds: 0.7849,
                  },
                  net_total_spread: {
                    without_refunds: 0.4623,
                    with_refunds: 0.4485,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5918,
                    with_refunds: 0.5749,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1906,
                  with_refunds: 1912,
                },
                arr_users: {
                  without_refunds: 7,
                  with_refunds: 7,
                },
                arr_spread_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                total_users: {
                  without_refunds: 1913,
                  with_refunds: 1917,
                },
                total_spread_users: {
                  without_refunds: 2118,
                  with_refunds: 2122,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5696,
                  with_refunds: 0.5714,
                },
                arr_users: {
                  without_refunds: 0.0266,
                  with_refunds: 0.0266,
                },
                arr_spread_users: {
                  without_refunds: 0.8797,
                  with_refunds: 0.8797,
                },
                total_users: {
                  without_refunds: 0.5351,
                  with_refunds: 0.5362,
                },
                total_spread_users: {
                  without_refunds: 0.5954,
                  with_refunds: 0.5966,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7612,
                  with_refunds: 0.7593,
                },
                arr_users: {
                  without_refunds: 0.14,
                  with_refunds: 0.1321,
                },
                arr_spread_users: {
                  without_refunds: 1.0291,
                  with_refunds: 1.0291,
                },
                total_users: {
                  without_refunds: 0.749,
                  with_refunds: 0.7456,
                },
                total_spread_users: {
                  without_refunds: 0.7815,
                  with_refunds: 0.779,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 21080.75,
                  with_refunds: 20742.84,
                },
                gross_mrr: {
                  without_refunds: 24389.1,
                  with_refunds: 23986.21,
                },
                net_arr: {
                  without_refunds: 536.86,
                  with_refunds: 536.86,
                },
                gross_arr: {
                  without_refunds: 740.22,
                  with_refunds: 740.22,
                },
                net_arr_spread: {
                  without_refunds: 1262.7,
                  with_refunds: 1262.7,
                },
                gross_arr_spread: {
                  without_refunds: 1954.33,
                  with_refunds: 1954.33,
                },
                net_total: {
                  without_refunds: 21617.61,
                  with_refunds: 21279.69,
                },
                gross_total: {
                  without_refunds: 25129.31,
                  with_refunds: 24726.42,
                },
                net_total_spread: {
                  without_refunds: 22343.45,
                  with_refunds: 22005.53,
                },
                gross_total_spread: {
                  without_refunds: 26343.42,
                  with_refunds: 25940.53,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.011,
                  with_refunds: 1.0211,
                },
                gross_mrr: {
                  without_refunds: 0.8788,
                  with_refunds: 0.8855,
                },
                net_arr: {
                  without_refunds: 0.0438,
                  with_refunds: 0.0467,
                },
                gross_arr: {
                  without_refunds: 0.0386,
                  with_refunds: 0.0413,
                },
                net_arr_spread: {
                  without_refunds: 1.3427,
                  with_refunds: 1.3427,
                },
                gross_arr_spread: {
                  without_refunds: 1.3343,
                  with_refunds: 1.3343,
                },
                net_total: {
                  without_refunds: 0.653,
                  with_refunds: 0.6688,
                },
                gross_total: {
                  without_refunds: 0.5357,
                  with_refunds: 0.5493,
                },
                net_total_spread: {
                  without_refunds: 1.0253,
                  with_refunds: 1.0354,
                },
                gross_total_spread: {
                  without_refunds: 0.9016,
                  with_refunds: 0.9085,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7772,
                  with_refunds: 0.7892,
                },
                gross_mrr: {
                  without_refunds: 0.7443,
                  with_refunds: 0.7555,
                },
                net_arr: {
                  without_refunds: 0.1484,
                  with_refunds: 0.1687,
                },
                gross_arr: {
                  without_refunds: 0.1325,
                  with_refunds: 0.1508,
                },
                net_arr_spread: {
                  without_refunds: 1.0319,
                  with_refunds: 1.0319,
                },
                gross_arr_spread: {
                  without_refunds: 1.0272,
                  with_refunds: 1.0272,
                },
                net_total: {
                  without_refunds: 0.7032,
                  with_refunds: 0.7222,
                },
                gross_total: {
                  without_refunds: 0.6551,
                  with_refunds: 0.6746,
                },
                net_total_spread: {
                  without_refunds: 0.7882,
                  with_refunds: 0.8,
                },
                gross_total_spread: {
                  without_refunds: 0.7598,
                  with_refunds: 0.7709,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.3,
                  with_refunds: 6.2,
                },
                gross_mrr: {
                  without_refunds: 7.29,
                  with_refunds: 7.17,
                },
                net_arr: {
                  without_refunds: 2.04,
                  with_refunds: 2.04,
                },
                gross_arr: {
                  without_refunds: 2.81,
                  with_refunds: 2.81,
                },
                net_arr_spread: {
                  without_refunds: 5.24,
                  with_refunds: 5.24,
                },
                gross_arr_spread: {
                  without_refunds: 8.11,
                  with_refunds: 8.11,
                },
                net_total: {
                  without_refunds: 6.05,
                  with_refunds: 5.95,
                },
                gross_total: {
                  without_refunds: 7.03,
                  with_refunds: 6.92,
                },
                net_total_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.19,
                },
                gross_total_spread: {
                  without_refunds: 7.41,
                  with_refunds: 7.29,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.06,
                  with_refunds: 10.85,
                },
                gross_mrr: {
                  without_refunds: 12.8,
                  with_refunds: 12.55,
                },
                net_arr: {
                  without_refunds: 76.69,
                  with_refunds: 76.69,
                },
                gross_arr: {
                  without_refunds: 105.75,
                  with_refunds: 105.75,
                },
                net_arr_spread: {
                  without_refunds: 5.96,
                  with_refunds: 5.96,
                },
                gross_arr_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.22,
                },
                net_total: {
                  without_refunds: 11.3,
                  with_refunds: 11.1,
                },
                gross_total: {
                  without_refunds: 13.14,
                  with_refunds: 12.9,
                },
                net_total_spread: {
                  without_refunds: 10.55,
                  with_refunds: 10.37,
                },
                gross_total_spread: {
                  without_refunds: 12.44,
                  with_refunds: 12.22,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.64,
                    with_refunds: 20.13,
                  },
                  gross_mrr: {
                    without_refunds: 25.38,
                    with_refunds: 24.75,
                  },
                  net_arr: {
                    without_refunds: 62.38,
                    with_refunds: 57.88,
                  },
                  gross_arr: {
                    without_refunds: 96.9,
                    with_refunds: 89.64,
                  },
                  net_arr_spread: {
                    without_refunds: 14.22,
                    with_refunds: 14.22,
                  },
                  gross_arr_spread: {
                    without_refunds: 22.08,
                    with_refunds: 22.08,
                  },
                  net_total: {
                    without_refunds: 23.91,
                    with_refunds: 23.09,
                  },
                  gross_total: {
                    without_refunds: 30.88,
                    with_refunds: 29.76,
                  },
                  net_total_spread: {
                    without_refunds: 20.38,
                    with_refunds: 19.89,
                  },
                  gross_total_spread: {
                    without_refunds: 25.37,
                    with_refunds: 24.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.05,
                    with_refunds: 31.19,
                  },
                  gross_mrr: {
                    without_refunds: 39.41,
                    with_refunds: 38.35,
                  },
                  net_arr: {
                    without_refunds: 220.27,
                    with_refunds: 203.62,
                  },
                  gross_arr: {
                    without_refunds: 328.88,
                    with_refunds: 302.57,
                  },
                  net_arr_spread: {
                    without_refunds: 17.81,
                    with_refunds: 17.81,
                  },
                  gross_arr_spread: {
                    without_refunds: 27.67,
                    with_refunds: 27.67,
                  },
                  net_total: {
                    without_refunds: 38.22,
                    with_refunds: 36.87,
                  },
                  gross_total: {
                    without_refunds: 49.25,
                    with_refunds: 47.4,
                  },
                  net_total_spread: {
                    without_refunds: 30.87,
                    with_refunds: 30.08,
                  },
                  gross_total_spread: {
                    without_refunds: 38.45,
                    with_refunds: 37.49,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4696,
                    with_refunds: 0.4579,
                  },
                  gross_mrr: {
                    without_refunds: 0.5774,
                    with_refunds: 0.5632,
                  },
                  net_arr: {
                    without_refunds: 1.4193,
                    with_refunds: 1.317,
                  },
                  gross_arr: {
                    without_refunds: 2.2047,
                    with_refunds: 2.0395,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3235,
                    with_refunds: 0.3235,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5024,
                    with_refunds: 0.5024,
                  },
                  net_total: {
                    without_refunds: 0.5439,
                    with_refunds: 0.5255,
                  },
                  gross_total: {
                    without_refunds: 0.7026,
                    with_refunds: 0.6772,
                  },
                  net_total_spread: {
                    without_refunds: 0.4636,
                    with_refunds: 0.4527,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5772,
                    with_refunds: 0.5638,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7293,
                    with_refunds: 0.7096,
                  },
                  gross_mrr: {
                    without_refunds: 0.8967,
                    with_refunds: 0.8727,
                  },
                  net_arr: {
                    without_refunds: 5.0117,
                    with_refunds: 4.633,
                  },
                  gross_arr: {
                    without_refunds: 7.483,
                    with_refunds: 6.8844,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4053,
                    with_refunds: 0.4053,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6295,
                    with_refunds: 0.6295,
                  },
                  net_total: {
                    without_refunds: 0.8696,
                    with_refunds: 0.8388,
                  },
                  gross_total: {
                    without_refunds: 1.1205,
                    with_refunds: 1.0784,
                  },
                  net_total_spread: {
                    without_refunds: 0.7023,
                    with_refunds: 0.6844,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8748,
                    with_refunds: 0.853,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1540,
                  with_refunds: 1545,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 7,
                },
                arr_spread_users: {
                  without_refunds: 218,
                  with_refunds: 218,
                },
                total_users: {
                  without_refunds: 1545,
                  with_refunds: 1551,
                },
                total_spread_users: {
                  without_refunds: 1757,
                  with_refunds: 1762,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4603,
                  with_refunds: 0.4617,
                },
                arr_users: {
                  without_refunds: 0.0228,
                  with_refunds: 0.0266,
                },
                arr_spread_users: {
                  without_refunds: 0.9046,
                  with_refunds: 0.9046,
                },
                total_users: {
                  without_refunds: 0.4322,
                  with_refunds: 0.4338,
                },
                total_spread_users: {
                  without_refunds: 0.494,
                  with_refunds: 0.4954,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.808,
                  with_refunds: 0.8081,
                },
                arr_users: {
                  without_refunds: 0.8571,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1.0283,
                  with_refunds: 1.0283,
                },
                total_users: {
                  without_refunds: 0.8076,
                  with_refunds: 0.8091,
                },
                total_spread_users: {
                  without_refunds: 0.8296,
                  with_refunds: 0.8303,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 17455.67,
                  with_refunds: 17063.71,
                },
                gross_mrr: {
                  without_refunds: 19708.23,
                  with_refunds: 19276.34,
                },
                net_arr: {
                  without_refunds: 507.37,
                  with_refunds: 438.44,
                },
                gross_arr: {
                  without_refunds: 658.61,
                  with_refunds: 539.61,
                },
                net_arr_spread: {
                  without_refunds: 1304.98,
                  with_refunds: 1304.98,
                },
                gross_arr_spread: {
                  without_refunds: 2009.21,
                  with_refunds: 2009.21,
                },
                net_total: {
                  without_refunds: 17963.03,
                  with_refunds: 17502.15,
                },
                gross_total: {
                  without_refunds: 20366.84,
                  with_refunds: 19815.95,
                },
                net_total_spread: {
                  without_refunds: 18760.65,
                  with_refunds: 18368.69,
                },
                gross_total_spread: {
                  without_refunds: 21717.44,
                  with_refunds: 21285.55,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8372,
                  with_refunds: 0.84,
                },
                gross_mrr: {
                  without_refunds: 0.7101,
                  with_refunds: 0.7116,
                },
                net_arr: {
                  without_refunds: 0.0414,
                  with_refunds: 0.0381,
                },
                gross_arr: {
                  without_refunds: 0.0344,
                  with_refunds: 0.0301,
                },
                net_arr_spread: {
                  without_refunds: 1.3877,
                  with_refunds: 1.3877,
                },
                gross_arr_spread: {
                  without_refunds: 1.3718,
                  with_refunds: 1.3718,
                },
                net_total: {
                  without_refunds: 0.5426,
                  with_refunds: 0.5501,
                },
                gross_total: {
                  without_refunds: 0.4342,
                  with_refunds: 0.4402,
                },
                net_total_spread: {
                  without_refunds: 0.8609,
                  with_refunds: 0.8643,
                },
                gross_total_spread: {
                  without_refunds: 0.7433,
                  with_refunds: 0.7455,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.828,
                  with_refunds: 0.8226,
                },
                gross_mrr: {
                  without_refunds: 0.8081,
                  with_refunds: 0.8036,
                },
                net_arr: {
                  without_refunds: 0.9451,
                  with_refunds: 0.8167,
                },
                gross_arr: {
                  without_refunds: 0.8898,
                  with_refunds: 0.729,
                },
                net_arr_spread: {
                  without_refunds: 1.0335,
                  with_refunds: 1.0335,
                },
                gross_arr_spread: {
                  without_refunds: 1.0281,
                  with_refunds: 1.0281,
                },
                net_total: {
                  without_refunds: 0.8309,
                  with_refunds: 0.8225,
                },
                gross_total: {
                  without_refunds: 0.8105,
                  with_refunds: 0.8014,
                },
                net_total_spread: {
                  without_refunds: 0.8396,
                  with_refunds: 0.8347,
                },
                gross_total_spread: {
                  without_refunds: 0.8244,
                  with_refunds: 0.8206,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.22,
                  with_refunds: 5.1,
                },
                gross_mrr: {
                  without_refunds: 5.89,
                  with_refunds: 5.76,
                },
                net_arr: {
                  without_refunds: 1.93,
                  with_refunds: 1.67,
                },
                gross_arr: {
                  without_refunds: 2.5,
                  with_refunds: 2.05,
                },
                net_arr_spread: {
                  without_refunds: 5.41,
                  with_refunds: 5.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.34,
                },
                net_total: {
                  without_refunds: 5.02,
                  with_refunds: 4.9,
                },
                gross_total: {
                  without_refunds: 5.7,
                  with_refunds: 5.54,
                },
                net_total_spread: {
                  without_refunds: 5.27,
                  with_refunds: 5.16,
                },
                gross_total_spread: {
                  without_refunds: 6.11,
                  with_refunds: 5.98,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.33,
                  with_refunds: 11.04,
                },
                gross_mrr: {
                  without_refunds: 12.8,
                  with_refunds: 12.48,
                },
                net_arr: {
                  without_refunds: 84.56,
                  with_refunds: 62.63,
                },
                gross_arr: {
                  without_refunds: 109.77,
                  with_refunds: 77.09,
                },
                net_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                gross_arr_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.22,
                },
                net_total: {
                  without_refunds: 11.63,
                  with_refunds: 11.28,
                },
                gross_total: {
                  without_refunds: 13.18,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.68,
                  with_refunds: 10.42,
                },
                gross_total_spread: {
                  without_refunds: 12.36,
                  with_refunds: 12.08,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.85,
                    with_refunds: 25.22,
                  },
                  gross_mrr: {
                    without_refunds: 31.27,
                    with_refunds: 30.51,
                  },
                  net_arr: {
                    without_refunds: 64.31,
                    with_refunds: 59.55,
                  },
                  gross_arr: {
                    without_refunds: 99.4,
                    with_refunds: 91.69,
                  },
                  net_arr_spread: {
                    without_refunds: 19.63,
                    with_refunds: 19.63,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.42,
                    with_refunds: 30.42,
                  },
                  net_total: {
                    without_refunds: 28.93,
                    with_refunds: 27.99,
                  },
                  gross_total: {
                    without_refunds: 36.58,
                    with_refunds: 35.3,
                  },
                  net_total_spread: {
                    without_refunds: 25.65,
                    with_refunds: 25.06,
                  },
                  gross_total_spread: {
                    without_refunds: 31.47,
                    with_refunds: 30.76,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 43.39,
                    with_refunds: 42.23,
                  },
                  gross_mrr: {
                    without_refunds: 52.21,
                    with_refunds: 50.83,
                  },
                  net_arr: {
                    without_refunds: 304.83,
                    with_refunds: 266.26,
                  },
                  gross_arr: {
                    without_refunds: 438.65,
                    with_refunds: 379.66,
                  },
                  net_arr_spread: {
                    without_refunds: 23.8,
                    with_refunds: 23.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.88,
                    with_refunds: 36.88,
                  },
                  net_total: {
                    without_refunds: 49.85,
                    with_refunds: 48.15,
                  },
                  gross_total: {
                    without_refunds: 62.43,
                    with_refunds: 60.17,
                  },
                  net_total_spread: {
                    without_refunds: 41.54,
                    with_refunds: 40.51,
                  },
                  gross_total_spread: {
                    without_refunds: 50.81,
                    with_refunds: 49.57,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5883,
                    with_refunds: 0.5739,
                  },
                  gross_mrr: {
                    without_refunds: 0.7114,
                    with_refunds: 0.6943,
                  },
                  net_arr: {
                    without_refunds: 1.4632,
                    with_refunds: 1.355,
                  },
                  gross_arr: {
                    without_refunds: 2.2616,
                    with_refunds: 2.0862,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4467,
                    with_refunds: 0.4467,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6921,
                    with_refunds: 0.6921,
                  },
                  net_total: {
                    without_refunds: 0.6582,
                    with_refunds: 0.6369,
                  },
                  gross_total: {
                    without_refunds: 0.8322,
                    with_refunds: 0.8033,
                  },
                  net_total_spread: {
                    without_refunds: 0.5837,
                    with_refunds: 0.5702,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7161,
                    with_refunds: 0.7,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9872,
                    with_refunds: 0.9609,
                  },
                  gross_mrr: {
                    without_refunds: 1.1878,
                    with_refunds: 1.1566,
                  },
                  net_arr: {
                    without_refunds: 6.9357,
                    with_refunds: 6.0582,
                  },
                  gross_arr: {
                    without_refunds: 9.9806,
                    with_refunds: 8.6384,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5415,
                    with_refunds: 0.5415,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8392,
                    with_refunds: 0.8392,
                  },
                  net_total: {
                    without_refunds: 1.1342,
                    with_refunds: 1.0956,
                  },
                  gross_total: {
                    without_refunds: 1.4205,
                    with_refunds: 1.3691,
                  },
                  net_total_spread: {
                    without_refunds: 0.9452,
                    with_refunds: 0.9216,
                  },
                  gross_total_spread: {
                    without_refunds: 1.156,
                    with_refunds: 1.1279,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1300,
                  with_refunds: 1302,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 218,
                  with_refunds: 218,
                },
                total_users: {
                  without_refunds: 1301,
                  with_refunds: 1303,
                },
                total_spread_users: {
                  without_refunds: 1518,
                  with_refunds: 1520,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3885,
                  with_refunds: 0.3891,
                },
                arr_users: {
                  without_refunds: 0.0038,
                  with_refunds: 0.0038,
                },
                arr_spread_users: {
                  without_refunds: 0.9046,
                  with_refunds: 0.9046,
                },
                total_users: {
                  without_refunds: 0.3639,
                  with_refunds: 0.3645,
                },
                total_spread_users: {
                  without_refunds: 0.4268,
                  with_refunds: 0.4273,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8442,
                  with_refunds: 0.8427,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1429,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8421,
                  with_refunds: 0.8401,
                },
                total_spread_users: {
                  without_refunds: 0.864,
                  with_refunds: 0.8627,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 14845.18,
                  with_refunds: 14564.22,
                },
                gross_mrr: {
                  without_refunds: 16716.65,
                  with_refunds: 16410.69,
                },
                net_arr: {
                  without_refunds: 84.99,
                },
                gross_arr: {
                  without_refunds: 99.99,
                },
                net_arr_spread: {
                  without_refunds: 1304.98,
                  with_refunds: 1304.98,
                },
                gross_arr_spread: {
                  without_refunds: 2009.21,
                  with_refunds: 2009.21,
                },
                net_total: {
                  without_refunds: 14930.17,
                  with_refunds: 14564.22,
                },
                gross_total: {
                  without_refunds: 16816.64,
                  with_refunds: 16410.69,
                },
                net_total_spread: {
                  without_refunds: 16150.16,
                  with_refunds: 15869.2,
                },
                gross_total_spread: {
                  without_refunds: 18725.86,
                  with_refunds: 18419.9,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.712,
                  with_refunds: 0.717,
                },
                gross_mrr: {
                  without_refunds: 0.6023,
                  with_refunds: 0.6058,
                },
                net_arr: {
                  without_refunds: 0.0069,
                },
                gross_arr: {
                  without_refunds: 0.0052,
                },
                net_arr_spread: {
                  without_refunds: 1.3877,
                  with_refunds: 1.3877,
                },
                gross_arr_spread: {
                  without_refunds: 1.3718,
                  with_refunds: 1.3718,
                },
                net_total: {
                  without_refunds: 0.451,
                  with_refunds: 0.4577,
                },
                gross_total: {
                  without_refunds: 0.3585,
                  with_refunds: 0.3646,
                },
                net_total_spread: {
                  without_refunds: 0.7411,
                  with_refunds: 0.7467,
                },
                gross_total_spread: {
                  without_refunds: 0.6409,
                  with_refunds: 0.6451,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8505,
                  with_refunds: 0.8535,
                },
                gross_mrr: {
                  without_refunds: 0.8482,
                  with_refunds: 0.8513,
                },
                net_arr: {
                  without_refunds: 0.1675,
                },
                gross_arr: {
                  without_refunds: 0.1518,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8312,
                  with_refunds: 0.8321,
                },
                gross_total: {
                  without_refunds: 0.8257,
                  with_refunds: 0.8282,
                },
                net_total_spread: {
                  without_refunds: 0.8609,
                  with_refunds: 0.8639,
                },
                gross_total_spread: {
                  without_refunds: 0.8622,
                  with_refunds: 0.8654,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.44,
                  with_refunds: 4.35,
                },
                gross_mrr: {
                  without_refunds: 5,
                  with_refunds: 4.9,
                },
                net_arr: {
                  without_refunds: 0.32,
                },
                gross_arr: {
                  without_refunds: 0.38,
                },
                net_arr_spread: {
                  without_refunds: 5.41,
                  with_refunds: 5.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.34,
                },
                net_total: {
                  without_refunds: 4.18,
                  with_refunds: 4.07,
                },
                gross_total: {
                  without_refunds: 4.7,
                  with_refunds: 4.59,
                },
                net_total_spread: {
                  without_refunds: 4.54,
                  with_refunds: 4.46,
                },
                gross_total_spread: {
                  without_refunds: 5.26,
                  with_refunds: 5.18,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.19,
                },
                gross_mrr: {
                  without_refunds: 12.86,
                  with_refunds: 12.6,
                },
                net_arr: {
                  without_refunds: 84.99,
                },
                gross_arr: {
                  without_refunds: 99.99,
                },
                net_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                gross_arr_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.22,
                },
                net_total: {
                  without_refunds: 11.48,
                  with_refunds: 11.18,
                },
                gross_total: {
                  without_refunds: 12.93,
                  with_refunds: 12.59,
                },
                net_total_spread: {
                  without_refunds: 10.64,
                  with_refunds: 10.44,
                },
                gross_total_spread: {
                  without_refunds: 12.34,
                  with_refunds: 12.12,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.29,
                    with_refunds: 29.58,
                  },
                  gross_mrr: {
                    without_refunds: 36.26,
                    with_refunds: 35.42,
                  },
                  net_arr: {
                    without_refunds: 64.63,
                    with_refunds: 59.55,
                  },
                  gross_arr: {
                    without_refunds: 99.78,
                    with_refunds: 91.69,
                  },
                  net_arr_spread: {
                    without_refunds: 25.05,
                    with_refunds: 25.05,
                  },
                  gross_arr_spread: {
                    without_refunds: 38.76,
                    with_refunds: 38.76,
                  },
                  net_total: {
                    without_refunds: 33.11,
                    with_refunds: 32.06,
                  },
                  gross_total: {
                    without_refunds: 41.28,
                    with_refunds: 39.89,
                  },
                  net_total_spread: {
                    without_refunds: 30.19,
                    with_refunds: 29.52,
                  },
                  gross_total_spread: {
                    without_refunds: 36.74,
                    with_refunds: 35.94,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 54.81,
                    with_refunds: 53.42,
                  },
                  gross_mrr: {
                    without_refunds: 65.06,
                    with_refunds: 63.43,
                  },
                  net_arr: {
                    without_refunds: 389.82,
                    with_refunds: 266.26,
                  },
                  gross_arr: {
                    without_refunds: 538.64,
                    with_refunds: 379.66,
                  },
                  net_arr_spread: {
                    without_refunds: 29.78,
                    with_refunds: 29.78,
                  },
                  gross_arr_spread: {
                    without_refunds: 46.1,
                    with_refunds: 46.1,
                  },
                  net_total: {
                    without_refunds: 61.32,
                    with_refunds: 59.33,
                  },
                  gross_total: {
                    without_refunds: 75.36,
                    with_refunds: 72.77,
                  },
                  net_total_spread: {
                    without_refunds: 52.18,
                    with_refunds: 50.95,
                  },
                  gross_total_spread: {
                    without_refunds: 63.14,
                    with_refunds: 61.69,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6892,
                    with_refunds: 0.673,
                  },
                  gross_mrr: {
                    without_refunds: 0.8251,
                    with_refunds: 0.8059,
                  },
                  net_arr: {
                    without_refunds: 1.4706,
                    with_refunds: 1.355,
                  },
                  gross_arr: {
                    without_refunds: 2.2703,
                    with_refunds: 2.0862,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5699,
                    with_refunds: 0.5699,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8818,
                    with_refunds: 0.8818,
                  },
                  net_total: {
                    without_refunds: 0.7533,
                    with_refunds: 0.7296,
                  },
                  gross_total: {
                    without_refunds: 0.9393,
                    with_refunds: 0.9077,
                  },
                  net_total_spread: {
                    without_refunds: 0.687,
                    with_refunds: 0.6717,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8359,
                    with_refunds: 0.8178,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.247,
                    with_refunds: 1.2154,
                  },
                  gross_mrr: {
                    without_refunds: 1.4804,
                    with_refunds: 1.4433,
                  },
                  net_arr: {
                    without_refunds: 8.8695,
                    with_refunds: 6.0582,
                  },
                  gross_arr: {
                    without_refunds: 12.2557,
                    with_refunds: 8.6384,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6777,
                    with_refunds: 0.6777,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0489,
                    with_refunds: 1.0489,
                  },
                  net_total: {
                    without_refunds: 1.3953,
                    with_refunds: 1.3499,
                  },
                  gross_total: {
                    without_refunds: 1.7146,
                    with_refunds: 1.6557,
                  },
                  net_total_spread: {
                    without_refunds: 1.1873,
                    with_refunds: 1.1592,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4367,
                    with_refunds: 1.4036,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1168,
                  with_refunds: 1171,
                },
                arr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 222,
                  with_refunds: 222,
                },
                total_users: {
                  without_refunds: 1172,
                  with_refunds: 1175,
                },
                total_spread_users: {
                  without_refunds: 1390,
                  with_refunds: 1393,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3491,
                  with_refunds: 0.35,
                },
                arr_users: {
                  without_refunds: 0.0152,
                  with_refunds: 0.0152,
                },
                arr_spread_users: {
                  without_refunds: 0.9212,
                  with_refunds: 0.9212,
                },
                total_users: {
                  without_refunds: 0.3278,
                  with_refunds: 0.3287,
                },
                total_spread_users: {
                  without_refunds: 0.3908,
                  with_refunds: 0.3916,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8985,
                  with_refunds: 0.8994,
                },
                arr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 1.0183,
                  with_refunds: 1.0183,
                },
                total_users: {
                  without_refunds: 0.9008,
                  with_refunds: 0.9018,
                },
                total_spread_users: {
                  without_refunds: 0.9157,
                  with_refunds: 0.9164,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13457.97,
                  with_refunds: 13128.03,
                },
                gross_mrr: {
                  without_refunds: 15136.06,
                  with_refunds: 14783.07,
                },
                net_arr: {
                  without_refunds: 481.1,
                  with_refunds: 481.1,
                },
                gross_arr: {
                  without_refunds: 615.8,
                  with_refunds: 615.8,
                },
                net_arr_spread: {
                  without_refunds: 1345.07,
                  with_refunds: 1345.07,
                },
                gross_arr_spread: {
                  without_refunds: 2060.53,
                  with_refunds: 2060.53,
                },
                net_total: {
                  without_refunds: 13939.08,
                  with_refunds: 13609.13,
                },
                gross_total: {
                  without_refunds: 15751.86,
                  with_refunds: 15398.87,
                },
                net_total_spread: {
                  without_refunds: 14803.04,
                  with_refunds: 14473.1,
                },
                gross_total_spread: {
                  without_refunds: 17196.59,
                  with_refunds: 16843.6,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6454,
                  with_refunds: 0.6463,
                },
                gross_mrr: {
                  without_refunds: 0.5454,
                  with_refunds: 0.5458,
                },
                net_arr: {
                  without_refunds: 0.0393,
                  with_refunds: 0.0418,
                },
                gross_arr: {
                  without_refunds: 0.0321,
                  with_refunds: 0.0344,
                },
                net_arr_spread: {
                  without_refunds: 1.4303,
                  with_refunds: 1.4303,
                },
                gross_arr_spread: {
                  without_refunds: 1.4068,
                  with_refunds: 1.4068,
                },
                net_total: {
                  without_refunds: 0.4211,
                  with_refunds: 0.4277,
                },
                gross_total: {
                  without_refunds: 0.3358,
                  with_refunds: 0.3421,
                },
                net_total_spread: {
                  without_refunds: 0.6793,
                  with_refunds: 0.681,
                },
                gross_total_spread: {
                  without_refunds: 0.5886,
                  with_refunds: 0.5899,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9066,
                  with_refunds: 0.9014,
                },
                gross_mrr: {
                  without_refunds: 0.9054,
                  with_refunds: 0.9008,
                },
                net_arr: {
                  without_refunds: 5.6607,
                  with_refunds: 1.0973,
                },
                gross_arr: {
                  without_refunds: 6.1586,
                  with_refunds: 1.1412,
                },
                net_arr_spread: {
                  without_refunds: 1.0307,
                  with_refunds: 1.0307,
                },
                gross_arr_spread: {
                  without_refunds: 1.0255,
                  with_refunds: 1.0255,
                },
                net_total: {
                  without_refunds: 0.9336,
                  with_refunds: 0.9344,
                },
                gross_total: {
                  without_refunds: 0.9367,
                  with_refunds: 0.9383,
                },
                net_total_spread: {
                  without_refunds: 0.9166,
                  with_refunds: 0.912,
                },
                gross_total_spread: {
                  without_refunds: 0.9183,
                  with_refunds: 0.9144,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.02,
                  with_refunds: 3.92,
                },
                gross_mrr: {
                  without_refunds: 4.52,
                  with_refunds: 4.42,
                },
                net_arr: {
                  without_refunds: 1.83,
                  with_refunds: 1.83,
                },
                gross_arr: {
                  without_refunds: 2.34,
                  with_refunds: 2.34,
                },
                net_arr_spread: {
                  without_refunds: 5.58,
                  with_refunds: 5.58,
                },
                gross_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                net_total: {
                  without_refunds: 3.9,
                  with_refunds: 3.81,
                },
                gross_total: {
                  without_refunds: 4.41,
                  with_refunds: 4.31,
                },
                net_total_spread: {
                  without_refunds: 4.16,
                  with_refunds: 4.07,
                },
                gross_total_spread: {
                  without_refunds: 4.83,
                  with_refunds: 4.74,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.52,
                  with_refunds: 11.21,
                },
                gross_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.62,
                },
                net_arr: {
                  without_refunds: 120.28,
                  with_refunds: 120.28,
                },
                gross_arr: {
                  without_refunds: 153.95,
                  with_refunds: 153.95,
                },
                net_arr_spread: {
                  without_refunds: 6.06,
                  with_refunds: 6.06,
                },
                gross_arr_spread: {
                  without_refunds: 9.28,
                  with_refunds: 9.28,
                },
                net_total: {
                  without_refunds: 11.89,
                  with_refunds: 11.58,
                },
                gross_total: {
                  without_refunds: 13.44,
                  with_refunds: 13.11,
                },
                net_total_spread: {
                  without_refunds: 10.65,
                  with_refunds: 10.39,
                },
                gross_total_spread: {
                  without_refunds: 12.37,
                  with_refunds: 12.09,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.31,
                    with_refunds: 33.5,
                  },
                  gross_mrr: {
                    without_refunds: 40.79,
                    with_refunds: 39.84,
                  },
                  net_arr: {
                    without_refunds: 66.46,
                    with_refunds: 61.38,
                  },
                  gross_arr: {
                    without_refunds: 102.12,
                    with_refunds: 94.03,
                  },
                  net_arr_spread: {
                    without_refunds: 30.63,
                    with_refunds: 30.63,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.31,
                    with_refunds: 47.31,
                  },
                  net_total: {
                    without_refunds: 37.01,
                    with_refunds: 35.87,
                  },
                  gross_total: {
                    without_refunds: 45.69,
                    with_refunds: 44.2,
                  },
                  net_total_spread: {
                    without_refunds: 34.35,
                    with_refunds: 33.59,
                  },
                  gross_total_spread: {
                    without_refunds: 41.57,
                    with_refunds: 40.68,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 66.33,
                    with_refunds: 64.63,
                  },
                  gross_mrr: {
                    without_refunds: 78.02,
                    with_refunds: 76.06,
                  },
                  net_arr: {
                    without_refunds: 510.09,
                    with_refunds: 386.53,
                  },
                  gross_arr: {
                    without_refunds: 692.59,
                    with_refunds: 533.61,
                  },
                  net_arr_spread: {
                    without_refunds: 35.84,
                    with_refunds: 35.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 55.38,
                    with_refunds: 55.38,
                  },
                  net_total: {
                    without_refunds: 73.22,
                    with_refunds: 70.91,
                  },
                  gross_total: {
                    without_refunds: 88.8,
                    with_refunds: 85.87,
                  },
                  net_total_spread: {
                    without_refunds: 62.83,
                    with_refunds: 61.34,
                  },
                  gross_total_spread: {
                    without_refunds: 75.51,
                    with_refunds: 73.78,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7807,
                    with_refunds: 0.7623,
                  },
                  gross_mrr: {
                    without_refunds: 0.928,
                    with_refunds: 0.9064,
                  },
                  net_arr: {
                    without_refunds: 1.5122,
                    with_refunds: 1.3966,
                  },
                  gross_arr: {
                    without_refunds: 2.3236,
                    with_refunds: 2.1394,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6969,
                    with_refunds: 0.6969,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0763,
                    with_refunds: 1.0763,
                  },
                  net_total: {
                    without_refunds: 0.842,
                    with_refunds: 0.8162,
                  },
                  gross_total: {
                    without_refunds: 1.0395,
                    with_refunds: 1.0057,
                  },
                  net_total_spread: {
                    without_refunds: 0.7817,
                    with_refunds: 0.7643,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9459,
                    with_refunds: 0.9256,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.5092,
                    with_refunds: 1.4705,
                  },
                  gross_mrr: {
                    without_refunds: 1.7753,
                    with_refunds: 1.7306,
                  },
                  net_arr: {
                    without_refunds: 11.6062,
                    with_refunds: 8.7948,
                  },
                  gross_arr: {
                    without_refunds: 15.7585,
                    with_refunds: 12.1412,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8155,
                    with_refunds: 0.8155,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2601,
                    with_refunds: 1.2601,
                  },
                  net_total: {
                    without_refunds: 1.6659,
                    with_refunds: 1.6135,
                  },
                  gross_total: {
                    without_refunds: 2.0204,
                    with_refunds: 1.9539,
                  },
                  net_total_spread: {
                    without_refunds: 1.4296,
                    with_refunds: 1.3956,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7182,
                    with_refunds: 1.6788,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 922,
                  with_refunds: 924,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 226,
                  with_refunds: 226,
                },
                total_users: {
                  without_refunds: 927,
                  with_refunds: 929,
                },
                total_spread_users: {
                  without_refunds: 1148,
                  with_refunds: 1150,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2756,
                  with_refunds: 0.2762,
                },
                arr_users: {
                  without_refunds: 0.0228,
                  with_refunds: 0.0228,
                },
                arr_spread_users: {
                  without_refunds: 0.9378,
                  with_refunds: 0.9378,
                },
                total_users: {
                  without_refunds: 0.2593,
                  with_refunds: 0.2599,
                },
                total_spread_users: {
                  without_refunds: 0.3227,
                  with_refunds: 0.3233,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7894,
                  with_refunds: 0.7891,
                },
                arr_users: {
                  without_refunds: 1.5,
                  with_refunds: 1.5,
                },
                arr_spread_users: {
                  without_refunds: 1.018,
                  with_refunds: 1.018,
                },
                total_users: {
                  without_refunds: 0.791,
                  with_refunds: 0.7906,
                },
                total_spread_users: {
                  without_refunds: 0.8259,
                  with_refunds: 0.8256,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10532.13,
                  with_refunds: 10126.7,
                },
                gross_mrr: {
                  without_refunds: 11688.4,
                  with_refunds: 11253.95,
                },
                net_arr: {
                  without_refunds: 709.08,
                  with_refunds: 473.1,
                },
                gross_arr: {
                  without_refunds: 924.42,
                  with_refunds: 619.53,
                },
                net_arr_spread: {
                  without_refunds: 1384.5,
                  with_refunds: 1384.5,
                },
                gross_arr_spread: {
                  without_refunds: 2112.15,
                  with_refunds: 2112.15,
                },
                net_total: {
                  without_refunds: 11241.21,
                  with_refunds: 10599.79,
                },
                gross_total: {
                  without_refunds: 12612.82,
                  with_refunds: 11873.48,
                },
                net_total_spread: {
                  without_refunds: 11916.62,
                  with_refunds: 11511.19,
                },
                gross_total_spread: {
                  without_refunds: 13800.56,
                  with_refunds: 13366.11,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5051,
                  with_refunds: 0.4985,
                },
                gross_mrr: {
                  without_refunds: 0.4212,
                  with_refunds: 0.4155,
                },
                net_arr: {
                  without_refunds: 0.0579,
                  with_refunds: 0.0411,
                },
                gross_arr: {
                  without_refunds: 0.0483,
                  with_refunds: 0.0346,
                },
                net_arr_spread: {
                  without_refunds: 1.4722,
                  with_refunds: 1.4722,
                },
                gross_arr_spread: {
                  without_refunds: 1.4421,
                  with_refunds: 1.4421,
                },
                net_total: {
                  without_refunds: 0.3396,
                  with_refunds: 0.3331,
                },
                gross_total: {
                  without_refunds: 0.2689,
                  with_refunds: 0.2638,
                },
                net_total_spread: {
                  without_refunds: 0.5468,
                  with_refunds: 0.5416,
                },
                gross_total_spread: {
                  without_refunds: 0.4723,
                  with_refunds: 0.4681,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7826,
                  with_refunds: 0.7714,
                },
                gross_mrr: {
                  without_refunds: 0.7722,
                  with_refunds: 0.7613,
                },
                net_arr: {
                  without_refunds: 1.4739,
                  with_refunds: 0.9834,
                },
                gross_arr: {
                  without_refunds: 1.5012,
                  with_refunds: 1.0061,
                },
                net_arr_spread: {
                  without_refunds: 1.0293,
                  with_refunds: 1.0293,
                },
                gross_arr_spread: {
                  without_refunds: 1.0251,
                  with_refunds: 1.0251,
                },
                net_total: {
                  without_refunds: 0.8065,
                  with_refunds: 0.7789,
                },
                gross_total: {
                  without_refunds: 0.8007,
                  with_refunds: 0.7711,
                },
                net_total_spread: {
                  without_refunds: 0.805,
                  with_refunds: 0.7954,
                },
                gross_total_spread: {
                  without_refunds: 0.8025,
                  with_refunds: 0.7935,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.15,
                  with_refunds: 3.03,
                },
                gross_mrr: {
                  without_refunds: 3.49,
                  with_refunds: 3.36,
                },
                net_arr: {
                  without_refunds: 2.7,
                  with_refunds: 1.8,
                },
                gross_arr: {
                  without_refunds: 3.51,
                  with_refunds: 2.36,
                },
                net_arr_spread: {
                  without_refunds: 5.74,
                  with_refunds: 5.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.76,
                  with_refunds: 8.76,
                },
                net_total: {
                  without_refunds: 3.14,
                  with_refunds: 2.96,
                },
                gross_total: {
                  without_refunds: 3.53,
                  with_refunds: 3.32,
                },
                net_total_spread: {
                  without_refunds: 3.35,
                  with_refunds: 3.24,
                },
                gross_total_spread: {
                  without_refunds: 3.88,
                  with_refunds: 3.76,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 10.96,
                },
                gross_mrr: {
                  without_refunds: 12.68,
                  with_refunds: 12.18,
                },
                net_arr: {
                  without_refunds: 118.18,
                  with_refunds: 78.85,
                },
                gross_arr: {
                  without_refunds: 154.07,
                  with_refunds: 103.25,
                },
                net_arr_spread: {
                  without_refunds: 6.13,
                  with_refunds: 6.13,
                },
                gross_arr_spread: {
                  without_refunds: 9.35,
                  with_refunds: 9.35,
                },
                net_total: {
                  without_refunds: 12.13,
                  with_refunds: 11.41,
                },
                gross_total: {
                  without_refunds: 13.61,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.38,
                  with_refunds: 10.01,
                },
                gross_total_spread: {
                  without_refunds: 12.02,
                  with_refunds: 11.62,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.46,
                    with_refunds: 36.53,
                  },
                  gross_mrr: {
                    without_refunds: 44.28,
                    with_refunds: 43.2,
                  },
                  net_arr: {
                    without_refunds: 69.16,
                    with_refunds: 63.18,
                  },
                  gross_arr: {
                    without_refunds: 105.64,
                    with_refunds: 96.38,
                  },
                  net_arr_spread: {
                    without_refunds: 36.37,
                    with_refunds: 36.37,
                  },
                  gross_arr_spread: {
                    without_refunds: 56.07,
                    with_refunds: 56.07,
                  },
                  net_total: {
                    without_refunds: 40.15,
                    with_refunds: 38.84,
                  },
                  gross_total: {
                    without_refunds: 49.22,
                    with_refunds: 47.52,
                  },
                  net_total_spread: {
                    without_refunds: 37.7,
                    with_refunds: 36.83,
                  },
                  gross_total_spread: {
                    without_refunds: 45.45,
                    with_refunds: 44.44,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 77.75,
                    with_refunds: 75.59,
                  },
                  gross_mrr: {
                    without_refunds: 90.7,
                    with_refunds: 88.24,
                  },
                  net_arr: {
                    without_refunds: 628.27,
                    with_refunds: 465.38,
                  },
                  gross_arr: {
                    without_refunds: 846.66,
                    with_refunds: 636.86,
                  },
                  net_arr_spread: {
                    without_refunds: 41.97,
                    with_refunds: 41.97,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.73,
                    with_refunds: 64.73,
                  },
                  net_total: {
                    without_refunds: 85.34,
                    with_refunds: 82.32,
                  },
                  gross_total: {
                    without_refunds: 102.4,
                    with_refunds: 98.65,
                  },
                  net_total_spread: {
                    without_refunds: 73.21,
                    with_refunds: 71.35,
                  },
                  gross_total_spread: {
                    without_refunds: 87.54,
                    with_refunds: 85.4,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8524,
                    with_refunds: 0.8311,
                  },
                  gross_mrr: {
                    without_refunds: 1.0075,
                    with_refunds: 0.9829,
                  },
                  net_arr: {
                    without_refunds: 1.5736,
                    with_refunds: 1.4375,
                  },
                  gross_arr: {
                    without_refunds: 2.4035,
                    with_refunds: 2.193,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8276,
                    with_refunds: 0.8276,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2757,
                    with_refunds: 1.2757,
                  },
                  net_total: {
                    without_refunds: 0.9135,
                    with_refunds: 0.8836,
                  },
                  gross_total: {
                    without_refunds: 1.1198,
                    with_refunds: 1.0813,
                  },
                  net_total_spread: {
                    without_refunds: 0.8579,
                    with_refunds: 0.8379,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0342,
                    with_refunds: 1.0111,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.7691,
                    with_refunds: 1.7198,
                  },
                  gross_mrr: {
                    without_refunds: 2.0637,
                    with_refunds: 2.0077,
                  },
                  net_arr: {
                    without_refunds: 14.2951,
                    with_refunds: 10.5889,
                  },
                  gross_arr: {
                    without_refunds: 19.2641,
                    with_refunds: 14.4906,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9549,
                    with_refunds: 0.9549,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4727,
                    with_refunds: 1.4727,
                  },
                  net_total: {
                    without_refunds: 1.9418,
                    with_refunds: 1.8731,
                  },
                  gross_total: {
                    without_refunds: 2.33,
                    with_refunds: 2.2447,
                  },
                  net_total_spread: {
                    without_refunds: 1.6658,
                    with_refunds: 1.6233,
                  },
                  gross_total_spread: {
                    without_refunds: 1.9917,
                    with_refunds: 1.9432,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 785,
                  with_refunds: 789,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 231,
                  with_refunds: 231,
                },
                total_users: {
                  without_refunds: 789,
                  with_refunds: 791,
                },
                total_spread_users: {
                  without_refunds: 1015,
                  with_refunds: 1017,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2346,
                  with_refunds: 0.2358,
                },
                arr_users: {
                  without_refunds: 0.019,
                  with_refunds: 0.019,
                },
                arr_spread_users: {
                  without_refunds: 0.9585,
                  with_refunds: 0.9585,
                },
                total_users: {
                  without_refunds: 0.2207,
                  with_refunds: 0.2213,
                },
                total_spread_users: {
                  without_refunds: 0.2854,
                  with_refunds: 0.2859,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8514,
                  with_refunds: 0.8539,
                },
                arr_users: {
                  without_refunds: 0.8333,
                  with_refunds: 0.8333,
                },
                arr_spread_users: {
                  without_refunds: 1.0221,
                  with_refunds: 1.0221,
                },
                total_users: {
                  without_refunds: 0.8511,
                  with_refunds: 0.8515,
                },
                total_spread_users: {
                  without_refunds: 0.8841,
                  with_refunds: 0.8843,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9028.64,
                  with_refunds: 8800.12,
                },
                gross_mrr: {
                  without_refunds: 9988.53,
                  with_refunds: 9735.76,
                },
                net_arr: {
                  without_refunds: 564.16,
                  with_refunds: 564.16,
                },
                gross_arr: {
                  without_refunds: 663.72,
                  with_refunds: 663.72,
                },
                net_arr_spread: {
                  without_refunds: 1431.51,
                  with_refunds: 1431.51,
                },
                gross_arr_spread: {
                  without_refunds: 2167.46,
                  with_refunds: 2167.46,
                },
                net_total: {
                  without_refunds: 9592.8,
                  with_refunds: 9364.28,
                },
                gross_total: {
                  without_refunds: 10652.25,
                  with_refunds: 10399.49,
                },
                net_total_spread: {
                  without_refunds: 10460.15,
                  with_refunds: 10231.63,
                },
                gross_total_spread: {
                  without_refunds: 12155.99,
                  with_refunds: 11903.23,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.433,
                  with_refunds: 0.4332,
                },
                gross_mrr: {
                  without_refunds: 0.3599,
                  with_refunds: 0.3594,
                },
                net_arr: {
                  without_refunds: 0.046,
                  with_refunds: 0.049,
                },
                gross_arr: {
                  without_refunds: 0.0347,
                  with_refunds: 0.037,
                },
                net_arr_spread: {
                  without_refunds: 1.5222,
                  with_refunds: 1.5222,
                },
                gross_arr_spread: {
                  without_refunds: 1.4798,
                  with_refunds: 1.4798,
                },
                net_total: {
                  without_refunds: 0.2898,
                  with_refunds: 0.2943,
                },
                gross_total: {
                  without_refunds: 0.2271,
                  with_refunds: 0.231,
                },
                net_total_spread: {
                  without_refunds: 0.48,
                  with_refunds: 0.4814,
                },
                gross_total_spread: {
                  without_refunds: 0.416,
                  with_refunds: 0.4169,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8572,
                  with_refunds: 0.869,
                },
                gross_mrr: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8651,
                },
                net_arr: {
                  without_refunds: 0.7956,
                  with_refunds: 1.1925,
                },
                gross_arr: {
                  without_refunds: 0.718,
                  with_refunds: 1.0713,
                },
                net_arr_spread: {
                  without_refunds: 1.034,
                  with_refunds: 1.034,
                },
                gross_arr_spread: {
                  without_refunds: 1.0262,
                  with_refunds: 1.0262,
                },
                net_total: {
                  without_refunds: 0.8534,
                  with_refunds: 0.8834,
                },
                gross_total: {
                  without_refunds: 0.8446,
                  with_refunds: 0.8759,
                },
                net_total_spread: {
                  without_refunds: 0.8778,
                  with_refunds: 0.8888,
                },
                gross_total_spread: {
                  without_refunds: 0.8808,
                  with_refunds: 0.8906,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.7,
                  with_refunds: 2.63,
                },
                gross_mrr: {
                  without_refunds: 2.99,
                  with_refunds: 2.91,
                },
                net_arr: {
                  without_refunds: 2.15,
                  with_refunds: 2.15,
                },
                gross_arr: {
                  without_refunds: 2.52,
                  with_refunds: 2.52,
                },
                net_arr_spread: {
                  without_refunds: 5.94,
                  with_refunds: 5.94,
                },
                gross_arr_spread: {
                  without_refunds: 8.99,
                  with_refunds: 8.99,
                },
                net_total: {
                  without_refunds: 2.68,
                  with_refunds: 2.62,
                },
                gross_total: {
                  without_refunds: 2.98,
                  with_refunds: 2.91,
                },
                net_total_spread: {
                  without_refunds: 2.94,
                  with_refunds: 2.88,
                },
                gross_total_spread: {
                  without_refunds: 3.42,
                  with_refunds: 3.35,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.5,
                  with_refunds: 11.15,
                },
                gross_mrr: {
                  without_refunds: 12.72,
                  with_refunds: 12.34,
                },
                net_arr: {
                  without_refunds: 112.83,
                  with_refunds: 112.83,
                },
                gross_arr: {
                  without_refunds: 132.74,
                  with_refunds: 132.74,
                },
                net_arr_spread: {
                  without_refunds: 6.2,
                  with_refunds: 6.2,
                },
                gross_arr_spread: {
                  without_refunds: 9.38,
                  with_refunds: 9.38,
                },
                net_total: {
                  without_refunds: 12.16,
                  with_refunds: 11.84,
                },
                gross_total: {
                  without_refunds: 13.5,
                  with_refunds: 13.15,
                },
                net_total_spread: {
                  without_refunds: 10.31,
                  with_refunds: 10.06,
                },
                gross_total_spread: {
                  without_refunds: 11.98,
                  with_refunds: 11.7,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.16,
                    with_refunds: 39.16,
                  },
                  gross_mrr: {
                    without_refunds: 47.27,
                    with_refunds: 46.11,
                  },
                  net_arr: {
                    without_refunds: 71.3,
                    with_refunds: 65.32,
                  },
                  gross_arr: {
                    without_refunds: 108.16,
                    with_refunds: 98.91,
                  },
                  net_arr_spread: {
                    without_refunds: 42.31,
                    with_refunds: 42.31,
                  },
                  gross_arr_spread: {
                    without_refunds: 65.06,
                    with_refunds: 65.06,
                  },
                  net_total: {
                    without_refunds: 42.83,
                    with_refunds: 41.45,
                  },
                  gross_total: {
                    without_refunds: 52.19,
                    with_refunds: 50.43,
                  },
                  net_total_spread: {
                    without_refunds: 40.64,
                    with_refunds: 39.7,
                  },
                  gross_total_spread: {
                    without_refunds: 48.87,
                    with_refunds: 47.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 89.25,
                    with_refunds: 86.74,
                  },
                  gross_mrr: {
                    without_refunds: 103.42,
                    with_refunds: 100.58,
                  },
                  net_arr: {
                    without_refunds: 741.1,
                    with_refunds: 578.21,
                  },
                  gross_arr: {
                    without_refunds: 979.4,
                    with_refunds: 769.61,
                  },
                  net_arr_spread: {
                    without_refunds: 48.17,
                    with_refunds: 48.17,
                  },
                  gross_arr_spread: {
                    without_refunds: 74.11,
                    with_refunds: 74.11,
                  },
                  net_total: {
                    without_refunds: 97.5,
                    with_refunds: 94.16,
                  },
                  gross_total: {
                    without_refunds: 115.9,
                    with_refunds: 111.8,
                  },
                  net_total_spread: {
                    without_refunds: 83.52,
                    with_refunds: 81.41,
                  },
                  gross_total_spread: {
                    without_refunds: 99.51,
                    with_refunds: 97.11,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9138,
                    with_refunds: 0.891,
                  },
                  gross_mrr: {
                    without_refunds: 1.0754,
                    with_refunds: 1.0491,
                  },
                  net_arr: {
                    without_refunds: 1.6224,
                    with_refunds: 1.4863,
                  },
                  gross_arr: {
                    without_refunds: 2.461,
                    with_refunds: 2.2505,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9628,
                    with_refunds: 0.9628,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4804,
                    with_refunds: 1.4804,
                  },
                  net_total: {
                    without_refunds: 0.9746,
                    with_refunds: 0.9432,
                  },
                  gross_total: {
                    without_refunds: 1.1876,
                    with_refunds: 1.1475,
                  },
                  net_total_spread: {
                    without_refunds: 0.9248,
                    with_refunds: 0.9033,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1119,
                    with_refunds: 1.0872,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.0308,
                    with_refunds: 1.9736,
                  },
                  gross_mrr: {
                    without_refunds: 2.3532,
                    with_refunds: 2.2885,
                  },
                  net_arr: {
                    without_refunds: 16.8624,
                    with_refunds: 13.1561,
                  },
                  gross_arr: {
                    without_refunds: 22.2844,
                    with_refunds: 17.5109,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0959,
                    with_refunds: 1.0959,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6862,
                    with_refunds: 1.6862,
                  },
                  net_total: {
                    without_refunds: 2.2185,
                    with_refunds: 2.1424,
                  },
                  gross_total: {
                    without_refunds: 2.6371,
                    with_refunds: 2.5438,
                  },
                  net_total_spread: {
                    without_refunds: 1.9003,
                    with_refunds: 1.8522,
                  },
                  gross_total_spread: {
                    without_refunds: 2.2642,
                    with_refunds: 2.2095,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 709,
                  with_refunds: 712,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 236,
                  with_refunds: 236,
                },
                total_users: {
                  without_refunds: 714,
                  with_refunds: 715,
                },
                total_spread_users: {
                  without_refunds: 944,
                  with_refunds: 945,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2119,
                  with_refunds: 0.2128,
                },
                arr_users: {
                  without_refunds: 0.0228,
                  with_refunds: 0.0228,
                },
                arr_spread_users: {
                  without_refunds: 0.9793,
                  with_refunds: 0.9793,
                },
                total_users: {
                  without_refunds: 0.1997,
                  with_refunds: 0.2,
                },
                total_spread_users: {
                  without_refunds: 0.2654,
                  with_refunds: 0.2657,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9032,
                  with_refunds: 0.9024,
                },
                arr_users: {
                  without_refunds: 1.2,
                  with_refunds: 1.2,
                },
                arr_spread_users: {
                  without_refunds: 1.0216,
                  with_refunds: 1.0216,
                },
                total_users: {
                  without_refunds: 0.9049,
                  with_refunds: 0.9039,
                },
                total_spread_users: {
                  without_refunds: 0.93,
                  with_refunds: 0.9292,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8191.3,
                  with_refunds: 8085.53,
                },
                gross_mrr: {
                  without_refunds: 9050.64,
                  with_refunds: 8937.02,
                },
                net_arr: {
                  without_refunds: 686.78,
                  with_refunds: 686.78,
                },
                gross_arr: {
                  without_refunds: 876.75,
                  with_refunds: 876.75,
                },
                net_arr_spread: {
                  without_refunds: 1482.58,
                  with_refunds: 1482.58,
                },
                gross_arr_spread: {
                  without_refunds: 2229.9,
                  with_refunds: 2229.9,
                },
                net_total: {
                  without_refunds: 8878.08,
                  with_refunds: 8772.32,
                },
                gross_total: {
                  without_refunds: 9927.38,
                  with_refunds: 9813.77,
                },
                net_total_spread: {
                  without_refunds: 9673.88,
                  with_refunds: 9568.11,
                },
                gross_total_spread: {
                  without_refunds: 11280.54,
                  with_refunds: 11166.92,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3928,
                  with_refunds: 0.398,
                },
                gross_mrr: {
                  without_refunds: 0.3261,
                  with_refunds: 0.3299,
                },
                net_arr: {
                  without_refunds: 0.0561,
                  with_refunds: 0.0597,
                },
                gross_arr: {
                  without_refunds: 0.0458,
                  with_refunds: 0.0489,
                },
                net_arr_spread: {
                  without_refunds: 1.5765,
                  with_refunds: 1.5765,
                },
                gross_arr_spread: {
                  without_refunds: 1.5224,
                  with_refunds: 1.5224,
                },
                net_total: {
                  without_refunds: 0.2682,
                  with_refunds: 0.2757,
                },
                gross_total: {
                  without_refunds: 0.2116,
                  with_refunds: 0.218,
                },
                net_total_spread: {
                  without_refunds: 0.4439,
                  with_refunds: 0.4502,
                },
                gross_total_spread: {
                  without_refunds: 0.3861,
                  with_refunds: 0.3911,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9073,
                  with_refunds: 0.9188,
                },
                gross_mrr: {
                  without_refunds: 0.9061,
                  with_refunds: 0.918,
                },
                net_arr: {
                  without_refunds: 1.2174,
                  with_refunds: 1.2174,
                },
                gross_arr: {
                  without_refunds: 1.321,
                  with_refunds: 1.321,
                },
                net_arr_spread: {
                  without_refunds: 1.0357,
                  with_refunds: 1.0357,
                },
                gross_arr_spread: {
                  without_refunds: 1.0288,
                  with_refunds: 1.0288,
                },
                net_total: {
                  without_refunds: 0.9255,
                  with_refunds: 0.9368,
                },
                gross_total: {
                  without_refunds: 0.932,
                  with_refunds: 0.9437,
                },
                net_total_spread: {
                  without_refunds: 0.9248,
                  with_refunds: 0.9352,
                },
                gross_total_spread: {
                  without_refunds: 0.928,
                  with_refunds: 0.9381,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.45,
                  with_refunds: 2.42,
                },
                gross_mrr: {
                  without_refunds: 2.7,
                  with_refunds: 2.67,
                },
                net_arr: {
                  without_refunds: 2.61,
                  with_refunds: 2.61,
                },
                gross_arr: {
                  without_refunds: 3.33,
                  with_refunds: 3.33,
                },
                net_arr_spread: {
                  without_refunds: 6.15,
                  with_refunds: 6.15,
                },
                gross_arr_spread: {
                  without_refunds: 9.25,
                  with_refunds: 9.25,
                },
                net_total: {
                  without_refunds: 2.48,
                  with_refunds: 2.45,
                },
                gross_total: {
                  without_refunds: 2.78,
                  with_refunds: 2.75,
                },
                net_total_spread: {
                  without_refunds: 2.72,
                  with_refunds: 2.69,
                },
                gross_total_spread: {
                  without_refunds: 3.17,
                  with_refunds: 3.14,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.55,
                  with_refunds: 11.36,
                },
                gross_mrr: {
                  without_refunds: 12.77,
                  with_refunds: 12.55,
                },
                net_arr: {
                  without_refunds: 114.46,
                  with_refunds: 114.46,
                },
                gross_arr: {
                  without_refunds: 146.12,
                  with_refunds: 146.12,
                },
                net_arr_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.28,
                },
                gross_arr_spread: {
                  without_refunds: 9.45,
                  with_refunds: 9.45,
                },
                net_total: {
                  without_refunds: 12.43,
                  with_refunds: 12.27,
                },
                gross_total: {
                  without_refunds: 13.9,
                  with_refunds: 13.73,
                },
                net_total_spread: {
                  without_refunds: 10.25,
                  with_refunds: 10.12,
                },
                gross_total_spread: {
                  without_refunds: 11.95,
                  with_refunds: 11.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.61,
                    with_refunds: 41.57,
                  },
                  gross_mrr: {
                    without_refunds: 49.97,
                    with_refunds: 48.78,
                  },
                  net_arr: {
                    without_refunds: 73.91,
                    with_refunds: 67.93,
                  },
                  gross_arr: {
                    without_refunds: 111.49,
                    with_refunds: 102.24,
                  },
                  net_arr_spread: {
                    without_refunds: 48.47,
                    with_refunds: 48.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 74.32,
                    with_refunds: 74.32,
                  },
                  net_total: {
                    without_refunds: 45.32,
                    with_refunds: 43.91,
                  },
                  gross_total: {
                    without_refunds: 54.97,
                    with_refunds: 53.18,
                  },
                  net_total_spread: {
                    without_refunds: 43.36,
                    with_refunds: 42.39,
                  },
                  gross_total_spread: {
                    without_refunds: 52.04,
                    with_refunds: 50.92,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 100.81,
                    with_refunds: 98.1,
                  },
                  gross_mrr: {
                    without_refunds: 116.19,
                    with_refunds: 113.13,
                  },
                  net_arr: {
                    without_refunds: 855.57,
                    with_refunds: 692.68,
                  },
                  gross_arr: {
                    without_refunds: 1125.52,
                    with_refunds: 915.73,
                  },
                  net_arr_spread: {
                    without_refunds: 54.45,
                    with_refunds: 54.45,
                  },
                  gross_arr_spread: {
                    without_refunds: 83.56,
                    with_refunds: 83.56,
                  },
                  net_total: {
                    without_refunds: 109.94,
                    with_refunds: 106.43,
                  },
                  gross_total: {
                    without_refunds: 129.81,
                    with_refunds: 125.53,
                  },
                  net_total_spread: {
                    without_refunds: 93.77,
                    with_refunds: 91.53,
                  },
                  gross_total_spread: {
                    without_refunds: 111.46,
                    with_refunds: 108.93,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9695,
                    with_refunds: 0.9459,
                  },
                  gross_mrr: {
                    without_refunds: 1.137,
                    with_refunds: 1.1099,
                  },
                  net_arr: {
                    without_refunds: 1.6818,
                    with_refunds: 1.5457,
                  },
                  gross_arr: {
                    without_refunds: 2.5368,
                    with_refunds: 2.3263,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1028,
                    with_refunds: 1.1028,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6909,
                    with_refunds: 1.6909,
                  },
                  net_total: {
                    without_refunds: 1.0311,
                    with_refunds: 0.9991,
                  },
                  gross_total: {
                    without_refunds: 1.2508,
                    with_refunds: 1.2099,
                  },
                  net_total_spread: {
                    without_refunds: 0.9867,
                    with_refunds: 0.9645,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1841,
                    with_refunds: 1.1586,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.2937,
                    with_refunds: 2.232,
                  },
                  gross_mrr: {
                    without_refunds: 2.6437,
                    with_refunds: 2.5741,
                  },
                  net_arr: {
                    without_refunds: 19.4668,
                    with_refunds: 15.7606,
                  },
                  gross_arr: {
                    without_refunds: 25.6092,
                    with_refunds: 20.8357,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2388,
                    with_refunds: 1.2388,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9012,
                    with_refunds: 1.9012,
                  },
                  net_total: {
                    without_refunds: 2.5014,
                    with_refunds: 2.4216,
                  },
                  gross_total: {
                    without_refunds: 2.9535,
                    with_refunds: 2.8561,
                  },
                  net_total_spread: {
                    without_refunds: 2.1335,
                    with_refunds: 2.0826,
                  },
                  gross_total_spread: {
                    without_refunds: 2.5361,
                    with_refunds: 2.4784,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 634,
                  with_refunds: 637,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 3,
                },
                arr_spread_users: {
                  without_refunds: 238,
                  with_refunds: 238,
                },
                total_users: {
                  without_refunds: 637,
                  with_refunds: 640,
                },
                total_spread_users: {
                  without_refunds: 872,
                  with_refunds: 875,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1895,
                  with_refunds: 0.1904,
                },
                arr_users: {
                  without_refunds: 0.0114,
                  with_refunds: 0.0114,
                },
                arr_spread_users: {
                  without_refunds: 0.9876,
                  with_refunds: 0.9876,
                },
                total_users: {
                  without_refunds: 0.1782,
                  with_refunds: 0.179,
                },
                total_spread_users: {
                  without_refunds: 0.2452,
                  with_refunds: 0.246,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8942,
                  with_refunds: 0.8947,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.5,
                },
                arr_spread_users: {
                  without_refunds: 1.0085,
                  with_refunds: 1.0085,
                },
                total_users: {
                  without_refunds: 0.8922,
                  with_refunds: 0.8951,
                },
                total_spread_users: {
                  without_refunds: 0.9237,
                  with_refunds: 0.9259,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7212.39,
                  with_refunds: 7061.86,
                },
                gross_mrr: {
                  without_refunds: 7956.48,
                  with_refunds: 7789.7,
                },
                net_arr: {
                  without_refunds: 315.77,
                  with_refunds: 174.62,
                },
                gross_arr: {
                  without_refunds: 419.4,
                  with_refunds: 219.47,
                },
                net_arr_spread: {
                  without_refunds: 1499.15,
                  with_refunds: 1499.15,
                },
                gross_arr_spread: {
                  without_refunds: 2251.66,
                  with_refunds: 2251.66,
                },
                net_total: {
                  without_refunds: 7528.16,
                  with_refunds: 7236.48,
                },
                gross_total: {
                  without_refunds: 8375.88,
                  with_refunds: 8009.17,
                },
                net_total_spread: {
                  without_refunds: 8711.54,
                  with_refunds: 8561,
                },
                gross_total_spread: {
                  without_refunds: 10208.14,
                  with_refunds: 10041.36,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3459,
                  with_refunds: 0.3476,
                },
                gross_mrr: {
                  without_refunds: 0.2867,
                  with_refunds: 0.2876,
                },
                net_arr: {
                  without_refunds: 0.0258,
                  with_refunds: 0.0152,
                },
                gross_arr: {
                  without_refunds: 0.0219,
                  with_refunds: 0.0122,
                },
                net_arr_spread: {
                  without_refunds: 1.5941,
                  with_refunds: 1.5941,
                },
                gross_arr_spread: {
                  without_refunds: 1.5373,
                  with_refunds: 1.5373,
                },
                net_total: {
                  without_refunds: 0.2274,
                  with_refunds: 0.2274,
                },
                gross_total: {
                  without_refunds: 0.1786,
                  with_refunds: 0.1779,
                },
                net_total_spread: {
                  without_refunds: 0.3998,
                  with_refunds: 0.4028,
                },
                gross_total_spread: {
                  without_refunds: 0.3494,
                  with_refunds: 0.3517,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8805,
                  with_refunds: 0.8734,
                },
                gross_mrr: {
                  without_refunds: 0.8791,
                  with_refunds: 0.8716,
                },
                net_arr: {
                  without_refunds: 0.4598,
                  with_refunds: 0.2543,
                },
                gross_arr: {
                  without_refunds: 0.4784,
                  with_refunds: 0.2503,
                },
                net_arr_spread: {
                  without_refunds: 1.0112,
                  with_refunds: 1.0112,
                },
                gross_arr_spread: {
                  without_refunds: 1.0098,
                  with_refunds: 1.0098,
                },
                net_total: {
                  without_refunds: 0.8479,
                  with_refunds: 0.8249,
                },
                gross_total: {
                  without_refunds: 0.8437,
                  with_refunds: 0.8161,
                },
                net_total_spread: {
                  without_refunds: 0.9005,
                  with_refunds: 0.8947,
                },
                gross_total_spread: {
                  without_refunds: 0.9049,
                  with_refunds: 0.8992,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.16,
                  with_refunds: 2.11,
                },
                gross_mrr: {
                  without_refunds: 2.38,
                  with_refunds: 2.33,
                },
                net_arr: {
                  without_refunds: 1.2,
                  with_refunds: 0.66,
                },
                gross_arr: {
                  without_refunds: 1.59,
                  with_refunds: 0.83,
                },
                net_arr_spread: {
                  without_refunds: 6.22,
                  with_refunds: 6.22,
                },
                gross_arr_spread: {
                  without_refunds: 9.34,
                  with_refunds: 9.34,
                },
                net_total: {
                  without_refunds: 2.11,
                  with_refunds: 2.02,
                },
                gross_total: {
                  without_refunds: 2.34,
                  with_refunds: 2.24,
                },
                net_total_spread: {
                  without_refunds: 2.45,
                  with_refunds: 2.41,
                },
                gross_total_spread: {
                  without_refunds: 2.87,
                  with_refunds: 2.82,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.38,
                  with_refunds: 11.09,
                },
                gross_mrr: {
                  without_refunds: 12.55,
                  with_refunds: 12.23,
                },
                net_arr: {
                  without_refunds: 105.26,
                  with_refunds: 58.21,
                },
                gross_arr: {
                  without_refunds: 139.8,
                  with_refunds: 73.16,
                },
                net_arr_spread: {
                  without_refunds: 6.3,
                  with_refunds: 6.3,
                },
                gross_arr_spread: {
                  without_refunds: 9.46,
                  with_refunds: 9.46,
                },
                net_total: {
                  without_refunds: 11.82,
                  with_refunds: 11.31,
                },
                gross_total: {
                  without_refunds: 13.15,
                  with_refunds: 12.51,
                },
                net_total_spread: {
                  without_refunds: 9.99,
                  with_refunds: 9.78,
                },
                gross_total_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.48,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.76,
                    with_refunds: 43.68,
                  },
                  gross_mrr: {
                    without_refunds: 52.35,
                    with_refunds: 51.11,
                  },
                  net_arr: {
                    without_refunds: 75.11,
                    with_refunds: 68.6,
                  },
                  gross_arr: {
                    without_refunds: 113.09,
                    with_refunds: 103.08,
                  },
                  net_arr_spread: {
                    without_refunds: 54.69,
                    with_refunds: 54.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 83.66,
                    with_refunds: 83.66,
                  },
                  net_total: {
                    without_refunds: 47.42,
                    with_refunds: 45.93,
                  },
                  gross_total: {
                    without_refunds: 57.31,
                    with_refunds: 55.42,
                  },
                  net_total_spread: {
                    without_refunds: 45.81,
                    with_refunds: 44.8,
                  },
                  gross_total_spread: {
                    without_refunds: 54.91,
                    with_refunds: 53.74,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 112.18,
                    with_refunds: 109.18,
                  },
                  gross_mrr: {
                    without_refunds: 128.74,
                    with_refunds: 125.36,
                  },
                  net_arr: {
                    without_refunds: 960.82,
                    with_refunds: 750.88,
                  },
                  gross_arr: {
                    without_refunds: 1265.32,
                    with_refunds: 988.89,
                  },
                  net_arr_spread: {
                    without_refunds: 60.75,
                    with_refunds: 60.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 93.02,
                    with_refunds: 93.02,
                  },
                  net_total: {
                    without_refunds: 121.75,
                    with_refunds: 117.74,
                  },
                  gross_total: {
                    without_refunds: 142.96,
                    with_refunds: 138.04,
                  },
                  net_total_spread: {
                    without_refunds: 103.76,
                    with_refunds: 101.32,
                  },
                  gross_total_spread: {
                    without_refunds: 123.17,
                    with_refunds: 120.4,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0185,
                    with_refunds: 0.994,
                  },
                  gross_mrr: {
                    without_refunds: 1.1911,
                    with_refunds: 1.1629,
                  },
                  net_arr: {
                    without_refunds: 1.7091,
                    with_refunds: 1.5608,
                  },
                  gross_arr: {
                    without_refunds: 2.5731,
                    with_refunds: 2.3453,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2443,
                    with_refunds: 1.2443,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9035,
                    with_refunds: 1.9035,
                  },
                  net_total: {
                    without_refunds: 1.079,
                    with_refunds: 1.0451,
                  },
                  gross_total: {
                    without_refunds: 1.3041,
                    with_refunds: 1.2609,
                  },
                  net_total_spread: {
                    without_refunds: 1.0424,
                    with_refunds: 1.0193,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2494,
                    with_refunds: 1.2229,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.5525,
                    with_refunds: 2.4842,
                  },
                  gross_mrr: {
                    without_refunds: 2.9292,
                    with_refunds: 2.8523,
                  },
                  net_arr: {
                    without_refunds: 21.8617,
                    with_refunds: 17.0849,
                  },
                  gross_arr: {
                    without_refunds: 28.7901,
                    with_refunds: 22.5003,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3822,
                    with_refunds: 1.3822,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.1165,
                    with_refunds: 2.1165,
                  },
                  net_total: {
                    without_refunds: 2.7703,
                    with_refunds: 2.6789,
                  },
                  gross_total: {
                    without_refunds: 3.2527,
                    with_refunds: 3.1408,
                  },
                  net_total_spread: {
                    without_refunds: 2.3608,
                    with_refunds: 2.3052,
                  },
                  gross_total_spread: {
                    without_refunds: 2.8025,
                    with_refunds: 2.7395,
                  },
                },
              },
            },
          },
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 587,
                  with_refunds: 588,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 3,
                },
                arr_spread_users: {
                  without_refunds: 240,
                  with_refunds: 240,
                },
                total_users: {
                  without_refunds: 590,
                  with_refunds: 591,
                },
                total_spread_users: {
                  without_refunds: 827,
                  with_refunds: 828,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1754,
                  with_refunds: 0.1757,
                },
                arr_users: {
                  without_refunds: 0.0114,
                  with_refunds: 0.0114,
                },
                arr_spread_users: {
                  without_refunds: 0.9959,
                  with_refunds: 0.9959,
                },
                total_users: {
                  without_refunds: 0.165,
                  with_refunds: 0.1653,
                },
                total_spread_users: {
                  without_refunds: 0.2325,
                  with_refunds: 0.2328,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9259,
                  with_refunds: 0.9231,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1.0084,
                  with_refunds: 1.0084,
                },
                total_users: {
                  without_refunds: 0.9262,
                  with_refunds: 0.9234,
                },
                total_spread_users: {
                  without_refunds: 0.9484,
                  with_refunds: 0.9463,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6716.14,
                  with_refunds: 6659.39,
                },
                gross_mrr: {
                  without_refunds: 7405.3,
                  with_refunds: 7345.3,
                },
                net_arr: {
                  without_refunds: 342.73,
                  with_refunds: 219.35,
                },
                gross_arr: {
                  without_refunds: 452.88,
                  with_refunds: 272.89,
                },
                net_arr_spread: {
                  without_refunds: 1517.43,
                  with_refunds: 1517.43,
                },
                gross_arr_spread: {
                  without_refunds: 2274.41,
                  with_refunds: 2274.41,
                },
                net_total: {
                  without_refunds: 7058.88,
                  with_refunds: 6878.75,
                },
                gross_total: {
                  without_refunds: 7858.18,
                  with_refunds: 7618.19,
                },
                net_total_spread: {
                  without_refunds: 8233.57,
                  with_refunds: 8176.82,
                },
                gross_total_spread: {
                  without_refunds: 9679.7,
                  with_refunds: 9619.7,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3221,
                  with_refunds: 0.3278,
                },
                gross_mrr: {
                  without_refunds: 0.2668,
                  with_refunds: 0.2712,
                },
                net_arr: {
                  without_refunds: 0.028,
                  with_refunds: 0.0191,
                },
                gross_arr: {
                  without_refunds: 0.0236,
                  with_refunds: 0.0152,
                },
                net_arr_spread: {
                  without_refunds: 1.6136,
                  with_refunds: 1.6136,
                },
                gross_arr_spread: {
                  without_refunds: 1.5528,
                  with_refunds: 1.5528,
                },
                net_total: {
                  without_refunds: 0.2132,
                  with_refunds: 0.2162,
                },
                gross_total: {
                  without_refunds: 0.1675,
                  with_refunds: 0.1692,
                },
                net_total_spread: {
                  without_refunds: 0.3778,
                  with_refunds: 0.3847,
                },
                gross_total_spread: {
                  without_refunds: 0.3313,
                  with_refunds: 0.3369,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9312,
                  with_refunds: 0.943,
                },
                gross_mrr: {
                  without_refunds: 0.9307,
                  with_refunds: 0.943,
                },
                net_arr: {
                  without_refunds: 1.0854,
                  with_refunds: 1.2562,
                },
                gross_arr: {
                  without_refunds: 1.0798,
                  with_refunds: 1.2434,
                },
                net_arr_spread: {
                  without_refunds: 1.0122,
                  with_refunds: 1.0122,
                },
                gross_arr_spread: {
                  without_refunds: 1.0101,
                  with_refunds: 1.0101,
                },
                net_total: {
                  without_refunds: 0.9377,
                  with_refunds: 0.9506,
                },
                gross_total: {
                  without_refunds: 0.9382,
                  with_refunds: 0.9512,
                },
                net_total_spread: {
                  without_refunds: 0.9451,
                  with_refunds: 0.9551,
                },
                gross_total_spread: {
                  without_refunds: 0.9482,
                  with_refunds: 0.958,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.01,
                  with_refunds: 1.99,
                },
                gross_mrr: {
                  without_refunds: 2.21,
                  with_refunds: 2.2,
                },
                net_arr: {
                  without_refunds: 1.3,
                  with_refunds: 0.83,
                },
                gross_arr: {
                  without_refunds: 1.72,
                  with_refunds: 1.04,
                },
                net_arr_spread: {
                  without_refunds: 6.3,
                  with_refunds: 6.3,
                },
                gross_arr_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.44,
                },
                net_total: {
                  without_refunds: 1.97,
                  with_refunds: 1.92,
                },
                gross_total: {
                  without_refunds: 2.2,
                  with_refunds: 2.13,
                },
                net_total_spread: {
                  without_refunds: 2.31,
                  with_refunds: 2.3,
                },
                gross_total_spread: {
                  without_refunds: 2.72,
                  with_refunds: 2.7,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.44,
                  with_refunds: 11.33,
                },
                gross_mrr: {
                  without_refunds: 12.62,
                  with_refunds: 12.49,
                },
                net_arr: {
                  without_refunds: 114.24,
                  with_refunds: 73.12,
                },
                gross_arr: {
                  without_refunds: 150.96,
                  with_refunds: 90.96,
                },
                net_arr_spread: {
                  without_refunds: 6.32,
                  with_refunds: 6.32,
                },
                gross_arr_spread: {
                  without_refunds: 9.48,
                  with_refunds: 9.48,
                },
                net_total: {
                  without_refunds: 11.96,
                  with_refunds: 11.64,
                },
                gross_total: {
                  without_refunds: 13.32,
                  with_refunds: 12.89,
                },
                net_total_spread: {
                  without_refunds: 9.96,
                  with_refunds: 9.88,
                },
                gross_total_spread: {
                  without_refunds: 11.7,
                  with_refunds: 11.62,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 46.77,
                    with_refunds: 45.67,
                  },
                  gross_mrr: {
                    without_refunds: 54.56,
                    with_refunds: 53.3,
                  },
                  net_arr: {
                    without_refunds: 76.42,
                    with_refunds: 69.43,
                  },
                  gross_arr: {
                    without_refunds: 114.81,
                    with_refunds: 104.11,
                  },
                  net_arr_spread: {
                    without_refunds: 60.98,
                    with_refunds: 60.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 93.1,
                    with_refunds: 93.1,
                  },
                  net_total: {
                    without_refunds: 49.4,
                    with_refunds: 47.86,
                  },
                  gross_total: {
                    without_refunds: 59.51,
                    with_refunds: 57.55,
                  },
                  net_total_spread: {
                    without_refunds: 48.13,
                    with_refunds: 47.1,
                  },
                  gross_total_spread: {
                    without_refunds: 57.63,
                    with_refunds: 56.45,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 123.63,
                    with_refunds: 120.51,
                  },
                  gross_mrr: {
                    without_refunds: 141.36,
                    with_refunds: 137.85,
                  },
                  net_arr: {
                    without_refunds: 1075.07,
                    with_refunds: 824,
                  },
                  gross_arr: {
                    without_refunds: 1416.28,
                    with_refunds: 1079.85,
                  },
                  net_arr_spread: {
                    without_refunds: 67.07,
                    with_refunds: 67.07,
                  },
                  gross_arr_spread: {
                    without_refunds: 102.5,
                    with_refunds: 102.5,
                  },
                  net_total: {
                    without_refunds: 133.72,
                    with_refunds: 129.38,
                  },
                  gross_total: {
                    without_refunds: 156.27,
                    with_refunds: 150.93,
                  },
                  net_total_spread: {
                    without_refunds: 113.71,
                    with_refunds: 111.19,
                  },
                  gross_total_spread: {
                    without_refunds: 134.87,
                    with_refunds: 132.02,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0642,
                    with_refunds: 1.0392,
                  },
                  gross_mrr: {
                    without_refunds: 1.2414,
                    with_refunds: 1.2128,
                  },
                  net_arr: {
                    without_refunds: 1.7387,
                    with_refunds: 1.5798,
                  },
                  gross_arr: {
                    without_refunds: 2.6123,
                    with_refunds: 2.3689,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3876,
                    with_refunds: 1.3876,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.1182,
                    with_refunds: 2.1182,
                  },
                  net_total: {
                    without_refunds: 1.1239,
                    with_refunds: 1.0889,
                  },
                  gross_total: {
                    without_refunds: 1.3541,
                    with_refunds: 1.3094,
                  },
                  net_total_spread: {
                    without_refunds: 1.0951,
                    with_refunds: 1.0716,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3113,
                    with_refunds: 1.2844,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.8129,
                    with_refunds: 2.7419,
                  },
                  gross_mrr: {
                    without_refunds: 3.2163,
                    with_refunds: 3.1365,
                  },
                  net_arr: {
                    without_refunds: 24.4612,
                    with_refunds: 18.7486,
                  },
                  gross_arr: {
                    without_refunds: 32.2249,
                    with_refunds: 24.57,
                  },
                  net_arr_spread: {
                    without_refunds: 1.526,
                    with_refunds: 1.526,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.3321,
                    with_refunds: 2.3321,
                  },
                  net_total: {
                    without_refunds: 3.0425,
                    with_refunds: 2.9437,
                  },
                  gross_total: {
                    without_refunds: 3.5557,
                    with_refunds: 3.4341,
                  },
                  net_total_spread: {
                    without_refunds: 2.5873,
                    with_refunds: 2.5299,
                  },
                  gross_total_spread: {
                    without_refunds: 3.0688,
                    with_refunds: 3.0039,
                  },
                },
              },
            },
          },
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 539,
                  with_refunds: 540,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 241,
                  with_refunds: 241,
                },
                total_users: {
                  without_refunds: 540,
                  with_refunds: 541,
                },
                total_spread_users: {
                  without_refunds: 780,
                  with_refunds: 781,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1611,
                  with_refunds: 0.1614,
                },
                arr_users: {
                  without_refunds: 0.0038,
                  with_refunds: 0.0038,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.151,
                  with_refunds: 0.1513,
                },
                total_spread_users: {
                  without_refunds: 0.2193,
                  with_refunds: 0.2196,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9182,
                  with_refunds: 0.9184,
                },
                arr_users: {
                  without_refunds: 0.3333,
                  with_refunds: 0.3333,
                },
                arr_spread_users: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                total_users: {
                  without_refunds: 0.9153,
                  with_refunds: 0.9154,
                },
                total_spread_users: {
                  without_refunds: 0.9432,
                  with_refunds: 0.9432,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6185.19,
                  with_refunds: 6173.84,
                },
                gross_mrr: {
                  without_refunds: 6830.25,
                  with_refunds: 6818.25,
                },
                net_arr: {
                  without_refunds: 106.24,
                  with_refunds: 106.24,
                },
                gross_arr: {
                  without_refunds: 124.99,
                  with_refunds: 124.99,
                },
                net_arr_spread: {
                  without_refunds: 1526.28,
                  with_refunds: 1526.28,
                },
                gross_arr_spread: {
                  without_refunds: 2284.82,
                  with_refunds: 2284.82,
                },
                net_total: {
                  without_refunds: 6291.43,
                  with_refunds: 6280.08,
                },
                gross_total: {
                  without_refunds: 6955.24,
                  with_refunds: 6943.24,
                },
                net_total_spread: {
                  without_refunds: 7711.46,
                  with_refunds: 7700.11,
                },
                gross_total_spread: {
                  without_refunds: 9115.07,
                  with_refunds: 9103.07,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2966,
                  with_refunds: 0.3039,
                },
                gross_mrr: {
                  without_refunds: 0.2461,
                  with_refunds: 0.2517,
                },
                net_arr: {
                  without_refunds: 0.0087,
                  with_refunds: 0.0092,
                },
                gross_arr: {
                  without_refunds: 0.0065,
                  with_refunds: 0.007,
                },
                net_arr_spread: {
                  without_refunds: 1.623,
                  with_refunds: 1.623,
                },
                gross_arr_spread: {
                  without_refunds: 1.5599,
                  with_refunds: 1.5599,
                },
                net_total: {
                  without_refunds: 0.1901,
                  with_refunds: 0.1974,
                },
                gross_total: {
                  without_refunds: 0.1483,
                  with_refunds: 0.1542,
                },
                net_total_spread: {
                  without_refunds: 0.3539,
                  with_refunds: 0.3623,
                },
                gross_total_spread: {
                  without_refunds: 0.312,
                  with_refunds: 0.3188,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9209,
                  with_refunds: 0.9271,
                },
                gross_mrr: {
                  without_refunds: 0.9223,
                  with_refunds: 0.9282,
                },
                net_arr: {
                  without_refunds: 0.31,
                  with_refunds: 0.4843,
                },
                gross_arr: {
                  without_refunds: 0.276,
                  with_refunds: 0.458,
                },
                net_arr_spread: {
                  without_refunds: 1.0058,
                  with_refunds: 1.0058,
                },
                gross_arr_spread: {
                  without_refunds: 1.0046,
                  with_refunds: 1.0046,
                },
                net_total: {
                  without_refunds: 0.8913,
                  with_refunds: 0.913,
                },
                gross_total: {
                  without_refunds: 0.8851,
                  with_refunds: 0.9114,
                },
                net_total_spread: {
                  without_refunds: 0.9366,
                  with_refunds: 0.9417,
                },
                gross_total_spread: {
                  without_refunds: 0.9417,
                  with_refunds: 0.9463,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.85,
                  with_refunds: 1.85,
                },
                gross_mrr: {
                  without_refunds: 2.04,
                  with_refunds: 2.04,
                },
                net_arr: {
                  without_refunds: 0.4,
                  with_refunds: 0.4,
                },
                gross_arr: {
                  without_refunds: 0.48,
                  with_refunds: 0.48,
                },
                net_arr_spread: {
                  without_refunds: 6.33,
                  with_refunds: 6.33,
                },
                gross_arr_spread: {
                  without_refunds: 9.48,
                  with_refunds: 9.48,
                },
                net_total: {
                  without_refunds: 1.76,
                  with_refunds: 1.76,
                },
                gross_total: {
                  without_refunds: 1.95,
                  with_refunds: 1.94,
                },
                net_total_spread: {
                  without_refunds: 2.17,
                  with_refunds: 2.16,
                },
                gross_total_spread: {
                  without_refunds: 2.56,
                  with_refunds: 2.56,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.48,
                  with_refunds: 11.43,
                },
                gross_mrr: {
                  without_refunds: 12.67,
                  with_refunds: 12.63,
                },
                net_arr: {
                  without_refunds: 106.24,
                  with_refunds: 106.24,
                },
                gross_arr: {
                  without_refunds: 124.99,
                  with_refunds: 124.99,
                },
                net_arr_spread: {
                  without_refunds: 6.33,
                  with_refunds: 6.33,
                },
                gross_arr_spread: {
                  without_refunds: 9.48,
                  with_refunds: 9.48,
                },
                net_total: {
                  without_refunds: 11.65,
                  with_refunds: 11.61,
                },
                gross_total: {
                  without_refunds: 12.88,
                  with_refunds: 12.83,
                },
                net_total_spread: {
                  without_refunds: 9.89,
                  with_refunds: 9.86,
                },
                gross_total_spread: {
                  without_refunds: 11.69,
                  with_refunds: 11.66,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 48.62,
                    with_refunds: 47.52,
                  },
                  gross_mrr: {
                    without_refunds: 56.6,
                    with_refunds: 55.34,
                  },
                  net_arr: {
                    without_refunds: 76.82,
                    with_refunds: 69.84,
                  },
                  gross_arr: {
                    without_refunds: 115.28,
                    with_refunds: 104.59,
                  },
                  net_arr_spread: {
                    without_refunds: 67.32,
                    with_refunds: 67.32,
                  },
                  gross_arr_spread: {
                    without_refunds: 102.58,
                    with_refunds: 102.58,
                  },
                  net_total: {
                    without_refunds: 51.16,
                    with_refunds: 49.61,
                  },
                  gross_total: {
                    without_refunds: 61.46,
                    with_refunds: 59.49,
                  },
                  net_total_spread: {
                    without_refunds: 50.3,
                    with_refunds: 49.26,
                  },
                  gross_total_spread: {
                    without_refunds: 60.2,
                    with_refunds: 59.01,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 135.1,
                    with_refunds: 131.94,
                  },
                  gross_mrr: {
                    without_refunds: 154.03,
                    with_refunds: 150.48,
                  },
                  net_arr: {
                    without_refunds: 1181.31,
                    with_refunds: 930.24,
                  },
                  gross_arr: {
                    without_refunds: 1541.27,
                    with_refunds: 1204.84,
                  },
                  net_arr_spread: {
                    without_refunds: 73.4,
                    with_refunds: 73.4,
                  },
                  gross_arr_spread: {
                    without_refunds: 111.98,
                    with_refunds: 111.98,
                  },
                  net_total: {
                    without_refunds: 145.37,
                    with_refunds: 140.98,
                  },
                  gross_total: {
                    without_refunds: 169.15,
                    with_refunds: 163.76,
                  },
                  net_total_spread: {
                    without_refunds: 123.6,
                    with_refunds: 121.05,
                  },
                  gross_total_spread: {
                    without_refunds: 146.56,
                    with_refunds: 143.68,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1062,
                    with_refunds: 1.0812,
                  },
                  gross_mrr: {
                    without_refunds: 1.2879,
                    with_refunds: 1.2592,
                  },
                  net_arr: {
                    without_refunds: 1.7479,
                    with_refunds: 1.589,
                  },
                  gross_arr: {
                    without_refunds: 2.6231,
                    with_refunds: 2.3797,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5317,
                    with_refunds: 1.5317,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.3339,
                    with_refunds: 2.3339,
                  },
                  net_total: {
                    without_refunds: 1.164,
                    with_refunds: 1.1289,
                  },
                  gross_total: {
                    without_refunds: 1.3984,
                    with_refunds: 1.3536,
                  },
                  net_total_spread: {
                    without_refunds: 1.1444,
                    with_refunds: 1.1209,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3696,
                    with_refunds: 1.3426,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.074,
                    with_refunds: 3.0021,
                  },
                  gross_mrr: {
                    without_refunds: 3.5046,
                    with_refunds: 3.4238,
                  },
                  net_arr: {
                    without_refunds: 26.8785,
                    with_refunds: 21.1659,
                  },
                  gross_arr: {
                    without_refunds: 35.0688,
                    with_refunds: 27.4139,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6701,
                    with_refunds: 1.6701,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.5478,
                    with_refunds: 2.5478,
                  },
                  net_total: {
                    without_refunds: 3.3076,
                    with_refunds: 3.2078,
                  },
                  gross_total: {
                    without_refunds: 3.8488,
                    with_refunds: 3.7262,
                  },
                  net_total_spread: {
                    without_refunds: 2.8122,
                    with_refunds: 2.7543,
                  },
                  gross_total_spread: {
                    without_refunds: 3.3347,
                    with_refunds: 3.2691,
                  },
                },
              },
            },
          },
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 162,
                  with_refunds: 162,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 82,
                  with_refunds: 82,
                },
                total_users: {
                  without_refunds: 162,
                  with_refunds: 162,
                },
                total_spread_users: {
                  without_refunds: 244,
                  with_refunds: 244,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0484,
                  with_refunds: 0.0484,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.0453,
                  with_refunds: 0.0453,
                },
                total_spread_users: {
                  without_refunds: 0.0686,
                  with_refunds: 0.0686,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1849.76,
                  with_refunds: 1849.76,
                },
                gross_mrr: {
                  without_refunds: 2030.52,
                  with_refunds: 2030.52,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 585.86,
                  with_refunds: 585.86,
                },
                gross_arr_spread: {
                  without_refunds: 820.13,
                  with_refunds: 820.13,
                },
                net_total: {
                  without_refunds: 1849.76,
                  with_refunds: 1849.76,
                },
                gross_total: {
                  without_refunds: 2030.52,
                  with_refunds: 2030.52,
                },
                net_total_spread: {
                  without_refunds: 2435.62,
                  with_refunds: 2435.62,
                },
                gross_total_spread: {
                  without_refunds: 2850.65,
                  with_refunds: 2850.65,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0887,
                  with_refunds: 0.0911,
                },
                gross_mrr: {
                  without_refunds: 0.0732,
                  with_refunds: 0.075,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.623,
                  with_refunds: 0.623,
                },
                gross_arr_spread: {
                  without_refunds: 0.5599,
                  with_refunds: 0.5599,
                },
                net_total: {
                  without_refunds: 0.0559,
                  with_refunds: 0.0581,
                },
                gross_total: {
                  without_refunds: 0.0433,
                  with_refunds: 0.0451,
                },
                net_total_spread: {
                  without_refunds: 0.1118,
                  with_refunds: 0.1146,
                },
                gross_total_spread: {
                  without_refunds: 0.0976,
                  with_refunds: 0.0998,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.55,
                  with_refunds: 0.55,
                },
                gross_mrr: {
                  without_refunds: 0.61,
                  with_refunds: 0.61,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.43,
                  with_refunds: 2.43,
                },
                gross_arr_spread: {
                  without_refunds: 3.4,
                  with_refunds: 3.4,
                },
                net_total: {
                  without_refunds: 0.52,
                  with_refunds: 0.52,
                },
                gross_total: {
                  without_refunds: 0.57,
                  with_refunds: 0.57,
                },
                net_total_spread: {
                  without_refunds: 0.68,
                  with_refunds: 0.68,
                },
                gross_total_spread: {
                  without_refunds: 0.8,
                  with_refunds: 0.8,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.42,
                },
                gross_mrr: {
                  without_refunds: 12.53,
                  with_refunds: 12.53,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.14,
                  with_refunds: 7.14,
                },
                gross_arr_spread: {
                  without_refunds: 10,
                  with_refunds: 10,
                },
                net_total: {
                  without_refunds: 11.42,
                  with_refunds: 11.42,
                },
                gross_total: {
                  without_refunds: 12.53,
                  with_refunds: 12.53,
                },
                net_total_spread: {
                  without_refunds: 9.98,
                  with_refunds: 9.98,
                },
                gross_total_spread: {
                  without_refunds: 11.68,
                  with_refunds: 11.68,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 49.17,
                    with_refunds: 48.07,
                  },
                  gross_mrr: {
                    without_refunds: 57.21,
                    with_refunds: 55.95,
                  },
                  net_arr: {
                    without_refunds: 76.82,
                    with_refunds: 69.84,
                  },
                  gross_arr: {
                    without_refunds: 115.28,
                    with_refunds: 104.59,
                  },
                  net_arr_spread: {
                    without_refunds: 69.75,
                    with_refunds: 69.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 105.98,
                    with_refunds: 105.98,
                  },
                  net_total: {
                    without_refunds: 51.67,
                    with_refunds: 50.13,
                  },
                  gross_total: {
                    without_refunds: 62.03,
                    with_refunds: 60.06,
                  },
                  net_total_spread: {
                    without_refunds: 50.98,
                    with_refunds: 49.95,
                  },
                  gross_total_spread: {
                    without_refunds: 61,
                    with_refunds: 59.81,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 146.52,
                    with_refunds: 143.36,
                  },
                  gross_mrr: {
                    without_refunds: 166.56,
                    with_refunds: 163.01,
                  },
                  net_arr: {
                    without_refunds: 1181.31,
                    with_refunds: 930.24,
                  },
                  gross_arr: {
                    without_refunds: 1541.27,
                    with_refunds: 1204.84,
                  },
                  net_arr_spread: {
                    without_refunds: 80.55,
                    with_refunds: 80.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 121.98,
                    with_refunds: 121.98,
                  },
                  net_total: {
                    without_refunds: 156.79,
                    with_refunds: 152.4,
                  },
                  gross_total: {
                    without_refunds: 181.69,
                    with_refunds: 176.3,
                  },
                  net_total_spread: {
                    without_refunds: 133.58,
                    with_refunds: 131.03,
                  },
                  gross_total_spread: {
                    without_refunds: 158.24,
                    with_refunds: 155.36,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1188,
                    with_refunds: 1.0938,
                  },
                  gross_mrr: {
                    without_refunds: 1.3017,
                    with_refunds: 1.273,
                  },
                  net_arr: {
                    without_refunds: 1.7479,
                    with_refunds: 1.589,
                  },
                  gross_arr: {
                    without_refunds: 2.6231,
                    with_refunds: 2.3797,
                  },
                  net_arr_spread: {
                    without_refunds: 1.587,
                    with_refunds: 1.587,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.4114,
                    with_refunds: 2.4114,
                  },
                  net_total: {
                    without_refunds: 1.1757,
                    with_refunds: 1.1406,
                  },
                  gross_total: {
                    without_refunds: 1.4113,
                    with_refunds: 1.3665,
                  },
                  net_total_spread: {
                    without_refunds: 1.16,
                    with_refunds: 1.1364,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3879,
                    with_refunds: 1.3609,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.3338,
                    with_refunds: 3.2619,
                  },
                  gross_mrr: {
                    without_refunds: 3.7898,
                    with_refunds: 3.709,
                  },
                  net_arr: {
                    without_refunds: 26.8785,
                    with_refunds: 21.1659,
                  },
                  gross_arr: {
                    without_refunds: 35.0688,
                    with_refunds: 27.4139,
                  },
                  net_arr_spread: {
                    without_refunds: 1.8327,
                    with_refunds: 1.8327,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.7754,
                    with_refunds: 2.7754,
                  },
                  net_total: {
                    without_refunds: 3.5674,
                    with_refunds: 3.4676,
                  },
                  gross_total: {
                    without_refunds: 4.134,
                    with_refunds: 4.0113,
                  },
                  net_total_spread: {
                    without_refunds: 3.0394,
                    with_refunds: 2.9814,
                  },
                  gross_total_spread: {
                    without_refunds: 3.6005,
                    with_refunds: 3.5349,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 2052,
              with_refunds: 2052,
            },
            returning_users: {},
            paying_users: {
              without_refunds: 2052,
              with_refunds: 2052,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 172,
              with_refunds: 172,
            },
            returning_users: {},
            paying_users: {
              without_refunds: 172,
              with_refunds: 172,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2224,
              with_refunds: 2224,
            },
            returning_users: {},
            paying_users: {
              without_refunds: 2224,
              with_refunds: 2224,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 164529.34,
                with_refunds: 160851.84,
              },
              gross_mrr: {
                without_refunds: 191423.32,
                with_refunds: 187201.91,
              },
              net_arr: {
                without_refunds: 20204.15,
                with_refunds: 18367.1,
              },
              gross_arr: {
                without_refunds: 30319.95,
                with_refunds: 27506.78,
              },
              net_arr_spread: {
                without_refunds: 16809.15,
                with_refunds: 16809.15,
              },
              gross_arr_spread: {
                without_refunds: 25541.07,
                with_refunds: 25541.07,
              },
              net_total: {
                without_refunds: 184733.49,
                with_refunds: 179218.93,
              },
              gross_total: {
                without_refunds: 221743.27,
                with_refunds: 214708.69,
              },
              net_total_spread: {
                without_refunds: 181338.48,
                with_refunds: 177660.98,
              },
              gross_total_spread: {
                without_refunds: 216964.38,
                with_refunds: 212742.98,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 164529.34,
                with_refunds: 160851.84,
              },
              gross_mrr: {
                without_refunds: 191423.32,
                with_refunds: 187201.91,
              },
              net_arr: {
                without_refunds: 20204.15,
                with_refunds: 18367.1,
              },
              gross_arr: {
                without_refunds: 30319.95,
                with_refunds: 27506.78,
              },
              net_arr_spread: {
                without_refunds: 16809.15,
                with_refunds: 16809.15,
              },
              gross_arr_spread: {
                without_refunds: 25541.07,
                with_refunds: 25541.07,
              },
              net_total: {
                without_refunds: 184733.49,
                with_refunds: 179218.93,
              },
              gross_total: {
                without_refunds: 221743.27,
                with_refunds: 214708.69,
              },
              net_total_spread: {
                without_refunds: 181338.48,
                with_refunds: 177660.98,
              },
              gross_total_spread: {
                without_refunds: 216964.38,
                with_refunds: 212742.98,
              },
            },
          },
        },
      },
      cac: 43.95,
      mrr_ua_spend: {
        without_refunds: 147056.7,
        with_refunds: 147056.7,
      },
      arr_ua_spend: {
        without_refunds: 11558.85,
        with_refunds: 11558.85,
      },
      arr_spread_ua_spend: {
        without_refunds: 10591.95,
        with_refunds: 10591.95,
      },
      total_ua_spend: {
        without_refunds: 157121.25,
        with_refunds: 157121.25,
      },
      total_spread_ua_spend: {
        without_refunds: 156330.15,
        with_refunds: 156330.15,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 8,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 3121,
        with_refunds: 3121,
      },
      first_period_arr_users: {
        without_refunds: 266,
        with_refunds: 266,
      },
      first_period_arr_spread_users: {
        without_refunds: 239,
        with_refunds: 239,
      },
      first_period_total_users: {
        without_refunds: 3349,
        with_refunds: 3349,
      },
      first_period_total_spread_users: {
        without_refunds: 3331,
        with_refunds: 3331,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2187,
                  with_refunds: 2187,
                },
                arr_users: {
                  without_refunds: 182,
                  with_refunds: 182,
                },
                arr_spread_users: {
                  without_refunds: 170,
                  with_refunds: 170,
                },
                total_users: {
                  without_refunds: 2369,
                  with_refunds: 2369,
                },
                total_spread_users: {
                  without_refunds: 2357,
                  with_refunds: 2357,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7007,
                  with_refunds: 0.7007,
                },
                arr_users: {
                  without_refunds: 0.6842,
                  with_refunds: 0.6842,
                },
                arr_spread_users: {
                  without_refunds: 0.7113,
                  with_refunds: 0.7113,
                },
                total_users: {
                  without_refunds: 0.7074,
                  with_refunds: 0.7074,
                },
                total_spread_users: {
                  without_refunds: 0.7076,
                  with_refunds: 0.7076,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 21861.27,
                  with_refunds: 21385.94,
                },
                gross_mrr: {
                  without_refunds: 29108.96,
                  with_refunds: 28450.49,
                },
                net_arr: {
                  without_refunds: 12627.61,
                  with_refunds: 11851.17,
                },
                gross_arr: {
                  without_refunds: 19321.18,
                  with_refunds: 18104.41,
                },
                net_arr_spread: {
                  without_refunds: 981.91,
                  with_refunds: 981.91,
                },
                gross_arr_spread: {
                  without_refunds: 1500.6,
                  with_refunds: 1500.6,
                },
                net_total: {
                  without_refunds: 34488.89,
                  with_refunds: 33237.11,
                },
                gross_total: {
                  without_refunds: 48430.14,
                  with_refunds: 46554.91,
                },
                net_total_spread: {
                  without_refunds: 22843.19,
                  with_refunds: 22367.85,
                },
                gross_total_spread: {
                  without_refunds: 30609.55,
                  with_refunds: 29951.09,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7,
                  with_refunds: 6.85,
                },
                gross_mrr: {
                  without_refunds: 9.33,
                  with_refunds: 9.12,
                },
                net_arr: {
                  without_refunds: 47.47,
                  with_refunds: 44.55,
                },
                gross_arr: {
                  without_refunds: 72.64,
                  with_refunds: 68.06,
                },
                net_arr_spread: {
                  without_refunds: 4.11,
                  with_refunds: 4.11,
                },
                gross_arr_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.28,
                },
                net_total: {
                  without_refunds: 10.3,
                  with_refunds: 9.92,
                },
                gross_total: {
                  without_refunds: 14.46,
                  with_refunds: 13.9,
                },
                net_total_spread: {
                  without_refunds: 6.86,
                  with_refunds: 6.72,
                },
                gross_total_spread: {
                  without_refunds: 9.19,
                  with_refunds: 8.99,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10,
                  with_refunds: 9.78,
                },
                gross_mrr: {
                  without_refunds: 13.31,
                  with_refunds: 13.01,
                },
                net_arr: {
                  without_refunds: 69.38,
                  with_refunds: 65.12,
                },
                gross_arr: {
                  without_refunds: 106.16,
                  with_refunds: 99.47,
                },
                net_arr_spread: {
                  without_refunds: 5.78,
                  with_refunds: 5.78,
                },
                gross_arr_spread: {
                  without_refunds: 8.83,
                  with_refunds: 8.83,
                },
                net_total: {
                  without_refunds: 14.56,
                  with_refunds: 14.03,
                },
                gross_total: {
                  without_refunds: 20.44,
                  with_refunds: 19.65,
                },
                net_total_spread: {
                  without_refunds: 9.69,
                  with_refunds: 9.49,
                },
                gross_total_spread: {
                  without_refunds: 12.99,
                  with_refunds: 12.71,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 7,
                    with_refunds: 6.85,
                  },
                  gross_mrr: {
                    without_refunds: 9.33,
                    with_refunds: 9.12,
                  },
                  net_arr: {
                    without_refunds: 47.47,
                    with_refunds: 44.55,
                  },
                  gross_arr: {
                    without_refunds: 72.64,
                    with_refunds: 68.06,
                  },
                  net_arr_spread: {
                    without_refunds: 4.11,
                    with_refunds: 4.11,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.28,
                    with_refunds: 6.28,
                  },
                  net_total: {
                    without_refunds: 10.3,
                    with_refunds: 9.92,
                  },
                  gross_total: {
                    without_refunds: 14.46,
                    with_refunds: 13.9,
                  },
                  net_total_spread: {
                    without_refunds: 6.86,
                    with_refunds: 6.72,
                  },
                  gross_total_spread: {
                    without_refunds: 9.19,
                    with_refunds: 8.99,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10,
                    with_refunds: 9.78,
                  },
                  gross_mrr: {
                    without_refunds: 13.31,
                    with_refunds: 13.01,
                  },
                  net_arr: {
                    without_refunds: 69.38,
                    with_refunds: 65.12,
                  },
                  gross_arr: {
                    without_refunds: 106.16,
                    with_refunds: 99.47,
                  },
                  net_arr_spread: {
                    without_refunds: 5.78,
                    with_refunds: 5.78,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.83,
                    with_refunds: 8.83,
                  },
                  net_total: {
                    without_refunds: 14.56,
                    with_refunds: 14.03,
                  },
                  gross_total: {
                    without_refunds: 20.44,
                    with_refunds: 19.65,
                  },
                  net_total_spread: {
                    without_refunds: 9.69,
                    with_refunds: 9.49,
                  },
                  gross_total_spread: {
                    without_refunds: 12.99,
                    with_refunds: 12.71,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0689,
                    with_refunds: 0.0675,
                  },
                  gross_mrr: {
                    without_refunds: 0.0918,
                    with_refunds: 0.0897,
                  },
                  net_arr: {
                    without_refunds: 0.4673,
                    with_refunds: 0.4386,
                  },
                  gross_arr: {
                    without_refunds: 0.715,
                    with_refunds: 0.67,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0404,
                    with_refunds: 0.0404,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0618,
                    with_refunds: 0.0618,
                  },
                  net_total: {
                    without_refunds: 0.1014,
                    with_refunds: 0.0977,
                  },
                  gross_total: {
                    without_refunds: 0.1423,
                    with_refunds: 0.1368,
                  },
                  net_total_spread: {
                    without_refunds: 0.0675,
                    with_refunds: 0.0661,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0905,
                    with_refunds: 0.0885,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0984,
                    with_refunds: 0.0963,
                  },
                  gross_mrr: {
                    without_refunds: 0.131,
                    with_refunds: 0.1281,
                  },
                  net_arr: {
                    without_refunds: 0.683,
                    with_refunds: 0.641,
                  },
                  gross_arr: {
                    without_refunds: 1.045,
                    with_refunds: 0.9792,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0569,
                    with_refunds: 0.0569,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0869,
                    with_refunds: 0.0869,
                  },
                  net_total: {
                    without_refunds: 0.1433,
                    with_refunds: 0.1381,
                  },
                  gross_total: {
                    without_refunds: 0.2012,
                    with_refunds: 0.1934,
                  },
                  net_total_spread: {
                    without_refunds: 0.0954,
                    with_refunds: 0.0934,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1278,
                    with_refunds: 0.1251,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2295,
                  with_refunds: 2303,
                },
                arr_users: {
                  without_refunds: 37,
                  with_refunds: 38,
                },
                arr_spread_users: {
                  without_refunds: 202,
                  with_refunds: 202,
                },
                total_users: {
                  without_refunds: 2332,
                  with_refunds: 2341,
                },
                total_spread_users: {
                  without_refunds: 2497,
                  with_refunds: 2505,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7353,
                  with_refunds: 0.7379,
                },
                arr_users: {
                  without_refunds: 0.1391,
                  with_refunds: 0.1429,
                },
                arr_spread_users: {
                  without_refunds: 0.8452,
                  with_refunds: 0.8452,
                },
                total_users: {
                  without_refunds: 0.6963,
                  with_refunds: 0.699,
                },
                total_spread_users: {
                  without_refunds: 0.7496,
                  with_refunds: 0.752,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.0494,
                  with_refunds: 1.053,
                },
                arr_users: {
                  without_refunds: 0.2033,
                  with_refunds: 0.2088,
                },
                arr_spread_users: {
                  without_refunds: 1.1882,
                  with_refunds: 1.1882,
                },
                total_users: {
                  without_refunds: 0.9844,
                  with_refunds: 0.9882,
                },
                total_spread_users: {
                  without_refunds: 1.0594,
                  with_refunds: 1.0628,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 25010.6,
                  with_refunds: 24547.4,
                },
                gross_mrr: {
                  without_refunds: 29779.63,
                  with_refunds: 29217.15,
                },
                net_arr: {
                  without_refunds: 2576.34,
                  with_refunds: 2362.67,
                },
                gross_arr: {
                  without_refunds: 3828.22,
                  with_refunds: 3498.8,
                },
                net_arr_spread: {
                  without_refunds: 1165.42,
                  with_refunds: 1165.42,
                },
                gross_arr_spread: {
                  without_refunds: 1774.23,
                  with_refunds: 1774.23,
                },
                net_total: {
                  without_refunds: 27586.94,
                  with_refunds: 26910.07,
                },
                gross_total: {
                  without_refunds: 33607.85,
                  with_refunds: 32715.95,
                },
                net_total_spread: {
                  without_refunds: 26176.02,
                  with_refunds: 25712.82,
                },
                gross_total_spread: {
                  without_refunds: 31553.86,
                  with_refunds: 30991.38,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.1441,
                  with_refunds: 1.1478,
                },
                gross_mrr: {
                  without_refunds: 1.023,
                  with_refunds: 1.0269,
                },
                net_arr: {
                  without_refunds: 0.204,
                  with_refunds: 0.1994,
                },
                gross_arr: {
                  without_refunds: 0.1981,
                  with_refunds: 0.1933,
                },
                net_arr_spread: {
                  without_refunds: 1.1869,
                  with_refunds: 1.1869,
                },
                gross_arr_spread: {
                  without_refunds: 1.1823,
                  with_refunds: 1.1823,
                },
                net_total: {
                  without_refunds: 0.7999,
                  with_refunds: 0.8096,
                },
                gross_total: {
                  without_refunds: 0.6939,
                  with_refunds: 0.7027,
                },
                net_total_spread: {
                  without_refunds: 1.1459,
                  with_refunds: 1.1495,
                },
                gross_total_spread: {
                  without_refunds: 1.0309,
                  with_refunds: 1.0347,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.1441,
                  with_refunds: 1.1478,
                },
                gross_mrr: {
                  without_refunds: 1.023,
                  with_refunds: 1.0269,
                },
                net_arr: {
                  without_refunds: 0.204,
                  with_refunds: 0.1994,
                },
                gross_arr: {
                  without_refunds: 0.1981,
                  with_refunds: 0.1933,
                },
                net_arr_spread: {
                  without_refunds: 1.1869,
                  with_refunds: 1.1869,
                },
                gross_arr_spread: {
                  without_refunds: 1.1823,
                  with_refunds: 1.1823,
                },
                net_total: {
                  without_refunds: 0.7999,
                  with_refunds: 0.8096,
                },
                gross_total: {
                  without_refunds: 0.6939,
                  with_refunds: 0.7027,
                },
                net_total_spread: {
                  without_refunds: 1.1459,
                  with_refunds: 1.1495,
                },
                gross_total_spread: {
                  without_refunds: 1.0309,
                  with_refunds: 1.0347,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.01,
                  with_refunds: 7.87,
                },
                gross_mrr: {
                  without_refunds: 9.54,
                  with_refunds: 9.36,
                },
                net_arr: {
                  without_refunds: 9.69,
                  with_refunds: 8.88,
                },
                gross_arr: {
                  without_refunds: 14.39,
                  with_refunds: 13.15,
                },
                net_arr_spread: {
                  without_refunds: 4.88,
                  with_refunds: 4.88,
                },
                gross_arr_spread: {
                  without_refunds: 7.42,
                  with_refunds: 7.42,
                },
                net_total: {
                  without_refunds: 8.24,
                  with_refunds: 8.04,
                },
                gross_total: {
                  without_refunds: 10.04,
                  with_refunds: 9.77,
                },
                net_total_spread: {
                  without_refunds: 7.86,
                  with_refunds: 7.72,
                },
                gross_total_spread: {
                  without_refunds: 9.47,
                  with_refunds: 9.3,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.9,
                  with_refunds: 10.66,
                },
                gross_mrr: {
                  without_refunds: 12.98,
                  with_refunds: 12.69,
                },
                net_arr: {
                  without_refunds: 69.63,
                  with_refunds: 62.18,
                },
                gross_arr: {
                  without_refunds: 103.47,
                  with_refunds: 92.07,
                },
                net_arr_spread: {
                  without_refunds: 5.77,
                  with_refunds: 5.77,
                },
                gross_arr_spread: {
                  without_refunds: 8.78,
                  with_refunds: 8.78,
                },
                net_total: {
                  without_refunds: 11.83,
                  with_refunds: 11.5,
                },
                gross_total: {
                  without_refunds: 14.41,
                  with_refunds: 13.98,
                },
                net_total_spread: {
                  without_refunds: 10.48,
                  with_refunds: 10.26,
                },
                gross_total_spread: {
                  without_refunds: 12.64,
                  with_refunds: 12.37,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.02,
                    with_refunds: 14.72,
                  },
                  gross_mrr: {
                    without_refunds: 18.87,
                    with_refunds: 18.48,
                  },
                  net_arr: {
                    without_refunds: 57.16,
                    with_refunds: 53.44,
                  },
                  gross_arr: {
                    without_refunds: 87.03,
                    with_refunds: 81.22,
                  },
                  net_arr_spread: {
                    without_refunds: 8.98,
                    with_refunds: 8.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.7,
                    with_refunds: 13.7,
                  },
                  net_total: {
                    without_refunds: 18.54,
                    with_refunds: 17.96,
                  },
                  gross_total: {
                    without_refunds: 24.5,
                    with_refunds: 23.67,
                  },
                  net_total_spread: {
                    without_refunds: 14.72,
                    with_refunds: 14.43,
                  },
                  gross_total_spread: {
                    without_refunds: 18.66,
                    with_refunds: 18.3,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.89,
                    with_refunds: 20.44,
                  },
                  gross_mrr: {
                    without_refunds: 26.29,
                    with_refunds: 25.7,
                  },
                  net_arr: {
                    without_refunds: 139.01,
                    with_refunds: 127.29,
                  },
                  gross_arr: {
                    without_refunds: 209.63,
                    with_refunds: 191.55,
                  },
                  net_arr_spread: {
                    without_refunds: 11.55,
                    with_refunds: 11.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.61,
                    with_refunds: 17.61,
                  },
                  net_total: {
                    without_refunds: 26.39,
                    with_refunds: 25.53,
                  },
                  gross_total: {
                    without_refunds: 34.85,
                    with_refunds: 33.63,
                  },
                  net_total_spread: {
                    without_refunds: 20.17,
                    with_refunds: 19.75,
                  },
                  gross_total_spread: {
                    without_refunds: 25.62,
                    with_refunds: 25.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1478,
                    with_refunds: 0.1449,
                  },
                  gross_mrr: {
                    without_refunds: 0.1857,
                    with_refunds: 0.1819,
                  },
                  net_arr: {
                    without_refunds: 0.5626,
                    with_refunds: 0.526,
                  },
                  gross_arr: {
                    without_refunds: 0.8567,
                    with_refunds: 0.7994,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0884,
                    with_refunds: 0.0884,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1349,
                    with_refunds: 0.1349,
                  },
                  net_total: {
                    without_refunds: 0.1825,
                    with_refunds: 0.1768,
                  },
                  gross_total: {
                    without_refunds: 0.2411,
                    with_refunds: 0.233,
                  },
                  net_total_spread: {
                    without_refunds: 0.1449,
                    with_refunds: 0.1421,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1837,
                    with_refunds: 0.1801,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2057,
                    with_refunds: 0.2012,
                  },
                  gross_mrr: {
                    without_refunds: 0.2587,
                    with_refunds: 0.2529,
                  },
                  net_arr: {
                    without_refunds: 1.3684,
                    with_refunds: 1.253,
                  },
                  gross_arr: {
                    without_refunds: 2.0634,
                    with_refunds: 1.8855,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1136,
                    with_refunds: 0.1136,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1733,
                    with_refunds: 0.1733,
                  },
                  net_total: {
                    without_refunds: 0.2598,
                    with_refunds: 0.2513,
                  },
                  gross_total: {
                    without_refunds: 0.3431,
                    with_refunds: 0.331,
                  },
                  net_total_spread: {
                    without_refunds: 0.1986,
                    with_refunds: 0.1945,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2522,
                    with_refunds: 0.2469,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1748,
                  with_refunds: 1755,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 10,
                },
                arr_spread_users: {
                  without_refunds: 210,
                  with_refunds: 210,
                },
                total_users: {
                  without_refunds: 1755,
                  with_refunds: 1764,
                },
                total_spread_users: {
                  without_refunds: 1957,
                  with_refunds: 1964,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5601,
                  with_refunds: 0.5623,
                },
                arr_users: {
                  without_refunds: 0.0301,
                  with_refunds: 0.0376,
                },
                arr_spread_users: {
                  without_refunds: 0.8787,
                  with_refunds: 0.8787,
                },
                total_users: {
                  without_refunds: 0.524,
                  with_refunds: 0.5267,
                },
                total_spread_users: {
                  without_refunds: 0.5875,
                  with_refunds: 0.5896,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7617,
                  with_refunds: 0.762,
                },
                arr_users: {
                  without_refunds: 0.2162,
                  with_refunds: 0.2632,
                },
                arr_spread_users: {
                  without_refunds: 1.0396,
                  with_refunds: 1.0396,
                },
                total_users: {
                  without_refunds: 0.7526,
                  with_refunds: 0.7535,
                },
                total_spread_users: {
                  without_refunds: 0.7837,
                  with_refunds: 0.784,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 19655.6,
                  with_refunds: 19393.67,
                },
                gross_mrr: {
                  without_refunds: 22651.54,
                  with_refunds: 22343.75,
                },
                net_arr: {
                  without_refunds: 647.33,
                  with_refunds: 423.71,
                },
                gross_arr: {
                  without_refunds: 788.87,
                  with_refunds: 482.02,
                },
                net_arr_spread: {
                  without_refunds: 1219.37,
                  with_refunds: 1219.37,
                },
                gross_arr_spread: {
                  without_refunds: 1839.97,
                  with_refunds: 1839.97,
                },
                net_total: {
                  without_refunds: 20302.93,
                  with_refunds: 19817.38,
                },
                gross_total: {
                  without_refunds: 23440.42,
                  with_refunds: 22825.77,
                },
                net_total_spread: {
                  without_refunds: 20874.97,
                  with_refunds: 20613.03,
                },
                gross_total_spread: {
                  without_refunds: 24491.51,
                  with_refunds: 24183.72,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8991,
                  with_refunds: 0.9068,
                },
                gross_mrr: {
                  without_refunds: 0.7782,
                  with_refunds: 0.7854,
                },
                net_arr: {
                  without_refunds: 0.0513,
                  with_refunds: 0.0358,
                },
                gross_arr: {
                  without_refunds: 0.0408,
                  with_refunds: 0.0266,
                },
                net_arr_spread: {
                  without_refunds: 1.2418,
                  with_refunds: 1.2418,
                },
                gross_arr_spread: {
                  without_refunds: 1.2262,
                  with_refunds: 1.2262,
                },
                net_total: {
                  without_refunds: 0.5887,
                  with_refunds: 0.5962,
                },
                gross_total: {
                  without_refunds: 0.484,
                  with_refunds: 0.4903,
                },
                net_total_spread: {
                  without_refunds: 0.9138,
                  with_refunds: 0.9215,
                },
                gross_total_spread: {
                  without_refunds: 0.8001,
                  with_refunds: 0.8074,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7859,
                  with_refunds: 0.79,
                },
                gross_mrr: {
                  without_refunds: 0.7606,
                  with_refunds: 0.7647,
                },
                net_arr: {
                  without_refunds: 0.2513,
                  with_refunds: 0.1793,
                },
                gross_arr: {
                  without_refunds: 0.2061,
                  with_refunds: 0.1378,
                },
                net_arr_spread: {
                  without_refunds: 1.0463,
                  with_refunds: 1.0463,
                },
                gross_arr_spread: {
                  without_refunds: 1.0371,
                  with_refunds: 1.0371,
                },
                net_total: {
                  without_refunds: 0.736,
                  with_refunds: 0.7364,
                },
                gross_total: {
                  without_refunds: 0.6975,
                  with_refunds: 0.6977,
                },
                net_total_spread: {
                  without_refunds: 0.7975,
                  with_refunds: 0.8017,
                },
                gross_total_spread: {
                  without_refunds: 0.7762,
                  with_refunds: 0.7803,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.3,
                  with_refunds: 6.21,
                },
                gross_mrr: {
                  without_refunds: 7.26,
                  with_refunds: 7.16,
                },
                net_arr: {
                  without_refunds: 2.43,
                  with_refunds: 1.59,
                },
                gross_arr: {
                  without_refunds: 2.97,
                  with_refunds: 1.81,
                },
                net_arr_spread: {
                  without_refunds: 5.1,
                  with_refunds: 5.1,
                },
                gross_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                net_total: {
                  without_refunds: 6.06,
                  with_refunds: 5.92,
                },
                gross_total: {
                  without_refunds: 7,
                  with_refunds: 6.82,
                },
                net_total_spread: {
                  without_refunds: 6.27,
                  with_refunds: 6.19,
                },
                gross_total_spread: {
                  without_refunds: 7.35,
                  with_refunds: 7.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.24,
                  with_refunds: 11.05,
                },
                gross_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.73,
                },
                net_arr: {
                  without_refunds: 80.92,
                  with_refunds: 42.37,
                },
                gross_arr: {
                  without_refunds: 98.61,
                  with_refunds: 48.2,
                },
                net_arr_spread: {
                  without_refunds: 5.81,
                  with_refunds: 5.81,
                },
                gross_arr_spread: {
                  without_refunds: 8.76,
                  with_refunds: 8.76,
                },
                net_total: {
                  without_refunds: 11.57,
                  with_refunds: 11.23,
                },
                gross_total: {
                  without_refunds: 13.36,
                  with_refunds: 12.94,
                },
                net_total_spread: {
                  without_refunds: 10.67,
                  with_refunds: 10.5,
                },
                gross_total_spread: {
                  without_refunds: 12.51,
                  with_refunds: 12.31,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.32,
                    with_refunds: 20.93,
                  },
                  gross_mrr: {
                    without_refunds: 26.13,
                    with_refunds: 25.64,
                  },
                  net_arr: {
                    without_refunds: 59.59,
                    with_refunds: 55.03,
                  },
                  gross_arr: {
                    without_refunds: 89.99,
                    with_refunds: 83.03,
                  },
                  net_arr_spread: {
                    without_refunds: 14.09,
                    with_refunds: 14.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 21.4,
                    with_refunds: 21.4,
                  },
                  net_total: {
                    without_refunds: 24.6,
                    with_refunds: 23.88,
                  },
                  gross_total: {
                    without_refunds: 31.5,
                    with_refunds: 30.49,
                  },
                  net_total_spread: {
                    without_refunds: 20.98,
                    with_refunds: 20.62,
                  },
                  gross_total_spread: {
                    without_refunds: 26.01,
                    with_refunds: 25.56,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.14,
                    with_refunds: 31.49,
                  },
                  gross_mrr: {
                    without_refunds: 39.24,
                    with_refunds: 38.43,
                  },
                  net_arr: {
                    without_refunds: 219.93,
                    with_refunds: 169.66,
                  },
                  gross_arr: {
                    without_refunds: 308.23,
                    with_refunds: 239.75,
                  },
                  net_arr_spread: {
                    without_refunds: 17.35,
                    with_refunds: 17.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 26.37,
                    with_refunds: 26.37,
                  },
                  net_total: {
                    without_refunds: 37.96,
                    with_refunds: 36.76,
                  },
                  gross_total: {
                    without_refunds: 48.21,
                    with_refunds: 46.57,
                  },
                  net_total_spread: {
                    without_refunds: 30.84,
                    with_refunds: 30.25,
                  },
                  gross_total_spread: {
                    without_refunds: 38.14,
                    with_refunds: 37.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2098,
                    with_refunds: 0.206,
                  },
                  gross_mrr: {
                    without_refunds: 0.2572,
                    with_refunds: 0.2524,
                  },
                  net_arr: {
                    without_refunds: 0.5866,
                    with_refunds: 0.5417,
                  },
                  gross_arr: {
                    without_refunds: 0.8859,
                    with_refunds: 0.8173,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1387,
                    with_refunds: 0.1387,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2107,
                    with_refunds: 0.2107,
                  },
                  net_total: {
                    without_refunds: 0.2421,
                    with_refunds: 0.235,
                  },
                  gross_total: {
                    without_refunds: 0.31,
                    with_refunds: 0.3001,
                  },
                  net_total_spread: {
                    without_refunds: 0.2065,
                    with_refunds: 0.203,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2561,
                    with_refunds: 0.2516,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3164,
                    with_refunds: 0.31,
                  },
                  gross_mrr: {
                    without_refunds: 0.3863,
                    with_refunds: 0.3783,
                  },
                  net_arr: {
                    without_refunds: 2.1649,
                    with_refunds: 1.6701,
                  },
                  gross_arr: {
                    without_refunds: 3.0341,
                    with_refunds: 2.36,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1708,
                    with_refunds: 0.1708,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2596,
                    with_refunds: 0.2596,
                  },
                  net_total: {
                    without_refunds: 0.3736,
                    with_refunds: 0.3618,
                  },
                  gross_total: {
                    without_refunds: 0.4746,
                    with_refunds: 0.4584,
                  },
                  net_total_spread: {
                    without_refunds: 0.3036,
                    with_refunds: 0.2978,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3754,
                    with_refunds: 0.3681,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1454,
                  with_refunds: 1458,
                },
                arr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                total_users: {
                  without_refunds: 1458,
                  with_refunds: 1462,
                },
                total_spread_users: {
                  without_refunds: 1666,
                  with_refunds: 1670,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4659,
                  with_refunds: 0.4672,
                },
                arr_users: {
                  without_refunds: 0.015,
                  with_refunds: 0.015,
                },
                arr_spread_users: {
                  without_refunds: 0.887,
                  with_refunds: 0.887,
                },
                total_users: {
                  without_refunds: 0.4354,
                  with_refunds: 0.4365,
                },
                total_spread_users: {
                  without_refunds: 0.5002,
                  with_refunds: 0.5014,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8318,
                  with_refunds: 0.8308,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.4,
                },
                arr_spread_users: {
                  without_refunds: 1.0095,
                  with_refunds: 1.0095,
                },
                total_users: {
                  without_refunds: 0.8308,
                  with_refunds: 0.8288,
                },
                total_spread_users: {
                  without_refunds: 0.8513,
                  with_refunds: 0.8503,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 16320.16,
                  with_refunds: 16059.53,
                },
                gross_mrr: {
                  without_refunds: 18764.56,
                  with_refunds: 18475.78,
                },
                net_arr: {
                  without_refunds: 367.3,
                  with_refunds: 195.58,
                },
                gross_arr: {
                  without_refunds: 481.59,
                  with_refunds: 257.35,
                },
                net_arr_spread: {
                  without_refunds: 1235.66,
                  with_refunds: 1235.66,
                },
                gross_arr_spread: {
                  without_refunds: 1861.41,
                  with_refunds: 1861.41,
                },
                net_total: {
                  without_refunds: 16687.46,
                  with_refunds: 16255.1,
                },
                gross_total: {
                  without_refunds: 19246.16,
                  with_refunds: 18733.13,
                },
                net_total_spread: {
                  without_refunds: 17555.82,
                  with_refunds: 17295.19,
                },
                gross_total_spread: {
                  without_refunds: 20625.98,
                  with_refunds: 20337.2,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7465,
                  with_refunds: 0.7509,
                },
                gross_mrr: {
                  without_refunds: 0.6446,
                  with_refunds: 0.6494,
                },
                net_arr: {
                  without_refunds: 0.0291,
                  with_refunds: 0.0165,
                },
                gross_arr: {
                  without_refunds: 0.0249,
                  with_refunds: 0.0142,
                },
                net_arr_spread: {
                  without_refunds: 1.2584,
                  with_refunds: 1.2584,
                },
                gross_arr_spread: {
                  without_refunds: 1.2404,
                  with_refunds: 1.2404,
                },
                net_total: {
                  without_refunds: 0.4839,
                  with_refunds: 0.4891,
                },
                gross_total: {
                  without_refunds: 0.3974,
                  with_refunds: 0.4024,
                },
                net_total_spread: {
                  without_refunds: 0.7685,
                  with_refunds: 0.7732,
                },
                gross_total_spread: {
                  without_refunds: 0.6738,
                  with_refunds: 0.679,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8303,
                  with_refunds: 0.8281,
                },
                gross_mrr: {
                  without_refunds: 0.8284,
                  with_refunds: 0.8269,
                },
                net_arr: {
                  without_refunds: 0.5674,
                  with_refunds: 0.4616,
                },
                gross_arr: {
                  without_refunds: 0.6105,
                  with_refunds: 0.5339,
                },
                net_arr_spread: {
                  without_refunds: 1.0134,
                  with_refunds: 1.0134,
                },
                gross_arr_spread: {
                  without_refunds: 1.0117,
                  with_refunds: 1.0117,
                },
                net_total: {
                  without_refunds: 0.8219,
                  with_refunds: 0.8202,
                },
                gross_total: {
                  without_refunds: 0.8211,
                  with_refunds: 0.8207,
                },
                net_total_spread: {
                  without_refunds: 0.841,
                  with_refunds: 0.839,
                },
                gross_total_spread: {
                  without_refunds: 0.8422,
                  with_refunds: 0.8409,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.23,
                  with_refunds: 5.15,
                },
                gross_mrr: {
                  without_refunds: 6.01,
                  with_refunds: 5.92,
                },
                net_arr: {
                  without_refunds: 1.38,
                  with_refunds: 0.74,
                },
                gross_arr: {
                  without_refunds: 1.81,
                  with_refunds: 0.97,
                },
                net_arr_spread: {
                  without_refunds: 5.17,
                  with_refunds: 5.17,
                },
                gross_arr_spread: {
                  without_refunds: 7.79,
                  with_refunds: 7.79,
                },
                net_total: {
                  without_refunds: 4.98,
                  with_refunds: 4.85,
                },
                gross_total: {
                  without_refunds: 5.75,
                  with_refunds: 5.59,
                },
                net_total_spread: {
                  without_refunds: 5.27,
                  with_refunds: 5.19,
                },
                gross_total_spread: {
                  without_refunds: 6.19,
                  with_refunds: 6.11,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.22,
                  with_refunds: 11.01,
                },
                gross_mrr: {
                  without_refunds: 12.91,
                  with_refunds: 12.67,
                },
                net_arr: {
                  without_refunds: 91.82,
                  with_refunds: 48.89,
                },
                gross_arr: {
                  without_refunds: 120.4,
                  with_refunds: 64.34,
                },
                net_arr_spread: {
                  without_refunds: 5.83,
                  with_refunds: 5.83,
                },
                gross_arr_spread: {
                  without_refunds: 8.78,
                  with_refunds: 8.78,
                },
                net_total: {
                  without_refunds: 11.45,
                  with_refunds: 11.12,
                },
                gross_total: {
                  without_refunds: 13.2,
                  with_refunds: 12.81,
                },
                net_total_spread: {
                  without_refunds: 10.54,
                  with_refunds: 10.36,
                },
                gross_total_spread: {
                  without_refunds: 12.38,
                  with_refunds: 12.18,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 26.55,
                    with_refunds: 26.08,
                  },
                  gross_mrr: {
                    without_refunds: 32.14,
                    with_refunds: 31.56,
                  },
                  net_arr: {
                    without_refunds: 60.97,
                    with_refunds: 55.76,
                  },
                  gross_arr: {
                    without_refunds: 91.8,
                    with_refunds: 83.99,
                  },
                  net_arr_spread: {
                    without_refunds: 19.26,
                    with_refunds: 19.26,
                  },
                  gross_arr_spread: {
                    without_refunds: 29.19,
                    with_refunds: 29.19,
                  },
                  net_total: {
                    without_refunds: 29.58,
                    with_refunds: 28.73,
                  },
                  gross_total: {
                    without_refunds: 37.24,
                    with_refunds: 36.08,
                  },
                  net_total_spread: {
                    without_refunds: 26.25,
                    with_refunds: 25.81,
                  },
                  gross_total_spread: {
                    without_refunds: 32.21,
                    with_refunds: 31.66,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 43.36,
                    with_refunds: 42.5,
                  },
                  gross_mrr: {
                    without_refunds: 52.15,
                    with_refunds: 51.1,
                  },
                  net_arr: {
                    without_refunds: 311.75,
                    with_refunds: 218.56,
                  },
                  gross_arr: {
                    without_refunds: 428.63,
                    with_refunds: 304.09,
                  },
                  net_arr_spread: {
                    without_refunds: 23.18,
                    with_refunds: 23.18,
                  },
                  gross_arr_spread: {
                    without_refunds: 35.15,
                    with_refunds: 35.15,
                  },
                  net_total: {
                    without_refunds: 49.4,
                    with_refunds: 47.88,
                  },
                  gross_total: {
                    without_refunds: 61.41,
                    with_refunds: 59.38,
                  },
                  net_total_spread: {
                    without_refunds: 41.38,
                    with_refunds: 40.61,
                  },
                  gross_total_spread: {
                    without_refunds: 50.52,
                    with_refunds: 49.57,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2613,
                    with_refunds: 0.2567,
                  },
                  gross_mrr: {
                    without_refunds: 0.3164,
                    with_refunds: 0.3106,
                  },
                  net_arr: {
                    without_refunds: 0.6002,
                    with_refunds: 0.5489,
                  },
                  gross_arr: {
                    without_refunds: 0.9037,
                    with_refunds: 0.8268,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1896,
                    with_refunds: 0.1896,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2873,
                    with_refunds: 0.2873,
                  },
                  net_total: {
                    without_refunds: 0.2912,
                    with_refunds: 0.2828,
                  },
                  gross_total: {
                    without_refunds: 0.3666,
                    with_refunds: 0.3551,
                  },
                  net_total_spread: {
                    without_refunds: 0.2584,
                    with_refunds: 0.2541,
                  },
                  gross_total_spread: {
                    without_refunds: 0.317,
                    with_refunds: 0.3117,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4268,
                    with_refunds: 0.4184,
                  },
                  gross_mrr: {
                    without_refunds: 0.5133,
                    with_refunds: 0.503,
                  },
                  net_arr: {
                    without_refunds: 3.0687,
                    with_refunds: 2.1514,
                  },
                  gross_arr: {
                    without_refunds: 4.2192,
                    with_refunds: 2.9933,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2282,
                    with_refunds: 0.2282,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.346,
                    with_refunds: 0.346,
                  },
                  net_total: {
                    without_refunds: 0.4863,
                    with_refunds: 0.4713,
                  },
                  gross_total: {
                    without_refunds: 0.6045,
                    with_refunds: 0.5845,
                  },
                  net_total_spread: {
                    without_refunds: 0.4073,
                    with_refunds: 0.3997,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4973,
                    with_refunds: 0.4879,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1221,
                  with_refunds: 1225,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                total_users: {
                  without_refunds: 1221,
                  with_refunds: 1225,
                },
                total_spread_users: {
                  without_refunds: 1433,
                  with_refunds: 1437,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3912,
                  with_refunds: 0.3925,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.887,
                  with_refunds: 0.887,
                },
                total_users: {
                  without_refunds: 0.3646,
                  with_refunds: 0.3658,
                },
                total_spread_users: {
                  without_refunds: 0.4302,
                  with_refunds: 0.4314,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8398,
                  with_refunds: 0.8402,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8374,
                  with_refunds: 0.8379,
                },
                total_spread_users: {
                  without_refunds: 0.8601,
                  with_refunds: 0.8605,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13808.58,
                  with_refunds: 13673.06,
                },
                gross_mrr: {
                  without_refunds: 15808.71,
                  with_refunds: 15659.19,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1235.66,
                  with_refunds: 1235.66,
                },
                gross_arr_spread: {
                  without_refunds: 1861.41,
                  with_refunds: 1861.41,
                },
                net_total: {
                  without_refunds: 13808.58,
                  with_refunds: 13673.06,
                },
                gross_total: {
                  without_refunds: 15808.71,
                  with_refunds: 15659.19,
                },
                net_total_spread: {
                  without_refunds: 15044.25,
                  with_refunds: 14908.73,
                },
                gross_total_spread: {
                  without_refunds: 17670.12,
                  with_refunds: 17520.6,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6393,
                },
                gross_mrr: {
                  without_refunds: 0.5431,
                  with_refunds: 0.5504,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2584,
                  with_refunds: 1.2584,
                },
                gross_arr_spread: {
                  without_refunds: 1.2404,
                  with_refunds: 1.2404,
                },
                net_total: {
                  without_refunds: 0.4004,
                  with_refunds: 0.4114,
                },
                gross_total: {
                  without_refunds: 0.3264,
                  with_refunds: 0.3364,
                },
                net_total_spread: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6665,
                },
                gross_total_spread: {
                  without_refunds: 0.5773,
                  with_refunds: 0.585,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8461,
                  with_refunds: 0.8514,
                },
                gross_mrr: {
                  without_refunds: 0.8425,
                  with_refunds: 0.8476,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8275,
                  with_refunds: 0.8412,
                },
                gross_total: {
                  without_refunds: 0.8214,
                  with_refunds: 0.8359,
                },
                net_total_spread: {
                  without_refunds: 0.8569,
                  with_refunds: 0.862,
                },
                gross_total_spread: {
                  without_refunds: 0.8567,
                  with_refunds: 0.8615,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.42,
                  with_refunds: 4.38,
                },
                gross_mrr: {
                  without_refunds: 5.07,
                  with_refunds: 5.02,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 5.17,
                  with_refunds: 5.17,
                },
                gross_arr_spread: {
                  without_refunds: 7.79,
                  with_refunds: 7.79,
                },
                net_total: {
                  without_refunds: 4.12,
                  with_refunds: 4.08,
                },
                gross_total: {
                  without_refunds: 4.72,
                  with_refunds: 4.68,
                },
                net_total_spread: {
                  without_refunds: 4.52,
                  with_refunds: 4.48,
                },
                gross_total_spread: {
                  without_refunds: 5.3,
                  with_refunds: 5.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.31,
                  with_refunds: 11.16,
                },
                gross_mrr: {
                  without_refunds: 12.95,
                  with_refunds: 12.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 5.83,
                  with_refunds: 5.83,
                },
                gross_arr_spread: {
                  without_refunds: 8.78,
                  with_refunds: 8.78,
                },
                net_total: {
                  without_refunds: 11.31,
                  with_refunds: 11.16,
                },
                gross_total: {
                  without_refunds: 12.95,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.5,
                  with_refunds: 10.37,
                },
                gross_total_spread: {
                  without_refunds: 12.33,
                  with_refunds: 12.19,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.97,
                    with_refunds: 30.46,
                  },
                  gross_mrr: {
                    without_refunds: 37.2,
                    with_refunds: 36.57,
                  },
                  net_arr: {
                    without_refunds: 60.97,
                    with_refunds: 55.76,
                  },
                  gross_arr: {
                    without_refunds: 91.8,
                    with_refunds: 83.99,
                  },
                  net_arr_spread: {
                    without_refunds: 24.43,
                    with_refunds: 24.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.98,
                    with_refunds: 36.98,
                  },
                  net_total: {
                    without_refunds: 33.7,
                    with_refunds: 32.81,
                  },
                  gross_total: {
                    without_refunds: 41.96,
                    with_refunds: 40.76,
                  },
                  net_total_spread: {
                    without_refunds: 30.77,
                    with_refunds: 30.29,
                  },
                  gross_total_spread: {
                    without_refunds: 37.51,
                    with_refunds: 36.92,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 54.67,
                    with_refunds: 53.66,
                  },
                  gross_mrr: {
                    without_refunds: 65.1,
                    with_refunds: 63.88,
                  },
                  net_arr: {
                    without_refunds: 311.75,
                    with_refunds: 218.56,
                  },
                  gross_arr: {
                    without_refunds: 428.63,
                    with_refunds: 304.09,
                  },
                  net_arr_spread: {
                    without_refunds: 29.01,
                    with_refunds: 29.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 43.93,
                    with_refunds: 43.93,
                  },
                  net_total: {
                    without_refunds: 60.71,
                    with_refunds: 59.04,
                  },
                  gross_total: {
                    without_refunds: 74.36,
                    with_refunds: 72.16,
                  },
                  net_total_spread: {
                    without_refunds: 51.88,
                    with_refunds: 50.98,
                  },
                  gross_total_spread: {
                    without_refunds: 62.85,
                    with_refunds: 61.76,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3048,
                    with_refunds: 0.2998,
                  },
                  gross_mrr: {
                    without_refunds: 0.3662,
                    with_refunds: 0.36,
                  },
                  net_arr: {
                    without_refunds: 0.6002,
                    with_refunds: 0.5489,
                  },
                  gross_arr: {
                    without_refunds: 0.9037,
                    with_refunds: 0.8268,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2404,
                    with_refunds: 0.2404,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.364,
                    with_refunds: 0.364,
                  },
                  net_total: {
                    without_refunds: 0.3318,
                    with_refunds: 0.323,
                  },
                  gross_total: {
                    without_refunds: 0.4131,
                    with_refunds: 0.4012,
                  },
                  net_total_spread: {
                    without_refunds: 0.3029,
                    with_refunds: 0.2982,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3692,
                    with_refunds: 0.3634,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5382,
                    with_refunds: 0.5282,
                  },
                  gross_mrr: {
                    without_refunds: 0.6408,
                    with_refunds: 0.6288,
                  },
                  net_arr: {
                    without_refunds: 3.0687,
                    with_refunds: 2.1514,
                  },
                  gross_arr: {
                    without_refunds: 4.2192,
                    with_refunds: 2.9933,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2856,
                    with_refunds: 0.2856,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4325,
                    with_refunds: 0.4325,
                  },
                  net_total: {
                    without_refunds: 0.5976,
                    with_refunds: 0.5812,
                  },
                  gross_total: {
                    without_refunds: 0.732,
                    with_refunds: 0.7103,
                  },
                  net_total_spread: {
                    without_refunds: 0.5107,
                    with_refunds: 0.5018,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6187,
                    with_refunds: 0.608,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 924,
                  with_refunds: 928,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 215,
                  with_refunds: 215,
                },
                total_users: {
                  without_refunds: 929,
                  with_refunds: 933,
                },
                total_spread_users: {
                  without_refunds: 1139,
                  with_refunds: 1143,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2961,
                  with_refunds: 0.2973,
                },
                arr_users: {
                  without_refunds: 0.0188,
                  with_refunds: 0.0188,
                },
                arr_spread_users: {
                  without_refunds: 0.8996,
                  with_refunds: 0.8996,
                },
                total_users: {
                  without_refunds: 0.2774,
                  with_refunds: 0.2786,
                },
                total_spread_users: {
                  without_refunds: 0.3419,
                  with_refunds: 0.3431,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7568,
                  with_refunds: 0.7576,
                },
                arr_users: {
                  without_refunds: 1.25,
                  with_refunds: 1.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0142,
                  with_refunds: 1.0142,
                },
                total_users: {
                  without_refunds: 0.7609,
                  with_refunds: 0.7616,
                },
                total_spread_users: {
                  without_refunds: 0.7948,
                  with_refunds: 0.7954,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10550.52,
                  with_refunds: 10219.62,
                },
                gross_mrr: {
                  without_refunds: 11859.09,
                  with_refunds: 11504.12,
                },
                net_arr: {
                  without_refunds: 567.79,
                  with_refunds: 356.97,
                },
                gross_arr: {
                  without_refunds: 668,
                  with_refunds: 419.97,
                },
                net_arr_spread: {
                  without_refunds: 1265.41,
                  with_refunds: 1265.41,
                },
                gross_arr_spread: {
                  without_refunds: 1896.41,
                  with_refunds: 1896.41,
                },
                net_total: {
                  without_refunds: 11118.31,
                  with_refunds: 10576.59,
                },
                gross_total: {
                  without_refunds: 12527.09,
                  with_refunds: 11924.09,
                },
                net_total_spread: {
                  without_refunds: 11815.93,
                  with_refunds: 11485.03,
                },
                gross_total_spread: {
                  without_refunds: 13755.5,
                  with_refunds: 13400.53,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4826,
                  with_refunds: 0.4779,
                },
                gross_mrr: {
                  without_refunds: 0.4074,
                  with_refunds: 0.4044,
                },
                net_arr: {
                  without_refunds: 0.045,
                  with_refunds: 0.0301,
                },
                gross_arr: {
                  without_refunds: 0.0346,
                  with_refunds: 0.0232,
                },
                net_arr_spread: {
                  without_refunds: 1.2887,
                  with_refunds: 1.2887,
                },
                gross_arr_spread: {
                  without_refunds: 1.2638,
                  with_refunds: 1.2638,
                },
                net_total: {
                  without_refunds: 0.3224,
                  with_refunds: 0.3182,
                },
                gross_total: {
                  without_refunds: 0.2587,
                  with_refunds: 0.2561,
                },
                net_total_spread: {
                  without_refunds: 0.5173,
                  with_refunds: 0.5135,
                },
                gross_total_spread: {
                  without_refunds: 0.4494,
                  with_refunds: 0.4474,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7641,
                  with_refunds: 0.7474,
                },
                gross_mrr: {
                  without_refunds: 0.7502,
                  with_refunds: 0.7347,
                },
                net_arr: {
                  without_refunds: 1.5459,
                  with_refunds: 1.8252,
                },
                gross_arr: {
                  without_refunds: 1.3871,
                  with_refunds: 1.6319,
                },
                net_arr_spread: {
                  without_refunds: 1.0241,
                  with_refunds: 1.0241,
                },
                gross_arr_spread: {
                  without_refunds: 1.0188,
                  with_refunds: 1.0188,
                },
                net_total: {
                  without_refunds: 0.8052,
                  with_refunds: 0.7735,
                },
                gross_total: {
                  without_refunds: 0.7924,
                  with_refunds: 0.7615,
                },
                net_total_spread: {
                  without_refunds: 0.7854,
                  with_refunds: 0.7704,
                },
                gross_total_spread: {
                  without_refunds: 0.7785,
                  with_refunds: 0.7648,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.38,
                  with_refunds: 3.27,
                },
                gross_mrr: {
                  without_refunds: 3.8,
                  with_refunds: 3.69,
                },
                net_arr: {
                  without_refunds: 2.13,
                  with_refunds: 1.34,
                },
                gross_arr: {
                  without_refunds: 2.51,
                  with_refunds: 1.58,
                },
                net_arr_spread: {
                  without_refunds: 5.29,
                  with_refunds: 5.29,
                },
                gross_arr_spread: {
                  without_refunds: 7.93,
                  with_refunds: 7.93,
                },
                net_total: {
                  without_refunds: 3.32,
                  with_refunds: 3.16,
                },
                gross_total: {
                  without_refunds: 3.74,
                  with_refunds: 3.56,
                },
                net_total_spread: {
                  without_refunds: 3.55,
                  with_refunds: 3.45,
                },
                gross_total_spread: {
                  without_refunds: 4.13,
                  with_refunds: 4.02,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.01,
                },
                gross_mrr: {
                  without_refunds: 12.83,
                  with_refunds: 12.4,
                },
                net_arr: {
                  without_refunds: 113.56,
                  with_refunds: 71.39,
                },
                gross_arr: {
                  without_refunds: 133.6,
                  with_refunds: 83.99,
                },
                net_arr_spread: {
                  without_refunds: 5.89,
                  with_refunds: 5.89,
                },
                gross_arr_spread: {
                  without_refunds: 8.82,
                  with_refunds: 8.82,
                },
                net_total: {
                  without_refunds: 11.97,
                  with_refunds: 11.34,
                },
                gross_total: {
                  without_refunds: 13.48,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.37,
                  with_refunds: 10.05,
                },
                gross_total_spread: {
                  without_refunds: 12.08,
                  with_refunds: 11.72,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.35,
                    with_refunds: 33.73,
                  },
                  gross_mrr: {
                    without_refunds: 41,
                    with_refunds: 40.26,
                  },
                  net_arr: {
                    without_refunds: 63.11,
                    with_refunds: 57.11,
                  },
                  gross_arr: {
                    without_refunds: 94.32,
                    with_refunds: 85.57,
                  },
                  net_arr_spread: {
                    without_refunds: 29.72,
                    with_refunds: 29.72,
                  },
                  gross_arr_spread: {
                    without_refunds: 44.91,
                    with_refunds: 44.91,
                  },
                  net_total: {
                    without_refunds: 37.02,
                    with_refunds: 35.97,
                  },
                  gross_total: {
                    without_refunds: 45.7,
                    with_refunds: 44.32,
                  },
                  net_total_spread: {
                    without_refunds: 34.32,
                    with_refunds: 33.74,
                  },
                  gross_total_spread: {
                    without_refunds: 41.64,
                    with_refunds: 40.94,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 66.09,
                    with_refunds: 64.68,
                  },
                  gross_mrr: {
                    without_refunds: 77.93,
                    with_refunds: 76.28,
                  },
                  net_arr: {
                    without_refunds: 425.31,
                    with_refunds: 289.95,
                  },
                  gross_arr: {
                    without_refunds: 562.23,
                    with_refunds: 388.08,
                  },
                  net_arr_spread: {
                    without_refunds: 34.89,
                    with_refunds: 34.89,
                  },
                  gross_arr_spread: {
                    without_refunds: 52.75,
                    with_refunds: 52.75,
                  },
                  net_total: {
                    without_refunds: 72.68,
                    with_refunds: 70.38,
                  },
                  gross_total: {
                    without_refunds: 87.84,
                    with_refunds: 84.94,
                  },
                  net_total_spread: {
                    without_refunds: 62.25,
                    with_refunds: 61.03,
                  },
                  gross_total_spread: {
                    without_refunds: 74.93,
                    with_refunds: 73.49,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3381,
                    with_refunds: 0.332,
                  },
                  gross_mrr: {
                    without_refunds: 0.4036,
                    with_refunds: 0.3963,
                  },
                  net_arr: {
                    without_refunds: 0.6212,
                    with_refunds: 0.5621,
                  },
                  gross_arr: {
                    without_refunds: 0.9284,
                    with_refunds: 0.8423,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2926,
                    with_refunds: 0.2926,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4421,
                    with_refunds: 0.4421,
                  },
                  net_total: {
                    without_refunds: 0.3644,
                    with_refunds: 0.3541,
                  },
                  gross_total: {
                    without_refunds: 0.4499,
                    with_refunds: 0.4362,
                  },
                  net_total_spread: {
                    without_refunds: 0.3378,
                    with_refunds: 0.3321,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4099,
                    with_refunds: 0.403,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6506,
                    with_refunds: 0.6366,
                  },
                  gross_mrr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.7508,
                  },
                  net_arr: {
                    without_refunds: 4.1866,
                    with_refunds: 2.8541,
                  },
                  gross_arr: {
                    without_refunds: 5.5343,
                    with_refunds: 3.8201,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3435,
                    with_refunds: 0.3435,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5193,
                    with_refunds: 0.5193,
                  },
                  net_total: {
                    without_refunds: 0.7154,
                    with_refunds: 0.6927,
                  },
                  gross_total: {
                    without_refunds: 0.8647,
                    with_refunds: 0.8361,
                  },
                  net_total_spread: {
                    without_refunds: 0.6128,
                    with_refunds: 0.6007,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7375,
                    with_refunds: 0.7234,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 806,
                  with_refunds: 811,
                },
                arr_users: {
                  without_refunds: 16,
                  with_refunds: 16,
                },
                arr_spread_users: {
                  without_refunds: 226,
                  with_refunds: 226,
                },
                total_users: {
                  without_refunds: 820,
                  with_refunds: 821,
                },
                total_spread_users: {
                  without_refunds: 1030,
                  with_refunds: 1031,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2583,
                  with_refunds: 0.2599,
                },
                arr_users: {
                  without_refunds: 0.0602,
                  with_refunds: 0.0602,
                },
                arr_spread_users: {
                  without_refunds: 0.9456,
                  with_refunds: 0.9456,
                },
                total_users: {
                  without_refunds: 0.2448,
                  with_refunds: 0.2451,
                },
                total_spread_users: {
                  without_refunds: 0.3092,
                  with_refunds: 0.3095,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8723,
                  with_refunds: 0.8739,
                },
                arr_users: {
                  without_refunds: 3.2,
                  with_refunds: 3.2,
                },
                arr_spread_users: {
                  without_refunds: 1.0512,
                  with_refunds: 1.0512,
                },
                total_users: {
                  without_refunds: 0.8827,
                  with_refunds: 0.88,
                },
                total_spread_users: {
                  without_refunds: 0.9043,
                  with_refunds: 0.902,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9257.97,
                  with_refunds: 9038.48,
                },
                gross_mrr: {
                  without_refunds: 10363.14,
                  with_refunds: 10112.91,
                },
                net_arr: {
                  without_refunds: 1930.16,
                  with_refunds: 1496.83,
                },
                gross_arr: {
                  without_refunds: 2518.03,
                  with_refunds: 1927.59,
                },
                net_arr_spread: {
                  without_refunds: 1370.75,
                  with_refunds: 1370.75,
                },
                gross_arr_spread: {
                  without_refunds: 2031.95,
                  with_refunds: 2031.95,
                },
                net_total: {
                  without_refunds: 11188.13,
                  with_refunds: 10535.31,
                },
                gross_total: {
                  without_refunds: 12881.17,
                  with_refunds: 12040.5,
                },
                net_total_spread: {
                  without_refunds: 10628.72,
                  with_refunds: 10409.23,
                },
                gross_total_spread: {
                  without_refunds: 12395.09,
                  with_refunds: 12144.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4235,
                  with_refunds: 0.4226,
                },
                gross_mrr: {
                  without_refunds: 0.356,
                  with_refunds: 0.3555,
                },
                net_arr: {
                  without_refunds: 0.1529,
                  with_refunds: 0.1263,
                },
                gross_arr: {
                  without_refunds: 0.1303,
                  with_refunds: 0.1065,
                },
                net_arr_spread: {
                  without_refunds: 1.396,
                  with_refunds: 1.396,
                },
                gross_arr_spread: {
                  without_refunds: 1.3541,
                  with_refunds: 1.3541,
                },
                net_total: {
                  without_refunds: 0.3244,
                  with_refunds: 0.317,
                },
                gross_total: {
                  without_refunds: 0.266,
                  with_refunds: 0.2586,
                },
                net_total_spread: {
                  without_refunds: 0.4653,
                  with_refunds: 0.4654,
                },
                gross_total_spread: {
                  without_refunds: 0.4049,
                  with_refunds: 0.4055,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8775,
                  with_refunds: 0.8844,
                },
                gross_mrr: {
                  without_refunds: 0.8739,
                  with_refunds: 0.8791,
                },
                net_arr: {
                  without_refunds: 3.3994,
                  with_refunds: 4.1931,
                },
                gross_arr: {
                  without_refunds: 3.7695,
                  with_refunds: 4.5898,
                },
                net_arr_spread: {
                  without_refunds: 1.0832,
                  with_refunds: 1.0832,
                },
                gross_arr_spread: {
                  without_refunds: 1.0715,
                  with_refunds: 1.0715,
                },
                net_total: {
                  without_refunds: 1.0063,
                  with_refunds: 0.9961,
                },
                gross_total: {
                  without_refunds: 1.0283,
                  with_refunds: 1.0098,
                },
                net_total_spread: {
                  without_refunds: 0.8995,
                  with_refunds: 0.9063,
                },
                gross_total_spread: {
                  without_refunds: 0.9011,
                  with_refunds: 0.9063,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.97,
                  with_refunds: 2.9,
                },
                gross_mrr: {
                  without_refunds: 3.32,
                  with_refunds: 3.24,
                },
                net_arr: {
                  without_refunds: 7.26,
                  with_refunds: 5.63,
                },
                gross_arr: {
                  without_refunds: 9.47,
                  with_refunds: 7.25,
                },
                net_arr_spread: {
                  without_refunds: 5.74,
                  with_refunds: 5.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.5,
                  with_refunds: 8.5,
                },
                net_total: {
                  without_refunds: 3.34,
                  with_refunds: 3.15,
                },
                gross_total: {
                  without_refunds: 3.85,
                  with_refunds: 3.6,
                },
                net_total_spread: {
                  without_refunds: 3.19,
                  with_refunds: 3.12,
                },
                gross_total_spread: {
                  without_refunds: 3.72,
                  with_refunds: 3.65,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.49,
                  with_refunds: 11.14,
                },
                gross_mrr: {
                  without_refunds: 12.86,
                  with_refunds: 12.47,
                },
                net_arr: {
                  without_refunds: 120.64,
                  with_refunds: 93.55,
                },
                gross_arr: {
                  without_refunds: 157.38,
                  with_refunds: 120.47,
                },
                net_arr_spread: {
                  without_refunds: 6.07,
                  with_refunds: 6.07,
                },
                gross_arr_spread: {
                  without_refunds: 8.99,
                  with_refunds: 8.99,
                },
                net_total: {
                  without_refunds: 13.64,
                  with_refunds: 12.83,
                },
                gross_total: {
                  without_refunds: 15.71,
                  with_refunds: 14.67,
                },
                net_total_spread: {
                  without_refunds: 10.32,
                  with_refunds: 10.1,
                },
                gross_total_spread: {
                  without_refunds: 12.03,
                  with_refunds: 11.78,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.32,
                    with_refunds: 36.63,
                  },
                  gross_mrr: {
                    without_refunds: 44.32,
                    with_refunds: 43.5,
                  },
                  net_arr: {
                    without_refunds: 70.36,
                    with_refunds: 62.73,
                  },
                  gross_arr: {
                    without_refunds: 103.78,
                    with_refunds: 92.82,
                  },
                  net_arr_spread: {
                    without_refunds: 35.46,
                    with_refunds: 35.46,
                  },
                  gross_arr_spread: {
                    without_refunds: 53.41,
                    with_refunds: 53.41,
                  },
                  net_total: {
                    without_refunds: 40.36,
                    with_refunds: 39.12,
                  },
                  gross_total: {
                    without_refunds: 49.55,
                    with_refunds: 47.91,
                  },
                  net_total_spread: {
                    without_refunds: 37.51,
                    with_refunds: 36.86,
                  },
                  gross_total_spread: {
                    without_refunds: 45.36,
                    with_refunds: 44.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 77.58,
                    with_refunds: 75.82,
                  },
                  gross_mrr: {
                    without_refunds: 90.79,
                    with_refunds: 88.75,
                  },
                  net_arr: {
                    without_refunds: 545.95,
                    with_refunds: 383.5,
                  },
                  gross_arr: {
                    without_refunds: 719.61,
                    with_refunds: 508.56,
                  },
                  net_arr_spread: {
                    without_refunds: 40.96,
                    with_refunds: 40.96,
                  },
                  gross_arr_spread: {
                    without_refunds: 61.74,
                    with_refunds: 61.74,
                  },
                  net_total: {
                    without_refunds: 86.32,
                    with_refunds: 83.21,
                  },
                  gross_total: {
                    without_refunds: 103.55,
                    with_refunds: 99.61,
                  },
                  net_total_spread: {
                    without_refunds: 72.57,
                    with_refunds: 71.13,
                  },
                  gross_total_spread: {
                    without_refunds: 86.96,
                    with_refunds: 85.27,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3673,
                    with_refunds: 0.3606,
                  },
                  gross_mrr: {
                    without_refunds: 0.4363,
                    with_refunds: 0.4282,
                  },
                  net_arr: {
                    without_refunds: 0.6926,
                    with_refunds: 0.6175,
                  },
                  gross_arr: {
                    without_refunds: 1.0216,
                    with_refunds: 0.9137,
                  },
                  net_arr_spread: {
                    without_refunds: 0.349,
                    with_refunds: 0.349,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5258,
                    with_refunds: 0.5258,
                  },
                  net_total: {
                    without_refunds: 0.3973,
                    with_refunds: 0.3851,
                  },
                  gross_total: {
                    without_refunds: 0.4877,
                    with_refunds: 0.4716,
                  },
                  net_total_spread: {
                    without_refunds: 0.3692,
                    with_refunds: 0.3629,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4465,
                    with_refunds: 0.4389,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7636,
                    with_refunds: 0.7464,
                  },
                  gross_mrr: {
                    without_refunds: 0.8937,
                    with_refunds: 0.8736,
                  },
                  net_arr: {
                    without_refunds: 5.374,
                    with_refunds: 3.775,
                  },
                  gross_arr: {
                    without_refunds: 7.0835,
                    with_refunds: 5.006,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4032,
                    with_refunds: 0.4032,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6078,
                    with_refunds: 0.6078,
                  },
                  net_total: {
                    without_refunds: 0.8497,
                    with_refunds: 0.8191,
                  },
                  gross_total: {
                    without_refunds: 1.0193,
                    with_refunds: 0.9805,
                  },
                  net_total_spread: {
                    without_refunds: 0.7143,
                    with_refunds: 0.7001,
                  },
                  gross_total_spread: {
                    without_refunds: 0.856,
                    with_refunds: 0.8393,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 723,
                  with_refunds: 726,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 9,
                },
                arr_spread_users: {
                  without_refunds: 232,
                  with_refunds: 232,
                },
                total_users: {
                  without_refunds: 729,
                  with_refunds: 732,
                },
                total_spread_users: {
                  without_refunds: 954,
                  with_refunds: 956,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2317,
                  with_refunds: 0.2326,
                },
                arr_users: {
                  without_refunds: 0.0301,
                  with_refunds: 0.0338,
                },
                arr_spread_users: {
                  without_refunds: 0.9707,
                  with_refunds: 0.9707,
                },
                total_users: {
                  without_refunds: 0.2177,
                  with_refunds: 0.2186,
                },
                total_spread_users: {
                  without_refunds: 0.2864,
                  with_refunds: 0.287,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.897,
                  with_refunds: 0.8952,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.5625,
                },
                arr_spread_users: {
                  without_refunds: 1.0265,
                  with_refunds: 1.0265,
                },
                total_users: {
                  without_refunds: 0.889,
                  with_refunds: 0.8916,
                },
                total_spread_users: {
                  without_refunds: 0.9262,
                  with_refunds: 0.9273,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8270.74,
                  with_refunds: 8099.22,
                },
                gross_mrr: {
                  without_refunds: 9214.9,
                  with_refunds: 9028.23,
                },
                net_arr: {
                  without_refunds: 956.08,
                  with_refunds: 604.77,
                },
                gross_arr: {
                  without_refunds: 1155.72,
                  with_refunds: 735.75,
                },
                net_arr_spread: {
                  without_refunds: 1431.06,
                  with_refunds: 1431.06,
                },
                gross_arr_spread: {
                  without_refunds: 2104.93,
                  with_refunds: 2104.93,
                },
                net_total: {
                  without_refunds: 9226.82,
                  with_refunds: 8703.99,
                },
                gross_total: {
                  without_refunds: 10370.62,
                  with_refunds: 9763.98,
                },
                net_total_spread: {
                  without_refunds: 9701.8,
                  with_refunds: 9530.28,
                },
                gross_total_spread: {
                  without_refunds: 11319.83,
                  with_refunds: 11133.16,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3783,
                  with_refunds: 0.3787,
                },
                gross_mrr: {
                  without_refunds: 0.3166,
                  with_refunds: 0.3173,
                },
                net_arr: {
                  without_refunds: 0.0757,
                  with_refunds: 0.051,
                },
                gross_arr: {
                  without_refunds: 0.0598,
                  with_refunds: 0.0406,
                },
                net_arr_spread: {
                  without_refunds: 1.4574,
                  with_refunds: 1.4574,
                },
                gross_arr_spread: {
                  without_refunds: 1.4027,
                  with_refunds: 1.4027,
                },
                net_total: {
                  without_refunds: 0.2675,
                  with_refunds: 0.2619,
                },
                gross_total: {
                  without_refunds: 0.2141,
                  with_refunds: 0.2097,
                },
                net_total_spread: {
                  without_refunds: 0.4247,
                  with_refunds: 0.4261,
                },
                gross_total_spread: {
                  without_refunds: 0.3698,
                  with_refunds: 0.3717,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8934,
                  with_refunds: 0.8961,
                },
                gross_mrr: {
                  without_refunds: 0.8892,
                  with_refunds: 0.8927,
                },
                net_arr: {
                  without_refunds: 0.4953,
                  with_refunds: 0.404,
                },
                gross_arr: {
                  without_refunds: 0.459,
                  with_refunds: 0.3817,
                },
                net_arr_spread: {
                  without_refunds: 1.044,
                  with_refunds: 1.044,
                },
                gross_arr_spread: {
                  without_refunds: 1.0359,
                  with_refunds: 1.0359,
                },
                net_total: {
                  without_refunds: 0.8247,
                  with_refunds: 0.8262,
                },
                gross_total: {
                  without_refunds: 0.8051,
                  with_refunds: 0.8109,
                },
                net_total_spread: {
                  without_refunds: 0.9128,
                  with_refunds: 0.9156,
                },
                gross_total_spread: {
                  without_refunds: 0.9133,
                  with_refunds: 0.9167,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.65,
                  with_refunds: 2.6,
                },
                gross_mrr: {
                  without_refunds: 2.95,
                  with_refunds: 2.89,
                },
                net_arr: {
                  without_refunds: 3.59,
                  with_refunds: 2.27,
                },
                gross_arr: {
                  without_refunds: 4.34,
                  with_refunds: 2.77,
                },
                net_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                gross_arr_spread: {
                  without_refunds: 8.81,
                  with_refunds: 8.81,
                },
                net_total: {
                  without_refunds: 2.76,
                  with_refunds: 2.6,
                },
                gross_total: {
                  without_refunds: 3.1,
                  with_refunds: 2.92,
                },
                net_total_spread: {
                  without_refunds: 2.91,
                  with_refunds: 2.86,
                },
                gross_total_spread: {
                  without_refunds: 3.4,
                  with_refunds: 3.34,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.44,
                  with_refunds: 11.16,
                },
                gross_mrr: {
                  without_refunds: 12.75,
                  with_refunds: 12.44,
                },
                net_arr: {
                  without_refunds: 119.51,
                  with_refunds: 67.2,
                },
                gross_arr: {
                  without_refunds: 144.47,
                  with_refunds: 81.75,
                },
                net_arr_spread: {
                  without_refunds: 6.17,
                  with_refunds: 6.17,
                },
                gross_arr_spread: {
                  without_refunds: 9.07,
                  with_refunds: 9.07,
                },
                net_total: {
                  without_refunds: 12.66,
                  with_refunds: 11.89,
                },
                gross_total: {
                  without_refunds: 14.23,
                  with_refunds: 13.34,
                },
                net_total_spread: {
                  without_refunds: 10.17,
                  with_refunds: 9.97,
                },
                gross_total_spread: {
                  without_refunds: 11.87,
                  with_refunds: 11.65,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.97,
                    with_refunds: 39.22,
                  },
                  gross_mrr: {
                    without_refunds: 47.28,
                    with_refunds: 46.39,
                  },
                  net_arr: {
                    without_refunds: 73.96,
                    with_refunds: 65.01,
                  },
                  gross_arr: {
                    without_refunds: 108.13,
                    with_refunds: 95.59,
                  },
                  net_arr_spread: {
                    without_refunds: 41.44,
                    with_refunds: 41.44,
                  },
                  gross_arr_spread: {
                    without_refunds: 62.22,
                    with_refunds: 62.22,
                  },
                  net_total: {
                    without_refunds: 43.12,
                    with_refunds: 41.72,
                  },
                  gross_total: {
                    without_refunds: 52.65,
                    with_refunds: 50.83,
                  },
                  net_total_spread: {
                    without_refunds: 40.42,
                    with_refunds: 39.72,
                  },
                  gross_total_spread: {
                    without_refunds: 48.76,
                    with_refunds: 47.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 89.02,
                    with_refunds: 86.98,
                  },
                  gross_mrr: {
                    without_refunds: 103.53,
                    with_refunds: 101.18,
                  },
                  net_arr: {
                    without_refunds: 665.46,
                    with_refunds: 450.7,
                  },
                  gross_arr: {
                    without_refunds: 864.08,
                    with_refunds: 590.31,
                  },
                  net_arr_spread: {
                    without_refunds: 47.13,
                    with_refunds: 47.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 70.82,
                    with_refunds: 70.82,
                  },
                  net_total: {
                    without_refunds: 98.98,
                    with_refunds: 95.1,
                  },
                  gross_total: {
                    without_refunds: 117.78,
                    with_refunds: 112.95,
                  },
                  net_total_spread: {
                    without_refunds: 82.74,
                    with_refunds: 81.09,
                  },
                  gross_total_spread: {
                    without_refunds: 98.83,
                    with_refunds: 96.91,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3934,
                    with_refunds: 0.3861,
                  },
                  gross_mrr: {
                    without_refunds: 0.4654,
                    with_refunds: 0.4567,
                  },
                  net_arr: {
                    without_refunds: 0.728,
                    with_refunds: 0.6399,
                  },
                  gross_arr: {
                    without_refunds: 1.0643,
                    with_refunds: 0.9409,
                  },
                  net_arr_spread: {
                    without_refunds: 0.408,
                    with_refunds: 0.408,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6125,
                    with_refunds: 0.6125,
                  },
                  net_total: {
                    without_refunds: 0.4244,
                    with_refunds: 0.4106,
                  },
                  gross_total: {
                    without_refunds: 0.5182,
                    with_refunds: 0.5003,
                  },
                  net_total_spread: {
                    without_refunds: 0.3979,
                    with_refunds: 0.391,
                  },
                  gross_total_spread: {
                    without_refunds: 0.48,
                    with_refunds: 0.4718,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8762,
                    with_refunds: 0.8562,
                  },
                  gross_mrr: {
                    without_refunds: 1.0191,
                    with_refunds: 0.996,
                  },
                  net_arr: {
                    without_refunds: 6.5504,
                    with_refunds: 4.4365,
                  },
                  gross_arr: {
                    without_refunds: 8.5055,
                    with_refunds: 5.8107,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4639,
                    with_refunds: 0.4639,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6971,
                    with_refunds: 0.6971,
                  },
                  net_total: {
                    without_refunds: 0.9743,
                    with_refunds: 0.9361,
                  },
                  gross_total: {
                    without_refunds: 1.1593,
                    with_refunds: 1.1118,
                  },
                  net_total_spread: {
                    without_refunds: 0.8145,
                    with_refunds: 0.7983,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9728,
                    with_refunds: 0.954,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 629,
                  with_refunds: 630,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 3,
                },
                arr_spread_users: {
                  without_refunds: 235,
                  with_refunds: 235,
                },
                total_users: {
                  without_refunds: 632,
                  with_refunds: 633,
                },
                total_spread_users: {
                  without_refunds: 864,
                  with_refunds: 865,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2015,
                  with_refunds: 0.2019,
                },
                arr_users: {
                  without_refunds: 0.0113,
                  with_refunds: 0.0113,
                },
                arr_spread_users: {
                  without_refunds: 0.9833,
                  with_refunds: 0.9833,
                },
                total_users: {
                  without_refunds: 0.1887,
                  with_refunds: 0.189,
                },
                total_spread_users: {
                  without_refunds: 0.2594,
                  with_refunds: 0.2597,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.87,
                  with_refunds: 0.8678,
                },
                arr_users: {
                  without_refunds: 0.375,
                  with_refunds: 0.3333,
                },
                arr_spread_users: {
                  without_refunds: 1.0129,
                  with_refunds: 1.0129,
                },
                total_users: {
                  without_refunds: 0.8669,
                  with_refunds: 0.8648,
                },
                total_spread_users: {
                  without_refunds: 0.9057,
                  with_refunds: 0.9048,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7122.55,
                  with_refunds: 7065.8,
                },
                gross_mrr: {
                  without_refunds: 7938.52,
                  with_refunds: 7878.52,
                },
                net_arr: {
                  without_refunds: 365.24,
                  with_refunds: 365.24,
                },
                gross_arr: {
                  without_refunds: 475.71,
                  with_refunds: 475.71,
                },
                net_arr_spread: {
                  without_refunds: 1461.5,
                  with_refunds: 1461.5,
                },
                gross_arr_spread: {
                  without_refunds: 2144.57,
                  with_refunds: 2144.57,
                },
                net_total: {
                  without_refunds: 7487.8,
                  with_refunds: 7431.05,
                },
                gross_total: {
                  without_refunds: 8414.23,
                  with_refunds: 8354.23,
                },
                net_total_spread: {
                  without_refunds: 8584.05,
                  with_refunds: 8527.3,
                },
                gross_total_spread: {
                  without_refunds: 10083.09,
                  with_refunds: 10023.09,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3258,
                  with_refunds: 0.3304,
                },
                gross_mrr: {
                  without_refunds: 0.2727,
                  with_refunds: 0.2769,
                },
                net_arr: {
                  without_refunds: 0.0289,
                  with_refunds: 0.0308,
                },
                gross_arr: {
                  without_refunds: 0.0246,
                  with_refunds: 0.0263,
                },
                net_arr_spread: {
                  without_refunds: 1.4884,
                  with_refunds: 1.4884,
                },
                gross_arr_spread: {
                  without_refunds: 1.4291,
                  with_refunds: 1.4291,
                },
                net_total: {
                  without_refunds: 0.2171,
                  with_refunds: 0.2236,
                },
                gross_total: {
                  without_refunds: 0.1737,
                  with_refunds: 0.1794,
                },
                net_total_spread: {
                  without_refunds: 0.3758,
                  with_refunds: 0.3812,
                },
                gross_total_spread: {
                  without_refunds: 0.3294,
                  with_refunds: 0.3346,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8612,
                  with_refunds: 0.8724,
                },
                gross_mrr: {
                  without_refunds: 0.8615,
                  with_refunds: 0.8727,
                },
                net_arr: {
                  without_refunds: 0.382,
                  with_refunds: 0.6039,
                },
                gross_arr: {
                  without_refunds: 0.4116,
                  with_refunds: 0.6466,
                },
                net_arr_spread: {
                  without_refunds: 1.0213,
                  with_refunds: 1.0213,
                },
                gross_arr_spread: {
                  without_refunds: 1.0188,
                  with_refunds: 1.0188,
                },
                net_total: {
                  without_refunds: 0.8115,
                  with_refunds: 0.8538,
                },
                gross_total: {
                  without_refunds: 0.8114,
                  with_refunds: 0.8556,
                },
                net_total_spread: {
                  without_refunds: 0.8848,
                  with_refunds: 0.8948,
                },
                gross_total_spread: {
                  without_refunds: 0.8907,
                  with_refunds: 0.9003,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.28,
                  with_refunds: 2.26,
                },
                gross_mrr: {
                  without_refunds: 2.54,
                  with_refunds: 2.52,
                },
                net_arr: {
                  without_refunds: 1.37,
                  with_refunds: 1.37,
                },
                gross_arr: {
                  without_refunds: 1.79,
                  with_refunds: 1.79,
                },
                net_arr_spread: {
                  without_refunds: 6.12,
                  with_refunds: 6.12,
                },
                gross_arr_spread: {
                  without_refunds: 8.97,
                  with_refunds: 8.97,
                },
                net_total: {
                  without_refunds: 2.24,
                  with_refunds: 2.22,
                },
                gross_total: {
                  without_refunds: 2.51,
                  with_refunds: 2.49,
                },
                net_total_spread: {
                  without_refunds: 2.58,
                  with_refunds: 2.56,
                },
                gross_total_spread: {
                  without_refunds: 3.03,
                  with_refunds: 3.01,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.32,
                  with_refunds: 11.22,
                },
                gross_mrr: {
                  without_refunds: 12.62,
                  with_refunds: 12.51,
                },
                net_arr: {
                  without_refunds: 121.75,
                  with_refunds: 121.75,
                },
                gross_arr: {
                  without_refunds: 158.57,
                  with_refunds: 158.57,
                },
                net_arr_spread: {
                  without_refunds: 6.22,
                  with_refunds: 6.22,
                },
                gross_arr_spread: {
                  without_refunds: 9.13,
                  with_refunds: 9.13,
                },
                net_total: {
                  without_refunds: 11.85,
                  with_refunds: 11.74,
                },
                gross_total: {
                  without_refunds: 13.31,
                  with_refunds: 13.2,
                },
                net_total_spread: {
                  without_refunds: 9.94,
                  with_refunds: 9.86,
                },
                gross_total_spread: {
                  without_refunds: 11.67,
                  with_refunds: 11.59,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.25,
                    with_refunds: 41.49,
                  },
                  gross_mrr: {
                    without_refunds: 49.82,
                    with_refunds: 48.92,
                  },
                  net_arr: {
                    without_refunds: 75.33,
                    with_refunds: 66.38,
                  },
                  gross_arr: {
                    without_refunds: 109.91,
                    with_refunds: 97.37,
                  },
                  net_arr_spread: {
                    without_refunds: 47.56,
                    with_refunds: 47.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 71.19,
                    with_refunds: 71.19,
                  },
                  net_total: {
                    without_refunds: 45.36,
                    with_refunds: 43.94,
                  },
                  gross_total: {
                    without_refunds: 55.16,
                    with_refunds: 53.32,
                  },
                  net_total_spread: {
                    without_refunds: 43,
                    with_refunds: 42.28,
                  },
                  gross_total_spread: {
                    without_refunds: 51.79,
                    with_refunds: 50.94,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 100.34,
                    with_refunds: 98.19,
                  },
                  gross_mrr: {
                    without_refunds: 116.16,
                    with_refunds: 113.69,
                  },
                  net_arr: {
                    without_refunds: 787.21,
                    with_refunds: 572.45,
                  },
                  gross_arr: {
                    without_refunds: 1022.64,
                    with_refunds: 748.88,
                  },
                  net_arr_spread: {
                    without_refunds: 53.35,
                    with_refunds: 53.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 79.94,
                    with_refunds: 79.94,
                  },
                  net_total: {
                    without_refunds: 110.83,
                    with_refunds: 106.84,
                  },
                  gross_total: {
                    without_refunds: 131.09,
                    with_refunds: 126.15,
                  },
                  net_total_spread: {
                    without_refunds: 92.68,
                    with_refunds: 90.95,
                  },
                  gross_total_spread: {
                    without_refunds: 110.5,
                    with_refunds: 108.5,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4159,
                    with_refunds: 0.4084,
                  },
                  gross_mrr: {
                    without_refunds: 0.4904,
                    with_refunds: 0.4815,
                  },
                  net_arr: {
                    without_refunds: 0.7415,
                    with_refunds: 0.6534,
                  },
                  gross_arr: {
                    without_refunds: 1.0819,
                    with_refunds: 0.9585,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4682,
                    with_refunds: 0.4682,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7008,
                    with_refunds: 0.7008,
                  },
                  net_total: {
                    without_refunds: 0.4465,
                    with_refunds: 0.4325,
                  },
                  gross_total: {
                    without_refunds: 0.543,
                    with_refunds: 0.5249,
                  },
                  net_total_spread: {
                    without_refunds: 0.4232,
                    with_refunds: 0.4162,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5098,
                    with_refunds: 0.5014,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9877,
                    with_refunds: 0.9666,
                  },
                  gross_mrr: {
                    without_refunds: 1.1434,
                    with_refunds: 1.1191,
                  },
                  net_arr: {
                    without_refunds: 7.7489,
                    with_refunds: 5.6349,
                  },
                  gross_arr: {
                    without_refunds: 10.0664,
                    with_refunds: 7.3716,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5251,
                    with_refunds: 0.5251,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7869,
                    with_refunds: 0.7869,
                  },
                  net_total: {
                    without_refunds: 1.0909,
                    with_refunds: 1.0517,
                  },
                  gross_total: {
                    without_refunds: 1.2904,
                    with_refunds: 1.2417,
                  },
                  net_total_spread: {
                    without_refunds: 0.9123,
                    with_refunds: 0.8953,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0877,
                    with_refunds: 1.068,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 570,
                  with_refunds: 571,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 237,
                  with_refunds: 237,
                },
                total_users: {
                  without_refunds: 572,
                  with_refunds: 573,
                },
                total_spread_users: {
                  without_refunds: 807,
                  with_refunds: 808,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1826,
                  with_refunds: 0.183,
                },
                arr_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0075,
                },
                arr_spread_users: {
                  without_refunds: 0.9916,
                  with_refunds: 0.9916,
                },
                total_users: {
                  without_refunds: 0.1708,
                  with_refunds: 0.1711,
                },
                total_spread_users: {
                  without_refunds: 0.2423,
                  with_refunds: 0.2426,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9062,
                  with_refunds: 0.9063,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0085,
                  with_refunds: 1.0085,
                },
                total_users: {
                  without_refunds: 0.9051,
                  with_refunds: 0.9052,
                },
                total_spread_users: {
                  without_refunds: 0.934,
                  with_refunds: 0.9341,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6510.55,
                  with_refunds: 6488.16,
                },
                gross_mrr: {
                  without_refunds: 7263,
                  with_refunds: 7238.01,
                },
                net_arr: {
                  without_refunds: 219.28,
                  with_refunds: 219.28,
                },
                gross_arr: {
                  without_refunds: 291.22,
                  with_refunds: 291.22,
                },
                net_arr_spread: {
                  without_refunds: 1479.77,
                  with_refunds: 1479.77,
                },
                gross_arr_spread: {
                  without_refunds: 2168.84,
                  with_refunds: 2168.84,
                },
                net_total: {
                  without_refunds: 6729.83,
                  with_refunds: 6707.44,
                },
                gross_total: {
                  without_refunds: 7554.23,
                  with_refunds: 7529.24,
                },
                net_total_spread: {
                  without_refunds: 7990.33,
                  with_refunds: 7967.94,
                },
                gross_total_spread: {
                  without_refunds: 9431.84,
                  with_refunds: 9406.85,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2978,
                  with_refunds: 0.3034,
                },
                gross_mrr: {
                  without_refunds: 0.2495,
                  with_refunds: 0.2544,
                },
                net_arr: {
                  without_refunds: 0.0174,
                  with_refunds: 0.0185,
                },
                gross_arr: {
                  without_refunds: 0.0151,
                  with_refunds: 0.0161,
                },
                net_arr_spread: {
                  without_refunds: 1.507,
                  with_refunds: 1.507,
                },
                gross_arr_spread: {
                  without_refunds: 1.4453,
                  with_refunds: 1.4453,
                },
                net_total: {
                  without_refunds: 0.1951,
                  with_refunds: 0.2018,
                },
                gross_total: {
                  without_refunds: 0.156,
                  with_refunds: 0.1617,
                },
                net_total_spread: {
                  without_refunds: 0.3498,
                  with_refunds: 0.3562,
                },
                gross_total_spread: {
                  without_refunds: 0.3081,
                  with_refunds: 0.3141,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9141,
                  with_refunds: 0.9182,
                },
                gross_mrr: {
                  without_refunds: 0.9149,
                  with_refunds: 0.9187,
                },
                net_arr: {
                  without_refunds: 0.6004,
                  with_refunds: 0.6004,
                },
                gross_arr: {
                  without_refunds: 0.6122,
                  with_refunds: 0.6122,
                },
                net_arr_spread: {
                  without_refunds: 1.0125,
                  with_refunds: 1.0125,
                },
                gross_arr_spread: {
                  without_refunds: 1.0113,
                  with_refunds: 1.0113,
                },
                net_total: {
                  without_refunds: 0.8988,
                  with_refunds: 0.9026,
                },
                gross_total: {
                  without_refunds: 0.8978,
                  with_refunds: 0.9012,
                },
                net_total_spread: {
                  without_refunds: 0.9308,
                  with_refunds: 0.9344,
                },
                gross_total_spread: {
                  without_refunds: 0.9354,
                  with_refunds: 0.9385,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.09,
                  with_refunds: 2.08,
                },
                gross_mrr: {
                  without_refunds: 2.33,
                  with_refunds: 2.32,
                },
                net_arr: {
                  without_refunds: 0.82,
                  with_refunds: 0.82,
                },
                gross_arr: {
                  without_refunds: 1.09,
                  with_refunds: 1.09,
                },
                net_arr_spread: {
                  without_refunds: 6.19,
                  with_refunds: 6.19,
                },
                gross_arr_spread: {
                  without_refunds: 9.07,
                  with_refunds: 9.07,
                },
                net_total: {
                  without_refunds: 2.01,
                  with_refunds: 2,
                },
                gross_total: {
                  without_refunds: 2.26,
                  with_refunds: 2.25,
                },
                net_total_spread: {
                  without_refunds: 2.4,
                  with_refunds: 2.39,
                },
                gross_total_spread: {
                  without_refunds: 2.83,
                  with_refunds: 2.82,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.36,
                },
                gross_mrr: {
                  without_refunds: 12.74,
                  with_refunds: 12.68,
                },
                net_arr: {
                  without_refunds: 109.64,
                  with_refunds: 109.64,
                },
                gross_arr: {
                  without_refunds: 145.61,
                  with_refunds: 145.61,
                },
                net_arr_spread: {
                  without_refunds: 6.24,
                  with_refunds: 6.24,
                },
                gross_arr_spread: {
                  without_refunds: 9.15,
                  with_refunds: 9.15,
                },
                net_total: {
                  without_refunds: 11.77,
                  with_refunds: 11.71,
                },
                gross_total: {
                  without_refunds: 13.21,
                  with_refunds: 13.14,
                },
                net_total_spread: {
                  without_refunds: 9.9,
                  with_refunds: 9.86,
                },
                gross_total_spread: {
                  without_refunds: 11.69,
                  with_refunds: 11.64,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.33,
                    with_refunds: 43.57,
                  },
                  gross_mrr: {
                    without_refunds: 52.15,
                    with_refunds: 51.24,
                  },
                  net_arr: {
                    without_refunds: 76.15,
                    with_refunds: 67.2,
                  },
                  gross_arr: {
                    without_refunds: 111.01,
                    with_refunds: 98.47,
                  },
                  net_arr_spread: {
                    without_refunds: 53.75,
                    with_refunds: 53.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 80.27,
                    with_refunds: 80.27,
                  },
                  net_total: {
                    without_refunds: 47.37,
                    with_refunds: 45.94,
                  },
                  gross_total: {
                    without_refunds: 57.41,
                    with_refunds: 55.57,
                  },
                  net_total_spread: {
                    without_refunds: 45.4,
                    with_refunds: 44.68,
                  },
                  gross_total_spread: {
                    without_refunds: 54.62,
                    with_refunds: 53.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 111.76,
                    with_refunds: 109.56,
                  },
                  gross_mrr: {
                    without_refunds: 128.9,
                    with_refunds: 126.37,
                  },
                  net_arr: {
                    without_refunds: 896.85,
                    with_refunds: 682.09,
                  },
                  gross_arr: {
                    without_refunds: 1168.26,
                    with_refunds: 894.49,
                  },
                  net_arr_spread: {
                    without_refunds: 59.59,
                    with_refunds: 59.59,
                  },
                  gross_arr_spread: {
                    without_refunds: 89.09,
                    with_refunds: 89.09,
                  },
                  net_total: {
                    without_refunds: 122.59,
                    with_refunds: 118.54,
                  },
                  gross_total: {
                    without_refunds: 144.3,
                    with_refunds: 139.29,
                  },
                  net_total_spread: {
                    without_refunds: 102.58,
                    with_refunds: 100.81,
                  },
                  gross_total_spread: {
                    without_refunds: 122.18,
                    with_refunds: 120.14,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4364,
                    with_refunds: 0.4288,
                  },
                  gross_mrr: {
                    without_refunds: 0.5133,
                    with_refunds: 0.5043,
                  },
                  net_arr: {
                    without_refunds: 0.7496,
                    with_refunds: 0.6615,
                  },
                  gross_arr: {
                    without_refunds: 1.0927,
                    with_refunds: 0.9693,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5291,
                    with_refunds: 0.5291,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7901,
                    with_refunds: 0.7901,
                  },
                  net_total: {
                    without_refunds: 0.4662,
                    with_refunds: 0.4522,
                  },
                  gross_total: {
                    without_refunds: 0.5652,
                    with_refunds: 0.547,
                  },
                  net_total_spread: {
                    without_refunds: 0.4469,
                    with_refunds: 0.4398,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5376,
                    with_refunds: 0.5292,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1001,
                    with_refunds: 1.0784,
                  },
                  gross_mrr: {
                    without_refunds: 1.2688,
                    with_refunds: 1.2439,
                  },
                  net_arr: {
                    without_refunds: 8.8281,
                    with_refunds: 6.7141,
                  },
                  gross_arr: {
                    without_refunds: 11.4997,
                    with_refunds: 8.8049,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5866,
                    with_refunds: 0.5866,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.877,
                    with_refunds: 0.877,
                  },
                  net_total: {
                    without_refunds: 1.2067,
                    with_refunds: 1.1669,
                  },
                  gross_total: {
                    without_refunds: 1.4204,
                    with_refunds: 1.3711,
                  },
                  net_total_spread: {
                    without_refunds: 1.0097,
                    with_refunds: 0.9924,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2027,
                    with_refunds: 1.1826,
                  },
                },
              },
            },
          },
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 510,
                  with_refunds: 511,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 239,
                  with_refunds: 239,
                },
                total_users: {
                  without_refunds: 512,
                  with_refunds: 513,
                },
                total_spread_users: {
                  without_refunds: 749,
                  with_refunds: 750,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1634,
                  with_refunds: 0.1637,
                },
                arr_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0075,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.1529,
                  with_refunds: 0.1532,
                },
                total_spread_users: {
                  without_refunds: 0.2249,
                  with_refunds: 0.2252,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8947,
                  with_refunds: 0.8949,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1.0084,
                  with_refunds: 1.0084,
                },
                total_users: {
                  without_refunds: 0.8951,
                  with_refunds: 0.8953,
                },
                total_spread_users: {
                  without_refunds: 0.9281,
                  with_refunds: 0.9282,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5781.43,
                  with_refunds: 5741.61,
                },
                gross_mrr: {
                  without_refunds: 6446.43,
                  with_refunds: 6396.46,
                },
                net_arr: {
                  without_refunds: 203.09,
                  with_refunds: 203.09,
                },
                gross_arr: {
                  without_refunds: 260.58,
                  with_refunds: 260.58,
                },
                net_arr_spread: {
                  without_refunds: 1496.7,
                  with_refunds: 1496.7,
                },
                gross_arr_spread: {
                  without_refunds: 2190.56,
                  with_refunds: 2190.56,
                },
                net_total: {
                  without_refunds: 5984.52,
                  with_refunds: 5944.7,
                },
                gross_total: {
                  without_refunds: 6707.01,
                  with_refunds: 6657.04,
                },
                net_total_spread: {
                  without_refunds: 7278.12,
                  with_refunds: 7238.31,
                },
                gross_total_spread: {
                  without_refunds: 8636.99,
                  with_refunds: 8587.02,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2645,
                  with_refunds: 0.2685,
                },
                gross_mrr: {
                  without_refunds: 0.2215,
                  with_refunds: 0.2248,
                },
                net_arr: {
                  without_refunds: 0.0161,
                  with_refunds: 0.0171,
                },
                gross_arr: {
                  without_refunds: 0.0135,
                  with_refunds: 0.0144,
                },
                net_arr_spread: {
                  without_refunds: 1.5243,
                  with_refunds: 1.5243,
                },
                gross_arr_spread: {
                  without_refunds: 1.4598,
                  with_refunds: 1.4598,
                },
                net_total: {
                  without_refunds: 0.1735,
                  with_refunds: 0.1789,
                },
                gross_total: {
                  without_refunds: 0.1385,
                  with_refunds: 0.143,
                },
                net_total_spread: {
                  without_refunds: 0.3186,
                  with_refunds: 0.3236,
                },
                gross_total_spread: {
                  without_refunds: 0.2822,
                  with_refunds: 0.2867,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.888,
                  with_refunds: 0.8849,
                },
                gross_mrr: {
                  without_refunds: 0.8876,
                  with_refunds: 0.8837,
                },
                net_arr: {
                  without_refunds: 0.9262,
                  with_refunds: 0.9262,
                },
                gross_arr: {
                  without_refunds: 0.8948,
                  with_refunds: 0.8948,
                },
                net_arr_spread: {
                  without_refunds: 1.0114,
                  with_refunds: 1.0114,
                },
                gross_arr_spread: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                net_total: {
                  without_refunds: 0.8893,
                  with_refunds: 0.8863,
                },
                gross_total: {
                  without_refunds: 0.8878,
                  with_refunds: 0.8842,
                },
                net_total_spread: {
                  without_refunds: 0.9109,
                  with_refunds: 0.9084,
                },
                gross_total_spread: {
                  without_refunds: 0.9157,
                  with_refunds: 0.9128,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.85,
                  with_refunds: 1.84,
                },
                gross_mrr: {
                  without_refunds: 2.07,
                  with_refunds: 2.05,
                },
                net_arr: {
                  without_refunds: 0.76,
                  with_refunds: 0.76,
                },
                gross_arr: {
                  without_refunds: 0.98,
                  with_refunds: 0.98,
                },
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 1.79,
                  with_refunds: 1.78,
                },
                gross_total: {
                  without_refunds: 2,
                  with_refunds: 1.99,
                },
                net_total_spread: {
                  without_refunds: 2.18,
                  with_refunds: 2.17,
                },
                gross_total_spread: {
                  without_refunds: 2.59,
                  with_refunds: 2.58,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.34,
                  with_refunds: 11.24,
                },
                gross_mrr: {
                  without_refunds: 12.64,
                  with_refunds: 12.52,
                },
                net_arr: {
                  without_refunds: 101.54,
                  with_refunds: 101.54,
                },
                gross_arr: {
                  without_refunds: 130.29,
                  with_refunds: 130.29,
                },
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 11.69,
                  with_refunds: 11.59,
                },
                gross_total: {
                  without_refunds: 13.1,
                  with_refunds: 12.98,
                },
                net_total_spread: {
                  without_refunds: 9.72,
                  with_refunds: 9.65,
                },
                gross_total_spread: {
                  without_refunds: 11.53,
                  with_refunds: 11.45,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 46.19,
                    with_refunds: 45.41,
                  },
                  gross_mrr: {
                    without_refunds: 54.21,
                    with_refunds: 53.29,
                  },
                  net_arr: {
                    without_refunds: 76.92,
                    with_refunds: 67.97,
                  },
                  gross_arr: {
                    without_refunds: 111.99,
                    with_refunds: 99.45,
                  },
                  net_arr_spread: {
                    without_refunds: 60.01,
                    with_refunds: 60.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 89.43,
                    with_refunds: 89.43,
                  },
                  net_total: {
                    without_refunds: 49.15,
                    with_refunds: 47.71,
                  },
                  gross_total: {
                    without_refunds: 59.42,
                    with_refunds: 57.56,
                  },
                  net_total_spread: {
                    without_refunds: 47.58,
                    with_refunds: 46.85,
                  },
                  gross_total_spread: {
                    without_refunds: 57.21,
                    with_refunds: 56.34,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 123.1,
                    with_refunds: 120.79,
                  },
                  gross_mrr: {
                    without_refunds: 141.54,
                    with_refunds: 138.88,
                  },
                  net_arr: {
                    without_refunds: 998.39,
                    with_refunds: 783.63,
                  },
                  gross_arr: {
                    without_refunds: 1298.55,
                    with_refunds: 1024.78,
                  },
                  net_arr_spread: {
                    without_refunds: 65.85,
                    with_refunds: 65.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.26,
                    with_refunds: 98.26,
                  },
                  net_total: {
                    without_refunds: 134.28,
                    with_refunds: 130.13,
                  },
                  gross_total: {
                    without_refunds: 157.4,
                    with_refunds: 152.26,
                  },
                  net_total_spread: {
                    without_refunds: 112.29,
                    with_refunds: 110.47,
                  },
                  gross_total_spread: {
                    without_refunds: 133.72,
                    with_refunds: 131.59,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4546,
                    with_refunds: 0.447,
                  },
                  gross_mrr: {
                    without_refunds: 0.5336,
                    with_refunds: 0.5245,
                  },
                  net_arr: {
                    without_refunds: 0.7571,
                    with_refunds: 0.669,
                  },
                  gross_arr: {
                    without_refunds: 1.1024,
                    with_refunds: 0.9789,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5907,
                    with_refunds: 0.5907,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8803,
                    with_refunds: 0.8803,
                  },
                  net_total: {
                    without_refunds: 0.4838,
                    with_refunds: 0.4697,
                  },
                  gross_total: {
                    without_refunds: 0.5849,
                    with_refunds: 0.5666,
                  },
                  net_total_spread: {
                    without_refunds: 0.4684,
                    with_refunds: 0.4612,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5632,
                    with_refunds: 0.5546,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2117,
                    with_refunds: 1.189,
                  },
                  gross_mrr: {
                    without_refunds: 1.3932,
                    with_refunds: 1.3671,
                  },
                  net_arr: {
                    without_refunds: 9.8276,
                    with_refunds: 7.7137,
                  },
                  gross_arr: {
                    without_refunds: 12.7822,
                    with_refunds: 10.0874,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6482,
                    with_refunds: 0.6482,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9672,
                    with_refunds: 0.9672,
                  },
                  net_total: {
                    without_refunds: 1.3218,
                    with_refunds: 1.281,
                  },
                  gross_total: {
                    without_refunds: 1.5493,
                    with_refunds: 1.4988,
                  },
                  net_total_spread: {
                    without_refunds: 1.1054,
                    with_refunds: 1.0874,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3162,
                    with_refunds: 1.2953,
                  },
                },
              },
            },
          },
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 150,
                  with_refunds: 150,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 239,
                  with_refunds: 239,
                },
                total_users: {
                  without_refunds: 150,
                  with_refunds: 150,
                },
                total_spread_users: {
                  without_refunds: 389,
                  with_refunds: 389,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0481,
                  with_refunds: 0.0481,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.0448,
                  with_refunds: 0.0448,
                },
                total_spread_users: {
                  without_refunds: 0.1168,
                  with_refunds: 0.1168,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2941,
                  with_refunds: 0.2935,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.293,
                  with_refunds: 0.2924,
                },
                total_spread_users: {
                  without_refunds: 0.5194,
                  with_refunds: 0.5187,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1723.43,
                  with_refunds: 1723.43,
                },
                gross_mrr: {
                  without_refunds: 1931.67,
                  with_refunds: 1931.67,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1496.7,
                  with_refunds: 1496.7,
                },
                gross_arr_spread: {
                  without_refunds: 2190.56,
                  with_refunds: 2190.56,
                },
                net_total: {
                  without_refunds: 1723.43,
                  with_refunds: 1723.43,
                },
                gross_total: {
                  without_refunds: 1931.67,
                  with_refunds: 1931.67,
                },
                net_total_spread: {
                  without_refunds: 3220.13,
                  with_refunds: 3220.13,
                },
                gross_total_spread: {
                  without_refunds: 4122.23,
                  with_refunds: 4122.23,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0788,
                  with_refunds: 0.0806,
                },
                gross_mrr: {
                  without_refunds: 0.0664,
                  with_refunds: 0.0679,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.5243,
                  with_refunds: 1.5243,
                },
                gross_arr_spread: {
                  without_refunds: 1.4598,
                  with_refunds: 1.4598,
                },
                net_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.0519,
                },
                gross_total: {
                  without_refunds: 0.0399,
                  with_refunds: 0.0415,
                },
                net_total_spread: {
                  without_refunds: 0.141,
                  with_refunds: 0.144,
                },
                gross_total_spread: {
                  without_refunds: 0.1347,
                  with_refunds: 0.1376,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2981,
                  with_refunds: 0.3002,
                },
                gross_mrr: {
                  without_refunds: 0.2996,
                  with_refunds: 0.302,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.288,
                  with_refunds: 0.2899,
                },
                gross_total: {
                  without_refunds: 0.288,
                  with_refunds: 0.2902,
                },
                net_total_spread: {
                  without_refunds: 0.4424,
                  with_refunds: 0.4449,
                },
                gross_total_spread: {
                  without_refunds: 0.4773,
                  with_refunds: 0.4801,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.55,
                  with_refunds: 0.55,
                },
                gross_mrr: {
                  without_refunds: 0.62,
                  with_refunds: 0.62,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 0.51,
                  with_refunds: 0.51,
                },
                gross_total: {
                  without_refunds: 0.58,
                  with_refunds: 0.58,
                },
                net_total_spread: {
                  without_refunds: 0.97,
                  with_refunds: 0.97,
                },
                gross_total_spread: {
                  without_refunds: 1.24,
                  with_refunds: 1.24,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.49,
                  with_refunds: 11.49,
                },
                gross_mrr: {
                  without_refunds: 12.88,
                  with_refunds: 12.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 11.49,
                  with_refunds: 11.49,
                },
                gross_total: {
                  without_refunds: 12.88,
                  with_refunds: 12.88,
                },
                net_total_spread: {
                  without_refunds: 8.28,
                  with_refunds: 8.28,
                },
                gross_total_spread: {
                  without_refunds: 10.6,
                  with_refunds: 10.6,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 46.74,
                    with_refunds: 45.96,
                  },
                  gross_mrr: {
                    without_refunds: 54.83,
                    with_refunds: 53.9,
                  },
                  net_arr: {
                    without_refunds: 76.92,
                    with_refunds: 67.97,
                  },
                  gross_arr: {
                    without_refunds: 111.99,
                    with_refunds: 99.45,
                  },
                  net_arr_spread: {
                    without_refunds: 66.28,
                    with_refunds: 66.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.6,
                    with_refunds: 98.6,
                  },
                  net_total: {
                    without_refunds: 49.67,
                    with_refunds: 48.23,
                  },
                  gross_total: {
                    without_refunds: 59.99,
                    with_refunds: 58.13,
                  },
                  net_total_spread: {
                    without_refunds: 48.55,
                    with_refunds: 47.82,
                  },
                  gross_total_spread: {
                    without_refunds: 58.45,
                    with_refunds: 57.58,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 134.59,
                    with_refunds: 132.28,
                  },
                  gross_mrr: {
                    without_refunds: 154.42,
                    with_refunds: 151.76,
                  },
                  net_arr: {
                    without_refunds: 998.39,
                    with_refunds: 783.63,
                  },
                  gross_arr: {
                    without_refunds: 1298.55,
                    with_refunds: 1024.78,
                  },
                  net_arr_spread: {
                    without_refunds: 72.12,
                    with_refunds: 72.12,
                  },
                  gross_arr_spread: {
                    without_refunds: 107.43,
                    with_refunds: 107.43,
                  },
                  net_total: {
                    without_refunds: 145.77,
                    with_refunds: 141.62,
                  },
                  gross_total: {
                    without_refunds: 170.28,
                    with_refunds: 165.14,
                  },
                  net_total_spread: {
                    without_refunds: 120.57,
                    with_refunds: 118.74,
                  },
                  gross_total_spread: {
                    without_refunds: 144.31,
                    with_refunds: 142.19,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4601,
                    with_refunds: 0.4524,
                  },
                  gross_mrr: {
                    without_refunds: 0.5397,
                    with_refunds: 0.5306,
                  },
                  net_arr: {
                    without_refunds: 0.7571,
                    with_refunds: 0.669,
                  },
                  gross_arr: {
                    without_refunds: 1.1024,
                    with_refunds: 0.9789,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6524,
                    with_refunds: 0.6524,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9706,
                    with_refunds: 0.9706,
                  },
                  net_total: {
                    without_refunds: 0.4889,
                    with_refunds: 0.4747,
                  },
                  gross_total: {
                    without_refunds: 0.5905,
                    with_refunds: 0.5722,
                  },
                  net_total_spread: {
                    without_refunds: 0.4779,
                    with_refunds: 0.4707,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5753,
                    with_refunds: 0.5668,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3248,
                    with_refunds: 1.3021,
                  },
                  gross_mrr: {
                    without_refunds: 1.52,
                    with_refunds: 1.4939,
                  },
                  net_arr: {
                    without_refunds: 9.8276,
                    with_refunds: 7.7137,
                  },
                  gross_arr: {
                    without_refunds: 12.7822,
                    with_refunds: 10.0874,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7099,
                    with_refunds: 0.7099,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0574,
                    with_refunds: 1.0574,
                  },
                  net_total: {
                    without_refunds: 1.4349,
                    with_refunds: 1.3941,
                  },
                  gross_total: {
                    without_refunds: 1.6761,
                    with_refunds: 1.6256,
                  },
                  net_total_spread: {
                    without_refunds: 1.1868,
                    with_refunds: 1.1688,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4205,
                    with_refunds: 1.3996,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 2187,
              with_refunds: 2187,
            },
            returning_users: {
              without_refunds: 2504,
              with_refunds: 2518,
            },
            paying_users: {
              without_refunds: 4691,
              with_refunds: 4705,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 182,
              with_refunds: 182,
            },
            returning_users: {
              without_refunds: 50,
              with_refunds: 53,
            },
            paying_users: {
              without_refunds: 232,
              with_refunds: 235,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2369,
              with_refunds: 2369,
            },
            returning_users: {
              without_refunds: 2554,
              with_refunds: 2571,
            },
            paying_users: {
              without_refunds: 4923,
              with_refunds: 4940,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 145873.42,
                with_refunds: 143435.93,
              },
              gross_mrr: {
                without_refunds: 171130.16,
                with_refunds: 168236.29,
              },
              net_arr: {
                without_refunds: 20460.23,
                with_refunds: 18079.31,
              },
              gross_arr: {
                without_refunds: 29789.14,
                with_refunds: 26453.41,
              },
              net_arr_spread: {
                without_refunds: 15839.91,
                with_refunds: 15839.91,
              },
              gross_arr_spread: {
                without_refunds: 23565.44,
                with_refunds: 23565.44,
              },
              net_total: {
                without_refunds: 166333.64,
                with_refunds: 161515.24,
              },
              gross_total: {
                without_refunds: 200919.3,
                with_refunds: 194689.69,
              },
              net_total_spread: {
                without_refunds: 161713.32,
                with_refunds: 159275.83,
              },
              gross_total_spread: {
                without_refunds: 194695.6,
                with_refunds: 191801.72,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 145873.42,
                with_refunds: 143435.93,
              },
              gross_mrr: {
                without_refunds: 171130.16,
                with_refunds: 168236.29,
              },
              net_arr: {
                without_refunds: 20460.23,
                with_refunds: 18079.31,
              },
              gross_arr: {
                without_refunds: 29789.14,
                with_refunds: 26453.41,
              },
              net_arr_spread: {
                without_refunds: 15839.91,
                with_refunds: 15839.91,
              },
              gross_arr_spread: {
                without_refunds: 23565.44,
                with_refunds: 23565.44,
              },
              net_total: {
                without_refunds: 166333.64,
                with_refunds: 161515.24,
              },
              gross_total: {
                without_refunds: 200919.3,
                with_refunds: 194689.69,
              },
              net_total_spread: {
                without_refunds: 161713.32,
                with_refunds: 159275.83,
              },
              gross_total_spread: {
                without_refunds: 194695.6,
                with_refunds: 191801.72,
              },
            },
          },
        },
      },
      cac: 101.59,
      mrr_ua_spend: {
        without_refunds: 317062.39,
        with_refunds: 317062.39,
      },
      arr_ua_spend: {
        without_refunds: 27022.94,
        with_refunds: 27022.94,
      },
      arr_spread_ua_spend: {
        without_refunds: 24280.01,
        with_refunds: 24280.01,
      },
      total_ua_spend: {
        without_refunds: 340224.91,
        with_refunds: 340224.91,
      },
      total_spread_ua_spend: {
        without_refunds: 338396.29,
        with_refunds: 338396.29,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 9,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 2789,
        with_refunds: 2789,
      },
      first_period_arr_users: {
        without_refunds: 994,
        with_refunds: 994,
      },
      first_period_arr_spread_users: {
        without_refunds: 848,
        with_refunds: 848,
      },
      first_period_total_users: {
        without_refunds: 3735,
        with_refunds: 3735,
      },
      first_period_total_spread_users: {
        without_refunds: 3595,
        with_refunds: 3595,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1849,
                  with_refunds: 1849,
                },
                arr_users: {
                  without_refunds: 441,
                  with_refunds: 441,
                },
                arr_spread_users: {
                  without_refunds: 390,
                  with_refunds: 390,
                },
                total_users: {
                  without_refunds: 2289,
                  with_refunds: 2289,
                },
                total_spread_users: {
                  without_refunds: 2238,
                  with_refunds: 2238,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.663,
                  with_refunds: 0.663,
                },
                arr_users: {
                  without_refunds: 0.4437,
                  with_refunds: 0.4437,
                },
                arr_spread_users: {
                  without_refunds: 0.4599,
                  with_refunds: 0.4599,
                },
                total_users: {
                  without_refunds: 0.6129,
                  with_refunds: 0.6129,
                },
                total_spread_users: {
                  without_refunds: 0.6225,
                  with_refunds: 0.6225,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 19946.49,
                  with_refunds: 19573.72,
                },
                gross_mrr: {
                  without_refunds: 25009.69,
                  with_refunds: 24536.01,
                },
                net_arr: {
                  without_refunds: 39391.43,
                  with_refunds: 36539.39,
                },
                gross_arr: {
                  without_refunds: 45061.91,
                  with_refunds: 41936.66,
                },
                net_arr_spread: {
                  without_refunds: 2888.19,
                  with_refunds: 2888.19,
                },
                gross_arr_spread: {
                  without_refunds: 3326.36,
                  with_refunds: 3326.36,
                },
                net_total: {
                  without_refunds: 59337.92,
                  with_refunds: 56113.11,
                },
                gross_total: {
                  without_refunds: 70071.6,
                  with_refunds: 66472.67,
                },
                net_total_spread: {
                  without_refunds: 22834.68,
                  with_refunds: 22461.92,
                },
                gross_total_spread: {
                  without_refunds: 28336.05,
                  with_refunds: 27862.37,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.15,
                  with_refunds: 7.02,
                },
                gross_mrr: {
                  without_refunds: 8.97,
                  with_refunds: 8.8,
                },
                net_arr: {
                  without_refunds: 39.63,
                  with_refunds: 36.76,
                },
                gross_arr: {
                  without_refunds: 45.33,
                  with_refunds: 42.19,
                },
                net_arr_spread: {
                  without_refunds: 3.41,
                  with_refunds: 3.41,
                },
                gross_arr_spread: {
                  without_refunds: 3.92,
                  with_refunds: 3.92,
                },
                net_total: {
                  without_refunds: 15.89,
                  with_refunds: 15.02,
                },
                gross_total: {
                  without_refunds: 18.76,
                  with_refunds: 17.8,
                },
                net_total_spread: {
                  without_refunds: 6.35,
                  with_refunds: 6.25,
                },
                gross_total_spread: {
                  without_refunds: 7.88,
                  with_refunds: 7.75,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.79,
                  with_refunds: 10.59,
                },
                gross_mrr: {
                  without_refunds: 13.53,
                  with_refunds: 13.27,
                },
                net_arr: {
                  without_refunds: 89.32,
                  with_refunds: 82.86,
                },
                gross_arr: {
                  without_refunds: 102.18,
                  with_refunds: 95.09,
                },
                net_arr_spread: {
                  without_refunds: 7.41,
                  with_refunds: 7.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                net_total: {
                  without_refunds: 25.92,
                  with_refunds: 24.51,
                },
                gross_total: {
                  without_refunds: 30.61,
                  with_refunds: 29.04,
                },
                net_total_spread: {
                  without_refunds: 10.2,
                  with_refunds: 10.04,
                },
                gross_total_spread: {
                  without_refunds: 12.66,
                  with_refunds: 12.45,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 7.15,
                    with_refunds: 7.02,
                  },
                  gross_mrr: {
                    without_refunds: 8.97,
                    with_refunds: 8.8,
                  },
                  net_arr: {
                    without_refunds: 39.63,
                    with_refunds: 36.76,
                  },
                  gross_arr: {
                    without_refunds: 45.33,
                    with_refunds: 42.19,
                  },
                  net_arr_spread: {
                    without_refunds: 3.41,
                    with_refunds: 3.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.92,
                    with_refunds: 3.92,
                  },
                  net_total: {
                    without_refunds: 15.89,
                    with_refunds: 15.02,
                  },
                  gross_total: {
                    without_refunds: 18.76,
                    with_refunds: 17.8,
                  },
                  net_total_spread: {
                    without_refunds: 6.35,
                    with_refunds: 6.25,
                  },
                  gross_total_spread: {
                    without_refunds: 7.88,
                    with_refunds: 7.75,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.79,
                    with_refunds: 10.59,
                  },
                  gross_mrr: {
                    without_refunds: 13.53,
                    with_refunds: 13.27,
                  },
                  net_arr: {
                    without_refunds: 89.32,
                    with_refunds: 82.86,
                  },
                  gross_arr: {
                    without_refunds: 102.18,
                    with_refunds: 95.09,
                  },
                  net_arr_spread: {
                    without_refunds: 7.41,
                    with_refunds: 7.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.53,
                    with_refunds: 8.53,
                  },
                  net_total: {
                    without_refunds: 25.92,
                    with_refunds: 24.51,
                  },
                  gross_total: {
                    without_refunds: 30.61,
                    with_refunds: 29.04,
                  },
                  net_total_spread: {
                    without_refunds: 10.2,
                    with_refunds: 10.04,
                  },
                  gross_total_spread: {
                    without_refunds: 12.66,
                    with_refunds: 12.45,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0588,
                    with_refunds: 0.0577,
                  },
                  gross_mrr: {
                    without_refunds: 0.0737,
                    with_refunds: 0.0723,
                  },
                  net_arr: {
                    without_refunds: 0.3258,
                    with_refunds: 0.3023,
                  },
                  gross_arr: {
                    without_refunds: 0.3728,
                    with_refunds: 0.3469,
                  },
                  net_arr_spread: {
                    without_refunds: 0.028,
                    with_refunds: 0.028,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0323,
                    with_refunds: 0.0323,
                  },
                  net_total: {
                    without_refunds: 0.1306,
                    with_refunds: 0.1235,
                  },
                  gross_total: {
                    without_refunds: 0.1543,
                    with_refunds: 0.1463,
                  },
                  net_total_spread: {
                    without_refunds: 0.0522,
                    with_refunds: 0.0514,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0648,
                    with_refunds: 0.0637,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0887,
                    with_refunds: 0.087,
                  },
                  gross_mrr: {
                    without_refunds: 0.1112,
                    with_refunds: 0.1091,
                  },
                  net_arr: {
                    without_refunds: 0.7344,
                    with_refunds: 0.6813,
                  },
                  gross_arr: {
                    without_refunds: 0.8402,
                    with_refunds: 0.7819,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0609,
                    with_refunds: 0.0609,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0701,
                    with_refunds: 0.0701,
                  },
                  net_total: {
                    without_refunds: 0.2131,
                    with_refunds: 0.2016,
                  },
                  gross_total: {
                    without_refunds: 0.2517,
                    with_refunds: 0.2388,
                  },
                  net_total_spread: {
                    without_refunds: 0.0839,
                    with_refunds: 0.0825,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1041,
                    with_refunds: 0.1024,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2135,
                  with_refunds: 2148,
                },
                arr_users: {
                  without_refunds: 494,
                  with_refunds: 514,
                },
                arr_spread_users: {
                  without_refunds: 799,
                  with_refunds: 799,
                },
                total_users: {
                  without_refunds: 2626,
                  with_refunds: 2658,
                },
                total_spread_users: {
                  without_refunds: 2931,
                  with_refunds: 2943,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7655,
                  with_refunds: 0.7702,
                },
                arr_users: {
                  without_refunds: 0.497,
                  with_refunds: 0.5171,
                },
                arr_spread_users: {
                  without_refunds: 0.9422,
                  with_refunds: 0.9422,
                },
                total_users: {
                  without_refunds: 0.7031,
                  with_refunds: 0.7116,
                },
                total_spread_users: {
                  without_refunds: 0.8153,
                  with_refunds: 0.8186,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.1547,
                  with_refunds: 1.1617,
                },
                arr_users: {
                  without_refunds: 1.1202,
                  with_refunds: 1.1655,
                },
                arr_spread_users: {
                  without_refunds: 2.0487,
                  with_refunds: 2.0487,
                },
                total_users: {
                  without_refunds: 1.1472,
                  with_refunds: 1.1612,
                },
                total_spread_users: {
                  without_refunds: 1.3097,
                  with_refunds: 1.315,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 24021.59,
                  with_refunds: 23383.18,
                },
                gross_mrr: {
                  without_refunds: 28262.27,
                  with_refunds: 27509.69,
                },
                net_arr: {
                  without_refunds: 46717.01,
                  with_refunds: 37432.74,
                },
                gross_arr: {
                  without_refunds: 49269.69,
                  with_refunds: 39505.29,
                },
                net_arr_spread: {
                  without_refunds: 6108.2,
                  with_refunds: 6108.2,
                },
                gross_arr_spread: {
                  without_refunds: 6728.77,
                  with_refunds: 6728.77,
                },
                net_total: {
                  without_refunds: 70738.6,
                  with_refunds: 60815.92,
                },
                gross_total: {
                  without_refunds: 77531.96,
                  with_refunds: 67014.98,
                },
                net_total_spread: {
                  without_refunds: 30129.78,
                  with_refunds: 29491.37,
                },
                gross_total_spread: {
                  without_refunds: 34991.04,
                  with_refunds: 34238.46,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.2043,
                  with_refunds: 1.1946,
                },
                gross_mrr: {
                  without_refunds: 1.1301,
                  with_refunds: 1.1212,
                },
                net_arr: {
                  without_refunds: 1.186,
                  with_refunds: 1.0244,
                },
                gross_arr: {
                  without_refunds: 1.0934,
                  with_refunds: 0.942,
                },
                net_arr_spread: {
                  without_refunds: 2.1149,
                  with_refunds: 2.1149,
                },
                gross_arr_spread: {
                  without_refunds: 2.0229,
                  with_refunds: 2.0229,
                },
                net_total: {
                  without_refunds: 1.1921,
                  with_refunds: 1.0838,
                },
                gross_total: {
                  without_refunds: 1.1065,
                  with_refunds: 1.0082,
                },
                net_total_spread: {
                  without_refunds: 1.3195,
                  with_refunds: 1.313,
                },
                gross_total_spread: {
                  without_refunds: 1.2349,
                  with_refunds: 1.2288,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.2043,
                  with_refunds: 1.1946,
                },
                gross_mrr: {
                  without_refunds: 1.1301,
                  with_refunds: 1.1212,
                },
                net_arr: {
                  without_refunds: 1.186,
                  with_refunds: 1.0244,
                },
                gross_arr: {
                  without_refunds: 1.0934,
                  with_refunds: 0.942,
                },
                net_arr_spread: {
                  without_refunds: 2.1149,
                  with_refunds: 2.1149,
                },
                gross_arr_spread: {
                  without_refunds: 2.0229,
                  with_refunds: 2.0229,
                },
                net_total: {
                  without_refunds: 1.1921,
                  with_refunds: 1.0838,
                },
                gross_total: {
                  without_refunds: 1.1065,
                  with_refunds: 1.0082,
                },
                net_total_spread: {
                  without_refunds: 1.3195,
                  with_refunds: 1.313,
                },
                gross_total_spread: {
                  without_refunds: 1.2349,
                  with_refunds: 1.2288,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.61,
                  with_refunds: 8.38,
                },
                gross_mrr: {
                  without_refunds: 10.13,
                  with_refunds: 9.86,
                },
                net_arr: {
                  without_refunds: 47,
                  with_refunds: 37.66,
                },
                gross_arr: {
                  without_refunds: 49.57,
                  with_refunds: 39.74,
                },
                net_arr_spread: {
                  without_refunds: 7.2,
                  with_refunds: 7.2,
                },
                gross_arr_spread: {
                  without_refunds: 7.93,
                  with_refunds: 7.93,
                },
                net_total: {
                  without_refunds: 18.94,
                  with_refunds: 16.28,
                },
                gross_total: {
                  without_refunds: 20.76,
                  with_refunds: 17.94,
                },
                net_total_spread: {
                  without_refunds: 8.38,
                  with_refunds: 8.2,
                },
                gross_total_spread: {
                  without_refunds: 9.73,
                  with_refunds: 9.52,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.25,
                  with_refunds: 10.89,
                },
                gross_mrr: {
                  without_refunds: 13.24,
                  with_refunds: 12.81,
                },
                net_arr: {
                  without_refunds: 94.57,
                  with_refunds: 72.83,
                },
                gross_arr: {
                  without_refunds: 99.74,
                  with_refunds: 76.86,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.42,
                  with_refunds: 8.42,
                },
                net_total: {
                  without_refunds: 26.94,
                  with_refunds: 22.88,
                },
                gross_total: {
                  without_refunds: 29.52,
                  with_refunds: 25.21,
                },
                net_total_spread: {
                  without_refunds: 10.28,
                  with_refunds: 10.02,
                },
                gross_total_spread: {
                  without_refunds: 11.94,
                  with_refunds: 11.63,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.76,
                    with_refunds: 15.4,
                  },
                  gross_mrr: {
                    without_refunds: 19.1,
                    with_refunds: 18.66,
                  },
                  net_arr: {
                    without_refunds: 86.63,
                    with_refunds: 74.42,
                  },
                  gross_arr: {
                    without_refunds: 94.9,
                    with_refunds: 81.93,
                  },
                  net_arr_spread: {
                    without_refunds: 10.61,
                    with_refunds: 10.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 11.86,
                    with_refunds: 11.86,
                  },
                  net_total: {
                    without_refunds: 34.83,
                    with_refunds: 31.31,
                  },
                  gross_total: {
                    without_refunds: 39.52,
                    with_refunds: 35.74,
                  },
                  net_total_spread: {
                    without_refunds: 14.73,
                    with_refunds: 14.45,
                  },
                  gross_total_spread: {
                    without_refunds: 17.62,
                    with_refunds: 17.27,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.04,
                    with_refunds: 21.47,
                  },
                  gross_mrr: {
                    without_refunds: 26.76,
                    with_refunds: 26.08,
                  },
                  net_arr: {
                    without_refunds: 183.89,
                    with_refunds: 155.68,
                  },
                  gross_arr: {
                    without_refunds: 201.92,
                    with_refunds: 171.95,
                  },
                  net_arr_spread: {
                    without_refunds: 15.05,
                    with_refunds: 15.05,
                  },
                  gross_arr_spread: {
                    without_refunds: 16.95,
                    with_refunds: 16.95,
                  },
                  net_total: {
                    without_refunds: 52.86,
                    with_refunds: 47.39,
                  },
                  gross_total: {
                    without_refunds: 60.14,
                    with_refunds: 54.25,
                  },
                  net_total_spread: {
                    without_refunds: 20.48,
                    with_refunds: 20.06,
                  },
                  gross_total_spread: {
                    without_refunds: 24.6,
                    with_refunds: 24.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1296,
                    with_refunds: 0.1266,
                  },
                  gross_mrr: {
                    without_refunds: 0.1571,
                    with_refunds: 0.1534,
                  },
                  net_arr: {
                    without_refunds: 0.7123,
                    with_refunds: 0.6119,
                  },
                  gross_arr: {
                    without_refunds: 0.7803,
                    with_refunds: 0.6737,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0872,
                    with_refunds: 0.0872,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0975,
                    with_refunds: 0.0975,
                  },
                  net_total: {
                    without_refunds: 0.2864,
                    with_refunds: 0.2574,
                  },
                  gross_total: {
                    without_refunds: 0.3249,
                    with_refunds: 0.2939,
                  },
                  net_total_spread: {
                    without_refunds: 0.1211,
                    with_refunds: 0.1188,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1448,
                    with_refunds: 0.142,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1812,
                    with_refunds: 0.1766,
                  },
                  gross_mrr: {
                    without_refunds: 0.2201,
                    with_refunds: 0.2144,
                  },
                  net_arr: {
                    without_refunds: 1.512,
                    with_refunds: 1.2801,
                  },
                  gross_arr: {
                    without_refunds: 1.6602,
                    with_refunds: 1.4139,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1237,
                    with_refunds: 0.1237,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1394,
                    with_refunds: 0.1394,
                  },
                  net_total: {
                    without_refunds: 0.4346,
                    with_refunds: 0.3897,
                  },
                  gross_total: {
                    without_refunds: 0.4945,
                    with_refunds: 0.4461,
                  },
                  net_total_spread: {
                    without_refunds: 0.1684,
                    with_refunds: 0.1649,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2023,
                    with_refunds: 0.198,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1563,
                  with_refunds: 1567,
                },
                arr_users: {
                  without_refunds: 14,
                  with_refunds: 22,
                },
                arr_spread_users: {
                  without_refunds: 807,
                  with_refunds: 807,
                },
                total_users: {
                  without_refunds: 1577,
                  with_refunds: 1589,
                },
                total_spread_users: {
                  without_refunds: 2370,
                  with_refunds: 2374,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5604,
                  with_refunds: 0.5619,
                },
                arr_users: {
                  without_refunds: 0.0141,
                  with_refunds: 0.0221,
                },
                arr_spread_users: {
                  without_refunds: 0.9517,
                  with_refunds: 0.9517,
                },
                total_users: {
                  without_refunds: 0.4222,
                  with_refunds: 0.4254,
                },
                total_spread_users: {
                  without_refunds: 0.6592,
                  with_refunds: 0.6604,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7321,
                  with_refunds: 0.7295,
                },
                arr_users: {
                  without_refunds: 0.0283,
                  with_refunds: 0.0428,
                },
                arr_spread_users: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                total_users: {
                  without_refunds: 0.6005,
                  with_refunds: 0.5978,
                },
                total_spread_users: {
                  without_refunds: 0.8086,
                  with_refunds: 0.8067,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 17527.75,
                  with_refunds: 17223.43,
                },
                gross_mrr: {
                  without_refunds: 20561.84,
                  with_refunds: 20215.09,
                },
                net_arr: {
                  without_refunds: 1344.01,
                  with_refunds: 158.78,
                },
                gross_arr: {
                  without_refunds: 1652.03,
                  with_refunds: 353.8,
                },
                net_arr_spread: {
                  without_refunds: 6169.34,
                  with_refunds: 6169.34,
                },
                gross_arr_spread: {
                  without_refunds: 6807.76,
                  with_refunds: 6807.76,
                },
                net_total: {
                  without_refunds: 18871.76,
                  with_refunds: 17382.21,
                },
                gross_total: {
                  without_refunds: 22213.87,
                  with_refunds: 20568.89,
                },
                net_total_spread: {
                  without_refunds: 23697.09,
                  with_refunds: 23392.77,
                },
                gross_total_spread: {
                  without_refunds: 27369.6,
                  with_refunds: 27022.84,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8787,
                  with_refunds: 0.8799,
                },
                gross_mrr: {
                  without_refunds: 0.8222,
                  with_refunds: 0.8239,
                },
                net_arr: {
                  without_refunds: 0.0341,
                  with_refunds: 0.0043,
                },
                gross_arr: {
                  without_refunds: 0.0367,
                  with_refunds: 0.0084,
                },
                net_arr_spread: {
                  without_refunds: 2.1361,
                  with_refunds: 2.1361,
                },
                gross_arr_spread: {
                  without_refunds: 2.0466,
                  with_refunds: 2.0466,
                },
                net_total: {
                  without_refunds: 0.318,
                  with_refunds: 0.3098,
                },
                gross_total: {
                  without_refunds: 0.317,
                  with_refunds: 0.3094,
                },
                net_total_spread: {
                  without_refunds: 1.0378,
                  with_refunds: 1.0414,
                },
                gross_total_spread: {
                  without_refunds: 0.9659,
                  with_refunds: 0.9699,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7297,
                  with_refunds: 0.7366,
                },
                gross_mrr: {
                  without_refunds: 0.7275,
                  with_refunds: 0.7348,
                },
                net_arr: {
                  without_refunds: 0.0288,
                  with_refunds: 0.0042,
                },
                gross_arr: {
                  without_refunds: 0.0335,
                  with_refunds: 0.009,
                },
                net_arr_spread: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                gross_arr_spread: {
                  without_refunds: 1.0117,
                  with_refunds: 1.0117,
                },
                net_total: {
                  without_refunds: 0.2668,
                  with_refunds: 0.2858,
                },
                gross_total: {
                  without_refunds: 0.2865,
                  with_refunds: 0.3069,
                },
                net_total_spread: {
                  without_refunds: 0.7865,
                  with_refunds: 0.7932,
                },
                gross_total_spread: {
                  without_refunds: 0.7822,
                  with_refunds: 0.7893,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.28,
                  with_refunds: 6.18,
                },
                gross_mrr: {
                  without_refunds: 7.37,
                  with_refunds: 7.25,
                },
                net_arr: {
                  without_refunds: 1.35,
                  with_refunds: 0.16,
                },
                gross_arr: {
                  without_refunds: 1.66,
                  with_refunds: 0.36,
                },
                net_arr_spread: {
                  without_refunds: 7.28,
                  with_refunds: 7.28,
                },
                gross_arr_spread: {
                  without_refunds: 8.03,
                  with_refunds: 8.03,
                },
                net_total: {
                  without_refunds: 5.05,
                  with_refunds: 4.65,
                },
                gross_total: {
                  without_refunds: 5.95,
                  with_refunds: 5.51,
                },
                net_total_spread: {
                  without_refunds: 6.59,
                  with_refunds: 6.51,
                },
                gross_total_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.52,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.21,
                  with_refunds: 10.99,
                },
                gross_mrr: {
                  without_refunds: 13.16,
                  with_refunds: 12.9,
                },
                net_arr: {
                  without_refunds: 96,
                  with_refunds: 7.22,
                },
                gross_arr: {
                  without_refunds: 118,
                  with_refunds: 16.08,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.44,
                  with_refunds: 8.44,
                },
                net_total: {
                  without_refunds: 11.97,
                  with_refunds: 10.94,
                },
                gross_total: {
                  without_refunds: 14.09,
                  with_refunds: 12.94,
                },
                net_total_spread: {
                  without_refunds: 10,
                  with_refunds: 9.85,
                },
                gross_total_spread: {
                  without_refunds: 11.55,
                  with_refunds: 11.38,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.05,
                    with_refunds: 21.58,
                  },
                  gross_mrr: {
                    without_refunds: 26.47,
                    with_refunds: 25.91,
                  },
                  net_arr: {
                    without_refunds: 87.98,
                    with_refunds: 74.58,
                  },
                  gross_arr: {
                    without_refunds: 96.56,
                    with_refunds: 82.29,
                  },
                  net_arr_spread: {
                    without_refunds: 17.88,
                    with_refunds: 17.88,
                  },
                  gross_arr_spread: {
                    without_refunds: 19.89,
                    with_refunds: 19.89,
                  },
                  net_total: {
                    without_refunds: 39.88,
                    with_refunds: 35.96,
                  },
                  gross_total: {
                    without_refunds: 45.47,
                    with_refunds: 41.25,
                  },
                  net_total_spread: {
                    without_refunds: 21.32,
                    with_refunds: 20.96,
                  },
                  gross_total_spread: {
                    without_refunds: 25.23,
                    with_refunds: 24.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.25,
                    with_refunds: 32.46,
                  },
                  gross_mrr: {
                    without_refunds: 39.92,
                    with_refunds: 38.98,
                  },
                  net_arr: {
                    without_refunds: 279.89,
                    with_refunds: 162.9,
                  },
                  gross_arr: {
                    without_refunds: 319.92,
                    with_refunds: 188.04,
                  },
                  net_arr_spread: {
                    without_refunds: 22.7,
                    with_refunds: 22.7,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.39,
                    with_refunds: 25.39,
                  },
                  net_total: {
                    without_refunds: 64.83,
                    with_refunds: 58.33,
                  },
                  gross_total: {
                    without_refunds: 74.22,
                    with_refunds: 67.2,
                  },
                  net_total_spread: {
                    without_refunds: 30.48,
                    with_refunds: 29.91,
                  },
                  gross_total_spread: {
                    without_refunds: 36.15,
                    with_refunds: 35.47,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1813,
                    with_refunds: 0.1774,
                  },
                  gross_mrr: {
                    without_refunds: 0.2177,
                    with_refunds: 0.213,
                  },
                  net_arr: {
                    without_refunds: 0.7234,
                    with_refunds: 0.6132,
                  },
                  gross_arr: {
                    without_refunds: 0.794,
                    with_refunds: 0.6766,
                  },
                  net_arr_spread: {
                    without_refunds: 0.147,
                    with_refunds: 0.147,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1635,
                    with_refunds: 0.1635,
                  },
                  net_total: {
                    without_refunds: 0.3279,
                    with_refunds: 0.2957,
                  },
                  gross_total: {
                    without_refunds: 0.3738,
                    with_refunds: 0.3391,
                  },
                  net_total_spread: {
                    without_refunds: 0.1753,
                    with_refunds: 0.1723,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2074,
                    with_refunds: 0.2038,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2734,
                    with_refunds: 0.2669,
                  },
                  gross_mrr: {
                    without_refunds: 0.3282,
                    with_refunds: 0.3205,
                  },
                  net_arr: {
                    without_refunds: 2.3014,
                    with_refunds: 1.3394,
                  },
                  gross_arr: {
                    without_refunds: 2.6305,
                    with_refunds: 1.5461,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1866,
                    with_refunds: 0.1866,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2087,
                    with_refunds: 0.2087,
                  },
                  net_total: {
                    without_refunds: 0.533,
                    with_refunds: 0.4796,
                  },
                  gross_total: {
                    without_refunds: 0.6103,
                    with_refunds: 0.5525,
                  },
                  net_total_spread: {
                    without_refunds: 0.2506,
                    with_refunds: 0.2459,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2972,
                    with_refunds: 0.2916,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1236,
                  with_refunds: 1240,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 808,
                  with_refunds: 808,
                },
                total_users: {
                  without_refunds: 1239,
                  with_refunds: 1245,
                },
                total_spread_users: {
                  without_refunds: 2044,
                  with_refunds: 2048,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4432,
                  with_refunds: 0.4446,
                },
                arr_users: {
                  without_refunds: 0.003,
                  with_refunds: 0.005,
                },
                arr_spread_users: {
                  without_refunds: 0.9528,
                  with_refunds: 0.9528,
                },
                total_users: {
                  without_refunds: 0.3317,
                  with_refunds: 0.3333,
                },
                total_spread_users: {
                  without_refunds: 0.5686,
                  with_refunds: 0.5697,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7908,
                  with_refunds: 0.7913,
                },
                arr_users: {
                  without_refunds: 0.2143,
                  with_refunds: 0.2273,
                },
                arr_spread_users: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                total_users: {
                  without_refunds: 0.7857,
                  with_refunds: 0.7835,
                },
                total_spread_users: {
                  without_refunds: 0.8624,
                  with_refunds: 0.8627,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13963.41,
                  with_refunds: 13748.95,
                },
                gross_mrr: {
                  without_refunds: 16329.74,
                  with_refunds: 16088.8,
                },
                net_arr: {
                  without_refunds: 322.97,
                  with_refunds: 148.49,
                },
                gross_arr: {
                  without_refunds: 379.97,
                  with_refunds: 199.97,
                },
                net_arr_spread: {
                  without_refunds: 6180.28,
                  with_refunds: 6180.28,
                },
                gross_arr_spread: {
                  without_refunds: 6822.92,
                  with_refunds: 6822.92,
                },
                net_total: {
                  without_refunds: 14286.38,
                  with_refunds: 13897.44,
                },
                gross_total: {
                  without_refunds: 16709.71,
                  with_refunds: 16288.77,
                },
                net_total_spread: {
                  without_refunds: 20143.69,
                  with_refunds: 19929.23,
                },
                gross_total_spread: {
                  without_refunds: 23152.65,
                  with_refunds: 22911.72,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7,
                  with_refunds: 0.7024,
                },
                gross_mrr: {
                  without_refunds: 0.6529,
                  with_refunds: 0.6557,
                },
                net_arr: {
                  without_refunds: 0.0082,
                  with_refunds: 0.0041,
                },
                gross_arr: {
                  without_refunds: 0.0084,
                  with_refunds: 0.0048,
                },
                net_arr_spread: {
                  without_refunds: 2.1398,
                  with_refunds: 2.1398,
                },
                gross_arr_spread: {
                  without_refunds: 2.0512,
                  with_refunds: 2.0512,
                },
                net_total: {
                  without_refunds: 0.2408,
                  with_refunds: 0.2477,
                },
                gross_total: {
                  without_refunds: 0.2385,
                  with_refunds: 0.245,
                },
                net_total_spread: {
                  without_refunds: 0.8822,
                  with_refunds: 0.8872,
                },
                gross_total_spread: {
                  without_refunds: 0.8171,
                  with_refunds: 0.8223,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7966,
                  with_refunds: 0.7983,
                },
                gross_mrr: {
                  without_refunds: 0.7942,
                  with_refunds: 0.7959,
                },
                net_arr: {
                  without_refunds: 0.2403,
                  with_refunds: 0.9352,
                },
                gross_arr: {
                  without_refunds: 0.23,
                  with_refunds: 0.5652,
                },
                net_arr_spread: {
                  without_refunds: 1.0018,
                  with_refunds: 1.0018,
                },
                gross_arr_spread: {
                  without_refunds: 1.0022,
                  with_refunds: 1.0022,
                },
                net_total: {
                  without_refunds: 0.757,
                  with_refunds: 0.7995,
                },
                gross_total: {
                  without_refunds: 0.7522,
                  with_refunds: 0.7919,
                },
                net_total_spread: {
                  without_refunds: 0.85,
                  with_refunds: 0.8519,
                },
                gross_total_spread: {
                  without_refunds: 0.8459,
                  with_refunds: 0.8479,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.01,
                  with_refunds: 4.93,
                },
                gross_mrr: {
                  without_refunds: 5.86,
                  with_refunds: 5.77,
                },
                net_arr: {
                  without_refunds: 0.32,
                  with_refunds: 0.15,
                },
                gross_arr: {
                  without_refunds: 0.38,
                  with_refunds: 0.2,
                },
                net_arr_spread: {
                  without_refunds: 7.29,
                  with_refunds: 7.29,
                },
                gross_arr_spread: {
                  without_refunds: 8.05,
                  with_refunds: 8.05,
                },
                net_total: {
                  without_refunds: 3.83,
                  with_refunds: 3.72,
                },
                gross_total: {
                  without_refunds: 4.47,
                  with_refunds: 4.36,
                },
                net_total_spread: {
                  without_refunds: 5.6,
                  with_refunds: 5.54,
                },
                gross_total_spread: {
                  without_refunds: 6.44,
                  with_refunds: 6.37,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.3,
                  with_refunds: 11.09,
                },
                gross_mrr: {
                  without_refunds: 13.21,
                  with_refunds: 12.97,
                },
                net_arr: {
                  without_refunds: 107.66,
                  with_refunds: 29.7,
                },
                gross_arr: {
                  without_refunds: 126.66,
                  with_refunds: 39.99,
                },
                net_arr_spread: {
                  without_refunds: 7.65,
                  with_refunds: 7.65,
                },
                gross_arr_spread: {
                  without_refunds: 8.44,
                  with_refunds: 8.44,
                },
                net_total: {
                  without_refunds: 11.53,
                  with_refunds: 11.16,
                },
                gross_total: {
                  without_refunds: 13.49,
                  with_refunds: 13.08,
                },
                net_total_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.73,
                },
                gross_total_spread: {
                  without_refunds: 11.33,
                  with_refunds: 11.19,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.06,
                    with_refunds: 26.51,
                  },
                  gross_mrr: {
                    without_refunds: 32.33,
                    with_refunds: 31.68,
                  },
                  net_arr: {
                    without_refunds: 88.31,
                    with_refunds: 74.73,
                  },
                  gross_arr: {
                    without_refunds: 96.95,
                    with_refunds: 82.49,
                  },
                  net_arr_spread: {
                    without_refunds: 25.17,
                    with_refunds: 25.17,
                  },
                  gross_arr_spread: {
                    without_refunds: 27.93,
                    with_refunds: 27.93,
                  },
                  net_total: {
                    without_refunds: 43.7,
                    with_refunds: 39.68,
                  },
                  gross_total: {
                    without_refunds: 49.94,
                    with_refunds: 45.61,
                  },
                  net_total_spread: {
                    without_refunds: 26.93,
                    with_refunds: 26.5,
                  },
                  gross_total_spread: {
                    without_refunds: 31.67,
                    with_refunds: 31.16,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.55,
                    with_refunds: 43.55,
                  },
                  gross_mrr: {
                    without_refunds: 53.13,
                    with_refunds: 51.95,
                  },
                  net_arr: {
                    without_refunds: 387.55,
                    with_refunds: 192.6,
                  },
                  gross_arr: {
                    without_refunds: 446.58,
                    with_refunds: 228.03,
                  },
                  net_arr_spread: {
                    without_refunds: 30.34,
                    with_refunds: 30.34,
                  },
                  gross_arr_spread: {
                    without_refunds: 33.83,
                    with_refunds: 33.83,
                  },
                  net_total: {
                    without_refunds: 76.36,
                    with_refunds: 69.5,
                  },
                  gross_total: {
                    without_refunds: 87.71,
                    with_refunds: 80.28,
                  },
                  net_total_spread: {
                    without_refunds: 40.34,
                    with_refunds: 39.64,
                  },
                  gross_total_spread: {
                    without_refunds: 47.48,
                    with_refunds: 46.65,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2225,
                    with_refunds: 0.218,
                  },
                  gross_mrr: {
                    without_refunds: 0.2658,
                    with_refunds: 0.2605,
                  },
                  net_arr: {
                    without_refunds: 0.7261,
                    with_refunds: 0.6144,
                  },
                  gross_arr: {
                    without_refunds: 0.7971,
                    with_refunds: 0.6783,
                  },
                  net_arr_spread: {
                    without_refunds: 0.207,
                    with_refunds: 0.207,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2297,
                    with_refunds: 0.2297,
                  },
                  net_total: {
                    without_refunds: 0.3593,
                    with_refunds: 0.3263,
                  },
                  gross_total: {
                    without_refunds: 0.4106,
                    with_refunds: 0.375,
                  },
                  net_total_spread: {
                    without_refunds: 0.2214,
                    with_refunds: 0.2179,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2604,
                    with_refunds: 0.2562,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3663,
                    with_refunds: 0.3581,
                  },
                  gross_mrr: {
                    without_refunds: 0.4369,
                    with_refunds: 0.4272,
                  },
                  net_arr: {
                    without_refunds: 3.1866,
                    with_refunds: 1.5836,
                  },
                  gross_arr: {
                    without_refunds: 3.6719,
                    with_refunds: 1.8749,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2495,
                    with_refunds: 0.2495,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2782,
                    with_refunds: 0.2782,
                  },
                  net_total: {
                    without_refunds: 0.6278,
                    with_refunds: 0.5714,
                  },
                  gross_total: {
                    without_refunds: 0.7212,
                    with_refunds: 0.6601,
                  },
                  net_total_spread: {
                    without_refunds: 0.3317,
                    with_refunds: 0.326,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3904,
                    with_refunds: 0.3836,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 856,
                  with_refunds: 860,
                },
                arr_users: {
                  without_refunds: 7,
                  with_refunds: 8,
                },
                arr_spread_users: {
                  without_refunds: 814,
                  with_refunds: 814,
                },
                total_users: {
                  without_refunds: 863,
                  with_refunds: 868,
                },
                total_spread_users: {
                  without_refunds: 1670,
                  with_refunds: 1674,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3069,
                  with_refunds: 0.3084,
                },
                arr_users: {
                  without_refunds: 0.007,
                  with_refunds: 0.008,
                },
                arr_spread_users: {
                  without_refunds: 0.9599,
                  with_refunds: 0.9599,
                },
                total_users: {
                  without_refunds: 0.2311,
                  with_refunds: 0.2324,
                },
                total_spread_users: {
                  without_refunds: 0.4645,
                  with_refunds: 0.4656,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6926,
                  with_refunds: 0.6935,
                },
                arr_users: {
                  without_refunds: 2.3333,
                  with_refunds: 1.6,
                },
                arr_spread_users: {
                  without_refunds: 1.0074,
                  with_refunds: 1.0074,
                },
                total_users: {
                  without_refunds: 0.6965,
                  with_refunds: 0.6972,
                },
                total_spread_users: {
                  without_refunds: 0.817,
                  with_refunds: 0.8174,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9780.79,
                  with_refunds: 9462.04,
                },
                gross_mrr: {
                  without_refunds: 11167.62,
                  with_refunds: 10813.85,
                },
                net_arr: {
                  without_refunds: 798.51,
                  with_refunds: 576.84,
                },
                gross_arr: {
                  without_refunds: 1012.84,
                  with_refunds: 765.8,
                },
                net_arr_spread: {
                  without_refunds: 6236.34,
                  with_refunds: 6236.34,
                },
                gross_arr_spread: {
                  without_refunds: 6894.99,
                  with_refunds: 6894.99,
                },
                net_total: {
                  without_refunds: 10579.3,
                  with_refunds: 10038.89,
                },
                gross_total: {
                  without_refunds: 12180.46,
                  with_refunds: 11579.65,
                },
                net_total_spread: {
                  without_refunds: 16017.13,
                  with_refunds: 15698.38,
                },
                gross_total_spread: {
                  without_refunds: 18062.61,
                  with_refunds: 17708.84,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4904,
                  with_refunds: 0.4834,
                },
                gross_mrr: {
                  without_refunds: 0.4465,
                  with_refunds: 0.4407,
                },
                net_arr: {
                  without_refunds: 0.0203,
                  with_refunds: 0.0158,
                },
                gross_arr: {
                  without_refunds: 0.0225,
                  with_refunds: 0.0183,
                },
                net_arr_spread: {
                  without_refunds: 2.1593,
                  with_refunds: 2.1593,
                },
                gross_arr_spread: {
                  without_refunds: 2.0728,
                  with_refunds: 2.0728,
                },
                net_total: {
                  without_refunds: 0.1783,
                  with_refunds: 0.1789,
                },
                gross_total: {
                  without_refunds: 0.1738,
                  with_refunds: 0.1742,
                },
                net_total_spread: {
                  without_refunds: 0.7014,
                  with_refunds: 0.6989,
                },
                gross_total_spread: {
                  without_refunds: 0.6374,
                  with_refunds: 0.6356,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7005,
                  with_refunds: 0.6882,
                },
                gross_mrr: {
                  without_refunds: 0.6839,
                  with_refunds: 0.6721,
                },
                net_arr: {
                  without_refunds: 2.4724,
                  with_refunds: 3.8847,
                },
                gross_arr: {
                  without_refunds: 2.6656,
                  with_refunds: 3.8296,
                },
                net_arr_spread: {
                  without_refunds: 1.0091,
                  with_refunds: 1.0091,
                },
                gross_arr_spread: {
                  without_refunds: 1.0106,
                  with_refunds: 1.0106,
                },
                net_total: {
                  without_refunds: 0.7405,
                  with_refunds: 0.7224,
                },
                gross_total: {
                  without_refunds: 0.7289,
                  with_refunds: 0.7109,
                },
                net_total_spread: {
                  without_refunds: 0.7951,
                  with_refunds: 0.7877,
                },
                gross_total_spread: {
                  without_refunds: 0.7802,
                  with_refunds: 0.7729,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.51,
                  with_refunds: 3.39,
                },
                gross_mrr: {
                  without_refunds: 4,
                  with_refunds: 3.88,
                },
                net_arr: {
                  without_refunds: 0.8,
                  with_refunds: 0.58,
                },
                gross_arr: {
                  without_refunds: 1.02,
                  with_refunds: 0.77,
                },
                net_arr_spread: {
                  without_refunds: 7.35,
                  with_refunds: 7.35,
                },
                gross_arr_spread: {
                  without_refunds: 8.13,
                  with_refunds: 8.13,
                },
                net_total: {
                  without_refunds: 2.83,
                  with_refunds: 2.69,
                },
                gross_total: {
                  without_refunds: 3.26,
                  with_refunds: 3.1,
                },
                net_total_spread: {
                  without_refunds: 4.46,
                  with_refunds: 4.37,
                },
                gross_total_spread: {
                  without_refunds: 5.02,
                  with_refunds: 4.93,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.43,
                  with_refunds: 11,
                },
                gross_mrr: {
                  without_refunds: 13.05,
                  with_refunds: 12.57,
                },
                net_arr: {
                  without_refunds: 114.07,
                  with_refunds: 72.11,
                },
                gross_arr: {
                  without_refunds: 144.69,
                  with_refunds: 95.73,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.47,
                  with_refunds: 8.47,
                },
                net_total: {
                  without_refunds: 12.26,
                  with_refunds: 11.57,
                },
                gross_total: {
                  without_refunds: 14.11,
                  with_refunds: 13.34,
                },
                net_total_spread: {
                  without_refunds: 9.59,
                  with_refunds: 9.38,
                },
                gross_total_spread: {
                  without_refunds: 10.82,
                  with_refunds: 10.58,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.56,
                    with_refunds: 29.9,
                  },
                  gross_mrr: {
                    without_refunds: 36.33,
                    with_refunds: 35.56,
                  },
                  net_arr: {
                    without_refunds: 89.11,
                    with_refunds: 75.31,
                  },
                  gross_arr: {
                    without_refunds: 97.96,
                    with_refunds: 83.26,
                  },
                  net_arr_spread: {
                    without_refunds: 32.53,
                    with_refunds: 32.53,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.06,
                    with_refunds: 36.06,
                  },
                  net_total: {
                    without_refunds: 46.54,
                    with_refunds: 42.37,
                  },
                  gross_total: {
                    without_refunds: 53.2,
                    with_refunds: 48.71,
                  },
                  net_total_spread: {
                    without_refunds: 31.38,
                    with_refunds: 30.87,
                  },
                  gross_total_spread: {
                    without_refunds: 36.69,
                    with_refunds: 36.09,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 55.98,
                    with_refunds: 54.55,
                  },
                  gross_mrr: {
                    without_refunds: 66.18,
                    with_refunds: 64.53,
                  },
                  net_arr: {
                    without_refunds: 501.62,
                    with_refunds: 264.7,
                  },
                  gross_arr: {
                    without_refunds: 591.27,
                    with_refunds: 323.75,
                  },
                  net_arr_spread: {
                    without_refunds: 38.01,
                    with_refunds: 38.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.3,
                    with_refunds: 42.3,
                  },
                  net_total: {
                    without_refunds: 88.62,
                    with_refunds: 81.06,
                  },
                  gross_total: {
                    without_refunds: 101.82,
                    with_refunds: 93.62,
                  },
                  net_total_spread: {
                    without_refunds: 49.93,
                    with_refunds: 49.02,
                  },
                  gross_total_spread: {
                    without_refunds: 58.29,
                    with_refunds: 57.23,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2513,
                    with_refunds: 0.2458,
                  },
                  gross_mrr: {
                    without_refunds: 0.2987,
                    with_refunds: 0.2923,
                  },
                  net_arr: {
                    without_refunds: 0.7327,
                    with_refunds: 0.6192,
                  },
                  gross_arr: {
                    without_refunds: 0.8055,
                    with_refunds: 0.6846,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2674,
                    with_refunds: 0.2674,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2965,
                    with_refunds: 0.2965,
                  },
                  net_total: {
                    without_refunds: 0.3826,
                    with_refunds: 0.3484,
                  },
                  gross_total: {
                    without_refunds: 0.4374,
                    with_refunds: 0.4005,
                  },
                  net_total_spread: {
                    without_refunds: 0.258,
                    with_refunds: 0.2538,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3017,
                    with_refunds: 0.2967,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4603,
                    with_refunds: 0.4486,
                  },
                  gross_mrr: {
                    without_refunds: 0.5441,
                    with_refunds: 0.5306,
                  },
                  net_arr: {
                    without_refunds: 4.1245,
                    with_refunds: 2.1765,
                  },
                  gross_arr: {
                    without_refunds: 4.8616,
                    with_refunds: 2.662,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3125,
                    with_refunds: 0.3125,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3478,
                    with_refunds: 0.3478,
                  },
                  net_total: {
                    without_refunds: 0.7286,
                    with_refunds: 0.6665,
                  },
                  gross_total: {
                    without_refunds: 0.8372,
                    with_refunds: 0.7698,
                  },
                  net_total_spread: {
                    without_refunds: 0.4105,
                    with_refunds: 0.4031,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4793,
                    with_refunds: 0.4706,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 710,
                  with_refunds: 715,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 10,
                },
                arr_spread_users: {
                  without_refunds: 823,
                  with_refunds: 823,
                },
                total_users: {
                  without_refunds: 718,
                  with_refunds: 720,
                },
                total_spread_users: {
                  without_refunds: 1531,
                  with_refunds: 1534,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2546,
                  with_refunds: 0.2564,
                },
                arr_users: {
                  without_refunds: 0.0101,
                  with_refunds: 0.0101,
                },
                arr_spread_users: {
                  without_refunds: 0.9705,
                  with_refunds: 0.9705,
                },
                total_users: {
                  without_refunds: 0.1922,
                  with_refunds: 0.1928,
                },
                total_spread_users: {
                  without_refunds: 0.4259,
                  with_refunds: 0.4267,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8294,
                  with_refunds: 0.8314,
                },
                arr_users: {
                  without_refunds: 1.4286,
                  with_refunds: 1.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0111,
                  with_refunds: 1.0111,
                },
                total_users: {
                  without_refunds: 0.832,
                  with_refunds: 0.8295,
                },
                total_spread_users: {
                  without_refunds: 0.9168,
                  with_refunds: 0.9164,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8178.96,
                  with_refunds: 8049.85,
                },
                gross_mrr: {
                  without_refunds: 9315.73,
                  with_refunds: 9158.99,
                },
                net_arr: {
                  without_refunds: 1172.51,
                  with_refunds: 1053.52,
                },
                gross_arr: {
                  without_refunds: 1482.74,
                  with_refunds: 1342.75,
                },
                net_arr_spread: {
                  without_refunds: 6324.13,
                  with_refunds: 6324.13,
                },
                gross_arr_spread: {
                  without_refunds: 7006.88,
                  with_refunds: 7006.88,
                },
                net_total: {
                  without_refunds: 9351.47,
                  with_refunds: 9103.36,
                },
                gross_total: {
                  without_refunds: 10798.47,
                  with_refunds: 10501.74,
                },
                net_total_spread: {
                  without_refunds: 14503.09,
                  with_refunds: 14373.98,
                },
                gross_total_spread: {
                  without_refunds: 16322.61,
                  with_refunds: 16165.87,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.41,
                  with_refunds: 0.4113,
                },
                gross_mrr: {
                  without_refunds: 0.3725,
                  with_refunds: 0.3733,
                },
                net_arr: {
                  without_refunds: 0.0298,
                  with_refunds: 0.0288,
                },
                gross_arr: {
                  without_refunds: 0.0329,
                  with_refunds: 0.032,
                },
                net_arr_spread: {
                  without_refunds: 2.1897,
                  with_refunds: 2.1897,
                },
                gross_arr_spread: {
                  without_refunds: 2.1065,
                  with_refunds: 2.1065,
                },
                net_total: {
                  without_refunds: 0.1576,
                  with_refunds: 0.1622,
                },
                gross_total: {
                  without_refunds: 0.1541,
                  with_refunds: 0.158,
                },
                net_total_spread: {
                  without_refunds: 0.6351,
                  with_refunds: 0.6399,
                },
                gross_total_spread: {
                  without_refunds: 0.576,
                  with_refunds: 0.5802,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8362,
                  with_refunds: 0.8508,
                },
                gross_mrr: {
                  without_refunds: 0.8342,
                  with_refunds: 0.847,
                },
                net_arr: {
                  without_refunds: 1.4684,
                  with_refunds: 1.8263,
                },
                gross_arr: {
                  without_refunds: 1.4639,
                  with_refunds: 1.7534,
                },
                net_arr_spread: {
                  without_refunds: 1.0141,
                  with_refunds: 1.0141,
                },
                gross_arr_spread: {
                  without_refunds: 1.0162,
                  with_refunds: 1.0162,
                },
                net_total: {
                  without_refunds: 0.8839,
                  with_refunds: 0.9068,
                },
                gross_total: {
                  without_refunds: 0.8865,
                  with_refunds: 0.9069,
                },
                net_total_spread: {
                  without_refunds: 0.9055,
                  with_refunds: 0.9156,
                },
                gross_total_spread: {
                  without_refunds: 0.9037,
                  with_refunds: 0.9129,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.93,
                  with_refunds: 2.89,
                },
                gross_mrr: {
                  without_refunds: 3.34,
                  with_refunds: 3.28,
                },
                net_arr: {
                  without_refunds: 1.18,
                  with_refunds: 1.06,
                },
                gross_arr: {
                  without_refunds: 1.49,
                  with_refunds: 1.35,
                },
                net_arr_spread: {
                  without_refunds: 7.46,
                  with_refunds: 7.46,
                },
                gross_arr_spread: {
                  without_refunds: 8.26,
                  with_refunds: 8.26,
                },
                net_total: {
                  without_refunds: 2.5,
                  with_refunds: 2.44,
                },
                gross_total: {
                  without_refunds: 2.89,
                  with_refunds: 2.81,
                },
                net_total_spread: {
                  without_refunds: 4.03,
                  with_refunds: 4,
                },
                gross_total_spread: {
                  without_refunds: 4.54,
                  with_refunds: 4.5,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.52,
                  with_refunds: 11.26,
                },
                gross_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 12.81,
                },
                net_arr: {
                  without_refunds: 117.25,
                  with_refunds: 105.35,
                },
                gross_arr: {
                  without_refunds: 148.27,
                  with_refunds: 134.27,
                },
                net_arr_spread: {
                  without_refunds: 7.68,
                  with_refunds: 7.68,
                },
                gross_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                net_total: {
                  without_refunds: 13.02,
                  with_refunds: 12.64,
                },
                gross_total: {
                  without_refunds: 15.04,
                  with_refunds: 14.59,
                },
                net_total_spread: {
                  without_refunds: 9.47,
                  with_refunds: 9.37,
                },
                gross_total_spread: {
                  without_refunds: 10.66,
                  with_refunds: 10.54,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.5,
                    with_refunds: 32.79,
                  },
                  gross_mrr: {
                    without_refunds: 39.67,
                    with_refunds: 38.84,
                  },
                  net_arr: {
                    without_refunds: 90.29,
                    with_refunds: 76.37,
                  },
                  gross_arr: {
                    without_refunds: 99.46,
                    with_refunds: 84.61,
                  },
                  net_arr_spread: {
                    without_refunds: 39.98,
                    with_refunds: 39.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 44.33,
                    with_refunds: 44.33,
                  },
                  net_total: {
                    without_refunds: 49.04,
                    with_refunds: 44.81,
                  },
                  gross_total: {
                    without_refunds: 56.09,
                    with_refunds: 51.52,
                  },
                  net_total_spread: {
                    without_refunds: 35.42,
                    with_refunds: 34.87,
                  },
                  gross_total_spread: {
                    without_refunds: 41.23,
                    with_refunds: 40.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 67.5,
                    with_refunds: 65.81,
                  },
                  gross_mrr: {
                    without_refunds: 79.3,
                    with_refunds: 77.34,
                  },
                  net_arr: {
                    without_refunds: 618.87,
                    with_refunds: 370.05,
                  },
                  gross_arr: {
                    without_refunds: 739.54,
                    with_refunds: 458.03,
                  },
                  net_arr_spread: {
                    without_refunds: 45.69,
                    with_refunds: 45.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 50.82,
                    with_refunds: 50.82,
                  },
                  net_total: {
                    without_refunds: 101.64,
                    with_refunds: 93.71,
                  },
                  gross_total: {
                    without_refunds: 116.86,
                    with_refunds: 108.21,
                  },
                  net_total_spread: {
                    without_refunds: 59.4,
                    with_refunds: 58.39,
                  },
                  gross_total_spread: {
                    without_refunds: 68.95,
                    with_refunds: 67.77,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2754,
                    with_refunds: 0.2696,
                  },
                  gross_mrr: {
                    without_refunds: 0.3262,
                    with_refunds: 0.3193,
                  },
                  net_arr: {
                    without_refunds: 0.7424,
                    with_refunds: 0.6279,
                  },
                  gross_arr: {
                    without_refunds: 0.8178,
                    with_refunds: 0.6957,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3288,
                    with_refunds: 0.3288,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3645,
                    with_refunds: 0.3645,
                  },
                  net_total: {
                    without_refunds: 0.4032,
                    with_refunds: 0.3684,
                  },
                  gross_total: {
                    without_refunds: 0.4612,
                    with_refunds: 0.4236,
                  },
                  net_total_spread: {
                    without_refunds: 0.2912,
                    with_refunds: 0.2867,
                  },
                  gross_total_spread: {
                    without_refunds: 0.339,
                    with_refunds: 0.3337,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.555,
                    with_refunds: 0.5411,
                  },
                  gross_mrr: {
                    without_refunds: 0.652,
                    with_refunds: 0.6359,
                  },
                  net_arr: {
                    without_refunds: 5.0886,
                    with_refunds: 3.0427,
                  },
                  gross_arr: {
                    without_refunds: 6.0808,
                    with_refunds: 3.7661,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3757,
                    with_refunds: 0.3757,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4178,
                    with_refunds: 0.4178,
                  },
                  net_total: {
                    without_refunds: 0.8357,
                    with_refunds: 0.7705,
                  },
                  gross_total: {
                    without_refunds: 0.9609,
                    with_refunds: 0.8897,
                  },
                  net_total_spread: {
                    without_refunds: 0.4884,
                    with_refunds: 0.4801,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5669,
                    with_refunds: 0.5572,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 624,
                  with_refunds: 629,
                },
                arr_users: {
                  without_refunds: 15,
                  with_refunds: 15,
                },
                arr_spread_users: {
                  without_refunds: 834,
                  with_refunds: 834,
                },
                total_users: {
                  without_refunds: 638,
                  with_refunds: 639,
                },
                total_spread_users: {
                  without_refunds: 1457,
                  with_refunds: 1458,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2237,
                  with_refunds: 0.2255,
                },
                arr_users: {
                  without_refunds: 0.0151,
                  with_refunds: 0.0151,
                },
                arr_spread_users: {
                  without_refunds: 0.9835,
                  with_refunds: 0.9835,
                },
                total_users: {
                  without_refunds: 0.1708,
                  with_refunds: 0.1711,
                },
                total_spread_users: {
                  without_refunds: 0.4053,
                  with_refunds: 0.4056,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8789,
                  with_refunds: 0.8797,
                },
                arr_users: {
                  without_refunds: 1.5,
                  with_refunds: 1.5,
                },
                arr_spread_users: {
                  without_refunds: 1.0134,
                  with_refunds: 1.0134,
                },
                total_users: {
                  without_refunds: 0.8886,
                  with_refunds: 0.8875,
                },
                total_spread_users: {
                  without_refunds: 0.9517,
                  with_refunds: 0.9505,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7165.42,
                  with_refunds: 7032.43,
                },
                gross_mrr: {
                  without_refunds: 8114.67,
                  with_refunds: 7967.38,
                },
                net_arr: {
                  without_refunds: 1727.36,
                  with_refunds: 1383.48,
                },
                gross_arr: {
                  without_refunds: 2230.8,
                  with_refunds: 1770.75,
                },
                net_arr_spread: {
                  without_refunds: 6434.01,
                  with_refunds: 6434.01,
                },
                gross_arr_spread: {
                  without_refunds: 7145.56,
                  with_refunds: 7145.56,
                },
                net_total: {
                  without_refunds: 8892.79,
                  with_refunds: 8415.91,
                },
                gross_total: {
                  without_refunds: 10345.47,
                  with_refunds: 9738.13,
                },
                net_total_spread: {
                  without_refunds: 13599.44,
                  with_refunds: 13466.45,
                },
                gross_total_spread: {
                  without_refunds: 15260.23,
                  with_refunds: 15112.94,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3592,
                  with_refunds: 0.3593,
                },
                gross_mrr: {
                  without_refunds: 0.3245,
                  with_refunds: 0.3247,
                },
                net_arr: {
                  without_refunds: 0.0439,
                  with_refunds: 0.0379,
                },
                gross_arr: {
                  without_refunds: 0.0495,
                  with_refunds: 0.0422,
                },
                net_arr_spread: {
                  without_refunds: 2.2277,
                  with_refunds: 2.2277,
                },
                gross_arr_spread: {
                  without_refunds: 2.1482,
                  with_refunds: 2.1482,
                },
                net_total: {
                  without_refunds: 0.1499,
                  with_refunds: 0.15,
                },
                gross_total: {
                  without_refunds: 0.1476,
                  with_refunds: 0.1465,
                },
                net_total_spread: {
                  without_refunds: 0.5956,
                  with_refunds: 0.5995,
                },
                gross_total_spread: {
                  without_refunds: 0.5385,
                  with_refunds: 0.5424,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8761,
                  with_refunds: 0.8736,
                },
                gross_mrr: {
                  without_refunds: 0.8711,
                  with_refunds: 0.8699,
                },
                net_arr: {
                  without_refunds: 1.4732,
                  with_refunds: 1.3132,
                },
                gross_arr: {
                  without_refunds: 1.5045,
                  with_refunds: 1.3187,
                },
                net_arr_spread: {
                  without_refunds: 1.0174,
                  with_refunds: 1.0174,
                },
                gross_arr_spread: {
                  without_refunds: 1.0198,
                  with_refunds: 1.0198,
                },
                net_total: {
                  without_refunds: 0.951,
                  with_refunds: 0.9245,
                },
                gross_total: {
                  without_refunds: 0.958,
                  with_refunds: 0.9273,
                },
                net_total_spread: {
                  without_refunds: 0.9377,
                  with_refunds: 0.9369,
                },
                gross_total_spread: {
                  without_refunds: 0.9349,
                  with_refunds: 0.9349,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.57,
                  with_refunds: 2.52,
                },
                gross_mrr: {
                  without_refunds: 2.91,
                  with_refunds: 2.86,
                },
                net_arr: {
                  without_refunds: 1.74,
                  with_refunds: 1.39,
                },
                gross_arr: {
                  without_refunds: 2.24,
                  with_refunds: 1.78,
                },
                net_arr_spread: {
                  without_refunds: 7.59,
                  with_refunds: 7.59,
                },
                gross_arr_spread: {
                  without_refunds: 8.43,
                  with_refunds: 8.43,
                },
                net_total: {
                  without_refunds: 2.38,
                  with_refunds: 2.25,
                },
                gross_total: {
                  without_refunds: 2.77,
                  with_refunds: 2.61,
                },
                net_total_spread: {
                  without_refunds: 3.78,
                  with_refunds: 3.75,
                },
                gross_total_spread: {
                  without_refunds: 4.24,
                  with_refunds: 4.2,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.48,
                  with_refunds: 11.18,
                },
                gross_mrr: {
                  without_refunds: 13,
                  with_refunds: 12.67,
                },
                net_arr: {
                  without_refunds: 115.16,
                  with_refunds: 92.23,
                },
                gross_arr: {
                  without_refunds: 148.72,
                  with_refunds: 118.05,
                },
                net_arr_spread: {
                  without_refunds: 7.71,
                  with_refunds: 7.71,
                },
                gross_arr_spread: {
                  without_refunds: 8.57,
                  with_refunds: 8.57,
                },
                net_total: {
                  without_refunds: 13.94,
                  with_refunds: 13.17,
                },
                gross_total: {
                  without_refunds: 16.22,
                  with_refunds: 15.24,
                },
                net_total_spread: {
                  without_refunds: 9.33,
                  with_refunds: 9.24,
                },
                gross_total_spread: {
                  without_refunds: 10.47,
                  with_refunds: 10.37,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.06,
                    with_refunds: 35.31,
                  },
                  gross_mrr: {
                    without_refunds: 42.58,
                    with_refunds: 41.7,
                  },
                  net_arr: {
                    without_refunds: 92.03,
                    with_refunds: 77.76,
                  },
                  gross_arr: {
                    without_refunds: 101.7,
                    with_refunds: 86.39,
                  },
                  net_arr_spread: {
                    without_refunds: 47.57,
                    with_refunds: 47.57,
                  },
                  gross_arr_spread: {
                    without_refunds: 52.75,
                    with_refunds: 52.75,
                  },
                  net_total: {
                    without_refunds: 51.42,
                    with_refunds: 47.06,
                  },
                  gross_total: {
                    without_refunds: 58.86,
                    with_refunds: 54.13,
                  },
                  net_total_spread: {
                    without_refunds: 39.2,
                    with_refunds: 38.61,
                  },
                  gross_total_spread: {
                    without_refunds: 45.48,
                    with_refunds: 44.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 78.98,
                    with_refunds: 76.99,
                  },
                  gross_mrr: {
                    without_refunds: 92.3,
                    with_refunds: 90,
                  },
                  net_arr: {
                    without_refunds: 734.03,
                    with_refunds: 462.29,
                  },
                  gross_arr: {
                    without_refunds: 888.26,
                    with_refunds: 576.08,
                  },
                  net_arr_spread: {
                    without_refunds: 53.4,
                    with_refunds: 53.4,
                  },
                  gross_arr_spread: {
                    without_refunds: 59.38,
                    with_refunds: 59.38,
                  },
                  net_total: {
                    without_refunds: 115.58,
                    with_refunds: 106.88,
                  },
                  gross_total: {
                    without_refunds: 133.08,
                    with_refunds: 123.45,
                  },
                  net_total_spread: {
                    without_refunds: 68.73,
                    with_refunds: 67.63,
                  },
                  gross_total_spread: {
                    without_refunds: 79.43,
                    with_refunds: 78.14,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2965,
                    with_refunds: 0.2903,
                  },
                  gross_mrr: {
                    without_refunds: 0.3501,
                    with_refunds: 0.3428,
                  },
                  net_arr: {
                    without_refunds: 0.7567,
                    with_refunds: 0.6394,
                  },
                  gross_arr: {
                    without_refunds: 0.8362,
                    with_refunds: 0.7104,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3911,
                    with_refunds: 0.3911,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4337,
                    with_refunds: 0.4337,
                  },
                  net_total: {
                    without_refunds: 0.4228,
                    with_refunds: 0.3869,
                  },
                  gross_total: {
                    without_refunds: 0.484,
                    with_refunds: 0.4451,
                  },
                  net_total_spread: {
                    without_refunds: 0.3223,
                    with_refunds: 0.3175,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3739,
                    with_refunds: 0.3683,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6494,
                    with_refunds: 0.6331,
                  },
                  gross_mrr: {
                    without_refunds: 0.7589,
                    with_refunds: 0.74,
                  },
                  net_arr: {
                    without_refunds: 6.0354,
                    with_refunds: 3.8011,
                  },
                  gross_arr: {
                    without_refunds: 7.3036,
                    with_refunds: 4.7367,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4391,
                    with_refunds: 0.4391,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4883,
                    with_refunds: 0.4883,
                  },
                  net_total: {
                    without_refunds: 0.9503,
                    with_refunds: 0.8788,
                  },
                  gross_total: {
                    without_refunds: 1.0942,
                    with_refunds: 1.015,
                  },
                  net_total_spread: {
                    without_refunds: 0.5652,
                    with_refunds: 0.556,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6531,
                    with_refunds: 0.6425,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 561,
                  with_refunds: 562,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 839,
                  with_refunds: 839,
                },
                total_users: {
                  without_refunds: 567,
                  with_refunds: 568,
                },
                total_spread_users: {
                  without_refunds: 1400,
                  with_refunds: 1401,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2011,
                  with_refunds: 0.2015,
                },
                arr_users: {
                  without_refunds: 0.006,
                  with_refunds: 0.006,
                },
                arr_spread_users: {
                  without_refunds: 0.9894,
                  with_refunds: 0.9894,
                },
                total_users: {
                  without_refunds: 0.1518,
                  with_refunds: 0.1521,
                },
                total_spread_users: {
                  without_refunds: 0.3894,
                  with_refunds: 0.3897,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.899,
                  with_refunds: 0.8935,
                },
                arr_users: {
                  without_refunds: 0.4,
                  with_refunds: 0.4,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8887,
                  with_refunds: 0.8889,
                },
                total_spread_users: {
                  without_refunds: 0.9609,
                  with_refunds: 0.9609,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6384.23,
                  with_refunds: 6338.83,
                },
                gross_mrr: {
                  without_refunds: 7236.79,
                  with_refunds: 7188.79,
                },
                net_arr: {
                  without_refunds: 677.93,
                  with_refunds: 563.32,
                },
                gross_arr: {
                  without_refunds: 825.88,
                  with_refunds: 676.2,
                },
                net_arr_spread: {
                  without_refunds: 6480.96,
                  with_refunds: 6480.96,
                },
                gross_arr_spread: {
                  without_refunds: 7201.91,
                  with_refunds: 7201.91,
                },
                net_total: {
                  without_refunds: 7062.16,
                  with_refunds: 6902.14,
                },
                gross_total: {
                  without_refunds: 8062.67,
                  with_refunds: 7865,
                },
                net_total_spread: {
                  without_refunds: 12865.18,
                  with_refunds: 12819.78,
                },
                gross_total_spread: {
                  without_refunds: 14438.7,
                  with_refunds: 14390.7,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3201,
                  with_refunds: 0.3238,
                },
                gross_mrr: {
                  without_refunds: 0.2894,
                  with_refunds: 0.293,
                },
                net_arr: {
                  without_refunds: 0.0172,
                  with_refunds: 0.0154,
                },
                gross_arr: {
                  without_refunds: 0.0183,
                  with_refunds: 0.0161,
                },
                net_arr_spread: {
                  without_refunds: 2.2439,
                  with_refunds: 2.2439,
                },
                gross_arr_spread: {
                  without_refunds: 2.1651,
                  with_refunds: 2.1651,
                },
                net_total: {
                  without_refunds: 0.119,
                  with_refunds: 0.123,
                },
                gross_total: {
                  without_refunds: 0.1151,
                  with_refunds: 0.1183,
                },
                net_total_spread: {
                  without_refunds: 0.5634,
                  with_refunds: 0.5707,
                },
                gross_total_spread: {
                  without_refunds: 0.5096,
                  with_refunds: 0.5165,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.891,
                  with_refunds: 0.9014,
                },
                gross_mrr: {
                  without_refunds: 0.8918,
                  with_refunds: 0.9023,
                },
                net_arr: {
                  without_refunds: 0.3925,
                  with_refunds: 0.4072,
                },
                gross_arr: {
                  without_refunds: 0.3702,
                  with_refunds: 0.3819,
                },
                net_arr_spread: {
                  without_refunds: 1.0073,
                  with_refunds: 1.0073,
                },
                gross_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                net_total: {
                  without_refunds: 0.7941,
                  with_refunds: 0.8201,
                },
                gross_total: {
                  without_refunds: 0.7793,
                  with_refunds: 0.8076,
                },
                net_total_spread: {
                  without_refunds: 0.946,
                  with_refunds: 0.952,
                },
                gross_total_spread: {
                  without_refunds: 0.9462,
                  with_refunds: 0.9522,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.29,
                  with_refunds: 2.27,
                },
                gross_mrr: {
                  without_refunds: 2.59,
                  with_refunds: 2.58,
                },
                net_arr: {
                  without_refunds: 0.68,
                  with_refunds: 0.57,
                },
                gross_arr: {
                  without_refunds: 0.83,
                  with_refunds: 0.68,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.49,
                  with_refunds: 8.49,
                },
                net_total: {
                  without_refunds: 1.89,
                  with_refunds: 1.85,
                },
                gross_total: {
                  without_refunds: 2.16,
                  with_refunds: 2.11,
                },
                net_total_spread: {
                  without_refunds: 3.58,
                  with_refunds: 3.57,
                },
                gross_total_spread: {
                  without_refunds: 4.02,
                  with_refunds: 4,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.38,
                  with_refunds: 11.28,
                },
                gross_mrr: {
                  without_refunds: 12.9,
                  with_refunds: 12.79,
                },
                net_arr: {
                  without_refunds: 112.99,
                  with_refunds: 93.89,
                },
                gross_arr: {
                  without_refunds: 137.65,
                  with_refunds: 112.7,
                },
                net_arr_spread: {
                  without_refunds: 7.72,
                  with_refunds: 7.72,
                },
                gross_arr_spread: {
                  without_refunds: 8.58,
                  with_refunds: 8.58,
                },
                net_total: {
                  without_refunds: 12.46,
                  with_refunds: 12.15,
                },
                gross_total: {
                  without_refunds: 14.22,
                  with_refunds: 13.85,
                },
                net_total_spread: {
                  without_refunds: 9.19,
                  with_refunds: 9.15,
                },
                gross_total_spread: {
                  without_refunds: 10.31,
                  with_refunds: 10.27,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.35,
                    with_refunds: 37.58,
                  },
                  gross_mrr: {
                    without_refunds: 45.18,
                    with_refunds: 44.27,
                  },
                  net_arr: {
                    without_refunds: 92.71,
                    with_refunds: 78.33,
                  },
                  gross_arr: {
                    without_refunds: 102.53,
                    with_refunds: 87.07,
                  },
                  net_arr_spread: {
                    without_refunds: 55.21,
                    with_refunds: 55.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 61.24,
                    with_refunds: 61.24,
                  },
                  net_total: {
                    without_refunds: 53.31,
                    with_refunds: 48.91,
                  },
                  gross_total: {
                    without_refunds: 61.02,
                    with_refunds: 56.23,
                  },
                  net_total_spread: {
                    without_refunds: 42.78,
                    with_refunds: 42.18,
                  },
                  gross_total_spread: {
                    without_refunds: 49.49,
                    with_refunds: 48.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 90.36,
                    with_refunds: 88.27,
                  },
                  gross_mrr: {
                    without_refunds: 105.2,
                    with_refunds: 102.79,
                  },
                  net_arr: {
                    without_refunds: 847.02,
                    with_refunds: 556.17,
                  },
                  gross_arr: {
                    without_refunds: 1025.91,
                    with_refunds: 688.78,
                  },
                  net_arr_spread: {
                    without_refunds: 61.13,
                    with_refunds: 61.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 67.97,
                    with_refunds: 67.97,
                  },
                  net_total: {
                    without_refunds: 128.04,
                    with_refunds: 119.03,
                  },
                  gross_total: {
                    without_refunds: 147.3,
                    with_refunds: 137.29,
                  },
                  net_total_spread: {
                    without_refunds: 77.92,
                    with_refunds: 76.78,
                  },
                  gross_total_spread: {
                    without_refunds: 89.74,
                    with_refunds: 88.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3154,
                    with_refunds: 0.309,
                  },
                  gross_mrr: {
                    without_refunds: 0.3715,
                    with_refunds: 0.364,
                  },
                  net_arr: {
                    without_refunds: 0.7623,
                    with_refunds: 0.644,
                  },
                  gross_arr: {
                    without_refunds: 0.843,
                    with_refunds: 0.7159,
                  },
                  net_arr_spread: {
                    without_refunds: 0.454,
                    with_refunds: 0.454,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5036,
                    with_refunds: 0.5036,
                  },
                  net_total: {
                    without_refunds: 0.4383,
                    with_refunds: 0.4021,
                  },
                  gross_total: {
                    without_refunds: 0.5017,
                    with_refunds: 0.4624,
                  },
                  net_total_spread: {
                    without_refunds: 0.3517,
                    with_refunds: 0.3468,
                  },
                  gross_total_spread: {
                    without_refunds: 0.407,
                    with_refunds: 0.4012,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.743,
                    with_refunds: 0.7258,
                  },
                  gross_mrr: {
                    without_refunds: 0.865,
                    with_refunds: 0.8452,
                  },
                  net_arr: {
                    without_refunds: 6.9645,
                    with_refunds: 4.573,
                  },
                  gross_arr: {
                    without_refunds: 8.4354,
                    with_refunds: 5.6634,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5026,
                    with_refunds: 0.5026,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5588,
                    with_refunds: 0.5588,
                  },
                  net_total: {
                    without_refunds: 1.0527,
                    with_refunds: 0.9787,
                  },
                  gross_total: {
                    without_refunds: 1.2111,
                    with_refunds: 1.1289,
                  },
                  net_total_spread: {
                    without_refunds: 0.6407,
                    with_refunds: 0.6313,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7379,
                    with_refunds: 0.7269,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 488,
                  with_refunds: 489,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 844,
                  with_refunds: 844,
                },
                total_users: {
                  without_refunds: 493,
                  with_refunds: 494,
                },
                total_spread_users: {
                  without_refunds: 1332,
                  with_refunds: 1333,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.175,
                  with_refunds: 0.1753,
                },
                arr_users: {
                  without_refunds: 0.005,
                  with_refunds: 0.005,
                },
                arr_spread_users: {
                  without_refunds: 0.9953,
                  with_refunds: 0.9953,
                },
                total_users: {
                  without_refunds: 0.132,
                  with_refunds: 0.1323,
                },
                total_spread_users: {
                  without_refunds: 0.3705,
                  with_refunds: 0.3708,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8699,
                  with_refunds: 0.8701,
                },
                arr_users: {
                  without_refunds: 0.8333,
                  with_refunds: 0.8333,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8695,
                  with_refunds: 0.8697,
                },
                total_spread_users: {
                  without_refunds: 0.9514,
                  with_refunds: 0.9515,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5660.65,
                  with_refunds: 5631.26,
                },
                gross_mrr: {
                  without_refunds: 6403.03,
                  with_refunds: 6369.8,
                },
                net_arr: {
                  without_refunds: 579.17,
                  with_refunds: 554.87,
                },
                gross_arr: {
                  without_refunds: 706.03,
                  with_refunds: 666.12,
                },
                net_arr_spread: {
                  without_refunds: 6529.22,
                  with_refunds: 6529.22,
                },
                gross_arr_spread: {
                  without_refunds: 7260.75,
                  with_refunds: 7260.75,
                },
                net_total: {
                  without_refunds: 6239.82,
                  with_refunds: 6186.13,
                },
                gross_total: {
                  without_refunds: 7109.06,
                  with_refunds: 7035.92,
                },
                net_total_spread: {
                  without_refunds: 12189.87,
                  with_refunds: 12160.48,
                },
                gross_total_spread: {
                  without_refunds: 13663.77,
                  with_refunds: 13630.54,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2838,
                  with_refunds: 0.2877,
                },
                gross_mrr: {
                  without_refunds: 0.256,
                  with_refunds: 0.2596,
                },
                net_arr: {
                  without_refunds: 0.0147,
                  with_refunds: 0.0152,
                },
                gross_arr: {
                  without_refunds: 0.0157,
                  with_refunds: 0.0159,
                },
                net_arr_spread: {
                  without_refunds: 2.2607,
                  with_refunds: 2.2607,
                },
                gross_arr_spread: {
                  without_refunds: 2.1828,
                  with_refunds: 2.1828,
                },
                net_total: {
                  without_refunds: 0.1052,
                  with_refunds: 0.1102,
                },
                gross_total: {
                  without_refunds: 0.1015,
                  with_refunds: 0.1058,
                },
                net_total_spread: {
                  without_refunds: 0.5338,
                  with_refunds: 0.5414,
                },
                gross_total_spread: {
                  without_refunds: 0.4822,
                  with_refunds: 0.4892,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8867,
                  with_refunds: 0.8884,
                },
                gross_mrr: {
                  without_refunds: 0.8848,
                  with_refunds: 0.8861,
                },
                net_arr: {
                  without_refunds: 0.8543,
                  with_refunds: 0.985,
                },
                gross_arr: {
                  without_refunds: 0.8549,
                  with_refunds: 0.9851,
                },
                net_arr_spread: {
                  without_refunds: 1.0074,
                  with_refunds: 1.0074,
                },
                gross_arr_spread: {
                  without_refunds: 1.0082,
                  with_refunds: 1.0082,
                },
                net_total: {
                  without_refunds: 0.8836,
                  with_refunds: 0.8963,
                },
                gross_total: {
                  without_refunds: 0.8817,
                  with_refunds: 0.8946,
                },
                net_total_spread: {
                  without_refunds: 0.9475,
                  with_refunds: 0.9486,
                },
                gross_total_spread: {
                  without_refunds: 0.9463,
                  with_refunds: 0.9472,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.03,
                  with_refunds: 2.02,
                },
                gross_mrr: {
                  without_refunds: 2.3,
                  with_refunds: 2.28,
                },
                net_arr: {
                  without_refunds: 0.58,
                  with_refunds: 0.56,
                },
                gross_arr: {
                  without_refunds: 0.71,
                  with_refunds: 0.67,
                },
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.56,
                  with_refunds: 8.56,
                },
                net_total: {
                  without_refunds: 1.67,
                  with_refunds: 1.66,
                },
                gross_total: {
                  without_refunds: 1.9,
                  with_refunds: 1.88,
                },
                net_total_spread: {
                  without_refunds: 3.39,
                  with_refunds: 3.38,
                },
                gross_total_spread: {
                  without_refunds: 3.8,
                  with_refunds: 3.79,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.6,
                  with_refunds: 11.52,
                },
                gross_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 13.03,
                },
                net_arr: {
                  without_refunds: 115.83,
                  with_refunds: 110.97,
                },
                gross_arr: {
                  without_refunds: 141.21,
                  with_refunds: 133.22,
                },
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 12.66,
                  with_refunds: 12.52,
                },
                gross_total: {
                  without_refunds: 14.42,
                  with_refunds: 14.24,
                },
                net_total_spread: {
                  without_refunds: 9.15,
                  with_refunds: 9.12,
                },
                gross_total_spread: {
                  without_refunds: 10.26,
                  with_refunds: 10.23,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.38,
                    with_refunds: 39.6,
                  },
                  gross_mrr: {
                    without_refunds: 47.47,
                    with_refunds: 46.56,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 62.91,
                    with_refunds: 62.91,
                  },
                  gross_arr_spread: {
                    without_refunds: 69.81,
                    with_refunds: 69.81,
                  },
                  net_total: {
                    without_refunds: 54.98,
                    with_refunds: 50.56,
                  },
                  gross_total: {
                    without_refunds: 62.92,
                    with_refunds: 58.12,
                  },
                  net_total_spread: {
                    without_refunds: 46.17,
                    with_refunds: 45.56,
                  },
                  gross_total_spread: {
                    without_refunds: 53.3,
                    with_refunds: 52.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 101.96,
                    with_refunds: 99.79,
                  },
                  gross_mrr: {
                    without_refunds: 118.32,
                    with_refunds: 115.82,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 68.86,
                    with_refunds: 68.86,
                  },
                  gross_arr_spread: {
                    without_refunds: 76.57,
                    with_refunds: 76.57,
                  },
                  net_total: {
                    without_refunds: 140.69,
                    with_refunds: 131.55,
                  },
                  gross_total: {
                    without_refunds: 161.72,
                    with_refunds: 151.54,
                  },
                  net_total_spread: {
                    without_refunds: 87.08,
                    with_refunds: 85.9,
                  },
                  gross_total_spread: {
                    without_refunds: 100,
                    with_refunds: 98.63,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.332,
                    with_refunds: 0.3256,
                  },
                  gross_mrr: {
                    without_refunds: 0.3903,
                    with_refunds: 0.3828,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5173,
                    with_refunds: 0.5173,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.574,
                    with_refunds: 0.574,
                  },
                  net_total: {
                    without_refunds: 0.4521,
                    with_refunds: 0.4158,
                  },
                  gross_total: {
                    without_refunds: 0.5174,
                    with_refunds: 0.4779,
                  },
                  net_total_spread: {
                    without_refunds: 0.3796,
                    with_refunds: 0.3746,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4382,
                    with_refunds: 0.4324,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8383,
                    with_refunds: 0.8205,
                  },
                  gross_mrr: {
                    without_refunds: 0.9729,
                    with_refunds: 0.9523,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5662,
                    with_refunds: 0.5662,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6296,
                    with_refunds: 0.6296,
                  },
                  net_total: {
                    without_refunds: 1.1568,
                    with_refunds: 1.0816,
                  },
                  gross_total: {
                    without_refunds: 1.3297,
                    with_refunds: 1.246,
                  },
                  net_total_spread: {
                    without_refunds: 0.716,
                    with_refunds: 0.7063,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8222,
                    with_refunds: 0.811,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 415,
                  with_refunds: 416,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 844,
                  with_refunds: 844,
                },
                total_users: {
                  without_refunds: 415,
                  with_refunds: 416,
                },
                total_spread_users: {
                  without_refunds: 1259,
                  with_refunds: 1260,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1488,
                  with_refunds: 0.1492,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9953,
                  with_refunds: 0.9953,
                },
                total_users: {
                  without_refunds: 0.1111,
                  with_refunds: 0.1114,
                },
                total_spread_users: {
                  without_refunds: 0.3502,
                  with_refunds: 0.3505,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8504,
                  with_refunds: 0.8507,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8418,
                  with_refunds: 0.8421,
                },
                total_spread_users: {
                  without_refunds: 0.9452,
                  with_refunds: 0.9452,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4776.67,
                  with_refunds: 4759.68,
                },
                gross_mrr: {
                  without_refunds: 5380.26,
                  with_refunds: 5360.27,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6529.22,
                  with_refunds: 6529.22,
                },
                gross_arr_spread: {
                  without_refunds: 7260.75,
                  with_refunds: 7260.75,
                },
                net_total: {
                  without_refunds: 4776.67,
                  with_refunds: 4759.68,
                },
                gross_total: {
                  without_refunds: 5380.26,
                  with_refunds: 5360.27,
                },
                net_total_spread: {
                  without_refunds: 11305.89,
                  with_refunds: 11288.9,
                },
                gross_total_spread: {
                  without_refunds: 12641.01,
                  with_refunds: 12621.02,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2395,
                  with_refunds: 0.2432,
                },
                gross_mrr: {
                  without_refunds: 0.2151,
                  with_refunds: 0.2185,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.2607,
                  with_refunds: 2.2607,
                },
                gross_arr_spread: {
                  without_refunds: 2.1828,
                  with_refunds: 2.1828,
                },
                net_total: {
                  without_refunds: 0.0805,
                  with_refunds: 0.0848,
                },
                gross_total: {
                  without_refunds: 0.0768,
                  with_refunds: 0.0806,
                },
                net_total_spread: {
                  without_refunds: 0.4951,
                  with_refunds: 0.5026,
                },
                gross_total_spread: {
                  without_refunds: 0.4461,
                  with_refunds: 0.453,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8438,
                  with_refunds: 0.8452,
                },
                gross_mrr: {
                  without_refunds: 0.8403,
                  with_refunds: 0.8415,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.7655,
                  with_refunds: 0.7694,
                },
                gross_total: {
                  without_refunds: 0.7568,
                  with_refunds: 0.7618,
                },
                net_total_spread: {
                  without_refunds: 0.9275,
                  with_refunds: 0.9283,
                },
                gross_total_spread: {
                  without_refunds: 0.9251,
                  with_refunds: 0.9259,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.71,
                  with_refunds: 1.71,
                },
                gross_mrr: {
                  without_refunds: 1.93,
                  with_refunds: 1.92,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.56,
                  with_refunds: 8.56,
                },
                net_total: {
                  without_refunds: 1.28,
                  with_refunds: 1.27,
                },
                gross_total: {
                  without_refunds: 1.44,
                  with_refunds: 1.44,
                },
                net_total_spread: {
                  without_refunds: 3.14,
                  with_refunds: 3.14,
                },
                gross_total_spread: {
                  without_refunds: 3.52,
                  with_refunds: 3.51,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.51,
                  with_refunds: 11.44,
                },
                gross_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.89,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 11.51,
                  with_refunds: 11.44,
                },
                gross_total: {
                  without_refunds: 12.96,
                  with_refunds: 12.89,
                },
                net_total_spread: {
                  without_refunds: 8.98,
                  with_refunds: 8.96,
                },
                gross_total_spread: {
                  without_refunds: 10.04,
                  with_refunds: 10.02,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.1,
                    with_refunds: 41.31,
                  },
                  gross_mrr: {
                    without_refunds: 49.4,
                    with_refunds: 48.48,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 70.61,
                    with_refunds: 70.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.37,
                    with_refunds: 78.37,
                  },
                  net_total: {
                    without_refunds: 56.26,
                    with_refunds: 51.84,
                  },
                  gross_total: {
                    without_refunds: 64.37,
                    with_refunds: 59.55,
                  },
                  net_total_spread: {
                    without_refunds: 49.31,
                    with_refunds: 48.7,
                  },
                  gross_total_spread: {
                    without_refunds: 56.81,
                    with_refunds: 56.1,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 113.47,
                    with_refunds: 111.23,
                  },
                  gross_mrr: {
                    without_refunds: 131.29,
                    with_refunds: 128.71,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 76.6,
                    with_refunds: 76.6,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.17,
                    with_refunds: 85.17,
                  },
                  net_total: {
                    without_refunds: 152.2,
                    with_refunds: 142.99,
                  },
                  gross_total: {
                    without_refunds: 174.68,
                    with_refunds: 164.42,
                  },
                  net_total_spread: {
                    without_refunds: 96.06,
                    with_refunds: 94.86,
                  },
                  gross_total_spread: {
                    without_refunds: 110.04,
                    with_refunds: 108.65,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3461,
                    with_refunds: 0.3396,
                  },
                  gross_mrr: {
                    without_refunds: 0.4062,
                    with_refunds: 0.3986,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5806,
                    with_refunds: 0.5806,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6444,
                    with_refunds: 0.6444,
                  },
                  net_total: {
                    without_refunds: 0.4626,
                    with_refunds: 0.4262,
                  },
                  gross_total: {
                    without_refunds: 0.5292,
                    with_refunds: 0.4897,
                  },
                  net_total_spread: {
                    without_refunds: 0.4055,
                    with_refunds: 0.4004,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4671,
                    with_refunds: 0.4612,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.933,
                    with_refunds: 0.9146,
                  },
                  gross_mrr: {
                    without_refunds: 1.0795,
                    with_refunds: 1.0583,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6298,
                    with_refunds: 0.6298,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7003,
                    with_refunds: 0.7003,
                  },
                  net_total: {
                    without_refunds: 1.2515,
                    with_refunds: 1.1757,
                  },
                  gross_total: {
                    without_refunds: 1.4363,
                    with_refunds: 1.3519,
                  },
                  net_total_spread: {
                    without_refunds: 0.7898,
                    with_refunds: 0.78,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9048,
                    with_refunds: 0.8934,
                  },
                },
              },
            },
          },
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 124,
                  with_refunds: 124,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 844,
                  with_refunds: 844,
                },
                total_users: {
                  without_refunds: 124,
                  with_refunds: 124,
                },
                total_spread_users: {
                  without_refunds: 968,
                  with_refunds: 968,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0445,
                  with_refunds: 0.0445,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9953,
                  with_refunds: 0.9953,
                },
                total_users: {
                  without_refunds: 0.0332,
                  with_refunds: 0.0332,
                },
                total_spread_users: {
                  without_refunds: 0.2693,
                  with_refunds: 0.2693,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2988,
                  with_refunds: 0.2981,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.2988,
                  with_refunds: 0.2981,
                },
                total_spread_users: {
                  without_refunds: 0.7689,
                  with_refunds: 0.7683,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1421.04,
                  with_refunds: 1421.04,
                },
                gross_mrr: {
                  without_refunds: 1584.89,
                  with_refunds: 1584.89,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6529.22,
                  with_refunds: 6529.22,
                },
                gross_arr_spread: {
                  without_refunds: 7260.75,
                  with_refunds: 7260.75,
                },
                net_total: {
                  without_refunds: 1421.04,
                  with_refunds: 1421.04,
                },
                gross_total: {
                  without_refunds: 1584.89,
                  with_refunds: 1584.89,
                },
                net_total_spread: {
                  without_refunds: 7950.26,
                  with_refunds: 7950.26,
                },
                gross_total_spread: {
                  without_refunds: 8845.64,
                  with_refunds: 8845.64,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0712,
                  with_refunds: 0.0726,
                },
                gross_mrr: {
                  without_refunds: 0.0634,
                  with_refunds: 0.0646,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.2607,
                  with_refunds: 2.2607,
                },
                gross_arr_spread: {
                  without_refunds: 2.1828,
                  with_refunds: 2.1828,
                },
                net_total: {
                  without_refunds: 0.0239,
                  with_refunds: 0.0253,
                },
                gross_total: {
                  without_refunds: 0.0226,
                  with_refunds: 0.0238,
                },
                net_total_spread: {
                  without_refunds: 0.3482,
                  with_refunds: 0.3539,
                },
                gross_total_spread: {
                  without_refunds: 0.3122,
                  with_refunds: 0.3175,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2975,
                  with_refunds: 0.2986,
                },
                gross_mrr: {
                  without_refunds: 0.2946,
                  with_refunds: 0.2957,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.2975,
                  with_refunds: 0.2986,
                },
                gross_total: {
                  without_refunds: 0.2946,
                  with_refunds: 0.2957,
                },
                net_total_spread: {
                  without_refunds: 0.7032,
                  with_refunds: 0.7043,
                },
                gross_total_spread: {
                  without_refunds: 0.6998,
                  with_refunds: 0.7009,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.51,
                  with_refunds: 0.51,
                },
                gross_mrr: {
                  without_refunds: 0.57,
                  with_refunds: 0.57,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.56,
                  with_refunds: 8.56,
                },
                net_total: {
                  without_refunds: 0.38,
                  with_refunds: 0.38,
                },
                gross_total: {
                  without_refunds: 0.42,
                  with_refunds: 0.42,
                },
                net_total_spread: {
                  without_refunds: 2.21,
                  with_refunds: 2.21,
                },
                gross_total_spread: {
                  without_refunds: 2.46,
                  with_refunds: 2.46,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_mrr: {
                  without_refunds: 12.78,
                  with_refunds: 12.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_total: {
                  without_refunds: 12.78,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 8.21,
                  with_refunds: 8.21,
                },
                gross_total_spread: {
                  without_refunds: 9.14,
                  with_refunds: 9.14,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.61,
                    with_refunds: 41.82,
                  },
                  gross_mrr: {
                    without_refunds: 49.97,
                    with_refunds: 49.05,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 78.31,
                    with_refunds: 78.31,
                  },
                  gross_arr_spread: {
                    without_refunds: 86.93,
                    with_refunds: 86.93,
                  },
                  net_total: {
                    without_refunds: 56.64,
                    with_refunds: 52.22,
                  },
                  gross_total: {
                    without_refunds: 64.79,
                    with_refunds: 59.98,
                  },
                  net_total_spread: {
                    without_refunds: 51.53,
                    with_refunds: 50.91,
                  },
                  gross_total_spread: {
                    without_refunds: 59.27,
                    with_refunds: 58.56,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 124.93,
                    with_refunds: 122.69,
                  },
                  gross_mrr: {
                    without_refunds: 144.07,
                    with_refunds: 141.49,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 84.34,
                    with_refunds: 84.34,
                  },
                  gross_arr_spread: {
                    without_refunds: 93.78,
                    with_refunds: 93.78,
                  },
                  net_total: {
                    without_refunds: 163.66,
                    with_refunds: 154.45,
                  },
                  gross_total: {
                    without_refunds: 187.46,
                    with_refunds: 177.2,
                  },
                  net_total_spread: {
                    without_refunds: 104.27,
                    with_refunds: 103.07,
                  },
                  gross_total_spread: {
                    without_refunds: 119.18,
                    with_refunds: 117.79,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3503,
                    with_refunds: 0.3438,
                  },
                  gross_mrr: {
                    without_refunds: 0.4109,
                    with_refunds: 0.4033,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6439,
                    with_refunds: 0.6439,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7148,
                    with_refunds: 0.7148,
                  },
                  net_total: {
                    without_refunds: 0.4657,
                    with_refunds: 0.4294,
                  },
                  gross_total: {
                    without_refunds: 0.5327,
                    with_refunds: 0.4931,
                  },
                  net_total_spread: {
                    without_refunds: 0.4237,
                    with_refunds: 0.4186,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4874,
                    with_refunds: 0.4815,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0272,
                    with_refunds: 1.0088,
                  },
                  gross_mrr: {
                    without_refunds: 1.1846,
                    with_refunds: 1.1634,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6934,
                    with_refunds: 0.6934,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7711,
                    with_refunds: 0.7711,
                  },
                  net_total: {
                    without_refunds: 1.3457,
                    with_refunds: 1.27,
                  },
                  gross_total: {
                    without_refunds: 1.5414,
                    with_refunds: 1.457,
                  },
                  net_total_spread: {
                    without_refunds: 0.8573,
                    with_refunds: 0.8475,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9799,
                    with_refunds: 0.9685,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 1849,
              with_refunds: 1849,
            },
            returning_users: {
              without_refunds: 4201,
              with_refunds: 4215,
            },
            paying_users: {
              without_refunds: 6050,
              with_refunds: 6064,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 441,
              with_refunds: 441,
            },
            returning_users: {
              without_refunds: 44,
              with_refunds: 45,
            },
            paying_users: {
              without_refunds: 485,
              with_refunds: 486,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2289,
              with_refunds: 2289,
            },
            returning_users: {
              without_refunds: 4245,
              with_refunds: 4258,
            },
            paying_users: {
              without_refunds: 6534,
              with_refunds: 6547,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 118826.99,
                with_refunds: 116624.39,
              },
              gross_mrr: {
                without_refunds: 139366.53,
                with_refunds: 136793.56,
              },
              net_arr: {
                without_refunds: 92730.9,
                with_refunds: 78411.43,
              },
              gross_arr: {
                without_refunds: 102621.89,
                with_refunds: 87217.35,
              },
              net_arr_spread: {
                without_refunds: 66409.13,
                with_refunds: 66409.13,
              },
              gross_arr_spread: {
                without_refunds: 73717.38,
                with_refunds: 73717.38,
              },
              net_total: {
                without_refunds: 211557.89,
                with_refunds: 195035.83,
              },
              gross_total: {
                without_refunds: 241988.42,
                with_refunds: 224010.91,
              },
              net_total_spread: {
                without_refunds: 185236.11,
                with_refunds: 183033.52,
              },
              gross_total_spread: {
                without_refunds: 213083.91,
                with_refunds: 210510.94,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 118826.99,
                with_refunds: 116624.39,
              },
              gross_mrr: {
                without_refunds: 139366.53,
                with_refunds: 136793.56,
              },
              net_arr: {
                without_refunds: 92730.9,
                with_refunds: 78411.43,
              },
              gross_arr: {
                without_refunds: 102621.89,
                with_refunds: 87217.35,
              },
              net_arr_spread: {
                without_refunds: 66409.13,
                with_refunds: 66409.13,
              },
              gross_arr_spread: {
                without_refunds: 73717.38,
                with_refunds: 73717.38,
              },
              net_total: {
                without_refunds: 211557.89,
                with_refunds: 195035.83,
              },
              gross_total: {
                without_refunds: 241988.42,
                with_refunds: 224010.91,
              },
              net_total_spread: {
                without_refunds: 185236.11,
                with_refunds: 183033.52,
              },
              gross_total_spread: {
                without_refunds: 213083.91,
                with_refunds: 210510.94,
              },
            },
          },
        },
      },
      cac: 121.62,
      mrr_ua_spend: {
        without_refunds: 339198.18,
        with_refunds: 339198.18,
      },
      arr_ua_spend: {
        without_refunds: 120890.28,
        with_refunds: 120890.28,
      },
      arr_spread_ua_spend: {
        without_refunds: 103133.76,
        with_refunds: 103133.76,
      },
      total_ua_spend: {
        without_refunds: 454250.7,
        with_refunds: 454250.7,
      },
      total_spread_ua_spend: {
        without_refunds: 437223.9,
        with_refunds: 437223.9,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 10,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 3435,
        with_refunds: 3435,
      },
      first_period_arr_users: {
        without_refunds: 1535,
        with_refunds: 1535,
      },
      first_period_arr_spread_users: {
        without_refunds: 1313,
        with_refunds: 1313,
      },
      first_period_total_users: {
        without_refunds: 4932,
        with_refunds: 4932,
      },
      first_period_total_spread_users: {
        without_refunds: 4721,
        with_refunds: 4721,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2411,
                  with_refunds: 2411,
                },
                arr_users: {
                  without_refunds: 805,
                  with_refunds: 805,
                },
                arr_spread_users: {
                  without_refunds: 700,
                  with_refunds: 700,
                },
                total_users: {
                  without_refunds: 3212,
                  with_refunds: 3212,
                },
                total_spread_users: {
                  without_refunds: 3109,
                  with_refunds: 3109,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7019,
                  with_refunds: 0.7019,
                },
                arr_users: {
                  without_refunds: 0.5244,
                  with_refunds: 0.5244,
                },
                arr_spread_users: {
                  without_refunds: 0.5331,
                  with_refunds: 0.5331,
                },
                total_users: {
                  without_refunds: 0.6513,
                  with_refunds: 0.6513,
                },
                total_spread_users: {
                  without_refunds: 0.6585,
                  with_refunds: 0.6585,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 26365.77,
                  with_refunds: 25603.16,
                },
                gross_mrr: {
                  without_refunds: 32554.89,
                  with_refunds: 31582.48,
                },
                net_arr: {
                  without_refunds: 73054.72,
                  with_refunds: 66118.89,
                },
                gross_arr: {
                  without_refunds: 82386.71,
                  with_refunds: 74813.32,
                },
                net_arr_spread: {
                  without_refunds: 5278.72,
                  with_refunds: 5278.72,
                },
                gross_arr_spread: {
                  without_refunds: 5974.17,
                  with_refunds: 5974.17,
                },
                net_total: {
                  without_refunds: 99420.48,
                  with_refunds: 91722.06,
                },
                gross_total: {
                  without_refunds: 114941.6,
                  with_refunds: 106395.79,
                },
                net_total_spread: {
                  without_refunds: 31644.48,
                  with_refunds: 30881.88,
                },
                gross_total_spread: {
                  without_refunds: 38529.06,
                  with_refunds: 37556.65,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.68,
                  with_refunds: 7.45,
                },
                gross_mrr: {
                  without_refunds: 9.48,
                  with_refunds: 9.19,
                },
                net_arr: {
                  without_refunds: 47.59,
                  with_refunds: 43.07,
                },
                gross_arr: {
                  without_refunds: 53.67,
                  with_refunds: 48.74,
                },
                net_arr_spread: {
                  without_refunds: 4.02,
                  with_refunds: 4.02,
                },
                gross_arr_spread: {
                  without_refunds: 4.55,
                  with_refunds: 4.55,
                },
                net_total: {
                  without_refunds: 20.16,
                  with_refunds: 18.6,
                },
                gross_total: {
                  without_refunds: 23.31,
                  with_refunds: 21.57,
                },
                net_total_spread: {
                  without_refunds: 6.7,
                  with_refunds: 6.54,
                },
                gross_total_spread: {
                  without_refunds: 8.16,
                  with_refunds: 7.96,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.94,
                  with_refunds: 10.62,
                },
                gross_mrr: {
                  without_refunds: 13.5,
                  with_refunds: 13.1,
                },
                net_arr: {
                  without_refunds: 90.75,
                  with_refunds: 82.14,
                },
                gross_arr: {
                  without_refunds: 102.34,
                  with_refunds: 92.94,
                },
                net_arr_spread: {
                  without_refunds: 7.54,
                  with_refunds: 7.54,
                },
                gross_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                net_total: {
                  without_refunds: 30.95,
                  with_refunds: 28.56,
                },
                gross_total: {
                  without_refunds: 35.79,
                  with_refunds: 33.12,
                },
                net_total_spread: {
                  without_refunds: 10.18,
                  with_refunds: 9.93,
                },
                gross_total_spread: {
                  without_refunds: 12.39,
                  with_refunds: 12.08,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 7.68,
                    with_refunds: 7.45,
                  },
                  gross_mrr: {
                    without_refunds: 9.48,
                    with_refunds: 9.19,
                  },
                  net_arr: {
                    without_refunds: 47.59,
                    with_refunds: 43.07,
                  },
                  gross_arr: {
                    without_refunds: 53.67,
                    with_refunds: 48.74,
                  },
                  net_arr_spread: {
                    without_refunds: 4.02,
                    with_refunds: 4.02,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.55,
                    with_refunds: 4.55,
                  },
                  net_total: {
                    without_refunds: 20.16,
                    with_refunds: 18.6,
                  },
                  gross_total: {
                    without_refunds: 23.31,
                    with_refunds: 21.57,
                  },
                  net_total_spread: {
                    without_refunds: 6.7,
                    with_refunds: 6.54,
                  },
                  gross_total_spread: {
                    without_refunds: 8.16,
                    with_refunds: 7.96,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.94,
                    with_refunds: 10.62,
                  },
                  gross_mrr: {
                    without_refunds: 13.5,
                    with_refunds: 13.1,
                  },
                  net_arr: {
                    without_refunds: 90.75,
                    with_refunds: 82.14,
                  },
                  gross_arr: {
                    without_refunds: 102.34,
                    with_refunds: 92.94,
                  },
                  net_arr_spread: {
                    without_refunds: 7.54,
                    with_refunds: 7.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.53,
                    with_refunds: 8.53,
                  },
                  net_total: {
                    without_refunds: 30.95,
                    with_refunds: 28.56,
                  },
                  gross_total: {
                    without_refunds: 35.79,
                    with_refunds: 33.12,
                  },
                  net_total_spread: {
                    without_refunds: 10.18,
                    with_refunds: 9.93,
                  },
                  gross_total_spread: {
                    without_refunds: 12.39,
                    with_refunds: 12.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1214,
                    with_refunds: 0.1179,
                  },
                  gross_mrr: {
                    without_refunds: 0.1499,
                    with_refunds: 0.1455,
                  },
                  net_arr: {
                    without_refunds: 0.7529,
                    with_refunds: 0.6814,
                  },
                  gross_arr: {
                    without_refunds: 0.8491,
                    with_refunds: 0.7711,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0636,
                    with_refunds: 0.0636,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.072,
                    with_refunds: 0.072,
                  },
                  net_total: {
                    without_refunds: 0.3189,
                    with_refunds: 0.2942,
                  },
                  gross_total: {
                    without_refunds: 0.3687,
                    with_refunds: 0.3413,
                  },
                  net_total_spread: {
                    without_refunds: 0.106,
                    with_refunds: 0.1035,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1291,
                    with_refunds: 0.1259,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.173,
                    with_refunds: 0.168,
                  },
                  gross_mrr: {
                    without_refunds: 0.2136,
                    with_refunds: 0.2072,
                  },
                  net_arr: {
                    without_refunds: 1.4357,
                    with_refunds: 1.2994,
                  },
                  gross_arr: {
                    without_refunds: 1.6191,
                    with_refunds: 1.4703,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1193,
                    with_refunds: 0.1193,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.135,
                    with_refunds: 0.135,
                  },
                  net_total: {
                    without_refunds: 0.4897,
                    with_refunds: 0.4518,
                  },
                  gross_total: {
                    without_refunds: 0.5661,
                    with_refunds: 0.524,
                  },
                  net_total_spread: {
                    without_refunds: 0.161,
                    with_refunds: 0.1571,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1961,
                    with_refunds: 0.1911,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2418,
                  with_refunds: 2434,
                },
                arr_users: {
                  without_refunds: 669,
                  with_refunds: 698,
                },
                arr_spread_users: {
                  without_refunds: 1256,
                  with_refunds: 1256,
                },
                total_users: {
                  without_refunds: 3084,
                  with_refunds: 3129,
                },
                total_spread_users: {
                  without_refunds: 3674,
                  with_refunds: 3690,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7039,
                  with_refunds: 0.7086,
                },
                arr_users: {
                  without_refunds: 0.4358,
                  with_refunds: 0.4547,
                },
                arr_spread_users: {
                  without_refunds: 0.9566,
                  with_refunds: 0.9566,
                },
                total_users: {
                  without_refunds: 0.6253,
                  with_refunds: 0.6344,
                },
                total_spread_users: {
                  without_refunds: 0.7782,
                  with_refunds: 0.7816,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0095,
                },
                arr_users: {
                  without_refunds: 0.8311,
                  with_refunds: 0.8671,
                },
                arr_spread_users: {
                  without_refunds: 1.7943,
                  with_refunds: 1.7943,
                },
                total_users: {
                  without_refunds: 0.9601,
                  with_refunds: 0.9742,
                },
                total_spread_users: {
                  without_refunds: 1.1817,
                  with_refunds: 1.1869,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 27121.71,
                  with_refunds: 26252.96,
                },
                gross_mrr: {
                  without_refunds: 32438.91,
                  with_refunds: 31407.36,
                },
                net_arr: {
                  without_refunds: 61926.95,
                  with_refunds: 49748.25,
                },
                gross_arr: {
                  without_refunds: 68169.04,
                  with_refunds: 54696.79,
                },
                net_arr_spread: {
                  without_refunds: 9553.66,
                  with_refunds: 9553.66,
                },
                gross_arr_spread: {
                  without_refunds: 10673.93,
                  with_refunds: 10673.93,
                },
                net_total: {
                  without_refunds: 89048.66,
                  with_refunds: 76001.22,
                },
                gross_total: {
                  without_refunds: 100607.95,
                  with_refunds: 86104.15,
                },
                net_total_spread: {
                  without_refunds: 36675.37,
                  with_refunds: 35806.62,
                },
                gross_total_spread: {
                  without_refunds: 43112.84,
                  with_refunds: 42081.29,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.0287,
                  with_refunds: 1.0254,
                },
                gross_mrr: {
                  without_refunds: 0.9964,
                  with_refunds: 0.9945,
                },
                net_arr: {
                  without_refunds: 0.8477,
                  with_refunds: 0.7524,
                },
                gross_arr: {
                  without_refunds: 0.8274,
                  with_refunds: 0.7311,
                },
                net_arr_spread: {
                  without_refunds: 1.8098,
                  with_refunds: 1.8098,
                },
                gross_arr_spread: {
                  without_refunds: 1.7867,
                  with_refunds: 1.7867,
                },
                net_total: {
                  without_refunds: 0.8957,
                  with_refunds: 0.8286,
                },
                gross_total: {
                  without_refunds: 0.8753,
                  with_refunds: 0.8093,
                },
                net_total_spread: {
                  without_refunds: 1.159,
                  with_refunds: 1.1595,
                },
                gross_total_spread: {
                  without_refunds: 1.119,
                  with_refunds: 1.1205,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.0287,
                  with_refunds: 1.0254,
                },
                gross_mrr: {
                  without_refunds: 0.9964,
                  with_refunds: 0.9945,
                },
                net_arr: {
                  without_refunds: 0.8477,
                  with_refunds: 0.7524,
                },
                gross_arr: {
                  without_refunds: 0.8274,
                  with_refunds: 0.7311,
                },
                net_arr_spread: {
                  without_refunds: 1.8098,
                  with_refunds: 1.8098,
                },
                gross_arr_spread: {
                  without_refunds: 1.7867,
                  with_refunds: 1.7867,
                },
                net_total: {
                  without_refunds: 0.8957,
                  with_refunds: 0.8286,
                },
                gross_total: {
                  without_refunds: 0.8753,
                  with_refunds: 0.8093,
                },
                net_total_spread: {
                  without_refunds: 1.159,
                  with_refunds: 1.1595,
                },
                gross_total_spread: {
                  without_refunds: 1.119,
                  with_refunds: 1.1205,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.9,
                  with_refunds: 7.64,
                },
                gross_mrr: {
                  without_refunds: 9.44,
                  with_refunds: 9.14,
                },
                net_arr: {
                  without_refunds: 40.34,
                  with_refunds: 32.41,
                },
                gross_arr: {
                  without_refunds: 44.41,
                  with_refunds: 35.63,
                },
                net_arr_spread: {
                  without_refunds: 7.28,
                  with_refunds: 7.28,
                },
                gross_arr_spread: {
                  without_refunds: 8.13,
                  with_refunds: 8.13,
                },
                net_total: {
                  without_refunds: 18.06,
                  with_refunds: 15.41,
                },
                gross_total: {
                  without_refunds: 20.4,
                  with_refunds: 17.46,
                },
                net_total_spread: {
                  without_refunds: 7.77,
                  with_refunds: 7.58,
                },
                gross_total_spread: {
                  without_refunds: 9.13,
                  with_refunds: 8.91,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.22,
                  with_refunds: 10.79,
                },
                gross_mrr: {
                  without_refunds: 13.42,
                  with_refunds: 12.9,
                },
                net_arr: {
                  without_refunds: 92.57,
                  with_refunds: 71.27,
                },
                gross_arr: {
                  without_refunds: 101.9,
                  with_refunds: 78.36,
                },
                net_arr_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.61,
                },
                gross_arr_spread: {
                  without_refunds: 8.5,
                  with_refunds: 8.5,
                },
                net_total: {
                  without_refunds: 28.87,
                  with_refunds: 24.29,
                },
                gross_total: {
                  without_refunds: 32.62,
                  with_refunds: 27.52,
                },
                net_total_spread: {
                  without_refunds: 9.98,
                  with_refunds: 9.7,
                },
                gross_total_spread: {
                  without_refunds: 11.73,
                  with_refunds: 11.4,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.57,
                    with_refunds: 15.1,
                  },
                  gross_mrr: {
                    without_refunds: 18.92,
                    with_refunds: 18.34,
                  },
                  net_arr: {
                    without_refunds: 87.94,
                    with_refunds: 75.48,
                  },
                  gross_arr: {
                    without_refunds: 98.08,
                    with_refunds: 84.37,
                  },
                  net_arr_spread: {
                    without_refunds: 11.3,
                    with_refunds: 11.3,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.68,
                    with_refunds: 12.68,
                  },
                  net_total: {
                    without_refunds: 38.21,
                    with_refunds: 34.01,
                  },
                  gross_total: {
                    without_refunds: 43.7,
                    with_refunds: 39.03,
                  },
                  net_total_spread: {
                    without_refunds: 14.47,
                    with_refunds: 14.13,
                  },
                  gross_total_spread: {
                    without_refunds: 17.29,
                    with_refunds: 16.87,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.15,
                    with_refunds: 21.41,
                  },
                  gross_mrr: {
                    without_refunds: 26.92,
                    with_refunds: 26,
                  },
                  net_arr: {
                    without_refunds: 183.32,
                    with_refunds: 153.41,
                  },
                  gross_arr: {
                    without_refunds: 204.24,
                    with_refunds: 171.3,
                  },
                  net_arr_spread: {
                    without_refunds: 15.15,
                    with_refunds: 15.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.03,
                    with_refunds: 17.03,
                  },
                  net_total: {
                    without_refunds: 59.83,
                    with_refunds: 52.85,
                  },
                  gross_total: {
                    without_refunds: 68.41,
                    with_refunds: 60.64,
                  },
                  net_total_spread: {
                    without_refunds: 20.16,
                    with_refunds: 19.64,
                  },
                  gross_total_spread: {
                    without_refunds: 24.13,
                    with_refunds: 23.48,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2463,
                    with_refunds: 0.2388,
                  },
                  gross_mrr: {
                    without_refunds: 0.2993,
                    with_refunds: 0.2901,
                  },
                  net_arr: {
                    without_refunds: 1.3912,
                    with_refunds: 1.1942,
                  },
                  gross_arr: {
                    without_refunds: 1.5517,
                    with_refunds: 1.3348,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1787,
                    with_refunds: 0.1787,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2006,
                    with_refunds: 0.2006,
                  },
                  net_total: {
                    without_refunds: 0.6045,
                    with_refunds: 0.538,
                  },
                  gross_total: {
                    without_refunds: 0.6914,
                    with_refunds: 0.6175,
                  },
                  net_total_spread: {
                    without_refunds: 0.2289,
                    with_refunds: 0.2235,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2736,
                    with_refunds: 0.2669,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3505,
                    with_refunds: 0.3386,
                  },
                  gross_mrr: {
                    without_refunds: 0.4259,
                    with_refunds: 0.4114,
                  },
                  net_arr: {
                    without_refunds: 2.9001,
                    with_refunds: 2.427,
                  },
                  gross_arr: {
                    without_refunds: 3.2311,
                    with_refunds: 2.71,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2396,
                    with_refunds: 0.2396,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2695,
                    with_refunds: 0.2695,
                  },
                  net_total: {
                    without_refunds: 0.9465,
                    with_refunds: 0.836,
                  },
                  gross_total: {
                    without_refunds: 1.0822,
                    with_refunds: 0.9594,
                  },
                  net_total_spread: {
                    without_refunds: 0.3189,
                    with_refunds: 0.3107,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3817,
                    with_refunds: 0.3715,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1754,
                  with_refunds: 1763,
                },
                arr_users: {
                  without_refunds: 19,
                  with_refunds: 27,
                },
                arr_spread_users: {
                  without_refunds: 1270,
                  with_refunds: 1270,
                },
                total_users: {
                  without_refunds: 1773,
                  with_refunds: 1790,
                },
                total_spread_users: {
                  without_refunds: 3024,
                  with_refunds: 3033,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5106,
                  with_refunds: 0.5132,
                },
                arr_users: {
                  without_refunds: 0.0124,
                  with_refunds: 0.0176,
                },
                arr_spread_users: {
                  without_refunds: 0.9673,
                  with_refunds: 0.9673,
                },
                total_users: {
                  without_refunds: 0.3595,
                  with_refunds: 0.3629,
                },
                total_spread_users: {
                  without_refunds: 0.6405,
                  with_refunds: 0.6424,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7254,
                  with_refunds: 0.7243,
                },
                arr_users: {
                  without_refunds: 0.0284,
                  with_refunds: 0.0387,
                },
                arr_spread_users: {
                  without_refunds: 1.0111,
                  with_refunds: 1.0111,
                },
                total_users: {
                  without_refunds: 0.5749,
                  with_refunds: 0.5721,
                },
                total_spread_users: {
                  without_refunds: 0.8231,
                  with_refunds: 0.822,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 19788.97,
                  with_refunds: 19333.65,
                },
                gross_mrr: {
                  without_refunds: 23563.2,
                  with_refunds: 23049.56,
                },
                net_arr: {
                  without_refunds: 1879.11,
                  with_refunds: 800.38,
                },
                gross_arr: {
                  without_refunds: 2330.28,
                  with_refunds: 1030.61,
                },
                net_arr_spread: {
                  without_refunds: 9670.08,
                  with_refunds: 9670.08,
                },
                gross_arr_spread: {
                  without_refunds: 10818.29,
                  with_refunds: 10818.29,
                },
                net_total: {
                  without_refunds: 21668.08,
                  with_refunds: 20134.03,
                },
                gross_total: {
                  without_refunds: 25893.48,
                  with_refunds: 24080.16,
                },
                net_total_spread: {
                  without_refunds: 29459.05,
                  with_refunds: 29003.73,
                },
                gross_total_spread: {
                  without_refunds: 34381.49,
                  with_refunds: 33867.85,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7506,
                  with_refunds: 0.7551,
                },
                gross_mrr: {
                  without_refunds: 0.7238,
                  with_refunds: 0.7298,
                },
                net_arr: {
                  without_refunds: 0.0257,
                  with_refunds: 0.0121,
                },
                gross_arr: {
                  without_refunds: 0.0283,
                  with_refunds: 0.0138,
                },
                net_arr_spread: {
                  without_refunds: 1.8319,
                  with_refunds: 1.8319,
                },
                gross_arr_spread: {
                  without_refunds: 1.8108,
                  with_refunds: 1.8108,
                },
                net_total: {
                  without_refunds: 0.2179,
                  with_refunds: 0.2195,
                },
                gross_total: {
                  without_refunds: 0.2253,
                  with_refunds: 0.2263,
                },
                net_total_spread: {
                  without_refunds: 0.9309,
                  with_refunds: 0.9392,
                },
                gross_total_spread: {
                  without_refunds: 0.8924,
                  with_refunds: 0.9018,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7296,
                  with_refunds: 0.7364,
                },
                gross_mrr: {
                  without_refunds: 0.7264,
                  with_refunds: 0.7339,
                },
                net_arr: {
                  without_refunds: 0.0303,
                  with_refunds: 0.0161,
                },
                gross_arr: {
                  without_refunds: 0.0342,
                  with_refunds: 0.0188,
                },
                net_arr_spread: {
                  without_refunds: 1.0122,
                  with_refunds: 1.0122,
                },
                gross_arr_spread: {
                  without_refunds: 1.0135,
                  with_refunds: 1.0135,
                },
                net_total: {
                  without_refunds: 0.2433,
                  with_refunds: 0.2649,
                },
                gross_total: {
                  without_refunds: 0.2574,
                  with_refunds: 0.2797,
                },
                net_total_spread: {
                  without_refunds: 0.8032,
                  with_refunds: 0.81,
                },
                gross_total_spread: {
                  without_refunds: 0.7975,
                  with_refunds: 0.8048,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.76,
                  with_refunds: 5.63,
                },
                gross_mrr: {
                  without_refunds: 6.86,
                  with_refunds: 6.71,
                },
                net_arr: {
                  without_refunds: 1.22,
                  with_refunds: 0.52,
                },
                gross_arr: {
                  without_refunds: 1.52,
                  with_refunds: 0.67,
                },
                net_arr_spread: {
                  without_refunds: 7.36,
                  with_refunds: 7.36,
                },
                gross_arr_spread: {
                  without_refunds: 8.24,
                  with_refunds: 8.24,
                },
                net_total: {
                  without_refunds: 4.39,
                  with_refunds: 4.08,
                },
                gross_total: {
                  without_refunds: 5.25,
                  with_refunds: 4.88,
                },
                net_total_spread: {
                  without_refunds: 6.24,
                  with_refunds: 6.14,
                },
                gross_total_spread: {
                  without_refunds: 7.28,
                  with_refunds: 7.17,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.28,
                  with_refunds: 10.97,
                },
                gross_mrr: {
                  without_refunds: 13.43,
                  with_refunds: 13.07,
                },
                net_arr: {
                  without_refunds: 98.9,
                  with_refunds: 29.64,
                },
                gross_arr: {
                  without_refunds: 122.65,
                  with_refunds: 38.17,
                },
                net_arr_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.61,
                },
                gross_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                net_total: {
                  without_refunds: 12.22,
                  with_refunds: 11.25,
                },
                gross_total: {
                  without_refunds: 14.6,
                  with_refunds: 13.45,
                },
                net_total_spread: {
                  without_refunds: 9.74,
                  with_refunds: 9.56,
                },
                gross_total_spread: {
                  without_refunds: 11.37,
                  with_refunds: 11.17,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.33,
                    with_refunds: 20.72,
                  },
                  gross_mrr: {
                    without_refunds: 25.78,
                    with_refunds: 25.05,
                  },
                  net_arr: {
                    without_refunds: 89.16,
                    with_refunds: 76,
                  },
                  gross_arr: {
                    without_refunds: 99.6,
                    with_refunds: 85.04,
                  },
                  net_arr_spread: {
                    without_refunds: 18.66,
                    with_refunds: 18.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 20.92,
                    with_refunds: 20.92,
                  },
                  net_total: {
                    without_refunds: 42.61,
                    with_refunds: 38.09,
                  },
                  gross_total: {
                    without_refunds: 48.95,
                    with_refunds: 43.91,
                  },
                  net_total_spread: {
                    without_refunds: 20.71,
                    with_refunds: 20.27,
                  },
                  gross_total_spread: {
                    without_refunds: 24.58,
                    with_refunds: 24.04,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.43,
                    with_refunds: 32.37,
                  },
                  gross_mrr: {
                    without_refunds: 40.35,
                    with_refunds: 39.08,
                  },
                  net_arr: {
                    without_refunds: 282.22,
                    with_refunds: 183.05,
                  },
                  gross_arr: {
                    without_refunds: 326.89,
                    with_refunds: 209.47,
                  },
                  net_arr_spread: {
                    without_refunds: 22.76,
                    with_refunds: 22.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.55,
                    with_refunds: 25.55,
                  },
                  net_total: {
                    without_refunds: 72.05,
                    with_refunds: 64.09,
                  },
                  gross_total: {
                    without_refunds: 83.01,
                    with_refunds: 74.1,
                  },
                  net_total_spread: {
                    without_refunds: 29.9,
                    with_refunds: 29.2,
                  },
                  gross_total_spread: {
                    without_refunds: 35.5,
                    with_refunds: 34.65,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3375,
                    with_refunds: 0.3279,
                  },
                  gross_mrr: {
                    without_refunds: 0.4079,
                    with_refunds: 0.3963,
                  },
                  net_arr: {
                    without_refunds: 1.4105,
                    with_refunds: 1.2024,
                  },
                  gross_arr: {
                    without_refunds: 1.5757,
                    with_refunds: 1.3454,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2952,
                    with_refunds: 0.2952,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3309,
                    with_refunds: 0.3309,
                  },
                  net_total: {
                    without_refunds: 0.6741,
                    with_refunds: 0.6026,
                  },
                  gross_total: {
                    without_refunds: 0.7745,
                    with_refunds: 0.6947,
                  },
                  net_total_spread: {
                    without_refunds: 0.3277,
                    with_refunds: 0.3207,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3888,
                    with_refunds: 0.3804,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5289,
                    with_refunds: 0.5121,
                  },
                  gross_mrr: {
                    without_refunds: 0.6384,
                    with_refunds: 0.6182,
                  },
                  net_arr: {
                    without_refunds: 4.4648,
                    with_refunds: 2.8959,
                  },
                  gross_arr: {
                    without_refunds: 5.1714,
                    with_refunds: 3.3139,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3601,
                    with_refunds: 0.3601,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4042,
                    with_refunds: 0.4042,
                  },
                  net_total: {
                    without_refunds: 1.1398,
                    with_refunds: 1.014,
                  },
                  gross_total: {
                    without_refunds: 1.3133,
                    with_refunds: 1.1722,
                  },
                  net_total_spread: {
                    without_refunds: 0.4731,
                    with_refunds: 0.4619,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5616,
                    with_refunds: 0.5482,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1124,
                  with_refunds: 1130,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 16,
                },
                arr_spread_users: {
                  without_refunds: 1279,
                  with_refunds: 1279,
                },
                total_users: {
                  without_refunds: 1134,
                  with_refunds: 1146,
                },
                total_spread_users: {
                  without_refunds: 2403,
                  with_refunds: 2409,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3272,
                  with_refunds: 0.329,
                },
                arr_users: {
                  without_refunds: 0.0065,
                  with_refunds: 0.0104,
                },
                arr_spread_users: {
                  without_refunds: 0.9741,
                  with_refunds: 0.9741,
                },
                total_users: {
                  without_refunds: 0.2299,
                  with_refunds: 0.2324,
                },
                total_spread_users: {
                  without_refunds: 0.509,
                  with_refunds: 0.5103,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6408,
                  with_refunds: 0.641,
                },
                arr_users: {
                  without_refunds: 0.5263,
                  with_refunds: 0.5926,
                },
                arr_spread_users: {
                  without_refunds: 1.0071,
                  with_refunds: 1.0071,
                },
                total_users: {
                  without_refunds: 0.6396,
                  with_refunds: 0.6402,
                },
                total_spread_users: {
                  without_refunds: 0.7946,
                  with_refunds: 0.7943,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 12918.81,
                  with_refunds: 12631.28,
                },
                gross_mrr: {
                  without_refunds: 14872.64,
                  with_refunds: 14551.08,
                },
                net_arr: {
                  without_refunds: 1115.22,
                  with_refunds: 454.88,
                },
                gross_arr: {
                  without_refunds: 1495.57,
                  with_refunds: 666.59,
                },
                net_arr_spread: {
                  without_refunds: 9753.57,
                  with_refunds: 9753.57,
                },
                gross_arr_spread: {
                  without_refunds: 10929.59,
                  with_refunds: 10929.59,
                },
                net_total: {
                  without_refunds: 14034.04,
                  with_refunds: 13086.16,
                },
                gross_total: {
                  without_refunds: 16368.21,
                  with_refunds: 15217.67,
                },
                net_total_spread: {
                  without_refunds: 22672.38,
                  with_refunds: 22384.85,
                },
                gross_total_spread: {
                  without_refunds: 25802.23,
                  with_refunds: 25480.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.49,
                  with_refunds: 0.4933,
                },
                gross_mrr: {
                  without_refunds: 0.4568,
                  with_refunds: 0.4607,
                },
                net_arr: {
                  without_refunds: 0.0153,
                  with_refunds: 0.0069,
                },
                gross_arr: {
                  without_refunds: 0.0182,
                  with_refunds: 0.0089,
                },
                net_arr_spread: {
                  without_refunds: 1.8477,
                  with_refunds: 1.8477,
                },
                gross_arr_spread: {
                  without_refunds: 1.8295,
                  with_refunds: 1.8295,
                },
                net_total: {
                  without_refunds: 0.1412,
                  with_refunds: 0.1427,
                },
                gross_total: {
                  without_refunds: 0.1424,
                  with_refunds: 0.143,
                },
                net_total_spread: {
                  without_refunds: 0.7165,
                  with_refunds: 0.7249,
                },
                gross_total_spread: {
                  without_refunds: 0.6697,
                  with_refunds: 0.6785,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6528,
                  with_refunds: 0.6533,
                },
                gross_mrr: {
                  without_refunds: 0.6312,
                  with_refunds: 0.6313,
                },
                net_arr: {
                  without_refunds: 0.5935,
                  with_refunds: 0.5683,
                },
                gross_arr: {
                  without_refunds: 0.6418,
                  with_refunds: 0.6468,
                },
                net_arr_spread: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                gross_arr_spread: {
                  without_refunds: 1.0103,
                  with_refunds: 1.0103,
                },
                net_total: {
                  without_refunds: 0.6477,
                  with_refunds: 0.65,
                },
                gross_total: {
                  without_refunds: 0.6321,
                  with_refunds: 0.632,
                },
                net_total_spread: {
                  without_refunds: 0.7696,
                  with_refunds: 0.7718,
                },
                gross_total_spread: {
                  without_refunds: 0.7505,
                  with_refunds: 0.7524,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.76,
                  with_refunds: 3.68,
                },
                gross_mrr: {
                  without_refunds: 4.33,
                  with_refunds: 4.24,
                },
                net_arr: {
                  without_refunds: 0.73,
                  with_refunds: 0.3,
                },
                gross_arr: {
                  without_refunds: 0.97,
                  with_refunds: 0.43,
                },
                net_arr_spread: {
                  without_refunds: 7.43,
                  with_refunds: 7.43,
                },
                gross_arr_spread: {
                  without_refunds: 8.32,
                  with_refunds: 8.32,
                },
                net_total: {
                  without_refunds: 2.85,
                  with_refunds: 2.65,
                },
                gross_total: {
                  without_refunds: 3.32,
                  with_refunds: 3.09,
                },
                net_total_spread: {
                  without_refunds: 4.8,
                  with_refunds: 4.74,
                },
                gross_total_spread: {
                  without_refunds: 5.47,
                  with_refunds: 5.4,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.49,
                  with_refunds: 11.18,
                },
                gross_mrr: {
                  without_refunds: 13.23,
                  with_refunds: 12.88,
                },
                net_arr: {
                  without_refunds: 111.52,
                  with_refunds: 28.43,
                },
                gross_arr: {
                  without_refunds: 149.56,
                  with_refunds: 41.66,
                },
                net_arr_spread: {
                  without_refunds: 7.63,
                  with_refunds: 7.63,
                },
                gross_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                net_total: {
                  without_refunds: 12.38,
                  with_refunds: 11.42,
                },
                gross_total: {
                  without_refunds: 14.43,
                  with_refunds: 13.28,
                },
                net_total_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.29,
                },
                gross_total_spread: {
                  without_refunds: 10.74,
                  with_refunds: 10.58,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.09,
                    with_refunds: 24.4,
                  },
                  gross_mrr: {
                    without_refunds: 30.11,
                    with_refunds: 29.28,
                  },
                  net_arr: {
                    without_refunds: 89.89,
                    with_refunds: 76.3,
                  },
                  gross_arr: {
                    without_refunds: 100.57,
                    with_refunds: 85.48,
                  },
                  net_arr_spread: {
                    without_refunds: 26.09,
                    with_refunds: 26.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 29.24,
                    with_refunds: 29.24,
                  },
                  net_total: {
                    without_refunds: 45.45,
                    with_refunds: 40.74,
                  },
                  gross_total: {
                    without_refunds: 52.27,
                    with_refunds: 47,
                  },
                  net_total_spread: {
                    without_refunds: 25.51,
                    with_refunds: 25.01,
                  },
                  gross_total_spread: {
                    without_refunds: 30.04,
                    with_refunds: 29.44,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.93,
                    with_refunds: 43.55,
                  },
                  gross_mrr: {
                    without_refunds: 53.58,
                    with_refunds: 51.95,
                  },
                  net_arr: {
                    without_refunds: 393.74,
                    with_refunds: 211.48,
                  },
                  gross_arr: {
                    without_refunds: 476.44,
                    with_refunds: 251.13,
                  },
                  net_arr_spread: {
                    without_refunds: 30.39,
                    with_refunds: 30.39,
                  },
                  gross_arr_spread: {
                    without_refunds: 34.1,
                    with_refunds: 34.1,
                  },
                  net_total: {
                    without_refunds: 84.42,
                    with_refunds: 75.51,
                  },
                  gross_total: {
                    without_refunds: 97.45,
                    with_refunds: 87.37,
                  },
                  net_total_spread: {
                    without_refunds: 39.34,
                    with_refunds: 38.49,
                  },
                  gross_total_spread: {
                    without_refunds: 46.23,
                    with_refunds: 45.23,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.397,
                    with_refunds: 0.386,
                  },
                  gross_mrr: {
                    without_refunds: 0.4764,
                    with_refunds: 0.4633,
                  },
                  net_arr: {
                    without_refunds: 1.422,
                    with_refunds: 1.2071,
                  },
                  gross_arr: {
                    without_refunds: 1.5911,
                    with_refunds: 1.3523,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4127,
                    with_refunds: 0.4127,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4626,
                    with_refunds: 0.4626,
                  },
                  net_total: {
                    without_refunds: 0.7191,
                    with_refunds: 0.6446,
                  },
                  gross_total: {
                    without_refunds: 0.827,
                    with_refunds: 0.7435,
                  },
                  net_total_spread: {
                    without_refunds: 0.4036,
                    with_refunds: 0.3957,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4753,
                    with_refunds: 0.4657,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7108,
                    with_refunds: 0.689,
                  },
                  gross_mrr: {
                    without_refunds: 0.8477,
                    with_refunds: 0.8219,
                  },
                  net_arr: {
                    without_refunds: 6.2291,
                    with_refunds: 3.3457,
                  },
                  gross_arr: {
                    without_refunds: 7.5375,
                    with_refunds: 3.973,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4807,
                    with_refunds: 0.4807,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5394,
                    with_refunds: 0.5394,
                  },
                  net_total: {
                    without_refunds: 1.3356,
                    with_refunds: 1.1946,
                  },
                  gross_total: {
                    without_refunds: 1.5416,
                    with_refunds: 1.3823,
                  },
                  net_total_spread: {
                    without_refunds: 0.6223,
                    with_refunds: 0.6089,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7314,
                    with_refunds: 0.7155,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 925,
                  with_refunds: 929,
                },
                arr_users: {
                  without_refunds: 9,
                  with_refunds: 9,
                },
                arr_spread_users: {
                  without_refunds: 1286,
                  with_refunds: 1286,
                },
                total_users: {
                  without_refunds: 933,
                  with_refunds: 935,
                },
                total_spread_users: {
                  without_refunds: 2210,
                  with_refunds: 2212,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2693,
                  with_refunds: 0.2705,
                },
                arr_users: {
                  without_refunds: 0.0059,
                  with_refunds: 0.0059,
                },
                arr_spread_users: {
                  without_refunds: 0.9794,
                  with_refunds: 0.9794,
                },
                total_users: {
                  without_refunds: 0.1892,
                  with_refunds: 0.1896,
                },
                total_spread_users: {
                  without_refunds: 0.4681,
                  with_refunds: 0.4685,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.823,
                  with_refunds: 0.8221,
                },
                arr_users: {
                  without_refunds: 0.9,
                  with_refunds: 0.5625,
                },
                arr_spread_users: {
                  without_refunds: 1.0055,
                  with_refunds: 1.0055,
                },
                total_users: {
                  without_refunds: 0.8228,
                  with_refunds: 0.8159,
                },
                total_spread_users: {
                  without_refunds: 0.9197,
                  with_refunds: 0.9182,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10670.03,
                  with_refunds: 10378.51,
                },
                gross_mrr: {
                  without_refunds: 12274.92,
                  with_refunds: 11948.91,
                },
                net_arr: {
                  without_refunds: 1046.83,
                  with_refunds: 932.52,
                },
                gross_arr: {
                  without_refunds: 1328.23,
                  with_refunds: 1168.2,
                },
                net_arr_spread: {
                  without_refunds: 9823.29,
                  with_refunds: 9823.29,
                },
                gross_arr_spread: {
                  without_refunds: 11018.69,
                  with_refunds: 11018.69,
                },
                net_total: {
                  without_refunds: 11716.86,
                  with_refunds: 11311.03,
                },
                gross_total: {
                  without_refunds: 13603.15,
                  with_refunds: 13117.11,
                },
                net_total_spread: {
                  without_refunds: 20493.32,
                  with_refunds: 20201.81,
                },
                gross_total_spread: {
                  without_refunds: 23293.61,
                  with_refunds: 22967.6,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4047,
                  with_refunds: 0.4054,
                },
                gross_mrr: {
                  without_refunds: 0.3771,
                  with_refunds: 0.3783,
                },
                net_arr: {
                  without_refunds: 0.0143,
                  with_refunds: 0.0141,
                },
                gross_arr: {
                  without_refunds: 0.0161,
                  with_refunds: 0.0156,
                },
                net_arr_spread: {
                  without_refunds: 1.8609,
                  with_refunds: 1.8609,
                },
                gross_arr_spread: {
                  without_refunds: 1.8444,
                  with_refunds: 1.8444,
                },
                net_total: {
                  without_refunds: 0.1179,
                  with_refunds: 0.1233,
                },
                gross_total: {
                  without_refunds: 0.1183,
                  with_refunds: 0.1233,
                },
                net_total_spread: {
                  without_refunds: 0.6476,
                  with_refunds: 0.6542,
                },
                gross_total_spread: {
                  without_refunds: 0.6046,
                  with_refunds: 0.6115,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8259,
                  with_refunds: 0.8217,
                },
                gross_mrr: {
                  without_refunds: 0.8253,
                  with_refunds: 0.8212,
                },
                net_arr: {
                  without_refunds: 0.9387,
                  with_refunds: 2.05,
                },
                gross_arr: {
                  without_refunds: 0.8881,
                  with_refunds: 1.7525,
                },
                net_arr_spread: {
                  without_refunds: 1.0071,
                  with_refunds: 1.0071,
                },
                gross_arr_spread: {
                  without_refunds: 1.0082,
                  with_refunds: 1.0082,
                },
                net_total: {
                  without_refunds: 0.8349,
                  with_refunds: 0.8644,
                },
                gross_total: {
                  without_refunds: 0.8311,
                  with_refunds: 0.862,
                },
                net_total_spread: {
                  without_refunds: 0.9039,
                  with_refunds: 0.9025,
                },
                gross_total_spread: {
                  without_refunds: 0.9028,
                  with_refunds: 0.9014,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.11,
                  with_refunds: 3.02,
                },
                gross_mrr: {
                  without_refunds: 3.57,
                  with_refunds: 3.48,
                },
                net_arr: {
                  without_refunds: 0.68,
                  with_refunds: 0.61,
                },
                gross_arr: {
                  without_refunds: 0.87,
                  with_refunds: 0.76,
                },
                net_arr_spread: {
                  without_refunds: 7.48,
                  with_refunds: 7.48,
                },
                gross_arr_spread: {
                  without_refunds: 8.39,
                  with_refunds: 8.39,
                },
                net_total: {
                  without_refunds: 2.38,
                  with_refunds: 2.29,
                },
                gross_total: {
                  without_refunds: 2.76,
                  with_refunds: 2.66,
                },
                net_total_spread: {
                  without_refunds: 4.34,
                  with_refunds: 4.28,
                },
                gross_total_spread: {
                  without_refunds: 4.93,
                  with_refunds: 4.86,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.54,
                  with_refunds: 11.17,
                },
                gross_mrr: {
                  without_refunds: 13.27,
                  with_refunds: 12.86,
                },
                net_arr: {
                  without_refunds: 116.31,
                  with_refunds: 103.61,
                },
                gross_arr: {
                  without_refunds: 147.58,
                  with_refunds: 129.8,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.57,
                  with_refunds: 8.57,
                },
                net_total: {
                  without_refunds: 12.56,
                  with_refunds: 12.1,
                },
                gross_total: {
                  without_refunds: 14.58,
                  with_refunds: 14.03,
                },
                net_total_spread: {
                  without_refunds: 9.27,
                  with_refunds: 9.13,
                },
                gross_total_spread: {
                  without_refunds: 10.54,
                  with_refunds: 10.38,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.2,
                    with_refunds: 27.42,
                  },
                  gross_mrr: {
                    without_refunds: 33.68,
                    with_refunds: 32.76,
                  },
                  net_arr: {
                    without_refunds: 90.57,
                    with_refunds: 76.91,
                  },
                  gross_arr: {
                    without_refunds: 101.44,
                    with_refunds: 86.24,
                  },
                  net_arr_spread: {
                    without_refunds: 33.57,
                    with_refunds: 33.57,
                  },
                  gross_arr_spread: {
                    without_refunds: 37.63,
                    with_refunds: 37.63,
                  },
                  net_total: {
                    without_refunds: 47.83,
                    with_refunds: 43.04,
                  },
                  gross_total: {
                    without_refunds: 55.03,
                    with_refunds: 49.66,
                  },
                  net_total_spread: {
                    without_refunds: 29.85,
                    with_refunds: 29.29,
                  },
                  gross_total_spread: {
                    without_refunds: 34.98,
                    with_refunds: 34.31,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 56.46,
                    with_refunds: 54.72,
                  },
                  gross_mrr: {
                    without_refunds: 66.85,
                    with_refunds: 64.82,
                  },
                  net_arr: {
                    without_refunds: 510.05,
                    with_refunds: 315.09,
                  },
                  gross_arr: {
                    without_refunds: 624.02,
                    with_refunds: 380.93,
                  },
                  net_arr_spread: {
                    without_refunds: 38.03,
                    with_refunds: 38.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.66,
                    with_refunds: 42.66,
                  },
                  net_total: {
                    without_refunds: 96.98,
                    with_refunds: 87.61,
                  },
                  gross_total: {
                    without_refunds: 112.03,
                    with_refunds: 101.4,
                  },
                  net_total_spread: {
                    without_refunds: 48.61,
                    with_refunds: 47.62,
                  },
                  gross_total_spread: {
                    without_refunds: 56.77,
                    with_refunds: 55.61,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4461,
                    with_refunds: 0.4338,
                  },
                  gross_mrr: {
                    without_refunds: 0.5329,
                    with_refunds: 0.5183,
                  },
                  net_arr: {
                    without_refunds: 1.4328,
                    with_refunds: 1.2167,
                  },
                  gross_arr: {
                    without_refunds: 1.6048,
                    with_refunds: 1.3643,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5311,
                    with_refunds: 0.5311,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5954,
                    with_refunds: 0.5954,
                  },
                  net_total: {
                    without_refunds: 0.7567,
                    with_refunds: 0.6808,
                  },
                  gross_total: {
                    without_refunds: 0.8706,
                    with_refunds: 0.7856,
                  },
                  net_total_spread: {
                    without_refunds: 0.4723,
                    with_refunds: 0.4634,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5533,
                    with_refunds: 0.5427,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8933,
                    with_refunds: 0.8657,
                  },
                  gross_mrr: {
                    without_refunds: 1.0577,
                    with_refunds: 1.0254,
                  },
                  net_arr: {
                    without_refunds: 8.0692,
                    with_refunds: 4.9849,
                  },
                  gross_arr: {
                    without_refunds: 9.8722,
                    with_refunds: 6.0264,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6016,
                    with_refunds: 0.6016,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.675,
                    with_refunds: 0.675,
                  },
                  net_total: {
                    without_refunds: 1.5343,
                    with_refunds: 1.386,
                  },
                  gross_total: {
                    without_refunds: 1.7723,
                    with_refunds: 1.6042,
                  },
                  net_total_spread: {
                    without_refunds: 0.769,
                    with_refunds: 0.7534,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8982,
                    with_refunds: 0.8798,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 785,
                  with_refunds: 788,
                },
                arr_users: {
                  without_refunds: 18,
                  with_refunds: 19,
                },
                arr_spread_users: {
                  without_refunds: 1300,
                  with_refunds: 1300,
                },
                total_users: {
                  without_refunds: 802,
                  with_refunds: 804,
                },
                total_spread_users: {
                  without_refunds: 2084,
                  with_refunds: 2086,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2285,
                  with_refunds: 0.2294,
                },
                arr_users: {
                  without_refunds: 0.0117,
                  with_refunds: 0.0124,
                },
                arr_spread_users: {
                  without_refunds: 0.9901,
                  with_refunds: 0.9901,
                },
                total_users: {
                  without_refunds: 0.1626,
                  with_refunds: 0.163,
                },
                total_spread_users: {
                  without_refunds: 0.4414,
                  with_refunds: 0.4419,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8486,
                  with_refunds: 0.8482,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2.1111,
                },
                arr_spread_users: {
                  without_refunds: 1.0109,
                  with_refunds: 1.0109,
                },
                total_users: {
                  without_refunds: 0.8596,
                  with_refunds: 0.8599,
                },
                total_spread_users: {
                  without_refunds: 0.943,
                  with_refunds: 0.943,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9105.42,
                  with_refunds: 8985.52,
                },
                gross_mrr: {
                  without_refunds: 10422.33,
                  with_refunds: 10286.2,
                },
                net_arr: {
                  without_refunds: 2170.21,
                  with_refunds: 1501.87,
                },
                gross_arr: {
                  without_refunds: 2718.24,
                  with_refunds: 1905.05,
                },
                net_arr_spread: {
                  without_refunds: 9955.33,
                  with_refunds: 9955.33,
                },
                gross_arr_spread: {
                  without_refunds: 11184.52,
                  with_refunds: 11184.52,
                },
                net_total: {
                  without_refunds: 11275.63,
                  with_refunds: 10487.39,
                },
                gross_total: {
                  without_refunds: 13140.57,
                  with_refunds: 12191.25,
                },
                net_total_spread: {
                  without_refunds: 19060.75,
                  with_refunds: 18940.85,
                },
                gross_total_spread: {
                  without_refunds: 21606.85,
                  with_refunds: 21470.73,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3454,
                  with_refunds: 0.351,
                },
                gross_mrr: {
                  without_refunds: 0.3201,
                  with_refunds: 0.3257,
                },
                net_arr: {
                  without_refunds: 0.0297,
                  with_refunds: 0.0227,
                },
                gross_arr: {
                  without_refunds: 0.033,
                  with_refunds: 0.0255,
                },
                net_arr_spread: {
                  without_refunds: 1.8859,
                  with_refunds: 1.8859,
                },
                gross_arr_spread: {
                  without_refunds: 1.8721,
                  with_refunds: 1.8721,
                },
                net_total: {
                  without_refunds: 0.1134,
                  with_refunds: 0.1143,
                },
                gross_total: {
                  without_refunds: 0.1143,
                  with_refunds: 0.1146,
                },
                net_total_spread: {
                  without_refunds: 0.6023,
                  with_refunds: 0.6133,
                },
                gross_total_spread: {
                  without_refunds: 0.5608,
                  with_refunds: 0.5717,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8534,
                  with_refunds: 0.8658,
                },
                gross_mrr: {
                  without_refunds: 0.8491,
                  with_refunds: 0.8608,
                },
                net_arr: {
                  without_refunds: 2.0731,
                  with_refunds: 1.6106,
                },
                gross_arr: {
                  without_refunds: 2.0465,
                  with_refunds: 1.6308,
                },
                net_arr_spread: {
                  without_refunds: 1.0134,
                  with_refunds: 1.0134,
                },
                gross_arr_spread: {
                  without_refunds: 1.0151,
                  with_refunds: 1.0151,
                },
                net_total: {
                  without_refunds: 0.9623,
                  with_refunds: 0.9272,
                },
                gross_total: {
                  without_refunds: 0.966,
                  with_refunds: 0.9294,
                },
                net_total_spread: {
                  without_refunds: 0.9301,
                  with_refunds: 0.9376,
                },
                gross_total_spread: {
                  without_refunds: 0.9276,
                  with_refunds: 0.9348,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.65,
                  with_refunds: 2.62,
                },
                gross_mrr: {
                  without_refunds: 3.03,
                  with_refunds: 2.99,
                },
                net_arr: {
                  without_refunds: 1.41,
                  with_refunds: 0.98,
                },
                gross_arr: {
                  without_refunds: 1.77,
                  with_refunds: 1.24,
                },
                net_arr_spread: {
                  without_refunds: 7.58,
                  with_refunds: 7.58,
                },
                gross_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                net_total: {
                  without_refunds: 2.29,
                  with_refunds: 2.13,
                },
                gross_total: {
                  without_refunds: 2.66,
                  with_refunds: 2.47,
                },
                net_total_spread: {
                  without_refunds: 4.04,
                  with_refunds: 4.01,
                },
                gross_total_spread: {
                  without_refunds: 4.58,
                  with_refunds: 4.55,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.6,
                  with_refunds: 11.4,
                },
                gross_mrr: {
                  without_refunds: 13.28,
                  with_refunds: 13.05,
                },
                net_arr: {
                  without_refunds: 120.57,
                  with_refunds: 79.05,
                },
                gross_arr: {
                  without_refunds: 151.01,
                  with_refunds: 100.27,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 14.06,
                  with_refunds: 13.04,
                },
                gross_total: {
                  without_refunds: 16.38,
                  with_refunds: 15.16,
                },
                net_total_spread: {
                  without_refunds: 9.15,
                  with_refunds: 9.08,
                },
                gross_total_spread: {
                  without_refunds: 10.37,
                  with_refunds: 10.29,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.85,
                    with_refunds: 30.04,
                  },
                  gross_mrr: {
                    without_refunds: 36.72,
                    with_refunds: 35.76,
                  },
                  net_arr: {
                    without_refunds: 91.98,
                    with_refunds: 77.89,
                  },
                  gross_arr: {
                    without_refunds: 103.21,
                    with_refunds: 87.48,
                  },
                  net_arr_spread: {
                    without_refunds: 41.15,
                    with_refunds: 41.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 46.15,
                    with_refunds: 46.15,
                  },
                  net_total: {
                    without_refunds: 50.11,
                    with_refunds: 45.16,
                  },
                  gross_total: {
                    without_refunds: 57.7,
                    with_refunds: 52.13,
                  },
                  net_total_spread: {
                    without_refunds: 33.89,
                    with_refunds: 33.3,
                  },
                  gross_total_spread: {
                    without_refunds: 39.55,
                    with_refunds: 38.85,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 68.06,
                    with_refunds: 66.12,
                  },
                  gross_mrr: {
                    without_refunds: 80.13,
                    with_refunds: 77.87,
                  },
                  net_arr: {
                    without_refunds: 630.62,
                    with_refunds: 394.14,
                  },
                  gross_arr: {
                    without_refunds: 775.04,
                    with_refunds: 481.2,
                  },
                  net_arr_spread: {
                    without_refunds: 45.68,
                    with_refunds: 45.68,
                  },
                  gross_arr_spread: {
                    without_refunds: 51.27,
                    with_refunds: 51.27,
                  },
                  net_total: {
                    without_refunds: 111.04,
                    with_refunds: 100.65,
                  },
                  gross_total: {
                    without_refunds: 128.41,
                    with_refunds: 116.57,
                  },
                  net_total_spread: {
                    without_refunds: 57.76,
                    with_refunds: 56.7,
                  },
                  gross_total_spread: {
                    without_refunds: 67.14,
                    with_refunds: 65.9,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4881,
                    with_refunds: 0.4752,
                  },
                  gross_mrr: {
                    without_refunds: 0.5809,
                    with_refunds: 0.5657,
                  },
                  net_arr: {
                    without_refunds: 1.4552,
                    with_refunds: 1.2322,
                  },
                  gross_arr: {
                    without_refunds: 1.6328,
                    with_refunds: 1.3839,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6511,
                    with_refunds: 0.6511,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7302,
                    with_refunds: 0.7302,
                  },
                  net_total: {
                    without_refunds: 0.7928,
                    with_refunds: 0.7145,
                  },
                  gross_total: {
                    without_refunds: 0.9128,
                    with_refunds: 0.8247,
                  },
                  net_total_spread: {
                    without_refunds: 0.5362,
                    with_refunds: 0.5269,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6257,
                    with_refunds: 0.6147,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0768,
                    with_refunds: 1.0461,
                  },
                  gross_mrr: {
                    without_refunds: 1.2677,
                    with_refunds: 1.2319,
                  },
                  net_arr: {
                    without_refunds: 9.9766,
                    with_refunds: 6.2354,
                  },
                  gross_arr: {
                    without_refunds: 12.2613,
                    with_refunds: 7.6127,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7227,
                    with_refunds: 0.7227,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8111,
                    with_refunds: 0.8111,
                  },
                  net_total: {
                    without_refunds: 1.7567,
                    with_refunds: 1.5924,
                  },
                  gross_total: {
                    without_refunds: 2.0315,
                    with_refunds: 1.8441,
                  },
                  net_total_spread: {
                    without_refunds: 0.9137,
                    with_refunds: 0.8971,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0622,
                    with_refunds: 1.0426,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 670,
                  with_refunds: 671,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 8,
                },
                arr_spread_users: {
                  without_refunds: 1307,
                  with_refunds: 1307,
                },
                total_users: {
                  without_refunds: 678,
                  with_refunds: 679,
                },
                total_spread_users: {
                  without_refunds: 1977,
                  with_refunds: 1978,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1951,
                  with_refunds: 0.1953,
                },
                arr_users: {
                  without_refunds: 0.0052,
                  with_refunds: 0.0052,
                },
                arr_spread_users: {
                  without_refunds: 0.9954,
                  with_refunds: 0.9954,
                },
                total_users: {
                  without_refunds: 0.1375,
                  with_refunds: 0.1377,
                },
                total_spread_users: {
                  without_refunds: 0.4188,
                  with_refunds: 0.419,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8535,
                  with_refunds: 0.8515,
                },
                arr_users: {
                  without_refunds: 0.4444,
                  with_refunds: 0.4211,
                },
                arr_spread_users: {
                  without_refunds: 1.0054,
                  with_refunds: 1.0054,
                },
                total_users: {
                  without_refunds: 0.8454,
                  with_refunds: 0.8445,
                },
                total_spread_users: {
                  without_refunds: 0.9487,
                  with_refunds: 0.9482,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7603.92,
                  with_refunds: 7534.29,
                },
                gross_mrr: {
                  without_refunds: 8698.59,
                  with_refunds: 8612.86,
                },
                net_arr: {
                  without_refunds: 958.99,
                  with_refunds: 958.99,
                },
                gross_arr: {
                  without_refunds: 1200.59,
                  with_refunds: 1200.59,
                },
                net_arr_spread: {
                  without_refunds: 10025.01,
                  with_refunds: 10025.01,
                },
                gross_arr_spread: {
                  without_refunds: 11271.56,
                  with_refunds: 11271.56,
                },
                net_total: {
                  without_refunds: 8562.91,
                  with_refunds: 8493.28,
                },
                gross_total: {
                  without_refunds: 9899.18,
                  with_refunds: 9813.45,
                },
                net_total_spread: {
                  without_refunds: 17628.93,
                  with_refunds: 17559.31,
                },
                gross_total_spread: {
                  without_refunds: 19970.15,
                  with_refunds: 19884.42,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2884,
                  with_refunds: 0.2943,
                },
                gross_mrr: {
                  without_refunds: 0.2672,
                  with_refunds: 0.2727,
                },
                net_arr: {
                  without_refunds: 0.0131,
                  with_refunds: 0.0145,
                },
                gross_arr: {
                  without_refunds: 0.0146,
                  with_refunds: 0.016,
                },
                net_arr_spread: {
                  without_refunds: 1.8991,
                  with_refunds: 1.8991,
                },
                gross_arr_spread: {
                  without_refunds: 1.8867,
                  with_refunds: 1.8867,
                },
                net_total: {
                  without_refunds: 0.0861,
                  with_refunds: 0.0926,
                },
                gross_total: {
                  without_refunds: 0.0861,
                  with_refunds: 0.0922,
                },
                net_total_spread: {
                  without_refunds: 0.5571,
                  with_refunds: 0.5686,
                },
                gross_total_spread: {
                  without_refunds: 0.5183,
                  with_refunds: 0.5295,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8351,
                  with_refunds: 0.8385,
                },
                gross_mrr: {
                  without_refunds: 0.8346,
                  with_refunds: 0.8373,
                },
                net_arr: {
                  without_refunds: 0.4419,
                  with_refunds: 0.6385,
                },
                gross_arr: {
                  without_refunds: 0.4417,
                  with_refunds: 0.6302,
                },
                net_arr_spread: {
                  without_refunds: 1.007,
                  with_refunds: 1.007,
                },
                gross_arr_spread: {
                  without_refunds: 1.0078,
                  with_refunds: 1.0078,
                },
                net_total: {
                  without_refunds: 0.7594,
                  with_refunds: 0.8099,
                },
                gross_total: {
                  without_refunds: 0.7533,
                  with_refunds: 0.805,
                },
                net_total_spread: {
                  without_refunds: 0.9249,
                  with_refunds: 0.9271,
                },
                gross_total_spread: {
                  without_refunds: 0.9243,
                  with_refunds: 0.9261,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.21,
                  with_refunds: 2.19,
                },
                gross_mrr: {
                  without_refunds: 2.53,
                  with_refunds: 2.51,
                },
                net_arr: {
                  without_refunds: 0.62,
                  with_refunds: 0.62,
                },
                gross_arr: {
                  without_refunds: 0.78,
                  with_refunds: 0.78,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.58,
                  with_refunds: 8.58,
                },
                net_total: {
                  without_refunds: 1.74,
                  with_refunds: 1.72,
                },
                gross_total: {
                  without_refunds: 2.01,
                  with_refunds: 1.99,
                },
                net_total_spread: {
                  without_refunds: 3.73,
                  with_refunds: 3.72,
                },
                gross_total_spread: {
                  without_refunds: 4.23,
                  with_refunds: 4.21,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.35,
                  with_refunds: 11.23,
                },
                gross_mrr: {
                  without_refunds: 12.98,
                  with_refunds: 12.84,
                },
                net_arr: {
                  without_refunds: 119.87,
                  with_refunds: 119.87,
                },
                gross_arr: {
                  without_refunds: 150.07,
                  with_refunds: 150.07,
                },
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 12.63,
                  with_refunds: 12.51,
                },
                gross_total: {
                  without_refunds: 14.6,
                  with_refunds: 14.45,
                },
                net_total_spread: {
                  without_refunds: 8.92,
                  with_refunds: 8.88,
                },
                gross_total_spread: {
                  without_refunds: 10.1,
                  with_refunds: 10.05,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.06,
                    with_refunds: 32.23,
                  },
                  gross_mrr: {
                    without_refunds: 39.25,
                    with_refunds: 38.26,
                  },
                  net_arr: {
                    without_refunds: 92.61,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 103.99,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 48.79,
                    with_refunds: 48.79,
                  },
                  gross_arr_spread: {
                    without_refunds: 54.74,
                    with_refunds: 54.74,
                  },
                  net_total: {
                    without_refunds: 51.85,
                    with_refunds: 46.88,
                  },
                  gross_total: {
                    without_refunds: 59.7,
                    with_refunds: 54.12,
                  },
                  net_total_spread: {
                    without_refunds: 37.63,
                    with_refunds: 37.02,
                  },
                  gross_total_spread: {
                    without_refunds: 43.78,
                    with_refunds: 43.06,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 79.41,
                    with_refunds: 77.35,
                  },
                  gross_mrr: {
                    without_refunds: 93.11,
                    with_refunds: 90.71,
                  },
                  net_arr: {
                    without_refunds: 750.5,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 925.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 53.35,
                    with_refunds: 53.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 59.89,
                    with_refunds: 59.89,
                  },
                  net_total: {
                    without_refunds: 123.67,
                    with_refunds: 113.16,
                  },
                  gross_total: {
                    without_refunds: 143.01,
                    with_refunds: 131.02,
                  },
                  net_total_spread: {
                    without_refunds: 66.67,
                    with_refunds: 65.58,
                  },
                  gross_total_spread: {
                    without_refunds: 77.24,
                    with_refunds: 75.96,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5231,
                    with_refunds: 0.5099,
                  },
                  gross_mrr: {
                    without_refunds: 0.621,
                    with_refunds: 0.6054,
                  },
                  net_arr: {
                    without_refunds: 1.4651,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6452,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7719,
                    with_refunds: 0.7719,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.866,
                    with_refunds: 0.866,
                  },
                  net_total: {
                    without_refunds: 0.8203,
                    with_refunds: 0.7417,
                  },
                  gross_total: {
                    without_refunds: 0.9445,
                    with_refunds: 0.8562,
                  },
                  net_total_spread: {
                    without_refunds: 0.5953,
                    with_refunds: 0.5857,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6926,
                    with_refunds: 0.6813,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2563,
                    with_refunds: 1.2237,
                  },
                  gross_mrr: {
                    without_refunds: 1.4731,
                    with_refunds: 1.435,
                  },
                  net_arr: {
                    without_refunds: 11.873,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 14.6355,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8441,
                    with_refunds: 0.8441,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9475,
                    with_refunds: 0.9475,
                  },
                  net_total: {
                    without_refunds: 1.9565,
                    with_refunds: 1.7903,
                  },
                  gross_total: {
                    without_refunds: 2.2625,
                    with_refunds: 2.0728,
                  },
                  net_total_spread: {
                    without_refunds: 1.0548,
                    with_refunds: 1.0375,
                  },
                  gross_total_spread: {
                    without_refunds: 1.222,
                    with_refunds: 1.2017,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 577,
                  with_refunds: 578,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 1308,
                  with_refunds: 1308,
                },
                total_users: {
                  without_refunds: 578,
                  with_refunds: 580,
                },
                total_spread_users: {
                  without_refunds: 1885,
                  with_refunds: 1886,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.168,
                  with_refunds: 0.1683,
                },
                arr_users: {
                  without_refunds: 0.0007,
                  with_refunds: 0.0013,
                },
                arr_spread_users: {
                  without_refunds: 0.9962,
                  with_refunds: 0.9962,
                },
                total_users: {
                  without_refunds: 0.1172,
                  with_refunds: 0.1176,
                },
                total_spread_users: {
                  without_refunds: 0.3993,
                  with_refunds: 0.3995,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8612,
                  with_refunds: 0.8614,
                },
                arr_users: {
                  without_refunds: 0.125,
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                total_users: {
                  without_refunds: 0.8525,
                  with_refunds: 0.8542,
                },
                total_spread_users: {
                  without_refunds: 0.9535,
                  with_refunds: 0.9535,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6679.88,
                  with_refunds: 6609.06,
                },
                gross_mrr: {
                  without_refunds: 7643.33,
                  with_refunds: 7565.04,
                },
                net_arr: {
                  without_refunds: 83.7,
                },
                gross_arr: {
                  without_refunds: 119,
                },
                net_arr_spread: {
                  without_refunds: 10031.99,
                  with_refunds: 10031.99,
                },
                gross_arr_spread: {
                  without_refunds: 11281.48,
                  with_refunds: 11281.48,
                },
                net_total: {
                  without_refunds: 6763.58,
                  with_refunds: 6609.06,
                },
                gross_total: {
                  without_refunds: 7762.33,
                  with_refunds: 7565.04,
                },
                net_total_spread: {
                  without_refunds: 16711.87,
                  with_refunds: 16641.05,
                },
                gross_total_spread: {
                  without_refunds: 18924.81,
                  with_refunds: 18846.52,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2534,
                  with_refunds: 0.2581,
                },
                gross_mrr: {
                  without_refunds: 0.2348,
                  with_refunds: 0.2395,
                },
                net_arr: {
                  without_refunds: 0.0011,
                },
                gross_arr: {
                  without_refunds: 0.0014,
                },
                net_arr_spread: {
                  without_refunds: 1.9005,
                  with_refunds: 1.9005,
                },
                gross_arr_spread: {
                  without_refunds: 1.8884,
                  with_refunds: 1.8884,
                },
                net_total: {
                  without_refunds: 0.068,
                  with_refunds: 0.0721,
                },
                gross_total: {
                  without_refunds: 0.0675,
                  with_refunds: 0.0711,
                },
                net_total_spread: {
                  without_refunds: 0.5281,
                  with_refunds: 0.5389,
                },
                gross_total_spread: {
                  without_refunds: 0.4912,
                  with_refunds: 0.5018,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8785,
                  with_refunds: 0.8772,
                },
                gross_mrr: {
                  without_refunds: 0.8787,
                  with_refunds: 0.8783,
                },
                net_arr: {
                  without_refunds: 0.0873,
                },
                gross_arr: {
                  without_refunds: 0.0991,
                },
                net_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                gross_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                net_total: {
                  without_refunds: 0.7899,
                  with_refunds: 0.7782,
                },
                gross_total: {
                  without_refunds: 0.7841,
                  with_refunds: 0.7709,
                },
                net_total_spread: {
                  without_refunds: 0.948,
                  with_refunds: 0.9477,
                },
                gross_total_spread: {
                  without_refunds: 0.9477,
                  with_refunds: 0.9478,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.94,
                  with_refunds: 1.92,
                },
                gross_mrr: {
                  without_refunds: 2.23,
                  with_refunds: 2.2,
                },
                net_arr: {
                  without_refunds: 0.05,
                },
                gross_arr: {
                  without_refunds: 0.08,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 1.37,
                  with_refunds: 1.34,
                },
                gross_total: {
                  without_refunds: 1.57,
                  with_refunds: 1.53,
                },
                net_total_spread: {
                  without_refunds: 3.54,
                  with_refunds: 3.52,
                },
                gross_total_spread: {
                  without_refunds: 4.01,
                  with_refunds: 3.99,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.58,
                  with_refunds: 11.43,
                },
                gross_mrr: {
                  without_refunds: 13.25,
                  with_refunds: 13.09,
                },
                net_arr: {
                  without_refunds: 83.7,
                },
                gross_arr: {
                  without_refunds: 119,
                },
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 11.7,
                  with_refunds: 11.39,
                },
                gross_total: {
                  without_refunds: 13.43,
                  with_refunds: 13.04,
                },
                net_total_spread: {
                  without_refunds: 8.87,
                  with_refunds: 8.82,
                },
                gross_total_spread: {
                  without_refunds: 10.04,
                  with_refunds: 9.99,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.01,
                    with_refunds: 34.16,
                  },
                  gross_mrr: {
                    without_refunds: 41.48,
                    with_refunds: 40.47,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 56.43,
                    with_refunds: 56.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 63.33,
                    with_refunds: 63.33,
                  },
                  net_total: {
                    without_refunds: 53.22,
                    with_refunds: 48.22,
                  },
                  gross_total: {
                    without_refunds: 61.28,
                    with_refunds: 55.65,
                  },
                  net_total_spread: {
                    without_refunds: 41.17,
                    with_refunds: 40.55,
                  },
                  gross_total_spread: {
                    without_refunds: 47.79,
                    with_refunds: 47.06,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 90.99,
                    with_refunds: 88.79,
                  },
                  gross_mrr: {
                    without_refunds: 106.36,
                    with_refunds: 103.79,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 61.02,
                    with_refunds: 61.02,
                  },
                  gross_arr_spread: {
                    without_refunds: 68.52,
                    with_refunds: 68.52,
                  },
                  net_total: {
                    without_refunds: 135.37,
                    with_refunds: 124.56,
                  },
                  gross_total: {
                    without_refunds: 156.44,
                    with_refunds: 144.06,
                  },
                  net_total_spread: {
                    without_refunds: 75.54,
                    with_refunds: 74.41,
                  },
                  gross_total_spread: {
                    without_refunds: 87.28,
                    with_refunds: 85.95,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5538,
                    with_refunds: 0.5404,
                  },
                  gross_mrr: {
                    without_refunds: 0.6562,
                    with_refunds: 0.6402,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8927,
                    with_refunds: 0.8927,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0019,
                    with_refunds: 1.0019,
                  },
                  net_total: {
                    without_refunds: 0.842,
                    with_refunds: 0.7629,
                  },
                  gross_total: {
                    without_refunds: 0.9694,
                    with_refunds: 0.8805,
                  },
                  net_total_spread: {
                    without_refunds: 0.6513,
                    with_refunds: 0.6415,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7561,
                    with_refunds: 0.7445,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.4395,
                    with_refunds: 1.4046,
                  },
                  gross_mrr: {
                    without_refunds: 1.6827,
                    with_refunds: 1.642,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9654,
                    with_refunds: 0.9654,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.084,
                    with_refunds: 1.084,
                  },
                  net_total: {
                    without_refunds: 2.1416,
                    with_refunds: 1.9705,
                  },
                  gross_total: {
                    without_refunds: 2.4749,
                    with_refunds: 2.2791,
                  },
                  net_total_spread: {
                    without_refunds: 1.1951,
                    with_refunds: 1.1771,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3808,
                    with_refunds: 1.3597,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 506,
                  with_refunds: 508,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1308,
                  with_refunds: 1308,
                },
                total_users: {
                  without_refunds: 506,
                  with_refunds: 508,
                },
                total_spread_users: {
                  without_refunds: 1814,
                  with_refunds: 1816,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1473,
                  with_refunds: 0.1479,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9962,
                  with_refunds: 0.9962,
                },
                total_users: {
                  without_refunds: 0.1026,
                  with_refunds: 0.103,
                },
                total_spread_users: {
                  without_refunds: 0.3842,
                  with_refunds: 0.3847,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8789,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8754,
                  with_refunds: 0.8759,
                },
                total_spread_users: {
                  without_refunds: 0.9623,
                  with_refunds: 0.9629,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5828.68,
                  with_refunds: 5716.74,
                },
                gross_mrr: {
                  without_refunds: 6665.57,
                  with_refunds: 6543.51,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10031.99,
                  with_refunds: 10031.99,
                },
                gross_arr_spread: {
                  without_refunds: 11281.48,
                  with_refunds: 11281.48,
                },
                net_total: {
                  without_refunds: 5828.68,
                  with_refunds: 5716.74,
                },
                gross_total: {
                  without_refunds: 6665.57,
                  with_refunds: 6543.51,
                },
                net_total_spread: {
                  without_refunds: 15860.67,
                  with_refunds: 15748.72,
                },
                gross_total_spread: {
                  without_refunds: 17947.05,
                  with_refunds: 17824.98,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2211,
                  with_refunds: 0.2233,
                },
                gross_mrr: {
                  without_refunds: 0.2047,
                  with_refunds: 0.2072,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9005,
                  with_refunds: 1.9005,
                },
                gross_arr_spread: {
                  without_refunds: 1.8884,
                  with_refunds: 1.8884,
                },
                net_total: {
                  without_refunds: 0.0586,
                  with_refunds: 0.0623,
                },
                gross_total: {
                  without_refunds: 0.058,
                  with_refunds: 0.0615,
                },
                net_total_spread: {
                  without_refunds: 0.5012,
                  with_refunds: 0.51,
                },
                gross_total_spread: {
                  without_refunds: 0.4658,
                  with_refunds: 0.4746,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8726,
                  with_refunds: 0.865,
                },
                gross_mrr: {
                  without_refunds: 0.8721,
                  with_refunds: 0.865,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8618,
                  with_refunds: 0.865,
                },
                gross_total: {
                  without_refunds: 0.8587,
                  with_refunds: 0.865,
                },
                net_total_spread: {
                  without_refunds: 0.9491,
                  with_refunds: 0.9464,
                },
                gross_total_spread: {
                  without_refunds: 0.9483,
                  with_refunds: 0.9458,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.7,
                  with_refunds: 1.66,
                },
                gross_mrr: {
                  without_refunds: 1.94,
                  with_refunds: 1.9,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 1.18,
                  with_refunds: 1.16,
                },
                gross_total: {
                  without_refunds: 1.35,
                  with_refunds: 1.33,
                },
                net_total_spread: {
                  without_refunds: 3.36,
                  with_refunds: 3.34,
                },
                gross_total_spread: {
                  without_refunds: 3.8,
                  with_refunds: 3.78,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.52,
                  with_refunds: 11.25,
                },
                gross_mrr: {
                  without_refunds: 13.17,
                  with_refunds: 12.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 11.52,
                  with_refunds: 11.25,
                },
                gross_total: {
                  without_refunds: 13.17,
                  with_refunds: 12.88,
                },
                net_total_spread: {
                  without_refunds: 8.74,
                  with_refunds: 8.67,
                },
                gross_total_spread: {
                  without_refunds: 9.89,
                  with_refunds: 9.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.71,
                    with_refunds: 35.82,
                  },
                  gross_mrr: {
                    without_refunds: 43.42,
                    with_refunds: 42.37,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 64.07,
                    with_refunds: 64.07,
                  },
                  gross_arr_spread: {
                    without_refunds: 71.92,
                    with_refunds: 71.92,
                  },
                  net_total: {
                    without_refunds: 54.4,
                    with_refunds: 49.38,
                  },
                  gross_total: {
                    without_refunds: 62.63,
                    with_refunds: 56.98,
                  },
                  net_total_spread: {
                    without_refunds: 44.53,
                    with_refunds: 43.88,
                  },
                  gross_total_spread: {
                    without_refunds: 51.59,
                    with_refunds: 50.83,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 102.51,
                    with_refunds: 100.04,
                  },
                  gross_mrr: {
                    without_refunds: 119.53,
                    with_refunds: 116.67,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 68.69,
                    with_refunds: 68.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 77.14,
                    with_refunds: 77.14,
                  },
                  net_total: {
                    without_refunds: 146.89,
                    with_refunds: 135.81,
                  },
                  gross_total: {
                    without_refunds: 169.61,
                    with_refunds: 156.94,
                  },
                  net_total_spread: {
                    without_refunds: 84.28,
                    with_refunds: 83.08,
                  },
                  gross_total_spread: {
                    without_refunds: 97.18,
                    with_refunds: 95.76,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5807,
                    with_refunds: 0.5667,
                  },
                  gross_mrr: {
                    without_refunds: 0.6869,
                    with_refunds: 0.6703,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0136,
                    with_refunds: 1.0136,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1378,
                    with_refunds: 1.1378,
                  },
                  net_total: {
                    without_refunds: 0.8607,
                    with_refunds: 0.7813,
                  },
                  gross_total: {
                    without_refunds: 0.9908,
                    with_refunds: 0.9014,
                  },
                  net_total_spread: {
                    without_refunds: 0.7044,
                    with_refunds: 0.6942,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8162,
                    with_refunds: 0.8042,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.6217,
                    with_refunds: 1.5827,
                  },
                  gross_mrr: {
                    without_refunds: 1.8911,
                    with_refunds: 1.8458,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0868,
                    with_refunds: 1.0868,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2204,
                    with_refunds: 1.2204,
                  },
                  net_total: {
                    without_refunds: 2.3239,
                    with_refunds: 2.1486,
                  },
                  gross_total: {
                    without_refunds: 2.6833,
                    with_refunds: 2.4829,
                  },
                  net_total_spread: {
                    without_refunds: 1.3334,
                    with_refunds: 1.3143,
                  },
                  gross_total_spread: {
                    without_refunds: 1.5374,
                    with_refunds: 1.515,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 118,
                  with_refunds: 119,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1308,
                  with_refunds: 1308,
                },
                total_users: {
                  without_refunds: 118,
                  with_refunds: 119,
                },
                total_spread_users: {
                  without_refunds: 1426,
                  with_refunds: 1427,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0344,
                  with_refunds: 0.0346,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9962,
                  with_refunds: 0.9962,
                },
                total_users: {
                  without_refunds: 0.0239,
                  with_refunds: 0.0241,
                },
                total_spread_users: {
                  without_refunds: 0.3021,
                  with_refunds: 0.3023,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2332,
                  with_refunds: 0.2343,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.2332,
                  with_refunds: 0.2343,
                },
                total_spread_users: {
                  without_refunds: 0.7861,
                  with_refunds: 0.7858,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1344.14,
                  with_refunds: 1332.79,
                },
                gross_mrr: {
                  without_refunds: 1531.05,
                  with_refunds: 1519.05,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10031.99,
                  with_refunds: 10031.99,
                },
                gross_arr_spread: {
                  without_refunds: 11281.48,
                  with_refunds: 11281.48,
                },
                net_total: {
                  without_refunds: 1344.14,
                  with_refunds: 1332.79,
                },
                gross_total: {
                  without_refunds: 1531.05,
                  with_refunds: 1519.05,
                },
                net_total_spread: {
                  without_refunds: 11376.13,
                  with_refunds: 11364.78,
                },
                gross_total_spread: {
                  without_refunds: 12812.53,
                  with_refunds: 12800.53,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.051,
                  with_refunds: 0.0521,
                },
                gross_mrr: {
                  without_refunds: 0.047,
                  with_refunds: 0.0481,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9005,
                  with_refunds: 1.9005,
                },
                gross_arr_spread: {
                  without_refunds: 1.8884,
                  with_refunds: 1.8884,
                },
                net_total: {
                  without_refunds: 0.0135,
                  with_refunds: 0.0145,
                },
                gross_total: {
                  without_refunds: 0.0133,
                  with_refunds: 0.0143,
                },
                net_total_spread: {
                  without_refunds: 0.3595,
                  with_refunds: 0.368,
                },
                gross_total_spread: {
                  without_refunds: 0.3325,
                  with_refunds: 0.3408,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2306,
                  with_refunds: 0.2331,
                },
                gross_mrr: {
                  without_refunds: 0.2297,
                  with_refunds: 0.2321,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.2306,
                  with_refunds: 0.2331,
                },
                gross_total: {
                  without_refunds: 0.2297,
                  with_refunds: 0.2321,
                },
                net_total_spread: {
                  without_refunds: 0.7173,
                  with_refunds: 0.7216,
                },
                gross_total_spread: {
                  without_refunds: 0.7139,
                  with_refunds: 0.7181,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.39,
                  with_refunds: 0.39,
                },
                gross_mrr: {
                  without_refunds: 0.45,
                  with_refunds: 0.44,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 0.27,
                  with_refunds: 0.27,
                },
                gross_total: {
                  without_refunds: 0.31,
                  with_refunds: 0.31,
                },
                net_total_spread: {
                  without_refunds: 2.41,
                  with_refunds: 2.41,
                },
                gross_total_spread: {
                  without_refunds: 2.71,
                  with_refunds: 2.71,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.39,
                  with_refunds: 11.2,
                },
                gross_mrr: {
                  without_refunds: 12.97,
                  with_refunds: 12.77,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 11.39,
                  with_refunds: 11.2,
                },
                gross_total: {
                  without_refunds: 12.97,
                  with_refunds: 12.77,
                },
                net_total_spread: {
                  without_refunds: 7.98,
                  with_refunds: 7.96,
                },
                gross_total_spread: {
                  without_refunds: 8.98,
                  with_refunds: 8.97,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.1,
                    with_refunds: 36.21,
                  },
                  gross_mrr: {
                    without_refunds: 43.86,
                    with_refunds: 42.81,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 71.71,
                    with_refunds: 71.71,
                  },
                  gross_arr_spread: {
                    without_refunds: 80.51,
                    with_refunds: 80.51,
                  },
                  net_total: {
                    without_refunds: 54.68,
                    with_refunds: 49.65,
                  },
                  gross_total: {
                    without_refunds: 62.94,
                    with_refunds: 57.29,
                  },
                  net_total_spread: {
                    without_refunds: 46.94,
                    with_refunds: 46.29,
                  },
                  gross_total_spread: {
                    without_refunds: 54.31,
                    with_refunds: 53.54,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 113.9,
                    with_refunds: 111.24,
                  },
                  gross_mrr: {
                    without_refunds: 132.51,
                    with_refunds: 129.44,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 76.36,
                    with_refunds: 76.36,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.77,
                    with_refunds: 85.77,
                  },
                  net_total: {
                    without_refunds: 158.28,
                    with_refunds: 147.01,
                  },
                  gross_total: {
                    without_refunds: 182.59,
                    with_refunds: 169.71,
                  },
                  net_total_spread: {
                    without_refunds: 92.26,
                    with_refunds: 91.04,
                  },
                  gross_total_spread: {
                    without_refunds: 106.16,
                    with_refunds: 104.74,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5869,
                    with_refunds: 0.5728,
                  },
                  gross_mrr: {
                    without_refunds: 0.6939,
                    with_refunds: 0.6773,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1345,
                    with_refunds: 1.1345,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2738,
                    with_refunds: 1.2738,
                  },
                  net_total: {
                    without_refunds: 0.865,
                    with_refunds: 0.7855,
                  },
                  gross_total: {
                    without_refunds: 0.9957,
                    with_refunds: 0.9063,
                  },
                  net_total_spread: {
                    without_refunds: 0.7425,
                    with_refunds: 0.7323,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8591,
                    with_refunds: 0.8471,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.8019,
                    with_refunds: 1.7599,
                  },
                  gross_mrr: {
                    without_refunds: 2.0963,
                    with_refunds: 2.0478,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2081,
                    with_refunds: 1.2081,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3569,
                    with_refunds: 1.3569,
                  },
                  net_total: {
                    without_refunds: 2.5041,
                    with_refunds: 2.3257,
                  },
                  gross_total: {
                    without_refunds: 2.8886,
                    with_refunds: 2.6848,
                  },
                  net_total_spread: {
                    without_refunds: 1.4596,
                    with_refunds: 1.4403,
                  },
                  gross_total_spread: {
                    without_refunds: 1.6795,
                    with_refunds: 1.6569,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 2411,
              with_refunds: 2411,
            },
            returning_users: {
              without_refunds: 5423,
              with_refunds: 5448,
            },
            paying_users: {
              without_refunds: 7834,
              with_refunds: 7859,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 805,
              with_refunds: 805,
            },
            returning_users: {
              without_refunds: 508,
              with_refunds: 531,
            },
            paying_users: {
              without_refunds: 1313,
              with_refunds: 1336,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 3212,
              with_refunds: 3212,
            },
            returning_users: {
              without_refunds: 5926,
              with_refunds: 5973,
            },
            paying_users: {
              without_refunds: 9138,
              with_refunds: 9185,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 127427.34,
                with_refunds: 124377.98,
              },
              gross_mrr: {
                without_refunds: 150665.42,
                with_refunds: 147066.04,
              },
              net_arr: {
                without_refunds: 142235.72,
                with_refunds: 120515.78,
              },
              gross_arr: {
                without_refunds: 159747.67,
                with_refunds: 135481.15,
              },
              net_arr_spread: {
                without_refunds: 94155.62,
                with_refunds: 94155.62,
              },
              gross_arr_spread: {
                without_refunds: 105715.18,
                with_refunds: 105715.18,
              },
              net_total: {
                without_refunds: 269663.06,
                with_refunds: 244893.76,
              },
              gross_total: {
                without_refunds: 310413.09,
                with_refunds: 282547.19,
              },
              net_total_spread: {
                without_refunds: 221582.96,
                with_refunds: 218533.6,
              },
              gross_total_spread: {
                without_refunds: 256380.61,
                with_refunds: 252781.22,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 127427.34,
                with_refunds: 124377.98,
              },
              gross_mrr: {
                without_refunds: 150665.42,
                with_refunds: 147066.04,
              },
              net_arr: {
                without_refunds: 142235.72,
                with_refunds: 120515.78,
              },
              gross_arr: {
                without_refunds: 159747.67,
                with_refunds: 135481.15,
              },
              net_arr_spread: {
                without_refunds: 94155.62,
                with_refunds: 94155.62,
              },
              gross_arr_spread: {
                without_refunds: 105715.18,
                with_refunds: 105715.18,
              },
              net_total: {
                without_refunds: 269663.06,
                with_refunds: 244893.76,
              },
              gross_total: {
                without_refunds: 310413.09,
                with_refunds: 282547.19,
              },
              net_total_spread: {
                without_refunds: 221582.96,
                with_refunds: 218533.6,
              },
              gross_total_spread: {
                without_refunds: 256380.61,
                with_refunds: 252781.22,
              },
            },
          },
        },
      },
      cac: 63.21,
      mrr_ua_spend: {
        without_refunds: 217126.35,
        with_refunds: 217126.35,
      },
      arr_ua_spend: {
        without_refunds: 97027.35,
        with_refunds: 97027.35,
      },
      arr_spread_ua_spend: {
        without_refunds: 82994.73,
        with_refunds: 82994.73,
      },
      total_ua_spend: {
        without_refunds: 311751.72,
        with_refunds: 311751.72,
      },
      total_spread_ua_spend: {
        without_refunds: 298414.41,
        with_refunds: 298414.41,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 11,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 2452,
        with_refunds: 2452,
      },
      first_period_arr_users: {
        without_refunds: 1588,
        with_refunds: 1588,
      },
      first_period_arr_spread_users: {
        without_refunds: 1365,
        with_refunds: 1365,
      },
      first_period_total_users: {
        without_refunds: 3993,
        with_refunds: 3993,
      },
      first_period_total_spread_users: {
        without_refunds: 3785,
        with_refunds: 3785,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1406,
                  with_refunds: 1406,
                },
                arr_users: {
                  without_refunds: 904,
                  with_refunds: 904,
                },
                arr_spread_users: {
                  without_refunds: 783,
                  with_refunds: 783,
                },
                total_users: {
                  without_refunds: 2304,
                  with_refunds: 2304,
                },
                total_spread_users: {
                  without_refunds: 2186,
                  with_refunds: 2186,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5734,
                  with_refunds: 0.5734,
                },
                arr_users: {
                  without_refunds: 0.5693,
                  with_refunds: 0.5693,
                },
                arr_spread_users: {
                  without_refunds: 0.5736,
                  with_refunds: 0.5736,
                },
                total_users: {
                  without_refunds: 0.577,
                  with_refunds: 0.577,
                },
                total_spread_users: {
                  without_refunds: 0.5775,
                  with_refunds: 0.5775,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 16425.96,
                  with_refunds: 15740.65,
                },
                gross_mrr: {
                  without_refunds: 19901.31,
                  with_refunds: 19041.88,
                },
                net_arr: {
                  without_refunds: 81233.27,
                  with_refunds: 73764.38,
                },
                gross_arr: {
                  without_refunds: 91386.65,
                  with_refunds: 82980.84,
                },
                net_arr_spread: {
                  without_refunds: 5845.79,
                  with_refunds: 5845.79,
                },
                gross_arr_spread: {
                  without_refunds: 6575.6,
                  with_refunds: 6575.6,
                },
                net_total: {
                  without_refunds: 97659.23,
                  with_refunds: 89505.04,
                },
                gross_total: {
                  without_refunds: 111287.97,
                  with_refunds: 102022.73,
                },
                net_total_spread: {
                  without_refunds: 22271.75,
                  with_refunds: 21586.45,
                },
                gross_total_spread: {
                  without_refunds: 26476.91,
                  with_refunds: 25617.48,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.7,
                  with_refunds: 6.42,
                },
                gross_mrr: {
                  without_refunds: 8.12,
                  with_refunds: 7.77,
                },
                net_arr: {
                  without_refunds: 51.15,
                  with_refunds: 46.45,
                },
                gross_arr: {
                  without_refunds: 57.55,
                  with_refunds: 52.25,
                },
                net_arr_spread: {
                  without_refunds: 4.28,
                  with_refunds: 4.28,
                },
                gross_arr_spread: {
                  without_refunds: 4.82,
                  with_refunds: 4.82,
                },
                net_total: {
                  without_refunds: 24.46,
                  with_refunds: 22.42,
                },
                gross_total: {
                  without_refunds: 27.87,
                  with_refunds: 25.55,
                },
                net_total_spread: {
                  without_refunds: 5.88,
                  with_refunds: 5.7,
                },
                gross_total_spread: {
                  without_refunds: 7,
                  with_refunds: 6.77,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.68,
                  with_refunds: 11.2,
                },
                gross_mrr: {
                  without_refunds: 14.15,
                  with_refunds: 13.54,
                },
                net_arr: {
                  without_refunds: 89.86,
                  with_refunds: 81.6,
                },
                gross_arr: {
                  without_refunds: 101.09,
                  with_refunds: 91.79,
                },
                net_arr_spread: {
                  without_refunds: 7.47,
                  with_refunds: 7.47,
                },
                gross_arr_spread: {
                  without_refunds: 8.4,
                  with_refunds: 8.4,
                },
                net_total: {
                  without_refunds: 42.39,
                  with_refunds: 38.85,
                },
                gross_total: {
                  without_refunds: 48.3,
                  with_refunds: 44.28,
                },
                net_total_spread: {
                  without_refunds: 10.19,
                  with_refunds: 9.87,
                },
                gross_total_spread: {
                  without_refunds: 12.11,
                  with_refunds: 11.72,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 6.7,
                    with_refunds: 6.42,
                  },
                  gross_mrr: {
                    without_refunds: 8.12,
                    with_refunds: 7.77,
                  },
                  net_arr: {
                    without_refunds: 51.15,
                    with_refunds: 46.45,
                  },
                  gross_arr: {
                    without_refunds: 57.55,
                    with_refunds: 52.25,
                  },
                  net_arr_spread: {
                    without_refunds: 4.28,
                    with_refunds: 4.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.82,
                    with_refunds: 4.82,
                  },
                  net_total: {
                    without_refunds: 24.46,
                    with_refunds: 22.42,
                  },
                  gross_total: {
                    without_refunds: 27.87,
                    with_refunds: 25.55,
                  },
                  net_total_spread: {
                    without_refunds: 5.88,
                    with_refunds: 5.7,
                  },
                  gross_total_spread: {
                    without_refunds: 7,
                    with_refunds: 6.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 11.68,
                    with_refunds: 11.2,
                  },
                  gross_mrr: {
                    without_refunds: 14.15,
                    with_refunds: 13.54,
                  },
                  net_arr: {
                    without_refunds: 89.86,
                    with_refunds: 81.6,
                  },
                  gross_arr: {
                    without_refunds: 101.09,
                    with_refunds: 91.79,
                  },
                  net_arr_spread: {
                    without_refunds: 7.47,
                    with_refunds: 7.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.4,
                    with_refunds: 8.4,
                  },
                  net_total: {
                    without_refunds: 42.39,
                    with_refunds: 38.85,
                  },
                  gross_total: {
                    without_refunds: 48.3,
                    with_refunds: 44.28,
                  },
                  net_total_spread: {
                    without_refunds: 10.19,
                    with_refunds: 9.87,
                  },
                  gross_total_spread: {
                    without_refunds: 12.11,
                    with_refunds: 11.72,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1503,
                    with_refunds: 0.144,
                  },
                  gross_mrr: {
                    without_refunds: 0.1821,
                    with_refunds: 0.1742,
                  },
                  net_arr: {
                    without_refunds: 1.1475,
                    with_refunds: 1.042,
                  },
                  gross_arr: {
                    without_refunds: 1.2909,
                    with_refunds: 1.1722,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0961,
                    with_refunds: 0.0961,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1081,
                    with_refunds: 0.1081,
                  },
                  net_total: {
                    without_refunds: 0.5486,
                    with_refunds: 0.5028,
                  },
                  gross_total: {
                    without_refunds: 0.6252,
                    with_refunds: 0.5731,
                  },
                  net_total_spread: {
                    without_refunds: 0.132,
                    with_refunds: 0.1279,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1569,
                    with_refunds: 0.1518,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2621,
                    with_refunds: 0.2511,
                  },
                  gross_mrr: {
                    without_refunds: 0.3175,
                    with_refunds: 0.3038,
                  },
                  net_arr: {
                    without_refunds: 2.0157,
                    with_refunds: 1.8304,
                  },
                  gross_arr: {
                    without_refunds: 2.2676,
                    with_refunds: 2.0591,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1675,
                    with_refunds: 0.1675,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1884,
                    with_refunds: 0.1884,
                  },
                  net_total: {
                    without_refunds: 0.9508,
                    with_refunds: 0.8714,
                  },
                  gross_total: {
                    without_refunds: 1.0835,
                    with_refunds: 0.9933,
                  },
                  net_total_spread: {
                    without_refunds: 0.2285,
                    with_refunds: 0.2215,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2717,
                    with_refunds: 0.2629,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1859,
                  with_refunds: 1864,
                },
                arr_users: {
                  without_refunds: 630,
                  with_refunds: 664,
                },
                arr_spread_users: {
                  without_refunds: 1316,
                  with_refunds: 1316,
                },
                total_users: {
                  without_refunds: 2486,
                  with_refunds: 2525,
                },
                total_spread_users: {
                  without_refunds: 3175,
                  with_refunds: 3180,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7582,
                  with_refunds: 0.7602,
                },
                arr_users: {
                  without_refunds: 0.3967,
                  with_refunds: 0.4181,
                },
                arr_spread_users: {
                  without_refunds: 0.9641,
                  with_refunds: 0.9641,
                },
                total_users: {
                  without_refunds: 0.6226,
                  with_refunds: 0.6324,
                },
                total_spread_users: {
                  without_refunds: 0.8388,
                  with_refunds: 0.8402,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.3222,
                  with_refunds: 1.3257,
                },
                arr_users: {
                  without_refunds: 0.6969,
                  with_refunds: 0.7345,
                },
                arr_spread_users: {
                  without_refunds: 1.6807,
                  with_refunds: 1.6807,
                },
                total_users: {
                  without_refunds: 1.079,
                  with_refunds: 1.0959,
                },
                total_spread_users: {
                  without_refunds: 1.4524,
                  with_refunds: 1.4547,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 22547.05,
                  with_refunds: 21580.29,
                },
                gross_mrr: {
                  without_refunds: 26691.42,
                  with_refunds: 25569.2,
                },
                net_arr: {
                  without_refunds: 60400.49,
                  with_refunds: 48913.87,
                },
                gross_arr: {
                  without_refunds: 67797.53,
                  with_refunds: 54949.77,
                },
                net_arr_spread: {
                  without_refunds: 10112.39,
                  with_refunds: 10112.39,
                },
                gross_arr_spread: {
                  without_refunds: 11378.61,
                  with_refunds: 11378.61,
                },
                net_total: {
                  without_refunds: 82947.54,
                  with_refunds: 70494.15,
                },
                gross_total: {
                  without_refunds: 94488.95,
                  with_refunds: 80518.97,
                },
                net_total_spread: {
                  without_refunds: 32659.44,
                  with_refunds: 31692.68,
                },
                gross_total_spread: {
                  without_refunds: 38070.03,
                  with_refunds: 36947.82,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.3726,
                  with_refunds: 1.371,
                },
                gross_mrr: {
                  without_refunds: 1.3412,
                  with_refunds: 1.3428,
                },
                net_arr: {
                  without_refunds: 0.7435,
                  with_refunds: 0.6631,
                },
                gross_arr: {
                  without_refunds: 0.7419,
                  with_refunds: 0.6622,
                },
                net_arr_spread: {
                  without_refunds: 1.7299,
                  with_refunds: 1.7299,
                },
                gross_arr_spread: {
                  without_refunds: 1.7304,
                  with_refunds: 1.7304,
                },
                net_total: {
                  without_refunds: 0.8494,
                  with_refunds: 0.7876,
                },
                gross_total: {
                  without_refunds: 0.849,
                  with_refunds: 0.7892,
                },
                net_total_spread: {
                  without_refunds: 1.4664,
                  with_refunds: 1.4682,
                },
                gross_total_spread: {
                  without_refunds: 1.4379,
                  with_refunds: 1.4423,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.3726,
                  with_refunds: 1.371,
                },
                gross_mrr: {
                  without_refunds: 1.3412,
                  with_refunds: 1.3428,
                },
                net_arr: {
                  without_refunds: 0.7435,
                  with_refunds: 0.6631,
                },
                gross_arr: {
                  without_refunds: 0.7419,
                  with_refunds: 0.6622,
                },
                net_arr_spread: {
                  without_refunds: 1.7299,
                  with_refunds: 1.7299,
                },
                gross_arr_spread: {
                  without_refunds: 1.7304,
                  with_refunds: 1.7304,
                },
                net_total: {
                  without_refunds: 0.8494,
                  with_refunds: 0.7876,
                },
                gross_total: {
                  without_refunds: 0.849,
                  with_refunds: 0.7892,
                },
                net_total_spread: {
                  without_refunds: 1.4664,
                  with_refunds: 1.4682,
                },
                gross_total_spread: {
                  without_refunds: 1.4379,
                  with_refunds: 1.4423,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 9.2,
                  with_refunds: 8.8,
                },
                gross_mrr: {
                  without_refunds: 10.89,
                  with_refunds: 10.43,
                },
                net_arr: {
                  without_refunds: 38.04,
                  with_refunds: 30.8,
                },
                gross_arr: {
                  without_refunds: 42.69,
                  with_refunds: 34.6,
                },
                net_arr_spread: {
                  without_refunds: 7.41,
                  with_refunds: 7.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.34,
                },
                net_total: {
                  without_refunds: 20.77,
                  with_refunds: 17.65,
                },
                gross_total: {
                  without_refunds: 23.66,
                  with_refunds: 20.17,
                },
                net_total_spread: {
                  without_refunds: 8.63,
                  with_refunds: 8.37,
                },
                gross_total_spread: {
                  without_refunds: 10.06,
                  with_refunds: 9.76,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.13,
                  with_refunds: 11.58,
                },
                gross_mrr: {
                  without_refunds: 14.36,
                  with_refunds: 13.72,
                },
                net_arr: {
                  without_refunds: 95.87,
                  with_refunds: 73.67,
                },
                gross_arr: {
                  without_refunds: 107.62,
                  with_refunds: 82.76,
                },
                net_arr_spread: {
                  without_refunds: 7.68,
                  with_refunds: 7.68,
                },
                gross_arr_spread: {
                  without_refunds: 8.65,
                  with_refunds: 8.65,
                },
                net_total: {
                  without_refunds: 33.37,
                  with_refunds: 27.92,
                },
                gross_total: {
                  without_refunds: 38.01,
                  with_refunds: 31.89,
                },
                net_total_spread: {
                  without_refunds: 10.29,
                  with_refunds: 9.97,
                },
                gross_total_spread: {
                  without_refunds: 11.99,
                  with_refunds: 11.62,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.89,
                    with_refunds: 15.22,
                  },
                  gross_mrr: {
                    without_refunds: 19,
                    with_refunds: 18.19,
                  },
                  net_arr: {
                    without_refunds: 89.19,
                    with_refunds: 77.25,
                  },
                  gross_arr: {
                    without_refunds: 100.24,
                    with_refunds: 86.86,
                  },
                  net_arr_spread: {
                    without_refunds: 11.69,
                    with_refunds: 11.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.15,
                    with_refunds: 13.15,
                  },
                  net_total: {
                    without_refunds: 45.23,
                    with_refunds: 40.07,
                  },
                  gross_total: {
                    without_refunds: 51.53,
                    with_refunds: 45.72,
                  },
                  net_total_spread: {
                    without_refunds: 14.51,
                    with_refunds: 14.08,
                  },
                  gross_total_spread: {
                    without_refunds: 17.05,
                    with_refunds: 16.53,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.81,
                    with_refunds: 22.77,
                  },
                  gross_mrr: {
                    without_refunds: 28.51,
                    with_refunds: 27.26,
                  },
                  net_arr: {
                    without_refunds: 185.73,
                    with_refunds: 155.26,
                  },
                  gross_arr: {
                    without_refunds: 208.71,
                    with_refunds: 174.55,
                  },
                  net_arr_spread: {
                    without_refunds: 15.15,
                    with_refunds: 15.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.04,
                    with_refunds: 17.04,
                  },
                  net_total: {
                    without_refunds: 75.75,
                    with_refunds: 66.77,
                  },
                  gross_total: {
                    without_refunds: 86.31,
                    with_refunds: 76.17,
                  },
                  net_total_spread: {
                    without_refunds: 20.47,
                    with_refunds: 19.84,
                  },
                  gross_total_spread: {
                    without_refunds: 24.1,
                    with_refunds: 23.34,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3565,
                    with_refunds: 0.3414,
                  },
                  gross_mrr: {
                    without_refunds: 0.4262,
                    with_refunds: 0.4081,
                  },
                  net_arr: {
                    without_refunds: 2.0007,
                    with_refunds: 1.7329,
                  },
                  gross_arr: {
                    without_refunds: 2.2486,
                    with_refunds: 1.9484,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2622,
                    with_refunds: 0.2622,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.295,
                    with_refunds: 0.295,
                  },
                  net_total: {
                    without_refunds: 1.0146,
                    with_refunds: 0.8988,
                  },
                  gross_total: {
                    without_refunds: 1.156,
                    with_refunds: 1.0255,
                  },
                  net_total_spread: {
                    without_refunds: 0.3255,
                    with_refunds: 0.3158,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3825,
                    with_refunds: 0.3708,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5341,
                    with_refunds: 0.5108,
                  },
                  gross_mrr: {
                    without_refunds: 0.6396,
                    with_refunds: 0.6115,
                  },
                  net_arr: {
                    without_refunds: 4.1663,
                    with_refunds: 3.4828,
                  },
                  gross_arr: {
                    without_refunds: 4.6816,
                    with_refunds: 3.9154,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3398,
                    with_refunds: 0.3398,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3823,
                    with_refunds: 0.3823,
                  },
                  net_total: {
                    without_refunds: 1.6993,
                    with_refunds: 1.4977,
                  },
                  gross_total: {
                    without_refunds: 1.9361,
                    with_refunds: 1.7086,
                  },
                  net_total_spread: {
                    without_refunds: 0.4593,
                    with_refunds: 0.4451,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5407,
                    with_refunds: 0.5235,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1117,
                  with_refunds: 1125,
                },
                arr_users: {
                  without_refunds: 14,
                  with_refunds: 28,
                },
                arr_spread_users: {
                  without_refunds: 1323,
                  with_refunds: 1323,
                },
                total_users: {
                  without_refunds: 1131,
                  with_refunds: 1152,
                },
                total_spread_users: {
                  without_refunds: 2440,
                  with_refunds: 2447,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4555,
                  with_refunds: 0.4588,
                },
                arr_users: {
                  without_refunds: 0.0088,
                  with_refunds: 0.0176,
                },
                arr_spread_users: {
                  without_refunds: 0.9692,
                  with_refunds: 0.9692,
                },
                total_users: {
                  without_refunds: 0.2832,
                  with_refunds: 0.2885,
                },
                total_spread_users: {
                  without_refunds: 0.6446,
                  with_refunds: 0.6465,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6009,
                  with_refunds: 0.6035,
                },
                arr_users: {
                  without_refunds: 0.0222,
                  with_refunds: 0.0422,
                },
                arr_spread_users: {
                  without_refunds: 1.0053,
                  with_refunds: 1.0053,
                },
                total_users: {
                  without_refunds: 0.4549,
                  with_refunds: 0.4562,
                },
                total_spread_users: {
                  without_refunds: 0.7685,
                  with_refunds: 0.7695,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13464.37,
                  with_refunds: 13030.32,
                },
                gross_mrr: {
                  without_refunds: 15547.09,
                  with_refunds: 15058.92,
                },
                net_arr: {
                  without_refunds: 1532.47,
                  with_refunds: 195.58,
                },
                gross_arr: {
                  without_refunds: 1943.6,
                  with_refunds: 510.12,
                },
                net_arr_spread: {
                  without_refunds: 10184.78,
                  with_refunds: 10184.78,
                },
                gross_arr_spread: {
                  without_refunds: 11476.57,
                  with_refunds: 11476.57,
                },
                net_total: {
                  without_refunds: 14996.85,
                  with_refunds: 13225.9,
                },
                gross_total: {
                  without_refunds: 17490.69,
                  with_refunds: 15569.03,
                },
                net_total_spread: {
                  without_refunds: 23649.15,
                  with_refunds: 23215.09,
                },
                gross_total_spread: {
                  without_refunds: 27023.66,
                  with_refunds: 26535.49,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8197,
                  with_refunds: 0.8278,
                },
                gross_mrr: {
                  without_refunds: 0.7812,
                  with_refunds: 0.7908,
                },
                net_arr: {
                  without_refunds: 0.0189,
                  with_refunds: 0.0027,
                },
                gross_arr: {
                  without_refunds: 0.0213,
                  with_refunds: 0.0061,
                },
                net_arr_spread: {
                  without_refunds: 1.7422,
                  with_refunds: 1.7422,
                },
                gross_arr_spread: {
                  without_refunds: 1.7453,
                  with_refunds: 1.7453,
                },
                net_total: {
                  without_refunds: 0.1536,
                  with_refunds: 0.1478,
                },
                gross_total: {
                  without_refunds: 0.1572,
                  with_refunds: 0.1526,
                },
                net_total_spread: {
                  without_refunds: 1.0618,
                  with_refunds: 1.0754,
                },
                gross_total_spread: {
                  without_refunds: 1.0207,
                  with_refunds: 1.0358,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5972,
                  with_refunds: 0.6038,
                },
                gross_mrr: {
                  without_refunds: 0.5825,
                  with_refunds: 0.5889,
                },
                net_arr: {
                  without_refunds: 0.0254,
                  with_refunds: 0.004,
                },
                gross_arr: {
                  without_refunds: 0.0287,
                  with_refunds: 0.0093,
                },
                net_arr_spread: {
                  without_refunds: 1.0072,
                  with_refunds: 1.0072,
                },
                gross_arr_spread: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                net_total: {
                  without_refunds: 0.1808,
                  with_refunds: 0.1876,
                },
                gross_total: {
                  without_refunds: 0.1851,
                  with_refunds: 0.1934,
                },
                net_total_spread: {
                  without_refunds: 0.7241,
                  with_refunds: 0.7325,
                },
                gross_total_spread: {
                  without_refunds: 0.7098,
                  with_refunds: 0.7182,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.49,
                  with_refunds: 5.31,
                },
                gross_mrr: {
                  without_refunds: 6.34,
                  with_refunds: 6.14,
                },
                net_arr: {
                  without_refunds: 0.97,
                  with_refunds: 0.12,
                },
                gross_arr: {
                  without_refunds: 1.22,
                  with_refunds: 0.32,
                },
                net_arr_spread: {
                  without_refunds: 7.46,
                  with_refunds: 7.46,
                },
                gross_arr_spread: {
                  without_refunds: 8.41,
                  with_refunds: 8.41,
                },
                net_total: {
                  without_refunds: 3.76,
                  with_refunds: 3.31,
                },
                gross_total: {
                  without_refunds: 4.38,
                  with_refunds: 3.9,
                },
                net_total_spread: {
                  without_refunds: 6.25,
                  with_refunds: 6.13,
                },
                gross_total_spread: {
                  without_refunds: 7.14,
                  with_refunds: 7.01,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.05,
                  with_refunds: 11.58,
                },
                gross_mrr: {
                  without_refunds: 13.92,
                  with_refunds: 13.39,
                },
                net_arr: {
                  without_refunds: 109.46,
                  with_refunds: 6.98,
                },
                gross_arr: {
                  without_refunds: 138.83,
                  with_refunds: 18.22,
                },
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                net_total: {
                  without_refunds: 13.26,
                  with_refunds: 11.48,
                },
                gross_total: {
                  without_refunds: 15.46,
                  with_refunds: 13.51,
                },
                net_total_spread: {
                  without_refunds: 9.69,
                  with_refunds: 9.49,
                },
                gross_total_spread: {
                  without_refunds: 11.08,
                  with_refunds: 10.84,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.39,
                    with_refunds: 20.53,
                  },
                  gross_mrr: {
                    without_refunds: 25.34,
                    with_refunds: 24.34,
                  },
                  net_arr: {
                    without_refunds: 90.16,
                    with_refunds: 77.38,
                  },
                  gross_arr: {
                    without_refunds: 101.47,
                    with_refunds: 87.18,
                  },
                  net_arr_spread: {
                    without_refunds: 19.15,
                    with_refunds: 19.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 21.56,
                    with_refunds: 21.56,
                  },
                  net_total: {
                    without_refunds: 48.99,
                    with_refunds: 43.38,
                  },
                  gross_total: {
                    without_refunds: 55.91,
                    with_refunds: 49.61,
                  },
                  net_total_spread: {
                    without_refunds: 20.76,
                    with_refunds: 20.21,
                  },
                  gross_total_spread: {
                    without_refunds: 24.19,
                    with_refunds: 23.54,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.87,
                    with_refunds: 34.36,
                  },
                  gross_mrr: {
                    without_refunds: 42.43,
                    with_refunds: 40.65,
                  },
                  net_arr: {
                    without_refunds: 295.2,
                    with_refunds: 162.25,
                  },
                  gross_arr: {
                    without_refunds: 347.54,
                    with_refunds: 192.77,
                  },
                  net_arr_spread: {
                    without_refunds: 22.85,
                    with_refunds: 22.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.72,
                    with_refunds: 25.72,
                  },
                  net_total: {
                    without_refunds: 89.01,
                    with_refunds: 78.25,
                  },
                  gross_total: {
                    without_refunds: 101.78,
                    with_refunds: 89.68,
                  },
                  net_total_spread: {
                    without_refunds: 30.17,
                    with_refunds: 29.33,
                  },
                  gross_total_spread: {
                    without_refunds: 35.18,
                    with_refunds: 34.18,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4797,
                    with_refunds: 0.4606,
                  },
                  gross_mrr: {
                    without_refunds: 0.5685,
                    with_refunds: 0.5459,
                  },
                  net_arr: {
                    without_refunds: 2.0223,
                    with_refunds: 1.7357,
                  },
                  gross_arr: {
                    without_refunds: 2.276,
                    with_refunds: 1.9556,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4296,
                    with_refunds: 0.4296,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4836,
                    with_refunds: 0.4836,
                  },
                  net_total: {
                    without_refunds: 1.0988,
                    with_refunds: 0.9731,
                  },
                  gross_total: {
                    without_refunds: 1.2543,
                    with_refunds: 1.1129,
                  },
                  net_total_spread: {
                    without_refunds: 0.4657,
                    with_refunds: 0.4533,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5427,
                    with_refunds: 0.5281,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8045,
                    with_refunds: 0.7706,
                  },
                  gross_mrr: {
                    without_refunds: 0.9518,
                    with_refunds: 0.9118,
                  },
                  net_arr: {
                    without_refunds: 6.6217,
                    with_refunds: 3.6395,
                  },
                  gross_arr: {
                    without_refunds: 7.7958,
                    with_refunds: 4.3241,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5125,
                    with_refunds: 0.5125,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5769,
                    with_refunds: 0.5769,
                  },
                  net_total: {
                    without_refunds: 1.9967,
                    with_refunds: 1.7552,
                  },
                  gross_total: {
                    without_refunds: 2.283,
                    with_refunds: 2.0118,
                  },
                  net_total_spread: {
                    without_refunds: 0.6767,
                    with_refunds: 0.6579,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7891,
                    with_refunds: 0.7668,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 888,
                  with_refunds: 892,
                },
                arr_users: {
                  without_refunds: 12,
                  with_refunds: 17,
                },
                arr_spread_users: {
                  without_refunds: 1330,
                  with_refunds: 1330,
                },
                total_users: {
                  without_refunds: 898,
                  with_refunds: 903,
                },
                total_spread_users: {
                  without_refunds: 2218,
                  with_refunds: 2220,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3622,
                  with_refunds: 0.3638,
                },
                arr_users: {
                  without_refunds: 0.0076,
                  with_refunds: 0.0107,
                },
                arr_spread_users: {
                  without_refunds: 0.9744,
                  with_refunds: 0.9744,
                },
                total_users: {
                  without_refunds: 0.2249,
                  with_refunds: 0.2261,
                },
                total_spread_users: {
                  without_refunds: 0.586,
                  with_refunds: 0.5865,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.795,
                  with_refunds: 0.7929,
                },
                arr_users: {
                  without_refunds: 0.8571,
                  with_refunds: 0.6071,
                },
                arr_spread_users: {
                  without_refunds: 1.0053,
                  with_refunds: 1.0053,
                },
                total_users: {
                  without_refunds: 0.794,
                  with_refunds: 0.7839,
                },
                total_spread_users: {
                  without_refunds: 0.909,
                  with_refunds: 0.9072,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10683.92,
                  with_refunds: 10458.6,
                },
                gross_mrr: {
                  without_refunds: 12300.45,
                  with_refunds: 12039.33,
                },
                net_arr: {
                  without_refunds: 1341.48,
                  with_refunds: 654.54,
                },
                gross_arr: {
                  without_refunds: 1713.74,
                  with_refunds: 911.69,
                },
                net_arr_spread: {
                  without_refunds: 10254.1,
                  with_refunds: 10254.1,
                },
                gross_arr_spread: {
                  without_refunds: 11566.89,
                  with_refunds: 11566.89,
                },
                net_total: {
                  without_refunds: 12025.4,
                  with_refunds: 11113.14,
                },
                gross_total: {
                  without_refunds: 14014.19,
                  with_refunds: 12951.02,
                },
                net_total_spread: {
                  without_refunds: 20938.02,
                  with_refunds: 20712.7,
                },
                gross_total_spread: {
                  without_refunds: 23867.34,
                  with_refunds: 23606.22,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6504,
                  with_refunds: 0.6644,
                },
                gross_mrr: {
                  without_refunds: 0.6181,
                  with_refunds: 0.6323,
                },
                net_arr: {
                  without_refunds: 0.0165,
                  with_refunds: 0.0089,
                },
                gross_arr: {
                  without_refunds: 0.0188,
                  with_refunds: 0.011,
                },
                net_arr_spread: {
                  without_refunds: 1.7541,
                  with_refunds: 1.7541,
                },
                gross_arr_spread: {
                  without_refunds: 1.7591,
                  with_refunds: 1.7591,
                },
                net_total: {
                  without_refunds: 0.1231,
                  with_refunds: 0.1242,
                },
                gross_total: {
                  without_refunds: 0.1259,
                  with_refunds: 0.1269,
                },
                net_total_spread: {
                  without_refunds: 0.9401,
                  with_refunds: 0.9595,
                },
                gross_total_spread: {
                  without_refunds: 0.9014,
                  with_refunds: 0.9215,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7935,
                  with_refunds: 0.8026,
                },
                gross_mrr: {
                  without_refunds: 0.7912,
                  with_refunds: 0.7995,
                },
                net_arr: {
                  without_refunds: 0.8754,
                  with_refunds: 3.3467,
                },
                gross_arr: {
                  without_refunds: 0.8817,
                  with_refunds: 1.7872,
                },
                net_arr_spread: {
                  without_refunds: 1.0068,
                  with_refunds: 1.0068,
                },
                gross_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                net_total: {
                  without_refunds: 0.8019,
                  with_refunds: 0.8403,
                },
                gross_total: {
                  without_refunds: 0.8012,
                  with_refunds: 0.8318,
                },
                net_total_spread: {
                  without_refunds: 0.8854,
                  with_refunds: 0.8922,
                },
                gross_total_spread: {
                  without_refunds: 0.8832,
                  with_refunds: 0.8896,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.36,
                  with_refunds: 4.27,
                },
                gross_mrr: {
                  without_refunds: 5.02,
                  with_refunds: 4.91,
                },
                net_arr: {
                  without_refunds: 0.84,
                  with_refunds: 0.41,
                },
                gross_arr: {
                  without_refunds: 1.08,
                  with_refunds: 0.57,
                },
                net_arr_spread: {
                  without_refunds: 7.51,
                  with_refunds: 7.51,
                },
                gross_arr_spread: {
                  without_refunds: 8.47,
                  with_refunds: 8.47,
                },
                net_total: {
                  without_refunds: 3.01,
                  with_refunds: 2.78,
                },
                gross_total: {
                  without_refunds: 3.51,
                  with_refunds: 3.24,
                },
                net_total_spread: {
                  without_refunds: 5.53,
                  with_refunds: 5.47,
                },
                gross_total_spread: {
                  without_refunds: 6.31,
                  with_refunds: 6.24,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.03,
                  with_refunds: 11.72,
                },
                gross_mrr: {
                  without_refunds: 13.85,
                  with_refunds: 13.5,
                },
                net_arr: {
                  without_refunds: 111.79,
                  with_refunds: 38.5,
                },
                gross_arr: {
                  without_refunds: 142.81,
                  with_refunds: 53.63,
                },
                net_arr_spread: {
                  without_refunds: 7.71,
                  with_refunds: 7.71,
                },
                gross_arr_spread: {
                  without_refunds: 8.7,
                  with_refunds: 8.7,
                },
                net_total: {
                  without_refunds: 13.39,
                  with_refunds: 12.31,
                },
                gross_total: {
                  without_refunds: 15.61,
                  with_refunds: 14.34,
                },
                net_total_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.33,
                },
                gross_total_spread: {
                  without_refunds: 10.76,
                  with_refunds: 10.63,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.74,
                    with_refunds: 24.8,
                  },
                  gross_mrr: {
                    without_refunds: 30.36,
                    with_refunds: 29.25,
                  },
                  net_arr: {
                    without_refunds: 91,
                    with_refunds: 77.79,
                  },
                  gross_arr: {
                    without_refunds: 102.55,
                    with_refunds: 87.75,
                  },
                  net_arr_spread: {
                    without_refunds: 26.66,
                    with_refunds: 26.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.03,
                    with_refunds: 30.03,
                  },
                  net_total: {
                    without_refunds: 52,
                    with_refunds: 46.17,
                  },
                  gross_total: {
                    without_refunds: 59.42,
                    with_refunds: 52.86,
                  },
                  net_total_spread: {
                    without_refunds: 26.29,
                    with_refunds: 25.68,
                  },
                  gross_total_spread: {
                    without_refunds: 30.5,
                    with_refunds: 29.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 47.9,
                    with_refunds: 46.08,
                  },
                  gross_mrr: {
                    without_refunds: 56.28,
                    with_refunds: 54.14,
                  },
                  net_arr: {
                    without_refunds: 406.99,
                    with_refunds: 200.75,
                  },
                  gross_arr: {
                    without_refunds: 490.35,
                    with_refunds: 246.4,
                  },
                  net_arr_spread: {
                    without_refunds: 30.56,
                    with_refunds: 30.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 34.42,
                    with_refunds: 34.42,
                  },
                  net_total: {
                    without_refunds: 102.4,
                    with_refunds: 90.55,
                  },
                  gross_total: {
                    without_refunds: 117.38,
                    with_refunds: 104.03,
                  },
                  net_total_spread: {
                    without_refunds: 39.61,
                    with_refunds: 38.66,
                  },
                  gross_total_spread: {
                    without_refunds: 45.94,
                    with_refunds: 44.82,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5775,
                    with_refunds: 0.5563,
                  },
                  gross_mrr: {
                    without_refunds: 0.681,
                    with_refunds: 0.656,
                  },
                  net_arr: {
                    without_refunds: 2.0413,
                    with_refunds: 1.7449,
                  },
                  gross_arr: {
                    without_refunds: 2.3002,
                    with_refunds: 1.9684,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5981,
                    with_refunds: 0.5981,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6737,
                    with_refunds: 0.6737,
                  },
                  net_total: {
                    without_refunds: 1.1664,
                    with_refunds: 1.0356,
                  },
                  gross_total: {
                    without_refunds: 1.333,
                    with_refunds: 1.1857,
                  },
                  net_total_spread: {
                    without_refunds: 0.5898,
                    with_refunds: 0.5761,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6841,
                    with_refunds: 0.668,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0744,
                    with_refunds: 1.0337,
                  },
                  gross_mrr: {
                    without_refunds: 1.2625,
                    with_refunds: 1.2145,
                  },
                  net_arr: {
                    without_refunds: 9.1293,
                    with_refunds: 4.5032,
                  },
                  gross_arr: {
                    without_refunds: 10.9993,
                    with_refunds: 5.527,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6855,
                    with_refunds: 0.6855,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.772,
                    with_refunds: 0.772,
                  },
                  net_total: {
                    without_refunds: 2.2971,
                    with_refunds: 2.0313,
                  },
                  gross_total: {
                    without_refunds: 2.633,
                    with_refunds: 2.3335,
                  },
                  net_total_spread: {
                    without_refunds: 0.8885,
                    with_refunds: 0.8672,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0305,
                    with_refunds: 1.0053,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 748,
                  with_refunds: 749,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 13,
                },
                arr_spread_users: {
                  without_refunds: 1335,
                  with_refunds: 1335,
                },
                total_users: {
                  without_refunds: 758,
                  with_refunds: 762,
                },
                total_spread_users: {
                  without_refunds: 2083,
                  with_refunds: 2084,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3051,
                  with_refunds: 0.3055,
                },
                arr_users: {
                  without_refunds: 0.0063,
                  with_refunds: 0.0082,
                },
                arr_spread_users: {
                  without_refunds: 0.978,
                  with_refunds: 0.978,
                },
                total_users: {
                  without_refunds: 0.1898,
                  with_refunds: 0.1908,
                },
                total_spread_users: {
                  without_refunds: 0.5503,
                  with_refunds: 0.5506,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8423,
                  with_refunds: 0.8397,
                },
                arr_users: {
                  without_refunds: 0.8333,
                  with_refunds: 0.7647,
                },
                arr_spread_users: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                total_users: {
                  without_refunds: 0.8441,
                  with_refunds: 0.8439,
                },
                total_spread_users: {
                  without_refunds: 0.9391,
                  with_refunds: 0.9387,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8950.08,
                  with_refunds: 8836.89,
                },
                gross_mrr: {
                  without_refunds: 10249.98,
                  with_refunds: 10128.99,
                },
                net_arr: {
                  without_refunds: 1146.17,
                  with_refunds: 301.08,
                },
                gross_arr: {
                  without_refunds: 1469.18,
                  with_refunds: 384.89,
                },
                net_arr_spread: {
                  without_refunds: 10301.78,
                  with_refunds: 10301.78,
                },
                gross_arr_spread: {
                  without_refunds: 11625.89,
                  with_refunds: 11625.89,
                },
                net_total: {
                  without_refunds: 10096.25,
                  with_refunds: 9137.97,
                },
                gross_total: {
                  without_refunds: 11719.16,
                  with_refunds: 10513.88,
                },
                net_total_spread: {
                  without_refunds: 19251.86,
                  with_refunds: 19138.67,
                },
                gross_total_spread: {
                  without_refunds: 21875.87,
                  with_refunds: 21754.88,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5449,
                  with_refunds: 0.5614,
                },
                gross_mrr: {
                  without_refunds: 0.515,
                  with_refunds: 0.5319,
                },
                net_arr: {
                  without_refunds: 0.0141,
                  with_refunds: 0.0041,
                },
                gross_arr: {
                  without_refunds: 0.0161,
                  with_refunds: 0.0046,
                },
                net_arr_spread: {
                  without_refunds: 1.7623,
                  with_refunds: 1.7623,
                },
                gross_arr_spread: {
                  without_refunds: 1.768,
                  with_refunds: 1.768,
                },
                net_total: {
                  without_refunds: 0.1034,
                  with_refunds: 0.1021,
                },
                gross_total: {
                  without_refunds: 0.1053,
                  with_refunds: 0.1031,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8866,
                },
                gross_total_spread: {
                  without_refunds: 0.8262,
                  with_refunds: 0.8492,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8377,
                  with_refunds: 0.8449,
                },
                gross_mrr: {
                  without_refunds: 0.8333,
                  with_refunds: 0.8413,
                },
                net_arr: {
                  without_refunds: 0.8544,
                  with_refunds: 0.46,
                },
                gross_arr: {
                  without_refunds: 0.8573,
                  with_refunds: 0.4222,
                },
                net_arr_spread: {
                  without_refunds: 1.0046,
                  with_refunds: 1.0046,
                },
                gross_arr_spread: {
                  without_refunds: 1.0051,
                  with_refunds: 1.0051,
                },
                net_total: {
                  without_refunds: 0.8396,
                  with_refunds: 0.8223,
                },
                gross_total: {
                  without_refunds: 0.8362,
                  with_refunds: 0.8118,
                },
                net_total_spread: {
                  without_refunds: 0.9195,
                  with_refunds: 0.924,
                },
                gross_total_spread: {
                  without_refunds: 0.9166,
                  with_refunds: 0.9216,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.65,
                  with_refunds: 3.6,
                },
                gross_mrr: {
                  without_refunds: 4.18,
                  with_refunds: 4.13,
                },
                net_arr: {
                  without_refunds: 0.72,
                  with_refunds: 0.19,
                },
                gross_arr: {
                  without_refunds: 0.93,
                  with_refunds: 0.24,
                },
                net_arr_spread: {
                  without_refunds: 7.55,
                  with_refunds: 7.55,
                },
                gross_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                net_total: {
                  without_refunds: 2.53,
                  with_refunds: 2.29,
                },
                gross_total: {
                  without_refunds: 2.93,
                  with_refunds: 2.63,
                },
                net_total_spread: {
                  without_refunds: 5.09,
                  with_refunds: 5.06,
                },
                gross_total_spread: {
                  without_refunds: 5.78,
                  with_refunds: 5.75,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.97,
                  with_refunds: 11.8,
                },
                gross_mrr: {
                  without_refunds: 13.7,
                  with_refunds: 13.52,
                },
                net_arr: {
                  without_refunds: 114.62,
                  with_refunds: 23.16,
                },
                gross_arr: {
                  without_refunds: 146.92,
                  with_refunds: 29.61,
                },
                net_arr_spread: {
                  without_refunds: 7.72,
                  with_refunds: 7.72,
                },
                gross_arr_spread: {
                  without_refunds: 8.71,
                  with_refunds: 8.71,
                },
                net_total: {
                  without_refunds: 13.32,
                  with_refunds: 11.99,
                },
                gross_total: {
                  without_refunds: 15.46,
                  with_refunds: 13.8,
                },
                net_total_spread: {
                  without_refunds: 9.24,
                  with_refunds: 9.18,
                },
                gross_total_spread: {
                  without_refunds: 10.5,
                  with_refunds: 10.44,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 29.39,
                    with_refunds: 28.4,
                  },
                  gross_mrr: {
                    without_refunds: 34.54,
                    with_refunds: 33.38,
                  },
                  net_arr: {
                    without_refunds: 91.72,
                    with_refunds: 77.98,
                  },
                  gross_arr: {
                    without_refunds: 103.47,
                    with_refunds: 88,
                  },
                  net_arr_spread: {
                    without_refunds: 34.21,
                    with_refunds: 34.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 38.55,
                    with_refunds: 38.55,
                  },
                  net_total: {
                    without_refunds: 54.53,
                    with_refunds: 48.45,
                  },
                  gross_total: {
                    without_refunds: 62.36,
                    with_refunds: 55.49,
                  },
                  net_total_spread: {
                    without_refunds: 31.38,
                    with_refunds: 30.74,
                  },
                  gross_total_spread: {
                    without_refunds: 36.28,
                    with_refunds: 35.52,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 59.86,
                    with_refunds: 57.88,
                  },
                  gross_mrr: {
                    without_refunds: 69.99,
                    with_refunds: 67.67,
                  },
                  net_arr: {
                    without_refunds: 521.6,
                    with_refunds: 223.91,
                  },
                  gross_arr: {
                    without_refunds: 637.26,
                    with_refunds: 276,
                  },
                  net_arr_spread: {
                    without_refunds: 38.27,
                    with_refunds: 38.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 43.12,
                    with_refunds: 43.12,
                  },
                  net_total: {
                    without_refunds: 115.72,
                    with_refunds: 102.55,
                  },
                  gross_total: {
                    without_refunds: 132.84,
                    with_refunds: 117.82,
                  },
                  net_total_spread: {
                    without_refunds: 48.85,
                    with_refunds: 47.84,
                  },
                  gross_total_spread: {
                    without_refunds: 56.44,
                    with_refunds: 55.25,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6593,
                    with_refunds: 0.6371,
                  },
                  gross_mrr: {
                    without_refunds: 0.7748,
                    with_refunds: 0.7487,
                  },
                  net_arr: {
                    without_refunds: 2.0575,
                    with_refunds: 1.7492,
                  },
                  gross_arr: {
                    without_refunds: 2.321,
                    with_refunds: 1.9739,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7674,
                    with_refunds: 0.7674,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8648,
                    with_refunds: 0.8648,
                  },
                  net_total: {
                    without_refunds: 1.2231,
                    with_refunds: 1.0869,
                  },
                  gross_total: {
                    without_refunds: 1.3988,
                    with_refunds: 1.2448,
                  },
                  net_total_spread: {
                    without_refunds: 0.7039,
                    with_refunds: 0.6895,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8138,
                    with_refunds: 0.7969,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3428,
                    with_refunds: 1.2983,
                  },
                  gross_mrr: {
                    without_refunds: 1.5699,
                    with_refunds: 1.5179,
                  },
                  net_arr: {
                    without_refunds: 11.7004,
                    with_refunds: 5.0227,
                  },
                  gross_arr: {
                    without_refunds: 14.2949,
                    with_refunds: 6.1912,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8586,
                    with_refunds: 0.8586,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9673,
                    with_refunds: 0.9673,
                  },
                  net_total: {
                    without_refunds: 2.5959,
                    with_refunds: 2.3003,
                  },
                  gross_total: {
                    without_refunds: 2.9799,
                    with_refunds: 2.643,
                  },
                  net_total_spread: {
                    without_refunds: 1.0958,
                    with_refunds: 1.0732,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2661,
                    with_refunds: 1.2394,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 615,
                  with_refunds: 617,
                },
                arr_users: {
                  without_refunds: 14,
                  with_refunds: 14,
                },
                arr_spread_users: {
                  without_refunds: 1343,
                  with_refunds: 1343,
                },
                total_users: {
                  without_refunds: 629,
                  with_refunds: 631,
                },
                total_spread_users: {
                  without_refunds: 1958,
                  with_refunds: 1960,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2508,
                  with_refunds: 0.2516,
                },
                arr_users: {
                  without_refunds: 0.0088,
                  with_refunds: 0.0088,
                },
                arr_spread_users: {
                  without_refunds: 0.9839,
                  with_refunds: 0.9839,
                },
                total_users: {
                  without_refunds: 0.1575,
                  with_refunds: 0.158,
                },
                total_spread_users: {
                  without_refunds: 0.5173,
                  with_refunds: 0.5178,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8222,
                  with_refunds: 0.8238,
                },
                arr_users: {
                  without_refunds: 1.4,
                  with_refunds: 1.0769,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8298,
                  with_refunds: 0.8281,
                },
                total_spread_users: {
                  without_refunds: 0.94,
                  with_refunds: 0.9405,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7278.29,
                  with_refunds: 7120.05,
                },
                gross_mrr: {
                  without_refunds: 8345.58,
                  with_refunds: 8164.4,
                },
                net_arr: {
                  without_refunds: 1606.54,
                  with_refunds: 1003.67,
                },
                gross_arr: {
                  without_refunds: 2112.94,
                  with_refunds: 1294.92,
                },
                net_arr_spread: {
                  without_refunds: 10377.44,
                  with_refunds: 10377.44,
                },
                gross_arr_spread: {
                  without_refunds: 11725.55,
                  with_refunds: 11725.55,
                },
                net_total: {
                  without_refunds: 8884.83,
                  with_refunds: 8123.72,
                },
                gross_total: {
                  without_refunds: 10458.52,
                  with_refunds: 9459.31,
                },
                net_total_spread: {
                  without_refunds: 17655.73,
                  with_refunds: 17497.49,
                },
                gross_total_spread: {
                  without_refunds: 20071.14,
                  with_refunds: 19889.95,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4431,
                  with_refunds: 0.4523,
                },
                gross_mrr: {
                  without_refunds: 0.4193,
                  with_refunds: 0.4288,
                },
                net_arr: {
                  without_refunds: 0.0198,
                  with_refunds: 0.0136,
                },
                gross_arr: {
                  without_refunds: 0.0231,
                  with_refunds: 0.0156,
                },
                net_arr_spread: {
                  without_refunds: 1.7752,
                  with_refunds: 1.7752,
                },
                gross_arr_spread: {
                  without_refunds: 1.7832,
                  with_refunds: 1.7832,
                },
                net_total: {
                  without_refunds: 0.091,
                  with_refunds: 0.0908,
                },
                gross_total: {
                  without_refunds: 0.094,
                  with_refunds: 0.0927,
                },
                net_total_spread: {
                  without_refunds: 0.7927,
                  with_refunds: 0.8106,
                },
                gross_total_spread: {
                  without_refunds: 0.7581,
                  with_refunds: 0.7764,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8132,
                  with_refunds: 0.8057,
                },
                gross_mrr: {
                  without_refunds: 0.8142,
                  with_refunds: 0.806,
                },
                net_arr: {
                  without_refunds: 1.4017,
                  with_refunds: 3.3336,
                },
                gross_arr: {
                  without_refunds: 1.4382,
                  with_refunds: 3.3644,
                },
                net_arr_spread: {
                  without_refunds: 1.0073,
                  with_refunds: 1.0073,
                },
                gross_arr_spread: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                net_total: {
                  without_refunds: 0.88,
                  with_refunds: 0.889,
                },
                gross_total: {
                  without_refunds: 0.8924,
                  with_refunds: 0.8997,
                },
                net_total_spread: {
                  without_refunds: 0.9171,
                  with_refunds: 0.9142,
                },
                gross_total_spread: {
                  without_refunds: 0.9175,
                  with_refunds: 0.9143,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.97,
                  with_refunds: 2.9,
                },
                gross_mrr: {
                  without_refunds: 3.4,
                  with_refunds: 3.33,
                },
                net_arr: {
                  without_refunds: 1.01,
                  with_refunds: 0.63,
                },
                gross_arr: {
                  without_refunds: 1.33,
                  with_refunds: 0.82,
                },
                net_arr_spread: {
                  without_refunds: 7.6,
                  with_refunds: 7.6,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 2.23,
                  with_refunds: 2.03,
                },
                gross_total: {
                  without_refunds: 2.62,
                  with_refunds: 2.37,
                },
                net_total_spread: {
                  without_refunds: 4.66,
                  with_refunds: 4.62,
                },
                gross_total_spread: {
                  without_refunds: 5.3,
                  with_refunds: 5.25,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.83,
                  with_refunds: 11.54,
                },
                gross_mrr: {
                  without_refunds: 13.57,
                  with_refunds: 13.23,
                },
                net_arr: {
                  without_refunds: 114.75,
                  with_refunds: 71.69,
                },
                gross_arr: {
                  without_refunds: 150.92,
                  with_refunds: 92.49,
                },
                net_arr_spread: {
                  without_refunds: 7.73,
                  with_refunds: 7.73,
                },
                gross_arr_spread: {
                  without_refunds: 8.73,
                  with_refunds: 8.73,
                },
                net_total: {
                  without_refunds: 14.13,
                  with_refunds: 12.87,
                },
                gross_total: {
                  without_refunds: 16.63,
                  with_refunds: 14.99,
                },
                net_total_spread: {
                  without_refunds: 9.02,
                  with_refunds: 8.93,
                },
                gross_total_spread: {
                  without_refunds: 10.25,
                  with_refunds: 10.15,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.36,
                    with_refunds: 31.31,
                  },
                  gross_mrr: {
                    without_refunds: 37.94,
                    with_refunds: 36.71,
                  },
                  net_arr: {
                    without_refunds: 92.73,
                    with_refunds: 78.61,
                  },
                  gross_arr: {
                    without_refunds: 104.8,
                    with_refunds: 88.81,
                  },
                  net_arr_spread: {
                    without_refunds: 41.81,
                    with_refunds: 41.81,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.14,
                    with_refunds: 47.14,
                  },
                  net_total: {
                    without_refunds: 56.75,
                    with_refunds: 50.49,
                  },
                  gross_total: {
                    without_refunds: 64.98,
                    with_refunds: 57.86,
                  },
                  net_total_spread: {
                    without_refunds: 36.04,
                    with_refunds: 35.36,
                  },
                  gross_total_spread: {
                    without_refunds: 41.58,
                    with_refunds: 40.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 71.7,
                    with_refunds: 69.42,
                  },
                  gross_mrr: {
                    without_refunds: 83.56,
                    with_refunds: 80.9,
                  },
                  net_arr: {
                    without_refunds: 636.36,
                    with_refunds: 295.6,
                  },
                  gross_arr: {
                    without_refunds: 788.19,
                    with_refunds: 368.5,
                  },
                  net_arr_spread: {
                    without_refunds: 46,
                    with_refunds: 46,
                  },
                  gross_arr_spread: {
                    without_refunds: 51.86,
                    with_refunds: 51.86,
                  },
                  net_total: {
                    without_refunds: 129.85,
                    with_refunds: 115.42,
                  },
                  gross_total: {
                    without_refunds: 149.47,
                    with_refunds: 132.82,
                  },
                  net_total_spread: {
                    without_refunds: 57.87,
                    with_refunds: 56.77,
                  },
                  gross_total_spread: {
                    without_refunds: 66.69,
                    with_refunds: 65.4,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7259,
                    with_refunds: 0.7023,
                  },
                  gross_mrr: {
                    without_refunds: 0.8511,
                    with_refunds: 0.8234,
                  },
                  net_arr: {
                    without_refunds: 2.0802,
                    with_refunds: 1.7634,
                  },
                  gross_arr: {
                    without_refunds: 2.3508,
                    with_refunds: 1.9922,
                  },
                  net_arr_spread: {
                    without_refunds: 0.938,
                    with_refunds: 0.938,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0575,
                    with_refunds: 1.0575,
                  },
                  net_total: {
                    without_refunds: 1.273,
                    with_refunds: 1.1325,
                  },
                  gross_total: {
                    without_refunds: 1.4576,
                    with_refunds: 1.2979,
                  },
                  net_total_spread: {
                    without_refunds: 0.8085,
                    with_refunds: 0.7932,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9327,
                    with_refunds: 0.9148,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.6083,
                    with_refunds: 1.5572,
                  },
                  gross_mrr: {
                    without_refunds: 1.8743,
                    with_refunds: 1.8147,
                  },
                  net_arr: {
                    without_refunds: 14.2745,
                    with_refunds: 6.6308,
                  },
                  gross_arr: {
                    without_refunds: 17.6803,
                    with_refunds: 8.266,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0319,
                    with_refunds: 1.0319,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1632,
                    with_refunds: 1.1632,
                  },
                  net_total: {
                    without_refunds: 2.9127,
                    with_refunds: 2.5891,
                  },
                  gross_total: {
                    without_refunds: 3.3528,
                    with_refunds: 2.9793,
                  },
                  net_total_spread: {
                    without_refunds: 1.298,
                    with_refunds: 1.2734,
                  },
                  gross_total_spread: {
                    without_refunds: 1.496,
                    with_refunds: 1.4671,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 534,
                  with_refunds: 535,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 10,
                },
                arr_spread_users: {
                  without_refunds: 1351,
                  with_refunds: 1351,
                },
                total_users: {
                  without_refunds: 542,
                  with_refunds: 545,
                },
                total_spread_users: {
                  without_refunds: 1885,
                  with_refunds: 1886,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2178,
                  with_refunds: 0.2182,
                },
                arr_users: {
                  without_refunds: 0.005,
                  with_refunds: 0.0063,
                },
                arr_spread_users: {
                  without_refunds: 0.9897,
                  with_refunds: 0.9897,
                },
                total_users: {
                  without_refunds: 0.1357,
                  with_refunds: 0.1365,
                },
                total_spread_users: {
                  without_refunds: 0.498,
                  with_refunds: 0.4983,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8683,
                  with_refunds: 0.8671,
                },
                arr_users: {
                  without_refunds: 0.5714,
                  with_refunds: 0.7143,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8617,
                  with_refunds: 0.8637,
                },
                total_spread_users: {
                  without_refunds: 0.9627,
                  with_refunds: 0.9622,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6398.41,
                  with_refunds: 6333.77,
                },
                gross_mrr: {
                  without_refunds: 7320.76,
                  with_refunds: 7237.92,
                },
                net_arr: {
                  without_refunds: 991.91,
                  with_refunds: 780.64,
                },
                gross_arr: {
                  without_refunds: 1221.06,
                  with_refunds: 987.73,
                },
                net_arr_spread: {
                  without_refunds: 10451.24,
                  with_refunds: 10451.24,
                },
                gross_arr_spread: {
                  without_refunds: 11816.89,
                  with_refunds: 11816.89,
                },
                net_total: {
                  without_refunds: 7390.31,
                  with_refunds: 7114.41,
                },
                gross_total: {
                  without_refunds: 8541.82,
                  with_refunds: 8225.65,
                },
                net_total_spread: {
                  without_refunds: 16849.65,
                  with_refunds: 16785.01,
                },
                gross_total_spread: {
                  without_refunds: 19137.65,
                  with_refunds: 19054.81,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3895,
                  with_refunds: 0.4024,
                },
                gross_mrr: {
                  without_refunds: 0.3679,
                  with_refunds: 0.3801,
                },
                net_arr: {
                  without_refunds: 0.0122,
                  with_refunds: 0.0106,
                },
                gross_arr: {
                  without_refunds: 0.0134,
                  with_refunds: 0.0119,
                },
                net_arr_spread: {
                  without_refunds: 1.7878,
                  with_refunds: 1.7878,
                },
                gross_arr_spread: {
                  without_refunds: 1.7971,
                  with_refunds: 1.7971,
                },
                net_total: {
                  without_refunds: 0.0757,
                  with_refunds: 0.0795,
                },
                gross_total: {
                  without_refunds: 0.0768,
                  with_refunds: 0.0806,
                },
                net_total_spread: {
                  without_refunds: 0.7565,
                  with_refunds: 0.7776,
                },
                gross_total_spread: {
                  without_refunds: 0.7228,
                  with_refunds: 0.7438,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8791,
                  with_refunds: 0.8896,
                },
                gross_mrr: {
                  without_refunds: 0.8772,
                  with_refunds: 0.8865,
                },
                net_arr: {
                  without_refunds: 0.6174,
                  with_refunds: 0.7778,
                },
                gross_arr: {
                  without_refunds: 0.5779,
                  with_refunds: 0.7628,
                },
                net_arr_spread: {
                  without_refunds: 1.0071,
                  with_refunds: 1.0071,
                },
                gross_arr_spread: {
                  without_refunds: 1.0078,
                  with_refunds: 1.0078,
                },
                net_total: {
                  without_refunds: 0.8318,
                  with_refunds: 0.8758,
                },
                gross_total: {
                  without_refunds: 0.8167,
                  with_refunds: 0.8696,
                },
                net_total_spread: {
                  without_refunds: 0.9543,
                  with_refunds: 0.9593,
                },
                gross_total_spread: {
                  without_refunds: 0.9535,
                  with_refunds: 0.958,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.61,
                  with_refunds: 2.58,
                },
                gross_mrr: {
                  without_refunds: 2.99,
                  with_refunds: 2.95,
                },
                net_arr: {
                  without_refunds: 0.62,
                  with_refunds: 0.49,
                },
                gross_arr: {
                  without_refunds: 0.77,
                  with_refunds: 0.62,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                net_total: {
                  without_refunds: 1.85,
                  with_refunds: 1.78,
                },
                gross_total: {
                  without_refunds: 2.14,
                  with_refunds: 2.06,
                },
                net_total_spread: {
                  without_refunds: 4.45,
                  with_refunds: 4.43,
                },
                gross_total_spread: {
                  without_refunds: 5.06,
                  with_refunds: 5.03,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.98,
                  with_refunds: 11.84,
                },
                gross_mrr: {
                  without_refunds: 13.71,
                  with_refunds: 13.53,
                },
                net_arr: {
                  without_refunds: 123.99,
                  with_refunds: 78.06,
                },
                gross_arr: {
                  without_refunds: 152.63,
                  with_refunds: 98.77,
                },
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.75,
                  with_refunds: 8.75,
                },
                net_total: {
                  without_refunds: 13.64,
                  with_refunds: 13.05,
                },
                gross_total: {
                  without_refunds: 15.76,
                  with_refunds: 15.09,
                },
                net_total_spread: {
                  without_refunds: 8.94,
                  with_refunds: 8.9,
                },
                gross_total_spread: {
                  without_refunds: 10.15,
                  with_refunds: 10.1,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.97,
                    with_refunds: 33.89,
                  },
                  gross_mrr: {
                    without_refunds: 40.93,
                    with_refunds: 39.66,
                  },
                  net_arr: {
                    without_refunds: 93.36,
                    with_refunds: 79.1,
                  },
                  gross_arr: {
                    without_refunds: 105.57,
                    with_refunds: 89.43,
                  },
                  net_arr_spread: {
                    without_refunds: 49.47,
                    with_refunds: 49.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 55.8,
                    with_refunds: 55.8,
                  },
                  net_total: {
                    without_refunds: 58.6,
                    with_refunds: 52.27,
                  },
                  gross_total: {
                    without_refunds: 67.12,
                    with_refunds: 59.92,
                  },
                  net_total_spread: {
                    without_refunds: 40.5,
                    with_refunds: 39.8,
                  },
                  gross_total_spread: {
                    without_refunds: 46.64,
                    with_refunds: 45.81,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 83.68,
                    with_refunds: 81.26,
                  },
                  gross_mrr: {
                    without_refunds: 97.27,
                    with_refunds: 94.43,
                  },
                  net_arr: {
                    without_refunds: 760.34,
                    with_refunds: 373.66,
                  },
                  gross_arr: {
                    without_refunds: 940.82,
                    with_refunds: 467.27,
                  },
                  net_arr_spread: {
                    without_refunds: 53.74,
                    with_refunds: 53.74,
                  },
                  gross_arr_spread: {
                    without_refunds: 60.6,
                    with_refunds: 60.6,
                  },
                  net_total: {
                    without_refunds: 143.48,
                    with_refunds: 128.47,
                  },
                  gross_total: {
                    without_refunds: 165.23,
                    with_refunds: 147.91,
                  },
                  net_total_spread: {
                    without_refunds: 66.81,
                    with_refunds: 65.67,
                  },
                  gross_total_spread: {
                    without_refunds: 76.84,
                    with_refunds: 75.51,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7844,
                    with_refunds: 0.7602,
                  },
                  gross_mrr: {
                    without_refunds: 0.9181,
                    with_refunds: 0.8896,
                  },
                  net_arr: {
                    without_refunds: 2.0942,
                    with_refunds: 1.7744,
                  },
                  gross_arr: {
                    without_refunds: 2.3681,
                    with_refunds: 2.0061,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1097,
                    with_refunds: 1.1097,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2517,
                    with_refunds: 1.2517,
                  },
                  net_total: {
                    without_refunds: 1.3146,
                    with_refunds: 1.1725,
                  },
                  gross_total: {
                    without_refunds: 1.5056,
                    with_refunds: 1.3441,
                  },
                  net_total_spread: {
                    without_refunds: 0.9084,
                    with_refunds: 0.8927,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0462,
                    with_refunds: 1.0277,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.877,
                    with_refunds: 1.8227,
                  },
                  gross_mrr: {
                    without_refunds: 2.1818,
                    with_refunds: 2.1182,
                  },
                  net_arr: {
                    without_refunds: 17.0557,
                    with_refunds: 8.3819,
                  },
                  gross_arr: {
                    without_refunds: 21.1041,
                    with_refunds: 10.4816,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2054,
                    with_refunds: 1.2054,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3594,
                    with_refunds: 1.3594,
                  },
                  net_total: {
                    without_refunds: 3.2186,
                    with_refunds: 2.8819,
                  },
                  gross_total: {
                    without_refunds: 3.7063,
                    with_refunds: 3.3178,
                  },
                  net_total_spread: {
                    without_refunds: 1.4986,
                    with_refunds: 1.4731,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7237,
                    with_refunds: 1.6937,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 454,
                  with_refunds: 455,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1352,
                  with_refunds: 1352,
                },
                total_users: {
                  without_refunds: 455,
                  with_refunds: 456,
                },
                total_spread_users: {
                  without_refunds: 1806,
                  with_refunds: 1807,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1852,
                  with_refunds: 0.1856,
                },
                arr_users: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0006,
                },
                arr_spread_users: {
                  without_refunds: 0.9905,
                  with_refunds: 0.9905,
                },
                total_users: {
                  without_refunds: 0.1139,
                  with_refunds: 0.1142,
                },
                total_spread_users: {
                  without_refunds: 0.4771,
                  with_refunds: 0.4774,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8502,
                  with_refunds: 0.8505,
                },
                arr_users: {
                  without_refunds: 0.125,
                  with_refunds: 0.1,
                },
                arr_spread_users: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                total_users: {
                  without_refunds: 0.8395,
                  with_refunds: 0.8367,
                },
                total_spread_users: {
                  without_refunds: 0.9581,
                  with_refunds: 0.9581,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5448.09,
                  with_refunds: 5400.04,
                },
                gross_mrr: {
                  without_refunds: 6214.31,
                  with_refunds: 6157.87,
                },
                net_arr: {
                  without_refunds: 109.06,
                  with_refunds: 109.06,
                },
                gross_arr: {
                  without_refunds: 155.06,
                  with_refunds: 155.06,
                },
                net_arr_spread: {
                  without_refunds: 10460.33,
                  with_refunds: 10460.33,
                },
                gross_arr_spread: {
                  without_refunds: 11829.81,
                  with_refunds: 11829.81,
                },
                net_total: {
                  without_refunds: 5557.16,
                  with_refunds: 5509.1,
                },
                gross_total: {
                  without_refunds: 6369.38,
                  with_refunds: 6312.93,
                },
                net_total_spread: {
                  without_refunds: 15908.43,
                  with_refunds: 15860.37,
                },
                gross_total_spread: {
                  without_refunds: 18044.13,
                  with_refunds: 17987.68,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3317,
                  with_refunds: 0.3431,
                },
                gross_mrr: {
                  without_refunds: 0.3123,
                  with_refunds: 0.3234,
                },
                net_arr: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0015,
                },
                gross_arr: {
                  without_refunds: 0.0017,
                  with_refunds: 0.0019,
                },
                net_arr_spread: {
                  without_refunds: 1.7894,
                  with_refunds: 1.7894,
                },
                gross_arr_spread: {
                  without_refunds: 1.799,
                  with_refunds: 1.799,
                },
                net_total: {
                  without_refunds: 0.0569,
                  with_refunds: 0.0616,
                },
                gross_total: {
                  without_refunds: 0.0572,
                  with_refunds: 0.0619,
                },
                net_total_spread: {
                  without_refunds: 0.7143,
                  with_refunds: 0.7347,
                },
                gross_total_spread: {
                  without_refunds: 0.6815,
                  with_refunds: 0.7022,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8515,
                  with_refunds: 0.8526,
                },
                gross_mrr: {
                  without_refunds: 0.8489,
                  with_refunds: 0.8508,
                },
                net_arr: {
                  without_refunds: 0.11,
                  with_refunds: 0.1397,
                },
                gross_arr: {
                  without_refunds: 0.127,
                  with_refunds: 0.157,
                },
                net_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                gross_arr_spread: {
                  without_refunds: 1.0011,
                  with_refunds: 1.0011,
                },
                net_total: {
                  without_refunds: 0.752,
                  with_refunds: 0.7744,
                },
                gross_total: {
                  without_refunds: 0.7457,
                  with_refunds: 0.7675,
                },
                net_total_spread: {
                  without_refunds: 0.9441,
                  with_refunds: 0.9449,
                },
                gross_total_spread: {
                  without_refunds: 0.9429,
                  with_refunds: 0.944,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.22,
                  with_refunds: 2.2,
                },
                gross_mrr: {
                  without_refunds: 2.53,
                  with_refunds: 2.51,
                },
                net_arr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                gross_arr: {
                  without_refunds: 0.1,
                  with_refunds: 0.1,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                net_total: {
                  without_refunds: 1.39,
                  with_refunds: 1.38,
                },
                gross_total: {
                  without_refunds: 1.6,
                  with_refunds: 1.58,
                },
                net_total_spread: {
                  without_refunds: 4.2,
                  with_refunds: 4.19,
                },
                gross_total_spread: {
                  without_refunds: 4.77,
                  with_refunds: 4.75,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12,
                  with_refunds: 11.87,
                },
                gross_mrr: {
                  without_refunds: 13.69,
                  with_refunds: 13.53,
                },
                net_arr: {
                  without_refunds: 109.06,
                  with_refunds: 109.06,
                },
                gross_arr: {
                  without_refunds: 155.06,
                  with_refunds: 155.06,
                },
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.75,
                  with_refunds: 8.75,
                },
                net_total: {
                  without_refunds: 12.21,
                  with_refunds: 12.08,
                },
                gross_total: {
                  without_refunds: 14,
                  with_refunds: 13.84,
                },
                net_total_spread: {
                  without_refunds: 8.81,
                  with_refunds: 8.78,
                },
                gross_total_spread: {
                  without_refunds: 9.99,
                  with_refunds: 9.95,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.19,
                    with_refunds: 36.09,
                  },
                  gross_mrr: {
                    without_refunds: 43.46,
                    with_refunds: 42.17,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 57.13,
                    with_refunds: 57.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.47,
                    with_refunds: 64.47,
                  },
                  net_total: {
                    without_refunds: 59.99,
                    with_refunds: 53.65,
                  },
                  gross_total: {
                    without_refunds: 68.71,
                    with_refunds: 61.5,
                  },
                  net_total_spread: {
                    without_refunds: 44.7,
                    with_refunds: 43.99,
                  },
                  gross_total_spread: {
                    without_refunds: 51.4,
                    with_refunds: 50.57,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 95.68,
                    with_refunds: 93.13,
                  },
                  gross_mrr: {
                    without_refunds: 110.95,
                    with_refunds: 107.96,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 61.47,
                    with_refunds: 61.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 69.35,
                    with_refunds: 69.35,
                  },
                  net_total: {
                    without_refunds: 155.7,
                    with_refunds: 140.56,
                  },
                  gross_total: {
                    without_refunds: 179.23,
                    with_refunds: 161.75,
                  },
                  net_total_spread: {
                    without_refunds: 75.61,
                    with_refunds: 74.45,
                  },
                  gross_total_spread: {
                    without_refunds: 86.84,
                    with_refunds: 85.46,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8343,
                    with_refunds: 0.8096,
                  },
                  gross_mrr: {
                    without_refunds: 0.9749,
                    with_refunds: 0.9459,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2816,
                    with_refunds: 1.2816,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4461,
                    with_refunds: 1.4461,
                  },
                  net_total: {
                    without_refunds: 1.3458,
                    with_refunds: 1.2034,
                  },
                  gross_total: {
                    without_refunds: 1.5413,
                    with_refunds: 1.3796,
                  },
                  net_total_spread: {
                    without_refunds: 1.0027,
                    with_refunds: 0.9867,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1531,
                    with_refunds: 1.1343,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.1462,
                    with_refunds: 2.0889,
                  },
                  gross_mrr: {
                    without_refunds: 2.4889,
                    with_refunds: 2.4218,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 1.379,
                    with_refunds: 1.379,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5557,
                    with_refunds: 1.5557,
                  },
                  net_total: {
                    without_refunds: 3.4925,
                    with_refunds: 3.1529,
                  },
                  gross_total: {
                    without_refunds: 4.0204,
                    with_refunds: 3.6284,
                  },
                  net_total_spread: {
                    without_refunds: 1.6961,
                    with_refunds: 1.6699,
                  },
                  gross_total_spread: {
                    without_refunds: 1.9479,
                    with_refunds: 1.917,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 105,
                  with_refunds: 105,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1352,
                  with_refunds: 1352,
                },
                total_users: {
                  without_refunds: 105,
                  with_refunds: 105,
                },
                total_spread_users: {
                  without_refunds: 1457,
                  with_refunds: 1457,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0428,
                  with_refunds: 0.0428,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9905,
                  with_refunds: 0.9905,
                },
                total_users: {
                  without_refunds: 0.0263,
                  with_refunds: 0.0263,
                },
                total_spread_users: {
                  without_refunds: 0.3849,
                  with_refunds: 0.3849,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2313,
                  with_refunds: 0.2308,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.2308,
                  with_refunds: 0.2303,
                },
                total_spread_users: {
                  without_refunds: 0.8068,
                  with_refunds: 0.8063,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1269.15,
                  with_refunds: 1269.15,
                },
                gross_mrr: {
                  without_refunds: 1451.92,
                  with_refunds: 1451.92,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10460.33,
                  with_refunds: 10460.33,
                },
                gross_arr_spread: {
                  without_refunds: 11829.81,
                  with_refunds: 11829.81,
                },
                net_total: {
                  without_refunds: 1269.15,
                  with_refunds: 1269.15,
                },
                gross_total: {
                  without_refunds: 1451.92,
                  with_refunds: 1451.92,
                },
                net_total_spread: {
                  without_refunds: 11729.48,
                  with_refunds: 11729.48,
                },
                gross_total_spread: {
                  without_refunds: 13281.73,
                  with_refunds: 13281.73,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0773,
                  with_refunds: 0.0806,
                },
                gross_mrr: {
                  without_refunds: 0.073,
                  with_refunds: 0.0762,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.7894,
                  with_refunds: 1.7894,
                },
                gross_arr_spread: {
                  without_refunds: 1.799,
                  with_refunds: 1.799,
                },
                net_total: {
                  without_refunds: 0.013,
                  with_refunds: 0.0142,
                },
                gross_total: {
                  without_refunds: 0.013,
                  with_refunds: 0.0142,
                },
                net_total_spread: {
                  without_refunds: 0.5267,
                  with_refunds: 0.5434,
                },
                gross_total_spread: {
                  without_refunds: 0.5016,
                  with_refunds: 0.5185,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.233,
                  with_refunds: 0.235,
                },
                gross_mrr: {
                  without_refunds: 0.2336,
                  with_refunds: 0.2358,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.2284,
                  with_refunds: 0.2304,
                },
                gross_total: {
                  without_refunds: 0.228,
                  with_refunds: 0.23,
                },
                net_total_spread: {
                  without_refunds: 0.7373,
                  with_refunds: 0.7395,
                },
                gross_total_spread: {
                  without_refunds: 0.7361,
                  with_refunds: 0.7384,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.52,
                  with_refunds: 0.52,
                },
                gross_mrr: {
                  without_refunds: 0.59,
                  with_refunds: 0.59,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                net_total: {
                  without_refunds: 0.32,
                  with_refunds: 0.32,
                },
                gross_total: {
                  without_refunds: 0.36,
                  with_refunds: 0.36,
                },
                net_total_spread: {
                  without_refunds: 3.1,
                  with_refunds: 3.1,
                },
                gross_total_spread: {
                  without_refunds: 3.51,
                  with_refunds: 3.51,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.09,
                  with_refunds: 12.09,
                },
                gross_mrr: {
                  without_refunds: 13.83,
                  with_refunds: 13.83,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.75,
                  with_refunds: 8.75,
                },
                net_total: {
                  without_refunds: 12.09,
                  with_refunds: 12.09,
                },
                gross_total: {
                  without_refunds: 13.83,
                  with_refunds: 13.83,
                },
                net_total_spread: {
                  without_refunds: 8.05,
                  with_refunds: 8.05,
                },
                gross_total_spread: {
                  without_refunds: 9.12,
                  with_refunds: 9.12,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.71,
                    with_refunds: 36.61,
                  },
                  gross_mrr: {
                    without_refunds: 44.05,
                    with_refunds: 42.76,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 64.8,
                    with_refunds: 64.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 73.13,
                    with_refunds: 73.13,
                  },
                  net_total: {
                    without_refunds: 60.31,
                    with_refunds: 53.97,
                  },
                  gross_total: {
                    without_refunds: 69.08,
                    with_refunds: 61.86,
                  },
                  net_total_spread: {
                    without_refunds: 47.8,
                    with_refunds: 47.09,
                  },
                  gross_total_spread: {
                    without_refunds: 54.91,
                    with_refunds: 54.08,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 107.77,
                    with_refunds: 105.21,
                  },
                  gross_mrr: {
                    without_refunds: 124.78,
                    with_refunds: 121.79,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 69.21,
                    with_refunds: 69.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.1,
                    with_refunds: 78.1,
                  },
                  net_total: {
                    without_refunds: 167.78,
                    with_refunds: 152.64,
                  },
                  gross_total: {
                    without_refunds: 193.06,
                    with_refunds: 175.58,
                  },
                  net_total_spread: {
                    without_refunds: 83.66,
                    with_refunds: 82.5,
                  },
                  gross_total_spread: {
                    without_refunds: 95.95,
                    with_refunds: 94.58,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8459,
                    with_refunds: 0.8212,
                  },
                  gross_mrr: {
                    without_refunds: 0.9882,
                    with_refunds: 0.9592,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4535,
                    with_refunds: 1.4535,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6405,
                    with_refunds: 1.6405,
                  },
                  net_total: {
                    without_refunds: 1.3529,
                    with_refunds: 1.2106,
                  },
                  gross_total: {
                    without_refunds: 1.5495,
                    with_refunds: 1.3877,
                  },
                  net_total_spread: {
                    without_refunds: 1.0722,
                    with_refunds: 1.0562,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2318,
                    with_refunds: 1.213,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.4174,
                    with_refunds: 2.3601,
                  },
                  gross_mrr: {
                    without_refunds: 2.799,
                    with_refunds: 2.7319,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5525,
                    with_refunds: 1.5525,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.7519,
                    with_refunds: 1.7519,
                  },
                  net_total: {
                    without_refunds: 3.7637,
                    with_refunds: 3.424,
                  },
                  gross_total: {
                    without_refunds: 4.3305,
                    with_refunds: 3.9385,
                  },
                  net_total_spread: {
                    without_refunds: 1.8767,
                    with_refunds: 1.8505,
                  },
                  gross_total_spread: {
                    without_refunds: 2.1523,
                    with_refunds: 2.1215,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 1406,
              with_refunds: 1406,
            },
            returning_users: {
              without_refunds: 6735,
              with_refunds: 6761,
            },
            paying_users: {
              without_refunds: 8141,
              with_refunds: 8167,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 904,
              with_refunds: 904,
            },
            returning_users: {
              without_refunds: 688,
              with_refunds: 725,
            },
            paying_users: {
              without_refunds: 1592,
              with_refunds: 1629,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2304,
              with_refunds: 2304,
            },
            returning_users: {
              without_refunds: 7420,
              with_refunds: 7483,
            },
            paying_users: {
              without_refunds: 9724,
              with_refunds: 9787,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 92465.32,
                with_refunds: 89769.74,
              },
              gross_mrr: {
                without_refunds: 108022.83,
                with_refunds: 104850.43,
              },
              net_arr: {
                without_refunds: 148361.4,
                with_refunds: 125722.82,
              },
              gross_arr: {
                without_refunds: 167799.77,
                with_refunds: 142175.01,
              },
              net_arr_spread: {
                without_refunds: 88448.19,
                with_refunds: 88448.19,
              },
              gross_arr_spread: {
                without_refunds: 99825.64,
                with_refunds: 99825.64,
              },
              net_total: {
                without_refunds: 240826.72,
                with_refunds: 215492.56,
              },
              gross_total: {
                without_refunds: 275822.6,
                with_refunds: 247025.45,
              },
              net_total_spread: {
                without_refunds: 180913.51,
                with_refunds: 178217.93,
              },
              gross_total_spread: {
                without_refunds: 207848.47,
                with_refunds: 204676.07,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 92465.32,
                with_refunds: 89769.74,
              },
              gross_mrr: {
                without_refunds: 108022.83,
                with_refunds: 104850.43,
              },
              net_arr: {
                without_refunds: 148361.4,
                with_refunds: 125722.82,
              },
              gross_arr: {
                without_refunds: 167799.77,
                with_refunds: 142175.01,
              },
              net_arr_spread: {
                without_refunds: 88448.19,
                with_refunds: 88448.19,
              },
              gross_arr_spread: {
                without_refunds: 99825.64,
                with_refunds: 99825.64,
              },
              net_total: {
                without_refunds: 240826.72,
                with_refunds: 215492.56,
              },
              gross_total: {
                without_refunds: 275822.6,
                with_refunds: 247025.45,
              },
              net_total_spread: {
                without_refunds: 180913.51,
                with_refunds: 178217.93,
              },
              gross_total_spread: {
                without_refunds: 207848.47,
                with_refunds: 204676.07,
              },
            },
          },
        },
      },
      cac: 44.58,
      mrr_ua_spend: {
        without_refunds: 109310.16,
        with_refunds: 109310.16,
      },
      arr_ua_spend: {
        without_refunds: 70793.04,
        with_refunds: 70793.04,
      },
      arr_spread_ua_spend: {
        without_refunds: 60851.7,
        with_refunds: 60851.7,
      },
      total_ua_spend: {
        without_refunds: 178007.94,
        with_refunds: 178007.94,
      },
      total_spread_ua_spend: {
        without_refunds: 168735.3,
        with_refunds: 168735.3,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 12,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 1273,
        with_refunds: 1273,
      },
      first_period_arr_users: {
        without_refunds: 1063,
        with_refunds: 1063,
      },
      first_period_arr_spread_users: {
        without_refunds: 794,
        with_refunds: 794,
      },
      first_period_total_users: {
        without_refunds: 2316,
        with_refunds: 2316,
      },
      first_period_total_spread_users: {
        without_refunds: 2057,
        with_refunds: 2057,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 925,
                  with_refunds: 925,
                },
                arr_users: {
                  without_refunds: 687,
                  with_refunds: 687,
                },
                arr_spread_users: {
                  without_refunds: 571,
                  with_refunds: 571,
                },
                total_users: {
                  without_refunds: 1610,
                  with_refunds: 1610,
                },
                total_spread_users: {
                  without_refunds: 1496,
                  with_refunds: 1496,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7266,
                  with_refunds: 0.7266,
                },
                arr_users: {
                  without_refunds: 0.6463,
                  with_refunds: 0.6463,
                },
                arr_spread_users: {
                  without_refunds: 0.7191,
                  with_refunds: 0.7191,
                },
                total_users: {
                  without_refunds: 0.6952,
                  with_refunds: 0.6952,
                },
                total_spread_users: {
                  without_refunds: 0.7273,
                  with_refunds: 0.7273,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 12218.1,
                  with_refunds: 11726.96,
                },
                gross_mrr: {
                  without_refunds: 14279.15,
                  with_refunds: 13723.35,
                },
                net_arr: {
                  without_refunds: 71272.18,
                  with_refunds: 62053.54,
                },
                gross_arr: {
                  without_refunds: 80446.46,
                  with_refunds: 70069.21,
                },
                net_arr_spread: {
                  without_refunds: 4940.04,
                  with_refunds: 4940.04,
                },
                gross_arr_spread: {
                  without_refunds: 5583.81,
                  with_refunds: 5583.81,
                },
                net_total: {
                  without_refunds: 83490.28,
                  with_refunds: 73780.5,
                },
                gross_total: {
                  without_refunds: 94725.61,
                  with_refunds: 83792.56,
                },
                net_total_spread: {
                  without_refunds: 17158.14,
                  with_refunds: 16667,
                },
                gross_total_spread: {
                  without_refunds: 19862.96,
                  with_refunds: 19307.16,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 9.6,
                  with_refunds: 9.21,
                },
                gross_mrr: {
                  without_refunds: 11.22,
                  with_refunds: 10.78,
                },
                net_arr: {
                  without_refunds: 67.05,
                  with_refunds: 58.38,
                },
                gross_arr: {
                  without_refunds: 75.68,
                  with_refunds: 65.92,
                },
                net_arr_spread: {
                  without_refunds: 6.22,
                  with_refunds: 6.22,
                },
                gross_arr_spread: {
                  without_refunds: 7.03,
                  with_refunds: 7.03,
                },
                net_total: {
                  without_refunds: 36.05,
                  with_refunds: 31.86,
                },
                gross_total: {
                  without_refunds: 40.9,
                  with_refunds: 36.18,
                },
                net_total_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.1,
                },
                gross_total_spread: {
                  without_refunds: 9.66,
                  with_refunds: 9.39,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.21,
                  with_refunds: 12.68,
                },
                gross_mrr: {
                  without_refunds: 15.44,
                  with_refunds: 14.84,
                },
                net_arr: {
                  without_refunds: 103.74,
                  with_refunds: 90.33,
                },
                gross_arr: {
                  without_refunds: 117.1,
                  with_refunds: 101.99,
                },
                net_arr_spread: {
                  without_refunds: 8.65,
                  with_refunds: 8.65,
                },
                gross_arr_spread: {
                  without_refunds: 9.78,
                  with_refunds: 9.78,
                },
                net_total: {
                  without_refunds: 51.86,
                  with_refunds: 45.83,
                },
                gross_total: {
                  without_refunds: 58.84,
                  with_refunds: 52.05,
                },
                net_total_spread: {
                  without_refunds: 11.47,
                  with_refunds: 11.14,
                },
                gross_total_spread: {
                  without_refunds: 13.28,
                  with_refunds: 12.91,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 9.6,
                    with_refunds: 9.21,
                  },
                  gross_mrr: {
                    without_refunds: 11.22,
                    with_refunds: 10.78,
                  },
                  net_arr: {
                    without_refunds: 67.05,
                    with_refunds: 58.38,
                  },
                  gross_arr: {
                    without_refunds: 75.68,
                    with_refunds: 65.92,
                  },
                  net_arr_spread: {
                    without_refunds: 6.22,
                    with_refunds: 6.22,
                  },
                  gross_arr_spread: {
                    without_refunds: 7.03,
                    with_refunds: 7.03,
                  },
                  net_total: {
                    without_refunds: 36.05,
                    with_refunds: 31.86,
                  },
                  gross_total: {
                    without_refunds: 40.9,
                    with_refunds: 36.18,
                  },
                  net_total_spread: {
                    without_refunds: 8.34,
                    with_refunds: 8.1,
                  },
                  gross_total_spread: {
                    without_refunds: 9.66,
                    with_refunds: 9.39,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 13.21,
                    with_refunds: 12.68,
                  },
                  gross_mrr: {
                    without_refunds: 15.44,
                    with_refunds: 14.84,
                  },
                  net_arr: {
                    without_refunds: 103.74,
                    with_refunds: 90.33,
                  },
                  gross_arr: {
                    without_refunds: 117.1,
                    with_refunds: 101.99,
                  },
                  net_arr_spread: {
                    without_refunds: 8.65,
                    with_refunds: 8.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.78,
                    with_refunds: 9.78,
                  },
                  net_total: {
                    without_refunds: 51.86,
                    with_refunds: 45.83,
                  },
                  gross_total: {
                    without_refunds: 58.84,
                    with_refunds: 52.05,
                  },
                  net_total_spread: {
                    without_refunds: 11.47,
                    with_refunds: 11.14,
                  },
                  gross_total_spread: {
                    without_refunds: 13.28,
                    with_refunds: 12.91,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1491,
                    with_refunds: 0.1431,
                  },
                  gross_mrr: {
                    without_refunds: 0.1743,
                    with_refunds: 0.1675,
                  },
                  net_arr: {
                    without_refunds: 1.0416,
                    with_refunds: 0.9069,
                  },
                  gross_arr: {
                    without_refunds: 1.1757,
                    with_refunds: 1.024,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0967,
                    with_refunds: 0.0967,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1093,
                    with_refunds: 0.1093,
                  },
                  net_total: {
                    without_refunds: 0.56,
                    with_refunds: 0.4949,
                  },
                  gross_total: {
                    without_refunds: 0.6354,
                    with_refunds: 0.5621,
                  },
                  net_total_spread: {
                    without_refunds: 0.1296,
                    with_refunds: 0.1259,
                  },
                  gross_total_spread: {
                    without_refunds: 0.15,
                    with_refunds: 0.1458,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2052,
                    with_refunds: 0.197,
                  },
                  gross_mrr: {
                    without_refunds: 0.2398,
                    with_refunds: 0.2305,
                  },
                  net_arr: {
                    without_refunds: 1.6117,
                    with_refunds: 1.4032,
                  },
                  gross_arr: {
                    without_refunds: 1.8191,
                    with_refunds: 1.5845,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1344,
                    with_refunds: 0.1344,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1519,
                    with_refunds: 0.1519,
                  },
                  net_total: {
                    without_refunds: 0.8056,
                    with_refunds: 0.7119,
                  },
                  gross_total: {
                    without_refunds: 0.914,
                    with_refunds: 0.8085,
                  },
                  net_total_spread: {
                    without_refunds: 0.1782,
                    with_refunds: 0.1731,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2063,
                    with_refunds: 0.2005,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 818,
                  with_refunds: 820,
                },
                arr_users: {
                  without_refunds: 351,
                  with_refunds: 375,
                },
                arr_spread_users: {
                  without_refunds: 769,
                  with_refunds: 769,
                },
                total_users: {
                  without_refunds: 1167,
                  with_refunds: 1193,
                },
                total_spread_users: {
                  without_refunds: 1587,
                  with_refunds: 1589,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6426,
                  with_refunds: 0.6441,
                },
                arr_users: {
                  without_refunds: 0.3302,
                  with_refunds: 0.3528,
                },
                arr_spread_users: {
                  without_refunds: 0.9685,
                  with_refunds: 0.9685,
                },
                total_users: {
                  without_refunds: 0.5039,
                  with_refunds: 0.5151,
                },
                total_spread_users: {
                  without_refunds: 0.7715,
                  with_refunds: 0.7725,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8843,
                  with_refunds: 0.8865,
                },
                arr_users: {
                  without_refunds: 0.5109,
                  with_refunds: 0.5459,
                },
                arr_spread_users: {
                  without_refunds: 1.3468,
                  with_refunds: 1.3468,
                },
                total_users: {
                  without_refunds: 0.7248,
                  with_refunds: 0.741,
                },
                total_spread_users: {
                  without_refunds: 1.0608,
                  with_refunds: 1.0622,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10684.92,
                  with_refunds: 9985.89,
                },
                gross_mrr: {
                  without_refunds: 12194.96,
                  with_refunds: 11446.5,
                },
                net_arr: {
                  without_refunds: 35254.14,
                  with_refunds: 19024.7,
                },
                gross_arr: {
                  without_refunds: 38844.09,
                  with_refunds: 21610.61,
                },
                net_arr_spread: {
                  without_refunds: 6633.68,
                  with_refunds: 6633.68,
                },
                gross_arr_spread: {
                  without_refunds: 7495.91,
                  with_refunds: 7495.91,
                },
                net_total: {
                  without_refunds: 45939.06,
                  with_refunds: 29010.6,
                },
                gross_total: {
                  without_refunds: 51039.05,
                  with_refunds: 33057.1,
                },
                net_total_spread: {
                  without_refunds: 17318.6,
                  with_refunds: 16619.57,
                },
                gross_total_spread: {
                  without_refunds: 19690.87,
                  with_refunds: 18942.4,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8745,
                  with_refunds: 0.8515,
                },
                gross_mrr: {
                  without_refunds: 0.854,
                  with_refunds: 0.8341,
                },
                net_arr: {
                  without_refunds: 0.4946,
                  with_refunds: 0.3066,
                },
                gross_arr: {
                  without_refunds: 0.4829,
                  with_refunds: 0.3084,
                },
                net_arr_spread: {
                  without_refunds: 1.3428,
                  with_refunds: 1.3428,
                },
                gross_arr_spread: {
                  without_refunds: 1.3424,
                  with_refunds: 1.3424,
                },
                net_total: {
                  without_refunds: 0.5502,
                  with_refunds: 0.3932,
                },
                gross_total: {
                  without_refunds: 0.5388,
                  with_refunds: 0.3945,
                },
                net_total_spread: {
                  without_refunds: 1.0094,
                  with_refunds: 0.9972,
                },
                gross_total_spread: {
                  without_refunds: 0.9913,
                  with_refunds: 0.9811,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8745,
                  with_refunds: 0.8515,
                },
                gross_mrr: {
                  without_refunds: 0.854,
                  with_refunds: 0.8341,
                },
                net_arr: {
                  without_refunds: 0.4946,
                  with_refunds: 0.3066,
                },
                gross_arr: {
                  without_refunds: 0.4829,
                  with_refunds: 0.3084,
                },
                net_arr_spread: {
                  without_refunds: 1.3428,
                  with_refunds: 1.3428,
                },
                gross_arr_spread: {
                  without_refunds: 1.3424,
                  with_refunds: 1.3424,
                },
                net_total: {
                  without_refunds: 0.5502,
                  with_refunds: 0.3932,
                },
                gross_total: {
                  without_refunds: 0.5388,
                  with_refunds: 0.3945,
                },
                net_total_spread: {
                  without_refunds: 1.0094,
                  with_refunds: 0.9972,
                },
                gross_total_spread: {
                  without_refunds: 0.9913,
                  with_refunds: 0.9811,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.39,
                  with_refunds: 7.84,
                },
                gross_mrr: {
                  without_refunds: 9.58,
                  with_refunds: 8.99,
                },
                net_arr: {
                  without_refunds: 33.16,
                  with_refunds: 17.9,
                },
                gross_arr: {
                  without_refunds: 36.54,
                  with_refunds: 20.33,
                },
                net_arr_spread: {
                  without_refunds: 8.35,
                  with_refunds: 8.35,
                },
                gross_arr_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.44,
                },
                net_total: {
                  without_refunds: 19.84,
                  with_refunds: 12.53,
                },
                gross_total: {
                  without_refunds: 22.04,
                  with_refunds: 14.27,
                },
                net_total_spread: {
                  without_refunds: 8.42,
                  with_refunds: 8.08,
                },
                gross_total_spread: {
                  without_refunds: 9.57,
                  with_refunds: 9.21,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.06,
                  with_refunds: 12.18,
                },
                gross_mrr: {
                  without_refunds: 14.91,
                  with_refunds: 13.96,
                },
                net_arr: {
                  without_refunds: 100.44,
                  with_refunds: 50.73,
                },
                gross_arr: {
                  without_refunds: 110.67,
                  with_refunds: 57.63,
                },
                net_arr_spread: {
                  without_refunds: 8.63,
                  with_refunds: 8.63,
                },
                gross_arr_spread: {
                  without_refunds: 9.75,
                  with_refunds: 9.75,
                },
                net_total: {
                  without_refunds: 39.37,
                  with_refunds: 24.32,
                },
                gross_total: {
                  without_refunds: 43.74,
                  with_refunds: 27.71,
                },
                net_total_spread: {
                  without_refunds: 10.91,
                  with_refunds: 10.46,
                },
                gross_total_spread: {
                  without_refunds: 12.41,
                  with_refunds: 11.92,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 17.99,
                    with_refunds: 17.06,
                  },
                  gross_mrr: {
                    without_refunds: 20.8,
                    with_refunds: 19.77,
                  },
                  net_arr: {
                    without_refunds: 100.21,
                    with_refunds: 76.27,
                  },
                  gross_arr: {
                    without_refunds: 112.22,
                    with_refunds: 86.25,
                  },
                  net_arr_spread: {
                    without_refunds: 14.58,
                    with_refunds: 14.58,
                  },
                  gross_arr_spread: {
                    without_refunds: 16.47,
                    with_refunds: 16.47,
                  },
                  net_total: {
                    without_refunds: 55.88,
                    with_refunds: 44.38,
                  },
                  gross_total: {
                    without_refunds: 62.94,
                    with_refunds: 50.45,
                  },
                  net_total_spread: {
                    without_refunds: 16.76,
                    with_refunds: 16.18,
                  },
                  gross_total_spread: {
                    without_refunds: 19.23,
                    with_refunds: 18.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 26.27,
                    with_refunds: 24.86,
                  },
                  gross_mrr: {
                    without_refunds: 30.35,
                    with_refunds: 28.8,
                  },
                  net_arr: {
                    without_refunds: 204.18,
                    with_refunds: 141.06,
                  },
                  gross_arr: {
                    without_refunds: 227.77,
                    with_refunds: 159.62,
                  },
                  net_arr_spread: {
                    without_refunds: 17.28,
                    with_refunds: 17.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 19.53,
                    with_refunds: 19.53,
                  },
                  net_total: {
                    without_refunds: 91.22,
                    with_refunds: 70.14,
                  },
                  gross_total: {
                    without_refunds: 102.57,
                    with_refunds: 79.75,
                  },
                  net_total_spread: {
                    without_refunds: 22.38,
                    with_refunds: 21.6,
                  },
                  gross_total_spread: {
                    without_refunds: 25.68,
                    with_refunds: 24.83,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2795,
                    with_refunds: 0.265,
                  },
                  gross_mrr: {
                    without_refunds: 0.3231,
                    with_refunds: 0.3072,
                  },
                  net_arr: {
                    without_refunds: 1.5568,
                    with_refunds: 1.1849,
                  },
                  gross_arr: {
                    without_refunds: 1.7434,
                    with_refunds: 1.3399,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2264,
                    with_refunds: 0.2264,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2559,
                    with_refunds: 0.2559,
                  },
                  net_total: {
                    without_refunds: 0.8682,
                    with_refunds: 0.6895,
                  },
                  gross_total: {
                    without_refunds: 0.9778,
                    with_refunds: 0.7838,
                  },
                  net_total_spread: {
                    without_refunds: 0.2604,
                    with_refunds: 0.2514,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2987,
                    with_refunds: 0.2889,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4081,
                    with_refunds: 0.3861,
                  },
                  gross_mrr: {
                    without_refunds: 0.4714,
                    with_refunds: 0.4473,
                  },
                  net_arr: {
                    without_refunds: 3.172,
                    with_refunds: 2.1914,
                  },
                  gross_arr: {
                    without_refunds: 3.5384,
                    with_refunds: 2.4797,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2684,
                    with_refunds: 0.2684,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3033,
                    with_refunds: 0.3033,
                  },
                  net_total: {
                    without_refunds: 1.4172,
                    with_refunds: 1.0897,
                  },
                  gross_total: {
                    without_refunds: 1.5935,
                    with_refunds: 1.239,
                  },
                  net_total_spread: {
                    without_refunds: 0.3477,
                    with_refunds: 0.3356,
                  },
                  gross_total_spread: {
                    without_refunds: 0.399,
                    with_refunds: 0.3857,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 566,
                  with_refunds: 567,
                },
                arr_users: {
                  without_refunds: 15,
                  with_refunds: 22,
                },
                arr_spread_users: {
                  without_refunds: 780,
                  with_refunds: 780,
                },
                total_users: {
                  without_refunds: 581,
                  with_refunds: 589,
                },
                total_spread_users: {
                  without_refunds: 1346,
                  with_refunds: 1347,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4446,
                  with_refunds: 0.4454,
                },
                arr_users: {
                  without_refunds: 0.0141,
                  with_refunds: 0.0207,
                },
                arr_spread_users: {
                  without_refunds: 0.9824,
                  with_refunds: 0.9824,
                },
                total_users: {
                  without_refunds: 0.2509,
                  with_refunds: 0.2543,
                },
                total_spread_users: {
                  without_refunds: 0.6544,
                  with_refunds: 0.6548,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6919,
                  with_refunds: 0.6915,
                },
                arr_users: {
                  without_refunds: 0.0427,
                  with_refunds: 0.0587,
                },
                arr_spread_users: {
                  without_refunds: 1.0143,
                  with_refunds: 1.0143,
                },
                total_users: {
                  without_refunds: 0.4979,
                  with_refunds: 0.4937,
                },
                total_spread_users: {
                  without_refunds: 0.8481,
                  with_refunds: 0.8477,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7376.73,
                  with_refunds: 7207.38,
                },
                gross_mrr: {
                  without_refunds: 8424.85,
                  with_refunds: 8240.48,
                },
                net_arr: {
                  without_refunds: 1676.33,
                  with_refunds: 597.79,
                },
                gross_arr: {
                  without_refunds: 2094.16,
                  with_refunds: 792.4,
                },
                net_arr_spread: {
                  without_refunds: 6738.74,
                  with_refunds: 6738.74,
                },
                gross_arr_spread: {
                  without_refunds: 7630.82,
                  with_refunds: 7630.82,
                },
                net_total: {
                  without_refunds: 9053.06,
                  with_refunds: 7805.16,
                },
                gross_total: {
                  without_refunds: 10519.01,
                  with_refunds: 9032.89,
                },
                net_total_spread: {
                  without_refunds: 14115.47,
                  with_refunds: 13946.12,
                },
                gross_total_spread: {
                  without_refunds: 16055.67,
                  with_refunds: 15871.3,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6038,
                  with_refunds: 0.6146,
                },
                gross_mrr: {
                  without_refunds: 0.59,
                  with_refunds: 0.6005,
                },
                net_arr: {
                  without_refunds: 0.0235,
                  with_refunds: 0.0096,
                },
                gross_arr: {
                  without_refunds: 0.026,
                  with_refunds: 0.0113,
                },
                net_arr_spread: {
                  without_refunds: 1.3641,
                  with_refunds: 1.3641,
                },
                gross_arr_spread: {
                  without_refunds: 1.3666,
                  with_refunds: 1.3666,
                },
                net_total: {
                  without_refunds: 0.1084,
                  with_refunds: 0.1058,
                },
                gross_total: {
                  without_refunds: 0.111,
                  with_refunds: 0.1078,
                },
                net_total_spread: {
                  without_refunds: 0.8227,
                  with_refunds: 0.8368,
                },
                gross_total_spread: {
                  without_refunds: 0.8083,
                  with_refunds: 0.822,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6904,
                  with_refunds: 0.7218,
                },
                gross_mrr: {
                  without_refunds: 0.6908,
                  with_refunds: 0.7199,
                },
                net_arr: {
                  without_refunds: 0.0475,
                  with_refunds: 0.0314,
                },
                gross_arr: {
                  without_refunds: 0.0539,
                  with_refunds: 0.0367,
                },
                net_arr_spread: {
                  without_refunds: 1.0158,
                  with_refunds: 1.0158,
                },
                gross_arr_spread: {
                  without_refunds: 1.018,
                  with_refunds: 1.018,
                },
                net_total: {
                  without_refunds: 0.1971,
                  with_refunds: 0.269,
                },
                gross_total: {
                  without_refunds: 0.2061,
                  with_refunds: 0.2733,
                },
                net_total_spread: {
                  without_refunds: 0.815,
                  with_refunds: 0.8391,
                },
                gross_total_spread: {
                  without_refunds: 0.8154,
                  with_refunds: 0.8379,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.79,
                  with_refunds: 5.66,
                },
                gross_mrr: {
                  without_refunds: 6.62,
                  with_refunds: 6.47,
                },
                net_arr: {
                  without_refunds: 1.58,
                  with_refunds: 0.56,
                },
                gross_arr: {
                  without_refunds: 1.97,
                  with_refunds: 0.75,
                },
                net_arr_spread: {
                  without_refunds: 8.49,
                  with_refunds: 8.49,
                },
                gross_arr_spread: {
                  without_refunds: 9.61,
                  with_refunds: 9.61,
                },
                net_total: {
                  without_refunds: 3.91,
                  with_refunds: 3.37,
                },
                gross_total: {
                  without_refunds: 4.54,
                  with_refunds: 3.9,
                },
                net_total_spread: {
                  without_refunds: 6.86,
                  with_refunds: 6.78,
                },
                gross_total_spread: {
                  without_refunds: 7.81,
                  with_refunds: 7.72,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.03,
                  with_refunds: 12.71,
                },
                gross_mrr: {
                  without_refunds: 14.88,
                  with_refunds: 14.53,
                },
                net_arr: {
                  without_refunds: 111.76,
                  with_refunds: 27.17,
                },
                gross_arr: {
                  without_refunds: 139.61,
                  with_refunds: 36.02,
                },
                net_arr_spread: {
                  without_refunds: 8.64,
                  with_refunds: 8.64,
                },
                gross_arr_spread: {
                  without_refunds: 9.78,
                  with_refunds: 9.78,
                },
                net_total: {
                  without_refunds: 15.58,
                  with_refunds: 13.25,
                },
                gross_total: {
                  without_refunds: 18.11,
                  with_refunds: 15.34,
                },
                net_total_spread: {
                  without_refunds: 10.49,
                  with_refunds: 10.35,
                },
                gross_total_spread: {
                  without_refunds: 11.93,
                  with_refunds: 11.78,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.79,
                    with_refunds: 22.72,
                  },
                  gross_mrr: {
                    without_refunds: 27.41,
                    with_refunds: 26.25,
                  },
                  net_arr: {
                    without_refunds: 101.79,
                    with_refunds: 76.84,
                  },
                  gross_arr: {
                    without_refunds: 114.19,
                    with_refunds: 86.99,
                  },
                  net_arr_spread: {
                    without_refunds: 23.06,
                    with_refunds: 23.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 26.08,
                    with_refunds: 26.08,
                  },
                  net_total: {
                    without_refunds: 59.79,
                    with_refunds: 47.75,
                  },
                  gross_total: {
                    without_refunds: 67.48,
                    with_refunds: 54.35,
                  },
                  net_total_spread: {
                    without_refunds: 23.62,
                    with_refunds: 22.96,
                  },
                  gross_total_spread: {
                    without_refunds: 27.03,
                    with_refunds: 26.31,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.3,
                    with_refunds: 37.57,
                  },
                  gross_mrr: {
                    without_refunds: 45.23,
                    with_refunds: 43.33,
                  },
                  net_arr: {
                    without_refunds: 315.94,
                    with_refunds: 168.23,
                  },
                  gross_arr: {
                    without_refunds: 367.38,
                    with_refunds: 195.64,
                  },
                  net_arr_spread: {
                    without_refunds: 25.92,
                    with_refunds: 25.92,
                  },
                  gross_arr_spread: {
                    without_refunds: 29.31,
                    with_refunds: 29.31,
                  },
                  net_total: {
                    without_refunds: 106.8,
                    with_refunds: 83.4,
                  },
                  gross_total: {
                    without_refunds: 120.68,
                    with_refunds: 95.09,
                  },
                  net_total_spread: {
                    without_refunds: 32.87,
                    with_refunds: 31.95,
                  },
                  gross_total_spread: {
                    without_refunds: 37.61,
                    with_refunds: 36.61,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3695,
                    with_refunds: 0.3529,
                  },
                  gross_mrr: {
                    without_refunds: 0.4259,
                    with_refunds: 0.4077,
                  },
                  net_arr: {
                    without_refunds: 1.5813,
                    with_refunds: 1.1937,
                  },
                  gross_arr: {
                    without_refunds: 1.774,
                    with_refunds: 1.3514,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3583,
                    with_refunds: 0.3583,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4052,
                    with_refunds: 0.4052,
                  },
                  net_total: {
                    without_refunds: 0.9289,
                    with_refunds: 0.7419,
                  },
                  gross_total: {
                    without_refunds: 1.0483,
                    with_refunds: 0.8444,
                  },
                  net_total_spread: {
                    without_refunds: 0.367,
                    with_refunds: 0.3567,
                  },
                  gross_total_spread: {
                    without_refunds: 0.42,
                    with_refunds: 0.4087,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6106,
                    with_refunds: 0.5836,
                  },
                  gross_mrr: {
                    without_refunds: 0.7027,
                    with_refunds: 0.6731,
                  },
                  net_arr: {
                    without_refunds: 4.9082,
                    with_refunds: 2.6135,
                  },
                  gross_arr: {
                    without_refunds: 5.7073,
                    with_refunds: 3.0393,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4026,
                    with_refunds: 0.4026,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4553,
                    with_refunds: 0.4553,
                  },
                  net_total: {
                    without_refunds: 1.6592,
                    with_refunds: 1.2956,
                  },
                  gross_total: {
                    without_refunds: 1.8747,
                    with_refunds: 1.4772,
                  },
                  net_total_spread: {
                    without_refunds: 0.5106,
                    with_refunds: 0.4964,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5843,
                    with_refunds: 0.5687,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 478,
                  with_refunds: 483,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 14,
                },
                arr_spread_users: {
                  without_refunds: 785,
                  with_refunds: 785,
                },
                total_users: {
                  without_refunds: 486,
                  with_refunds: 496,
                },
                total_spread_users: {
                  without_refunds: 1263,
                  with_refunds: 1268,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3755,
                  with_refunds: 0.3794,
                },
                arr_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0132,
                },
                arr_spread_users: {
                  without_refunds: 0.9887,
                  with_refunds: 0.9887,
                },
                total_users: {
                  without_refunds: 0.2098,
                  with_refunds: 0.2142,
                },
                total_spread_users: {
                  without_refunds: 0.614,
                  with_refunds: 0.6164,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8445,
                  with_refunds: 0.8519,
                },
                arr_users: {
                  without_refunds: 0.5333,
                  with_refunds: 0.6364,
                },
                arr_spread_users: {
                  without_refunds: 1.0064,
                  with_refunds: 1.0064,
                },
                total_users: {
                  without_refunds: 0.8365,
                  with_refunds: 0.8421,
                },
                total_spread_users: {
                  without_refunds: 0.9383,
                  with_refunds: 0.9414,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6270.74,
                  with_refunds: 6100.34,
                },
                gross_mrr: {
                  without_refunds: 7099.76,
                  with_refunds: 6916.66,
                },
                net_arr: {
                  without_refunds: 931.75,
                  with_refunds: 128.96,
                },
                gross_arr: {
                  without_refunds: 1194.26,
                  with_refunds: 278.33,
                },
                net_arr_spread: {
                  without_refunds: 6785.25,
                  with_refunds: 6785.25,
                },
                gross_arr_spread: {
                  without_refunds: 7691.69,
                  with_refunds: 7691.69,
                },
                net_total: {
                  without_refunds: 7202.49,
                  with_refunds: 6229.3,
                },
                gross_total: {
                  without_refunds: 8294.01,
                  with_refunds: 7194.99,
                },
                net_total_spread: {
                  without_refunds: 13055.99,
                  with_refunds: 12885.59,
                },
                gross_total_spread: {
                  without_refunds: 14791.44,
                  with_refunds: 14608.35,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5132,
                  with_refunds: 0.5202,
                },
                gross_mrr: {
                  without_refunds: 0.4972,
                  with_refunds: 0.504,
                },
                net_arr: {
                  without_refunds: 0.0131,
                  with_refunds: 0.0021,
                },
                gross_arr: {
                  without_refunds: 0.0148,
                  with_refunds: 0.004,
                },
                net_arr_spread: {
                  without_refunds: 1.3735,
                  with_refunds: 1.3735,
                },
                gross_arr_spread: {
                  without_refunds: 1.3775,
                  with_refunds: 1.3775,
                },
                net_total: {
                  without_refunds: 0.0863,
                  with_refunds: 0.0844,
                },
                gross_total: {
                  without_refunds: 0.0876,
                  with_refunds: 0.0859,
                },
                net_total_spread: {
                  without_refunds: 0.7609,
                  with_refunds: 0.7731,
                },
                gross_total_spread: {
                  without_refunds: 0.7447,
                  with_refunds: 0.7566,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8501,
                  with_refunds: 0.8464,
                },
                gross_mrr: {
                  without_refunds: 0.8427,
                  with_refunds: 0.8394,
                },
                net_arr: {
                  without_refunds: 0.5558,
                  with_refunds: 0.2157,
                },
                gross_arr: {
                  without_refunds: 0.5703,
                  with_refunds: 0.3512,
                },
                net_arr_spread: {
                  without_refunds: 1.0069,
                  with_refunds: 1.0069,
                },
                gross_arr_spread: {
                  without_refunds: 1.008,
                  with_refunds: 1.008,
                },
                net_total: {
                  without_refunds: 0.7956,
                  with_refunds: 0.7981,
                },
                gross_total: {
                  without_refunds: 0.7885,
                  with_refunds: 0.7965,
                },
                net_total_spread: {
                  without_refunds: 0.9249,
                  with_refunds: 0.924,
                },
                gross_total_spread: {
                  without_refunds: 0.9213,
                  with_refunds: 0.9204,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.93,
                  with_refunds: 4.79,
                },
                gross_mrr: {
                  without_refunds: 5.58,
                  with_refunds: 5.43,
                },
                net_arr: {
                  without_refunds: 0.88,
                  with_refunds: 0.12,
                },
                gross_arr: {
                  without_refunds: 1.12,
                  with_refunds: 0.26,
                },
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.69,
                  with_refunds: 9.69,
                },
                net_total: {
                  without_refunds: 3.11,
                  with_refunds: 2.69,
                },
                gross_total: {
                  without_refunds: 3.58,
                  with_refunds: 3.11,
                },
                net_total_spread: {
                  without_refunds: 6.35,
                  with_refunds: 6.26,
                },
                gross_total_spread: {
                  without_refunds: 7.19,
                  with_refunds: 7.1,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 12.63,
                },
                gross_mrr: {
                  without_refunds: 14.85,
                  with_refunds: 14.32,
                },
                net_arr: {
                  without_refunds: 116.47,
                  with_refunds: 9.21,
                },
                gross_arr: {
                  without_refunds: 149.28,
                  with_refunds: 19.88,
                },
                net_arr_spread: {
                  without_refunds: 8.64,
                  with_refunds: 8.64,
                },
                gross_arr_spread: {
                  without_refunds: 9.8,
                  with_refunds: 9.8,
                },
                net_total: {
                  without_refunds: 14.82,
                  with_refunds: 12.56,
                },
                gross_total: {
                  without_refunds: 17.07,
                  with_refunds: 14.51,
                },
                net_total_spread: {
                  without_refunds: 10.34,
                  with_refunds: 10.16,
                },
                gross_total_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.52,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.71,
                    with_refunds: 27.51,
                  },
                  gross_mrr: {
                    without_refunds: 32.99,
                    with_refunds: 31.68,
                  },
                  net_arr: {
                    without_refunds: 102.67,
                    with_refunds: 76.96,
                  },
                  gross_arr: {
                    without_refunds: 115.31,
                    with_refunds: 87.25,
                  },
                  net_arr_spread: {
                    without_refunds: 31.61,
                    with_refunds: 31.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 35.77,
                    with_refunds: 35.77,
                  },
                  net_total: {
                    without_refunds: 62.9,
                    with_refunds: 50.44,
                  },
                  gross_total: {
                    without_refunds: 71.06,
                    with_refunds: 57.46,
                  },
                  net_total_spread: {
                    without_refunds: 29.97,
                    with_refunds: 29.23,
                  },
                  gross_total_spread: {
                    without_refunds: 34.23,
                    with_refunds: 33.41,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 52.42,
                    with_refunds: 50.2,
                  },
                  gross_mrr: {
                    without_refunds: 60.08,
                    with_refunds: 57.65,
                  },
                  net_arr: {
                    without_refunds: 432.41,
                    with_refunds: 177.44,
                  },
                  gross_arr: {
                    without_refunds: 516.66,
                    with_refunds: 215.52,
                  },
                  net_arr_spread: {
                    without_refunds: 34.56,
                    with_refunds: 34.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 39.11,
                    with_refunds: 39.11,
                  },
                  net_total: {
                    without_refunds: 121.62,
                    with_refunds: 95.95,
                  },
                  gross_total: {
                    without_refunds: 137.74,
                    with_refunds: 109.6,
                  },
                  net_total_spread: {
                    without_refunds: 43.21,
                    with_refunds: 42.12,
                  },
                  gross_total_spread: {
                    without_refunds: 49.32,
                    with_refunds: 48.13,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.446,
                    with_refunds: 0.4274,
                  },
                  gross_mrr: {
                    without_refunds: 0.5125,
                    with_refunds: 0.4921,
                  },
                  net_arr: {
                    without_refunds: 1.5949,
                    with_refunds: 1.1955,
                  },
                  gross_arr: {
                    without_refunds: 1.7914,
                    with_refunds: 1.3555,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4911,
                    with_refunds: 0.4911,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5557,
                    with_refunds: 0.5557,
                  },
                  net_total: {
                    without_refunds: 0.9772,
                    with_refunds: 0.7836,
                  },
                  gross_total: {
                    without_refunds: 1.1039,
                    with_refunds: 0.8927,
                  },
                  net_total_spread: {
                    without_refunds: 0.4656,
                    with_refunds: 0.454,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5317,
                    with_refunds: 0.5191,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8144,
                    with_refunds: 0.7798,
                  },
                  gross_mrr: {
                    without_refunds: 0.9334,
                    with_refunds: 0.8956,
                  },
                  net_arr: {
                    without_refunds: 6.7175,
                    with_refunds: 2.7566,
                  },
                  gross_arr: {
                    without_refunds: 8.0264,
                    with_refunds: 3.3481,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5369,
                    with_refunds: 0.5369,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6076,
                    with_refunds: 0.6076,
                  },
                  net_total: {
                    without_refunds: 1.8895,
                    with_refunds: 1.4907,
                  },
                  gross_total: {
                    without_refunds: 2.1398,
                    with_refunds: 1.7026,
                  },
                  net_total_spread: {
                    without_refunds: 0.6712,
                    with_refunds: 0.6543,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7663,
                    with_refunds: 0.7477,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 398,
                  with_refunds: 400,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 403,
                  with_refunds: 405,
                },
                total_spread_users: {
                  without_refunds: 1182,
                  with_refunds: 1184,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3126,
                  with_refunds: 0.3142,
                },
                arr_users: {
                  without_refunds: 0.0047,
                  with_refunds: 0.0056,
                },
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.174,
                  with_refunds: 0.1749,
                },
                total_spread_users: {
                  without_refunds: 0.5746,
                  with_refunds: 0.5756,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8326,
                  with_refunds: 0.8282,
                },
                arr_users: {
                  without_refunds: 0.625,
                  with_refunds: 0.4286,
                },
                arr_spread_users: {
                  without_refunds: 0.9987,
                  with_refunds: 0.9987,
                },
                total_users: {
                  without_refunds: 0.8292,
                  with_refunds: 0.8165,
                },
                total_spread_users: {
                  without_refunds: 0.9359,
                  with_refunds: 0.9338,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5124.47,
                  with_refunds: 4990.55,
                },
                gross_mrr: {
                  without_refunds: 5818.38,
                  with_refunds: 5666.47,
                },
                net_arr: {
                  without_refunds: 597.01,
                  with_refunds: 353.68,
                },
                gross_arr: {
                  without_refunds: 828.23,
                  with_refunds: 504.25,
                },
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 5721.48,
                  with_refunds: 5344.24,
                },
                gross_total: {
                  without_refunds: 6646.61,
                  with_refunds: 6170.71,
                },
                net_total_spread: {
                  without_refunds: 11915.12,
                  with_refunds: 11781.19,
                },
                gross_total_spread: {
                  without_refunds: 13526.91,
                  with_refunds: 13374.99,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4194,
                  with_refunds: 0.4256,
                },
                gross_mrr: {
                  without_refunds: 0.4075,
                  with_refunds: 0.4129,
                },
                net_arr: {
                  without_refunds: 0.0084,
                  with_refunds: 0.0057,
                },
                gross_arr: {
                  without_refunds: 0.0103,
                  with_refunds: 0.0072,
                },
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0685,
                  with_refunds: 0.0724,
                },
                gross_total: {
                  without_refunds: 0.0702,
                  with_refunds: 0.0736,
                },
                net_total_spread: {
                  without_refunds: 0.6944,
                  with_refunds: 0.7069,
                },
                gross_total_spread: {
                  without_refunds: 0.681,
                  with_refunds: 0.6927,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8172,
                  with_refunds: 0.8181,
                },
                gross_mrr: {
                  without_refunds: 0.8195,
                  with_refunds: 0.8192,
                },
                net_arr: {
                  without_refunds: 0.6407,
                  with_refunds: 2.7426,
                },
                gross_arr: {
                  without_refunds: 0.6935,
                  with_refunds: 1.8117,
                },
                net_arr_spread: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                gross_arr_spread: {
                  without_refunds: 1.0022,
                  with_refunds: 1.0022,
                },
                net_total: {
                  without_refunds: 0.7944,
                  with_refunds: 0.8579,
                },
                gross_total: {
                  without_refunds: 0.8014,
                  with_refunds: 0.8576,
                },
                net_total_spread: {
                  without_refunds: 0.9126,
                  with_refunds: 0.9143,
                },
                gross_total_spread: {
                  without_refunds: 0.9145,
                  with_refunds: 0.9156,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.03,
                  with_refunds: 3.92,
                },
                gross_mrr: {
                  without_refunds: 4.57,
                  with_refunds: 4.45,
                },
                net_arr: {
                  without_refunds: 0.56,
                  with_refunds: 0.33,
                },
                gross_arr: {
                  without_refunds: 0.78,
                  with_refunds: 0.47,
                },
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 2.47,
                  with_refunds: 2.31,
                },
                gross_total: {
                  without_refunds: 2.87,
                  with_refunds: 2.66,
                },
                net_total_spread: {
                  without_refunds: 5.79,
                  with_refunds: 5.73,
                },
                gross_total_spread: {
                  without_refunds: 6.58,
                  with_refunds: 6.5,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.88,
                  with_refunds: 12.48,
                },
                gross_mrr: {
                  without_refunds: 14.62,
                  with_refunds: 14.17,
                },
                net_arr: {
                  without_refunds: 119.4,
                  with_refunds: 58.95,
                },
                gross_arr: {
                  without_refunds: 165.65,
                  with_refunds: 84.04,
                },
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 14.2,
                  with_refunds: 13.2,
                },
                gross_total: {
                  without_refunds: 16.49,
                  with_refunds: 15.24,
                },
                net_total_spread: {
                  without_refunds: 10.08,
                  with_refunds: 9.95,
                },
                gross_total_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.3,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.74,
                    with_refunds: 31.43,
                  },
                  gross_mrr: {
                    without_refunds: 37.56,
                    with_refunds: 36.13,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 40.16,
                    with_refunds: 40.16,
                  },
                  gross_arr_spread: {
                    without_refunds: 45.48,
                    with_refunds: 45.48,
                  },
                  net_total: {
                    without_refunds: 65.37,
                    with_refunds: 52.75,
                  },
                  gross_total: {
                    without_refunds: 73.93,
                    with_refunds: 60.12,
                  },
                  net_total_spread: {
                    without_refunds: 35.76,
                    with_refunds: 34.95,
                  },
                  gross_total_spread: {
                    without_refunds: 40.8,
                    with_refunds: 39.91,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 65.3,
                    with_refunds: 62.67,
                  },
                  gross_mrr: {
                    without_refunds: 74.7,
                    with_refunds: 71.82,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 43.22,
                    with_refunds: 43.22,
                  },
                  gross_arr_spread: {
                    without_refunds: 48.94,
                    with_refunds: 48.94,
                  },
                  net_total: {
                    without_refunds: 135.82,
                    with_refunds: 109.15,
                  },
                  gross_total: {
                    without_refunds: 154.23,
                    with_refunds: 124.83,
                  },
                  net_total_spread: {
                    without_refunds: 53.29,
                    with_refunds: 52.07,
                  },
                  gross_total_spread: {
                    without_refunds: 60.77,
                    with_refunds: 59.43,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5086,
                    with_refunds: 0.4883,
                  },
                  gross_mrr: {
                    without_refunds: 0.5835,
                    with_refunds: 0.5613,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6239,
                    with_refunds: 0.6239,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7065,
                    with_refunds: 0.7065,
                  },
                  net_total: {
                    without_refunds: 1.0156,
                    with_refunds: 0.8195,
                  },
                  gross_total: {
                    without_refunds: 1.1485,
                    with_refunds: 0.934,
                  },
                  net_total_spread: {
                    without_refunds: 0.5556,
                    with_refunds: 0.543,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6339,
                    with_refunds: 0.6201,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0144,
                    with_refunds: 0.9736,
                  },
                  gross_mrr: {
                    without_refunds: 1.1605,
                    with_refunds: 1.1157,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6715,
                    with_refunds: 0.6715,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7603,
                    with_refunds: 0.7603,
                  },
                  net_total: {
                    without_refunds: 2.11,
                    with_refunds: 1.6957,
                  },
                  gross_total: {
                    without_refunds: 2.3961,
                    with_refunds: 1.9393,
                  },
                  net_total_spread: {
                    without_refunds: 0.8278,
                    with_refunds: 0.8089,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9441,
                    with_refunds: 0.9232,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 339,
                  with_refunds: 344,
                },
                arr_users: {
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 339,
                  with_refunds: 348,
                },
                total_spread_users: {
                  without_refunds: 1123,
                  with_refunds: 1128,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2663,
                  with_refunds: 0.2702,
                },
                arr_users: {
                  with_refunds: 0.0038,
                },
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.1464,
                  with_refunds: 0.1503,
                },
                total_spread_users: {
                  without_refunds: 0.5459,
                  with_refunds: 0.5484,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8518,
                  with_refunds: 0.86,
                },
                arr_users: {
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8412,
                  with_refunds: 0.8593,
                },
                total_spread_users: {
                  without_refunds: 0.9501,
                  with_refunds: 0.9527,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4405.01,
                  with_refunds: 4204.3,
                },
                gross_mrr: {
                  without_refunds: 4973.67,
                  with_refunds: 4761.68,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 4405.01,
                  with_refunds: 4204.3,
                },
                gross_total: {
                  without_refunds: 4973.67,
                  with_refunds: 4761.68,
                },
                net_total_spread: {
                  without_refunds: 11195.66,
                  with_refunds: 10994.95,
                },
                gross_total_spread: {
                  without_refunds: 12682.2,
                  with_refunds: 12470.21,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3605,
                  with_refunds: 0.3585,
                },
                gross_mrr: {
                  without_refunds: 0.3483,
                  with_refunds: 0.347,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0528,
                  with_refunds: 0.057,
                },
                gross_total: {
                  without_refunds: 0.0525,
                  with_refunds: 0.0568,
                },
                net_total_spread: {
                  without_refunds: 0.6525,
                  with_refunds: 0.6597,
                },
                gross_total_spread: {
                  without_refunds: 0.6385,
                  with_refunds: 0.6459,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8596,
                  with_refunds: 0.8425,
                },
                gross_mrr: {
                  without_refunds: 0.8548,
                  with_refunds: 0.8403,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.7699,
                  with_refunds: 0.7867,
                },
                gross_total: {
                  without_refunds: 0.7483,
                  with_refunds: 0.7717,
                },
                net_total_spread: {
                  without_refunds: 0.9396,
                  with_refunds: 0.9333,
                },
                gross_total_spread: {
                  without_refunds: 0.9376,
                  with_refunds: 0.9324,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.46,
                  with_refunds: 3.3,
                },
                gross_mrr: {
                  without_refunds: 3.91,
                  with_refunds: 3.74,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 1.9,
                  with_refunds: 1.82,
                },
                gross_total: {
                  without_refunds: 2.15,
                  with_refunds: 2.06,
                },
                net_total_spread: {
                  without_refunds: 5.44,
                  with_refunds: 5.35,
                },
                gross_total_spread: {
                  without_refunds: 6.17,
                  with_refunds: 6.06,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.99,
                  with_refunds: 12.22,
                },
                gross_mrr: {
                  without_refunds: 14.67,
                  with_refunds: 13.84,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 12.99,
                  with_refunds: 12.08,
                },
                gross_total: {
                  without_refunds: 14.67,
                  with_refunds: 13.68,
                },
                net_total_spread: {
                  without_refunds: 9.97,
                  with_refunds: 9.75,
                },
                gross_total_spread: {
                  without_refunds: 11.29,
                  with_refunds: 11.06,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.2,
                    with_refunds: 34.73,
                  },
                  gross_mrr: {
                    without_refunds: 41.47,
                    with_refunds: 39.87,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 48.71,
                    with_refunds: 48.71,
                  },
                  gross_arr_spread: {
                    without_refunds: 55.19,
                    with_refunds: 55.19,
                  },
                  net_total: {
                    without_refunds: 67.28,
                    with_refunds: 54.57,
                  },
                  gross_total: {
                    without_refunds: 76.08,
                    with_refunds: 62.18,
                  },
                  net_total_spread: {
                    without_refunds: 41.21,
                    with_refunds: 40.3,
                  },
                  gross_total_spread: {
                    without_refunds: 46.97,
                    with_refunds: 45.98,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 78.29,
                    with_refunds: 74.9,
                  },
                  gross_mrr: {
                    without_refunds: 89.37,
                    with_refunds: 85.66,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 51.88,
                    with_refunds: 51.88,
                  },
                  gross_arr_spread: {
                    without_refunds: 58.77,
                    with_refunds: 58.77,
                  },
                  net_total: {
                    without_refunds: 148.82,
                    with_refunds: 121.23,
                  },
                  gross_total: {
                    without_refunds: 168.91,
                    with_refunds: 138.52,
                  },
                  net_total_spread: {
                    without_refunds: 63.26,
                    with_refunds: 61.81,
                  },
                  gross_total_spread: {
                    without_refunds: 72.06,
                    with_refunds: 70.48,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5623,
                    with_refunds: 0.5396,
                  },
                  gross_mrr: {
                    without_refunds: 0.6442,
                    with_refunds: 0.6194,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7568,
                    with_refunds: 0.7568,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8574,
                    with_refunds: 0.8574,
                  },
                  net_total: {
                    without_refunds: 1.0451,
                    with_refunds: 0.8477,
                  },
                  gross_total: {
                    without_refunds: 1.1819,
                    with_refunds: 0.966,
                  },
                  net_total_spread: {
                    without_refunds: 0.6401,
                    with_refunds: 0.626,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7296,
                    with_refunds: 0.7143,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2163,
                    with_refunds: 1.1635,
                  },
                  gross_mrr: {
                    without_refunds: 1.3884,
                    with_refunds: 1.3307,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 0.806,
                    with_refunds: 0.806,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.913,
                    with_refunds: 0.913,
                  },
                  net_total: {
                    without_refunds: 2.3119,
                    with_refunds: 1.8834,
                  },
                  gross_total: {
                    without_refunds: 2.624,
                    with_refunds: 2.1519,
                  },
                  net_total_spread: {
                    without_refunds: 0.9827,
                    with_refunds: 0.9603,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1195,
                    with_refunds: 1.0949,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 288,
                  with_refunds: 291,
                },
                arr_users: {
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 288,
                  with_refunds: 292,
                },
                total_spread_users: {
                  without_refunds: 1072,
                  with_refunds: 1075,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2262,
                  with_refunds: 0.2286,
                },
                arr_users: {
                  with_refunds: 0.0009,
                },
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.1244,
                  with_refunds: 0.1261,
                },
                total_spread_users: {
                  without_refunds: 0.5211,
                  with_refunds: 0.5226,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8496,
                  with_refunds: 0.8459,
                },
                arr_users: {
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8496,
                  with_refunds: 0.8391,
                },
                total_spread_users: {
                  without_refunds: 0.9546,
                  with_refunds: 0.953,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3789.25,
                  with_refunds: 3716.6,
                },
                gross_mrr: {
                  without_refunds: 4280.25,
                  with_refunds: 4198.62,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 3789.25,
                  with_refunds: 3716.6,
                },
                gross_total: {
                  without_refunds: 4280.25,
                  with_refunds: 4198.62,
                },
                net_total_spread: {
                  without_refunds: 10579.89,
                  with_refunds: 10507.24,
                },
                gross_total_spread: {
                  without_refunds: 11988.78,
                  with_refunds: 11907.15,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3101,
                  with_refunds: 0.3169,
                },
                gross_mrr: {
                  without_refunds: 0.2998,
                  with_refunds: 0.3059,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0454,
                  with_refunds: 0.0504,
                },
                gross_total: {
                  without_refunds: 0.0452,
                  with_refunds: 0.0501,
                },
                net_total_spread: {
                  without_refunds: 0.6166,
                  with_refunds: 0.6304,
                },
                gross_total_spread: {
                  without_refunds: 0.6036,
                  with_refunds: 0.6167,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8602,
                  with_refunds: 0.884,
                },
                gross_mrr: {
                  without_refunds: 0.8606,
                  with_refunds: 0.8818,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8602,
                  with_refunds: 0.884,
                },
                gross_total: {
                  without_refunds: 0.8606,
                  with_refunds: 0.8818,
                },
                net_total_spread: {
                  without_refunds: 0.945,
                  with_refunds: 0.9556,
                },
                gross_total_spread: {
                  without_refunds: 0.9453,
                  with_refunds: 0.9548,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.98,
                  with_refunds: 2.92,
                },
                gross_mrr: {
                  without_refunds: 3.36,
                  with_refunds: 3.3,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 1.64,
                  with_refunds: 1.6,
                },
                gross_total: {
                  without_refunds: 1.85,
                  with_refunds: 1.81,
                },
                net_total_spread: {
                  without_refunds: 5.14,
                  with_refunds: 5.11,
                },
                gross_total_spread: {
                  without_refunds: 5.83,
                  with_refunds: 5.79,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.16,
                  with_refunds: 12.77,
                },
                gross_mrr: {
                  without_refunds: 14.86,
                  with_refunds: 14.43,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 13.16,
                  with_refunds: 12.73,
                },
                gross_total: {
                  without_refunds: 14.86,
                  with_refunds: 14.38,
                },
                net_total_spread: {
                  without_refunds: 9.87,
                  with_refunds: 9.77,
                },
                gross_total_spread: {
                  without_refunds: 11.18,
                  with_refunds: 11.08,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.17,
                    with_refunds: 37.65,
                  },
                  gross_mrr: {
                    without_refunds: 44.83,
                    with_refunds: 43.17,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 57.27,
                    with_refunds: 57.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.9,
                    with_refunds: 64.9,
                  },
                  net_total: {
                    without_refunds: 68.91,
                    with_refunds: 56.17,
                  },
                  gross_total: {
                    without_refunds: 77.93,
                    with_refunds: 63.99,
                  },
                  net_total_spread: {
                    without_refunds: 46.35,
                    with_refunds: 45.41,
                  },
                  gross_total_spread: {
                    without_refunds: 52.79,
                    with_refunds: 51.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 91.45,
                    with_refunds: 87.67,
                  },
                  gross_mrr: {
                    without_refunds: 104.24,
                    with_refunds: 100.09,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 60.55,
                    with_refunds: 60.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 68.6,
                    with_refunds: 68.6,
                  },
                  net_total: {
                    without_refunds: 161.97,
                    with_refunds: 133.96,
                  },
                  gross_total: {
                    without_refunds: 183.77,
                    with_refunds: 152.89,
                  },
                  net_total_spread: {
                    without_refunds: 73.13,
                    with_refunds: 71.59,
                  },
                  gross_total_spread: {
                    without_refunds: 83.25,
                    with_refunds: 81.56,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6086,
                    with_refunds: 0.5849,
                  },
                  gross_mrr: {
                    without_refunds: 0.6965,
                    with_refunds: 0.6706,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8896,
                    with_refunds: 0.8896,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0082,
                    with_refunds: 1.0082,
                  },
                  net_total: {
                    without_refunds: 1.0706,
                    with_refunds: 0.8726,
                  },
                  gross_total: {
                    without_refunds: 1.2106,
                    with_refunds: 0.9941,
                  },
                  net_total_spread: {
                    without_refunds: 0.72,
                    with_refunds: 0.7054,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8202,
                    with_refunds: 0.8042,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.4207,
                    with_refunds: 1.3619,
                  },
                  gross_mrr: {
                    without_refunds: 1.6193,
                    with_refunds: 1.5548,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9406,
                    with_refunds: 0.9406,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0658,
                    with_refunds: 1.0658,
                  },
                  net_total: {
                    without_refunds: 2.5163,
                    with_refunds: 2.0811,
                  },
                  gross_total: {
                    without_refunds: 2.8549,
                    with_refunds: 2.3752,
                  },
                  net_total_spread: {
                    without_refunds: 1.136,
                    with_refunds: 1.1121,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2932,
                    with_refunds: 1.267,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 48,
                  with_refunds: 49,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 48,
                  with_refunds: 49,
                },
                total_spread_users: {
                  without_refunds: 832,
                  with_refunds: 833,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0377,
                  with_refunds: 0.0385,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.0207,
                  with_refunds: 0.0212,
                },
                total_spread_users: {
                  without_refunds: 0.4045,
                  with_refunds: 0.405,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1684,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1678,
                },
                total_spread_users: {
                  without_refunds: 0.7761,
                  with_refunds: 0.7749,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 627.58,
                  with_refunds: 548.13,
                },
                gross_mrr: {
                  without_refunds: 719.78,
                  with_refunds: 635.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 627.58,
                  with_refunds: 548.13,
                },
                gross_total: {
                  without_refunds: 719.78,
                  with_refunds: 635.78,
                },
                net_total_spread: {
                  without_refunds: 7418.22,
                  with_refunds: 7338.77,
                },
                gross_total_spread: {
                  without_refunds: 8428.31,
                  with_refunds: 8344.31,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0514,
                  with_refunds: 0.0467,
                },
                gross_mrr: {
                  without_refunds: 0.0504,
                  with_refunds: 0.0463,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0074,
                },
                gross_total: {
                  without_refunds: 0.0076,
                  with_refunds: 0.0076,
                },
                net_total_spread: {
                  without_refunds: 0.4323,
                  with_refunds: 0.4403,
                },
                gross_total_spread: {
                  without_refunds: 0.4243,
                  with_refunds: 0.4322,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.1656,
                  with_refunds: 0.1475,
                },
                gross_mrr: {
                  without_refunds: 0.1682,
                  with_refunds: 0.1514,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.1656,
                  with_refunds: 0.1475,
                },
                gross_total: {
                  without_refunds: 0.1682,
                  with_refunds: 0.1514,
                },
                net_total_spread: {
                  without_refunds: 0.7012,
                  with_refunds: 0.6984,
                },
                gross_total_spread: {
                  without_refunds: 0.703,
                  with_refunds: 0.7008,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.49,
                  with_refunds: 0.43,
                },
                gross_mrr: {
                  without_refunds: 0.57,
                  with_refunds: 0.5,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 0.27,
                  with_refunds: 0.24,
                },
                gross_total: {
                  without_refunds: 0.31,
                  with_refunds: 0.27,
                },
                net_total_spread: {
                  without_refunds: 3.61,
                  with_refunds: 3.57,
                },
                gross_total_spread: {
                  without_refunds: 4.1,
                  with_refunds: 4.06,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.07,
                  with_refunds: 11.19,
                },
                gross_mrr: {
                  without_refunds: 15,
                  with_refunds: 12.98,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 13.07,
                  with_refunds: 11.19,
                },
                gross_total: {
                  without_refunds: 15,
                  with_refunds: 12.98,
                },
                net_total_spread: {
                  without_refunds: 8.92,
                  with_refunds: 8.81,
                },
                gross_total_spread: {
                  without_refunds: 10.13,
                  with_refunds: 10.02,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.67,
                    with_refunds: 38.08,
                  },
                  gross_mrr: {
                    without_refunds: 45.4,
                    with_refunds: 43.67,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 65.82,
                    with_refunds: 65.82,
                  },
                  gross_arr_spread: {
                    without_refunds: 74.6,
                    with_refunds: 74.6,
                  },
                  net_total: {
                    without_refunds: 69.18,
                    with_refunds: 56.41,
                  },
                  gross_total: {
                    without_refunds: 78.24,
                    with_refunds: 64.27,
                  },
                  net_total_spread: {
                    without_refunds: 49.95,
                    with_refunds: 48.97,
                  },
                  gross_total_spread: {
                    without_refunds: 56.89,
                    with_refunds: 55.82,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 104.52,
                    with_refunds: 98.85,
                  },
                  gross_mrr: {
                    without_refunds: 119.23,
                    with_refunds: 113.06,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 69.21,
                    with_refunds: 69.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.44,
                    with_refunds: 78.44,
                  },
                  net_total: {
                    without_refunds: 175.05,
                    with_refunds: 145.15,
                  },
                  gross_total: {
                    without_refunds: 198.76,
                    with_refunds: 165.87,
                  },
                  net_total_spread: {
                    without_refunds: 82.04,
                    with_refunds: 80.4,
                  },
                  gross_total_spread: {
                    without_refunds: 93.38,
                    with_refunds: 91.58,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6162,
                    with_refunds: 0.5916,
                  },
                  gross_mrr: {
                    without_refunds: 0.7053,
                    with_refunds: 0.6784,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0225,
                    with_refunds: 1.0225,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.159,
                    with_refunds: 1.159,
                  },
                  net_total: {
                    without_refunds: 1.0748,
                    with_refunds: 0.8763,
                  },
                  gross_total: {
                    without_refunds: 1.2154,
                    with_refunds: 0.9984,
                  },
                  net_total_spread: {
                    without_refunds: 0.7761,
                    with_refunds: 0.7608,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8838,
                    with_refunds: 0.8672,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.6238,
                    with_refunds: 1.5357,
                  },
                  gross_mrr: {
                    without_refunds: 1.8523,
                    with_refunds: 1.7564,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0751,
                    with_refunds: 1.0751,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2185,
                    with_refunds: 1.2185,
                  },
                  net_total: {
                    without_refunds: 2.7194,
                    with_refunds: 2.2549,
                  },
                  gross_total: {
                    without_refunds: 3.0878,
                    with_refunds: 2.5768,
                  },
                  net_total_spread: {
                    without_refunds: 1.2745,
                    with_refunds: 1.249,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4506,
                    with_refunds: 1.4226,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 925,
              with_refunds: 925,
            },
            returning_users: {
              without_refunds: 7238,
              with_refunds: 7263,
            },
            paying_users: {
              without_refunds: 8163,
              with_refunds: 8188,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 687,
              with_refunds: 687,
            },
            returning_users: {
              without_refunds: 656,
              with_refunds: 700,
            },
            paying_users: {
              without_refunds: 1343,
              with_refunds: 1387,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1610,
              with_refunds: 1610,
            },
            returning_users: {
              without_refunds: 7891,
              with_refunds: 7960,
            },
            paying_users: {
              without_refunds: 9501,
              with_refunds: 9570,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 50496.8,
                with_refunds: 48480.16,
              },
              gross_mrr: {
                without_refunds: 57790.79,
                with_refunds: 55589.54,
              },
              net_arr: {
                without_refunds: 109731.42,
                with_refunds: 82158.67,
              },
              gross_arr: {
                without_refunds: 123407.2,
                with_refunds: 93254.8,
              },
              net_arr_spread: {
                without_refunds: 52260.29,
                with_refunds: 52260.29,
              },
              gross_arr_spread: {
                without_refunds: 59236.33,
                with_refunds: 59236.33,
              },
              net_total: {
                without_refunds: 160228.22,
                with_refunds: 130638.83,
              },
              gross_total: {
                without_refunds: 181198,
                with_refunds: 148844.34,
              },
              net_total_spread: {
                without_refunds: 102757.09,
                with_refunds: 100740.44,
              },
              gross_total_spread: {
                without_refunds: 117027.13,
                with_refunds: 114825.88,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 50496.8,
                with_refunds: 48480.16,
              },
              gross_mrr: {
                without_refunds: 57790.79,
                with_refunds: 55589.54,
              },
              net_arr: {
                without_refunds: 109731.42,
                with_refunds: 82158.67,
              },
              gross_arr: {
                without_refunds: 123407.2,
                with_refunds: 93254.8,
              },
              net_arr_spread: {
                without_refunds: 52260.29,
                with_refunds: 52260.29,
              },
              gross_arr_spread: {
                without_refunds: 59236.33,
                with_refunds: 59236.33,
              },
              net_total: {
                without_refunds: 160228.22,
                with_refunds: 130638.83,
              },
              gross_total: {
                without_refunds: 181198,
                with_refunds: 148844.34,
              },
              net_total_spread: {
                without_refunds: 102757.09,
                with_refunds: 100740.44,
              },
              gross_total_spread: {
                without_refunds: 117027.13,
                with_refunds: 114825.88,
              },
            },
          },
        },
      },
      cac: 64.37,
      mrr_ua_spend: {
        without_refunds: 81943.01,
        with_refunds: 81943.01,
      },
      arr_ua_spend: {
        without_refunds: 68425.31,
        with_refunds: 68425.31,
      },
      arr_spread_ua_spend: {
        without_refunds: 51109.78,
        with_refunds: 51109.78,
      },
      total_ua_spend: {
        without_refunds: 149080.92,
        with_refunds: 149080.92,
      },
      total_spread_ua_spend: {
        without_refunds: 132409.09,
        with_refunds: 132409.09,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 1,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 2355,
        with_refunds: 2355,
      },
      first_period_arr_users: {
        without_refunds: 1439,
        with_refunds: 1439,
      },
      first_period_arr_spread_users: {
        without_refunds: 1253,
        with_refunds: 1253,
      },
      first_period_total_users: {
        without_refunds: 3750,
        with_refunds: 3750,
      },
      first_period_total_spread_users: {
        without_refunds: 3572,
        with_refunds: 3572,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 893,
                  with_refunds: 893,
                },
                arr_users: {
                  without_refunds: 556,
                  with_refunds: 556,
                },
                arr_spread_users: {
                  without_refunds: 493,
                  with_refunds: 493,
                },
                total_users: {
                  without_refunds: 1449,
                  with_refunds: 1449,
                },
                total_spread_users: {
                  without_refunds: 1386,
                  with_refunds: 1386,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3792,
                  with_refunds: 0.3792,
                },
                arr_users: {
                  without_refunds: 0.3864,
                  with_refunds: 0.3864,
                },
                arr_spread_users: {
                  without_refunds: 0.3935,
                  with_refunds: 0.3935,
                },
                total_users: {
                  without_refunds: 0.3864,
                  with_refunds: 0.3864,
                },
                total_spread_users: {
                  without_refunds: 0.388,
                  with_refunds: 0.388,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 11571.86,
                  with_refunds: 11258.35,
                },
                gross_mrr: {
                  without_refunds: 13292.75,
                  with_refunds: 12914.93,
                },
                net_arr: {
                  without_refunds: 57049.47,
                  with_refunds: 52855.83,
                },
                gross_arr: {
                  without_refunds: 63790.51,
                  with_refunds: 59045.8,
                },
                net_arr_spread: {
                  without_refunds: 4210.1,
                  with_refunds: 4210.1,
                },
                gross_arr_spread: {
                  without_refunds: 4695.13,
                  with_refunds: 4695.13,
                },
                net_total: {
                  without_refunds: 68621.33,
                  with_refunds: 64114.18,
                },
                gross_total: {
                  without_refunds: 77083.26,
                  with_refunds: 71960.73,
                },
                net_total_spread: {
                  without_refunds: 15781.96,
                  with_refunds: 15468.45,
                },
                gross_total_spread: {
                  without_refunds: 17987.88,
                  with_refunds: 17610.06,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.91,
                  with_refunds: 4.78,
                },
                gross_mrr: {
                  without_refunds: 5.64,
                  with_refunds: 5.48,
                },
                net_arr: {
                  without_refunds: 39.65,
                  with_refunds: 36.73,
                },
                gross_arr: {
                  without_refunds: 44.33,
                  with_refunds: 41.03,
                },
                net_arr_spread: {
                  without_refunds: 3.36,
                  with_refunds: 3.36,
                },
                gross_arr_spread: {
                  without_refunds: 3.75,
                  with_refunds: 3.75,
                },
                net_total: {
                  without_refunds: 18.3,
                  with_refunds: 17.1,
                },
                gross_total: {
                  without_refunds: 20.56,
                  with_refunds: 19.19,
                },
                net_total_spread: {
                  without_refunds: 4.42,
                  with_refunds: 4.33,
                },
                gross_total_spread: {
                  without_refunds: 5.04,
                  with_refunds: 4.93,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.61,
                },
                gross_mrr: {
                  without_refunds: 14.89,
                  with_refunds: 14.46,
                },
                net_arr: {
                  without_refunds: 102.61,
                  with_refunds: 95.06,
                },
                gross_arr: {
                  without_refunds: 114.73,
                  with_refunds: 106.2,
                },
                net_arr_spread: {
                  without_refunds: 8.54,
                  with_refunds: 8.54,
                },
                gross_arr_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.52,
                },
                net_total: {
                  without_refunds: 47.36,
                  with_refunds: 44.25,
                },
                gross_total: {
                  without_refunds: 53.2,
                  with_refunds: 49.66,
                },
                net_total_spread: {
                  without_refunds: 11.39,
                  with_refunds: 11.16,
                },
                gross_total_spread: {
                  without_refunds: 12.98,
                  with_refunds: 12.71,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 4.91,
                    with_refunds: 4.78,
                  },
                  gross_mrr: {
                    without_refunds: 5.64,
                    with_refunds: 5.48,
                  },
                  net_arr: {
                    without_refunds: 39.65,
                    with_refunds: 36.73,
                  },
                  gross_arr: {
                    without_refunds: 44.33,
                    with_refunds: 41.03,
                  },
                  net_arr_spread: {
                    without_refunds: 3.36,
                    with_refunds: 3.36,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.75,
                    with_refunds: 3.75,
                  },
                  net_total: {
                    without_refunds: 18.3,
                    with_refunds: 17.1,
                  },
                  gross_total: {
                    without_refunds: 20.56,
                    with_refunds: 19.19,
                  },
                  net_total_spread: {
                    without_refunds: 4.42,
                    with_refunds: 4.33,
                  },
                  gross_total_spread: {
                    without_refunds: 5.04,
                    with_refunds: 4.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 12.96,
                    with_refunds: 12.61,
                  },
                  gross_mrr: {
                    without_refunds: 14.89,
                    with_refunds: 14.46,
                  },
                  net_arr: {
                    without_refunds: 102.61,
                    with_refunds: 95.06,
                  },
                  gross_arr: {
                    without_refunds: 114.73,
                    with_refunds: 106.2,
                  },
                  net_arr_spread: {
                    without_refunds: 8.54,
                    with_refunds: 8.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.52,
                    with_refunds: 9.52,
                  },
                  net_total: {
                    without_refunds: 47.36,
                    with_refunds: 44.25,
                  },
                  gross_total: {
                    without_refunds: 53.2,
                    with_refunds: 49.66,
                  },
                  net_total_spread: {
                    without_refunds: 11.39,
                    with_refunds: 11.16,
                  },
                  gross_total_spread: {
                    without_refunds: 12.98,
                    with_refunds: 12.71,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0876,
                    with_refunds: 0.0852,
                  },
                  gross_mrr: {
                    without_refunds: 0.1007,
                    with_refunds: 0.0978,
                  },
                  net_arr: {
                    without_refunds: 0.7069,
                    with_refunds: 0.655,
                  },
                  gross_arr: {
                    without_refunds: 0.7905,
                    with_refunds: 0.7317,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0599,
                    with_refunds: 0.0599,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0668,
                    with_refunds: 0.0668,
                  },
                  net_total: {
                    without_refunds: 0.3263,
                    with_refunds: 0.3049,
                  },
                  gross_total: {
                    without_refunds: 0.3665,
                    with_refunds: 0.3422,
                  },
                  net_total_spread: {
                    without_refunds: 0.0788,
                    with_refunds: 0.0772,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0898,
                    with_refunds: 0.0879,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2311,
                    with_refunds: 0.2248,
                  },
                  gross_mrr: {
                    without_refunds: 0.2654,
                    with_refunds: 0.2579,
                  },
                  net_arr: {
                    without_refunds: 1.8297,
                    with_refunds: 1.6952,
                  },
                  gross_arr: {
                    without_refunds: 2.0458,
                    with_refunds: 1.8937,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1523,
                    with_refunds: 0.1523,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1698,
                    with_refunds: 0.1698,
                  },
                  net_total: {
                    without_refunds: 0.8445,
                    with_refunds: 0.789,
                  },
                  gross_total: {
                    without_refunds: 0.9486,
                    with_refunds: 0.8856,
                  },
                  net_total_spread: {
                    without_refunds: 0.203,
                    with_refunds: 0.199,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2314,
                    with_refunds: 0.2266,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1991,
                  with_refunds: 2003,
                },
                arr_users: {
                  without_refunds: 821,
                  with_refunds: 843,
                },
                arr_spread_users: {
                  without_refunds: 1204,
                  with_refunds: 1204,
                },
                total_users: {
                  without_refunds: 2809,
                  with_refunds: 2842,
                },
                total_spread_users: {
                  without_refunds: 3194,
                  with_refunds: 3206,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.8454,
                  with_refunds: 0.8505,
                },
                arr_users: {
                  without_refunds: 0.5705,
                  with_refunds: 0.5858,
                },
                arr_spread_users: {
                  without_refunds: 0.9609,
                  with_refunds: 0.9609,
                },
                total_users: {
                  without_refunds: 0.7491,
                  with_refunds: 0.7579,
                },
                total_spread_users: {
                  without_refunds: 0.8942,
                  with_refunds: 0.8975,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 2.2296,
                  with_refunds: 2.243,
                },
                arr_users: {
                  without_refunds: 1.4766,
                  with_refunds: 1.5162,
                },
                arr_spread_users: {
                  without_refunds: 2.4422,
                  with_refunds: 2.4422,
                },
                total_users: {
                  without_refunds: 1.9386,
                  with_refunds: 1.9614,
                },
                total_spread_users: {
                  without_refunds: 2.3045,
                  with_refunds: 2.3131,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 25952.71,
                  with_refunds: 25187.89,
                },
                gross_mrr: {
                  without_refunds: 29672.17,
                  with_refunds: 28793.59,
                },
                net_arr: {
                  without_refunds: 83443.91,
                  with_refunds: 71789.84,
                },
                gross_arr: {
                  without_refunds: 92465.1,
                  with_refunds: 79445.1,
                },
                net_arr_spread: {
                  without_refunds: 10222.34,
                  with_refunds: 10222.34,
                },
                gross_arr_spread: {
                  without_refunds: 11363.73,
                  with_refunds: 11363.73,
                },
                net_total: {
                  without_refunds: 109396.62,
                  with_refunds: 96977.73,
                },
                gross_total: {
                  without_refunds: 122137.27,
                  with_refunds: 108238.69,
                },
                net_total_spread: {
                  without_refunds: 36175.05,
                  with_refunds: 35410.23,
                },
                gross_total_spread: {
                  without_refunds: 41035.9,
                  with_refunds: 40157.32,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 2.2427,
                  with_refunds: 2.2373,
                },
                gross_mrr: {
                  without_refunds: 2.2322,
                  with_refunds: 2.2295,
                },
                net_arr: {
                  without_refunds: 1.4627,
                  with_refunds: 1.3582,
                },
                gross_arr: {
                  without_refunds: 1.4495,
                  with_refunds: 1.3455,
                },
                net_arr_spread: {
                  without_refunds: 2.428,
                  with_refunds: 2.428,
                },
                gross_arr_spread: {
                  without_refunds: 2.4203,
                  with_refunds: 2.4203,
                },
                net_total: {
                  without_refunds: 1.5942,
                  with_refunds: 1.5126,
                },
                gross_total: {
                  without_refunds: 1.5845,
                  with_refunds: 1.5041,
                },
                net_total_spread: {
                  without_refunds: 2.2922,
                  with_refunds: 2.2892,
                },
                gross_total_spread: {
                  without_refunds: 2.2813,
                  with_refunds: 2.2804,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 2.2427,
                  with_refunds: 2.2373,
                },
                gross_mrr: {
                  without_refunds: 2.2322,
                  with_refunds: 2.2295,
                },
                net_arr: {
                  without_refunds: 1.4627,
                  with_refunds: 1.3582,
                },
                gross_arr: {
                  without_refunds: 1.4495,
                  with_refunds: 1.3455,
                },
                net_arr_spread: {
                  without_refunds: 2.428,
                  with_refunds: 2.428,
                },
                gross_arr_spread: {
                  without_refunds: 2.4203,
                  with_refunds: 2.4203,
                },
                net_total: {
                  without_refunds: 1.5942,
                  with_refunds: 1.5126,
                },
                gross_total: {
                  without_refunds: 1.5845,
                  with_refunds: 1.5041,
                },
                net_total_spread: {
                  without_refunds: 2.2922,
                  with_refunds: 2.2892,
                },
                gross_total_spread: {
                  without_refunds: 2.2813,
                  with_refunds: 2.2804,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 11.02,
                  with_refunds: 10.7,
                },
                gross_mrr: {
                  without_refunds: 12.6,
                  with_refunds: 12.23,
                },
                net_arr: {
                  without_refunds: 57.99,
                  with_refunds: 49.89,
                },
                gross_arr: {
                  without_refunds: 64.26,
                  with_refunds: 55.21,
                },
                net_arr_spread: {
                  without_refunds: 8.16,
                  with_refunds: 8.16,
                },
                gross_arr_spread: {
                  without_refunds: 9.07,
                  with_refunds: 9.07,
                },
                net_total: {
                  without_refunds: 29.17,
                  with_refunds: 25.86,
                },
                gross_total: {
                  without_refunds: 32.57,
                  with_refunds: 28.86,
                },
                net_total_spread: {
                  without_refunds: 10.13,
                  with_refunds: 9.91,
                },
                gross_total_spread: {
                  without_refunds: 11.49,
                  with_refunds: 11.24,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.04,
                  with_refunds: 12.58,
                },
                gross_mrr: {
                  without_refunds: 14.9,
                  with_refunds: 14.38,
                },
                net_arr: {
                  without_refunds: 101.64,
                  with_refunds: 85.16,
                },
                gross_arr: {
                  without_refunds: 112.62,
                  with_refunds: 94.24,
                },
                net_arr_spread: {
                  without_refunds: 8.49,
                  with_refunds: 8.49,
                },
                gross_arr_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.44,
                },
                net_total: {
                  without_refunds: 38.95,
                  with_refunds: 34.12,
                },
                gross_total: {
                  without_refunds: 43.48,
                  with_refunds: 38.09,
                },
                net_total_spread: {
                  without_refunds: 11.33,
                  with_refunds: 11.04,
                },
                gross_total_spread: {
                  without_refunds: 12.85,
                  with_refunds: 12.53,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.93,
                    with_refunds: 15.48,
                  },
                  gross_mrr: {
                    without_refunds: 18.24,
                    with_refunds: 17.71,
                  },
                  net_arr: {
                    without_refunds: 97.63,
                    with_refunds: 86.62,
                  },
                  gross_arr: {
                    without_refunds: 108.59,
                    with_refunds: 96.24,
                  },
                  net_arr_spread: {
                    without_refunds: 11.52,
                    with_refunds: 11.52,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.82,
                    with_refunds: 12.82,
                  },
                  net_total: {
                    without_refunds: 47.47,
                    with_refunds: 42.96,
                  },
                  gross_total: {
                    without_refunds: 53.13,
                    with_refunds: 48.05,
                  },
                  net_total_spread: {
                    without_refunds: 14.55,
                    with_refunds: 14.24,
                  },
                  gross_total_spread: {
                    without_refunds: 16.52,
                    with_refunds: 16.17,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.99,
                    with_refunds: 25.18,
                  },
                  gross_mrr: {
                    without_refunds: 29.79,
                    with_refunds: 28.84,
                  },
                  net_arr: {
                    without_refunds: 204.24,
                    with_refunds: 180.22,
                  },
                  gross_arr: {
                    without_refunds: 227.36,
                    with_refunds: 200.44,
                  },
                  net_arr_spread: {
                    without_refunds: 17.03,
                    with_refunds: 17.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 18.96,
                    with_refunds: 18.96,
                  },
                  net_total: {
                    without_refunds: 86.3,
                    with_refunds: 78.37,
                  },
                  gross_total: {
                    without_refunds: 96.68,
                    with_refunds: 87.75,
                  },
                  net_total_spread: {
                    without_refunds: 22.71,
                    with_refunds: 22.21,
                  },
                  gross_total_spread: {
                    without_refunds: 25.83,
                    with_refunds: 25.23,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2841,
                    with_refunds: 0.276,
                  },
                  gross_mrr: {
                    without_refunds: 0.3253,
                    with_refunds: 0.3158,
                  },
                  net_arr: {
                    without_refunds: 1.741,
                    with_refunds: 1.5446,
                  },
                  gross_arr: {
                    without_refunds: 1.9363,
                    with_refunds: 1.7161,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2054,
                    with_refunds: 0.2054,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2285,
                    with_refunds: 0.2285,
                  },
                  net_total: {
                    without_refunds: 0.8465,
                    with_refunds: 0.766,
                  },
                  gross_total: {
                    without_refunds: 0.9473,
                    with_refunds: 0.8569,
                  },
                  net_total_spread: {
                    without_refunds: 0.2594,
                    with_refunds: 0.254,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2947,
                    with_refunds: 0.2884,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4635,
                    with_refunds: 0.449,
                  },
                  gross_mrr: {
                    without_refunds: 0.5312,
                    with_refunds: 0.5142,
                  },
                  net_arr: {
                    without_refunds: 3.642,
                    with_refunds: 3.2137,
                  },
                  gross_arr: {
                    without_refunds: 4.0541,
                    with_refunds: 3.5742,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3037,
                    with_refunds: 0.3037,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3381,
                    with_refunds: 0.3381,
                  },
                  net_total: {
                    without_refunds: 1.5389,
                    with_refunds: 1.3975,
                  },
                  gross_total: {
                    without_refunds: 1.7239,
                    with_refunds: 1.5647,
                  },
                  net_total_spread: {
                    without_refunds: 0.405,
                    with_refunds: 0.396,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4605,
                    with_refunds: 0.4499,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1472,
                  with_refunds: 1481,
                },
                arr_users: {
                  without_refunds: 36,
                  with_refunds: 55,
                },
                arr_spread_users: {
                  without_refunds: 1224,
                  with_refunds: 1224,
                },
                total_users: {
                  without_refunds: 1508,
                  with_refunds: 1536,
                },
                total_spread_users: {
                  without_refunds: 2696,
                  with_refunds: 2705,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6251,
                  with_refunds: 0.6289,
                },
                arr_users: {
                  without_refunds: 0.025,
                  with_refunds: 0.0382,
                },
                arr_spread_users: {
                  without_refunds: 0.9769,
                  with_refunds: 0.9769,
                },
                total_users: {
                  without_refunds: 0.4021,
                  with_refunds: 0.4096,
                },
                total_spread_users: {
                  without_refunds: 0.7548,
                  with_refunds: 0.7573,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7393,
                  with_refunds: 0.7394,
                },
                arr_users: {
                  without_refunds: 0.0438,
                  with_refunds: 0.0652,
                },
                arr_spread_users: {
                  without_refunds: 1.0166,
                  with_refunds: 1.0166,
                },
                total_users: {
                  without_refunds: 0.5368,
                  with_refunds: 0.5405,
                },
                total_spread_users: {
                  without_refunds: 0.8441,
                  with_refunds: 0.8437,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 18917.63,
                  with_refunds: 18293.27,
                },
                gross_mrr: {
                  without_refunds: 21423.54,
                  with_refunds: 20750.05,
                },
                net_arr: {
                  without_refunds: 4191.31,
                  with_refunds: 1028.41,
                },
                gross_arr: {
                  without_refunds: 5383.33,
                  with_refunds: 1695.68,
                },
                net_arr_spread: {
                  without_refunds: 10416.82,
                  with_refunds: 10416.82,
                },
                gross_arr_spread: {
                  without_refunds: 11616.35,
                  with_refunds: 11616.35,
                },
                net_total: {
                  without_refunds: 23108.94,
                  with_refunds: 19321.68,
                },
                gross_total: {
                  without_refunds: 26806.87,
                  with_refunds: 22445.73,
                },
                net_total_spread: {
                  without_refunds: 29334.46,
                  with_refunds: 28710.1,
                },
                gross_total_spread: {
                  without_refunds: 33039.89,
                  with_refunds: 32366.4,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.6348,
                  with_refunds: 1.6249,
                },
                gross_mrr: {
                  without_refunds: 1.6117,
                  with_refunds: 1.6067,
                },
                net_arr: {
                  without_refunds: 0.0735,
                  with_refunds: 0.0195,
                },
                gross_arr: {
                  without_refunds: 0.0844,
                  with_refunds: 0.0287,
                },
                net_arr_spread: {
                  without_refunds: 2.4742,
                  with_refunds: 2.4742,
                },
                gross_arr_spread: {
                  without_refunds: 2.4741,
                  with_refunds: 2.4741,
                },
                net_total: {
                  without_refunds: 0.3368,
                  with_refunds: 0.3014,
                },
                gross_total: {
                  without_refunds: 0.3478,
                  with_refunds: 0.3119,
                },
                net_total_spread: {
                  without_refunds: 1.8587,
                  with_refunds: 1.856,
                },
                gross_total_spread: {
                  without_refunds: 1.8368,
                  with_refunds: 1.8379,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7289,
                  with_refunds: 0.7263,
                },
                gross_mrr: {
                  without_refunds: 0.722,
                  with_refunds: 0.7206,
                },
                net_arr: {
                  without_refunds: 0.0502,
                  with_refunds: 0.0143,
                },
                gross_arr: {
                  without_refunds: 0.0582,
                  with_refunds: 0.0213,
                },
                net_arr_spread: {
                  without_refunds: 1.019,
                  with_refunds: 1.019,
                },
                gross_arr_spread: {
                  without_refunds: 1.0222,
                  with_refunds: 1.0222,
                },
                net_total: {
                  without_refunds: 0.2112,
                  with_refunds: 0.1992,
                },
                gross_total: {
                  without_refunds: 0.2195,
                  with_refunds: 0.2074,
                },
                net_total_spread: {
                  without_refunds: 0.8109,
                  with_refunds: 0.8108,
                },
                gross_total_spread: {
                  without_refunds: 0.8051,
                  with_refunds: 0.806,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.03,
                  with_refunds: 7.77,
                },
                gross_mrr: {
                  without_refunds: 9.1,
                  with_refunds: 8.81,
                },
                net_arr: {
                  without_refunds: 2.91,
                  with_refunds: 0.71,
                },
                gross_arr: {
                  without_refunds: 3.74,
                  with_refunds: 1.18,
                },
                net_arr_spread: {
                  without_refunds: 8.31,
                  with_refunds: 8.31,
                },
                gross_arr_spread: {
                  without_refunds: 9.27,
                  with_refunds: 9.27,
                },
                net_total: {
                  without_refunds: 6.16,
                  with_refunds: 5.15,
                },
                gross_total: {
                  without_refunds: 7.15,
                  with_refunds: 5.99,
                },
                net_total_spread: {
                  without_refunds: 8.21,
                  with_refunds: 8.04,
                },
                gross_total_spread: {
                  without_refunds: 9.25,
                  with_refunds: 9.06,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.85,
                  with_refunds: 12.35,
                },
                gross_mrr: {
                  without_refunds: 14.55,
                  with_refunds: 14.01,
                },
                net_arr: {
                  without_refunds: 116.43,
                  with_refunds: 18.7,
                },
                gross_arr: {
                  without_refunds: 149.54,
                  with_refunds: 30.83,
                },
                net_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                gross_arr_spread: {
                  without_refunds: 9.49,
                  with_refunds: 9.49,
                },
                net_total: {
                  without_refunds: 15.32,
                  with_refunds: 12.58,
                },
                gross_total: {
                  without_refunds: 17.78,
                  with_refunds: 14.61,
                },
                net_total_spread: {
                  without_refunds: 10.88,
                  with_refunds: 10.61,
                },
                gross_total_spread: {
                  without_refunds: 12.26,
                  with_refunds: 11.97,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.97,
                    with_refunds: 23.24,
                  },
                  gross_mrr: {
                    without_refunds: 27.34,
                    with_refunds: 26.52,
                  },
                  net_arr: {
                    without_refunds: 100.55,
                    with_refunds: 87.33,
                  },
                  gross_arr: {
                    without_refunds: 112.33,
                    with_refunds: 97.42,
                  },
                  net_arr_spread: {
                    without_refunds: 19.83,
                    with_refunds: 19.83,
                  },
                  gross_arr_spread: {
                    without_refunds: 22.09,
                    with_refunds: 22.09,
                  },
                  net_total: {
                    without_refunds: 53.63,
                    with_refunds: 48.11,
                  },
                  gross_total: {
                    without_refunds: 60.27,
                    with_refunds: 54.04,
                  },
                  net_total_spread: {
                    without_refunds: 22.76,
                    with_refunds: 22.28,
                  },
                  gross_total_spread: {
                    without_refunds: 25.77,
                    with_refunds: 25.23,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.85,
                    with_refunds: 37.53,
                  },
                  gross_mrr: {
                    without_refunds: 44.34,
                    with_refunds: 42.85,
                  },
                  net_arr: {
                    without_refunds: 320.67,
                    with_refunds: 198.92,
                  },
                  gross_arr: {
                    without_refunds: 376.89,
                    with_refunds: 231.27,
                  },
                  net_arr_spread: {
                    without_refunds: 25.54,
                    with_refunds: 25.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 28.45,
                    with_refunds: 28.45,
                  },
                  net_total: {
                    without_refunds: 101.63,
                    with_refunds: 90.95,
                  },
                  gross_total: {
                    without_refunds: 114.45,
                    with_refunds: 102.36,
                  },
                  net_total_spread: {
                    without_refunds: 33.59,
                    with_refunds: 32.82,
                  },
                  gross_total_spread: {
                    without_refunds: 38.08,
                    with_refunds: 37.2,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4274,
                    with_refunds: 0.4145,
                  },
                  gross_mrr: {
                    without_refunds: 0.4875,
                    with_refunds: 0.4729,
                  },
                  net_arr: {
                    without_refunds: 1.7929,
                    with_refunds: 1.5573,
                  },
                  gross_arr: {
                    without_refunds: 2.003,
                    with_refunds: 1.7372,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3536,
                    with_refunds: 0.3536,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3939,
                    with_refunds: 0.3939,
                  },
                  net_total: {
                    without_refunds: 0.9564,
                    with_refunds: 0.8579,
                  },
                  gross_total: {
                    without_refunds: 1.0748,
                    with_refunds: 0.9636,
                  },
                  net_total_spread: {
                    without_refunds: 0.4058,
                    with_refunds: 0.3973,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4596,
                    with_refunds: 0.45,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6927,
                    with_refunds: 0.6693,
                  },
                  gross_mrr: {
                    without_refunds: 0.7907,
                    with_refunds: 0.7641,
                  },
                  net_arr: {
                    without_refunds: 5.7181,
                    with_refunds: 3.5471,
                  },
                  gross_arr: {
                    without_refunds: 6.7206,
                    with_refunds: 4.1239,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4554,
                    with_refunds: 0.4554,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5074,
                    with_refunds: 0.5074,
                  },
                  net_total: {
                    without_refunds: 1.8122,
                    with_refunds: 1.6218,
                  },
                  gross_total: {
                    without_refunds: 2.0409,
                    with_refunds: 1.8253,
                  },
                  net_total_spread: {
                    without_refunds: 0.599,
                    with_refunds: 0.5852,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6791,
                    with_refunds: 0.6633,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1082,
                  with_refunds: 1086,
                },
                arr_users: {
                  without_refunds: 15,
                  with_refunds: 20,
                },
                arr_spread_users: {
                  without_refunds: 1237,
                  with_refunds: 1237,
                },
                total_users: {
                  without_refunds: 1097,
                  with_refunds: 1106,
                },
                total_spread_users: {
                  without_refunds: 2319,
                  with_refunds: 2323,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4594,
                  with_refunds: 0.4611,
                },
                arr_users: {
                  without_refunds: 0.0104,
                  with_refunds: 0.0139,
                },
                arr_spread_users: {
                  without_refunds: 0.9872,
                  with_refunds: 0.9872,
                },
                total_users: {
                  without_refunds: 0.2925,
                  with_refunds: 0.2949,
                },
                total_spread_users: {
                  without_refunds: 0.6492,
                  with_refunds: 0.6503,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7351,
                  with_refunds: 0.7333,
                },
                arr_users: {
                  without_refunds: 0.4167,
                  with_refunds: 0.3636,
                },
                arr_spread_users: {
                  without_refunds: 1.0106,
                  with_refunds: 1.0106,
                },
                total_users: {
                  without_refunds: 0.7275,
                  with_refunds: 0.7201,
                },
                total_spread_users: {
                  without_refunds: 0.8602,
                  with_refunds: 0.8588,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13674.48,
                  with_refunds: 13349.95,
                },
                gross_mrr: {
                  without_refunds: 15427.86,
                  with_refunds: 15065.79,
                },
                net_arr: {
                  without_refunds: 1751.86,
                  with_refunds: 965.54,
                },
                gross_arr: {
                  without_refunds: 2181.81,
                  with_refunds: 1219.81,
                },
                net_arr_spread: {
                  without_refunds: 10543.45,
                  with_refunds: 10543.45,
                },
                gross_arr_spread: {
                  without_refunds: 11773.16,
                  with_refunds: 11773.16,
                },
                net_total: {
                  without_refunds: 15426.34,
                  with_refunds: 14315.49,
                },
                gross_total: {
                  without_refunds: 17609.67,
                  with_refunds: 16285.61,
                },
                net_total_spread: {
                  without_refunds: 24217.93,
                  with_refunds: 23893.4,
                },
                gross_total_spread: {
                  without_refunds: 27201.03,
                  with_refunds: 26838.96,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.1817,
                  with_refunds: 1.1858,
                },
                gross_mrr: {
                  without_refunds: 1.1606,
                  with_refunds: 1.1665,
                },
                net_arr: {
                  without_refunds: 0.0307,
                  with_refunds: 0.0183,
                },
                gross_arr: {
                  without_refunds: 0.0342,
                  with_refunds: 0.0207,
                },
                net_arr_spread: {
                  without_refunds: 2.5043,
                  with_refunds: 2.5043,
                },
                gross_arr_spread: {
                  without_refunds: 2.5075,
                  with_refunds: 2.5075,
                },
                net_total: {
                  without_refunds: 0.2248,
                  with_refunds: 0.2233,
                },
                gross_total: {
                  without_refunds: 0.2285,
                  with_refunds: 0.2263,
                },
                net_total_spread: {
                  without_refunds: 1.5345,
                  with_refunds: 1.5447,
                },
                gross_total_spread: {
                  without_refunds: 1.5122,
                  with_refunds: 1.5241,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7228,
                  with_refunds: 0.7298,
                },
                gross_mrr: {
                  without_refunds: 0.7201,
                  with_refunds: 0.7261,
                },
                net_arr: {
                  without_refunds: 0.418,
                  with_refunds: 0.9389,
                },
                gross_arr: {
                  without_refunds: 0.4053,
                  with_refunds: 0.7194,
                },
                net_arr_spread: {
                  without_refunds: 1.0122,
                  with_refunds: 1.0122,
                },
                gross_arr_spread: {
                  without_refunds: 1.0135,
                  with_refunds: 1.0135,
                },
                net_total: {
                  without_refunds: 0.6675,
                  with_refunds: 0.7409,
                },
                gross_total: {
                  without_refunds: 0.6569,
                  with_refunds: 0.7256,
                },
                net_total_spread: {
                  without_refunds: 0.8256,
                  with_refunds: 0.8322,
                },
                gross_total_spread: {
                  without_refunds: 0.8233,
                  with_refunds: 0.8292,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.81,
                  with_refunds: 5.67,
                },
                gross_mrr: {
                  without_refunds: 6.55,
                  with_refunds: 6.4,
                },
                net_arr: {
                  without_refunds: 1.22,
                  with_refunds: 0.67,
                },
                gross_arr: {
                  without_refunds: 1.52,
                  with_refunds: 0.85,
                },
                net_arr_spread: {
                  without_refunds: 8.41,
                  with_refunds: 8.41,
                },
                gross_arr_spread: {
                  without_refunds: 9.4,
                  with_refunds: 9.4,
                },
                net_total: {
                  without_refunds: 4.11,
                  with_refunds: 3.82,
                },
                gross_total: {
                  without_refunds: 4.7,
                  with_refunds: 4.34,
                },
                net_total_spread: {
                  without_refunds: 6.78,
                  with_refunds: 6.69,
                },
                gross_total_spread: {
                  without_refunds: 7.62,
                  with_refunds: 7.51,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.64,
                  with_refunds: 12.29,
                },
                gross_mrr: {
                  without_refunds: 14.26,
                  with_refunds: 13.87,
                },
                net_arr: {
                  without_refunds: 116.79,
                  with_refunds: 48.28,
                },
                gross_arr: {
                  without_refunds: 145.45,
                  with_refunds: 60.99,
                },
                net_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                gross_arr_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.52,
                },
                net_total: {
                  without_refunds: 14.06,
                  with_refunds: 12.94,
                },
                gross_total: {
                  without_refunds: 16.05,
                  with_refunds: 14.72,
                },
                net_total_spread: {
                  without_refunds: 10.44,
                  with_refunds: 10.29,
                },
                gross_total_spread: {
                  without_refunds: 11.73,
                  with_refunds: 11.55,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 29.77,
                    with_refunds: 28.91,
                  },
                  gross_mrr: {
                    without_refunds: 33.89,
                    with_refunds: 32.92,
                  },
                  net_arr: {
                    without_refunds: 101.76,
                    with_refunds: 88.01,
                  },
                  gross_arr: {
                    without_refunds: 113.84,
                    with_refunds: 98.27,
                  },
                  net_arr_spread: {
                    without_refunds: 28.25,
                    with_refunds: 28.25,
                  },
                  gross_arr_spread: {
                    without_refunds: 31.48,
                    with_refunds: 31.48,
                  },
                  net_total: {
                    without_refunds: 57.75,
                    with_refunds: 51.93,
                  },
                  gross_total: {
                    without_refunds: 64.97,
                    with_refunds: 58.38,
                  },
                  net_total_spread: {
                    without_refunds: 29.54,
                    with_refunds: 28.97,
                  },
                  gross_total_spread: {
                    without_refunds: 33.39,
                    with_refunds: 32.75,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 51.48,
                    with_refunds: 49.83,
                  },
                  gross_mrr: {
                    without_refunds: 58.6,
                    with_refunds: 56.72,
                  },
                  net_arr: {
                    without_refunds: 437.46,
                    with_refunds: 247.2,
                  },
                  gross_arr: {
                    without_refunds: 522.35,
                    with_refunds: 292.26,
                  },
                  net_arr_spread: {
                    without_refunds: 34.06,
                    with_refunds: 34.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 37.97,
                    with_refunds: 37.97,
                  },
                  net_total: {
                    without_refunds: 115.69,
                    with_refunds: 103.89,
                  },
                  gross_total: {
                    without_refunds: 130.51,
                    with_refunds: 117.09,
                  },
                  net_total_spread: {
                    without_refunds: 44.04,
                    with_refunds: 43.1,
                  },
                  gross_total_spread: {
                    without_refunds: 49.81,
                    with_refunds: 48.75,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5309,
                    with_refunds: 0.5156,
                  },
                  gross_mrr: {
                    without_refunds: 0.6044,
                    with_refunds: 0.587,
                  },
                  net_arr: {
                    without_refunds: 1.8146,
                    with_refunds: 1.5693,
                  },
                  gross_arr: {
                    without_refunds: 2.03,
                    with_refunds: 1.7523,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5037,
                    with_refunds: 0.5037,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5614,
                    with_refunds: 0.5614,
                  },
                  net_total: {
                    without_refunds: 1.0297,
                    with_refunds: 0.926,
                  },
                  gross_total: {
                    without_refunds: 1.1585,
                    with_refunds: 1.041,
                  },
                  net_total_spread: {
                    without_refunds: 0.5267,
                    with_refunds: 0.5166,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5954,
                    with_refunds: 0.5839,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.918,
                    with_refunds: 0.8885,
                  },
                  gross_mrr: {
                    without_refunds: 1.045,
                    with_refunds: 1.0114,
                  },
                  net_arr: {
                    without_refunds: 7.8006,
                    with_refunds: 4.408,
                  },
                  gross_arr: {
                    without_refunds: 9.3143,
                    with_refunds: 5.2115,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6074,
                    with_refunds: 0.6074,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6771,
                    with_refunds: 0.6771,
                  },
                  net_total: {
                    without_refunds: 2.0629,
                    with_refunds: 1.8526,
                  },
                  gross_total: {
                    without_refunds: 2.3272,
                    with_refunds: 2.0878,
                  },
                  net_total_spread: {
                    without_refunds: 0.7852,
                    with_refunds: 0.7686,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8882,
                    with_refunds: 0.8693,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 868,
                  with_refunds: 872,
                },
                arr_users: {
                  without_refunds: 11,
                  with_refunds: 11,
                },
                arr_spread_users: {
                  without_refunds: 1248,
                  with_refunds: 1248,
                },
                total_users: {
                  without_refunds: 879,
                  with_refunds: 883,
                },
                total_spread_users: {
                  without_refunds: 2115,
                  with_refunds: 2119,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3686,
                  with_refunds: 0.3703,
                },
                arr_users: {
                  without_refunds: 0.0076,
                  with_refunds: 0.0076,
                },
                arr_spread_users: {
                  without_refunds: 0.996,
                  with_refunds: 0.996,
                },
                total_users: {
                  without_refunds: 0.2344,
                  with_refunds: 0.2355,
                },
                total_spread_users: {
                  without_refunds: 0.5921,
                  with_refunds: 0.5932,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8022,
                  with_refunds: 0.8029,
                },
                arr_users: {
                  without_refunds: 0.7333,
                  with_refunds: 0.55,
                },
                arr_spread_users: {
                  without_refunds: 1.0089,
                  with_refunds: 1.0089,
                },
                total_users: {
                  without_refunds: 0.8013,
                  with_refunds: 0.7984,
                },
                total_spread_users: {
                  without_refunds: 0.912,
                  with_refunds: 0.9122,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 11120.22,
                  with_refunds: 10917.32,
                },
                gross_mrr: {
                  without_refunds: 12549.58,
                  with_refunds: 12309.54,
                },
                net_arr: {
                  without_refunds: 1266.55,
                  with_refunds: 1266.55,
                },
                gross_arr: {
                  without_refunds: 1596.22,
                  with_refunds: 1596.22,
                },
                net_arr_spread: {
                  without_refunds: 10649,
                  with_refunds: 10649,
                },
                gross_arr_spread: {
                  without_refunds: 11906.18,
                  with_refunds: 11906.18,
                },
                net_total: {
                  without_refunds: 12386.78,
                  with_refunds: 12183.88,
                },
                gross_total: {
                  without_refunds: 14145.8,
                  with_refunds: 13905.76,
                },
                net_total_spread: {
                  without_refunds: 21769.22,
                  with_refunds: 21566.32,
                },
                gross_total_spread: {
                  without_refunds: 24455.76,
                  with_refunds: 24215.73,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.961,
                  with_refunds: 0.9697,
                },
                gross_mrr: {
                  without_refunds: 0.9441,
                  with_refunds: 0.9531,
                },
                net_arr: {
                  without_refunds: 0.0222,
                  with_refunds: 0.024,
                },
                gross_arr: {
                  without_refunds: 0.025,
                  with_refunds: 0.027,
                },
                net_arr_spread: {
                  without_refunds: 2.5294,
                  with_refunds: 2.5294,
                },
                gross_arr_spread: {
                  without_refunds: 2.5359,
                  with_refunds: 2.5359,
                },
                net_total: {
                  without_refunds: 0.1805,
                  with_refunds: 0.19,
                },
                gross_total: {
                  without_refunds: 0.1835,
                  with_refunds: 0.1932,
                },
                net_total_spread: {
                  without_refunds: 1.3794,
                  with_refunds: 1.3942,
                },
                gross_total_spread: {
                  without_refunds: 1.3596,
                  with_refunds: 1.3751,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8132,
                  with_refunds: 0.8178,
                },
                gross_mrr: {
                  without_refunds: 0.8134,
                  with_refunds: 0.8171,
                },
                net_arr: {
                  without_refunds: 0.723,
                  with_refunds: 1.3118,
                },
                gross_arr: {
                  without_refunds: 0.7316,
                  with_refunds: 1.3086,
                },
                net_arr_spread: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                gross_arr_spread: {
                  without_refunds: 1.0113,
                  with_refunds: 1.0113,
                },
                net_total: {
                  without_refunds: 0.803,
                  with_refunds: 0.8511,
                },
                gross_total: {
                  without_refunds: 0.8033,
                  with_refunds: 0.8539,
                },
                net_total_spread: {
                  without_refunds: 0.8989,
                  with_refunds: 0.9026,
                },
                gross_total_spread: {
                  without_refunds: 0.8991,
                  with_refunds: 0.9023,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.72,
                  with_refunds: 4.64,
                },
                gross_mrr: {
                  without_refunds: 5.33,
                  with_refunds: 5.23,
                },
                net_arr: {
                  without_refunds: 0.88,
                  with_refunds: 0.88,
                },
                gross_arr: {
                  without_refunds: 1.11,
                  with_refunds: 1.11,
                },
                net_arr_spread: {
                  without_refunds: 8.5,
                  with_refunds: 8.5,
                },
                gross_arr_spread: {
                  without_refunds: 9.5,
                  with_refunds: 9.5,
                },
                net_total: {
                  without_refunds: 3.3,
                  with_refunds: 3.25,
                },
                gross_total: {
                  without_refunds: 3.77,
                  with_refunds: 3.71,
                },
                net_total_spread: {
                  without_refunds: 6.09,
                  with_refunds: 6.04,
                },
                gross_total_spread: {
                  without_refunds: 6.85,
                  with_refunds: 6.78,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.81,
                  with_refunds: 12.52,
                },
                gross_mrr: {
                  without_refunds: 14.46,
                  with_refunds: 14.12,
                },
                net_arr: {
                  without_refunds: 115.14,
                  with_refunds: 115.14,
                },
                gross_arr: {
                  without_refunds: 145.11,
                  with_refunds: 145.11,
                },
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 14.09,
                  with_refunds: 13.8,
                },
                gross_total: {
                  without_refunds: 16.09,
                  with_refunds: 15.75,
                },
                net_total_spread: {
                  without_refunds: 10.29,
                  with_refunds: 10.18,
                },
                gross_total_spread: {
                  without_refunds: 11.56,
                  with_refunds: 11.43,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.5,
                    with_refunds: 33.55,
                  },
                  gross_mrr: {
                    without_refunds: 39.22,
                    with_refunds: 38.15,
                  },
                  net_arr: {
                    without_refunds: 102.64,
                    with_refunds: 88.89,
                  },
                  gross_arr: {
                    without_refunds: 114.95,
                    with_refunds: 99.38,
                  },
                  net_arr_spread: {
                    without_refunds: 36.75,
                    with_refunds: 36.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 40.99,
                    with_refunds: 40.99,
                  },
                  net_total: {
                    without_refunds: 61.05,
                    with_refunds: 55.18,
                  },
                  gross_total: {
                    without_refunds: 68.74,
                    with_refunds: 62.09,
                  },
                  net_total_spread: {
                    without_refunds: 35.63,
                    with_refunds: 35.01,
                  },
                  gross_total_spread: {
                    without_refunds: 40.24,
                    with_refunds: 39.53,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 64.29,
                    with_refunds: 62.35,
                  },
                  gross_mrr: {
                    without_refunds: 73.06,
                    with_refunds: 70.84,
                  },
                  net_arr: {
                    without_refunds: 552.6,
                    with_refunds: 362.34,
                  },
                  gross_arr: {
                    without_refunds: 667.46,
                    with_refunds: 437.37,
                  },
                  net_arr_spread: {
                    without_refunds: 42.6,
                    with_refunds: 42.6,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.51,
                    with_refunds: 47.51,
                  },
                  net_total: {
                    without_refunds: 129.78,
                    with_refunds: 117.69,
                  },
                  gross_total: {
                    without_refunds: 146.6,
                    with_refunds: 132.83,
                  },
                  net_total_spread: {
                    without_refunds: 54.33,
                    with_refunds: 53.28,
                  },
                  gross_total_spread: {
                    without_refunds: 61.37,
                    with_refunds: 60.18,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6151,
                    with_refunds: 0.5982,
                  },
                  gross_mrr: {
                    without_refunds: 0.6994,
                    with_refunds: 0.6802,
                  },
                  net_arr: {
                    without_refunds: 1.8303,
                    with_refunds: 1.585,
                  },
                  gross_arr: {
                    without_refunds: 2.0498,
                    with_refunds: 1.772,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6552,
                    with_refunds: 0.6552,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7308,
                    with_refunds: 0.7308,
                  },
                  net_total: {
                    without_refunds: 1.0886,
                    with_refunds: 0.9839,
                  },
                  gross_total: {
                    without_refunds: 1.2258,
                    with_refunds: 1.1072,
                  },
                  net_total_spread: {
                    without_refunds: 0.6354,
                    with_refunds: 0.6243,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7175,
                    with_refunds: 0.7048,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1465,
                    with_refunds: 1.1118,
                  },
                  gross_mrr: {
                    without_refunds: 1.3028,
                    with_refunds: 1.2632,
                  },
                  net_arr: {
                    without_refunds: 9.8538,
                    with_refunds: 6.4611,
                  },
                  gross_arr: {
                    without_refunds: 11.9019,
                    with_refunds: 7.799,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7596,
                    with_refunds: 0.7596,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8472,
                    with_refunds: 0.8472,
                  },
                  net_total: {
                    without_refunds: 2.3142,
                    with_refunds: 2.0986,
                  },
                  gross_total: {
                    without_refunds: 2.6141,
                    with_refunds: 2.3687,
                  },
                  net_total_spread: {
                    without_refunds: 0.9688,
                    with_refunds: 0.9501,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0944,
                    with_refunds: 1.0731,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 743,
                  with_refunds: 744,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 1249,
                  with_refunds: 1249,
                },
                total_users: {
                  without_refunds: 745,
                  with_refunds: 746,
                },
                total_spread_users: {
                  without_refunds: 1991,
                  with_refunds: 1992,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3155,
                  with_refunds: 0.3159,
                },
                arr_users: {
                  without_refunds: 0.0014,
                  with_refunds: 0.0014,
                },
                arr_spread_users: {
                  without_refunds: 0.9968,
                  with_refunds: 0.9968,
                },
                total_users: {
                  without_refunds: 0.1987,
                  with_refunds: 0.1989,
                },
                total_spread_users: {
                  without_refunds: 0.5574,
                  with_refunds: 0.5577,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.856,
                  with_refunds: 0.8532,
                },
                arr_users: {
                  without_refunds: 0.1818,
                  with_refunds: 0.1818,
                },
                arr_spread_users: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                total_users: {
                  without_refunds: 0.8476,
                  with_refunds: 0.8448,
                },
                total_spread_users: {
                  without_refunds: 0.9414,
                  with_refunds: 0.9401,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9412.25,
                  with_refunds: 9339.78,
                },
                gross_mrr: {
                  without_refunds: 10547.8,
                  with_refunds: 10461.68,
                },
                net_arr: {
                  without_refunds: 227.31,
                  with_refunds: 227.31,
                },
                gross_arr: {
                  without_refunds: 249.99,
                  with_refunds: 249.99,
                },
                net_arr_spread: {
                  without_refunds: 10658.68,
                  with_refunds: 10658.68,
                },
                gross_arr_spread: {
                  without_refunds: 11916.12,
                  with_refunds: 11916.12,
                },
                net_total: {
                  without_refunds: 9639.56,
                  with_refunds: 9567.09,
                },
                gross_total: {
                  without_refunds: 10797.79,
                  with_refunds: 10711.67,
                },
                net_total_spread: {
                  without_refunds: 20070.93,
                  with_refunds: 19998.46,
                },
                gross_total_spread: {
                  without_refunds: 22463.92,
                  with_refunds: 22377.8,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8134,
                  with_refunds: 0.8296,
                },
                gross_mrr: {
                  without_refunds: 0.7935,
                  with_refunds: 0.81,
                },
                net_arr: {
                  without_refunds: 0.004,
                  with_refunds: 0.0043,
                },
                gross_arr: {
                  without_refunds: 0.0039,
                  with_refunds: 0.0042,
                },
                net_arr_spread: {
                  without_refunds: 2.5317,
                  with_refunds: 2.5317,
                },
                gross_arr_spread: {
                  without_refunds: 2.538,
                  with_refunds: 2.538,
                },
                net_total: {
                  without_refunds: 0.1405,
                  with_refunds: 0.1492,
                },
                gross_total: {
                  without_refunds: 0.1401,
                  with_refunds: 0.1489,
                },
                net_total_spread: {
                  without_refunds: 1.2718,
                  with_refunds: 1.2929,
                },
                gross_total_spread: {
                  without_refunds: 1.2488,
                  with_refunds: 1.2707,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8464,
                  with_refunds: 0.8555,
                },
                gross_mrr: {
                  without_refunds: 0.8405,
                  with_refunds: 0.8499,
                },
                net_arr: {
                  without_refunds: 0.1795,
                  with_refunds: 0.1795,
                },
                gross_arr: {
                  without_refunds: 0.1566,
                  with_refunds: 0.1566,
                },
                net_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                gross_arr_spread: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                net_total: {
                  without_refunds: 0.7782,
                  with_refunds: 0.7852,
                },
                gross_total: {
                  without_refunds: 0.7633,
                  with_refunds: 0.7703,
                },
                net_total_spread: {
                  without_refunds: 0.922,
                  with_refunds: 0.9273,
                },
                gross_total_spread: {
                  without_refunds: 0.9186,
                  with_refunds: 0.9241,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4,
                  with_refunds: 3.97,
                },
                gross_mrr: {
                  without_refunds: 4.48,
                  with_refunds: 4.44,
                },
                net_arr: {
                  without_refunds: 0.16,
                  with_refunds: 0.16,
                },
                gross_arr: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                net_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                gross_arr_spread: {
                  without_refunds: 9.51,
                  with_refunds: 9.51,
                },
                net_total: {
                  without_refunds: 2.57,
                  with_refunds: 2.55,
                },
                gross_total: {
                  without_refunds: 2.88,
                  with_refunds: 2.86,
                },
                net_total_spread: {
                  without_refunds: 5.62,
                  with_refunds: 5.6,
                },
                gross_total_spread: {
                  without_refunds: 6.29,
                  with_refunds: 6.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.67,
                  with_refunds: 12.55,
                },
                gross_mrr: {
                  without_refunds: 14.2,
                  with_refunds: 14.06,
                },
                net_arr: {
                  without_refunds: 113.66,
                  with_refunds: 113.66,
                },
                gross_arr: {
                  without_refunds: 125,
                  with_refunds: 125,
                },
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 12.94,
                  with_refunds: 12.82,
                },
                gross_total: {
                  without_refunds: 14.49,
                  with_refunds: 14.36,
                },
                net_total_spread: {
                  without_refunds: 10.08,
                  with_refunds: 10.04,
                },
                gross_total_spread: {
                  without_refunds: 11.28,
                  with_refunds: 11.23,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.49,
                    with_refunds: 37.51,
                  },
                  gross_mrr: {
                    without_refunds: 43.7,
                    with_refunds: 42.59,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 45.25,
                    with_refunds: 45.25,
                  },
                  gross_arr_spread: {
                    without_refunds: 50.5,
                    with_refunds: 50.5,
                  },
                  net_total: {
                    without_refunds: 63.62,
                    with_refunds: 57.73,
                  },
                  gross_total: {
                    without_refunds: 71.62,
                    with_refunds: 64.95,
                  },
                  net_total_spread: {
                    without_refunds: 41.25,
                    with_refunds: 40.61,
                  },
                  gross_total_spread: {
                    without_refunds: 46.52,
                    with_refunds: 45.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 76.96,
                    with_refunds: 74.9,
                  },
                  gross_mrr: {
                    without_refunds: 87.26,
                    with_refunds: 84.9,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 51.13,
                    with_refunds: 51.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 57.05,
                    with_refunds: 57.05,
                  },
                  net_total: {
                    without_refunds: 142.72,
                    with_refunds: 130.52,
                  },
                  gross_total: {
                    without_refunds: 161.09,
                    with_refunds: 147.19,
                  },
                  net_total_spread: {
                    without_refunds: 64.41,
                    with_refunds: 63.32,
                  },
                  gross_total_spread: {
                    without_refunds: 72.66,
                    with_refunds: 71.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6864,
                    with_refunds: 0.6689,
                  },
                  gross_mrr: {
                    without_refunds: 0.7792,
                    with_refunds: 0.7594,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8069,
                    with_refunds: 0.8069,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9004,
                    with_refunds: 0.9004,
                  },
                  net_total: {
                    without_refunds: 1.1345,
                    with_refunds: 1.0294,
                  },
                  gross_total: {
                    without_refunds: 1.2771,
                    with_refunds: 1.1581,
                  },
                  net_total_spread: {
                    without_refunds: 0.7356,
                    with_refunds: 0.7241,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8296,
                    with_refunds: 0.8165,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3724,
                    with_refunds: 1.3356,
                  },
                  gross_mrr: {
                    without_refunds: 1.5559,
                    with_refunds: 1.5139,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9117,
                    with_refunds: 0.9117,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0173,
                    with_refunds: 1.0173,
                  },
                  net_total: {
                    without_refunds: 2.5449,
                    with_refunds: 2.3273,
                  },
                  gross_total: {
                    without_refunds: 2.8726,
                    with_refunds: 2.6247,
                  },
                  net_total_spread: {
                    without_refunds: 1.1485,
                    with_refunds: 1.1291,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2956,
                    with_refunds: 1.2734,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 240,
                  with_refunds: 240,
                },
                arr_users: {
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1249,
                  with_refunds: 1249,
                },
                total_users: {
                  without_refunds: 240,
                  with_refunds: 241,
                },
                total_spread_users: {
                  without_refunds: 1489,
                  with_refunds: 1489,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1019,
                  with_refunds: 0.1019,
                },
                arr_users: {
                  with_refunds: 0.0007,
                },
                arr_spread_users: {
                  without_refunds: 0.9968,
                  with_refunds: 0.9968,
                },
                total_users: {
                  without_refunds: 0.064,
                  with_refunds: 0.0643,
                },
                total_spread_users: {
                  without_refunds: 0.4169,
                  with_refunds: 0.4169,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.323,
                  with_refunds: 0.3226,
                },
                arr_users: {
                  with_refunds: 0.5,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.3221,
                  with_refunds: 0.3231,
                },
                total_spread_users: {
                  without_refunds: 0.7479,
                  with_refunds: 0.7475,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2974.31,
                  with_refunds: 2972.04,
                },
                gross_mrr: {
                  without_refunds: 3309.41,
                  with_refunds: 3306.74,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10658.68,
                  with_refunds: 10658.68,
                },
                gross_arr_spread: {
                  without_refunds: 11916.12,
                  with_refunds: 11916.12,
                },
                net_total: {
                  without_refunds: 2974.31,
                  with_refunds: 2972.04,
                },
                gross_total: {
                  without_refunds: 3309.41,
                  with_refunds: 3306.74,
                },
                net_total_spread: {
                  without_refunds: 13632.99,
                  with_refunds: 13630.72,
                },
                gross_total_spread: {
                  without_refunds: 15225.53,
                  with_refunds: 15222.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.257,
                  with_refunds: 0.264,
                },
                gross_mrr: {
                  without_refunds: 0.249,
                  with_refunds: 0.256,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.5317,
                  with_refunds: 2.5317,
                },
                gross_arr_spread: {
                  without_refunds: 2.538,
                  with_refunds: 2.538,
                },
                net_total: {
                  without_refunds: 0.0433,
                  with_refunds: 0.0464,
                },
                gross_total: {
                  without_refunds: 0.0429,
                  with_refunds: 0.046,
                },
                net_total_spread: {
                  without_refunds: 0.8638,
                  with_refunds: 0.8812,
                },
                gross_total_spread: {
                  without_refunds: 0.8464,
                  with_refunds: 0.8644,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.316,
                  with_refunds: 0.3182,
                },
                gross_mrr: {
                  without_refunds: 0.3138,
                  with_refunds: 0.3161,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.3086,
                  with_refunds: 0.3107,
                },
                gross_total: {
                  without_refunds: 0.3065,
                  with_refunds: 0.3087,
                },
                net_total_spread: {
                  without_refunds: 0.6792,
                  with_refunds: 0.6816,
                },
                gross_total_spread: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6803,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.26,
                  with_refunds: 1.26,
                },
                gross_mrr: {
                  without_refunds: 1.41,
                  with_refunds: 1.4,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                gross_arr_spread: {
                  without_refunds: 9.51,
                  with_refunds: 9.51,
                },
                net_total: {
                  without_refunds: 0.79,
                  with_refunds: 0.79,
                },
                gross_total: {
                  without_refunds: 0.88,
                  with_refunds: 0.88,
                },
                net_total_spread: {
                  without_refunds: 3.82,
                  with_refunds: 3.82,
                },
                gross_total_spread: {
                  without_refunds: 4.26,
                  with_refunds: 4.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.39,
                  with_refunds: 12.38,
                },
                gross_mrr: {
                  without_refunds: 13.79,
                  with_refunds: 13.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 12.39,
                  with_refunds: 12.33,
                },
                gross_total: {
                  without_refunds: 13.79,
                  with_refunds: 13.72,
                },
                net_total_spread: {
                  without_refunds: 9.16,
                  with_refunds: 9.15,
                },
                gross_total_spread: {
                  without_refunds: 10.23,
                  with_refunds: 10.22,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.76,
                    with_refunds: 38.78,
                  },
                  gross_mrr: {
                    without_refunds: 45.11,
                    with_refunds: 43.99,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 53.76,
                    with_refunds: 53.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 60.01,
                    with_refunds: 60.01,
                  },
                  net_total: {
                    without_refunds: 64.41,
                    with_refunds: 58.52,
                  },
                  gross_total: {
                    without_refunds: 72.5,
                    with_refunds: 65.83,
                  },
                  net_total_spread: {
                    without_refunds: 45.07,
                    with_refunds: 44.42,
                  },
                  gross_total_spread: {
                    without_refunds: 50.79,
                    with_refunds: 50.05,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 89.36,
                    with_refunds: 87.28,
                  },
                  gross_mrr: {
                    without_refunds: 101.04,
                    with_refunds: 98.68,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 59.66,
                    with_refunds: 59.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 66.59,
                    with_refunds: 66.59,
                  },
                  net_total: {
                    without_refunds: 155.11,
                    with_refunds: 142.85,
                  },
                  gross_total: {
                    without_refunds: 174.88,
                    with_refunds: 160.91,
                  },
                  net_total_spread: {
                    without_refunds: 73.57,
                    with_refunds: 72.48,
                  },
                  gross_total_spread: {
                    without_refunds: 82.88,
                    with_refunds: 81.64,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7089,
                    with_refunds: 0.6914,
                  },
                  gross_mrr: {
                    without_refunds: 0.8043,
                    with_refunds: 0.7845,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9586,
                    with_refunds: 0.9586,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.07,
                    with_refunds: 1.07,
                  },
                  net_total: {
                    without_refunds: 1.1486,
                    with_refunds: 1.0435,
                  },
                  gross_total: {
                    without_refunds: 1.2929,
                    with_refunds: 1.1738,
                  },
                  net_total_spread: {
                    without_refunds: 0.8036,
                    with_refunds: 0.7921,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9056,
                    with_refunds: 0.8925,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.5934,
                    with_refunds: 1.5564,
                  },
                  gross_mrr: {
                    without_refunds: 1.8018,
                    with_refunds: 1.7596,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0639,
                    with_refunds: 1.0639,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1874,
                    with_refunds: 1.1874,
                  },
                  net_total: {
                    without_refunds: 2.7659,
                    with_refunds: 2.5472,
                  },
                  gross_total: {
                    without_refunds: 3.1185,
                    with_refunds: 2.8694,
                  },
                  net_total_spread: {
                    without_refunds: 1.3118,
                    with_refunds: 1.2924,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4779,
                    with_refunds: 1.4557,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 893,
              with_refunds: 893,
            },
            returning_users: {
              without_refunds: 5761,
              with_refunds: 5787,
            },
            paying_users: {
              without_refunds: 6654,
              with_refunds: 6680,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 556,
              with_refunds: 556,
            },
            returning_users: {
              without_refunds: 393,
              with_refunds: 438,
            },
            paying_users: {
              without_refunds: 949,
              with_refunds: 994,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1449,
              with_refunds: 1449,
            },
            returning_users: {
              without_refunds: 6151,
              with_refunds: 6221,
            },
            paying_users: {
              without_refunds: 7600,
              with_refunds: 7670,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 93623.47,
                with_refunds: 91318.61,
              },
              gross_mrr: {
                without_refunds: 106223.11,
                with_refunds: 103602.34,
              },
              net_arr: {
                without_refunds: 147930.42,
                with_refunds: 128133.49,
              },
              gross_arr: {
                without_refunds: 165666.95,
                with_refunds: 143252.6,
              },
              net_arr_spread: {
                without_refunds: 67359.07,
                with_refunds: 67359.07,
              },
              gross_arr_spread: {
                without_refunds: 75186.79,
                with_refunds: 75186.79,
              },
              net_total: {
                without_refunds: 241553.89,
                with_refunds: 219452.09,
              },
              gross_total: {
                without_refunds: 271890.06,
                with_refunds: 246854.93,
              },
              net_total_spread: {
                without_refunds: 160982.54,
                with_refunds: 158677.68,
              },
              gross_total_spread: {
                without_refunds: 181409.9,
                with_refunds: 178789.12,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 93623.47,
                with_refunds: 91318.61,
              },
              gross_mrr: {
                without_refunds: 106223.11,
                with_refunds: 103602.34,
              },
              net_arr: {
                without_refunds: 147930.42,
                with_refunds: 128133.49,
              },
              gross_arr: {
                without_refunds: 165666.95,
                with_refunds: 143252.6,
              },
              net_arr_spread: {
                without_refunds: 67359.07,
                with_refunds: 67359.07,
              },
              gross_arr_spread: {
                without_refunds: 75186.79,
                with_refunds: 75186.79,
              },
              net_total: {
                without_refunds: 241553.89,
                with_refunds: 219452.09,
              },
              gross_total: {
                without_refunds: 271890.06,
                with_refunds: 246854.93,
              },
              net_total_spread: {
                without_refunds: 160982.54,
                with_refunds: 158677.68,
              },
              gross_total_spread: {
                without_refunds: 181409.9,
                with_refunds: 178789.12,
              },
            },
          },
        },
      },
      cac: 56.08,
      mrr_ua_spend: {
        without_refunds: 132068.4,
        with_refunds: 132068.4,
      },
      arr_ua_spend: {
        without_refunds: 80699.12,
        with_refunds: 80699.12,
      },
      arr_spread_ua_spend: {
        without_refunds: 70268.24,
        with_refunds: 70268.24,
      },
      total_ua_spend: {
        without_refunds: 210300,
        with_refunds: 210300,
      },
      total_spread_ua_spend: {
        without_refunds: 200317.76,
        with_refunds: 200317.76,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 2,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 1395,
        with_refunds: 1395,
      },
      first_period_arr_users: {
        without_refunds: 1890,
        with_refunds: 1890,
      },
      first_period_arr_spread_users: {
        without_refunds: 1401,
        with_refunds: 1401,
      },
      first_period_total_users: {
        without_refunds: 3266,
        with_refunds: 3266,
      },
      first_period_total_spread_users: {
        without_refunds: 2781,
        with_refunds: 2781,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 872,
                  with_refunds: 872,
                },
                arr_users: {
                  without_refunds: 850,
                  with_refunds: 850,
                },
                arr_spread_users: {
                  without_refunds: 647,
                  with_refunds: 647,
                },
                total_users: {
                  without_refunds: 1719,
                  with_refunds: 1719,
                },
                total_spread_users: {
                  without_refunds: 1517,
                  with_refunds: 1517,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6251,
                  with_refunds: 0.6251,
                },
                arr_users: {
                  without_refunds: 0.4497,
                  with_refunds: 0.4497,
                },
                arr_spread_users: {
                  without_refunds: 0.4618,
                  with_refunds: 0.4618,
                },
                total_users: {
                  without_refunds: 0.5263,
                  with_refunds: 0.5263,
                },
                total_spread_users: {
                  without_refunds: 0.5455,
                  with_refunds: 0.5455,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 11224.61,
                  with_refunds: 10852.4,
                },
                gross_mrr: {
                  without_refunds: 12879.26,
                  with_refunds: 12454.84,
                },
                net_arr: {
                  without_refunds: 92177.74,
                  with_refunds: 82338.46,
                },
                gross_arr: {
                  without_refunds: 104274.54,
                  with_refunds: 93008.01,
                },
                net_arr_spread: {
                  without_refunds: 5841.52,
                  with_refunds: 5841.52,
                },
                gross_arr_spread: {
                  without_refunds: 6656.81,
                  with_refunds: 6656.81,
                },
                net_total: {
                  without_refunds: 103402.35,
                  with_refunds: 93190.86,
                },
                gross_total: {
                  without_refunds: 117153.79,
                  with_refunds: 105462.84,
                },
                net_total_spread: {
                  without_refunds: 17066.13,
                  with_refunds: 16693.92,
                },
                gross_total_spread: {
                  without_refunds: 19536.07,
                  with_refunds: 19111.65,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.05,
                  with_refunds: 7.78,
                },
                gross_mrr: {
                  without_refunds: 9.23,
                  with_refunds: 8.93,
                },
                net_arr: {
                  without_refunds: 48.77,
                  with_refunds: 43.57,
                },
                gross_arr: {
                  without_refunds: 55.17,
                  with_refunds: 49.21,
                },
                net_arr_spread: {
                  without_refunds: 4.17,
                  with_refunds: 4.17,
                },
                gross_arr_spread: {
                  without_refunds: 4.75,
                  with_refunds: 4.75,
                },
                net_total: {
                  without_refunds: 31.66,
                  with_refunds: 28.53,
                },
                gross_total: {
                  without_refunds: 35.87,
                  with_refunds: 32.29,
                },
                net_total_spread: {
                  without_refunds: 6.14,
                  with_refunds: 6,
                },
                gross_total_spread: {
                  without_refunds: 7.02,
                  with_refunds: 6.87,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.87,
                  with_refunds: 12.45,
                },
                gross_mrr: {
                  without_refunds: 14.77,
                  with_refunds: 14.28,
                },
                net_arr: {
                  without_refunds: 108.44,
                  with_refunds: 96.87,
                },
                gross_arr: {
                  without_refunds: 122.68,
                  with_refunds: 109.42,
                },
                net_arr_spread: {
                  without_refunds: 9.03,
                  with_refunds: 9.03,
                },
                gross_arr_spread: {
                  without_refunds: 10.29,
                  with_refunds: 10.29,
                },
                net_total: {
                  without_refunds: 60.15,
                  with_refunds: 54.21,
                },
                gross_total: {
                  without_refunds: 68.15,
                  with_refunds: 61.35,
                },
                net_total_spread: {
                  without_refunds: 11.25,
                  with_refunds: 11,
                },
                gross_total_spread: {
                  without_refunds: 12.88,
                  with_refunds: 12.6,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 8.05,
                    with_refunds: 7.78,
                  },
                  gross_mrr: {
                    without_refunds: 9.23,
                    with_refunds: 8.93,
                  },
                  net_arr: {
                    without_refunds: 48.77,
                    with_refunds: 43.57,
                  },
                  gross_arr: {
                    without_refunds: 55.17,
                    with_refunds: 49.21,
                  },
                  net_arr_spread: {
                    without_refunds: 4.17,
                    with_refunds: 4.17,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.75,
                    with_refunds: 4.75,
                  },
                  net_total: {
                    without_refunds: 31.66,
                    with_refunds: 28.53,
                  },
                  gross_total: {
                    without_refunds: 35.87,
                    with_refunds: 32.29,
                  },
                  net_total_spread: {
                    without_refunds: 6.14,
                    with_refunds: 6,
                  },
                  gross_total_spread: {
                    without_refunds: 7.02,
                    with_refunds: 6.87,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 12.87,
                    with_refunds: 12.45,
                  },
                  gross_mrr: {
                    without_refunds: 14.77,
                    with_refunds: 14.28,
                  },
                  net_arr: {
                    without_refunds: 108.44,
                    with_refunds: 96.87,
                  },
                  gross_arr: {
                    without_refunds: 122.68,
                    with_refunds: 109.42,
                  },
                  net_arr_spread: {
                    without_refunds: 9.03,
                    with_refunds: 9.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.29,
                    with_refunds: 10.29,
                  },
                  net_total: {
                    without_refunds: 60.15,
                    with_refunds: 54.21,
                  },
                  gross_total: {
                    without_refunds: 68.15,
                    with_refunds: 61.35,
                  },
                  net_total_spread: {
                    without_refunds: 11.25,
                    with_refunds: 11,
                  },
                  gross_total_spread: {
                    without_refunds: 12.88,
                    with_refunds: 12.6,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1216,
                    with_refunds: 0.1176,
                  },
                  gross_mrr: {
                    without_refunds: 0.1395,
                    with_refunds: 0.1349,
                  },
                  net_arr: {
                    without_refunds: 0.7369,
                    with_refunds: 0.6583,
                  },
                  gross_arr: {
                    without_refunds: 0.8337,
                    with_refunds: 0.7436,
                  },
                  net_arr_spread: {
                    without_refunds: 0.063,
                    with_refunds: 0.063,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0718,
                    with_refunds: 0.0718,
                  },
                  net_total: {
                    without_refunds: 0.4784,
                    with_refunds: 0.4312,
                  },
                  gross_total: {
                    without_refunds: 0.542,
                    with_refunds: 0.4879,
                  },
                  net_total_spread: {
                    without_refunds: 0.0927,
                    with_refunds: 0.0907,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1061,
                    with_refunds: 0.1038,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1945,
                    with_refunds: 0.1881,
                  },
                  gross_mrr: {
                    without_refunds: 0.2232,
                    with_refunds: 0.2158,
                  },
                  net_arr: {
                    without_refunds: 1.6386,
                    with_refunds: 1.4637,
                  },
                  gross_arr: {
                    without_refunds: 1.8537,
                    with_refunds: 1.6534,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1364,
                    with_refunds: 0.1364,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1555,
                    with_refunds: 0.1555,
                  },
                  net_total: {
                    without_refunds: 0.9089,
                    with_refunds: 0.8192,
                  },
                  gross_total: {
                    without_refunds: 1.0298,
                    with_refunds: 0.927,
                  },
                  net_total_spread: {
                    without_refunds: 0.17,
                    with_refunds: 0.1663,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1946,
                    with_refunds: 0.1904,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1074,
                  with_refunds: 1086,
                },
                arr_users: {
                  without_refunds: 1000,
                  with_refunds: 1099,
                },
                arr_spread_users: {
                  without_refunds: 1365,
                  with_refunds: 1365,
                },
                total_users: {
                  without_refunds: 2073,
                  with_refunds: 2182,
                },
                total_spread_users: {
                  without_refunds: 2439,
                  with_refunds: 2451,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7699,
                  with_refunds: 0.7785,
                },
                arr_users: {
                  without_refunds: 0.5291,
                  with_refunds: 0.5815,
                },
                arr_spread_users: {
                  without_refunds: 0.9743,
                  with_refunds: 0.9743,
                },
                total_users: {
                  without_refunds: 0.6347,
                  with_refunds: 0.6681,
                },
                total_spread_users: {
                  without_refunds: 0.877,
                  with_refunds: 0.8813,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.2317,
                  with_refunds: 1.2454,
                },
                arr_users: {
                  without_refunds: 1.1765,
                  with_refunds: 1.2929,
                },
                arr_spread_users: {
                  without_refunds: 2.1097,
                  with_refunds: 2.1097,
                },
                total_users: {
                  without_refunds: 1.2059,
                  with_refunds: 1.2693,
                },
                total_spread_users: {
                  without_refunds: 1.6078,
                  with_refunds: 1.6157,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 14112.97,
                  with_refunds: 12891.29,
                },
                gross_mrr: {
                  without_refunds: 16192.1,
                  with_refunds: 14866.82,
                },
                net_arr: {
                  without_refunds: 121714.35,
                  with_refunds: 81946.43,
                },
                gross_arr: {
                  without_refunds: 134820.98,
                  with_refunds: 91484.32,
                },
                net_arr_spread: {
                  without_refunds: 13073.9,
                  with_refunds: 13073.9,
                },
                gross_arr_spread: {
                  without_refunds: 14714.98,
                  with_refunds: 14714.98,
                },
                net_total: {
                  without_refunds: 135827.33,
                  with_refunds: 94837.72,
                },
                gross_total: {
                  without_refunds: 151013.08,
                  with_refunds: 106351.15,
                },
                net_total_spread: {
                  without_refunds: 27186.87,
                  with_refunds: 25965.19,
                },
                gross_total_spread: {
                  without_refunds: 30907.08,
                  with_refunds: 29581.81,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.2573,
                  with_refunds: 1.1879,
                },
                gross_mrr: {
                  without_refunds: 1.2572,
                  with_refunds: 1.1937,
                },
                net_arr: {
                  without_refunds: 1.3204,
                  with_refunds: 0.9952,
                },
                gross_arr: {
                  without_refunds: 1.2929,
                  with_refunds: 0.9836,
                },
                net_arr_spread: {
                  without_refunds: 2.2381,
                  with_refunds: 2.2381,
                },
                gross_arr_spread: {
                  without_refunds: 2.2105,
                  with_refunds: 2.2105,
                },
                net_total: {
                  without_refunds: 1.3136,
                  with_refunds: 1.0177,
                },
                gross_total: {
                  without_refunds: 1.289,
                  with_refunds: 1.0084,
                },
                net_total_spread: {
                  without_refunds: 1.593,
                  with_refunds: 1.5554,
                },
                gross_total_spread: {
                  without_refunds: 1.5821,
                  with_refunds: 1.5478,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.2573,
                  with_refunds: 1.1879,
                },
                gross_mrr: {
                  without_refunds: 1.2572,
                  with_refunds: 1.1937,
                },
                net_arr: {
                  without_refunds: 1.3204,
                  with_refunds: 0.9952,
                },
                gross_arr: {
                  without_refunds: 1.2929,
                  with_refunds: 0.9836,
                },
                net_arr_spread: {
                  without_refunds: 2.2381,
                  with_refunds: 2.2381,
                },
                gross_arr_spread: {
                  without_refunds: 2.2105,
                  with_refunds: 2.2105,
                },
                net_total: {
                  without_refunds: 1.3136,
                  with_refunds: 1.0177,
                },
                gross_total: {
                  without_refunds: 1.289,
                  with_refunds: 1.0084,
                },
                net_total_spread: {
                  without_refunds: 1.593,
                  with_refunds: 1.5554,
                },
                gross_total_spread: {
                  without_refunds: 1.5821,
                  with_refunds: 1.5478,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 10.12,
                  with_refunds: 9.24,
                },
                gross_mrr: {
                  without_refunds: 11.61,
                  with_refunds: 10.66,
                },
                net_arr: {
                  without_refunds: 64.4,
                  with_refunds: 43.36,
                },
                gross_arr: {
                  without_refunds: 71.33,
                  with_refunds: 48.4,
                },
                net_arr_spread: {
                  without_refunds: 9.33,
                  with_refunds: 9.33,
                },
                gross_arr_spread: {
                  without_refunds: 10.5,
                  with_refunds: 10.5,
                },
                net_total: {
                  without_refunds: 41.59,
                  with_refunds: 29.04,
                },
                gross_total: {
                  without_refunds: 46.24,
                  with_refunds: 32.56,
                },
                net_total_spread: {
                  without_refunds: 9.78,
                  with_refunds: 9.34,
                },
                gross_total_spread: {
                  without_refunds: 11.11,
                  with_refunds: 10.64,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.14,
                  with_refunds: 11.87,
                },
                gross_mrr: {
                  without_refunds: 15.08,
                  with_refunds: 13.69,
                },
                net_arr: {
                  without_refunds: 121.71,
                  with_refunds: 74.56,
                },
                gross_arr: {
                  without_refunds: 134.82,
                  with_refunds: 83.24,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.78,
                  with_refunds: 10.78,
                },
                net_total: {
                  without_refunds: 65.52,
                  with_refunds: 43.46,
                },
                gross_total: {
                  without_refunds: 72.85,
                  with_refunds: 48.74,
                },
                net_total_spread: {
                  without_refunds: 11.15,
                  with_refunds: 10.59,
                },
                gross_total_spread: {
                  without_refunds: 12.67,
                  with_refunds: 12.07,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.16,
                    with_refunds: 17.02,
                  },
                  gross_mrr: {
                    without_refunds: 20.84,
                    with_refunds: 19.59,
                  },
                  net_arr: {
                    without_refunds: 113.17,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 126.51,
                    with_refunds: 97.61,
                  },
                  net_arr_spread: {
                    without_refunds: 13.5,
                    with_refunds: 13.5,
                  },
                  gross_arr_spread: {
                    without_refunds: 15.25,
                    with_refunds: 15.25,
                  },
                  net_total: {
                    without_refunds: 73.25,
                    with_refunds: 57.57,
                  },
                  gross_total: {
                    without_refunds: 82.11,
                    with_refunds: 64.85,
                  },
                  net_total_spread: {
                    without_refunds: 15.91,
                    with_refunds: 15.34,
                  },
                  gross_total_spread: {
                    without_refunds: 18.14,
                    with_refunds: 17.51,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 26.01,
                    with_refunds: 24.32,
                  },
                  gross_mrr: {
                    without_refunds: 29.85,
                    with_refunds: 27.97,
                  },
                  net_arr: {
                    without_refunds: 230.16,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 257.5,
                    with_refunds: 192.66,
                  },
                  net_arr_spread: {
                    without_refunds: 18.61,
                    with_refunds: 18.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 21.07,
                    with_refunds: 21.07,
                  },
                  net_total: {
                    without_refunds: 125.67,
                    with_refunds: 97.68,
                  },
                  gross_total: {
                    without_refunds: 141,
                    with_refunds: 110.09,
                  },
                  net_total_spread: {
                    without_refunds: 22.4,
                    with_refunds: 21.6,
                  },
                  gross_total_spread: {
                    without_refunds: 25.55,
                    with_refunds: 24.67,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2745,
                    with_refunds: 0.2572,
                  },
                  gross_mrr: {
                    without_refunds: 0.3149,
                    with_refunds: 0.2959,
                  },
                  net_arr: {
                    without_refunds: 1.71,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9115,
                    with_refunds: 1.475,
                  },
                  net_arr_spread: {
                    without_refunds: 0.204,
                    with_refunds: 0.204,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2305,
                    with_refunds: 0.2305,
                  },
                  net_total: {
                    without_refunds: 1.1068,
                    with_refunds: 0.8699,
                  },
                  gross_total: {
                    without_refunds: 1.2407,
                    with_refunds: 0.98,
                  },
                  net_total_spread: {
                    without_refunds: 0.2404,
                    with_refunds: 0.2318,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2741,
                    with_refunds: 0.2646,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3931,
                    with_refunds: 0.3674,
                  },
                  gross_mrr: {
                    without_refunds: 0.451,
                    with_refunds: 0.4227,
                  },
                  net_arr: {
                    without_refunds: 3.4778,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 3.8909,
                    with_refunds: 2.9112,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2812,
                    with_refunds: 0.2812,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3184,
                    with_refunds: 0.3184,
                  },
                  net_total: {
                    without_refunds: 1.899,
                    with_refunds: 1.4759,
                  },
                  gross_total: {
                    without_refunds: 2.1306,
                    with_refunds: 1.6635,
                  },
                  net_total_spread: {
                    without_refunds: 0.3384,
                    with_refunds: 0.3264,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3861,
                    with_refunds: 0.3727,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 725,
                  with_refunds: 739,
                },
                arr_users: {
                  without_refunds: 31,
                  with_refunds: 89,
                },
                arr_spread_users: {
                  without_refunds: 1385,
                  with_refunds: 1385,
                },
                total_users: {
                  without_refunds: 756,
                  with_refunds: 828,
                },
                total_spread_users: {
                  without_refunds: 2110,
                  with_refunds: 2124,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5197,
                  with_refunds: 0.5297,
                },
                arr_users: {
                  without_refunds: 0.0164,
                  with_refunds: 0.0471,
                },
                arr_spread_users: {
                  without_refunds: 0.9886,
                  with_refunds: 0.9886,
                },
                total_users: {
                  without_refunds: 0.2315,
                  with_refunds: 0.2535,
                },
                total_spread_users: {
                  without_refunds: 0.7587,
                  with_refunds: 0.7638,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.675,
                  with_refunds: 0.6805,
                },
                arr_users: {
                  without_refunds: 0.031,
                  with_refunds: 0.081,
                },
                arr_spread_users: {
                  without_refunds: 1.0147,
                  with_refunds: 1.0147,
                },
                total_users: {
                  without_refunds: 0.3647,
                  with_refunds: 0.3795,
                },
                total_spread_users: {
                  without_refunds: 0.8651,
                  with_refunds: 0.8666,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9241.67,
                  with_refunds: 8919.06,
                },
                gross_mrr: {
                  without_refunds: 10531.11,
                  with_refunds: 10185.39,
                },
                net_arr: {
                  without_refunds: 3631.79,
                },
                gross_arr: {
                  without_refunds: 4470.52,
                },
                net_arr_spread: {
                  without_refunds: 13272.69,
                  with_refunds: 13272.69,
                },
                gross_arr_spread: {
                  without_refunds: 14965.28,
                  with_refunds: 14965.28,
                },
                net_total: {
                  without_refunds: 12873.46,
                  with_refunds: 8919.06,
                },
                gross_total: {
                  without_refunds: 15001.63,
                  with_refunds: 10185.39,
                },
                net_total_spread: {
                  without_refunds: 22514.36,
                  with_refunds: 22191.75,
                },
                gross_total_spread: {
                  without_refunds: 25496.39,
                  with_refunds: 25150.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8233,
                  with_refunds: 0.8219,
                },
                gross_mrr: {
                  without_refunds: 0.8177,
                  with_refunds: 0.8178,
                },
                net_arr: {
                  without_refunds: 0.0394,
                },
                gross_arr: {
                  without_refunds: 0.0429,
                },
                net_arr_spread: {
                  without_refunds: 2.2721,
                  with_refunds: 2.2721,
                },
                gross_arr_spread: {
                  without_refunds: 2.2481,
                  with_refunds: 2.2481,
                },
                net_total: {
                  without_refunds: 0.1245,
                  with_refunds: 0.0957,
                },
                gross_total: {
                  without_refunds: 0.1281,
                  with_refunds: 0.0966,
                },
                net_total_spread: {
                  without_refunds: 1.3192,
                  with_refunds: 1.3293,
                },
                gross_total_spread: {
                  without_refunds: 1.3051,
                  with_refunds: 1.316,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6548,
                  with_refunds: 0.6919,
                },
                gross_mrr: {
                  without_refunds: 0.6504,
                  with_refunds: 0.6851,
                },
                net_arr: {
                  without_refunds: 0.0298,
                },
                gross_arr: {
                  without_refunds: 0.0332,
                },
                net_arr_spread: {
                  without_refunds: 1.0152,
                  with_refunds: 1.0152,
                },
                gross_arr_spread: {
                  without_refunds: 1.017,
                  with_refunds: 1.017,
                },
                net_total: {
                  without_refunds: 0.0948,
                  with_refunds: 0.094,
                },
                gross_total: {
                  without_refunds: 0.0993,
                  with_refunds: 0.0958,
                },
                net_total_spread: {
                  without_refunds: 0.8281,
                  with_refunds: 0.8547,
                },
                gross_total_spread: {
                  without_refunds: 0.8249,
                  with_refunds: 0.8502,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.62,
                  with_refunds: 6.39,
                },
                gross_mrr: {
                  without_refunds: 7.55,
                  with_refunds: 7.3,
                },
                net_arr: {
                  without_refunds: 1.92,
                },
                gross_arr: {
                  without_refunds: 2.37,
                },
                net_arr_spread: {
                  without_refunds: 9.47,
                  with_refunds: 9.47,
                },
                gross_arr_spread: {
                  without_refunds: 10.68,
                  with_refunds: 10.68,
                },
                net_total: {
                  without_refunds: 3.94,
                  with_refunds: 2.73,
                },
                gross_total: {
                  without_refunds: 4.59,
                  with_refunds: 3.12,
                },
                net_total_spread: {
                  without_refunds: 8.1,
                  with_refunds: 7.98,
                },
                gross_total_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.04,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.75,
                  with_refunds: 12.07,
                },
                gross_mrr: {
                  without_refunds: 14.53,
                  with_refunds: 13.78,
                },
                net_arr: {
                  without_refunds: 117.15,
                },
                gross_arr: {
                  without_refunds: 144.21,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 17.03,
                  with_refunds: 10.77,
                },
                gross_total: {
                  without_refunds: 19.84,
                  with_refunds: 12.3,
                },
                net_total_spread: {
                  without_refunds: 10.67,
                  with_refunds: 10.45,
                },
                gross_total_spread: {
                  without_refunds: 12.08,
                  with_refunds: 11.84,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.79,
                    with_refunds: 23.41,
                  },
                  gross_mrr: {
                    without_refunds: 28.39,
                    with_refunds: 26.89,
                  },
                  net_arr: {
                    without_refunds: 115.09,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 128.87,
                    with_refunds: 97.61,
                  },
                  net_arr_spread: {
                    without_refunds: 22.98,
                    with_refunds: 22.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.94,
                    with_refunds: 25.94,
                  },
                  net_total: {
                    without_refunds: 77.19,
                    with_refunds: 60.3,
                  },
                  gross_total: {
                    without_refunds: 86.7,
                    with_refunds: 67.97,
                  },
                  net_total_spread: {
                    without_refunds: 24.01,
                    with_refunds: 23.32,
                  },
                  gross_total_spread: {
                    without_refunds: 27.31,
                    with_refunds: 26.55,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.76,
                    with_refunds: 36.38,
                  },
                  gross_mrr: {
                    without_refunds: 44.37,
                    with_refunds: 41.76,
                  },
                  net_arr: {
                    without_refunds: 347.31,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 401.71,
                    with_refunds: 192.66,
                  },
                  net_arr_spread: {
                    without_refunds: 28.19,
                    with_refunds: 28.19,
                  },
                  gross_arr_spread: {
                    without_refunds: 31.87,
                    with_refunds: 31.87,
                  },
                  net_total: {
                    without_refunds: 142.7,
                    with_refunds: 108.45,
                  },
                  gross_total: {
                    without_refunds: 160.84,
                    with_refunds: 122.39,
                  },
                  net_total_spread: {
                    without_refunds: 33.07,
                    with_refunds: 32.05,
                  },
                  gross_total_spread: {
                    without_refunds: 37.63,
                    with_refunds: 36.51,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3746,
                    with_refunds: 0.3538,
                  },
                  gross_mrr: {
                    without_refunds: 0.429,
                    with_refunds: 0.4063,
                  },
                  net_arr: {
                    without_refunds: 1.7391,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9473,
                    with_refunds: 1.475,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3472,
                    with_refunds: 0.3472,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3919,
                    with_refunds: 0.3919,
                  },
                  net_total: {
                    without_refunds: 1.1664,
                    with_refunds: 0.9112,
                  },
                  gross_total: {
                    without_refunds: 1.3101,
                    with_refunds: 1.0271,
                  },
                  net_total_spread: {
                    without_refunds: 0.3628,
                    with_refunds: 0.3524,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4126,
                    with_refunds: 0.4012,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5857,
                    with_refunds: 0.5498,
                  },
                  gross_mrr: {
                    without_refunds: 0.6705,
                    with_refunds: 0.6309,
                  },
                  net_arr: {
                    without_refunds: 5.248,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 6.0699,
                    with_refunds: 2.9112,
                  },
                  net_arr_spread: {
                    without_refunds: 0.426,
                    with_refunds: 0.426,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4816,
                    with_refunds: 0.4816,
                  },
                  net_total: {
                    without_refunds: 2.1563,
                    with_refunds: 1.6387,
                  },
                  gross_total: {
                    without_refunds: 2.4304,
                    with_refunds: 1.8494,
                  },
                  net_total_spread: {
                    without_refunds: 0.4997,
                    with_refunds: 0.4842,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5687,
                    with_refunds: 0.5517,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 567,
                  with_refunds: 568,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 16,
                },
                arr_spread_users: {
                  without_refunds: 1389,
                  with_refunds: 1389,
                },
                total_users: {
                  without_refunds: 577,
                  with_refunds: 584,
                },
                total_spread_users: {
                  without_refunds: 1956,
                  with_refunds: 1957,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4065,
                  with_refunds: 0.4072,
                },
                arr_users: {
                  without_refunds: 0.0053,
                  with_refunds: 0.0085,
                },
                arr_spread_users: {
                  without_refunds: 0.9914,
                  with_refunds: 0.9914,
                },
                total_users: {
                  without_refunds: 0.1767,
                  with_refunds: 0.1788,
                },
                total_spread_users: {
                  without_refunds: 0.7033,
                  with_refunds: 0.7037,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7821,
                  with_refunds: 0.7686,
                },
                arr_users: {
                  without_refunds: 0.3226,
                  with_refunds: 0.1798,
                },
                arr_spread_users: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                total_users: {
                  without_refunds: 0.7632,
                  with_refunds: 0.7053,
                },
                total_spread_users: {
                  without_refunds: 0.927,
                  with_refunds: 0.9214,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7352.83,
                  with_refunds: 7169.13,
                },
                gross_mrr: {
                  without_refunds: 8393.29,
                  with_refunds: 8183.55,
                },
                net_arr: {
                  without_refunds: 1121.42,
                },
                gross_arr: {
                  without_refunds: 1447.69,
                  with_refunds: 120.17,
                },
                net_arr_spread: {
                  without_refunds: 13307.44,
                  with_refunds: 13307.44,
                },
                gross_arr_spread: {
                  without_refunds: 15012.32,
                  with_refunds: 15012.32,
                },
                net_total: {
                  without_refunds: 8474.25,
                  with_refunds: 7169.13,
                },
                gross_total: {
                  without_refunds: 9840.98,
                  with_refunds: 8303.71,
                },
                net_total_spread: {
                  without_refunds: 20660.27,
                  with_refunds: 20476.57,
                },
                gross_total_spread: {
                  without_refunds: 23405.6,
                  with_refunds: 23195.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6551,
                  with_refunds: 0.6606,
                },
                gross_mrr: {
                  without_refunds: 0.6517,
                  with_refunds: 0.6571,
                },
                net_arr: {
                  without_refunds: 0.0122,
                },
                gross_arr: {
                  without_refunds: 0.0139,
                  with_refunds: 0.0013,
                },
                net_arr_spread: {
                  without_refunds: 2.2781,
                  with_refunds: 2.2781,
                },
                gross_arr_spread: {
                  without_refunds: 2.2552,
                  with_refunds: 2.2552,
                },
                net_total: {
                  without_refunds: 0.082,
                  with_refunds: 0.0769,
                },
                gross_total: {
                  without_refunds: 0.084,
                  with_refunds: 0.0787,
                },
                net_total_spread: {
                  without_refunds: 1.2106,
                  with_refunds: 1.2266,
                },
                gross_total_spread: {
                  without_refunds: 1.1981,
                  with_refunds: 1.2137,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7956,
                  with_refunds: 0.8038,
                },
                gross_mrr: {
                  without_refunds: 0.797,
                  with_refunds: 0.8035,
                },
                net_arr: {
                  without_refunds: 0.3088,
                },
                gross_arr: {
                  without_refunds: 0.3238,
                  with_refunds: 0.0013,
                },
                net_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                gross_arr_spread: {
                  without_refunds: 1.0031,
                  with_refunds: 1.0031,
                },
                net_total: {
                  without_refunds: 0.6583,
                  with_refunds: 0.8038,
                },
                gross_total: {
                  without_refunds: 0.656,
                  with_refunds: 0.8153,
                },
                net_total_spread: {
                  without_refunds: 0.9176,
                  with_refunds: 0.9227,
                },
                gross_total_spread: {
                  without_refunds: 0.918,
                  with_refunds: 0.9223,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.27,
                  with_refunds: 5.14,
                },
                gross_mrr: {
                  without_refunds: 6.02,
                  with_refunds: 5.87,
                },
                net_arr: {
                  without_refunds: 0.59,
                },
                gross_arr: {
                  without_refunds: 0.77,
                  with_refunds: 0.06,
                },
                net_arr_spread: {
                  without_refunds: 9.5,
                  with_refunds: 9.5,
                },
                gross_arr_spread: {
                  without_refunds: 10.72,
                  with_refunds: 10.72,
                },
                net_total: {
                  without_refunds: 2.59,
                  with_refunds: 2.2,
                },
                gross_total: {
                  without_refunds: 3.01,
                  with_refunds: 2.54,
                },
                net_total_spread: {
                  without_refunds: 7.43,
                  with_refunds: 7.36,
                },
                gross_total_spread: {
                  without_refunds: 8.42,
                  with_refunds: 8.34,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.97,
                  with_refunds: 12.62,
                },
                gross_mrr: {
                  without_refunds: 14.8,
                  with_refunds: 14.41,
                },
                net_arr: {
                  without_refunds: 112.14,
                },
                gross_arr: {
                  without_refunds: 144.77,
                  with_refunds: 7.51,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 14.69,
                  with_refunds: 12.28,
                },
                gross_total: {
                  without_refunds: 17.06,
                  with_refunds: 14.22,
                },
                net_total_spread: {
                  without_refunds: 10.56,
                  with_refunds: 10.46,
                },
                gross_total_spread: {
                  without_refunds: 11.97,
                  with_refunds: 11.85,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.06,
                    with_refunds: 28.55,
                  },
                  gross_mrr: {
                    without_refunds: 34.41,
                    with_refunds: 32.75,
                  },
                  net_arr: {
                    without_refunds: 115.69,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 129.64,
                    with_refunds: 97.68,
                  },
                  net_arr_spread: {
                    without_refunds: 32.47,
                    with_refunds: 32.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.65,
                    with_refunds: 36.65,
                  },
                  net_total: {
                    without_refunds: 79.78,
                    with_refunds: 62.5,
                  },
                  gross_total: {
                    without_refunds: 89.72,
                    with_refunds: 70.52,
                  },
                  net_total_spread: {
                    without_refunds: 31.44,
                    with_refunds: 30.68,
                  },
                  gross_total_spread: {
                    without_refunds: 35.72,
                    with_refunds: 34.89,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 51.73,
                    with_refunds: 49.01,
                  },
                  gross_mrr: {
                    without_refunds: 59.17,
                    with_refunds: 56.16,
                  },
                  net_arr: {
                    without_refunds: 459.46,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 546.48,
                    with_refunds: 200.17,
                  },
                  net_arr_spread: {
                    without_refunds: 37.77,
                    with_refunds: 37.77,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.68,
                    with_refunds: 42.68,
                  },
                  net_total: {
                    without_refunds: 157.39,
                    with_refunds: 120.72,
                  },
                  gross_total: {
                    without_refunds: 177.9,
                    with_refunds: 136.61,
                  },
                  net_total_spread: {
                    without_refunds: 43.63,
                    with_refunds: 42.51,
                  },
                  gross_total_spread: {
                    without_refunds: 49.6,
                    with_refunds: 48.36,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4542,
                    with_refunds: 0.4314,
                  },
                  gross_mrr: {
                    without_refunds: 0.5199,
                    with_refunds: 0.4949,
                  },
                  net_arr: {
                    without_refunds: 1.748,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9589,
                    with_refunds: 1.476,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4907,
                    with_refunds: 0.4907,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5538,
                    with_refunds: 0.5538,
                  },
                  net_total: {
                    without_refunds: 1.2056,
                    with_refunds: 0.9444,
                  },
                  gross_total: {
                    without_refunds: 1.3556,
                    with_refunds: 1.0655,
                  },
                  net_total_spread: {
                    without_refunds: 0.475,
                    with_refunds: 0.4636,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5398,
                    with_refunds: 0.5273,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7816,
                    with_refunds: 0.7405,
                  },
                  gross_mrr: {
                    without_refunds: 0.8942,
                    with_refunds: 0.8486,
                  },
                  net_arr: {
                    without_refunds: 6.9425,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 8.2574,
                    with_refunds: 3.0247,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5707,
                    with_refunds: 0.5707,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6449,
                    with_refunds: 0.6449,
                  },
                  net_total: {
                    without_refunds: 2.3782,
                    with_refunds: 1.8242,
                  },
                  gross_total: {
                    without_refunds: 2.6881,
                    with_refunds: 2.0642,
                  },
                  net_total_spread: {
                    without_refunds: 0.6593,
                    with_refunds: 0.6423,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7495,
                    with_refunds: 0.7308,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 456,
                  with_refunds: 458,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 1391,
                  with_refunds: 1391,
                },
                total_users: {
                  without_refunds: 458,
                  with_refunds: 462,
                },
                total_spread_users: {
                  without_refunds: 1847,
                  with_refunds: 1849,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3269,
                  with_refunds: 0.3283,
                },
                arr_users: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0021,
                },
                arr_spread_users: {
                  without_refunds: 0.9929,
                  with_refunds: 0.9929,
                },
                total_users: {
                  without_refunds: 0.1402,
                  with_refunds: 0.1415,
                },
                total_spread_users: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6649,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8042,
                  with_refunds: 0.8063,
                },
                arr_users: {
                  without_refunds: 0.2,
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0014,
                  with_refunds: 1.0014,
                },
                total_users: {
                  without_refunds: 0.7938,
                  with_refunds: 0.7911,
                },
                total_spread_users: {
                  without_refunds: 0.9443,
                  with_refunds: 0.9448,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5849.2,
                  with_refunds: 5649.99,
                },
                gross_mrr: {
                  without_refunds: 6653.73,
                  with_refunds: 6434.24,
                },
                net_arr: {
                  without_refunds: 206.06,
                },
                gross_arr: {
                  without_refunds: 224.99,
                },
                net_arr_spread: {
                  without_refunds: 13324.61,
                  with_refunds: 13324.61,
                },
                gross_arr_spread: {
                  without_refunds: 15031.07,
                  with_refunds: 15031.07,
                },
                net_total: {
                  without_refunds: 6055.26,
                  with_refunds: 5649.99,
                },
                gross_total: {
                  without_refunds: 6878.72,
                  with_refunds: 6434.24,
                },
                net_total_spread: {
                  without_refunds: 19173.81,
                  with_refunds: 18974.6,
                },
                gross_total_spread: {
                  without_refunds: 21684.79,
                  with_refunds: 21465.31,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5211,
                  with_refunds: 0.5206,
                },
                gross_mrr: {
                  without_refunds: 0.5166,
                  with_refunds: 0.5166,
                },
                net_arr: {
                  without_refunds: 0.0022,
                },
                gross_arr: {
                  without_refunds: 0.0022,
                },
                net_arr_spread: {
                  without_refunds: 2.281,
                  with_refunds: 2.281,
                },
                gross_arr_spread: {
                  without_refunds: 2.258,
                  with_refunds: 2.258,
                },
                net_total: {
                  without_refunds: 0.0586,
                  with_refunds: 0.0606,
                },
                gross_total: {
                  without_refunds: 0.0587,
                  with_refunds: 0.061,
                },
                net_total_spread: {
                  without_refunds: 1.1235,
                  with_refunds: 1.1366,
                },
                gross_total_spread: {
                  without_refunds: 1.11,
                  with_refunds: 1.1232,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7955,
                  with_refunds: 0.7881,
                },
                gross_mrr: {
                  without_refunds: 0.7927,
                  with_refunds: 0.7862,
                },
                net_arr: {
                  without_refunds: 0.1837,
                },
                gross_arr: {
                  without_refunds: 0.1554,
                },
                net_arr_spread: {
                  without_refunds: 1.0013,
                  with_refunds: 1.0013,
                },
                gross_arr_spread: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                net_total: {
                  without_refunds: 0.7145,
                  with_refunds: 0.7881,
                },
                gross_total: {
                  without_refunds: 0.699,
                  with_refunds: 0.7749,
                },
                net_total_spread: {
                  without_refunds: 0.9281,
                  with_refunds: 0.9266,
                },
                gross_total_spread: {
                  without_refunds: 0.9265,
                  with_refunds: 0.9254,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.19,
                  with_refunds: 4.05,
                },
                gross_mrr: {
                  without_refunds: 4.77,
                  with_refunds: 4.61,
                },
                net_arr: {
                  without_refunds: 0.11,
                },
                gross_arr: {
                  without_refunds: 0.12,
                },
                net_arr_spread: {
                  without_refunds: 9.51,
                  with_refunds: 9.51,
                },
                gross_arr_spread: {
                  without_refunds: 10.73,
                  with_refunds: 10.73,
                },
                net_total: {
                  without_refunds: 1.85,
                  with_refunds: 1.73,
                },
                gross_total: {
                  without_refunds: 2.11,
                  with_refunds: 1.97,
                },
                net_total_spread: {
                  without_refunds: 6.89,
                  with_refunds: 6.82,
                },
                gross_total_spread: {
                  without_refunds: 7.8,
                  with_refunds: 7.72,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.83,
                  with_refunds: 12.34,
                },
                gross_mrr: {
                  without_refunds: 14.59,
                  with_refunds: 14.05,
                },
                net_arr: {
                  without_refunds: 103.03,
                },
                gross_arr: {
                  without_refunds: 112.5,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 13.22,
                  with_refunds: 12.23,
                },
                gross_total: {
                  without_refunds: 15.02,
                  with_refunds: 13.93,
                },
                net_total_spread: {
                  without_refunds: 10.38,
                  with_refunds: 10.26,
                },
                gross_total_spread: {
                  without_refunds: 11.74,
                  with_refunds: 11.61,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.25,
                    with_refunds: 32.6,
                  },
                  gross_mrr: {
                    without_refunds: 39.18,
                    with_refunds: 37.37,
                  },
                  net_arr: {
                    without_refunds: 115.79,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 129.76,
                    with_refunds: 97.68,
                  },
                  net_arr_spread: {
                    without_refunds: 41.98,
                    with_refunds: 41.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.38,
                    with_refunds: 47.38,
                  },
                  net_total: {
                    without_refunds: 81.64,
                    with_refunds: 64.23,
                  },
                  gross_total: {
                    without_refunds: 91.82,
                    with_refunds: 72.49,
                  },
                  net_total_spread: {
                    without_refunds: 38.33,
                    with_refunds: 37.51,
                  },
                  gross_total_spread: {
                    without_refunds: 43.52,
                    with_refunds: 42.61,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 64.56,
                    with_refunds: 61.34,
                  },
                  gross_mrr: {
                    without_refunds: 73.77,
                    with_refunds: 70.21,
                  },
                  net_arr: {
                    without_refunds: 562.49,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 658.97,
                    with_refunds: 200.17,
                  },
                  net_arr_spread: {
                    without_refunds: 47.35,
                    with_refunds: 47.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 53.49,
                    with_refunds: 53.49,
                  },
                  net_total: {
                    without_refunds: 170.61,
                    with_refunds: 132.95,
                  },
                  gross_total: {
                    without_refunds: 192.92,
                    with_refunds: 150.54,
                  },
                  net_total_spread: {
                    without_refunds: 54.01,
                    with_refunds: 52.77,
                  },
                  gross_total_spread: {
                    without_refunds: 61.34,
                    with_refunds: 59.97,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5176,
                    with_refunds: 0.4926,
                  },
                  gross_mrr: {
                    without_refunds: 0.5919,
                    with_refunds: 0.5646,
                  },
                  net_arr: {
                    without_refunds: 1.7497,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9607,
                    with_refunds: 1.476,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6344,
                    with_refunds: 0.6344,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7159,
                    with_refunds: 0.7159,
                  },
                  net_total: {
                    without_refunds: 1.2336,
                    with_refunds: 0.9705,
                  },
                  gross_total: {
                    without_refunds: 1.3874,
                    with_refunds: 1.0953,
                  },
                  net_total_spread: {
                    without_refunds: 0.5792,
                    with_refunds: 0.5667,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6576,
                    with_refunds: 0.6439,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9754,
                    with_refunds: 0.9269,
                  },
                  gross_mrr: {
                    without_refunds: 1.1146,
                    with_refunds: 1.0609,
                  },
                  net_arr: {
                    without_refunds: 8.4993,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 9.9573,
                    with_refunds: 3.0247,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7155,
                    with_refunds: 0.7155,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8082,
                    with_refunds: 0.8082,
                  },
                  net_total: {
                    without_refunds: 2.578,
                    with_refunds: 2.009,
                  },
                  gross_total: {
                    without_refunds: 2.915,
                    with_refunds: 2.2747,
                  },
                  net_total_spread: {
                    without_refunds: 0.8161,
                    with_refunds: 0.7974,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9269,
                    with_refunds: 0.9062,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 88,
                  with_refunds: 88,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1392,
                  with_refunds: 1392,
                },
                total_users: {
                  without_refunds: 89,
                  with_refunds: 89,
                },
                total_spread_users: {
                  without_refunds: 1480,
                  with_refunds: 1480,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0631,
                  with_refunds: 0.0631,
                },
                arr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0005,
                },
                arr_spread_users: {
                  without_refunds: 0.9936,
                  with_refunds: 0.9936,
                },
                total_users: {
                  without_refunds: 0.0273,
                  with_refunds: 0.0273,
                },
                total_spread_users: {
                  without_refunds: 0.5322,
                  with_refunds: 0.5322,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.193,
                  with_refunds: 0.1921,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                total_users: {
                  without_refunds: 0.1943,
                  with_refunds: 0.1926,
                },
                total_spread_users: {
                  without_refunds: 0.8013,
                  with_refunds: 0.8004,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1113.29,
                  with_refunds: 1113.29,
                },
                gross_mrr: {
                  without_refunds: 1266.55,
                  with_refunds: 1266.55,
                },
                net_arr: {
                  without_refunds: 98.1,
                  with_refunds: 98.1,
                },
                gross_arr: {
                  without_refunds: 124.94,
                  with_refunds: 124.94,
                },
                net_arr_spread: {
                  without_refunds: 13332.79,
                  with_refunds: 13332.79,
                },
                gross_arr_spread: {
                  without_refunds: 15041.48,
                  with_refunds: 15041.48,
                },
                net_total: {
                  without_refunds: 1211.39,
                  with_refunds: 1211.39,
                },
                gross_total: {
                  without_refunds: 1391.48,
                  with_refunds: 1391.48,
                },
                net_total_spread: {
                  without_refunds: 14446.08,
                  with_refunds: 14446.08,
                },
                gross_total_spread: {
                  without_refunds: 16308.02,
                  with_refunds: 16308.02,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0992,
                  with_refunds: 0.1026,
                },
                gross_mrr: {
                  without_refunds: 0.0983,
                  with_refunds: 0.1017,
                },
                net_arr: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0012,
                },
                gross_arr: {
                  without_refunds: 0.0012,
                  with_refunds: 0.0013,
                },
                net_arr_spread: {
                  without_refunds: 2.2824,
                  with_refunds: 2.2824,
                },
                gross_arr_spread: {
                  without_refunds: 2.2596,
                  with_refunds: 2.2596,
                },
                net_total: {
                  without_refunds: 0.0117,
                  with_refunds: 0.013,
                },
                gross_total: {
                  without_refunds: 0.0119,
                  with_refunds: 0.0132,
                },
                net_total_spread: {
                  without_refunds: 0.8465,
                  with_refunds: 0.8653,
                },
                gross_total_spread: {
                  without_refunds: 0.8348,
                  with_refunds: 0.8533,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.1903,
                  with_refunds: 0.197,
                },
                gross_mrr: {
                  without_refunds: 0.1904,
                  with_refunds: 0.1968,
                },
                net_arr: {
                  without_refunds: 0.4761,
                  with_refunds: 0.0012,
                },
                gross_arr: {
                  without_refunds: 0.5553,
                  with_refunds: 1.0397,
                },
                net_arr_spread: {
                  without_refunds: 1.0006,
                  with_refunds: 1.0006,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.2001,
                  with_refunds: 0.2144,
                },
                gross_total: {
                  without_refunds: 0.2023,
                  with_refunds: 0.2163,
                },
                net_total_spread: {
                  without_refunds: 0.7534,
                  with_refunds: 0.7613,
                },
                gross_total_spread: {
                  without_refunds: 0.752,
                  with_refunds: 0.7597,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.8,
                  with_refunds: 0.8,
                },
                gross_mrr: {
                  without_refunds: 0.91,
                  with_refunds: 0.91,
                },
                net_arr: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                gross_arr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                net_arr_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.52,
                },
                gross_arr_spread: {
                  without_refunds: 10.74,
                  with_refunds: 10.74,
                },
                net_total: {
                  without_refunds: 0.37,
                  with_refunds: 0.37,
                },
                gross_total: {
                  without_refunds: 0.43,
                  with_refunds: 0.43,
                },
                net_total_spread: {
                  without_refunds: 5.19,
                  with_refunds: 5.19,
                },
                gross_total_spread: {
                  without_refunds: 5.86,
                  with_refunds: 5.86,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.65,
                  with_refunds: 12.65,
                },
                gross_mrr: {
                  without_refunds: 14.39,
                  with_refunds: 14.39,
                },
                net_arr: {
                  without_refunds: 98.1,
                  with_refunds: 98.1,
                },
                gross_arr: {
                  without_refunds: 124.94,
                  with_refunds: 124.94,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 13.61,
                  with_refunds: 13.61,
                },
                gross_total: {
                  without_refunds: 15.63,
                  with_refunds: 15.63,
                },
                net_total_spread: {
                  without_refunds: 9.76,
                  with_refunds: 9.76,
                },
                gross_total_spread: {
                  without_refunds: 11.02,
                  with_refunds: 11.02,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.05,
                    with_refunds: 33.4,
                  },
                  gross_mrr: {
                    without_refunds: 40.08,
                    with_refunds: 38.27,
                  },
                  net_arr: {
                    without_refunds: 115.85,
                    with_refunds: 86.98,
                  },
                  gross_arr: {
                    without_refunds: 129.82,
                    with_refunds: 97.74,
                  },
                  net_arr_spread: {
                    without_refunds: 51.5,
                    with_refunds: 51.5,
                  },
                  gross_arr_spread: {
                    without_refunds: 58.12,
                    with_refunds: 58.12,
                  },
                  net_total: {
                    without_refunds: 82.01,
                    with_refunds: 64.6,
                  },
                  gross_total: {
                    without_refunds: 92.25,
                    with_refunds: 72.91,
                  },
                  net_total_spread: {
                    without_refunds: 43.53,
                    with_refunds: 42.7,
                  },
                  gross_total_spread: {
                    without_refunds: 49.38,
                    with_refunds: 48.48,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 77.21,
                    with_refunds: 73.99,
                  },
                  gross_mrr: {
                    without_refunds: 88.16,
                    with_refunds: 84.6,
                  },
                  net_arr: {
                    without_refunds: 660.59,
                    with_refunds: 269.53,
                  },
                  gross_arr: {
                    without_refunds: 783.91,
                    with_refunds: 325.11,
                  },
                  net_arr_spread: {
                    without_refunds: 56.93,
                    with_refunds: 56.93,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.29,
                    with_refunds: 64.29,
                  },
                  net_total: {
                    without_refunds: 184.22,
                    with_refunds: 146.56,
                  },
                  gross_total: {
                    without_refunds: 208.55,
                    with_refunds: 166.17,
                  },
                  net_total_spread: {
                    without_refunds: 63.77,
                    with_refunds: 62.53,
                  },
                  gross_total_spread: {
                    without_refunds: 72.36,
                    with_refunds: 70.99,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5296,
                    with_refunds: 0.5047,
                  },
                  gross_mrr: {
                    without_refunds: 0.6057,
                    with_refunds: 0.5783,
                  },
                  net_arr: {
                    without_refunds: 1.7505,
                    with_refunds: 1.3142,
                  },
                  gross_arr: {
                    without_refunds: 1.9617,
                    with_refunds: 1.477,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7782,
                    with_refunds: 0.7782,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8782,
                    with_refunds: 0.8782,
                  },
                  net_total: {
                    without_refunds: 1.2392,
                    with_refunds: 0.9761,
                  },
                  gross_total: {
                    without_refunds: 1.3939,
                    with_refunds: 1.1017,
                  },
                  net_total_spread: {
                    without_refunds: 0.6577,
                    with_refunds: 0.6452,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7462,
                    with_refunds: 0.7325,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1666,
                    with_refunds: 1.1181,
                  },
                  gross_mrr: {
                    without_refunds: 1.3321,
                    with_refunds: 1.2784,
                  },
                  net_arr: {
                    without_refunds: 9.9817,
                    with_refunds: 4.0727,
                  },
                  gross_arr: {
                    without_refunds: 11.8451,
                    with_refunds: 4.9125,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8602,
                    with_refunds: 0.8602,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9715,
                    with_refunds: 0.9715,
                  },
                  net_total: {
                    without_refunds: 2.7837,
                    with_refunds: 2.2146,
                  },
                  gross_total: {
                    without_refunds: 3.1513,
                    with_refunds: 2.5109,
                  },
                  net_total_spread: {
                    without_refunds: 0.9636,
                    with_refunds: 0.9449,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0934,
                    with_refunds: 1.0727,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 872,
              with_refunds: 872,
            },
            returning_users: {
              without_refunds: 6671,
              with_refunds: 6706,
            },
            paying_users: {
              without_refunds: 7543,
              with_refunds: 7578,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 850,
              with_refunds: 850,
            },
            returning_users: {
              without_refunds: 888,
              with_refunds: 922,
            },
            paying_users: {
              without_refunds: 1738,
              with_refunds: 1772,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1719,
              with_refunds: 1719,
            },
            returning_users: {
              without_refunds: 7548,
              with_refunds: 7601,
            },
            paying_users: {
              without_refunds: 9267,
              with_refunds: 9320,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 48894.58,
                with_refunds: 46595.16,
              },
              gross_mrr: {
                without_refunds: 55916.02,
                with_refunds: 53391.38,
              },
              net_arr: {
                without_refunds: 218949.46,
                with_refunds: 164382.99,
              },
              gross_arr: {
                without_refunds: 245363.66,
                with_refunds: 184737.43,
              },
              net_arr_spread: {
                without_refunds: 72152.94,
                with_refunds: 72152.94,
              },
              gross_arr_spread: {
                without_refunds: 81421.94,
                with_refunds: 81421.94,
              },
              net_total: {
                without_refunds: 267844.04,
                with_refunds: 210978.15,
              },
              gross_total: {
                without_refunds: 301279.67,
                with_refunds: 238128.81,
              },
              net_total_spread: {
                without_refunds: 121047.52,
                with_refunds: 118748.1,
              },
              gross_total_spread: {
                without_refunds: 137337.96,
                with_refunds: 134813.32,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 48894.58,
                with_refunds: 46595.16,
              },
              gross_mrr: {
                without_refunds: 55916.02,
                with_refunds: 53391.38,
              },
              net_arr: {
                without_refunds: 218949.46,
                with_refunds: 164382.99,
              },
              gross_arr: {
                without_refunds: 245363.66,
                with_refunds: 184737.43,
              },
              net_arr_spread: {
                without_refunds: 72152.94,
                with_refunds: 72152.94,
              },
              gross_arr_spread: {
                without_refunds: 81421.94,
                with_refunds: 81421.94,
              },
              net_total: {
                without_refunds: 267844.04,
                with_refunds: 210978.15,
              },
              gross_total: {
                without_refunds: 301279.67,
                with_refunds: 238128.81,
              },
              net_total_spread: {
                without_refunds: 121047.52,
                with_refunds: 118748.1,
              },
              gross_total_spread: {
                without_refunds: 137337.96,
                with_refunds: 134813.32,
              },
            },
          },
        },
      },
      cac: 66.18,
      mrr_ua_spend: {
        without_refunds: 92321.1,
        with_refunds: 92321.1,
      },
      arr_ua_spend: {
        without_refunds: 125080.2,
        with_refunds: 125080.2,
      },
      arr_spread_ua_spend: {
        without_refunds: 92718.18,
        with_refunds: 92718.18,
      },
      total_ua_spend: {
        without_refunds: 216143.88,
        with_refunds: 216143.88,
      },
      total_spread_ua_spend: {
        without_refunds: 184046.58,
        with_refunds: 184046.58,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 3,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 421,
        with_refunds: 421,
      },
      first_period_arr_users: {
        without_refunds: 1951,
        with_refunds: 1951,
      },
      first_period_arr_spread_users: {
        without_refunds: 1471,
        with_refunds: 1471,
      },
      first_period_total_users: {
        without_refunds: 2362,
        with_refunds: 2362,
      },
      first_period_total_spread_users: {
        without_refunds: 1887,
        with_refunds: 1887,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 328,
                  with_refunds: 328,
                },
                arr_users: {
                  without_refunds: 1071,
                  with_refunds: 1071,
                },
                arr_spread_users: {
                  without_refunds: 770,
                  with_refunds: 770,
                },
                total_users: {
                  without_refunds: 1397,
                  with_refunds: 1397,
                },
                total_spread_users: {
                  without_refunds: 1097,
                  with_refunds: 1097,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7791,
                  with_refunds: 0.7791,
                },
                arr_users: {
                  without_refunds: 0.5489,
                  with_refunds: 0.5489,
                },
                arr_spread_users: {
                  without_refunds: 0.5235,
                  with_refunds: 0.5235,
                },
                total_users: {
                  without_refunds: 0.5914,
                  with_refunds: 0.5914,
                },
                total_spread_users: {
                  without_refunds: 0.5813,
                  with_refunds: 0.5813,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3689,
                  with_refunds: 3439.79,
                },
                gross_mrr: {
                  without_refunds: 4039.63,
                  with_refunds: 3769.65,
                },
                net_arr: {
                  without_refunds: 131949.58,
                  with_refunds: 104461.85,
                },
                gross_arr: {
                  without_refunds: 147789.71,
                  with_refunds: 117444.96,
                },
                net_arr_spread: {
                  without_refunds: 7799.79,
                  with_refunds: 7799.79,
                },
                gross_arr_spread: {
                  without_refunds: 8808.63,
                  with_refunds: 8808.63,
                },
                net_total: {
                  without_refunds: 135638.58,
                  with_refunds: 107901.64,
                },
                gross_total: {
                  without_refunds: 151829.35,
                  with_refunds: 121214.61,
                },
                net_total_spread: {
                  without_refunds: 11488.79,
                  with_refunds: 11239.58,
                },
                gross_total_spread: {
                  without_refunds: 12848.26,
                  with_refunds: 12578.28,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.76,
                  with_refunds: 8.17,
                },
                gross_mrr: {
                  without_refunds: 9.6,
                  with_refunds: 8.95,
                },
                net_arr: {
                  without_refunds: 67.63,
                  with_refunds: 53.54,
                },
                gross_arr: {
                  without_refunds: 75.75,
                  with_refunds: 60.2,
                },
                net_arr_spread: {
                  without_refunds: 5.3,
                  with_refunds: 5.3,
                },
                gross_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                net_total: {
                  without_refunds: 57.43,
                  with_refunds: 45.68,
                },
                gross_total: {
                  without_refunds: 64.28,
                  with_refunds: 51.32,
                },
                net_total_spread: {
                  without_refunds: 6.09,
                  with_refunds: 5.96,
                },
                gross_total_spread: {
                  without_refunds: 6.81,
                  with_refunds: 6.67,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.25,
                  with_refunds: 10.49,
                },
                gross_mrr: {
                  without_refunds: 12.32,
                  with_refunds: 11.49,
                },
                net_arr: {
                  without_refunds: 123.2,
                  with_refunds: 97.54,
                },
                gross_arr: {
                  without_refunds: 137.99,
                  with_refunds: 109.66,
                },
                net_arr_spread: {
                  without_refunds: 10.13,
                  with_refunds: 10.13,
                },
                gross_arr_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.44,
                },
                net_total: {
                  without_refunds: 97.09,
                  with_refunds: 77.24,
                },
                gross_total: {
                  without_refunds: 108.68,
                  with_refunds: 86.77,
                },
                net_total_spread: {
                  without_refunds: 10.47,
                  with_refunds: 10.25,
                },
                gross_total_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.47,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 8.76,
                    with_refunds: 8.17,
                  },
                  gross_mrr: {
                    without_refunds: 9.6,
                    with_refunds: 8.95,
                  },
                  net_arr: {
                    without_refunds: 67.63,
                    with_refunds: 53.54,
                  },
                  gross_arr: {
                    without_refunds: 75.75,
                    with_refunds: 60.2,
                  },
                  net_arr_spread: {
                    without_refunds: 5.3,
                    with_refunds: 5.3,
                  },
                  gross_arr_spread: {
                    without_refunds: 5.99,
                    with_refunds: 5.99,
                  },
                  net_total: {
                    without_refunds: 57.43,
                    with_refunds: 45.68,
                  },
                  gross_total: {
                    without_refunds: 64.28,
                    with_refunds: 51.32,
                  },
                  net_total_spread: {
                    without_refunds: 6.09,
                    with_refunds: 5.96,
                  },
                  gross_total_spread: {
                    without_refunds: 6.81,
                    with_refunds: 6.67,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 11.25,
                    with_refunds: 10.49,
                  },
                  gross_mrr: {
                    without_refunds: 12.32,
                    with_refunds: 11.49,
                  },
                  net_arr: {
                    without_refunds: 123.2,
                    with_refunds: 97.54,
                  },
                  gross_arr: {
                    without_refunds: 137.99,
                    with_refunds: 109.66,
                  },
                  net_arr_spread: {
                    without_refunds: 10.13,
                    with_refunds: 10.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 11.44,
                    with_refunds: 11.44,
                  },
                  net_total: {
                    without_refunds: 97.09,
                    with_refunds: 77.24,
                  },
                  gross_total: {
                    without_refunds: 108.68,
                    with_refunds: 86.77,
                  },
                  net_total_spread: {
                    without_refunds: 10.47,
                    with_refunds: 10.25,
                  },
                  gross_total_spread: {
                    without_refunds: 11.71,
                    with_refunds: 11.47,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0669,
                    with_refunds: 0.0624,
                  },
                  gross_mrr: {
                    without_refunds: 0.0733,
                    with_refunds: 0.0684,
                  },
                  net_arr: {
                    without_refunds: 0.5163,
                    with_refunds: 0.4088,
                  },
                  gross_arr: {
                    without_refunds: 0.5783,
                    with_refunds: 0.4596,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0405,
                    with_refunds: 0.0405,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0457,
                    with_refunds: 0.0457,
                  },
                  net_total: {
                    without_refunds: 0.4384,
                    with_refunds: 0.3487,
                  },
                  gross_total: {
                    without_refunds: 0.4907,
                    with_refunds: 0.3918,
                  },
                  net_total_spread: {
                    without_refunds: 0.0465,
                    with_refunds: 0.0455,
                  },
                  gross_total_spread: {
                    without_refunds: 0.052,
                    with_refunds: 0.0509,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0859,
                    with_refunds: 0.0801,
                  },
                  gross_mrr: {
                    without_refunds: 0.094,
                    with_refunds: 0.0877,
                  },
                  net_arr: {
                    without_refunds: 0.9405,
                    with_refunds: 0.7446,
                  },
                  gross_arr: {
                    without_refunds: 1.0535,
                    with_refunds: 0.8372,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0773,
                    with_refunds: 0.0773,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0873,
                    with_refunds: 0.0873,
                  },
                  net_total: {
                    without_refunds: 0.7412,
                    with_refunds: 0.5896,
                  },
                  gross_total: {
                    without_refunds: 0.8297,
                    with_refunds: 0.6624,
                  },
                  net_total_spread: {
                    without_refunds: 0.08,
                    with_refunds: 0.0782,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0894,
                    with_refunds: 0.0875,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 271,
                  with_refunds: 276,
                },
                arr_users: {
                  without_refunds: 862,
                  with_refunds: 932,
                },
                arr_spread_users: {
                  without_refunds: 1452,
                  with_refunds: 1452,
                },
                total_users: {
                  without_refunds: 1132,
                  with_refunds: 1207,
                },
                total_spread_users: {
                  without_refunds: 1723,
                  with_refunds: 1728,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6437,
                  with_refunds: 0.6556,
                },
                arr_users: {
                  without_refunds: 0.4418,
                  with_refunds: 0.4777,
                },
                arr_spread_users: {
                  without_refunds: 0.9871,
                  with_refunds: 0.9871,
                },
                total_users: {
                  without_refunds: 0.4793,
                  with_refunds: 0.511,
                },
                total_spread_users: {
                  without_refunds: 0.9131,
                  with_refunds: 0.9157,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8262,
                  with_refunds: 0.8415,
                },
                arr_users: {
                  without_refunds: 0.8049,
                  with_refunds: 0.8702,
                },
                arr_spread_users: {
                  without_refunds: 1.8857,
                  with_refunds: 1.8857,
                },
                total_users: {
                  without_refunds: 0.8103,
                  with_refunds: 0.864,
                },
                total_spread_users: {
                  without_refunds: 1.5706,
                  with_refunds: 1.5752,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3141.71,
                  with_refunds: 3018.84,
                },
                gross_mrr: {
                  without_refunds: 3506.29,
                  with_refunds: 3365.43,
                },
                net_arr: {
                  without_refunds: 120857.28,
                  with_refunds: 88945.07,
                },
                gross_arr: {
                  without_refunds: 133454.49,
                  with_refunds: 98651.39,
                },
                net_arr_spread: {
                  without_refunds: 15791.12,
                  with_refunds: 15791.12,
                },
                gross_arr_spread: {
                  without_refunds: 17645.47,
                  with_refunds: 17645.47,
                },
                net_total: {
                  without_refunds: 123998.99,
                  with_refunds: 91963.91,
                },
                gross_total: {
                  without_refunds: 136960.78,
                  with_refunds: 102016.82,
                },
                net_total_spread: {
                  without_refunds: 18932.83,
                  with_refunds: 18809.96,
                },
                gross_total_spread: {
                  without_refunds: 21151.76,
                  with_refunds: 21010.9,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8516,
                  with_refunds: 0.8776,
                },
                gross_mrr: {
                  without_refunds: 0.868,
                  with_refunds: 0.8928,
                },
                net_arr: {
                  without_refunds: 0.9159,
                  with_refunds: 0.8515,
                },
                gross_arr: {
                  without_refunds: 0.903,
                  with_refunds: 0.84,
                },
                net_arr_spread: {
                  without_refunds: 2.0246,
                  with_refunds: 2.0246,
                },
                gross_arr_spread: {
                  without_refunds: 2.0032,
                  with_refunds: 2.0032,
                },
                net_total: {
                  without_refunds: 0.9142,
                  with_refunds: 0.8523,
                },
                gross_total: {
                  without_refunds: 0.9021,
                  with_refunds: 0.8416,
                },
                net_total_spread: {
                  without_refunds: 1.6479,
                  with_refunds: 1.6735,
                },
                gross_total_spread: {
                  without_refunds: 1.6463,
                  with_refunds: 1.6704,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8516,
                  with_refunds: 0.8776,
                },
                gross_mrr: {
                  without_refunds: 0.868,
                  with_refunds: 0.8928,
                },
                net_arr: {
                  without_refunds: 0.9159,
                  with_refunds: 0.8515,
                },
                gross_arr: {
                  without_refunds: 0.903,
                  with_refunds: 0.84,
                },
                net_arr_spread: {
                  without_refunds: 2.0246,
                  with_refunds: 2.0246,
                },
                gross_arr_spread: {
                  without_refunds: 2.0032,
                  with_refunds: 2.0032,
                },
                net_total: {
                  without_refunds: 0.9142,
                  with_refunds: 0.8523,
                },
                gross_total: {
                  without_refunds: 0.9021,
                  with_refunds: 0.8416,
                },
                net_total_spread: {
                  without_refunds: 1.6479,
                  with_refunds: 1.6735,
                },
                gross_total_spread: {
                  without_refunds: 1.6463,
                  with_refunds: 1.6704,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.46,
                  with_refunds: 7.17,
                },
                gross_mrr: {
                  without_refunds: 8.33,
                  with_refunds: 7.99,
                },
                net_arr: {
                  without_refunds: 61.95,
                  with_refunds: 45.59,
                },
                gross_arr: {
                  without_refunds: 68.4,
                  with_refunds: 50.56,
                },
                net_arr_spread: {
                  without_refunds: 10.73,
                  with_refunds: 10.73,
                },
                gross_arr_spread: {
                  without_refunds: 12,
                  with_refunds: 12,
                },
                net_total: {
                  without_refunds: 52.5,
                  with_refunds: 38.93,
                },
                gross_total: {
                  without_refunds: 57.99,
                  with_refunds: 43.19,
                },
                net_total_spread: {
                  without_refunds: 10.03,
                  with_refunds: 9.97,
                },
                gross_total_spread: {
                  without_refunds: 11.21,
                  with_refunds: 11.13,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.59,
                  with_refunds: 10.94,
                },
                gross_mrr: {
                  without_refunds: 12.94,
                  with_refunds: 12.19,
                },
                net_arr: {
                  without_refunds: 140.21,
                  with_refunds: 95.43,
                },
                gross_arr: {
                  without_refunds: 154.82,
                  with_refunds: 105.85,
                },
                net_arr_spread: {
                  without_refunds: 10.88,
                  with_refunds: 10.88,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 109.54,
                  with_refunds: 76.19,
                },
                gross_total: {
                  without_refunds: 120.99,
                  with_refunds: 84.52,
                },
                net_total_spread: {
                  without_refunds: 10.99,
                  with_refunds: 10.89,
                },
                gross_total_spread: {
                  without_refunds: 12.28,
                  with_refunds: 12.16,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 16.22,
                    with_refunds: 15.34,
                  },
                  gross_mrr: {
                    without_refunds: 17.92,
                    with_refunds: 16.95,
                  },
                  net_arr: {
                    without_refunds: 129.58,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 144.15,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 16.04,
                    with_refunds: 16.04,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.98,
                    with_refunds: 17.98,
                  },
                  net_total: {
                    without_refunds: 109.92,
                    with_refunds: 84.62,
                  },
                  gross_total: {
                    without_refunds: 122.27,
                    with_refunds: 94.51,
                  },
                  net_total_spread: {
                    without_refunds: 16.12,
                    with_refunds: 15.92,
                  },
                  gross_total_spread: {
                    without_refunds: 18.02,
                    with_refunds: 17.8,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.84,
                    with_refunds: 21.42,
                  },
                  gross_mrr: {
                    without_refunds: 25.25,
                    with_refunds: 23.69,
                  },
                  net_arr: {
                    without_refunds: 263.41,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 292.81,
                    with_refunds: 215.51,
                  },
                  net_arr_spread: {
                    without_refunds: 21.01,
                    with_refunds: 21.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 23.59,
                    with_refunds: 23.59,
                  },
                  net_total: {
                    without_refunds: 206.63,
                    with_refunds: 153.43,
                  },
                  gross_total: {
                    without_refunds: 229.67,
                    with_refunds: 171.29,
                  },
                  net_total_spread: {
                    without_refunds: 21.46,
                    with_refunds: 21.13,
                  },
                  gross_total_spread: {
                    without_refunds: 23.99,
                    with_refunds: 23.63,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1239,
                    with_refunds: 0.1171,
                  },
                  gross_mrr: {
                    without_refunds: 0.1368,
                    with_refunds: 0.1294,
                  },
                  net_arr: {
                    without_refunds: 0.9892,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1005,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1224,
                    with_refunds: 0.1224,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1373,
                    with_refunds: 0.1373,
                  },
                  net_total: {
                    without_refunds: 0.8392,
                    with_refunds: 0.646,
                  },
                  gross_total: {
                    without_refunds: 0.9334,
                    with_refunds: 0.7215,
                  },
                  net_total_spread: {
                    without_refunds: 0.1231,
                    with_refunds: 0.1216,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1376,
                    with_refunds: 0.1359,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1744,
                    with_refunds: 0.1636,
                  },
                  gross_mrr: {
                    without_refunds: 0.1928,
                    with_refunds: 0.1808,
                  },
                  net_arr: {
                    without_refunds: 2.0109,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 2.2354,
                    with_refunds: 1.6452,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1604,
                    with_refunds: 0.1604,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1801,
                    with_refunds: 0.1801,
                  },
                  net_total: {
                    without_refunds: 1.5775,
                    with_refunds: 1.1713,
                  },
                  gross_total: {
                    without_refunds: 1.7534,
                    with_refunds: 1.3076,
                  },
                  net_total_spread: {
                    without_refunds: 0.1638,
                    with_refunds: 0.1613,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1831,
                    with_refunds: 0.1804,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 211,
                  with_refunds: 213,
                },
                arr_users: {
                  without_refunds: 17,
                  with_refunds: 41,
                },
                arr_spread_users: {
                  without_refunds: 1462,
                  with_refunds: 1462,
                },
                total_users: {
                  without_refunds: 228,
                  with_refunds: 254,
                },
                total_spread_users: {
                  without_refunds: 1673,
                  with_refunds: 1675,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5012,
                  with_refunds: 0.5059,
                },
                arr_users: {
                  without_refunds: 0.0087,
                  with_refunds: 0.021,
                },
                arr_spread_users: {
                  without_refunds: 0.9939,
                  with_refunds: 0.9939,
                },
                total_users: {
                  without_refunds: 0.0965,
                  with_refunds: 0.1075,
                },
                total_spread_users: {
                  without_refunds: 0.8866,
                  with_refunds: 0.8877,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7786,
                  with_refunds: 0.7717,
                },
                arr_users: {
                  without_refunds: 0.0197,
                  with_refunds: 0.044,
                },
                arr_spread_users: {
                  without_refunds: 1.0069,
                  with_refunds: 1.0069,
                },
                total_users: {
                  without_refunds: 0.2014,
                  with_refunds: 0.2104,
                },
                total_spread_users: {
                  without_refunds: 0.971,
                  with_refunds: 0.9693,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2595.96,
                  with_refunds: 2459.7,
                },
                gross_mrr: {
                  without_refunds: 2955.2,
                  with_refunds: 2789.39,
                },
                net_arr: {
                  without_refunds: 2256.74,
                },
                gross_arr: {
                  without_refunds: 2683.58,
                },
                net_arr_spread: {
                  without_refunds: 15887.05,
                  with_refunds: 15887.05,
                },
                gross_arr_spread: {
                  without_refunds: 17763.93,
                  with_refunds: 17763.93,
                },
                net_total: {
                  without_refunds: 4852.69,
                  with_refunds: 2459.7,
                },
                gross_total: {
                  without_refunds: 5638.78,
                  with_refunds: 2789.39,
                },
                net_total_spread: {
                  without_refunds: 18483.01,
                  with_refunds: 18346.75,
                },
                gross_total_spread: {
                  without_refunds: 20719.13,
                  with_refunds: 20553.32,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7037,
                  with_refunds: 0.7151,
                },
                gross_mrr: {
                  without_refunds: 0.7316,
                  with_refunds: 0.74,
                },
                net_arr: {
                  without_refunds: 0.0171,
                },
                gross_arr: {
                  without_refunds: 0.0182,
                },
                net_arr_spread: {
                  without_refunds: 2.0369,
                  with_refunds: 2.0369,
                },
                gross_arr_spread: {
                  without_refunds: 2.0167,
                  with_refunds: 2.0167,
                },
                net_total: {
                  without_refunds: 0.0358,
                  with_refunds: 0.0228,
                },
                gross_total: {
                  without_refunds: 0.0371,
                  with_refunds: 0.023,
                },
                net_total_spread: {
                  without_refunds: 1.6088,
                  with_refunds: 1.6323,
                },
                gross_total_spread: {
                  without_refunds: 1.6126,
                  with_refunds: 1.634,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8263,
                  with_refunds: 0.8148,
                },
                gross_mrr: {
                  without_refunds: 0.8428,
                  with_refunds: 0.8288,
                },
                net_arr: {
                  without_refunds: 0.0187,
                },
                gross_arr: {
                  without_refunds: 0.0201,
                },
                net_arr_spread: {
                  without_refunds: 1.0061,
                  with_refunds: 1.0061,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.0391,
                  with_refunds: 0.0267,
                },
                gross_total: {
                  without_refunds: 0.0412,
                  with_refunds: 0.0273,
                },
                net_total_spread: {
                  without_refunds: 0.9762,
                  with_refunds: 0.9754,
                },
                gross_total_spread: {
                  without_refunds: 0.9795,
                  with_refunds: 0.9782,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.17,
                  with_refunds: 5.84,
                },
                gross_mrr: {
                  without_refunds: 7.02,
                  with_refunds: 6.63,
                },
                net_arr: {
                  without_refunds: 1.16,
                },
                gross_arr: {
                  without_refunds: 1.38,
                },
                net_arr_spread: {
                  without_refunds: 10.8,
                  with_refunds: 10.8,
                },
                gross_arr_spread: {
                  without_refunds: 12.08,
                  with_refunds: 12.08,
                },
                net_total: {
                  without_refunds: 2.05,
                  with_refunds: 1.04,
                },
                gross_total: {
                  without_refunds: 2.39,
                  with_refunds: 1.18,
                },
                net_total_spread: {
                  without_refunds: 9.79,
                  with_refunds: 9.72,
                },
                gross_total_spread: {
                  without_refunds: 10.98,
                  with_refunds: 10.89,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.3,
                  with_refunds: 11.55,
                },
                gross_mrr: {
                  without_refunds: 14.01,
                  with_refunds: 13.1,
                },
                net_arr: {
                  without_refunds: 132.75,
                },
                gross_arr: {
                  without_refunds: 157.86,
                },
                net_arr_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.87,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 21.28,
                  with_refunds: 9.68,
                },
                gross_total: {
                  without_refunds: 24.73,
                  with_refunds: 10.98,
                },
                net_total_spread: {
                  without_refunds: 11.05,
                  with_refunds: 10.95,
                },
                gross_total_spread: {
                  without_refunds: 12.38,
                  with_refunds: 12.27,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.39,
                    with_refunds: 21.18,
                  },
                  gross_mrr: {
                    without_refunds: 24.94,
                    with_refunds: 23.57,
                  },
                  net_arr: {
                    without_refunds: 130.73,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.53,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 26.84,
                    with_refunds: 26.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.06,
                    with_refunds: 30.06,
                  },
                  net_total: {
                    without_refunds: 111.98,
                    with_refunds: 85.66,
                  },
                  gross_total: {
                    without_refunds: 124.65,
                    with_refunds: 95.69,
                  },
                  net_total_spread: {
                    without_refunds: 25.92,
                    with_refunds: 25.65,
                  },
                  gross_total_spread: {
                    without_refunds: 29,
                    with_refunds: 28.69,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.14,
                    with_refunds: 32.97,
                  },
                  gross_mrr: {
                    without_refunds: 39.26,
                    with_refunds: 36.78,
                  },
                  net_arr: {
                    without_refunds: 396.16,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 450.67,
                    with_refunds: 215.51,
                  },
                  net_arr_spread: {
                    without_refunds: 31.87,
                    with_refunds: 31.87,
                  },
                  gross_arr_spread: {
                    without_refunds: 35.74,
                    with_refunds: 35.74,
                  },
                  net_total: {
                    without_refunds: 227.92,
                    with_refunds: 163.11,
                  },
                  gross_total: {
                    without_refunds: 254.4,
                    with_refunds: 182.27,
                  },
                  net_total_spread: {
                    without_refunds: 32.51,
                    with_refunds: 32.08,
                  },
                  gross_total_spread: {
                    without_refunds: 36.37,
                    with_refunds: 35.9,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1709,
                    with_refunds: 0.1617,
                  },
                  gross_mrr: {
                    without_refunds: 0.1904,
                    with_refunds: 0.18,
                  },
                  net_arr: {
                    without_refunds: 0.9981,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.111,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2049,
                    with_refunds: 0.2049,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2295,
                    with_refunds: 0.2295,
                  },
                  net_total: {
                    without_refunds: 0.8549,
                    with_refunds: 0.6539,
                  },
                  gross_total: {
                    without_refunds: 0.9516,
                    with_refunds: 0.7305,
                  },
                  net_total_spread: {
                    without_refunds: 0.1979,
                    with_refunds: 0.1958,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2214,
                    with_refunds: 0.219,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2683,
                    with_refunds: 0.2517,
                  },
                  gross_mrr: {
                    without_refunds: 0.2997,
                    with_refunds: 0.2808,
                  },
                  net_arr: {
                    without_refunds: 3.0243,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 3.4405,
                    with_refunds: 1.6452,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2433,
                    with_refunds: 0.2433,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2729,
                    with_refunds: 0.2729,
                  },
                  net_total: {
                    without_refunds: 1.74,
                    with_refunds: 1.2452,
                  },
                  gross_total: {
                    without_refunds: 1.9422,
                    with_refunds: 1.3915,
                  },
                  net_total_spread: {
                    without_refunds: 0.2482,
                    with_refunds: 0.2449,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2777,
                    with_refunds: 0.274,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 172,
                  with_refunds: 173,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 1464,
                  with_refunds: 1464,
                },
                total_users: {
                  without_refunds: 175,
                  with_refunds: 179,
                },
                total_spread_users: {
                  without_refunds: 1636,
                  with_refunds: 1637,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4086,
                  with_refunds: 0.4109,
                },
                arr_users: {
                  without_refunds: 0.0015,
                  with_refunds: 0.0031,
                },
                arr_spread_users: {
                  without_refunds: 0.9952,
                  with_refunds: 0.9952,
                },
                total_users: {
                  without_refunds: 0.0741,
                  with_refunds: 0.0758,
                },
                total_spread_users: {
                  without_refunds: 0.867,
                  with_refunds: 0.8675,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8152,
                  with_refunds: 0.8122,
                },
                arr_users: {
                  without_refunds: 0.1765,
                  with_refunds: 0.1463,
                },
                arr_spread_users: {
                  without_refunds: 1.0014,
                  with_refunds: 1.0014,
                },
                total_users: {
                  without_refunds: 0.7675,
                  with_refunds: 0.7047,
                },
                total_spread_users: {
                  without_refunds: 0.9779,
                  with_refunds: 0.9773,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2152.03,
                  with_refunds: 2130.02,
                },
                gross_mrr: {
                  without_refunds: 2410.43,
                  with_refunds: 2384.53,
                },
                net_arr: {
                  without_refunds: 298.92,
                },
                gross_arr: {
                  without_refunds: 401.08,
                  with_refunds: 5.34,
                },
                net_arr_spread: {
                  without_refunds: 15900.74,
                  with_refunds: 15900.74,
                },
                gross_arr_spread: {
                  without_refunds: 17785.77,
                  with_refunds: 17785.77,
                },
                net_total: {
                  without_refunds: 2450.95,
                  with_refunds: 2130.02,
                },
                gross_total: {
                  without_refunds: 2811.51,
                  with_refunds: 2389.87,
                },
                net_total_spread: {
                  without_refunds: 18052.78,
                  with_refunds: 18030.76,
                },
                gross_total_spread: {
                  without_refunds: 20196.2,
                  with_refunds: 20170.29,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5834,
                  with_refunds: 0.6192,
                },
                gross_mrr: {
                  without_refunds: 0.5967,
                  with_refunds: 0.6326,
                },
                net_arr: {
                  without_refunds: 0.0023,
                },
                gross_arr: {
                  without_refunds: 0.0027,
                },
                net_arr_spread: {
                  without_refunds: 2.0386,
                  with_refunds: 2.0386,
                },
                gross_arr_spread: {
                  without_refunds: 2.0191,
                  with_refunds: 2.0191,
                },
                net_total: {
                  without_refunds: 0.0181,
                  with_refunds: 0.0197,
                },
                gross_total: {
                  without_refunds: 0.0185,
                  with_refunds: 0.0197,
                },
                net_total_spread: {
                  without_refunds: 1.5713,
                  with_refunds: 1.6042,
                },
                gross_total_spread: {
                  without_refunds: 1.5719,
                  with_refunds: 1.6036,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.829,
                  with_refunds: 0.866,
                },
                gross_mrr: {
                  without_refunds: 0.8157,
                  with_refunds: 0.8549,
                },
                net_arr: {
                  without_refunds: 0.1325,
                },
                gross_arr: {
                  without_refunds: 0.1495,
                  with_refunds: 0.0001,
                },
                net_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                gross_arr_spread: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                net_total: {
                  without_refunds: 0.5051,
                  with_refunds: 0.866,
                },
                gross_total: {
                  without_refunds: 0.4986,
                  with_refunds: 0.8568,
                },
                net_total_spread: {
                  without_refunds: 0.9767,
                  with_refunds: 0.9828,
                },
                gross_total_spread: {
                  without_refunds: 0.9748,
                  with_refunds: 0.9814,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.11,
                  with_refunds: 5.06,
                },
                gross_mrr: {
                  without_refunds: 5.73,
                  with_refunds: 5.66,
                },
                net_arr: {
                  without_refunds: 0.15,
                },
                gross_arr: {
                  without_refunds: 0.21,
                },
                net_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                gross_arr_spread: {
                  without_refunds: 12.09,
                  with_refunds: 12.09,
                },
                net_total: {
                  without_refunds: 1.04,
                  with_refunds: 0.9,
                },
                gross_total: {
                  without_refunds: 1.19,
                  with_refunds: 1.01,
                },
                net_total_spread: {
                  without_refunds: 9.57,
                  with_refunds: 9.56,
                },
                gross_total_spread: {
                  without_refunds: 10.7,
                  with_refunds: 10.69,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.51,
                  with_refunds: 12.31,
                },
                gross_mrr: {
                  without_refunds: 14.01,
                  with_refunds: 13.78,
                },
                net_arr: {
                  without_refunds: 99.64,
                },
                gross_arr: {
                  without_refunds: 133.69,
                  with_refunds: 0.89,
                },
                net_arr_spread: {
                  without_refunds: 10.86,
                  with_refunds: 10.86,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 14.01,
                  with_refunds: 11.9,
                },
                gross_total: {
                  without_refunds: 16.07,
                  with_refunds: 13.35,
                },
                net_total_spread: {
                  without_refunds: 11.03,
                  with_refunds: 11.01,
                },
                gross_total_spread: {
                  without_refunds: 12.34,
                  with_refunds: 12.32,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.5,
                    with_refunds: 26.24,
                  },
                  gross_mrr: {
                    without_refunds: 30.67,
                    with_refunds: 29.24,
                  },
                  net_arr: {
                    without_refunds: 130.89,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.73,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 37.65,
                    with_refunds: 37.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.15,
                    with_refunds: 42.15,
                  },
                  net_total: {
                    without_refunds: 113.01,
                    with_refunds: 86.56,
                  },
                  gross_total: {
                    without_refunds: 125.84,
                    with_refunds: 96.7,
                  },
                  net_total_spread: {
                    without_refunds: 35.48,
                    with_refunds: 35.2,
                  },
                  gross_total_spread: {
                    without_refunds: 39.7,
                    with_refunds: 39.38,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 47.65,
                    with_refunds: 45.29,
                  },
                  gross_mrr: {
                    without_refunds: 53.27,
                    with_refunds: 50.57,
                  },
                  net_arr: {
                    without_refunds: 495.8,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 584.36,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 42.73,
                    with_refunds: 42.73,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.89,
                    with_refunds: 47.89,
                  },
                  net_total: {
                    without_refunds: 241.92,
                    with_refunds: 175.01,
                  },
                  gross_total: {
                    without_refunds: 270.47,
                    with_refunds: 195.62,
                  },
                  net_total_spread: {
                    without_refunds: 43.54,
                    with_refunds: 43.1,
                  },
                  gross_total_spread: {
                    without_refunds: 48.72,
                    with_refunds: 48.22,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.21,
                    with_refunds: 0.2003,
                  },
                  gross_mrr: {
                    without_refunds: 0.2341,
                    with_refunds: 0.2232,
                  },
                  net_arr: {
                    without_refunds: 0.9992,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1126,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2874,
                    with_refunds: 0.2874,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3218,
                    with_refunds: 0.3218,
                  },
                  net_total: {
                    without_refunds: 0.8628,
                    with_refunds: 0.6608,
                  },
                  gross_total: {
                    without_refunds: 0.9607,
                    with_refunds: 0.7382,
                  },
                  net_total_spread: {
                    without_refunds: 0.2709,
                    with_refunds: 0.2687,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3031,
                    with_refunds: 0.3006,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3638,
                    with_refunds: 0.3457,
                  },
                  gross_mrr: {
                    without_refunds: 0.4067,
                    with_refunds: 0.386,
                  },
                  net_arr: {
                    without_refunds: 3.785,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 4.4611,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3262,
                    with_refunds: 0.3262,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3656,
                    with_refunds: 0.3656,
                  },
                  net_total: {
                    without_refunds: 1.8469,
                    with_refunds: 1.3361,
                  },
                  gross_total: {
                    without_refunds: 2.0648,
                    with_refunds: 1.4934,
                  },
                  net_total_spread: {
                    without_refunds: 0.3324,
                    with_refunds: 0.329,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3719,
                    with_refunds: 0.3681,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 15,
                  with_refunds: 15,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 1465,
                  with_refunds: 1465,
                },
                total_users: {
                  without_refunds: 16,
                  with_refunds: 17,
                },
                total_spread_users: {
                  without_refunds: 1480,
                  with_refunds: 1480,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0356,
                  with_refunds: 0.0356,
                },
                arr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.001,
                },
                arr_spread_users: {
                  without_refunds: 0.9959,
                  with_refunds: 0.9959,
                },
                total_users: {
                  without_refunds: 0.0068,
                  with_refunds: 0.0072,
                },
                total_spread_users: {
                  without_refunds: 0.7843,
                  with_refunds: 0.7843,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.0872,
                  with_refunds: 0.0867,
                },
                arr_users: {
                  without_refunds: 0.3333,
                  with_refunds: 0.3333,
                },
                arr_spread_users: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                total_users: {
                  without_refunds: 0.0914,
                  with_refunds: 0.095,
                },
                total_spread_users: {
                  without_refunds: 0.9046,
                  with_refunds: 0.9041,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 177.84,
                  with_refunds: 177.84,
                },
                gross_mrr: {
                  without_refunds: 218.16,
                  with_refunds: 218.16,
                },
                net_arr: {
                  without_refunds: 134.67,
                },
                gross_arr: {
                  without_refunds: 139,
                },
                net_arr_spread: {
                  without_refunds: 15911.96,
                  with_refunds: 15911.96,
                },
                gross_arr_spread: {
                  without_refunds: 17797.35,
                  with_refunds: 17797.35,
                },
                net_total: {
                  without_refunds: 312.51,
                  with_refunds: 177.84,
                },
                gross_total: {
                  without_refunds: 357.16,
                  with_refunds: 218.16,
                },
                net_total_spread: {
                  without_refunds: 16089.8,
                  with_refunds: 16089.8,
                },
                gross_total_spread: {
                  without_refunds: 18015.51,
                  with_refunds: 18015.51,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0482,
                  with_refunds: 0.0517,
                },
                gross_mrr: {
                  without_refunds: 0.054,
                  with_refunds: 0.0579,
                },
                net_arr: {
                  without_refunds: 0.001,
                },
                gross_arr: {
                  without_refunds: 0.0009,
                },
                net_arr_spread: {
                  without_refunds: 2.0401,
                  with_refunds: 2.0401,
                },
                gross_arr_spread: {
                  without_refunds: 2.0204,
                  with_refunds: 2.0204,
                },
                net_total: {
                  without_refunds: 0.0023,
                  with_refunds: 0.0016,
                },
                gross_total: {
                  without_refunds: 0.0024,
                  with_refunds: 0.0018,
                },
                net_total_spread: {
                  without_refunds: 1.4005,
                  with_refunds: 1.4315,
                },
                gross_total_spread: {
                  without_refunds: 1.4022,
                  with_refunds: 1.4323,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.0826,
                  with_refunds: 0.0835,
                },
                gross_mrr: {
                  without_refunds: 0.0905,
                  with_refunds: 0.0915,
                },
                net_arr: {
                  without_refunds: 0.4505,
                },
                gross_arr: {
                  without_refunds: 0.3466,
                },
                net_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.1275,
                  with_refunds: 0.0835,
                },
                gross_total: {
                  without_refunds: 0.127,
                  with_refunds: 0.0913,
                },
                net_total_spread: {
                  without_refunds: 0.8913,
                  with_refunds: 0.8924,
                },
                gross_total_spread: {
                  without_refunds: 0.892,
                  with_refunds: 0.8932,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.42,
                  with_refunds: 0.42,
                },
                gross_mrr: {
                  without_refunds: 0.52,
                  with_refunds: 0.52,
                },
                net_arr: {
                  without_refunds: 0.07,
                },
                gross_arr: {
                  without_refunds: 0.07,
                },
                net_arr_spread: {
                  without_refunds: 10.82,
                  with_refunds: 10.82,
                },
                gross_arr_spread: {
                  without_refunds: 12.1,
                  with_refunds: 12.1,
                },
                net_total: {
                  without_refunds: 0.13,
                  with_refunds: 0.08,
                },
                gross_total: {
                  without_refunds: 0.15,
                  with_refunds: 0.09,
                },
                net_total_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_total_spread: {
                  without_refunds: 9.55,
                  with_refunds: 9.55,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.86,
                  with_refunds: 11.86,
                },
                gross_mrr: {
                  without_refunds: 14.54,
                  with_refunds: 14.54,
                },
                net_arr: {
                  without_refunds: 134.67,
                },
                gross_arr: {
                  without_refunds: 139,
                },
                net_arr_spread: {
                  without_refunds: 10.86,
                  with_refunds: 10.86,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 19.53,
                  with_refunds: 10.46,
                },
                gross_total: {
                  without_refunds: 22.32,
                  with_refunds: 12.83,
                },
                net_total_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.87,
                },
                gross_total_spread: {
                  without_refunds: 12.17,
                  with_refunds: 12.17,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.93,
                    with_refunds: 26.67,
                  },
                  gross_mrr: {
                    without_refunds: 31.19,
                    with_refunds: 29.76,
                  },
                  net_arr: {
                    without_refunds: 130.96,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.81,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 48.46,
                    with_refunds: 48.46,
                  },
                  gross_arr_spread: {
                    without_refunds: 54.25,
                    with_refunds: 54.25,
                  },
                  net_total: {
                    without_refunds: 113.15,
                    with_refunds: 86.64,
                  },
                  gross_total: {
                    without_refunds: 125.99,
                    with_refunds: 96.79,
                  },
                  net_total_spread: {
                    without_refunds: 44.01,
                    with_refunds: 43.73,
                  },
                  gross_total_spread: {
                    without_refunds: 49.25,
                    with_refunds: 48.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 59.51,
                    with_refunds: 57.14,
                  },
                  gross_mrr: {
                    without_refunds: 67.82,
                    with_refunds: 65.11,
                  },
                  net_arr: {
                    without_refunds: 630.47,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 723.36,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 53.59,
                    with_refunds: 53.59,
                  },
                  gross_arr_spread: {
                    without_refunds: 60.04,
                    with_refunds: 60.04,
                  },
                  net_total: {
                    without_refunds: 261.45,
                    with_refunds: 185.47,
                  },
                  gross_total: {
                    without_refunds: 292.79,
                    with_refunds: 208.45,
                  },
                  net_total_spread: {
                    without_refunds: 54.42,
                    with_refunds: 53.97,
                  },
                  gross_total_spread: {
                    without_refunds: 60.89,
                    with_refunds: 60.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2132,
                    with_refunds: 0.2036,
                  },
                  gross_mrr: {
                    without_refunds: 0.2381,
                    with_refunds: 0.2272,
                  },
                  net_arr: {
                    without_refunds: 0.9997,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1131,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.37,
                    with_refunds: 0.37,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4142,
                    with_refunds: 0.4142,
                  },
                  net_total: {
                    without_refunds: 0.8638,
                    with_refunds: 0.6614,
                  },
                  gross_total: {
                    without_refunds: 0.9619,
                    with_refunds: 0.7389,
                  },
                  net_total_spread: {
                    without_refunds: 0.336,
                    with_refunds: 0.3338,
                  },
                  gross_total_spread: {
                    without_refunds: 0.376,
                    with_refunds: 0.3735,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4543,
                    with_refunds: 0.4362,
                  },
                  gross_mrr: {
                    without_refunds: 0.5177,
                    with_refunds: 0.4971,
                  },
                  net_arr: {
                    without_refunds: 4.8131,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 5.5223,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4091,
                    with_refunds: 0.4091,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4584,
                    with_refunds: 0.4584,
                  },
                  net_total: {
                    without_refunds: 1.996,
                    with_refunds: 1.4159,
                  },
                  gross_total: {
                    without_refunds: 2.2352,
                    with_refunds: 1.5914,
                  },
                  net_total_spread: {
                    without_refunds: 0.4154,
                    with_refunds: 0.412,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4648,
                    with_refunds: 0.461,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 328,
              with_refunds: 328,
            },
            returning_users: {
              without_refunds: 6613,
              with_refunds: 6654,
            },
            paying_users: {
              without_refunds: 6941,
              with_refunds: 6982,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 1071,
              with_refunds: 1071,
            },
            returning_users: {
              without_refunds: 1101,
              with_refunds: 1230,
            },
            paying_users: {
              without_refunds: 2172,
              with_refunds: 2301,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1397,
              with_refunds: 1397,
            },
            returning_users: {
              without_refunds: 7708,
              with_refunds: 7866,
            },
            paying_users: {
              without_refunds: 9105,
              with_refunds: 9263,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 11756.53,
                with_refunds: 11226.18,
              },
              gross_mrr: {
                without_refunds: 13129.71,
                with_refunds: 12527.16,
              },
              net_arr: {
                without_refunds: 255497.19,
                with_refunds: 193406.92,
              },
              gross_arr: {
                without_refunds: 284467.86,
                with_refunds: 216101.69,
              },
              net_arr_spread: {
                without_refunds: 71290.67,
                with_refunds: 71290.67,
              },
              gross_arr_spread: {
                without_refunds: 79801.15,
                with_refunds: 79801.15,
              },
              net_total: {
                without_refunds: 267253.72,
                with_refunds: 204633.1,
              },
              gross_total: {
                without_refunds: 297597.57,
                with_refunds: 228628.84,
              },
              net_total_spread: {
                without_refunds: 83047.21,
                with_refunds: 82516.85,
              },
              gross_total_spread: {
                without_refunds: 92930.86,
                with_refunds: 92328.3,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 11756.53,
                with_refunds: 11226.18,
              },
              gross_mrr: {
                without_refunds: 13129.71,
                with_refunds: 12527.16,
              },
              net_arr: {
                without_refunds: 255497.19,
                with_refunds: 193406.92,
              },
              gross_arr: {
                without_refunds: 284467.86,
                with_refunds: 216101.69,
              },
              net_arr_spread: {
                without_refunds: 71290.67,
                with_refunds: 71290.67,
              },
              gross_arr_spread: {
                without_refunds: 79801.15,
                with_refunds: 79801.15,
              },
              net_total: {
                without_refunds: 267253.72,
                with_refunds: 204633.1,
              },
              gross_total: {
                without_refunds: 297597.57,
                with_refunds: 228628.84,
              },
              net_total_spread: {
                without_refunds: 83047.21,
                with_refunds: 82516.85,
              },
              gross_total_spread: {
                without_refunds: 92930.86,
                with_refunds: 92328.3,
              },
            },
          },
        },
      },
      cac: 130.99,
      mrr_ua_spend: {
        without_refunds: 55146.79,
        with_refunds: 55146.79,
      },
      arr_ua_spend: {
        without_refunds: 255561.49,
        with_refunds: 255561.49,
      },
      arr_spread_ua_spend: {
        without_refunds: 192686.29,
        with_refunds: 192686.29,
      },
      total_ua_spend: {
        without_refunds: 309398.38,
        with_refunds: 309398.38,
      },
      total_spread_ua_spend: {
        without_refunds: 247178.13,
        with_refunds: 247178.13,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 4,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 37,
        with_refunds: 37,
      },
      first_period_arr_users: {
        without_refunds: 928,
        with_refunds: 928,
      },
      first_period_arr_spread_users: {
        without_refunds: 750,
        with_refunds: 750,
      },
      first_period_total_users: {
        without_refunds: 963,
        with_refunds: 963,
      },
      first_period_total_spread_users: {
        without_refunds: 787,
        with_refunds: 787,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 20,
                  with_refunds: 20,
                },
                arr_users: {
                  without_refunds: 535,
                  with_refunds: 535,
                },
                arr_spread_users: {
                  without_refunds: 426,
                  with_refunds: 426,
                },
                total_users: {
                  without_refunds: 555,
                  with_refunds: 555,
                },
                total_spread_users: {
                  without_refunds: 446,
                  with_refunds: 446,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5405,
                  with_refunds: 0.5405,
                },
                arr_users: {
                  without_refunds: 0.5765,
                  with_refunds: 0.5765,
                },
                arr_spread_users: {
                  without_refunds: 0.568,
                  with_refunds: 0.568,
                },
                total_users: {
                  without_refunds: 0.5763,
                  with_refunds: 0.5763,
                },
                total_spread_users: {
                  without_refunds: 0.5667,
                  with_refunds: 0.5667,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 202.93,
                  with_refunds: 202.93,
                },
                gross_mrr: {
                  without_refunds: 266.22,
                  with_refunds: 266.22,
                },
                net_arr: {
                  without_refunds: 71477.46,
                  with_refunds: 59301.72,
                },
                gross_arr: {
                  without_refunds: 78904.9,
                  with_refunds: 65461.69,
                },
                net_arr_spread: {
                  without_refunds: 4710.41,
                  with_refunds: 4710.41,
                },
                gross_arr_spread: {
                  without_refunds: 5208.09,
                  with_refunds: 5208.09,
                },
                net_total: {
                  without_refunds: 71680.39,
                  with_refunds: 59504.65,
                },
                gross_total: {
                  without_refunds: 79171.12,
                  with_refunds: 65727.91,
                },
                net_total_spread: {
                  without_refunds: 4913.34,
                  with_refunds: 4913.34,
                },
                gross_total_spread: {
                  without_refunds: 5474.3,
                  with_refunds: 5474.3,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.48,
                  with_refunds: 5.48,
                },
                gross_mrr: {
                  without_refunds: 7.2,
                  with_refunds: 7.2,
                },
                net_arr: {
                  without_refunds: 77.02,
                  with_refunds: 63.9,
                },
                gross_arr: {
                  without_refunds: 85.03,
                  with_refunds: 70.54,
                },
                net_arr_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.28,
                },
                gross_arr_spread: {
                  without_refunds: 6.94,
                  with_refunds: 6.94,
                },
                net_total: {
                  without_refunds: 74.43,
                  with_refunds: 61.79,
                },
                gross_total: {
                  without_refunds: 82.21,
                  with_refunds: 68.25,
                },
                net_total_spread: {
                  without_refunds: 6.24,
                  with_refunds: 6.24,
                },
                gross_total_spread: {
                  without_refunds: 6.96,
                  with_refunds: 6.96,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.15,
                  with_refunds: 10.15,
                },
                gross_mrr: {
                  without_refunds: 13.31,
                  with_refunds: 13.31,
                },
                net_arr: {
                  without_refunds: 133.6,
                  with_refunds: 110.84,
                },
                gross_arr: {
                  without_refunds: 147.49,
                  with_refunds: 122.36,
                },
                net_arr_spread: {
                  without_refunds: 11.06,
                  with_refunds: 11.06,
                },
                gross_arr_spread: {
                  without_refunds: 12.23,
                  with_refunds: 12.23,
                },
                net_total: {
                  without_refunds: 129.15,
                  with_refunds: 107.22,
                },
                gross_total: {
                  without_refunds: 142.65,
                  with_refunds: 118.43,
                },
                net_total_spread: {
                  without_refunds: 11.02,
                  with_refunds: 11.02,
                },
                gross_total_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 5.48,
                    with_refunds: 5.48,
                  },
                  gross_mrr: {
                    without_refunds: 7.2,
                    with_refunds: 7.2,
                  },
                  net_arr: {
                    without_refunds: 77.02,
                    with_refunds: 63.9,
                  },
                  gross_arr: {
                    without_refunds: 85.03,
                    with_refunds: 70.54,
                  },
                  net_arr_spread: {
                    without_refunds: 6.28,
                    with_refunds: 6.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.94,
                    with_refunds: 6.94,
                  },
                  net_total: {
                    without_refunds: 74.43,
                    with_refunds: 61.79,
                  },
                  gross_total: {
                    without_refunds: 82.21,
                    with_refunds: 68.25,
                  },
                  net_total_spread: {
                    without_refunds: 6.24,
                    with_refunds: 6.24,
                  },
                  gross_total_spread: {
                    without_refunds: 6.96,
                    with_refunds: 6.96,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.15,
                    with_refunds: 10.15,
                  },
                  gross_mrr: {
                    without_refunds: 13.31,
                    with_refunds: 13.31,
                  },
                  net_arr: {
                    without_refunds: 133.6,
                    with_refunds: 110.84,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 122.36,
                  },
                  net_arr_spread: {
                    without_refunds: 11.06,
                    with_refunds: 11.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.23,
                    with_refunds: 12.23,
                  },
                  net_total: {
                    without_refunds: 129.15,
                    with_refunds: 107.22,
                  },
                  gross_total: {
                    without_refunds: 142.65,
                    with_refunds: 118.43,
                  },
                  net_total_spread: {
                    without_refunds: 11.02,
                    with_refunds: 11.02,
                  },
                  gross_total_spread: {
                    without_refunds: 12.27,
                    with_refunds: 12.27,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0677,
                    with_refunds: 0.0677,
                  },
                  gross_mrr: {
                    without_refunds: 0.0888,
                    with_refunds: 0.0888,
                  },
                  net_arr: {
                    without_refunds: 0.9504,
                    with_refunds: 0.7885,
                  },
                  gross_arr: {
                    without_refunds: 1.0492,
                    with_refunds: 0.8704,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0775,
                    with_refunds: 0.0775,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0857,
                    with_refunds: 0.0857,
                  },
                  net_total: {
                    without_refunds: 0.9185,
                    with_refunds: 0.7625,
                  },
                  gross_total: {
                    without_refunds: 1.0145,
                    with_refunds: 0.8422,
                  },
                  net_total_spread: {
                    without_refunds: 0.077,
                    with_refunds: 0.077,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0858,
                    with_refunds: 0.0858,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1252,
                    with_refunds: 0.1252,
                  },
                  gross_mrr: {
                    without_refunds: 0.1643,
                    with_refunds: 0.1643,
                  },
                  net_arr: {
                    without_refunds: 1.6486,
                    with_refunds: 1.3678,
                  },
                  gross_arr: {
                    without_refunds: 1.8199,
                    with_refunds: 1.5099,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1364,
                    with_refunds: 0.1364,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1509,
                    with_refunds: 0.1509,
                  },
                  net_total: {
                    without_refunds: 1.5937,
                    with_refunds: 1.323,
                  },
                  gross_total: {
                    without_refunds: 1.7603,
                    with_refunds: 1.4614,
                  },
                  net_total_spread: {
                    without_refunds: 0.1359,
                    with_refunds: 0.1359,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1515,
                    with_refunds: 0.1515,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 26,
                  with_refunds: 26,
                },
                arr_users: {
                  without_refunds: 387,
                  with_refunds: 407,
                },
                arr_spread_users: {
                  without_refunds: 744,
                  with_refunds: 744,
                },
                total_users: {
                  without_refunds: 413,
                  with_refunds: 433,
                },
                total_spread_users: {
                  without_refunds: 770,
                  with_refunds: 770,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7027,
                  with_refunds: 0.7027,
                },
                arr_users: {
                  without_refunds: 0.417,
                  with_refunds: 0.4386,
                },
                arr_spread_users: {
                  without_refunds: 0.992,
                  with_refunds: 0.992,
                },
                total_users: {
                  without_refunds: 0.4289,
                  with_refunds: 0.4496,
                },
                total_spread_users: {
                  without_refunds: 0.9784,
                  with_refunds: 0.9784,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.3,
                  with_refunds: 1.3,
                },
                arr_users: {
                  without_refunds: 0.7234,
                  with_refunds: 0.7607,
                },
                arr_spread_users: {
                  without_refunds: 1.7465,
                  with_refunds: 1.7465,
                },
                total_users: {
                  without_refunds: 0.7441,
                  with_refunds: 0.7802,
                },
                total_spread_users: {
                  without_refunds: 1.7265,
                  with_refunds: 1.7265,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 337.74,
                  with_refunds: 337.74,
                },
                gross_mrr: {
                  without_refunds: 430.64,
                  with_refunds: 430.64,
                },
                net_arr: {
                  without_refunds: 57064.13,
                  with_refunds: 43980.13,
                },
                gross_arr: {
                  without_refunds: 60618.29,
                  with_refunds: 46716.71,
                },
                net_arr_spread: {
                  without_refunds: 8527.52,
                  with_refunds: 8527.52,
                },
                gross_arr_spread: {
                  without_refunds: 9267.03,
                  with_refunds: 9267.03,
                },
                net_total: {
                  without_refunds: 57401.87,
                  with_refunds: 44317.87,
                },
                gross_total: {
                  without_refunds: 61048.93,
                  with_refunds: 47147.35,
                },
                net_total_spread: {
                  without_refunds: 8865.26,
                  with_refunds: 8865.26,
                },
                gross_total_spread: {
                  without_refunds: 9697.67,
                  with_refunds: 9697.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.6643,
                  with_refunds: 1.6643,
                },
                gross_mrr: {
                  without_refunds: 1.6176,
                  with_refunds: 1.6176,
                },
                net_arr: {
                  without_refunds: 0.7984,
                  with_refunds: 0.7416,
                },
                gross_arr: {
                  without_refunds: 0.7682,
                  with_refunds: 0.7136,
                },
                net_arr_spread: {
                  without_refunds: 1.8104,
                  with_refunds: 1.8104,
                },
                gross_arr_spread: {
                  without_refunds: 1.7794,
                  with_refunds: 1.7794,
                },
                net_total: {
                  without_refunds: 0.8008,
                  with_refunds: 0.7448,
                },
                gross_total: {
                  without_refunds: 0.7711,
                  with_refunds: 0.7173,
                },
                net_total_spread: {
                  without_refunds: 1.8043,
                  with_refunds: 1.8043,
                },
                gross_total_spread: {
                  without_refunds: 1.7715,
                  with_refunds: 1.7715,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.6643,
                  with_refunds: 1.6643,
                },
                gross_mrr: {
                  without_refunds: 1.6176,
                  with_refunds: 1.6176,
                },
                net_arr: {
                  without_refunds: 0.7984,
                  with_refunds: 0.7416,
                },
                gross_arr: {
                  without_refunds: 0.7682,
                  with_refunds: 0.7136,
                },
                net_arr_spread: {
                  without_refunds: 1.8104,
                  with_refunds: 1.8104,
                },
                gross_arr_spread: {
                  without_refunds: 1.7794,
                  with_refunds: 1.7794,
                },
                net_total: {
                  without_refunds: 0.8008,
                  with_refunds: 0.7448,
                },
                gross_total: {
                  without_refunds: 0.7711,
                  with_refunds: 0.7173,
                },
                net_total_spread: {
                  without_refunds: 1.8043,
                  with_refunds: 1.8043,
                },
                gross_total_spread: {
                  without_refunds: 1.7715,
                  with_refunds: 1.7715,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 9.13,
                  with_refunds: 9.13,
                },
                gross_mrr: {
                  without_refunds: 11.64,
                  with_refunds: 11.64,
                },
                net_arr: {
                  without_refunds: 61.49,
                  with_refunds: 47.39,
                },
                gross_arr: {
                  without_refunds: 65.32,
                  with_refunds: 50.34,
                },
                net_arr_spread: {
                  without_refunds: 11.37,
                  with_refunds: 11.37,
                },
                gross_arr_spread: {
                  without_refunds: 12.36,
                  with_refunds: 12.36,
                },
                net_total: {
                  without_refunds: 59.61,
                  with_refunds: 46.02,
                },
                gross_total: {
                  without_refunds: 63.39,
                  with_refunds: 48.96,
                },
                net_total_spread: {
                  without_refunds: 11.26,
                  with_refunds: 11.26,
                },
                gross_total_spread: {
                  without_refunds: 12.32,
                  with_refunds: 12.32,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.99,
                  with_refunds: 12.99,
                },
                gross_mrr: {
                  without_refunds: 16.56,
                  with_refunds: 16.56,
                },
                net_arr: {
                  without_refunds: 147.45,
                  with_refunds: 108.06,
                },
                gross_arr: {
                  without_refunds: 156.64,
                  with_refunds: 114.78,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.46,
                  with_refunds: 12.46,
                },
                net_total: {
                  without_refunds: 138.99,
                  with_refunds: 102.35,
                },
                gross_total: {
                  without_refunds: 147.82,
                  with_refunds: 108.89,
                },
                net_total_spread: {
                  without_refunds: 11.51,
                  with_refunds: 11.51,
                },
                gross_total_spread: {
                  without_refunds: 12.59,
                  with_refunds: 12.59,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 14.61,
                    with_refunds: 14.61,
                  },
                  gross_mrr: {
                    without_refunds: 18.83,
                    with_refunds: 18.83,
                  },
                  net_arr: {
                    without_refunds: 138.51,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 150.35,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 17.65,
                    with_refunds: 17.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 19.3,
                    with_refunds: 19.3,
                  },
                  net_total: {
                    without_refunds: 134.04,
                    with_refunds: 107.81,
                  },
                  gross_total: {
                    without_refunds: 145.61,
                    with_refunds: 117.21,
                  },
                  net_total_spread: {
                    without_refunds: 17.51,
                    with_refunds: 17.51,
                  },
                  gross_total_spread: {
                    without_refunds: 19.28,
                    with_refunds: 19.28,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.14,
                    with_refunds: 23.14,
                  },
                  gross_mrr: {
                    without_refunds: 29.87,
                    with_refunds: 29.87,
                  },
                  net_arr: {
                    without_refunds: 281.06,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 304.12,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 22.52,
                    with_refunds: 22.52,
                  },
                  gross_arr_spread: {
                    without_refunds: 24.68,
                    with_refunds: 24.68,
                  },
                  net_total: {
                    without_refunds: 268.14,
                    with_refunds: 209.57,
                  },
                  gross_total: {
                    without_refunds: 290.47,
                    with_refunds: 227.31,
                  },
                  net_total_spread: {
                    without_refunds: 22.53,
                    with_refunds: 22.53,
                  },
                  gross_total_spread: {
                    without_refunds: 24.87,
                    with_refunds: 24.87,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1803,
                    with_refunds: 0.1803,
                  },
                  gross_mrr: {
                    without_refunds: 0.2324,
                    with_refunds: 0.2324,
                  },
                  net_arr: {
                    without_refunds: 1.7092,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8552,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2178,
                    with_refunds: 0.2178,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2382,
                    with_refunds: 0.2382,
                  },
                  net_total: {
                    without_refunds: 1.654,
                    with_refunds: 1.3303,
                  },
                  gross_total: {
                    without_refunds: 1.7967,
                    with_refunds: 1.4463,
                  },
                  net_total_spread: {
                    without_refunds: 0.216,
                    with_refunds: 0.216,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2379,
                    with_refunds: 0.2379,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2855,
                    with_refunds: 0.2855,
                  },
                  gross_mrr: {
                    without_refunds: 0.3686,
                    with_refunds: 0.3686,
                  },
                  net_arr: {
                    without_refunds: 3.4681,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 3.7527,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2779,
                    with_refunds: 0.2779,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3046,
                    with_refunds: 0.3046,
                  },
                  net_total: {
                    without_refunds: 3.3088,
                    with_refunds: 2.586,
                  },
                  gross_total: {
                    without_refunds: 3.5843,
                    with_refunds: 2.805,
                  },
                  net_total_spread: {
                    without_refunds: 0.278,
                    with_refunds: 0.278,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3069,
                    with_refunds: 0.3069,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 17,
                  with_refunds: 17,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 12,
                },
                arr_spread_users: {
                  without_refunds: 749,
                  with_refunds: 749,
                },
                total_users: {
                  without_refunds: 23,
                  with_refunds: 29,
                },
                total_spread_users: {
                  without_refunds: 766,
                  with_refunds: 766,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4595,
                  with_refunds: 0.4595,
                },
                arr_users: {
                  without_refunds: 0.0065,
                  with_refunds: 0.0129,
                },
                arr_spread_users: {
                  without_refunds: 0.9987,
                  with_refunds: 0.9987,
                },
                total_users: {
                  without_refunds: 0.0239,
                  with_refunds: 0.0301,
                },
                total_spread_users: {
                  without_refunds: 0.9733,
                  with_refunds: 0.9733,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6538,
                  with_refunds: 0.6538,
                },
                arr_users: {
                  without_refunds: 0.0155,
                  with_refunds: 0.0295,
                },
                arr_spread_users: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                total_users: {
                  without_refunds: 0.0557,
                  with_refunds: 0.067,
                },
                total_spread_users: {
                  without_refunds: 0.9948,
                  with_refunds: 0.9948,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 230.92,
                  with_refunds: 230.92,
                },
                gross_mrr: {
                  without_refunds: 283.88,
                  with_refunds: 283.88,
                },
                net_arr: {
                  without_refunds: 764.49,
                },
                gross_arr: {
                  without_refunds: 804.22,
                },
                net_arr_spread: {
                  without_refunds: 8580,
                  with_refunds: 8580,
                },
                gross_arr_spread: {
                  without_refunds: 9322.47,
                  with_refunds: 9322.47,
                },
                net_total: {
                  without_refunds: 995.41,
                  with_refunds: 230.92,
                },
                gross_total: {
                  without_refunds: 1088.1,
                  with_refunds: 283.88,
                },
                net_total_spread: {
                  without_refunds: 8810.93,
                  with_refunds: 8810.93,
                },
                gross_total_spread: {
                  without_refunds: 9606.34,
                  with_refunds: 9606.34,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.1379,
                  with_refunds: 1.1379,
                },
                gross_mrr: {
                  without_refunds: 1.0663,
                  with_refunds: 1.0663,
                },
                net_arr: {
                  without_refunds: 0.0107,
                },
                gross_arr: {
                  without_refunds: 0.0102,
                },
                net_arr_spread: {
                  without_refunds: 1.8215,
                  with_refunds: 1.8215,
                },
                gross_arr_spread: {
                  without_refunds: 1.79,
                  with_refunds: 1.79,
                },
                net_total: {
                  without_refunds: 0.0139,
                  with_refunds: 0.0039,
                },
                gross_total: {
                  without_refunds: 0.0137,
                  with_refunds: 0.0043,
                },
                net_total_spread: {
                  without_refunds: 1.7933,
                  with_refunds: 1.7933,
                },
                gross_total_spread: {
                  without_refunds: 1.7548,
                  with_refunds: 1.7548,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6837,
                  with_refunds: 0.6837,
                },
                gross_mrr: {
                  without_refunds: 0.6592,
                  with_refunds: 0.6592,
                },
                net_arr: {
                  without_refunds: 0.0134,
                },
                gross_arr: {
                  without_refunds: 0.0133,
                },
                net_arr_spread: {
                  without_refunds: 1.0062,
                  with_refunds: 1.0062,
                },
                gross_arr_spread: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                net_total: {
                  without_refunds: 0.0173,
                  with_refunds: 0.0052,
                },
                gross_total: {
                  without_refunds: 0.0178,
                  with_refunds: 0.006,
                },
                net_total_spread: {
                  without_refunds: 0.9939,
                  with_refunds: 0.9939,
                },
                gross_total_spread: {
                  without_refunds: 0.9906,
                  with_refunds: 0.9906,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.24,
                  with_refunds: 6.24,
                },
                gross_mrr: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                net_arr: {
                  without_refunds: 0.82,
                },
                gross_arr: {
                  without_refunds: 0.87,
                },
                net_arr_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.44,
                },
                gross_arr_spread: {
                  without_refunds: 12.43,
                  with_refunds: 12.43,
                },
                net_total: {
                  without_refunds: 1.03,
                  with_refunds: 0.24,
                },
                gross_total: {
                  without_refunds: 1.13,
                  with_refunds: 0.29,
                },
                net_total_spread: {
                  without_refunds: 11.2,
                  with_refunds: 11.2,
                },
                gross_total_spread: {
                  without_refunds: 12.21,
                  with_refunds: 12.21,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.58,
                  with_refunds: 13.58,
                },
                gross_mrr: {
                  without_refunds: 16.7,
                  with_refunds: 16.7,
                },
                net_arr: {
                  without_refunds: 127.41,
                },
                gross_arr: {
                  without_refunds: 134.04,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.45,
                  with_refunds: 12.45,
                },
                net_total: {
                  without_refunds: 43.28,
                  with_refunds: 7.96,
                },
                gross_total: {
                  without_refunds: 47.31,
                  with_refunds: 9.79,
                },
                net_total_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.5,
                },
                gross_total_spread: {
                  without_refunds: 12.54,
                  with_refunds: 12.54,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.85,
                    with_refunds: 20.85,
                  },
                  gross_mrr: {
                    without_refunds: 26.51,
                    with_refunds: 26.51,
                  },
                  net_arr: {
                    without_refunds: 139.34,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.21,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 29.09,
                    with_refunds: 29.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 31.73,
                    with_refunds: 31.73,
                  },
                  net_total: {
                    without_refunds: 135.08,
                    with_refunds: 108.05,
                  },
                  gross_total: {
                    without_refunds: 146.74,
                    with_refunds: 117.51,
                  },
                  net_total_spread: {
                    without_refunds: 28.7,
                    with_refunds: 28.7,
                  },
                  gross_total_spread: {
                    without_refunds: 31.48,
                    with_refunds: 31.48,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.72,
                    with_refunds: 36.72,
                  },
                  gross_mrr: {
                    without_refunds: 46.57,
                    with_refunds: 46.57,
                  },
                  net_arr: {
                    without_refunds: 408.47,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 438.16,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 33.97,
                    with_refunds: 33.97,
                  },
                  gross_arr_spread: {
                    without_refunds: 37.13,
                    with_refunds: 37.13,
                  },
                  net_total: {
                    without_refunds: 311.42,
                    with_refunds: 217.53,
                  },
                  gross_total: {
                    without_refunds: 337.78,
                    with_refunds: 237.1,
                  },
                  net_total_spread: {
                    without_refunds: 34.03,
                    with_refunds: 34.03,
                  },
                  gross_total_spread: {
                    without_refunds: 37.41,
                    with_refunds: 37.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2573,
                    with_refunds: 0.2573,
                  },
                  gross_mrr: {
                    without_refunds: 0.3271,
                    with_refunds: 0.3271,
                  },
                  net_arr: {
                    without_refunds: 1.7194,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8659,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.359,
                    with_refunds: 0.359,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3915,
                    with_refunds: 0.3915,
                  },
                  net_total: {
                    without_refunds: 1.6668,
                    with_refunds: 1.3333,
                  },
                  gross_total: {
                    without_refunds: 1.8107,
                    with_refunds: 1.45,
                  },
                  net_total_spread: {
                    without_refunds: 0.3542,
                    with_refunds: 0.3542,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3885,
                    with_refunds: 0.3885,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4531,
                    with_refunds: 0.4531,
                  },
                  gross_mrr: {
                    without_refunds: 0.5747,
                    with_refunds: 0.5747,
                  },
                  net_arr: {
                    without_refunds: 5.0404,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 5.4067,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4192,
                    with_refunds: 0.4192,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4581,
                    with_refunds: 0.4581,
                  },
                  net_total: {
                    without_refunds: 3.8428,
                    with_refunds: 2.6842,
                  },
                  gross_total: {
                    without_refunds: 4.168,
                    with_refunds: 2.9258,
                  },
                  net_total_spread: {
                    without_refunds: 0.4199,
                    with_refunds: 0.4199,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4616,
                    with_refunds: 0.4616,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 750,
                  with_refunds: 750,
                },
                total_users: {
                  without_refunds: 5,
                  with_refunds: 6,
                },
                total_spread_users: {
                  without_refunds: 754,
                  with_refunds: 754,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1081,
                  with_refunds: 0.1081,
                },
                arr_users: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0022,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.0052,
                  with_refunds: 0.0062,
                },
                total_spread_users: {
                  without_refunds: 0.9581,
                  with_refunds: 0.9581,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2353,
                  with_refunds: 0.2353,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0013,
                  with_refunds: 1.0013,
                },
                total_users: {
                  without_refunds: 0.2174,
                  with_refunds: 0.2069,
                },
                total_spread_users: {
                  without_refunds: 0.9843,
                  with_refunds: 0.9843,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 61.02,
                  with_refunds: 44.03,
                },
                gross_mrr: {
                  without_refunds: 77.07,
                  with_refunds: 57.08,
                },
                net_arr: {
                  without_refunds: 121.07,
                },
                gross_arr: {
                  without_refunds: 125,
                },
                net_arr_spread: {
                  without_refunds: 8590.09,
                  with_refunds: 8590.09,
                },
                gross_arr_spread: {
                  without_refunds: 9332.88,
                  with_refunds: 9332.88,
                },
                net_total: {
                  without_refunds: 182.09,
                  with_refunds: 44.03,
                },
                gross_total: {
                  without_refunds: 202.07,
                  with_refunds: 57.08,
                },
                net_total_spread: {
                  without_refunds: 8651.12,
                  with_refunds: 8634.13,
                },
                gross_total_spread: {
                  without_refunds: 9409.96,
                  with_refunds: 9389.97,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3007,
                  with_refunds: 0.217,
                },
                gross_mrr: {
                  without_refunds: 0.2895,
                  with_refunds: 0.2144,
                },
                net_arr: {
                  without_refunds: 0.0017,
                },
                gross_arr: {
                  without_refunds: 0.0016,
                },
                net_arr_spread: {
                  without_refunds: 1.8236,
                  with_refunds: 1.8236,
                },
                gross_arr_spread: {
                  without_refunds: 1.792,
                  with_refunds: 1.792,
                },
                net_total: {
                  without_refunds: 0.0025,
                  with_refunds: 0.0007,
                },
                gross_total: {
                  without_refunds: 0.0026,
                  with_refunds: 0.0009,
                },
                net_total_spread: {
                  without_refunds: 1.7607,
                  with_refunds: 1.7573,
                },
                gross_total_spread: {
                  without_refunds: 1.7189,
                  with_refunds: 1.7153,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2643,
                  with_refunds: 0.1907,
                },
                gross_mrr: {
                  without_refunds: 0.2715,
                  with_refunds: 0.2011,
                },
                net_arr: {
                  without_refunds: 0.1584,
                },
                gross_arr: {
                  without_refunds: 0.1554,
                },
                net_arr_spread: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                gross_arr_spread: {
                  without_refunds: 1.0011,
                  with_refunds: 1.0011,
                },
                net_total: {
                  without_refunds: 0.1829,
                  with_refunds: 0.1907,
                },
                gross_total: {
                  without_refunds: 0.1857,
                  with_refunds: 0.2011,
                },
                net_total_spread: {
                  without_refunds: 0.9819,
                  with_refunds: 0.9799,
                },
                gross_total_spread: {
                  without_refunds: 0.9796,
                  with_refunds: 0.9775,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.65,
                  with_refunds: 1.19,
                },
                gross_mrr: {
                  without_refunds: 2.08,
                  with_refunds: 1.54,
                },
                net_arr: {
                  without_refunds: 0.13,
                },
                gross_arr: {
                  without_refunds: 0.13,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.44,
                  with_refunds: 12.44,
                },
                net_total: {
                  without_refunds: 0.19,
                  with_refunds: 0.05,
                },
                gross_total: {
                  without_refunds: 0.21,
                  with_refunds: 0.06,
                },
                net_total_spread: {
                  without_refunds: 10.99,
                  with_refunds: 10.97,
                },
                gross_total_spread: {
                  without_refunds: 11.96,
                  with_refunds: 11.93,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.26,
                  with_refunds: 11.01,
                },
                gross_mrr: {
                  without_refunds: 19.27,
                  with_refunds: 14.27,
                },
                net_arr: {
                  without_refunds: 121.07,
                },
                gross_arr: {
                  without_refunds: 125,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.44,
                  with_refunds: 12.44,
                },
                net_total: {
                  without_refunds: 36.42,
                  with_refunds: 7.34,
                },
                gross_total: {
                  without_refunds: 40.41,
                  with_refunds: 9.51,
                },
                net_total_spread: {
                  without_refunds: 11.47,
                  with_refunds: 11.45,
                },
                gross_total_spread: {
                  without_refunds: 12.48,
                  with_refunds: 12.45,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.5,
                    with_refunds: 22.04,
                  },
                  gross_mrr: {
                    without_refunds: 28.59,
                    with_refunds: 28.05,
                  },
                  net_arr: {
                    without_refunds: 139.47,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.35,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 40.54,
                    with_refunds: 40.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 44.17,
                    with_refunds: 44.17,
                  },
                  net_total: {
                    without_refunds: 135.26,
                    with_refunds: 108.1,
                  },
                  gross_total: {
                    without_refunds: 146.95,
                    with_refunds: 117.57,
                  },
                  net_total_spread: {
                    without_refunds: 39.7,
                    with_refunds: 39.67,
                  },
                  gross_total_spread: {
                    without_refunds: 43.44,
                    with_refunds: 43.42,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 51.98,
                    with_refunds: 47.73,
                  },
                  gross_mrr: {
                    without_refunds: 65.84,
                    with_refunds: 60.84,
                  },
                  net_arr: {
                    without_refunds: 529.54,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 563.16,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 45.43,
                    with_refunds: 45.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 49.57,
                    with_refunds: 49.57,
                  },
                  net_total: {
                    without_refunds: 347.84,
                    with_refunds: 224.87,
                  },
                  gross_total: {
                    without_refunds: 378.19,
                    with_refunds: 246.62,
                  },
                  net_total_spread: {
                    without_refunds: 45.51,
                    with_refunds: 45.48,
                  },
                  gross_total_spread: {
                    without_refunds: 49.89,
                    with_refunds: 49.86,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2777,
                    with_refunds: 0.272,
                  },
                  gross_mrr: {
                    without_refunds: 0.3528,
                    with_refunds: 0.3461,
                  },
                  net_arr: {
                    without_refunds: 1.721,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8676,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5003,
                    with_refunds: 0.5003,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5451,
                    with_refunds: 0.5451,
                  },
                  net_total: {
                    without_refunds: 1.6691,
                    with_refunds: 1.3339,
                  },
                  gross_total: {
                    without_refunds: 1.8133,
                    with_refunds: 1.4507,
                  },
                  net_total_spread: {
                    without_refunds: 0.4898,
                    with_refunds: 0.4896,
                  },
                  gross_total_spread: {
                    without_refunds: 0.536,
                    with_refunds: 0.5357,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6414,
                    with_refunds: 0.5889,
                  },
                  gross_mrr: {
                    without_refunds: 0.8125,
                    with_refunds: 0.7508,
                  },
                  net_arr: {
                    without_refunds: 6.5343,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 6.9491,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5606,
                    with_refunds: 0.5606,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6117,
                    with_refunds: 0.6117,
                  },
                  net_total: {
                    without_refunds: 4.2922,
                    with_refunds: 2.7748,
                  },
                  gross_total: {
                    without_refunds: 4.6667,
                    with_refunds: 3.0432,
                  },
                  net_total_spread: {
                    without_refunds: 0.5615,
                    with_refunds: 0.5612,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6156,
                    with_refunds: 0.6153,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 20,
              with_refunds: 20,
            },
            returning_users: {
              without_refunds: 5585,
              with_refunds: 5618,
            },
            paying_users: {
              without_refunds: 5605,
              with_refunds: 5638,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 535,
              with_refunds: 535,
            },
            returning_users: {
              without_refunds: 947,
              with_refunds: 1081,
            },
            paying_users: {
              without_refunds: 1482,
              with_refunds: 1616,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 555,
              with_refunds: 555,
            },
            returning_users: {
              without_refunds: 6531,
              with_refunds: 6697,
            },
            paying_users: {
              without_refunds: 7086,
              with_refunds: 7252,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 832.61,
                with_refunds: 815.62,
              },
              gross_mrr: {
                without_refunds: 1057.81,
                with_refunds: 1037.82,
              },
              net_arr: {
                without_refunds: 129427.15,
                with_refunds: 103281.86,
              },
              gross_arr: {
                without_refunds: 140452.41,
                with_refunds: 112178.4,
              },
              net_arr_spread: {
                without_refunds: 30408.03,
                with_refunds: 30408.03,
              },
              gross_arr_spread: {
                without_refunds: 33130.46,
                with_refunds: 33130.46,
              },
              net_total: {
                without_refunds: 130259.77,
                with_refunds: 104097.48,
              },
              gross_total: {
                without_refunds: 141510.22,
                with_refunds: 113216.22,
              },
              net_total_spread: {
                without_refunds: 31240.64,
                with_refunds: 31223.65,
              },
              gross_total_spread: {
                without_refunds: 34188.28,
                with_refunds: 34168.29,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 832.61,
                with_refunds: 815.62,
              },
              gross_mrr: {
                without_refunds: 1057.81,
                with_refunds: 1037.82,
              },
              net_arr: {
                without_refunds: 129427.15,
                with_refunds: 103281.86,
              },
              gross_arr: {
                without_refunds: 140452.41,
                with_refunds: 112178.4,
              },
              net_arr_spread: {
                without_refunds: 30408.03,
                with_refunds: 30408.03,
              },
              gross_arr_spread: {
                without_refunds: 33130.46,
                with_refunds: 33130.46,
              },
              net_total: {
                without_refunds: 130259.77,
                with_refunds: 104097.48,
              },
              gross_total: {
                without_refunds: 141510.22,
                with_refunds: 113216.22,
              },
              net_total_spread: {
                without_refunds: 31240.64,
                with_refunds: 31223.65,
              },
              gross_total_spread: {
                without_refunds: 34188.28,
                with_refunds: 34168.29,
              },
            },
          },
        },
      },
      cac: 81.04,
      mrr_ua_spend: {
        without_refunds: 2998.48,
        with_refunds: 2998.48,
      },
      arr_ua_spend: {
        without_refunds: 75205.12,
        with_refunds: 75205.12,
      },
      arr_spread_ua_spend: {
        without_refunds: 60780,
        with_refunds: 60780,
      },
      total_ua_spend: {
        without_refunds: 78041.52,
        with_refunds: 78041.52,
      },
      total_spread_ua_spend: {
        without_refunds: 63778.48,
        with_refunds: 63778.48,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 5,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 337,
        with_refunds: 337,
      },
      first_period_arr_users: {
        without_refunds: 386,
        with_refunds: 386,
      },
      first_period_arr_spread_users: {
        without_refunds: 340,
        with_refunds: 340,
      },
      first_period_total_users: {
        without_refunds: 719,
        with_refunds: 719,
      },
      first_period_total_spread_users: {
        without_refunds: 676,
        with_refunds: 676,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                arr_users: {
                  without_refunds: 208,
                  with_refunds: 208,
                },
                arr_spread_users: {
                  without_refunds: 182,
                  with_refunds: 182,
                },
                total_users: {
                  without_refunds: 418,
                  with_refunds: 418,
                },
                total_spread_users: {
                  without_refunds: 394,
                  with_refunds: 394,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6291,
                  with_refunds: 0.6291,
                },
                arr_users: {
                  without_refunds: 0.5389,
                  with_refunds: 0.5389,
                },
                arr_spread_users: {
                  without_refunds: 0.5353,
                  with_refunds: 0.5353,
                },
                total_users: {
                  without_refunds: 0.5814,
                  with_refunds: 0.5814,
                },
                total_spread_users: {
                  without_refunds: 0.5828,
                  with_refunds: 0.5828,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4153.19,
                  with_refunds: 4004.05,
                },
                gross_mrr: {
                  without_refunds: 4867.1,
                  with_refunds: 4682.43,
                },
                net_arr: {
                  without_refunds: 28025.45,
                  with_refunds: 25295.94,
                },
                gross_arr: {
                  without_refunds: 30255.55,
                  with_refunds: 27346.05,
                },
                net_arr_spread: {
                  without_refunds: 2030.32,
                  with_refunds: 2030.32,
                },
                gross_arr_spread: {
                  without_refunds: 2198.63,
                  with_refunds: 2198.63,
                },
                net_total: {
                  without_refunds: 32178.64,
                  with_refunds: 29299.99,
                },
                gross_total: {
                  without_refunds: 35122.65,
                  with_refunds: 32028.48,
                },
                net_total_spread: {
                  without_refunds: 6183.51,
                  with_refunds: 6034.36,
                },
                gross_total_spread: {
                  without_refunds: 7065.73,
                  with_refunds: 6881.06,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 12.32,
                  with_refunds: 11.88,
                },
                gross_mrr: {
                  without_refunds: 14.44,
                  with_refunds: 13.89,
                },
                net_arr: {
                  without_refunds: 72.6,
                  with_refunds: 65.53,
                },
                gross_arr: {
                  without_refunds: 78.38,
                  with_refunds: 70.84,
                },
                net_arr_spread: {
                  without_refunds: 5.97,
                  with_refunds: 5.97,
                },
                gross_arr_spread: {
                  without_refunds: 6.47,
                  with_refunds: 6.47,
                },
                net_total: {
                  without_refunds: 44.75,
                  with_refunds: 40.75,
                },
                gross_total: {
                  without_refunds: 48.85,
                  with_refunds: 44.55,
                },
                net_total_spread: {
                  without_refunds: 9.15,
                  with_refunds: 8.93,
                },
                gross_total_spread: {
                  without_refunds: 10.45,
                  with_refunds: 10.18,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.59,
                  with_refunds: 18.89,
                },
                gross_mrr: {
                  without_refunds: 22.96,
                  with_refunds: 22.09,
                },
                net_arr: {
                  without_refunds: 134.74,
                  with_refunds: 121.62,
                },
                gross_arr: {
                  without_refunds: 145.46,
                  with_refunds: 131.47,
                },
                net_arr_spread: {
                  without_refunds: 11.16,
                  with_refunds: 11.16,
                },
                gross_arr_spread: {
                  without_refunds: 12.08,
                  with_refunds: 12.08,
                },
                net_total: {
                  without_refunds: 76.98,
                  with_refunds: 70.1,
                },
                gross_total: {
                  without_refunds: 84.03,
                  with_refunds: 76.62,
                },
                net_total_spread: {
                  without_refunds: 15.69,
                  with_refunds: 15.32,
                },
                gross_total_spread: {
                  without_refunds: 17.93,
                  with_refunds: 17.46,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 12.32,
                    with_refunds: 11.88,
                  },
                  gross_mrr: {
                    without_refunds: 14.44,
                    with_refunds: 13.89,
                  },
                  net_arr: {
                    without_refunds: 72.6,
                    with_refunds: 65.53,
                  },
                  gross_arr: {
                    without_refunds: 78.38,
                    with_refunds: 70.84,
                  },
                  net_arr_spread: {
                    without_refunds: 5.97,
                    with_refunds: 5.97,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.47,
                    with_refunds: 6.47,
                  },
                  net_total: {
                    without_refunds: 44.75,
                    with_refunds: 40.75,
                  },
                  gross_total: {
                    without_refunds: 48.85,
                    with_refunds: 44.55,
                  },
                  net_total_spread: {
                    without_refunds: 9.15,
                    with_refunds: 8.93,
                  },
                  gross_total_spread: {
                    without_refunds: 10.45,
                    with_refunds: 10.18,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 19.59,
                    with_refunds: 18.89,
                  },
                  gross_mrr: {
                    without_refunds: 22.96,
                    with_refunds: 22.09,
                  },
                  net_arr: {
                    without_refunds: 134.74,
                    with_refunds: 121.62,
                  },
                  gross_arr: {
                    without_refunds: 145.46,
                    with_refunds: 131.47,
                  },
                  net_arr_spread: {
                    without_refunds: 11.16,
                    with_refunds: 11.16,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.08,
                    with_refunds: 12.08,
                  },
                  net_total: {
                    without_refunds: 76.98,
                    with_refunds: 70.1,
                  },
                  gross_total: {
                    without_refunds: 84.03,
                    with_refunds: 76.62,
                  },
                  net_total_spread: {
                    without_refunds: 15.69,
                    with_refunds: 15.32,
                  },
                  gross_total_spread: {
                    without_refunds: 17.93,
                    with_refunds: 17.46,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2349,
                    with_refunds: 1.1905,
                  },
                  gross_mrr: {
                    without_refunds: 1.4471,
                    with_refunds: 1.3922,
                  },
                  net_arr: {
                    without_refunds: 7.275,
                    with_refunds: 6.5665,
                  },
                  gross_arr: {
                    without_refunds: 7.8539,
                    with_refunds: 7.0987,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5983,
                    with_refunds: 0.5983,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.648,
                    with_refunds: 0.648,
                  },
                  net_total: {
                    without_refunds: 4.4844,
                    with_refunds: 4.0833,
                  },
                  gross_total: {
                    without_refunds: 4.8947,
                    with_refunds: 4.4635,
                  },
                  net_total_spread: {
                    without_refunds: 0.9166,
                    with_refunds: 0.8944,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0473,
                    with_refunds: 1.0199,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.963,
                    with_refunds: 1.8925,
                  },
                  gross_mrr: {
                    without_refunds: 2.3004,
                    with_refunds: 2.2131,
                  },
                  net_arr: {
                    without_refunds: 13.5008,
                    with_refunds: 12.1859,
                  },
                  gross_arr: {
                    without_refunds: 14.5751,
                    with_refunds: 13.1735,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1178,
                    with_refunds: 1.1178,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2105,
                    with_refunds: 1.2105,
                  },
                  net_total: {
                    without_refunds: 7.7137,
                    with_refunds: 7.0236,
                  },
                  gross_total: {
                    without_refunds: 8.4194,
                    with_refunds: 7.6777,
                  },
                  net_total_spread: {
                    without_refunds: 1.5726,
                    with_refunds: 1.5346,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7969,
                    with_refunds: 1.75,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 225,
                  with_refunds: 225,
                },
                arr_users: {
                  without_refunds: 176,
                  with_refunds: 182,
                },
                arr_spread_users: {
                  without_refunds: 338,
                  with_refunds: 338,
                },
                total_users: {
                  without_refunds: 400,
                  with_refunds: 406,
                },
                total_spread_users: {
                  without_refunds: 563,
                  with_refunds: 563,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6677,
                  with_refunds: 0.6677,
                },
                arr_users: {
                  without_refunds: 0.456,
                  with_refunds: 0.4715,
                },
                arr_spread_users: {
                  without_refunds: 0.9941,
                  with_refunds: 0.9941,
                },
                total_users: {
                  without_refunds: 0.5563,
                  with_refunds: 0.5647,
                },
                total_spread_users: {
                  without_refunds: 0.8328,
                  with_refunds: 0.8328,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.0613,
                  with_refunds: 1.0613,
                },
                arr_users: {
                  without_refunds: 0.8462,
                  with_refunds: 0.875,
                },
                arr_spread_users: {
                  without_refunds: 1.8571,
                  with_refunds: 1.8571,
                },
                total_users: {
                  without_refunds: 0.9569,
                  with_refunds: 0.9713,
                },
                total_spread_users: {
                  without_refunds: 1.4289,
                  with_refunds: 1.4289,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4421.45,
                  with_refunds: 4253.36,
                },
                gross_mrr: {
                  without_refunds: 5016.77,
                  with_refunds: 4817.45,
                },
                net_arr: {
                  without_refunds: 25128.71,
                  with_refunds: 22005.52,
                },
                gross_arr: {
                  without_refunds: 26320.39,
                  with_refunds: 23096.39,
                },
                net_arr_spread: {
                  without_refunds: 3886.34,
                  with_refunds: 3886.34,
                },
                gross_arr_spread: {
                  without_refunds: 4146.33,
                  with_refunds: 4146.33,
                },
                net_total: {
                  without_refunds: 29550.16,
                  with_refunds: 26258.89,
                },
                gross_total: {
                  without_refunds: 31337.17,
                  with_refunds: 27913.84,
                },
                net_total_spread: {
                  without_refunds: 8307.79,
                  with_refunds: 8139.7,
                },
                gross_total_spread: {
                  without_refunds: 9163.1,
                  with_refunds: 8963.78,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.0646,
                  with_refunds: 1.0623,
                },
                gross_mrr: {
                  without_refunds: 1.0308,
                  with_refunds: 1.0288,
                },
                net_arr: {
                  without_refunds: 0.8966,
                  with_refunds: 0.8699,
                },
                gross_arr: {
                  without_refunds: 0.8699,
                  with_refunds: 0.8446,
                },
                net_arr_spread: {
                  without_refunds: 1.9142,
                  with_refunds: 1.9142,
                },
                gross_arr_spread: {
                  without_refunds: 1.8859,
                  with_refunds: 1.8859,
                },
                net_total: {
                  without_refunds: 0.9183,
                  with_refunds: 0.8962,
                },
                gross_total: {
                  without_refunds: 0.8922,
                  with_refunds: 0.8715,
                },
                net_total_spread: {
                  without_refunds: 1.3435,
                  with_refunds: 1.3489,
                },
                gross_total_spread: {
                  without_refunds: 1.2968,
                  with_refunds: 1.3027,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.0646,
                  with_refunds: 1.0623,
                },
                gross_mrr: {
                  without_refunds: 1.0308,
                  with_refunds: 1.0288,
                },
                net_arr: {
                  without_refunds: 0.8966,
                  with_refunds: 0.8699,
                },
                gross_arr: {
                  without_refunds: 0.8699,
                  with_refunds: 0.8446,
                },
                net_arr_spread: {
                  without_refunds: 1.9142,
                  with_refunds: 1.9142,
                },
                gross_arr_spread: {
                  without_refunds: 1.8859,
                  with_refunds: 1.8859,
                },
                net_total: {
                  without_refunds: 0.9183,
                  with_refunds: 0.8962,
                },
                gross_total: {
                  without_refunds: 0.8922,
                  with_refunds: 0.8715,
                },
                net_total_spread: {
                  without_refunds: 1.3435,
                  with_refunds: 1.3489,
                },
                gross_total_spread: {
                  without_refunds: 1.2968,
                  with_refunds: 1.3027,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 12.62,
                },
                gross_mrr: {
                  without_refunds: 14.89,
                  with_refunds: 14.3,
                },
                net_arr: {
                  without_refunds: 65.1,
                  with_refunds: 57.01,
                },
                gross_arr: {
                  without_refunds: 68.19,
                  with_refunds: 59.84,
                },
                net_arr_spread: {
                  without_refunds: 11.43,
                  with_refunds: 11.43,
                },
                gross_arr_spread: {
                  without_refunds: 12.2,
                  with_refunds: 12.2,
                },
                net_total: {
                  without_refunds: 41.1,
                  with_refunds: 36.52,
                },
                gross_total: {
                  without_refunds: 43.58,
                  with_refunds: 38.82,
                },
                net_total_spread: {
                  without_refunds: 12.29,
                  with_refunds: 12.04,
                },
                gross_total_spread: {
                  without_refunds: 13.55,
                  with_refunds: 13.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.65,
                  with_refunds: 18.9,
                },
                gross_mrr: {
                  without_refunds: 22.3,
                  with_refunds: 21.41,
                },
                net_arr: {
                  without_refunds: 142.78,
                  with_refunds: 120.91,
                },
                gross_arr: {
                  without_refunds: 149.55,
                  with_refunds: 126.9,
                },
                net_arr_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.5,
                },
                gross_arr_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
                net_total: {
                  without_refunds: 73.88,
                  with_refunds: 64.68,
                },
                gross_total: {
                  without_refunds: 78.34,
                  with_refunds: 68.75,
                },
                net_total_spread: {
                  without_refunds: 14.76,
                  with_refunds: 14.46,
                },
                gross_total_spread: {
                  without_refunds: 16.28,
                  with_refunds: 15.92,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.44,
                    with_refunds: 24.5,
                  },
                  gross_mrr: {
                    without_refunds: 29.33,
                    with_refunds: 28.19,
                  },
                  net_arr: {
                    without_refunds: 137.71,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 146.57,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 17.4,
                    with_refunds: 17.4,
                  },
                  gross_arr_spread: {
                    without_refunds: 18.66,
                    with_refunds: 18.66,
                  },
                  net_total: {
                    without_refunds: 85.85,
                    with_refunds: 77.27,
                  },
                  gross_total: {
                    without_refunds: 92.43,
                    with_refunds: 83.37,
                  },
                  net_total_spread: {
                    without_refunds: 21.44,
                    with_refunds: 20.97,
                  },
                  gross_total_spread: {
                    without_refunds: 24.01,
                    with_refunds: 23.44,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.24,
                    with_refunds: 37.79,
                  },
                  gross_mrr: {
                    without_refunds: 45.25,
                    with_refunds: 43.5,
                  },
                  net_arr: {
                    without_refunds: 277.51,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 295.01,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 22.65,
                    with_refunds: 22.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 24.35,
                    with_refunds: 24.35,
                  },
                  net_total: {
                    without_refunds: 150.86,
                    with_refunds: 134.77,
                  },
                  gross_total: {
                    without_refunds: 162.37,
                    with_refunds: 145.38,
                  },
                  net_total_spread: {
                    without_refunds: 30.45,
                    with_refunds: 29.77,
                  },
                  gross_total_spread: {
                    without_refunds: 34.21,
                    with_refunds: 33.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.5495,
                    with_refunds: 2.4552,
                  },
                  gross_mrr: {
                    without_refunds: 2.9388,
                    with_refunds: 2.8246,
                  },
                  net_arr: {
                    without_refunds: 13.7981,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.6864,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 1.7437,
                    with_refunds: 1.7437,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8699,
                    with_refunds: 1.8699,
                  },
                  net_total: {
                    without_refunds: 8.6026,
                    with_refunds: 7.7427,
                  },
                  gross_total: {
                    without_refunds: 9.2619,
                    with_refunds: 8.3536,
                  },
                  net_total_spread: {
                    without_refunds: 2.148,
                    with_refunds: 2.101,
                  },
                  gross_total_spread: {
                    without_refunds: 2.4055,
                    with_refunds: 2.3486,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.932,
                    with_refunds: 3.7867,
                  },
                  gross_mrr: {
                    without_refunds: 4.5345,
                    with_refunds: 4.3585,
                  },
                  net_arr: {
                    without_refunds: 27.8071,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 29.5598,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 2.2699,
                    with_refunds: 2.2699,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.4396,
                    with_refunds: 2.4396,
                  },
                  net_total: {
                    without_refunds: 15.116,
                    with_refunds: 13.5043,
                  },
                  gross_total: {
                    without_refunds: 16.2694,
                    with_refunds: 14.5668,
                  },
                  net_total_spread: {
                    without_refunds: 3.0512,
                    with_refunds: 2.9833,
                  },
                  gross_total_spread: {
                    without_refunds: 3.4277,
                    with_refunds: 3.3453,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 41,
                  with_refunds: 42,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 340,
                  with_refunds: 340,
                },
                total_users: {
                  without_refunds: 43,
                  with_refunds: 47,
                },
                total_spread_users: {
                  without_refunds: 381,
                  with_refunds: 382,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1217,
                  with_refunds: 0.1246,
                },
                arr_users: {
                  without_refunds: 0.0052,
                  with_refunds: 0.013,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.0598,
                  with_refunds: 0.0654,
                },
                total_spread_users: {
                  without_refunds: 0.5636,
                  with_refunds: 0.5651,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.1822,
                  with_refunds: 0.1867,
                },
                arr_users: {
                  without_refunds: 0.0114,
                  with_refunds: 0.0275,
                },
                arr_spread_users: {
                  without_refunds: 1.0059,
                  with_refunds: 1.0059,
                },
                total_users: {
                  without_refunds: 0.1075,
                  with_refunds: 0.1158,
                },
                total_spread_users: {
                  without_refunds: 0.6767,
                  with_refunds: 0.6785,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 781.09,
                  with_refunds: 726.09,
                },
                gross_mrr: {
                  without_refunds: 846.33,
                  with_refunds: 785.03,
                },
                net_arr: {
                  without_refunds: 218.25,
                },
                gross_arr: {
                  without_refunds: 267.91,
                },
                net_arr_spread: {
                  without_refunds: 3904.53,
                  with_refunds: 3904.53,
                },
                gross_arr_spread: {
                  without_refunds: 4168.65,
                  with_refunds: 4168.65,
                },
                net_total: {
                  without_refunds: 999.34,
                  with_refunds: 726.09,
                },
                gross_total: {
                  without_refunds: 1114.24,
                  with_refunds: 785.03,
                },
                net_total_spread: {
                  without_refunds: 4685.62,
                  with_refunds: 4630.62,
                },
                gross_total_spread: {
                  without_refunds: 5014.98,
                  with_refunds: 4953.69,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1881,
                  with_refunds: 0.1813,
                },
                gross_mrr: {
                  without_refunds: 0.1739,
                  with_refunds: 0.1677,
                },
                net_arr: {
                  without_refunds: 0.0078,
                },
                gross_arr: {
                  without_refunds: 0.0089,
                },
                net_arr_spread: {
                  without_refunds: 1.9231,
                  with_refunds: 1.9231,
                },
                gross_arr_spread: {
                  without_refunds: 1.896,
                  with_refunds: 1.896,
                },
                net_total: {
                  without_refunds: 0.0311,
                  with_refunds: 0.0248,
                },
                gross_total: {
                  without_refunds: 0.0317,
                  with_refunds: 0.0245,
                },
                net_total_spread: {
                  without_refunds: 0.7578,
                  with_refunds: 0.7674,
                },
                gross_total_spread: {
                  without_refunds: 0.7098,
                  with_refunds: 0.7199,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.1767,
                  with_refunds: 0.1707,
                },
                gross_mrr: {
                  without_refunds: 0.1687,
                  with_refunds: 0.163,
                },
                net_arr: {
                  without_refunds: 0.0087,
                },
                gross_arr: {
                  without_refunds: 0.0102,
                },
                net_arr_spread: {
                  without_refunds: 1.0047,
                  with_refunds: 1.0047,
                },
                gross_arr_spread: {
                  without_refunds: 1.0054,
                  with_refunds: 1.0054,
                },
                net_total: {
                  without_refunds: 0.0338,
                  with_refunds: 0.0277,
                },
                gross_total: {
                  without_refunds: 0.0356,
                  with_refunds: 0.0281,
                },
                net_total_spread: {
                  without_refunds: 0.564,
                  with_refunds: 0.5689,
                },
                gross_total_spread: {
                  without_refunds: 0.5473,
                  with_refunds: 0.5526,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.32,
                  with_refunds: 2.15,
                },
                gross_mrr: {
                  without_refunds: 2.51,
                  with_refunds: 2.33,
                },
                net_arr: {
                  without_refunds: 0.57,
                },
                gross_arr: {
                  without_refunds: 0.69,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 1.39,
                  with_refunds: 1.01,
                },
                gross_total: {
                  without_refunds: 1.55,
                  with_refunds: 1.09,
                },
                net_total_spread: {
                  without_refunds: 6.93,
                  with_refunds: 6.85,
                },
                gross_total_spread: {
                  without_refunds: 7.42,
                  with_refunds: 7.33,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.05,
                  with_refunds: 17.29,
                },
                gross_mrr: {
                  without_refunds: 20.64,
                  with_refunds: 18.69,
                },
                net_arr: {
                  without_refunds: 109.12,
                },
                gross_arr: {
                  without_refunds: 133.95,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 23.24,
                  with_refunds: 15.45,
                },
                gross_total: {
                  without_refunds: 25.91,
                  with_refunds: 16.7,
                },
                net_total_spread: {
                  without_refunds: 12.3,
                  with_refunds: 12.12,
                },
                gross_total_spread: {
                  without_refunds: 13.16,
                  with_refunds: 12.97,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.76,
                    with_refunds: 26.66,
                  },
                  gross_mrr: {
                    without_refunds: 31.84,
                    with_refunds: 30.52,
                  },
                  net_arr: {
                    without_refunds: 138.27,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.26,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 28.89,
                    with_refunds: 28.89,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.92,
                    with_refunds: 30.92,
                  },
                  net_total: {
                    without_refunds: 87.24,
                    with_refunds: 78.28,
                  },
                  gross_total: {
                    without_refunds: 93.98,
                    with_refunds: 84.46,
                  },
                  net_total_spread: {
                    without_refunds: 28.37,
                    with_refunds: 27.82,
                  },
                  gross_total_spread: {
                    without_refunds: 31.43,
                    with_refunds: 30.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 58.29,
                    with_refunds: 55.08,
                  },
                  gross_mrr: {
                    without_refunds: 65.9,
                    with_refunds: 62.19,
                  },
                  net_arr: {
                    without_refunds: 386.64,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 428.96,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 34.14,
                    with_refunds: 34.14,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.61,
                    with_refunds: 36.61,
                  },
                  net_total: {
                    without_refunds: 174.1,
                    with_refunds: 150.22,
                  },
                  gross_total: {
                    without_refunds: 188.28,
                    with_refunds: 162.08,
                  },
                  net_total_spread: {
                    without_refunds: 42.75,
                    with_refunds: 41.9,
                  },
                  gross_total_spread: {
                    without_refunds: 47.37,
                    with_refunds: 46.35,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.7817,
                    with_refunds: 2.6711,
                  },
                  gross_mrr: {
                    without_refunds: 3.1904,
                    with_refunds: 3.058,
                  },
                  net_arr: {
                    without_refunds: 13.8548,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7559,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 2.8944,
                    with_refunds: 2.8944,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.0984,
                    with_refunds: 3.0984,
                  },
                  net_total: {
                    without_refunds: 8.7418,
                    with_refunds: 7.8439,
                  },
                  gross_total: {
                    without_refunds: 9.4172,
                    with_refunds: 8.463,
                  },
                  net_total_spread: {
                    without_refunds: 2.8425,
                    with_refunds: 2.7873,
                  },
                  gross_total_spread: {
                    without_refunds: 3.1489,
                    with_refunds: 3.0829,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 5.8409,
                    with_refunds: 5.5189,
                  },
                  gross_mrr: {
                    without_refunds: 6.6029,
                    with_refunds: 6.2314,
                  },
                  net_arr: {
                    without_refunds: 38.7414,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 42.9821,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 3.4206,
                    with_refunds: 3.4206,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.6682,
                    with_refunds: 3.6682,
                  },
                  net_total: {
                    without_refunds: 17.4447,
                    with_refunds: 15.0523,
                  },
                  gross_total: {
                    without_refunds: 18.8658,
                    with_refunds: 16.2404,
                  },
                  net_total_spread: {
                    without_refunds: 4.2834,
                    with_refunds: 4.1979,
                  },
                  gross_total_spread: {
                    without_refunds: 4.7466,
                    with_refunds: 4.6447,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 212,
              with_refunds: 212,
            },
            returning_users: {
              without_refunds: 4767,
              with_refunds: 4784,
            },
            paying_users: {
              without_refunds: 4979,
              with_refunds: 4996,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 208,
              with_refunds: 208,
            },
            returning_users: {
              without_refunds: 444,
              with_refunds: 501,
            },
            paying_users: {
              without_refunds: 652,
              with_refunds: 709,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 418,
              with_refunds: 418,
            },
            returning_users: {
              without_refunds: 5211,
              with_refunds: 5285,
            },
            paying_users: {
              without_refunds: 5629,
              with_refunds: 5703,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 9355.74,
                with_refunds: 8983.5,
              },
              gross_mrr: {
                without_refunds: 10730.2,
                with_refunds: 10284.91,
              },
              net_arr: {
                without_refunds: 53372.41,
                with_refunds: 47301.47,
              },
              gross_arr: {
                without_refunds: 56843.85,
                with_refunds: 50442.44,
              },
              net_arr_spread: {
                without_refunds: 9821.18,
                with_refunds: 9821.18,
              },
              gross_arr_spread: {
                without_refunds: 10513.61,
                with_refunds: 10513.61,
              },
              net_total: {
                without_refunds: 62728.14,
                with_refunds: 56284.97,
              },
              gross_total: {
                without_refunds: 67574.05,
                with_refunds: 60727.35,
              },
              net_total_spread: {
                without_refunds: 19176.92,
                with_refunds: 18804.69,
              },
              gross_total_spread: {
                without_refunds: 21243.81,
                with_refunds: 20798.52,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 9355.74,
                with_refunds: 8983.5,
              },
              gross_mrr: {
                without_refunds: 10730.2,
                with_refunds: 10284.91,
              },
              net_arr: {
                without_refunds: 53372.41,
                with_refunds: 47301.47,
              },
              gross_arr: {
                without_refunds: 56843.85,
                with_refunds: 50442.44,
              },
              net_arr_spread: {
                without_refunds: 9821.18,
                with_refunds: 9821.18,
              },
              gross_arr_spread: {
                without_refunds: 10513.61,
                with_refunds: 10513.61,
              },
              net_total: {
                without_refunds: 62728.14,
                with_refunds: 56284.97,
              },
              gross_total: {
                without_refunds: 67574.05,
                with_refunds: 60727.35,
              },
              net_total_spread: {
                without_refunds: 19176.92,
                with_refunds: 18804.69,
              },
              gross_total_spread: {
                without_refunds: 21243.81,
                with_refunds: 20798.52,
              },
            },
          },
        },
      },
      cac: 9.98,
      mrr_ua_spend: {
        without_refunds: 3363.26,
        with_refunds: 3363.26,
      },
      arr_ua_spend: {
        without_refunds: 3852.28,
        with_refunds: 3852.28,
      },
      arr_spread_ua_spend: {
        without_refunds: 3393.2,
        with_refunds: 3393.2,
      },
      total_ua_spend: {
        without_refunds: 7175.62,
        with_refunds: 7175.62,
      },
      total_spread_ua_spend: {
        without_refunds: 6746.48,
        with_refunds: 6746.48,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 6,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 263,
        with_refunds: 263,
      },
      first_period_arr_users: {
        without_refunds: 199,
        with_refunds: 199,
      },
      first_period_arr_spread_users: {
        without_refunds: 180,
        with_refunds: 180,
      },
      first_period_total_users: {
        without_refunds: 462,
        with_refunds: 462,
      },
      first_period_total_spread_users: {
        without_refunds: 443,
        with_refunds: 443,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                arr_users: {
                  without_refunds: 168,
                  with_refunds: 168,
                },
                arr_spread_users: {
                  without_refunds: 149,
                  with_refunds: 149,
                },
                total_users: {
                  without_refunds: 380,
                  with_refunds: 380,
                },
                total_spread_users: {
                  without_refunds: 361,
                  with_refunds: 361,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.8061,
                  with_refunds: 0.8061,
                },
                arr_users: {
                  without_refunds: 0.8442,
                  with_refunds: 0.8442,
                },
                arr_spread_users: {
                  without_refunds: 0.8278,
                  with_refunds: 0.8278,
                },
                total_users: {
                  without_refunds: 0.8225,
                  with_refunds: 0.8225,
                },
                total_spread_users: {
                  without_refunds: 0.8149,
                  with_refunds: 0.8149,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3957.64,
                  with_refunds: 3687.91,
                },
                gross_mrr: {
                  without_refunds: 4535.94,
                  with_refunds: 4246.68,
                },
                net_arr: {
                  without_refunds: 22899.94,
                  with_refunds: 21124.35,
                },
                gross_arr: {
                  without_refunds: 24525.26,
                  with_refunds: 22677.31,
                },
                net_arr_spread: {
                  without_refunds: 1700.48,
                  with_refunds: 1700.48,
                },
                gross_arr_spread: {
                  without_refunds: 1826.59,
                  with_refunds: 1826.59,
                },
                net_total: {
                  without_refunds: 26857.58,
                  with_refunds: 24812.26,
                },
                gross_total: {
                  without_refunds: 29061.21,
                  with_refunds: 26923.99,
                },
                net_total_spread: {
                  without_refunds: 5658.12,
                  with_refunds: 5388.39,
                },
                gross_total_spread: {
                  without_refunds: 6362.53,
                  with_refunds: 6073.27,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 15.05,
                  with_refunds: 14.02,
                },
                gross_mrr: {
                  without_refunds: 17.25,
                  with_refunds: 16.15,
                },
                net_arr: {
                  without_refunds: 115.08,
                  with_refunds: 106.15,
                },
                gross_arr: {
                  without_refunds: 123.24,
                  with_refunds: 113.96,
                },
                net_arr_spread: {
                  without_refunds: 9.45,
                  with_refunds: 9.45,
                },
                gross_arr_spread: {
                  without_refunds: 10.15,
                  with_refunds: 10.15,
                },
                net_total: {
                  without_refunds: 58.13,
                  with_refunds: 53.71,
                },
                gross_total: {
                  without_refunds: 62.9,
                  with_refunds: 58.28,
                },
                net_total_spread: {
                  without_refunds: 12.77,
                  with_refunds: 12.16,
                },
                gross_total_spread: {
                  without_refunds: 14.36,
                  with_refunds: 13.71,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 18.67,
                  with_refunds: 17.4,
                },
                gross_mrr: {
                  without_refunds: 21.4,
                  with_refunds: 20.03,
                },
                net_arr: {
                  without_refunds: 136.31,
                  with_refunds: 125.74,
                },
                gross_arr: {
                  without_refunds: 145.98,
                  with_refunds: 134.98,
                },
                net_arr_spread: {
                  without_refunds: 11.41,
                  with_refunds: 11.41,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 70.68,
                  with_refunds: 65.3,
                },
                gross_total: {
                  without_refunds: 76.48,
                  with_refunds: 70.85,
                },
                net_total_spread: {
                  without_refunds: 15.67,
                  with_refunds: 14.93,
                },
                gross_total_spread: {
                  without_refunds: 17.62,
                  with_refunds: 16.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.05,
                    with_refunds: 14.02,
                  },
                  gross_mrr: {
                    without_refunds: 17.25,
                    with_refunds: 16.15,
                  },
                  net_arr: {
                    without_refunds: 115.08,
                    with_refunds: 106.15,
                  },
                  gross_arr: {
                    without_refunds: 123.24,
                    with_refunds: 113.96,
                  },
                  net_arr_spread: {
                    without_refunds: 9.45,
                    with_refunds: 9.45,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.15,
                    with_refunds: 10.15,
                  },
                  net_total: {
                    without_refunds: 58.13,
                    with_refunds: 53.71,
                  },
                  gross_total: {
                    without_refunds: 62.9,
                    with_refunds: 58.28,
                  },
                  net_total_spread: {
                    without_refunds: 12.77,
                    with_refunds: 12.16,
                  },
                  gross_total_spread: {
                    without_refunds: 14.36,
                    with_refunds: 13.71,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.67,
                    with_refunds: 17.4,
                  },
                  gross_mrr: {
                    without_refunds: 21.4,
                    with_refunds: 20.03,
                  },
                  net_arr: {
                    without_refunds: 136.31,
                    with_refunds: 125.74,
                  },
                  gross_arr: {
                    without_refunds: 145.98,
                    with_refunds: 134.98,
                  },
                  net_arr_spread: {
                    without_refunds: 11.41,
                    with_refunds: 11.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.26,
                    with_refunds: 12.26,
                  },
                  net_total: {
                    without_refunds: 70.68,
                    with_refunds: 65.3,
                  },
                  gross_total: {
                    without_refunds: 76.48,
                    with_refunds: 70.85,
                  },
                  net_total_spread: {
                    without_refunds: 15.67,
                    with_refunds: 14.93,
                  },
                  gross_total_spread: {
                    without_refunds: 17.62,
                    with_refunds: 16.82,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 58,
                  with_refunds: 59,
                },
                arr_users: {
                  without_refunds: 31,
                  with_refunds: 37,
                },
                arr_spread_users: {
                  without_refunds: 180,
                  with_refunds: 180,
                },
                total_users: {
                  without_refunds: 89,
                  with_refunds: 96,
                },
                total_spread_users: {
                  without_refunds: 238,
                  with_refunds: 239,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2205,
                  with_refunds: 0.2243,
                },
                arr_users: {
                  without_refunds: 0.1558,
                  with_refunds: 0.1859,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.1926,
                  with_refunds: 0.2078,
                },
                total_spread_users: {
                  without_refunds: 0.5372,
                  with_refunds: 0.5395,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2736,
                  with_refunds: 0.2783,
                },
                arr_users: {
                  without_refunds: 0.1845,
                  with_refunds: 0.2202,
                },
                arr_spread_users: {
                  without_refunds: 1.2081,
                  with_refunds: 1.2081,
                },
                total_users: {
                  without_refunds: 0.2342,
                  with_refunds: 0.2526,
                },
                total_spread_users: {
                  without_refunds: 0.6593,
                  with_refunds: 0.662,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1053.89,
                  with_refunds: 1036.9,
                },
                gross_mrr: {
                  without_refunds: 1166.68,
                  with_refunds: 1146.69,
                },
                net_arr: {
                  without_refunds: 4340.72,
                  with_refunds: 3641.23,
                },
                gross_arr: {
                  without_refunds: 4558.05,
                  with_refunds: 3819.8,
                },
                net_arr_spread: {
                  without_refunds: 2062.21,
                  with_refunds: 2062.21,
                },
                gross_arr_spread: {
                  without_refunds: 2206.43,
                  with_refunds: 2206.43,
                },
                net_total: {
                  without_refunds: 5394.61,
                  with_refunds: 4678.13,
                },
                gross_total: {
                  without_refunds: 5724.73,
                  with_refunds: 4966.5,
                },
                net_total_spread: {
                  without_refunds: 3116.1,
                  with_refunds: 3099.11,
                },
                gross_total_spread: {
                  without_refunds: 3373.11,
                  with_refunds: 3353.12,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2663,
                  with_refunds: 0.2812,
                },
                gross_mrr: {
                  without_refunds: 0.2572,
                  with_refunds: 0.27,
                },
                net_arr: {
                  without_refunds: 0.1896,
                  with_refunds: 0.1724,
                },
                gross_arr: {
                  without_refunds: 0.1859,
                  with_refunds: 0.1684,
                },
                net_arr_spread: {
                  without_refunds: 1.2127,
                  with_refunds: 1.2127,
                },
                gross_arr_spread: {
                  without_refunds: 1.2079,
                  with_refunds: 1.2079,
                },
                net_total: {
                  without_refunds: 0.2009,
                  with_refunds: 0.1885,
                },
                gross_total: {
                  without_refunds: 0.197,
                  with_refunds: 0.1845,
                },
                net_total_spread: {
                  without_refunds: 0.5507,
                  with_refunds: 0.5751,
                },
                gross_total_spread: {
                  without_refunds: 0.5302,
                  with_refunds: 0.5521,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2663,
                  with_refunds: 0.2812,
                },
                gross_mrr: {
                  without_refunds: 0.2572,
                  with_refunds: 0.27,
                },
                net_arr: {
                  without_refunds: 0.1896,
                  with_refunds: 0.1724,
                },
                gross_arr: {
                  without_refunds: 0.1859,
                  with_refunds: 0.1684,
                },
                net_arr_spread: {
                  without_refunds: 1.2127,
                  with_refunds: 1.2127,
                },
                gross_arr_spread: {
                  without_refunds: 1.2079,
                  with_refunds: 1.2079,
                },
                net_total: {
                  without_refunds: 0.2009,
                  with_refunds: 0.1885,
                },
                gross_total: {
                  without_refunds: 0.197,
                  with_refunds: 0.1845,
                },
                net_total_spread: {
                  without_refunds: 0.5507,
                  with_refunds: 0.5751,
                },
                gross_total_spread: {
                  without_refunds: 0.5302,
                  with_refunds: 0.5521,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.01,
                  with_refunds: 3.94,
                },
                gross_mrr: {
                  without_refunds: 4.44,
                  with_refunds: 4.36,
                },
                net_arr: {
                  without_refunds: 21.81,
                  with_refunds: 18.3,
                },
                gross_arr: {
                  without_refunds: 22.9,
                  with_refunds: 19.19,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 11.68,
                  with_refunds: 10.13,
                },
                gross_total: {
                  without_refunds: 12.39,
                  with_refunds: 10.75,
                },
                net_total_spread: {
                  without_refunds: 7.03,
                  with_refunds: 7,
                },
                gross_total_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.57,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 18.17,
                  with_refunds: 17.57,
                },
                gross_mrr: {
                  without_refunds: 20.12,
                  with_refunds: 19.44,
                },
                net_arr: {
                  without_refunds: 140.02,
                  with_refunds: 98.41,
                },
                gross_arr: {
                  without_refunds: 147.03,
                  with_refunds: 103.24,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 60.61,
                  with_refunds: 48.73,
                },
                gross_total: {
                  without_refunds: 64.32,
                  with_refunds: 51.73,
                },
                net_total_spread: {
                  without_refunds: 13.09,
                  with_refunds: 12.97,
                },
                gross_total_spread: {
                  without_refunds: 14.17,
                  with_refunds: 14.03,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 19.06,
                    with_refunds: 17.97,
                  },
                  gross_mrr: {
                    without_refunds: 21.68,
                    with_refunds: 20.51,
                  },
                  net_arr: {
                    without_refunds: 136.89,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.15,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 20.9,
                    with_refunds: 20.9,
                  },
                  gross_arr_spread: {
                    without_refunds: 22.41,
                    with_refunds: 22.41,
                  },
                  net_total: {
                    without_refunds: 69.81,
                    with_refunds: 63.83,
                  },
                  gross_total: {
                    without_refunds: 75.29,
                    with_refunds: 69.03,
                  },
                  net_total_spread: {
                    without_refunds: 19.81,
                    with_refunds: 19.16,
                  },
                  gross_total_spread: {
                    without_refunds: 21.98,
                    with_refunds: 21.28,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.84,
                    with_refunds: 34.97,
                  },
                  gross_mrr: {
                    without_refunds: 41.51,
                    with_refunds: 39.47,
                  },
                  net_arr: {
                    without_refunds: 276.33,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 293.02,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 22.87,
                    with_refunds: 22.87,
                  },
                  gross_arr_spread: {
                    without_refunds: 24.52,
                    with_refunds: 24.52,
                  },
                  net_total: {
                    without_refunds: 131.29,
                    with_refunds: 114.03,
                  },
                  gross_total: {
                    without_refunds: 140.8,
                    with_refunds: 122.59,
                  },
                  net_total_spread: {
                    without_refunds: 28.77,
                    with_refunds: 27.89,
                  },
                  gross_total_spread: {
                    without_refunds: 31.8,
                    with_refunds: 30.85,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 212,
              with_refunds: 212,
            },
            returning_users: {
              without_refunds: 4325,
              with_refunds: 4338,
            },
            paying_users: {
              without_refunds: 4537,
              with_refunds: 4550,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 168,
              with_refunds: 168,
            },
            returning_users: {
              without_refunds: 193,
              with_refunds: 211,
            },
            paying_users: {
              without_refunds: 361,
              with_refunds: 379,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 380,
              with_refunds: 380,
            },
            returning_users: {
              without_refunds: 4517,
              with_refunds: 4548,
            },
            paying_users: {
              without_refunds: 4897,
              with_refunds: 4928,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 5011.53,
                with_refunds: 4724.81,
              },
              gross_mrr: {
                without_refunds: 5702.63,
                with_refunds: 5393.37,
              },
              net_arr: {
                without_refunds: 27240.66,
                with_refunds: 24765.58,
              },
              gross_arr: {
                without_refunds: 29083.31,
                with_refunds: 26497.12,
              },
              net_arr_spread: {
                without_refunds: 3762.69,
                with_refunds: 3762.69,
              },
              gross_arr_spread: {
                without_refunds: 4033.02,
                with_refunds: 4033.02,
              },
              net_total: {
                without_refunds: 32252.2,
                with_refunds: 29490.4,
              },
              gross_total: {
                without_refunds: 34785.94,
                with_refunds: 31890.48,
              },
              net_total_spread: {
                without_refunds: 8774.22,
                with_refunds: 8487.5,
              },
              gross_total_spread: {
                without_refunds: 9735.64,
                with_refunds: 9426.39,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 5011.53,
                with_refunds: 4724.81,
              },
              gross_mrr: {
                without_refunds: 5702.63,
                with_refunds: 5393.37,
              },
              net_arr: {
                without_refunds: 27240.66,
                with_refunds: 24765.58,
              },
              gross_arr: {
                without_refunds: 29083.31,
                with_refunds: 26497.12,
              },
              net_arr_spread: {
                without_refunds: 3762.69,
                with_refunds: 3762.69,
              },
              gross_arr_spread: {
                without_refunds: 4033.02,
                with_refunds: 4033.02,
              },
              net_total: {
                without_refunds: 32252.2,
                with_refunds: 29490.4,
              },
              gross_total: {
                without_refunds: 34785.94,
                with_refunds: 31890.48,
              },
              net_total_spread: {
                without_refunds: 8774.22,
                with_refunds: 8487.5,
              },
              gross_total_spread: {
                without_refunds: 9735.64,
                with_refunds: 9426.39,
              },
            },
          },
        },
      },
      mrr_ua_spend: {},
      arr_ua_spend: {},
      arr_spread_ua_spend: {},
      total_ua_spend: {},
      total_spread_ua_spend: {},
    },
    {
      cohort_date: {
        year: 2024,
        month: 7,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 13,
        with_refunds: 13,
      },
      first_period_arr_users: {
        without_refunds: 11,
        with_refunds: 11,
      },
      first_period_arr_spread_users: {
        without_refunds: 10,
        with_refunds: 10,
      },
      first_period_total_users: {
        without_refunds: 24,
        with_refunds: 24,
      },
      first_period_total_spread_users: {
        without_refunds: 23,
        with_refunds: 23,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 13,
                  with_refunds: 13,
                },
                arr_users: {
                  without_refunds: 11,
                  with_refunds: 11,
                },
                arr_spread_users: {
                  without_refunds: 10,
                  with_refunds: 10,
                },
                total_users: {
                  without_refunds: 24,
                  with_refunds: 24,
                },
                total_spread_users: {
                  without_refunds: 23,
                  with_refunds: 23,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 239.75,
                  with_refunds: 222.25,
                },
                gross_mrr: {
                  without_refunds: 265.48,
                  with_refunds: 240.71,
                },
                net_arr: {
                  without_refunds: 1302.11,
                  with_refunds: 1181.04,
                },
                gross_arr: {
                  without_refunds: 1374.98,
                  with_refunds: 1249.98,
                },
                net_arr_spread: {
                  without_refunds: 98.42,
                  with_refunds: 98.42,
                },
                gross_arr_spread: {
                  without_refunds: 104.16,
                  with_refunds: 104.16,
                },
                net_total: {
                  without_refunds: 1541.86,
                  with_refunds: 1403.29,
                },
                gross_total: {
                  without_refunds: 1640.46,
                  with_refunds: 1490.69,
                },
                net_total_spread: {
                  without_refunds: 338.17,
                  with_refunds: 320.67,
                },
                gross_total_spread: {
                  without_refunds: 369.65,
                  with_refunds: 344.87,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 18.44,
                  with_refunds: 17.1,
                },
                gross_mrr: {
                  without_refunds: 20.42,
                  with_refunds: 18.52,
                },
                net_arr: {
                  without_refunds: 118.37,
                  with_refunds: 107.37,
                },
                gross_arr: {
                  without_refunds: 125,
                  with_refunds: 113.63,
                },
                net_arr_spread: {
                  without_refunds: 9.84,
                  with_refunds: 9.84,
                },
                gross_arr_spread: {
                  without_refunds: 10.42,
                  with_refunds: 10.42,
                },
                net_total: {
                  without_refunds: 64.24,
                  with_refunds: 58.47,
                },
                gross_total: {
                  without_refunds: 68.35,
                  with_refunds: 62.11,
                },
                net_total_spread: {
                  without_refunds: 14.7,
                  with_refunds: 13.94,
                },
                gross_total_spread: {
                  without_refunds: 16.07,
                  with_refunds: 14.99,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 18.44,
                  with_refunds: 17.1,
                },
                gross_mrr: {
                  without_refunds: 20.42,
                  with_refunds: 18.52,
                },
                net_arr: {
                  without_refunds: 118.37,
                  with_refunds: 107.37,
                },
                gross_arr: {
                  without_refunds: 125,
                  with_refunds: 113.63,
                },
                net_arr_spread: {
                  without_refunds: 9.84,
                  with_refunds: 9.84,
                },
                gross_arr_spread: {
                  without_refunds: 10.42,
                  with_refunds: 10.42,
                },
                net_total: {
                  without_refunds: 64.24,
                  with_refunds: 58.47,
                },
                gross_total: {
                  without_refunds: 68.35,
                  with_refunds: 62.11,
                },
                net_total_spread: {
                  without_refunds: 14.7,
                  with_refunds: 13.94,
                },
                gross_total_spread: {
                  without_refunds: 16.07,
                  with_refunds: 14.99,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.44,
                    with_refunds: 17.1,
                  },
                  gross_mrr: {
                    without_refunds: 20.42,
                    with_refunds: 18.52,
                  },
                  net_arr: {
                    without_refunds: 118.37,
                    with_refunds: 107.37,
                  },
                  gross_arr: {
                    without_refunds: 125,
                    with_refunds: 113.63,
                  },
                  net_arr_spread: {
                    without_refunds: 9.84,
                    with_refunds: 9.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.42,
                    with_refunds: 10.42,
                  },
                  net_total: {
                    without_refunds: 64.24,
                    with_refunds: 58.47,
                  },
                  gross_total: {
                    without_refunds: 68.35,
                    with_refunds: 62.11,
                  },
                  net_total_spread: {
                    without_refunds: 14.7,
                    with_refunds: 13.94,
                  },
                  gross_total_spread: {
                    without_refunds: 16.07,
                    with_refunds: 14.99,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.44,
                    with_refunds: 17.1,
                  },
                  gross_mrr: {
                    without_refunds: 20.42,
                    with_refunds: 18.52,
                  },
                  net_arr: {
                    without_refunds: 118.37,
                    with_refunds: 107.37,
                  },
                  gross_arr: {
                    without_refunds: 125,
                    with_refunds: 113.63,
                  },
                  net_arr_spread: {
                    without_refunds: 9.84,
                    with_refunds: 9.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.42,
                    with_refunds: 10.42,
                  },
                  net_total: {
                    without_refunds: 64.24,
                    with_refunds: 58.47,
                  },
                  gross_total: {
                    without_refunds: 68.35,
                    with_refunds: 62.11,
                  },
                  net_total_spread: {
                    without_refunds: 14.7,
                    with_refunds: 13.94,
                  },
                  gross_total_spread: {
                    without_refunds: 16.07,
                    with_refunds: 14.99,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 13,
              with_refunds: 13,
            },
            returning_users: {
              without_refunds: 1153,
              with_refunds: 1157,
            },
            paying_users: {
              without_refunds: 1166,
              with_refunds: 1170,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 11,
              with_refunds: 11,
            },
            returning_users: {
              without_refunds: 36,
              with_refunds: 48,
            },
            paying_users: {
              without_refunds: 47,
              with_refunds: 59,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 24,
              with_refunds: 24,
            },
            returning_users: {
              without_refunds: 1189,
              with_refunds: 1205,
            },
            paying_users: {
              without_refunds: 1213,
              with_refunds: 1229,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 239.75,
                with_refunds: 222.25,
              },
              gross_mrr: {
                without_refunds: 265.48,
                with_refunds: 240.71,
              },
              net_arr: {
                without_refunds: 1302.11,
                with_refunds: 1181.04,
              },
              gross_arr: {
                without_refunds: 1374.98,
                with_refunds: 1249.98,
              },
              net_arr_spread: {
                without_refunds: 98.42,
                with_refunds: 98.42,
              },
              gross_arr_spread: {
                without_refunds: 104.16,
                with_refunds: 104.16,
              },
              net_total: {
                without_refunds: 1541.86,
                with_refunds: 1403.29,
              },
              gross_total: {
                without_refunds: 1640.46,
                with_refunds: 1490.69,
              },
              net_total_spread: {
                without_refunds: 338.17,
                with_refunds: 320.67,
              },
              gross_total_spread: {
                without_refunds: 369.65,
                with_refunds: 344.87,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 239.75,
                with_refunds: 222.25,
              },
              gross_mrr: {
                without_refunds: 265.48,
                with_refunds: 240.71,
              },
              net_arr: {
                without_refunds: 1302.11,
                with_refunds: 1181.04,
              },
              gross_arr: {
                without_refunds: 1374.98,
                with_refunds: 1249.98,
              },
              net_arr_spread: {
                without_refunds: 98.42,
                with_refunds: 98.42,
              },
              gross_arr_spread: {
                without_refunds: 104.16,
                with_refunds: 104.16,
              },
              net_total: {
                without_refunds: 1541.86,
                with_refunds: 1403.29,
              },
              gross_total: {
                without_refunds: 1640.46,
                with_refunds: 1490.69,
              },
              net_total_spread: {
                without_refunds: 338.17,
                with_refunds: 320.67,
              },
              gross_total_spread: {
                without_refunds: 369.65,
                with_refunds: 344.87,
              },
            },
          },
        },
      },
      mrr_ua_spend: {},
      arr_ua_spend: {},
      arr_spread_ua_spend: {},
      total_ua_spend: {},
      total_spread_ua_spend: {},
    },
  ],
  vectors: [
    {
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.63,
                with_refunds: 0.63,
              },
              arr_users: {
                without_refunds: 0.5267,
                with_refunds: 0.5267,
              },
              arr_spread_users: {
                without_refunds: 0.5341,
                with_refunds: 0.5341,
              },
              total_users: {
                without_refunds: 0.5965,
                with_refunds: 0.5965,
              },
              total_spread_users: {
                without_refunds: 0.6029,
                with_refunds: 0.6029,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.7129,
                with_refunds: 0.7129,
              },
              arr_users: {
                without_refunds: 0.6493,
                with_refunds: 0.6493,
              },
              arr_spread_users: {
                without_refunds: 0.6434,
                with_refunds: 0.6434,
              },
              total_users: {
                without_refunds: 0.6822,
                with_refunds: 0.6822,
              },
              total_spread_users: {
                without_refunds: 0.6813,
                with_refunds: 0.6813,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.6719,
                with_refunds: 0.6719,
              },
              arr_users: {
                without_refunds: 0.5299,
                with_refunds: 0.5299,
              },
              arr_spread_users: {
                without_refunds: 0.526,
                with_refunds: 0.526,
              },
              total_users: {
                without_refunds: 0.5763,
                with_refunds: 0.5763,
              },
              total_spread_users: {
                without_refunds: 0.5818,
                with_refunds: 0.5818,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 7.19,
                with_refunds: 6.97,
              },
              gross_mrr: {
                without_refunds: 8.89,
                with_refunds: 8.62,
              },
              net_arr: {
                without_refunds: 55.52,
                with_refunds: 48.62,
              },
              gross_arr: {
                without_refunds: 63.03,
                with_refunds: 55.31,
              },
              net_arr_spread: {
                without_refunds: 4.63,
                with_refunds: 4.63,
              },
              gross_arr_spread: {
                without_refunds: 5.28,
                with_refunds: 5.28,
              },
              net_total: {
                without_refunds: 25.34,
                with_refunds: 22.62,
              },
              gross_total: {
                without_refunds: 29.22,
                with_refunds: 26.16,
              },
              net_total_spread: {
                without_refunds: 6.41,
                with_refunds: 6.26,
              },
              gross_total_spread: {
                without_refunds: 7.77,
                with_refunds: 7.59,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 13.62,
                with_refunds: 12.91,
              },
              gross_mrr: {
                without_refunds: 15.77,
                with_refunds: 14.96,
              },
              net_arr: {
                without_refunds: 87.63,
                with_refunds: 79.87,
              },
              gross_arr: {
                without_refunds: 94.22,
                with_refunds: 86.03,
              },
              net_arr_spread: {
                without_refunds: 7.22,
                with_refunds: 7.22,
              },
              gross_arr_spread: {
                without_refunds: 7.79,
                with_refunds: 7.79,
              },
              net_total: {
                without_refunds: 50.27,
                with_refunds: 46.07,
              },
              gross_total: {
                without_refunds: 54.63,
                with_refunds: 50.16,
              },
              net_total_spread: {
                without_refunds: 10.67,
                with_refunds: 10.28,
              },
              gross_total_spread: {
                without_refunds: 12.08,
                with_refunds: 11.65,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 9.52,
                with_refunds: 9.09,
              },
              gross_mrr: {
                without_refunds: 10.89,
                with_refunds: 10.41,
              },
              net_arr: {
                without_refunds: 64.83,
                with_refunds: 54.74,
              },
              gross_arr: {
                without_refunds: 72.16,
                with_refunds: 60.99,
              },
              net_arr_spread: {
                without_refunds: 5.34,
                with_refunds: 5.34,
              },
              gross_arr_spread: {
                without_refunds: 5.97,
                with_refunds: 5.97,
              },
              net_total: {
                without_refunds: 47.63,
                with_refunds: 40.55,
              },
              gross_total: {
                without_refunds: 53.1,
                with_refunds: 45.26,
              },
              net_total_spread: {
                without_refunds: 6.92,
                with_refunds: 6.76,
              },
              gross_total_spread: {
                without_refunds: 7.83,
                with_refunds: 7.65,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 9.71,
                with_refunds: 9.2,
              },
              gross_mrr: {
                without_refunds: 11.24,
                with_refunds: 10.66,
              },
              net_arr: {
                without_refunds: 56.9,
                with_refunds: 51.86,
              },
              gross_arr: {
                without_refunds: 61.18,
                with_refunds: 55.86,
              },
              net_arr_spread: {
                without_refunds: 4.65,
                with_refunds: 4.65,
              },
              gross_arr_spread: {
                without_refunds: 5.01,
                with_refunds: 5.01,
              },
              net_total: {
                without_refunds: 34.29,
                with_refunds: 31.43,
              },
              gross_total: {
                without_refunds: 37.26,
                with_refunds: 34.22,
              },
              net_total_spread: {
                without_refunds: 7.27,
                with_refunds: 7.01,
              },
              gross_total_spread: {
                without_refunds: 8.23,
                with_refunds: 7.93,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 6.39,
                with_refunds: 6.11,
              },
              gross_mrr: {
                without_refunds: 7.32,
                with_refunds: 6.99,
              },
              net_arr: {
                without_refunds: 34.36,
                with_refunds: 29.01,
              },
              gross_arr: {
                without_refunds: 38.24,
                with_refunds: 32.32,
              },
              net_arr_spread: {
                without_refunds: 2.81,
                with_refunds: 2.81,
              },
              gross_arr_spread: {
                without_refunds: 3.14,
                with_refunds: 3.14,
              },
              net_total: {
                without_refunds: 27.45,
                with_refunds: 23.37,
              },
              gross_total: {
                without_refunds: 30.6,
                with_refunds: 26.08,
              },
              net_total_spread: {
                without_refunds: 4.03,
                with_refunds: 3.93,
              },
              gross_total_spread: {
                without_refunds: 4.56,
                with_refunds: 4.45,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.47,
                with_refunds: 11.12,
              },
              gross_mrr: {
                without_refunds: 14.14,
                with_refunds: 13.71,
              },
              net_arr: {
                without_refunds: 105.14,
                with_refunds: 92.31,
              },
              gross_arr: {
                without_refunds: 119.14,
                with_refunds: 104.79,
              },
              net_arr_spread: {
                without_refunds: 8.68,
                with_refunds: 8.68,
              },
              gross_arr_spread: {
                without_refunds: 9.87,
                with_refunds: 9.87,
              },
              net_total: {
                without_refunds: 43.6,
                with_refunds: 38.94,
              },
              gross_total: {
                without_refunds: 50.15,
                with_refunds: 44.92,
              },
              net_total_spread: {
                without_refunds: 10.65,
                with_refunds: 10.41,
              },
              gross_total_spread: {
                without_refunds: 12.87,
                with_refunds: 12.57,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.17,
                with_refunds: 18.21,
              },
              gross_mrr: {
                without_refunds: 22.23,
                with_refunds: 21.13,
              },
              net_arr: {
                without_refunds: 134.96,
                with_refunds: 122.73,
              },
              gross_arr: {
                without_refunds: 145.26,
                with_refunds: 132.32,
              },
              net_arr_spread: {
                without_refunds: 11.22,
                with_refunds: 11.22,
              },
              gross_arr_spread: {
                without_refunds: 12.11,
                with_refunds: 12.11,
              },
              net_total: {
                without_refunds: 74.31,
                with_refunds: 68.02,
              },
              gross_total: {
                without_refunds: 80.82,
                with_refunds: 74.12,
              },
              net_total_spread: {
                without_refunds: 15.67,
                with_refunds: 15.14,
              },
              gross_total_spread: {
                without_refunds: 17.78,
                with_refunds: 17.17,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.12,
                with_refunds: 13.51,
              },
              gross_mrr: {
                without_refunds: 16.18,
                with_refunds: 15.49,
              },
              net_arr: {
                without_refunds: 121.11,
                with_refunds: 102.4,
              },
              gross_arr: {
                without_refunds: 135.05,
                with_refunds: 114.29,
              },
              net_arr_spread: {
                without_refunds: 10.06,
                with_refunds: 10.06,
              },
              gross_arr_spread: {
                without_refunds: 11.28,
                with_refunds: 11.28,
              },
              net_total: {
                without_refunds: 82.06,
                with_refunds: 69.87,
              },
              gross_total: {
                without_refunds: 91.59,
                with_refunds: 78.08,
              },
              net_total_spread: {
                without_refunds: 11.76,
                with_refunds: 11.5,
              },
              gross_total_spread: {
                without_refunds: 13.32,
                with_refunds: 13.03,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 9.71,
                with_refunds: 9.2,
              },
              gross_mrr: {
                without_refunds: 11.24,
                with_refunds: 10.66,
              },
              net_arr: {
                without_refunds: 56.9,
                with_refunds: 51.86,
              },
              gross_arr: {
                without_refunds: 61.18,
                with_refunds: 55.86,
              },
              net_arr_spread: {
                without_refunds: 4.65,
                with_refunds: 4.65,
              },
              gross_arr_spread: {
                without_refunds: 5.01,
                with_refunds: 5.01,
              },
              net_total: {
                without_refunds: 34.29,
                with_refunds: 31.43,
              },
              gross_total: {
                without_refunds: 37.26,
                with_refunds: 34.22,
              },
              net_total_spread: {
                without_refunds: 7.27,
                with_refunds: 7.01,
              },
              gross_total_spread: {
                without_refunds: 8.23,
                with_refunds: 7.93,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 6.39,
                with_refunds: 6.11,
              },
              gross_mrr: {
                without_refunds: 7.32,
                with_refunds: 6.99,
              },
              net_arr: {
                without_refunds: 34.36,
                with_refunds: 29.01,
              },
              gross_arr: {
                without_refunds: 38.24,
                with_refunds: 32.32,
              },
              net_arr_spread: {
                without_refunds: 2.81,
                with_refunds: 2.81,
              },
              gross_arr_spread: {
                without_refunds: 3.14,
                with_refunds: 3.14,
              },
              net_total: {
                without_refunds: 27.45,
                with_refunds: 23.37,
              },
              gross_total: {
                without_refunds: 30.6,
                with_refunds: 26.08,
              },
              net_total_spread: {
                without_refunds: 4.03,
                with_refunds: 3.93,
              },
              gross_total_spread: {
                without_refunds: 4.56,
                with_refunds: 4.45,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 7.19,
                  with_refunds: 6.97,
                },
                gross_mrr: {
                  without_refunds: 8.89,
                  with_refunds: 8.62,
                },
                net_arr: {
                  without_refunds: 55.52,
                  with_refunds: 48.62,
                },
                gross_arr: {
                  without_refunds: 63.03,
                  with_refunds: 55.31,
                },
                net_arr_spread: {
                  without_refunds: 4.63,
                  with_refunds: 4.63,
                },
                gross_arr_spread: {
                  without_refunds: 5.28,
                  with_refunds: 5.28,
                },
                net_total: {
                  without_refunds: 25.34,
                  with_refunds: 22.62,
                },
                gross_total: {
                  without_refunds: 29.22,
                  with_refunds: 26.16,
                },
                net_total_spread: {
                  without_refunds: 6.41,
                  with_refunds: 6.26,
                },
                gross_total_spread: {
                  without_refunds: 7.77,
                  with_refunds: 7.59,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 13.62,
                  with_refunds: 12.91,
                },
                gross_mrr: {
                  without_refunds: 15.77,
                  with_refunds: 14.96,
                },
                net_arr: {
                  without_refunds: 87.63,
                  with_refunds: 79.87,
                },
                gross_arr: {
                  without_refunds: 94.22,
                  with_refunds: 86.03,
                },
                net_arr_spread: {
                  without_refunds: 7.22,
                  with_refunds: 7.22,
                },
                gross_arr_spread: {
                  without_refunds: 7.79,
                  with_refunds: 7.79,
                },
                net_total: {
                  without_refunds: 50.27,
                  with_refunds: 46.07,
                },
                gross_total: {
                  without_refunds: 54.63,
                  with_refunds: 50.16,
                },
                net_total_spread: {
                  without_refunds: 10.67,
                  with_refunds: 10.28,
                },
                gross_total_spread: {
                  without_refunds: 12.08,
                  with_refunds: 11.65,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 9.52,
                  with_refunds: 9.09,
                },
                gross_mrr: {
                  without_refunds: 10.89,
                  with_refunds: 10.41,
                },
                net_arr: {
                  without_refunds: 64.83,
                  with_refunds: 54.74,
                },
                gross_arr: {
                  without_refunds: 72.16,
                  with_refunds: 60.99,
                },
                net_arr_spread: {
                  without_refunds: 5.34,
                  with_refunds: 5.34,
                },
                gross_arr_spread: {
                  without_refunds: 5.97,
                  with_refunds: 5.97,
                },
                net_total: {
                  without_refunds: 47.63,
                  with_refunds: 40.55,
                },
                gross_total: {
                  without_refunds: 53.1,
                  with_refunds: 45.26,
                },
                net_total_spread: {
                  without_refunds: 6.92,
                  with_refunds: 6.76,
                },
                gross_total_spread: {
                  without_refunds: 7.83,
                  with_refunds: 7.65,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 11.47,
                  with_refunds: 11.12,
                },
                gross_mrr: {
                  without_refunds: 14.14,
                  with_refunds: 13.71,
                },
                net_arr: {
                  without_refunds: 105.14,
                  with_refunds: 92.31,
                },
                gross_arr: {
                  without_refunds: 119.14,
                  with_refunds: 104.79,
                },
                net_arr_spread: {
                  without_refunds: 8.68,
                  with_refunds: 8.68,
                },
                gross_arr_spread: {
                  without_refunds: 9.87,
                  with_refunds: 9.87,
                },
                net_total: {
                  without_refunds: 43.6,
                  with_refunds: 38.94,
                },
                gross_total: {
                  without_refunds: 50.15,
                  with_refunds: 44.92,
                },
                net_total_spread: {
                  without_refunds: 10.65,
                  with_refunds: 10.41,
                },
                gross_total_spread: {
                  without_refunds: 12.87,
                  with_refunds: 12.57,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 19.17,
                  with_refunds: 18.21,
                },
                gross_mrr: {
                  without_refunds: 22.23,
                  with_refunds: 21.13,
                },
                net_arr: {
                  without_refunds: 134.96,
                  with_refunds: 122.73,
                },
                gross_arr: {
                  without_refunds: 145.26,
                  with_refunds: 132.32,
                },
                net_arr_spread: {
                  without_refunds: 11.22,
                  with_refunds: 11.22,
                },
                gross_arr_spread: {
                  without_refunds: 12.11,
                  with_refunds: 12.11,
                },
                net_total: {
                  without_refunds: 74.31,
                  with_refunds: 68.02,
                },
                gross_total: {
                  without_refunds: 80.82,
                  with_refunds: 74.12,
                },
                net_total_spread: {
                  without_refunds: 15.67,
                  with_refunds: 15.14,
                },
                gross_total_spread: {
                  without_refunds: 17.78,
                  with_refunds: 17.17,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 14.12,
                  with_refunds: 13.51,
                },
                gross_mrr: {
                  without_refunds: 16.18,
                  with_refunds: 15.49,
                },
                net_arr: {
                  without_refunds: 121.11,
                  with_refunds: 102.4,
                },
                gross_arr: {
                  without_refunds: 135.05,
                  with_refunds: 114.29,
                },
                net_arr_spread: {
                  without_refunds: 10.06,
                  with_refunds: 10.06,
                },
                gross_arr_spread: {
                  without_refunds: 11.28,
                  with_refunds: 11.28,
                },
                net_total: {
                  without_refunds: 82.06,
                  with_refunds: 69.87,
                },
                gross_total: {
                  without_refunds: 91.59,
                  with_refunds: 78.08,
                },
                net_total_spread: {
                  without_refunds: 11.76,
                  with_refunds: 11.5,
                },
                gross_total_spread: {
                  without_refunds: 13.32,
                  with_refunds: 13.03,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.1249,
                  with_refunds: 0.121,
                },
                gross_mrr: {
                  without_refunds: 0.1535,
                  with_refunds: 0.1487,
                },
                net_arr: {
                  without_refunds: 0.9526,
                  with_refunds: 0.8466,
                },
                gross_arr: {
                  without_refunds: 1.0746,
                  with_refunds: 0.9564,
                },
                net_arr_spread: {
                  without_refunds: 0.0814,
                  with_refunds: 0.0814,
                },
                gross_arr_spread: {
                  without_refunds: 0.0921,
                  with_refunds: 0.0921,
                },
                net_total: {
                  without_refunds: 0.4357,
                  with_refunds: 0.3936,
                },
                gross_total: {
                  without_refunds: 0.4995,
                  with_refunds: 0.4522,
                },
                net_total_spread: {
                  without_refunds: 0.1116,
                  with_refunds: 0.1089,
                },
                gross_total_spread: {
                  without_refunds: 0.1346,
                  with_refunds: 0.1313,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6789,
                  with_refunds: 0.6545,
                },
                gross_mrr: {
                  without_refunds: 0.7956,
                  with_refunds: 0.7654,
                },
                net_arr: {
                  without_refunds: 4.7117,
                  with_refunds: 4.2528,
                },
                gross_arr: {
                  without_refunds: 5.0866,
                  with_refunds: 4.5975,
                },
                net_arr_spread: {
                  without_refunds: 0.3838,
                  with_refunds: 0.3838,
                },
                gross_arr_spread: {
                  without_refunds: 0.4157,
                  with_refunds: 0.4157,
                },
                net_total: {
                  without_refunds: 2.6758,
                  with_refunds: 2.4364,
                },
                gross_total: {
                  without_refunds: 2.9206,
                  with_refunds: 2.6633,
                },
                net_total_spread: {
                  without_refunds: 0.5425,
                  with_refunds: 0.5295,
                },
                gross_total_spread: {
                  without_refunds: 0.62,
                  with_refunds: 0.6038,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.25,
                  with_refunds: 0.2409,
                },
                gross_mrr: {
                  without_refunds: 0.2905,
                  with_refunds: 0.2796,
                },
                net_arr: {
                  without_refunds: 1.1352,
                  with_refunds: 0.9894,
                },
                gross_arr: {
                  without_refunds: 1.2505,
                  with_refunds: 1.0904,
                },
                net_arr_spread: {
                  without_refunds: 0.0986,
                  with_refunds: 0.0986,
                },
                gross_arr_spread: {
                  without_refunds: 0.109,
                  with_refunds: 0.109,
                },
                net_total: {
                  without_refunds: 0.8603,
                  with_refunds: 0.7571,
                },
                gross_total: {
                  without_refunds: 0.9525,
                  with_refunds: 0.8388,
                },
                net_total_spread: {
                  without_refunds: 0.1555,
                  with_refunds: 0.1521,
                },
                gross_total_spread: {
                  without_refunds: 0.1772,
                  with_refunds: 0.1731,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.2045,
                  with_refunds: 0.1982,
                },
                gross_mrr: {
                  without_refunds: 0.2507,
                  with_refunds: 0.2428,
                },
                net_arr: {
                  without_refunds: 1.8191,
                  with_refunds: 1.6201,
                },
                gross_arr: {
                  without_refunds: 2.0472,
                  with_refunds: 1.8254,
                },
                net_arr_spread: {
                  without_refunds: 0.1536,
                  with_refunds: 0.1536,
                },
                gross_arr_spread: {
                  without_refunds: 0.1734,
                  with_refunds: 0.1734,
                },
                net_total: {
                  without_refunds: 0.7634,
                  with_refunds: 0.6903,
                },
                gross_total: {
                  without_refunds: 0.8729,
                  with_refunds: 0.7908,
                },
                net_total_spread: {
                  without_refunds: 0.1899,
                  with_refunds: 0.1854,
                },
                gross_total_spread: {
                  without_refunds: 0.228,
                  with_refunds: 0.2225,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.0792,
                  with_refunds: 1.0404,
                },
                gross_mrr: {
                  without_refunds: 1.2647,
                  with_refunds: 1.2167,
                },
                net_arr: {
                  without_refunds: 8.7438,
                  with_refunds: 7.8922,
                },
                gross_arr: {
                  without_refunds: 9.4396,
                  with_refunds: 8.5318,
                },
                net_arr_spread: {
                  without_refunds: 0.7171,
                  with_refunds: 0.7171,
                },
                gross_arr_spread: {
                  without_refunds: 0.7765,
                  with_refunds: 0.7765,
                },
                net_total: {
                  without_refunds: 4.6026,
                  with_refunds: 4.1909,
                },
                gross_total: {
                  without_refunds: 5.0237,
                  with_refunds: 4.5811,
                },
                net_total_spread: {
                  without_refunds: 0.9309,
                  with_refunds: 0.9084,
                },
                gross_total_spread: {
                  without_refunds: 1.0637,
                  with_refunds: 1.0359,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.3948,
                  with_refunds: 0.3806,
                },
                gross_mrr: {
                  without_refunds: 0.4591,
                  with_refunds: 0.442,
                },
                net_arr: {
                  without_refunds: 2.1758,
                  with_refunds: 1.8998,
                },
                gross_arr: {
                  without_refunds: 2.3996,
                  with_refunds: 2.0959,
                },
                net_arr_spread: {
                  without_refunds: 0.1896,
                  with_refunds: 0.1896,
                },
                gross_arr_spread: {
                  without_refunds: 0.2098,
                  with_refunds: 0.2098,
                },
                net_total: {
                  without_refunds: 1.5136,
                  with_refunds: 1.333,
                },
                gross_total: {
                  without_refunds: 1.6767,
                  with_refunds: 1.4777,
                },
                net_total_spread: {
                  without_refunds: 0.2719,
                  with_refunds: 0.2659,
                },
                gross_total_spread: {
                  without_refunds: 0.3098,
                  with_refunds: 0.3027,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 1,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.7385,
                with_refunds: 0.7426,
              },
              arr_users: {
                without_refunds: 0.4406,
                with_refunds: 0.4673,
              },
              arr_spread_users: {
                without_refunds: 0.9643,
                with_refunds: 0.9643,
              },
              total_users: {
                without_refunds: 0.6333,
                with_refunds: 0.6458,
              },
              total_spread_users: {
                without_refunds: 0.8175,
                with_refunds: 0.8203,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1.2338,
                with_refunds: 1.2407,
              },
              arr_users: {
                without_refunds: 0.8897,
                with_refunds: 0.9425,
              },
              arr_spread_users: {
                without_refunds: 1.8638,
                with_refunds: 1.8638,
              },
              total_users: {
                without_refunds: 1.1084,
                with_refunds: 1.1305,
              },
              total_spread_users: {
                without_refunds: 1.4151,
                with_refunds: 1.4199,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.4851,
                with_refunds: 0.4867,
              },
              arr_users: {
                without_refunds: 0.3926,
                with_refunds: 0.4137,
              },
              arr_spread_users: {
                without_refunds: 0.9937,
                with_refunds: 0.9937,
              },
              total_users: {
                without_refunds: 0.4207,
                with_refunds: 0.4361,
              },
              total_spread_users: {
                without_refunds: 0.8242,
                with_refunds: 0.8248,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7499,
                with_refunds: 0.7519,
              },
              arr_users: {
                without_refunds: 0.6838,
                with_refunds: 0.7188,
              },
              arr_spread_users: {
                without_refunds: 1.6998,
                with_refunds: 1.6998,
              },
              total_users: {
                without_refunds: 0.7056,
                with_refunds: 0.7306,
              },
              total_spread_users: {
                without_refunds: 1.3729,
                with_refunds: 1.3735,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.7581,
                with_refunds: 0.7644,
              },
              arr_users: {
                without_refunds: 0.4824,
                with_refunds: 0.5152,
              },
              arr_spread_users: {
                without_refunds: 0.9792,
                with_refunds: 0.9792,
              },
              total_users: {
                without_refunds: 0.6002,
                with_refunds: 0.6219,
              },
              total_spread_users: {
                without_refunds: 0.8799,
                with_refunds: 0.8828,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1.6211,
                with_refunds: 1.6333,
              },
              arr_users: {
                without_refunds: 1.0236,
                with_refunds: 1.0909,
              },
              arr_spread_users: {
                without_refunds: 2.0294,
                with_refunds: 2.0294,
              },
              total_users: {
                without_refunds: 1.2702,
                with_refunds: 1.3112,
              },
              total_spread_users: {
                without_refunds: 1.802,
                with_refunds: 1.8082,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1.2806,
                with_refunds: 1.2722,
              },
              gross_mrr: {
                without_refunds: 1.2224,
                with_refunds: 1.2153,
              },
              net_arr: {
                without_refunds: 0.9463,
                with_refunds: 0.8185,
              },
              gross_arr: {
                without_refunds: 0.9242,
                with_refunds: 0.8013,
              },
              net_arr_spread: {
                without_refunds: 1.9203,
                with_refunds: 1.9203,
              },
              gross_arr_spread: {
                without_refunds: 1.8984,
                with_refunds: 1.8984,
              },
              net_total: {
                without_refunds: 1.0019,
                with_refunds: 0.9177,
              },
              gross_total: {
                without_refunds: 0.9602,
                with_refunds: 0.88,
              },
              net_total_spread: {
                without_refunds: 1.4294,
                with_refunds: 1.4261,
              },
              gross_total_spread: {
                without_refunds: 1.3792,
                with_refunds: 1.3768,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1.2806,
                with_refunds: 1.2722,
              },
              gross_mrr: {
                without_refunds: 1.2224,
                with_refunds: 1.2153,
              },
              net_arr: {
                without_refunds: 0.9463,
                with_refunds: 0.8185,
              },
              gross_arr: {
                without_refunds: 0.9242,
                with_refunds: 0.8013,
              },
              net_arr_spread: {
                without_refunds: 1.9203,
                with_refunds: 1.9203,
              },
              gross_arr_spread: {
                without_refunds: 1.8984,
                with_refunds: 1.8984,
              },
              net_total: {
                without_refunds: 1.0019,
                with_refunds: 0.9177,
              },
              gross_total: {
                without_refunds: 0.9602,
                with_refunds: 0.88,
              },
              net_total_spread: {
                without_refunds: 1.4294,
                with_refunds: 1.4261,
              },
              gross_total_spread: {
                without_refunds: 1.3792,
                with_refunds: 1.3768,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.7698,
                with_refunds: 0.7747,
              },
              gross_mrr: {
                without_refunds: 0.7455,
                with_refunds: 0.7497,
              },
              net_arr: {
                without_refunds: 0.7434,
                with_refunds: 0.6995,
              },
              gross_arr: {
                without_refunds: 0.7176,
                with_refunds: 0.6753,
              },
              net_arr_spread: {
                without_refunds: 1.7534,
                with_refunds: 1.7534,
              },
              gross_arr_spread: {
                without_refunds: 1.7269,
                with_refunds: 1.7269,
              },
              net_total: {
                without_refunds: 0.7109,
                with_refunds: 0.6757,
              },
              gross_total: {
                without_refunds: 0.688,
                with_refunds: 0.6542,
              },
              net_total_spread: {
                without_refunds: 1.3495,
                with_refunds: 1.3571,
              },
              gross_total_spread: {
                without_refunds: 1.3146,
                with_refunds: 1.3218,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7698,
                with_refunds: 0.7747,
              },
              gross_mrr: {
                without_refunds: 0.7455,
                with_refunds: 0.7497,
              },
              net_arr: {
                without_refunds: 0.7434,
                with_refunds: 0.6995,
              },
              gross_arr: {
                without_refunds: 0.7176,
                with_refunds: 0.6753,
              },
              net_arr_spread: {
                without_refunds: 1.7534,
                with_refunds: 1.7534,
              },
              gross_arr_spread: {
                without_refunds: 1.7269,
                with_refunds: 1.7269,
              },
              net_total: {
                without_refunds: 0.7109,
                with_refunds: 0.6757,
              },
              gross_total: {
                without_refunds: 0.688,
                with_refunds: 0.6542,
              },
              net_total_spread: {
                without_refunds: 1.3495,
                with_refunds: 1.3571,
              },
              gross_total_spread: {
                without_refunds: 1.3146,
                with_refunds: 1.3218,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1.6399,
                with_refunds: 1.62,
              },
              gross_mrr: {
                without_refunds: 1.6329,
                with_refunds: 1.6159,
              },
              net_arr: {
                without_refunds: 1.1059,
                with_refunds: 0.965,
              },
              gross_arr: {
                without_refunds: 1.086,
                with_refunds: 0.9504,
              },
              net_arr_spread: {
                without_refunds: 2.1099,
                with_refunds: 2.1099,
              },
              gross_arr_spread: {
                without_refunds: 2.0889,
                with_refunds: 2.0889,
              },
              net_total: {
                without_refunds: 1.2109,
                with_refunds: 1.0812,
              },
              gross_total: {
                without_refunds: 1.194,
                with_refunds: 1.0697,
              },
              net_total_spread: {
                without_refunds: 1.8036,
                with_refunds: 1.7985,
              },
              gross_total_spread: {
                without_refunds: 1.7899,
                with_refunds: 1.7861,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1.6399,
                with_refunds: 1.62,
              },
              gross_mrr: {
                without_refunds: 1.6329,
                with_refunds: 1.6159,
              },
              net_arr: {
                without_refunds: 1.1059,
                with_refunds: 0.965,
              },
              gross_arr: {
                without_refunds: 1.086,
                with_refunds: 0.9504,
              },
              net_arr_spread: {
                without_refunds: 2.1099,
                with_refunds: 2.1099,
              },
              gross_arr_spread: {
                without_refunds: 2.0889,
                with_refunds: 2.0889,
              },
              net_total: {
                without_refunds: 1.2109,
                with_refunds: 1.0812,
              },
              gross_total: {
                without_refunds: 1.194,
                with_refunds: 1.0697,
              },
              net_total_spread: {
                without_refunds: 1.8036,
                with_refunds: 1.7985,
              },
              gross_total_spread: {
                without_refunds: 1.7899,
                with_refunds: 1.7861,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 239.7493,
              with_refunds: 222.2472,
            },
            gross_mrr: {
              without_refunds: 265.4838,
              with_refunds: 240.7056,
            },
            net_arr: {
              without_refunds: 1302.11,
              with_refunds: 1181.04,
            },
            gross_arr: {
              without_refunds: 1374.98,
              with_refunds: 1249.98,
            },
            net_arr_spread: {
              without_refunds: 98.42,
              with_refunds: 98.42,
            },
            gross_arr_spread: {
              without_refunds: 104.165,
              with_refunds: 104.165,
            },
            net_total: {
              without_refunds: 1541.8593,
              with_refunds: 1403.2872,
            },
            gross_total: {
              without_refunds: 1640.4638,
              with_refunds: 1490.6856,
            },
            net_total_spread: {
              without_refunds: 338.1693,
              with_refunds: 320.6672,
            },
            gross_total_spread: {
              without_refunds: 369.6488,
              with_refunds: 344.8706,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 8.74,
                with_refunds: 8.42,
              },
              gross_mrr: {
                without_refunds: 10.28,
                with_refunds: 9.91,
              },
              net_arr: {
                without_refunds: 49.84,
                with_refunds: 37.83,
              },
              gross_arr: {
                without_refunds: 54.85,
                with_refunds: 41.78,
              },
              net_arr_spread: {
                without_refunds: 8.67,
                with_refunds: 8.67,
              },
              gross_arr_spread: {
                without_refunds: 9.74,
                with_refunds: 9.74,
              },
              net_total: {
                without_refunds: 24.19,
                with_refunds: 19.5,
              },
              gross_total: {
                without_refunds: 27.04,
                with_refunds: 21.92,
              },
              net_total_spread: {
                without_refunds: 8.78,
                with_refunds: 8.56,
              },
              gross_total_spread: {
                without_refunds: 10.18,
                with_refunds: 9.93,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 9.13,
                with_refunds: 8.84,
              },
              gross_mrr: {
                without_refunds: 10.38,
                with_refunds: 10.04,
              },
              net_arr: {
                without_refunds: 57.19,
                with_refunds: 46.02,
              },
              gross_arr: {
                without_refunds: 60.47,
                with_refunds: 48.67,
              },
              net_arr_spread: {
                without_refunds: 11.4,
                with_refunds: 11.4,
              },
              gross_arr_spread: {
                without_refunds: 12.3,
                with_refunds: 12.3,
              },
              net_total: {
                without_refunds: 43.07,
                with_refunds: 35.1,
              },
              gross_total: {
                without_refunds: 45.76,
                with_refunds: 37.33,
              },
              net_total_spread: {
                without_refunds: 10.64,
                with_refunds: 10.55,
              },
              gross_total_spread: {
                without_refunds: 11.67,
                with_refunds: 11.55,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 10.2,
                with_refunds: 9.72,
              },
              gross_mrr: {
                without_refunds: 11.64,
                with_refunds: 11.11,
              },
              net_arr: {
                without_refunds: 60.73,
                with_refunds: 45.98,
              },
              gross_arr: {
                without_refunds: 66.57,
                with_refunds: 50.52,
              },
              net_arr_spread: {
                without_refunds: 9.93,
                with_refunds: 9.93,
              },
              gross_arr_spread: {
                without_refunds: 11,
                with_refunds: 11,
              },
              net_total: {
                without_refunds: 40.06,
                with_refunds: 31.16,
              },
              gross_total: {
                without_refunds: 44.11,
                with_refunds: 34.42,
              },
              net_total_spread: {
                without_refunds: 10.11,
                with_refunds: 9.88,
              },
              gross_total_spread: {
                without_refunds: 11.37,
                with_refunds: 11.11,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 16.32,
                with_refunds: 15.49,
              },
              gross_mrr: {
                without_refunds: 18.9,
                with_refunds: 17.94,
              },
              net_arr: {
                without_refunds: 91.3,
                with_refunds: 84.91,
              },
              gross_arr: {
                without_refunds: 98.17,
                with_refunds: 91.46,
              },
              net_arr_spread: {
                without_refunds: 11.83,
                with_refunds: 11.83,
              },
              gross_arr_spread: {
                without_refunds: 12.76,
                with_refunds: 12.76,
              },
              net_total: {
                without_refunds: 55.44,
                with_refunds: 51.52,
              },
              gross_total: {
                without_refunds: 60.25,
                with_refunds: 56.09,
              },
              net_total_spread: {
                without_refunds: 16.06,
                with_refunds: 15.49,
              },
              gross_total_spread: {
                without_refunds: 18.19,
                with_refunds: 17.54,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 13.61,
                with_refunds: 13.05,
              },
              gross_mrr: {
                without_refunds: 15.57,
                with_refunds: 14.95,
              },
              net_arr: {
                without_refunds: 65.63,
                with_refunds: 57.22,
              },
              gross_arr: {
                without_refunds: 73.05,
                with_refunds: 63.74,
              },
              net_arr_spread: {
                without_refunds: 8.04,
                with_refunds: 8.04,
              },
              gross_arr_spread: {
                without_refunds: 8.99,
                with_refunds: 8.99,
              },
              net_total: {
                without_refunds: 56.04,
                with_refunds: 48.59,
              },
              gross_total: {
                without_refunds: 62.48,
                with_refunds: 54.23,
              },
              net_total_spread: {
                without_refunds: 10.11,
                with_refunds: 9.9,
              },
              gross_total_spread: {
                without_refunds: 11.45,
                with_refunds: 11.2,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.89,
                with_refunds: 11.39,
              },
              gross_mrr: {
                without_refunds: 13.97,
                with_refunds: 13.39,
              },
              net_arr: {
                without_refunds: 112.17,
                with_refunds: 80.09,
              },
              gross_arr: {
                without_refunds: 124.16,
                with_refunds: 89.08,
              },
              net_arr_spread: {
                without_refunds: 8.95,
                with_refunds: 8.95,
              },
              gross_arr_spread: {
                without_refunds: 10.07,
                with_refunds: 10.07,
              },
              net_total: {
                without_refunds: 41.4,
                with_refunds: 32.01,
              },
              gross_total: {
                without_refunds: 46.12,
                with_refunds: 35.87,
              },
              net_total_spread: {
                without_refunds: 10.73,
                with_refunds: 10.43,
              },
              gross_total_spread: {
                without_refunds: 12.45,
                with_refunds: 12.1,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 18.65,
                with_refunds: 18.01,
              },
              gross_mrr: {
                without_refunds: 21.06,
                with_refunds: 20.31,
              },
              net_arr: {
                without_refunds: 145.28,
                with_refunds: 110.07,
              },
              gross_arr: {
                without_refunds: 153.56,
                with_refunds: 116.36,
              },
              net_arr_spread: {
                without_refunds: 11.47,
                with_refunds: 11.47,
              },
              gross_arr_spread: {
                without_refunds: 12.38,
                with_refunds: 12.38,
              },
              net_total: {
                without_refunds: 100.26,
                with_refunds: 78.16,
              },
              gross_total: {
                without_refunds: 106.53,
                with_refunds: 83.11,
              },
              net_total_spread: {
                without_refunds: 13.03,
                with_refunds: 12.9,
              },
              gross_total_spread: {
                without_refunds: 14.27,
                with_refunds: 14.11,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 13.68,
                with_refunds: 12.95,
              },
              gross_mrr: {
                without_refunds: 15.6,
                with_refunds: 14.77,
              },
              net_arr: {
                without_refunds: 128.02,
                with_refunds: 90.71,
              },
              gross_arr: {
                without_refunds: 140.04,
                with_refunds: 99.44,
              },
              net_arr_spread: {
                without_refunds: 10.12,
                with_refunds: 10.12,
              },
              gross_arr_spread: {
                without_refunds: 11.22,
                with_refunds: 11.22,
              },
              net_total: {
                without_refunds: 72.36,
                with_refunds: 53.59,
              },
              gross_total: {
                without_refunds: 79.43,
                with_refunds: 59,
              },
              net_total_spread: {
                without_refunds: 11.53,
                with_refunds: 11.24,
              },
              gross_total_spread: {
                without_refunds: 12.96,
                with_refunds: 12.63,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 16.32,
                with_refunds: 15.49,
              },
              gross_mrr: {
                without_refunds: 18.9,
                with_refunds: 17.94,
              },
              net_arr: {
                without_refunds: 91.3,
                with_refunds: 84.91,
              },
              gross_arr: {
                without_refunds: 98.17,
                with_refunds: 91.46,
              },
              net_arr_spread: {
                without_refunds: 11.83,
                with_refunds: 11.83,
              },
              gross_arr_spread: {
                without_refunds: 12.76,
                with_refunds: 12.76,
              },
              net_total: {
                without_refunds: 55.44,
                with_refunds: 51.52,
              },
              gross_total: {
                without_refunds: 60.25,
                with_refunds: 56.09,
              },
              net_total_spread: {
                without_refunds: 16.06,
                with_refunds: 15.49,
              },
              gross_total_spread: {
                without_refunds: 18.19,
                with_refunds: 17.54,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 13.61,
                with_refunds: 13.05,
              },
              gross_mrr: {
                without_refunds: 15.57,
                with_refunds: 14.95,
              },
              net_arr: {
                without_refunds: 65.63,
                with_refunds: 57.22,
              },
              gross_arr: {
                without_refunds: 73.05,
                with_refunds: 63.74,
              },
              net_arr_spread: {
                without_refunds: 8.04,
                with_refunds: 8.04,
              },
              gross_arr_spread: {
                without_refunds: 8.99,
                with_refunds: 8.99,
              },
              net_total: {
                without_refunds: 56.04,
                with_refunds: 48.59,
              },
              gross_total: {
                without_refunds: 62.48,
                with_refunds: 54.23,
              },
              net_total_spread: {
                without_refunds: 10.11,
                with_refunds: 9.9,
              },
              gross_total_spread: {
                without_refunds: 11.45,
                with_refunds: 11.2,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 15.93,
                  with_refunds: 15.39,
                },
                gross_mrr: {
                  without_refunds: 19.16,
                  with_refunds: 18.52,
                },
                net_arr: {
                  without_refunds: 105.3,
                  with_refunds: 86.4,
                },
                gross_arr: {
                  without_refunds: 117.82,
                  with_refunds: 97.04,
                },
                net_arr_spread: {
                  without_refunds: 13.29,
                  with_refunds: 13.29,
                },
                gross_arr_spread: {
                  without_refunds: 15.02,
                  with_refunds: 15.02,
                },
                net_total: {
                  without_refunds: 49.5,
                  with_refunds: 42.09,
                },
                gross_total: {
                  without_refunds: 56.24,
                  with_refunds: 48.06,
                },
                net_total_spread: {
                  without_refunds: 15.18,
                  with_refunds: 14.81,
                },
                gross_total_spread: {
                  without_refunds: 17.94,
                  with_refunds: 17.51,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 22.18,
                  with_refunds: 21.23,
                },
                gross_mrr: {
                  without_refunds: 25.56,
                  with_refunds: 24.47,
                },
                net_arr: {
                  without_refunds: 138.09,
                  with_refunds: 115.89,
                },
                gross_arr: {
                  without_refunds: 148.83,
                  with_refunds: 125,
                },
                net_arr_spread: {
                  without_refunds: 18.05,
                  with_refunds: 18.05,
                },
                gross_arr_spread: {
                  without_refunds: 19.57,
                  with_refunds: 19.57,
                },
                net_total: {
                  without_refunds: 104.04,
                  with_refunds: 88.09,
                },
                gross_total: {
                  without_refunds: 112.62,
                  with_refunds: 95.48,
                },
                net_total_spread: {
                  without_refunds: 19.44,
                  with_refunds: 19.12,
                },
                gross_total_spread: {
                  without_refunds: 21.58,
                  with_refunds: 21.22,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 17.43,
                  with_refunds: 16.67,
                },
                gross_mrr: {
                  without_refunds: 19.94,
                  with_refunds: 19.08,
                },
                net_arr: {
                  without_refunds: 120.14,
                  with_refunds: 96.82,
                },
                gross_arr: {
                  without_refunds: 132.75,
                  with_refunds: 107.2,
                },
                net_arr_spread: {
                  without_refunds: 14.8,
                  with_refunds: 14.8,
                },
                gross_arr_spread: {
                  without_refunds: 16.45,
                  with_refunds: 16.45,
                },
                net_total: {
                  without_refunds: 78.11,
                  with_refunds: 64.04,
                },
                gross_total: {
                  without_refunds: 86.59,
                  with_refunds: 71.16,
                },
                net_total_spread: {
                  without_refunds: 16.13,
                  with_refunds: 15.77,
                },
                gross_total_spread: {
                  without_refunds: 18.19,
                  with_refunds: 17.79,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 23.36,
                  with_refunds: 22.51,
                },
                gross_mrr: {
                  without_refunds: 28.1,
                  with_refunds: 27.1,
                },
                net_arr: {
                  without_refunds: 217.3,
                  with_refunds: 172.39,
                },
                gross_arr: {
                  without_refunds: 243.3,
                  with_refunds: 193.86,
                },
                net_arr_spread: {
                  without_refunds: 17.62,
                  with_refunds: 17.62,
                },
                gross_arr_spread: {
                  without_refunds: 19.95,
                  with_refunds: 19.95,
                },
                net_total: {
                  without_refunds: 84.98,
                  with_refunds: 70.94,
                },
                gross_total: {
                  without_refunds: 96.26,
                  with_refunds: 80.78,
                },
                net_total_spread: {
                  without_refunds: 21.38,
                  with_refunds: 20.83,
                },
                gross_total_spread: {
                  without_refunds: 25.31,
                  with_refunds: 24.67,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 37.31,
                  with_refunds: 35.78,
                },
                gross_mrr: {
                  without_refunds: 42.82,
                  with_refunds: 41.04,
                },
                net_arr: {
                  without_refunds: 279.53,
                  with_refunds: 225.62,
                },
                gross_arr: {
                  without_refunds: 300.34,
                  with_refunds: 242.7,
                },
                net_arr_spread: {
                  without_refunds: 22.6,
                  with_refunds: 22.6,
                },
                gross_arr_spread: {
                  without_refunds: 24.57,
                  with_refunds: 24.57,
                },
                net_total: {
                  without_refunds: 199.32,
                  with_refunds: 163.9,
                },
                gross_total: {
                  without_refunds: 215.26,
                  with_refunds: 177.27,
                },
                net_total_spread: {
                  without_refunds: 26.79,
                  with_refunds: 26.35,
                },
                gross_total_spread: {
                  without_refunds: 29.79,
                  with_refunds: 29.28,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 27.22,
                  with_refunds: 26.01,
                },
                gross_mrr: {
                  without_refunds: 31.13,
                  with_refunds: 29.75,
                },
                net_arr: {
                  without_refunds: 245.22,
                  with_refunds: 191.55,
                },
                gross_arr: {
                  without_refunds: 270.8,
                  with_refunds: 212.02,
                },
                net_arr_spread: {
                  without_refunds: 19.84,
                  with_refunds: 19.84,
                },
                gross_arr_spread: {
                  without_refunds: 22.09,
                  with_refunds: 22.09,
                },
                net_total: {
                  without_refunds: 143.16,
                  with_refunds: 115.14,
                },
                gross_total: {
                  without_refunds: 158.57,
                  with_refunds: 127.87,
                },
                net_total_spread: {
                  without_refunds: 23.16,
                  with_refunds: 22.62,
                },
                gross_total_spread: {
                  without_refunds: 26.15,
                  with_refunds: 25.54,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.2808,
                  with_refunds: 0.271,
                },
                gross_mrr: {
                  without_refunds: 0.3365,
                  with_refunds: 0.3248,
                },
                net_arr: {
                  without_refunds: 1.821,
                  with_refunds: 1.5295,
                },
                gross_arr: {
                  without_refunds: 2.0244,
                  with_refunds: 1.7049,
                },
                net_arr_spread: {
                  without_refunds: 0.235,
                  with_refunds: 0.235,
                },
                gross_arr_spread: {
                  without_refunds: 0.2635,
                  with_refunds: 0.2635,
                },
                net_total: {
                  without_refunds: 0.8595,
                  with_refunds: 0.7443,
                },
                gross_total: {
                  without_refunds: 0.9709,
                  with_refunds: 0.844,
                },
                net_total_spread: {
                  without_refunds: 0.2679,
                  with_refunds: 0.2612,
                },
                gross_total_spread: {
                  without_refunds: 0.3151,
                  with_refunds: 0.3071,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.3593,
                  with_refunds: 1.3094,
                },
                gross_mrr: {
                  without_refunds: 1.5682,
                  with_refunds: 1.5078,
                },
                net_arr: {
                  without_refunds: 4.5686,
                  with_refunds: 3.9749,
                },
                gross_arr: {
                  without_refunds: 4.8847,
                  with_refunds: 4.2555,
                },
                net_arr_spread: {
                  without_refunds: 0.5954,
                  with_refunds: 0.5954,
                },
                gross_arr_spread: {
                  without_refunds: 0.6412,
                  with_refunds: 0.6412,
                },
                net_total: {
                  without_refunds: 3.6278,
                  with_refunds: 3.1941,
                },
                gross_total: {
                  without_refunds: 3.913,
                  with_refunds: 3.4511,
                },
                net_total_spread: {
                  without_refunds: 0.851,
                  with_refunds: 0.8343,
                },
                gross_total_spread: {
                  without_refunds: 0.9514,
                  with_refunds: 0.9312,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.4097,
                  with_refunds: 0.3935,
                },
                gross_mrr: {
                  without_refunds: 0.4705,
                  with_refunds: 0.4516,
                },
                net_arr: {
                  without_refunds: 2.1462,
                  with_refunds: 1.7953,
                },
                gross_arr: {
                  without_refunds: 2.3461,
                  with_refunds: 1.9646,
                },
                net_arr_spread: {
                  without_refunds: 0.2742,
                  with_refunds: 0.2742,
                },
                gross_arr_spread: {
                  without_refunds: 0.3013,
                  with_refunds: 0.3013,
                },
                net_total: {
                  without_refunds: 1.4363,
                  with_refunds: 1.2227,
                },
                gross_total: {
                  without_refunds: 1.5795,
                  with_refunds: 1.3467,
                },
                net_total_spread: {
                  without_refunds: 0.34,
                  with_refunds: 0.3323,
                },
                gross_total_spread: {
                  without_refunds: 0.3832,
                  with_refunds: 0.3743,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.4159,
                  with_refunds: 0.4006,
                },
                gross_mrr: {
                  without_refunds: 0.4986,
                  with_refunds: 0.4803,
                },
                net_arr: {
                  without_refunds: 3.7478,
                  with_refunds: 3.0581,
                },
                gross_arr: {
                  without_refunds: 4.1698,
                  with_refunds: 3.4124,
                },
                net_arr_spread: {
                  without_refunds: 0.3118,
                  with_refunds: 0.3118,
                },
                gross_arr_spread: {
                  without_refunds: 0.3502,
                  with_refunds: 0.3502,
                },
                net_total: {
                  without_refunds: 1.4713,
                  with_refunds: 1.2581,
                },
                gross_total: {
                  without_refunds: 1.6578,
                  with_refunds: 1.4233,
                },
                net_total_spread: {
                  without_refunds: 0.3799,
                  with_refunds: 0.3699,
                },
                gross_total_spread: {
                  without_refunds: 0.4477,
                  with_refunds: 0.4358,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 2.0968,
                  with_refunds: 2.0199,
                },
                gross_mrr: {
                  without_refunds: 2.4204,
                  with_refunds: 2.3272,
                },
                net_arr: {
                  without_refunds: 9.2214,
                  with_refunds: 7.8565,
                },
                gross_arr: {
                  without_refunds: 9.8431,
                  with_refunds: 8.3997,
                },
                net_arr_spread: {
                  without_refunds: 0.7718,
                  with_refunds: 0.7718,
                },
                gross_arr_spread: {
                  without_refunds: 0.833,
                  with_refunds: 0.833,
                },
                net_total: {
                  without_refunds: 6.5554,
                  with_refunds: 5.6902,
                },
                gross_total: {
                  without_refunds: 7.0659,
                  with_refunds: 6.1449,
                },
                net_total_spread: {
                  without_refunds: 1.1969,
                  with_refunds: 1.1729,
                },
                gross_total_spread: {
                  without_refunds: 1.3424,
                  with_refunds: 1.3132,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6341,
                  with_refunds: 0.6085,
                },
                gross_mrr: {
                  without_refunds: 0.7286,
                  with_refunds: 0.6987,
                },
                net_arr: {
                  without_refunds: 4.3705,
                  with_refunds: 3.5745,
                },
                gross_arr: {
                  without_refunds: 4.7757,
                  with_refunds: 3.9105,
                },
                net_arr_spread: {
                  without_refunds: 0.3689,
                  with_refunds: 0.3689,
                },
                gross_arr_spread: {
                  without_refunds: 0.4064,
                  with_refunds: 0.4064,
                },
                net_total: {
                  without_refunds: 2.5823,
                  with_refunds: 2.1721,
                },
                gross_total: {
                  without_refunds: 2.8393,
                  with_refunds: 2.3923,
                },
                net_total_spread: {
                  without_refunds: 0.4907,
                  with_refunds: 0.4792,
                },
                gross_total_spread: {
                  without_refunds: 0.5542,
                  with_refunds: 0.5408,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 2,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.5305,
                with_refunds: 0.5334,
              },
              arr_users: {
                without_refunds: 0.0137,
                with_refunds: 0.0258,
              },
              arr_spread_users: {
                without_refunds: 0.9747,
                with_refunds: 0.9747,
              },
              total_users: {
                without_refunds: 0.3425,
                with_refunds: 0.3488,
              },
              total_spread_users: {
                without_refunds: 0.6791,
                with_refunds: 0.681,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7112,
                with_refunds: 0.7111,
              },
              arr_users: {
                without_refunds: 0.0346,
                with_refunds: 0.0574,
              },
              arr_spread_users: {
                without_refunds: 1.0117,
                with_refunds: 1.0117,
              },
              total_users: {
                without_refunds: 0.5175,
                with_refunds: 0.5194,
              },
              total_spread_users: {
                without_refunds: 0.8238,
                with_refunds: 0.8232,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.3384,
                with_refunds: 0.3421,
              },
              arr_users: {
                without_refunds: 0.0077,
                with_refunds: 0.0178,
              },
              arr_spread_users: {
                without_refunds: 0.9961,
                with_refunds: 0.9961,
              },
              total_users: {
                without_refunds: 0.0727,
                with_refunds: 0.0816,
              },
              total_spread_users: {
                without_refunds: 0.8418,
                with_refunds: 0.8427,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.52,
                with_refunds: 0.5182,
              },
              arr_users: {
                without_refunds: 0.0175,
                with_refunds: 0.0379,
              },
              arr_spread_users: {
                without_refunds: 1.0067,
                with_refunds: 1.0067,
              },
              total_users: {
                without_refunds: 0.15,
                with_refunds: 0.1594,
              },
              total_spread_users: {
                without_refunds: 0.9172,
                with_refunds: 0.9166,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.5211,
                with_refunds: 0.5258,
              },
              arr_users: {
                without_refunds: 0.014,
                with_refunds: 0.0293,
              },
              arr_spread_users: {
                without_refunds: 0.9885,
                with_refunds: 0.9885,
              },
              total_users: {
                without_refunds: 0.2347,
                with_refunds: 0.2454,
              },
              total_spread_users: {
                without_refunds: 0.7629,
                with_refunds: 0.7652,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6836,
                with_refunds: 0.6846,
              },
              arr_users: {
                without_refunds: 0.0293,
                with_refunds: 0.0566,
              },
              arr_spread_users: {
                without_refunds: 1.0116,
                with_refunds: 1.0116,
              },
              total_users: {
                without_refunds: 0.3711,
                with_refunds: 0.3779,
              },
              total_spread_users: {
                without_refunds: 0.8706,
                with_refunds: 0.8706,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.9254,
                with_refunds: 0.9296,
              },
              gross_mrr: {
                without_refunds: 0.8662,
                with_refunds: 0.8709,
              },
              net_arr: {
                without_refunds: 0.0309,
                with_refunds: 0.0071,
              },
              gross_arr: {
                without_refunds: 0.0336,
                with_refunds: 0.009,
              },
              net_arr_spread: {
                without_refunds: 1.9569,
                with_refunds: 1.9569,
              },
              gross_arr_spread: {
                without_refunds: 1.937,
                with_refunds: 1.937,
              },
              net_total: {
                without_refunds: 0.2798,
                with_refunds: 0.2725,
              },
              gross_total: {
                without_refunds: 0.2598,
                with_refunds: 0.2518,
              },
              net_total_spread: {
                without_refunds: 1.1641,
                with_refunds: 1.1727,
              },
              gross_total_spread: {
                without_refunds: 1.1115,
                with_refunds: 1.1205,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7156,
                with_refunds: 0.7247,
              },
              gross_mrr: {
                without_refunds: 0.7032,
                with_refunds: 0.7121,
              },
              net_arr: {
                without_refunds: 0.0368,
                with_refunds: 0.0147,
              },
              gross_arr: {
                without_refunds: 0.039,
                with_refunds: 0.0161,
              },
              net_arr_spread: {
                without_refunds: 1.0126,
                with_refunds: 1.0126,
              },
              gross_arr_spread: {
                without_refunds: 1.0137,
                with_refunds: 1.0137,
              },
              net_total: {
                without_refunds: 0.2909,
                with_refunds: 0.3015,
              },
              gross_total: {
                without_refunds: 0.2889,
                with_refunds: 0.2991,
              },
              net_total_spread: {
                without_refunds: 0.8033,
                with_refunds: 0.8121,
              },
              gross_total_spread: {
                without_refunds: 0.7934,
                with_refunds: 0.8021,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.5053,
                with_refunds: 0.5085,
              },
              gross_mrr: {
                without_refunds: 0.5107,
                with_refunds: 0.5125,
              },
              net_arr: {
                without_refunds: 0.0142,
              },
              gross_arr: {
                without_refunds: 0.0148,
              },
              net_arr_spread: {
                without_refunds: 1.9587,
                with_refunds: 1.9587,
              },
              gross_arr_spread: {
                without_refunds: 1.9343,
                with_refunds: 1.9343,
              },
              net_total: {
                without_refunds: 0.0297,
                with_refunds: 0.0186,
              },
              gross_total: {
                without_refunds: 0.0306,
                with_refunds: 0.0188,
              },
              net_total_spread: {
                without_refunds: 1.4804,
                with_refunds: 1.4956,
              },
              gross_total_spread: {
                without_refunds: 1.4638,
                with_refunds: 1.4779,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.5443,
                with_refunds: 0.5357,
              },
              gross_mrr: {
                without_refunds: 0.5485,
                with_refunds: 0.5387,
              },
              net_arr: {
                without_refunds: 0.016,
              },
              gross_arr: {
                without_refunds: 0.017,
              },
              net_arr_spread: {
                without_refunds: 1.0059,
                with_refunds: 1.0059,
              },
              gross_arr_spread: {
                without_refunds: 1.0063,
                with_refunds: 1.0063,
              },
              net_total: {
                without_refunds: 0.033,
                with_refunds: 0.0218,
              },
              gross_total: {
                without_refunds: 0.0346,
                with_refunds: 0.0224,
              },
              net_total_spread: {
                without_refunds: 0.8972,
                with_refunds: 0.8977,
              },
              gross_total_spread: {
                without_refunds: 0.8949,
                with_refunds: 0.8952,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1.0603,
                with_refunds: 1.0587,
              },
              gross_mrr: {
                without_refunds: 1.0473,
                with_refunds: 1.0479,
              },
              net_arr: {
                without_refunds: 0.0328,
                with_refunds: 0.005,
              },
              gross_arr: {
                without_refunds: 0.0364,
                with_refunds: 0.007,
              },
              net_arr_spread: {
                without_refunds: 2.0607,
                with_refunds: 2.0607,
              },
              gross_arr_spread: {
                without_refunds: 2.045,
                with_refunds: 2.045,
              },
              net_total: {
                without_refunds: 0.1526,
                with_refunds: 0.1318,
              },
              gross_total: {
                without_refunds: 0.1572,
                with_refunds: 0.1354,
              },
              net_total_spread: {
                without_refunds: 1.4422,
                with_refunds: 1.4505,
              },
              gross_total_spread: {
                without_refunds: 1.4249,
                with_refunds: 1.4343,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6775,
                with_refunds: 0.691,
              },
              gross_mrr: {
                without_refunds: 0.6743,
                with_refunds: 0.6871,
              },
              net_arr: {
                without_refunds: 0.0302,
                with_refunds: 0.0071,
              },
              gross_arr: {
                without_refunds: 0.0339,
                with_refunds: 0.0091,
              },
              net_arr_spread: {
                without_refunds: 1.0121,
                with_refunds: 1.0121,
              },
              gross_arr_spread: {
                without_refunds: 1.0137,
                with_refunds: 1.0137,
              },
              net_total: {
                without_refunds: 0.1265,
                with_refunds: 0.132,
              },
              gross_total: {
                without_refunds: 0.1319,
                with_refunds: 0.1356,
              },
              net_total_spread: {
                without_refunds: 0.8403,
                with_refunds: 0.8509,
              },
              gross_total_spread: {
                without_refunds: 0.8372,
                with_refunds: 0.8474,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 479.4986,
              with_refunds: 444.4944,
            },
            gross_mrr: {
              without_refunds: 530.9676,
              with_refunds: 481.4113,
            },
            net_arr: {
              without_refunds: 2604.22,
              with_refunds: 2362.08,
            },
            gross_arr: {
              without_refunds: 2749.96,
              with_refunds: 2499.96,
            },
            net_arr_spread: {
              without_refunds: 196.84,
              with_refunds: 196.84,
            },
            gross_arr_spread: {
              without_refunds: 208.33,
              with_refunds: 208.33,
            },
            net_total: {
              without_refunds: 3083.7186,
              with_refunds: 2806.5744,
            },
            gross_total: {
              without_refunds: 3280.9276,
              with_refunds: 2981.3713,
            },
            net_total_spread: {
              without_refunds: 676.3386,
              with_refunds: 641.3344,
            },
            gross_total_spread: {
              without_refunds: 739.2976,
              with_refunds: 689.7413,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 6.23,
                with_refunds: 6.09,
              },
              gross_mrr: {
                without_refunds: 7.21,
                with_refunds: 7.05,
              },
              net_arr: {
                without_refunds: 1.52,
                with_refunds: 0.3,
              },
              gross_arr: {
                without_refunds: 1.88,
                with_refunds: 0.46,
              },
              net_arr_spread: {
                without_refunds: 8.72,
                with_refunds: 8.72,
              },
              gross_arr_spread: {
                without_refunds: 9.82,
                with_refunds: 9.82,
              },
              net_total: {
                without_refunds: 4.53,
                with_refunds: 3.98,
              },
              gross_total: {
                without_refunds: 5.29,
                with_refunds: 4.65,
              },
              net_total_spread: {
                without_refunds: 7.09,
                with_refunds: 6.99,
              },
              gross_total_spread: {
                without_refunds: 8.12,
                with_refunds: 8,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 4.54,
                with_refunds: 4.3,
              },
              gross_mrr: {
                without_refunds: 5.14,
                with_refunds: 4.85,
              },
              net_arr: {
                without_refunds: 0.99,
              },
              gross_arr: {
                without_refunds: 1.15,
              },
              net_arr_spread: {
                without_refunds: 11.08,
                with_refunds: 11.08,
              },
              gross_arr_spread: {
                without_refunds: 12.2,
                with_refunds: 12.2,
              },
              net_total: {
                without_refunds: 1.69,
                with_refunds: 0.84,
              },
              gross_total: {
                without_refunds: 1.94,
                with_refunds: 0.95,
              },
              net_total_spread: {
                without_refunds: 9.55,
                with_refunds: 9.49,
              },
              gross_total_spread: {
                without_refunds: 10.55,
                with_refunds: 10.48,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 6.73,
                with_refunds: 6.5,
              },
              gross_mrr: {
                without_refunds: 7.64,
                with_refunds: 7.4,
              },
              net_arr: {
                without_refunds: 1.66,
                with_refunds: 0.21,
              },
              gross_arr: {
                without_refunds: 2.05,
                with_refunds: 0.32,
              },
              net_arr_spread: {
                without_refunds: 9.79,
                with_refunds: 9.79,
              },
              gross_arr_spread: {
                without_refunds: 10.89,
                with_refunds: 10.89,
              },
              net_total: {
                without_refunds: 3.88,
                with_refunds: 2.95,
              },
              gross_total: {
                without_refunds: 4.5,
                with_refunds: 3.4,
              },
              net_total_spread: {
                without_refunds: 8.33,
                with_refunds: 8.22,
              },
              gross_total_spread: {
                without_refunds: 9.35,
                with_refunds: 9.23,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.93,
                with_refunds: 19.9,
              },
              gross_mrr: {
                without_refunds: 24.23,
                with_refunds: 23.06,
              },
              net_arr: {
                without_refunds: 91.98,
                with_refunds: 86.32,
              },
              gross_arr: {
                without_refunds: 98.89,
                with_refunds: 92.98,
              },
              net_arr_spread: {
                without_refunds: 19.02,
                with_refunds: 19.02,
              },
              gross_arr_spread: {
                without_refunds: 20.52,
                with_refunds: 20.52,
              },
              net_total: {
                without_refunds: 59.1,
                with_refunds: 55.28,
              },
              gross_total: {
                without_refunds: 64.22,
                with_refunds: 60.19,
              },
              net_total_spread: {
                without_refunds: 25.03,
                with_refunds: 24.15,
              },
              gross_total_spread: {
                without_refunds: 28.36,
                with_refunds: 27.35,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 18.57,
                with_refunds: 17.83,
              },
              gross_mrr: {
                without_refunds: 21.25,
                with_refunds: 20.42,
              },
              net_arr: {
                without_refunds: 66.54,
                with_refunds: 58.82,
              },
              gross_arr: {
                without_refunds: 74.06,
                with_refunds: 65.52,
              },
              net_arr_spread: {
                without_refunds: 13.32,
                with_refunds: 13.32,
              },
              gross_arr_spread: {
                without_refunds: 14.9,
                with_refunds: 14.9,
              },
              net_total: {
                without_refunds: 67.21,
                with_refunds: 58.54,
              },
              gross_total: {
                without_refunds: 74.94,
                with_refunds: 65.34,
              },
              net_total_spread: {
                without_refunds: 15.39,
                with_refunds: 15.07,
              },
              gross_total_spread: {
                without_refunds: 17.42,
                with_refunds: 17.06,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.85,
                with_refunds: 11.49,
              },
              gross_mrr: {
                without_refunds: 13.7,
                with_refunds: 13.29,
              },
              net_arr: {
                without_refunds: 112.97,
                with_refunds: 12.27,
              },
              gross_arr: {
                without_refunds: 138.2,
                with_refunds: 18.43,
              },
              net_arr_spread: {
                without_refunds: 8.91,
                with_refunds: 8.91,
              },
              gross_arr_spread: {
                without_refunds: 10.05,
                with_refunds: 10.05,
              },
              net_total: {
                without_refunds: 15.01,
                with_refunds: 11.35,
              },
              gross_total: {
                without_refunds: 17.42,
                with_refunds: 13.23,
              },
              net_total_spread: {
                without_refunds: 10.41,
                with_refunds: 10.23,
              },
              gross_total_spread: {
                without_refunds: 11.94,
                with_refunds: 11.74,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 15.22,
                with_refunds: 14.08,
              },
              gross_mrr: {
                without_refunds: 16.94,
                with_refunds: 15.64,
              },
              net_arr: {
                without_refunds: 128.44,
              },
              gross_arr: {
                without_refunds: 148.26,
              },
              net_arr_spread: {
                without_refunds: 11.12,
                with_refunds: 11.12,
              },
              gross_arr_spread: {
                without_refunds: 12.25,
                with_refunds: 12.25,
              },
              net_total: {
                without_refunds: 26.87,
                with_refunds: 10.3,
              },
              gross_total: {
                without_refunds: 30.32,
                with_refunds: 11.71,
              },
              net_total_spread: {
                without_refunds: 11.41,
                with_refunds: 11.32,
              },
              gross_total_spread: {
                without_refunds: 12.58,
                with_refunds: 12.47,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 13.19,
                with_refunds: 12.6,
              },
              gross_mrr: {
                without_refunds: 14.95,
                with_refunds: 14.29,
              },
              net_arr: {
                without_refunds: 121.08,
                with_refunds: 7.29,
              },
              gross_arr: {
                without_refunds: 146.3,
                with_refunds: 10.79,
              },
              net_arr_spread: {
                without_refunds: 9.89,
                with_refunds: 9.89,
              },
              gross_arr_spread: {
                without_refunds: 11.01,
                with_refunds: 11.01,
              },
              net_total: {
                without_refunds: 19.28,
                with_refunds: 11.56,
              },
              gross_total: {
                without_refunds: 22.13,
                with_refunds: 13.3,
              },
              net_total_spread: {
                without_refunds: 10.91,
                with_refunds: 10.73,
              },
              gross_total_spread: {
                without_refunds: 12.25,
                with_refunds: 12.05,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.93,
                with_refunds: 19.9,
              },
              gross_mrr: {
                without_refunds: 24.23,
                with_refunds: 23.06,
              },
              net_arr: {
                without_refunds: 91.98,
                with_refunds: 86.32,
              },
              gross_arr: {
                without_refunds: 98.89,
                with_refunds: 92.98,
              },
              net_arr_spread: {
                without_refunds: 19.02,
                with_refunds: 19.02,
              },
              gross_arr_spread: {
                without_refunds: 20.52,
                with_refunds: 20.52,
              },
              net_total: {
                without_refunds: 59.1,
                with_refunds: 55.28,
              },
              gross_total: {
                without_refunds: 64.22,
                with_refunds: 60.19,
              },
              net_total_spread: {
                without_refunds: 25.03,
                with_refunds: 24.15,
              },
              gross_total_spread: {
                without_refunds: 28.36,
                with_refunds: 27.35,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 18.57,
                with_refunds: 17.83,
              },
              gross_mrr: {
                without_refunds: 21.25,
                with_refunds: 20.42,
              },
              net_arr: {
                without_refunds: 66.54,
                with_refunds: 58.82,
              },
              gross_arr: {
                without_refunds: 74.06,
                with_refunds: 65.52,
              },
              net_arr_spread: {
                without_refunds: 13.32,
                with_refunds: 13.32,
              },
              gross_arr_spread: {
                without_refunds: 14.9,
                with_refunds: 14.9,
              },
              net_total: {
                without_refunds: 67.21,
                with_refunds: 58.54,
              },
              gross_total: {
                without_refunds: 74.94,
                with_refunds: 65.34,
              },
              net_total_spread: {
                without_refunds: 15.39,
                with_refunds: 15.07,
              },
              gross_total_spread: {
                without_refunds: 17.42,
                with_refunds: 17.06,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 22.12,
                  with_refunds: 21.44,
                },
                gross_mrr: {
                  without_refunds: 26.34,
                  with_refunds: 25.54,
                },
                net_arr: {
                  without_refunds: 106.31,
                  with_refunds: 86.09,
                },
                gross_arr: {
                  without_refunds: 119.25,
                  with_refunds: 96.91,
                },
                net_arr_spread: {
                  without_refunds: 21.87,
                  with_refunds: 21.87,
                },
                gross_arr_spread: {
                  without_refunds: 24.71,
                  with_refunds: 24.71,
                },
                net_total: {
                  without_refunds: 53.75,
                  with_refunds: 45.77,
                },
                gross_total: {
                  without_refunds: 61.26,
                  with_refunds: 52.42,
                },
                net_total_spread: {
                  without_refunds: 22.2,
                  with_refunds: 21.74,
                },
                gross_total_spread: {
                  without_refunds: 26,
                  with_refunds: 25.46,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 24.6,
                  with_refunds: 23.49,
                },
                gross_mrr: {
                  without_refunds: 27.94,
                  with_refunds: 26.65,
                },
                net_arr: {
                  without_refunds: 134.07,
                  with_refunds: 105.36,
                },
                gross_arr: {
                  without_refunds: 147.35,
                  with_refunds: 115.99,
                },
                net_arr_spread: {
                  without_refunds: 27.77,
                  with_refunds: 27.77,
                },
                gross_arr_spread: {
                  without_refunds: 30.66,
                  with_refunds: 30.66,
                },
                net_total: {
                  without_refunds: 113.08,
                  with_refunds: 89.68,
                },
                gross_total: {
                  without_refunds: 124.46,
                  with_refunds: 98.89,
                },
                net_total_spread: {
                  without_refunds: 27.07,
                  with_refunds: 26.8,
                },
                gross_total_spread: {
                  without_refunds: 30.07,
                  with_refunds: 29.77,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 24.21,
                  with_refunds: 23.2,
                },
                gross_mrr: {
                  without_refunds: 27.69,
                  with_refunds: 26.57,
                },
                net_arr: {
                  without_refunds: 118.6,
                  with_refunds: 93.46,
                },
                gross_arr: {
                  without_refunds: 131.6,
                  with_refunds: 103.94,
                },
                net_arr_spread: {
                  without_refunds: 24.37,
                  with_refunds: 24.37,
                },
                gross_arr_spread: {
                  without_refunds: 27.17,
                  with_refunds: 27.17,
                },
                net_total: {
                  without_refunds: 78.42,
                  with_refunds: 63.59,
                },
                gross_total: {
                  without_refunds: 87.38,
                  with_refunds: 71.06,
                },
                net_total_spread: {
                  without_refunds: 24.43,
                  with_refunds: 23.93,
                },
                gross_total_spread: {
                  without_refunds: 27.58,
                  with_refunds: 27.03,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 35.04,
                  with_refunds: 33.84,
                },
                gross_mrr: {
                  without_refunds: 41.63,
                  with_refunds: 40.23,
                },
                net_arr: {
                  without_refunds: 329.31,
                  with_refunds: 183.82,
                },
                gross_arr: {
                  without_refunds: 380.69,
                  with_refunds: 211.58,
                },
                net_arr_spread: {
                  without_refunds: 26.44,
                  with_refunds: 26.44,
                },
                gross_arr_spread: {
                  without_refunds: 29.92,
                  with_refunds: 29.92,
                },
                net_total: {
                  without_refunds: 99.35,
                  with_refunds: 81.69,
                },
                gross_total: {
                  without_refunds: 113.05,
                  with_refunds: 93.42,
                },
                net_total_spread: {
                  without_refunds: 31.68,
                  with_refunds: 30.96,
                },
                gross_total_spread: {
                  without_refunds: 37.16,
                  with_refunds: 36.31,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 45.03,
                  with_refunds: 42.52,
                },
                gross_mrr: {
                  without_refunds: 50.89,
                  with_refunds: 48.01,
                },
                net_arr: {
                  without_refunds: 398.53,
                  with_refunds: 206.2,
                },
                gross_arr: {
                  without_refunds: 444.55,
                  with_refunds: 226.72,
                },
                net_arr_spread: {
                  without_refunds: 32.79,
                  with_refunds: 32.79,
                },
                gross_arr_spread: {
                  without_refunds: 36.26,
                  with_refunds: 36.26,
                },
                net_total: {
                  without_refunds: 238.23,
                  with_refunds: 173.78,
                },
                gross_total: {
                  without_refunds: 262.5,
                  with_refunds: 191.74,
                },
                net_total_spread: {
                  without_refunds: 34.93,
                  with_refunds: 34.52,
                },
                gross_total_spread: {
                  without_refunds: 38.84,
                  with_refunds: 38.36,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 39.77,
                  with_refunds: 37.95,
                },
                gross_mrr: {
                  without_refunds: 45.44,
                  with_refunds: 43.4,
                },
                net_arr: {
                  without_refunds: 359.79,
                  with_refunds: 190.98,
                },
                gross_arr: {
                  without_refunds: 410.55,
                  with_refunds: 214.86,
                },
                net_arr_spread: {
                  without_refunds: 29.3,
                  with_refunds: 29.3,
                },
                gross_arr_spread: {
                  without_refunds: 32.69,
                  with_refunds: 32.69,
                },
                net_total: {
                  without_refunds: 153.85,
                  with_refunds: 118.96,
                },
                gross_total: {
                  without_refunds: 171.62,
                  with_refunds: 133.01,
                },
                net_total_spread: {
                  without_refunds: 33.72,
                  with_refunds: 32.97,
                },
                gross_total_spread: {
                  without_refunds: 38.11,
                  with_refunds: 37.26,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.3857,
                  with_refunds: 0.3732,
                },
                gross_mrr: {
                  without_refunds: 0.4576,
                  with_refunds: 0.4429,
                },
                net_arr: {
                  without_refunds: 1.8746,
                  with_refunds: 1.5594,
                },
                gross_arr: {
                  without_refunds: 2.0874,
                  with_refunds: 1.7402,
                },
                net_arr_spread: {
                  without_refunds: 0.3973,
                  with_refunds: 0.3973,
                },
                gross_arr_spread: {
                  without_refunds: 0.4447,
                  with_refunds: 0.4447,
                },
                net_total: {
                  without_refunds: 0.9451,
                  with_refunds: 0.8194,
                },
                gross_total: {
                  without_refunds: 1.0702,
                  with_refunds: 0.9312,
                },
                net_total_spread: {
                  without_refunds: 0.3923,
                  with_refunds: 0.3838,
                },
                gross_total_spread: {
                  without_refunds: 0.4568,
                  with_refunds: 0.4468,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.2817,
                  with_refunds: 1.2299,
                },
                gross_mrr: {
                  without_refunds: 1.4685,
                  with_refunds: 1.4068,
                },
                net_arr: {
                  without_refunds: 2.723,
                  with_refunds: 2.2942,
                },
                gross_arr: {
                  without_refunds: 2.9387,
                  with_refunds: 2.4773,
                },
                net_arr_spread: {
                  without_refunds: 0.6071,
                  with_refunds: 0.6071,
                },
                gross_arr_spread: {
                  without_refunds: 0.6578,
                  with_refunds: 0.6578,
                },
                net_total: {
                  without_refunds: 2.4505,
                  with_refunds: 2.0941,
                },
                gross_total: {
                  without_refunds: 2.6613,
                  with_refunds: 2.2766,
                },
                net_total_spread: {
                  without_refunds: 0.7682,
                  with_refunds: 0.756,
                },
                gross_total_spread: {
                  without_refunds: 0.8514,
                  with_refunds: 0.8367,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5188,
                  with_refunds: 0.4979,
                },
                gross_mrr: {
                  without_refunds: 0.594,
                  with_refunds: 0.5703,
                },
                net_arr: {
                  without_refunds: 2.1469,
                  with_refunds: 1.7608,
                },
                gross_arr: {
                  without_refunds: 2.3564,
                  with_refunds: 1.9345,
                },
                net_arr_spread: {
                  without_refunds: 0.4608,
                  with_refunds: 0.4608,
                },
                gross_arr_spread: {
                  without_refunds: 0.5074,
                  with_refunds: 0.5074,
                },
                net_total: {
                  without_refunds: 1.4546,
                  with_refunds: 1.2245,
                },
                gross_total: {
                  without_refunds: 1.6073,
                  with_refunds: 1.3554,
                },
                net_total_spread: {
                  without_refunds: 0.4921,
                  with_refunds: 0.4817,
                },
                gross_total_spread: {
                  without_refunds: 0.5532,
                  with_refunds: 0.5414,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6347,
                  with_refunds: 0.6108,
                },
                gross_mrr: {
                  without_refunds: 0.7496,
                  with_refunds: 0.7218,
                },
                net_arr: {
                  without_refunds: 5.726,
                  with_refunds: 3.3129,
                },
                gross_arr: {
                  without_refunds: 6.6031,
                  with_refunds: 3.7807,
                },
                net_arr_spread: {
                  without_refunds: 0.4784,
                  with_refunds: 0.4784,
                },
                gross_arr_spread: {
                  without_refunds: 0.5365,
                  with_refunds: 0.5365,
                },
                net_total: {
                  without_refunds: 1.7591,
                  with_refunds: 1.4804,
                },
                gross_total: {
                  without_refunds: 1.9893,
                  with_refunds: 1.6795,
                },
                net_total_spread: {
                  without_refunds: 0.5684,
                  with_refunds: 0.5551,
                },
                gross_total_spread: {
                  without_refunds: 0.6626,
                  with_refunds: 0.6469,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 2.6391,
                  with_refunds: 2.4939,
                },
                gross_mrr: {
                  without_refunds: 2.9844,
                  with_refunds: 2.8169,
                },
                net_arr: {
                  without_refunds: 7.8199,
                  with_refunds: 4.521,
                },
                gross_arr: {
                  without_refunds: 8.6741,
                  with_refunds: 4.8755,
                },
                net_arr_spread: {
                  without_refunds: 0.7166,
                  with_refunds: 0.7166,
                },
                gross_arr_spread: {
                  without_refunds: 0.7779,
                  with_refunds: 0.7779,
                },
                net_total: {
                  without_refunds: 5.0329,
                  with_refunds: 4.0427,
                },
                gross_total: {
                  without_refunds: 5.4811,
                  with_refunds: 4.3969,
                },
                net_total_spread: {
                  without_refunds: 1.1028,
                  with_refunds: 1.0837,
                },
                gross_total_spread: {
                  without_refunds: 1.2227,
                  with_refunds: 1.2001,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.915,
                  with_refunds: 0.8712,
                },
                gross_mrr: {
                  without_refunds: 1.0424,
                  with_refunds: 0.9928,
                },
                net_arr: {
                  without_refunds: 6.3859,
                  with_refunds: 3.5966,
                },
                gross_arr: {
                  without_refunds: 7.2523,
                  with_refunds: 3.9945,
                },
                net_arr_spread: {
                  without_refunds: 0.5529,
                  with_refunds: 0.5529,
                },
                gross_arr_spread: {
                  without_refunds: 0.6098,
                  with_refunds: 0.6098,
                },
                net_total: {
                  without_refunds: 2.8435,
                  with_refunds: 2.3013,
                },
                gross_total: {
                  without_refunds: 3.1473,
                  with_refunds: 2.5484,
                },
                net_total_spread: {
                  without_refunds: 0.7036,
                  with_refunds: 0.6878,
                },
                gross_total_spread: {
                  without_refunds: 0.7912,
                  with_refunds: 0.7733,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 3,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.4143,
                with_refunds: 0.416,
              },
              arr_users: {
                without_refunds: 0.006,
                with_refunds: 0.009,
              },
              arr_spread_users: {
                without_refunds: 0.979,
                with_refunds: 0.979,
              },
              total_users: {
                without_refunds: 0.2672,
                with_refunds: 0.2692,
              },
              total_spread_users: {
                without_refunds: 0.5991,
                with_refunds: 0.6001,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7712,
                with_refunds: 0.7701,
              },
              arr_users: {
                without_refunds: 0.4209,
                with_refunds: 0.3733,
              },
              arr_spread_users: {
                without_refunds: 1.0054,
                with_refunds: 1.0054,
              },
              total_users: {
                without_refunds: 0.7478,
                with_refunds: 0.7351,
              },
              total_spread_users: {
                without_refunds: 0.8739,
                with_refunds: 0.8731,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.401,
                with_refunds: 0.4021,
              },
              arr_users: {
                without_refunds: 0.0029,
                with_refunds: 0.005,
              },
              arr_spread_users: {
                without_refunds: 0.9948,
                with_refunds: 0.9948,
              },
              total_users: {
                without_refunds: 0.1149,
                with_refunds: 0.1167,
              },
              total_spread_users: {
                without_refunds: 0.7967,
                with_refunds: 0.7971,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7787,
                with_refunds: 0.7679,
              },
              arr_users: {
                without_refunds: 0.2325,
                with_refunds: 0.1635,
              },
              arr_spread_users: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              total_users: {
                without_refunds: 0.685,
                with_refunds: 0.6323,
              },
              total_spread_users: {
                without_refunds: 0.9529,
                with_refunds: 0.9498,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.4022,
                with_refunds: 0.4041,
              },
              arr_users: {
                without_refunds: 0.0055,
                with_refunds: 0.0085,
              },
              arr_spread_users: {
                without_refunds: 0.9888,
                with_refunds: 0.9888,
              },
              total_users: {
                without_refunds: 0.1945,
                with_refunds: 0.1966,
              },
              total_spread_users: {
                without_refunds: 0.6824,
                with_refunds: 0.6832,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7813,
                with_refunds: 0.7788,
              },
              arr_users: {
                without_refunds: 0.4105,
                with_refunds: 0.3323,
              },
              arr_spread_users: {
                without_refunds: 1.0046,
                with_refunds: 1.0046,
              },
              total_users: {
                without_refunds: 0.7418,
                with_refunds: 0.7176,
              },
              total_spread_users: {
                without_refunds: 0.9174,
                with_refunds: 0.9159,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.7258,
                with_refunds: 0.7313,
              },
              gross_mrr: {
                without_refunds: 0.6706,
                with_refunds: 0.6764,
              },
              net_arr: {
                without_refunds: 0.0137,
                with_refunds: 0.006,
              },
              gross_arr: {
                without_refunds: 0.0151,
                with_refunds: 0.0071,
              },
              net_arr_spread: {
                without_refunds: 1.9697,
                with_refunds: 1.9697,
              },
              gross_arr_spread: {
                without_refunds: 1.9513,
                with_refunds: 1.9513,
              },
              net_total: {
                without_refunds: 0.2172,
                with_refunds: 0.219,
              },
              gross_total: {
                without_refunds: 0.1972,
                with_refunds: 0.1985,
              },
              net_total_spread: {
                without_refunds: 1.0141,
                with_refunds: 1.0249,
              },
              gross_total_spread: {
                without_refunds: 0.9638,
                with_refunds: 0.9747,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.777,
                with_refunds: 0.7789,
              },
              gross_mrr: {
                without_refunds: 0.7683,
                with_refunds: 0.7702,
              },
              net_arr: {
                without_refunds: 0.4297,
                with_refunds: 0.7581,
              },
              gross_arr: {
                without_refunds: 0.4404,
                with_refunds: 0.515,
              },
              net_arr_spread: {
                without_refunds: 1.0062,
                with_refunds: 1.0062,
              },
              gross_arr_spread: {
                without_refunds: 1.0068,
                with_refunds: 1.0068,
              },
              net_total: {
                without_refunds: 0.7075,
                with_refunds: 0.7666,
              },
              gross_total: {
                without_refunds: 0.6998,
                with_refunds: 0.7585,
              },
              net_total_spread: {
                without_refunds: 0.8595,
                with_refunds: 0.8617,
              },
              gross_total_spread: {
                without_refunds: 0.8534,
                with_refunds: 0.8555,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.6317,
                with_refunds: 0.6423,
              },
              gross_mrr: {
                without_refunds: 0.632,
                with_refunds: 0.6427,
              },
              net_arr: {
                without_refunds: 0.0061,
              },
              gross_arr: {
                without_refunds: 0.0069,
                with_refunds: 0.0005,
              },
              net_arr_spread: {
                without_refunds: 2.0867,
                with_refunds: 2.0867,
              },
              gross_arr_spread: {
                without_refunds: 2.0634,
                with_refunds: 2.0634,
              },
              net_total: {
                without_refunds: 0.0475,
                with_refunds: 0.0453,
              },
              gross_total: {
                without_refunds: 0.0486,
                with_refunds: 0.0462,
              },
              net_total_spread: {
                without_refunds: 1.4148,
                with_refunds: 1.4338,
              },
              gross_total_spread: {
                without_refunds: 1.4025,
                with_refunds: 1.4209,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7926,
                with_refunds: 0.8057,
              },
              gross_mrr: {
                without_refunds: 0.7907,
                with_refunds: 0.8031,
              },
              net_arr: {
                without_refunds: 0.2074,
              },
              gross_arr: {
                without_refunds: 0.2197,
                with_refunds: 0.0005,
              },
              net_arr_spread: {
                without_refunds: 1.0016,
                with_refunds: 1.0016,
              },
              gross_arr_spread: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              net_total: {
                without_refunds: 0.5339,
                with_refunds: 0.7365,
              },
              gross_total: {
                without_refunds: 0.5309,
                with_refunds: 0.7404,
              },
              net_total_spread: {
                without_refunds: 0.9473,
                with_refunds: 0.9517,
              },
              gross_total_spread: {
                without_refunds: 0.9465,
                with_refunds: 0.9507,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.7818,
                with_refunds: 0.7909,
              },
              gross_mrr: {
                without_refunds: 0.763,
                with_refunds: 0.7727,
              },
              net_arr: {
                without_refunds: 0.0128,
                with_refunds: 0.0048,
              },
              gross_arr: {
                without_refunds: 0.0144,
                with_refunds: 0.0061,
              },
              net_arr_spread: {
                without_refunds: 2.0161,
                with_refunds: 2.0161,
              },
              gross_arr_spread: {
                without_refunds: 2.006,
                with_refunds: 2.006,
              },
              net_total: {
                without_refunds: 0.1109,
                with_refunds: 0.1097,
              },
              gross_total: {
                without_refunds: 0.1131,
                with_refunds: 0.1117,
              },
              net_total_spread: {
                without_refunds: 1.2323,
                with_refunds: 1.2483,
              },
              gross_total_spread: {
                without_refunds: 1.2103,
                with_refunds: 1.2267,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7814,
                with_refunds: 0.7887,
              },
              gross_mrr: {
                without_refunds: 0.7783,
                with_refunds: 0.7849,
              },
              net_arr: {
                without_refunds: 0.4031,
                with_refunds: 0.7783,
              },
              gross_arr: {
                without_refunds: 0.4106,
                with_refunds: 0.4796,
              },
              net_arr_spread: {
                without_refunds: 1.0051,
                with_refunds: 1.0051,
              },
              gross_arr_spread: {
                without_refunds: 1.0058,
                with_refunds: 1.0058,
              },
              net_total: {
                without_refunds: 0.6647,
                with_refunds: 0.7709,
              },
              gross_total: {
                without_refunds: 0.6599,
                with_refunds: 0.7668,
              },
              net_total_spread: {
                without_refunds: 0.8992,
                with_refunds: 0.904,
              },
              gross_total_spread: {
                without_refunds: 0.8973,
                with_refunds: 0.9018,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 719.2479,
              with_refunds: 666.7415,
            },
            gross_mrr: {
              without_refunds: 796.4514,
              with_refunds: 722.1169,
            },
            net_arr: {
              without_refunds: 3906.33,
              with_refunds: 3543.12,
            },
            gross_arr: {
              without_refunds: 4124.94,
              with_refunds: 3749.94,
            },
            net_arr_spread: {
              without_refunds: 295.26,
              with_refunds: 295.26,
            },
            gross_arr_spread: {
              without_refunds: 312.495,
              with_refunds: 312.495,
            },
            net_total: {
              without_refunds: 4625.5779,
              with_refunds: 4209.8615,
            },
            gross_total: {
              without_refunds: 4921.3914,
              with_refunds: 4472.0569,
            },
            net_total_spread: {
              without_refunds: 1014.5079,
              with_refunds: 962.0015,
            },
            gross_total_spread: {
              without_refunds: 1108.9464,
              with_refunds: 1034.6119,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 4.89,
                with_refunds: 4.79,
              },
              gross_mrr: {
                without_refunds: 5.59,
                with_refunds: 5.48,
              },
              net_arr: {
                without_refunds: 0.66,
                with_refunds: 0.25,
              },
              gross_arr: {
                without_refunds: 0.85,
                with_refunds: 0.35,
              },
              net_arr_spread: {
                without_refunds: 8.67,
                with_refunds: 8.67,
              },
              gross_arr_spread: {
                without_refunds: 9.8,
                with_refunds: 9.8,
              },
              net_total: {
                without_refunds: 3.37,
                with_refunds: 3.16,
              },
              gross_total: {
                without_refunds: 3.89,
                with_refunds: 3.64,
              },
              net_total_spread: {
                without_refunds: 6.14,
                with_refunds: 6.07,
              },
              gross_total_spread: {
                without_refunds: 6.99,
                with_refunds: 6.91,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 5.16,
                with_refunds: 5.04,
              },
              gross_mrr: {
                without_refunds: 5.87,
                with_refunds: 5.73,
              },
              net_arr: {
                without_refunds: 0.32,
              },
              gross_arr: {
                without_refunds: 0.41,
                with_refunds: 0.03,
              },
              net_arr_spread: {
                without_refunds: 10.44,
                with_refunds: 10.44,
              },
              gross_arr_spread: {
                without_refunds: 11.63,
                with_refunds: 11.63,
              },
              net_total: {
                without_refunds: 1.69,
                with_refunds: 1.42,
              },
              gross_total: {
                without_refunds: 1.95,
                with_refunds: 1.63,
              },
              net_total_spread: {
                without_refunds: 8.68,
                with_refunds: 8.64,
              },
              gross_total_spread: {
                without_refunds: 9.72,
                with_refunds: 9.67,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 5.07,
                with_refunds: 4.95,
              },
              gross_mrr: {
                without_refunds: 5.76,
                with_refunds: 5.63,
              },
              net_arr: {
                without_refunds: 0.63,
                with_refunds: 0.2,
              },
              gross_arr: {
                without_refunds: 0.8,
                with_refunds: 0.29,
              },
              net_arr_spread: {
                without_refunds: 9.3,
                with_refunds: 9.3,
              },
              gross_arr_spread: {
                without_refunds: 10.4,
                with_refunds: 10.4,
              },
              net_total: {
                without_refunds: 2.75,
                with_refunds: 2.46,
              },
              gross_total: {
                without_refunds: 3.17,
                with_refunds: 2.83,
              },
              net_total_spread: {
                without_refunds: 7.1,
                with_refunds: 7.04,
              },
              gross_total_spread: {
                without_refunds: 7.99,
                with_refunds: 7.92,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 26.39,
                with_refunds: 25.09,
              },
              gross_mrr: {
                without_refunds: 30.56,
                with_refunds: 29.08,
              },
              net_arr: {
                without_refunds: 92.23,
                with_refunds: 86.73,
              },
              gross_arr: {
                without_refunds: 99.17,
                with_refunds: 93.42,
              },
              net_arr_spread: {
                without_refunds: 26.21,
                with_refunds: 26.21,
              },
              gross_arr_spread: {
                without_refunds: 28.27,
                with_refunds: 28.27,
              },
              net_total: {
                without_refunds: 64.87,
                with_refunds: 60.65,
              },
              gross_total: {
                without_refunds: 70.49,
                with_refunds: 66.04,
              },
              net_total_spread: {
                without_refunds: 33.53,
                with_refunds: 32.35,
              },
              gross_total_spread: {
                without_refunds: 37.99,
                with_refunds: 36.63,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.4,
                with_refunds: 21.5,
              },
              gross_mrr: {
                without_refunds: 25.63,
                with_refunds: 24.62,
              },
              net_arr: {
                without_refunds: 66.9,
                with_refunds: 59.28,
              },
              gross_arr: {
                without_refunds: 74.45,
                with_refunds: 66.04,
              },
              net_arr_spread: {
                without_refunds: 18.6,
                with_refunds: 18.6,
              },
              gross_arr_spread: {
                without_refunds: 20.8,
                with_refunds: 20.8,
              },
              net_total: {
                without_refunds: 76.48,
                with_refunds: 66.51,
              },
              gross_total: {
                without_refunds: 85.27,
                with_refunds: 74.24,
              },
              net_total_spread: {
                without_refunds: 20.11,
                with_refunds: 19.69,
              },
              gross_total_spread: {
                without_refunds: 22.76,
                with_refunds: 22.28,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.82,
                with_refunds: 11.52,
              },
              gross_mrr: {
                without_refunds: 13.53,
                with_refunds: 13.2,
              },
              net_arr: {
                without_refunds: 110.17,
                with_refunds: 20.39,
              },
              gross_arr: {
                without_refunds: 139.43,
                with_refunds: 29.46,
              },
              net_arr_spread: {
                without_refunds: 8.83,
                with_refunds: 8.83,
              },
              gross_arr_spread: {
                without_refunds: 9.99,
                with_refunds: 9.99,
              },
              net_total: {
                without_refunds: 13.67,
                with_refunds: 11.71,
              },
              gross_total: {
                without_refunds: 15.74,
                with_refunds: 13.53,
              },
              net_total_spread: {
                without_refunds: 10.19,
                with_refunds: 10.05,
              },
              gross_total_spread: {
                without_refunds: 11.63,
                with_refunds: 11.47,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 12.91,
                with_refunds: 12.52,
              },
              gross_mrr: {
                without_refunds: 14.71,
                with_refunds: 14.26,
              },
              net_arr: {
                without_refunds: 108.76,
              },
              gross_arr: {
                without_refunds: 136.39,
                with_refunds: 3.34,
              },
              net_arr_spread: {
                without_refunds: 10.49,
                with_refunds: 10.49,
              },
              gross_arr_spread: {
                without_refunds: 11.69,
                with_refunds: 11.69,
              },
              net_total: {
                without_refunds: 17.62,
                with_refunds: 11.42,
              },
              gross_total: {
                without_refunds: 20.11,
                with_refunds: 13.22,
              },
              net_total_spread: {
                without_refunds: 10.86,
                with_refunds: 10.8,
              },
              gross_total_spread: {
                without_refunds: 12.17,
                with_refunds: 12.1,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 12.59,
                with_refunds: 12.22,
              },
              gross_mrr: {
                without_refunds: 14.33,
                with_refunds: 13.92,
              },
              net_arr: {
                without_refunds: 111.54,
                with_refunds: 15.85,
              },
              gross_arr: {
                without_refunds: 140.56,
                with_refunds: 23.7,
              },
              net_arr_spread: {
                without_refunds: 9.39,
                with_refunds: 9.39,
              },
              gross_arr_spread: {
                without_refunds: 10.51,
                with_refunds: 10.51,
              },
              net_total: {
                without_refunds: 15.41,
                with_refunds: 12.13,
              },
              gross_total: {
                without_refunds: 17.69,
                with_refunds: 14.01,
              },
              net_total_spread: {
                without_refunds: 10.33,
                with_refunds: 10.21,
              },
              gross_total_spread: {
                without_refunds: 11.64,
                with_refunds: 11.52,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 26.39,
                with_refunds: 25.09,
              },
              gross_mrr: {
                without_refunds: 30.56,
                with_refunds: 29.08,
              },
              net_arr: {
                without_refunds: 92.23,
                with_refunds: 86.73,
              },
              gross_arr: {
                without_refunds: 99.17,
                with_refunds: 93.42,
              },
              net_arr_spread: {
                without_refunds: 26.21,
                with_refunds: 26.21,
              },
              gross_arr_spread: {
                without_refunds: 28.27,
                with_refunds: 28.27,
              },
              net_total: {
                without_refunds: 64.87,
                with_refunds: 60.65,
              },
              gross_total: {
                without_refunds: 70.49,
                with_refunds: 66.04,
              },
              net_total_spread: {
                without_refunds: 33.53,
                with_refunds: 32.35,
              },
              gross_total_spread: {
                without_refunds: 37.99,
                with_refunds: 36.63,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.4,
                with_refunds: 21.5,
              },
              gross_mrr: {
                without_refunds: 25.63,
                with_refunds: 24.62,
              },
              net_arr: {
                without_refunds: 66.9,
                with_refunds: 59.28,
              },
              gross_arr: {
                without_refunds: 74.45,
                with_refunds: 66.04,
              },
              net_arr_spread: {
                without_refunds: 18.6,
                with_refunds: 18.6,
              },
              gross_arr_spread: {
                without_refunds: 20.8,
                with_refunds: 20.8,
              },
              net_total: {
                without_refunds: 76.48,
                with_refunds: 66.51,
              },
              gross_total: {
                without_refunds: 85.27,
                with_refunds: 74.24,
              },
              net_total_spread: {
                without_refunds: 20.11,
                with_refunds: 19.69,
              },
              gross_total_spread: {
                without_refunds: 22.76,
                with_refunds: 22.28,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 26.92,
                  with_refunds: 26.14,
                },
                gross_mrr: {
                  without_refunds: 31.84,
                  with_refunds: 30.94,
                },
                net_arr: {
                  without_refunds: 105.93,
                  with_refunds: 85.16,
                },
                gross_arr: {
                  without_refunds: 119.19,
                  with_refunds: 96.17,
                },
                net_arr_spread: {
                  without_refunds: 30.3,
                  with_refunds: 30.3,
                },
                gross_arr_spread: {
                  without_refunds: 34.29,
                  with_refunds: 34.29,
                },
                net_total: {
                  without_refunds: 56.37,
                  with_refunds: 48.2,
                },
                gross_total: {
                  without_refunds: 64.42,
                  with_refunds: 55.34,
                },
                net_total_spread: {
                  without_refunds: 28.21,
                  with_refunds: 27.67,
                },
                gross_total_spread: {
                  without_refunds: 32.87,
                  with_refunds: 32.25,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 29.33,
                  with_refunds: 27.9,
                },
                gross_mrr: {
                  without_refunds: 33.44,
                  with_refunds: 31.86,
                },
                net_arr: {
                  without_refunds: 126.53,
                  with_refunds: 96.66,
                },
                gross_arr: {
                  without_refunds: 140.45,
                  with_refunds: 107.55,
                },
                net_arr_spread: {
                  without_refunds: 36.25,
                  with_refunds: 36.25,
                },
                gross_arr_spread: {
                  without_refunds: 40.44,
                  with_refunds: 40.44,
                },
                net_total: {
                  without_refunds: 99.8,
                  with_refunds: 77.78,
                },
                gross_total: {
                  without_refunds: 111.02,
                  with_refunds: 86.77,
                },
                net_total_spread: {
                  without_refunds: 34.03,
                  with_refunds: 33.54,
                },
                gross_total_spread: {
                  without_refunds: 38.21,
                  with_refunds: 37.68,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 28.25,
                  with_refunds: 27.18,
                },
                gross_mrr: {
                  without_refunds: 32.55,
                  with_refunds: 31.34,
                },
                net_arr: {
                  without_refunds: 113.28,
                  with_refunds: 89.51,
                },
                gross_arr: {
                  without_refunds: 126.32,
                  with_refunds: 100.06,
                },
                net_arr_spread: {
                  without_refunds: 32.44,
                  with_refunds: 32.44,
                },
                gross_arr_spread: {
                  without_refunds: 36.3,
                  with_refunds: 36.3,
                },
                net_total: {
                  without_refunds: 73.73,
                  with_refunds: 60.57,
                },
                gross_total: {
                  without_refunds: 82.72,
                  with_refunds: 68.17,
                },
                net_total_spread: {
                  without_refunds: 30.42,
                  with_refunds: 29.85,
                },
                gross_total_spread: {
                  without_refunds: 34.54,
                  with_refunds: 33.89,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 46.48,
                  with_refunds: 45.02,
                },
                gross_mrr: {
                  without_refunds: 54.77,
                  with_refunds: 53.07,
                },
                net_arr: {
                  without_refunds: 437.63,
                  with_refunds: 202.32,
                },
                gross_arr: {
                  without_refunds: 518.56,
                  with_refunds: 239.52,
                },
                net_arr_spread: {
                  without_refunds: 35,
                  with_refunds: 35,
                },
                gross_arr_spread: {
                  without_refunds: 39.67,
                  with_refunds: 39.67,
                },
                net_total: {
                  without_refunds: 111.35,
                  with_refunds: 91.87,
                },
                gross_total: {
                  without_refunds: 127.11,
                  with_refunds: 105.42,
                },
                net_total_spread: {
                  without_refunds: 41.62,
                  with_refunds: 40.76,
                },
                gross_total_spread: {
                  without_refunds: 48.56,
                  with_refunds: 47.56,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 50.81,
                  with_refunds: 48.14,
                },
                gross_mrr: {
                  without_refunds: 57.97,
                  with_refunds: 54.98,
                },
                net_arr: {
                  without_refunds: 487.96,
                  with_refunds: 189.48,
                },
                gross_arr: {
                  without_refunds: 565.22,
                  with_refunds: 214.01,
                },
                net_arr_spread: {
                  without_refunds: 41.37,
                  with_refunds: 41.37,
                },
                gross_arr_spread: {
                  without_refunds: 46.22,
                  with_refunds: 46.22,
                },
                net_total: {
                  without_refunds: 215.51,
                  with_refunds: 155.4,
                },
                gross_total: {
                  without_refunds: 240.34,
                  with_refunds: 173.83,
                },
                net_total_spread: {
                  without_refunds: 43.87,
                  with_refunds: 43.14,
                },
                gross_total_spread: {
                  without_refunds: 49.34,
                  with_refunds: 48.53,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 50.37,
                  with_refunds: 48.33,
                },
                gross_mrr: {
                  without_refunds: 57.97,
                  with_refunds: 55.67,
                },
                net_arr: {
                  without_refunds: 458.58,
                  with_refunds: 199.43,
                },
                gross_arr: {
                  without_refunds: 539.01,
                  with_refunds: 232.7,
                },
                net_arr_spread: {
                  without_refunds: 37.2,
                  with_refunds: 37.2,
                },
                gross_arr_spread: {
                  without_refunds: 41.66,
                  with_refunds: 41.66,
                },
                net_total: {
                  without_refunds: 152.84,
                  with_refunds: 119.98,
                },
                gross_total: {
                  without_refunds: 171.84,
                  with_refunds: 135.38,
                },
                net_total_spread: {
                  without_refunds: 42.73,
                  with_refunds: 41.85,
                },
                gross_total_spread: {
                  without_refunds: 48.58,
                  with_refunds: 47.58,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.4235,
                  with_refunds: 0.4109,
                },
                gross_mrr: {
                  without_refunds: 0.5008,
                  with_refunds: 0.4861,
                },
                net_arr: {
                  without_refunds: 1.4977,
                  with_refunds: 1.2167,
                },
                gross_arr: {
                  without_refunds: 1.6912,
                  with_refunds: 1.3787,
                },
                net_arr_spread: {
                  without_refunds: 0.4334,
                  with_refunds: 0.4334,
                },
                gross_arr_spread: {
                  without_refunds: 0.492,
                  with_refunds: 0.492,
                },
                net_total: {
                  without_refunds: 0.8245,
                  with_refunds: 0.7126,
                },
                gross_total: {
                  without_refunds: 0.9454,
                  with_refunds: 0.8205,
                },
                net_total_spread: {
                  without_refunds: 0.4299,
                  with_refunds: 0.4212,
                },
                gross_total_spread: {
                  without_refunds: 0.5017,
                  with_refunds: 0.4916,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.3952,
                  with_refunds: 0.3758,
                },
                gross_mrr: {
                  without_refunds: 0.4516,
                  with_refunds: 0.4302,
                },
                net_arr: {
                  without_refunds: 1.4364,
                  with_refunds: 1.0974,
                },
                gross_arr: {
                  without_refunds: 1.5949,
                  with_refunds: 1.2211,
                },
                net_arr_spread: {
                  without_refunds: 0.4101,
                  with_refunds: 0.4101,
                },
                gross_arr_spread: {
                  without_refunds: 0.4578,
                  with_refunds: 0.4578,
                },
                net_total: {
                  without_refunds: 1.1505,
                  with_refunds: 0.8997,
                },
                gross_total: {
                  without_refunds: 1.281,
                  with_refunds: 1.0045,
                },
                net_total_spread: {
                  without_refunds: 0.4065,
                  with_refunds: 0.3999,
                },
                gross_total_spread: {
                  without_refunds: 0.4574,
                  with_refunds: 0.4501,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5,
                  with_refunds: 0.4813,
                },
                gross_mrr: {
                  without_refunds: 0.5776,
                  with_refunds: 0.5565,
                },
                net_arr: {
                  without_refunds: 1.6253,
                  with_refunds: 1.3019,
                },
                gross_arr: {
                  without_refunds: 1.8156,
                  with_refunds: 1.4575,
                },
                net_arr_spread: {
                  without_refunds: 0.4724,
                  with_refunds: 0.4724,
                },
                gross_arr_spread: {
                  without_refunds: 0.5292,
                  with_refunds: 0.5292,
                },
                net_total: {
                  without_refunds: 1.103,
                  with_refunds: 0.922,
                },
                gross_total: {
                  without_refunds: 1.2412,
                  with_refunds: 1.0406,
                },
                net_total_spread: {
                  without_refunds: 0.4902,
                  with_refunds: 0.4803,
                },
                gross_total_spread: {
                  without_refunds: 0.5585,
                  with_refunds: 0.5472,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.737,
                  with_refunds: 0.7131,
                },
                gross_mrr: {
                  without_refunds: 0.8679,
                  with_refunds: 0.8402,
                },
                net_arr: {
                  without_refunds: 6.277,
                  with_refunds: 2.9853,
                },
                gross_arr: {
                  without_refunds: 7.4791,
                  with_refunds: 3.5681,
                },
                net_arr_spread: {
                  without_refunds: 0.5014,
                  with_refunds: 0.5014,
                },
                gross_arr_spread: {
                  without_refunds: 0.5699,
                  with_refunds: 0.5699,
                },
                net_total: {
                  without_refunds: 1.6179,
                  with_refunds: 1.3591,
                },
                gross_total: {
                  without_refunds: 1.8531,
                  with_refunds: 1.564,
                },
                net_total_spread: {
                  without_refunds: 0.6418,
                  with_refunds: 0.6278,
                },
                gross_total_spread: {
                  without_refunds: 0.7497,
                  with_refunds: 0.7333,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6839,
                  with_refunds: 0.6478,
                },
                gross_mrr: {
                  without_refunds: 0.7818,
                  with_refunds: 0.7416,
                },
                net_arr: {
                  without_refunds: 5.5713,
                  with_refunds: 2.1549,
                },
                gross_arr: {
                  without_refunds: 6.4498,
                  with_refunds: 2.444,
                },
                net_arr_spread: {
                  without_refunds: 0.4693,
                  with_refunds: 0.4693,
                },
                gross_arr_spread: {
                  without_refunds: 0.5246,
                  with_refunds: 0.5246,
                },
                net_total: {
                  without_refunds: 2.4674,
                  with_refunds: 1.7881,
                },
                gross_total: {
                  without_refunds: 2.7538,
                  with_refunds: 2.0027,
                },
                net_total_spread: {
                  without_refunds: 0.5321,
                  with_refunds: 0.5223,
                },
                gross_total_spread: {
                  without_refunds: 0.5996,
                  with_refunds: 0.5886,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.895,
                  with_refunds: 0.8597,
                },
                gross_mrr: {
                  without_refunds: 1.0328,
                  with_refunds: 0.9926,
                },
                net_arr: {
                  without_refunds: 6.7088,
                  with_refunds: 3.014,
                },
                gross_arr: {
                  without_refunds: 7.9198,
                  with_refunds: 3.5547,
                },
                net_arr_spread: {
                  without_refunds: 0.5435,
                  with_refunds: 0.5435,
                },
                gross_arr_spread: {
                  without_refunds: 0.6091,
                  with_refunds: 0.6091,
                },
                net_total: {
                  without_refunds: 2.2551,
                  with_refunds: 1.8196,
                },
                gross_total: {
                  without_refunds: 2.5434,
                  with_refunds: 2.0595,
                },
                net_total_spread: {
                  without_refunds: 0.7029,
                  with_refunds: 0.6875,
                },
                gross_total_spread: {
                  without_refunds: 0.8017,
                  with_refunds: 0.7841,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 4,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.3297,
                with_refunds: 0.3308,
              },
              arr_users: {
                without_refunds: 0.0042,
                with_refunds: 0.0049,
              },
              arr_spread_users: {
                without_refunds: 0.9807,
                with_refunds: 0.9807,
              },
              total_users: {
                without_refunds: 0.2184,
                with_refunds: 0.2193,
              },
              total_spread_users: {
                without_refunds: 0.5306,
                with_refunds: 0.5313,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7955,
                with_refunds: 0.795,
              },
              arr_users: {
                without_refunds: 0.7113,
                with_refunds: 0.5529,
              },
              arr_spread_users: {
                without_refunds: 1.0036,
                with_refunds: 1.0036,
              },
              total_users: {
                without_refunds: 0.7538,
                with_refunds: 0.7513,
              },
              total_spread_users: {
                without_refunds: 0.8976,
                with_refunds: 0.8971,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.321,
                with_refunds: 0.3225,
              },
              arr_users: {
                without_refunds: 0.0027,
                with_refunds: 0.0032,
              },
              arr_spread_users: {
                without_refunds: 0.9949,
                with_refunds: 0.9949,
              },
              total_users: {
                without_refunds: 0.1443,
                with_refunds: 0.1452,
              },
              total_spread_users: {
                without_refunds: 0.6604,
                with_refunds: 0.6612,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7307,
                with_refunds: 0.7318,
              },
              arr_users: {
                without_refunds: 0.3946,
                with_refunds: 0.3626,
              },
              arr_spread_users: {
                without_refunds: 1.0034,
                with_refunds: 1.0034,
              },
              total_users: {
                without_refunds: 0.6199,
                with_refunds: 0.6187,
              },
              total_spread_users: {
                without_refunds: 0.9212,
                with_refunds: 0.9213,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.3009,
                with_refunds: 0.3021,
              },
              arr_users: {
                without_refunds: 0.004,
                with_refunds: 0.0048,
              },
              arr_spread_users: {
                without_refunds: 0.9884,
                with_refunds: 0.9884,
              },
              total_users: {
                without_refunds: 0.1672,
                with_refunds: 0.168,
              },
              total_spread_users: {
                without_refunds: 0.5806,
                with_refunds: 0.5812,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7943,
                with_refunds: 0.7933,
              },
              arr_users: {
                without_refunds: 0.576,
                with_refunds: 0.4699,
              },
              arr_spread_users: {
                without_refunds: 1.0034,
                with_refunds: 1.0034,
              },
              total_users: {
                without_refunds: 0.7353,
                with_refunds: 0.7317,
              },
              total_spread_users: {
                without_refunds: 0.926,
                with_refunds: 0.9254,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.5825,
                with_refunds: 0.587,
              },
              gross_mrr: {
                without_refunds: 0.5343,
                with_refunds: 0.5389,
              },
              net_arr: {
                without_refunds: 0.0103,
                with_refunds: 0.0077,
              },
              gross_arr: {
                without_refunds: 0.0115,
                with_refunds: 0.0087,
              },
              net_arr_spread: {
                without_refunds: 1.9913,
                with_refunds: 1.9913,
              },
              gross_arr_spread: {
                without_refunds: 1.9752,
                with_refunds: 1.9752,
              },
              net_total: {
                without_refunds: 0.1805,
                with_refunds: 0.1848,
              },
              gross_total: {
                without_refunds: 0.1623,
                with_refunds: 0.1662,
              },
              net_total_spread: {
                without_refunds: 0.8811,
                with_refunds: 0.8922,
              },
              gross_total_spread: {
                without_refunds: 0.8354,
                with_refunds: 0.8466,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7981,
                with_refunds: 0.798,
              },
              gross_mrr: {
                without_refunds: 0.7945,
                with_refunds: 0.7944,
              },
              net_arr: {
                without_refunds: 0.7505,
                with_refunds: 1.1413,
              },
              gross_arr: {
                without_refunds: 0.7438,
                with_refunds: 0.9988,
              },
              net_arr_spread: {
                without_refunds: 1.0044,
                with_refunds: 1.0044,
              },
              gross_arr_spread: {
                without_refunds: 1.0051,
                with_refunds: 1.0051,
              },
              net_total: {
                without_refunds: 0.7502,
                with_refunds: 0.7669,
              },
              gross_total: {
                without_refunds: 0.7454,
                with_refunds: 0.7623,
              },
              net_total_spread: {
                without_refunds: 0.8835,
                with_refunds: 0.8844,
              },
              gross_total_spread: {
                without_refunds: 0.8813,
                with_refunds: 0.8822,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.7217,
                with_refunds: 0.7269,
              },
              gross_mrr: {
                without_refunds: 0.7113,
                with_refunds: 0.7168,
              },
              net_arr: {
                without_refunds: 0.0072,
                with_refunds: 0.0065,
              },
              gross_arr: {
                without_refunds: 0.0079,
                with_refunds: 0.0074,
              },
              net_arr_spread: {
                without_refunds: 2.2705,
                with_refunds: 2.2705,
              },
              gross_arr_spread: {
                without_refunds: 2.2577,
                with_refunds: 2.2577,
              },
              net_total: {
                without_refunds: 0.0932,
                with_refunds: 0.0975,
              },
              gross_total: {
                without_refunds: 0.0944,
                with_refunds: 0.099,
              },
              net_total_spread: {
                without_refunds: 1.2978,
                with_refunds: 1.3158,
              },
              gross_total_spread: {
                without_refunds: 1.2851,
                with_refunds: 1.3032,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7335,
                with_refunds: 0.7337,
              },
              gross_mrr: {
                without_refunds: 0.7335,
                with_refunds: 0.7335,
              },
              net_arr: {
                without_refunds: 0.4293,
                with_refunds: 0.3575,
              },
              gross_arr: {
                without_refunds: 0.3831,
                with_refunds: 0.3566,
              },
              net_arr_spread: {
                without_refunds: 1.0037,
                with_refunds: 1.0037,
              },
              gross_arr_spread: {
                without_refunds: 1.0041,
                with_refunds: 1.0041,
              },
              net_total: {
                without_refunds: 0.602,
                with_refunds: 0.6358,
              },
              gross_total: {
                without_refunds: 0.5966,
                with_refunds: 0.6343,
              },
              net_total_spread: {
                without_refunds: 0.907,
                with_refunds: 0.9084,
              },
              gross_total_spread: {
                without_refunds: 0.9067,
                with_refunds: 0.908,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.5534,
                with_refunds: 0.5597,
              },
              gross_mrr: {
                without_refunds: 0.5334,
                with_refunds: 0.54,
              },
              net_arr: {
                without_refunds: 0.0097,
                with_refunds: 0.0073,
              },
              gross_arr: {
                without_refunds: 0.0109,
                with_refunds: 0.0082,
              },
              net_arr_spread: {
                without_refunds: 2.0148,
                with_refunds: 2.0148,
              },
              gross_arr_spread: {
                without_refunds: 2.0066,
                with_refunds: 2.0066,
              },
              net_total: {
                without_refunds: 0.0983,
                with_refunds: 0.1018,
              },
              gross_total: {
                without_refunds: 0.0995,
                with_refunds: 0.1027,
              },
              net_total_spread: {
                without_refunds: 0.9813,
                with_refunds: 0.9967,
              },
              gross_total_spread: {
                without_refunds: 0.9558,
                with_refunds: 0.9714,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7935,
                with_refunds: 0.7939,
              },
              gross_mrr: {
                without_refunds: 0.7926,
                with_refunds: 0.793,
              },
              net_arr: {
                without_refunds: 0.607,
                with_refunds: 0.917,
              },
              gross_arr: {
                without_refunds: 0.5794,
                with_refunds: 0.7574,
              },
              net_arr_spread: {
                without_refunds: 1.0042,
                with_refunds: 1.0042,
              },
              gross_arr_spread: {
                without_refunds: 1.0048,
                with_refunds: 1.0048,
              },
              net_total: {
                without_refunds: 0.7253,
                with_refunds: 0.7515,
              },
              gross_total: {
                without_refunds: 0.7221,
                with_refunds: 0.7482,
              },
              net_total_spread: {
                without_refunds: 0.9093,
                with_refunds: 0.9107,
              },
              gross_total_spread: {
                without_refunds: 0.9086,
                with_refunds: 0.9099,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 958.9972,
              with_refunds: 888.9887,
            },
            gross_mrr: {
              without_refunds: 1061.9352,
              with_refunds: 962.8226,
            },
            net_arr: {
              without_refunds: 5208.44,
              with_refunds: 4724.16,
            },
            gross_arr: {
              without_refunds: 5499.92,
              with_refunds: 4999.92,
            },
            net_arr_spread: {
              without_refunds: 393.68,
              with_refunds: 393.68,
            },
            gross_arr_spread: {
              without_refunds: 416.66,
              with_refunds: 416.66,
            },
            net_total: {
              without_refunds: 6167.4372,
              with_refunds: 5613.1487,
            },
            gross_total: {
              without_refunds: 6561.8552,
              with_refunds: 5962.7426,
            },
            net_total_spread: {
              without_refunds: 1352.6772,
              with_refunds: 1282.6687,
            },
            gross_total_spread: {
              without_refunds: 1478.5952,
              with_refunds: 1379.4826,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 3.9,
                with_refunds: 3.82,
              },
              gross_mrr: {
                without_refunds: 4.44,
                with_refunds: 4.35,
              },
              net_arr: {
                without_refunds: 0.48,
                with_refunds: 0.31,
              },
              gross_arr: {
                without_refunds: 0.61,
                with_refunds: 0.4,
              },
              net_arr_spread: {
                without_refunds: 8.47,
                with_refunds: 8.47,
              },
              gross_arr_spread: {
                without_refunds: 9.62,
                with_refunds: 9.62,
              },
              net_total: {
                without_refunds: 2.74,
                with_refunds: 2.62,
              },
              gross_total: {
                without_refunds: 3.14,
                with_refunds: 3.01,
              },
              net_total_spread: {
                without_refunds: 5.32,
                with_refunds: 5.27,
              },
              gross_total_spread: {
                without_refunds: 6.05,
                with_refunds: 5.99,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 4.11,
                with_refunds: 4.01,
              },
              gross_mrr: {
                without_refunds: 4.66,
                with_refunds: 4.55,
              },
              net_arr: {
                without_refunds: 0.3,
                with_refunds: 0.24,
              },
              gross_arr: {
                without_refunds: 0.37,
                with_refunds: 0.3,
              },
              net_arr_spread: {
                without_refunds: 9.67,
                with_refunds: 9.67,
              },
              gross_arr_spread: {
                without_refunds: 10.84,
                with_refunds: 10.84,
              },
              net_total: {
                without_refunds: 2,
                with_refunds: 1.92,
              },
              gross_total: {
                without_refunds: 2.28,
                with_refunds: 2.19,
              },
              net_total_spread: {
                without_refunds: 6.92,
                with_refunds: 6.87,
              },
              gross_total_spread: {
                without_refunds: 7.79,
                with_refunds: 7.73,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 3.7,
                with_refunds: 3.61,
              },
              gross_mrr: {
                without_refunds: 4.22,
                with_refunds: 4.12,
              },
              net_arr: {
                without_refunds: 0.46,
                with_refunds: 0.3,
              },
              gross_arr: {
                without_refunds: 0.59,
                with_refunds: 0.39,
              },
              net_arr_spread: {
                without_refunds: 8.79,
                with_refunds: 8.79,
              },
              gross_arr_spread: {
                without_refunds: 9.88,
                with_refunds: 9.88,
              },
              net_total: {
                without_refunds: 2.24,
                with_refunds: 2.12,
              },
              gross_total: {
                without_refunds: 2.59,
                with_refunds: 2.44,
              },
              net_total_spread: {
                without_refunds: 5.78,
                with_refunds: 5.73,
              },
              gross_total_spread: {
                without_refunds: 6.53,
                with_refunds: 6.48,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 30.76,
                with_refunds: 29.26,
              },
              gross_mrr: {
                without_refunds: 35.62,
                with_refunds: 33.9,
              },
              net_arr: {
                without_refunds: 92.46,
                with_refunds: 86.98,
              },
              gross_arr: {
                without_refunds: 99.42,
                with_refunds: 93.69,
              },
              net_arr_spread: {
                without_refunds: 33.4,
                with_refunds: 33.4,
              },
              gross_arr_spread: {
                without_refunds: 36.02,
                with_refunds: 36.02,
              },
              net_total: {
                without_refunds: 72.13,
                with_refunds: 67.35,
              },
              gross_total: {
                without_refunds: 78.37,
                with_refunds: 73.32,
              },
              net_total_spread: {
                without_refunds: 40.58,
                with_refunds: 39.15,
              },
              gross_total_spread: {
                without_refunds: 45.97,
                with_refunds: 44.33,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 25.26,
                with_refunds: 24.25,
              },
              gross_mrr: {
                without_refunds: 28.9,
                with_refunds: 27.77,
              },
              net_arr: {
                without_refunds: 67.16,
                with_refunds: 59.54,
              },
              gross_arr: {
                without_refunds: 74.74,
                with_refunds: 66.33,
              },
              net_arr_spread: {
                without_refunds: 23.88,
                with_refunds: 23.88,
              },
              gross_arr_spread: {
                without_refunds: 26.71,
                with_refunds: 26.71,
              },
              net_total: {
                without_refunds: 84.44,
                with_refunds: 73.32,
              },
              gross_total: {
                without_refunds: 94.14,
                with_refunds: 81.85,
              },
              net_total_spread: {
                without_refunds: 24.13,
                with_refunds: 23.62,
              },
              gross_total_spread: {
                without_refunds: 27.31,
                with_refunds: 26.73,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.84,
                with_refunds: 11.55,
              },
              gross_mrr: {
                without_refunds: 13.51,
                with_refunds: 13.19,
              },
              net_arr: {
                without_refunds: 113.42,
                with_refunds: 45.12,
              },
              gross_arr: {
                without_refunds: 136.38,
                with_refunds: 58.2,
              },
              net_arr_spread: {
                without_refunds: 8.61,
                with_refunds: 8.61,
              },
              gross_arr_spread: {
                without_refunds: 9.79,
                with_refunds: 9.79,
              },
              net_total: {
                without_refunds: 13.26,
                with_refunds: 11.99,
              },
              gross_total: {
                without_refunds: 15.23,
                with_refunds: 13.81,
              },
              net_total_spread: {
                without_refunds: 10,
                with_refunds: 9.87,
              },
              gross_total_spread: {
                without_refunds: 11.4,
                with_refunds: 11.25,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 12.72,
                with_refunds: 12.39,
              },
              gross_mrr: {
                without_refunds: 14.51,
                with_refunds: 14.14,
              },
              net_arr: {
                without_refunds: 118.02,
                with_refunds: 31.38,
              },
              gross_arr: {
                without_refunds: 131.18,
                with_refunds: 39.55,
              },
              net_arr_spread: {
                without_refunds: 9.72,
                with_refunds: 9.72,
              },
              gross_arr_spread: {
                without_refunds: 10.9,
                with_refunds: 10.9,
              },
              net_total: {
                without_refunds: 15.16,
                with_refunds: 12.41,
              },
              gross_total: {
                without_refunds: 17.29,
                with_refunds: 14.38,
              },
              net_total_spread: {
                without_refunds: 10.46,
                with_refunds: 10.37,
              },
              gross_total_spread: {
                without_refunds: 11.76,
                with_refunds: 11.66,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 12.22,
                with_refunds: 11.9,
              },
              gross_mrr: {
                without_refunds: 13.97,
                with_refunds: 13.62,
              },
              net_arr: {
                without_refunds: 117.33,
                with_refunds: 44.81,
              },
              gross_arr: {
                without_refunds: 140.35,
                with_refunds: 57.51,
              },
              net_arr_spread: {
                without_refunds: 8.89,
                with_refunds: 8.89,
              },
              gross_arr_spread: {
                without_refunds: 9.99,
                with_refunds: 9.99,
              },
              net_total: {
                without_refunds: 14.07,
                with_refunds: 12.34,
              },
              gross_total: {
                without_refunds: 16.2,
                with_refunds: 14.28,
              },
              net_total_spread: {
                without_refunds: 9.87,
                with_refunds: 9.77,
              },
              gross_total_spread: {
                without_refunds: 11.17,
                with_refunds: 11.05,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 30.76,
                with_refunds: 29.26,
              },
              gross_mrr: {
                without_refunds: 35.62,
                with_refunds: 33.9,
              },
              net_arr: {
                without_refunds: 92.46,
                with_refunds: 86.98,
              },
              gross_arr: {
                without_refunds: 99.42,
                with_refunds: 93.69,
              },
              net_arr_spread: {
                without_refunds: 33.4,
                with_refunds: 33.4,
              },
              gross_arr_spread: {
                without_refunds: 36.02,
                with_refunds: 36.02,
              },
              net_total: {
                without_refunds: 72.13,
                with_refunds: 67.35,
              },
              gross_total: {
                without_refunds: 78.37,
                with_refunds: 73.32,
              },
              net_total_spread: {
                without_refunds: 40.58,
                with_refunds: 39.15,
              },
              gross_total_spread: {
                without_refunds: 45.97,
                with_refunds: 44.33,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 25.26,
                with_refunds: 24.25,
              },
              gross_mrr: {
                without_refunds: 28.9,
                with_refunds: 27.77,
              },
              net_arr: {
                without_refunds: 67.16,
                with_refunds: 59.54,
              },
              gross_arr: {
                without_refunds: 74.74,
                with_refunds: 66.33,
              },
              net_arr_spread: {
                without_refunds: 23.88,
                with_refunds: 23.88,
              },
              gross_arr_spread: {
                without_refunds: 26.71,
                with_refunds: 26.71,
              },
              net_total: {
                without_refunds: 84.44,
                with_refunds: 73.32,
              },
              gross_total: {
                without_refunds: 94.14,
                with_refunds: 81.85,
              },
              net_total_spread: {
                without_refunds: 24.13,
                with_refunds: 23.62,
              },
              gross_total_spread: {
                without_refunds: 27.31,
                with_refunds: 26.73,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 30.83,
                  with_refunds: 29.97,
                },
                gross_mrr: {
                  without_refunds: 36.29,
                  with_refunds: 35.3,
                },
                net_arr: {
                  without_refunds: 103.58,
                  with_refunds: 83.26,
                },
                gross_arr: {
                  without_refunds: 117.08,
                  with_refunds: 94.49,
                },
                net_arr_spread: {
                  without_refunds: 37.9,
                  with_refunds: 37.9,
                },
                gross_arr_spread: {
                  without_refunds: 43.07,
                  with_refunds: 43.07,
                },
                net_total: {
                  without_refunds: 56.68,
                  with_refunds: 48.98,
                },
                gross_total: {
                  without_refunds: 65.02,
                  with_refunds: 56.43,
                },
                net_total_spread: {
                  without_refunds: 33.22,
                  with_refunds: 32.62,
                },
                gross_total_spread: {
                  without_refunds: 38.64,
                  with_refunds: 37.94,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 33.75,
                  with_refunds: 32.54,
                },
                gross_mrr: {
                  without_refunds: 38.39,
                  with_refunds: 37.04,
                },
                net_arr: {
                  without_refunds: 117.81,
                  with_refunds: 91.97,
                },
                gross_arr: {
                  without_refunds: 131.65,
                  with_refunds: 102.98,
                },
                net_arr_spread: {
                  without_refunds: 42.7,
                  with_refunds: 42.7,
                },
                gross_arr_spread: {
                  without_refunds: 47.89,
                  with_refunds: 47.89,
                },
                net_total: {
                  without_refunds: 81.34,
                  with_refunds: 66.25,
                },
                gross_total: {
                  without_refunds: 91.2,
                  with_refunds: 74.45,
                },
                net_total_spread: {
                  without_refunds: 38.46,
                  with_refunds: 37.85,
                },
                gross_total_spread: {
                  without_refunds: 43.41,
                  with_refunds: 42.72,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 31.01,
                  with_refunds: 29.97,
                },
                gross_mrr: {
                  without_refunds: 36.04,
                  with_refunds: 34.85,
                },
                net_arr: {
                  without_refunds: 107.38,
                  with_refunds: 85.53,
                },
                gross_arr: {
                  without_refunds: 120.28,
                  with_refunds: 96.04,
                },
                net_arr_spread: {
                  without_refunds: 39.33,
                  with_refunds: 39.33,
                },
                gross_arr_spread: {
                  without_refunds: 44.19,
                  with_refunds: 44.19,
                },
                net_total: {
                  without_refunds: 66.34,
                  with_refunds: 55.74,
                },
                gross_total: {
                  without_refunds: 75.02,
                  with_refunds: 63.24,
                },
                net_total_spread: {
                  without_refunds: 34.58,
                  with_refunds: 33.95,
                },
                gross_total_spread: {
                  without_refunds: 39.57,
                  with_refunds: 38.85,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 58.31,
                  with_refunds: 56.56,
                },
                gross_mrr: {
                  without_refunds: 68.26,
                  with_refunds: 66.24,
                },
                net_arr: {
                  without_refunds: 543.29,
                  with_refunds: 246.04,
                },
                gross_arr: {
                  without_refunds: 651.17,
                  with_refunds: 297.93,
                },
                net_arr_spread: {
                  without_refunds: 42.73,
                  with_refunds: 42.73,
                },
                gross_arr_spread: {
                  without_refunds: 48.63,
                  with_refunds: 48.63,
                },
                net_total: {
                  without_refunds: 117.33,
                  with_refunds: 99.77,
                },
                gross_total: {
                  without_refunds: 134.61,
                  with_refunds: 114.88,
                },
                net_total_spread: {
                  without_refunds: 51.52,
                  with_refunds: 50.51,
                },
                gross_total_spread: {
                  without_refunds: 59.92,
                  with_refunds: 58.75,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 63.9,
                  with_refunds: 61.49,
                },
                gross_mrr: {
                  without_refunds: 72.77,
                  with_refunds: 70.05,
                },
                net_arr: {
                  without_refunds: 584.91,
                  with_refunds: 231.42,
                },
                gross_arr: {
                  without_refunds: 685.08,
                  with_refunds: 270.81,
                },
                net_arr_spread: {
                  without_refunds: 48.13,
                  with_refunds: 48.13,
                },
                gross_arr_spread: {
                  without_refunds: 54.01,
                  with_refunds: 54.01,
                },
                net_total: {
                  without_refunds: 177.16,
                  with_refunds: 140.08,
                },
                gross_total: {
                  without_refunds: 199.55,
                  with_refunds: 158.05,
                },
                net_total_spread: {
                  without_refunds: 54.24,
                  with_refunds: 53.27,
                },
                gross_total_spread: {
                  without_refunds: 61.25,
                  with_refunds: 60.16,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 60.93,
                  with_refunds: 58.79,
                },
                gross_mrr: {
                  without_refunds: 70.59,
                  with_refunds: 68.15,
                },
                net_arr: {
                  without_refunds: 558.43,
                  with_refunds: 244.29,
                },
                gross_arr: {
                  without_refunds: 666.84,
                  with_refunds: 292.77,
                },
                net_arr_spread: {
                  without_refunds: 44.1,
                  with_refunds: 44.1,
                },
                gross_arr_spread: {
                  without_refunds: 49.56,
                  with_refunds: 49.56,
                },
                net_total: {
                  without_refunds: 141.44,
                  with_refunds: 116.79,
                },
                gross_total: {
                  without_refunds: 160.61,
                  with_refunds: 132.98,
                },
                net_total_spread: {
                  without_refunds: 51.64,
                  with_refunds: 50.63,
                },
                gross_total_spread: {
                  without_refunds: 59.11,
                  with_refunds: 57.95,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.4865,
                  with_refunds: 0.4726,
                },
                gross_mrr: {
                  without_refunds: 0.5724,
                  with_refunds: 0.5562,
                },
                net_arr: {
                  without_refunds: 1.4867,
                  with_refunds: 1.2084,
                },
                gross_arr: {
                  without_refunds: 1.6864,
                  with_refunds: 1.3755,
                },
                net_arr_spread: {
                  without_refunds: 0.5508,
                  with_refunds: 0.5508,
                },
                gross_arr_spread: {
                  without_refunds: 0.6277,
                  with_refunds: 0.6277,
                },
                net_total: {
                  without_refunds: 0.8425,
                  with_refunds: 0.7356,
                },
                gross_total: {
                  without_refunds: 0.9692,
                  with_refunds: 0.8494,
                },
                net_total_spread: {
                  without_refunds: 0.5098,
                  with_refunds: 0.5001,
                },
                gross_total_spread: {
                  without_refunds: 0.5936,
                  with_refunds: 0.5823,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5419,
                  with_refunds: 0.5231,
                },
                gross_mrr: {
                  without_refunds: 0.6169,
                  with_refunds: 0.5958,
                },
                net_arr: {
                  without_refunds: 1.4945,
                  with_refunds: 1.1818,
                },
                gross_arr: {
                  without_refunds: 1.6718,
                  with_refunds: 1.3237,
                },
                net_arr_spread: {
                  without_refunds: 0.5464,
                  with_refunds: 0.5464,
                },
                gross_arr_spread: {
                  without_refunds: 0.6128,
                  with_refunds: 0.6128,
                },
                net_total: {
                  without_refunds: 1.0825,
                  with_refunds: 0.898,
                },
                gross_total: {
                  without_refunds: 1.2156,
                  with_refunds: 1.0103,
                },
                net_total_spread: {
                  without_refunds: 0.5479,
                  with_refunds: 0.5383,
                },
                gross_total_spread: {
                  without_refunds: 0.6191,
                  with_refunds: 0.6084,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5345,
                  with_refunds: 0.5168,
                },
                gross_mrr: {
                  without_refunds: 0.6218,
                  with_refunds: 0.6015,
                },
                net_arr: {
                  without_refunds: 1.5912,
                  with_refunds: 1.2847,
                },
                gross_arr: {
                  without_refunds: 1.7846,
                  with_refunds: 1.4438,
                },
                net_arr_spread: {
                  without_refunds: 0.5916,
                  with_refunds: 0.5916,
                },
                gross_arr_spread: {
                  without_refunds: 0.6649,
                  with_refunds: 0.6649,
                },
                net_total: {
                  without_refunds: 1.0243,
                  with_refunds: 0.8738,
                },
                gross_total: {
                  without_refunds: 1.161,
                  with_refunds: 0.9934,
                },
                net_total_spread: {
                  without_refunds: 0.5611,
                  with_refunds: 0.5505,
                },
                gross_total_spread: {
                  without_refunds: 0.6434,
                  with_refunds: 0.6311,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.9249,
                  with_refunds: 0.8966,
                },
                gross_mrr: {
                  without_refunds: 1.0818,
                  with_refunds: 1.0492,
                },
                net_arr: {
                  without_refunds: 7.9135,
                  with_refunds: 3.7245,
                },
                gross_arr: {
                  without_refunds: 9.5568,
                  with_refunds: 4.5414,
                },
                net_arr_spread: {
                  without_refunds: 0.6216,
                  with_refunds: 0.6216,
                },
                gross_arr_spread: {
                  without_refunds: 0.7092,
                  with_refunds: 0.7092,
                },
                net_total: {
                  without_refunds: 1.7374,
                  with_refunds: 1.5022,
                },
                gross_total: {
                  without_refunds: 1.9982,
                  with_refunds: 1.7329,
                },
                net_total_spread: {
                  without_refunds: 0.7988,
                  with_refunds: 0.7825,
                },
                gross_total_spread: {
                  without_refunds: 0.9299,
                  with_refunds: 0.9109,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.0194,
                  with_refunds: 0.9817,
                },
                gross_mrr: {
                  without_refunds: 1.1606,
                  with_refunds: 1.1182,
                },
                net_arr: {
                  without_refunds: 7.5064,
                  with_refunds: 3.2325,
                },
                gross_arr: {
                  without_refunds: 8.8485,
                  with_refunds: 3.8187,
                },
                net_arr_spread: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                gross_arr_spread: {
                  without_refunds: 0.6953,
                  with_refunds: 0.6953,
                },
                net_total: {
                  without_refunds: 2.3259,
                  with_refunds: 1.8955,
                },
                gross_total: {
                  without_refunds: 2.6235,
                  with_refunds: 2.1402,
                },
                net_total_spread: {
                  without_refunds: 0.7905,
                  with_refunds: 0.7753,
                },
                gross_total_spread: {
                  without_refunds: 0.8937,
                  with_refunds: 0.8766,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.0506,
                  with_refunds: 1.0142,
                },
                gross_mrr: {
                  without_refunds: 1.218,
                  with_refunds: 1.1763,
                },
                net_arr: {
                  without_refunds: 8.4209,
                  with_refunds: 3.8664,
                },
                gross_arr: {
                  without_refunds: 10.1248,
                  with_refunds: 4.6685,
                },
                net_arr_spread: {
                  without_refunds: 0.6649,
                  with_refunds: 0.6649,
                },
                gross_arr_spread: {
                  without_refunds: 0.7475,
                  with_refunds: 0.7475,
                },
                net_total: {
                  without_refunds: 2.1646,
                  with_refunds: 1.8296,
                },
                gross_total: {
                  without_refunds: 2.4634,
                  with_refunds: 2.0868,
                },
                net_total_spread: {
                  without_refunds: 0.8507,
                  with_refunds: 0.8335,
                },
                gross_total_spread: {
                  without_refunds: 0.9753,
                  with_refunds: 0.9555,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 5,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.2664,
                with_refunds: 0.2675,
              },
              arr_users: {
                without_refunds: 0.006,
                with_refunds: 0.0065,
              },
              arr_spread_users: {
                without_refunds: 0.9831,
                with_refunds: 0.9831,
              },
              total_users: {
                without_refunds: 0.1875,
                with_refunds: 0.1883,
              },
              total_spread_users: {
                without_refunds: 0.4634,
                with_refunds: 0.4641,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7925,
                with_refunds: 0.7933,
              },
              arr_users: {
                without_refunds: 1.0295,
                with_refunds: 0.9981,
              },
              arr_spread_users: {
                without_refunds: 1.0056,
                with_refunds: 1.0056,
              },
              total_users: {
                without_refunds: 0.7674,
                with_refunds: 0.768,
              },
              total_spread_users: {
                without_refunds: 0.9035,
                with_refunds: 0.9037,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.2329,
                with_refunds: 0.2341,
              },
              arr_users: {
                without_refunds: 0.0007,
                with_refunds: 0.0016,
              },
              arr_spread_users: {
                without_refunds: 0.9933,
                with_refunds: 0.9933,
              },
              total_users: {
                without_refunds: 0.1257,
                with_refunds: 0.1268,
              },
              total_spread_users: {
                without_refunds: 0.5463,
                with_refunds: 0.547,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6708,
                with_refunds: 0.6713,
              },
              arr_users: {
                without_refunds: 0.2747,
                with_refunds: 0.3285,
              },
              arr_spread_users: {
                without_refunds: 1.0006,
                with_refunds: 1.0006,
              },
              total_users: {
                without_refunds: 0.6174,
                with_refunds: 0.6202,
              },
              total_spread_users: {
                without_refunds: 0.8972,
                with_refunds: 0.897,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.2394,
                with_refunds: 0.2406,
              },
              arr_users: {
                without_refunds: 0.0053,
                with_refunds: 0.0059,
              },
              arr_spread_users: {
                without_refunds: 0.9881,
                with_refunds: 0.9881,
              },
              total_users: {
                without_refunds: 0.1511,
                with_refunds: 0.1518,
              },
              total_spread_users: {
                without_refunds: 0.4957,
                with_refunds: 0.4963,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7748,
                with_refunds: 0.7756,
              },
              arr_users: {
                without_refunds: 0.9308,
                with_refunds: 0.8974,
              },
              arr_spread_users: {
                without_refunds: 1.0049,
                with_refunds: 1.0049,
              },
              total_users: {
                without_refunds: 0.7467,
                with_refunds: 0.7472,
              },
              total_spread_users: {
                without_refunds: 0.9191,
                with_refunds: 0.919,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.4758,
                with_refunds: 0.4795,
              },
              gross_mrr: {
                without_refunds: 0.432,
                with_refunds: 0.4359,
              },
              net_arr: {
                without_refunds: 0.0151,
                with_refunds: 0.0125,
              },
              gross_arr: {
                without_refunds: 0.0161,
                with_refunds: 0.0132,
              },
              net_arr_spread: {
                without_refunds: 1.9949,
                with_refunds: 1.9949,
              },
              gross_arr_spread: {
                without_refunds: 1.9803,
                with_refunds: 1.9803,
              },
              net_total: {
                without_refunds: 0.1654,
                with_refunds: 0.1681,
              },
              gross_total: {
                without_refunds: 0.1476,
                with_refunds: 0.1501,
              },
              net_total_spread: {
                without_refunds: 0.7484,
                with_refunds: 0.7584,
              },
              gross_total_spread: {
                without_refunds: 0.7034,
                with_refunds: 0.7136,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7948,
                with_refunds: 0.7951,
              },
              gross_mrr: {
                without_refunds: 0.7906,
                with_refunds: 0.7909,
              },
              net_arr: {
                without_refunds: 1.0982,
                with_refunds: 1.1746,
              },
              gross_arr: {
                without_refunds: 1.1224,
                with_refunds: 1.3845,
              },
              net_arr_spread: {
                without_refunds: 1.0073,
                with_refunds: 1.0073,
              },
              gross_arr_spread: {
                without_refunds: 1.0078,
                with_refunds: 1.0078,
              },
              net_total: {
                without_refunds: 0.7937,
                with_refunds: 0.7922,
              },
              gross_total: {
                without_refunds: 0.7919,
                with_refunds: 0.7902,
              },
              net_total_spread: {
                without_refunds: 0.8875,
                with_refunds: 0.8883,
              },
              gross_total_spread: {
                without_refunds: 0.8855,
                with_refunds: 0.8864,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.5003,
                with_refunds: 0.5083,
              },
              gross_mrr: {
                without_refunds: 0.4876,
                with_refunds: 0.496,
              },
              net_arr: {
                without_refunds: 0.0018,
                with_refunds: 0.0019,
              },
              gross_arr: {
                without_refunds: 0.0018,
                with_refunds: 0.002,
              },
              net_arr_spread: {
                without_refunds: 2.164,
                with_refunds: 2.164,
              },
              gross_arr_spread: {
                without_refunds: 2.1583,
                with_refunds: 2.1583,
              },
              net_total: {
                without_refunds: 0.0736,
                with_refunds: 0.0787,
              },
              gross_total: {
                without_refunds: 0.0735,
                with_refunds: 0.0785,
              },
              net_total_spread: {
                without_refunds: 0.9797,
                with_refunds: 0.9966,
              },
              gross_total_spread: {
                without_refunds: 0.9626,
                with_refunds: 0.9799,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6675,
                with_refunds: 0.6693,
              },
              gross_mrr: {
                without_refunds: 0.6636,
                with_refunds: 0.6661,
              },
              net_arr: {
                without_refunds: 0.2637,
                with_refunds: 0.0593,
              },
              gross_arr: {
                without_refunds: 0.2903,
                with_refunds: 0.4987,
              },
              net_arr_spread: {
                without_refunds: 1.0006,
                with_refunds: 1.0006,
              },
              gross_arr_spread: {
                without_refunds: 1.0006,
                with_refunds: 1.0006,
              },
              net_total: {
                without_refunds: 0.5738,
                with_refunds: 0.5858,
              },
              gross_total: {
                without_refunds: 0.5632,
                with_refunds: 0.5767,
              },
              net_total_spread: {
                without_refunds: 0.8706,
                with_refunds: 0.8739,
              },
              gross_total_spread: {
                without_refunds: 0.8681,
                with_refunds: 0.8718,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.4328,
                with_refunds: 0.4391,
              },
              gross_mrr: {
                without_refunds: 0.41,
                with_refunds: 0.4163,
              },
              net_arr: {
                without_refunds: 0.0134,
                with_refunds: 0.011,
              },
              gross_arr: {
                without_refunds: 0.015,
                with_refunds: 0.0123,
              },
              net_arr_spread: {
                without_refunds: 2.0386,
                with_refunds: 2.0386,
              },
              gross_arr_spread: {
                without_refunds: 2.0247,
                with_refunds: 2.0247,
              },
              net_total: {
                without_refunds: 0.1,
                with_refunds: 0.1031,
              },
              gross_total: {
                without_refunds: 0.1001,
                with_refunds: 0.1027,
              },
              net_total_spread: {
                without_refunds: 0.7979,
                with_refunds: 0.8115,
              },
              gross_total_spread: {
                without_refunds: 0.7646,
                with_refunds: 0.7783,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7746,
                with_refunds: 0.7799,
              },
              gross_mrr: {
                without_refunds: 0.7718,
                with_refunds: 0.7768,
              },
              net_arr: {
                without_refunds: 0.9432,
                with_refunds: 1.1566,
              },
              gross_arr: {
                without_refunds: 0.9584,
                with_refunds: 1.3843,
              },
              net_arr_spread: {
                without_refunds: 1.006,
                with_refunds: 1.006,
              },
              gross_arr_spread: {
                without_refunds: 1.0068,
                with_refunds: 1.0068,
              },
              net_total: {
                without_refunds: 0.7692,
                with_refunds: 0.7719,
              },
              gross_total: {
                without_refunds: 0.7682,
                with_refunds: 0.7705,
              },
              net_total_spread: {
                without_refunds: 0.899,
                with_refunds: 0.9033,
              },
              gross_total_spread: {
                without_refunds: 0.8972,
                with_refunds: 0.9013,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 1198.7466,
              with_refunds: 1111.2359,
            },
            gross_mrr: {
              without_refunds: 1327.419,
              with_refunds: 1203.5282,
            },
            net_arr: {
              without_refunds: 6510.55,
              with_refunds: 5905.2,
            },
            gross_arr: {
              without_refunds: 6874.9,
              with_refunds: 6249.9,
            },
            net_arr_spread: {
              without_refunds: 492.1,
              with_refunds: 492.1,
            },
            gross_arr_spread: {
              without_refunds: 520.825,
              with_refunds: 520.825,
            },
            net_total: {
              without_refunds: 7709.2966,
              with_refunds: 7016.4359,
            },
            gross_total: {
              without_refunds: 8202.319,
              with_refunds: 7453.4282,
            },
            net_total_spread: {
              without_refunds: 1690.8466,
              with_refunds: 1603.3359,
            },
            gross_total_spread: {
              without_refunds: 1848.244,
              with_refunds: 1724.3532,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 3.15,
                with_refunds: 3.08,
              },
              gross_mrr: {
                without_refunds: 3.56,
                with_refunds: 3.49,
              },
              net_arr: {
                without_refunds: 0.7,
                with_refunds: 0.52,
              },
              gross_arr: {
                without_refunds: 0.88,
                with_refunds: 0.66,
              },
              net_arr_spread: {
                without_refunds: 8.06,
                with_refunds: 8.06,
              },
              gross_arr_spread: {
                without_refunds: 9.2,
                with_refunds: 9.2,
              },
              net_total: {
                without_refunds: 2.41,
                with_refunds: 2.31,
              },
              gross_total: {
                without_refunds: 2.76,
                with_refunds: 2.64,
              },
              net_total_spread: {
                without_refunds: 4.51,
                with_refunds: 4.46,
              },
              gross_total_spread: {
                without_refunds: 5.12,
                with_refunds: 5.07,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 2.97,
                with_refunds: 2.92,
              },
              gross_mrr: {
                without_refunds: 3.34,
                with_refunds: 3.28,
              },
              net_arr: {
                without_refunds: 0.07,
                with_refunds: 0.07,
              },
              gross_arr: {
                without_refunds: 0.09,
                with_refunds: 0.09,
              },
              net_arr_spread: {
                without_refunds: 8.93,
                with_refunds: 8.93,
              },
              gross_arr_spread: {
                without_refunds: 10.05,
                with_refunds: 10.05,
              },
              net_total: {
                without_refunds: 1.63,
                with_refunds: 1.61,
              },
              gross_total: {
                without_refunds: 1.84,
                with_refunds: 1.81,
              },
              net_total_spread: {
                without_refunds: 5.44,
                with_refunds: 5.4,
              },
              gross_total_spread: {
                without_refunds: 6.12,
                with_refunds: 6.08,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 2.88,
                with_refunds: 2.83,
              },
              gross_mrr: {
                without_refunds: 3.28,
                with_refunds: 3.22,
              },
              net_arr: {
                without_refunds: 0.62,
                with_refunds: 0.46,
              },
              gross_arr: {
                without_refunds: 0.79,
                with_refunds: 0.58,
              },
              net_arr_spread: {
                without_refunds: 8.24,
                with_refunds: 8.24,
              },
              gross_arr_spread: {
                without_refunds: 9.26,
                with_refunds: 9.26,
              },
              net_total: {
                without_refunds: 2.04,
                with_refunds: 1.94,
              },
              gross_total: {
                without_refunds: 2.34,
                with_refunds: 2.23,
              },
              net_total_spread: {
                without_refunds: 4.73,
                with_refunds: 4.69,
              },
              gross_total_spread: {
                without_refunds: 5.34,
                with_refunds: 5.3,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 33.94,
                with_refunds: 32.28,
              },
              gross_mrr: {
                without_refunds: 39.29,
                with_refunds: 37.4,
              },
              net_arr: {
                without_refunds: 92.52,
                with_refunds: 87.11,
              },
              gross_arr: {
                without_refunds: 99.48,
                with_refunds: 93.83,
              },
              net_arr_spread: {
                without_refunds: 40.58,
                with_refunds: 40.58,
              },
              gross_arr_spread: {
                without_refunds: 43.76,
                with_refunds: 43.76,
              },
              net_total: {
                without_refunds: 78.44,
                with_refunds: 73.19,
              },
              gross_total: {
                without_refunds: 85.24,
                with_refunds: 79.68,
              },
              net_total_spread: {
                without_refunds: 46.4,
                with_refunds: 44.77,
              },
              gross_total_spread: {
                without_refunds: 52.57,
                with_refunds: 50.7,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 27.54,
                with_refunds: 26.43,
              },
              gross_mrr: {
                without_refunds: 31.51,
                with_refunds: 30.27,
              },
              net_arr: {
                without_refunds: 67.5,
                with_refunds: 59.86,
              },
              gross_arr: {
                without_refunds: 75.13,
                with_refunds: 66.69,
              },
              net_arr_spread: {
                without_refunds: 29.16,
                with_refunds: 29.16,
              },
              gross_arr_spread: {
                without_refunds: 32.61,
                with_refunds: 32.61,
              },
              net_total: {
                without_refunds: 91.63,
                with_refunds: 79.48,
              },
              gross_total: {
                without_refunds: 102.16,
                with_refunds: 88.72,
              },
              net_total_spread: {
                without_refunds: 27.56,
                with_refunds: 26.97,
              },
              gross_total_spread: {
                without_refunds: 31.19,
                with_refunds: 30.53,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.86,
                with_refunds: 11.58,
              },
              gross_mrr: {
                without_refunds: 13.44,
                with_refunds: 13.13,
              },
              net_arr: {
                without_refunds: 98.99,
                with_refunds: 81.82,
              },
              gross_arr: {
                without_refunds: 122.91,
                with_refunds: 101.03,
              },
              net_arr_spread: {
                without_refunds: 8.18,
                with_refunds: 8.18,
              },
              gross_arr_spread: {
                without_refunds: 9.35,
                with_refunds: 9.35,
              },
              net_total: {
                without_refunds: 13.14,
                with_refunds: 12.55,
              },
              gross_total: {
                without_refunds: 15.08,
                with_refunds: 14.36,
              },
              net_total_spread: {
                without_refunds: 9.76,
                with_refunds: 9.63,
              },
              gross_total_spread: {
                without_refunds: 11.11,
                with_refunds: 10.97,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 12.75,
                with_refunds: 12.5,
              },
              gross_mrr: {
                without_refunds: 14.37,
                with_refunds: 14.1,
              },
              net_arr: {
                without_refunds: 79.45,
                with_refunds: 79.45,
              },
              gross_arr: {
                without_refunds: 94.72,
                with_refunds: 94.72,
              },
              net_arr_spread: {
                without_refunds: 8.99,
                with_refunds: 8.99,
              },
              gross_arr_spread: {
                without_refunds: 10.12,
                with_refunds: 10.12,
              },
              net_total: {
                without_refunds: 13.19,
                with_refunds: 12.92,
              },
              gross_total: {
                without_refunds: 14.94,
                with_refunds: 14.64,
              },
              net_total_spread: {
                without_refunds: 9.95,
                with_refunds: 9.88,
              },
              gross_total_spread: {
                without_refunds: 11.2,
                with_refunds: 11.12,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 12.05,
                with_refunds: 11.8,
              },
              gross_mrr: {
                without_refunds: 13.7,
                with_refunds: 13.42,
              },
              net_arr: {
                without_refunds: 97.87,
                with_refunds: 80.96,
              },
              gross_arr: {
                without_refunds: 121.62,
                with_refunds: 99.92,
              },
              net_arr_spread: {
                without_refunds: 8.33,
                with_refunds: 8.33,
              },
              gross_arr_spread: {
                without_refunds: 9.37,
                with_refunds: 9.37,
              },
              net_total: {
                without_refunds: 13.53,
                with_refunds: 12.89,
              },
              gross_total: {
                without_refunds: 15.59,
                with_refunds: 14.81,
              },
              net_total_spread: {
                without_refunds: 9.51,
                with_refunds: 9.43,
              },
              gross_total_spread: {
                without_refunds: 10.74,
                with_refunds: 10.65,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 33.94,
                with_refunds: 32.28,
              },
              gross_mrr: {
                without_refunds: 39.29,
                with_refunds: 37.4,
              },
              net_arr: {
                without_refunds: 92.52,
                with_refunds: 87.11,
              },
              gross_arr: {
                without_refunds: 99.48,
                with_refunds: 93.83,
              },
              net_arr_spread: {
                without_refunds: 40.58,
                with_refunds: 40.58,
              },
              gross_arr_spread: {
                without_refunds: 43.76,
                with_refunds: 43.76,
              },
              net_total: {
                without_refunds: 78.44,
                with_refunds: 73.19,
              },
              gross_total: {
                without_refunds: 85.24,
                with_refunds: 79.68,
              },
              net_total_spread: {
                without_refunds: 46.4,
                with_refunds: 44.77,
              },
              gross_total_spread: {
                without_refunds: 52.57,
                with_refunds: 50.7,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 27.54,
                with_refunds: 26.43,
              },
              gross_mrr: {
                without_refunds: 31.51,
                with_refunds: 30.27,
              },
              net_arr: {
                without_refunds: 67.5,
                with_refunds: 59.86,
              },
              gross_arr: {
                without_refunds: 75.13,
                with_refunds: 66.69,
              },
              net_arr_spread: {
                without_refunds: 29.16,
                with_refunds: 29.16,
              },
              gross_arr_spread: {
                without_refunds: 32.61,
                with_refunds: 32.61,
              },
              net_total: {
                without_refunds: 91.63,
                with_refunds: 79.48,
              },
              gross_total: {
                without_refunds: 102.16,
                with_refunds: 88.72,
              },
              net_total_spread: {
                without_refunds: 27.56,
                with_refunds: 26.97,
              },
              gross_total_spread: {
                without_refunds: 31.19,
                with_refunds: 30.53,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 34.04,
                  with_refunds: 33.12,
                },
                gross_mrr: {
                  without_refunds: 39.96,
                  with_refunds: 38.9,
                },
                net_arr: {
                  without_refunds: 98.37,
                  with_refunds: 80.36,
                },
                gross_arr: {
                  without_refunds: 111.76,
                  with_refunds: 91.63,
                },
                net_arr_spread: {
                  without_refunds: 43.87,
                  with_refunds: 43.87,
                },
                gross_arr_spread: {
                  without_refunds: 50.06,
                  with_refunds: 50.06,
                },
                net_total: {
                  without_refunds: 54.48,
                  with_refunds: 48.22,
                },
                gross_total: {
                  without_refunds: 62.8,
                  with_refunds: 55.77,
                },
                net_total_spread: {
                  without_refunds: 36.99,
                  with_refunds: 36.31,
                },
                gross_total_spread: {
                  without_refunds: 43.03,
                  with_refunds: 42.25,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 36.95,
                  with_refunds: 35.67,
                },
                gross_mrr: {
                  without_refunds: 42.13,
                  with_refunds: 40.7,
                },
                net_arr: {
                  without_refunds: 108.52,
                  with_refunds: 85.31,
                },
                gross_arr: {
                  without_refunds: 121.68,
                  with_refunds: 95.91,
                },
                net_arr_spread: {
                  without_refunds: 48.59,
                  with_refunds: 48.59,
                },
                gross_arr_spread: {
                  without_refunds: 54.67,
                  with_refunds: 54.67,
                },
                net_total: {
                  without_refunds: 70.96,
                  with_refunds: 59.35,
                },
                gross_total: {
                  without_refunds: 79.95,
                  with_refunds: 67.05,
                },
                net_total_spread: {
                  without_refunds: 41.99,
                  with_refunds: 41.22,
                },
                gross_total_spread: {
                  without_refunds: 47.58,
                  with_refunds: 46.72,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 33.9,
                  with_refunds: 32.89,
                },
                gross_mrr: {
                  without_refunds: 39.52,
                  with_refunds: 38.37,
                },
                net_arr: {
                  without_refunds: 100.46,
                  with_refunds: 81.68,
                },
                gross_arr: {
                  without_refunds: 112.6,
                  with_refunds: 91.75,
                },
                net_arr_spread: {
                  without_refunds: 44.82,
                  with_refunds: 44.82,
                },
                gross_arr_spread: {
                  without_refunds: 50.34,
                  with_refunds: 50.34,
                },
                net_total: {
                  without_refunds: 59.98,
                  with_refunds: 52.09,
                },
                gross_total: {
                  without_refunds: 68.19,
                  with_refunds: 59.4,
                },
                net_total_spread: {
                  without_refunds: 37.88,
                  with_refunds: 37.2,
                },
                gross_total_spread: {
                  without_refunds: 43.51,
                  with_refunds: 42.74,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 70.15,
                  with_refunds: 68.13,
                },
                gross_mrr: {
                  without_refunds: 81.71,
                  with_refunds: 79.4,
                },
                net_arr: {
                  without_refunds: 623.46,
                  with_refunds: 339.31,
                },
                gross_arr: {
                  without_refunds: 758.5,
                  with_refunds: 416.55,
                },
                net_arr_spread: {
                  without_refunds: 48.77,
                  with_refunds: 48.77,
                },
                gross_arr_spread: {
                  without_refunds: 55.72,
                  with_refunds: 55.72,
                },
                net_total: {
                  without_refunds: 118.7,
                  with_refunds: 105.32,
                },
                gross_total: {
                  without_refunds: 136.76,
                  with_refunds: 121.61,
                },
                net_total_spread: {
                  without_refunds: 61.08,
                  with_refunds: 59.9,
                },
                gross_total_spread: {
                  without_refunds: 70.97,
                  with_refunds: 69.6,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 77.37,
                  with_refunds: 74.65,
                },
                gross_mrr: {
                  without_refunds: 88.04,
                  with_refunds: 85.01,
                },
                net_arr: {
                  without_refunds: 636.12,
                  with_refunds: 329.16,
                },
                gross_arr: {
                  without_refunds: 762.12,
                  with_refunds: 396.66,
                },
                net_arr_spread: {
                  without_refunds: 53.66,
                  with_refunds: 53.66,
                },
                gross_arr_spread: {
                  without_refunds: 60.39,
                  with_refunds: 60.39,
                },
                net_total: {
                  without_refunds: 158.76,
                  with_refunds: 133.83,
                },
                gross_total: {
                  without_refunds: 179.64,
                  with_refunds: 151.68,
                },
                net_total_spread: {
                  without_refunds: 63.92,
                  with_refunds: 62.69,
                },
                gross_total_spread: {
                  without_refunds: 72.41,
                  with_refunds: 71.04,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 72.01,
                  with_refunds: 69.78,
                },
                gross_mrr: {
                  without_refunds: 83.48,
                  with_refunds: 80.92,
                },
                net_arr: {
                  without_refunds: 633.16,
                  with_refunds: 339.4,
                },
                gross_arr: {
                  without_refunds: 766.68,
                  with_refunds: 413.82,
                },
                net_arr_spread: {
                  without_refunds: 49.69,
                  with_refunds: 49.69,
                },
                gross_arr_spread: {
                  without_refunds: 55.84,
                  with_refunds: 55.84,
                },
                net_total: {
                  without_refunds: 133.11,
                  with_refunds: 116.23,
                },
                gross_total: {
                  without_refunds: 152.01,
                  with_refunds: 133,
                },
                net_total_spread: {
                  without_refunds: 60.59,
                  with_refunds: 59.47,
                },
                gross_total_spread: {
                  without_refunds: 69.54,
                  with_refunds: 68.25,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.5437,
                  with_refunds: 0.5286,
                },
                gross_mrr: {
                  without_refunds: 0.6376,
                  with_refunds: 0.6202,
                },
                net_arr: {
                  without_refunds: 1.6031,
                  with_refunds: 1.3142,
                },
                gross_arr: {
                  without_refunds: 1.8244,
                  with_refunds: 1.5004,
                },
                net_arr_spread: {
                  without_refunds: 0.7183,
                  with_refunds: 0.7183,
                },
                gross_arr_spread: {
                  without_refunds: 0.8204,
                  with_refunds: 0.8204,
                },
                net_total: {
                  without_refunds: 0.8802,
                  with_refunds: 0.7794,
                },
                gross_total: {
                  without_refunds: 1.0149,
                  with_refunds: 0.9015,
                },
                net_total_spread: {
                  without_refunds: 0.5955,
                  with_refunds: 0.5845,
                },
                gross_total_spread: {
                  without_refunds: 0.6925,
                  with_refunds: 0.6796,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6114,
                  with_refunds: 0.5905,
                },
                gross_mrr: {
                  without_refunds: 0.6968,
                  with_refunds: 0.6736,
                },
                net_arr: {
                  without_refunds: 1.742,
                  with_refunds: 1.3764,
                },
                gross_arr: {
                  without_refunds: 1.9533,
                  with_refunds: 1.547,
                },
                net_arr_spread: {
                  without_refunds: 0.7837,
                  with_refunds: 0.7837,
                },
                gross_arr_spread: {
                  without_refunds: 0.8815,
                  with_refunds: 0.8815,
                },
                net_total: {
                  without_refunds: 1.149,
                  with_refunds: 0.9656,
                },
                gross_total: {
                  without_refunds: 1.2944,
                  with_refunds: 1.0907,
                },
                net_total_spread: {
                  without_refunds: 0.6865,
                  with_refunds: 0.674,
                },
                gross_total_spread: {
                  without_refunds: 0.7776,
                  with_refunds: 0.7637,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5326,
                  with_refunds: 0.5163,
                },
                gross_mrr: {
                  without_refunds: 0.6199,
                  with_refunds: 0.6012,
                },
                net_arr: {
                  without_refunds: 1.6366,
                  with_refunds: 1.3352,
                },
                gross_arr: {
                  without_refunds: 1.837,
                  with_refunds: 1.5012,
                },
                net_arr_spread: {
                  without_refunds: 0.7336,
                  with_refunds: 0.7336,
                },
                gross_arr_spread: {
                  without_refunds: 0.8246,
                  with_refunds: 0.8246,
                },
                net_total: {
                  without_refunds: 0.965,
                  with_refunds: 0.8382,
                },
                gross_total: {
                  without_refunds: 1.0969,
                  with_refunds: 0.9554,
                },
                net_total_spread: {
                  without_refunds: 0.6051,
                  with_refunds: 0.5943,
                },
                gross_total_spread: {
                  without_refunds: 0.6944,
                  with_refunds: 0.6819,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.1246,
                  with_refunds: 1.0916,
                },
                gross_mrr: {
                  without_refunds: 1.3088,
                  with_refunds: 1.271,
                },
                net_arr: {
                  without_refunds: 10.2102,
                  with_refunds: 5.5336,
                },
                gross_arr: {
                  without_refunds: 12.4527,
                  with_refunds: 6.7964,
                },
                net_arr_spread: {
                  without_refunds: 0.7972,
                  with_refunds: 0.7972,
                },
                gross_arr_spread: {
                  without_refunds: 0.9117,
                  with_refunds: 0.9117,
                },
                net_total: {
                  without_refunds: 1.9282,
                  with_refunds: 1.7112,
                },
                gross_total: {
                  without_refunds: 2.2222,
                  with_refunds: 1.9756,
                },
                net_total_spread: {
                  without_refunds: 0.9823,
                  with_refunds: 0.9629,
                },
                gross_total_spread: {
                  without_refunds: 1.1407,
                  with_refunds: 1.1184,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.2757,
                  with_refunds: 1.2316,
                },
                gross_mrr: {
                  without_refunds: 1.4513,
                  with_refunds: 1.4021,
                },
                net_arr: {
                  without_refunds: 10.2627,
                  with_refunds: 5.4224,
                },
                gross_arr: {
                  without_refunds: 12.2925,
                  with_refunds: 6.5259,
                },
                net_arr_spread: {
                  without_refunds: 0.8665,
                  with_refunds: 0.8665,
                },
                gross_arr_spread: {
                  without_refunds: 0.9747,
                  with_refunds: 0.9747,
                },
                net_total: {
                  without_refunds: 2.5706,
                  with_refunds: 2.1777,
                },
                gross_total: {
                  without_refunds: 2.9084,
                  with_refunds: 2.4675,
                },
                net_total_spread: {
                  without_refunds: 1.0468,
                  with_refunds: 1.0269,
                },
                gross_total_spread: {
                  without_refunds: 1.1856,
                  with_refunds: 1.1634,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.1386,
                  with_refunds: 1.1028,
                },
                gross_mrr: {
                  without_refunds: 1.3183,
                  with_refunds: 1.2773,
                },
                net_arr: {
                  without_refunds: 10.3554,
                  with_refunds: 5.5166,
                },
                gross_arr: {
                  without_refunds: 12.5668,
                  with_refunds: 6.7243,
                },
                net_arr_spread: {
                  without_refunds: 0.8121,
                  with_refunds: 0.8121,
                },
                gross_arr_spread: {
                  without_refunds: 0.9131,
                  with_refunds: 0.9131,
                },
                net_total: {
                  without_refunds: 2.1556,
                  with_refunds: 1.8821,
                },
                gross_total: {
                  without_refunds: 2.4617,
                  with_refunds: 2.1527,
                },
                net_total_spread: {
                  without_refunds: 0.9647,
                  with_refunds: 0.9467,
                },
                gross_total_spread: {
                  without_refunds: 1.1061,
                  with_refunds: 1.0854,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 6,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.2176,
                with_refunds: 0.2185,
              },
              arr_users: {
                without_refunds: 0.0074,
                with_refunds: 0.008,
              },
              arr_spread_users: {
                without_refunds: 0.9874,
                with_refunds: 0.9874,
              },
              total_users: {
                without_refunds: 0.1611,
                with_refunds: 0.1616,
              },
              total_spread_users: {
                without_refunds: 0.4086,
                with_refunds: 0.4089,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7841,
                with_refunds: 0.7836,
              },
              arr_users: {
                without_refunds: 0.6053,
                with_refunds: 0.7698,
              },
              arr_spread_users: {
                without_refunds: 1.0071,
                with_refunds: 1.0071,
              },
              total_users: {
                without_refunds: 0.7754,
                with_refunds: 0.7742,
              },
              total_spread_users: {
                without_refunds: 0.8989,
                with_refunds: 0.898,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1747,
                with_refunds: 0.1753,
              },
              arr_users: {
                without_refunds: 0.002,
                with_refunds: 0.0029,
              },
              arr_spread_users: {
                without_refunds: 0.9918,
                with_refunds: 0.9918,
              },
              total_users: {
                without_refunds: 0.1064,
                with_refunds: 0.1072,
              },
              total_spread_users: {
                without_refunds: 0.4723,
                with_refunds: 0.4727,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6532,
                with_refunds: 0.6518,
              },
              arr_users: {
                without_refunds: 0.2219,
                with_refunds: 0.5182,
              },
              arr_spread_users: {
                without_refunds: 1.0024,
                with_refunds: 1.0024,
              },
              total_users: {
                without_refunds: 0.6578,
                with_refunds: 0.6565,
              },
              total_spread_users: {
                without_refunds: 0.8794,
                with_refunds: 0.8787,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.205,
                with_refunds: 0.206,
              },
              arr_users: {
                without_refunds: 0.0068,
                with_refunds: 0.0074,
              },
              arr_spread_users: {
                without_refunds: 0.9895,
                with_refunds: 0.9895,
              },
              total_users: {
                without_refunds: 0.1452,
                with_refunds: 0.1457,
              },
              total_spread_users: {
                without_refunds: 0.4231,
                with_refunds: 0.4234,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7829,
                with_refunds: 0.7824,
              },
              arr_users: {
                without_refunds: 0.5711,
                with_refunds: 0.7419,
              },
              arr_spread_users: {
                without_refunds: 1.0067,
                with_refunds: 1.0067,
              },
              total_users: {
                without_refunds: 0.7729,
                with_refunds: 0.7715,
              },
              total_spread_users: {
                without_refunds: 0.9112,
                with_refunds: 0.9102,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.3708,
                with_refunds: 0.3735,
              },
              gross_mrr: {
                without_refunds: 0.331,
                with_refunds: 0.3338,
              },
              net_arr: {
                without_refunds: 0.0194,
                with_refunds: 0.0169,
              },
              gross_arr: {
                without_refunds: 0.0196,
                with_refunds: 0.0172,
              },
              net_arr_spread: {
                without_refunds: 1.9453,
                with_refunds: 1.9453,
              },
              gross_arr_spread: {
                without_refunds: 1.9328,
                with_refunds: 1.9328,
              },
              net_total: {
                without_refunds: 0.1503,
                with_refunds: 0.1512,
              },
              gross_total: {
                without_refunds: 0.1326,
                with_refunds: 0.1334,
              },
              net_total_spread: {
                without_refunds: 0.629,
                with_refunds: 0.6379,
              },
              gross_total_spread: {
                without_refunds: 0.5855,
                with_refunds: 0.5946,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7812,
                with_refunds: 0.7839,
              },
              gross_mrr: {
                without_refunds: 0.7774,
                with_refunds: 0.7796,
              },
              net_arr: {
                without_refunds: 0.6177,
                with_refunds: 0.6847,
              },
              gross_arr: {
                without_refunds: 0.628,
                with_refunds: 0.696,
              },
              net_arr_spread: {
                without_refunds: 1.01,
                with_refunds: 1.01,
              },
              gross_arr_spread: {
                without_refunds: 1.01,
                with_refunds: 1.01,
              },
              net_total: {
                without_refunds: 0.7805,
                with_refunds: 0.7905,
              },
              gross_total: {
                without_refunds: 0.7799,
                with_refunds: 0.7892,
              },
              net_total_spread: {
                without_refunds: 0.8766,
                with_refunds: 0.8784,
              },
              gross_total_spread: {
                without_refunds: 0.8756,
                with_refunds: 0.8772,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.3216,
                with_refunds: 0.3309,
              },
              gross_mrr: {
                without_refunds: 0.3075,
                with_refunds: 0.3165,
              },
              net_arr: {
                without_refunds: 0.0047,
                with_refunds: 0.0041,
              },
              gross_arr: {
                without_refunds: 0.0052,
                with_refunds: 0.0046,
              },
              net_arr_spread: {
                without_refunds: 1.9648,
                with_refunds: 1.9648,
              },
              gross_arr_spread: {
                without_refunds: 1.9722,
                with_refunds: 1.9722,
              },
              net_total: {
                without_refunds: 0.0566,
                with_refunds: 0.0604,
              },
              gross_total: {
                without_refunds: 0.0569,
                with_refunds: 0.0607,
              },
              net_total_spread: {
                without_refunds: 0.7667,
                with_refunds: 0.7847,
              },
              gross_total_spread: {
                without_refunds: 0.7437,
                with_refunds: 0.7618,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.657,
                with_refunds: 0.6671,
              },
              gross_mrr: {
                without_refunds: 0.6555,
                with_refunds: 0.6646,
              },
              net_arr: {
                without_refunds: 0.2397,
                with_refunds: 0.302,
              },
              gross_arr: {
                without_refunds: 0.2244,
                with_refunds: 0.2962,
              },
              net_arr_spread: {
                without_refunds: 1.0028,
                with_refunds: 1.0028,
              },
              gross_arr_spread: {
                without_refunds: 1.0031,
                with_refunds: 1.0031,
              },
              net_total: {
                without_refunds: 0.6433,
                with_refunds: 0.667,
              },
              gross_total: {
                without_refunds: 0.6366,
                with_refunds: 0.6633,
              },
              net_total_spread: {
                without_refunds: 0.8479,
                with_refunds: 0.8531,
              },
              gross_total_spread: {
                without_refunds: 0.8471,
                with_refunds: 0.8519,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.3416,
                with_refunds: 0.3464,
              },
              gross_mrr: {
                without_refunds: 0.3114,
                with_refunds: 0.3161,
              },
              net_arr: {
                without_refunds: 0.018,
                with_refunds: 0.016,
              },
              gross_arr: {
                without_refunds: 0.0185,
                with_refunds: 0.0165,
              },
              net_arr_spread: {
                without_refunds: 1.965,
                with_refunds: 1.965,
              },
              gross_arr_spread: {
                without_refunds: 1.9532,
                with_refunds: 1.9532,
              },
              net_total: {
                without_refunds: 0.1196,
                with_refunds: 0.1217,
              },
              gross_total: {
                without_refunds: 0.1105,
                with_refunds: 0.1123,
              },
              net_total_spread: {
                without_refunds: 0.6428,
                with_refunds: 0.6542,
              },
              gross_total_spread: {
                without_refunds: 0.6046,
                with_refunds: 0.6159,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7809,
                with_refunds: 0.7866,
              },
              gross_mrr: {
                without_refunds: 0.7785,
                with_refunds: 0.7836,
              },
              net_arr: {
                without_refunds: 0.585,
                with_refunds: 0.6733,
              },
              gross_arr: {
                without_refunds: 0.5946,
                with_refunds: 0.6842,
              },
              net_arr_spread: {
                without_refunds: 1.0092,
                with_refunds: 1.0092,
              },
              gross_arr_spread: {
                without_refunds: 1.0094,
                with_refunds: 1.0094,
              },
              net_total: {
                without_refunds: 0.7764,
                with_refunds: 0.7924,
              },
              gross_total: {
                without_refunds: 0.7765,
                with_refunds: 0.7922,
              },
              net_total_spread: {
                without_refunds: 0.8887,
                with_refunds: 0.8924,
              },
              gross_total_spread: {
                without_refunds: 0.8879,
                with_refunds: 0.8913,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 1438.4959,
              with_refunds: 1333.4831,
            },
            gross_mrr: {
              without_refunds: 1592.9028,
              with_refunds: 1444.2339,
            },
            net_arr: {
              without_refunds: 7812.66,
              with_refunds: 7086.24,
            },
            gross_arr: {
              without_refunds: 8249.88,
              with_refunds: 7499.88,
            },
            net_arr_spread: {
              without_refunds: 590.52,
              with_refunds: 590.52,
            },
            gross_arr_spread: {
              without_refunds: 624.99,
              with_refunds: 624.99,
            },
            net_total: {
              without_refunds: 9251.1559,
              with_refunds: 8419.7231,
            },
            gross_total: {
              without_refunds: 9842.7828,
              with_refunds: 8944.1139,
            },
            net_total_spread: {
              without_refunds: 2029.0159,
              with_refunds: 1924.0031,
            },
            gross_total_spread: {
              without_refunds: 2217.8928,
              with_refunds: 2069.2239,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 2.54,
                with_refunds: 2.49,
              },
              gross_mrr: {
                without_refunds: 2.86,
                with_refunds: 2.81,
              },
              net_arr: {
                without_refunds: 0.88,
                with_refunds: 0.71,
              },
              gross_arr: {
                without_refunds: 1.13,
                with_refunds: 0.91,
              },
              net_arr_spread: {
                without_refunds: 7.78,
                with_refunds: 7.78,
              },
              gross_arr_spread: {
                without_refunds: 8.92,
                with_refunds: 8.92,
              },
              net_total: {
                without_refunds: 2.11,
                with_refunds: 2.02,
              },
              gross_total: {
                without_refunds: 2.41,
                with_refunds: 2.31,
              },
              net_total_spread: {
                without_refunds: 3.85,
                with_refunds: 3.81,
              },
              gross_total_spread: {
                without_refunds: 4.38,
                with_refunds: 4.33,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 2.16,
                with_refunds: 2.14,
              },
              gross_mrr: {
                without_refunds: 2.45,
                with_refunds: 2.42,
              },
              net_arr: {
                without_refunds: 0.24,
                with_refunds: 0.19,
              },
              gross_arr: {
                without_refunds: 0.3,
                with_refunds: 0.24,
              },
              net_arr_spread: {
                without_refunds: 8.18,
                with_refunds: 8.18,
              },
              gross_arr_spread: {
                without_refunds: 9.21,
                with_refunds: 9.21,
              },
              net_total: {
                without_refunds: 1.41,
                with_refunds: 1.37,
              },
              gross_total: {
                without_refunds: 1.6,
                with_refunds: 1.56,
              },
              net_total_spread: {
                without_refunds: 4.36,
                with_refunds: 4.35,
              },
              gross_total_spread: {
                without_refunds: 4.92,
                with_refunds: 4.91,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 2.41,
                with_refunds: 2.37,
              },
              gross_mrr: {
                without_refunds: 2.73,
                with_refunds: 2.69,
              },
              net_arr: {
                without_refunds: 0.81,
                with_refunds: 0.67,
              },
              gross_arr: {
                without_refunds: 1.04,
                with_refunds: 0.85,
              },
              net_arr_spread: {
                without_refunds: 7.87,
                with_refunds: 7.87,
              },
              gross_arr_spread: {
                without_refunds: 8.93,
                with_refunds: 8.93,
              },
              net_total: {
                without_refunds: 1.94,
                with_refunds: 1.87,
              },
              gross_total: {
                without_refunds: 2.23,
                with_refunds: 2.14,
              },
              net_total_spread: {
                without_refunds: 3.94,
                with_refunds: 3.91,
              },
              gross_total_spread: {
                without_refunds: 4.46,
                with_refunds: 4.43,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 36.32,
                with_refunds: 34.54,
              },
              gross_mrr: {
                without_refunds: 42.05,
                with_refunds: 40.02,
              },
              net_arr: {
                without_refunds: 92.7,
                with_refunds: 87.35,
              },
              gross_arr: {
                without_refunds: 99.67,
                with_refunds: 94.08,
              },
              net_arr_spread: {
                without_refunds: 47.74,
                with_refunds: 47.74,
              },
              gross_arr_spread: {
                without_refunds: 51.48,
                with_refunds: 51.48,
              },
              net_total: {
                without_refunds: 83.79,
                with_refunds: 78.12,
              },
              gross_total: {
                without_refunds: 91.05,
                with_refunds: 85.06,
              },
              net_total_spread: {
                without_refunds: 51.44,
                with_refunds: 49.63,
              },
              gross_total_spread: {
                without_refunds: 58.27,
                with_refunds: 56.21,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 29.49,
                with_refunds: 28.31,
              },
              gross_mrr: {
                without_refunds: 33.74,
                with_refunds: 32.41,
              },
              net_arr: {
                without_refunds: 67.94,
                with_refunds: 60.27,
              },
              gross_arr: {
                without_refunds: 75.62,
                with_refunds: 67.14,
              },
              net_arr_spread: {
                without_refunds: 34.45,
                with_refunds: 34.45,
              },
              gross_arr_spread: {
                without_refunds: 38.52,
                with_refunds: 38.52,
              },
              net_total: {
                without_refunds: 98.55,
                with_refunds: 85.39,
              },
              gross_total: {
                without_refunds: 109.88,
                with_refunds: 95.31,
              },
              net_total_spread: {
                without_refunds: 30.49,
                with_refunds: 29.83,
              },
              gross_total_spread: {
                without_refunds: 34.5,
                with_refunds: 33.76,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.74,
                with_refunds: 11.49,
              },
              gross_mrr: {
                without_refunds: 13.23,
                with_refunds: 12.95,
              },
              net_arr: {
                without_refunds: 78.14,
                with_refunds: 62.29,
              },
              gross_arr: {
                without_refunds: 98.34,
                with_refunds: 78.87,
              },
              net_arr_spread: {
                without_refunds: 7.88,
                with_refunds: 7.88,
              },
              gross_arr_spread: {
                without_refunds: 9.04,
                with_refunds: 9.04,
              },
              net_total: {
                without_refunds: 13.05,
                with_refunds: 12.57,
              },
              gross_total: {
                without_refunds: 14.93,
                with_refunds: 14.35,
              },
              net_total_spread: {
                without_refunds: 9.5,
                with_refunds: 9.38,
              },
              gross_total_spread: {
                without_refunds: 10.81,
                with_refunds: 10.68,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 12.39,
                with_refunds: 12.25,
              },
              gross_mrr: {
                without_refunds: 13.98,
                with_refunds: 13.81,
              },
              net_arr: {
                without_refunds: 48.14,
                with_refunds: 30.31,
              },
              gross_arr: {
                without_refunds: 59.26,
                with_refunds: 38.35,
              },
              net_arr_spread: {
                without_refunds: 8.24,
                with_refunds: 8.24,
              },
              gross_arr_spread: {
                without_refunds: 9.29,
                with_refunds: 9.29,
              },
              net_total: {
                without_refunds: 13.06,
                with_refunds: 12.71,
              },
              gross_total: {
                without_refunds: 14.82,
                with_refunds: 14.42,
              },
              net_total_spread: {
                without_refunds: 9.22,
                with_refunds: 9.19,
              },
              gross_total_spread: {
                without_refunds: 10.41,
                with_refunds: 10.36,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 11.81,
                with_refunds: 11.6,
              },
              gross_mrr: {
                without_refunds: 13.36,
                with_refunds: 13.12,
              },
              net_arr: {
                without_refunds: 76.61,
                with_refunds: 61.66,
              },
              gross_arr: {
                without_refunds: 96.21,
                with_refunds: 77.94,
              },
              net_arr_spread: {
                without_refunds: 7.95,
                with_refunds: 7.95,
              },
              gross_arr_spread: {
                without_refunds: 9.02,
                with_refunds: 9.02,
              },
              net_total: {
                without_refunds: 13.2,
                with_refunds: 12.76,
              },
              gross_total: {
                without_refunds: 15.14,
                with_refunds: 14.6,
              },
              net_total_spread: {
                without_refunds: 9.35,
                with_refunds: 9.27,
              },
              gross_total_spread: {
                without_refunds: 10.61,
                with_refunds: 10.52,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 36.32,
                with_refunds: 34.54,
              },
              gross_mrr: {
                without_refunds: 42.05,
                with_refunds: 40.02,
              },
              net_arr: {
                without_refunds: 92.7,
                with_refunds: 87.35,
              },
              gross_arr: {
                without_refunds: 99.67,
                with_refunds: 94.08,
              },
              net_arr_spread: {
                without_refunds: 47.74,
                with_refunds: 47.74,
              },
              gross_arr_spread: {
                without_refunds: 51.48,
                with_refunds: 51.48,
              },
              net_total: {
                without_refunds: 83.79,
                with_refunds: 78.12,
              },
              gross_total: {
                without_refunds: 91.05,
                with_refunds: 85.06,
              },
              net_total_spread: {
                without_refunds: 51.44,
                with_refunds: 49.63,
              },
              gross_total_spread: {
                without_refunds: 58.27,
                with_refunds: 56.21,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 29.49,
                with_refunds: 28.31,
              },
              gross_mrr: {
                without_refunds: 33.74,
                with_refunds: 32.41,
              },
              net_arr: {
                without_refunds: 67.94,
                with_refunds: 60.27,
              },
              gross_arr: {
                without_refunds: 75.62,
                with_refunds: 67.14,
              },
              net_arr_spread: {
                without_refunds: 34.45,
                with_refunds: 34.45,
              },
              gross_arr_spread: {
                without_refunds: 38.52,
                with_refunds: 38.52,
              },
              net_total: {
                without_refunds: 98.55,
                with_refunds: 85.39,
              },
              gross_total: {
                without_refunds: 109.88,
                with_refunds: 95.31,
              },
              net_total_spread: {
                without_refunds: 30.49,
                with_refunds: 29.83,
              },
              gross_total_spread: {
                without_refunds: 34.5,
                with_refunds: 33.76,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 36.5,
                  with_refunds: 35.59,
                },
                gross_mrr: {
                  without_refunds: 42.82,
                  with_refunds: 41.76,
                },
                net_arr: {
                  without_refunds: 94.63,
                  with_refunds: 79.33,
                },
                gross_arr: {
                  without_refunds: 108.12,
                  with_refunds: 90.92,
                },
                net_arr_spread: {
                  without_refunds: 49.89,
                  with_refunds: 49.89,
                },
                gross_arr_spread: {
                  without_refunds: 57.12,
                  with_refunds: 57.12,
                },
                net_total: {
                  without_refunds: 53.09,
                  with_refunds: 48.15,
                },
                gross_total: {
                  without_refunds: 61.46,
                  with_refunds: 55.9,
                },
                net_total_spread: {
                  without_refunds: 40.1,
                  with_refunds: 39.41,
                },
                gross_total_spread: {
                  without_refunds: 46.69,
                  with_refunds: 45.89,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 37.7,
                  with_refunds: 36.57,
                },
                gross_mrr: {
                  without_refunds: 43.36,
                  with_refunds: 42.07,
                },
                net_arr: {
                  without_refunds: 99.25,
                  with_refunds: 82.13,
                },
                gross_arr: {
                  without_refunds: 111.67,
                  with_refunds: 92.55,
                },
                net_arr_spread: {
                  without_refunds: 52.86,
                  with_refunds: 52.86,
                },
                gross_arr_spread: {
                  without_refunds: 59.46,
                  with_refunds: 59.46,
                },
                net_total: {
                  without_refunds: 63.14,
                  with_refunds: 55.5,
                },
                gross_total: {
                  without_refunds: 71.61,
                  with_refunds: 63.06,
                },
                net_total_spread: {
                  without_refunds: 43.51,
                  with_refunds: 42.78,
                },
                gross_total_spread: {
                  without_refunds: 49.56,
                  with_refunds: 48.72,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 36.3,
                  with_refunds: 35.39,
                },
                gross_mrr: {
                  without_refunds: 42.5,
                  with_refunds: 41.44,
                },
                net_arr: {
                  without_refunds: 95.61,
                  with_refunds: 79.94,
                },
                gross_arr: {
                  without_refunds: 108.21,
                  with_refunds: 90.72,
                },
                net_arr_spread: {
                  without_refunds: 50.45,
                  with_refunds: 50.45,
                },
                gross_arr_spread: {
                  without_refunds: 57.17,
                  with_refunds: 57.17,
                },
                net_total: {
                  without_refunds: 55.18,
                  with_refunds: 49.66,
                },
                gross_total: {
                  without_refunds: 63.45,
                  with_refunds: 57.25,
                },
                net_total_spread: {
                  without_refunds: 40.51,
                  with_refunds: 39.84,
                },
                gross_total_spread: {
                  without_refunds: 46.9,
                  with_refunds: 46.13,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 81.37,
                  with_refunds: 79.19,
                },
                gross_mrr: {
                  without_refunds: 94.47,
                  with_refunds: 91.96,
                },
                net_arr: {
                  without_refunds: 691.78,
                  with_refunds: 420.05,
                },
                gross_arr: {
                  without_refunds: 850.13,
                  with_refunds: 519.6,
                },
                net_arr_spread: {
                  without_refunds: 54.75,
                  with_refunds: 54.75,
                },
                gross_arr_spread: {
                  without_refunds: 62.78,
                  with_refunds: 62.78,
                },
                net_total: {
                  without_refunds: 123.41,
                  with_refunds: 112.64,
                },
                gross_total: {
                  without_refunds: 142.55,
                  with_refunds: 130.28,
                },
                net_total_spread: {
                  without_refunds: 70.28,
                  with_refunds: 68.98,
                },
                gross_total_spread: {
                  without_refunds: 81.62,
                  with_refunds: 80.12,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 87.5,
                  with_refunds: 84.93,
                },
                gross_mrr: {
                  without_refunds: 100.19,
                  with_refunds: 97.26,
                },
                net_arr: {
                  without_refunds: 673.04,
                  with_refunds: 373.99,
                },
                gross_arr: {
                  without_refunds: 821.43,
                  with_refunds: 457.14,
                },
                net_arr_spread: {
                  without_refunds: 57.5,
                  with_refunds: 57.5,
                },
                gross_arr_spread: {
                  without_refunds: 64.66,
                  with_refunds: 64.66,
                },
                net_total: {
                  without_refunds: 152.08,
                  with_refunds: 135.1,
                },
                gross_total: {
                  without_refunds: 173.1,
                  with_refunds: 153.9,
                },
                net_total_spread: {
                  without_refunds: 70.75,
                  with_refunds: 69.55,
                },
                gross_total_spread: {
                  without_refunds: 80.53,
                  with_refunds: 79.15,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 82.15,
                  with_refunds: 79.97,
                },
                gross_mrr: {
                  without_refunds: 95.29,
                  with_refunds: 92.77,
                },
                net_arr: {
                  without_refunds: 694.21,
                  with_refunds: 418.32,
                },
                gross_arr: {
                  without_refunds: 850.26,
                  with_refunds: 515.12,
                },
                net_arr_spread: {
                  without_refunds: 55.28,
                  with_refunds: 55.28,
                },
                gross_arr_spread: {
                  without_refunds: 62.7,
                  with_refunds: 62.7,
                },
                net_total: {
                  without_refunds: 129.58,
                  with_refunds: 117.55,
                },
                gross_total: {
                  without_refunds: 149.05,
                  with_refunds: 135.4,
                },
                net_total_spread: {
                  without_refunds: 69.78,
                  with_refunds: 68.58,
                },
                gross_total_spread: {
                  without_refunds: 80.62,
                  with_refunds: 79.23,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.5855,
                  with_refunds: 0.5703,
                },
                gross_mrr: {
                  without_refunds: 0.6859,
                  with_refunds: 0.6682,
                },
                net_arr: {
                  without_refunds: 1.5763,
                  with_refunds: 1.3239,
                },
                gross_arr: {
                  without_refunds: 1.8036,
                  with_refunds: 1.519,
                },
                net_arr_spread: {
                  without_refunds: 0.8343,
                  with_refunds: 0.8343,
                },
                gross_arr_spread: {
                  without_refunds: 0.9562,
                  with_refunds: 0.9562,
                },
                net_total: {
                  without_refunds: 0.8677,
                  with_refunds: 0.7863,
                },
                gross_total: {
                  without_refunds: 1.0045,
                  with_refunds: 0.9123,
                },
                net_total_spread: {
                  without_refunds: 0.6516,
                  with_refunds: 0.64,
                },
                gross_total_spread: {
                  without_refunds: 0.7581,
                  with_refunds: 0.7446,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7184,
                  with_refunds: 0.6969,
                },
                gross_mrr: {
                  without_refunds: 0.8276,
                  with_refunds: 0.803,
                },
                net_arr: {
                  without_refunds: 1.8748,
                  with_refunds: 1.5596,
                },
                gross_arr: {
                  without_refunds: 2.1105,
                  with_refunds: 1.7577,
                },
                net_arr_spread: {
                  without_refunds: 1.003,
                  with_refunds: 1.003,
                },
                gross_arr_spread: {
                  without_refunds: 1.1283,
                  with_refunds: 1.1283,
                },
                net_total: {
                  without_refunds: 1.1965,
                  with_refunds: 1.0554,
                },
                gross_total: {
                  without_refunds: 1.3584,
                  with_refunds: 1.2,
                },
                net_total_spread: {
                  without_refunds: 0.8275,
                  with_refunds: 0.8136,
                },
                gross_total_spread: {
                  without_refunds: 0.9434,
                  with_refunds: 0.9276,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5276,
                  with_refunds: 0.5137,
                },
                gross_mrr: {
                  without_refunds: 0.6161,
                  with_refunds: 0.6,
                },
                net_arr: {
                  without_refunds: 1.5764,
                  with_refunds: 1.3196,
                },
                gross_arr: {
                  without_refunds: 1.7807,
                  with_refunds: 1.4933,
                },
                net_arr_spread: {
                  without_refunds: 0.8346,
                  with_refunds: 0.8346,
                },
                gross_arr_spread: {
                  without_refunds: 0.9429,
                  with_refunds: 0.9429,
                },
                net_total: {
                  without_refunds: 0.8603,
                  with_refunds: 0.7705,
                },
                gross_total: {
                  without_refunds: 0.9859,
                  with_refunds: 0.885,
                },
                net_total_spread: {
                  without_refunds: 0.6167,
                  with_refunds: 0.6065,
                },
                gross_total_spread: {
                  without_refunds: 0.7114,
                  with_refunds: 0.6996,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.3101,
                  with_refunds: 1.2744,
                },
                gross_mrr: {
                  without_refunds: 1.5197,
                  with_refunds: 1.4786,
                },
                net_arr: {
                  without_refunds: 11.5706,
                  with_refunds: 6.9219,
                },
                gross_arr: {
                  without_refunds: 14.2405,
                  with_refunds: 8.5622,
                },
                net_arr_spread: {
                  without_refunds: 0.914,
                  with_refunds: 0.914,
                },
                gross_arr_spread: {
                  without_refunds: 1.0487,
                  with_refunds: 1.0487,
                },
                net_total: {
                  without_refunds: 2.0288,
                  with_refunds: 1.8491,
                },
                gross_total: {
                  without_refunds: 2.3429,
                  with_refunds: 2.1377,
                },
                net_total_spread: {
                  without_refunds: 1.1378,
                  with_refunds: 1.1164,
                },
                gross_total_spread: {
                  without_refunds: 1.3208,
                  with_refunds: 1.2961,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.6716,
                  with_refunds: 1.6231,
                },
                gross_mrr: {
                  without_refunds: 1.9169,
                  with_refunds: 1.8613,
                },
                net_arr: {
                  without_refunds: 13.0301,
                  with_refunds: 7.1951,
                },
                gross_arr: {
                  without_refunds: 15.9205,
                  with_refunds: 8.8073,
                },
                net_arr_spread: {
                  without_refunds: 1.0918,
                  with_refunds: 1.0918,
                },
                gross_arr_spread: {
                  without_refunds: 1.2279,
                  with_refunds: 1.2279,
                },
                net_total: {
                  without_refunds: 2.8881,
                  with_refunds: 2.5727,
                },
                gross_total: {
                  without_refunds: 3.2911,
                  with_refunds: 2.9336,
                },
                net_total_spread: {
                  without_refunds: 1.3485,
                  with_refunds: 1.3256,
                },
                gross_total_spread: {
                  without_refunds: 1.5364,
                  with_refunds: 1.5102,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.2106,
                  with_refunds: 1.1778,
                },
                gross_mrr: {
                  without_refunds: 1.4016,
                  with_refunds: 1.3638,
                },
                net_arr: {
                  without_refunds: 11.4665,
                  with_refunds: 6.7818,
                },
                gross_arr: {
                  without_refunds: 14.0486,
                  with_refunds: 8.3357,
                },
                net_arr_spread: {
                  without_refunds: 0.9123,
                  with_refunds: 0.9123,
                },
                gross_arr_spread: {
                  without_refunds: 1.0312,
                  with_refunds: 1.0312,
                },
                net_total: {
                  without_refunds: 2.0429,
                  with_refunds: 1.8453,
                },
                gross_total: {
                  without_refunds: 2.3449,
                  with_refunds: 2.1204,
                },
                net_total_spread: {
                  without_refunds: 1.0486,
                  with_refunds: 1.0308,
                },
                gross_total_spread: {
                  without_refunds: 1.2075,
                  with_refunds: 1.1868,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 7,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1918,
                with_refunds: 0.1924,
              },
              arr_users: {
                without_refunds: 0.0037,
                with_refunds: 0.004,
              },
              arr_spread_users: {
                without_refunds: 0.9888,
                with_refunds: 0.9888,
              },
              total_users: {
                without_refunds: 0.1446,
                with_refunds: 0.145,
              },
              total_spread_users: {
                without_refunds: 0.375,
                with_refunds: 0.3754,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.8169,
                with_refunds: 0.8164,
              },
              arr_users: {
                without_refunds: 0.1997,
                with_refunds: 0.2293,
              },
              arr_spread_users: {
                without_refunds: 1.0039,
                with_refunds: 1.0039,
              },
              total_users: {
                without_refunds: 0.7891,
                with_refunds: 0.7896,
              },
              total_spread_users: {
                without_refunds: 0.9222,
                with_refunds: 0.9223,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1507,
                with_refunds: 0.1511,
              },
              arr_users: {
                without_refunds: 0.0005,
                with_refunds: 0.0007,
              },
              arr_spread_users: {
                without_refunds: 0.9919,
                with_refunds: 0.9919,
              },
              total_users: {
                without_refunds: 0.0962,
                with_refunds: 0.0965,
              },
              total_spread_users: {
                without_refunds: 0.4282,
                with_refunds: 0.4285,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7339,
                with_refunds: 0.7344,
              },
              arr_users: {
                without_refunds: 0.0933,
                with_refunds: 0.1296,
              },
              arr_spread_users: {
                without_refunds: 1.0006,
                with_refunds: 1.0006,
              },
              total_users: {
                without_refunds: 0.7066,
                with_refunds: 0.7066,
              },
              total_spread_users: {
                without_refunds: 0.9206,
                with_refunds: 0.9204,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1918,
                with_refunds: 0.1924,
              },
              arr_users: {
                without_refunds: 0.0037,
                with_refunds: 0.004,
              },
              arr_spread_users: {
                without_refunds: 0.9888,
                with_refunds: 0.9888,
              },
              total_users: {
                without_refunds: 0.1446,
                with_refunds: 0.145,
              },
              total_spread_users: {
                without_refunds: 0.375,
                with_refunds: 0.3754,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.8169,
                with_refunds: 0.8164,
              },
              arr_users: {
                without_refunds: 0.1997,
                with_refunds: 0.2293,
              },
              arr_spread_users: {
                without_refunds: 1.0039,
                with_refunds: 1.0039,
              },
              total_users: {
                without_refunds: 0.7891,
                with_refunds: 0.7896,
              },
              total_spread_users: {
                without_refunds: 0.9222,
                with_refunds: 0.9223,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.3211,
                with_refunds: 0.3242,
              },
              gross_mrr: {
                without_refunds: 0.2824,
                with_refunds: 0.2854,
              },
              net_arr: {
                without_refunds: 0.0093,
                with_refunds: 0.0077,
              },
              gross_arr: {
                without_refunds: 0.0084,
                with_refunds: 0.0069,
              },
              net_arr_spread: {
                without_refunds: 1.8015,
                with_refunds: 1.8015,
              },
              gross_arr_spread: {
                without_refunds: 1.7832,
                with_refunds: 1.7832,
              },
              net_total: {
                without_refunds: 0.135,
                with_refunds: 0.1373,
              },
              gross_total: {
                without_refunds: 0.1159,
                with_refunds: 0.1181,
              },
              net_total_spread: {
                without_refunds: 0.5338,
                with_refunds: 0.5423,
              },
              gross_total_spread: {
                without_refunds: 0.4901,
                with_refunds: 0.4986,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.8198,
                with_refunds: 0.823,
              },
              gross_mrr: {
                without_refunds: 0.8184,
                with_refunds: 0.822,
              },
              net_arr: {
                without_refunds: 0.1821,
                with_refunds: 0.1835,
              },
              gross_arr: {
                without_refunds: 0.1809,
                with_refunds: 0.1773,
              },
              net_arr_spread: {
                without_refunds: 1.0056,
                with_refunds: 1.0056,
              },
              gross_arr_spread: {
                without_refunds: 1.005,
                with_refunds: 1.005,
              },
              net_total: {
                without_refunds: 0.7334,
                with_refunds: 0.7425,
              },
              gross_total: {
                without_refunds: 0.7242,
                with_refunds: 0.7343,
              },
              net_total_spread: {
                without_refunds: 0.9054,
                with_refunds: 0.9085,
              },
              gross_total_spread: {
                without_refunds: 0.9059,
                with_refunds: 0.9091,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2443,
                with_refunds: 0.2496,
              },
              gross_mrr: {
                without_refunds: 0.2285,
                with_refunds: 0.2339,
              },
              net_arr: {
                without_refunds: 0.0009,
                with_refunds: 0.0006,
              },
              gross_arr: {
                without_refunds: 0.0012,
                with_refunds: 0.0007,
              },
              net_arr_spread: {
                without_refunds: 1.7365,
                with_refunds: 1.7365,
              },
              gross_arr_spread: {
                without_refunds: 1.7371,
                with_refunds: 1.7371,
              },
              net_total: {
                without_refunds: 0.0516,
                with_refunds: 0.055,
              },
              gross_total: {
                without_refunds: 0.0515,
                with_refunds: 0.0547,
              },
              net_total_spread: {
                without_refunds: 0.5762,
                with_refunds: 0.5899,
              },
              gross_total_spread: {
                without_refunds: 0.5464,
                with_refunds: 0.56,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7425,
                with_refunds: 0.739,
              },
              gross_mrr: {
                without_refunds: 0.7421,
                with_refunds: 0.7397,
              },
              net_arr: {
                without_refunds: 0.0737,
                with_refunds: 0.053,
              },
              gross_arr: {
                without_refunds: 0.0845,
                with_refunds: 0.0596,
              },
              net_arr_spread: {
                without_refunds: 1.0006,
                with_refunds: 1.0006,
              },
              gross_arr_spread: {
                without_refunds: 1.0008,
                with_refunds: 1.0008,
              },
              net_total: {
                without_refunds: 0.6478,
                with_refunds: 0.6469,
              },
              gross_total: {
                without_refunds: 0.6436,
                with_refunds: 0.642,
              },
              net_total_spread: {
                without_refunds: 0.8985,
                with_refunds: 0.8982,
              },
              gross_total_spread: {
                without_refunds: 0.8983,
                with_refunds: 0.8983,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.3211,
                with_refunds: 0.3242,
              },
              gross_mrr: {
                without_refunds: 0.2824,
                with_refunds: 0.2854,
              },
              net_arr: {
                without_refunds: 0.0093,
                with_refunds: 0.0077,
              },
              gross_arr: {
                without_refunds: 0.0084,
                with_refunds: 0.0069,
              },
              net_arr_spread: {
                without_refunds: 1.8015,
                with_refunds: 1.8015,
              },
              gross_arr_spread: {
                without_refunds: 1.7832,
                with_refunds: 1.7832,
              },
              net_total: {
                without_refunds: 0.135,
                with_refunds: 0.1373,
              },
              gross_total: {
                without_refunds: 0.1159,
                with_refunds: 0.1181,
              },
              net_total_spread: {
                without_refunds: 0.5338,
                with_refunds: 0.5423,
              },
              gross_total_spread: {
                without_refunds: 0.4901,
                with_refunds: 0.4986,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.8198,
                with_refunds: 0.823,
              },
              gross_mrr: {
                without_refunds: 0.8184,
                with_refunds: 0.822,
              },
              net_arr: {
                without_refunds: 0.1821,
                with_refunds: 0.1835,
              },
              gross_arr: {
                without_refunds: 0.1809,
                with_refunds: 0.1773,
              },
              net_arr_spread: {
                without_refunds: 1.0056,
                with_refunds: 1.0056,
              },
              gross_arr_spread: {
                without_refunds: 1.005,
                with_refunds: 1.005,
              },
              net_total: {
                without_refunds: 0.7334,
                with_refunds: 0.7425,
              },
              gross_total: {
                without_refunds: 0.7242,
                with_refunds: 0.7343,
              },
              net_total_spread: {
                without_refunds: 0.9054,
                with_refunds: 0.9085,
              },
              gross_total_spread: {
                without_refunds: 0.9059,
                with_refunds: 0.9091,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 1678.2452,
              with_refunds: 1555.7303,
            },
            gross_mrr: {
              without_refunds: 1858.3866,
              with_refunds: 1684.9395,
            },
            net_arr: {
              without_refunds: 9114.77,
              with_refunds: 8267.28,
            },
            gross_arr: {
              without_refunds: 9624.86,
              with_refunds: 8749.86,
            },
            net_arr_spread: {
              without_refunds: 688.94,
              with_refunds: 688.94,
            },
            gross_arr_spread: {
              without_refunds: 729.155,
              with_refunds: 729.155,
            },
            net_total: {
              without_refunds: 10793.0152,
              with_refunds: 9823.0103,
            },
            gross_total: {
              without_refunds: 11483.2466,
              with_refunds: 10434.7995,
            },
            net_total_spread: {
              without_refunds: 2367.1852,
              with_refunds: 2244.6703,
            },
            gross_total_spread: {
              without_refunds: 2587.5416,
              with_refunds: 2414.0945,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 2.22,
                with_refunds: 2.18,
              },
              gross_mrr: {
                without_refunds: 2.5,
                with_refunds: 2.46,
              },
              net_arr: {
                without_refunds: 0.42,
                with_refunds: 0.32,
              },
              gross_arr: {
                without_refunds: 0.51,
                with_refunds: 0.39,
              },
              net_arr_spread: {
                without_refunds: 7.63,
                with_refunds: 7.63,
              },
              gross_arr_spread: {
                without_refunds: 8.81,
                with_refunds: 8.81,
              },
              net_total: {
                without_refunds: 1.77,
                with_refunds: 1.72,
              },
              gross_total: {
                without_refunds: 2.01,
                with_refunds: 1.94,
              },
              net_total_spread: {
                without_refunds: 3.47,
                with_refunds: 3.44,
              },
              gross_total_spread: {
                without_refunds: 3.96,
                with_refunds: 3.93,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 1.78,
                with_refunds: 1.75,
              },
              gross_mrr: {
                without_refunds: 2.04,
                with_refunds: 2.01,
              },
              net_arr: {
                without_refunds: 0.05,
                with_refunds: 0.03,
              },
              gross_arr: {
                without_refunds: 0.07,
                with_refunds: 0.04,
              },
              net_arr_spread: {
                without_refunds: 7.86,
                with_refunds: 7.86,
              },
              gross_arr_spread: {
                without_refunds: 8.88,
                with_refunds: 8.88,
              },
              net_total: {
                without_refunds: 1.15,
                with_refunds: 1.13,
              },
              gross_total: {
                without_refunds: 1.32,
                with_refunds: 1.29,
              },
              net_total_spread: {
                without_refunds: 3.79,
                with_refunds: 3.77,
              },
              gross_total_spread: {
                without_refunds: 4.3,
                with_refunds: 4.28,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 2.22,
                with_refunds: 2.18,
              },
              gross_mrr: {
                without_refunds: 2.5,
                with_refunds: 2.46,
              },
              net_arr: {
                without_refunds: 0.42,
                with_refunds: 0.32,
              },
              gross_arr: {
                without_refunds: 0.51,
                with_refunds: 0.39,
              },
              net_arr_spread: {
                without_refunds: 7.63,
                with_refunds: 7.63,
              },
              gross_arr_spread: {
                without_refunds: 8.81,
                with_refunds: 8.81,
              },
              net_total: {
                without_refunds: 1.77,
                with_refunds: 1.72,
              },
              gross_total: {
                without_refunds: 2.01,
                with_refunds: 1.94,
              },
              net_total_spread: {
                without_refunds: 3.47,
                with_refunds: 3.44,
              },
              gross_total_spread: {
                without_refunds: 3.96,
                with_refunds: 3.93,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 38.37,
                with_refunds: 36.5,
              },
              gross_mrr: {
                without_refunds: 44.43,
                with_refunds: 42.29,
              },
              net_arr: {
                without_refunds: 92.74,
                with_refunds: 87.4,
              },
              gross_arr: {
                without_refunds: 99.71,
                with_refunds: 94.14,
              },
              net_arr_spread: {
                without_refunds: 54.91,
                with_refunds: 54.91,
              },
              gross_arr_spread: {
                without_refunds: 59.21,
                with_refunds: 59.21,
              },
              net_total: {
                without_refunds: 88.63,
                with_refunds: 82.57,
              },
              gross_total: {
                without_refunds: 96.3,
                with_refunds: 89.9,
              },
              net_total_spread: {
                without_refunds: 56.01,
                with_refunds: 54.04,
              },
              gross_total_spread: {
                without_refunds: 63.45,
                with_refunds: 61.2,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 31.31,
                with_refunds: 30.05,
              },
              gross_mrr: {
                without_refunds: 35.83,
                with_refunds: 34.41,
              },
              net_arr: {
                without_refunds: 68.18,
                with_refunds: 60.49,
              },
              gross_arr: {
                without_refunds: 75.88,
                with_refunds: 67.39,
              },
              net_arr_spread: {
                without_refunds: 39.73,
                with_refunds: 39.73,
              },
              gross_arr_spread: {
                without_refunds: 44.43,
                with_refunds: 44.43,
              },
              net_total: {
                without_refunds: 105.43,
                with_refunds: 91.27,
              },
              gross_total: {
                without_refunds: 117.55,
                with_refunds: 101.88,
              },
              net_total_spread: {
                without_refunds: 33.08,
                with_refunds: 32.37,
              },
              gross_total_spread: {
                without_refunds: 37.44,
                with_refunds: 36.64,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.68,
                with_refunds: 11.34,
              },
              gross_mrr: {
                without_refunds: 13.19,
                with_refunds: 12.82,
              },
              net_arr: {
                without_refunds: 83.28,
                with_refunds: 55.01,
              },
              gross_arr: {
                without_refunds: 111.94,
                with_refunds: 72.68,
              },
              net_arr_spread: {
                without_refunds: 7.71,
                with_refunds: 7.71,
              },
              gross_arr_spread: {
                without_refunds: 8.91,
                with_refunds: 8.91,
              },
              net_total: {
                without_refunds: 12.29,
                with_refunds: 11.78,
              },
              gross_total: {
                without_refunds: 13.97,
                with_refunds: 13.38,
              },
              net_total_spread: {
                without_refunds: 9.37,
                with_refunds: 9.26,
              },
              gross_total_spread: {
                without_refunds: 10.7,
                with_refunds: 10.59,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 11.99,
                with_refunds: 11.54,
              },
              gross_mrr: {
                without_refunds: 13.71,
                with_refunds: 13.22,
              },
              net_arr: {
                without_refunds: 72.07,
                with_refunds: 41.37,
              },
              gross_arr: {
                without_refunds: 102.46,
                with_refunds: 58.82,
              },
              net_arr_spread: {
                without_refunds: 7.92,
                with_refunds: 7.92,
              },
              gross_arr_spread: {
                without_refunds: 8.95,
                with_refunds: 8.95,
              },
              net_total: {
                without_refunds: 12.17,
                with_refunds: 11.6,
              },
              gross_total: {
                without_refunds: 13.95,
                with_refunds: 13.31,
              },
              net_total_spread: {
                without_refunds: 8.86,
                with_refunds: 8.8,
              },
              gross_total_spread: {
                without_refunds: 10.04,
                with_refunds: 9.98,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 11.68,
                with_refunds: 11.34,
              },
              gross_mrr: {
                without_refunds: 13.19,
                with_refunds: 12.82,
              },
              net_arr: {
                without_refunds: 83.28,
                with_refunds: 55.01,
              },
              gross_arr: {
                without_refunds: 111.94,
                with_refunds: 72.68,
              },
              net_arr_spread: {
                without_refunds: 7.71,
                with_refunds: 7.71,
              },
              gross_arr_spread: {
                without_refunds: 8.91,
                with_refunds: 8.91,
              },
              net_total: {
                without_refunds: 12.29,
                with_refunds: 11.78,
              },
              gross_total: {
                without_refunds: 13.97,
                with_refunds: 13.38,
              },
              net_total_spread: {
                without_refunds: 9.37,
                with_refunds: 9.26,
              },
              gross_total_spread: {
                without_refunds: 10.7,
                with_refunds: 10.59,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 38.37,
                with_refunds: 36.5,
              },
              gross_mrr: {
                without_refunds: 44.43,
                with_refunds: 42.29,
              },
              net_arr: {
                without_refunds: 92.74,
                with_refunds: 87.4,
              },
              gross_arr: {
                without_refunds: 99.71,
                with_refunds: 94.14,
              },
              net_arr_spread: {
                without_refunds: 54.91,
                with_refunds: 54.91,
              },
              gross_arr_spread: {
                without_refunds: 59.21,
                with_refunds: 59.21,
              },
              net_total: {
                without_refunds: 88.63,
                with_refunds: 82.57,
              },
              gross_total: {
                without_refunds: 96.3,
                with_refunds: 89.9,
              },
              net_total_spread: {
                without_refunds: 56.01,
                with_refunds: 54.04,
              },
              gross_total_spread: {
                without_refunds: 63.45,
                with_refunds: 61.2,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 31.31,
                with_refunds: 30.05,
              },
              gross_mrr: {
                without_refunds: 35.83,
                with_refunds: 34.41,
              },
              net_arr: {
                without_refunds: 68.18,
                with_refunds: 60.49,
              },
              gross_arr: {
                without_refunds: 75.88,
                with_refunds: 67.39,
              },
              net_arr_spread: {
                without_refunds: 39.73,
                with_refunds: 39.73,
              },
              gross_arr_spread: {
                without_refunds: 44.43,
                with_refunds: 44.43,
              },
              net_total: {
                without_refunds: 105.43,
                with_refunds: 91.27,
              },
              gross_total: {
                without_refunds: 117.55,
                with_refunds: 101.88,
              },
              net_total_spread: {
                without_refunds: 33.08,
                with_refunds: 32.37,
              },
              gross_total_spread: {
                without_refunds: 37.44,
                with_refunds: 36.64,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 38.26,
                  with_refunds: 37.31,
                },
                gross_mrr: {
                  without_refunds: 44.99,
                  with_refunds: 43.89,
                },
                net_arr: {
                  without_refunds: 92.99,
                  with_refunds: 77.2,
                },
                gross_arr: {
                  without_refunds: 106.86,
                  with_refunds: 89.14,
                },
                net_arr_spread: {
                  without_refunds: 56.51,
                  with_refunds: 56.51,
                },
                gross_arr_spread: {
                  without_refunds: 65.18,
                  with_refunds: 65.18,
                },
                net_total: {
                  without_refunds: 52.92,
                  with_refunds: 48.1,
                },
                gross_total: {
                  without_refunds: 61.58,
                  with_refunds: 56.14,
                },
                net_total_spread: {
                  without_refunds: 42.73,
                  with_refunds: 41.99,
                },
                gross_total_spread: {
                  without_refunds: 49.96,
                  with_refunds: 49.1,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 36.58,
                  with_refunds: 35.52,
                },
                gross_mrr: {
                  without_refunds: 42.85,
                  with_refunds: 41.62,
                },
                net_arr: {
                  without_refunds: 95.64,
                  with_refunds: 78.45,
                },
                gross_arr: {
                  without_refunds: 107.73,
                  with_refunds: 88.61,
                },
                net_arr_spread: {
                  without_refunds: 58.85,
                  with_refunds: 58.85,
                },
                gross_arr_spread: {
                  without_refunds: 66.35,
                  with_refunds: 66.35,
                },
                net_total: {
                  without_refunds: 58.92,
                  with_refunds: 51.84,
                },
                gross_total: {
                  without_refunds: 67.41,
                  with_refunds: 59.51,
                },
                net_total_spread: {
                  without_refunds: 44.14,
                  with_refunds: 43.42,
                },
                gross_total_spread: {
                  without_refunds: 50.86,
                  with_refunds: 50.02,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 38.26,
                  with_refunds: 37.31,
                },
                gross_mrr: {
                  without_refunds: 44.99,
                  with_refunds: 43.89,
                },
                net_arr: {
                  without_refunds: 92.99,
                  with_refunds: 77.2,
                },
                gross_arr: {
                  without_refunds: 106.86,
                  with_refunds: 89.14,
                },
                net_arr_spread: {
                  without_refunds: 56.51,
                  with_refunds: 56.51,
                },
                gross_arr_spread: {
                  without_refunds: 65.18,
                  with_refunds: 65.18,
                },
                net_total: {
                  without_refunds: 52.92,
                  with_refunds: 48.1,
                },
                gross_total: {
                  without_refunds: 61.58,
                  with_refunds: 56.14,
                },
                net_total_spread: {
                  without_refunds: 42.73,
                  with_refunds: 41.99,
                },
                gross_total_spread: {
                  without_refunds: 49.96,
                  with_refunds: 49.1,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 91.9,
                  with_refunds: 89.37,
                },
                gross_mrr: {
                  without_refunds: 106.71,
                  with_refunds: 103.81,
                },
                net_arr: {
                  without_refunds: 781.49,
                  with_refunds: 460.97,
                },
                gross_arr: {
                  without_refunds: 976.61,
                  with_refunds: 581.5,
                },
                net_arr_spread: {
                  without_refunds: 61.19,
                  with_refunds: 61.19,
                },
                gross_arr_spread: {
                  without_refunds: 70.69,
                  with_refunds: 70.69,
                },
                net_total: {
                  without_refunds: 130.28,
                  with_refunds: 119.24,
                },
                gross_total: {
                  without_refunds: 150.98,
                  with_refunds: 138.41,
                },
                net_total_spread: {
                  without_refunds: 79.08,
                  with_refunds: 77.65,
                },
                gross_total_spread: {
                  without_refunds: 92.11,
                  with_refunds: 90.45,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 95,
                  with_refunds: 92.06,
                },
                gross_mrr: {
                  without_refunds: 110.22,
                  with_refunds: 106.87,
                },
                net_arr: {
                  without_refunds: 775.84,
                  with_refunds: 431.64,
                },
                gross_arr: {
                  without_refunds: 971.88,
                  with_refunds: 543.65,
                },
                net_arr_spread: {
                  without_refunds: 63.07,
                  with_refunds: 63.07,
                },
                gross_arr_spread: {
                  without_refunds: 71.11,
                  with_refunds: 71.11,
                },
                net_total: {
                  without_refunds: 150.77,
                  with_refunds: 134.48,
                },
                gross_total: {
                  without_refunds: 173.26,
                  with_refunds: 154.84,
                },
                net_total_spread: {
                  without_refunds: 76.83,
                  with_refunds: 75.59,
                },
                gross_total_spread: {
                  without_refunds: 88.31,
                  with_refunds: 86.87,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 91.9,
                  with_refunds: 89.37,
                },
                gross_mrr: {
                  without_refunds: 106.71,
                  with_refunds: 103.81,
                },
                net_arr: {
                  without_refunds: 781.49,
                  with_refunds: 460.97,
                },
                gross_arr: {
                  without_refunds: 976.61,
                  with_refunds: 581.5,
                },
                net_arr_spread: {
                  without_refunds: 61.19,
                  with_refunds: 61.19,
                },
                gross_arr_spread: {
                  without_refunds: 70.69,
                  with_refunds: 70.69,
                },
                net_total: {
                  without_refunds: 130.28,
                  with_refunds: 119.24,
                },
                gross_total: {
                  without_refunds: 150.98,
                  with_refunds: 138.41,
                },
                net_total_spread: {
                  without_refunds: 79.08,
                  with_refunds: 77.65,
                },
                gross_total_spread: {
                  without_refunds: 92.11,
                  with_refunds: 90.45,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6029,
                  with_refunds: 0.5874,
                },
                gross_mrr: {
                  without_refunds: 0.7084,
                  with_refunds: 0.6904,
                },
                net_arr: {
                  without_refunds: 1.5171,
                  with_refunds: 1.2619,
                },
                gross_arr: {
                  without_refunds: 1.7475,
                  with_refunds: 1.46,
                },
                net_arr_spread: {
                  without_refunds: 0.9267,
                  with_refunds: 0.9267,
                },
                gross_arr_spread: {
                  without_refunds: 1.0708,
                  with_refunds: 1.0708,
                },
                net_total: {
                  without_refunds: 0.8474,
                  with_refunds: 0.7693,
                },
                gross_total: {
                  without_refunds: 0.9866,
                  with_refunds: 0.8981,
                },
                net_total_spread: {
                  without_refunds: 0.6816,
                  with_refunds: 0.6695,
                },
                gross_total_spread: {
                  without_refunds: 0.7968,
                  with_refunds: 0.7827,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.661,
                  with_refunds: 0.6417,
                },
                gross_mrr: {
                  without_refunds: 0.7741,
                  with_refunds: 0.7517,
                },
                net_arr: {
                  without_refunds: 1.7398,
                  with_refunds: 1.4341,
                },
                gross_arr: {
                  without_refunds: 1.9609,
                  with_refunds: 1.62,
                },
                net_arr_spread: {
                  without_refunds: 1.0753,
                  with_refunds: 1.0753,
                },
                gross_arr_spread: {
                  without_refunds: 1.2124,
                  with_refunds: 1.2124,
                },
                net_total: {
                  without_refunds: 1.0689,
                  with_refunds: 0.9428,
                },
                gross_total: {
                  without_refunds: 1.2233,
                  with_refunds: 1.0821,
                },
                net_total_spread: {
                  without_refunds: 0.8015,
                  with_refunds: 0.7884,
                },
                gross_total_spread: {
                  without_refunds: 0.9232,
                  with_refunds: 0.908,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6029,
                  with_refunds: 0.5874,
                },
                gross_mrr: {
                  without_refunds: 0.7084,
                  with_refunds: 0.6904,
                },
                net_arr: {
                  without_refunds: 1.5171,
                  with_refunds: 1.2619,
                },
                gross_arr: {
                  without_refunds: 1.7475,
                  with_refunds: 1.46,
                },
                net_arr_spread: {
                  without_refunds: 0.9267,
                  with_refunds: 0.9267,
                },
                gross_arr_spread: {
                  without_refunds: 1.0708,
                  with_refunds: 1.0708,
                },
                net_total: {
                  without_refunds: 0.8474,
                  with_refunds: 0.7693,
                },
                gross_total: {
                  without_refunds: 0.9866,
                  with_refunds: 0.8981,
                },
                net_total_spread: {
                  without_refunds: 0.6816,
                  with_refunds: 0.6695,
                },
                gross_total_spread: {
                  without_refunds: 0.7968,
                  with_refunds: 0.7827,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.4544,
                  with_refunds: 1.4134,
                },
                gross_mrr: {
                  without_refunds: 1.6879,
                  with_refunds: 1.6409,
                },
                net_arr: {
                  without_refunds: 12.8638,
                  with_refunds: 7.4912,
                },
                gross_arr: {
                  without_refunds: 16.1443,
                  with_refunds: 9.4983,
                },
                net_arr_spread: {
                  without_refunds: 1.001,
                  with_refunds: 1.001,
                },
                gross_arr_spread: {
                  without_refunds: 1.1586,
                  with_refunds: 1.1586,
                },
                net_total: {
                  without_refunds: 2.0974,
                  with_refunds: 1.9169,
                },
                gross_total: {
                  without_refunds: 2.4313,
                  with_refunds: 2.2251,
                },
                net_total_spread: {
                  without_refunds: 1.2572,
                  with_refunds: 1.2337,
                },
                gross_total_spread: {
                  without_refunds: 1.4645,
                  with_refunds: 1.4374,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7143,
                  with_refunds: 1.6623,
                },
                gross_mrr: {
                  without_refunds: 1.9889,
                  with_refunds: 1.9294,
                },
                net_arr: {
                  without_refunds: 14.4146,
                  with_refunds: 8.0223,
                },
                gross_arr: {
                  without_refunds: 18.0745,
                  with_refunds: 10.1398,
                },
                net_arr_spread: {
                  without_refunds: 1.1531,
                  with_refunds: 1.1531,
                },
                gross_arr_spread: {
                  without_refunds: 1.3002,
                  with_refunds: 1.3002,
                },
                net_total: {
                  without_refunds: 2.7405,
                  with_refunds: 2.4491,
                },
                gross_total: {
                  without_refunds: 3.1502,
                  with_refunds: 2.8197,
                },
                net_total_spread: {
                  without_refunds: 1.3901,
                  with_refunds: 1.3677,
                },
                gross_total_spread: {
                  without_refunds: 1.5976,
                  with_refunds: 1.5717,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.4544,
                  with_refunds: 1.4134,
                },
                gross_mrr: {
                  without_refunds: 1.6879,
                  with_refunds: 1.6409,
                },
                net_arr: {
                  without_refunds: 12.8638,
                  with_refunds: 7.4912,
                },
                gross_arr: {
                  without_refunds: 16.1443,
                  with_refunds: 9.4983,
                },
                net_arr_spread: {
                  without_refunds: 1.001,
                  with_refunds: 1.001,
                },
                gross_arr_spread: {
                  without_refunds: 1.1586,
                  with_refunds: 1.1586,
                },
                net_total: {
                  without_refunds: 2.0974,
                  with_refunds: 1.9169,
                },
                gross_total: {
                  without_refunds: 2.4313,
                  with_refunds: 2.2251,
                },
                net_total_spread: {
                  without_refunds: 1.2572,
                  with_refunds: 1.2337,
                },
                gross_total_spread: {
                  without_refunds: 1.4645,
                  with_refunds: 1.4374,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 8,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1609,
                with_refunds: 0.1614,
              },
              arr_users: {
                without_refunds: 0.003,
                with_refunds: 0.003,
              },
              arr_spread_users: {
                without_refunds: 0.9923,
                with_refunds: 0.9923,
              },
              total_users: {
                without_refunds: 0.1251,
                with_refunds: 0.1254,
              },
              total_spread_users: {
                without_refunds: 0.3376,
                with_refunds: 0.3379,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7755,
                with_refunds: 0.7752,
              },
              arr_users: {
                without_refunds: 0.2677,
                with_refunds: 0.2653,
              },
              arr_spread_users: {
                without_refunds: 1.0033,
                with_refunds: 1.0033,
              },
              total_users: {
                without_refunds: 0.7468,
                with_refunds: 0.7463,
              },
              total_spread_users: {
                without_refunds: 0.9133,
                with_refunds: 0.913,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1267,
                with_refunds: 0.127,
              },
              arr_users: {
                without_refunds: 0.0012,
                with_refunds: 0.0012,
              },
              arr_spread_users: {
                without_refunds: 0.9938,
                with_refunds: 0.9938,
              },
              total_users: {
                without_refunds: 0.0872,
                with_refunds: 0.0874,
              },
              total_spread_users: {
                without_refunds: 0.3804,
                with_refunds: 0.3806,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6922,
                with_refunds: 0.6929,
              },
              arr_users: {
                without_refunds: 0.2012,
                with_refunds: 0.2012,
              },
              arr_spread_users: {
                without_refunds: 1.0014,
                with_refunds: 1.0014,
              },
              total_users: {
                without_refunds: 0.6704,
                with_refunds: 0.6704,
              },
              total_spread_users: {
                without_refunds: 0.9104,
                with_refunds: 0.9105,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1609,
                with_refunds: 0.1614,
              },
              arr_users: {
                without_refunds: 0.003,
                with_refunds: 0.003,
              },
              arr_spread_users: {
                without_refunds: 0.9923,
                with_refunds: 0.9923,
              },
              total_users: {
                without_refunds: 0.1251,
                with_refunds: 0.1254,
              },
              total_spread_users: {
                without_refunds: 0.3376,
                with_refunds: 0.3379,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7755,
                with_refunds: 0.7752,
              },
              arr_users: {
                without_refunds: 0.2677,
                with_refunds: 0.2653,
              },
              arr_spread_users: {
                without_refunds: 1.0033,
                with_refunds: 1.0033,
              },
              total_users: {
                without_refunds: 0.7468,
                with_refunds: 0.7463,
              },
              total_spread_users: {
                without_refunds: 0.9133,
                with_refunds: 0.913,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2689,
                with_refunds: 0.2727,
              },
              gross_mrr: {
                without_refunds: 0.2337,
                with_refunds: 0.2371,
              },
              net_arr: {
                without_refunds: 0.008,
                with_refunds: 0.0084,
              },
              gross_arr: {
                without_refunds: 0.0074,
                with_refunds: 0.0077,
              },
              net_arr_spread: {
                without_refunds: 1.8948,
                with_refunds: 1.8948,
              },
              gross_arr_spread: {
                without_refunds: 1.8708,
                with_refunds: 1.8708,
              },
              net_total: {
                without_refunds: 0.1236,
                with_refunds: 0.1282,
              },
              gross_total: {
                without_refunds: 0.105,
                with_refunds: 0.1091,
              },
              net_total_spread: {
                without_refunds: 0.4797,
                with_refunds: 0.4888,
              },
              gross_total_spread: {
                without_refunds: 0.4372,
                with_refunds: 0.4459,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7769,
                with_refunds: 0.7807,
              },
              gross_mrr: {
                without_refunds: 0.7764,
                with_refunds: 0.7803,
              },
              net_arr: {
                without_refunds: 0.2736,
                with_refunds: 0.3142,
              },
              gross_arr: {
                without_refunds: 0.2812,
                with_refunds: 0.3226,
              },
              net_arr_spread: {
                without_refunds: 1.005,
                with_refunds: 1.005,
              },
              gross_arr_spread: {
                without_refunds: 1.0046,
                with_refunds: 1.0046,
              },
              net_total: {
                without_refunds: 0.7398,
                with_refunds: 0.7527,
              },
              gross_total: {
                without_refunds: 0.7398,
                with_refunds: 0.7539,
              },
              net_total_spread: {
                without_refunds: 0.8907,
                with_refunds: 0.8944,
              },
              gross_total_spread: {
                without_refunds: 0.8917,
                with_refunds: 0.8953,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2006,
                with_refunds: 0.2037,
              },
              gross_mrr: {
                without_refunds: 0.184,
                with_refunds: 0.187,
              },
              net_arr: {
                without_refunds: 0.0035,
                with_refunds: 0.0037,
              },
              gross_arr: {
                without_refunds: 0.0038,
                with_refunds: 0.0038,
              },
              net_arr_spread: {
                without_refunds: 1.9441,
                with_refunds: 1.9441,
              },
              gross_arr_spread: {
                without_refunds: 1.9246,
                with_refunds: 1.9246,
              },
              net_total: {
                without_refunds: 0.058,
                with_refunds: 0.0613,
              },
              gross_total: {
                without_refunds: 0.0566,
                with_refunds: 0.0597,
              },
              net_total_spread: {
                without_refunds: 0.5189,
                with_refunds: 0.5297,
              },
              gross_total_spread: {
                without_refunds: 0.4819,
                with_refunds: 0.4927,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6963,
                with_refunds: 0.6945,
              },
              gross_mrr: {
                without_refunds: 0.6957,
                with_refunds: 0.6939,
              },
              net_arr: {
                without_refunds: 0.2063,
                with_refunds: 0.2378,
              },
              gross_arr: {
                without_refunds: 0.2064,
                with_refunds: 0.2378,
              },
              net_arr_spread: {
                without_refunds: 1.0018,
                with_refunds: 1.0018,
              },
              gross_arr_spread: {
                without_refunds: 1.002,
                with_refunds: 1.002,
              },
              net_total: {
                without_refunds: 0.6684,
                with_refunds: 0.6741,
              },
              gross_total: {
                without_refunds: 0.6666,
                with_refunds: 0.6734,
              },
              net_total_spread: {
                without_refunds: 0.8824,
                with_refunds: 0.8823,
              },
              gross_total_spread: {
                without_refunds: 0.8813,
                with_refunds: 0.8813,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2689,
                with_refunds: 0.2727,
              },
              gross_mrr: {
                without_refunds: 0.2337,
                with_refunds: 0.2371,
              },
              net_arr: {
                without_refunds: 0.008,
                with_refunds: 0.0084,
              },
              gross_arr: {
                without_refunds: 0.0074,
                with_refunds: 0.0077,
              },
              net_arr_spread: {
                without_refunds: 1.8948,
                with_refunds: 1.8948,
              },
              gross_arr_spread: {
                without_refunds: 1.8708,
                with_refunds: 1.8708,
              },
              net_total: {
                without_refunds: 0.1236,
                with_refunds: 0.1282,
              },
              gross_total: {
                without_refunds: 0.105,
                with_refunds: 0.1091,
              },
              net_total_spread: {
                without_refunds: 0.4797,
                with_refunds: 0.4888,
              },
              gross_total_spread: {
                without_refunds: 0.4372,
                with_refunds: 0.4459,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7769,
                with_refunds: 0.7807,
              },
              gross_mrr: {
                without_refunds: 0.7764,
                with_refunds: 0.7803,
              },
              net_arr: {
                without_refunds: 0.2736,
                with_refunds: 0.3142,
              },
              gross_arr: {
                without_refunds: 0.2812,
                with_refunds: 0.3226,
              },
              net_arr_spread: {
                without_refunds: 1.005,
                with_refunds: 1.005,
              },
              gross_arr_spread: {
                without_refunds: 1.0046,
                with_refunds: 1.0046,
              },
              net_total: {
                without_refunds: 0.7398,
                with_refunds: 0.7527,
              },
              gross_total: {
                without_refunds: 0.7398,
                with_refunds: 0.7539,
              },
              net_total_spread: {
                without_refunds: 0.8907,
                with_refunds: 0.8944,
              },
              gross_total_spread: {
                without_refunds: 0.8917,
                with_refunds: 0.8953,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 1917.9945,
              with_refunds: 1777.9775,
            },
            gross_mrr: {
              without_refunds: 2123.8704,
              with_refunds: 1925.6452,
            },
            net_arr: {
              without_refunds: 10416.88,
              with_refunds: 9448.32,
            },
            gross_arr: {
              without_refunds: 10999.84,
              with_refunds: 9999.84,
            },
            net_arr_spread: {
              without_refunds: 787.36,
              with_refunds: 787.36,
            },
            gross_arr_spread: {
              without_refunds: 833.32,
              with_refunds: 833.32,
            },
            net_total: {
              without_refunds: 12334.8745,
              with_refunds: 11226.2975,
            },
            gross_total: {
              without_refunds: 13123.7104,
              with_refunds: 11925.4852,
            },
            net_total_spread: {
              without_refunds: 2705.3545,
              with_refunds: 2565.3375,
            },
            gross_total_spread: {
              without_refunds: 2957.1904,
              with_refunds: 2758.9652,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 1.85,
                with_refunds: 1.83,
              },
              gross_mrr: {
                without_refunds: 2.08,
                with_refunds: 2.06,
              },
              net_arr: {
                without_refunds: 0.35,
                with_refunds: 0.35,
              },
              gross_arr: {
                without_refunds: 0.44,
                with_refunds: 0.43,
              },
              net_arr_spread: {
                without_refunds: 7.48,
                with_refunds: 7.48,
              },
              gross_arr_spread: {
                without_refunds: 8.67,
                with_refunds: 8.67,
              },
              net_total: {
                without_refunds: 1.52,
                with_refunds: 1.5,
              },
              gross_total: {
                without_refunds: 1.71,
                with_refunds: 1.7,
              },
              net_total_spread: {
                without_refunds: 3.06,
                with_refunds: 3.04,
              },
              gross_total_spread: {
                without_refunds: 3.49,
                with_refunds: 3.47,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 1.47,
                with_refunds: 1.45,
              },
              gross_mrr: {
                without_refunds: 1.67,
                with_refunds: 1.66,
              },
              net_arr: {
                without_refunds: 0.14,
                with_refunds: 0.13,
              },
              gross_arr: {
                without_refunds: 0.17,
                with_refunds: 0.16,
              },
              net_arr_spread: {
                without_refunds: 7.66,
                with_refunds: 7.66,
              },
              gross_arr_spread: {
                without_refunds: 8.61,
                with_refunds: 8.61,
              },
              net_total: {
                without_refunds: 1.05,
                with_refunds: 1.04,
              },
              gross_total: {
                without_refunds: 1.2,
                with_refunds: 1.19,
              },
              net_total_spread: {
                without_refunds: 3.29,
                with_refunds: 3.28,
              },
              gross_total_spread: {
                without_refunds: 3.71,
                with_refunds: 3.7,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 1.85,
                with_refunds: 1.83,
              },
              gross_mrr: {
                without_refunds: 2.08,
                with_refunds: 2.06,
              },
              net_arr: {
                without_refunds: 0.35,
                with_refunds: 0.35,
              },
              gross_arr: {
                without_refunds: 0.44,
                with_refunds: 0.43,
              },
              net_arr_spread: {
                without_refunds: 7.48,
                with_refunds: 7.48,
              },
              gross_arr_spread: {
                without_refunds: 8.67,
                with_refunds: 8.67,
              },
              net_total: {
                without_refunds: 1.52,
                with_refunds: 1.5,
              },
              gross_total: {
                without_refunds: 1.71,
                with_refunds: 1.7,
              },
              net_total_spread: {
                without_refunds: 3.06,
                with_refunds: 3.04,
              },
              gross_total_spread: {
                without_refunds: 3.49,
                with_refunds: 3.47,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 40.1,
                with_refunds: 38.14,
              },
              gross_mrr: {
                without_refunds: 46.42,
                with_refunds: 44.19,
              },
              net_arr: {
                without_refunds: 92.84,
                with_refunds: 87.5,
              },
              gross_arr: {
                without_refunds: 99.83,
                with_refunds: 94.25,
              },
              net_arr_spread: {
                without_refunds: 62.09,
                with_refunds: 62.09,
              },
              gross_arr_spread: {
                without_refunds: 66.96,
                with_refunds: 66.96,
              },
              net_total: {
                without_refunds: 93.01,
                with_refunds: 86.6,
              },
              gross_total: {
                without_refunds: 101.07,
                with_refunds: 94.28,
              },
              net_total_spread: {
                without_refunds: 60.06,
                with_refunds: 57.95,
              },
              gross_total_spread: {
                without_refunds: 68.04,
                with_refunds: 65.63,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 32.85,
                with_refunds: 31.52,
              },
              gross_mrr: {
                without_refunds: 37.59,
                with_refunds: 36.09,
              },
              net_arr: {
                without_refunds: 68.38,
                with_refunds: 60.65,
              },
              gross_arr: {
                without_refunds: 76.1,
                with_refunds: 67.57,
              },
              net_arr_spread: {
                without_refunds: 45.03,
                with_refunds: 45.03,
              },
              gross_arr_spread: {
                without_refunds: 50.36,
                with_refunds: 50.36,
              },
              net_total: {
                without_refunds: 111.39,
                with_refunds: 96.35,
              },
              gross_total: {
                without_refunds: 124.2,
                with_refunds: 107.55,
              },
              net_total_spread: {
                without_refunds: 35.42,
                with_refunds: 34.66,
              },
              gross_total_spread: {
                without_refunds: 40.08,
                with_refunds: 39.22,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.59,
                with_refunds: 11.45,
              },
              gross_mrr: {
                without_refunds: 13.07,
                with_refunds: 12.91,
              },
              net_arr: {
                without_refunds: 38.23,
                with_refunds: 37.19,
              },
              gross_arr: {
                without_refunds: 47.56,
                with_refunds: 45.85,
              },
              net_arr_spread: {
                without_refunds: 7.54,
                with_refunds: 7.54,
              },
              gross_arr_spread: {
                without_refunds: 8.74,
                with_refunds: 8.74,
              },
              net_total: {
                without_refunds: 12.08,
                with_refunds: 11.93,
              },
              gross_total: {
                without_refunds: 13.7,
                with_refunds: 13.54,
              },
              net_total_spread: {
                without_refunds: 9.17,
                with_refunds: 9.11,
              },
              gross_total_spread: {
                without_refunds: 10.5,
                with_refunds: 10.44,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 11.71,
                with_refunds: 11.57,
              },
              gross_mrr: {
                without_refunds: 13.34,
                with_refunds: 13.2,
              },
              net_arr: {
                without_refunds: 27.97,
                with_refunds: 26.79,
              },
              gross_arr: {
                without_refunds: 34.09,
                with_refunds: 32.17,
              },
              net_arr_spread: {
                without_refunds: 7.71,
                with_refunds: 7.71,
              },
              gross_arr_spread: {
                without_refunds: 8.67,
                with_refunds: 8.67,
              },
              net_total: {
                without_refunds: 12.03,
                with_refunds: 11.89,
              },
              gross_total: {
                without_refunds: 13.75,
                with_refunds: 13.58,
              },
              net_total_spread: {
                without_refunds: 8.65,
                with_refunds: 8.61,
              },
              gross_total_spread: {
                without_refunds: 9.76,
                with_refunds: 9.72,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 11.59,
                with_refunds: 11.45,
              },
              gross_mrr: {
                without_refunds: 13.07,
                with_refunds: 12.91,
              },
              net_arr: {
                without_refunds: 38.23,
                with_refunds: 37.19,
              },
              gross_arr: {
                without_refunds: 47.56,
                with_refunds: 45.85,
              },
              net_arr_spread: {
                without_refunds: 7.54,
                with_refunds: 7.54,
              },
              gross_arr_spread: {
                without_refunds: 8.74,
                with_refunds: 8.74,
              },
              net_total: {
                without_refunds: 12.08,
                with_refunds: 11.93,
              },
              gross_total: {
                without_refunds: 13.7,
                with_refunds: 13.54,
              },
              net_total_spread: {
                without_refunds: 9.17,
                with_refunds: 9.11,
              },
              gross_total_spread: {
                without_refunds: 10.5,
                with_refunds: 10.44,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 40.1,
                with_refunds: 38.14,
              },
              gross_mrr: {
                without_refunds: 46.42,
                with_refunds: 44.19,
              },
              net_arr: {
                without_refunds: 92.84,
                with_refunds: 87.5,
              },
              gross_arr: {
                without_refunds: 99.83,
                with_refunds: 94.25,
              },
              net_arr_spread: {
                without_refunds: 62.09,
                with_refunds: 62.09,
              },
              gross_arr_spread: {
                without_refunds: 66.96,
                with_refunds: 66.96,
              },
              net_total: {
                without_refunds: 93.01,
                with_refunds: 86.6,
              },
              gross_total: {
                without_refunds: 101.07,
                with_refunds: 94.28,
              },
              net_total_spread: {
                without_refunds: 60.06,
                with_refunds: 57.95,
              },
              gross_total_spread: {
                without_refunds: 68.04,
                with_refunds: 65.63,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 32.85,
                with_refunds: 31.52,
              },
              gross_mrr: {
                without_refunds: 37.59,
                with_refunds: 36.09,
              },
              net_arr: {
                without_refunds: 68.38,
                with_refunds: 60.65,
              },
              gross_arr: {
                without_refunds: 76.1,
                with_refunds: 67.57,
              },
              net_arr_spread: {
                without_refunds: 45.03,
                with_refunds: 45.03,
              },
              gross_arr_spread: {
                without_refunds: 50.36,
                with_refunds: 50.36,
              },
              net_total: {
                without_refunds: 111.39,
                with_refunds: 96.35,
              },
              gross_total: {
                without_refunds: 124.2,
                with_refunds: 107.55,
              },
              net_total_spread: {
                without_refunds: 35.42,
                with_refunds: 34.66,
              },
              gross_total_spread: {
                without_refunds: 40.08,
                with_refunds: 39.22,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 39.99,
                  with_refunds: 39.08,
                },
                gross_mrr: {
                  without_refunds: 47.03,
                  with_refunds: 45.97,
                },
                net_arr: {
                  without_refunds: 91,
                  with_refunds: 77.52,
                },
                gross_arr: {
                  without_refunds: 105.19,
                  with_refunds: 89.9,
                },
                net_arr_spread: {
                  without_refunds: 62.15,
                  with_refunds: 62.15,
                },
                gross_arr_spread: {
                  without_refunds: 71.99,
                  with_refunds: 71.99,
                },
                net_total: {
                  without_refunds: 52.51,
                  with_refunds: 48.61,
                },
                gross_total: {
                  without_refunds: 61.32,
                  with_refunds: 56.87,
                },
                net_total_spread: {
                  without_refunds: 45,
                  with_refunds: 44.28,
                },
                gross_total_spread: {
                  without_refunds: 52.7,
                  with_refunds: 51.85,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 38.17,
                  with_refunds: 37.26,
                },
                gross_mrr: {
                  without_refunds: 44.9,
                  with_refunds: 43.83,
                },
                net_arr: {
                  without_refunds: 93.11,
                  with_refunds: 78.86,
                },
                gross_arr: {
                  without_refunds: 104.49,
                  with_refunds: 88.63,
                },
                net_arr_spread: {
                  without_refunds: 64.07,
                  with_refunds: 64.07,
                },
                gross_arr_spread: {
                  without_refunds: 71.88,
                  with_refunds: 71.88,
                },
                net_total: {
                  without_refunds: 56.44,
                  with_refunds: 51.18,
                },
                gross_total: {
                  without_refunds: 64.75,
                  with_refunds: 58.86,
                },
                net_total_spread: {
                  without_refunds: 46.04,
                  with_refunds: 45.38,
                },
                gross_total_spread: {
                  without_refunds: 53.14,
                  with_refunds: 52.37,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 39.99,
                  with_refunds: 39.08,
                },
                gross_mrr: {
                  without_refunds: 47.03,
                  with_refunds: 45.97,
                },
                net_arr: {
                  without_refunds: 91,
                  with_refunds: 77.52,
                },
                gross_arr: {
                  without_refunds: 105.19,
                  with_refunds: 89.9,
                },
                net_arr_spread: {
                  without_refunds: 62.15,
                  with_refunds: 62.15,
                },
                gross_arr_spread: {
                  without_refunds: 71.99,
                  with_refunds: 71.99,
                },
                net_total: {
                  without_refunds: 52.51,
                  with_refunds: 48.61,
                },
                gross_total: {
                  without_refunds: 61.32,
                  with_refunds: 56.87,
                },
                net_total_spread: {
                  without_refunds: 45,
                  with_refunds: 44.28,
                },
                gross_total_spread: {
                  without_refunds: 52.7,
                  with_refunds: 51.85,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 102.44,
                  with_refunds: 100.02,
                },
                gross_mrr: {
                  without_refunds: 118.73,
                  with_refunds: 115.95,
                },
                net_arr: {
                  without_refunds: 872.28,
                  with_refunds: 549.54,
                },
                gross_arr: {
                  without_refunds: 1091.5,
                  with_refunds: 691.86,
                },
                net_arr_spread: {
                  without_refunds: 67.13,
                  with_refunds: 67.13,
                },
                gross_arr_spread: {
                  without_refunds: 77.9,
                  with_refunds: 77.9,
                },
                net_total: {
                  without_refunds: 137.06,
                  with_refunds: 128.11,
                },
                gross_total: {
                  without_refunds: 159.03,
                  with_refunds: 148.71,
                },
                net_total_spread: {
                  without_refunds: 87.94,
                  with_refunds: 86.46,
                },
                gross_total_spread: {
                  without_refunds: 102.48,
                  with_refunds: 100.77,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 103.82,
                  with_refunds: 101.42,
                },
                gross_mrr: {
                  without_refunds: 120.63,
                  with_refunds: 117.85,
                },
                net_arr: {
                  without_refunds: 878.84,
                  with_refunds: 538.92,
                },
                gross_arr: {
                  without_refunds: 1093.78,
                  with_refunds: 673.87,
                },
                net_arr_spread: {
                  without_refunds: 68.94,
                  with_refunds: 68.94,
                },
                gross_arr_spread: {
                  without_refunds: 77.38,
                  with_refunds: 77.38,
                },
                net_total: {
                  without_refunds: 151.65,
                  with_refunds: 139.86,
                },
                gross_total: {
                  without_refunds: 174.68,
                  with_refunds: 161.23,
                },
                net_total_spread: {
                  without_refunds: 84.92,
                  with_refunds: 83.73,
                },
                gross_total_spread: {
                  without_refunds: 97.63,
                  with_refunds: 96.25,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 102.44,
                  with_refunds: 100.02,
                },
                gross_mrr: {
                  without_refunds: 118.73,
                  with_refunds: 115.95,
                },
                net_arr: {
                  without_refunds: 872.28,
                  with_refunds: 549.54,
                },
                gross_arr: {
                  without_refunds: 1091.5,
                  with_refunds: 691.86,
                },
                net_arr_spread: {
                  without_refunds: 67.13,
                  with_refunds: 67.13,
                },
                gross_arr_spread: {
                  without_refunds: 77.9,
                  with_refunds: 77.9,
                },
                net_total: {
                  without_refunds: 137.06,
                  with_refunds: 128.11,
                },
                gross_total: {
                  without_refunds: 159.03,
                  with_refunds: 148.71,
                },
                net_total_spread: {
                  without_refunds: 87.94,
                  with_refunds: 86.46,
                },
                gross_total_spread: {
                  without_refunds: 102.48,
                  with_refunds: 100.77,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6298,
                  with_refunds: 0.6147,
                },
                gross_mrr: {
                  without_refunds: 0.74,
                  with_refunds: 0.7224,
                },
                net_arr: {
                  without_refunds: 1.5024,
                  with_refunds: 1.2811,
                },
                gross_arr: {
                  without_refunds: 1.7413,
                  with_refunds: 1.4887,
                },
                net_arr_spread: {
                  without_refunds: 1.0313,
                  with_refunds: 1.0313,
                },
                gross_arr_spread: {
                  without_refunds: 1.1969,
                  with_refunds: 1.1969,
                },
                net_total: {
                  without_refunds: 0.8434,
                  with_refunds: 0.7792,
                },
                gross_total: {
                  without_refunds: 0.9853,
                  with_refunds: 0.9117,
                },
                net_total_spread: {
                  without_refunds: 0.7198,
                  with_refunds: 0.7077,
                },
                gross_total_spread: {
                  without_refunds: 0.8426,
                  with_refunds: 0.8286,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5757,
                  with_refunds: 0.5611,
                },
                gross_mrr: {
                  without_refunds: 0.6767,
                  with_refunds: 0.6595,
                },
                net_arr: {
                  without_refunds: 1.5401,
                  with_refunds: 1.3047,
                },
                gross_arr: {
                  without_refunds: 1.7331,
                  with_refunds: 1.4694,
                },
                net_arr_spread: {
                  without_refunds: 1.0645,
                  with_refunds: 1.0645,
                },
                gross_arr_spread: {
                  without_refunds: 1.1968,
                  with_refunds: 1.1968,
                },
                net_total: {
                  without_refunds: 0.8954,
                  with_refunds: 0.8088,
                },
                gross_total: {
                  without_refunds: 1.0273,
                  with_refunds: 0.9299,
                },
                net_total_spread: {
                  without_refunds: 0.723,
                  with_refunds: 0.7125,
                },
                gross_total_spread: {
                  without_refunds: 0.8339,
                  with_refunds: 0.8216,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6298,
                  with_refunds: 0.6147,
                },
                gross_mrr: {
                  without_refunds: 0.74,
                  with_refunds: 0.7224,
                },
                net_arr: {
                  without_refunds: 1.5024,
                  with_refunds: 1.2811,
                },
                gross_arr: {
                  without_refunds: 1.7413,
                  with_refunds: 1.4887,
                },
                net_arr_spread: {
                  without_refunds: 1.0313,
                  with_refunds: 1.0313,
                },
                gross_arr_spread: {
                  without_refunds: 1.1969,
                  with_refunds: 1.1969,
                },
                net_total: {
                  without_refunds: 0.8434,
                  with_refunds: 0.7792,
                },
                gross_total: {
                  without_refunds: 0.9853,
                  with_refunds: 0.9117,
                },
                net_total_spread: {
                  without_refunds: 0.7198,
                  with_refunds: 0.7077,
                },
                gross_total_spread: {
                  without_refunds: 0.8426,
                  with_refunds: 0.8286,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.6241,
                  with_refunds: 1.5847,
                },
                gross_mrr: {
                  without_refunds: 1.8812,
                  with_refunds: 1.8359,
                },
                net_arr: {
                  without_refunds: 14.2655,
                  with_refunds: 8.7762,
                },
                gross_arr: {
                  without_refunds: 17.9389,
                  with_refunds: 11.1186,
                },
                net_arr_spread: {
                  without_refunds: 1.1109,
                  with_refunds: 1.1109,
                },
                gross_arr_spread: {
                  without_refunds: 1.2916,
                  with_refunds: 1.2916,
                },
                net_total: {
                  without_refunds: 2.2164,
                  with_refunds: 2.0674,
                },
                gross_total: {
                  without_refunds: 2.5721,
                  with_refunds: 2.3997,
                },
                net_total_spread: {
                  without_refunds: 1.4008,
                  with_refunds: 1.3765,
                },
                gross_total_spread: {
                  without_refunds: 1.6328,
                  with_refunds: 1.6047,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.5948,
                  with_refunds: 1.5574,
                },
                gross_mrr: {
                  without_refunds: 1.8525,
                  with_refunds: 1.809,
                },
                net_arr: {
                  without_refunds: 14.3543,
                  with_refunds: 8.533,
                },
                gross_arr: {
                  without_refunds: 17.9575,
                  with_refunds: 10.74,
                },
                net_arr_spread: {
                  without_refunds: 1.1419,
                  with_refunds: 1.1419,
                },
                gross_arr_spread: {
                  without_refunds: 1.2841,
                  with_refunds: 1.2841,
                },
                net_total: {
                  without_refunds: 2.4337,
                  with_refunds: 2.2361,
                },
                gross_total: {
                  without_refunds: 2.8035,
                  with_refunds: 2.5771,
                },
                net_total_spread: {
                  without_refunds: 1.3199,
                  with_refunds: 1.3014,
                },
                gross_total_spread: {
                  without_refunds: 1.5173,
                  with_refunds: 1.4956,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.6241,
                  with_refunds: 1.5847,
                },
                gross_mrr: {
                  without_refunds: 1.8812,
                  with_refunds: 1.8359,
                },
                net_arr: {
                  without_refunds: 14.2655,
                  with_refunds: 8.7762,
                },
                gross_arr: {
                  without_refunds: 17.9389,
                  with_refunds: 11.1186,
                },
                net_arr_spread: {
                  without_refunds: 1.1109,
                  with_refunds: 1.1109,
                },
                gross_arr_spread: {
                  without_refunds: 1.2916,
                  with_refunds: 1.2916,
                },
                net_total: {
                  without_refunds: 2.2164,
                  with_refunds: 2.0674,
                },
                gross_total: {
                  without_refunds: 2.5721,
                  with_refunds: 2.3997,
                },
                net_total_spread: {
                  without_refunds: 1.4008,
                  with_refunds: 1.3765,
                },
                gross_total_spread: {
                  without_refunds: 1.6328,
                  with_refunds: 1.6047,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 9,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1369,
                with_refunds: 0.1373,
              },
              arr_users: {
                without_refunds: 0.0016,
                with_refunds: 0.0016,
              },
              arr_spread_users: {
                without_refunds: 0.9947,
                with_refunds: 0.9947,
              },
              total_users: {
                without_refunds: 0.1117,
                with_refunds: 0.1121,
              },
              total_spread_users: {
                without_refunds: 0.287,
                with_refunds: 0.2874,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7086,
                with_refunds: 0.7091,
              },
              arr_users: {
                without_refunds: 0.101,
                with_refunds: 0.101,
              },
              arr_spread_users: {
                without_refunds: 1.0015,
                with_refunds: 1.0015,
              },
              total_users: {
                without_refunds: 0.6744,
                with_refunds: 0.6755,
              },
              total_spread_users: {
                without_refunds: 0.8883,
                with_refunds: 0.8888,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.118,
                with_refunds: 0.1184,
              },
              arr_users: {
                without_refunds: 0.0007,
                with_refunds: 0.0007,
              },
              arr_spread_users: {
                without_refunds: 0.9954,
                with_refunds: 0.9954,
              },
              total_users: {
                without_refunds: 0.092,
                with_refunds: 0.0922,
              },
              total_spread_users: {
                without_refunds: 0.2998,
                with_refunds: 0.3001,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6422,
                with_refunds: 0.6427,
              },
              arr_users: {
                without_refunds: 0.0634,
                with_refunds: 0.0634,
              },
              arr_spread_users: {
                without_refunds: 1.0008,
                with_refunds: 1.0008,
              },
              total_users: {
                without_refunds: 0.6096,
                with_refunds: 0.6102,
              },
              total_spread_users: {
                without_refunds: 0.8775,
                with_refunds: 0.8774,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1369,
                with_refunds: 0.1373,
              },
              arr_users: {
                without_refunds: 0.0016,
                with_refunds: 0.0016,
              },
              arr_spread_users: {
                without_refunds: 0.9947,
                with_refunds: 0.9947,
              },
              total_users: {
                without_refunds: 0.1117,
                with_refunds: 0.1121,
              },
              total_spread_users: {
                without_refunds: 0.287,
                with_refunds: 0.2874,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7086,
                with_refunds: 0.7091,
              },
              arr_users: {
                without_refunds: 0.101,
                with_refunds: 0.101,
              },
              arr_spread_users: {
                without_refunds: 1.0015,
                with_refunds: 1.0015,
              },
              total_users: {
                without_refunds: 0.6744,
                with_refunds: 0.6755,
              },
              total_spread_users: {
                without_refunds: 0.8883,
                with_refunds: 0.8888,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2309,
                with_refunds: 0.2338,
              },
              gross_mrr: {
                without_refunds: 0.197,
                with_refunds: 0.1994,
              },
              net_arr: {
                without_refunds: 0.0037,
                with_refunds: 0.0029,
              },
              gross_arr: {
                without_refunds: 0.0032,
                with_refunds: 0.0025,
              },
              net_arr_spread: {
                without_refunds: 1.9526,
                with_refunds: 1.9526,
              },
              gross_arr_spread: {
                without_refunds: 1.9108,
                with_refunds: 1.9108,
              },
              net_total: {
                without_refunds: 0.1176,
                with_refunds: 0.1204,
              },
              gross_total: {
                without_refunds: 0.0971,
                with_refunds: 0.0994,
              },
              net_total_spread: {
                without_refunds: 0.3989,
                with_refunds: 0.4054,
              },
              gross_total_spread: {
                without_refunds: 0.358,
                with_refunds: 0.364,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7048,
                with_refunds: 0.7049,
              },
              gross_mrr: {
                without_refunds: 0.7036,
                with_refunds: 0.7035,
              },
              net_arr: {
                without_refunds: 0.0918,
                with_refunds: 0.0741,
              },
              gross_arr: {
                without_refunds: 0.0944,
                with_refunds: 0.0748,
              },
              net_arr_spread: {
                without_refunds: 1.0022,
                with_refunds: 1.0022,
              },
              gross_arr_spread: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              net_total: {
                without_refunds: 0.6438,
                with_refunds: 0.6411,
              },
              gross_total: {
                without_refunds: 0.6403,
                with_refunds: 0.6367,
              },
              net_total_spread: {
                without_refunds: 0.8566,
                with_refunds: 0.8576,
              },
              gross_total_spread: {
                without_refunds: 0.8571,
                with_refunds: 0.8579,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.1897,
                with_refunds: 0.193,
              },
              gross_mrr: {
                without_refunds: 0.1648,
                with_refunds: 0.1678,
              },
              net_arr: {
                without_refunds: 0.0017,
                with_refunds: 0.0018,
              },
              gross_arr: {
                without_refunds: 0.0014,
                with_refunds: 0.0015,
              },
              net_arr_spread: {
                without_refunds: 1.9886,
                with_refunds: 1.9886,
              },
              gross_arr_spread: {
                without_refunds: 1.9483,
                with_refunds: 1.9483,
              },
              net_total: {
                without_refunds: 0.085,
                with_refunds: 0.0886,
              },
              gross_total: {
                without_refunds: 0.0728,
                with_refunds: 0.076,
              },
              net_total_spread: {
                without_refunds: 0.3986,
                with_refunds: 0.4062,
              },
              gross_total_spread: {
                without_refunds: 0.3606,
                with_refunds: 0.3678,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6419,
                with_refunds: 0.6446,
              },
              gross_mrr: {
                without_refunds: 0.6408,
                with_refunds: 0.6433,
              },
              net_arr: {
                without_refunds: 0.0571,
                with_refunds: 0.0571,
              },
              gross_arr: {
                without_refunds: 0.0583,
                with_refunds: 0.0583,
              },
              net_arr_spread: {
                without_refunds: 1.0012,
                with_refunds: 1.0012,
              },
              gross_arr_spread: {
                without_refunds: 1.0011,
                with_refunds: 1.0011,
              },
              net_total: {
                without_refunds: 0.5831,
                with_refunds: 0.5864,
              },
              gross_total: {
                without_refunds: 0.5797,
                with_refunds: 0.5833,
              },
              net_total_spread: {
                without_refunds: 0.8432,
                with_refunds: 0.8463,
              },
              gross_total_spread: {
                without_refunds: 0.8425,
                with_refunds: 0.8453,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2309,
                with_refunds: 0.2338,
              },
              gross_mrr: {
                without_refunds: 0.197,
                with_refunds: 0.1994,
              },
              net_arr: {
                without_refunds: 0.0037,
                with_refunds: 0.0029,
              },
              gross_arr: {
                without_refunds: 0.0032,
                with_refunds: 0.0025,
              },
              net_arr_spread: {
                without_refunds: 1.9526,
                with_refunds: 1.9526,
              },
              gross_arr_spread: {
                without_refunds: 1.9108,
                with_refunds: 1.9108,
              },
              net_total: {
                without_refunds: 0.1176,
                with_refunds: 0.1204,
              },
              gross_total: {
                without_refunds: 0.0971,
                with_refunds: 0.0994,
              },
              net_total_spread: {
                without_refunds: 0.3989,
                with_refunds: 0.4054,
              },
              gross_total_spread: {
                without_refunds: 0.358,
                with_refunds: 0.364,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7048,
                with_refunds: 0.7049,
              },
              gross_mrr: {
                without_refunds: 0.7036,
                with_refunds: 0.7035,
              },
              net_arr: {
                without_refunds: 0.0918,
                with_refunds: 0.0741,
              },
              gross_arr: {
                without_refunds: 0.0944,
                with_refunds: 0.0748,
              },
              net_arr_spread: {
                without_refunds: 1.0022,
                with_refunds: 1.0022,
              },
              gross_arr_spread: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              net_total: {
                without_refunds: 0.6438,
                with_refunds: 0.6411,
              },
              gross_total: {
                without_refunds: 0.6403,
                with_refunds: 0.6367,
              },
              net_total_spread: {
                without_refunds: 0.8566,
                with_refunds: 0.8576,
              },
              gross_total_spread: {
                without_refunds: 0.8571,
                with_refunds: 0.8579,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 2157.7438,
              with_refunds: 2000.2246,
            },
            gross_mrr: {
              without_refunds: 2389.3542,
              with_refunds: 2166.3508,
            },
            net_arr: {
              without_refunds: 11718.99,
              with_refunds: 10629.36,
            },
            gross_arr: {
              without_refunds: 12374.82,
              with_refunds: 11249.82,
            },
            net_arr_spread: {
              without_refunds: 885.78,
              with_refunds: 885.78,
            },
            gross_arr_spread: {
              without_refunds: 937.485,
              with_refunds: 937.485,
            },
            net_total: {
              without_refunds: 13876.7338,
              with_refunds: 12629.5846,
            },
            gross_total: {
              without_refunds: 14764.1742,
              with_refunds: 13416.1708,
            },
            net_total_spread: {
              without_refunds: 3043.5238,
              with_refunds: 2886.0046,
            },
            gross_total_spread: {
              without_refunds: 3326.8392,
              with_refunds: 3103.8358,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 1.56,
                with_refunds: 1.55,
              },
              gross_mrr: {
                without_refunds: 1.74,
                with_refunds: 1.73,
              },
              net_arr: {
                without_refunds: 0.17,
                with_refunds: 0.13,
              },
              gross_arr: {
                without_refunds: 0.23,
                with_refunds: 0.17,
              },
              net_arr_spread: {
                without_refunds: 7.4,
                with_refunds: 7.4,
              },
              gross_arr_spread: {
                without_refunds: 8.69,
                with_refunds: 8.69,
              },
              net_total: {
                without_refunds: 1.31,
                with_refunds: 1.29,
              },
              gross_total: {
                without_refunds: 1.47,
                with_refunds: 1.44,
              },
              net_total_spread: {
                without_refunds: 2.59,
                with_refunds: 2.58,
              },
              gross_total_spread: {
                without_refunds: 2.97,
                with_refunds: 2.95,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 1.35,
                with_refunds: 1.35,
              },
              gross_mrr: {
                without_refunds: 1.52,
                with_refunds: 1.51,
              },
              net_arr: {
                without_refunds: 0.08,
                with_refunds: 0.08,
              },
              gross_arr: {
                without_refunds: 0.1,
                with_refunds: 0.1,
              },
              net_arr_spread: {
                without_refunds: 7.52,
                with_refunds: 7.52,
              },
              gross_arr_spread: {
                without_refunds: 8.63,
                with_refunds: 8.63,
              },
              net_total: {
                without_refunds: 1.07,
                with_refunds: 1.07,
              },
              gross_total: {
                without_refunds: 1.2,
                with_refunds: 1.2,
              },
              net_total_spread: {
                without_refunds: 2.63,
                with_refunds: 2.63,
              },
              gross_total_spread: {
                without_refunds: 3,
                with_refunds: 2.99,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 1.56,
                with_refunds: 1.55,
              },
              gross_mrr: {
                without_refunds: 1.74,
                with_refunds: 1.73,
              },
              net_arr: {
                without_refunds: 0.17,
                with_refunds: 0.13,
              },
              gross_arr: {
                without_refunds: 0.23,
                with_refunds: 0.17,
              },
              net_arr_spread: {
                without_refunds: 7.4,
                with_refunds: 7.4,
              },
              gross_arr_spread: {
                without_refunds: 8.69,
                with_refunds: 8.69,
              },
              net_total: {
                without_refunds: 1.31,
                with_refunds: 1.29,
              },
              gross_total: {
                without_refunds: 1.47,
                with_refunds: 1.44,
              },
              net_total_spread: {
                without_refunds: 2.59,
                with_refunds: 2.58,
              },
              gross_total_spread: {
                without_refunds: 2.97,
                with_refunds: 2.95,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 41.7,
                with_refunds: 39.66,
              },
              gross_mrr: {
                without_refunds: 48.28,
                with_refunds: 45.96,
              },
              net_arr: {
                without_refunds: 92.91,
                with_refunds: 87.56,
              },
              gross_arr: {
                without_refunds: 99.89,
                with_refunds: 94.31,
              },
              net_arr_spread: {
                without_refunds: 69.28,
                with_refunds: 69.28,
              },
              gross_arr_spread: {
                without_refunds: 74.71,
                with_refunds: 74.71,
              },
              net_total: {
                without_refunds: 97.63,
                with_refunds: 90.85,
              },
              gross_total: {
                without_refunds: 106.09,
                with_refunds: 98.91,
              },
              net_total_spread: {
                without_refunds: 63.26,
                with_refunds: 61.04,
              },
              gross_total_spread: {
                without_refunds: 71.66,
                with_refunds: 69.13,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 34.15,
                with_refunds: 32.77,
              },
              gross_mrr: {
                without_refunds: 39.08,
                with_refunds: 37.52,
              },
              net_arr: {
                without_refunds: 68.48,
                with_refunds: 60.74,
              },
              gross_arr: {
                without_refunds: 76.22,
                with_refunds: 67.67,
              },
              net_arr_spread: {
                without_refunds: 50.35,
                with_refunds: 50.35,
              },
              gross_arr_spread: {
                without_refunds: 56.3,
                with_refunds: 56.3,
              },
              net_total: {
                without_refunds: 116.71,
                with_refunds: 100.9,
              },
              gross_total: {
                without_refunds: 130.13,
                with_refunds: 112.62,
              },
              net_total_spread: {
                without_refunds: 37.41,
                with_refunds: 36.6,
              },
              gross_total_spread: {
                without_refunds: 42.33,
                with_refunds: 41.42,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.42,
                with_refunds: 11.26,
              },
              gross_mrr: {
                without_refunds: 12.8,
                with_refunds: 12.63,
              },
              net_arr: {
                without_refunds: 18.59,
                with_refunds: 14.54,
              },
              gross_arr: {
                without_refunds: 24.69,
                with_refunds: 18.96,
              },
              net_arr_spread: {
                without_refunds: 7.44,
                with_refunds: 7.44,
              },
              gross_arr_spread: {
                without_refunds: 8.74,
                with_refunds: 8.74,
              },
              net_total: {
                without_refunds: 11.6,
                with_refunds: 11.39,
              },
              gross_total: {
                without_refunds: 13.06,
                with_refunds: 12.82,
              },
              net_total_spread: {
                without_refunds: 9.11,
                with_refunds: 9.04,
              },
              gross_total_spread: {
                without_refunds: 10.46,
                with_refunds: 10.39,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 11.44,
                with_refunds: 11.33,
              },
              gross_mrr: {
                without_refunds: 12.89,
                with_refunds: 12.77,
              },
              net_arr: {
                without_refunds: 10.43,
                with_refunds: 10.43,
              },
              gross_arr: {
                without_refunds: 13.86,
                with_refunds: 13.86,
              },
              net_arr_spread: {
                without_refunds: 7.55,
                with_refunds: 7.55,
              },
              gross_arr_spread: {
                without_refunds: 8.67,
                with_refunds: 8.67,
              },
              net_total: {
                without_refunds: 11.53,
                with_refunds: 11.42,
              },
              gross_total: {
                without_refunds: 13.04,
                with_refunds: 12.91,
              },
              net_total_spread: {
                without_refunds: 8.84,
                with_refunds: 8.81,
              },
              gross_total_spread: {
                without_refunds: 10.08,
                with_refunds: 10.06,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 11.42,
                with_refunds: 11.26,
              },
              gross_mrr: {
                without_refunds: 12.8,
                with_refunds: 12.63,
              },
              net_arr: {
                without_refunds: 18.59,
                with_refunds: 14.54,
              },
              gross_arr: {
                without_refunds: 24.69,
                with_refunds: 18.96,
              },
              net_arr_spread: {
                without_refunds: 7.44,
                with_refunds: 7.44,
              },
              gross_arr_spread: {
                without_refunds: 8.74,
                with_refunds: 8.74,
              },
              net_total: {
                without_refunds: 11.6,
                with_refunds: 11.39,
              },
              gross_total: {
                without_refunds: 13.06,
                with_refunds: 12.82,
              },
              net_total_spread: {
                without_refunds: 9.11,
                with_refunds: 9.04,
              },
              gross_total_spread: {
                without_refunds: 10.46,
                with_refunds: 10.39,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 41.7,
                with_refunds: 39.66,
              },
              gross_mrr: {
                without_refunds: 48.28,
                with_refunds: 45.96,
              },
              net_arr: {
                without_refunds: 92.91,
                with_refunds: 87.56,
              },
              gross_arr: {
                without_refunds: 99.89,
                with_refunds: 94.31,
              },
              net_arr_spread: {
                without_refunds: 69.28,
                with_refunds: 69.28,
              },
              gross_arr_spread: {
                without_refunds: 74.71,
                with_refunds: 74.71,
              },
              net_total: {
                without_refunds: 97.63,
                with_refunds: 90.85,
              },
              gross_total: {
                without_refunds: 106.09,
                with_refunds: 98.91,
              },
              net_total_spread: {
                without_refunds: 63.26,
                with_refunds: 61.04,
              },
              gross_total_spread: {
                without_refunds: 71.66,
                with_refunds: 69.13,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 34.15,
                with_refunds: 32.77,
              },
              gross_mrr: {
                without_refunds: 39.08,
                with_refunds: 37.52,
              },
              net_arr: {
                without_refunds: 68.48,
                with_refunds: 60.74,
              },
              gross_arr: {
                without_refunds: 76.22,
                with_refunds: 67.67,
              },
              net_arr_spread: {
                without_refunds: 50.35,
                with_refunds: 50.35,
              },
              gross_arr_spread: {
                without_refunds: 56.3,
                with_refunds: 56.3,
              },
              net_total: {
                without_refunds: 116.71,
                with_refunds: 100.9,
              },
              gross_total: {
                without_refunds: 130.13,
                with_refunds: 112.62,
              },
              net_total_spread: {
                without_refunds: 37.41,
                with_refunds: 36.6,
              },
              gross_total_spread: {
                without_refunds: 42.33,
                with_refunds: 41.42,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 42,
                  with_refunds: 41.11,
                },
                gross_mrr: {
                  without_refunds: 49.35,
                  with_refunds: 48.32,
                },
                net_arr: {
                  without_refunds: 89.92,
                  with_refunds: 76.8,
                },
                gross_arr: {
                  without_refunds: 105.18,
                  with_refunds: 90.26,
                },
                net_arr_spread: {
                  without_refunds: 68.18,
                  with_refunds: 68.18,
                },
                gross_arr_spread: {
                  without_refunds: 80.09,
                  with_refunds: 80.09,
                },
                net_total: {
                  without_refunds: 51.82,
                  with_refunds: 48.53,
                },
                gross_total: {
                  without_refunds: 60.8,
                  with_refunds: 57.03,
                },
                net_total_spread: {
                  without_refunds: 46.9,
                  with_refunds: 46.16,
                },
                gross_total_spread: {
                  without_refunds: 55.11,
                  with_refunds: 54.24,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 41.01,
                  with_refunds: 40.19,
                },
                gross_mrr: {
                  without_refunds: 48.28,
                  with_refunds: 47.32,
                },
                net_arr: {
                  without_refunds: 91.31,
                  with_refunds: 77.57,
                },
                gross_arr: {
                  without_refunds: 104.44,
                  with_refunds: 89.05,
                },
                net_arr_spread: {
                  without_refunds: 69.53,
                  with_refunds: 69.53,
                },
                gross_arr_spread: {
                  without_refunds: 79.73,
                  with_refunds: 79.73,
                },
                net_total: {
                  without_refunds: 53.13,
                  with_refunds: 49.3,
                },
                gross_total: {
                  without_refunds: 61.84,
                  with_refunds: 57.51,
                },
                net_total_spread: {
                  without_refunds: 47.23,
                  with_refunds: 46.57,
                },
                gross_total_spread: {
                  without_refunds: 55.17,
                  with_refunds: 54.4,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 42,
                  with_refunds: 41.11,
                },
                gross_mrr: {
                  without_refunds: 49.35,
                  with_refunds: 48.32,
                },
                net_arr: {
                  without_refunds: 89.92,
                  with_refunds: 76.8,
                },
                gross_arr: {
                  without_refunds: 105.18,
                  with_refunds: 90.26,
                },
                net_arr_spread: {
                  without_refunds: 68.18,
                  with_refunds: 68.18,
                },
                gross_arr_spread: {
                  without_refunds: 80.09,
                  with_refunds: 80.09,
                },
                net_total: {
                  without_refunds: 51.82,
                  with_refunds: 48.53,
                },
                gross_total: {
                  without_refunds: 60.8,
                  with_refunds: 57.03,
                },
                net_total_spread: {
                  without_refunds: 46.9,
                  with_refunds: 46.16,
                },
                gross_total_spread: {
                  without_refunds: 55.11,
                  with_refunds: 54.24,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 112.83,
                  with_refunds: 110.28,
                },
                gross_mrr: {
                  without_refunds: 130.36,
                  with_refunds: 127.45,
                },
                net_arr: {
                  without_refunds: 892.35,
                  with_refunds: 598.78,
                },
                gross_arr: {
                  without_refunds: 1113.92,
                  with_refunds: 746.92,
                },
                net_arr_spread: {
                  without_refunds: 73.5,
                  with_refunds: 73.5,
                },
                gross_arr_spread: {
                  without_refunds: 86.54,
                  with_refunds: 86.54,
                },
                net_total: {
                  without_refunds: 140.78,
                  with_refunds: 133.22,
                },
                gross_total: {
                  without_refunds: 163.38,
                  with_refunds: 154.65,
                },
                net_total_spread: {
                  without_refunds: 98.11,
                  with_refunds: 96.49,
                },
                gross_total_spread: {
                  without_refunds: 114.57,
                  with_refunds: 112.7,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 113.06,
                  with_refunds: 110.67,
                },
                gross_mrr: {
                  without_refunds: 130.94,
                  with_refunds: 128.19,
                },
                net_arr: {
                  without_refunds: 885.91,
                  with_refunds: 584.47,
                },
                gross_arr: {
                  without_refunds: 1099.67,
                  with_refunds: 724.15,
                },
                net_arr_spread: {
                  without_refunds: 74.78,
                  with_refunds: 74.78,
                },
                gross_arr_spread: {
                  without_refunds: 85.89,
                  with_refunds: 85.89,
                },
                net_total: {
                  without_refunds: 146.45,
                  with_refunds: 137.83,
                },
                gross_total: {
                  without_refunds: 169.46,
                  with_refunds: 159.59,
                },
                net_total_spread: {
                  without_refunds: 96.38,
                  with_refunds: 95.01,
                },
                gross_total_spread: {
                  without_refunds: 111.94,
                  with_refunds: 110.35,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 112.83,
                  with_refunds: 110.28,
                },
                gross_mrr: {
                  without_refunds: 130.36,
                  with_refunds: 127.45,
                },
                net_arr: {
                  without_refunds: 892.35,
                  with_refunds: 598.78,
                },
                gross_arr: {
                  without_refunds: 1113.92,
                  with_refunds: 746.92,
                },
                net_arr_spread: {
                  without_refunds: 73.5,
                  with_refunds: 73.5,
                },
                gross_arr_spread: {
                  without_refunds: 86.54,
                  with_refunds: 86.54,
                },
                net_total: {
                  without_refunds: 140.78,
                  with_refunds: 133.22,
                },
                gross_total: {
                  without_refunds: 163.38,
                  with_refunds: 154.65,
                },
                net_total_spread: {
                  without_refunds: 98.11,
                  with_refunds: 96.49,
                },
                gross_total_spread: {
                  without_refunds: 114.57,
                  with_refunds: 112.7,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6108,
                  with_refunds: 0.5972,
                },
                gross_mrr: {
                  without_refunds: 0.7173,
                  with_refunds: 0.7016,
                },
                net_arr: {
                  without_refunds: 1.1974,
                  with_refunds: 1.0261,
                },
                gross_arr: {
                  without_refunds: 1.4187,
                  with_refunds: 1.2214,
                },
                net_arr_spread: {
                  without_refunds: 0.9119,
                  with_refunds: 0.9119,
                },
                gross_arr_spread: {
                  without_refunds: 1.0854,
                  with_refunds: 1.0854,
                },
                net_total: {
                  without_refunds: 0.732,
                  with_refunds: 0.6874,
                },
                gross_total: {
                  without_refunds: 0.8622,
                  with_refunds: 0.8106,
                },
                net_total_spread: {
                  without_refunds: 0.6682,
                  with_refunds: 0.6569,
                },
                gross_total_spread: {
                  without_refunds: 0.7873,
                  with_refunds: 0.7741,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.4648,
                  with_refunds: 0.4551,
                },
                gross_mrr: {
                  without_refunds: 0.5477,
                  with_refunds: 0.5364,
                },
                net_arr: {
                  without_refunds: 1.1492,
                  with_refunds: 0.9758,
                },
                gross_arr: {
                  without_refunds: 1.3101,
                  with_refunds: 1.1157,
                },
                net_arr_spread: {
                  without_refunds: 0.8785,
                  with_refunds: 0.8785,
                },
                gross_arr_spread: {
                  without_refunds: 1.0032,
                  with_refunds: 1.0032,
                },
                net_total: {
                  without_refunds: 0.6288,
                  with_refunds: 0.5809,
                },
                gross_total: {
                  without_refunds: 0.7307,
                  with_refunds: 0.6767,
                },
                net_total_spread: {
                  without_refunds: 0.5539,
                  with_refunds: 0.5462,
                },
                gross_total_spread: {
                  without_refunds: 0.6462,
                  with_refunds: 0.6371,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6108,
                  with_refunds: 0.5972,
                },
                gross_mrr: {
                  without_refunds: 0.7173,
                  with_refunds: 0.7016,
                },
                net_arr: {
                  without_refunds: 1.1974,
                  with_refunds: 1.0261,
                },
                gross_arr: {
                  without_refunds: 1.4187,
                  with_refunds: 1.2214,
                },
                net_arr_spread: {
                  without_refunds: 0.9119,
                  with_refunds: 0.9119,
                },
                gross_arr_spread: {
                  without_refunds: 1.0854,
                  with_refunds: 1.0854,
                },
                net_total: {
                  without_refunds: 0.732,
                  with_refunds: 0.6874,
                },
                gross_total: {
                  without_refunds: 0.8622,
                  with_refunds: 0.8106,
                },
                net_total_spread: {
                  without_refunds: 0.6682,
                  with_refunds: 0.6569,
                },
                gross_total_spread: {
                  without_refunds: 0.7873,
                  with_refunds: 0.7741,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.6363,
                  with_refunds: 1.5975,
                },
                gross_mrr: {
                  without_refunds: 1.889,
                  with_refunds: 1.8447,
                },
                net_arr: {
                  without_refunds: 11.846,
                  with_refunds: 7.9183,
                },
                gross_arr: {
                  without_refunds: 14.8872,
                  with_refunds: 9.911,
                },
                net_arr_spread: {
                  without_refunds: 0.9821,
                  with_refunds: 0.9821,
                },
                gross_arr_spread: {
                  without_refunds: 1.1719,
                  with_refunds: 1.1719,
                },
                net_total: {
                  without_refunds: 1.9864,
                  with_refunds: 1.8823,
                },
                gross_total: {
                  without_refunds: 2.3088,
                  with_refunds: 2.1879,
                },
                net_total_spread: {
                  without_refunds: 1.4135,
                  with_refunds: 1.3884,
                },
                gross_total_spread: {
                  without_refunds: 1.6546,
                  with_refunds: 1.6257,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.3082,
                  with_refunds: 1.28,
                },
                gross_mrr: {
                  without_refunds: 1.5165,
                  with_refunds: 1.484,
                },
                net_arr: {
                  without_refunds: 10.9035,
                  with_refunds: 7.0558,
                },
                gross_arr: {
                  without_refunds: 13.5789,
                  with_refunds: 8.7264,
                },
                net_arr_spread: {
                  without_refunds: 0.9419,
                  with_refunds: 0.9419,
                },
                gross_arr_spread: {
                  without_refunds: 1.0771,
                  with_refunds: 1.0771,
                },
                net_total: {
                  without_refunds: 1.7531,
                  with_refunds: 1.6453,
                },
                gross_total: {
                  without_refunds: 2.028,
                  with_refunds: 1.9044,
                },
                net_total_spread: {
                  without_refunds: 1.1242,
                  with_refunds: 1.1084,
                },
                gross_total_spread: {
                  without_refunds: 1.304,
                  with_refunds: 1.2856,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.6363,
                  with_refunds: 1.5975,
                },
                gross_mrr: {
                  without_refunds: 1.889,
                  with_refunds: 1.8447,
                },
                net_arr: {
                  without_refunds: 11.846,
                  with_refunds: 7.9183,
                },
                gross_arr: {
                  without_refunds: 14.8872,
                  with_refunds: 9.911,
                },
                net_arr_spread: {
                  without_refunds: 0.9821,
                  with_refunds: 0.9821,
                },
                gross_arr_spread: {
                  without_refunds: 1.1719,
                  with_refunds: 1.1719,
                },
                net_total: {
                  without_refunds: 1.9864,
                  with_refunds: 1.8823,
                },
                gross_total: {
                  without_refunds: 2.3088,
                  with_refunds: 2.1879,
                },
                net_total_spread: {
                  without_refunds: 1.4135,
                  with_refunds: 1.3884,
                },
                gross_total_spread: {
                  without_refunds: 1.6546,
                  with_refunds: 1.6257,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 10,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1319,
                with_refunds: 0.1321,
              },
              arr_users: {
                without_refunds: 0.0033,
                with_refunds: 0.0033,
              },
              arr_spread_users: {
                without_refunds: 0.9962,
                with_refunds: 0.9962,
              },
              total_users: {
                without_refunds: 0.115,
                with_refunds: 0.1152,
              },
              total_spread_users: {
                without_refunds: 0.2427,
                with_refunds: 0.2429,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7264,
                with_refunds: 0.7253,
              },
              arr_users: {
                without_refunds: 0.3473,
                with_refunds: 0.3473,
              },
              arr_spread_users: {
                without_refunds: 1.003,
                with_refunds: 1.003,
              },
              total_users: {
                without_refunds: 0.6966,
                with_refunds: 0.6955,
              },
              total_spread_users: {
                without_refunds: 0.8804,
                with_refunds: 0.8795,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1319,
                with_refunds: 0.1321,
              },
              arr_users: {
                without_refunds: 0.0033,
                with_refunds: 0.0033,
              },
              arr_spread_users: {
                without_refunds: 0.9962,
                with_refunds: 0.9962,
              },
              total_users: {
                without_refunds: 0.115,
                with_refunds: 0.1152,
              },
              total_spread_users: {
                without_refunds: 0.2427,
                with_refunds: 0.2429,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7264,
                with_refunds: 0.7253,
              },
              arr_users: {
                without_refunds: 0.3473,
                with_refunds: 0.3473,
              },
              arr_spread_users: {
                without_refunds: 1.003,
                with_refunds: 1.003,
              },
              total_users: {
                without_refunds: 0.6966,
                with_refunds: 0.6955,
              },
              total_spread_users: {
                without_refunds: 0.8804,
                with_refunds: 0.8795,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1319,
                with_refunds: 0.1321,
              },
              arr_users: {
                without_refunds: 0.0033,
                with_refunds: 0.0033,
              },
              arr_spread_users: {
                without_refunds: 0.9962,
                with_refunds: 0.9962,
              },
              total_users: {
                without_refunds: 0.115,
                with_refunds: 0.1152,
              },
              total_spread_users: {
                without_refunds: 0.2427,
                with_refunds: 0.2429,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7264,
                with_refunds: 0.7253,
              },
              arr_users: {
                without_refunds: 0.3473,
                with_refunds: 0.3473,
              },
              arr_spread_users: {
                without_refunds: 1.003,
                with_refunds: 1.003,
              },
              total_users: {
                without_refunds: 0.6966,
                with_refunds: 0.6955,
              },
              total_spread_users: {
                without_refunds: 0.8804,
                with_refunds: 0.8795,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2271,
                with_refunds: 0.2309,
              },
              gross_mrr: {
                without_refunds: 0.1902,
                with_refunds: 0.1933,
              },
              net_arr: {
                without_refunds: 0.0076,
                with_refunds: 0.0063,
              },
              gross_arr: {
                without_refunds: 0.0064,
                with_refunds: 0.0051,
              },
              net_arr_spread: {
                without_refunds: 2.0107,
                with_refunds: 2.0107,
              },
              gross_arr_spread: {
                without_refunds: 1.9383,
                with_refunds: 1.9383,
              },
              net_total: {
                without_refunds: 0.1344,
                with_refunds: 0.1376,
              },
              gross_total: {
                without_refunds: 0.1076,
                with_refunds: 0.11,
              },
              net_total_spread: {
                without_refunds: 0.3488,
                with_refunds: 0.3547,
              },
              gross_total_spread: {
                without_refunds: 0.3091,
                with_refunds: 0.3143,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7257,
                with_refunds: 0.7292,
              },
              gross_mrr: {
                without_refunds: 0.7245,
                with_refunds: 0.7279,
              },
              net_arr: {
                without_refunds: 0.3492,
                with_refunds: 0.3787,
              },
              gross_arr: {
                without_refunds: 0.3427,
                with_refunds: 0.371,
              },
              net_arr_spread: {
                without_refunds: 1.0043,
                with_refunds: 1.0043,
              },
              gross_arr_spread: {
                without_refunds: 1.0036,
                with_refunds: 1.0036,
              },
              net_total: {
                without_refunds: 0.6981,
                with_refunds: 0.7019,
              },
              gross_total: {
                without_refunds: 0.6968,
                with_refunds: 0.7004,
              },
              net_total_spread: {
                without_refunds: 0.8513,
                with_refunds: 0.8543,
              },
              gross_total_spread: {
                without_refunds: 0.8527,
                with_refunds: 0.8555,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2271,
                with_refunds: 0.2309,
              },
              gross_mrr: {
                without_refunds: 0.1902,
                with_refunds: 0.1933,
              },
              net_arr: {
                without_refunds: 0.0076,
                with_refunds: 0.0063,
              },
              gross_arr: {
                without_refunds: 0.0064,
                with_refunds: 0.0051,
              },
              net_arr_spread: {
                without_refunds: 2.0107,
                with_refunds: 2.0107,
              },
              gross_arr_spread: {
                without_refunds: 1.9383,
                with_refunds: 1.9383,
              },
              net_total: {
                without_refunds: 0.1344,
                with_refunds: 0.1376,
              },
              gross_total: {
                without_refunds: 0.1076,
                with_refunds: 0.11,
              },
              net_total_spread: {
                without_refunds: 0.3488,
                with_refunds: 0.3547,
              },
              gross_total_spread: {
                without_refunds: 0.3091,
                with_refunds: 0.3143,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7257,
                with_refunds: 0.7292,
              },
              gross_mrr: {
                without_refunds: 0.7245,
                with_refunds: 0.7279,
              },
              net_arr: {
                without_refunds: 0.3492,
                with_refunds: 0.3787,
              },
              gross_arr: {
                without_refunds: 0.3427,
                with_refunds: 0.371,
              },
              net_arr_spread: {
                without_refunds: 1.0043,
                with_refunds: 1.0043,
              },
              gross_arr_spread: {
                without_refunds: 1.0036,
                with_refunds: 1.0036,
              },
              net_total: {
                without_refunds: 0.6981,
                with_refunds: 0.7019,
              },
              gross_total: {
                without_refunds: 0.6968,
                with_refunds: 0.7004,
              },
              net_total_spread: {
                without_refunds: 0.8513,
                with_refunds: 0.8543,
              },
              gross_total_spread: {
                without_refunds: 0.8527,
                with_refunds: 0.8555,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2271,
                with_refunds: 0.2309,
              },
              gross_mrr: {
                without_refunds: 0.1902,
                with_refunds: 0.1933,
              },
              net_arr: {
                without_refunds: 0.0076,
                with_refunds: 0.0063,
              },
              gross_arr: {
                without_refunds: 0.0064,
                with_refunds: 0.0051,
              },
              net_arr_spread: {
                without_refunds: 2.0107,
                with_refunds: 2.0107,
              },
              gross_arr_spread: {
                without_refunds: 1.9383,
                with_refunds: 1.9383,
              },
              net_total: {
                without_refunds: 0.1344,
                with_refunds: 0.1376,
              },
              gross_total: {
                without_refunds: 0.1076,
                with_refunds: 0.11,
              },
              net_total_spread: {
                without_refunds: 0.3488,
                with_refunds: 0.3547,
              },
              gross_total_spread: {
                without_refunds: 0.3091,
                with_refunds: 0.3143,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7257,
                with_refunds: 0.7292,
              },
              gross_mrr: {
                without_refunds: 0.7245,
                with_refunds: 0.7279,
              },
              net_arr: {
                without_refunds: 0.3492,
                with_refunds: 0.3787,
              },
              gross_arr: {
                without_refunds: 0.3427,
                with_refunds: 0.371,
              },
              net_arr_spread: {
                without_refunds: 1.0043,
                with_refunds: 1.0043,
              },
              gross_arr_spread: {
                without_refunds: 1.0036,
                with_refunds: 1.0036,
              },
              net_total: {
                without_refunds: 0.6981,
                with_refunds: 0.7019,
              },
              gross_total: {
                without_refunds: 0.6968,
                with_refunds: 0.7004,
              },
              net_total_spread: {
                without_refunds: 0.8513,
                with_refunds: 0.8543,
              },
              gross_total_spread: {
                without_refunds: 0.8527,
                with_refunds: 0.8555,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 2397.4931,
              with_refunds: 2222.4718,
            },
            gross_mrr: {
              without_refunds: 2654.838,
              with_refunds: 2407.0565,
            },
            net_arr: {
              without_refunds: 13021.1,
              with_refunds: 11810.4,
            },
            gross_arr: {
              without_refunds: 13749.8,
              with_refunds: 12499.8,
            },
            net_arr_spread: {
              without_refunds: 984.2,
              with_refunds: 984.2,
            },
            gross_arr_spread: {
              without_refunds: 1041.65,
              with_refunds: 1041.65,
            },
            net_total: {
              without_refunds: 15418.5931,
              with_refunds: 14032.8718,
            },
            gross_total: {
              without_refunds: 16404.638,
              with_refunds: 14906.8565,
            },
            net_total_spread: {
              without_refunds: 3381.6931,
              with_refunds: 3206.6718,
            },
            gross_total_spread: {
              without_refunds: 3696.488,
              with_refunds: 3448.7065,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 1.5,
                with_refunds: 1.49,
              },
              gross_mrr: {
                without_refunds: 1.67,
                with_refunds: 1.66,
              },
              net_arr: {
                without_refunds: 0.36,
                with_refunds: 0.28,
              },
              gross_arr: {
                without_refunds: 0.47,
                with_refunds: 0.35,
              },
              net_arr_spread: {
                without_refunds: 7.19,
                with_refunds: 7.19,
              },
              gross_arr_spread: {
                without_refunds: 8.83,
                with_refunds: 8.83,
              },
              net_total: {
                without_refunds: 1.36,
                with_refunds: 1.34,
              },
              gross_total: {
                without_refunds: 1.52,
                with_refunds: 1.49,
              },
              net_total_spread: {
                without_refunds: 2.24,
                with_refunds: 2.23,
              },
              gross_total_spread: {
                without_refunds: 2.59,
                with_refunds: 2.58,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 1.5,
                with_refunds: 1.49,
              },
              gross_mrr: {
                without_refunds: 1.67,
                with_refunds: 1.66,
              },
              net_arr: {
                without_refunds: 0.36,
                with_refunds: 0.28,
              },
              gross_arr: {
                without_refunds: 0.47,
                with_refunds: 0.35,
              },
              net_arr_spread: {
                without_refunds: 7.19,
                with_refunds: 7.19,
              },
              gross_arr_spread: {
                without_refunds: 8.83,
                with_refunds: 8.83,
              },
              net_total: {
                without_refunds: 1.36,
                with_refunds: 1.34,
              },
              gross_total: {
                without_refunds: 1.52,
                with_refunds: 1.49,
              },
              net_total_spread: {
                without_refunds: 2.24,
                with_refunds: 2.23,
              },
              gross_total_spread: {
                without_refunds: 2.59,
                with_refunds: 2.58,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 1.5,
                with_refunds: 1.49,
              },
              gross_mrr: {
                without_refunds: 1.67,
                with_refunds: 1.66,
              },
              net_arr: {
                without_refunds: 0.36,
                with_refunds: 0.28,
              },
              gross_arr: {
                without_refunds: 0.47,
                with_refunds: 0.35,
              },
              net_arr_spread: {
                without_refunds: 7.19,
                with_refunds: 7.19,
              },
              gross_arr_spread: {
                without_refunds: 8.83,
                with_refunds: 8.83,
              },
              net_total: {
                without_refunds: 1.36,
                with_refunds: 1.34,
              },
              gross_total: {
                without_refunds: 1.52,
                with_refunds: 1.49,
              },
              net_total_spread: {
                without_refunds: 2.24,
                with_refunds: 2.23,
              },
              gross_total_spread: {
                without_refunds: 2.59,
                with_refunds: 2.58,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 43.5,
                with_refunds: 41.37,
              },
              gross_mrr: {
                without_refunds: 50.37,
                with_refunds: 47.93,
              },
              net_arr: {
                without_refunds: 93.19,
                with_refunds: 87.82,
              },
              gross_arr: {
                without_refunds: 100.2,
                with_refunds: 94.59,
              },
              net_arr_spread: {
                without_refunds: 76.48,
                with_refunds: 76.48,
              },
              gross_arr_spread: {
                without_refunds: 82.47,
                with_refunds: 82.47,
              },
              net_total: {
                without_refunds: 103.42,
                with_refunds: 96.15,
              },
              gross_total: {
                without_refunds: 112.37,
                with_refunds: 104.69,
              },
              net_total_spread: {
                without_refunds: 65.85,
                with_refunds: 63.54,
              },
              gross_total_spread: {
                without_refunds: 74.6,
                with_refunds: 71.95,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 35.4,
                with_refunds: 33.97,
              },
              gross_mrr: {
                without_refunds: 40.51,
                with_refunds: 38.9,
              },
              net_arr: {
                without_refunds: 68.7,
                with_refunds: 60.92,
              },
              gross_arr: {
                without_refunds: 76.46,
                with_refunds: 67.87,
              },
              net_arr_spread: {
                without_refunds: 55.67,
                with_refunds: 55.67,
              },
              gross_arr_spread: {
                without_refunds: 62.25,
                with_refunds: 62.25,
              },
              net_total: {
                without_refunds: 122.19,
                with_refunds: 105.57,
              },
              gross_total: {
                without_refunds: 136.24,
                with_refunds: 117.84,
              },
              net_total_spread: {
                without_refunds: 39.09,
                with_refunds: 38.24,
              },
              gross_total_spread: {
                without_refunds: 44.23,
                with_refunds: 43.28,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.41,
                with_refunds: 11.34,
              },
              gross_mrr: {
                without_refunds: 12.67,
                with_refunds: 12.59,
              },
              net_arr: {
                without_refunds: 37.46,
                with_refunds: 30.36,
              },
              gross_arr: {
                without_refunds: 48.82,
                with_refunds: 38.46,
              },
              net_arr_spread: {
                without_refunds: 7.21,
                with_refunds: 7.21,
              },
              gross_arr_spread: {
                without_refunds: 8.86,
                with_refunds: 8.86,
              },
              net_total: {
                without_refunds: 11.7,
                with_refunds: 11.56,
              },
              gross_total: {
                without_refunds: 13.06,
                with_refunds: 12.88,
              },
              net_total_spread: {
                without_refunds: 9.28,
                with_refunds: 9.23,
              },
              gross_total_spread: {
                without_refunds: 10.77,
                with_refunds: 10.71,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 11.41,
                with_refunds: 11.34,
              },
              gross_mrr: {
                without_refunds: 12.67,
                with_refunds: 12.59,
              },
              net_arr: {
                without_refunds: 37.46,
                with_refunds: 30.36,
              },
              gross_arr: {
                without_refunds: 48.82,
                with_refunds: 38.46,
              },
              net_arr_spread: {
                without_refunds: 7.21,
                with_refunds: 7.21,
              },
              gross_arr_spread: {
                without_refunds: 8.86,
                with_refunds: 8.86,
              },
              net_total: {
                without_refunds: 11.7,
                with_refunds: 11.56,
              },
              gross_total: {
                without_refunds: 13.06,
                with_refunds: 12.88,
              },
              net_total_spread: {
                without_refunds: 9.28,
                with_refunds: 9.23,
              },
              gross_total_spread: {
                without_refunds: 10.77,
                with_refunds: 10.71,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 11.41,
                with_refunds: 11.34,
              },
              gross_mrr: {
                without_refunds: 12.67,
                with_refunds: 12.59,
              },
              net_arr: {
                without_refunds: 37.46,
                with_refunds: 30.36,
              },
              gross_arr: {
                without_refunds: 48.82,
                with_refunds: 38.46,
              },
              net_arr_spread: {
                without_refunds: 7.21,
                with_refunds: 7.21,
              },
              gross_arr_spread: {
                without_refunds: 8.86,
                with_refunds: 8.86,
              },
              net_total: {
                without_refunds: 11.7,
                with_refunds: 11.56,
              },
              gross_total: {
                without_refunds: 13.06,
                with_refunds: 12.88,
              },
              net_total_spread: {
                without_refunds: 9.28,
                with_refunds: 9.23,
              },
              gross_total_spread: {
                without_refunds: 10.77,
                with_refunds: 10.71,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 43.5,
                with_refunds: 41.37,
              },
              gross_mrr: {
                without_refunds: 50.37,
                with_refunds: 47.93,
              },
              net_arr: {
                without_refunds: 93.19,
                with_refunds: 87.82,
              },
              gross_arr: {
                without_refunds: 100.2,
                with_refunds: 94.59,
              },
              net_arr_spread: {
                without_refunds: 76.48,
                with_refunds: 76.48,
              },
              gross_arr_spread: {
                without_refunds: 82.47,
                with_refunds: 82.47,
              },
              net_total: {
                without_refunds: 103.42,
                with_refunds: 96.15,
              },
              gross_total: {
                without_refunds: 112.37,
                with_refunds: 104.69,
              },
              net_total_spread: {
                without_refunds: 65.85,
                with_refunds: 63.54,
              },
              gross_total_spread: {
                without_refunds: 74.6,
                with_refunds: 71.95,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 35.4,
                with_refunds: 33.97,
              },
              gross_mrr: {
                without_refunds: 40.51,
                with_refunds: 38.9,
              },
              net_arr: {
                without_refunds: 68.7,
                with_refunds: 60.92,
              },
              gross_arr: {
                without_refunds: 76.46,
                with_refunds: 67.87,
              },
              net_arr_spread: {
                without_refunds: 55.67,
                with_refunds: 55.67,
              },
              gross_arr_spread: {
                without_refunds: 62.25,
                with_refunds: 62.25,
              },
              net_total: {
                without_refunds: 122.19,
                with_refunds: 105.57,
              },
              gross_total: {
                without_refunds: 136.24,
                with_refunds: 117.84,
              },
              net_total_spread: {
                without_refunds: 39.09,
                with_refunds: 38.24,
              },
              gross_total_spread: {
                without_refunds: 44.23,
                with_refunds: 43.28,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 45.32,
                  with_refunds: 44.42,
                },
                gross_mrr: {
                  without_refunds: 53.06,
                  with_refunds: 52.02,
                },
                net_arr: {
                  without_refunds: 87.52,
                  with_refunds: 75.35,
                },
                gross_arr: {
                  without_refunds: 106.77,
                  with_refunds: 92.61,
                },
                net_arr_spread: {
                  without_refunds: 71.87,
                  with_refunds: 71.87,
                },
                gross_arr_spread: {
                  without_refunds: 88.5,
                  with_refunds: 88.5,
                },
                net_total: {
                  without_refunds: 51.86,
                  with_refunds: 49.34,
                },
                gross_total: {
                  without_refunds: 61.33,
                  with_refunds: 58.4,
                },
                net_total_spread: {
                  without_refunds: 49.12,
                  with_refunds: 48.33,
                },
                gross_total_spread: {
                  without_refunds: 58.06,
                  with_refunds: 57.14,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 45.32,
                  with_refunds: 44.42,
                },
                gross_mrr: {
                  without_refunds: 53.06,
                  with_refunds: 52.02,
                },
                net_arr: {
                  without_refunds: 87.52,
                  with_refunds: 75.35,
                },
                gross_arr: {
                  without_refunds: 106.77,
                  with_refunds: 92.61,
                },
                net_arr_spread: {
                  without_refunds: 71.87,
                  with_refunds: 71.87,
                },
                gross_arr_spread: {
                  without_refunds: 88.5,
                  with_refunds: 88.5,
                },
                net_total: {
                  without_refunds: 51.86,
                  with_refunds: 49.34,
                },
                gross_total: {
                  without_refunds: 61.33,
                  with_refunds: 58.4,
                },
                net_total_spread: {
                  without_refunds: 49.12,
                  with_refunds: 48.33,
                },
                gross_total_spread: {
                  without_refunds: 58.06,
                  with_refunds: 57.14,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 45.32,
                  with_refunds: 44.42,
                },
                gross_mrr: {
                  without_refunds: 53.06,
                  with_refunds: 52.02,
                },
                net_arr: {
                  without_refunds: 87.52,
                  with_refunds: 75.35,
                },
                gross_arr: {
                  without_refunds: 106.77,
                  with_refunds: 92.61,
                },
                net_arr_spread: {
                  without_refunds: 71.87,
                  with_refunds: 71.87,
                },
                gross_arr_spread: {
                  without_refunds: 88.5,
                  with_refunds: 88.5,
                },
                net_total: {
                  without_refunds: 51.86,
                  with_refunds: 49.34,
                },
                gross_total: {
                  without_refunds: 61.33,
                  with_refunds: 58.4,
                },
                net_total_spread: {
                  without_refunds: 49.12,
                  with_refunds: 48.33,
                },
                gross_total_spread: {
                  without_refunds: 58.06,
                  with_refunds: 57.14,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 123.84,
                  with_refunds: 121.26,
                },
                gross_mrr: {
                  without_refunds: 142.23,
                  with_refunds: 139.29,
                },
                net_arr: {
                  without_refunds: 988.44,
                  with_refunds: 714.58,
                },
                gross_arr: {
                  without_refunds: 1233.1,
                  with_refunds: 901.95,
                },
                net_arr_spread: {
                  without_refunds: 77.88,
                  with_refunds: 77.88,
                },
                gross_arr_spread: {
                  without_refunds: 96.16,
                  with_refunds: 96.16,
                },
                net_total: {
                  without_refunds: 144.39,
                  with_refunds: 138.4,
                },
                gross_total: {
                  without_refunds: 167.56,
                  with_refunds: 160.55,
                },
                net_total_spread: {
                  without_refunds: 110.02,
                  with_refunds: 108.18,
                },
                gross_total_spread: {
                  without_refunds: 129.12,
                  with_refunds: 127,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 123.84,
                  with_refunds: 121.26,
                },
                gross_mrr: {
                  without_refunds: 142.23,
                  with_refunds: 139.29,
                },
                net_arr: {
                  without_refunds: 988.44,
                  with_refunds: 714.58,
                },
                gross_arr: {
                  without_refunds: 1233.1,
                  with_refunds: 901.95,
                },
                net_arr_spread: {
                  without_refunds: 77.88,
                  with_refunds: 77.88,
                },
                gross_arr_spread: {
                  without_refunds: 96.16,
                  with_refunds: 96.16,
                },
                net_total: {
                  without_refunds: 144.39,
                  with_refunds: 138.4,
                },
                gross_total: {
                  without_refunds: 167.56,
                  with_refunds: 160.55,
                },
                net_total_spread: {
                  without_refunds: 110.02,
                  with_refunds: 108.18,
                },
                gross_total_spread: {
                  without_refunds: 129.12,
                  with_refunds: 127,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 123.84,
                  with_refunds: 121.26,
                },
                gross_mrr: {
                  without_refunds: 142.23,
                  with_refunds: 139.29,
                },
                net_arr: {
                  without_refunds: 988.44,
                  with_refunds: 714.58,
                },
                gross_arr: {
                  without_refunds: 1233.1,
                  with_refunds: 901.95,
                },
                net_arr_spread: {
                  without_refunds: 77.88,
                  with_refunds: 77.88,
                },
                gross_arr_spread: {
                  without_refunds: 96.16,
                  with_refunds: 96.16,
                },
                net_total: {
                  without_refunds: 144.39,
                  with_refunds: 138.4,
                },
                gross_total: {
                  without_refunds: 167.56,
                  with_refunds: 160.55,
                },
                net_total_spread: {
                  without_refunds: 110.02,
                  with_refunds: 108.18,
                },
                gross_total_spread: {
                  without_refunds: 129.12,
                  with_refunds: 127,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6436,
                  with_refunds: 0.63,
                },
                gross_mrr: {
                  without_refunds: 0.7525,
                  with_refunds: 0.7368,
                },
                net_arr: {
                  without_refunds: 0.9331,
                  with_refunds: 0.813,
                },
                gross_arr: {
                  without_refunds: 1.1977,
                  with_refunds: 1.0509,
                },
                net_arr_spread: {
                  without_refunds: 0.7693,
                  with_refunds: 0.7693,
                },
                gross_arr_spread: {
                  without_refunds: 0.9993,
                  with_refunds: 0.9993,
                },
                net_total: {
                  without_refunds: 0.6922,
                  with_refunds: 0.6632,
                },
                gross_total: {
                  without_refunds: 0.8246,
                  with_refunds: 0.79,
                },
                net_total_spread: {
                  without_refunds: 0.6657,
                  with_refunds: 0.6537,
                },
                gross_total_spread: {
                  without_refunds: 0.791,
                  with_refunds: 0.7772,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6436,
                  with_refunds: 0.63,
                },
                gross_mrr: {
                  without_refunds: 0.7525,
                  with_refunds: 0.7368,
                },
                net_arr: {
                  without_refunds: 0.9331,
                  with_refunds: 0.813,
                },
                gross_arr: {
                  without_refunds: 1.1977,
                  with_refunds: 1.0509,
                },
                net_arr_spread: {
                  without_refunds: 0.7693,
                  with_refunds: 0.7693,
                },
                gross_arr_spread: {
                  without_refunds: 0.9993,
                  with_refunds: 0.9993,
                },
                net_total: {
                  without_refunds: 0.6922,
                  with_refunds: 0.6632,
                },
                gross_total: {
                  without_refunds: 0.8246,
                  with_refunds: 0.79,
                },
                net_total_spread: {
                  without_refunds: 0.6657,
                  with_refunds: 0.6537,
                },
                gross_total_spread: {
                  without_refunds: 0.791,
                  with_refunds: 0.7772,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6436,
                  with_refunds: 0.63,
                },
                gross_mrr: {
                  without_refunds: 0.7525,
                  with_refunds: 0.7368,
                },
                net_arr: {
                  without_refunds: 0.9331,
                  with_refunds: 0.813,
                },
                gross_arr: {
                  without_refunds: 1.1977,
                  with_refunds: 1.0509,
                },
                net_arr_spread: {
                  without_refunds: 0.7693,
                  with_refunds: 0.7693,
                },
                gross_arr_spread: {
                  without_refunds: 0.9993,
                  with_refunds: 0.9993,
                },
                net_total: {
                  without_refunds: 0.6922,
                  with_refunds: 0.6632,
                },
                gross_total: {
                  without_refunds: 0.8246,
                  with_refunds: 0.79,
                },
                net_total_spread: {
                  without_refunds: 0.6657,
                  with_refunds: 0.6537,
                },
                gross_total_spread: {
                  without_refunds: 0.791,
                  with_refunds: 0.7772,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.7349,
                  with_refunds: 1.6961,
                },
                gross_mrr: {
                  without_refunds: 1.9894,
                  with_refunds: 1.9453,
                },
                net_arr: {
                  without_refunds: 11.1076,
                  with_refunds: 8.165,
                },
                gross_arr: {
                  without_refunds: 14.0604,
                  with_refunds: 10.4159,
                },
                net_arr_spread: {
                  without_refunds: 0.8364,
                  with_refunds: 0.8364,
                },
                gross_arr_spread: {
                  without_refunds: 1.0896,
                  with_refunds: 1.0896,
                },
                net_total: {
                  without_refunds: 1.9073,
                  with_refunds: 1.8348,
                },
                gross_total: {
                  without_refunds: 2.2195,
                  with_refunds: 2.1333,
                },
                net_total_spread: {
                  without_refunds: 1.5231,
                  with_refunds: 1.4946,
                },
                gross_total_spread: {
                  without_refunds: 1.7956,
                  with_refunds: 1.763,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7349,
                  with_refunds: 1.6961,
                },
                gross_mrr: {
                  without_refunds: 1.9894,
                  with_refunds: 1.9453,
                },
                net_arr: {
                  without_refunds: 11.1076,
                  with_refunds: 8.165,
                },
                gross_arr: {
                  without_refunds: 14.0604,
                  with_refunds: 10.4159,
                },
                net_arr_spread: {
                  without_refunds: 0.8364,
                  with_refunds: 0.8364,
                },
                gross_arr_spread: {
                  without_refunds: 1.0896,
                  with_refunds: 1.0896,
                },
                net_total: {
                  without_refunds: 1.9073,
                  with_refunds: 1.8348,
                },
                gross_total: {
                  without_refunds: 2.2195,
                  with_refunds: 2.1333,
                },
                net_total_spread: {
                  without_refunds: 1.5231,
                  with_refunds: 1.4946,
                },
                gross_total_spread: {
                  without_refunds: 1.7956,
                  with_refunds: 1.763,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7349,
                  with_refunds: 1.6961,
                },
                gross_mrr: {
                  without_refunds: 1.9894,
                  with_refunds: 1.9453,
                },
                net_arr: {
                  without_refunds: 11.1076,
                  with_refunds: 8.165,
                },
                gross_arr: {
                  without_refunds: 14.0604,
                  with_refunds: 10.4159,
                },
                net_arr_spread: {
                  without_refunds: 0.8364,
                  with_refunds: 0.8364,
                },
                gross_arr_spread: {
                  without_refunds: 1.0896,
                  with_refunds: 1.0896,
                },
                net_total: {
                  without_refunds: 1.9073,
                  with_refunds: 1.8348,
                },
                gross_total: {
                  without_refunds: 2.2195,
                  with_refunds: 2.1333,
                },
                net_total_spread: {
                  without_refunds: 1.5231,
                  with_refunds: 1.4946,
                },
                gross_total_spread: {
                  without_refunds: 1.7956,
                  with_refunds: 1.763,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 11,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1065,
                with_refunds: 0.1067,
              },
              arr_users: {
                without_refunds: 0.0019,
                with_refunds: 0.0019,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 0.0997,
                with_refunds: 0.0998,
              },
              total_spread_users: {
                without_refunds: 0.1697,
                with_refunds: 0.1699,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.617,
                with_refunds: 0.6168,
              },
              arr_users: {
                without_refunds: 0.1657,
                with_refunds: 0.1657,
              },
              arr_spread_users: {
                without_refunds: 1.0021,
                with_refunds: 1.0021,
              },
              total_users: {
                without_refunds: 0.6143,
                with_refunds: 0.6141,
              },
              total_spread_users: {
                without_refunds: 0.7382,
                with_refunds: 0.7379,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1065,
                with_refunds: 0.1067,
              },
              arr_users: {
                without_refunds: 0.0019,
                with_refunds: 0.0019,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 0.0997,
                with_refunds: 0.0998,
              },
              total_spread_users: {
                without_refunds: 0.1697,
                with_refunds: 0.1699,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.617,
                with_refunds: 0.6168,
              },
              arr_users: {
                without_refunds: 0.1657,
                with_refunds: 0.1657,
              },
              arr_spread_users: {
                without_refunds: 1.0021,
                with_refunds: 1.0021,
              },
              total_users: {
                without_refunds: 0.6143,
                with_refunds: 0.6141,
              },
              total_spread_users: {
                without_refunds: 0.7382,
                with_refunds: 0.7379,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1065,
                with_refunds: 0.1067,
              },
              arr_users: {
                without_refunds: 0.0019,
                with_refunds: 0.0019,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 0.0997,
                with_refunds: 0.0998,
              },
              total_spread_users: {
                without_refunds: 0.1697,
                with_refunds: 0.1699,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.617,
                with_refunds: 0.6168,
              },
              arr_users: {
                without_refunds: 0.1657,
                with_refunds: 0.1657,
              },
              arr_spread_users: {
                without_refunds: 1.0021,
                with_refunds: 1.0021,
              },
              total_users: {
                without_refunds: 0.6143,
                with_refunds: 0.6141,
              },
              total_spread_users: {
                without_refunds: 0.7382,
                with_refunds: 0.7379,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.1915,
                with_refunds: 0.1961,
              },
              gross_mrr: {
                without_refunds: 0.1594,
                with_refunds: 0.163,
              },
              net_arr: {
                without_refunds: 0.0043,
                with_refunds: 0.0046,
              },
              gross_arr: {
                without_refunds: 0.0032,
                with_refunds: 0.0035,
              },
              net_arr_spread: {
                without_refunds: 1.5738,
                with_refunds: 1.5738,
              },
              gross_arr_spread: {
                without_refunds: 1.5101,
                with_refunds: 1.5101,
              },
              net_total: {
                without_refunds: 0.1223,
                with_refunds: 0.127,
              },
              gross_total: {
                without_refunds: 0.0958,
                with_refunds: 0.0997,
              },
              net_total_spread: {
                without_refunds: 0.2509,
                with_refunds: 0.2567,
              },
              gross_total_spread: {
                without_refunds: 0.2262,
                with_refunds: 0.2312,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6204,
                with_refunds: 0.6245,
              },
              gross_mrr: {
                without_refunds: 0.6218,
                with_refunds: 0.626,
              },
              net_arr: {
                without_refunds: 0.1541,
                with_refunds: 0.2408,
              },
              gross_arr: {
                without_refunds: 0.1372,
                with_refunds: 0.2277,
              },
              net_arr_spread: {
                without_refunds: 1.0029,
                with_refunds: 1.0029,
              },
              gross_arr_spread: {
                without_refunds: 1.0023,
                with_refunds: 1.0023,
              },
              net_total: {
                without_refunds: 0.5995,
                with_refunds: 0.6116,
              },
              gross_total: {
                without_refunds: 0.5963,
                with_refunds: 0.6109,
              },
              net_total_spread: {
                without_refunds: 0.6976,
                with_refunds: 0.7014,
              },
              gross_total_spread: {
                without_refunds: 0.7171,
                with_refunds: 0.7208,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.1915,
                with_refunds: 0.1961,
              },
              gross_mrr: {
                without_refunds: 0.1594,
                with_refunds: 0.163,
              },
              net_arr: {
                without_refunds: 0.0043,
                with_refunds: 0.0046,
              },
              gross_arr: {
                without_refunds: 0.0032,
                with_refunds: 0.0035,
              },
              net_arr_spread: {
                without_refunds: 1.5738,
                with_refunds: 1.5738,
              },
              gross_arr_spread: {
                without_refunds: 1.5101,
                with_refunds: 1.5101,
              },
              net_total: {
                without_refunds: 0.1223,
                with_refunds: 0.127,
              },
              gross_total: {
                without_refunds: 0.0958,
                with_refunds: 0.0997,
              },
              net_total_spread: {
                without_refunds: 0.2509,
                with_refunds: 0.2567,
              },
              gross_total_spread: {
                without_refunds: 0.2262,
                with_refunds: 0.2312,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6204,
                with_refunds: 0.6245,
              },
              gross_mrr: {
                without_refunds: 0.6218,
                with_refunds: 0.626,
              },
              net_arr: {
                without_refunds: 0.1541,
                with_refunds: 0.2408,
              },
              gross_arr: {
                without_refunds: 0.1372,
                with_refunds: 0.2277,
              },
              net_arr_spread: {
                without_refunds: 1.0029,
                with_refunds: 1.0029,
              },
              gross_arr_spread: {
                without_refunds: 1.0023,
                with_refunds: 1.0023,
              },
              net_total: {
                without_refunds: 0.5995,
                with_refunds: 0.6116,
              },
              gross_total: {
                without_refunds: 0.5963,
                with_refunds: 0.6109,
              },
              net_total_spread: {
                without_refunds: 0.6976,
                with_refunds: 0.7014,
              },
              gross_total_spread: {
                without_refunds: 0.7171,
                with_refunds: 0.7208,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.1915,
                with_refunds: 0.1961,
              },
              gross_mrr: {
                without_refunds: 0.1594,
                with_refunds: 0.163,
              },
              net_arr: {
                without_refunds: 0.0043,
                with_refunds: 0.0046,
              },
              gross_arr: {
                without_refunds: 0.0032,
                with_refunds: 0.0035,
              },
              net_arr_spread: {
                without_refunds: 1.5738,
                with_refunds: 1.5738,
              },
              gross_arr_spread: {
                without_refunds: 1.5101,
                with_refunds: 1.5101,
              },
              net_total: {
                without_refunds: 0.1223,
                with_refunds: 0.127,
              },
              gross_total: {
                without_refunds: 0.0958,
                with_refunds: 0.0997,
              },
              net_total_spread: {
                without_refunds: 0.2509,
                with_refunds: 0.2567,
              },
              gross_total_spread: {
                without_refunds: 0.2262,
                with_refunds: 0.2312,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6204,
                with_refunds: 0.6245,
              },
              gross_mrr: {
                without_refunds: 0.6218,
                with_refunds: 0.626,
              },
              net_arr: {
                without_refunds: 0.1541,
                with_refunds: 0.2408,
              },
              gross_arr: {
                without_refunds: 0.1372,
                with_refunds: 0.2277,
              },
              net_arr_spread: {
                without_refunds: 1.0029,
                with_refunds: 1.0029,
              },
              gross_arr_spread: {
                without_refunds: 1.0023,
                with_refunds: 1.0023,
              },
              net_total: {
                without_refunds: 0.5995,
                with_refunds: 0.6116,
              },
              gross_total: {
                without_refunds: 0.5963,
                with_refunds: 0.6109,
              },
              net_total_spread: {
                without_refunds: 0.6976,
                with_refunds: 0.7014,
              },
              gross_total_spread: {
                without_refunds: 0.7171,
                with_refunds: 0.7208,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 2637.2424,
              with_refunds: 2444.719,
            },
            gross_mrr: {
              without_refunds: 2920.3218,
              with_refunds: 2647.7621,
            },
            net_arr: {
              without_refunds: 14323.21,
              with_refunds: 12991.44,
            },
            gross_arr: {
              without_refunds: 15124.78,
              with_refunds: 13749.78,
            },
            net_arr_spread: {
              without_refunds: 1082.62,
              with_refunds: 1082.62,
            },
            gross_arr_spread: {
              without_refunds: 1145.815,
              with_refunds: 1145.815,
            },
            net_total: {
              without_refunds: 16960.4524,
              with_refunds: 15436.159,
            },
            gross_total: {
              without_refunds: 18045.1018,
              with_refunds: 16397.5421,
            },
            net_total_spread: {
              without_refunds: 3719.8624,
              with_refunds: 3527.339,
            },
            gross_total_spread: {
              without_refunds: 4066.1368,
              with_refunds: 3793.5771,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 1.22,
                with_refunds: 1.22,
              },
              gross_mrr: {
                without_refunds: 1.35,
                with_refunds: 1.35,
              },
              net_arr: {
                without_refunds: 0.2,
                with_refunds: 0.2,
              },
              gross_arr: {
                without_refunds: 0.24,
                with_refunds: 0.24,
              },
              net_arr_spread: {
                without_refunds: 6.3,
                with_refunds: 6.3,
              },
              gross_arr_spread: {
                without_refunds: 9.32,
                with_refunds: 9.32,
              },
              net_total: {
                without_refunds: 1.16,
                with_refunds: 1.16,
              },
              gross_total: {
                without_refunds: 1.28,
                with_refunds: 1.28,
              },
              net_total_spread: {
                without_refunds: 1.59,
                with_refunds: 1.59,
              },
              gross_total_spread: {
                without_refunds: 1.92,
                with_refunds: 1.92,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 1.22,
                with_refunds: 1.22,
              },
              gross_mrr: {
                without_refunds: 1.35,
                with_refunds: 1.35,
              },
              net_arr: {
                without_refunds: 0.2,
                with_refunds: 0.2,
              },
              gross_arr: {
                without_refunds: 0.24,
                with_refunds: 0.24,
              },
              net_arr_spread: {
                without_refunds: 6.3,
                with_refunds: 6.3,
              },
              gross_arr_spread: {
                without_refunds: 9.32,
                with_refunds: 9.32,
              },
              net_total: {
                without_refunds: 1.16,
                with_refunds: 1.16,
              },
              gross_total: {
                without_refunds: 1.28,
                with_refunds: 1.28,
              },
              net_total_spread: {
                without_refunds: 1.59,
                with_refunds: 1.59,
              },
              gross_total_spread: {
                without_refunds: 1.92,
                with_refunds: 1.92,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 1.22,
                with_refunds: 1.22,
              },
              gross_mrr: {
                without_refunds: 1.35,
                with_refunds: 1.35,
              },
              net_arr: {
                without_refunds: 0.2,
                with_refunds: 0.2,
              },
              gross_arr: {
                without_refunds: 0.24,
                with_refunds: 0.24,
              },
              net_arr_spread: {
                without_refunds: 6.3,
                with_refunds: 6.3,
              },
              gross_arr_spread: {
                without_refunds: 9.32,
                with_refunds: 9.32,
              },
              net_total: {
                without_refunds: 1.16,
                with_refunds: 1.16,
              },
              gross_total: {
                without_refunds: 1.28,
                with_refunds: 1.28,
              },
              net_total_spread: {
                without_refunds: 1.59,
                with_refunds: 1.59,
              },
              gross_total_spread: {
                without_refunds: 1.92,
                with_refunds: 1.92,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 44.95,
                with_refunds: 42.75,
              },
              gross_mrr: {
                without_refunds: 52.05,
                with_refunds: 49.53,
              },
              net_arr: {
                without_refunds: 93.36,
                with_refunds: 87.97,
              },
              gross_arr: {
                without_refunds: 100.38,
                with_refunds: 94.76,
              },
              net_arr_spread: {
                without_refunds: 83.7,
                with_refunds: 83.7,
              },
              gross_arr_spread: {
                without_refunds: 90.26,
                with_refunds: 90.26,
              },
              net_total: {
                without_refunds: 108.43,
                with_refunds: 100.75,
              },
              gross_total: {
                without_refunds: 117.82,
                with_refunds: 109.69,
              },
              net_total_spread: {
                without_refunds: 67.66,
                with_refunds: 65.28,
              },
              gross_total_spread: {
                without_refunds: 76.65,
                with_refunds: 73.93,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 36.42,
                with_refunds: 34.94,
              },
              gross_mrr: {
                without_refunds: 41.67,
                with_refunds: 40.01,
              },
              net_arr: {
                without_refunds: 68.82,
                with_refunds: 61.03,
              },
              gross_arr: {
                without_refunds: 76.59,
                with_refunds: 67.98,
              },
              net_arr_spread: {
                without_refunds: 61.01,
                with_refunds: 61.01,
              },
              gross_arr_spread: {
                without_refunds: 68.22,
                with_refunds: 68.22,
              },
              net_total: {
                without_refunds: 126.94,
                with_refunds: 109.62,
              },
              gross_total: {
                without_refunds: 141.53,
                with_refunds: 122.35,
              },
              net_total_spread: {
                without_refunds: 40.26,
                with_refunds: 39.39,
              },
              gross_total_spread: {
                without_refunds: 45.56,
                with_refunds: 44.58,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.48,
                with_refunds: 11.46,
              },
              gross_mrr: {
                without_refunds: 12.77,
                with_refunds: 12.75,
              },
              net_arr: {
                without_refunds: 52.82,
                with_refunds: 52.82,
              },
              gross_arr: {
                without_refunds: 62.14,
                with_refunds: 62.14,
              },
              net_arr_spread: {
                without_refunds: 6.3,
                with_refunds: 6.3,
              },
              gross_arr_spread: {
                without_refunds: 9.32,
                with_refunds: 9.32,
              },
              net_total: {
                without_refunds: 11.57,
                with_refunds: 11.55,
              },
              gross_total: {
                without_refunds: 12.88,
                with_refunds: 12.86,
              },
              net_total_spread: {
                without_refunds: 9.11,
                with_refunds: 9.09,
              },
              gross_total_spread: {
                without_refunds: 11.16,
                with_refunds: 11.14,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 11.48,
                with_refunds: 11.46,
              },
              gross_mrr: {
                without_refunds: 12.77,
                with_refunds: 12.75,
              },
              net_arr: {
                without_refunds: 52.82,
                with_refunds: 52.82,
              },
              gross_arr: {
                without_refunds: 62.14,
                with_refunds: 62.14,
              },
              net_arr_spread: {
                without_refunds: 6.3,
                with_refunds: 6.3,
              },
              gross_arr_spread: {
                without_refunds: 9.32,
                with_refunds: 9.32,
              },
              net_total: {
                without_refunds: 11.57,
                with_refunds: 11.55,
              },
              gross_total: {
                without_refunds: 12.88,
                with_refunds: 12.86,
              },
              net_total_spread: {
                without_refunds: 9.11,
                with_refunds: 9.09,
              },
              gross_total_spread: {
                without_refunds: 11.16,
                with_refunds: 11.14,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 11.48,
                with_refunds: 11.46,
              },
              gross_mrr: {
                without_refunds: 12.77,
                with_refunds: 12.75,
              },
              net_arr: {
                without_refunds: 52.82,
                with_refunds: 52.82,
              },
              gross_arr: {
                without_refunds: 62.14,
                with_refunds: 62.14,
              },
              net_arr_spread: {
                without_refunds: 6.3,
                with_refunds: 6.3,
              },
              gross_arr_spread: {
                without_refunds: 9.32,
                with_refunds: 9.32,
              },
              net_total: {
                without_refunds: 11.57,
                with_refunds: 11.55,
              },
              gross_total: {
                without_refunds: 12.88,
                with_refunds: 12.86,
              },
              net_total_spread: {
                without_refunds: 9.11,
                with_refunds: 9.09,
              },
              gross_total_spread: {
                without_refunds: 11.16,
                with_refunds: 11.14,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 44.95,
                with_refunds: 42.75,
              },
              gross_mrr: {
                without_refunds: 52.05,
                with_refunds: 49.53,
              },
              net_arr: {
                without_refunds: 93.36,
                with_refunds: 87.97,
              },
              gross_arr: {
                without_refunds: 100.38,
                with_refunds: 94.76,
              },
              net_arr_spread: {
                without_refunds: 83.7,
                with_refunds: 83.7,
              },
              gross_arr_spread: {
                without_refunds: 90.26,
                with_refunds: 90.26,
              },
              net_total: {
                without_refunds: 108.43,
                with_refunds: 100.75,
              },
              gross_total: {
                without_refunds: 117.82,
                with_refunds: 109.69,
              },
              net_total_spread: {
                without_refunds: 67.66,
                with_refunds: 65.28,
              },
              gross_total_spread: {
                without_refunds: 76.65,
                with_refunds: 73.93,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 36.42,
                with_refunds: 34.94,
              },
              gross_mrr: {
                without_refunds: 41.67,
                with_refunds: 40.01,
              },
              net_arr: {
                without_refunds: 68.82,
                with_refunds: 61.03,
              },
              gross_arr: {
                without_refunds: 76.59,
                with_refunds: 67.98,
              },
              net_arr_spread: {
                without_refunds: 61.01,
                with_refunds: 61.01,
              },
              gross_arr_spread: {
                without_refunds: 68.22,
                with_refunds: 68.22,
              },
              net_total: {
                without_refunds: 126.94,
                with_refunds: 109.62,
              },
              gross_total: {
                without_refunds: 141.53,
                with_refunds: 122.35,
              },
              net_total_spread: {
                without_refunds: 40.26,
                with_refunds: 39.39,
              },
              gross_total_spread: {
                without_refunds: 45.56,
                with_refunds: 44.58,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 47.71,
                  with_refunds: 46.77,
                },
                gross_mrr: {
                  without_refunds: 55.75,
                  with_refunds: 54.65,
                },
                net_arr: {
                  without_refunds: 76.87,
                  with_refunds: 68.9,
                },
                gross_arr: {
                  without_refunds: 113.63,
                  with_refunds: 102,
                },
                net_arr_spread: {
                  without_refunds: 66.8,
                  with_refunds: 66.8,
                },
                gross_arr_spread: {
                  without_refunds: 100.6,
                  with_refunds: 100.6,
                },
                net_total: {
                  without_refunds: 50.44,
                  with_refunds: 48.94,
                },
                gross_total: {
                  without_refunds: 60.75,
                  with_refunds: 58.83,
                },
                net_total_spread: {
                  without_refunds: 49.45,
                  with_refunds: 48.56,
                },
                gross_total_spread: {
                  without_refunds: 59.35,
                  with_refunds: 58.32,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 47.71,
                  with_refunds: 46.77,
                },
                gross_mrr: {
                  without_refunds: 55.75,
                  with_refunds: 54.65,
                },
                net_arr: {
                  without_refunds: 76.87,
                  with_refunds: 68.9,
                },
                gross_arr: {
                  without_refunds: 113.63,
                  with_refunds: 102,
                },
                net_arr_spread: {
                  without_refunds: 66.8,
                  with_refunds: 66.8,
                },
                gross_arr_spread: {
                  without_refunds: 100.6,
                  with_refunds: 100.6,
                },
                net_total: {
                  without_refunds: 50.44,
                  with_refunds: 48.94,
                },
                gross_total: {
                  without_refunds: 60.75,
                  with_refunds: 58.83,
                },
                net_total_spread: {
                  without_refunds: 49.45,
                  with_refunds: 48.56,
                },
                gross_total_spread: {
                  without_refunds: 59.35,
                  with_refunds: 58.32,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 47.71,
                  with_refunds: 46.77,
                },
                gross_mrr: {
                  without_refunds: 55.75,
                  with_refunds: 54.65,
                },
                net_arr: {
                  without_refunds: 76.87,
                  with_refunds: 68.9,
                },
                gross_arr: {
                  without_refunds: 113.63,
                  with_refunds: 102,
                },
                net_arr_spread: {
                  without_refunds: 66.8,
                  with_refunds: 66.8,
                },
                gross_arr_spread: {
                  without_refunds: 100.6,
                  with_refunds: 100.6,
                },
                net_total: {
                  without_refunds: 50.44,
                  with_refunds: 48.94,
                },
                gross_total: {
                  without_refunds: 60.75,
                  with_refunds: 58.83,
                },
                net_total_spread: {
                  without_refunds: 49.45,
                  with_refunds: 48.56,
                },
                gross_total_spread: {
                  without_refunds: 59.35,
                  with_refunds: 58.32,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 134.85,
                  with_refunds: 132.11,
                },
                gross_mrr: {
                  without_refunds: 154.21,
                  with_refunds: 151.1,
                },
                net_arr: {
                  without_refunds: 1089.33,
                  with_refunds: 856.52,
                },
                gross_arr: {
                  without_refunds: 1419.22,
                  with_refunds: 1114.3,
                },
                net_arr_spread: {
                  without_refunds: 72.76,
                  with_refunds: 72.76,
                },
                gross_arr_spread: {
                  without_refunds: 109.71,
                  with_refunds: 109.71,
                },
                net_total: {
                  without_refunds: 145.56,
                  with_refunds: 141.29,
                },
                gross_total: {
                  without_refunds: 169.7,
                  with_refunds: 164.43,
                },
                net_total_spread: {
                  without_refunds: 122.13,
                  with_refunds: 119.93,
                },
                gross_total_spread: {
                  without_refunds: 145.47,
                  with_refunds: 142.96,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 134.85,
                  with_refunds: 132.11,
                },
                gross_mrr: {
                  without_refunds: 154.21,
                  with_refunds: 151.1,
                },
                net_arr: {
                  without_refunds: 1089.33,
                  with_refunds: 856.52,
                },
                gross_arr: {
                  without_refunds: 1419.22,
                  with_refunds: 1114.3,
                },
                net_arr_spread: {
                  without_refunds: 72.76,
                  with_refunds: 72.76,
                },
                gross_arr_spread: {
                  without_refunds: 109.71,
                  with_refunds: 109.71,
                },
                net_total: {
                  without_refunds: 145.56,
                  with_refunds: 141.29,
                },
                gross_total: {
                  without_refunds: 169.7,
                  with_refunds: 164.43,
                },
                net_total_spread: {
                  without_refunds: 122.13,
                  with_refunds: 119.93,
                },
                gross_total_spread: {
                  without_refunds: 145.47,
                  with_refunds: 142.96,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 134.85,
                  with_refunds: 132.11,
                },
                gross_mrr: {
                  without_refunds: 154.21,
                  with_refunds: 151.1,
                },
                net_arr: {
                  without_refunds: 1089.33,
                  with_refunds: 856.52,
                },
                gross_arr: {
                  without_refunds: 1419.22,
                  with_refunds: 1114.3,
                },
                net_arr_spread: {
                  without_refunds: 72.76,
                  with_refunds: 72.76,
                },
                gross_arr_spread: {
                  without_refunds: 109.71,
                  with_refunds: 109.71,
                },
                net_total: {
                  without_refunds: 145.56,
                  with_refunds: 141.29,
                },
                gross_total: {
                  without_refunds: 169.7,
                  with_refunds: 164.43,
                },
                net_total_spread: {
                  without_refunds: 122.13,
                  with_refunds: 119.93,
                },
                gross_total_spread: {
                  without_refunds: 145.47,
                  with_refunds: 142.96,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.7944,
                  with_refunds: 0.7777,
                },
                gross_mrr: {
                  without_refunds: 0.9268,
                  with_refunds: 0.9076,
                },
                net_arr: {
                  without_refunds: 1.2497,
                  with_refunds: 1.1264,
                },
                gross_arr: {
                  without_refunds: 1.8584,
                  with_refunds: 1.6753,
                },
                net_arr_spread: {
                  without_refunds: 1.0939,
                  with_refunds: 1.0939,
                },
                gross_arr_spread: {
                  without_refunds: 1.6551,
                  with_refunds: 1.6551,
                },
                net_total: {
                  without_refunds: 0.8374,
                  with_refunds: 0.8125,
                },
                gross_total: {
                  without_refunds: 1.0076,
                  with_refunds: 0.9757,
                },
                net_total_spread: {
                  without_refunds: 0.8221,
                  with_refunds: 0.8064,
                },
                gross_total_spread: {
                  without_refunds: 0.9855,
                  with_refunds: 0.9674,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7944,
                  with_refunds: 0.7777,
                },
                gross_mrr: {
                  without_refunds: 0.9268,
                  with_refunds: 0.9076,
                },
                net_arr: {
                  without_refunds: 1.2497,
                  with_refunds: 1.1264,
                },
                gross_arr: {
                  without_refunds: 1.8584,
                  with_refunds: 1.6753,
                },
                net_arr_spread: {
                  without_refunds: 1.0939,
                  with_refunds: 1.0939,
                },
                gross_arr_spread: {
                  without_refunds: 1.6551,
                  with_refunds: 1.6551,
                },
                net_total: {
                  without_refunds: 0.8374,
                  with_refunds: 0.8125,
                },
                gross_total: {
                  without_refunds: 1.0076,
                  with_refunds: 0.9757,
                },
                net_total_spread: {
                  without_refunds: 0.8221,
                  with_refunds: 0.8064,
                },
                gross_total_spread: {
                  without_refunds: 0.9855,
                  with_refunds: 0.9674,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7944,
                  with_refunds: 0.7777,
                },
                gross_mrr: {
                  without_refunds: 0.9268,
                  with_refunds: 0.9076,
                },
                net_arr: {
                  without_refunds: 1.2497,
                  with_refunds: 1.1264,
                },
                gross_arr: {
                  without_refunds: 1.8584,
                  with_refunds: 1.6753,
                },
                net_arr_spread: {
                  without_refunds: 1.0939,
                  with_refunds: 1.0939,
                },
                gross_arr_spread: {
                  without_refunds: 1.6551,
                  with_refunds: 1.6551,
                },
                net_total: {
                  without_refunds: 0.8374,
                  with_refunds: 0.8125,
                },
                gross_total: {
                  without_refunds: 1.0076,
                  with_refunds: 0.9757,
                },
                net_total_spread: {
                  without_refunds: 0.8221,
                  with_refunds: 0.8064,
                },
                gross_total_spread: {
                  without_refunds: 0.9855,
                  with_refunds: 0.9674,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 2.2298,
                  with_refunds: 2.1817,
                },
                gross_mrr: {
                  without_refunds: 2.5468,
                  with_refunds: 2.4924,
                },
                net_arr: {
                  without_refunds: 18.3047,
                  with_refunds: 14.4016,
                },
                gross_arr: {
                  without_refunds: 23.8623,
                  with_refunds: 18.7015,
                },
                net_arr_spread: {
                  without_refunds: 1.192,
                  with_refunds: 1.192,
                },
                gross_arr_spread: {
                  without_refunds: 1.8057,
                  with_refunds: 1.8057,
                },
                net_total: {
                  without_refunds: 2.4018,
                  with_refunds: 2.3305,
                },
                gross_total: {
                  without_refunds: 2.7979,
                  with_refunds: 2.7101,
                },
                net_total_spread: {
                  without_refunds: 2.0262,
                  with_refunds: 1.9876,
                },
                gross_total_spread: {
                  without_refunds: 2.409,
                  with_refunds: 2.365,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 2.2298,
                  with_refunds: 2.1817,
                },
                gross_mrr: {
                  without_refunds: 2.5468,
                  with_refunds: 2.4924,
                },
                net_arr: {
                  without_refunds: 18.3047,
                  with_refunds: 14.4016,
                },
                gross_arr: {
                  without_refunds: 23.8623,
                  with_refunds: 18.7015,
                },
                net_arr_spread: {
                  without_refunds: 1.192,
                  with_refunds: 1.192,
                },
                gross_arr_spread: {
                  without_refunds: 1.8057,
                  with_refunds: 1.8057,
                },
                net_total: {
                  without_refunds: 2.4018,
                  with_refunds: 2.3305,
                },
                gross_total: {
                  without_refunds: 2.7979,
                  with_refunds: 2.7101,
                },
                net_total_spread: {
                  without_refunds: 2.0262,
                  with_refunds: 1.9876,
                },
                gross_total_spread: {
                  without_refunds: 2.409,
                  with_refunds: 2.365,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 2.2298,
                  with_refunds: 2.1817,
                },
                gross_mrr: {
                  without_refunds: 2.5468,
                  with_refunds: 2.4924,
                },
                net_arr: {
                  without_refunds: 18.3047,
                  with_refunds: 14.4016,
                },
                gross_arr: {
                  without_refunds: 23.8623,
                  with_refunds: 18.7015,
                },
                net_arr_spread: {
                  without_refunds: 1.192,
                  with_refunds: 1.192,
                },
                gross_arr_spread: {
                  without_refunds: 1.8057,
                  with_refunds: 1.8057,
                },
                net_total: {
                  without_refunds: 2.4018,
                  with_refunds: 2.3305,
                },
                gross_total: {
                  without_refunds: 2.7979,
                  with_refunds: 2.7101,
                },
                net_total_spread: {
                  without_refunds: 2.0262,
                  with_refunds: 1.9876,
                },
                gross_total_spread: {
                  without_refunds: 2.409,
                  with_refunds: 2.365,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 12,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0484,
                with_refunds: 0.0484,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.0453,
                with_refunds: 0.0453,
              },
              total_spread_users: {
                without_refunds: 0.0686,
                with_refunds: 0.0686,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3006,
                with_refunds: 0.3,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.3,
                with_refunds: 0.2994,
              },
              total_spread_users: {
                without_refunds: 0.3128,
                with_refunds: 0.3124,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0484,
                with_refunds: 0.0484,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.0453,
                with_refunds: 0.0453,
              },
              total_spread_users: {
                without_refunds: 0.0686,
                with_refunds: 0.0686,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3006,
                with_refunds: 0.3,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.3,
                with_refunds: 0.2994,
              },
              total_spread_users: {
                without_refunds: 0.3128,
                with_refunds: 0.3124,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0484,
                with_refunds: 0.0484,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.0453,
                with_refunds: 0.0453,
              },
              total_spread_users: {
                without_refunds: 0.0686,
                with_refunds: 0.0686,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3006,
                with_refunds: 0.3,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.3,
                with_refunds: 0.2994,
              },
              total_spread_users: {
                without_refunds: 0.3128,
                with_refunds: 0.3124,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0887,
                with_refunds: 0.0911,
              },
              gross_mrr: {
                without_refunds: 0.0732,
                with_refunds: 0.075,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.623,
                with_refunds: 0.623,
              },
              gross_arr_spread: {
                without_refunds: 0.5599,
                with_refunds: 0.5599,
              },
              net_total: {
                without_refunds: 0.0559,
                with_refunds: 0.0581,
              },
              gross_total: {
                without_refunds: 0.0433,
                with_refunds: 0.0451,
              },
              net_total_spread: {
                without_refunds: 0.1118,
                with_refunds: 0.1146,
              },
              gross_total_spread: {
                without_refunds: 0.0976,
                with_refunds: 0.0998,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.2991,
                with_refunds: 0.2996,
              },
              gross_mrr: {
                without_refunds: 0.2973,
                with_refunds: 0.2978,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.3839,
                with_refunds: 0.3839,
              },
              gross_arr_spread: {
                without_refunds: 0.3589,
                with_refunds: 0.3589,
              },
              net_total: {
                without_refunds: 0.294,
                with_refunds: 0.2945,
              },
              gross_total: {
                without_refunds: 0.2919,
                with_refunds: 0.2924,
              },
              net_total_spread: {
                without_refunds: 0.3158,
                with_refunds: 0.3163,
              },
              gross_total_spread: {
                without_refunds: 0.3127,
                with_refunds: 0.3132,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0887,
                with_refunds: 0.0911,
              },
              gross_mrr: {
                without_refunds: 0.0732,
                with_refunds: 0.075,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.623,
                with_refunds: 0.623,
              },
              gross_arr_spread: {
                without_refunds: 0.5599,
                with_refunds: 0.5599,
              },
              net_total: {
                without_refunds: 0.0559,
                with_refunds: 0.0581,
              },
              gross_total: {
                without_refunds: 0.0433,
                with_refunds: 0.0451,
              },
              net_total_spread: {
                without_refunds: 0.1118,
                with_refunds: 0.1146,
              },
              gross_total_spread: {
                without_refunds: 0.0976,
                with_refunds: 0.0998,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.2991,
                with_refunds: 0.2996,
              },
              gross_mrr: {
                without_refunds: 0.2973,
                with_refunds: 0.2978,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.3839,
                with_refunds: 0.3839,
              },
              gross_arr_spread: {
                without_refunds: 0.3589,
                with_refunds: 0.3589,
              },
              net_total: {
                without_refunds: 0.294,
                with_refunds: 0.2945,
              },
              gross_total: {
                without_refunds: 0.2919,
                with_refunds: 0.2924,
              },
              net_total_spread: {
                without_refunds: 0.3158,
                with_refunds: 0.3163,
              },
              gross_total_spread: {
                without_refunds: 0.3127,
                with_refunds: 0.3132,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0887,
                with_refunds: 0.0911,
              },
              gross_mrr: {
                without_refunds: 0.0732,
                with_refunds: 0.075,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.623,
                with_refunds: 0.623,
              },
              gross_arr_spread: {
                without_refunds: 0.5599,
                with_refunds: 0.5599,
              },
              net_total: {
                without_refunds: 0.0559,
                with_refunds: 0.0581,
              },
              gross_total: {
                without_refunds: 0.0433,
                with_refunds: 0.0451,
              },
              net_total_spread: {
                without_refunds: 0.1118,
                with_refunds: 0.1146,
              },
              gross_total_spread: {
                without_refunds: 0.0976,
                with_refunds: 0.0998,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.2991,
                with_refunds: 0.2996,
              },
              gross_mrr: {
                without_refunds: 0.2973,
                with_refunds: 0.2978,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.3839,
                with_refunds: 0.3839,
              },
              gross_arr_spread: {
                without_refunds: 0.3589,
                with_refunds: 0.3589,
              },
              net_total: {
                without_refunds: 0.294,
                with_refunds: 0.2945,
              },
              gross_total: {
                without_refunds: 0.2919,
                with_refunds: 0.2924,
              },
              net_total_spread: {
                without_refunds: 0.3158,
                with_refunds: 0.3163,
              },
              gross_total_spread: {
                without_refunds: 0.3127,
                with_refunds: 0.3132,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 2876.9917,
              with_refunds: 2666.9662,
            },
            gross_mrr: {
              without_refunds: 3185.8057,
              with_refunds: 2888.4678,
            },
            net_arr: {
              without_refunds: 15625.32,
              with_refunds: 14172.48,
            },
            gross_arr: {
              without_refunds: 16499.76,
              with_refunds: 14999.76,
            },
            net_arr_spread: {
              without_refunds: 1181.04,
              with_refunds: 1181.04,
            },
            gross_arr_spread: {
              without_refunds: 1249.98,
              with_refunds: 1249.98,
            },
            net_total: {
              without_refunds: 18502.3117,
              with_refunds: 16839.4462,
            },
            gross_total: {
              without_refunds: 19685.5657,
              with_refunds: 17888.2278,
            },
            net_total_spread: {
              without_refunds: 4058.0317,
              with_refunds: 3848.0062,
            },
            gross_total_spread: {
              without_refunds: 4435.7857,
              with_refunds: 4138.4478,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 0.55,
                with_refunds: 0.55,
              },
              gross_mrr: {
                without_refunds: 0.61,
                with_refunds: 0.61,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.43,
                with_refunds: 2.43,
              },
              gross_arr_spread: {
                without_refunds: 3.4,
                with_refunds: 3.4,
              },
              net_total: {
                without_refunds: 0.52,
                with_refunds: 0.52,
              },
              gross_total: {
                without_refunds: 0.57,
                with_refunds: 0.57,
              },
              net_total_spread: {
                without_refunds: 0.68,
                with_refunds: 0.68,
              },
              gross_total_spread: {
                without_refunds: 0.8,
                with_refunds: 0.8,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.55,
                with_refunds: 0.55,
              },
              gross_mrr: {
                without_refunds: 0.61,
                with_refunds: 0.61,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.43,
                with_refunds: 2.43,
              },
              gross_arr_spread: {
                without_refunds: 3.4,
                with_refunds: 3.4,
              },
              net_total: {
                without_refunds: 0.52,
                with_refunds: 0.52,
              },
              gross_total: {
                without_refunds: 0.57,
                with_refunds: 0.57,
              },
              net_total_spread: {
                without_refunds: 0.68,
                with_refunds: 0.68,
              },
              gross_total_spread: {
                without_refunds: 0.8,
                with_refunds: 0.8,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.55,
                with_refunds: 0.55,
              },
              gross_mrr: {
                without_refunds: 0.61,
                with_refunds: 0.61,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.43,
                with_refunds: 2.43,
              },
              gross_arr_spread: {
                without_refunds: 3.4,
                with_refunds: 3.4,
              },
              net_total: {
                without_refunds: 0.52,
                with_refunds: 0.52,
              },
              gross_total: {
                without_refunds: 0.57,
                with_refunds: 0.57,
              },
              net_total_spread: {
                without_refunds: 0.68,
                with_refunds: 0.68,
              },
              gross_total_spread: {
                without_refunds: 0.8,
                with_refunds: 0.8,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 45.61,
                with_refunds: 43.37,
              },
              gross_mrr: {
                without_refunds: 52.81,
                with_refunds: 50.25,
              },
              net_arr: {
                without_refunds: 93.36,
                with_refunds: 87.97,
              },
              gross_arr: {
                without_refunds: 100.38,
                with_refunds: 94.76,
              },
              net_arr_spread: {
                without_refunds: 86.16,
                with_refunds: 86.16,
              },
              gross_arr_spread: {
                without_refunds: 92.92,
                with_refunds: 92.92,
              },
              net_total: {
                without_refunds: 110.7,
                with_refunds: 102.84,
              },
              gross_total: {
                without_refunds: 120.29,
                with_refunds: 111.97,
              },
              net_total_spread: {
                without_refunds: 68.39,
                with_refunds: 65.99,
              },
              gross_total_spread: {
                without_refunds: 77.48,
                with_refunds: 74.73,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 36.88,
                with_refunds: 35.38,
              },
              gross_mrr: {
                without_refunds: 42.2,
                with_refunds: 40.51,
              },
              net_arr: {
                without_refunds: 68.82,
                with_refunds: 61.03,
              },
              gross_arr: {
                without_refunds: 76.59,
                with_refunds: 67.98,
              },
              net_arr_spread: {
                without_refunds: 62.83,
                with_refunds: 62.83,
              },
              gross_arr_spread: {
                without_refunds: 70.26,
                with_refunds: 70.26,
              },
              net_total: {
                without_refunds: 129.1,
                with_refunds: 111.45,
              },
              gross_total: {
                without_refunds: 143.93,
                with_refunds: 124.41,
              },
              net_total_spread: {
                without_refunds: 40.73,
                with_refunds: 39.85,
              },
              gross_total_spread: {
                without_refunds: 46.1,
                with_refunds: 45.1,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.42,
                with_refunds: 11.42,
              },
              gross_mrr: {
                without_refunds: 12.53,
                with_refunds: 12.53,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 7.14,
                with_refunds: 7.14,
              },
              gross_arr_spread: {
                without_refunds: 10,
                with_refunds: 10,
              },
              net_total: {
                without_refunds: 11.42,
                with_refunds: 11.42,
              },
              gross_total: {
                without_refunds: 12.53,
                with_refunds: 12.53,
              },
              net_total_spread: {
                without_refunds: 9.98,
                with_refunds: 9.98,
              },
              gross_total_spread: {
                without_refunds: 11.68,
                with_refunds: 11.68,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 11.42,
                with_refunds: 11.42,
              },
              gross_mrr: {
                without_refunds: 12.53,
                with_refunds: 12.53,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 7.14,
                with_refunds: 7.14,
              },
              gross_arr_spread: {
                without_refunds: 10,
                with_refunds: 10,
              },
              net_total: {
                without_refunds: 11.42,
                with_refunds: 11.42,
              },
              gross_total: {
                without_refunds: 12.53,
                with_refunds: 12.53,
              },
              net_total_spread: {
                without_refunds: 9.98,
                with_refunds: 9.98,
              },
              gross_total_spread: {
                without_refunds: 11.68,
                with_refunds: 11.68,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 11.42,
                with_refunds: 11.42,
              },
              gross_mrr: {
                without_refunds: 12.53,
                with_refunds: 12.53,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 7.14,
                with_refunds: 7.14,
              },
              gross_arr_spread: {
                without_refunds: 10,
                with_refunds: 10,
              },
              net_total: {
                without_refunds: 11.42,
                with_refunds: 11.42,
              },
              gross_total: {
                without_refunds: 12.53,
                with_refunds: 12.53,
              },
              net_total_spread: {
                without_refunds: 9.98,
                with_refunds: 9.98,
              },
              gross_total_spread: {
                without_refunds: 11.68,
                with_refunds: 11.68,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 45.61,
                with_refunds: 43.37,
              },
              gross_mrr: {
                without_refunds: 52.81,
                with_refunds: 50.25,
              },
              net_arr: {
                without_refunds: 93.36,
                with_refunds: 87.97,
              },
              gross_arr: {
                without_refunds: 100.38,
                with_refunds: 94.76,
              },
              net_arr_spread: {
                without_refunds: 86.16,
                with_refunds: 86.16,
              },
              gross_arr_spread: {
                without_refunds: 92.92,
                with_refunds: 92.92,
              },
              net_total: {
                without_refunds: 110.7,
                with_refunds: 102.84,
              },
              gross_total: {
                without_refunds: 120.29,
                with_refunds: 111.97,
              },
              net_total_spread: {
                without_refunds: 68.39,
                with_refunds: 65.99,
              },
              gross_total_spread: {
                without_refunds: 77.48,
                with_refunds: 74.73,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 36.88,
                with_refunds: 35.38,
              },
              gross_mrr: {
                without_refunds: 42.2,
                with_refunds: 40.51,
              },
              net_arr: {
                without_refunds: 68.82,
                with_refunds: 61.03,
              },
              gross_arr: {
                without_refunds: 76.59,
                with_refunds: 67.98,
              },
              net_arr_spread: {
                without_refunds: 62.83,
                with_refunds: 62.83,
              },
              gross_arr_spread: {
                without_refunds: 70.26,
                with_refunds: 70.26,
              },
              net_total: {
                without_refunds: 129.1,
                with_refunds: 111.45,
              },
              gross_total: {
                without_refunds: 143.93,
                with_refunds: 124.41,
              },
              net_total_spread: {
                without_refunds: 40.73,
                with_refunds: 39.85,
              },
              gross_total_spread: {
                without_refunds: 46.1,
                with_refunds: 45.1,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 49.17,
                  with_refunds: 48.07,
                },
                gross_mrr: {
                  without_refunds: 57.21,
                  with_refunds: 55.95,
                },
                net_arr: {
                  without_refunds: 76.82,
                  with_refunds: 69.84,
                },
                gross_arr: {
                  without_refunds: 115.28,
                  with_refunds: 104.59,
                },
                net_arr_spread: {
                  without_refunds: 69.75,
                  with_refunds: 69.75,
                },
                gross_arr_spread: {
                  without_refunds: 105.98,
                  with_refunds: 105.98,
                },
                net_total: {
                  without_refunds: 51.67,
                  with_refunds: 50.13,
                },
                gross_total: {
                  without_refunds: 62.03,
                  with_refunds: 60.06,
                },
                net_total_spread: {
                  without_refunds: 50.98,
                  with_refunds: 49.95,
                },
                gross_total_spread: {
                  without_refunds: 61,
                  with_refunds: 59.81,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 49.17,
                  with_refunds: 48.07,
                },
                gross_mrr: {
                  without_refunds: 57.21,
                  with_refunds: 55.95,
                },
                net_arr: {
                  without_refunds: 76.82,
                  with_refunds: 69.84,
                },
                gross_arr: {
                  without_refunds: 115.28,
                  with_refunds: 104.59,
                },
                net_arr_spread: {
                  without_refunds: 69.75,
                  with_refunds: 69.75,
                },
                gross_arr_spread: {
                  without_refunds: 105.98,
                  with_refunds: 105.98,
                },
                net_total: {
                  without_refunds: 51.67,
                  with_refunds: 50.13,
                },
                gross_total: {
                  without_refunds: 62.03,
                  with_refunds: 60.06,
                },
                net_total_spread: {
                  without_refunds: 50.98,
                  with_refunds: 49.95,
                },
                gross_total_spread: {
                  without_refunds: 61,
                  with_refunds: 59.81,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 49.17,
                  with_refunds: 48.07,
                },
                gross_mrr: {
                  without_refunds: 57.21,
                  with_refunds: 55.95,
                },
                net_arr: {
                  without_refunds: 76.82,
                  with_refunds: 69.84,
                },
                gross_arr: {
                  without_refunds: 115.28,
                  with_refunds: 104.59,
                },
                net_arr_spread: {
                  without_refunds: 69.75,
                  with_refunds: 69.75,
                },
                gross_arr_spread: {
                  without_refunds: 105.98,
                  with_refunds: 105.98,
                },
                net_total: {
                  without_refunds: 51.67,
                  with_refunds: 50.13,
                },
                gross_total: {
                  without_refunds: 62.03,
                  with_refunds: 60.06,
                },
                net_total_spread: {
                  without_refunds: 50.98,
                  with_refunds: 49.95,
                },
                gross_total_spread: {
                  without_refunds: 61,
                  with_refunds: 59.81,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 146.52,
                  with_refunds: 143.36,
                },
                gross_mrr: {
                  without_refunds: 166.56,
                  with_refunds: 163.01,
                },
                net_arr: {
                  without_refunds: 1181.31,
                  with_refunds: 930.24,
                },
                gross_arr: {
                  without_refunds: 1541.27,
                  with_refunds: 1204.84,
                },
                net_arr_spread: {
                  without_refunds: 80.55,
                  with_refunds: 80.55,
                },
                gross_arr_spread: {
                  without_refunds: 121.98,
                  with_refunds: 121.98,
                },
                net_total: {
                  without_refunds: 156.79,
                  with_refunds: 152.4,
                },
                gross_total: {
                  without_refunds: 181.69,
                  with_refunds: 176.3,
                },
                net_total_spread: {
                  without_refunds: 133.58,
                  with_refunds: 131.03,
                },
                gross_total_spread: {
                  without_refunds: 158.24,
                  with_refunds: 155.36,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 146.52,
                  with_refunds: 143.36,
                },
                gross_mrr: {
                  without_refunds: 166.56,
                  with_refunds: 163.01,
                },
                net_arr: {
                  without_refunds: 1181.31,
                  with_refunds: 930.24,
                },
                gross_arr: {
                  without_refunds: 1541.27,
                  with_refunds: 1204.84,
                },
                net_arr_spread: {
                  without_refunds: 80.55,
                  with_refunds: 80.55,
                },
                gross_arr_spread: {
                  without_refunds: 121.98,
                  with_refunds: 121.98,
                },
                net_total: {
                  without_refunds: 156.79,
                  with_refunds: 152.4,
                },
                gross_total: {
                  without_refunds: 181.69,
                  with_refunds: 176.3,
                },
                net_total_spread: {
                  without_refunds: 133.58,
                  with_refunds: 131.03,
                },
                gross_total_spread: {
                  without_refunds: 158.24,
                  with_refunds: 155.36,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 146.52,
                  with_refunds: 143.36,
                },
                gross_mrr: {
                  without_refunds: 166.56,
                  with_refunds: 163.01,
                },
                net_arr: {
                  without_refunds: 1181.31,
                  with_refunds: 930.24,
                },
                gross_arr: {
                  without_refunds: 1541.27,
                  with_refunds: 1204.84,
                },
                net_arr_spread: {
                  without_refunds: 80.55,
                  with_refunds: 80.55,
                },
                gross_arr_spread: {
                  without_refunds: 121.98,
                  with_refunds: 121.98,
                },
                net_total: {
                  without_refunds: 156.79,
                  with_refunds: 152.4,
                },
                gross_total: {
                  without_refunds: 181.69,
                  with_refunds: 176.3,
                },
                net_total_spread: {
                  without_refunds: 133.58,
                  with_refunds: 131.03,
                },
                gross_total_spread: {
                  without_refunds: 158.24,
                  with_refunds: 155.36,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.1188,
                  with_refunds: 1.0938,
                },
                gross_mrr: {
                  without_refunds: 1.3017,
                  with_refunds: 1.273,
                },
                net_arr: {
                  without_refunds: 1.7479,
                  with_refunds: 1.589,
                },
                gross_arr: {
                  without_refunds: 2.6231,
                  with_refunds: 2.3797,
                },
                net_arr_spread: {
                  without_refunds: 1.587,
                  with_refunds: 1.587,
                },
                gross_arr_spread: {
                  without_refunds: 2.4114,
                  with_refunds: 2.4114,
                },
                net_total: {
                  without_refunds: 1.1757,
                  with_refunds: 1.1406,
                },
                gross_total: {
                  without_refunds: 1.4113,
                  with_refunds: 1.3665,
                },
                net_total_spread: {
                  without_refunds: 1.16,
                  with_refunds: 1.1364,
                },
                gross_total_spread: {
                  without_refunds: 1.3879,
                  with_refunds: 1.3609,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.1188,
                  with_refunds: 1.0938,
                },
                gross_mrr: {
                  without_refunds: 1.3017,
                  with_refunds: 1.273,
                },
                net_arr: {
                  without_refunds: 1.7479,
                  with_refunds: 1.589,
                },
                gross_arr: {
                  without_refunds: 2.6231,
                  with_refunds: 2.3797,
                },
                net_arr_spread: {
                  without_refunds: 1.587,
                  with_refunds: 1.587,
                },
                gross_arr_spread: {
                  without_refunds: 2.4114,
                  with_refunds: 2.4114,
                },
                net_total: {
                  without_refunds: 1.1757,
                  with_refunds: 1.1406,
                },
                gross_total: {
                  without_refunds: 1.4113,
                  with_refunds: 1.3665,
                },
                net_total_spread: {
                  without_refunds: 1.16,
                  with_refunds: 1.1364,
                },
                gross_total_spread: {
                  without_refunds: 1.3879,
                  with_refunds: 1.3609,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.1188,
                  with_refunds: 1.0938,
                },
                gross_mrr: {
                  without_refunds: 1.3017,
                  with_refunds: 1.273,
                },
                net_arr: {
                  without_refunds: 1.7479,
                  with_refunds: 1.589,
                },
                gross_arr: {
                  without_refunds: 2.6231,
                  with_refunds: 2.3797,
                },
                net_arr_spread: {
                  without_refunds: 1.587,
                  with_refunds: 1.587,
                },
                gross_arr_spread: {
                  without_refunds: 2.4114,
                  with_refunds: 2.4114,
                },
                net_total: {
                  without_refunds: 1.1757,
                  with_refunds: 1.1406,
                },
                gross_total: {
                  without_refunds: 1.4113,
                  with_refunds: 1.3665,
                },
                net_total_spread: {
                  without_refunds: 1.16,
                  with_refunds: 1.1364,
                },
                gross_total_spread: {
                  without_refunds: 1.3879,
                  with_refunds: 1.3609,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 3.3338,
                  with_refunds: 3.2619,
                },
                gross_mrr: {
                  without_refunds: 3.7898,
                  with_refunds: 3.709,
                },
                net_arr: {
                  without_refunds: 26.8785,
                  with_refunds: 21.1659,
                },
                gross_arr: {
                  without_refunds: 35.0688,
                  with_refunds: 27.4139,
                },
                net_arr_spread: {
                  without_refunds: 1.8327,
                  with_refunds: 1.8327,
                },
                gross_arr_spread: {
                  without_refunds: 2.7754,
                  with_refunds: 2.7754,
                },
                net_total: {
                  without_refunds: 3.5674,
                  with_refunds: 3.4676,
                },
                gross_total: {
                  without_refunds: 4.134,
                  with_refunds: 4.0113,
                },
                net_total_spread: {
                  without_refunds: 3.0394,
                  with_refunds: 2.9814,
                },
                gross_total_spread: {
                  without_refunds: 3.6005,
                  with_refunds: 3.5349,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 3.3338,
                  with_refunds: 3.2619,
                },
                gross_mrr: {
                  without_refunds: 3.7898,
                  with_refunds: 3.709,
                },
                net_arr: {
                  without_refunds: 26.8785,
                  with_refunds: 21.1659,
                },
                gross_arr: {
                  without_refunds: 35.0688,
                  with_refunds: 27.4139,
                },
                net_arr_spread: {
                  without_refunds: 1.8327,
                  with_refunds: 1.8327,
                },
                gross_arr_spread: {
                  without_refunds: 2.7754,
                  with_refunds: 2.7754,
                },
                net_total: {
                  without_refunds: 3.5674,
                  with_refunds: 3.4676,
                },
                gross_total: {
                  without_refunds: 4.134,
                  with_refunds: 4.0113,
                },
                net_total_spread: {
                  without_refunds: 3.0394,
                  with_refunds: 2.9814,
                },
                gross_total_spread: {
                  without_refunds: 3.6005,
                  with_refunds: 3.5349,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 3.3338,
                  with_refunds: 3.2619,
                },
                gross_mrr: {
                  without_refunds: 3.7898,
                  with_refunds: 3.709,
                },
                net_arr: {
                  without_refunds: 26.8785,
                  with_refunds: 21.1659,
                },
                gross_arr: {
                  without_refunds: 35.0688,
                  with_refunds: 27.4139,
                },
                net_arr_spread: {
                  without_refunds: 1.8327,
                  with_refunds: 1.8327,
                },
                gross_arr_spread: {
                  without_refunds: 2.7754,
                  with_refunds: 2.7754,
                },
                net_total: {
                  without_refunds: 3.5674,
                  with_refunds: 3.4676,
                },
                gross_total: {
                  without_refunds: 4.134,
                  with_refunds: 4.0113,
                },
                net_total_spread: {
                  without_refunds: 3.0394,
                  with_refunds: 2.9814,
                },
                gross_total_spread: {
                  without_refunds: 3.6005,
                  with_refunds: 3.5349,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
  ],
  kpis: {
    sum_ltv: {
      arpu: {
        ltv_3_months: {
          net_mrr: {
            without_refunds: 49.17,
            with_refunds: 48.07,
          },
          gross_mrr: {
            without_refunds: 57.21,
            with_refunds: 55.95,
          },
          net_arr: {
            without_refunds: 76.82,
            with_refunds: 69.84,
          },
          gross_arr: {
            without_refunds: 115.28,
            with_refunds: 104.59,
          },
          net_arr_spread: {
            without_refunds: 69.75,
            with_refunds: 69.75,
          },
          gross_arr_spread: {
            without_refunds: 105.98,
            with_refunds: 105.98,
          },
          net_total: {
            without_refunds: 51.67,
            with_refunds: 50.13,
          },
          gross_total: {
            without_refunds: 62.03,
            with_refunds: 60.06,
          },
          net_total_spread: {
            without_refunds: 50.98,
            with_refunds: 49.95,
          },
          gross_total_spread: {
            without_refunds: 61,
            with_refunds: 59.81,
          },
        },
        ltv_6_months: {
          net_mrr: {
            without_refunds: 49.17,
            with_refunds: 48.07,
          },
          gross_mrr: {
            without_refunds: 57.21,
            with_refunds: 55.95,
          },
          net_arr: {
            without_refunds: 76.82,
            with_refunds: 69.84,
          },
          gross_arr: {
            without_refunds: 115.28,
            with_refunds: 104.59,
          },
          net_arr_spread: {
            without_refunds: 69.75,
            with_refunds: 69.75,
          },
          gross_arr_spread: {
            without_refunds: 105.98,
            with_refunds: 105.98,
          },
          net_total: {
            without_refunds: 51.67,
            with_refunds: 50.13,
          },
          gross_total: {
            without_refunds: 62.03,
            with_refunds: 60.06,
          },
          net_total_spread: {
            without_refunds: 50.98,
            with_refunds: 49.95,
          },
          gross_total_spread: {
            without_refunds: 61,
            with_refunds: 59.81,
          },
        },
      },
    },
    roas_100: {
      roas: {
        arpu: {
          avg_3_months: {
            net_mrr: {
              without_refunds: 2,
              with_refunds: 2,
            },
            gross_mrr: {
              without_refunds: 2,
              with_refunds: 2,
            },
            net_arr: {
              without_refunds: 1,
              with_refunds: 1,
            },
            gross_arr: {
              without_refunds: 1,
              with_refunds: 1,
            },
            net_arr_spread: {
              without_refunds: 7,
              with_refunds: 7,
            },
            gross_arr_spread: {
              without_refunds: 7,
              with_refunds: 7,
            },
            net_total: {
              without_refunds: 1,
              with_refunds: 1,
            },
            gross_total: {
              without_refunds: 1,
              with_refunds: 1,
            },
            net_total_spread: {
              without_refunds: 13,
              with_refunds: 13,
            },
            gross_total_spread: {
              without_refunds: 13,
              with_refunds: 13,
            },
          },
        },
      },
    },
    duration: {
      percent_25: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.09,
              with_refunds: 0.09,
            },
            gross_mrr: {
              without_refunds: 0.07,
              with_refunds: 0.08,
            },
            net_arr: {
              without_refunds: 0.01,
              with_refunds: 0.02,
            },
            gross_arr: {
              without_refunds: 0.01,
              with_refunds: 0.01,
            },
            net_arr_spread: {
              without_refunds: 0.18,
              with_refunds: 0.18,
            },
            gross_arr_spread: {
              without_refunds: 0.17,
              with_refunds: 0.17,
            },
            net_total: {
              without_refunds: 0.02,
              with_refunds: 0.03,
            },
            gross_total: {
              without_refunds: 0.02,
              with_refunds: 0.02,
            },
            net_total_spread: {
              without_refunds: 0.11,
              with_refunds: 0.12,
            },
            gross_total_spread: {
              without_refunds: 0.1,
              with_refunds: 0.1,
            },
          },
        },
      },
      percent_50: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.17,
              with_refunds: 0.18,
            },
            gross_mrr: {
              without_refunds: 0.15,
              with_refunds: 0.16,
            },
            net_arr: {
              without_refunds: 0.03,
              with_refunds: 0.03,
            },
            gross_arr: {
              without_refunds: 0.03,
              with_refunds: 0.03,
            },
            net_arr_spread: {
              without_refunds: 0.36,
              with_refunds: 0.36,
            },
            gross_arr_spread: {
              without_refunds: 0.33,
              with_refunds: 0.33,
            },
            net_total: {
              without_refunds: 0.05,
              with_refunds: 0.05,
            },
            gross_total: {
              without_refunds: 0.04,
              with_refunds: 0.05,
            },
            net_total_spread: {
              without_refunds: 0.23,
              with_refunds: 0.24,
            },
            gross_total_spread: {
              without_refunds: 0.2,
              with_refunds: 0.21,
            },
          },
        },
      },
      percent_75: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.26,
              with_refunds: 0.27,
            },
            gross_mrr: {
              without_refunds: 0.22,
              with_refunds: 0.23,
            },
            net_arr: {
              without_refunds: 0.04,
              with_refunds: 0.05,
            },
            gross_arr: {
              without_refunds: 0.04,
              with_refunds: 0.04,
            },
            net_arr_spread: {
              without_refunds: 0.54,
              with_refunds: 0.54,
            },
            gross_arr_spread: {
              without_refunds: 0.5,
              with_refunds: 0.5,
            },
            net_total: {
              without_refunds: 0.07,
              with_refunds: 0.08,
            },
            gross_total: {
              without_refunds: 0.07,
              with_refunds: 0.07,
            },
            net_total_spread: {
              without_refunds: 0.34,
              with_refunds: 0.36,
            },
            gross_total_spread: {
              without_refunds: 0.3,
              with_refunds: 0.31,
            },
          },
        },
      },
      percent_100: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.34,
              with_refunds: 0.36,
            },
            gross_mrr: {
              without_refunds: 0.3,
              with_refunds: 0.31,
            },
            net_arr: {
              without_refunds: 0.06,
              with_refunds: 0.06,
            },
            gross_arr: {
              without_refunds: 0.05,
              with_refunds: 0.06,
            },
            net_arr_spread: {
              without_refunds: 0.72,
              with_refunds: 0.72,
            },
            gross_arr_spread: {
              without_refunds: 0.66,
              with_refunds: 0.66,
            },
            net_total: {
              without_refunds: 0.1,
              with_refunds: 0.11,
            },
            gross_total: {
              without_refunds: 0.09,
              with_refunds: 0.1,
            },
            net_total_spread: {
              without_refunds: 0.46,
              with_refunds: 0.47,
            },
            gross_total_spread: {
              without_refunds: 0.4,
              with_refunds: 0.42,
            },
          },
        },
      },
    },
    current: {
      arpu: {
        avg_3_months: {
          net_mrr: {
            without_refunds: 9.71,
            with_refunds: 9.2,
          },
          gross_mrr: {
            without_refunds: 11.24,
            with_refunds: 10.66,
          },
          net_arr: {
            without_refunds: 56.9,
            with_refunds: 51.86,
          },
          gross_arr: {
            without_refunds: 61.18,
            with_refunds: 55.86,
          },
          net_arr_spread: {
            without_refunds: 4.65,
            with_refunds: 4.65,
          },
          gross_arr_spread: {
            without_refunds: 5.01,
            with_refunds: 5.01,
          },
          net_total: {
            without_refunds: 34.29,
            with_refunds: 31.43,
          },
          gross_total: {
            without_refunds: 37.26,
            with_refunds: 34.22,
          },
          net_total_spread: {
            without_refunds: 7.27,
            with_refunds: 7.01,
          },
          gross_total_spread: {
            without_refunds: 8.23,
            with_refunds: 7.93,
          },
        },
        avg_6_months: {
          net_mrr: {
            without_refunds: 6.39,
            with_refunds: 6.11,
          },
          gross_mrr: {
            without_refunds: 7.32,
            with_refunds: 6.99,
          },
          net_arr: {
            without_refunds: 34.36,
            with_refunds: 29.01,
          },
          gross_arr: {
            without_refunds: 38.24,
            with_refunds: 32.32,
          },
          net_arr_spread: {
            without_refunds: 2.81,
            with_refunds: 2.81,
          },
          gross_arr_spread: {
            without_refunds: 3.14,
            with_refunds: 3.14,
          },
          net_total: {
            without_refunds: 27.45,
            with_refunds: 23.37,
          },
          gross_total: {
            without_refunds: 30.6,
            with_refunds: 26.08,
          },
          net_total_spread: {
            without_refunds: 4.03,
            with_refunds: 3.93,
          },
          gross_total_spread: {
            without_refunds: 4.56,
            with_refunds: 4.45,
          },
        },
      },
    },
    cac_avg_3_months: 3.33,
    first_period_retention: {
      start_over_month: {
        mrr_users: {
          without_refunds: 0.49,
          with_refunds: 0.49,
        },
        arr_users: {
          without_refunds: 0.39,
          with_refunds: 0.41,
        },
        arr_spread_users: {
          without_refunds: 0.99,
          with_refunds: 0.99,
        },
        total_users: {
          without_refunds: 0.42,
          with_refunds: 0.44,
        },
        total_spread_users: {
          without_refunds: 0.82,
          with_refunds: 0.82,
        },
      },
      month_over_month: {
        mrr_users: {
          without_refunds: 0.75,
          with_refunds: 0.75,
        },
        arr_users: {
          without_refunds: 0.68,
          with_refunds: 0.72,
        },
        arr_spread_users: {
          without_refunds: 1.7,
          with_refunds: 1.7,
        },
        total_users: {
          without_refunds: 0.71,
          with_refunds: 0.73,
        },
        total_spread_users: {
          without_refunds: 1.37,
          with_refunds: 1.37,
        },
      },
    },
  },
};

export const salesKpisMockData = {
  revenueKpis: {
    number_of_users: {
      combined: 12348,
      monthly: 6284,
      annually: 6065,
    },
    percent_of_users: {
      monthly: 0.5088671147461333,
      annually: 0.4911328852538667,
    },
    last_1_day: {
      combined: {
        net: 124563.56015875087,
        gross: 144479.97167445082,
      },
      monthly: {
        net: 76910.04610127547,
        gross: 89489.8824593027,
      },
      annually: {
        net: 47653.514057475535,
        gross: 54990.08921514818,
      },
    },
    avg_last_7_days: {
      combined: {
        net: 133984.40098172028,
        gross: 155426.47664503322,
      },
      monthly: {
        net: 86330.88692424477,
        gross: 100436.38742988506,
      },
      annually: {
        net: 47653.514057475535,
        gross: 54990.08921514817,
      },
    },
    annual_run_rate_1_day: {
      combined: {
        net: 1494762.7219050105,
        gross: 1733759.66009341,
      },
      monthly: {
        net: 922920.5532153056,
        gross: 1073878.5895116325,
      },
      annually: {
        net: 571842.1686897064,
        gross: 659881.0705817782,
      },
    },
    annual_run_rate_7_days: {
      combined: {
        net: 1607812.8117806432,
        gross: 1865117.7197403987,
      },
      monthly: {
        net: 1035970.6430909373,
        gross: 1205236.6491586207,
      },
      annually: {
        net: 571842.1686897064,
        gross: 659881.070581778,
      },
    },
    annual_run_rate_30_days: {
      combined: {
        net: 1666001.2363795938,
        gross: 1935953.7329884598,
      },
      monthly: {
        net: 1136644.9173065708,
        gross: 1324246.9813442759,
      },
      annually: {
        net: 529356.3190730232,
        gross: 611706.7516441838,
      },
    },
    avg_last_30_days: {
      combined: {
        net: 138833.43636496615,
        gross: 161329.4777490383,
      },
      monthly: {
        net: 94720.40977554757,
        gross: 110353.915112023,
      },
      annually: {
        net: 44113.026589418594,
        gross: 50975.56263701531,
      },
    },
    change_in_mrr_last_30_days: {
      combined: {
        net: -3573.149248682501,
        gross: -4639.709700200794,
      },
      monthly: {
        net: -12962.309116914359,
        gross: -15311.781771438109,
      },
      annually: {
        net: 9389.15986823199,
        gross: 10672.072071237417,
      },
    },
    change_in_mrr_last_30_days_percentage: {
      combined: {
        net: -0.027885445671318433,
        gross: -0.031113999556798166,
      },
      monthly: {
        net: -0.1442302150137803,
        gross: -0.14610246777881608,
      },
      annually: {
        net: 0.24537614882499093,
        gross: 0.2408066235586026,
      },
    },
    change_in_mrr_last_12_months: {
      combined: {
        net: 123771.87476642041,
        gross: 143572.54554185906,
      },
      monthly: {
        net: 76123.97404227835,
        gross: 88588.28966004428,
      },
      annually: {
        net: 47647.9007241422,
        gross: 54984.255881814846,
      },
    },
    change_in_mrr_last_12_months_percentage: {
      combined: {
        net: 156.33972278063314,
        gross: 158.21954028565807,
      },
      monthly: {
        net: 96.84096155179192,
        gross: 98.25753902749732,
      },
      annually: {
        net: 8488.343359407756,
        gross: 9425.872436882546,
      },
    },
    cagr_6_months: {
      combined: {
        net: 0.14542374452684825,
        gross: 0.12799600079770523,
      },
      monthly: {
        net: 0.06516827096696542,
        gross: 0.05128346343048884,
      },
      annually: {
        net: 0.6347290976166677,
        gross: 0.5577617185031307,
      },
    },
  },
  cashflowKpis: {
    total_last_30_days_users: {
      monthly: {
        without_refunds: 6410,
        with_refunds: 19801,
      },
      annually: {
        without_refunds: 1239,
        with_refunds: 60661,
      },
      total: {
        without_refunds: 7643,
        with_refunds: 80437,
      },
    },
    total_last_30_days_users_percent: {
      monthly: {
        without_refunds: 0.8386759125997645,
        with_refunds: 0.24616780834690502,
      },
      annually: {
        without_refunds: 0.1621091194557111,
        with_refunds: 0.7541429938958439,
      },
    },
    last_1_day: {
      monthly: {},
      annually: {},
      total: {},
    },
    annual_last_1_day: {
      monthly: {},
      annually: {},
      total: {},
    },
    month_to_date: {
      monthly: {
        net_without_refunds: 50839.14553054955,
        net_with_refunds: 49697.984282861944,
        gross_without_refunds: 59607.21985431432,
        gross_with_refunds: 58269.58482814855,
      },
      annually: {
        net_without_refunds: 86465.62207056189,
        net_with_refunds: 77258.68029737199,
        gross_without_refunds: 98778.93237661125,
        gross_with_refunds: 88045.95326930727,
      },
      total: {
        net_without_refunds: 137309.85910111244,
        net_with_refunds: 126961.75608023474,
        gross_without_refunds: 158392.74223092553,
        gross_with_refunds: 146322.12809745577,
      },
    },
    avg_last_7_days: {
      monthly: {},
      annually: {},
      total: {},
    },
    annual_last_7_days: {
      monthly: {},
      annually: {},
      total: {},
    },
    avg_last_30_days: {
      monthly: {
        net_without_refunds: 2762.7310971760717,
        net_with_refunds: 2691.229965602271,
        gross_without_refunds: 3229.606769704391,
        gross_with_refunds: 3147.6976926798216,
      },
      annually: {
        net_without_refunds: 4519.380505759462,
        net_with_refunds: 4008.90157034691,
        gross_without_refunds: 5169.406780930212,
        gross_with_refunds: 4578.734813998939,
      },
      total: {
        net_without_refunds: 7282.451036268945,
        net_with_refunds: 6700.470969282581,
        gross_without_refunds: 8399.432883967998,
        gross_with_refunds: 7726.8518400121375,
      },
    },
    annual_last_30_days: {
      monthly: {
        net_without_refunds: 1008396.8504692662,
        net_with_refunds: 982298.9374448289,
        gross_without_refunds: 1178806.4709421026,
        gross_with_refunds: 1148909.657828135,
      },
      annually: {
        net_without_refunds: 1649573.8846022035,
        net_with_refunds: 1463249.0731766222,
        gross_without_refunds: 1886833.4750395275,
        gross_with_refunds: 1671238.2071096126,
      },
      total: {
        net_without_refunds: 2658094.628238165,
        net_with_refunds: 2445671.903788142,
        gross_without_refunds: 3065793.0026483196,
        gross_with_refunds: 2820300.9216044303,
      },
    },
    change_last_30_days: {
      monthly: {
        net_without_refunds: -0.14762305538104334,
        net_with_refunds: -0.1391891970074461,
        gross_without_refunds: -0.14288074898376532,
        gross_with_refunds: -0.1355769385071365,
      },
      annually: {
        net_without_refunds: 0.41192630562995897,
        net_with_refunds: 0.7063637744707969,
        gross_without_refunds: 0.42682060723045817,
        gross_with_refunds: 0.7000826305422638,
      },
      total: {
        net_without_refunds: 0.1303925259874116,
        net_with_refunds: 0.22358184681657814,
        gross_without_refunds: 0.13637566391505912,
        gross_with_refunds: 0.21969912633511943,
      },
    },
    total_last_30_days: {
      monthly: {
        net_without_refunds: 82881.93291528214,
        net_with_refunds: 80736.89896806813,
        gross_without_refunds: 96888.20309113173,
        gross_with_refunds: 94430.93078039464,
      },
      annually: {
        net_without_refunds: 135581.41517278386,
        net_with_refunds: 120267.0471104073,
        gross_without_refunds: 155082.20342790638,
        gross_with_refunds: 137362.04441996818,
      },
      total: {
        net_without_refunds: 218473.53108806835,
        net_with_refunds: 201014.12907847742,
        gross_without_refunds: 251982.98651903996,
        gross_with_refunds: 231805.55520036412,
      },
    },
    total_last_12_months: {
      monthly: {
        net_without_refunds: 657554.5166441642,
        net_with_refunds: 639896.3869211046,
        gross_without_refunds: 780062.0468073774,
        gross_with_refunds: 759487.5556703856,
      },
      annually: {
        net_without_refunds: 677088.4474356995,
        net_with_refunds: 577676.5180119389,
        gross_without_refunds: 778982.6788411359,
        gross_with_refunds: 667143.863524954,
      },
      total: {
        net_without_refunds: 1335278.74057986,
        net_with_refunds: 1218208.6814330143,
        gross_without_refunds: 1559748.595648576,
        gross_with_refunds: 1427335.2891954132,
      },
    },
    change_last_12_months: {
      monthly: {
        net_without_refunds: 604.4444933902097,
        net_with_refunds: 619.0597424832146,
        gross_without_refunds: 581.0914039464988,
        gross_with_refunds: 597.7239516367265,
      },
      annually: {
        net_without_refunds: 10050.788115138057,
        net_with_refunds: 8574.957808965839,
        gross_without_refunds: 11127.3239834448,
        gross_with_refunds: 9529.626621785057,
      },
      total: {
        net_without_refunds: 1119.9845658926938,
        net_with_refunds: 1070.3402482989022,
        gross_without_refunds: 1062.816255005198,
        gross_with_refunds: 1022.47993757683,
      },
    },
    cagr_6_months: {
      monthly: {
        net_without_refunds: 0.20063677859909657,
        net_with_refunds: 0.1992181958785837,
        gross_without_refunds: 0.18324088198781974,
        gross_with_refunds: 0.18192237200727912,
      },
      annually: {
        net_without_refunds: 0.43447637800952355,
        net_with_refunds: 0.39083457877898464,
        gross_without_refunds: 0.36331620481336113,
        gross_with_refunds: 0.3247982811606318,
      },
      total: {
        net_without_refunds: 0.2861883928635558,
        net_with_refunds: 0.2641629594337005,
        gross_without_refunds: 0.2535020934990482,
        gross_with_refunds: 0.23378525936000805,
      },
    },
  },
};

export const recurringRevenueMockData = {
  daily: {
    revenue: [
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 1,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 208308.51025896502,
              net_with_refunds: 208308.51025896502,
              gross_without_refunds: 238832.18789276568,
              gross_with_refunds: 238832.18789276568,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 97739.1646463963,
              net_with_refunds: 97739.1646463963,
              gross_without_refunds: 113877.87104188498,
              gross_with_refunds: 113877.87104188498,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 110569.34561256874,
              net_with_refunds: 110569.34561256874,
              gross_without_refunds: 124954.31685088071,
              gross_with_refunds: 124954.31685088071,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 2,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 206024.70963695433,
              net_with_refunds: 206024.70963695433,
              gross_without_refunds: 236546.2716158325,
              gross_with_refunds: 236546.2716158325,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 97291.71295285641,
              net_with_refunds: 97291.71295285641,
              gross_without_refunds: 113312.69593123958,
              gross_with_refunds: 113312.69593123958,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 108732.99668409792,
              net_with_refunds: 108732.99668409792,
              gross_without_refunds: 123233.57568459291,
              gross_with_refunds: 123233.57568459291,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 3,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 204117.08808650065,
              net_with_refunds: 204117.08808650065,
              gross_without_refunds: 234201.30540493852,
              gross_with_refunds: 234201.30540493852,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 96905.45662500306,
              net_with_refunds: 96905.45662500306,
              gross_without_refunds: 112833.98667665239,
              gross_with_refunds: 112833.98667665239,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 107211.63146149759,
              net_with_refunds: 107211.63146149759,
              gross_without_refunds: 121367.31872828612,
              gross_with_refunds: 121367.31872828612,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 4,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 202322.21955053206,
              net_with_refunds: 202322.21955053206,
              gross_without_refunds: 232001.32934783545,
              gross_with_refunds: 232001.32934783545,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 96591.31377952553,
              net_with_refunds: 96591.31377952553,
              gross_without_refunds: 112480.39499314828,
              gross_with_refunds: 112480.39499314828,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 105730.90577100654,
              net_with_refunds: 105730.90577100654,
              gross_without_refunds: 119520.93435468717,
              gross_with_refunds: 119520.93435468717,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 5,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 201983.23761908815,
              net_with_refunds: 201983.23761908815,
              gross_without_refunds: 231652.39479063047,
              gross_with_refunds: 231652.39479063047,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 96329.88996028826,
              net_with_refunds: 96329.88996028826,
              gross_without_refunds: 112192.9563587036,
              gross_with_refunds: 112192.9563587036,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 105653.34765879989,
              net_with_refunds: 105653.34765879989,
              gross_without_refunds: 119459.43843192687,
              gross_with_refunds: 119459.43843192687,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 6,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 200769.87182970153,
              net_with_refunds: 200769.87182970153,
              gross_without_refunds: 230478.52459895896,
              gross_with_refunds: 230478.52459895896,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 96123.26377113689,
              net_with_refunds: 96123.26377113689,
              gross_without_refunds: 111950.62366072385,
              gross_with_refunds: 111950.62366072385,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 104646.60805856463,
              net_with_refunds: 104646.60805856463,
              gross_without_refunds: 118527.90093823512,
              gross_with_refunds: 118527.90093823512,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 7,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 198412.05751523294,
              net_with_refunds: 198412.05751523294,
              gross_without_refunds: 227913.9302411149,
              gross_with_refunds: 227913.9302411149,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 95404.93668726328,
              net_with_refunds: 95404.93668726328,
              gross_without_refunds: 111125.07584463365,
              gross_with_refunds: 111125.07584463365,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 103007.12082796967,
              net_with_refunds: 103007.12082796967,
              gross_without_refunds: 116788.85439648124,
              gross_with_refunds: 116788.85439648124,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 8,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 195468.19310051377,
              net_with_refunds: 195468.19310051377,
              gross_without_refunds: 224581.75637122773,
              gross_with_refunds: 224581.75637122773,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 94531.61194788628,
              net_with_refunds: 94531.61194788628,
              gross_without_refunds: 110097.97453534622,
              gross_with_refunds: 110097.97453534622,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 100936.58115262748,
              net_with_refunds: 100936.58115262748,
              gross_without_refunds: 114483.78183588151,
              gross_with_refunds: 114483.78183588151,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 9,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 192768.78771227621,
              net_with_refunds: 192768.78771227621,
              gross_without_refunds: 221462.90271472605,
              gross_with_refunds: 221462.90271472605,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 93665.11717745858,
              net_with_refunds: 93665.11717745858,
              gross_without_refunds: 109015.12098407479,
              gross_with_refunds: 109015.12098407479,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 99103.67053481763,
              net_with_refunds: 99103.67053481763,
              gross_without_refunds: 112447.78173065124,
              gross_with_refunds: 112447.78173065124,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 10,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 191788.24822745597,
              net_with_refunds: 191788.24822745597,
              gross_without_refunds: 220505.079110892,
              gross_with_refunds: 220505.079110892,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 93839.75338806304,
              net_with_refunds: 93839.75338806304,
              gross_without_refunds: 109345.30325766886,
              gross_with_refunds: 109345.30325766886,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 97948.49483939294,
              net_with_refunds: 97948.49483939294,
              gross_without_refunds: 111159.77585322314,
              gross_with_refunds: 111159.77585322314,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 11,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 188661.9045089364,
              net_with_refunds: 188661.9045089364,
              gross_without_refunds: 216874.30148498528,
              gross_with_refunds: 216874.30148498528,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 93028.53060117929,
              net_with_refunds: 93028.53060117929,
              gross_without_refunds: 108407.09551348345,
              gross_with_refunds: 108407.09551348345,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 95633.37390775711,
              net_with_refunds: 95633.37390775711,
              gross_without_refunds: 108467.20597150184,
              gross_with_refunds: 108467.20597150184,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 12,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 185439.22343624255,
              net_with_refunds: 185439.22343624255,
              gross_without_refunds: 213295.7036612907,
              gross_with_refunds: 213295.7036612907,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 92383.27345943109,
              net_with_refunds: 92383.27345943109,
              gross_without_refunds: 107648.41906627594,
              gross_with_refunds: 107648.41906627594,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 93055.94997681146,
              net_with_refunds: 93055.94997681146,
              gross_without_refunds: 105647.28459501475,
              gross_with_refunds: 105647.28459501475,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 13,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 184149.4865021677,
              net_with_refunds: 184149.4865021677,
              gross_without_refunds: 211927.57527135548,
              gross_with_refunds: 211927.57527135548,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 91884.7551535846,
              net_with_refunds: 91884.7551535846,
              gross_without_refunds: 107048.29513694168,
              gross_with_refunds: 107048.29513694168,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 92264.73134858311,
              net_with_refunds: 92264.73134858311,
              gross_without_refunds: 104879.2801344138,
              gross_with_refunds: 104879.2801344138,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 14,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 181940.0862239076,
              net_with_refunds: 181940.0862239076,
              gross_without_refunds: 209423.57423120938,
              gross_with_refunds: 209423.57423120938,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 91974.45636755103,
              net_with_refunds: 91974.45636755103,
              gross_without_refunds: 107058.10363762437,
              gross_with_refunds: 107058.10363762437,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 89965.62985635658,
              net_with_refunds: 89965.62985635658,
              gross_without_refunds: 102365.470593585,
              gross_with_refunds: 102365.470593585,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 15,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 178812.7655909721,
              net_with_refunds: 178812.7655909721,
              gross_without_refunds: 206128.45011718225,
              gross_with_refunds: 206128.45011718225,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 91926.56034214533,
              net_with_refunds: 91926.56034214533,
              gross_without_refunds: 107133.78303165133,
              gross_with_refunds: 107133.78303165133,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 86886.20524882678,
              net_with_refunds: 86886.20524882678,
              gross_without_refunds: 98994.66708553092,
              gross_with_refunds: 98994.66708553092,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 16,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 176920.17456416454,
              net_with_refunds: 176920.17456416454,
              gross_without_refunds: 204089.04036887543,
              gross_with_refunds: 204089.04036887543,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 91885.62792074122,
              net_with_refunds: 91885.62792074122,
              gross_without_refunds: 107173.27327613773,
              gross_with_refunds: 107173.27327613773,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 85034.54664342334,
              net_with_refunds: 85034.54664342334,
              gross_without_refunds: 96915.76709273772,
              gross_with_refunds: 96915.76709273772,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 17,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 175130.40515768464,
              net_with_refunds: 175130.40515768464,
              gross_without_refunds: 202024.94334865795,
              gross_with_refunds: 202024.94334865795,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 90996.51337766983,
              net_with_refunds: 90996.51337766983,
              gross_without_refunds: 106146.52485068931,
              gross_with_refunds: 106146.52485068931,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 84133.89178001482,
              net_with_refunds: 84133.89178001482,
              gross_without_refunds: 95878.41849796864,
              gross_with_refunds: 95878.41849796864,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 18,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 171851.90435036304,
              net_with_refunds: 171851.90435036304,
              gross_without_refunds: 198421.8729545754,
              gross_with_refunds: 198421.8729545754,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 90911.20807639921,
              net_with_refunds: 90911.20807639921,
              gross_without_refunds: 106009.29679390101,
              gross_with_refunds: 106009.29679390101,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 80940.69627396383,
              net_with_refunds: 80940.69627396383,
              gross_without_refunds: 92412.5761606744,
              gross_with_refunds: 92412.5761606744,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 19,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 167093.9885435293,
              net_with_refunds: 167093.9885435293,
              gross_without_refunds: 193329.85901180824,
              gross_with_refunds: 193329.85901180824,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 90964.96883458282,
              net_with_refunds: 90964.96883458282,
              gross_without_refunds: 106042.53585109663,
              gross_with_refunds: 106042.53585109663,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 76129.01970894648,
              net_with_refunds: 76129.01970894648,
              gross_without_refunds: 87287.3231607116,
              gross_with_refunds: 87287.3231607116,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 20,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 166051.384688225,
              net_with_refunds: 166051.384688225,
              gross_without_refunds: 192102.63261036205,
              gross_with_refunds: 192102.63261036205,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 90933.11882406652,
              net_with_refunds: 90933.11882406652,
              gross_without_refunds: 106015.22290790308,
              gross_with_refunds: 106015.22290790308,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 75118.26586415849,
              net_with_refunds: 75118.26586415849,
              gross_without_refunds: 86087.40970245897,
              gross_with_refunds: 86087.40970245897,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 21,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 162141.23931469605,
              net_with_refunds: 162141.23931469605,
              gross_without_refunds: 187651.266845511,
              gross_with_refunds: 187651.266845511,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 90063.0020706739,
              net_with_refunds: 90063.0020706739,
              gross_without_refunds: 104945.69930968389,
              gross_with_refunds: 104945.69930968389,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 72078.23724402217,
              net_with_refunds: 72078.23724402217,
              gross_without_refunds: 82705.56753582713,
              gross_with_refunds: 82705.56753582713,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 22,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 162764.11367750884,
              net_with_refunds: 162764.11367750884,
              gross_without_refunds: 188164.08926556923,
              gross_with_refunds: 188164.08926556923,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 89562.81549999173,
              net_with_refunds: 89562.81549999173,
              gross_without_refunds: 104404.36155624872,
              gross_with_refunds: 104404.36155624872,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 73201.29817751712,
              net_with_refunds: 73201.29817751712,
              gross_without_refunds: 83759.7277093205,
              gross_with_refunds: 83759.7277093205,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 23,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 161235.86242919829,
              net_with_refunds: 161235.86242919829,
              gross_without_refunds: 186973.1768849041,
              gross_with_refunds: 186973.1768849041,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 89872.35521818984,
              net_with_refunds: 89872.35521818984,
              gross_without_refunds: 104801.66423074077,
              gross_with_refunds: 104801.66423074077,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 71363.50721100846,
              net_with_refunds: 71363.50721100846,
              gross_without_refunds: 82171.51265416332,
              gross_with_refunds: 82171.51265416332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 24,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 163943.62028416988,
              net_with_refunds: 163943.62028416988,
              gross_without_refunds: 190212.36333130306,
              gross_with_refunds: 190212.36333130306,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 90173.79687582681,
              net_with_refunds: 90173.79687582681,
              gross_without_refunds: 105116.46549480213,
              gross_with_refunds: 105116.46549480213,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 73769.82340834307,
              net_with_refunds: 73769.82340834307,
              gross_without_refunds: 85095.89783650092,
              gross_with_refunds: 85095.89783650092,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 25,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 166868.26409088157,
              net_with_refunds: 166868.26409088157,
              gross_without_refunds: 193529.53544510718,
              gross_with_refunds: 193529.53544510718,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 91032.32639051422,
              net_with_refunds: 91032.32639051422,
              gross_without_refunds: 106124.44584988618,
              gross_with_refunds: 106124.44584988618,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 75835.93770036736,
              net_with_refunds: 75835.93770036736,
              gross_without_refunds: 87405.089595221,
              gross_with_refunds: 87405.089595221,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 26,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 167668.30469529834,
              net_with_refunds: 167668.30469529834,
              gross_without_refunds: 194497.53522163222,
              gross_with_refunds: 194497.53522163222,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 91259.42931694169,
              net_with_refunds: 91259.42931694169,
              gross_without_refunds: 106337.1209753611,
              gross_with_refunds: 106337.1209753611,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 76408.87537835665,
              net_with_refunds: 76408.87537835665,
              gross_without_refunds: 88160.41424627113,
              gross_with_refunds: 88160.41424627113,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 27,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 169459.59526048048,
              net_with_refunds: 169459.59526048048,
              gross_without_refunds: 196209.0474928422,
              gross_with_refunds: 196209.0474928422,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 91766.85209622791,
              net_with_refunds: 91766.85209622791,
              gross_without_refunds: 106908.3758180689,
              gross_with_refunds: 106908.3758180689,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 77692.74316425258,
              net_with_refunds: 77692.74316425258,
              gross_without_refunds: 89300.6716747733,
              gross_with_refunds: 89300.6716747733,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 28,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 170968.16727682902,
              net_with_refunds: 170968.16727682902,
              gross_without_refunds: 198005.6306394285,
              gross_with_refunds: 198005.6306394285,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 92612.49486166204,
              net_with_refunds: 92612.49486166204,
              gross_without_refunds: 107971.91176724114,
              gross_with_refunds: 107971.91176724114,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 78355.67241516699,
              net_with_refunds: 78355.67241516699,
              gross_without_refunds: 90033.71887218738,
              gross_with_refunds: 90033.71887218738,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 29,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 175051.8000443069,
              net_with_refunds: 175051.8000443069,
              gross_without_refunds: 202579.47445432865,
              gross_with_refunds: 202579.47445432865,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 93249.42234016159,
              net_with_refunds: 93249.42234016159,
              gross_without_refunds: 108687.9450222322,
              gross_with_refunds: 108687.9450222322,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 81802.3777041453,
              net_with_refunds: 81802.3777041453,
              gross_without_refunds: 93891.52943209646,
              gross_with_refunds: 93891.52943209646,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 30,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 178611.88359953437,
              net_with_refunds: 178611.88359953437,
              gross_without_refunds: 206976.08226885606,
              gross_with_refunds: 206976.08226885606,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 95061.56555516529,
              net_with_refunds: 95061.56555516529,
              gross_without_refunds: 110893.96200663422,
              gross_with_refunds: 110893.96200663422,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 83550.31804436908,
              net_with_refunds: 83550.31804436908,
              gross_without_refunds: 96082.12026222184,
              gross_with_refunds: 96082.12026222184,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 31,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 181201.29247488175,
              net_with_refunds: 181201.29247488175,
              gross_without_refunds: 210152.19073540688,
              gross_with_refunds: 210152.19073540688,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 95187.8325388441,
              net_with_refunds: 95187.8325388441,
              gross_without_refunds: 111044.91000450683,
              gross_with_refunds: 111044.91000450683,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 86013.45993603766,
              net_with_refunds: 86013.45993603766,
              gross_without_refunds: 99107.28073090005,
              gross_with_refunds: 99107.28073090005,
            },
          },
        },
      },
    ],
    cash_flow: [
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 1,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 6896.999055223019,
              net_with_refunds: 5200.409055223017,
              gross_without_refunds: 7906.910022132684,
              gross_with_refunds: 6149.930022132685,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2988.0863520847934,
              net_with_refunds: 2920.6063520847933,
              gross_without_refunds: 3438.5799942210506,
              gross_with_refunds: 3364.59999422105,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 3908.912703138214,
              net_with_refunds: 2279.8027031382153,
              gross_without_refunds: 4468.330027911642,
              gross_with_refunds: 2785.330027911642,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 2,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 6336.768992975305,
              net_with_refunds: 5533.928355241435,
              gross_without_refunds: 7403.439277303091,
              gross_with_refunds: 6487.132659139348,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2731.0279667720906,
              net_with_refunds: 2663.237966772091,
              gross_without_refunds: 3154.902935002256,
              gross_with_refunds: 3081.9129350022563,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 3605.7410262032186,
              net_with_refunds: 2870.6903884693506,
              gross_without_refunds: 4248.536342300842,
              gross_with_refunds: 3405.219724137099,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 3,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5384.983683990831,
              net_with_refunds: 4184.96253998655,
              gross_without_refunds: 6057.364534771019,
              gross_with_refunds: 4737.198323179339,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2913.1448295461896,
              net_with_refunds: 2833.6948295461902,
              gross_without_refunds: 3394.672152985534,
              gross_with_refunds: 3310.672152985534,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2471.838854444596,
              net_with_refunds: 1351.2677104403174,
              gross_without_refunds: 2662.692381785494,
              gross_with_refunds: 1426.5261701938136,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 4,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5469.233962822224,
              net_with_refunds: 3857.016873383847,
              gross_without_refunds: 6223.779071273998,
              gross_with_refunds: 4496.50843402707,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3096.389494081992,
              net_with_refunds: 2951.501597860661,
              gross_without_refunds: 3649.667185657491,
              gross_with_refunds: 3476.992278717641,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2372.8444687402334,
              net_with_refunds: 905.5152755231866,
              gross_without_refunds: 2574.111885616509,
              gross_with_refunds: 1019.5161553094292,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 5,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 6756.45873888947,
              net_with_refunds: 5499.128865687098,
              gross_without_refunds: 7811.975086739694,
              gross_with_refunds: 6465.832167934486,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3086.5284850246885,
              net_with_refunds: 2913.2561782388652,
              gross_without_refunds: 3632.924339718012,
              gross_with_refunds: 3440.547884104855,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 3669.930253864782,
              net_with_refunds: 2585.8726874482345,
              gross_without_refunds: 4179.05074702168,
              gross_with_refunds: 3025.2842838296283,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 6,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5431.07905271459,
              net_with_refunds: 4569.636231111258,
              gross_without_refunds: 6286.563574241068,
              gross_with_refunds: 5289.684388174486,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3064.968417446616,
              net_with_refunds: 2985.5184174466167,
              gross_without_refunds: 3548.2816596347516,
              gross_with_refunds: 3464.281659634753,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2366.1106352679726,
              net_with_refunds: 1584.1178136646456,
              gross_without_refunds: 2738.281914606317,
              gross_with_refunds: 1825.4027285397372,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 7,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 4681.721368096426,
              net_with_refunds: 4263.339118167372,
              gross_without_refunds: 5456.966982825081,
              gross_with_refunds: 4998.054584765903,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2638.7769667171333,
              net_with_refunds: 2520.0562167880844,
              gross_without_refunds: 3063.8935166373894,
              gross_with_refunds: 2926.6711185782137,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2042.9444013792945,
              net_with_refunds: 1743.2829013792943,
              gross_without_refunds: 2393.0734661876927,
              gross_with_refunds: 2071.3834661876926,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 8,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 4203.634815461264,
              net_with_refunds: 3568.4762907043028,
              gross_without_refunds: 4877.550963189537,
              gross_with_refunds: 4187.281189979835,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2728.271148994967,
              net_with_refunds: 2576.3626242380074,
              gross_without_refunds: 3173.8363166262825,
              gross_with_refunds: 3005.1365434165778,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 1475.3636664662963,
              net_with_refunds: 992.1136664662961,
              gross_without_refunds: 1703.7146465632557,
              gross_with_refunds: 1182.1446465632557,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 9,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 4910.113569601734,
              net_with_refunds: 4443.098972886443,
              gross_without_refunds: 5614.910496299235,
              gross_with_refunds: 5076.277278606936,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2854.873287878183,
              net_with_refunds: 2700.9674672949764,
              gross_without_refunds: 3322.3765545130736,
              gross_with_refunds: 3153.2029728675607,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2055.2402817235347,
              net_with_refunds: 1742.1315055914683,
              gross_without_refunds: 2292.533941786166,
              gross_with_refunds: 1923.0743057393784,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 10,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5567.148073271801,
              net_with_refunds: 4785.486855381764,
              gross_without_refunds: 6670.595172311134,
              gross_with_refunds: 5829.165361935061,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3417.846091065784,
              net_with_refunds: 3327.356091065785,
              gross_without_refunds: 4103.14981167236,
              gross_with_refunds: 4006.1598116723612,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2149.3019822060246,
              net_with_refunds: 1458.1307643159876,
              gross_without_refunds: 2567.4453606387833,
              gross_with_refunds: 1823.005550262712,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 11,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 4476.882585407879,
              net_with_refunds: 3370.020994038254,
              gross_without_refunds: 5148.859679217059,
              gross_with_refunds: 3892.406927470148,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2705.80651820287,
              net_with_refunds: 2591.8065540932253,
              gross_without_refunds: 3147.2529872096484,
              gross_with_refunds: 3014.4130065058143,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 1771.0760672049937,
              net_with_refunds: 778.2144399450277,
              gross_without_refunds: 2001.6066920074177,
              gross_with_refunds: 877.9939209643383,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 12,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5506.666721325631,
              net_with_refunds: 4615.796721325629,
              gross_without_refunds: 6507.275839886975,
              gross_with_refunds: 5581.295839886975,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3227.0352177776645,
              net_with_refunds: 3102.8052177776653,
              gross_without_refunds: 3764.520814971727,
              gross_with_refunds: 3630.5408149717273,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2279.6315035479743,
              net_with_refunds: 1512.9915035479748,
              gross_without_refunds: 2742.755024915252,
              gross_with_refunds: 1950.7550249152519,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 13,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 7797.063922460061,
              net_with_refunds: 5695.1068884522665,
              gross_without_refunds: 8811.289246956652,
              gross_with_refunds: 6593.10241470622,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3763.03364829986,
              net_with_refunds: 3623.996614292103,
              gross_without_refunds: 4339.977267981112,
              gross_with_refunds: 4183.770435730673,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 4034.030274160162,
              net_with_refunds: 2071.1102741601635,
              gross_without_refunds: 4471.311978975547,
              gross_with_refunds: 2409.331978975548,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 14,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5598.959324476107,
              net_with_refunds: 5105.550529000208,
              gross_without_refunds: 6549.483913980983,
              gross_with_refunds: 6031.874246729519,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3651.7436947693336,
              net_with_refunds: 3574.7848992934373,
              gross_without_refunds: 4249.314121506931,
              gross_with_refunds: 4161.514454255469,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 1947.215629706777,
              net_with_refunds: 1530.7656297067767,
              gross_without_refunds: 2300.169792474048,
              gross_with_refunds: 1870.3597924740477,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 15,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5061.636783118624,
              net_with_refunds: 4700.882265660196,
              gross_without_refunds: 6120.207313991034,
              gross_with_refunds: 5709.765397049352,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3394.0944500852133,
              net_with_refunds: 3348.6944500852137,
              gross_without_refunds: 4027.318686171106,
              gross_with_refunds: 3979.3186861711056,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 1667.5423330334168,
              net_with_refunds: 1352.1878155749898,
              gross_without_refunds: 2092.8886278199316,
              gross_with_refunds: 1730.4467108782494,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 16,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 6770.097177988095,
              net_with_refunds: 6289.547177988093,
              gross_without_refunds: 7732.713805137942,
              gross_with_refunds: 7234.713805137942,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3161.434829897275,
              net_with_refunds: 3093.3348298972755,
              gross_without_refunds: 3711.720098211574,
              gross_with_refunds: 3639.720098211574,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 3608.6623480907597,
              net_with_refunds: 3196.21234809076,
              gross_without_refunds: 4020.993706926381,
              gross_with_refunds: 3594.993706926381,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 17,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 6182.483024032518,
              net_with_refunds: 5286.674526394671,
              gross_without_refunds: 7052.165169181433,
              gross_with_refunds: 6124.857983275295,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2995.451912299816,
              net_with_refunds: 2953.673414661969,
              gross_without_refunds: 3442.348580657922,
              gross_with_refunds: 3397.0413947517836,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 3187.031111732634,
              net_with_refunds: 2333.001111732635,
              gross_without_refunds: 3609.8165885235176,
              gross_with_refunds: 2727.8165885235176,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 18,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5662.4246677835445,
              net_with_refunds: 5024.767647448074,
              gross_without_refunds: 6517.148351299229,
              gross_with_refunds: 5849.415948174993,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3763.0998803891803,
              net_with_refunds: 3604.69286005371,
              gross_without_refunds: 4326.940665988673,
              gross_with_refunds: 4153.998262864437,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 1899.3247873943658,
              net_with_refunds: 1420.0747873943656,
              gross_without_refunds: 2190.207685310557,
              gross_with_refunds: 1695.4176853105566,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 19,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5989.633080095123,
              net_with_refunds: 5014.017846350142,
              gross_without_refunds: 6892.982716042677,
              gross_with_refunds: 5788.425489163353,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3601.187539707467,
              net_with_refunds: 3499.037539707467,
              gross_without_refunds: 4171.366436930644,
              gross_with_refunds: 4063.366436930645,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2388.445540387651,
              net_with_refunds: 1514.9803066426837,
              gross_without_refunds: 2721.616279112034,
              gross_with_refunds: 1725.05905223271,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 20,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 7571.5284547557085,
              net_with_refunds: 5825.4997117392095,
              gross_without_refunds: 8557.729006161082,
              gross_with_refunds: 6615.964028229189,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3413.9139671373873,
              net_with_refunds: 3151.5780415670797,
              gross_without_refunds: 3958.6783048508487,
              gross_with_refunds: 3669.8430596765284,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 4157.6144876183225,
              net_with_refunds: 2673.921670172132,
              gross_without_refunds: 4599.050701310232,
              gross_with_refunds: 2946.120968552662,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 21,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 5052.639219359772,
              net_with_refunds: 4593.079219359771,
              gross_without_refunds: 5776.882119815968,
              gross_with_refunds: 5294.122119815967,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 2864.0048449114724,
              net_with_refunds: 2787.7648449114727,
              gross_without_refunds: 3300.436825626767,
              gross_with_refunds: 3213.6768256267674,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 2188.6343744482647,
              net_with_refunds: 1805.314374448265,
              gross_without_refunds: 2476.4452941892096,
              gross_with_refunds: 2080.4452941892096,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 22,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 8020.136344925076,
              net_with_refunds: 7253.833100247584,
              gross_without_refunds: 9138.786797506978,
              gross_with_refunds: 8278.504083002825,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3376.471549043781,
              net_with_refunds: 3290.9049574177984,
              gross_without_refunds: 3942.8417450869897,
              gross_with_refunds: 3845.7663771913135,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 4643.664795881231,
              net_with_refunds: 3962.928142829725,
              gross_without_refunds: 5195.945052419994,
              gross_with_refunds: 4432.737705811514,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 23,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 8398.508353188638,
              net_with_refunds: 7974.978353188635,
              gross_without_refunds: 9695.587253690563,
              gross_with_refunds: 9254.997253690563,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3542.4432514359214,
              net_with_refunds: 3446.0032514359223,
              gross_without_refunds: 4146.70862896415,
              gross_with_refunds: 4042.7186289641504,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 4856.065101752648,
              net_with_refunds: 4528.975101752648,
              gross_without_refunds: 5548.878624726423,
              gross_with_refunds: 5212.278624726423,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 24,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 9470.790250628394,
              net_with_refunds: 8660.43138287086,
              gross_without_refunds: 10997.448704672512,
              gross_with_refunds: 10019.662554966213,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3558.988514069283,
              net_with_refunds: 3475.755214457812,
              gross_without_refunds: 4125.392328996102,
              gross_with_refunds: 4028.145466758884,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5911.801736559111,
              net_with_refunds: 5184.676168413063,
              gross_without_refunds: 6872.056375676409,
              gross_with_refunds: 5991.517088207336,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 25,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 9478.153953400446,
              net_with_refunds: 8430.256979648553,
              gross_without_refunds: 10804.551597490597,
              gross_with_refunds: 9631.208298753325,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3652.9760403371183,
              net_with_refunds: 3571.8861350056136,
              gross_without_refunds: 4267.708652833194,
              gross_with_refunds: 4175.883120426168,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5825.177913063258,
              net_with_refunds: 4858.37084464291,
              gross_without_refunds: 6536.842944657412,
              gross_with_refunds: 5455.325178327157,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 26,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 8527.461304739327,
              net_with_refunds: 7687.875956198331,
              gross_without_refunds: 9794.937006201897,
              gross_with_refunds: 8889.185417822766,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3303.9745057909945,
              net_with_refunds: 3277.349157249998,
              gross_without_refunds: 3823.998972770749,
              gross_with_refunds: 3792.2273843916173,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5223.486798948258,
              net_with_refunds: 4410.526798948259,
              gross_without_refunds: 5970.938033431155,
              gross_with_refunds: 5096.958033431155,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 27,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 9265.952861365551,
              net_with_refunds: 8154.57274598562,
              gross_without_refunds: 10320.376993443928,
              gross_with_refunds: 9099.392708986017,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3909.5130563146927,
              net_with_refunds: 3608.771556314695,
              gross_without_refunds: 4524.7223553615595,
              gross_with_refunds: 4202.9323553615595,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5356.43980505078,
              net_with_refunds: 4545.801189670858,
              gross_without_refunds: 5795.654638082372,
              gross_with_refunds: 4896.460353624462,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 28,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 8878.915298111893,
              net_with_refunds: 8450.225515546243,
              gross_without_refunds: 10210.890153145985,
              gross_with_refunds: 9751.12384877603,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3640.628046311904,
              net_with_refunds: 3499.4282637462584,
              gross_without_refunds: 4260.999164409569,
              gross_with_refunds: 4098.232860039618,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5238.287251799947,
              net_with_refunds: 4950.797251799948,
              gross_without_refunds: 5949.890988736427,
              gross_with_refunds: 5652.890988736427,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 29,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 9380.149495225809,
              net_with_refunds: 8486.7370054312,
              gross_without_refunds: 10675.672984569237,
              gross_with_refunds: 9594.385090887323,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3569.4616957603303,
              net_with_refunds: 3396.143671025946,
              gross_without_refunds: 4110.766602019372,
              gross_with_refunds: 3922.3625724870635,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5810.6877994654005,
              net_with_refunds: 5090.593334405194,
              gross_without_refunds: 6564.906382549865,
              gross_with_refunds: 5672.0225184002575,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 30,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 9418.280376954173,
              net_with_refunds: 8619.867019851177,
              gross_without_refunds: 11246.69077778989,
              gross_with_refunds: 10306.95943325717,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3807.284299725581,
              net_with_refunds: 3748.5560710752425,
              gross_without_refunds: 4479.621007023393,
              gross_with_refunds: 4413.590248838293,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5610.996077228593,
              net_with_refunds: 4871.310948775941,
              gross_without_refunds: 6767.069770766491,
              gross_with_refunds: 5893.369184418871,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 31,
        },
        data: {
          combined: {
            actual: {
              net_without_refunds: 8345.46009334087,
              net_with_refunds: 7587.428039521305,
              gross_without_refunds: 9844.088817107953,
              gross_with_refunds: 8936.512495767609,
            },
            avg_calc: {
              net_without_refunds: 6790.063374378385,
              net_with_refunds: 5894.601057549004,
              gross_without_refunds: 7829.22011059281,
              gross_with_refunds: 6844.998057916901,
            },
          },
          monthly: {
            actual: {
              net_without_refunds: 3062.6094749868344,
              net_with_refunds: 3020.1128648947533,
              gross_without_refunds: 3547.0555244393563,
              gross_with_refunds: 3491.243314978794,
            },
            avg_calc: {
              net_without_refunds: 3273.7119347376265,
              net_with_refunds: 3163.2141338806687,
              gross_without_refunds: 3811.3540076993413,
              gross_with_refunds: 3688.725263107574,
            },
          },
          annually: {
            actual: {
              net_without_refunds: 5282.850618353972,
              net_with_refunds: 4567.3151746264875,
              gross_without_refunds: 6297.033292668603,
              gross_with_refunds: 5445.269180788822,
            },
            avg_calc: {
              net_without_refunds: 3516.351439640732,
              net_with_refunds: 2731.3869236683167,
              gross_without_refunds: 4017.866102893473,
              gross_with_refunds: 3156.272794809332,
            },
          },
        },
      },
    ],
  },
  monthly: {
    revenue: [
      {
        anchor_date: {
          year: 2023,
          month: 2,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 1132.6747409893055,
            net_with_refunds: 1132.6747409893055,
            gross_without_refunds: 1308.9818889507837,
            gross_with_refunds: 1308.9818889507837,
          },
          monthly: {
            net_without_refunds: 1098.994740989306,
            net_with_refunds: 1098.994740989306,
            gross_without_refunds: 1273.9818889507837,
            gross_with_refunds: 1273.9818889507837,
          },
          annually: {
            net_without_refunds: 33.68,
            net_with_refunds: 33.68,
            gross_without_refunds: 35,
            gross_with_refunds: 35,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 3,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 2739.2182808983844,
            net_with_refunds: 2739.2182808983844,
            gross_without_refunds: 3369.5592403780724,
            gross_with_refunds: 3369.5592403780724,
          },
          monthly: {
            net_without_refunds: 2179.232280898384,
            net_with_refunds: 2179.232280898384,
            gross_without_refunds: 2561.519240378074,
            gross_with_refunds: 2561.519240378074,
          },
          annually: {
            net_without_refunds: 559.986,
            net_with_refunds: 559.986,
            gross_without_refunds: 808.04,
            gross_with_refunds: 808.04,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 4,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 5034.824293973828,
            net_with_refunds: 5034.824293973828,
            gross_without_refunds: 6425.310734281447,
            gross_with_refunds: 6425.310734281447,
          },
          monthly: {
            net_without_refunds: 4019.4927130961687,
            net_with_refunds: 4019.4927130961687,
            gross_without_refunds: 4814.1988348053965,
            gross_with_refunds: 4814.1988348053965,
          },
          annually: {
            net_without_refunds: 1015.3315808776601,
            net_with_refunds: 1015.3315808776601,
            gross_without_refunds: 1611.111899476052,
            gross_with_refunds: 1611.111899476052,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 5,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 7246.754214257716,
            net_with_refunds: 7246.754214257716,
            gross_without_refunds: 9259.503738187217,
            gross_with_refunds: 9259.503738187217,
          },
          monthly: {
            net_without_refunds: 6131.139809358526,
            net_with_refunds: 6131.139809358526,
            gross_without_refunds: 7593.978429457868,
            gross_with_refunds: 7593.978429457868,
          },
          annually: {
            net_without_refunds: 1115.6144048991898,
            net_with_refunds: 1115.6144048991898,
            gross_without_refunds: 1665.5253087293531,
            gross_with_refunds: 1665.5253087293531,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 6,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 17230.54456082593,
            net_with_refunds: 17230.54456082593,
            gross_without_refunds: 22317.472933296893,
            gross_with_refunds: 22317.472933296893,
          },
          monthly: {
            net_without_refunds: 13607.591640612669,
            net_with_refunds: 13607.591640612669,
            gross_without_refunds: 16702.67886375507,
            gross_with_refunds: 16702.67886375507,
          },
          annually: {
            net_without_refunds: 3622.9529202132467,
            net_with_refunds: 3622.9529202132467,
            gross_without_refunds: 5614.7940695418065,
            gross_with_refunds: 5614.7940695418065,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 7,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 48365.5953475943,
            net_with_refunds: 48365.5953475943,
            gross_without_refunds: 65097.515037074765,
            gross_with_refunds: 65097.515037074765,
          },
          monthly: {
            net_without_refunds: 35461.38189951807,
            net_with_refunds: 35461.38189951807,
            gross_without_refunds: 45138.95459525602,
            gross_with_refunds: 45138.95459525602,
          },
          annually: {
            net_without_refunds: 12904.213448076212,
            net_with_refunds: 12904.213448076212,
            gross_without_refunds: 19958.560441818718,
            gross_with_refunds: 19958.560441818718,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 8,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 75287.31336947418,
            net_with_refunds: 75287.31336947418,
            gross_without_refunds: 98392.33265771542,
            gross_with_refunds: 98392.33265771542,
          },
          monthly: {
            net_without_refunds: 59829.99738690986,
            net_with_refunds: 59829.99738690986,
            gross_without_refunds: 74724.095318617,
            gross_with_refunds: 74724.095318617,
          },
          annually: {
            net_without_refunds: 15457.315982564332,
            net_with_refunds: 15457.315982564332,
            gross_without_refunds: 23668.23733909838,
            gross_with_refunds: 23668.23733909838,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 9,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 113240.46405001922,
            net_with_refunds: 113240.46405001922,
            gross_without_refunds: 134296.26433710504,
            gross_with_refunds: 134296.26433710504,
          },
          monthly: {
            net_without_refunds: 75790.13725452554,
            net_with_refunds: 75790.13725452554,
            gross_without_refunds: 90297.70481129133,
            gross_with_refunds: 90297.70481129133,
          },
          annually: {
            net_without_refunds: 37450.3267954937,
            net_with_refunds: 37450.3267954937,
            gross_without_refunds: 43998.55952581365,
            gross_with_refunds: 43998.55952581365,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 10,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 197963.86982587902,
            net_with_refunds: 197963.86982587902,
            gross_without_refunds: 225086.3116602086,
            gross_with_refunds: 225086.3116602086,
          },
          monthly: {
            net_without_refunds: 94777.03013154706,
            net_with_refunds: 94777.03013154706,
            gross_without_refunds: 111091.90616809054,
            gross_with_refunds: 111091.90616809054,
          },
          annually: {
            net_without_refunds: 103186.83969433192,
            net_with_refunds: 103186.83969433192,
            gross_without_refunds: 113994.4054921182,
            gross_with_refunds: 113994.4054921182,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 11,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 221715.4482063826,
            net_with_refunds: 221715.4482063826,
            gross_without_refunds: 252769.7139434963,
            gross_with_refunds: 252769.7139434963,
          },
          monthly: {
            net_without_refunds: 98558.88887328254,
            net_with_refunds: 98558.88887328254,
            gross_without_refunds: 115339.55898801443,
            gross_with_refunds: 115339.55898801443,
          },
          annually: {
            net_without_refunds: 123156.55933309998,
            net_with_refunds: 123156.55933309998,
            gross_without_refunds: 137430.15495548188,
            gross_with_refunds: 137430.15495548188,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 12,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 214577.6150060173,
            net_with_refunds: 214577.6150060173,
            gross_without_refunds: 246150.42830983028,
            gross_with_refunds: 246150.42830983028,
          },
          monthly: {
            net_without_refunds: 100900.57875682885,
            net_with_refunds: 100900.57875682885,
            gross_without_refunds: 117594.41247542403,
            gross_with_refunds: 117594.41247542403,
          },
          annually: {
            net_without_refunds: 113677.0362491884,
            net_with_refunds: 113677.0362491884,
            gross_without_refunds: 128556.01583440644,
            gross_with_refunds: 128556.01583440644,
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 184010.05729773932,
            net_with_refunds: 184010.05729773932,
            gross_without_refunds: 213393.67537806966,
            gross_with_refunds: 213393.67537806966,
          },
          monthly: {
            net_without_refunds: 97996.59736170167,
            net_with_refunds: 97996.59736170167,
            gross_without_refunds: 114286.39464716968,
            gross_with_refunds: 114286.39464716968,
          },
          annually: {
            net_without_refunds: 86013.4599360376,
            net_with_refunds: 86013.4599360376,
            gross_without_refunds: 99107.2807309001,
            gross_with_refunds: 99107.2807309001,
          },
        },
      },
    ],
    cash_flow: [
      {
        anchor_date: {
          year: 2023,
          month: 2,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 1247.7282990506885,
            net_with_refunds: 1149.4467409893052,
            gross_without_refunds: 1448.3688523088324,
            gross_with_refunds: 1333.9118889507831,
          },
          monthly: {
            net_without_refunds: 1214.0482990506887,
            net_with_refunds: 1115.7667409893052,
            gross_without_refunds: 1413.3688523088324,
            gross_with_refunds: 1298.9118889507834,
          },
          annually: {
            net_without_refunds: 33.68,
            net_with_refunds: 33.68,
            gross_without_refunds: 35,
            gross_with_refunds: 35,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 3,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 2902.29956417783,
            net_with_refunds: 2771.08650174497,
            gross_without_refunds: 3568.3228074952763,
            gross_with_refunds: 3414.4208994852356,
          },
          monthly: {
            net_without_refunds: 2342.313564177831,
            net_with_refunds: 2211.1005017449697,
            gross_without_refunds: 2760.282807495276,
            gross_with_refunds: 2606.3808994852357,
          },
          annually: {
            net_without_refunds: 559.986,
            net_with_refunds: 559.986,
            gross_without_refunds: 808.04,
            gross_with_refunds: 808.04,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 4,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 5385.057487167501,
            net_with_refunds: 5049.30329397383,
            gross_without_refunds: 6908.994923672705,
            gross_with_refunds: 6471.870734281452,
          },
          monthly: {
            net_without_refunds: 4155.244756520811,
            net_with_refunds: 3963.9717130961694,
            gross_without_refunds: 4969.45579816345,
            gross_with_refunds: 4760.768834805401,
          },
          annually: {
            net_without_refunds: 1229.8127306466886,
            net_with_refunds: 1085.3315808776597,
            gross_without_refunds: 1939.5391255092563,
            gross_with_refunds: 1711.1018994760518,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 5,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 7493.771308753312,
            net_with_refunds: 7167.693214257713,
            gross_without_refunds: 9580.283395799079,
            gross_with_refunds: 9155.583738187224,
          },
          monthly: {
            net_without_refunds: 6308.156903854122,
            net_with_refunds: 6122.078809358525,
            gross_without_refunds: 7814.768087069723,
            gross_with_refunds: 7590.048429457872,
          },
          annually: {
            net_without_refunds: 1185.6144048991898,
            net_with_refunds: 1045.6144048991898,
            gross_without_refunds: 1765.5153087293531,
            gross_with_refunds: 1565.5353087293531,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 6,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 18024.447636253622,
            net_with_refunds: 17309.375949833717,
            gross_without_refunds: 23434.180121965423,
            gross_with_refunds: 22441.809841974136,
          },
          monthly: {
            net_without_refunds: 13898.429945462198,
            net_with_refunds: 13546.430029620467,
            gross_without_refunds: 17063.776048260883,
            gross_with_refunds: 16627.03577243233,
          },
          annually: {
            net_without_refunds: 4126.017690791423,
            net_with_refunds: 3762.945920213248,
            gross_without_refunds: 6370.404073704543,
            gross_with_refunds: 5814.774069541807,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 7,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 50476.14726359563,
            net_with_refunds: 48564.241902930014,
            gross_without_refunds: 68084.98451548081,
            gross_with_refunds: 65409.20853807006,
          },
          monthly: {
            net_without_refunds: 36604.70844623288,
            net_with_refunds: 35580.85577348705,
            gross_without_refunds: 46547.84063851912,
            gross_with_refunds: 45301.13665001134,
          },
          annually: {
            net_without_refunds: 13871.438817362814,
            net_with_refunds: 12983.386129443044,
            gross_without_refunds: 21537.143876961694,
            gross_with_refunds: 20108.071888058734,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 8,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 78377.89925707241,
            net_with_refunds: 75574.76702685717,
            gross_without_refunds: 102450.66354465399,
            gross_with_refunds: 98575.2231597149,
          },
          monthly: {
            net_without_refunds: 61858.53340346775,
            net_with_refunds: 60266.40355499345,
            gross_without_refunds: 77134.54591260215,
            gross_with_refunds: 75156.16129637696,
          },
          annually: {
            net_without_refunds: 16519.365853604704,
            net_with_refunds: 15308.363471863764,
            gross_without_refunds: 25316.117632051868,
            gross_with_refunds: 23419.061863337978,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 9,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 120290.11403522875,
            net_with_refunds: 115559.09415111892,
            gross_without_refunds: 142339.0950146426,
            gross_with_refunds: 136853.61374987484,
          },
          monthly: {
            net_without_refunds: 77423.60764749783,
            net_with_refunds: 75996.9420396403,
            gross_without_refunds: 92232.03602364646,
            gross_with_refunds: 90499.00998381185,
          },
          annually: {
            net_without_refunds: 42866.50638773073,
            net_with_refunds: 39562.15211147845,
            gross_without_refunds: 50107.05899099623,
            gross_with_refunds: 46354.60376606307,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 10,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 218040.45297471262,
            net_with_refunds: 199253.2810447742,
            gross_without_refunds: 246915.83112159697,
            gross_with_refunds: 226457.95513042054,
          },
          monthly: {
            net_without_refunds: 97101.73794201267,
            net_with_refunds: 94798.19770632891,
            gross_without_refunds: 113830.3741827518,
            gross_with_refunds: 111097.68752109057,
          },
          annually: {
            net_without_refunds: 120938.71503269869,
            net_with_refunds: 104455.08333844434,
            gross_without_refunds: 133085.45693884522,
            gross_with_refunds: 115360.26760933004,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 11,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 246586.61938164983,
            net_with_refunds: 222566.27324259345,
            gross_without_refunds: 280786.9884229655,
            gross_with_refunds: 253865.25525286948,
          },
          monthly: {
            net_without_refunds: 101239.79635332985,
            net_with_refunds: 98374.84453985284,
            gross_without_refunds: 118478.55328390302,
            gross_with_refunds: 115152.78404125718,
          },
          annually: {
            net_without_refunds: 145346.82302831797,
            net_with_refunds: 124191.42870273897,
            gross_without_refunds: 162308.43513906255,
            gross_with_refunds: 138712.4712116124,
          },
        },
      },
      {
        anchor_date: {
          year: 2023,
          month: 12,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 239362.2472824408,
            net_with_refunds: 214238.5085587342,
            gross_without_refunds: 273949.45703126205,
            gross_with_refunds: 245627.82116790683,
          },
          monthly: {
            net_without_refunds: 104390.59649221852,
            net_with_refunds: 101447.95713784393,
            gross_without_refunds: 121537.27084229406,
            gross_with_refunds: 118228.29516749716,
          },
          annually: {
            net_without_refunds: 134971.65079022088,
            net_with_refunds: 112790.55142088901,
            gross_without_refunds: 152412.18618896813,
            gross_with_refunds: 127399.5260004099,
          },
        },
      },
      {
        anchor_date: {
          year: 2024,
          month: 1,
          day: 1,
        },
        actual: {
          combined: {
            net_without_refunds: 210491.96460572985,
            net_with_refunds: 182732.6327840191,
            gross_without_refunds: 242705.82342837704,
            gross_with_refunds: 212194.93979542388,
          },
          monthly: {
            net_without_refunds: 101485.06997686642,
            net_with_refunds: 98059.63815030071,
            gross_without_refunds: 118151.97423867961,
            gross_with_refunds: 114350.48315633478,
          },
          annually: {
            net_without_refunds: 109006.89462886268,
            net_with_refunds: 84672.9946337178,
            gross_without_refunds: 124553.84918969768,
            gross_with_refunds: 97844.45663908929,
          },
        },
      },
    ],
  },
};

export const compareCohortsMockData = {
  rows: [
    {
      cohort_date: {
        year: 2023,
        month: 7,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 3346,
        with_refunds: 3346,
      },
      first_period_arr_users: {
        without_refunds: 263,
        with_refunds: 263,
      },
      first_period_arr_spread_users: {
        without_refunds: 241,
        with_refunds: 241,
      },
      first_period_total_users: {
        without_refunds: 3575,
        with_refunds: 3575,
      },
      first_period_total_spread_users: {
        without_refunds: 3557,
        with_refunds: 3557,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2052,
                  with_refunds: 2052,
                },
                arr_users: {
                  without_refunds: 172,
                  with_refunds: 172,
                },
                arr_spread_users: {
                  without_refunds: 159,
                  with_refunds: 159,
                },
                total_users: {
                  without_refunds: 2224,
                  with_refunds: 2224,
                },
                total_spread_users: {
                  without_refunds: 2211,
                  with_refunds: 2211,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6133,
                  with_refunds: 0.6133,
                },
                arr_users: {
                  without_refunds: 0.654,
                  with_refunds: 0.654,
                },
                arr_spread_users: {
                  without_refunds: 0.6598,
                  with_refunds: 0.6598,
                },
                total_users: {
                  without_refunds: 0.6221,
                  with_refunds: 0.6221,
                },
                total_spread_users: {
                  without_refunds: 0.6216,
                  with_refunds: 0.6216,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 20851.15,
                  with_refunds: 20313.22,
                },
                gross_mrr: {
                  without_refunds: 27753.14,
                  with_refunds: 27087.39,
                },
                net_arr: {
                  without_refunds: 12252.2,
                  with_refunds: 11504.14,
                },
                gross_arr: {
                  without_refunds: 19154.82,
                  with_refunds: 17925.73,
                },
                net_arr_spread: {
                  without_refunds: 940.41,
                  with_refunds: 940.41,
                },
                gross_arr_spread: {
                  without_refunds: 1464.69,
                  with_refunds: 1464.69,
                },
                net_total: {
                  without_refunds: 33103.36,
                  with_refunds: 31817.36,
                },
                gross_total: {
                  without_refunds: 46907.96,
                  with_refunds: 45013.12,
                },
                net_total_spread: {
                  without_refunds: 21791.57,
                  with_refunds: 21253.63,
                },
                gross_total_spread: {
                  without_refunds: 29217.83,
                  with_refunds: 28552.07,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.23,
                  with_refunds: 6.07,
                },
                gross_mrr: {
                  without_refunds: 8.29,
                  with_refunds: 8.1,
                },
                net_arr: {
                  without_refunds: 46.59,
                  with_refunds: 43.74,
                },
                gross_arr: {
                  without_refunds: 72.83,
                  with_refunds: 68.16,
                },
                net_arr_spread: {
                  without_refunds: 3.9,
                  with_refunds: 3.9,
                },
                gross_arr_spread: {
                  without_refunds: 6.08,
                  with_refunds: 6.08,
                },
                net_total: {
                  without_refunds: 9.26,
                  with_refunds: 8.9,
                },
                gross_total: {
                  without_refunds: 13.12,
                  with_refunds: 12.59,
                },
                net_total_spread: {
                  without_refunds: 6.13,
                  with_refunds: 5.98,
                },
                gross_total_spread: {
                  without_refunds: 8.21,
                  with_refunds: 8.03,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.16,
                  with_refunds: 9.9,
                },
                gross_mrr: {
                  without_refunds: 13.52,
                  with_refunds: 13.2,
                },
                net_arr: {
                  without_refunds: 71.23,
                  with_refunds: 66.88,
                },
                gross_arr: {
                  without_refunds: 111.37,
                  with_refunds: 104.22,
                },
                net_arr_spread: {
                  without_refunds: 5.91,
                  with_refunds: 5.91,
                },
                gross_arr_spread: {
                  without_refunds: 9.21,
                  with_refunds: 9.21,
                },
                net_total: {
                  without_refunds: 14.88,
                  with_refunds: 14.31,
                },
                gross_total: {
                  without_refunds: 21.09,
                  with_refunds: 20.24,
                },
                net_total_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.61,
                },
                gross_total_spread: {
                  without_refunds: 13.21,
                  with_refunds: 12.91,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 6.23,
                    with_refunds: 6.07,
                  },
                  gross_mrr: {
                    without_refunds: 8.29,
                    with_refunds: 8.1,
                  },
                  net_arr: {
                    without_refunds: 46.59,
                    with_refunds: 43.74,
                  },
                  gross_arr: {
                    without_refunds: 72.83,
                    with_refunds: 68.16,
                  },
                  net_arr_spread: {
                    without_refunds: 3.9,
                    with_refunds: 3.9,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.08,
                    with_refunds: 6.08,
                  },
                  net_total: {
                    without_refunds: 9.26,
                    with_refunds: 8.9,
                  },
                  gross_total: {
                    without_refunds: 13.12,
                    with_refunds: 12.59,
                  },
                  net_total_spread: {
                    without_refunds: 6.13,
                    with_refunds: 5.98,
                  },
                  gross_total_spread: {
                    without_refunds: 8.21,
                    with_refunds: 8.03,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.16,
                    with_refunds: 9.9,
                  },
                  gross_mrr: {
                    without_refunds: 13.52,
                    with_refunds: 13.2,
                  },
                  net_arr: {
                    without_refunds: 71.23,
                    with_refunds: 66.88,
                  },
                  gross_arr: {
                    without_refunds: 111.37,
                    with_refunds: 104.22,
                  },
                  net_arr_spread: {
                    without_refunds: 5.91,
                    with_refunds: 5.91,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.21,
                    with_refunds: 9.21,
                  },
                  net_total: {
                    without_refunds: 14.88,
                    with_refunds: 14.31,
                  },
                  gross_total: {
                    without_refunds: 21.09,
                    with_refunds: 20.24,
                  },
                  net_total_spread: {
                    without_refunds: 9.86,
                    with_refunds: 9.61,
                  },
                  gross_total_spread: {
                    without_refunds: 13.21,
                    with_refunds: 12.91,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1418,
                    with_refunds: 0.1381,
                  },
                  gross_mrr: {
                    without_refunds: 0.1887,
                    with_refunds: 0.1842,
                  },
                  net_arr: {
                    without_refunds: 1.06,
                    with_refunds: 0.9953,
                  },
                  gross_arr: {
                    without_refunds: 1.6572,
                    with_refunds: 1.5508,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0888,
                    with_refunds: 0.0888,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1383,
                    with_refunds: 0.1383,
                  },
                  net_total: {
                    without_refunds: 0.2107,
                    with_refunds: 0.2025,
                  },
                  gross_total: {
                    without_refunds: 0.2985,
                    with_refunds: 0.2865,
                  },
                  net_total_spread: {
                    without_refunds: 0.1394,
                    with_refunds: 0.136,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1869,
                    with_refunds: 0.1826,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2312,
                    with_refunds: 0.2252,
                  },
                  gross_mrr: {
                    without_refunds: 0.3077,
                    with_refunds: 0.3004,
                  },
                  net_arr: {
                    without_refunds: 1.6208,
                    with_refunds: 1.5218,
                  },
                  gross_arr: {
                    without_refunds: 2.5339,
                    with_refunds: 2.3713,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1346,
                    with_refunds: 0.1346,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2096,
                    with_refunds: 0.2096,
                  },
                  net_total: {
                    without_refunds: 0.3387,
                    with_refunds: 0.3255,
                  },
                  gross_total: {
                    without_refunds: 0.4799,
                    with_refunds: 0.4605,
                  },
                  net_total_spread: {
                    without_refunds: 0.2243,
                    with_refunds: 0.2187,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3007,
                    with_refunds: 0.2938,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2504,
                  with_refunds: 2518,
                },
                arr_users: {
                  without_refunds: 50,
                  with_refunds: 53,
                },
                arr_spread_users: {
                  without_refunds: 206,
                  with_refunds: 206,
                },
                total_users: {
                  without_refunds: 2554,
                  with_refunds: 2571,
                },
                total_spread_users: {
                  without_refunds: 2710,
                  with_refunds: 2724,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7484,
                  with_refunds: 0.7525,
                },
                arr_users: {
                  without_refunds: 0.1901,
                  with_refunds: 0.2015,
                },
                arr_spread_users: {
                  without_refunds: 0.8548,
                  with_refunds: 0.8548,
                },
                total_users: {
                  without_refunds: 0.7144,
                  with_refunds: 0.7192,
                },
                total_spread_users: {
                  without_refunds: 0.7619,
                  with_refunds: 0.7658,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.2203,
                  with_refunds: 1.2271,
                },
                arr_users: {
                  without_refunds: 0.2907,
                  with_refunds: 0.3081,
                },
                arr_spread_users: {
                  without_refunds: 1.2956,
                  with_refunds: 1.2956,
                },
                total_users: {
                  without_refunds: 1.1484,
                  with_refunds: 1.156,
                },
                total_spread_users: {
                  without_refunds: 1.2257,
                  with_refunds: 1.232,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 27123.06,
                  with_refunds: 26282.62,
                },
                gross_mrr: {
                  without_refunds: 32770.02,
                  with_refunds: 31747.71,
                },
                net_arr: {
                  without_refunds: 3616.87,
                  with_refunds: 3182.31,
                },
                gross_arr: {
                  without_refunds: 5588.36,
                  with_refunds: 4908.08,
                },
                net_arr_spread: {
                  without_refunds: 1223.71,
                  with_refunds: 1223.71,
                },
                gross_arr_spread: {
                  without_refunds: 1902.56,
                  with_refunds: 1902.56,
                },
                net_total: {
                  without_refunds: 30739.93,
                  with_refunds: 29464.93,
                },
                gross_total: {
                  without_refunds: 38358.38,
                  with_refunds: 36655.79,
                },
                net_total_spread: {
                  without_refunds: 28346.77,
                  with_refunds: 27506.33,
                },
                gross_total_spread: {
                  without_refunds: 34672.58,
                  with_refunds: 33650.27,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2939,
                },
                gross_mrr: {
                  without_refunds: 1.1808,
                  with_refunds: 1.172,
                },
                net_arr: {
                  without_refunds: 0.2952,
                  with_refunds: 0.2766,
                },
                gross_arr: {
                  without_refunds: 0.2917,
                  with_refunds: 0.2738,
                },
                net_arr_spread: {
                  without_refunds: 1.3012,
                  with_refunds: 1.3012,
                },
                gross_arr_spread: {
                  without_refunds: 1.299,
                  with_refunds: 1.299,
                },
                net_total: {
                  without_refunds: 0.9286,
                  with_refunds: 0.9261,
                },
                gross_total: {
                  without_refunds: 0.8177,
                  with_refunds: 0.8143,
                },
                net_total_spread: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2942,
                },
                gross_total_spread: {
                  without_refunds: 1.1867,
                  with_refunds: 1.1786,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2939,
                },
                gross_mrr: {
                  without_refunds: 1.1808,
                  with_refunds: 1.172,
                },
                net_arr: {
                  without_refunds: 0.2952,
                  with_refunds: 0.2766,
                },
                gross_arr: {
                  without_refunds: 0.2917,
                  with_refunds: 0.2738,
                },
                net_arr_spread: {
                  without_refunds: 1.3012,
                  with_refunds: 1.3012,
                },
                gross_arr_spread: {
                  without_refunds: 1.299,
                  with_refunds: 1.299,
                },
                net_total: {
                  without_refunds: 0.9286,
                  with_refunds: 0.9261,
                },
                gross_total: {
                  without_refunds: 0.8177,
                  with_refunds: 0.8143,
                },
                net_total_spread: {
                  without_refunds: 1.3008,
                  with_refunds: 1.2942,
                },
                gross_total_spread: {
                  without_refunds: 1.1867,
                  with_refunds: 1.1786,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.11,
                  with_refunds: 7.85,
                },
                gross_mrr: {
                  without_refunds: 9.79,
                  with_refunds: 9.49,
                },
                net_arr: {
                  without_refunds: 13.75,
                  with_refunds: 12.1,
                },
                gross_arr: {
                  without_refunds: 21.25,
                  with_refunds: 18.66,
                },
                net_arr_spread: {
                  without_refunds: 5.08,
                  with_refunds: 5.08,
                },
                gross_arr_spread: {
                  without_refunds: 7.89,
                  with_refunds: 7.89,
                },
                net_total: {
                  without_refunds: 8.6,
                  with_refunds: 8.24,
                },
                gross_total: {
                  without_refunds: 10.73,
                  with_refunds: 10.25,
                },
                net_total_spread: {
                  without_refunds: 7.97,
                  with_refunds: 7.73,
                },
                gross_total_spread: {
                  without_refunds: 9.75,
                  with_refunds: 9.46,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.83,
                  with_refunds: 10.44,
                },
                gross_mrr: {
                  without_refunds: 13.09,
                  with_refunds: 12.61,
                },
                net_arr: {
                  without_refunds: 72.34,
                  with_refunds: 60.04,
                },
                gross_arr: {
                  without_refunds: 111.77,
                  with_refunds: 92.61,
                },
                net_arr_spread: {
                  without_refunds: 5.94,
                  with_refunds: 5.94,
                },
                gross_arr_spread: {
                  without_refunds: 9.24,
                  with_refunds: 9.24,
                },
                net_total: {
                  without_refunds: 12.04,
                  with_refunds: 11.46,
                },
                gross_total: {
                  without_refunds: 15.02,
                  with_refunds: 14.26,
                },
                net_total_spread: {
                  without_refunds: 10.46,
                  with_refunds: 10.1,
                },
                gross_total_spread: {
                  without_refunds: 12.79,
                  with_refunds: 12.35,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 14.34,
                    with_refunds: 13.93,
                  },
                  gross_mrr: {
                    without_refunds: 18.09,
                    with_refunds: 17.58,
                  },
                  net_arr: {
                    without_refunds: 60.34,
                    with_refunds: 55.84,
                  },
                  gross_arr: {
                    without_refunds: 94.08,
                    with_refunds: 86.82,
                  },
                  net_arr_spread: {
                    without_refunds: 8.98,
                    with_refunds: 8.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.97,
                    with_refunds: 13.97,
                  },
                  net_total: {
                    without_refunds: 17.86,
                    with_refunds: 17.14,
                  },
                  gross_total: {
                    without_refunds: 23.85,
                    with_refunds: 22.84,
                  },
                  net_total_spread: {
                    without_refunds: 14.1,
                    with_refunds: 13.71,
                  },
                  gross_total_spread: {
                    without_refunds: 17.96,
                    with_refunds: 17.49,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.99,
                    with_refunds: 20.34,
                  },
                  gross_mrr: {
                    without_refunds: 26.61,
                    with_refunds: 25.81,
                  },
                  net_arr: {
                    without_refunds: 143.57,
                    with_refunds: 126.93,
                  },
                  gross_arr: {
                    without_refunds: 223.13,
                    with_refunds: 196.82,
                  },
                  net_arr_spread: {
                    without_refunds: 11.85,
                    with_refunds: 11.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 18.45,
                    with_refunds: 18.45,
                  },
                  net_total: {
                    without_refunds: 26.92,
                    with_refunds: 25.77,
                  },
                  gross_total: {
                    without_refunds: 36.11,
                    with_refunds: 34.5,
                  },
                  net_total_spread: {
                    without_refunds: 20.32,
                    with_refunds: 19.71,
                  },
                  gross_total_spread: {
                    without_refunds: 26.01,
                    with_refunds: 25.27,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3262,
                    with_refunds: 0.3169,
                  },
                  gross_mrr: {
                    without_refunds: 0.4116,
                    with_refunds: 0.4001,
                  },
                  net_arr: {
                    without_refunds: 1.3729,
                    with_refunds: 1.2706,
                  },
                  gross_arr: {
                    without_refunds: 2.1406,
                    with_refunds: 1.9754,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2043,
                    with_refunds: 0.2043,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3179,
                    with_refunds: 0.3179,
                  },
                  net_total: {
                    without_refunds: 0.4063,
                    with_refunds: 0.39,
                  },
                  gross_total: {
                    without_refunds: 0.5427,
                    with_refunds: 0.5198,
                  },
                  net_total_spread: {
                    without_refunds: 0.3207,
                    with_refunds: 0.3119,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4087,
                    with_refunds: 0.3979,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4777,
                    with_refunds: 0.4627,
                  },
                  gross_mrr: {
                    without_refunds: 0.6055,
                    with_refunds: 0.5872,
                  },
                  net_arr: {
                    without_refunds: 3.2667,
                    with_refunds: 2.888,
                  },
                  gross_arr: {
                    without_refunds: 5.077,
                    with_refunds: 4.4784,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2697,
                    with_refunds: 0.2697,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4197,
                    with_refunds: 0.4197,
                  },
                  net_total: {
                    without_refunds: 0.6125,
                    with_refunds: 0.5863,
                  },
                  gross_total: {
                    without_refunds: 0.8216,
                    with_refunds: 0.7849,
                  },
                  net_total_spread: {
                    without_refunds: 0.4623,
                    with_refunds: 0.4485,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5918,
                    with_refunds: 0.5749,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1906,
                  with_refunds: 1912,
                },
                arr_users: {
                  without_refunds: 7,
                  with_refunds: 7,
                },
                arr_spread_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                total_users: {
                  without_refunds: 1913,
                  with_refunds: 1917,
                },
                total_spread_users: {
                  without_refunds: 2118,
                  with_refunds: 2122,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5696,
                  with_refunds: 0.5714,
                },
                arr_users: {
                  without_refunds: 0.0266,
                  with_refunds: 0.0266,
                },
                arr_spread_users: {
                  without_refunds: 0.8797,
                  with_refunds: 0.8797,
                },
                total_users: {
                  without_refunds: 0.5351,
                  with_refunds: 0.5362,
                },
                total_spread_users: {
                  without_refunds: 0.5954,
                  with_refunds: 0.5966,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7612,
                  with_refunds: 0.7593,
                },
                arr_users: {
                  without_refunds: 0.14,
                  with_refunds: 0.1321,
                },
                arr_spread_users: {
                  without_refunds: 1.0291,
                  with_refunds: 1.0291,
                },
                total_users: {
                  without_refunds: 0.749,
                  with_refunds: 0.7456,
                },
                total_spread_users: {
                  without_refunds: 0.7815,
                  with_refunds: 0.779,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 21080.75,
                  with_refunds: 20742.84,
                },
                gross_mrr: {
                  without_refunds: 24389.1,
                  with_refunds: 23986.21,
                },
                net_arr: {
                  without_refunds: 536.86,
                  with_refunds: 536.86,
                },
                gross_arr: {
                  without_refunds: 740.22,
                  with_refunds: 740.22,
                },
                net_arr_spread: {
                  without_refunds: 1262.7,
                  with_refunds: 1262.7,
                },
                gross_arr_spread: {
                  without_refunds: 1954.33,
                  with_refunds: 1954.33,
                },
                net_total: {
                  without_refunds: 21617.61,
                  with_refunds: 21279.69,
                },
                gross_total: {
                  without_refunds: 25129.31,
                  with_refunds: 24726.42,
                },
                net_total_spread: {
                  without_refunds: 22343.45,
                  with_refunds: 22005.53,
                },
                gross_total_spread: {
                  without_refunds: 26343.42,
                  with_refunds: 25940.53,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.011,
                  with_refunds: 1.0211,
                },
                gross_mrr: {
                  without_refunds: 0.8788,
                  with_refunds: 0.8855,
                },
                net_arr: {
                  without_refunds: 0.0438,
                  with_refunds: 0.0467,
                },
                gross_arr: {
                  without_refunds: 0.0386,
                  with_refunds: 0.0413,
                },
                net_arr_spread: {
                  without_refunds: 1.3427,
                  with_refunds: 1.3427,
                },
                gross_arr_spread: {
                  without_refunds: 1.3343,
                  with_refunds: 1.3343,
                },
                net_total: {
                  without_refunds: 0.653,
                  with_refunds: 0.6688,
                },
                gross_total: {
                  without_refunds: 0.5357,
                  with_refunds: 0.5493,
                },
                net_total_spread: {
                  without_refunds: 1.0253,
                  with_refunds: 1.0354,
                },
                gross_total_spread: {
                  without_refunds: 0.9016,
                  with_refunds: 0.9085,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7772,
                  with_refunds: 0.7892,
                },
                gross_mrr: {
                  without_refunds: 0.7443,
                  with_refunds: 0.7555,
                },
                net_arr: {
                  without_refunds: 0.1484,
                  with_refunds: 0.1687,
                },
                gross_arr: {
                  without_refunds: 0.1325,
                  with_refunds: 0.1508,
                },
                net_arr_spread: {
                  without_refunds: 1.0319,
                  with_refunds: 1.0319,
                },
                gross_arr_spread: {
                  without_refunds: 1.0272,
                  with_refunds: 1.0272,
                },
                net_total: {
                  without_refunds: 0.7032,
                  with_refunds: 0.7222,
                },
                gross_total: {
                  without_refunds: 0.6551,
                  with_refunds: 0.6746,
                },
                net_total_spread: {
                  without_refunds: 0.7882,
                  with_refunds: 0.8,
                },
                gross_total_spread: {
                  without_refunds: 0.7598,
                  with_refunds: 0.7709,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.3,
                  with_refunds: 6.2,
                },
                gross_mrr: {
                  without_refunds: 7.29,
                  with_refunds: 7.17,
                },
                net_arr: {
                  without_refunds: 2.04,
                  with_refunds: 2.04,
                },
                gross_arr: {
                  without_refunds: 2.81,
                  with_refunds: 2.81,
                },
                net_arr_spread: {
                  without_refunds: 5.24,
                  with_refunds: 5.24,
                },
                gross_arr_spread: {
                  without_refunds: 8.11,
                  with_refunds: 8.11,
                },
                net_total: {
                  without_refunds: 6.05,
                  with_refunds: 5.95,
                },
                gross_total: {
                  without_refunds: 7.03,
                  with_refunds: 6.92,
                },
                net_total_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.19,
                },
                gross_total_spread: {
                  without_refunds: 7.41,
                  with_refunds: 7.29,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.06,
                  with_refunds: 10.85,
                },
                gross_mrr: {
                  without_refunds: 12.8,
                  with_refunds: 12.55,
                },
                net_arr: {
                  without_refunds: 76.69,
                  with_refunds: 76.69,
                },
                gross_arr: {
                  without_refunds: 105.75,
                  with_refunds: 105.75,
                },
                net_arr_spread: {
                  without_refunds: 5.96,
                  with_refunds: 5.96,
                },
                gross_arr_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.22,
                },
                net_total: {
                  without_refunds: 11.3,
                  with_refunds: 11.1,
                },
                gross_total: {
                  without_refunds: 13.14,
                  with_refunds: 12.9,
                },
                net_total_spread: {
                  without_refunds: 10.55,
                  with_refunds: 10.37,
                },
                gross_total_spread: {
                  without_refunds: 12.44,
                  with_refunds: 12.22,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.64,
                    with_refunds: 20.13,
                  },
                  gross_mrr: {
                    without_refunds: 25.38,
                    with_refunds: 24.75,
                  },
                  net_arr: {
                    without_refunds: 62.38,
                    with_refunds: 57.88,
                  },
                  gross_arr: {
                    without_refunds: 96.9,
                    with_refunds: 89.64,
                  },
                  net_arr_spread: {
                    without_refunds: 14.22,
                    with_refunds: 14.22,
                  },
                  gross_arr_spread: {
                    without_refunds: 22.08,
                    with_refunds: 22.08,
                  },
                  net_total: {
                    without_refunds: 23.91,
                    with_refunds: 23.09,
                  },
                  gross_total: {
                    without_refunds: 30.88,
                    with_refunds: 29.76,
                  },
                  net_total_spread: {
                    without_refunds: 20.38,
                    with_refunds: 19.89,
                  },
                  gross_total_spread: {
                    without_refunds: 25.37,
                    with_refunds: 24.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.05,
                    with_refunds: 31.19,
                  },
                  gross_mrr: {
                    without_refunds: 39.41,
                    with_refunds: 38.35,
                  },
                  net_arr: {
                    without_refunds: 220.27,
                    with_refunds: 203.62,
                  },
                  gross_arr: {
                    without_refunds: 328.88,
                    with_refunds: 302.57,
                  },
                  net_arr_spread: {
                    without_refunds: 17.81,
                    with_refunds: 17.81,
                  },
                  gross_arr_spread: {
                    without_refunds: 27.67,
                    with_refunds: 27.67,
                  },
                  net_total: {
                    without_refunds: 38.22,
                    with_refunds: 36.87,
                  },
                  gross_total: {
                    without_refunds: 49.25,
                    with_refunds: 47.4,
                  },
                  net_total_spread: {
                    without_refunds: 30.87,
                    with_refunds: 30.08,
                  },
                  gross_total_spread: {
                    without_refunds: 38.45,
                    with_refunds: 37.49,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4696,
                    with_refunds: 0.4579,
                  },
                  gross_mrr: {
                    without_refunds: 0.5774,
                    with_refunds: 0.5632,
                  },
                  net_arr: {
                    without_refunds: 1.4193,
                    with_refunds: 1.317,
                  },
                  gross_arr: {
                    without_refunds: 2.2047,
                    with_refunds: 2.0395,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3235,
                    with_refunds: 0.3235,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5024,
                    with_refunds: 0.5024,
                  },
                  net_total: {
                    without_refunds: 0.5439,
                    with_refunds: 0.5255,
                  },
                  gross_total: {
                    without_refunds: 0.7026,
                    with_refunds: 0.6772,
                  },
                  net_total_spread: {
                    without_refunds: 0.4636,
                    with_refunds: 0.4527,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5772,
                    with_refunds: 0.5638,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7293,
                    with_refunds: 0.7096,
                  },
                  gross_mrr: {
                    without_refunds: 0.8967,
                    with_refunds: 0.8727,
                  },
                  net_arr: {
                    without_refunds: 5.0117,
                    with_refunds: 4.633,
                  },
                  gross_arr: {
                    without_refunds: 7.483,
                    with_refunds: 6.8844,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4053,
                    with_refunds: 0.4053,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6295,
                    with_refunds: 0.6295,
                  },
                  net_total: {
                    without_refunds: 0.8696,
                    with_refunds: 0.8388,
                  },
                  gross_total: {
                    without_refunds: 1.1205,
                    with_refunds: 1.0784,
                  },
                  net_total_spread: {
                    without_refunds: 0.7023,
                    with_refunds: 0.6844,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8748,
                    with_refunds: 0.853,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1540,
                  with_refunds: 1545,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 7,
                },
                arr_spread_users: {
                  without_refunds: 218,
                  with_refunds: 218,
                },
                total_users: {
                  without_refunds: 1545,
                  with_refunds: 1551,
                },
                total_spread_users: {
                  without_refunds: 1757,
                  with_refunds: 1762,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4603,
                  with_refunds: 0.4617,
                },
                arr_users: {
                  without_refunds: 0.0228,
                  with_refunds: 0.0266,
                },
                arr_spread_users: {
                  without_refunds: 0.9046,
                  with_refunds: 0.9046,
                },
                total_users: {
                  without_refunds: 0.4322,
                  with_refunds: 0.4338,
                },
                total_spread_users: {
                  without_refunds: 0.494,
                  with_refunds: 0.4954,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.808,
                  with_refunds: 0.8081,
                },
                arr_users: {
                  without_refunds: 0.8571,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1.0283,
                  with_refunds: 1.0283,
                },
                total_users: {
                  without_refunds: 0.8076,
                  with_refunds: 0.8091,
                },
                total_spread_users: {
                  without_refunds: 0.8296,
                  with_refunds: 0.8303,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 17455.67,
                  with_refunds: 17063.71,
                },
                gross_mrr: {
                  without_refunds: 19708.23,
                  with_refunds: 19276.34,
                },
                net_arr: {
                  without_refunds: 507.37,
                  with_refunds: 438.44,
                },
                gross_arr: {
                  without_refunds: 658.61,
                  with_refunds: 539.61,
                },
                net_arr_spread: {
                  without_refunds: 1304.98,
                  with_refunds: 1304.98,
                },
                gross_arr_spread: {
                  without_refunds: 2009.21,
                  with_refunds: 2009.21,
                },
                net_total: {
                  without_refunds: 17963.03,
                  with_refunds: 17502.15,
                },
                gross_total: {
                  without_refunds: 20366.84,
                  with_refunds: 19815.95,
                },
                net_total_spread: {
                  without_refunds: 18760.65,
                  with_refunds: 18368.69,
                },
                gross_total_spread: {
                  without_refunds: 21717.44,
                  with_refunds: 21285.55,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8372,
                  with_refunds: 0.84,
                },
                gross_mrr: {
                  without_refunds: 0.7101,
                  with_refunds: 0.7116,
                },
                net_arr: {
                  without_refunds: 0.0414,
                  with_refunds: 0.0381,
                },
                gross_arr: {
                  without_refunds: 0.0344,
                  with_refunds: 0.0301,
                },
                net_arr_spread: {
                  without_refunds: 1.3877,
                  with_refunds: 1.3877,
                },
                gross_arr_spread: {
                  without_refunds: 1.3718,
                  with_refunds: 1.3718,
                },
                net_total: {
                  without_refunds: 0.5426,
                  with_refunds: 0.5501,
                },
                gross_total: {
                  without_refunds: 0.4342,
                  with_refunds: 0.4402,
                },
                net_total_spread: {
                  without_refunds: 0.8609,
                  with_refunds: 0.8643,
                },
                gross_total_spread: {
                  without_refunds: 0.7433,
                  with_refunds: 0.7455,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.828,
                  with_refunds: 0.8226,
                },
                gross_mrr: {
                  without_refunds: 0.8081,
                  with_refunds: 0.8036,
                },
                net_arr: {
                  without_refunds: 0.9451,
                  with_refunds: 0.8167,
                },
                gross_arr: {
                  without_refunds: 0.8898,
                  with_refunds: 0.729,
                },
                net_arr_spread: {
                  without_refunds: 1.0335,
                  with_refunds: 1.0335,
                },
                gross_arr_spread: {
                  without_refunds: 1.0281,
                  with_refunds: 1.0281,
                },
                net_total: {
                  without_refunds: 0.8309,
                  with_refunds: 0.8225,
                },
                gross_total: {
                  without_refunds: 0.8105,
                  with_refunds: 0.8014,
                },
                net_total_spread: {
                  without_refunds: 0.8396,
                  with_refunds: 0.8347,
                },
                gross_total_spread: {
                  without_refunds: 0.8244,
                  with_refunds: 0.8206,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.22,
                  with_refunds: 5.1,
                },
                gross_mrr: {
                  without_refunds: 5.89,
                  with_refunds: 5.76,
                },
                net_arr: {
                  without_refunds: 1.93,
                  with_refunds: 1.67,
                },
                gross_arr: {
                  without_refunds: 2.5,
                  with_refunds: 2.05,
                },
                net_arr_spread: {
                  without_refunds: 5.41,
                  with_refunds: 5.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.34,
                },
                net_total: {
                  without_refunds: 5.02,
                  with_refunds: 4.9,
                },
                gross_total: {
                  without_refunds: 5.7,
                  with_refunds: 5.54,
                },
                net_total_spread: {
                  without_refunds: 5.27,
                  with_refunds: 5.16,
                },
                gross_total_spread: {
                  without_refunds: 6.11,
                  with_refunds: 5.98,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.33,
                  with_refunds: 11.04,
                },
                gross_mrr: {
                  without_refunds: 12.8,
                  with_refunds: 12.48,
                },
                net_arr: {
                  without_refunds: 84.56,
                  with_refunds: 62.63,
                },
                gross_arr: {
                  without_refunds: 109.77,
                  with_refunds: 77.09,
                },
                net_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                gross_arr_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.22,
                },
                net_total: {
                  without_refunds: 11.63,
                  with_refunds: 11.28,
                },
                gross_total: {
                  without_refunds: 13.18,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.68,
                  with_refunds: 10.42,
                },
                gross_total_spread: {
                  without_refunds: 12.36,
                  with_refunds: 12.08,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.85,
                    with_refunds: 25.22,
                  },
                  gross_mrr: {
                    without_refunds: 31.27,
                    with_refunds: 30.51,
                  },
                  net_arr: {
                    without_refunds: 64.31,
                    with_refunds: 59.55,
                  },
                  gross_arr: {
                    without_refunds: 99.4,
                    with_refunds: 91.69,
                  },
                  net_arr_spread: {
                    without_refunds: 19.63,
                    with_refunds: 19.63,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.42,
                    with_refunds: 30.42,
                  },
                  net_total: {
                    without_refunds: 28.93,
                    with_refunds: 27.99,
                  },
                  gross_total: {
                    without_refunds: 36.58,
                    with_refunds: 35.3,
                  },
                  net_total_spread: {
                    without_refunds: 25.65,
                    with_refunds: 25.06,
                  },
                  gross_total_spread: {
                    without_refunds: 31.47,
                    with_refunds: 30.76,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 43.39,
                    with_refunds: 42.23,
                  },
                  gross_mrr: {
                    without_refunds: 52.21,
                    with_refunds: 50.83,
                  },
                  net_arr: {
                    without_refunds: 304.83,
                    with_refunds: 266.26,
                  },
                  gross_arr: {
                    without_refunds: 438.65,
                    with_refunds: 379.66,
                  },
                  net_arr_spread: {
                    without_refunds: 23.8,
                    with_refunds: 23.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.88,
                    with_refunds: 36.88,
                  },
                  net_total: {
                    without_refunds: 49.85,
                    with_refunds: 48.15,
                  },
                  gross_total: {
                    without_refunds: 62.43,
                    with_refunds: 60.17,
                  },
                  net_total_spread: {
                    without_refunds: 41.54,
                    with_refunds: 40.51,
                  },
                  gross_total_spread: {
                    without_refunds: 50.81,
                    with_refunds: 49.57,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5883,
                    with_refunds: 0.5739,
                  },
                  gross_mrr: {
                    without_refunds: 0.7114,
                    with_refunds: 0.6943,
                  },
                  net_arr: {
                    without_refunds: 1.4632,
                    with_refunds: 1.355,
                  },
                  gross_arr: {
                    without_refunds: 2.2616,
                    with_refunds: 2.0862,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4467,
                    with_refunds: 0.4467,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6921,
                    with_refunds: 0.6921,
                  },
                  net_total: {
                    without_refunds: 0.6582,
                    with_refunds: 0.6369,
                  },
                  gross_total: {
                    without_refunds: 0.8322,
                    with_refunds: 0.8033,
                  },
                  net_total_spread: {
                    without_refunds: 0.5837,
                    with_refunds: 0.5702,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7161,
                    with_refunds: 0.7,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9872,
                    with_refunds: 0.9609,
                  },
                  gross_mrr: {
                    without_refunds: 1.1878,
                    with_refunds: 1.1566,
                  },
                  net_arr: {
                    without_refunds: 6.9357,
                    with_refunds: 6.0582,
                  },
                  gross_arr: {
                    without_refunds: 9.9806,
                    with_refunds: 8.6384,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5415,
                    with_refunds: 0.5415,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8392,
                    with_refunds: 0.8392,
                  },
                  net_total: {
                    without_refunds: 1.1342,
                    with_refunds: 1.0956,
                  },
                  gross_total: {
                    without_refunds: 1.4205,
                    with_refunds: 1.3691,
                  },
                  net_total_spread: {
                    without_refunds: 0.9452,
                    with_refunds: 0.9216,
                  },
                  gross_total_spread: {
                    without_refunds: 1.156,
                    with_refunds: 1.1279,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1300,
                  with_refunds: 1302,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 218,
                  with_refunds: 218,
                },
                total_users: {
                  without_refunds: 1301,
                  with_refunds: 1303,
                },
                total_spread_users: {
                  without_refunds: 1518,
                  with_refunds: 1520,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3885,
                  with_refunds: 0.3891,
                },
                arr_users: {
                  without_refunds: 0.0038,
                  with_refunds: 0.0038,
                },
                arr_spread_users: {
                  without_refunds: 0.9046,
                  with_refunds: 0.9046,
                },
                total_users: {
                  without_refunds: 0.3639,
                  with_refunds: 0.3645,
                },
                total_spread_users: {
                  without_refunds: 0.4268,
                  with_refunds: 0.4273,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8442,
                  with_refunds: 0.8427,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1429,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8421,
                  with_refunds: 0.8401,
                },
                total_spread_users: {
                  without_refunds: 0.864,
                  with_refunds: 0.8627,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 14845.18,
                  with_refunds: 14564.22,
                },
                gross_mrr: {
                  without_refunds: 16716.65,
                  with_refunds: 16410.69,
                },
                net_arr: {
                  without_refunds: 84.99,
                },
                gross_arr: {
                  without_refunds: 99.99,
                },
                net_arr_spread: {
                  without_refunds: 1304.98,
                  with_refunds: 1304.98,
                },
                gross_arr_spread: {
                  without_refunds: 2009.21,
                  with_refunds: 2009.21,
                },
                net_total: {
                  without_refunds: 14930.17,
                  with_refunds: 14564.22,
                },
                gross_total: {
                  without_refunds: 16816.64,
                  with_refunds: 16410.69,
                },
                net_total_spread: {
                  without_refunds: 16150.16,
                  with_refunds: 15869.2,
                },
                gross_total_spread: {
                  without_refunds: 18725.86,
                  with_refunds: 18419.9,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.712,
                  with_refunds: 0.717,
                },
                gross_mrr: {
                  without_refunds: 0.6023,
                  with_refunds: 0.6058,
                },
                net_arr: {
                  without_refunds: 0.0069,
                },
                gross_arr: {
                  without_refunds: 0.0052,
                },
                net_arr_spread: {
                  without_refunds: 1.3877,
                  with_refunds: 1.3877,
                },
                gross_arr_spread: {
                  without_refunds: 1.3718,
                  with_refunds: 1.3718,
                },
                net_total: {
                  without_refunds: 0.451,
                  with_refunds: 0.4577,
                },
                gross_total: {
                  without_refunds: 0.3585,
                  with_refunds: 0.3646,
                },
                net_total_spread: {
                  without_refunds: 0.7411,
                  with_refunds: 0.7467,
                },
                gross_total_spread: {
                  without_refunds: 0.6409,
                  with_refunds: 0.6451,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8505,
                  with_refunds: 0.8535,
                },
                gross_mrr: {
                  without_refunds: 0.8482,
                  with_refunds: 0.8513,
                },
                net_arr: {
                  without_refunds: 0.1675,
                },
                gross_arr: {
                  without_refunds: 0.1518,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8312,
                  with_refunds: 0.8321,
                },
                gross_total: {
                  without_refunds: 0.8257,
                  with_refunds: 0.8282,
                },
                net_total_spread: {
                  without_refunds: 0.8609,
                  with_refunds: 0.8639,
                },
                gross_total_spread: {
                  without_refunds: 0.8622,
                  with_refunds: 0.8654,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.44,
                  with_refunds: 4.35,
                },
                gross_mrr: {
                  without_refunds: 5,
                  with_refunds: 4.9,
                },
                net_arr: {
                  without_refunds: 0.32,
                },
                gross_arr: {
                  without_refunds: 0.38,
                },
                net_arr_spread: {
                  without_refunds: 5.41,
                  with_refunds: 5.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.34,
                },
                net_total: {
                  without_refunds: 4.18,
                  with_refunds: 4.07,
                },
                gross_total: {
                  without_refunds: 4.7,
                  with_refunds: 4.59,
                },
                net_total_spread: {
                  without_refunds: 4.54,
                  with_refunds: 4.46,
                },
                gross_total_spread: {
                  without_refunds: 5.26,
                  with_refunds: 5.18,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.19,
                },
                gross_mrr: {
                  without_refunds: 12.86,
                  with_refunds: 12.6,
                },
                net_arr: {
                  without_refunds: 84.99,
                },
                gross_arr: {
                  without_refunds: 99.99,
                },
                net_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                gross_arr_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.22,
                },
                net_total: {
                  without_refunds: 11.48,
                  with_refunds: 11.18,
                },
                gross_total: {
                  without_refunds: 12.93,
                  with_refunds: 12.59,
                },
                net_total_spread: {
                  without_refunds: 10.64,
                  with_refunds: 10.44,
                },
                gross_total_spread: {
                  without_refunds: 12.34,
                  with_refunds: 12.12,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.29,
                    with_refunds: 29.58,
                  },
                  gross_mrr: {
                    without_refunds: 36.26,
                    with_refunds: 35.42,
                  },
                  net_arr: {
                    without_refunds: 64.63,
                    with_refunds: 59.55,
                  },
                  gross_arr: {
                    without_refunds: 99.78,
                    with_refunds: 91.69,
                  },
                  net_arr_spread: {
                    without_refunds: 25.05,
                    with_refunds: 25.05,
                  },
                  gross_arr_spread: {
                    without_refunds: 38.76,
                    with_refunds: 38.76,
                  },
                  net_total: {
                    without_refunds: 33.11,
                    with_refunds: 32.06,
                  },
                  gross_total: {
                    without_refunds: 41.28,
                    with_refunds: 39.89,
                  },
                  net_total_spread: {
                    without_refunds: 30.19,
                    with_refunds: 29.52,
                  },
                  gross_total_spread: {
                    without_refunds: 36.74,
                    with_refunds: 35.94,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 54.81,
                    with_refunds: 53.42,
                  },
                  gross_mrr: {
                    without_refunds: 65.06,
                    with_refunds: 63.43,
                  },
                  net_arr: {
                    without_refunds: 389.82,
                    with_refunds: 266.26,
                  },
                  gross_arr: {
                    without_refunds: 538.64,
                    with_refunds: 379.66,
                  },
                  net_arr_spread: {
                    without_refunds: 29.78,
                    with_refunds: 29.78,
                  },
                  gross_arr_spread: {
                    without_refunds: 46.1,
                    with_refunds: 46.1,
                  },
                  net_total: {
                    without_refunds: 61.32,
                    with_refunds: 59.33,
                  },
                  gross_total: {
                    without_refunds: 75.36,
                    with_refunds: 72.77,
                  },
                  net_total_spread: {
                    without_refunds: 52.18,
                    with_refunds: 50.95,
                  },
                  gross_total_spread: {
                    without_refunds: 63.14,
                    with_refunds: 61.69,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6892,
                    with_refunds: 0.673,
                  },
                  gross_mrr: {
                    without_refunds: 0.8251,
                    with_refunds: 0.8059,
                  },
                  net_arr: {
                    without_refunds: 1.4706,
                    with_refunds: 1.355,
                  },
                  gross_arr: {
                    without_refunds: 2.2703,
                    with_refunds: 2.0862,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5699,
                    with_refunds: 0.5699,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8818,
                    with_refunds: 0.8818,
                  },
                  net_total: {
                    without_refunds: 0.7533,
                    with_refunds: 0.7296,
                  },
                  gross_total: {
                    without_refunds: 0.9393,
                    with_refunds: 0.9077,
                  },
                  net_total_spread: {
                    without_refunds: 0.687,
                    with_refunds: 0.6717,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8359,
                    with_refunds: 0.8178,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.247,
                    with_refunds: 1.2154,
                  },
                  gross_mrr: {
                    without_refunds: 1.4804,
                    with_refunds: 1.4433,
                  },
                  net_arr: {
                    without_refunds: 8.8695,
                    with_refunds: 6.0582,
                  },
                  gross_arr: {
                    without_refunds: 12.2557,
                    with_refunds: 8.6384,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6777,
                    with_refunds: 0.6777,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0489,
                    with_refunds: 1.0489,
                  },
                  net_total: {
                    without_refunds: 1.3953,
                    with_refunds: 1.3499,
                  },
                  gross_total: {
                    without_refunds: 1.7146,
                    with_refunds: 1.6557,
                  },
                  net_total_spread: {
                    without_refunds: 1.1873,
                    with_refunds: 1.1592,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4367,
                    with_refunds: 1.4036,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1168,
                  with_refunds: 1171,
                },
                arr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 222,
                  with_refunds: 222,
                },
                total_users: {
                  without_refunds: 1172,
                  with_refunds: 1175,
                },
                total_spread_users: {
                  without_refunds: 1390,
                  with_refunds: 1393,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3491,
                  with_refunds: 0.35,
                },
                arr_users: {
                  without_refunds: 0.0152,
                  with_refunds: 0.0152,
                },
                arr_spread_users: {
                  without_refunds: 0.9212,
                  with_refunds: 0.9212,
                },
                total_users: {
                  without_refunds: 0.3278,
                  with_refunds: 0.3287,
                },
                total_spread_users: {
                  without_refunds: 0.3908,
                  with_refunds: 0.3916,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8985,
                  with_refunds: 0.8994,
                },
                arr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 1.0183,
                  with_refunds: 1.0183,
                },
                total_users: {
                  without_refunds: 0.9008,
                  with_refunds: 0.9018,
                },
                total_spread_users: {
                  without_refunds: 0.9157,
                  with_refunds: 0.9164,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13457.97,
                  with_refunds: 13128.03,
                },
                gross_mrr: {
                  without_refunds: 15136.06,
                  with_refunds: 14783.07,
                },
                net_arr: {
                  without_refunds: 481.1,
                  with_refunds: 481.1,
                },
                gross_arr: {
                  without_refunds: 615.8,
                  with_refunds: 615.8,
                },
                net_arr_spread: {
                  without_refunds: 1345.07,
                  with_refunds: 1345.07,
                },
                gross_arr_spread: {
                  without_refunds: 2060.53,
                  with_refunds: 2060.53,
                },
                net_total: {
                  without_refunds: 13939.08,
                  with_refunds: 13609.13,
                },
                gross_total: {
                  without_refunds: 15751.86,
                  with_refunds: 15398.87,
                },
                net_total_spread: {
                  without_refunds: 14803.04,
                  with_refunds: 14473.1,
                },
                gross_total_spread: {
                  without_refunds: 17196.59,
                  with_refunds: 16843.6,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6454,
                  with_refunds: 0.6463,
                },
                gross_mrr: {
                  without_refunds: 0.5454,
                  with_refunds: 0.5458,
                },
                net_arr: {
                  without_refunds: 0.0393,
                  with_refunds: 0.0418,
                },
                gross_arr: {
                  without_refunds: 0.0321,
                  with_refunds: 0.0344,
                },
                net_arr_spread: {
                  without_refunds: 1.4303,
                  with_refunds: 1.4303,
                },
                gross_arr_spread: {
                  without_refunds: 1.4068,
                  with_refunds: 1.4068,
                },
                net_total: {
                  without_refunds: 0.4211,
                  with_refunds: 0.4277,
                },
                gross_total: {
                  without_refunds: 0.3358,
                  with_refunds: 0.3421,
                },
                net_total_spread: {
                  without_refunds: 0.6793,
                  with_refunds: 0.681,
                },
                gross_total_spread: {
                  without_refunds: 0.5886,
                  with_refunds: 0.5899,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9066,
                  with_refunds: 0.9014,
                },
                gross_mrr: {
                  without_refunds: 0.9054,
                  with_refunds: 0.9008,
                },
                net_arr: {
                  without_refunds: 5.6607,
                  with_refunds: 1.0973,
                },
                gross_arr: {
                  without_refunds: 6.1586,
                  with_refunds: 1.1412,
                },
                net_arr_spread: {
                  without_refunds: 1.0307,
                  with_refunds: 1.0307,
                },
                gross_arr_spread: {
                  without_refunds: 1.0255,
                  with_refunds: 1.0255,
                },
                net_total: {
                  without_refunds: 0.9336,
                  with_refunds: 0.9344,
                },
                gross_total: {
                  without_refunds: 0.9367,
                  with_refunds: 0.9383,
                },
                net_total_spread: {
                  without_refunds: 0.9166,
                  with_refunds: 0.912,
                },
                gross_total_spread: {
                  without_refunds: 0.9183,
                  with_refunds: 0.9144,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.02,
                  with_refunds: 3.92,
                },
                gross_mrr: {
                  without_refunds: 4.52,
                  with_refunds: 4.42,
                },
                net_arr: {
                  without_refunds: 1.83,
                  with_refunds: 1.83,
                },
                gross_arr: {
                  without_refunds: 2.34,
                  with_refunds: 2.34,
                },
                net_arr_spread: {
                  without_refunds: 5.58,
                  with_refunds: 5.58,
                },
                gross_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                net_total: {
                  without_refunds: 3.9,
                  with_refunds: 3.81,
                },
                gross_total: {
                  without_refunds: 4.41,
                  with_refunds: 4.31,
                },
                net_total_spread: {
                  without_refunds: 4.16,
                  with_refunds: 4.07,
                },
                gross_total_spread: {
                  without_refunds: 4.83,
                  with_refunds: 4.74,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.52,
                  with_refunds: 11.21,
                },
                gross_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.62,
                },
                net_arr: {
                  without_refunds: 120.28,
                  with_refunds: 120.28,
                },
                gross_arr: {
                  without_refunds: 153.95,
                  with_refunds: 153.95,
                },
                net_arr_spread: {
                  without_refunds: 6.06,
                  with_refunds: 6.06,
                },
                gross_arr_spread: {
                  without_refunds: 9.28,
                  with_refunds: 9.28,
                },
                net_total: {
                  without_refunds: 11.89,
                  with_refunds: 11.58,
                },
                gross_total: {
                  without_refunds: 13.44,
                  with_refunds: 13.11,
                },
                net_total_spread: {
                  without_refunds: 10.65,
                  with_refunds: 10.39,
                },
                gross_total_spread: {
                  without_refunds: 12.37,
                  with_refunds: 12.09,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.31,
                    with_refunds: 33.5,
                  },
                  gross_mrr: {
                    without_refunds: 40.79,
                    with_refunds: 39.84,
                  },
                  net_arr: {
                    without_refunds: 66.46,
                    with_refunds: 61.38,
                  },
                  gross_arr: {
                    without_refunds: 102.12,
                    with_refunds: 94.03,
                  },
                  net_arr_spread: {
                    without_refunds: 30.63,
                    with_refunds: 30.63,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.31,
                    with_refunds: 47.31,
                  },
                  net_total: {
                    without_refunds: 37.01,
                    with_refunds: 35.87,
                  },
                  gross_total: {
                    without_refunds: 45.69,
                    with_refunds: 44.2,
                  },
                  net_total_spread: {
                    without_refunds: 34.35,
                    with_refunds: 33.59,
                  },
                  gross_total_spread: {
                    without_refunds: 41.57,
                    with_refunds: 40.68,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 66.33,
                    with_refunds: 64.63,
                  },
                  gross_mrr: {
                    without_refunds: 78.02,
                    with_refunds: 76.06,
                  },
                  net_arr: {
                    without_refunds: 510.09,
                    with_refunds: 386.53,
                  },
                  gross_arr: {
                    without_refunds: 692.59,
                    with_refunds: 533.61,
                  },
                  net_arr_spread: {
                    without_refunds: 35.84,
                    with_refunds: 35.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 55.38,
                    with_refunds: 55.38,
                  },
                  net_total: {
                    without_refunds: 73.22,
                    with_refunds: 70.91,
                  },
                  gross_total: {
                    without_refunds: 88.8,
                    with_refunds: 85.87,
                  },
                  net_total_spread: {
                    without_refunds: 62.83,
                    with_refunds: 61.34,
                  },
                  gross_total_spread: {
                    without_refunds: 75.51,
                    with_refunds: 73.78,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7807,
                    with_refunds: 0.7623,
                  },
                  gross_mrr: {
                    without_refunds: 0.928,
                    with_refunds: 0.9064,
                  },
                  net_arr: {
                    without_refunds: 1.5122,
                    with_refunds: 1.3966,
                  },
                  gross_arr: {
                    without_refunds: 2.3236,
                    with_refunds: 2.1394,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6969,
                    with_refunds: 0.6969,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0763,
                    with_refunds: 1.0763,
                  },
                  net_total: {
                    without_refunds: 0.842,
                    with_refunds: 0.8162,
                  },
                  gross_total: {
                    without_refunds: 1.0395,
                    with_refunds: 1.0057,
                  },
                  net_total_spread: {
                    without_refunds: 0.7817,
                    with_refunds: 0.7643,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9459,
                    with_refunds: 0.9256,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.5092,
                    with_refunds: 1.4705,
                  },
                  gross_mrr: {
                    without_refunds: 1.7753,
                    with_refunds: 1.7306,
                  },
                  net_arr: {
                    without_refunds: 11.6062,
                    with_refunds: 8.7948,
                  },
                  gross_arr: {
                    without_refunds: 15.7585,
                    with_refunds: 12.1412,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8155,
                    with_refunds: 0.8155,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2601,
                    with_refunds: 1.2601,
                  },
                  net_total: {
                    without_refunds: 1.6659,
                    with_refunds: 1.6135,
                  },
                  gross_total: {
                    without_refunds: 2.0204,
                    with_refunds: 1.9539,
                  },
                  net_total_spread: {
                    without_refunds: 1.4296,
                    with_refunds: 1.3956,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7182,
                    with_refunds: 1.6788,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 922,
                  with_refunds: 924,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 226,
                  with_refunds: 226,
                },
                total_users: {
                  without_refunds: 927,
                  with_refunds: 929,
                },
                total_spread_users: {
                  without_refunds: 1148,
                  with_refunds: 1150,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2756,
                  with_refunds: 0.2762,
                },
                arr_users: {
                  without_refunds: 0.0228,
                  with_refunds: 0.0228,
                },
                arr_spread_users: {
                  without_refunds: 0.9378,
                  with_refunds: 0.9378,
                },
                total_users: {
                  without_refunds: 0.2593,
                  with_refunds: 0.2599,
                },
                total_spread_users: {
                  without_refunds: 0.3227,
                  with_refunds: 0.3233,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7894,
                  with_refunds: 0.7891,
                },
                arr_users: {
                  without_refunds: 1.5,
                  with_refunds: 1.5,
                },
                arr_spread_users: {
                  without_refunds: 1.018,
                  with_refunds: 1.018,
                },
                total_users: {
                  without_refunds: 0.791,
                  with_refunds: 0.7906,
                },
                total_spread_users: {
                  without_refunds: 0.8259,
                  with_refunds: 0.8256,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10532.13,
                  with_refunds: 10126.7,
                },
                gross_mrr: {
                  without_refunds: 11688.4,
                  with_refunds: 11253.95,
                },
                net_arr: {
                  without_refunds: 709.08,
                  with_refunds: 473.1,
                },
                gross_arr: {
                  without_refunds: 924.42,
                  with_refunds: 619.53,
                },
                net_arr_spread: {
                  without_refunds: 1384.5,
                  with_refunds: 1384.5,
                },
                gross_arr_spread: {
                  without_refunds: 2112.15,
                  with_refunds: 2112.15,
                },
                net_total: {
                  without_refunds: 11241.21,
                  with_refunds: 10599.79,
                },
                gross_total: {
                  without_refunds: 12612.82,
                  with_refunds: 11873.48,
                },
                net_total_spread: {
                  without_refunds: 11916.62,
                  with_refunds: 11511.19,
                },
                gross_total_spread: {
                  without_refunds: 13800.56,
                  with_refunds: 13366.11,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5051,
                  with_refunds: 0.4985,
                },
                gross_mrr: {
                  without_refunds: 0.4212,
                  with_refunds: 0.4155,
                },
                net_arr: {
                  without_refunds: 0.0579,
                  with_refunds: 0.0411,
                },
                gross_arr: {
                  without_refunds: 0.0483,
                  with_refunds: 0.0346,
                },
                net_arr_spread: {
                  without_refunds: 1.4722,
                  with_refunds: 1.4722,
                },
                gross_arr_spread: {
                  without_refunds: 1.4421,
                  with_refunds: 1.4421,
                },
                net_total: {
                  without_refunds: 0.3396,
                  with_refunds: 0.3331,
                },
                gross_total: {
                  without_refunds: 0.2689,
                  with_refunds: 0.2638,
                },
                net_total_spread: {
                  without_refunds: 0.5468,
                  with_refunds: 0.5416,
                },
                gross_total_spread: {
                  without_refunds: 0.4723,
                  with_refunds: 0.4681,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7826,
                  with_refunds: 0.7714,
                },
                gross_mrr: {
                  without_refunds: 0.7722,
                  with_refunds: 0.7613,
                },
                net_arr: {
                  without_refunds: 1.4739,
                  with_refunds: 0.9834,
                },
                gross_arr: {
                  without_refunds: 1.5012,
                  with_refunds: 1.0061,
                },
                net_arr_spread: {
                  without_refunds: 1.0293,
                  with_refunds: 1.0293,
                },
                gross_arr_spread: {
                  without_refunds: 1.0251,
                  with_refunds: 1.0251,
                },
                net_total: {
                  without_refunds: 0.8065,
                  with_refunds: 0.7789,
                },
                gross_total: {
                  without_refunds: 0.8007,
                  with_refunds: 0.7711,
                },
                net_total_spread: {
                  without_refunds: 0.805,
                  with_refunds: 0.7954,
                },
                gross_total_spread: {
                  without_refunds: 0.8025,
                  with_refunds: 0.7935,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.15,
                  with_refunds: 3.03,
                },
                gross_mrr: {
                  without_refunds: 3.49,
                  with_refunds: 3.36,
                },
                net_arr: {
                  without_refunds: 2.7,
                  with_refunds: 1.8,
                },
                gross_arr: {
                  without_refunds: 3.51,
                  with_refunds: 2.36,
                },
                net_arr_spread: {
                  without_refunds: 5.74,
                  with_refunds: 5.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.76,
                  with_refunds: 8.76,
                },
                net_total: {
                  without_refunds: 3.14,
                  with_refunds: 2.96,
                },
                gross_total: {
                  without_refunds: 3.53,
                  with_refunds: 3.32,
                },
                net_total_spread: {
                  without_refunds: 3.35,
                  with_refunds: 3.24,
                },
                gross_total_spread: {
                  without_refunds: 3.88,
                  with_refunds: 3.76,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 10.96,
                },
                gross_mrr: {
                  without_refunds: 12.68,
                  with_refunds: 12.18,
                },
                net_arr: {
                  without_refunds: 118.18,
                  with_refunds: 78.85,
                },
                gross_arr: {
                  without_refunds: 154.07,
                  with_refunds: 103.25,
                },
                net_arr_spread: {
                  without_refunds: 6.13,
                  with_refunds: 6.13,
                },
                gross_arr_spread: {
                  without_refunds: 9.35,
                  with_refunds: 9.35,
                },
                net_total: {
                  without_refunds: 12.13,
                  with_refunds: 11.41,
                },
                gross_total: {
                  without_refunds: 13.61,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.38,
                  with_refunds: 10.01,
                },
                gross_total_spread: {
                  without_refunds: 12.02,
                  with_refunds: 11.62,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.46,
                    with_refunds: 36.53,
                  },
                  gross_mrr: {
                    without_refunds: 44.28,
                    with_refunds: 43.2,
                  },
                  net_arr: {
                    without_refunds: 69.16,
                    with_refunds: 63.18,
                  },
                  gross_arr: {
                    without_refunds: 105.64,
                    with_refunds: 96.38,
                  },
                  net_arr_spread: {
                    without_refunds: 36.37,
                    with_refunds: 36.37,
                  },
                  gross_arr_spread: {
                    without_refunds: 56.07,
                    with_refunds: 56.07,
                  },
                  net_total: {
                    without_refunds: 40.15,
                    with_refunds: 38.84,
                  },
                  gross_total: {
                    without_refunds: 49.22,
                    with_refunds: 47.52,
                  },
                  net_total_spread: {
                    without_refunds: 37.7,
                    with_refunds: 36.83,
                  },
                  gross_total_spread: {
                    without_refunds: 45.45,
                    with_refunds: 44.44,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 77.75,
                    with_refunds: 75.59,
                  },
                  gross_mrr: {
                    without_refunds: 90.7,
                    with_refunds: 88.24,
                  },
                  net_arr: {
                    without_refunds: 628.27,
                    with_refunds: 465.38,
                  },
                  gross_arr: {
                    without_refunds: 846.66,
                    with_refunds: 636.86,
                  },
                  net_arr_spread: {
                    without_refunds: 41.97,
                    with_refunds: 41.97,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.73,
                    with_refunds: 64.73,
                  },
                  net_total: {
                    without_refunds: 85.34,
                    with_refunds: 82.32,
                  },
                  gross_total: {
                    without_refunds: 102.4,
                    with_refunds: 98.65,
                  },
                  net_total_spread: {
                    without_refunds: 73.21,
                    with_refunds: 71.35,
                  },
                  gross_total_spread: {
                    without_refunds: 87.54,
                    with_refunds: 85.4,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8524,
                    with_refunds: 0.8311,
                  },
                  gross_mrr: {
                    without_refunds: 1.0075,
                    with_refunds: 0.9829,
                  },
                  net_arr: {
                    without_refunds: 1.5736,
                    with_refunds: 1.4375,
                  },
                  gross_arr: {
                    without_refunds: 2.4035,
                    with_refunds: 2.193,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8276,
                    with_refunds: 0.8276,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2757,
                    with_refunds: 1.2757,
                  },
                  net_total: {
                    without_refunds: 0.9135,
                    with_refunds: 0.8836,
                  },
                  gross_total: {
                    without_refunds: 1.1198,
                    with_refunds: 1.0813,
                  },
                  net_total_spread: {
                    without_refunds: 0.8579,
                    with_refunds: 0.8379,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0342,
                    with_refunds: 1.0111,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.7691,
                    with_refunds: 1.7198,
                  },
                  gross_mrr: {
                    without_refunds: 2.0637,
                    with_refunds: 2.0077,
                  },
                  net_arr: {
                    without_refunds: 14.2951,
                    with_refunds: 10.5889,
                  },
                  gross_arr: {
                    without_refunds: 19.2641,
                    with_refunds: 14.4906,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9549,
                    with_refunds: 0.9549,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4727,
                    with_refunds: 1.4727,
                  },
                  net_total: {
                    without_refunds: 1.9418,
                    with_refunds: 1.8731,
                  },
                  gross_total: {
                    without_refunds: 2.33,
                    with_refunds: 2.2447,
                  },
                  net_total_spread: {
                    without_refunds: 1.6658,
                    with_refunds: 1.6233,
                  },
                  gross_total_spread: {
                    without_refunds: 1.9917,
                    with_refunds: 1.9432,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 785,
                  with_refunds: 789,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 231,
                  with_refunds: 231,
                },
                total_users: {
                  without_refunds: 789,
                  with_refunds: 791,
                },
                total_spread_users: {
                  without_refunds: 1015,
                  with_refunds: 1017,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2346,
                  with_refunds: 0.2358,
                },
                arr_users: {
                  without_refunds: 0.019,
                  with_refunds: 0.019,
                },
                arr_spread_users: {
                  without_refunds: 0.9585,
                  with_refunds: 0.9585,
                },
                total_users: {
                  without_refunds: 0.2207,
                  with_refunds: 0.2213,
                },
                total_spread_users: {
                  without_refunds: 0.2854,
                  with_refunds: 0.2859,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8514,
                  with_refunds: 0.8539,
                },
                arr_users: {
                  without_refunds: 0.8333,
                  with_refunds: 0.8333,
                },
                arr_spread_users: {
                  without_refunds: 1.0221,
                  with_refunds: 1.0221,
                },
                total_users: {
                  without_refunds: 0.8511,
                  with_refunds: 0.8515,
                },
                total_spread_users: {
                  without_refunds: 0.8841,
                  with_refunds: 0.8843,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9028.64,
                  with_refunds: 8800.12,
                },
                gross_mrr: {
                  without_refunds: 9988.53,
                  with_refunds: 9735.76,
                },
                net_arr: {
                  without_refunds: 564.16,
                  with_refunds: 564.16,
                },
                gross_arr: {
                  without_refunds: 663.72,
                  with_refunds: 663.72,
                },
                net_arr_spread: {
                  without_refunds: 1431.51,
                  with_refunds: 1431.51,
                },
                gross_arr_spread: {
                  without_refunds: 2167.46,
                  with_refunds: 2167.46,
                },
                net_total: {
                  without_refunds: 9592.8,
                  with_refunds: 9364.28,
                },
                gross_total: {
                  without_refunds: 10652.25,
                  with_refunds: 10399.49,
                },
                net_total_spread: {
                  without_refunds: 10460.15,
                  with_refunds: 10231.63,
                },
                gross_total_spread: {
                  without_refunds: 12155.99,
                  with_refunds: 11903.23,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.433,
                  with_refunds: 0.4332,
                },
                gross_mrr: {
                  without_refunds: 0.3599,
                  with_refunds: 0.3594,
                },
                net_arr: {
                  without_refunds: 0.046,
                  with_refunds: 0.049,
                },
                gross_arr: {
                  without_refunds: 0.0347,
                  with_refunds: 0.037,
                },
                net_arr_spread: {
                  without_refunds: 1.5222,
                  with_refunds: 1.5222,
                },
                gross_arr_spread: {
                  without_refunds: 1.4798,
                  with_refunds: 1.4798,
                },
                net_total: {
                  without_refunds: 0.2898,
                  with_refunds: 0.2943,
                },
                gross_total: {
                  without_refunds: 0.2271,
                  with_refunds: 0.231,
                },
                net_total_spread: {
                  without_refunds: 0.48,
                  with_refunds: 0.4814,
                },
                gross_total_spread: {
                  without_refunds: 0.416,
                  with_refunds: 0.4169,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8572,
                  with_refunds: 0.869,
                },
                gross_mrr: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8651,
                },
                net_arr: {
                  without_refunds: 0.7956,
                  with_refunds: 1.1925,
                },
                gross_arr: {
                  without_refunds: 0.718,
                  with_refunds: 1.0713,
                },
                net_arr_spread: {
                  without_refunds: 1.034,
                  with_refunds: 1.034,
                },
                gross_arr_spread: {
                  without_refunds: 1.0262,
                  with_refunds: 1.0262,
                },
                net_total: {
                  without_refunds: 0.8534,
                  with_refunds: 0.8834,
                },
                gross_total: {
                  without_refunds: 0.8446,
                  with_refunds: 0.8759,
                },
                net_total_spread: {
                  without_refunds: 0.8778,
                  with_refunds: 0.8888,
                },
                gross_total_spread: {
                  without_refunds: 0.8808,
                  with_refunds: 0.8906,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.7,
                  with_refunds: 2.63,
                },
                gross_mrr: {
                  without_refunds: 2.99,
                  with_refunds: 2.91,
                },
                net_arr: {
                  without_refunds: 2.15,
                  with_refunds: 2.15,
                },
                gross_arr: {
                  without_refunds: 2.52,
                  with_refunds: 2.52,
                },
                net_arr_spread: {
                  without_refunds: 5.94,
                  with_refunds: 5.94,
                },
                gross_arr_spread: {
                  without_refunds: 8.99,
                  with_refunds: 8.99,
                },
                net_total: {
                  without_refunds: 2.68,
                  with_refunds: 2.62,
                },
                gross_total: {
                  without_refunds: 2.98,
                  with_refunds: 2.91,
                },
                net_total_spread: {
                  without_refunds: 2.94,
                  with_refunds: 2.88,
                },
                gross_total_spread: {
                  without_refunds: 3.42,
                  with_refunds: 3.35,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.5,
                  with_refunds: 11.15,
                },
                gross_mrr: {
                  without_refunds: 12.72,
                  with_refunds: 12.34,
                },
                net_arr: {
                  without_refunds: 112.83,
                  with_refunds: 112.83,
                },
                gross_arr: {
                  without_refunds: 132.74,
                  with_refunds: 132.74,
                },
                net_arr_spread: {
                  without_refunds: 6.2,
                  with_refunds: 6.2,
                },
                gross_arr_spread: {
                  without_refunds: 9.38,
                  with_refunds: 9.38,
                },
                net_total: {
                  without_refunds: 12.16,
                  with_refunds: 11.84,
                },
                gross_total: {
                  without_refunds: 13.5,
                  with_refunds: 13.15,
                },
                net_total_spread: {
                  without_refunds: 10.31,
                  with_refunds: 10.06,
                },
                gross_total_spread: {
                  without_refunds: 11.98,
                  with_refunds: 11.7,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.16,
                    with_refunds: 39.16,
                  },
                  gross_mrr: {
                    without_refunds: 47.27,
                    with_refunds: 46.11,
                  },
                  net_arr: {
                    without_refunds: 71.3,
                    with_refunds: 65.32,
                  },
                  gross_arr: {
                    without_refunds: 108.16,
                    with_refunds: 98.91,
                  },
                  net_arr_spread: {
                    without_refunds: 42.31,
                    with_refunds: 42.31,
                  },
                  gross_arr_spread: {
                    without_refunds: 65.06,
                    with_refunds: 65.06,
                  },
                  net_total: {
                    without_refunds: 42.83,
                    with_refunds: 41.45,
                  },
                  gross_total: {
                    without_refunds: 52.19,
                    with_refunds: 50.43,
                  },
                  net_total_spread: {
                    without_refunds: 40.64,
                    with_refunds: 39.7,
                  },
                  gross_total_spread: {
                    without_refunds: 48.87,
                    with_refunds: 47.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 89.25,
                    with_refunds: 86.74,
                  },
                  gross_mrr: {
                    without_refunds: 103.42,
                    with_refunds: 100.58,
                  },
                  net_arr: {
                    without_refunds: 741.1,
                    with_refunds: 578.21,
                  },
                  gross_arr: {
                    without_refunds: 979.4,
                    with_refunds: 769.61,
                  },
                  net_arr_spread: {
                    without_refunds: 48.17,
                    with_refunds: 48.17,
                  },
                  gross_arr_spread: {
                    without_refunds: 74.11,
                    with_refunds: 74.11,
                  },
                  net_total: {
                    without_refunds: 97.5,
                    with_refunds: 94.16,
                  },
                  gross_total: {
                    without_refunds: 115.9,
                    with_refunds: 111.8,
                  },
                  net_total_spread: {
                    without_refunds: 83.52,
                    with_refunds: 81.41,
                  },
                  gross_total_spread: {
                    without_refunds: 99.51,
                    with_refunds: 97.11,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9138,
                    with_refunds: 0.891,
                  },
                  gross_mrr: {
                    without_refunds: 1.0754,
                    with_refunds: 1.0491,
                  },
                  net_arr: {
                    without_refunds: 1.6224,
                    with_refunds: 1.4863,
                  },
                  gross_arr: {
                    without_refunds: 2.461,
                    with_refunds: 2.2505,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9628,
                    with_refunds: 0.9628,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4804,
                    with_refunds: 1.4804,
                  },
                  net_total: {
                    without_refunds: 0.9746,
                    with_refunds: 0.9432,
                  },
                  gross_total: {
                    without_refunds: 1.1876,
                    with_refunds: 1.1475,
                  },
                  net_total_spread: {
                    without_refunds: 0.9248,
                    with_refunds: 0.9033,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1119,
                    with_refunds: 1.0872,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.0308,
                    with_refunds: 1.9736,
                  },
                  gross_mrr: {
                    without_refunds: 2.3532,
                    with_refunds: 2.2885,
                  },
                  net_arr: {
                    without_refunds: 16.8624,
                    with_refunds: 13.1561,
                  },
                  gross_arr: {
                    without_refunds: 22.2844,
                    with_refunds: 17.5109,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0959,
                    with_refunds: 1.0959,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6862,
                    with_refunds: 1.6862,
                  },
                  net_total: {
                    without_refunds: 2.2185,
                    with_refunds: 2.1424,
                  },
                  gross_total: {
                    without_refunds: 2.6371,
                    with_refunds: 2.5438,
                  },
                  net_total_spread: {
                    without_refunds: 1.9003,
                    with_refunds: 1.8522,
                  },
                  gross_total_spread: {
                    without_refunds: 2.2642,
                    with_refunds: 2.2095,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 709,
                  with_refunds: 712,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 236,
                  with_refunds: 236,
                },
                total_users: {
                  without_refunds: 714,
                  with_refunds: 715,
                },
                total_spread_users: {
                  without_refunds: 944,
                  with_refunds: 945,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2119,
                  with_refunds: 0.2128,
                },
                arr_users: {
                  without_refunds: 0.0228,
                  with_refunds: 0.0228,
                },
                arr_spread_users: {
                  without_refunds: 0.9793,
                  with_refunds: 0.9793,
                },
                total_users: {
                  without_refunds: 0.1997,
                  with_refunds: 0.2,
                },
                total_spread_users: {
                  without_refunds: 0.2654,
                  with_refunds: 0.2657,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9032,
                  with_refunds: 0.9024,
                },
                arr_users: {
                  without_refunds: 1.2,
                  with_refunds: 1.2,
                },
                arr_spread_users: {
                  without_refunds: 1.0216,
                  with_refunds: 1.0216,
                },
                total_users: {
                  without_refunds: 0.9049,
                  with_refunds: 0.9039,
                },
                total_spread_users: {
                  without_refunds: 0.93,
                  with_refunds: 0.9292,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8191.3,
                  with_refunds: 8085.53,
                },
                gross_mrr: {
                  without_refunds: 9050.64,
                  with_refunds: 8937.02,
                },
                net_arr: {
                  without_refunds: 686.78,
                  with_refunds: 686.78,
                },
                gross_arr: {
                  without_refunds: 876.75,
                  with_refunds: 876.75,
                },
                net_arr_spread: {
                  without_refunds: 1482.58,
                  with_refunds: 1482.58,
                },
                gross_arr_spread: {
                  without_refunds: 2229.9,
                  with_refunds: 2229.9,
                },
                net_total: {
                  without_refunds: 8878.08,
                  with_refunds: 8772.32,
                },
                gross_total: {
                  without_refunds: 9927.38,
                  with_refunds: 9813.77,
                },
                net_total_spread: {
                  without_refunds: 9673.88,
                  with_refunds: 9568.11,
                },
                gross_total_spread: {
                  without_refunds: 11280.54,
                  with_refunds: 11166.92,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3928,
                  with_refunds: 0.398,
                },
                gross_mrr: {
                  without_refunds: 0.3261,
                  with_refunds: 0.3299,
                },
                net_arr: {
                  without_refunds: 0.0561,
                  with_refunds: 0.0597,
                },
                gross_arr: {
                  without_refunds: 0.0458,
                  with_refunds: 0.0489,
                },
                net_arr_spread: {
                  without_refunds: 1.5765,
                  with_refunds: 1.5765,
                },
                gross_arr_spread: {
                  without_refunds: 1.5224,
                  with_refunds: 1.5224,
                },
                net_total: {
                  without_refunds: 0.2682,
                  with_refunds: 0.2757,
                },
                gross_total: {
                  without_refunds: 0.2116,
                  with_refunds: 0.218,
                },
                net_total_spread: {
                  without_refunds: 0.4439,
                  with_refunds: 0.4502,
                },
                gross_total_spread: {
                  without_refunds: 0.3861,
                  with_refunds: 0.3911,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9073,
                  with_refunds: 0.9188,
                },
                gross_mrr: {
                  without_refunds: 0.9061,
                  with_refunds: 0.918,
                },
                net_arr: {
                  without_refunds: 1.2174,
                  with_refunds: 1.2174,
                },
                gross_arr: {
                  without_refunds: 1.321,
                  with_refunds: 1.321,
                },
                net_arr_spread: {
                  without_refunds: 1.0357,
                  with_refunds: 1.0357,
                },
                gross_arr_spread: {
                  without_refunds: 1.0288,
                  with_refunds: 1.0288,
                },
                net_total: {
                  without_refunds: 0.9255,
                  with_refunds: 0.9368,
                },
                gross_total: {
                  without_refunds: 0.932,
                  with_refunds: 0.9437,
                },
                net_total_spread: {
                  without_refunds: 0.9248,
                  with_refunds: 0.9352,
                },
                gross_total_spread: {
                  without_refunds: 0.928,
                  with_refunds: 0.9381,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.45,
                  with_refunds: 2.42,
                },
                gross_mrr: {
                  without_refunds: 2.7,
                  with_refunds: 2.67,
                },
                net_arr: {
                  without_refunds: 2.61,
                  with_refunds: 2.61,
                },
                gross_arr: {
                  without_refunds: 3.33,
                  with_refunds: 3.33,
                },
                net_arr_spread: {
                  without_refunds: 6.15,
                  with_refunds: 6.15,
                },
                gross_arr_spread: {
                  without_refunds: 9.25,
                  with_refunds: 9.25,
                },
                net_total: {
                  without_refunds: 2.48,
                  with_refunds: 2.45,
                },
                gross_total: {
                  without_refunds: 2.78,
                  with_refunds: 2.75,
                },
                net_total_spread: {
                  without_refunds: 2.72,
                  with_refunds: 2.69,
                },
                gross_total_spread: {
                  without_refunds: 3.17,
                  with_refunds: 3.14,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.55,
                  with_refunds: 11.36,
                },
                gross_mrr: {
                  without_refunds: 12.77,
                  with_refunds: 12.55,
                },
                net_arr: {
                  without_refunds: 114.46,
                  with_refunds: 114.46,
                },
                gross_arr: {
                  without_refunds: 146.12,
                  with_refunds: 146.12,
                },
                net_arr_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.28,
                },
                gross_arr_spread: {
                  without_refunds: 9.45,
                  with_refunds: 9.45,
                },
                net_total: {
                  without_refunds: 12.43,
                  with_refunds: 12.27,
                },
                gross_total: {
                  without_refunds: 13.9,
                  with_refunds: 13.73,
                },
                net_total_spread: {
                  without_refunds: 10.25,
                  with_refunds: 10.12,
                },
                gross_total_spread: {
                  without_refunds: 11.95,
                  with_refunds: 11.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.61,
                    with_refunds: 41.57,
                  },
                  gross_mrr: {
                    without_refunds: 49.97,
                    with_refunds: 48.78,
                  },
                  net_arr: {
                    without_refunds: 73.91,
                    with_refunds: 67.93,
                  },
                  gross_arr: {
                    without_refunds: 111.49,
                    with_refunds: 102.24,
                  },
                  net_arr_spread: {
                    without_refunds: 48.47,
                    with_refunds: 48.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 74.32,
                    with_refunds: 74.32,
                  },
                  net_total: {
                    without_refunds: 45.32,
                    with_refunds: 43.91,
                  },
                  gross_total: {
                    without_refunds: 54.97,
                    with_refunds: 53.18,
                  },
                  net_total_spread: {
                    without_refunds: 43.36,
                    with_refunds: 42.39,
                  },
                  gross_total_spread: {
                    without_refunds: 52.04,
                    with_refunds: 50.92,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 100.81,
                    with_refunds: 98.1,
                  },
                  gross_mrr: {
                    without_refunds: 116.19,
                    with_refunds: 113.13,
                  },
                  net_arr: {
                    without_refunds: 855.57,
                    with_refunds: 692.68,
                  },
                  gross_arr: {
                    without_refunds: 1125.52,
                    with_refunds: 915.73,
                  },
                  net_arr_spread: {
                    without_refunds: 54.45,
                    with_refunds: 54.45,
                  },
                  gross_arr_spread: {
                    without_refunds: 83.56,
                    with_refunds: 83.56,
                  },
                  net_total: {
                    without_refunds: 109.94,
                    with_refunds: 106.43,
                  },
                  gross_total: {
                    without_refunds: 129.81,
                    with_refunds: 125.53,
                  },
                  net_total_spread: {
                    without_refunds: 93.77,
                    with_refunds: 91.53,
                  },
                  gross_total_spread: {
                    without_refunds: 111.46,
                    with_refunds: 108.93,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9695,
                    with_refunds: 0.9459,
                  },
                  gross_mrr: {
                    without_refunds: 1.137,
                    with_refunds: 1.1099,
                  },
                  net_arr: {
                    without_refunds: 1.6818,
                    with_refunds: 1.5457,
                  },
                  gross_arr: {
                    without_refunds: 2.5368,
                    with_refunds: 2.3263,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1028,
                    with_refunds: 1.1028,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6909,
                    with_refunds: 1.6909,
                  },
                  net_total: {
                    without_refunds: 1.0311,
                    with_refunds: 0.9991,
                  },
                  gross_total: {
                    without_refunds: 1.2508,
                    with_refunds: 1.2099,
                  },
                  net_total_spread: {
                    without_refunds: 0.9867,
                    with_refunds: 0.9645,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1841,
                    with_refunds: 1.1586,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.2937,
                    with_refunds: 2.232,
                  },
                  gross_mrr: {
                    without_refunds: 2.6437,
                    with_refunds: 2.5741,
                  },
                  net_arr: {
                    without_refunds: 19.4668,
                    with_refunds: 15.7606,
                  },
                  gross_arr: {
                    without_refunds: 25.6092,
                    with_refunds: 20.8357,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2388,
                    with_refunds: 1.2388,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9012,
                    with_refunds: 1.9012,
                  },
                  net_total: {
                    without_refunds: 2.5014,
                    with_refunds: 2.4216,
                  },
                  gross_total: {
                    without_refunds: 2.9535,
                    with_refunds: 2.8561,
                  },
                  net_total_spread: {
                    without_refunds: 2.1335,
                    with_refunds: 2.0826,
                  },
                  gross_total_spread: {
                    without_refunds: 2.5361,
                    with_refunds: 2.4784,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 634,
                  with_refunds: 637,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 3,
                },
                arr_spread_users: {
                  without_refunds: 238,
                  with_refunds: 238,
                },
                total_users: {
                  without_refunds: 637,
                  with_refunds: 640,
                },
                total_spread_users: {
                  without_refunds: 872,
                  with_refunds: 875,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1895,
                  with_refunds: 0.1904,
                },
                arr_users: {
                  without_refunds: 0.0114,
                  with_refunds: 0.0114,
                },
                arr_spread_users: {
                  without_refunds: 0.9876,
                  with_refunds: 0.9876,
                },
                total_users: {
                  without_refunds: 0.1782,
                  with_refunds: 0.179,
                },
                total_spread_users: {
                  without_refunds: 0.2452,
                  with_refunds: 0.246,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8942,
                  with_refunds: 0.8947,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.5,
                },
                arr_spread_users: {
                  without_refunds: 1.0085,
                  with_refunds: 1.0085,
                },
                total_users: {
                  without_refunds: 0.8922,
                  with_refunds: 0.8951,
                },
                total_spread_users: {
                  without_refunds: 0.9237,
                  with_refunds: 0.9259,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7212.39,
                  with_refunds: 7061.86,
                },
                gross_mrr: {
                  without_refunds: 7956.48,
                  with_refunds: 7789.7,
                },
                net_arr: {
                  without_refunds: 315.77,
                  with_refunds: 174.62,
                },
                gross_arr: {
                  without_refunds: 419.4,
                  with_refunds: 219.47,
                },
                net_arr_spread: {
                  without_refunds: 1499.15,
                  with_refunds: 1499.15,
                },
                gross_arr_spread: {
                  without_refunds: 2251.66,
                  with_refunds: 2251.66,
                },
                net_total: {
                  without_refunds: 7528.16,
                  with_refunds: 7236.48,
                },
                gross_total: {
                  without_refunds: 8375.88,
                  with_refunds: 8009.17,
                },
                net_total_spread: {
                  without_refunds: 8711.54,
                  with_refunds: 8561,
                },
                gross_total_spread: {
                  without_refunds: 10208.14,
                  with_refunds: 10041.36,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3459,
                  with_refunds: 0.3476,
                },
                gross_mrr: {
                  without_refunds: 0.2867,
                  with_refunds: 0.2876,
                },
                net_arr: {
                  without_refunds: 0.0258,
                  with_refunds: 0.0152,
                },
                gross_arr: {
                  without_refunds: 0.0219,
                  with_refunds: 0.0122,
                },
                net_arr_spread: {
                  without_refunds: 1.5941,
                  with_refunds: 1.5941,
                },
                gross_arr_spread: {
                  without_refunds: 1.5373,
                  with_refunds: 1.5373,
                },
                net_total: {
                  without_refunds: 0.2274,
                  with_refunds: 0.2274,
                },
                gross_total: {
                  without_refunds: 0.1786,
                  with_refunds: 0.1779,
                },
                net_total_spread: {
                  without_refunds: 0.3998,
                  with_refunds: 0.4028,
                },
                gross_total_spread: {
                  without_refunds: 0.3494,
                  with_refunds: 0.3517,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8805,
                  with_refunds: 0.8734,
                },
                gross_mrr: {
                  without_refunds: 0.8791,
                  with_refunds: 0.8716,
                },
                net_arr: {
                  without_refunds: 0.4598,
                  with_refunds: 0.2543,
                },
                gross_arr: {
                  without_refunds: 0.4784,
                  with_refunds: 0.2503,
                },
                net_arr_spread: {
                  without_refunds: 1.0112,
                  with_refunds: 1.0112,
                },
                gross_arr_spread: {
                  without_refunds: 1.0098,
                  with_refunds: 1.0098,
                },
                net_total: {
                  without_refunds: 0.8479,
                  with_refunds: 0.8249,
                },
                gross_total: {
                  without_refunds: 0.8437,
                  with_refunds: 0.8161,
                },
                net_total_spread: {
                  without_refunds: 0.9005,
                  with_refunds: 0.8947,
                },
                gross_total_spread: {
                  without_refunds: 0.9049,
                  with_refunds: 0.8992,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.16,
                  with_refunds: 2.11,
                },
                gross_mrr: {
                  without_refunds: 2.38,
                  with_refunds: 2.33,
                },
                net_arr: {
                  without_refunds: 1.2,
                  with_refunds: 0.66,
                },
                gross_arr: {
                  without_refunds: 1.59,
                  with_refunds: 0.83,
                },
                net_arr_spread: {
                  without_refunds: 6.22,
                  with_refunds: 6.22,
                },
                gross_arr_spread: {
                  without_refunds: 9.34,
                  with_refunds: 9.34,
                },
                net_total: {
                  without_refunds: 2.11,
                  with_refunds: 2.02,
                },
                gross_total: {
                  without_refunds: 2.34,
                  with_refunds: 2.24,
                },
                net_total_spread: {
                  without_refunds: 2.45,
                  with_refunds: 2.41,
                },
                gross_total_spread: {
                  without_refunds: 2.87,
                  with_refunds: 2.82,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.38,
                  with_refunds: 11.09,
                },
                gross_mrr: {
                  without_refunds: 12.55,
                  with_refunds: 12.23,
                },
                net_arr: {
                  without_refunds: 105.26,
                  with_refunds: 58.21,
                },
                gross_arr: {
                  without_refunds: 139.8,
                  with_refunds: 73.16,
                },
                net_arr_spread: {
                  without_refunds: 6.3,
                  with_refunds: 6.3,
                },
                gross_arr_spread: {
                  without_refunds: 9.46,
                  with_refunds: 9.46,
                },
                net_total: {
                  without_refunds: 11.82,
                  with_refunds: 11.31,
                },
                gross_total: {
                  without_refunds: 13.15,
                  with_refunds: 12.51,
                },
                net_total_spread: {
                  without_refunds: 9.99,
                  with_refunds: 9.78,
                },
                gross_total_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.48,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.76,
                    with_refunds: 43.68,
                  },
                  gross_mrr: {
                    without_refunds: 52.35,
                    with_refunds: 51.11,
                  },
                  net_arr: {
                    without_refunds: 75.11,
                    with_refunds: 68.6,
                  },
                  gross_arr: {
                    without_refunds: 113.09,
                    with_refunds: 103.08,
                  },
                  net_arr_spread: {
                    without_refunds: 54.69,
                    with_refunds: 54.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 83.66,
                    with_refunds: 83.66,
                  },
                  net_total: {
                    without_refunds: 47.42,
                    with_refunds: 45.93,
                  },
                  gross_total: {
                    without_refunds: 57.31,
                    with_refunds: 55.42,
                  },
                  net_total_spread: {
                    without_refunds: 45.81,
                    with_refunds: 44.8,
                  },
                  gross_total_spread: {
                    without_refunds: 54.91,
                    with_refunds: 53.74,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 112.18,
                    with_refunds: 109.18,
                  },
                  gross_mrr: {
                    without_refunds: 128.74,
                    with_refunds: 125.36,
                  },
                  net_arr: {
                    without_refunds: 960.82,
                    with_refunds: 750.88,
                  },
                  gross_arr: {
                    without_refunds: 1265.32,
                    with_refunds: 988.89,
                  },
                  net_arr_spread: {
                    without_refunds: 60.75,
                    with_refunds: 60.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 93.02,
                    with_refunds: 93.02,
                  },
                  net_total: {
                    without_refunds: 121.75,
                    with_refunds: 117.74,
                  },
                  gross_total: {
                    without_refunds: 142.96,
                    with_refunds: 138.04,
                  },
                  net_total_spread: {
                    without_refunds: 103.76,
                    with_refunds: 101.32,
                  },
                  gross_total_spread: {
                    without_refunds: 123.17,
                    with_refunds: 120.4,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0185,
                    with_refunds: 0.994,
                  },
                  gross_mrr: {
                    without_refunds: 1.1911,
                    with_refunds: 1.1629,
                  },
                  net_arr: {
                    without_refunds: 1.7091,
                    with_refunds: 1.5608,
                  },
                  gross_arr: {
                    without_refunds: 2.5731,
                    with_refunds: 2.3453,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2443,
                    with_refunds: 1.2443,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9035,
                    with_refunds: 1.9035,
                  },
                  net_total: {
                    without_refunds: 1.079,
                    with_refunds: 1.0451,
                  },
                  gross_total: {
                    without_refunds: 1.3041,
                    with_refunds: 1.2609,
                  },
                  net_total_spread: {
                    without_refunds: 1.0424,
                    with_refunds: 1.0193,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2494,
                    with_refunds: 1.2229,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.5525,
                    with_refunds: 2.4842,
                  },
                  gross_mrr: {
                    without_refunds: 2.9292,
                    with_refunds: 2.8523,
                  },
                  net_arr: {
                    without_refunds: 21.8617,
                    with_refunds: 17.0849,
                  },
                  gross_arr: {
                    without_refunds: 28.7901,
                    with_refunds: 22.5003,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3822,
                    with_refunds: 1.3822,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.1165,
                    with_refunds: 2.1165,
                  },
                  net_total: {
                    without_refunds: 2.7703,
                    with_refunds: 2.6789,
                  },
                  gross_total: {
                    without_refunds: 3.2527,
                    with_refunds: 3.1408,
                  },
                  net_total_spread: {
                    without_refunds: 2.3608,
                    with_refunds: 2.3052,
                  },
                  gross_total_spread: {
                    without_refunds: 2.8025,
                    with_refunds: 2.7395,
                  },
                },
              },
            },
          },
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 587,
                  with_refunds: 588,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 3,
                },
                arr_spread_users: {
                  without_refunds: 240,
                  with_refunds: 240,
                },
                total_users: {
                  without_refunds: 590,
                  with_refunds: 591,
                },
                total_spread_users: {
                  without_refunds: 827,
                  with_refunds: 828,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1754,
                  with_refunds: 0.1757,
                },
                arr_users: {
                  without_refunds: 0.0114,
                  with_refunds: 0.0114,
                },
                arr_spread_users: {
                  without_refunds: 0.9959,
                  with_refunds: 0.9959,
                },
                total_users: {
                  without_refunds: 0.165,
                  with_refunds: 0.1653,
                },
                total_spread_users: {
                  without_refunds: 0.2325,
                  with_refunds: 0.2328,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9259,
                  with_refunds: 0.9231,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1.0084,
                  with_refunds: 1.0084,
                },
                total_users: {
                  without_refunds: 0.9262,
                  with_refunds: 0.9234,
                },
                total_spread_users: {
                  without_refunds: 0.9484,
                  with_refunds: 0.9463,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6716.14,
                  with_refunds: 6659.39,
                },
                gross_mrr: {
                  without_refunds: 7405.3,
                  with_refunds: 7345.3,
                },
                net_arr: {
                  without_refunds: 342.73,
                  with_refunds: 219.35,
                },
                gross_arr: {
                  without_refunds: 452.88,
                  with_refunds: 272.89,
                },
                net_arr_spread: {
                  without_refunds: 1517.43,
                  with_refunds: 1517.43,
                },
                gross_arr_spread: {
                  without_refunds: 2274.41,
                  with_refunds: 2274.41,
                },
                net_total: {
                  without_refunds: 7058.88,
                  with_refunds: 6878.75,
                },
                gross_total: {
                  without_refunds: 7858.18,
                  with_refunds: 7618.19,
                },
                net_total_spread: {
                  without_refunds: 8233.57,
                  with_refunds: 8176.82,
                },
                gross_total_spread: {
                  without_refunds: 9679.7,
                  with_refunds: 9619.7,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3221,
                  with_refunds: 0.3278,
                },
                gross_mrr: {
                  without_refunds: 0.2668,
                  with_refunds: 0.2712,
                },
                net_arr: {
                  without_refunds: 0.028,
                  with_refunds: 0.0191,
                },
                gross_arr: {
                  without_refunds: 0.0236,
                  with_refunds: 0.0152,
                },
                net_arr_spread: {
                  without_refunds: 1.6136,
                  with_refunds: 1.6136,
                },
                gross_arr_spread: {
                  without_refunds: 1.5528,
                  with_refunds: 1.5528,
                },
                net_total: {
                  without_refunds: 0.2132,
                  with_refunds: 0.2162,
                },
                gross_total: {
                  without_refunds: 0.1675,
                  with_refunds: 0.1692,
                },
                net_total_spread: {
                  without_refunds: 0.3778,
                  with_refunds: 0.3847,
                },
                gross_total_spread: {
                  without_refunds: 0.3313,
                  with_refunds: 0.3369,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9312,
                  with_refunds: 0.943,
                },
                gross_mrr: {
                  without_refunds: 0.9307,
                  with_refunds: 0.943,
                },
                net_arr: {
                  without_refunds: 1.0854,
                  with_refunds: 1.2562,
                },
                gross_arr: {
                  without_refunds: 1.0798,
                  with_refunds: 1.2434,
                },
                net_arr_spread: {
                  without_refunds: 1.0122,
                  with_refunds: 1.0122,
                },
                gross_arr_spread: {
                  without_refunds: 1.0101,
                  with_refunds: 1.0101,
                },
                net_total: {
                  without_refunds: 0.9377,
                  with_refunds: 0.9506,
                },
                gross_total: {
                  without_refunds: 0.9382,
                  with_refunds: 0.9512,
                },
                net_total_spread: {
                  without_refunds: 0.9451,
                  with_refunds: 0.9551,
                },
                gross_total_spread: {
                  without_refunds: 0.9482,
                  with_refunds: 0.958,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.01,
                  with_refunds: 1.99,
                },
                gross_mrr: {
                  without_refunds: 2.21,
                  with_refunds: 2.2,
                },
                net_arr: {
                  without_refunds: 1.3,
                  with_refunds: 0.83,
                },
                gross_arr: {
                  without_refunds: 1.72,
                  with_refunds: 1.04,
                },
                net_arr_spread: {
                  without_refunds: 6.3,
                  with_refunds: 6.3,
                },
                gross_arr_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.44,
                },
                net_total: {
                  without_refunds: 1.97,
                  with_refunds: 1.92,
                },
                gross_total: {
                  without_refunds: 2.2,
                  with_refunds: 2.13,
                },
                net_total_spread: {
                  without_refunds: 2.31,
                  with_refunds: 2.3,
                },
                gross_total_spread: {
                  without_refunds: 2.72,
                  with_refunds: 2.7,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.44,
                  with_refunds: 11.33,
                },
                gross_mrr: {
                  without_refunds: 12.62,
                  with_refunds: 12.49,
                },
                net_arr: {
                  without_refunds: 114.24,
                  with_refunds: 73.12,
                },
                gross_arr: {
                  without_refunds: 150.96,
                  with_refunds: 90.96,
                },
                net_arr_spread: {
                  without_refunds: 6.32,
                  with_refunds: 6.32,
                },
                gross_arr_spread: {
                  without_refunds: 9.48,
                  with_refunds: 9.48,
                },
                net_total: {
                  without_refunds: 11.96,
                  with_refunds: 11.64,
                },
                gross_total: {
                  without_refunds: 13.32,
                  with_refunds: 12.89,
                },
                net_total_spread: {
                  without_refunds: 9.96,
                  with_refunds: 9.88,
                },
                gross_total_spread: {
                  without_refunds: 11.7,
                  with_refunds: 11.62,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 46.77,
                    with_refunds: 45.67,
                  },
                  gross_mrr: {
                    without_refunds: 54.56,
                    with_refunds: 53.3,
                  },
                  net_arr: {
                    without_refunds: 76.42,
                    with_refunds: 69.43,
                  },
                  gross_arr: {
                    without_refunds: 114.81,
                    with_refunds: 104.11,
                  },
                  net_arr_spread: {
                    without_refunds: 60.98,
                    with_refunds: 60.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 93.1,
                    with_refunds: 93.1,
                  },
                  net_total: {
                    without_refunds: 49.4,
                    with_refunds: 47.86,
                  },
                  gross_total: {
                    without_refunds: 59.51,
                    with_refunds: 57.55,
                  },
                  net_total_spread: {
                    without_refunds: 48.13,
                    with_refunds: 47.1,
                  },
                  gross_total_spread: {
                    without_refunds: 57.63,
                    with_refunds: 56.45,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 123.63,
                    with_refunds: 120.51,
                  },
                  gross_mrr: {
                    without_refunds: 141.36,
                    with_refunds: 137.85,
                  },
                  net_arr: {
                    without_refunds: 1075.07,
                    with_refunds: 824,
                  },
                  gross_arr: {
                    without_refunds: 1416.28,
                    with_refunds: 1079.85,
                  },
                  net_arr_spread: {
                    without_refunds: 67.07,
                    with_refunds: 67.07,
                  },
                  gross_arr_spread: {
                    without_refunds: 102.5,
                    with_refunds: 102.5,
                  },
                  net_total: {
                    without_refunds: 133.72,
                    with_refunds: 129.38,
                  },
                  gross_total: {
                    without_refunds: 156.27,
                    with_refunds: 150.93,
                  },
                  net_total_spread: {
                    without_refunds: 113.71,
                    with_refunds: 111.19,
                  },
                  gross_total_spread: {
                    without_refunds: 134.87,
                    with_refunds: 132.02,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0642,
                    with_refunds: 1.0392,
                  },
                  gross_mrr: {
                    without_refunds: 1.2414,
                    with_refunds: 1.2128,
                  },
                  net_arr: {
                    without_refunds: 1.7387,
                    with_refunds: 1.5798,
                  },
                  gross_arr: {
                    without_refunds: 2.6123,
                    with_refunds: 2.3689,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3876,
                    with_refunds: 1.3876,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.1182,
                    with_refunds: 2.1182,
                  },
                  net_total: {
                    without_refunds: 1.1239,
                    with_refunds: 1.0889,
                  },
                  gross_total: {
                    without_refunds: 1.3541,
                    with_refunds: 1.3094,
                  },
                  net_total_spread: {
                    without_refunds: 1.0951,
                    with_refunds: 1.0716,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3113,
                    with_refunds: 1.2844,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.8129,
                    with_refunds: 2.7419,
                  },
                  gross_mrr: {
                    without_refunds: 3.2163,
                    with_refunds: 3.1365,
                  },
                  net_arr: {
                    without_refunds: 24.4612,
                    with_refunds: 18.7486,
                  },
                  gross_arr: {
                    without_refunds: 32.2249,
                    with_refunds: 24.57,
                  },
                  net_arr_spread: {
                    without_refunds: 1.526,
                    with_refunds: 1.526,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.3321,
                    with_refunds: 2.3321,
                  },
                  net_total: {
                    without_refunds: 3.0425,
                    with_refunds: 2.9437,
                  },
                  gross_total: {
                    without_refunds: 3.5557,
                    with_refunds: 3.4341,
                  },
                  net_total_spread: {
                    without_refunds: 2.5873,
                    with_refunds: 2.5299,
                  },
                  gross_total_spread: {
                    without_refunds: 3.0688,
                    with_refunds: 3.0039,
                  },
                },
              },
            },
          },
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 539,
                  with_refunds: 540,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 241,
                  with_refunds: 241,
                },
                total_users: {
                  without_refunds: 540,
                  with_refunds: 541,
                },
                total_spread_users: {
                  without_refunds: 780,
                  with_refunds: 781,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1611,
                  with_refunds: 0.1614,
                },
                arr_users: {
                  without_refunds: 0.0038,
                  with_refunds: 0.0038,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.151,
                  with_refunds: 0.1513,
                },
                total_spread_users: {
                  without_refunds: 0.2193,
                  with_refunds: 0.2196,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9182,
                  with_refunds: 0.9184,
                },
                arr_users: {
                  without_refunds: 0.3333,
                  with_refunds: 0.3333,
                },
                arr_spread_users: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                total_users: {
                  without_refunds: 0.9153,
                  with_refunds: 0.9154,
                },
                total_spread_users: {
                  without_refunds: 0.9432,
                  with_refunds: 0.9432,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6185.19,
                  with_refunds: 6173.84,
                },
                gross_mrr: {
                  without_refunds: 6830.25,
                  with_refunds: 6818.25,
                },
                net_arr: {
                  without_refunds: 106.24,
                  with_refunds: 106.24,
                },
                gross_arr: {
                  without_refunds: 124.99,
                  with_refunds: 124.99,
                },
                net_arr_spread: {
                  without_refunds: 1526.28,
                  with_refunds: 1526.28,
                },
                gross_arr_spread: {
                  without_refunds: 2284.82,
                  with_refunds: 2284.82,
                },
                net_total: {
                  without_refunds: 6291.43,
                  with_refunds: 6280.08,
                },
                gross_total: {
                  without_refunds: 6955.24,
                  with_refunds: 6943.24,
                },
                net_total_spread: {
                  without_refunds: 7711.46,
                  with_refunds: 7700.11,
                },
                gross_total_spread: {
                  without_refunds: 9115.07,
                  with_refunds: 9103.07,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2966,
                  with_refunds: 0.3039,
                },
                gross_mrr: {
                  without_refunds: 0.2461,
                  with_refunds: 0.2517,
                },
                net_arr: {
                  without_refunds: 0.0087,
                  with_refunds: 0.0092,
                },
                gross_arr: {
                  without_refunds: 0.0065,
                  with_refunds: 0.007,
                },
                net_arr_spread: {
                  without_refunds: 1.623,
                  with_refunds: 1.623,
                },
                gross_arr_spread: {
                  without_refunds: 1.5599,
                  with_refunds: 1.5599,
                },
                net_total: {
                  without_refunds: 0.1901,
                  with_refunds: 0.1974,
                },
                gross_total: {
                  without_refunds: 0.1483,
                  with_refunds: 0.1542,
                },
                net_total_spread: {
                  without_refunds: 0.3539,
                  with_refunds: 0.3623,
                },
                gross_total_spread: {
                  without_refunds: 0.312,
                  with_refunds: 0.3188,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9209,
                  with_refunds: 0.9271,
                },
                gross_mrr: {
                  without_refunds: 0.9223,
                  with_refunds: 0.9282,
                },
                net_arr: {
                  without_refunds: 0.31,
                  with_refunds: 0.4843,
                },
                gross_arr: {
                  without_refunds: 0.276,
                  with_refunds: 0.458,
                },
                net_arr_spread: {
                  without_refunds: 1.0058,
                  with_refunds: 1.0058,
                },
                gross_arr_spread: {
                  without_refunds: 1.0046,
                  with_refunds: 1.0046,
                },
                net_total: {
                  without_refunds: 0.8913,
                  with_refunds: 0.913,
                },
                gross_total: {
                  without_refunds: 0.8851,
                  with_refunds: 0.9114,
                },
                net_total_spread: {
                  without_refunds: 0.9366,
                  with_refunds: 0.9417,
                },
                gross_total_spread: {
                  without_refunds: 0.9417,
                  with_refunds: 0.9463,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.85,
                  with_refunds: 1.85,
                },
                gross_mrr: {
                  without_refunds: 2.04,
                  with_refunds: 2.04,
                },
                net_arr: {
                  without_refunds: 0.4,
                  with_refunds: 0.4,
                },
                gross_arr: {
                  without_refunds: 0.48,
                  with_refunds: 0.48,
                },
                net_arr_spread: {
                  without_refunds: 6.33,
                  with_refunds: 6.33,
                },
                gross_arr_spread: {
                  without_refunds: 9.48,
                  with_refunds: 9.48,
                },
                net_total: {
                  without_refunds: 1.76,
                  with_refunds: 1.76,
                },
                gross_total: {
                  without_refunds: 1.95,
                  with_refunds: 1.94,
                },
                net_total_spread: {
                  without_refunds: 2.17,
                  with_refunds: 2.16,
                },
                gross_total_spread: {
                  without_refunds: 2.56,
                  with_refunds: 2.56,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.48,
                  with_refunds: 11.43,
                },
                gross_mrr: {
                  without_refunds: 12.67,
                  with_refunds: 12.63,
                },
                net_arr: {
                  without_refunds: 106.24,
                  with_refunds: 106.24,
                },
                gross_arr: {
                  without_refunds: 124.99,
                  with_refunds: 124.99,
                },
                net_arr_spread: {
                  without_refunds: 6.33,
                  with_refunds: 6.33,
                },
                gross_arr_spread: {
                  without_refunds: 9.48,
                  with_refunds: 9.48,
                },
                net_total: {
                  without_refunds: 11.65,
                  with_refunds: 11.61,
                },
                gross_total: {
                  without_refunds: 12.88,
                  with_refunds: 12.83,
                },
                net_total_spread: {
                  without_refunds: 9.89,
                  with_refunds: 9.86,
                },
                gross_total_spread: {
                  without_refunds: 11.69,
                  with_refunds: 11.66,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 48.62,
                    with_refunds: 47.52,
                  },
                  gross_mrr: {
                    without_refunds: 56.6,
                    with_refunds: 55.34,
                  },
                  net_arr: {
                    without_refunds: 76.82,
                    with_refunds: 69.84,
                  },
                  gross_arr: {
                    without_refunds: 115.28,
                    with_refunds: 104.59,
                  },
                  net_arr_spread: {
                    without_refunds: 67.32,
                    with_refunds: 67.32,
                  },
                  gross_arr_spread: {
                    without_refunds: 102.58,
                    with_refunds: 102.58,
                  },
                  net_total: {
                    without_refunds: 51.16,
                    with_refunds: 49.61,
                  },
                  gross_total: {
                    without_refunds: 61.46,
                    with_refunds: 59.49,
                  },
                  net_total_spread: {
                    without_refunds: 50.3,
                    with_refunds: 49.26,
                  },
                  gross_total_spread: {
                    without_refunds: 60.2,
                    with_refunds: 59.01,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 135.1,
                    with_refunds: 131.94,
                  },
                  gross_mrr: {
                    without_refunds: 154.03,
                    with_refunds: 150.48,
                  },
                  net_arr: {
                    without_refunds: 1181.31,
                    with_refunds: 930.24,
                  },
                  gross_arr: {
                    without_refunds: 1541.27,
                    with_refunds: 1204.84,
                  },
                  net_arr_spread: {
                    without_refunds: 73.4,
                    with_refunds: 73.4,
                  },
                  gross_arr_spread: {
                    without_refunds: 111.98,
                    with_refunds: 111.98,
                  },
                  net_total: {
                    without_refunds: 145.37,
                    with_refunds: 140.98,
                  },
                  gross_total: {
                    without_refunds: 169.15,
                    with_refunds: 163.76,
                  },
                  net_total_spread: {
                    without_refunds: 123.6,
                    with_refunds: 121.05,
                  },
                  gross_total_spread: {
                    without_refunds: 146.56,
                    with_refunds: 143.68,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1062,
                    with_refunds: 1.0812,
                  },
                  gross_mrr: {
                    without_refunds: 1.2879,
                    with_refunds: 1.2592,
                  },
                  net_arr: {
                    without_refunds: 1.7479,
                    with_refunds: 1.589,
                  },
                  gross_arr: {
                    without_refunds: 2.6231,
                    with_refunds: 2.3797,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5317,
                    with_refunds: 1.5317,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.3339,
                    with_refunds: 2.3339,
                  },
                  net_total: {
                    without_refunds: 1.164,
                    with_refunds: 1.1289,
                  },
                  gross_total: {
                    without_refunds: 1.3984,
                    with_refunds: 1.3536,
                  },
                  net_total_spread: {
                    without_refunds: 1.1444,
                    with_refunds: 1.1209,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3696,
                    with_refunds: 1.3426,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.074,
                    with_refunds: 3.0021,
                  },
                  gross_mrr: {
                    without_refunds: 3.5046,
                    with_refunds: 3.4238,
                  },
                  net_arr: {
                    without_refunds: 26.8785,
                    with_refunds: 21.1659,
                  },
                  gross_arr: {
                    without_refunds: 35.0688,
                    with_refunds: 27.4139,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6701,
                    with_refunds: 1.6701,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.5478,
                    with_refunds: 2.5478,
                  },
                  net_total: {
                    without_refunds: 3.3076,
                    with_refunds: 3.2078,
                  },
                  gross_total: {
                    without_refunds: 3.8488,
                    with_refunds: 3.7262,
                  },
                  net_total_spread: {
                    without_refunds: 2.8122,
                    with_refunds: 2.7543,
                  },
                  gross_total_spread: {
                    without_refunds: 3.3347,
                    with_refunds: 3.2691,
                  },
                },
              },
            },
          },
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 162,
                  with_refunds: 162,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 82,
                  with_refunds: 82,
                },
                total_users: {
                  without_refunds: 162,
                  with_refunds: 162,
                },
                total_spread_users: {
                  without_refunds: 244,
                  with_refunds: 244,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0484,
                  with_refunds: 0.0484,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.0453,
                  with_refunds: 0.0453,
                },
                total_spread_users: {
                  without_refunds: 0.0686,
                  with_refunds: 0.0686,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1849.76,
                  with_refunds: 1849.76,
                },
                gross_mrr: {
                  without_refunds: 2030.52,
                  with_refunds: 2030.52,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 585.86,
                  with_refunds: 585.86,
                },
                gross_arr_spread: {
                  without_refunds: 820.13,
                  with_refunds: 820.13,
                },
                net_total: {
                  without_refunds: 1849.76,
                  with_refunds: 1849.76,
                },
                gross_total: {
                  without_refunds: 2030.52,
                  with_refunds: 2030.52,
                },
                net_total_spread: {
                  without_refunds: 2435.62,
                  with_refunds: 2435.62,
                },
                gross_total_spread: {
                  without_refunds: 2850.65,
                  with_refunds: 2850.65,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0887,
                  with_refunds: 0.0911,
                },
                gross_mrr: {
                  without_refunds: 0.0732,
                  with_refunds: 0.075,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.623,
                  with_refunds: 0.623,
                },
                gross_arr_spread: {
                  without_refunds: 0.5599,
                  with_refunds: 0.5599,
                },
                net_total: {
                  without_refunds: 0.0559,
                  with_refunds: 0.0581,
                },
                gross_total: {
                  without_refunds: 0.0433,
                  with_refunds: 0.0451,
                },
                net_total_spread: {
                  without_refunds: 0.1118,
                  with_refunds: 0.1146,
                },
                gross_total_spread: {
                  without_refunds: 0.0976,
                  with_refunds: 0.0998,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.55,
                  with_refunds: 0.55,
                },
                gross_mrr: {
                  without_refunds: 0.61,
                  with_refunds: 0.61,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.43,
                  with_refunds: 2.43,
                },
                gross_arr_spread: {
                  without_refunds: 3.4,
                  with_refunds: 3.4,
                },
                net_total: {
                  without_refunds: 0.52,
                  with_refunds: 0.52,
                },
                gross_total: {
                  without_refunds: 0.57,
                  with_refunds: 0.57,
                },
                net_total_spread: {
                  without_refunds: 0.68,
                  with_refunds: 0.68,
                },
                gross_total_spread: {
                  without_refunds: 0.8,
                  with_refunds: 0.8,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.42,
                },
                gross_mrr: {
                  without_refunds: 12.53,
                  with_refunds: 12.53,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.14,
                  with_refunds: 7.14,
                },
                gross_arr_spread: {
                  without_refunds: 10,
                  with_refunds: 10,
                },
                net_total: {
                  without_refunds: 11.42,
                  with_refunds: 11.42,
                },
                gross_total: {
                  without_refunds: 12.53,
                  with_refunds: 12.53,
                },
                net_total_spread: {
                  without_refunds: 9.98,
                  with_refunds: 9.98,
                },
                gross_total_spread: {
                  without_refunds: 11.68,
                  with_refunds: 11.68,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 49.17,
                    with_refunds: 48.07,
                  },
                  gross_mrr: {
                    without_refunds: 57.21,
                    with_refunds: 55.95,
                  },
                  net_arr: {
                    without_refunds: 76.82,
                    with_refunds: 69.84,
                  },
                  gross_arr: {
                    without_refunds: 115.28,
                    with_refunds: 104.59,
                  },
                  net_arr_spread: {
                    without_refunds: 69.75,
                    with_refunds: 69.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 105.98,
                    with_refunds: 105.98,
                  },
                  net_total: {
                    without_refunds: 51.67,
                    with_refunds: 50.13,
                  },
                  gross_total: {
                    without_refunds: 62.03,
                    with_refunds: 60.06,
                  },
                  net_total_spread: {
                    without_refunds: 50.98,
                    with_refunds: 49.95,
                  },
                  gross_total_spread: {
                    without_refunds: 61,
                    with_refunds: 59.81,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 146.52,
                    with_refunds: 143.36,
                  },
                  gross_mrr: {
                    without_refunds: 166.56,
                    with_refunds: 163.01,
                  },
                  net_arr: {
                    without_refunds: 1181.31,
                    with_refunds: 930.24,
                  },
                  gross_arr: {
                    without_refunds: 1541.27,
                    with_refunds: 1204.84,
                  },
                  net_arr_spread: {
                    without_refunds: 80.55,
                    with_refunds: 80.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 121.98,
                    with_refunds: 121.98,
                  },
                  net_total: {
                    without_refunds: 156.79,
                    with_refunds: 152.4,
                  },
                  gross_total: {
                    without_refunds: 181.69,
                    with_refunds: 176.3,
                  },
                  net_total_spread: {
                    without_refunds: 133.58,
                    with_refunds: 131.03,
                  },
                  gross_total_spread: {
                    without_refunds: 158.24,
                    with_refunds: 155.36,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1188,
                    with_refunds: 1.0938,
                  },
                  gross_mrr: {
                    without_refunds: 1.3017,
                    with_refunds: 1.273,
                  },
                  net_arr: {
                    without_refunds: 1.7479,
                    with_refunds: 1.589,
                  },
                  gross_arr: {
                    without_refunds: 2.6231,
                    with_refunds: 2.3797,
                  },
                  net_arr_spread: {
                    without_refunds: 1.587,
                    with_refunds: 1.587,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.4114,
                    with_refunds: 2.4114,
                  },
                  net_total: {
                    without_refunds: 1.1757,
                    with_refunds: 1.1406,
                  },
                  gross_total: {
                    without_refunds: 1.4113,
                    with_refunds: 1.3665,
                  },
                  net_total_spread: {
                    without_refunds: 1.16,
                    with_refunds: 1.1364,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3879,
                    with_refunds: 1.3609,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.3338,
                    with_refunds: 3.2619,
                  },
                  gross_mrr: {
                    without_refunds: 3.7898,
                    with_refunds: 3.709,
                  },
                  net_arr: {
                    without_refunds: 26.8785,
                    with_refunds: 21.1659,
                  },
                  gross_arr: {
                    without_refunds: 35.0688,
                    with_refunds: 27.4139,
                  },
                  net_arr_spread: {
                    without_refunds: 1.8327,
                    with_refunds: 1.8327,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.7754,
                    with_refunds: 2.7754,
                  },
                  net_total: {
                    without_refunds: 3.5674,
                    with_refunds: 3.4676,
                  },
                  gross_total: {
                    without_refunds: 4.134,
                    with_refunds: 4.0113,
                  },
                  net_total_spread: {
                    without_refunds: 3.0394,
                    with_refunds: 2.9814,
                  },
                  gross_total_spread: {
                    without_refunds: 3.6005,
                    with_refunds: 3.5349,
                  },
                },
              },
            },
          },
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 2052,
              with_refunds: 2052,
            },
            returning_users: {},
            paying_users: {
              without_refunds: 2052,
              with_refunds: 2052,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 172,
              with_refunds: 172,
            },
            returning_users: {},
            paying_users: {
              without_refunds: 172,
              with_refunds: 172,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2224,
              with_refunds: 2224,
            },
            returning_users: {},
            paying_users: {
              without_refunds: 2224,
              with_refunds: 2224,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 164529.34,
                with_refunds: 160851.84,
              },
              gross_mrr: {
                without_refunds: 191423.32,
                with_refunds: 187201.91,
              },
              net_arr: {
                without_refunds: 20204.15,
                with_refunds: 18367.1,
              },
              gross_arr: {
                without_refunds: 30319.95,
                with_refunds: 27506.78,
              },
              net_arr_spread: {
                without_refunds: 16809.15,
                with_refunds: 16809.15,
              },
              gross_arr_spread: {
                without_refunds: 25541.07,
                with_refunds: 25541.07,
              },
              net_total: {
                without_refunds: 184733.49,
                with_refunds: 179218.93,
              },
              gross_total: {
                without_refunds: 221743.27,
                with_refunds: 214708.69,
              },
              net_total_spread: {
                without_refunds: 181338.48,
                with_refunds: 177660.98,
              },
              gross_total_spread: {
                without_refunds: 216964.38,
                with_refunds: 212742.98,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 164529.34,
                with_refunds: 160851.84,
              },
              gross_mrr: {
                without_refunds: 191423.32,
                with_refunds: 187201.91,
              },
              net_arr: {
                without_refunds: 20204.15,
                with_refunds: 18367.1,
              },
              gross_arr: {
                without_refunds: 30319.95,
                with_refunds: 27506.78,
              },
              net_arr_spread: {
                without_refunds: 16809.15,
                with_refunds: 16809.15,
              },
              gross_arr_spread: {
                without_refunds: 25541.07,
                with_refunds: 25541.07,
              },
              net_total: {
                without_refunds: 184733.49,
                with_refunds: 179218.93,
              },
              gross_total: {
                without_refunds: 221743.27,
                with_refunds: 214708.69,
              },
              net_total_spread: {
                without_refunds: 181338.48,
                with_refunds: 177660.98,
              },
              gross_total_spread: {
                without_refunds: 216964.38,
                with_refunds: 212742.98,
              },
            },
          },
        },
      },
      cac: 43.95,
      mrr_ua_spend: {
        without_refunds: 147056.7,
        with_refunds: 147056.7,
      },
      arr_ua_spend: {
        without_refunds: 11558.85,
        with_refunds: 11558.85,
      },
      arr_spread_ua_spend: {
        without_refunds: 10591.95,
        with_refunds: 10591.95,
      },
      total_ua_spend: {
        without_refunds: 157121.25,
        with_refunds: 157121.25,
      },
      total_spread_ua_spend: {
        without_refunds: 156330.15,
        with_refunds: 156330.15,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 8,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 3121,
        with_refunds: 3121,
      },
      first_period_arr_users: {
        without_refunds: 266,
        with_refunds: 266,
      },
      first_period_arr_spread_users: {
        without_refunds: 239,
        with_refunds: 239,
      },
      first_period_total_users: {
        without_refunds: 3349,
        with_refunds: 3349,
      },
      first_period_total_spread_users: {
        without_refunds: 3331,
        with_refunds: 3331,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2187,
                  with_refunds: 2187,
                },
                arr_users: {
                  without_refunds: 182,
                  with_refunds: 182,
                },
                arr_spread_users: {
                  without_refunds: 170,
                  with_refunds: 170,
                },
                total_users: {
                  without_refunds: 2369,
                  with_refunds: 2369,
                },
                total_spread_users: {
                  without_refunds: 2357,
                  with_refunds: 2357,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7007,
                  with_refunds: 0.7007,
                },
                arr_users: {
                  without_refunds: 0.6842,
                  with_refunds: 0.6842,
                },
                arr_spread_users: {
                  without_refunds: 0.7113,
                  with_refunds: 0.7113,
                },
                total_users: {
                  without_refunds: 0.7074,
                  with_refunds: 0.7074,
                },
                total_spread_users: {
                  without_refunds: 0.7076,
                  with_refunds: 0.7076,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 21861.27,
                  with_refunds: 21385.94,
                },
                gross_mrr: {
                  without_refunds: 29108.96,
                  with_refunds: 28450.49,
                },
                net_arr: {
                  without_refunds: 12627.61,
                  with_refunds: 11851.17,
                },
                gross_arr: {
                  without_refunds: 19321.18,
                  with_refunds: 18104.41,
                },
                net_arr_spread: {
                  without_refunds: 981.91,
                  with_refunds: 981.91,
                },
                gross_arr_spread: {
                  without_refunds: 1500.6,
                  with_refunds: 1500.6,
                },
                net_total: {
                  without_refunds: 34488.89,
                  with_refunds: 33237.11,
                },
                gross_total: {
                  without_refunds: 48430.14,
                  with_refunds: 46554.91,
                },
                net_total_spread: {
                  without_refunds: 22843.19,
                  with_refunds: 22367.85,
                },
                gross_total_spread: {
                  without_refunds: 30609.55,
                  with_refunds: 29951.09,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7,
                  with_refunds: 6.85,
                },
                gross_mrr: {
                  without_refunds: 9.33,
                  with_refunds: 9.12,
                },
                net_arr: {
                  without_refunds: 47.47,
                  with_refunds: 44.55,
                },
                gross_arr: {
                  without_refunds: 72.64,
                  with_refunds: 68.06,
                },
                net_arr_spread: {
                  without_refunds: 4.11,
                  with_refunds: 4.11,
                },
                gross_arr_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.28,
                },
                net_total: {
                  without_refunds: 10.3,
                  with_refunds: 9.92,
                },
                gross_total: {
                  without_refunds: 14.46,
                  with_refunds: 13.9,
                },
                net_total_spread: {
                  without_refunds: 6.86,
                  with_refunds: 6.72,
                },
                gross_total_spread: {
                  without_refunds: 9.19,
                  with_refunds: 8.99,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10,
                  with_refunds: 9.78,
                },
                gross_mrr: {
                  without_refunds: 13.31,
                  with_refunds: 13.01,
                },
                net_arr: {
                  without_refunds: 69.38,
                  with_refunds: 65.12,
                },
                gross_arr: {
                  without_refunds: 106.16,
                  with_refunds: 99.47,
                },
                net_arr_spread: {
                  without_refunds: 5.78,
                  with_refunds: 5.78,
                },
                gross_arr_spread: {
                  without_refunds: 8.83,
                  with_refunds: 8.83,
                },
                net_total: {
                  without_refunds: 14.56,
                  with_refunds: 14.03,
                },
                gross_total: {
                  without_refunds: 20.44,
                  with_refunds: 19.65,
                },
                net_total_spread: {
                  without_refunds: 9.69,
                  with_refunds: 9.49,
                },
                gross_total_spread: {
                  without_refunds: 12.99,
                  with_refunds: 12.71,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 7,
                    with_refunds: 6.85,
                  },
                  gross_mrr: {
                    without_refunds: 9.33,
                    with_refunds: 9.12,
                  },
                  net_arr: {
                    without_refunds: 47.47,
                    with_refunds: 44.55,
                  },
                  gross_arr: {
                    without_refunds: 72.64,
                    with_refunds: 68.06,
                  },
                  net_arr_spread: {
                    without_refunds: 4.11,
                    with_refunds: 4.11,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.28,
                    with_refunds: 6.28,
                  },
                  net_total: {
                    without_refunds: 10.3,
                    with_refunds: 9.92,
                  },
                  gross_total: {
                    without_refunds: 14.46,
                    with_refunds: 13.9,
                  },
                  net_total_spread: {
                    without_refunds: 6.86,
                    with_refunds: 6.72,
                  },
                  gross_total_spread: {
                    without_refunds: 9.19,
                    with_refunds: 8.99,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10,
                    with_refunds: 9.78,
                  },
                  gross_mrr: {
                    without_refunds: 13.31,
                    with_refunds: 13.01,
                  },
                  net_arr: {
                    without_refunds: 69.38,
                    with_refunds: 65.12,
                  },
                  gross_arr: {
                    without_refunds: 106.16,
                    with_refunds: 99.47,
                  },
                  net_arr_spread: {
                    without_refunds: 5.78,
                    with_refunds: 5.78,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.83,
                    with_refunds: 8.83,
                  },
                  net_total: {
                    without_refunds: 14.56,
                    with_refunds: 14.03,
                  },
                  gross_total: {
                    without_refunds: 20.44,
                    with_refunds: 19.65,
                  },
                  net_total_spread: {
                    without_refunds: 9.69,
                    with_refunds: 9.49,
                  },
                  gross_total_spread: {
                    without_refunds: 12.99,
                    with_refunds: 12.71,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0689,
                    with_refunds: 0.0675,
                  },
                  gross_mrr: {
                    without_refunds: 0.0918,
                    with_refunds: 0.0897,
                  },
                  net_arr: {
                    without_refunds: 0.4673,
                    with_refunds: 0.4386,
                  },
                  gross_arr: {
                    without_refunds: 0.715,
                    with_refunds: 0.67,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0404,
                    with_refunds: 0.0404,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0618,
                    with_refunds: 0.0618,
                  },
                  net_total: {
                    without_refunds: 0.1014,
                    with_refunds: 0.0977,
                  },
                  gross_total: {
                    without_refunds: 0.1423,
                    with_refunds: 0.1368,
                  },
                  net_total_spread: {
                    without_refunds: 0.0675,
                    with_refunds: 0.0661,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0905,
                    with_refunds: 0.0885,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0984,
                    with_refunds: 0.0963,
                  },
                  gross_mrr: {
                    without_refunds: 0.131,
                    with_refunds: 0.1281,
                  },
                  net_arr: {
                    without_refunds: 0.683,
                    with_refunds: 0.641,
                  },
                  gross_arr: {
                    without_refunds: 1.045,
                    with_refunds: 0.9792,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0569,
                    with_refunds: 0.0569,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0869,
                    with_refunds: 0.0869,
                  },
                  net_total: {
                    without_refunds: 0.1433,
                    with_refunds: 0.1381,
                  },
                  gross_total: {
                    without_refunds: 0.2012,
                    with_refunds: 0.1934,
                  },
                  net_total_spread: {
                    without_refunds: 0.0954,
                    with_refunds: 0.0934,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1278,
                    with_refunds: 0.1251,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2295,
                  with_refunds: 2303,
                },
                arr_users: {
                  without_refunds: 37,
                  with_refunds: 38,
                },
                arr_spread_users: {
                  without_refunds: 202,
                  with_refunds: 202,
                },
                total_users: {
                  without_refunds: 2332,
                  with_refunds: 2341,
                },
                total_spread_users: {
                  without_refunds: 2497,
                  with_refunds: 2505,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7353,
                  with_refunds: 0.7379,
                },
                arr_users: {
                  without_refunds: 0.1391,
                  with_refunds: 0.1429,
                },
                arr_spread_users: {
                  without_refunds: 0.8452,
                  with_refunds: 0.8452,
                },
                total_users: {
                  without_refunds: 0.6963,
                  with_refunds: 0.699,
                },
                total_spread_users: {
                  without_refunds: 0.7496,
                  with_refunds: 0.752,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.0494,
                  with_refunds: 1.053,
                },
                arr_users: {
                  without_refunds: 0.2033,
                  with_refunds: 0.2088,
                },
                arr_spread_users: {
                  without_refunds: 1.1882,
                  with_refunds: 1.1882,
                },
                total_users: {
                  without_refunds: 0.9844,
                  with_refunds: 0.9882,
                },
                total_spread_users: {
                  without_refunds: 1.0594,
                  with_refunds: 1.0628,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 25010.6,
                  with_refunds: 24547.4,
                },
                gross_mrr: {
                  without_refunds: 29779.63,
                  with_refunds: 29217.15,
                },
                net_arr: {
                  without_refunds: 2576.34,
                  with_refunds: 2362.67,
                },
                gross_arr: {
                  without_refunds: 3828.22,
                  with_refunds: 3498.8,
                },
                net_arr_spread: {
                  without_refunds: 1165.42,
                  with_refunds: 1165.42,
                },
                gross_arr_spread: {
                  without_refunds: 1774.23,
                  with_refunds: 1774.23,
                },
                net_total: {
                  without_refunds: 27586.94,
                  with_refunds: 26910.07,
                },
                gross_total: {
                  without_refunds: 33607.85,
                  with_refunds: 32715.95,
                },
                net_total_spread: {
                  without_refunds: 26176.02,
                  with_refunds: 25712.82,
                },
                gross_total_spread: {
                  without_refunds: 31553.86,
                  with_refunds: 30991.38,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.1441,
                  with_refunds: 1.1478,
                },
                gross_mrr: {
                  without_refunds: 1.023,
                  with_refunds: 1.0269,
                },
                net_arr: {
                  without_refunds: 0.204,
                  with_refunds: 0.1994,
                },
                gross_arr: {
                  without_refunds: 0.1981,
                  with_refunds: 0.1933,
                },
                net_arr_spread: {
                  without_refunds: 1.1869,
                  with_refunds: 1.1869,
                },
                gross_arr_spread: {
                  without_refunds: 1.1823,
                  with_refunds: 1.1823,
                },
                net_total: {
                  without_refunds: 0.7999,
                  with_refunds: 0.8096,
                },
                gross_total: {
                  without_refunds: 0.6939,
                  with_refunds: 0.7027,
                },
                net_total_spread: {
                  without_refunds: 1.1459,
                  with_refunds: 1.1495,
                },
                gross_total_spread: {
                  without_refunds: 1.0309,
                  with_refunds: 1.0347,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.1441,
                  with_refunds: 1.1478,
                },
                gross_mrr: {
                  without_refunds: 1.023,
                  with_refunds: 1.0269,
                },
                net_arr: {
                  without_refunds: 0.204,
                  with_refunds: 0.1994,
                },
                gross_arr: {
                  without_refunds: 0.1981,
                  with_refunds: 0.1933,
                },
                net_arr_spread: {
                  without_refunds: 1.1869,
                  with_refunds: 1.1869,
                },
                gross_arr_spread: {
                  without_refunds: 1.1823,
                  with_refunds: 1.1823,
                },
                net_total: {
                  without_refunds: 0.7999,
                  with_refunds: 0.8096,
                },
                gross_total: {
                  without_refunds: 0.6939,
                  with_refunds: 0.7027,
                },
                net_total_spread: {
                  without_refunds: 1.1459,
                  with_refunds: 1.1495,
                },
                gross_total_spread: {
                  without_refunds: 1.0309,
                  with_refunds: 1.0347,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.01,
                  with_refunds: 7.87,
                },
                gross_mrr: {
                  without_refunds: 9.54,
                  with_refunds: 9.36,
                },
                net_arr: {
                  without_refunds: 9.69,
                  with_refunds: 8.88,
                },
                gross_arr: {
                  without_refunds: 14.39,
                  with_refunds: 13.15,
                },
                net_arr_spread: {
                  without_refunds: 4.88,
                  with_refunds: 4.88,
                },
                gross_arr_spread: {
                  without_refunds: 7.42,
                  with_refunds: 7.42,
                },
                net_total: {
                  without_refunds: 8.24,
                  with_refunds: 8.04,
                },
                gross_total: {
                  without_refunds: 10.04,
                  with_refunds: 9.77,
                },
                net_total_spread: {
                  without_refunds: 7.86,
                  with_refunds: 7.72,
                },
                gross_total_spread: {
                  without_refunds: 9.47,
                  with_refunds: 9.3,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.9,
                  with_refunds: 10.66,
                },
                gross_mrr: {
                  without_refunds: 12.98,
                  with_refunds: 12.69,
                },
                net_arr: {
                  without_refunds: 69.63,
                  with_refunds: 62.18,
                },
                gross_arr: {
                  without_refunds: 103.47,
                  with_refunds: 92.07,
                },
                net_arr_spread: {
                  without_refunds: 5.77,
                  with_refunds: 5.77,
                },
                gross_arr_spread: {
                  without_refunds: 8.78,
                  with_refunds: 8.78,
                },
                net_total: {
                  without_refunds: 11.83,
                  with_refunds: 11.5,
                },
                gross_total: {
                  without_refunds: 14.41,
                  with_refunds: 13.98,
                },
                net_total_spread: {
                  without_refunds: 10.48,
                  with_refunds: 10.26,
                },
                gross_total_spread: {
                  without_refunds: 12.64,
                  with_refunds: 12.37,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.02,
                    with_refunds: 14.72,
                  },
                  gross_mrr: {
                    without_refunds: 18.87,
                    with_refunds: 18.48,
                  },
                  net_arr: {
                    without_refunds: 57.16,
                    with_refunds: 53.44,
                  },
                  gross_arr: {
                    without_refunds: 87.03,
                    with_refunds: 81.22,
                  },
                  net_arr_spread: {
                    without_refunds: 8.98,
                    with_refunds: 8.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.7,
                    with_refunds: 13.7,
                  },
                  net_total: {
                    without_refunds: 18.54,
                    with_refunds: 17.96,
                  },
                  gross_total: {
                    without_refunds: 24.5,
                    with_refunds: 23.67,
                  },
                  net_total_spread: {
                    without_refunds: 14.72,
                    with_refunds: 14.43,
                  },
                  gross_total_spread: {
                    without_refunds: 18.66,
                    with_refunds: 18.3,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.89,
                    with_refunds: 20.44,
                  },
                  gross_mrr: {
                    without_refunds: 26.29,
                    with_refunds: 25.7,
                  },
                  net_arr: {
                    without_refunds: 139.01,
                    with_refunds: 127.29,
                  },
                  gross_arr: {
                    without_refunds: 209.63,
                    with_refunds: 191.55,
                  },
                  net_arr_spread: {
                    without_refunds: 11.55,
                    with_refunds: 11.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.61,
                    with_refunds: 17.61,
                  },
                  net_total: {
                    without_refunds: 26.39,
                    with_refunds: 25.53,
                  },
                  gross_total: {
                    without_refunds: 34.85,
                    with_refunds: 33.63,
                  },
                  net_total_spread: {
                    without_refunds: 20.17,
                    with_refunds: 19.75,
                  },
                  gross_total_spread: {
                    without_refunds: 25.62,
                    with_refunds: 25.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1478,
                    with_refunds: 0.1449,
                  },
                  gross_mrr: {
                    without_refunds: 0.1857,
                    with_refunds: 0.1819,
                  },
                  net_arr: {
                    without_refunds: 0.5626,
                    with_refunds: 0.526,
                  },
                  gross_arr: {
                    without_refunds: 0.8567,
                    with_refunds: 0.7994,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0884,
                    with_refunds: 0.0884,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1349,
                    with_refunds: 0.1349,
                  },
                  net_total: {
                    without_refunds: 0.1825,
                    with_refunds: 0.1768,
                  },
                  gross_total: {
                    without_refunds: 0.2411,
                    with_refunds: 0.233,
                  },
                  net_total_spread: {
                    without_refunds: 0.1449,
                    with_refunds: 0.1421,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1837,
                    with_refunds: 0.1801,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2057,
                    with_refunds: 0.2012,
                  },
                  gross_mrr: {
                    without_refunds: 0.2587,
                    with_refunds: 0.2529,
                  },
                  net_arr: {
                    without_refunds: 1.3684,
                    with_refunds: 1.253,
                  },
                  gross_arr: {
                    without_refunds: 2.0634,
                    with_refunds: 1.8855,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1136,
                    with_refunds: 0.1136,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1733,
                    with_refunds: 0.1733,
                  },
                  net_total: {
                    without_refunds: 0.2598,
                    with_refunds: 0.2513,
                  },
                  gross_total: {
                    without_refunds: 0.3431,
                    with_refunds: 0.331,
                  },
                  net_total_spread: {
                    without_refunds: 0.1986,
                    with_refunds: 0.1945,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2522,
                    with_refunds: 0.2469,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1748,
                  with_refunds: 1755,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 10,
                },
                arr_spread_users: {
                  without_refunds: 210,
                  with_refunds: 210,
                },
                total_users: {
                  without_refunds: 1755,
                  with_refunds: 1764,
                },
                total_spread_users: {
                  without_refunds: 1957,
                  with_refunds: 1964,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5601,
                  with_refunds: 0.5623,
                },
                arr_users: {
                  without_refunds: 0.0301,
                  with_refunds: 0.0376,
                },
                arr_spread_users: {
                  without_refunds: 0.8787,
                  with_refunds: 0.8787,
                },
                total_users: {
                  without_refunds: 0.524,
                  with_refunds: 0.5267,
                },
                total_spread_users: {
                  without_refunds: 0.5875,
                  with_refunds: 0.5896,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7617,
                  with_refunds: 0.762,
                },
                arr_users: {
                  without_refunds: 0.2162,
                  with_refunds: 0.2632,
                },
                arr_spread_users: {
                  without_refunds: 1.0396,
                  with_refunds: 1.0396,
                },
                total_users: {
                  without_refunds: 0.7526,
                  with_refunds: 0.7535,
                },
                total_spread_users: {
                  without_refunds: 0.7837,
                  with_refunds: 0.784,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 19655.6,
                  with_refunds: 19393.67,
                },
                gross_mrr: {
                  without_refunds: 22651.54,
                  with_refunds: 22343.75,
                },
                net_arr: {
                  without_refunds: 647.33,
                  with_refunds: 423.71,
                },
                gross_arr: {
                  without_refunds: 788.87,
                  with_refunds: 482.02,
                },
                net_arr_spread: {
                  without_refunds: 1219.37,
                  with_refunds: 1219.37,
                },
                gross_arr_spread: {
                  without_refunds: 1839.97,
                  with_refunds: 1839.97,
                },
                net_total: {
                  without_refunds: 20302.93,
                  with_refunds: 19817.38,
                },
                gross_total: {
                  without_refunds: 23440.42,
                  with_refunds: 22825.77,
                },
                net_total_spread: {
                  without_refunds: 20874.97,
                  with_refunds: 20613.03,
                },
                gross_total_spread: {
                  without_refunds: 24491.51,
                  with_refunds: 24183.72,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8991,
                  with_refunds: 0.9068,
                },
                gross_mrr: {
                  without_refunds: 0.7782,
                  with_refunds: 0.7854,
                },
                net_arr: {
                  without_refunds: 0.0513,
                  with_refunds: 0.0358,
                },
                gross_arr: {
                  without_refunds: 0.0408,
                  with_refunds: 0.0266,
                },
                net_arr_spread: {
                  without_refunds: 1.2418,
                  with_refunds: 1.2418,
                },
                gross_arr_spread: {
                  without_refunds: 1.2262,
                  with_refunds: 1.2262,
                },
                net_total: {
                  without_refunds: 0.5887,
                  with_refunds: 0.5962,
                },
                gross_total: {
                  without_refunds: 0.484,
                  with_refunds: 0.4903,
                },
                net_total_spread: {
                  without_refunds: 0.9138,
                  with_refunds: 0.9215,
                },
                gross_total_spread: {
                  without_refunds: 0.8001,
                  with_refunds: 0.8074,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7859,
                  with_refunds: 0.79,
                },
                gross_mrr: {
                  without_refunds: 0.7606,
                  with_refunds: 0.7647,
                },
                net_arr: {
                  without_refunds: 0.2513,
                  with_refunds: 0.1793,
                },
                gross_arr: {
                  without_refunds: 0.2061,
                  with_refunds: 0.1378,
                },
                net_arr_spread: {
                  without_refunds: 1.0463,
                  with_refunds: 1.0463,
                },
                gross_arr_spread: {
                  without_refunds: 1.0371,
                  with_refunds: 1.0371,
                },
                net_total: {
                  without_refunds: 0.736,
                  with_refunds: 0.7364,
                },
                gross_total: {
                  without_refunds: 0.6975,
                  with_refunds: 0.6977,
                },
                net_total_spread: {
                  without_refunds: 0.7975,
                  with_refunds: 0.8017,
                },
                gross_total_spread: {
                  without_refunds: 0.7762,
                  with_refunds: 0.7803,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.3,
                  with_refunds: 6.21,
                },
                gross_mrr: {
                  without_refunds: 7.26,
                  with_refunds: 7.16,
                },
                net_arr: {
                  without_refunds: 2.43,
                  with_refunds: 1.59,
                },
                gross_arr: {
                  without_refunds: 2.97,
                  with_refunds: 1.81,
                },
                net_arr_spread: {
                  without_refunds: 5.1,
                  with_refunds: 5.1,
                },
                gross_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                net_total: {
                  without_refunds: 6.06,
                  with_refunds: 5.92,
                },
                gross_total: {
                  without_refunds: 7,
                  with_refunds: 6.82,
                },
                net_total_spread: {
                  without_refunds: 6.27,
                  with_refunds: 6.19,
                },
                gross_total_spread: {
                  without_refunds: 7.35,
                  with_refunds: 7.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.24,
                  with_refunds: 11.05,
                },
                gross_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.73,
                },
                net_arr: {
                  without_refunds: 80.92,
                  with_refunds: 42.37,
                },
                gross_arr: {
                  without_refunds: 98.61,
                  with_refunds: 48.2,
                },
                net_arr_spread: {
                  without_refunds: 5.81,
                  with_refunds: 5.81,
                },
                gross_arr_spread: {
                  without_refunds: 8.76,
                  with_refunds: 8.76,
                },
                net_total: {
                  without_refunds: 11.57,
                  with_refunds: 11.23,
                },
                gross_total: {
                  without_refunds: 13.36,
                  with_refunds: 12.94,
                },
                net_total_spread: {
                  without_refunds: 10.67,
                  with_refunds: 10.5,
                },
                gross_total_spread: {
                  without_refunds: 12.51,
                  with_refunds: 12.31,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.32,
                    with_refunds: 20.93,
                  },
                  gross_mrr: {
                    without_refunds: 26.13,
                    with_refunds: 25.64,
                  },
                  net_arr: {
                    without_refunds: 59.59,
                    with_refunds: 55.03,
                  },
                  gross_arr: {
                    without_refunds: 89.99,
                    with_refunds: 83.03,
                  },
                  net_arr_spread: {
                    without_refunds: 14.09,
                    with_refunds: 14.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 21.4,
                    with_refunds: 21.4,
                  },
                  net_total: {
                    without_refunds: 24.6,
                    with_refunds: 23.88,
                  },
                  gross_total: {
                    without_refunds: 31.5,
                    with_refunds: 30.49,
                  },
                  net_total_spread: {
                    without_refunds: 20.98,
                    with_refunds: 20.62,
                  },
                  gross_total_spread: {
                    without_refunds: 26.01,
                    with_refunds: 25.56,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.14,
                    with_refunds: 31.49,
                  },
                  gross_mrr: {
                    without_refunds: 39.24,
                    with_refunds: 38.43,
                  },
                  net_arr: {
                    without_refunds: 219.93,
                    with_refunds: 169.66,
                  },
                  gross_arr: {
                    without_refunds: 308.23,
                    with_refunds: 239.75,
                  },
                  net_arr_spread: {
                    without_refunds: 17.35,
                    with_refunds: 17.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 26.37,
                    with_refunds: 26.37,
                  },
                  net_total: {
                    without_refunds: 37.96,
                    with_refunds: 36.76,
                  },
                  gross_total: {
                    without_refunds: 48.21,
                    with_refunds: 46.57,
                  },
                  net_total_spread: {
                    without_refunds: 30.84,
                    with_refunds: 30.25,
                  },
                  gross_total_spread: {
                    without_refunds: 38.14,
                    with_refunds: 37.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2098,
                    with_refunds: 0.206,
                  },
                  gross_mrr: {
                    without_refunds: 0.2572,
                    with_refunds: 0.2524,
                  },
                  net_arr: {
                    without_refunds: 0.5866,
                    with_refunds: 0.5417,
                  },
                  gross_arr: {
                    without_refunds: 0.8859,
                    with_refunds: 0.8173,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1387,
                    with_refunds: 0.1387,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2107,
                    with_refunds: 0.2107,
                  },
                  net_total: {
                    without_refunds: 0.2421,
                    with_refunds: 0.235,
                  },
                  gross_total: {
                    without_refunds: 0.31,
                    with_refunds: 0.3001,
                  },
                  net_total_spread: {
                    without_refunds: 0.2065,
                    with_refunds: 0.203,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2561,
                    with_refunds: 0.2516,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3164,
                    with_refunds: 0.31,
                  },
                  gross_mrr: {
                    without_refunds: 0.3863,
                    with_refunds: 0.3783,
                  },
                  net_arr: {
                    without_refunds: 2.1649,
                    with_refunds: 1.6701,
                  },
                  gross_arr: {
                    without_refunds: 3.0341,
                    with_refunds: 2.36,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1708,
                    with_refunds: 0.1708,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2596,
                    with_refunds: 0.2596,
                  },
                  net_total: {
                    without_refunds: 0.3736,
                    with_refunds: 0.3618,
                  },
                  gross_total: {
                    without_refunds: 0.4746,
                    with_refunds: 0.4584,
                  },
                  net_total_spread: {
                    without_refunds: 0.3036,
                    with_refunds: 0.2978,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3754,
                    with_refunds: 0.3681,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1454,
                  with_refunds: 1458,
                },
                arr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                total_users: {
                  without_refunds: 1458,
                  with_refunds: 1462,
                },
                total_spread_users: {
                  without_refunds: 1666,
                  with_refunds: 1670,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4659,
                  with_refunds: 0.4672,
                },
                arr_users: {
                  without_refunds: 0.015,
                  with_refunds: 0.015,
                },
                arr_spread_users: {
                  without_refunds: 0.887,
                  with_refunds: 0.887,
                },
                total_users: {
                  without_refunds: 0.4354,
                  with_refunds: 0.4365,
                },
                total_spread_users: {
                  without_refunds: 0.5002,
                  with_refunds: 0.5014,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8318,
                  with_refunds: 0.8308,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.4,
                },
                arr_spread_users: {
                  without_refunds: 1.0095,
                  with_refunds: 1.0095,
                },
                total_users: {
                  without_refunds: 0.8308,
                  with_refunds: 0.8288,
                },
                total_spread_users: {
                  without_refunds: 0.8513,
                  with_refunds: 0.8503,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 16320.16,
                  with_refunds: 16059.53,
                },
                gross_mrr: {
                  without_refunds: 18764.56,
                  with_refunds: 18475.78,
                },
                net_arr: {
                  without_refunds: 367.3,
                  with_refunds: 195.58,
                },
                gross_arr: {
                  without_refunds: 481.59,
                  with_refunds: 257.35,
                },
                net_arr_spread: {
                  without_refunds: 1235.66,
                  with_refunds: 1235.66,
                },
                gross_arr_spread: {
                  without_refunds: 1861.41,
                  with_refunds: 1861.41,
                },
                net_total: {
                  without_refunds: 16687.46,
                  with_refunds: 16255.1,
                },
                gross_total: {
                  without_refunds: 19246.16,
                  with_refunds: 18733.13,
                },
                net_total_spread: {
                  without_refunds: 17555.82,
                  with_refunds: 17295.19,
                },
                gross_total_spread: {
                  without_refunds: 20625.98,
                  with_refunds: 20337.2,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7465,
                  with_refunds: 0.7509,
                },
                gross_mrr: {
                  without_refunds: 0.6446,
                  with_refunds: 0.6494,
                },
                net_arr: {
                  without_refunds: 0.0291,
                  with_refunds: 0.0165,
                },
                gross_arr: {
                  without_refunds: 0.0249,
                  with_refunds: 0.0142,
                },
                net_arr_spread: {
                  without_refunds: 1.2584,
                  with_refunds: 1.2584,
                },
                gross_arr_spread: {
                  without_refunds: 1.2404,
                  with_refunds: 1.2404,
                },
                net_total: {
                  without_refunds: 0.4839,
                  with_refunds: 0.4891,
                },
                gross_total: {
                  without_refunds: 0.3974,
                  with_refunds: 0.4024,
                },
                net_total_spread: {
                  without_refunds: 0.7685,
                  with_refunds: 0.7732,
                },
                gross_total_spread: {
                  without_refunds: 0.6738,
                  with_refunds: 0.679,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8303,
                  with_refunds: 0.8281,
                },
                gross_mrr: {
                  without_refunds: 0.8284,
                  with_refunds: 0.8269,
                },
                net_arr: {
                  without_refunds: 0.5674,
                  with_refunds: 0.4616,
                },
                gross_arr: {
                  without_refunds: 0.6105,
                  with_refunds: 0.5339,
                },
                net_arr_spread: {
                  without_refunds: 1.0134,
                  with_refunds: 1.0134,
                },
                gross_arr_spread: {
                  without_refunds: 1.0117,
                  with_refunds: 1.0117,
                },
                net_total: {
                  without_refunds: 0.8219,
                  with_refunds: 0.8202,
                },
                gross_total: {
                  without_refunds: 0.8211,
                  with_refunds: 0.8207,
                },
                net_total_spread: {
                  without_refunds: 0.841,
                  with_refunds: 0.839,
                },
                gross_total_spread: {
                  without_refunds: 0.8422,
                  with_refunds: 0.8409,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.23,
                  with_refunds: 5.15,
                },
                gross_mrr: {
                  without_refunds: 6.01,
                  with_refunds: 5.92,
                },
                net_arr: {
                  without_refunds: 1.38,
                  with_refunds: 0.74,
                },
                gross_arr: {
                  without_refunds: 1.81,
                  with_refunds: 0.97,
                },
                net_arr_spread: {
                  without_refunds: 5.17,
                  with_refunds: 5.17,
                },
                gross_arr_spread: {
                  without_refunds: 7.79,
                  with_refunds: 7.79,
                },
                net_total: {
                  without_refunds: 4.98,
                  with_refunds: 4.85,
                },
                gross_total: {
                  without_refunds: 5.75,
                  with_refunds: 5.59,
                },
                net_total_spread: {
                  without_refunds: 5.27,
                  with_refunds: 5.19,
                },
                gross_total_spread: {
                  without_refunds: 6.19,
                  with_refunds: 6.11,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.22,
                  with_refunds: 11.01,
                },
                gross_mrr: {
                  without_refunds: 12.91,
                  with_refunds: 12.67,
                },
                net_arr: {
                  without_refunds: 91.82,
                  with_refunds: 48.89,
                },
                gross_arr: {
                  without_refunds: 120.4,
                  with_refunds: 64.34,
                },
                net_arr_spread: {
                  without_refunds: 5.83,
                  with_refunds: 5.83,
                },
                gross_arr_spread: {
                  without_refunds: 8.78,
                  with_refunds: 8.78,
                },
                net_total: {
                  without_refunds: 11.45,
                  with_refunds: 11.12,
                },
                gross_total: {
                  without_refunds: 13.2,
                  with_refunds: 12.81,
                },
                net_total_spread: {
                  without_refunds: 10.54,
                  with_refunds: 10.36,
                },
                gross_total_spread: {
                  without_refunds: 12.38,
                  with_refunds: 12.18,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 26.55,
                    with_refunds: 26.08,
                  },
                  gross_mrr: {
                    without_refunds: 32.14,
                    with_refunds: 31.56,
                  },
                  net_arr: {
                    without_refunds: 60.97,
                    with_refunds: 55.76,
                  },
                  gross_arr: {
                    without_refunds: 91.8,
                    with_refunds: 83.99,
                  },
                  net_arr_spread: {
                    without_refunds: 19.26,
                    with_refunds: 19.26,
                  },
                  gross_arr_spread: {
                    without_refunds: 29.19,
                    with_refunds: 29.19,
                  },
                  net_total: {
                    without_refunds: 29.58,
                    with_refunds: 28.73,
                  },
                  gross_total: {
                    without_refunds: 37.24,
                    with_refunds: 36.08,
                  },
                  net_total_spread: {
                    without_refunds: 26.25,
                    with_refunds: 25.81,
                  },
                  gross_total_spread: {
                    without_refunds: 32.21,
                    with_refunds: 31.66,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 43.36,
                    with_refunds: 42.5,
                  },
                  gross_mrr: {
                    without_refunds: 52.15,
                    with_refunds: 51.1,
                  },
                  net_arr: {
                    without_refunds: 311.75,
                    with_refunds: 218.56,
                  },
                  gross_arr: {
                    without_refunds: 428.63,
                    with_refunds: 304.09,
                  },
                  net_arr_spread: {
                    without_refunds: 23.18,
                    with_refunds: 23.18,
                  },
                  gross_arr_spread: {
                    without_refunds: 35.15,
                    with_refunds: 35.15,
                  },
                  net_total: {
                    without_refunds: 49.4,
                    with_refunds: 47.88,
                  },
                  gross_total: {
                    without_refunds: 61.41,
                    with_refunds: 59.38,
                  },
                  net_total_spread: {
                    without_refunds: 41.38,
                    with_refunds: 40.61,
                  },
                  gross_total_spread: {
                    without_refunds: 50.52,
                    with_refunds: 49.57,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2613,
                    with_refunds: 0.2567,
                  },
                  gross_mrr: {
                    without_refunds: 0.3164,
                    with_refunds: 0.3106,
                  },
                  net_arr: {
                    without_refunds: 0.6002,
                    with_refunds: 0.5489,
                  },
                  gross_arr: {
                    without_refunds: 0.9037,
                    with_refunds: 0.8268,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1896,
                    with_refunds: 0.1896,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2873,
                    with_refunds: 0.2873,
                  },
                  net_total: {
                    without_refunds: 0.2912,
                    with_refunds: 0.2828,
                  },
                  gross_total: {
                    without_refunds: 0.3666,
                    with_refunds: 0.3551,
                  },
                  net_total_spread: {
                    without_refunds: 0.2584,
                    with_refunds: 0.2541,
                  },
                  gross_total_spread: {
                    without_refunds: 0.317,
                    with_refunds: 0.3117,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4268,
                    with_refunds: 0.4184,
                  },
                  gross_mrr: {
                    without_refunds: 0.5133,
                    with_refunds: 0.503,
                  },
                  net_arr: {
                    without_refunds: 3.0687,
                    with_refunds: 2.1514,
                  },
                  gross_arr: {
                    without_refunds: 4.2192,
                    with_refunds: 2.9933,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2282,
                    with_refunds: 0.2282,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.346,
                    with_refunds: 0.346,
                  },
                  net_total: {
                    without_refunds: 0.4863,
                    with_refunds: 0.4713,
                  },
                  gross_total: {
                    without_refunds: 0.6045,
                    with_refunds: 0.5845,
                  },
                  net_total_spread: {
                    without_refunds: 0.4073,
                    with_refunds: 0.3997,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4973,
                    with_refunds: 0.4879,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1221,
                  with_refunds: 1225,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                total_users: {
                  without_refunds: 1221,
                  with_refunds: 1225,
                },
                total_spread_users: {
                  without_refunds: 1433,
                  with_refunds: 1437,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3912,
                  with_refunds: 0.3925,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.887,
                  with_refunds: 0.887,
                },
                total_users: {
                  without_refunds: 0.3646,
                  with_refunds: 0.3658,
                },
                total_spread_users: {
                  without_refunds: 0.4302,
                  with_refunds: 0.4314,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8398,
                  with_refunds: 0.8402,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8374,
                  with_refunds: 0.8379,
                },
                total_spread_users: {
                  without_refunds: 0.8601,
                  with_refunds: 0.8605,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13808.58,
                  with_refunds: 13673.06,
                },
                gross_mrr: {
                  without_refunds: 15808.71,
                  with_refunds: 15659.19,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1235.66,
                  with_refunds: 1235.66,
                },
                gross_arr_spread: {
                  without_refunds: 1861.41,
                  with_refunds: 1861.41,
                },
                net_total: {
                  without_refunds: 13808.58,
                  with_refunds: 13673.06,
                },
                gross_total: {
                  without_refunds: 15808.71,
                  with_refunds: 15659.19,
                },
                net_total_spread: {
                  without_refunds: 15044.25,
                  with_refunds: 14908.73,
                },
                gross_total_spread: {
                  without_refunds: 17670.12,
                  with_refunds: 17520.6,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6393,
                },
                gross_mrr: {
                  without_refunds: 0.5431,
                  with_refunds: 0.5504,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2584,
                  with_refunds: 1.2584,
                },
                gross_arr_spread: {
                  without_refunds: 1.2404,
                  with_refunds: 1.2404,
                },
                net_total: {
                  without_refunds: 0.4004,
                  with_refunds: 0.4114,
                },
                gross_total: {
                  without_refunds: 0.3264,
                  with_refunds: 0.3364,
                },
                net_total_spread: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6665,
                },
                gross_total_spread: {
                  without_refunds: 0.5773,
                  with_refunds: 0.585,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8461,
                  with_refunds: 0.8514,
                },
                gross_mrr: {
                  without_refunds: 0.8425,
                  with_refunds: 0.8476,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8275,
                  with_refunds: 0.8412,
                },
                gross_total: {
                  without_refunds: 0.8214,
                  with_refunds: 0.8359,
                },
                net_total_spread: {
                  without_refunds: 0.8569,
                  with_refunds: 0.862,
                },
                gross_total_spread: {
                  without_refunds: 0.8567,
                  with_refunds: 0.8615,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.42,
                  with_refunds: 4.38,
                },
                gross_mrr: {
                  without_refunds: 5.07,
                  with_refunds: 5.02,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 5.17,
                  with_refunds: 5.17,
                },
                gross_arr_spread: {
                  without_refunds: 7.79,
                  with_refunds: 7.79,
                },
                net_total: {
                  without_refunds: 4.12,
                  with_refunds: 4.08,
                },
                gross_total: {
                  without_refunds: 4.72,
                  with_refunds: 4.68,
                },
                net_total_spread: {
                  without_refunds: 4.52,
                  with_refunds: 4.48,
                },
                gross_total_spread: {
                  without_refunds: 5.3,
                  with_refunds: 5.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.31,
                  with_refunds: 11.16,
                },
                gross_mrr: {
                  without_refunds: 12.95,
                  with_refunds: 12.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 5.83,
                  with_refunds: 5.83,
                },
                gross_arr_spread: {
                  without_refunds: 8.78,
                  with_refunds: 8.78,
                },
                net_total: {
                  without_refunds: 11.31,
                  with_refunds: 11.16,
                },
                gross_total: {
                  without_refunds: 12.95,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.5,
                  with_refunds: 10.37,
                },
                gross_total_spread: {
                  without_refunds: 12.33,
                  with_refunds: 12.19,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.97,
                    with_refunds: 30.46,
                  },
                  gross_mrr: {
                    without_refunds: 37.2,
                    with_refunds: 36.57,
                  },
                  net_arr: {
                    without_refunds: 60.97,
                    with_refunds: 55.76,
                  },
                  gross_arr: {
                    without_refunds: 91.8,
                    with_refunds: 83.99,
                  },
                  net_arr_spread: {
                    without_refunds: 24.43,
                    with_refunds: 24.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.98,
                    with_refunds: 36.98,
                  },
                  net_total: {
                    without_refunds: 33.7,
                    with_refunds: 32.81,
                  },
                  gross_total: {
                    without_refunds: 41.96,
                    with_refunds: 40.76,
                  },
                  net_total_spread: {
                    without_refunds: 30.77,
                    with_refunds: 30.29,
                  },
                  gross_total_spread: {
                    without_refunds: 37.51,
                    with_refunds: 36.92,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 54.67,
                    with_refunds: 53.66,
                  },
                  gross_mrr: {
                    without_refunds: 65.1,
                    with_refunds: 63.88,
                  },
                  net_arr: {
                    without_refunds: 311.75,
                    with_refunds: 218.56,
                  },
                  gross_arr: {
                    without_refunds: 428.63,
                    with_refunds: 304.09,
                  },
                  net_arr_spread: {
                    without_refunds: 29.01,
                    with_refunds: 29.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 43.93,
                    with_refunds: 43.93,
                  },
                  net_total: {
                    without_refunds: 60.71,
                    with_refunds: 59.04,
                  },
                  gross_total: {
                    without_refunds: 74.36,
                    with_refunds: 72.16,
                  },
                  net_total_spread: {
                    without_refunds: 51.88,
                    with_refunds: 50.98,
                  },
                  gross_total_spread: {
                    without_refunds: 62.85,
                    with_refunds: 61.76,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3048,
                    with_refunds: 0.2998,
                  },
                  gross_mrr: {
                    without_refunds: 0.3662,
                    with_refunds: 0.36,
                  },
                  net_arr: {
                    without_refunds: 0.6002,
                    with_refunds: 0.5489,
                  },
                  gross_arr: {
                    without_refunds: 0.9037,
                    with_refunds: 0.8268,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2404,
                    with_refunds: 0.2404,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.364,
                    with_refunds: 0.364,
                  },
                  net_total: {
                    without_refunds: 0.3318,
                    with_refunds: 0.323,
                  },
                  gross_total: {
                    without_refunds: 0.4131,
                    with_refunds: 0.4012,
                  },
                  net_total_spread: {
                    without_refunds: 0.3029,
                    with_refunds: 0.2982,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3692,
                    with_refunds: 0.3634,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5382,
                    with_refunds: 0.5282,
                  },
                  gross_mrr: {
                    without_refunds: 0.6408,
                    with_refunds: 0.6288,
                  },
                  net_arr: {
                    without_refunds: 3.0687,
                    with_refunds: 2.1514,
                  },
                  gross_arr: {
                    without_refunds: 4.2192,
                    with_refunds: 2.9933,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2856,
                    with_refunds: 0.2856,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4325,
                    with_refunds: 0.4325,
                  },
                  net_total: {
                    without_refunds: 0.5976,
                    with_refunds: 0.5812,
                  },
                  gross_total: {
                    without_refunds: 0.732,
                    with_refunds: 0.7103,
                  },
                  net_total_spread: {
                    without_refunds: 0.5107,
                    with_refunds: 0.5018,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6187,
                    with_refunds: 0.608,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 924,
                  with_refunds: 928,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 215,
                  with_refunds: 215,
                },
                total_users: {
                  without_refunds: 929,
                  with_refunds: 933,
                },
                total_spread_users: {
                  without_refunds: 1139,
                  with_refunds: 1143,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2961,
                  with_refunds: 0.2973,
                },
                arr_users: {
                  without_refunds: 0.0188,
                  with_refunds: 0.0188,
                },
                arr_spread_users: {
                  without_refunds: 0.8996,
                  with_refunds: 0.8996,
                },
                total_users: {
                  without_refunds: 0.2774,
                  with_refunds: 0.2786,
                },
                total_spread_users: {
                  without_refunds: 0.3419,
                  with_refunds: 0.3431,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7568,
                  with_refunds: 0.7576,
                },
                arr_users: {
                  without_refunds: 1.25,
                  with_refunds: 1.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0142,
                  with_refunds: 1.0142,
                },
                total_users: {
                  without_refunds: 0.7609,
                  with_refunds: 0.7616,
                },
                total_spread_users: {
                  without_refunds: 0.7948,
                  with_refunds: 0.7954,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10550.52,
                  with_refunds: 10219.62,
                },
                gross_mrr: {
                  without_refunds: 11859.09,
                  with_refunds: 11504.12,
                },
                net_arr: {
                  without_refunds: 567.79,
                  with_refunds: 356.97,
                },
                gross_arr: {
                  without_refunds: 668,
                  with_refunds: 419.97,
                },
                net_arr_spread: {
                  without_refunds: 1265.41,
                  with_refunds: 1265.41,
                },
                gross_arr_spread: {
                  without_refunds: 1896.41,
                  with_refunds: 1896.41,
                },
                net_total: {
                  without_refunds: 11118.31,
                  with_refunds: 10576.59,
                },
                gross_total: {
                  without_refunds: 12527.09,
                  with_refunds: 11924.09,
                },
                net_total_spread: {
                  without_refunds: 11815.93,
                  with_refunds: 11485.03,
                },
                gross_total_spread: {
                  without_refunds: 13755.5,
                  with_refunds: 13400.53,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4826,
                  with_refunds: 0.4779,
                },
                gross_mrr: {
                  without_refunds: 0.4074,
                  with_refunds: 0.4044,
                },
                net_arr: {
                  without_refunds: 0.045,
                  with_refunds: 0.0301,
                },
                gross_arr: {
                  without_refunds: 0.0346,
                  with_refunds: 0.0232,
                },
                net_arr_spread: {
                  without_refunds: 1.2887,
                  with_refunds: 1.2887,
                },
                gross_arr_spread: {
                  without_refunds: 1.2638,
                  with_refunds: 1.2638,
                },
                net_total: {
                  without_refunds: 0.3224,
                  with_refunds: 0.3182,
                },
                gross_total: {
                  without_refunds: 0.2587,
                  with_refunds: 0.2561,
                },
                net_total_spread: {
                  without_refunds: 0.5173,
                  with_refunds: 0.5135,
                },
                gross_total_spread: {
                  without_refunds: 0.4494,
                  with_refunds: 0.4474,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7641,
                  with_refunds: 0.7474,
                },
                gross_mrr: {
                  without_refunds: 0.7502,
                  with_refunds: 0.7347,
                },
                net_arr: {
                  without_refunds: 1.5459,
                  with_refunds: 1.8252,
                },
                gross_arr: {
                  without_refunds: 1.3871,
                  with_refunds: 1.6319,
                },
                net_arr_spread: {
                  without_refunds: 1.0241,
                  with_refunds: 1.0241,
                },
                gross_arr_spread: {
                  without_refunds: 1.0188,
                  with_refunds: 1.0188,
                },
                net_total: {
                  without_refunds: 0.8052,
                  with_refunds: 0.7735,
                },
                gross_total: {
                  without_refunds: 0.7924,
                  with_refunds: 0.7615,
                },
                net_total_spread: {
                  without_refunds: 0.7854,
                  with_refunds: 0.7704,
                },
                gross_total_spread: {
                  without_refunds: 0.7785,
                  with_refunds: 0.7648,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.38,
                  with_refunds: 3.27,
                },
                gross_mrr: {
                  without_refunds: 3.8,
                  with_refunds: 3.69,
                },
                net_arr: {
                  without_refunds: 2.13,
                  with_refunds: 1.34,
                },
                gross_arr: {
                  without_refunds: 2.51,
                  with_refunds: 1.58,
                },
                net_arr_spread: {
                  without_refunds: 5.29,
                  with_refunds: 5.29,
                },
                gross_arr_spread: {
                  without_refunds: 7.93,
                  with_refunds: 7.93,
                },
                net_total: {
                  without_refunds: 3.32,
                  with_refunds: 3.16,
                },
                gross_total: {
                  without_refunds: 3.74,
                  with_refunds: 3.56,
                },
                net_total_spread: {
                  without_refunds: 3.55,
                  with_refunds: 3.45,
                },
                gross_total_spread: {
                  without_refunds: 4.13,
                  with_refunds: 4.02,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.01,
                },
                gross_mrr: {
                  without_refunds: 12.83,
                  with_refunds: 12.4,
                },
                net_arr: {
                  without_refunds: 113.56,
                  with_refunds: 71.39,
                },
                gross_arr: {
                  without_refunds: 133.6,
                  with_refunds: 83.99,
                },
                net_arr_spread: {
                  without_refunds: 5.89,
                  with_refunds: 5.89,
                },
                gross_arr_spread: {
                  without_refunds: 8.82,
                  with_refunds: 8.82,
                },
                net_total: {
                  without_refunds: 11.97,
                  with_refunds: 11.34,
                },
                gross_total: {
                  without_refunds: 13.48,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 10.37,
                  with_refunds: 10.05,
                },
                gross_total_spread: {
                  without_refunds: 12.08,
                  with_refunds: 11.72,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.35,
                    with_refunds: 33.73,
                  },
                  gross_mrr: {
                    without_refunds: 41,
                    with_refunds: 40.26,
                  },
                  net_arr: {
                    without_refunds: 63.11,
                    with_refunds: 57.11,
                  },
                  gross_arr: {
                    without_refunds: 94.32,
                    with_refunds: 85.57,
                  },
                  net_arr_spread: {
                    without_refunds: 29.72,
                    with_refunds: 29.72,
                  },
                  gross_arr_spread: {
                    without_refunds: 44.91,
                    with_refunds: 44.91,
                  },
                  net_total: {
                    without_refunds: 37.02,
                    with_refunds: 35.97,
                  },
                  gross_total: {
                    without_refunds: 45.7,
                    with_refunds: 44.32,
                  },
                  net_total_spread: {
                    without_refunds: 34.32,
                    with_refunds: 33.74,
                  },
                  gross_total_spread: {
                    without_refunds: 41.64,
                    with_refunds: 40.94,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 66.09,
                    with_refunds: 64.68,
                  },
                  gross_mrr: {
                    without_refunds: 77.93,
                    with_refunds: 76.28,
                  },
                  net_arr: {
                    without_refunds: 425.31,
                    with_refunds: 289.95,
                  },
                  gross_arr: {
                    without_refunds: 562.23,
                    with_refunds: 388.08,
                  },
                  net_arr_spread: {
                    without_refunds: 34.89,
                    with_refunds: 34.89,
                  },
                  gross_arr_spread: {
                    without_refunds: 52.75,
                    with_refunds: 52.75,
                  },
                  net_total: {
                    without_refunds: 72.68,
                    with_refunds: 70.38,
                  },
                  gross_total: {
                    without_refunds: 87.84,
                    with_refunds: 84.94,
                  },
                  net_total_spread: {
                    without_refunds: 62.25,
                    with_refunds: 61.03,
                  },
                  gross_total_spread: {
                    without_refunds: 74.93,
                    with_refunds: 73.49,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3381,
                    with_refunds: 0.332,
                  },
                  gross_mrr: {
                    without_refunds: 0.4036,
                    with_refunds: 0.3963,
                  },
                  net_arr: {
                    without_refunds: 0.6212,
                    with_refunds: 0.5621,
                  },
                  gross_arr: {
                    without_refunds: 0.9284,
                    with_refunds: 0.8423,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2926,
                    with_refunds: 0.2926,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4421,
                    with_refunds: 0.4421,
                  },
                  net_total: {
                    without_refunds: 0.3644,
                    with_refunds: 0.3541,
                  },
                  gross_total: {
                    without_refunds: 0.4499,
                    with_refunds: 0.4362,
                  },
                  net_total_spread: {
                    without_refunds: 0.3378,
                    with_refunds: 0.3321,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4099,
                    with_refunds: 0.403,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6506,
                    with_refunds: 0.6366,
                  },
                  gross_mrr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.7508,
                  },
                  net_arr: {
                    without_refunds: 4.1866,
                    with_refunds: 2.8541,
                  },
                  gross_arr: {
                    without_refunds: 5.5343,
                    with_refunds: 3.8201,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3435,
                    with_refunds: 0.3435,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5193,
                    with_refunds: 0.5193,
                  },
                  net_total: {
                    without_refunds: 0.7154,
                    with_refunds: 0.6927,
                  },
                  gross_total: {
                    without_refunds: 0.8647,
                    with_refunds: 0.8361,
                  },
                  net_total_spread: {
                    without_refunds: 0.6128,
                    with_refunds: 0.6007,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7375,
                    with_refunds: 0.7234,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 806,
                  with_refunds: 811,
                },
                arr_users: {
                  without_refunds: 16,
                  with_refunds: 16,
                },
                arr_spread_users: {
                  without_refunds: 226,
                  with_refunds: 226,
                },
                total_users: {
                  without_refunds: 820,
                  with_refunds: 821,
                },
                total_spread_users: {
                  without_refunds: 1030,
                  with_refunds: 1031,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2583,
                  with_refunds: 0.2599,
                },
                arr_users: {
                  without_refunds: 0.0602,
                  with_refunds: 0.0602,
                },
                arr_spread_users: {
                  without_refunds: 0.9456,
                  with_refunds: 0.9456,
                },
                total_users: {
                  without_refunds: 0.2448,
                  with_refunds: 0.2451,
                },
                total_spread_users: {
                  without_refunds: 0.3092,
                  with_refunds: 0.3095,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8723,
                  with_refunds: 0.8739,
                },
                arr_users: {
                  without_refunds: 3.2,
                  with_refunds: 3.2,
                },
                arr_spread_users: {
                  without_refunds: 1.0512,
                  with_refunds: 1.0512,
                },
                total_users: {
                  without_refunds: 0.8827,
                  with_refunds: 0.88,
                },
                total_spread_users: {
                  without_refunds: 0.9043,
                  with_refunds: 0.902,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9257.97,
                  with_refunds: 9038.48,
                },
                gross_mrr: {
                  without_refunds: 10363.14,
                  with_refunds: 10112.91,
                },
                net_arr: {
                  without_refunds: 1930.16,
                  with_refunds: 1496.83,
                },
                gross_arr: {
                  without_refunds: 2518.03,
                  with_refunds: 1927.59,
                },
                net_arr_spread: {
                  without_refunds: 1370.75,
                  with_refunds: 1370.75,
                },
                gross_arr_spread: {
                  without_refunds: 2031.95,
                  with_refunds: 2031.95,
                },
                net_total: {
                  without_refunds: 11188.13,
                  with_refunds: 10535.31,
                },
                gross_total: {
                  without_refunds: 12881.17,
                  with_refunds: 12040.5,
                },
                net_total_spread: {
                  without_refunds: 10628.72,
                  with_refunds: 10409.23,
                },
                gross_total_spread: {
                  without_refunds: 12395.09,
                  with_refunds: 12144.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4235,
                  with_refunds: 0.4226,
                },
                gross_mrr: {
                  without_refunds: 0.356,
                  with_refunds: 0.3555,
                },
                net_arr: {
                  without_refunds: 0.1529,
                  with_refunds: 0.1263,
                },
                gross_arr: {
                  without_refunds: 0.1303,
                  with_refunds: 0.1065,
                },
                net_arr_spread: {
                  without_refunds: 1.396,
                  with_refunds: 1.396,
                },
                gross_arr_spread: {
                  without_refunds: 1.3541,
                  with_refunds: 1.3541,
                },
                net_total: {
                  without_refunds: 0.3244,
                  with_refunds: 0.317,
                },
                gross_total: {
                  without_refunds: 0.266,
                  with_refunds: 0.2586,
                },
                net_total_spread: {
                  without_refunds: 0.4653,
                  with_refunds: 0.4654,
                },
                gross_total_spread: {
                  without_refunds: 0.4049,
                  with_refunds: 0.4055,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8775,
                  with_refunds: 0.8844,
                },
                gross_mrr: {
                  without_refunds: 0.8739,
                  with_refunds: 0.8791,
                },
                net_arr: {
                  without_refunds: 3.3994,
                  with_refunds: 4.1931,
                },
                gross_arr: {
                  without_refunds: 3.7695,
                  with_refunds: 4.5898,
                },
                net_arr_spread: {
                  without_refunds: 1.0832,
                  with_refunds: 1.0832,
                },
                gross_arr_spread: {
                  without_refunds: 1.0715,
                  with_refunds: 1.0715,
                },
                net_total: {
                  without_refunds: 1.0063,
                  with_refunds: 0.9961,
                },
                gross_total: {
                  without_refunds: 1.0283,
                  with_refunds: 1.0098,
                },
                net_total_spread: {
                  without_refunds: 0.8995,
                  with_refunds: 0.9063,
                },
                gross_total_spread: {
                  without_refunds: 0.9011,
                  with_refunds: 0.9063,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.97,
                  with_refunds: 2.9,
                },
                gross_mrr: {
                  without_refunds: 3.32,
                  with_refunds: 3.24,
                },
                net_arr: {
                  without_refunds: 7.26,
                  with_refunds: 5.63,
                },
                gross_arr: {
                  without_refunds: 9.47,
                  with_refunds: 7.25,
                },
                net_arr_spread: {
                  without_refunds: 5.74,
                  with_refunds: 5.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.5,
                  with_refunds: 8.5,
                },
                net_total: {
                  without_refunds: 3.34,
                  with_refunds: 3.15,
                },
                gross_total: {
                  without_refunds: 3.85,
                  with_refunds: 3.6,
                },
                net_total_spread: {
                  without_refunds: 3.19,
                  with_refunds: 3.12,
                },
                gross_total_spread: {
                  without_refunds: 3.72,
                  with_refunds: 3.65,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.49,
                  with_refunds: 11.14,
                },
                gross_mrr: {
                  without_refunds: 12.86,
                  with_refunds: 12.47,
                },
                net_arr: {
                  without_refunds: 120.64,
                  with_refunds: 93.55,
                },
                gross_arr: {
                  without_refunds: 157.38,
                  with_refunds: 120.47,
                },
                net_arr_spread: {
                  without_refunds: 6.07,
                  with_refunds: 6.07,
                },
                gross_arr_spread: {
                  without_refunds: 8.99,
                  with_refunds: 8.99,
                },
                net_total: {
                  without_refunds: 13.64,
                  with_refunds: 12.83,
                },
                gross_total: {
                  without_refunds: 15.71,
                  with_refunds: 14.67,
                },
                net_total_spread: {
                  without_refunds: 10.32,
                  with_refunds: 10.1,
                },
                gross_total_spread: {
                  without_refunds: 12.03,
                  with_refunds: 11.78,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.32,
                    with_refunds: 36.63,
                  },
                  gross_mrr: {
                    without_refunds: 44.32,
                    with_refunds: 43.5,
                  },
                  net_arr: {
                    without_refunds: 70.36,
                    with_refunds: 62.73,
                  },
                  gross_arr: {
                    without_refunds: 103.78,
                    with_refunds: 92.82,
                  },
                  net_arr_spread: {
                    without_refunds: 35.46,
                    with_refunds: 35.46,
                  },
                  gross_arr_spread: {
                    without_refunds: 53.41,
                    with_refunds: 53.41,
                  },
                  net_total: {
                    without_refunds: 40.36,
                    with_refunds: 39.12,
                  },
                  gross_total: {
                    without_refunds: 49.55,
                    with_refunds: 47.91,
                  },
                  net_total_spread: {
                    without_refunds: 37.51,
                    with_refunds: 36.86,
                  },
                  gross_total_spread: {
                    without_refunds: 45.36,
                    with_refunds: 44.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 77.58,
                    with_refunds: 75.82,
                  },
                  gross_mrr: {
                    without_refunds: 90.79,
                    with_refunds: 88.75,
                  },
                  net_arr: {
                    without_refunds: 545.95,
                    with_refunds: 383.5,
                  },
                  gross_arr: {
                    without_refunds: 719.61,
                    with_refunds: 508.56,
                  },
                  net_arr_spread: {
                    without_refunds: 40.96,
                    with_refunds: 40.96,
                  },
                  gross_arr_spread: {
                    without_refunds: 61.74,
                    with_refunds: 61.74,
                  },
                  net_total: {
                    without_refunds: 86.32,
                    with_refunds: 83.21,
                  },
                  gross_total: {
                    without_refunds: 103.55,
                    with_refunds: 99.61,
                  },
                  net_total_spread: {
                    without_refunds: 72.57,
                    with_refunds: 71.13,
                  },
                  gross_total_spread: {
                    without_refunds: 86.96,
                    with_refunds: 85.27,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3673,
                    with_refunds: 0.3606,
                  },
                  gross_mrr: {
                    without_refunds: 0.4363,
                    with_refunds: 0.4282,
                  },
                  net_arr: {
                    without_refunds: 0.6926,
                    with_refunds: 0.6175,
                  },
                  gross_arr: {
                    without_refunds: 1.0216,
                    with_refunds: 0.9137,
                  },
                  net_arr_spread: {
                    without_refunds: 0.349,
                    with_refunds: 0.349,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5258,
                    with_refunds: 0.5258,
                  },
                  net_total: {
                    without_refunds: 0.3973,
                    with_refunds: 0.3851,
                  },
                  gross_total: {
                    without_refunds: 0.4877,
                    with_refunds: 0.4716,
                  },
                  net_total_spread: {
                    without_refunds: 0.3692,
                    with_refunds: 0.3629,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4465,
                    with_refunds: 0.4389,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7636,
                    with_refunds: 0.7464,
                  },
                  gross_mrr: {
                    without_refunds: 0.8937,
                    with_refunds: 0.8736,
                  },
                  net_arr: {
                    without_refunds: 5.374,
                    with_refunds: 3.775,
                  },
                  gross_arr: {
                    without_refunds: 7.0835,
                    with_refunds: 5.006,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4032,
                    with_refunds: 0.4032,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6078,
                    with_refunds: 0.6078,
                  },
                  net_total: {
                    without_refunds: 0.8497,
                    with_refunds: 0.8191,
                  },
                  gross_total: {
                    without_refunds: 1.0193,
                    with_refunds: 0.9805,
                  },
                  net_total_spread: {
                    without_refunds: 0.7143,
                    with_refunds: 0.7001,
                  },
                  gross_total_spread: {
                    without_refunds: 0.856,
                    with_refunds: 0.8393,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 723,
                  with_refunds: 726,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 9,
                },
                arr_spread_users: {
                  without_refunds: 232,
                  with_refunds: 232,
                },
                total_users: {
                  without_refunds: 729,
                  with_refunds: 732,
                },
                total_spread_users: {
                  without_refunds: 954,
                  with_refunds: 956,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2317,
                  with_refunds: 0.2326,
                },
                arr_users: {
                  without_refunds: 0.0301,
                  with_refunds: 0.0338,
                },
                arr_spread_users: {
                  without_refunds: 0.9707,
                  with_refunds: 0.9707,
                },
                total_users: {
                  without_refunds: 0.2177,
                  with_refunds: 0.2186,
                },
                total_spread_users: {
                  without_refunds: 0.2864,
                  with_refunds: 0.287,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.897,
                  with_refunds: 0.8952,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.5625,
                },
                arr_spread_users: {
                  without_refunds: 1.0265,
                  with_refunds: 1.0265,
                },
                total_users: {
                  without_refunds: 0.889,
                  with_refunds: 0.8916,
                },
                total_spread_users: {
                  without_refunds: 0.9262,
                  with_refunds: 0.9273,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8270.74,
                  with_refunds: 8099.22,
                },
                gross_mrr: {
                  without_refunds: 9214.9,
                  with_refunds: 9028.23,
                },
                net_arr: {
                  without_refunds: 956.08,
                  with_refunds: 604.77,
                },
                gross_arr: {
                  without_refunds: 1155.72,
                  with_refunds: 735.75,
                },
                net_arr_spread: {
                  without_refunds: 1431.06,
                  with_refunds: 1431.06,
                },
                gross_arr_spread: {
                  without_refunds: 2104.93,
                  with_refunds: 2104.93,
                },
                net_total: {
                  without_refunds: 9226.82,
                  with_refunds: 8703.99,
                },
                gross_total: {
                  without_refunds: 10370.62,
                  with_refunds: 9763.98,
                },
                net_total_spread: {
                  without_refunds: 9701.8,
                  with_refunds: 9530.28,
                },
                gross_total_spread: {
                  without_refunds: 11319.83,
                  with_refunds: 11133.16,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3783,
                  with_refunds: 0.3787,
                },
                gross_mrr: {
                  without_refunds: 0.3166,
                  with_refunds: 0.3173,
                },
                net_arr: {
                  without_refunds: 0.0757,
                  with_refunds: 0.051,
                },
                gross_arr: {
                  without_refunds: 0.0598,
                  with_refunds: 0.0406,
                },
                net_arr_spread: {
                  without_refunds: 1.4574,
                  with_refunds: 1.4574,
                },
                gross_arr_spread: {
                  without_refunds: 1.4027,
                  with_refunds: 1.4027,
                },
                net_total: {
                  without_refunds: 0.2675,
                  with_refunds: 0.2619,
                },
                gross_total: {
                  without_refunds: 0.2141,
                  with_refunds: 0.2097,
                },
                net_total_spread: {
                  without_refunds: 0.4247,
                  with_refunds: 0.4261,
                },
                gross_total_spread: {
                  without_refunds: 0.3698,
                  with_refunds: 0.3717,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8934,
                  with_refunds: 0.8961,
                },
                gross_mrr: {
                  without_refunds: 0.8892,
                  with_refunds: 0.8927,
                },
                net_arr: {
                  without_refunds: 0.4953,
                  with_refunds: 0.404,
                },
                gross_arr: {
                  without_refunds: 0.459,
                  with_refunds: 0.3817,
                },
                net_arr_spread: {
                  without_refunds: 1.044,
                  with_refunds: 1.044,
                },
                gross_arr_spread: {
                  without_refunds: 1.0359,
                  with_refunds: 1.0359,
                },
                net_total: {
                  without_refunds: 0.8247,
                  with_refunds: 0.8262,
                },
                gross_total: {
                  without_refunds: 0.8051,
                  with_refunds: 0.8109,
                },
                net_total_spread: {
                  without_refunds: 0.9128,
                  with_refunds: 0.9156,
                },
                gross_total_spread: {
                  without_refunds: 0.9133,
                  with_refunds: 0.9167,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.65,
                  with_refunds: 2.6,
                },
                gross_mrr: {
                  without_refunds: 2.95,
                  with_refunds: 2.89,
                },
                net_arr: {
                  without_refunds: 3.59,
                  with_refunds: 2.27,
                },
                gross_arr: {
                  without_refunds: 4.34,
                  with_refunds: 2.77,
                },
                net_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                gross_arr_spread: {
                  without_refunds: 8.81,
                  with_refunds: 8.81,
                },
                net_total: {
                  without_refunds: 2.76,
                  with_refunds: 2.6,
                },
                gross_total: {
                  without_refunds: 3.1,
                  with_refunds: 2.92,
                },
                net_total_spread: {
                  without_refunds: 2.91,
                  with_refunds: 2.86,
                },
                gross_total_spread: {
                  without_refunds: 3.4,
                  with_refunds: 3.34,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.44,
                  with_refunds: 11.16,
                },
                gross_mrr: {
                  without_refunds: 12.75,
                  with_refunds: 12.44,
                },
                net_arr: {
                  without_refunds: 119.51,
                  with_refunds: 67.2,
                },
                gross_arr: {
                  without_refunds: 144.47,
                  with_refunds: 81.75,
                },
                net_arr_spread: {
                  without_refunds: 6.17,
                  with_refunds: 6.17,
                },
                gross_arr_spread: {
                  without_refunds: 9.07,
                  with_refunds: 9.07,
                },
                net_total: {
                  without_refunds: 12.66,
                  with_refunds: 11.89,
                },
                gross_total: {
                  without_refunds: 14.23,
                  with_refunds: 13.34,
                },
                net_total_spread: {
                  without_refunds: 10.17,
                  with_refunds: 9.97,
                },
                gross_total_spread: {
                  without_refunds: 11.87,
                  with_refunds: 11.65,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.97,
                    with_refunds: 39.22,
                  },
                  gross_mrr: {
                    without_refunds: 47.28,
                    with_refunds: 46.39,
                  },
                  net_arr: {
                    without_refunds: 73.96,
                    with_refunds: 65.01,
                  },
                  gross_arr: {
                    without_refunds: 108.13,
                    with_refunds: 95.59,
                  },
                  net_arr_spread: {
                    without_refunds: 41.44,
                    with_refunds: 41.44,
                  },
                  gross_arr_spread: {
                    without_refunds: 62.22,
                    with_refunds: 62.22,
                  },
                  net_total: {
                    without_refunds: 43.12,
                    with_refunds: 41.72,
                  },
                  gross_total: {
                    without_refunds: 52.65,
                    with_refunds: 50.83,
                  },
                  net_total_spread: {
                    without_refunds: 40.42,
                    with_refunds: 39.72,
                  },
                  gross_total_spread: {
                    without_refunds: 48.76,
                    with_refunds: 47.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 89.02,
                    with_refunds: 86.98,
                  },
                  gross_mrr: {
                    without_refunds: 103.53,
                    with_refunds: 101.18,
                  },
                  net_arr: {
                    without_refunds: 665.46,
                    with_refunds: 450.7,
                  },
                  gross_arr: {
                    without_refunds: 864.08,
                    with_refunds: 590.31,
                  },
                  net_arr_spread: {
                    without_refunds: 47.13,
                    with_refunds: 47.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 70.82,
                    with_refunds: 70.82,
                  },
                  net_total: {
                    without_refunds: 98.98,
                    with_refunds: 95.1,
                  },
                  gross_total: {
                    without_refunds: 117.78,
                    with_refunds: 112.95,
                  },
                  net_total_spread: {
                    without_refunds: 82.74,
                    with_refunds: 81.09,
                  },
                  gross_total_spread: {
                    without_refunds: 98.83,
                    with_refunds: 96.91,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3934,
                    with_refunds: 0.3861,
                  },
                  gross_mrr: {
                    without_refunds: 0.4654,
                    with_refunds: 0.4567,
                  },
                  net_arr: {
                    without_refunds: 0.728,
                    with_refunds: 0.6399,
                  },
                  gross_arr: {
                    without_refunds: 1.0643,
                    with_refunds: 0.9409,
                  },
                  net_arr_spread: {
                    without_refunds: 0.408,
                    with_refunds: 0.408,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6125,
                    with_refunds: 0.6125,
                  },
                  net_total: {
                    without_refunds: 0.4244,
                    with_refunds: 0.4106,
                  },
                  gross_total: {
                    without_refunds: 0.5182,
                    with_refunds: 0.5003,
                  },
                  net_total_spread: {
                    without_refunds: 0.3979,
                    with_refunds: 0.391,
                  },
                  gross_total_spread: {
                    without_refunds: 0.48,
                    with_refunds: 0.4718,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8762,
                    with_refunds: 0.8562,
                  },
                  gross_mrr: {
                    without_refunds: 1.0191,
                    with_refunds: 0.996,
                  },
                  net_arr: {
                    without_refunds: 6.5504,
                    with_refunds: 4.4365,
                  },
                  gross_arr: {
                    without_refunds: 8.5055,
                    with_refunds: 5.8107,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4639,
                    with_refunds: 0.4639,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6971,
                    with_refunds: 0.6971,
                  },
                  net_total: {
                    without_refunds: 0.9743,
                    with_refunds: 0.9361,
                  },
                  gross_total: {
                    without_refunds: 1.1593,
                    with_refunds: 1.1118,
                  },
                  net_total_spread: {
                    without_refunds: 0.8145,
                    with_refunds: 0.7983,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9728,
                    with_refunds: 0.954,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 629,
                  with_refunds: 630,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 3,
                },
                arr_spread_users: {
                  without_refunds: 235,
                  with_refunds: 235,
                },
                total_users: {
                  without_refunds: 632,
                  with_refunds: 633,
                },
                total_spread_users: {
                  without_refunds: 864,
                  with_refunds: 865,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2015,
                  with_refunds: 0.2019,
                },
                arr_users: {
                  without_refunds: 0.0113,
                  with_refunds: 0.0113,
                },
                arr_spread_users: {
                  without_refunds: 0.9833,
                  with_refunds: 0.9833,
                },
                total_users: {
                  without_refunds: 0.1887,
                  with_refunds: 0.189,
                },
                total_spread_users: {
                  without_refunds: 0.2594,
                  with_refunds: 0.2597,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.87,
                  with_refunds: 0.8678,
                },
                arr_users: {
                  without_refunds: 0.375,
                  with_refunds: 0.3333,
                },
                arr_spread_users: {
                  without_refunds: 1.0129,
                  with_refunds: 1.0129,
                },
                total_users: {
                  without_refunds: 0.8669,
                  with_refunds: 0.8648,
                },
                total_spread_users: {
                  without_refunds: 0.9057,
                  with_refunds: 0.9048,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7122.55,
                  with_refunds: 7065.8,
                },
                gross_mrr: {
                  without_refunds: 7938.52,
                  with_refunds: 7878.52,
                },
                net_arr: {
                  without_refunds: 365.24,
                  with_refunds: 365.24,
                },
                gross_arr: {
                  without_refunds: 475.71,
                  with_refunds: 475.71,
                },
                net_arr_spread: {
                  without_refunds: 1461.5,
                  with_refunds: 1461.5,
                },
                gross_arr_spread: {
                  without_refunds: 2144.57,
                  with_refunds: 2144.57,
                },
                net_total: {
                  without_refunds: 7487.8,
                  with_refunds: 7431.05,
                },
                gross_total: {
                  without_refunds: 8414.23,
                  with_refunds: 8354.23,
                },
                net_total_spread: {
                  without_refunds: 8584.05,
                  with_refunds: 8527.3,
                },
                gross_total_spread: {
                  without_refunds: 10083.09,
                  with_refunds: 10023.09,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3258,
                  with_refunds: 0.3304,
                },
                gross_mrr: {
                  without_refunds: 0.2727,
                  with_refunds: 0.2769,
                },
                net_arr: {
                  without_refunds: 0.0289,
                  with_refunds: 0.0308,
                },
                gross_arr: {
                  without_refunds: 0.0246,
                  with_refunds: 0.0263,
                },
                net_arr_spread: {
                  without_refunds: 1.4884,
                  with_refunds: 1.4884,
                },
                gross_arr_spread: {
                  without_refunds: 1.4291,
                  with_refunds: 1.4291,
                },
                net_total: {
                  without_refunds: 0.2171,
                  with_refunds: 0.2236,
                },
                gross_total: {
                  without_refunds: 0.1737,
                  with_refunds: 0.1794,
                },
                net_total_spread: {
                  without_refunds: 0.3758,
                  with_refunds: 0.3812,
                },
                gross_total_spread: {
                  without_refunds: 0.3294,
                  with_refunds: 0.3346,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8612,
                  with_refunds: 0.8724,
                },
                gross_mrr: {
                  without_refunds: 0.8615,
                  with_refunds: 0.8727,
                },
                net_arr: {
                  without_refunds: 0.382,
                  with_refunds: 0.6039,
                },
                gross_arr: {
                  without_refunds: 0.4116,
                  with_refunds: 0.6466,
                },
                net_arr_spread: {
                  without_refunds: 1.0213,
                  with_refunds: 1.0213,
                },
                gross_arr_spread: {
                  without_refunds: 1.0188,
                  with_refunds: 1.0188,
                },
                net_total: {
                  without_refunds: 0.8115,
                  with_refunds: 0.8538,
                },
                gross_total: {
                  without_refunds: 0.8114,
                  with_refunds: 0.8556,
                },
                net_total_spread: {
                  without_refunds: 0.8848,
                  with_refunds: 0.8948,
                },
                gross_total_spread: {
                  without_refunds: 0.8907,
                  with_refunds: 0.9003,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.28,
                  with_refunds: 2.26,
                },
                gross_mrr: {
                  without_refunds: 2.54,
                  with_refunds: 2.52,
                },
                net_arr: {
                  without_refunds: 1.37,
                  with_refunds: 1.37,
                },
                gross_arr: {
                  without_refunds: 1.79,
                  with_refunds: 1.79,
                },
                net_arr_spread: {
                  without_refunds: 6.12,
                  with_refunds: 6.12,
                },
                gross_arr_spread: {
                  without_refunds: 8.97,
                  with_refunds: 8.97,
                },
                net_total: {
                  without_refunds: 2.24,
                  with_refunds: 2.22,
                },
                gross_total: {
                  without_refunds: 2.51,
                  with_refunds: 2.49,
                },
                net_total_spread: {
                  without_refunds: 2.58,
                  with_refunds: 2.56,
                },
                gross_total_spread: {
                  without_refunds: 3.03,
                  with_refunds: 3.01,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.32,
                  with_refunds: 11.22,
                },
                gross_mrr: {
                  without_refunds: 12.62,
                  with_refunds: 12.51,
                },
                net_arr: {
                  without_refunds: 121.75,
                  with_refunds: 121.75,
                },
                gross_arr: {
                  without_refunds: 158.57,
                  with_refunds: 158.57,
                },
                net_arr_spread: {
                  without_refunds: 6.22,
                  with_refunds: 6.22,
                },
                gross_arr_spread: {
                  without_refunds: 9.13,
                  with_refunds: 9.13,
                },
                net_total: {
                  without_refunds: 11.85,
                  with_refunds: 11.74,
                },
                gross_total: {
                  without_refunds: 13.31,
                  with_refunds: 13.2,
                },
                net_total_spread: {
                  without_refunds: 9.94,
                  with_refunds: 9.86,
                },
                gross_total_spread: {
                  without_refunds: 11.67,
                  with_refunds: 11.59,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.25,
                    with_refunds: 41.49,
                  },
                  gross_mrr: {
                    without_refunds: 49.82,
                    with_refunds: 48.92,
                  },
                  net_arr: {
                    without_refunds: 75.33,
                    with_refunds: 66.38,
                  },
                  gross_arr: {
                    without_refunds: 109.91,
                    with_refunds: 97.37,
                  },
                  net_arr_spread: {
                    without_refunds: 47.56,
                    with_refunds: 47.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 71.19,
                    with_refunds: 71.19,
                  },
                  net_total: {
                    without_refunds: 45.36,
                    with_refunds: 43.94,
                  },
                  gross_total: {
                    without_refunds: 55.16,
                    with_refunds: 53.32,
                  },
                  net_total_spread: {
                    without_refunds: 43,
                    with_refunds: 42.28,
                  },
                  gross_total_spread: {
                    without_refunds: 51.79,
                    with_refunds: 50.94,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 100.34,
                    with_refunds: 98.19,
                  },
                  gross_mrr: {
                    without_refunds: 116.16,
                    with_refunds: 113.69,
                  },
                  net_arr: {
                    without_refunds: 787.21,
                    with_refunds: 572.45,
                  },
                  gross_arr: {
                    without_refunds: 1022.64,
                    with_refunds: 748.88,
                  },
                  net_arr_spread: {
                    without_refunds: 53.35,
                    with_refunds: 53.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 79.94,
                    with_refunds: 79.94,
                  },
                  net_total: {
                    without_refunds: 110.83,
                    with_refunds: 106.84,
                  },
                  gross_total: {
                    without_refunds: 131.09,
                    with_refunds: 126.15,
                  },
                  net_total_spread: {
                    without_refunds: 92.68,
                    with_refunds: 90.95,
                  },
                  gross_total_spread: {
                    without_refunds: 110.5,
                    with_refunds: 108.5,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4159,
                    with_refunds: 0.4084,
                  },
                  gross_mrr: {
                    without_refunds: 0.4904,
                    with_refunds: 0.4815,
                  },
                  net_arr: {
                    without_refunds: 0.7415,
                    with_refunds: 0.6534,
                  },
                  gross_arr: {
                    without_refunds: 1.0819,
                    with_refunds: 0.9585,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4682,
                    with_refunds: 0.4682,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7008,
                    with_refunds: 0.7008,
                  },
                  net_total: {
                    without_refunds: 0.4465,
                    with_refunds: 0.4325,
                  },
                  gross_total: {
                    without_refunds: 0.543,
                    with_refunds: 0.5249,
                  },
                  net_total_spread: {
                    without_refunds: 0.4232,
                    with_refunds: 0.4162,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5098,
                    with_refunds: 0.5014,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9877,
                    with_refunds: 0.9666,
                  },
                  gross_mrr: {
                    without_refunds: 1.1434,
                    with_refunds: 1.1191,
                  },
                  net_arr: {
                    without_refunds: 7.7489,
                    with_refunds: 5.6349,
                  },
                  gross_arr: {
                    without_refunds: 10.0664,
                    with_refunds: 7.3716,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5251,
                    with_refunds: 0.5251,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7869,
                    with_refunds: 0.7869,
                  },
                  net_total: {
                    without_refunds: 1.0909,
                    with_refunds: 1.0517,
                  },
                  gross_total: {
                    without_refunds: 1.2904,
                    with_refunds: 1.2417,
                  },
                  net_total_spread: {
                    without_refunds: 0.9123,
                    with_refunds: 0.8953,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0877,
                    with_refunds: 1.068,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 570,
                  with_refunds: 571,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 237,
                  with_refunds: 237,
                },
                total_users: {
                  without_refunds: 572,
                  with_refunds: 573,
                },
                total_spread_users: {
                  without_refunds: 807,
                  with_refunds: 808,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1826,
                  with_refunds: 0.183,
                },
                arr_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0075,
                },
                arr_spread_users: {
                  without_refunds: 0.9916,
                  with_refunds: 0.9916,
                },
                total_users: {
                  without_refunds: 0.1708,
                  with_refunds: 0.1711,
                },
                total_spread_users: {
                  without_refunds: 0.2423,
                  with_refunds: 0.2426,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.9062,
                  with_refunds: 0.9063,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0085,
                  with_refunds: 1.0085,
                },
                total_users: {
                  without_refunds: 0.9051,
                  with_refunds: 0.9052,
                },
                total_spread_users: {
                  without_refunds: 0.934,
                  with_refunds: 0.9341,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6510.55,
                  with_refunds: 6488.16,
                },
                gross_mrr: {
                  without_refunds: 7263,
                  with_refunds: 7238.01,
                },
                net_arr: {
                  without_refunds: 219.28,
                  with_refunds: 219.28,
                },
                gross_arr: {
                  without_refunds: 291.22,
                  with_refunds: 291.22,
                },
                net_arr_spread: {
                  without_refunds: 1479.77,
                  with_refunds: 1479.77,
                },
                gross_arr_spread: {
                  without_refunds: 2168.84,
                  with_refunds: 2168.84,
                },
                net_total: {
                  without_refunds: 6729.83,
                  with_refunds: 6707.44,
                },
                gross_total: {
                  without_refunds: 7554.23,
                  with_refunds: 7529.24,
                },
                net_total_spread: {
                  without_refunds: 7990.33,
                  with_refunds: 7967.94,
                },
                gross_total_spread: {
                  without_refunds: 9431.84,
                  with_refunds: 9406.85,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2978,
                  with_refunds: 0.3034,
                },
                gross_mrr: {
                  without_refunds: 0.2495,
                  with_refunds: 0.2544,
                },
                net_arr: {
                  without_refunds: 0.0174,
                  with_refunds: 0.0185,
                },
                gross_arr: {
                  without_refunds: 0.0151,
                  with_refunds: 0.0161,
                },
                net_arr_spread: {
                  without_refunds: 1.507,
                  with_refunds: 1.507,
                },
                gross_arr_spread: {
                  without_refunds: 1.4453,
                  with_refunds: 1.4453,
                },
                net_total: {
                  without_refunds: 0.1951,
                  with_refunds: 0.2018,
                },
                gross_total: {
                  without_refunds: 0.156,
                  with_refunds: 0.1617,
                },
                net_total_spread: {
                  without_refunds: 0.3498,
                  with_refunds: 0.3562,
                },
                gross_total_spread: {
                  without_refunds: 0.3081,
                  with_refunds: 0.3141,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9141,
                  with_refunds: 0.9182,
                },
                gross_mrr: {
                  without_refunds: 0.9149,
                  with_refunds: 0.9187,
                },
                net_arr: {
                  without_refunds: 0.6004,
                  with_refunds: 0.6004,
                },
                gross_arr: {
                  without_refunds: 0.6122,
                  with_refunds: 0.6122,
                },
                net_arr_spread: {
                  without_refunds: 1.0125,
                  with_refunds: 1.0125,
                },
                gross_arr_spread: {
                  without_refunds: 1.0113,
                  with_refunds: 1.0113,
                },
                net_total: {
                  without_refunds: 0.8988,
                  with_refunds: 0.9026,
                },
                gross_total: {
                  without_refunds: 0.8978,
                  with_refunds: 0.9012,
                },
                net_total_spread: {
                  without_refunds: 0.9308,
                  with_refunds: 0.9344,
                },
                gross_total_spread: {
                  without_refunds: 0.9354,
                  with_refunds: 0.9385,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.09,
                  with_refunds: 2.08,
                },
                gross_mrr: {
                  without_refunds: 2.33,
                  with_refunds: 2.32,
                },
                net_arr: {
                  without_refunds: 0.82,
                  with_refunds: 0.82,
                },
                gross_arr: {
                  without_refunds: 1.09,
                  with_refunds: 1.09,
                },
                net_arr_spread: {
                  without_refunds: 6.19,
                  with_refunds: 6.19,
                },
                gross_arr_spread: {
                  without_refunds: 9.07,
                  with_refunds: 9.07,
                },
                net_total: {
                  without_refunds: 2.01,
                  with_refunds: 2,
                },
                gross_total: {
                  without_refunds: 2.26,
                  with_refunds: 2.25,
                },
                net_total_spread: {
                  without_refunds: 2.4,
                  with_refunds: 2.39,
                },
                gross_total_spread: {
                  without_refunds: 2.83,
                  with_refunds: 2.82,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.42,
                  with_refunds: 11.36,
                },
                gross_mrr: {
                  without_refunds: 12.74,
                  with_refunds: 12.68,
                },
                net_arr: {
                  without_refunds: 109.64,
                  with_refunds: 109.64,
                },
                gross_arr: {
                  without_refunds: 145.61,
                  with_refunds: 145.61,
                },
                net_arr_spread: {
                  without_refunds: 6.24,
                  with_refunds: 6.24,
                },
                gross_arr_spread: {
                  without_refunds: 9.15,
                  with_refunds: 9.15,
                },
                net_total: {
                  without_refunds: 11.77,
                  with_refunds: 11.71,
                },
                gross_total: {
                  without_refunds: 13.21,
                  with_refunds: 13.14,
                },
                net_total_spread: {
                  without_refunds: 9.9,
                  with_refunds: 9.86,
                },
                gross_total_spread: {
                  without_refunds: 11.69,
                  with_refunds: 11.64,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.33,
                    with_refunds: 43.57,
                  },
                  gross_mrr: {
                    without_refunds: 52.15,
                    with_refunds: 51.24,
                  },
                  net_arr: {
                    without_refunds: 76.15,
                    with_refunds: 67.2,
                  },
                  gross_arr: {
                    without_refunds: 111.01,
                    with_refunds: 98.47,
                  },
                  net_arr_spread: {
                    without_refunds: 53.75,
                    with_refunds: 53.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 80.27,
                    with_refunds: 80.27,
                  },
                  net_total: {
                    without_refunds: 47.37,
                    with_refunds: 45.94,
                  },
                  gross_total: {
                    without_refunds: 57.41,
                    with_refunds: 55.57,
                  },
                  net_total_spread: {
                    without_refunds: 45.4,
                    with_refunds: 44.68,
                  },
                  gross_total_spread: {
                    without_refunds: 54.62,
                    with_refunds: 53.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 111.76,
                    with_refunds: 109.56,
                  },
                  gross_mrr: {
                    without_refunds: 128.9,
                    with_refunds: 126.37,
                  },
                  net_arr: {
                    without_refunds: 896.85,
                    with_refunds: 682.09,
                  },
                  gross_arr: {
                    without_refunds: 1168.26,
                    with_refunds: 894.49,
                  },
                  net_arr_spread: {
                    without_refunds: 59.59,
                    with_refunds: 59.59,
                  },
                  gross_arr_spread: {
                    without_refunds: 89.09,
                    with_refunds: 89.09,
                  },
                  net_total: {
                    without_refunds: 122.59,
                    with_refunds: 118.54,
                  },
                  gross_total: {
                    without_refunds: 144.3,
                    with_refunds: 139.29,
                  },
                  net_total_spread: {
                    without_refunds: 102.58,
                    with_refunds: 100.81,
                  },
                  gross_total_spread: {
                    without_refunds: 122.18,
                    with_refunds: 120.14,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4364,
                    with_refunds: 0.4288,
                  },
                  gross_mrr: {
                    without_refunds: 0.5133,
                    with_refunds: 0.5043,
                  },
                  net_arr: {
                    without_refunds: 0.7496,
                    with_refunds: 0.6615,
                  },
                  gross_arr: {
                    without_refunds: 1.0927,
                    with_refunds: 0.9693,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5291,
                    with_refunds: 0.5291,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7901,
                    with_refunds: 0.7901,
                  },
                  net_total: {
                    without_refunds: 0.4662,
                    with_refunds: 0.4522,
                  },
                  gross_total: {
                    without_refunds: 0.5652,
                    with_refunds: 0.547,
                  },
                  net_total_spread: {
                    without_refunds: 0.4469,
                    with_refunds: 0.4398,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5376,
                    with_refunds: 0.5292,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1001,
                    with_refunds: 1.0784,
                  },
                  gross_mrr: {
                    without_refunds: 1.2688,
                    with_refunds: 1.2439,
                  },
                  net_arr: {
                    without_refunds: 8.8281,
                    with_refunds: 6.7141,
                  },
                  gross_arr: {
                    without_refunds: 11.4997,
                    with_refunds: 8.8049,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5866,
                    with_refunds: 0.5866,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.877,
                    with_refunds: 0.877,
                  },
                  net_total: {
                    without_refunds: 1.2067,
                    with_refunds: 1.1669,
                  },
                  gross_total: {
                    without_refunds: 1.4204,
                    with_refunds: 1.3711,
                  },
                  net_total_spread: {
                    without_refunds: 1.0097,
                    with_refunds: 0.9924,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2027,
                    with_refunds: 1.1826,
                  },
                },
              },
            },
          },
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 510,
                  with_refunds: 511,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 239,
                  with_refunds: 239,
                },
                total_users: {
                  without_refunds: 512,
                  with_refunds: 513,
                },
                total_spread_users: {
                  without_refunds: 749,
                  with_refunds: 750,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1634,
                  with_refunds: 0.1637,
                },
                arr_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0075,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.1529,
                  with_refunds: 0.1532,
                },
                total_spread_users: {
                  without_refunds: 0.2249,
                  with_refunds: 0.2252,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8947,
                  with_refunds: 0.8949,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1.0084,
                  with_refunds: 1.0084,
                },
                total_users: {
                  without_refunds: 0.8951,
                  with_refunds: 0.8953,
                },
                total_spread_users: {
                  without_refunds: 0.9281,
                  with_refunds: 0.9282,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5781.43,
                  with_refunds: 5741.61,
                },
                gross_mrr: {
                  without_refunds: 6446.43,
                  with_refunds: 6396.46,
                },
                net_arr: {
                  without_refunds: 203.09,
                  with_refunds: 203.09,
                },
                gross_arr: {
                  without_refunds: 260.58,
                  with_refunds: 260.58,
                },
                net_arr_spread: {
                  without_refunds: 1496.7,
                  with_refunds: 1496.7,
                },
                gross_arr_spread: {
                  without_refunds: 2190.56,
                  with_refunds: 2190.56,
                },
                net_total: {
                  without_refunds: 5984.52,
                  with_refunds: 5944.7,
                },
                gross_total: {
                  without_refunds: 6707.01,
                  with_refunds: 6657.04,
                },
                net_total_spread: {
                  without_refunds: 7278.12,
                  with_refunds: 7238.31,
                },
                gross_total_spread: {
                  without_refunds: 8636.99,
                  with_refunds: 8587.02,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2645,
                  with_refunds: 0.2685,
                },
                gross_mrr: {
                  without_refunds: 0.2215,
                  with_refunds: 0.2248,
                },
                net_arr: {
                  without_refunds: 0.0161,
                  with_refunds: 0.0171,
                },
                gross_arr: {
                  without_refunds: 0.0135,
                  with_refunds: 0.0144,
                },
                net_arr_spread: {
                  without_refunds: 1.5243,
                  with_refunds: 1.5243,
                },
                gross_arr_spread: {
                  without_refunds: 1.4598,
                  with_refunds: 1.4598,
                },
                net_total: {
                  without_refunds: 0.1735,
                  with_refunds: 0.1789,
                },
                gross_total: {
                  without_refunds: 0.1385,
                  with_refunds: 0.143,
                },
                net_total_spread: {
                  without_refunds: 0.3186,
                  with_refunds: 0.3236,
                },
                gross_total_spread: {
                  without_refunds: 0.2822,
                  with_refunds: 0.2867,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.888,
                  with_refunds: 0.8849,
                },
                gross_mrr: {
                  without_refunds: 0.8876,
                  with_refunds: 0.8837,
                },
                net_arr: {
                  without_refunds: 0.9262,
                  with_refunds: 0.9262,
                },
                gross_arr: {
                  without_refunds: 0.8948,
                  with_refunds: 0.8948,
                },
                net_arr_spread: {
                  without_refunds: 1.0114,
                  with_refunds: 1.0114,
                },
                gross_arr_spread: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                net_total: {
                  without_refunds: 0.8893,
                  with_refunds: 0.8863,
                },
                gross_total: {
                  without_refunds: 0.8878,
                  with_refunds: 0.8842,
                },
                net_total_spread: {
                  without_refunds: 0.9109,
                  with_refunds: 0.9084,
                },
                gross_total_spread: {
                  without_refunds: 0.9157,
                  with_refunds: 0.9128,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.85,
                  with_refunds: 1.84,
                },
                gross_mrr: {
                  without_refunds: 2.07,
                  with_refunds: 2.05,
                },
                net_arr: {
                  without_refunds: 0.76,
                  with_refunds: 0.76,
                },
                gross_arr: {
                  without_refunds: 0.98,
                  with_refunds: 0.98,
                },
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 1.79,
                  with_refunds: 1.78,
                },
                gross_total: {
                  without_refunds: 2,
                  with_refunds: 1.99,
                },
                net_total_spread: {
                  without_refunds: 2.18,
                  with_refunds: 2.17,
                },
                gross_total_spread: {
                  without_refunds: 2.59,
                  with_refunds: 2.58,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.34,
                  with_refunds: 11.24,
                },
                gross_mrr: {
                  without_refunds: 12.64,
                  with_refunds: 12.52,
                },
                net_arr: {
                  without_refunds: 101.54,
                  with_refunds: 101.54,
                },
                gross_arr: {
                  without_refunds: 130.29,
                  with_refunds: 130.29,
                },
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 11.69,
                  with_refunds: 11.59,
                },
                gross_total: {
                  without_refunds: 13.1,
                  with_refunds: 12.98,
                },
                net_total_spread: {
                  without_refunds: 9.72,
                  with_refunds: 9.65,
                },
                gross_total_spread: {
                  without_refunds: 11.53,
                  with_refunds: 11.45,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 46.19,
                    with_refunds: 45.41,
                  },
                  gross_mrr: {
                    without_refunds: 54.21,
                    with_refunds: 53.29,
                  },
                  net_arr: {
                    without_refunds: 76.92,
                    with_refunds: 67.97,
                  },
                  gross_arr: {
                    without_refunds: 111.99,
                    with_refunds: 99.45,
                  },
                  net_arr_spread: {
                    without_refunds: 60.01,
                    with_refunds: 60.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 89.43,
                    with_refunds: 89.43,
                  },
                  net_total: {
                    without_refunds: 49.15,
                    with_refunds: 47.71,
                  },
                  gross_total: {
                    without_refunds: 59.42,
                    with_refunds: 57.56,
                  },
                  net_total_spread: {
                    without_refunds: 47.58,
                    with_refunds: 46.85,
                  },
                  gross_total_spread: {
                    without_refunds: 57.21,
                    with_refunds: 56.34,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 123.1,
                    with_refunds: 120.79,
                  },
                  gross_mrr: {
                    without_refunds: 141.54,
                    with_refunds: 138.88,
                  },
                  net_arr: {
                    without_refunds: 998.39,
                    with_refunds: 783.63,
                  },
                  gross_arr: {
                    without_refunds: 1298.55,
                    with_refunds: 1024.78,
                  },
                  net_arr_spread: {
                    without_refunds: 65.85,
                    with_refunds: 65.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.26,
                    with_refunds: 98.26,
                  },
                  net_total: {
                    without_refunds: 134.28,
                    with_refunds: 130.13,
                  },
                  gross_total: {
                    without_refunds: 157.4,
                    with_refunds: 152.26,
                  },
                  net_total_spread: {
                    without_refunds: 112.29,
                    with_refunds: 110.47,
                  },
                  gross_total_spread: {
                    without_refunds: 133.72,
                    with_refunds: 131.59,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4546,
                    with_refunds: 0.447,
                  },
                  gross_mrr: {
                    without_refunds: 0.5336,
                    with_refunds: 0.5245,
                  },
                  net_arr: {
                    without_refunds: 0.7571,
                    with_refunds: 0.669,
                  },
                  gross_arr: {
                    without_refunds: 1.1024,
                    with_refunds: 0.9789,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5907,
                    with_refunds: 0.5907,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8803,
                    with_refunds: 0.8803,
                  },
                  net_total: {
                    without_refunds: 0.4838,
                    with_refunds: 0.4697,
                  },
                  gross_total: {
                    without_refunds: 0.5849,
                    with_refunds: 0.5666,
                  },
                  net_total_spread: {
                    without_refunds: 0.4684,
                    with_refunds: 0.4612,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5632,
                    with_refunds: 0.5546,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2117,
                    with_refunds: 1.189,
                  },
                  gross_mrr: {
                    without_refunds: 1.3932,
                    with_refunds: 1.3671,
                  },
                  net_arr: {
                    without_refunds: 9.8276,
                    with_refunds: 7.7137,
                  },
                  gross_arr: {
                    without_refunds: 12.7822,
                    with_refunds: 10.0874,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6482,
                    with_refunds: 0.6482,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9672,
                    with_refunds: 0.9672,
                  },
                  net_total: {
                    without_refunds: 1.3218,
                    with_refunds: 1.281,
                  },
                  gross_total: {
                    without_refunds: 1.5493,
                    with_refunds: 1.4988,
                  },
                  net_total_spread: {
                    without_refunds: 1.1054,
                    with_refunds: 1.0874,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3162,
                    with_refunds: 1.2953,
                  },
                },
              },
            },
          },
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 150,
                  with_refunds: 150,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 239,
                  with_refunds: 239,
                },
                total_users: {
                  without_refunds: 150,
                  with_refunds: 150,
                },
                total_spread_users: {
                  without_refunds: 389,
                  with_refunds: 389,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0481,
                  with_refunds: 0.0481,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.0448,
                  with_refunds: 0.0448,
                },
                total_spread_users: {
                  without_refunds: 0.1168,
                  with_refunds: 0.1168,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2941,
                  with_refunds: 0.2935,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.293,
                  with_refunds: 0.2924,
                },
                total_spread_users: {
                  without_refunds: 0.5194,
                  with_refunds: 0.5187,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1723.43,
                  with_refunds: 1723.43,
                },
                gross_mrr: {
                  without_refunds: 1931.67,
                  with_refunds: 1931.67,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1496.7,
                  with_refunds: 1496.7,
                },
                gross_arr_spread: {
                  without_refunds: 2190.56,
                  with_refunds: 2190.56,
                },
                net_total: {
                  without_refunds: 1723.43,
                  with_refunds: 1723.43,
                },
                gross_total: {
                  without_refunds: 1931.67,
                  with_refunds: 1931.67,
                },
                net_total_spread: {
                  without_refunds: 3220.13,
                  with_refunds: 3220.13,
                },
                gross_total_spread: {
                  without_refunds: 4122.23,
                  with_refunds: 4122.23,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0788,
                  with_refunds: 0.0806,
                },
                gross_mrr: {
                  without_refunds: 0.0664,
                  with_refunds: 0.0679,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.5243,
                  with_refunds: 1.5243,
                },
                gross_arr_spread: {
                  without_refunds: 1.4598,
                  with_refunds: 1.4598,
                },
                net_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.0519,
                },
                gross_total: {
                  without_refunds: 0.0399,
                  with_refunds: 0.0415,
                },
                net_total_spread: {
                  without_refunds: 0.141,
                  with_refunds: 0.144,
                },
                gross_total_spread: {
                  without_refunds: 0.1347,
                  with_refunds: 0.1376,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2981,
                  with_refunds: 0.3002,
                },
                gross_mrr: {
                  without_refunds: 0.2996,
                  with_refunds: 0.302,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.288,
                  with_refunds: 0.2899,
                },
                gross_total: {
                  without_refunds: 0.288,
                  with_refunds: 0.2902,
                },
                net_total_spread: {
                  without_refunds: 0.4424,
                  with_refunds: 0.4449,
                },
                gross_total_spread: {
                  without_refunds: 0.4773,
                  with_refunds: 0.4801,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.55,
                  with_refunds: 0.55,
                },
                gross_mrr: {
                  without_refunds: 0.62,
                  with_refunds: 0.62,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 0.51,
                  with_refunds: 0.51,
                },
                gross_total: {
                  without_refunds: 0.58,
                  with_refunds: 0.58,
                },
                net_total_spread: {
                  without_refunds: 0.97,
                  with_refunds: 0.97,
                },
                gross_total_spread: {
                  without_refunds: 1.24,
                  with_refunds: 1.24,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.49,
                  with_refunds: 11.49,
                },
                gross_mrr: {
                  without_refunds: 12.88,
                  with_refunds: 12.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6.26,
                  with_refunds: 6.26,
                },
                gross_arr_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.17,
                },
                net_total: {
                  without_refunds: 11.49,
                  with_refunds: 11.49,
                },
                gross_total: {
                  without_refunds: 12.88,
                  with_refunds: 12.88,
                },
                net_total_spread: {
                  without_refunds: 8.28,
                  with_refunds: 8.28,
                },
                gross_total_spread: {
                  without_refunds: 10.6,
                  with_refunds: 10.6,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 46.74,
                    with_refunds: 45.96,
                  },
                  gross_mrr: {
                    without_refunds: 54.83,
                    with_refunds: 53.9,
                  },
                  net_arr: {
                    without_refunds: 76.92,
                    with_refunds: 67.97,
                  },
                  gross_arr: {
                    without_refunds: 111.99,
                    with_refunds: 99.45,
                  },
                  net_arr_spread: {
                    without_refunds: 66.28,
                    with_refunds: 66.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.6,
                    with_refunds: 98.6,
                  },
                  net_total: {
                    without_refunds: 49.67,
                    with_refunds: 48.23,
                  },
                  gross_total: {
                    without_refunds: 59.99,
                    with_refunds: 58.13,
                  },
                  net_total_spread: {
                    without_refunds: 48.55,
                    with_refunds: 47.82,
                  },
                  gross_total_spread: {
                    without_refunds: 58.45,
                    with_refunds: 57.58,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 134.59,
                    with_refunds: 132.28,
                  },
                  gross_mrr: {
                    without_refunds: 154.42,
                    with_refunds: 151.76,
                  },
                  net_arr: {
                    without_refunds: 998.39,
                    with_refunds: 783.63,
                  },
                  gross_arr: {
                    without_refunds: 1298.55,
                    with_refunds: 1024.78,
                  },
                  net_arr_spread: {
                    without_refunds: 72.12,
                    with_refunds: 72.12,
                  },
                  gross_arr_spread: {
                    without_refunds: 107.43,
                    with_refunds: 107.43,
                  },
                  net_total: {
                    without_refunds: 145.77,
                    with_refunds: 141.62,
                  },
                  gross_total: {
                    without_refunds: 170.28,
                    with_refunds: 165.14,
                  },
                  net_total_spread: {
                    without_refunds: 120.57,
                    with_refunds: 118.74,
                  },
                  gross_total_spread: {
                    without_refunds: 144.31,
                    with_refunds: 142.19,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4601,
                    with_refunds: 0.4524,
                  },
                  gross_mrr: {
                    without_refunds: 0.5397,
                    with_refunds: 0.5306,
                  },
                  net_arr: {
                    without_refunds: 0.7571,
                    with_refunds: 0.669,
                  },
                  gross_arr: {
                    without_refunds: 1.1024,
                    with_refunds: 0.9789,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6524,
                    with_refunds: 0.6524,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9706,
                    with_refunds: 0.9706,
                  },
                  net_total: {
                    without_refunds: 0.4889,
                    with_refunds: 0.4747,
                  },
                  gross_total: {
                    without_refunds: 0.5905,
                    with_refunds: 0.5722,
                  },
                  net_total_spread: {
                    without_refunds: 0.4779,
                    with_refunds: 0.4707,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5753,
                    with_refunds: 0.5668,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3248,
                    with_refunds: 1.3021,
                  },
                  gross_mrr: {
                    without_refunds: 1.52,
                    with_refunds: 1.4939,
                  },
                  net_arr: {
                    without_refunds: 9.8276,
                    with_refunds: 7.7137,
                  },
                  gross_arr: {
                    without_refunds: 12.7822,
                    with_refunds: 10.0874,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7099,
                    with_refunds: 0.7099,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0574,
                    with_refunds: 1.0574,
                  },
                  net_total: {
                    without_refunds: 1.4349,
                    with_refunds: 1.3941,
                  },
                  gross_total: {
                    without_refunds: 1.6761,
                    with_refunds: 1.6256,
                  },
                  net_total_spread: {
                    without_refunds: 1.1868,
                    with_refunds: 1.1688,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4205,
                    with_refunds: 1.3996,
                  },
                },
              },
            },
          },
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 45.08,
                  with_refunds: 45,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 81.32,
                  with_refunds: 81.32,
                },
                total_users: {
                  without_refunds: 45,
                  with_refunds: 44.92,
                },
                total_spread_users: {
                  without_refunds: 121.69,
                  with_refunds: 121.53,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0144,
                  with_refunds: 0.0144,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.0134,
                  with_refunds: 0.0134,
                },
                total_spread_users: {
                  without_refunds: 0.0365,
                  with_refunds: 0.0365,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 515.42,
                  with_refunds: 516.36,
                },
                gross_mrr: {
                  without_refunds: 574.25,
                  with_refunds: 575.26,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 574.51,
                  with_refunds: 574.51,
                },
                gross_arr_spread: {
                  without_refunds: 786.3,
                  with_refunds: 786.3,
                },
                net_total: {
                  without_refunds: 506.71,
                  with_refunds: 507.63,
                },
                gross_total: {
                  without_refunds: 563.93,
                  with_refunds: 564.91,
                },
                net_total_spread: {
                  without_refunds: 1017.06,
                  with_refunds: 1018.56,
                },
                gross_total_spread: {
                  without_refunds: 1289.19,
                  with_refunds: 1290.89,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0236,
                  with_refunds: 0.0241,
                },
                gross_mrr: {
                  without_refunds: 0.0197,
                  with_refunds: 0.0202,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.5851,
                  with_refunds: 0.5851,
                },
                gross_arr_spread: {
                  without_refunds: 0.524,
                  with_refunds: 0.524,
                },
                net_total: {
                  without_refunds: 0.0147,
                  with_refunds: 0.0153,
                },
                gross_total: {
                  without_refunds: 0.0116,
                  with_refunds: 0.0121,
                },
                net_total_spread: {
                  without_refunds: 0.0445,
                  with_refunds: 0.0455,
                },
                gross_total_spread: {
                  without_refunds: 0.0421,
                  with_refunds: 0.0431,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                gross_mrr: {
                  without_refunds: 0.18,
                  with_refunds: 0.18,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.4,
                  with_refunds: 2.4,
                },
                gross_arr_spread: {
                  without_refunds: 3.29,
                  with_refunds: 3.29,
                },
                net_total: {
                  without_refunds: 0.15,
                  with_refunds: 0.15,
                },
                gross_total: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                net_total_spread: {
                  without_refunds: 0.31,
                  with_refunds: 0.31,
                },
                gross_total_spread: {
                  without_refunds: 0.39,
                  with_refunds: 0.39,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.43,
                  with_refunds: 11.47,
                },
                gross_mrr: {
                  without_refunds: 12.74,
                  with_refunds: 12.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.06,
                  with_refunds: 7.06,
                },
                gross_arr_spread: {
                  without_refunds: 9.67,
                  with_refunds: 9.67,
                },
                net_total: {
                  without_refunds: 11.26,
                  with_refunds: 11.3,
                },
                gross_total: {
                  without_refunds: 12.53,
                  with_refunds: 12.58,
                },
                net_total_spread: {
                  without_refunds: 8.36,
                  with_refunds: 8.38,
                },
                gross_total_spread: {
                  without_refunds: 10.59,
                  with_refunds: 10.62,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 46.9,
                    with_refunds: 46.12,
                  },
                  gross_mrr: {
                    without_refunds: 55.02,
                    with_refunds: 54.09,
                  },
                  net_arr: {
                    without_refunds: 76.92,
                    with_refunds: 67.97,
                  },
                  gross_arr: {
                    without_refunds: 111.99,
                    with_refunds: 99.45,
                  },
                  net_arr_spread: {
                    without_refunds: 68.68,
                    with_refunds: 68.68,
                  },
                  gross_arr_spread: {
                    without_refunds: 101.89,
                    with_refunds: 101.89,
                  },
                  net_total: {
                    without_refunds: 49.82,
                    with_refunds: 48.38,
                  },
                  gross_total: {
                    without_refunds: 60.16,
                    with_refunds: 58.3,
                  },
                  net_total_spread: {
                    without_refunds: 48.85,
                    with_refunds: 48.12,
                  },
                  gross_total_spread: {
                    without_refunds: 58.84,
                    with_refunds: 57.97,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 146.02,
                    with_refunds: 143.76,
                  },
                  gross_mrr: {
                    without_refunds: 167.15,
                    with_refunds: 164.54,
                  },
                  net_arr: {
                    without_refunds: 998.39,
                    with_refunds: 783.63,
                  },
                  gross_arr: {
                    without_refunds: 1298.55,
                    with_refunds: 1024.78,
                  },
                  net_arr_spread: {
                    without_refunds: 79.18,
                    with_refunds: 79.18,
                  },
                  gross_arr_spread: {
                    without_refunds: 117.09,
                    with_refunds: 117.09,
                  },
                  net_total: {
                    without_refunds: 157.03,
                    with_refunds: 152.92,
                  },
                  gross_total: {
                    without_refunds: 182.81,
                    with_refunds: 177.72,
                  },
                  net_total_spread: {
                    without_refunds: 128.93,
                    with_refunds: 127.12,
                  },
                  gross_total_spread: {
                    without_refunds: 154.91,
                    with_refunds: 152.81,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4617,
                    with_refunds: 0.454,
                  },
                  gross_mrr: {
                    without_refunds: 0.5415,
                    with_refunds: 0.5324,
                  },
                  net_arr: {
                    without_refunds: 0.7571,
                    with_refunds: 0.669,
                  },
                  gross_arr: {
                    without_refunds: 1.1024,
                    with_refunds: 0.9789,
                  },
                  net_arr_spread: {
                    without_refunds: 0.676,
                    with_refunds: 0.676,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.003,
                    with_refunds: 1.003,
                  },
                  net_total: {
                    without_refunds: 0.4904,
                    with_refunds: 0.4762,
                  },
                  gross_total: {
                    without_refunds: 0.5922,
                    with_refunds: 0.5739,
                  },
                  net_total_spread: {
                    without_refunds: 0.4809,
                    with_refunds: 0.4737,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5792,
                    with_refunds: 0.5706,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.4373,
                    with_refunds: 1.4151,
                  },
                  gross_mrr: {
                    without_refunds: 1.6454,
                    with_refunds: 1.6197,
                  },
                  net_arr: {
                    without_refunds: 9.8276,
                    with_refunds: 7.7137,
                  },
                  gross_arr: {
                    without_refunds: 12.7822,
                    with_refunds: 10.0874,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7794,
                    with_refunds: 0.7794,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1526,
                    with_refunds: 1.1526,
                  },
                  net_total: {
                    without_refunds: 1.5457,
                    with_refunds: 1.5053,
                  },
                  gross_total: {
                    without_refunds: 1.7995,
                    with_refunds: 1.7494,
                  },
                  net_total_spread: {
                    without_refunds: 1.2691,
                    with_refunds: 1.2513,
                  },
                  gross_total_spread: {
                    without_refunds: 1.5248,
                    with_refunds: 1.5042,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 2187,
              with_refunds: 2187,
            },
            returning_users: {
              without_refunds: 2504,
              with_refunds: 2518,
            },
            paying_users: {
              without_refunds: 4691,
              with_refunds: 4705,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 182,
              with_refunds: 182,
            },
            returning_users: {
              without_refunds: 50,
              with_refunds: 53,
            },
            paying_users: {
              without_refunds: 232,
              with_refunds: 235,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2369,
              with_refunds: 2369,
            },
            returning_users: {
              without_refunds: 2554,
              with_refunds: 2571,
            },
            paying_users: {
              without_refunds: 4923,
              with_refunds: 4940,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 145873.42,
                with_refunds: 143435.93,
              },
              gross_mrr: {
                without_refunds: 171130.16,
                with_refunds: 168236.29,
              },
              net_arr: {
                without_refunds: 20460.23,
                with_refunds: 18079.31,
              },
              gross_arr: {
                without_refunds: 29789.14,
                with_refunds: 26453.41,
              },
              net_arr_spread: {
                without_refunds: 15839.91,
                with_refunds: 15839.91,
              },
              gross_arr_spread: {
                without_refunds: 23565.44,
                with_refunds: 23565.44,
              },
              net_total: {
                without_refunds: 166333.64,
                with_refunds: 161515.24,
              },
              gross_total: {
                without_refunds: 200919.3,
                with_refunds: 194689.69,
              },
              net_total_spread: {
                without_refunds: 161713.32,
                with_refunds: 159275.83,
              },
              gross_total_spread: {
                without_refunds: 194695.6,
                with_refunds: 191801.72,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 146388.83,
                with_refunds: 143952.29,
              },
              gross_mrr: {
                without_refunds: 171704.41,
                with_refunds: 168811.55,
              },
              net_arr: {
                without_refunds: 20460.23,
                with_refunds: 18079.31,
              },
              gross_arr: {
                without_refunds: 29789.14,
                with_refunds: 26453.41,
              },
              net_arr_spread: {
                without_refunds: 16414.41,
                with_refunds: 16414.41,
              },
              gross_arr_spread: {
                without_refunds: 24351.74,
                with_refunds: 24351.74,
              },
              net_total: {
                without_refunds: 166840.36,
                with_refunds: 162022.87,
              },
              gross_total: {
                without_refunds: 201483.23,
                with_refunds: 195254.6,
              },
              net_total_spread: {
                without_refunds: 162730.38,
                with_refunds: 160294.39,
              },
              gross_total_spread: {
                without_refunds: 195984.78,
                with_refunds: 193092.61,
              },
            },
          },
        },
      },
      cac: 101.59,
      mrr_ua_spend: {
        without_refunds: 317062.39,
        with_refunds: 317062.39,
      },
      arr_ua_spend: {
        without_refunds: 27022.94,
        with_refunds: 27022.94,
      },
      arr_spread_ua_spend: {
        without_refunds: 24280.01,
        with_refunds: 24280.01,
      },
      total_ua_spend: {
        without_refunds: 340224.91,
        with_refunds: 340224.91,
      },
      total_spread_ua_spend: {
        without_refunds: 338396.29,
        with_refunds: 338396.29,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 9,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 2789,
        with_refunds: 2789,
      },
      first_period_arr_users: {
        without_refunds: 994,
        with_refunds: 994,
      },
      first_period_arr_spread_users: {
        without_refunds: 848,
        with_refunds: 848,
      },
      first_period_total_users: {
        without_refunds: 3735,
        with_refunds: 3735,
      },
      first_period_total_spread_users: {
        without_refunds: 3595,
        with_refunds: 3595,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1849,
                  with_refunds: 1849,
                },
                arr_users: {
                  without_refunds: 441,
                  with_refunds: 441,
                },
                arr_spread_users: {
                  without_refunds: 390,
                  with_refunds: 390,
                },
                total_users: {
                  without_refunds: 2289,
                  with_refunds: 2289,
                },
                total_spread_users: {
                  without_refunds: 2238,
                  with_refunds: 2238,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.663,
                  with_refunds: 0.663,
                },
                arr_users: {
                  without_refunds: 0.4437,
                  with_refunds: 0.4437,
                },
                arr_spread_users: {
                  without_refunds: 0.4599,
                  with_refunds: 0.4599,
                },
                total_users: {
                  without_refunds: 0.6129,
                  with_refunds: 0.6129,
                },
                total_spread_users: {
                  without_refunds: 0.6225,
                  with_refunds: 0.6225,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 19946.49,
                  with_refunds: 19573.72,
                },
                gross_mrr: {
                  without_refunds: 25009.69,
                  with_refunds: 24536.01,
                },
                net_arr: {
                  without_refunds: 39391.43,
                  with_refunds: 36539.39,
                },
                gross_arr: {
                  without_refunds: 45061.91,
                  with_refunds: 41936.66,
                },
                net_arr_spread: {
                  without_refunds: 2888.19,
                  with_refunds: 2888.19,
                },
                gross_arr_spread: {
                  without_refunds: 3326.36,
                  with_refunds: 3326.36,
                },
                net_total: {
                  without_refunds: 59337.92,
                  with_refunds: 56113.11,
                },
                gross_total: {
                  without_refunds: 70071.6,
                  with_refunds: 66472.67,
                },
                net_total_spread: {
                  without_refunds: 22834.68,
                  with_refunds: 22461.92,
                },
                gross_total_spread: {
                  without_refunds: 28336.05,
                  with_refunds: 27862.37,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.15,
                  with_refunds: 7.02,
                },
                gross_mrr: {
                  without_refunds: 8.97,
                  with_refunds: 8.8,
                },
                net_arr: {
                  without_refunds: 39.63,
                  with_refunds: 36.76,
                },
                gross_arr: {
                  without_refunds: 45.33,
                  with_refunds: 42.19,
                },
                net_arr_spread: {
                  without_refunds: 3.41,
                  with_refunds: 3.41,
                },
                gross_arr_spread: {
                  without_refunds: 3.92,
                  with_refunds: 3.92,
                },
                net_total: {
                  without_refunds: 15.89,
                  with_refunds: 15.02,
                },
                gross_total: {
                  without_refunds: 18.76,
                  with_refunds: 17.8,
                },
                net_total_spread: {
                  without_refunds: 6.35,
                  with_refunds: 6.25,
                },
                gross_total_spread: {
                  without_refunds: 7.88,
                  with_refunds: 7.75,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.79,
                  with_refunds: 10.59,
                },
                gross_mrr: {
                  without_refunds: 13.53,
                  with_refunds: 13.27,
                },
                net_arr: {
                  without_refunds: 89.32,
                  with_refunds: 82.86,
                },
                gross_arr: {
                  without_refunds: 102.18,
                  with_refunds: 95.09,
                },
                net_arr_spread: {
                  without_refunds: 7.41,
                  with_refunds: 7.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                net_total: {
                  without_refunds: 25.92,
                  with_refunds: 24.51,
                },
                gross_total: {
                  without_refunds: 30.61,
                  with_refunds: 29.04,
                },
                net_total_spread: {
                  without_refunds: 10.2,
                  with_refunds: 10.04,
                },
                gross_total_spread: {
                  without_refunds: 12.66,
                  with_refunds: 12.45,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 7.15,
                    with_refunds: 7.02,
                  },
                  gross_mrr: {
                    without_refunds: 8.97,
                    with_refunds: 8.8,
                  },
                  net_arr: {
                    without_refunds: 39.63,
                    with_refunds: 36.76,
                  },
                  gross_arr: {
                    without_refunds: 45.33,
                    with_refunds: 42.19,
                  },
                  net_arr_spread: {
                    without_refunds: 3.41,
                    with_refunds: 3.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.92,
                    with_refunds: 3.92,
                  },
                  net_total: {
                    without_refunds: 15.89,
                    with_refunds: 15.02,
                  },
                  gross_total: {
                    without_refunds: 18.76,
                    with_refunds: 17.8,
                  },
                  net_total_spread: {
                    without_refunds: 6.35,
                    with_refunds: 6.25,
                  },
                  gross_total_spread: {
                    without_refunds: 7.88,
                    with_refunds: 7.75,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.79,
                    with_refunds: 10.59,
                  },
                  gross_mrr: {
                    without_refunds: 13.53,
                    with_refunds: 13.27,
                  },
                  net_arr: {
                    without_refunds: 89.32,
                    with_refunds: 82.86,
                  },
                  gross_arr: {
                    without_refunds: 102.18,
                    with_refunds: 95.09,
                  },
                  net_arr_spread: {
                    without_refunds: 7.41,
                    with_refunds: 7.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.53,
                    with_refunds: 8.53,
                  },
                  net_total: {
                    without_refunds: 25.92,
                    with_refunds: 24.51,
                  },
                  gross_total: {
                    without_refunds: 30.61,
                    with_refunds: 29.04,
                  },
                  net_total_spread: {
                    without_refunds: 10.2,
                    with_refunds: 10.04,
                  },
                  gross_total_spread: {
                    without_refunds: 12.66,
                    with_refunds: 12.45,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0588,
                    with_refunds: 0.0577,
                  },
                  gross_mrr: {
                    without_refunds: 0.0737,
                    with_refunds: 0.0723,
                  },
                  net_arr: {
                    without_refunds: 0.3258,
                    with_refunds: 0.3023,
                  },
                  gross_arr: {
                    without_refunds: 0.3728,
                    with_refunds: 0.3469,
                  },
                  net_arr_spread: {
                    without_refunds: 0.028,
                    with_refunds: 0.028,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0323,
                    with_refunds: 0.0323,
                  },
                  net_total: {
                    without_refunds: 0.1306,
                    with_refunds: 0.1235,
                  },
                  gross_total: {
                    without_refunds: 0.1543,
                    with_refunds: 0.1463,
                  },
                  net_total_spread: {
                    without_refunds: 0.0522,
                    with_refunds: 0.0514,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0648,
                    with_refunds: 0.0637,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0887,
                    with_refunds: 0.087,
                  },
                  gross_mrr: {
                    without_refunds: 0.1112,
                    with_refunds: 0.1091,
                  },
                  net_arr: {
                    without_refunds: 0.7344,
                    with_refunds: 0.6813,
                  },
                  gross_arr: {
                    without_refunds: 0.8402,
                    with_refunds: 0.7819,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0609,
                    with_refunds: 0.0609,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0701,
                    with_refunds: 0.0701,
                  },
                  net_total: {
                    without_refunds: 0.2131,
                    with_refunds: 0.2016,
                  },
                  gross_total: {
                    without_refunds: 0.2517,
                    with_refunds: 0.2388,
                  },
                  net_total_spread: {
                    without_refunds: 0.0839,
                    with_refunds: 0.0825,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1041,
                    with_refunds: 0.1024,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2135,
                  with_refunds: 2148,
                },
                arr_users: {
                  without_refunds: 494,
                  with_refunds: 514,
                },
                arr_spread_users: {
                  without_refunds: 799,
                  with_refunds: 799,
                },
                total_users: {
                  without_refunds: 2626,
                  with_refunds: 2658,
                },
                total_spread_users: {
                  without_refunds: 2931,
                  with_refunds: 2943,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7655,
                  with_refunds: 0.7702,
                },
                arr_users: {
                  without_refunds: 0.497,
                  with_refunds: 0.5171,
                },
                arr_spread_users: {
                  without_refunds: 0.9422,
                  with_refunds: 0.9422,
                },
                total_users: {
                  without_refunds: 0.7031,
                  with_refunds: 0.7116,
                },
                total_spread_users: {
                  without_refunds: 0.8153,
                  with_refunds: 0.8186,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.1547,
                  with_refunds: 1.1617,
                },
                arr_users: {
                  without_refunds: 1.1202,
                  with_refunds: 1.1655,
                },
                arr_spread_users: {
                  without_refunds: 2.0487,
                  with_refunds: 2.0487,
                },
                total_users: {
                  without_refunds: 1.1472,
                  with_refunds: 1.1612,
                },
                total_spread_users: {
                  without_refunds: 1.3097,
                  with_refunds: 1.315,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 24021.59,
                  with_refunds: 23383.18,
                },
                gross_mrr: {
                  without_refunds: 28262.27,
                  with_refunds: 27509.69,
                },
                net_arr: {
                  without_refunds: 46717.01,
                  with_refunds: 37432.74,
                },
                gross_arr: {
                  without_refunds: 49269.69,
                  with_refunds: 39505.29,
                },
                net_arr_spread: {
                  without_refunds: 6108.2,
                  with_refunds: 6108.2,
                },
                gross_arr_spread: {
                  without_refunds: 6728.77,
                  with_refunds: 6728.77,
                },
                net_total: {
                  without_refunds: 70738.6,
                  with_refunds: 60815.92,
                },
                gross_total: {
                  without_refunds: 77531.96,
                  with_refunds: 67014.98,
                },
                net_total_spread: {
                  without_refunds: 30129.78,
                  with_refunds: 29491.37,
                },
                gross_total_spread: {
                  without_refunds: 34991.04,
                  with_refunds: 34238.46,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.2043,
                  with_refunds: 1.1946,
                },
                gross_mrr: {
                  without_refunds: 1.1301,
                  with_refunds: 1.1212,
                },
                net_arr: {
                  without_refunds: 1.186,
                  with_refunds: 1.0244,
                },
                gross_arr: {
                  without_refunds: 1.0934,
                  with_refunds: 0.942,
                },
                net_arr_spread: {
                  without_refunds: 2.1149,
                  with_refunds: 2.1149,
                },
                gross_arr_spread: {
                  without_refunds: 2.0229,
                  with_refunds: 2.0229,
                },
                net_total: {
                  without_refunds: 1.1921,
                  with_refunds: 1.0838,
                },
                gross_total: {
                  without_refunds: 1.1065,
                  with_refunds: 1.0082,
                },
                net_total_spread: {
                  without_refunds: 1.3195,
                  with_refunds: 1.313,
                },
                gross_total_spread: {
                  without_refunds: 1.2349,
                  with_refunds: 1.2288,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.2043,
                  with_refunds: 1.1946,
                },
                gross_mrr: {
                  without_refunds: 1.1301,
                  with_refunds: 1.1212,
                },
                net_arr: {
                  without_refunds: 1.186,
                  with_refunds: 1.0244,
                },
                gross_arr: {
                  without_refunds: 1.0934,
                  with_refunds: 0.942,
                },
                net_arr_spread: {
                  without_refunds: 2.1149,
                  with_refunds: 2.1149,
                },
                gross_arr_spread: {
                  without_refunds: 2.0229,
                  with_refunds: 2.0229,
                },
                net_total: {
                  without_refunds: 1.1921,
                  with_refunds: 1.0838,
                },
                gross_total: {
                  without_refunds: 1.1065,
                  with_refunds: 1.0082,
                },
                net_total_spread: {
                  without_refunds: 1.3195,
                  with_refunds: 1.313,
                },
                gross_total_spread: {
                  without_refunds: 1.2349,
                  with_refunds: 1.2288,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.61,
                  with_refunds: 8.38,
                },
                gross_mrr: {
                  without_refunds: 10.13,
                  with_refunds: 9.86,
                },
                net_arr: {
                  without_refunds: 47,
                  with_refunds: 37.66,
                },
                gross_arr: {
                  without_refunds: 49.57,
                  with_refunds: 39.74,
                },
                net_arr_spread: {
                  without_refunds: 7.2,
                  with_refunds: 7.2,
                },
                gross_arr_spread: {
                  without_refunds: 7.93,
                  with_refunds: 7.93,
                },
                net_total: {
                  without_refunds: 18.94,
                  with_refunds: 16.28,
                },
                gross_total: {
                  without_refunds: 20.76,
                  with_refunds: 17.94,
                },
                net_total_spread: {
                  without_refunds: 8.38,
                  with_refunds: 8.2,
                },
                gross_total_spread: {
                  without_refunds: 9.73,
                  with_refunds: 9.52,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.25,
                  with_refunds: 10.89,
                },
                gross_mrr: {
                  without_refunds: 13.24,
                  with_refunds: 12.81,
                },
                net_arr: {
                  without_refunds: 94.57,
                  with_refunds: 72.83,
                },
                gross_arr: {
                  without_refunds: 99.74,
                  with_refunds: 76.86,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.42,
                  with_refunds: 8.42,
                },
                net_total: {
                  without_refunds: 26.94,
                  with_refunds: 22.88,
                },
                gross_total: {
                  without_refunds: 29.52,
                  with_refunds: 25.21,
                },
                net_total_spread: {
                  without_refunds: 10.28,
                  with_refunds: 10.02,
                },
                gross_total_spread: {
                  without_refunds: 11.94,
                  with_refunds: 11.63,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.76,
                    with_refunds: 15.4,
                  },
                  gross_mrr: {
                    without_refunds: 19.1,
                    with_refunds: 18.66,
                  },
                  net_arr: {
                    without_refunds: 86.63,
                    with_refunds: 74.42,
                  },
                  gross_arr: {
                    without_refunds: 94.9,
                    with_refunds: 81.93,
                  },
                  net_arr_spread: {
                    without_refunds: 10.61,
                    with_refunds: 10.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 11.86,
                    with_refunds: 11.86,
                  },
                  net_total: {
                    without_refunds: 34.83,
                    with_refunds: 31.31,
                  },
                  gross_total: {
                    without_refunds: 39.52,
                    with_refunds: 35.74,
                  },
                  net_total_spread: {
                    without_refunds: 14.73,
                    with_refunds: 14.45,
                  },
                  gross_total_spread: {
                    without_refunds: 17.62,
                    with_refunds: 17.27,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.04,
                    with_refunds: 21.47,
                  },
                  gross_mrr: {
                    without_refunds: 26.76,
                    with_refunds: 26.08,
                  },
                  net_arr: {
                    without_refunds: 183.89,
                    with_refunds: 155.68,
                  },
                  gross_arr: {
                    without_refunds: 201.92,
                    with_refunds: 171.95,
                  },
                  net_arr_spread: {
                    without_refunds: 15.05,
                    with_refunds: 15.05,
                  },
                  gross_arr_spread: {
                    without_refunds: 16.95,
                    with_refunds: 16.95,
                  },
                  net_total: {
                    without_refunds: 52.86,
                    with_refunds: 47.39,
                  },
                  gross_total: {
                    without_refunds: 60.14,
                    with_refunds: 54.25,
                  },
                  net_total_spread: {
                    without_refunds: 20.48,
                    with_refunds: 20.06,
                  },
                  gross_total_spread: {
                    without_refunds: 24.6,
                    with_refunds: 24.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1296,
                    with_refunds: 0.1266,
                  },
                  gross_mrr: {
                    without_refunds: 0.1571,
                    with_refunds: 0.1534,
                  },
                  net_arr: {
                    without_refunds: 0.7123,
                    with_refunds: 0.6119,
                  },
                  gross_arr: {
                    without_refunds: 0.7803,
                    with_refunds: 0.6737,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0872,
                    with_refunds: 0.0872,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0975,
                    with_refunds: 0.0975,
                  },
                  net_total: {
                    without_refunds: 0.2864,
                    with_refunds: 0.2574,
                  },
                  gross_total: {
                    without_refunds: 0.3249,
                    with_refunds: 0.2939,
                  },
                  net_total_spread: {
                    without_refunds: 0.1211,
                    with_refunds: 0.1188,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1448,
                    with_refunds: 0.142,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1812,
                    with_refunds: 0.1766,
                  },
                  gross_mrr: {
                    without_refunds: 0.2201,
                    with_refunds: 0.2144,
                  },
                  net_arr: {
                    without_refunds: 1.512,
                    with_refunds: 1.2801,
                  },
                  gross_arr: {
                    without_refunds: 1.6602,
                    with_refunds: 1.4139,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1237,
                    with_refunds: 0.1237,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1394,
                    with_refunds: 0.1394,
                  },
                  net_total: {
                    without_refunds: 0.4346,
                    with_refunds: 0.3897,
                  },
                  gross_total: {
                    without_refunds: 0.4945,
                    with_refunds: 0.4461,
                  },
                  net_total_spread: {
                    without_refunds: 0.1684,
                    with_refunds: 0.1649,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2023,
                    with_refunds: 0.198,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1563,
                  with_refunds: 1567,
                },
                arr_users: {
                  without_refunds: 14,
                  with_refunds: 22,
                },
                arr_spread_users: {
                  without_refunds: 807,
                  with_refunds: 807,
                },
                total_users: {
                  without_refunds: 1577,
                  with_refunds: 1589,
                },
                total_spread_users: {
                  without_refunds: 2370,
                  with_refunds: 2374,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5604,
                  with_refunds: 0.5619,
                },
                arr_users: {
                  without_refunds: 0.0141,
                  with_refunds: 0.0221,
                },
                arr_spread_users: {
                  without_refunds: 0.9517,
                  with_refunds: 0.9517,
                },
                total_users: {
                  without_refunds: 0.4222,
                  with_refunds: 0.4254,
                },
                total_spread_users: {
                  without_refunds: 0.6592,
                  with_refunds: 0.6604,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7321,
                  with_refunds: 0.7295,
                },
                arr_users: {
                  without_refunds: 0.0283,
                  with_refunds: 0.0428,
                },
                arr_spread_users: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                total_users: {
                  without_refunds: 0.6005,
                  with_refunds: 0.5978,
                },
                total_spread_users: {
                  without_refunds: 0.8086,
                  with_refunds: 0.8067,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 17527.75,
                  with_refunds: 17223.43,
                },
                gross_mrr: {
                  without_refunds: 20561.84,
                  with_refunds: 20215.09,
                },
                net_arr: {
                  without_refunds: 1344.01,
                  with_refunds: 158.78,
                },
                gross_arr: {
                  without_refunds: 1652.03,
                  with_refunds: 353.8,
                },
                net_arr_spread: {
                  without_refunds: 6169.34,
                  with_refunds: 6169.34,
                },
                gross_arr_spread: {
                  without_refunds: 6807.76,
                  with_refunds: 6807.76,
                },
                net_total: {
                  without_refunds: 18871.76,
                  with_refunds: 17382.21,
                },
                gross_total: {
                  without_refunds: 22213.87,
                  with_refunds: 20568.89,
                },
                net_total_spread: {
                  without_refunds: 23697.09,
                  with_refunds: 23392.77,
                },
                gross_total_spread: {
                  without_refunds: 27369.6,
                  with_refunds: 27022.84,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8787,
                  with_refunds: 0.8799,
                },
                gross_mrr: {
                  without_refunds: 0.8222,
                  with_refunds: 0.8239,
                },
                net_arr: {
                  without_refunds: 0.0341,
                  with_refunds: 0.0043,
                },
                gross_arr: {
                  without_refunds: 0.0367,
                  with_refunds: 0.0084,
                },
                net_arr_spread: {
                  without_refunds: 2.1361,
                  with_refunds: 2.1361,
                },
                gross_arr_spread: {
                  without_refunds: 2.0466,
                  with_refunds: 2.0466,
                },
                net_total: {
                  without_refunds: 0.318,
                  with_refunds: 0.3098,
                },
                gross_total: {
                  without_refunds: 0.317,
                  with_refunds: 0.3094,
                },
                net_total_spread: {
                  without_refunds: 1.0378,
                  with_refunds: 1.0414,
                },
                gross_total_spread: {
                  without_refunds: 0.9659,
                  with_refunds: 0.9699,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7297,
                  with_refunds: 0.7366,
                },
                gross_mrr: {
                  without_refunds: 0.7275,
                  with_refunds: 0.7348,
                },
                net_arr: {
                  without_refunds: 0.0288,
                  with_refunds: 0.0042,
                },
                gross_arr: {
                  without_refunds: 0.0335,
                  with_refunds: 0.009,
                },
                net_arr_spread: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                gross_arr_spread: {
                  without_refunds: 1.0117,
                  with_refunds: 1.0117,
                },
                net_total: {
                  without_refunds: 0.2668,
                  with_refunds: 0.2858,
                },
                gross_total: {
                  without_refunds: 0.2865,
                  with_refunds: 0.3069,
                },
                net_total_spread: {
                  without_refunds: 0.7865,
                  with_refunds: 0.7932,
                },
                gross_total_spread: {
                  without_refunds: 0.7822,
                  with_refunds: 0.7893,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.28,
                  with_refunds: 6.18,
                },
                gross_mrr: {
                  without_refunds: 7.37,
                  with_refunds: 7.25,
                },
                net_arr: {
                  without_refunds: 1.35,
                  with_refunds: 0.16,
                },
                gross_arr: {
                  without_refunds: 1.66,
                  with_refunds: 0.36,
                },
                net_arr_spread: {
                  without_refunds: 7.28,
                  with_refunds: 7.28,
                },
                gross_arr_spread: {
                  without_refunds: 8.03,
                  with_refunds: 8.03,
                },
                net_total: {
                  without_refunds: 5.05,
                  with_refunds: 4.65,
                },
                gross_total: {
                  without_refunds: 5.95,
                  with_refunds: 5.51,
                },
                net_total_spread: {
                  without_refunds: 6.59,
                  with_refunds: 6.51,
                },
                gross_total_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.52,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.21,
                  with_refunds: 10.99,
                },
                gross_mrr: {
                  without_refunds: 13.16,
                  with_refunds: 12.9,
                },
                net_arr: {
                  without_refunds: 96,
                  with_refunds: 7.22,
                },
                gross_arr: {
                  without_refunds: 118,
                  with_refunds: 16.08,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.44,
                  with_refunds: 8.44,
                },
                net_total: {
                  without_refunds: 11.97,
                  with_refunds: 10.94,
                },
                gross_total: {
                  without_refunds: 14.09,
                  with_refunds: 12.94,
                },
                net_total_spread: {
                  without_refunds: 10,
                  with_refunds: 9.85,
                },
                gross_total_spread: {
                  without_refunds: 11.55,
                  with_refunds: 11.38,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.05,
                    with_refunds: 21.58,
                  },
                  gross_mrr: {
                    without_refunds: 26.47,
                    with_refunds: 25.91,
                  },
                  net_arr: {
                    without_refunds: 87.98,
                    with_refunds: 74.58,
                  },
                  gross_arr: {
                    without_refunds: 96.56,
                    with_refunds: 82.29,
                  },
                  net_arr_spread: {
                    without_refunds: 17.88,
                    with_refunds: 17.88,
                  },
                  gross_arr_spread: {
                    without_refunds: 19.89,
                    with_refunds: 19.89,
                  },
                  net_total: {
                    without_refunds: 39.88,
                    with_refunds: 35.96,
                  },
                  gross_total: {
                    without_refunds: 45.47,
                    with_refunds: 41.25,
                  },
                  net_total_spread: {
                    without_refunds: 21.32,
                    with_refunds: 20.96,
                  },
                  gross_total_spread: {
                    without_refunds: 25.23,
                    with_refunds: 24.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.25,
                    with_refunds: 32.46,
                  },
                  gross_mrr: {
                    without_refunds: 39.92,
                    with_refunds: 38.98,
                  },
                  net_arr: {
                    without_refunds: 279.89,
                    with_refunds: 162.9,
                  },
                  gross_arr: {
                    without_refunds: 319.92,
                    with_refunds: 188.04,
                  },
                  net_arr_spread: {
                    without_refunds: 22.7,
                    with_refunds: 22.7,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.39,
                    with_refunds: 25.39,
                  },
                  net_total: {
                    without_refunds: 64.83,
                    with_refunds: 58.33,
                  },
                  gross_total: {
                    without_refunds: 74.22,
                    with_refunds: 67.2,
                  },
                  net_total_spread: {
                    without_refunds: 30.48,
                    with_refunds: 29.91,
                  },
                  gross_total_spread: {
                    without_refunds: 36.15,
                    with_refunds: 35.47,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1813,
                    with_refunds: 0.1774,
                  },
                  gross_mrr: {
                    without_refunds: 0.2177,
                    with_refunds: 0.213,
                  },
                  net_arr: {
                    without_refunds: 0.7234,
                    with_refunds: 0.6132,
                  },
                  gross_arr: {
                    without_refunds: 0.794,
                    with_refunds: 0.6766,
                  },
                  net_arr_spread: {
                    without_refunds: 0.147,
                    with_refunds: 0.147,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1635,
                    with_refunds: 0.1635,
                  },
                  net_total: {
                    without_refunds: 0.3279,
                    with_refunds: 0.2957,
                  },
                  gross_total: {
                    without_refunds: 0.3738,
                    with_refunds: 0.3391,
                  },
                  net_total_spread: {
                    without_refunds: 0.1753,
                    with_refunds: 0.1723,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2074,
                    with_refunds: 0.2038,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2734,
                    with_refunds: 0.2669,
                  },
                  gross_mrr: {
                    without_refunds: 0.3282,
                    with_refunds: 0.3205,
                  },
                  net_arr: {
                    without_refunds: 2.3014,
                    with_refunds: 1.3394,
                  },
                  gross_arr: {
                    without_refunds: 2.6305,
                    with_refunds: 1.5461,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1866,
                    with_refunds: 0.1866,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2087,
                    with_refunds: 0.2087,
                  },
                  net_total: {
                    without_refunds: 0.533,
                    with_refunds: 0.4796,
                  },
                  gross_total: {
                    without_refunds: 0.6103,
                    with_refunds: 0.5525,
                  },
                  net_total_spread: {
                    without_refunds: 0.2506,
                    with_refunds: 0.2459,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2972,
                    with_refunds: 0.2916,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1236,
                  with_refunds: 1240,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 808,
                  with_refunds: 808,
                },
                total_users: {
                  without_refunds: 1239,
                  with_refunds: 1245,
                },
                total_spread_users: {
                  without_refunds: 2044,
                  with_refunds: 2048,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4432,
                  with_refunds: 0.4446,
                },
                arr_users: {
                  without_refunds: 0.003,
                  with_refunds: 0.005,
                },
                arr_spread_users: {
                  without_refunds: 0.9528,
                  with_refunds: 0.9528,
                },
                total_users: {
                  without_refunds: 0.3317,
                  with_refunds: 0.3333,
                },
                total_spread_users: {
                  without_refunds: 0.5686,
                  with_refunds: 0.5697,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7908,
                  with_refunds: 0.7913,
                },
                arr_users: {
                  without_refunds: 0.2143,
                  with_refunds: 0.2273,
                },
                arr_spread_users: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                total_users: {
                  without_refunds: 0.7857,
                  with_refunds: 0.7835,
                },
                total_spread_users: {
                  without_refunds: 0.8624,
                  with_refunds: 0.8627,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13963.41,
                  with_refunds: 13748.95,
                },
                gross_mrr: {
                  without_refunds: 16329.74,
                  with_refunds: 16088.8,
                },
                net_arr: {
                  without_refunds: 322.97,
                  with_refunds: 148.49,
                },
                gross_arr: {
                  without_refunds: 379.97,
                  with_refunds: 199.97,
                },
                net_arr_spread: {
                  without_refunds: 6180.28,
                  with_refunds: 6180.28,
                },
                gross_arr_spread: {
                  without_refunds: 6822.92,
                  with_refunds: 6822.92,
                },
                net_total: {
                  without_refunds: 14286.38,
                  with_refunds: 13897.44,
                },
                gross_total: {
                  without_refunds: 16709.71,
                  with_refunds: 16288.77,
                },
                net_total_spread: {
                  without_refunds: 20143.69,
                  with_refunds: 19929.23,
                },
                gross_total_spread: {
                  without_refunds: 23152.65,
                  with_refunds: 22911.72,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7,
                  with_refunds: 0.7024,
                },
                gross_mrr: {
                  without_refunds: 0.6529,
                  with_refunds: 0.6557,
                },
                net_arr: {
                  without_refunds: 0.0082,
                  with_refunds: 0.0041,
                },
                gross_arr: {
                  without_refunds: 0.0084,
                  with_refunds: 0.0048,
                },
                net_arr_spread: {
                  without_refunds: 2.1398,
                  with_refunds: 2.1398,
                },
                gross_arr_spread: {
                  without_refunds: 2.0512,
                  with_refunds: 2.0512,
                },
                net_total: {
                  without_refunds: 0.2408,
                  with_refunds: 0.2477,
                },
                gross_total: {
                  without_refunds: 0.2385,
                  with_refunds: 0.245,
                },
                net_total_spread: {
                  without_refunds: 0.8822,
                  with_refunds: 0.8872,
                },
                gross_total_spread: {
                  without_refunds: 0.8171,
                  with_refunds: 0.8223,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7966,
                  with_refunds: 0.7983,
                },
                gross_mrr: {
                  without_refunds: 0.7942,
                  with_refunds: 0.7959,
                },
                net_arr: {
                  without_refunds: 0.2403,
                  with_refunds: 0.9352,
                },
                gross_arr: {
                  without_refunds: 0.23,
                  with_refunds: 0.5652,
                },
                net_arr_spread: {
                  without_refunds: 1.0018,
                  with_refunds: 1.0018,
                },
                gross_arr_spread: {
                  without_refunds: 1.0022,
                  with_refunds: 1.0022,
                },
                net_total: {
                  without_refunds: 0.757,
                  with_refunds: 0.7995,
                },
                gross_total: {
                  without_refunds: 0.7522,
                  with_refunds: 0.7919,
                },
                net_total_spread: {
                  without_refunds: 0.85,
                  with_refunds: 0.8519,
                },
                gross_total_spread: {
                  without_refunds: 0.8459,
                  with_refunds: 0.8479,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.01,
                  with_refunds: 4.93,
                },
                gross_mrr: {
                  without_refunds: 5.86,
                  with_refunds: 5.77,
                },
                net_arr: {
                  without_refunds: 0.32,
                  with_refunds: 0.15,
                },
                gross_arr: {
                  without_refunds: 0.38,
                  with_refunds: 0.2,
                },
                net_arr_spread: {
                  without_refunds: 7.29,
                  with_refunds: 7.29,
                },
                gross_arr_spread: {
                  without_refunds: 8.05,
                  with_refunds: 8.05,
                },
                net_total: {
                  without_refunds: 3.83,
                  with_refunds: 3.72,
                },
                gross_total: {
                  without_refunds: 4.47,
                  with_refunds: 4.36,
                },
                net_total_spread: {
                  without_refunds: 5.6,
                  with_refunds: 5.54,
                },
                gross_total_spread: {
                  without_refunds: 6.44,
                  with_refunds: 6.37,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.3,
                  with_refunds: 11.09,
                },
                gross_mrr: {
                  without_refunds: 13.21,
                  with_refunds: 12.97,
                },
                net_arr: {
                  without_refunds: 107.66,
                  with_refunds: 29.7,
                },
                gross_arr: {
                  without_refunds: 126.66,
                  with_refunds: 39.99,
                },
                net_arr_spread: {
                  without_refunds: 7.65,
                  with_refunds: 7.65,
                },
                gross_arr_spread: {
                  without_refunds: 8.44,
                  with_refunds: 8.44,
                },
                net_total: {
                  without_refunds: 11.53,
                  with_refunds: 11.16,
                },
                gross_total: {
                  without_refunds: 13.49,
                  with_refunds: 13.08,
                },
                net_total_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.73,
                },
                gross_total_spread: {
                  without_refunds: 11.33,
                  with_refunds: 11.19,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.06,
                    with_refunds: 26.51,
                  },
                  gross_mrr: {
                    without_refunds: 32.33,
                    with_refunds: 31.68,
                  },
                  net_arr: {
                    without_refunds: 88.31,
                    with_refunds: 74.73,
                  },
                  gross_arr: {
                    without_refunds: 96.95,
                    with_refunds: 82.49,
                  },
                  net_arr_spread: {
                    without_refunds: 25.17,
                    with_refunds: 25.17,
                  },
                  gross_arr_spread: {
                    without_refunds: 27.93,
                    with_refunds: 27.93,
                  },
                  net_total: {
                    without_refunds: 43.7,
                    with_refunds: 39.68,
                  },
                  gross_total: {
                    without_refunds: 49.94,
                    with_refunds: 45.61,
                  },
                  net_total_spread: {
                    without_refunds: 26.93,
                    with_refunds: 26.5,
                  },
                  gross_total_spread: {
                    without_refunds: 31.67,
                    with_refunds: 31.16,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.55,
                    with_refunds: 43.55,
                  },
                  gross_mrr: {
                    without_refunds: 53.13,
                    with_refunds: 51.95,
                  },
                  net_arr: {
                    without_refunds: 387.55,
                    with_refunds: 192.6,
                  },
                  gross_arr: {
                    without_refunds: 446.58,
                    with_refunds: 228.03,
                  },
                  net_arr_spread: {
                    without_refunds: 30.34,
                    with_refunds: 30.34,
                  },
                  gross_arr_spread: {
                    without_refunds: 33.83,
                    with_refunds: 33.83,
                  },
                  net_total: {
                    without_refunds: 76.36,
                    with_refunds: 69.5,
                  },
                  gross_total: {
                    without_refunds: 87.71,
                    with_refunds: 80.28,
                  },
                  net_total_spread: {
                    without_refunds: 40.34,
                    with_refunds: 39.64,
                  },
                  gross_total_spread: {
                    without_refunds: 47.48,
                    with_refunds: 46.65,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2225,
                    with_refunds: 0.218,
                  },
                  gross_mrr: {
                    without_refunds: 0.2658,
                    with_refunds: 0.2605,
                  },
                  net_arr: {
                    without_refunds: 0.7261,
                    with_refunds: 0.6144,
                  },
                  gross_arr: {
                    without_refunds: 0.7971,
                    with_refunds: 0.6783,
                  },
                  net_arr_spread: {
                    without_refunds: 0.207,
                    with_refunds: 0.207,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2297,
                    with_refunds: 0.2297,
                  },
                  net_total: {
                    without_refunds: 0.3593,
                    with_refunds: 0.3263,
                  },
                  gross_total: {
                    without_refunds: 0.4106,
                    with_refunds: 0.375,
                  },
                  net_total_spread: {
                    without_refunds: 0.2214,
                    with_refunds: 0.2179,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2604,
                    with_refunds: 0.2562,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3663,
                    with_refunds: 0.3581,
                  },
                  gross_mrr: {
                    without_refunds: 0.4369,
                    with_refunds: 0.4272,
                  },
                  net_arr: {
                    without_refunds: 3.1866,
                    with_refunds: 1.5836,
                  },
                  gross_arr: {
                    without_refunds: 3.6719,
                    with_refunds: 1.8749,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2495,
                    with_refunds: 0.2495,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2782,
                    with_refunds: 0.2782,
                  },
                  net_total: {
                    without_refunds: 0.6278,
                    with_refunds: 0.5714,
                  },
                  gross_total: {
                    without_refunds: 0.7212,
                    with_refunds: 0.6601,
                  },
                  net_total_spread: {
                    without_refunds: 0.3317,
                    with_refunds: 0.326,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3904,
                    with_refunds: 0.3836,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 856,
                  with_refunds: 860,
                },
                arr_users: {
                  without_refunds: 7,
                  with_refunds: 8,
                },
                arr_spread_users: {
                  without_refunds: 814,
                  with_refunds: 814,
                },
                total_users: {
                  without_refunds: 863,
                  with_refunds: 868,
                },
                total_spread_users: {
                  without_refunds: 1670,
                  with_refunds: 1674,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3069,
                  with_refunds: 0.3084,
                },
                arr_users: {
                  without_refunds: 0.007,
                  with_refunds: 0.008,
                },
                arr_spread_users: {
                  without_refunds: 0.9599,
                  with_refunds: 0.9599,
                },
                total_users: {
                  without_refunds: 0.2311,
                  with_refunds: 0.2324,
                },
                total_spread_users: {
                  without_refunds: 0.4645,
                  with_refunds: 0.4656,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6926,
                  with_refunds: 0.6935,
                },
                arr_users: {
                  without_refunds: 2.3333,
                  with_refunds: 1.6,
                },
                arr_spread_users: {
                  without_refunds: 1.0074,
                  with_refunds: 1.0074,
                },
                total_users: {
                  without_refunds: 0.6965,
                  with_refunds: 0.6972,
                },
                total_spread_users: {
                  without_refunds: 0.817,
                  with_refunds: 0.8174,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9780.79,
                  with_refunds: 9462.04,
                },
                gross_mrr: {
                  without_refunds: 11167.62,
                  with_refunds: 10813.85,
                },
                net_arr: {
                  without_refunds: 798.51,
                  with_refunds: 576.84,
                },
                gross_arr: {
                  without_refunds: 1012.84,
                  with_refunds: 765.8,
                },
                net_arr_spread: {
                  without_refunds: 6236.34,
                  with_refunds: 6236.34,
                },
                gross_arr_spread: {
                  without_refunds: 6894.99,
                  with_refunds: 6894.99,
                },
                net_total: {
                  without_refunds: 10579.3,
                  with_refunds: 10038.89,
                },
                gross_total: {
                  without_refunds: 12180.46,
                  with_refunds: 11579.65,
                },
                net_total_spread: {
                  without_refunds: 16017.13,
                  with_refunds: 15698.38,
                },
                gross_total_spread: {
                  without_refunds: 18062.61,
                  with_refunds: 17708.84,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4904,
                  with_refunds: 0.4834,
                },
                gross_mrr: {
                  without_refunds: 0.4465,
                  with_refunds: 0.4407,
                },
                net_arr: {
                  without_refunds: 0.0203,
                  with_refunds: 0.0158,
                },
                gross_arr: {
                  without_refunds: 0.0225,
                  with_refunds: 0.0183,
                },
                net_arr_spread: {
                  without_refunds: 2.1593,
                  with_refunds: 2.1593,
                },
                gross_arr_spread: {
                  without_refunds: 2.0728,
                  with_refunds: 2.0728,
                },
                net_total: {
                  without_refunds: 0.1783,
                  with_refunds: 0.1789,
                },
                gross_total: {
                  without_refunds: 0.1738,
                  with_refunds: 0.1742,
                },
                net_total_spread: {
                  without_refunds: 0.7014,
                  with_refunds: 0.6989,
                },
                gross_total_spread: {
                  without_refunds: 0.6374,
                  with_refunds: 0.6356,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7005,
                  with_refunds: 0.6882,
                },
                gross_mrr: {
                  without_refunds: 0.6839,
                  with_refunds: 0.6721,
                },
                net_arr: {
                  without_refunds: 2.4724,
                  with_refunds: 3.8847,
                },
                gross_arr: {
                  without_refunds: 2.6656,
                  with_refunds: 3.8296,
                },
                net_arr_spread: {
                  without_refunds: 1.0091,
                  with_refunds: 1.0091,
                },
                gross_arr_spread: {
                  without_refunds: 1.0106,
                  with_refunds: 1.0106,
                },
                net_total: {
                  without_refunds: 0.7405,
                  with_refunds: 0.7224,
                },
                gross_total: {
                  without_refunds: 0.7289,
                  with_refunds: 0.7109,
                },
                net_total_spread: {
                  without_refunds: 0.7951,
                  with_refunds: 0.7877,
                },
                gross_total_spread: {
                  without_refunds: 0.7802,
                  with_refunds: 0.7729,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.51,
                  with_refunds: 3.39,
                },
                gross_mrr: {
                  without_refunds: 4,
                  with_refunds: 3.88,
                },
                net_arr: {
                  without_refunds: 0.8,
                  with_refunds: 0.58,
                },
                gross_arr: {
                  without_refunds: 1.02,
                  with_refunds: 0.77,
                },
                net_arr_spread: {
                  without_refunds: 7.35,
                  with_refunds: 7.35,
                },
                gross_arr_spread: {
                  without_refunds: 8.13,
                  with_refunds: 8.13,
                },
                net_total: {
                  without_refunds: 2.83,
                  with_refunds: 2.69,
                },
                gross_total: {
                  without_refunds: 3.26,
                  with_refunds: 3.1,
                },
                net_total_spread: {
                  without_refunds: 4.46,
                  with_refunds: 4.37,
                },
                gross_total_spread: {
                  without_refunds: 5.02,
                  with_refunds: 4.93,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.43,
                  with_refunds: 11,
                },
                gross_mrr: {
                  without_refunds: 13.05,
                  with_refunds: 12.57,
                },
                net_arr: {
                  without_refunds: 114.07,
                  with_refunds: 72.11,
                },
                gross_arr: {
                  without_refunds: 144.69,
                  with_refunds: 95.73,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.47,
                  with_refunds: 8.47,
                },
                net_total: {
                  without_refunds: 12.26,
                  with_refunds: 11.57,
                },
                gross_total: {
                  without_refunds: 14.11,
                  with_refunds: 13.34,
                },
                net_total_spread: {
                  without_refunds: 9.59,
                  with_refunds: 9.38,
                },
                gross_total_spread: {
                  without_refunds: 10.82,
                  with_refunds: 10.58,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.56,
                    with_refunds: 29.9,
                  },
                  gross_mrr: {
                    without_refunds: 36.33,
                    with_refunds: 35.56,
                  },
                  net_arr: {
                    without_refunds: 89.11,
                    with_refunds: 75.31,
                  },
                  gross_arr: {
                    without_refunds: 97.96,
                    with_refunds: 83.26,
                  },
                  net_arr_spread: {
                    without_refunds: 32.53,
                    with_refunds: 32.53,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.06,
                    with_refunds: 36.06,
                  },
                  net_total: {
                    without_refunds: 46.54,
                    with_refunds: 42.37,
                  },
                  gross_total: {
                    without_refunds: 53.2,
                    with_refunds: 48.71,
                  },
                  net_total_spread: {
                    without_refunds: 31.38,
                    with_refunds: 30.87,
                  },
                  gross_total_spread: {
                    without_refunds: 36.69,
                    with_refunds: 36.09,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 55.98,
                    with_refunds: 54.55,
                  },
                  gross_mrr: {
                    without_refunds: 66.18,
                    with_refunds: 64.53,
                  },
                  net_arr: {
                    without_refunds: 501.62,
                    with_refunds: 264.7,
                  },
                  gross_arr: {
                    without_refunds: 591.27,
                    with_refunds: 323.75,
                  },
                  net_arr_spread: {
                    without_refunds: 38.01,
                    with_refunds: 38.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.3,
                    with_refunds: 42.3,
                  },
                  net_total: {
                    without_refunds: 88.62,
                    with_refunds: 81.06,
                  },
                  gross_total: {
                    without_refunds: 101.82,
                    with_refunds: 93.62,
                  },
                  net_total_spread: {
                    without_refunds: 49.93,
                    with_refunds: 49.02,
                  },
                  gross_total_spread: {
                    without_refunds: 58.29,
                    with_refunds: 57.23,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2513,
                    with_refunds: 0.2458,
                  },
                  gross_mrr: {
                    without_refunds: 0.2987,
                    with_refunds: 0.2923,
                  },
                  net_arr: {
                    without_refunds: 0.7327,
                    with_refunds: 0.6192,
                  },
                  gross_arr: {
                    without_refunds: 0.8055,
                    with_refunds: 0.6846,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2674,
                    with_refunds: 0.2674,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2965,
                    with_refunds: 0.2965,
                  },
                  net_total: {
                    without_refunds: 0.3826,
                    with_refunds: 0.3484,
                  },
                  gross_total: {
                    without_refunds: 0.4374,
                    with_refunds: 0.4005,
                  },
                  net_total_spread: {
                    without_refunds: 0.258,
                    with_refunds: 0.2538,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3017,
                    with_refunds: 0.2967,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4603,
                    with_refunds: 0.4486,
                  },
                  gross_mrr: {
                    without_refunds: 0.5441,
                    with_refunds: 0.5306,
                  },
                  net_arr: {
                    without_refunds: 4.1245,
                    with_refunds: 2.1765,
                  },
                  gross_arr: {
                    without_refunds: 4.8616,
                    with_refunds: 2.662,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3125,
                    with_refunds: 0.3125,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3478,
                    with_refunds: 0.3478,
                  },
                  net_total: {
                    without_refunds: 0.7286,
                    with_refunds: 0.6665,
                  },
                  gross_total: {
                    without_refunds: 0.8372,
                    with_refunds: 0.7698,
                  },
                  net_total_spread: {
                    without_refunds: 0.4105,
                    with_refunds: 0.4031,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4793,
                    with_refunds: 0.4706,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 710,
                  with_refunds: 715,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 10,
                },
                arr_spread_users: {
                  without_refunds: 823,
                  with_refunds: 823,
                },
                total_users: {
                  without_refunds: 718,
                  with_refunds: 720,
                },
                total_spread_users: {
                  without_refunds: 1531,
                  with_refunds: 1534,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2546,
                  with_refunds: 0.2564,
                },
                arr_users: {
                  without_refunds: 0.0101,
                  with_refunds: 0.0101,
                },
                arr_spread_users: {
                  without_refunds: 0.9705,
                  with_refunds: 0.9705,
                },
                total_users: {
                  without_refunds: 0.1922,
                  with_refunds: 0.1928,
                },
                total_spread_users: {
                  without_refunds: 0.4259,
                  with_refunds: 0.4267,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8294,
                  with_refunds: 0.8314,
                },
                arr_users: {
                  without_refunds: 1.4286,
                  with_refunds: 1.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0111,
                  with_refunds: 1.0111,
                },
                total_users: {
                  without_refunds: 0.832,
                  with_refunds: 0.8295,
                },
                total_spread_users: {
                  without_refunds: 0.9168,
                  with_refunds: 0.9164,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8178.96,
                  with_refunds: 8049.85,
                },
                gross_mrr: {
                  without_refunds: 9315.73,
                  with_refunds: 9158.99,
                },
                net_arr: {
                  without_refunds: 1172.51,
                  with_refunds: 1053.52,
                },
                gross_arr: {
                  without_refunds: 1482.74,
                  with_refunds: 1342.75,
                },
                net_arr_spread: {
                  without_refunds: 6324.13,
                  with_refunds: 6324.13,
                },
                gross_arr_spread: {
                  without_refunds: 7006.88,
                  with_refunds: 7006.88,
                },
                net_total: {
                  without_refunds: 9351.47,
                  with_refunds: 9103.36,
                },
                gross_total: {
                  without_refunds: 10798.47,
                  with_refunds: 10501.74,
                },
                net_total_spread: {
                  without_refunds: 14503.09,
                  with_refunds: 14373.98,
                },
                gross_total_spread: {
                  without_refunds: 16322.61,
                  with_refunds: 16165.87,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.41,
                  with_refunds: 0.4113,
                },
                gross_mrr: {
                  without_refunds: 0.3725,
                  with_refunds: 0.3733,
                },
                net_arr: {
                  without_refunds: 0.0298,
                  with_refunds: 0.0288,
                },
                gross_arr: {
                  without_refunds: 0.0329,
                  with_refunds: 0.032,
                },
                net_arr_spread: {
                  without_refunds: 2.1897,
                  with_refunds: 2.1897,
                },
                gross_arr_spread: {
                  without_refunds: 2.1065,
                  with_refunds: 2.1065,
                },
                net_total: {
                  without_refunds: 0.1576,
                  with_refunds: 0.1622,
                },
                gross_total: {
                  without_refunds: 0.1541,
                  with_refunds: 0.158,
                },
                net_total_spread: {
                  without_refunds: 0.6351,
                  with_refunds: 0.6399,
                },
                gross_total_spread: {
                  without_refunds: 0.576,
                  with_refunds: 0.5802,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8362,
                  with_refunds: 0.8508,
                },
                gross_mrr: {
                  without_refunds: 0.8342,
                  with_refunds: 0.847,
                },
                net_arr: {
                  without_refunds: 1.4684,
                  with_refunds: 1.8263,
                },
                gross_arr: {
                  without_refunds: 1.4639,
                  with_refunds: 1.7534,
                },
                net_arr_spread: {
                  without_refunds: 1.0141,
                  with_refunds: 1.0141,
                },
                gross_arr_spread: {
                  without_refunds: 1.0162,
                  with_refunds: 1.0162,
                },
                net_total: {
                  without_refunds: 0.8839,
                  with_refunds: 0.9068,
                },
                gross_total: {
                  without_refunds: 0.8865,
                  with_refunds: 0.9069,
                },
                net_total_spread: {
                  without_refunds: 0.9055,
                  with_refunds: 0.9156,
                },
                gross_total_spread: {
                  without_refunds: 0.9037,
                  with_refunds: 0.9129,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.93,
                  with_refunds: 2.89,
                },
                gross_mrr: {
                  without_refunds: 3.34,
                  with_refunds: 3.28,
                },
                net_arr: {
                  without_refunds: 1.18,
                  with_refunds: 1.06,
                },
                gross_arr: {
                  without_refunds: 1.49,
                  with_refunds: 1.35,
                },
                net_arr_spread: {
                  without_refunds: 7.46,
                  with_refunds: 7.46,
                },
                gross_arr_spread: {
                  without_refunds: 8.26,
                  with_refunds: 8.26,
                },
                net_total: {
                  without_refunds: 2.5,
                  with_refunds: 2.44,
                },
                gross_total: {
                  without_refunds: 2.89,
                  with_refunds: 2.81,
                },
                net_total_spread: {
                  without_refunds: 4.03,
                  with_refunds: 4,
                },
                gross_total_spread: {
                  without_refunds: 4.54,
                  with_refunds: 4.5,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.52,
                  with_refunds: 11.26,
                },
                gross_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 12.81,
                },
                net_arr: {
                  without_refunds: 117.25,
                  with_refunds: 105.35,
                },
                gross_arr: {
                  without_refunds: 148.27,
                  with_refunds: 134.27,
                },
                net_arr_spread: {
                  without_refunds: 7.68,
                  with_refunds: 7.68,
                },
                gross_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                net_total: {
                  without_refunds: 13.02,
                  with_refunds: 12.64,
                },
                gross_total: {
                  without_refunds: 15.04,
                  with_refunds: 14.59,
                },
                net_total_spread: {
                  without_refunds: 9.47,
                  with_refunds: 9.37,
                },
                gross_total_spread: {
                  without_refunds: 10.66,
                  with_refunds: 10.54,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.5,
                    with_refunds: 32.79,
                  },
                  gross_mrr: {
                    without_refunds: 39.67,
                    with_refunds: 38.84,
                  },
                  net_arr: {
                    without_refunds: 90.29,
                    with_refunds: 76.37,
                  },
                  gross_arr: {
                    without_refunds: 99.46,
                    with_refunds: 84.61,
                  },
                  net_arr_spread: {
                    without_refunds: 39.98,
                    with_refunds: 39.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 44.33,
                    with_refunds: 44.33,
                  },
                  net_total: {
                    without_refunds: 49.04,
                    with_refunds: 44.81,
                  },
                  gross_total: {
                    without_refunds: 56.09,
                    with_refunds: 51.52,
                  },
                  net_total_spread: {
                    without_refunds: 35.42,
                    with_refunds: 34.87,
                  },
                  gross_total_spread: {
                    without_refunds: 41.23,
                    with_refunds: 40.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 67.5,
                    with_refunds: 65.81,
                  },
                  gross_mrr: {
                    without_refunds: 79.3,
                    with_refunds: 77.34,
                  },
                  net_arr: {
                    without_refunds: 618.87,
                    with_refunds: 370.05,
                  },
                  gross_arr: {
                    without_refunds: 739.54,
                    with_refunds: 458.03,
                  },
                  net_arr_spread: {
                    without_refunds: 45.69,
                    with_refunds: 45.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 50.82,
                    with_refunds: 50.82,
                  },
                  net_total: {
                    without_refunds: 101.64,
                    with_refunds: 93.71,
                  },
                  gross_total: {
                    without_refunds: 116.86,
                    with_refunds: 108.21,
                  },
                  net_total_spread: {
                    without_refunds: 59.4,
                    with_refunds: 58.39,
                  },
                  gross_total_spread: {
                    without_refunds: 68.95,
                    with_refunds: 67.77,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2754,
                    with_refunds: 0.2696,
                  },
                  gross_mrr: {
                    without_refunds: 0.3262,
                    with_refunds: 0.3193,
                  },
                  net_arr: {
                    without_refunds: 0.7424,
                    with_refunds: 0.6279,
                  },
                  gross_arr: {
                    without_refunds: 0.8178,
                    with_refunds: 0.6957,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3288,
                    with_refunds: 0.3288,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3645,
                    with_refunds: 0.3645,
                  },
                  net_total: {
                    without_refunds: 0.4032,
                    with_refunds: 0.3684,
                  },
                  gross_total: {
                    without_refunds: 0.4612,
                    with_refunds: 0.4236,
                  },
                  net_total_spread: {
                    without_refunds: 0.2912,
                    with_refunds: 0.2867,
                  },
                  gross_total_spread: {
                    without_refunds: 0.339,
                    with_refunds: 0.3337,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.555,
                    with_refunds: 0.5411,
                  },
                  gross_mrr: {
                    without_refunds: 0.652,
                    with_refunds: 0.6359,
                  },
                  net_arr: {
                    without_refunds: 5.0886,
                    with_refunds: 3.0427,
                  },
                  gross_arr: {
                    without_refunds: 6.0808,
                    with_refunds: 3.7661,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3757,
                    with_refunds: 0.3757,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4178,
                    with_refunds: 0.4178,
                  },
                  net_total: {
                    without_refunds: 0.8357,
                    with_refunds: 0.7705,
                  },
                  gross_total: {
                    without_refunds: 0.9609,
                    with_refunds: 0.8897,
                  },
                  net_total_spread: {
                    without_refunds: 0.4884,
                    with_refunds: 0.4801,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5669,
                    with_refunds: 0.5572,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 624,
                  with_refunds: 629,
                },
                arr_users: {
                  without_refunds: 15,
                  with_refunds: 15,
                },
                arr_spread_users: {
                  without_refunds: 834,
                  with_refunds: 834,
                },
                total_users: {
                  without_refunds: 638,
                  with_refunds: 639,
                },
                total_spread_users: {
                  without_refunds: 1457,
                  with_refunds: 1458,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2237,
                  with_refunds: 0.2255,
                },
                arr_users: {
                  without_refunds: 0.0151,
                  with_refunds: 0.0151,
                },
                arr_spread_users: {
                  without_refunds: 0.9835,
                  with_refunds: 0.9835,
                },
                total_users: {
                  without_refunds: 0.1708,
                  with_refunds: 0.1711,
                },
                total_spread_users: {
                  without_refunds: 0.4053,
                  with_refunds: 0.4056,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8789,
                  with_refunds: 0.8797,
                },
                arr_users: {
                  without_refunds: 1.5,
                  with_refunds: 1.5,
                },
                arr_spread_users: {
                  without_refunds: 1.0134,
                  with_refunds: 1.0134,
                },
                total_users: {
                  without_refunds: 0.8886,
                  with_refunds: 0.8875,
                },
                total_spread_users: {
                  without_refunds: 0.9517,
                  with_refunds: 0.9505,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7165.42,
                  with_refunds: 7032.43,
                },
                gross_mrr: {
                  without_refunds: 8114.67,
                  with_refunds: 7967.38,
                },
                net_arr: {
                  without_refunds: 1727.36,
                  with_refunds: 1383.48,
                },
                gross_arr: {
                  without_refunds: 2230.8,
                  with_refunds: 1770.75,
                },
                net_arr_spread: {
                  without_refunds: 6434.01,
                  with_refunds: 6434.01,
                },
                gross_arr_spread: {
                  without_refunds: 7145.56,
                  with_refunds: 7145.56,
                },
                net_total: {
                  without_refunds: 8892.79,
                  with_refunds: 8415.91,
                },
                gross_total: {
                  without_refunds: 10345.47,
                  with_refunds: 9738.13,
                },
                net_total_spread: {
                  without_refunds: 13599.44,
                  with_refunds: 13466.45,
                },
                gross_total_spread: {
                  without_refunds: 15260.23,
                  with_refunds: 15112.94,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3592,
                  with_refunds: 0.3593,
                },
                gross_mrr: {
                  without_refunds: 0.3245,
                  with_refunds: 0.3247,
                },
                net_arr: {
                  without_refunds: 0.0439,
                  with_refunds: 0.0379,
                },
                gross_arr: {
                  without_refunds: 0.0495,
                  with_refunds: 0.0422,
                },
                net_arr_spread: {
                  without_refunds: 2.2277,
                  with_refunds: 2.2277,
                },
                gross_arr_spread: {
                  without_refunds: 2.1482,
                  with_refunds: 2.1482,
                },
                net_total: {
                  without_refunds: 0.1499,
                  with_refunds: 0.15,
                },
                gross_total: {
                  without_refunds: 0.1476,
                  with_refunds: 0.1465,
                },
                net_total_spread: {
                  without_refunds: 0.5956,
                  with_refunds: 0.5995,
                },
                gross_total_spread: {
                  without_refunds: 0.5385,
                  with_refunds: 0.5424,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8761,
                  with_refunds: 0.8736,
                },
                gross_mrr: {
                  without_refunds: 0.8711,
                  with_refunds: 0.8699,
                },
                net_arr: {
                  without_refunds: 1.4732,
                  with_refunds: 1.3132,
                },
                gross_arr: {
                  without_refunds: 1.5045,
                  with_refunds: 1.3187,
                },
                net_arr_spread: {
                  without_refunds: 1.0174,
                  with_refunds: 1.0174,
                },
                gross_arr_spread: {
                  without_refunds: 1.0198,
                  with_refunds: 1.0198,
                },
                net_total: {
                  without_refunds: 0.951,
                  with_refunds: 0.9245,
                },
                gross_total: {
                  without_refunds: 0.958,
                  with_refunds: 0.9273,
                },
                net_total_spread: {
                  without_refunds: 0.9377,
                  with_refunds: 0.9369,
                },
                gross_total_spread: {
                  without_refunds: 0.9349,
                  with_refunds: 0.9349,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.57,
                  with_refunds: 2.52,
                },
                gross_mrr: {
                  without_refunds: 2.91,
                  with_refunds: 2.86,
                },
                net_arr: {
                  without_refunds: 1.74,
                  with_refunds: 1.39,
                },
                gross_arr: {
                  without_refunds: 2.24,
                  with_refunds: 1.78,
                },
                net_arr_spread: {
                  without_refunds: 7.59,
                  with_refunds: 7.59,
                },
                gross_arr_spread: {
                  without_refunds: 8.43,
                  with_refunds: 8.43,
                },
                net_total: {
                  without_refunds: 2.38,
                  with_refunds: 2.25,
                },
                gross_total: {
                  without_refunds: 2.77,
                  with_refunds: 2.61,
                },
                net_total_spread: {
                  without_refunds: 3.78,
                  with_refunds: 3.75,
                },
                gross_total_spread: {
                  without_refunds: 4.24,
                  with_refunds: 4.2,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.48,
                  with_refunds: 11.18,
                },
                gross_mrr: {
                  without_refunds: 13,
                  with_refunds: 12.67,
                },
                net_arr: {
                  without_refunds: 115.16,
                  with_refunds: 92.23,
                },
                gross_arr: {
                  without_refunds: 148.72,
                  with_refunds: 118.05,
                },
                net_arr_spread: {
                  without_refunds: 7.71,
                  with_refunds: 7.71,
                },
                gross_arr_spread: {
                  without_refunds: 8.57,
                  with_refunds: 8.57,
                },
                net_total: {
                  without_refunds: 13.94,
                  with_refunds: 13.17,
                },
                gross_total: {
                  without_refunds: 16.22,
                  with_refunds: 15.24,
                },
                net_total_spread: {
                  without_refunds: 9.33,
                  with_refunds: 9.24,
                },
                gross_total_spread: {
                  without_refunds: 10.47,
                  with_refunds: 10.37,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.06,
                    with_refunds: 35.31,
                  },
                  gross_mrr: {
                    without_refunds: 42.58,
                    with_refunds: 41.7,
                  },
                  net_arr: {
                    without_refunds: 92.03,
                    with_refunds: 77.76,
                  },
                  gross_arr: {
                    without_refunds: 101.7,
                    with_refunds: 86.39,
                  },
                  net_arr_spread: {
                    without_refunds: 47.57,
                    with_refunds: 47.57,
                  },
                  gross_arr_spread: {
                    without_refunds: 52.75,
                    with_refunds: 52.75,
                  },
                  net_total: {
                    without_refunds: 51.42,
                    with_refunds: 47.06,
                  },
                  gross_total: {
                    without_refunds: 58.86,
                    with_refunds: 54.13,
                  },
                  net_total_spread: {
                    without_refunds: 39.2,
                    with_refunds: 38.61,
                  },
                  gross_total_spread: {
                    without_refunds: 45.48,
                    with_refunds: 44.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 78.98,
                    with_refunds: 76.99,
                  },
                  gross_mrr: {
                    without_refunds: 92.3,
                    with_refunds: 90,
                  },
                  net_arr: {
                    without_refunds: 734.03,
                    with_refunds: 462.29,
                  },
                  gross_arr: {
                    without_refunds: 888.26,
                    with_refunds: 576.08,
                  },
                  net_arr_spread: {
                    without_refunds: 53.4,
                    with_refunds: 53.4,
                  },
                  gross_arr_spread: {
                    without_refunds: 59.38,
                    with_refunds: 59.38,
                  },
                  net_total: {
                    without_refunds: 115.58,
                    with_refunds: 106.88,
                  },
                  gross_total: {
                    without_refunds: 133.08,
                    with_refunds: 123.45,
                  },
                  net_total_spread: {
                    without_refunds: 68.73,
                    with_refunds: 67.63,
                  },
                  gross_total_spread: {
                    without_refunds: 79.43,
                    with_refunds: 78.14,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2965,
                    with_refunds: 0.2903,
                  },
                  gross_mrr: {
                    without_refunds: 0.3501,
                    with_refunds: 0.3428,
                  },
                  net_arr: {
                    without_refunds: 0.7567,
                    with_refunds: 0.6394,
                  },
                  gross_arr: {
                    without_refunds: 0.8362,
                    with_refunds: 0.7104,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3911,
                    with_refunds: 0.3911,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4337,
                    with_refunds: 0.4337,
                  },
                  net_total: {
                    without_refunds: 0.4228,
                    with_refunds: 0.3869,
                  },
                  gross_total: {
                    without_refunds: 0.484,
                    with_refunds: 0.4451,
                  },
                  net_total_spread: {
                    without_refunds: 0.3223,
                    with_refunds: 0.3175,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3739,
                    with_refunds: 0.3683,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6494,
                    with_refunds: 0.6331,
                  },
                  gross_mrr: {
                    without_refunds: 0.7589,
                    with_refunds: 0.74,
                  },
                  net_arr: {
                    without_refunds: 6.0354,
                    with_refunds: 3.8011,
                  },
                  gross_arr: {
                    without_refunds: 7.3036,
                    with_refunds: 4.7367,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4391,
                    with_refunds: 0.4391,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4883,
                    with_refunds: 0.4883,
                  },
                  net_total: {
                    without_refunds: 0.9503,
                    with_refunds: 0.8788,
                  },
                  gross_total: {
                    without_refunds: 1.0942,
                    with_refunds: 1.015,
                  },
                  net_total_spread: {
                    without_refunds: 0.5652,
                    with_refunds: 0.556,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6531,
                    with_refunds: 0.6425,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 561,
                  with_refunds: 562,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 839,
                  with_refunds: 839,
                },
                total_users: {
                  without_refunds: 567,
                  with_refunds: 568,
                },
                total_spread_users: {
                  without_refunds: 1400,
                  with_refunds: 1401,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2011,
                  with_refunds: 0.2015,
                },
                arr_users: {
                  without_refunds: 0.006,
                  with_refunds: 0.006,
                },
                arr_spread_users: {
                  without_refunds: 0.9894,
                  with_refunds: 0.9894,
                },
                total_users: {
                  without_refunds: 0.1518,
                  with_refunds: 0.1521,
                },
                total_spread_users: {
                  without_refunds: 0.3894,
                  with_refunds: 0.3897,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.899,
                  with_refunds: 0.8935,
                },
                arr_users: {
                  without_refunds: 0.4,
                  with_refunds: 0.4,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8887,
                  with_refunds: 0.8889,
                },
                total_spread_users: {
                  without_refunds: 0.9609,
                  with_refunds: 0.9609,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6384.23,
                  with_refunds: 6338.83,
                },
                gross_mrr: {
                  without_refunds: 7236.79,
                  with_refunds: 7188.79,
                },
                net_arr: {
                  without_refunds: 677.93,
                  with_refunds: 563.32,
                },
                gross_arr: {
                  without_refunds: 825.88,
                  with_refunds: 676.2,
                },
                net_arr_spread: {
                  without_refunds: 6480.96,
                  with_refunds: 6480.96,
                },
                gross_arr_spread: {
                  without_refunds: 7201.91,
                  with_refunds: 7201.91,
                },
                net_total: {
                  without_refunds: 7062.16,
                  with_refunds: 6902.14,
                },
                gross_total: {
                  without_refunds: 8062.67,
                  with_refunds: 7865,
                },
                net_total_spread: {
                  without_refunds: 12865.18,
                  with_refunds: 12819.78,
                },
                gross_total_spread: {
                  without_refunds: 14438.7,
                  with_refunds: 14390.7,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3201,
                  with_refunds: 0.3238,
                },
                gross_mrr: {
                  without_refunds: 0.2894,
                  with_refunds: 0.293,
                },
                net_arr: {
                  without_refunds: 0.0172,
                  with_refunds: 0.0154,
                },
                gross_arr: {
                  without_refunds: 0.0183,
                  with_refunds: 0.0161,
                },
                net_arr_spread: {
                  without_refunds: 2.2439,
                  with_refunds: 2.2439,
                },
                gross_arr_spread: {
                  without_refunds: 2.1651,
                  with_refunds: 2.1651,
                },
                net_total: {
                  without_refunds: 0.119,
                  with_refunds: 0.123,
                },
                gross_total: {
                  without_refunds: 0.1151,
                  with_refunds: 0.1183,
                },
                net_total_spread: {
                  without_refunds: 0.5634,
                  with_refunds: 0.5707,
                },
                gross_total_spread: {
                  without_refunds: 0.5096,
                  with_refunds: 0.5165,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.891,
                  with_refunds: 0.9014,
                },
                gross_mrr: {
                  without_refunds: 0.8918,
                  with_refunds: 0.9023,
                },
                net_arr: {
                  without_refunds: 0.3925,
                  with_refunds: 0.4072,
                },
                gross_arr: {
                  without_refunds: 0.3702,
                  with_refunds: 0.3819,
                },
                net_arr_spread: {
                  without_refunds: 1.0073,
                  with_refunds: 1.0073,
                },
                gross_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                net_total: {
                  without_refunds: 0.7941,
                  with_refunds: 0.8201,
                },
                gross_total: {
                  without_refunds: 0.7793,
                  with_refunds: 0.8076,
                },
                net_total_spread: {
                  without_refunds: 0.946,
                  with_refunds: 0.952,
                },
                gross_total_spread: {
                  without_refunds: 0.9462,
                  with_refunds: 0.9522,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.29,
                  with_refunds: 2.27,
                },
                gross_mrr: {
                  without_refunds: 2.59,
                  with_refunds: 2.58,
                },
                net_arr: {
                  without_refunds: 0.68,
                  with_refunds: 0.57,
                },
                gross_arr: {
                  without_refunds: 0.83,
                  with_refunds: 0.68,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.49,
                  with_refunds: 8.49,
                },
                net_total: {
                  without_refunds: 1.89,
                  with_refunds: 1.85,
                },
                gross_total: {
                  without_refunds: 2.16,
                  with_refunds: 2.11,
                },
                net_total_spread: {
                  without_refunds: 3.58,
                  with_refunds: 3.57,
                },
                gross_total_spread: {
                  without_refunds: 4.02,
                  with_refunds: 4,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.38,
                  with_refunds: 11.28,
                },
                gross_mrr: {
                  without_refunds: 12.9,
                  with_refunds: 12.79,
                },
                net_arr: {
                  without_refunds: 112.99,
                  with_refunds: 93.89,
                },
                gross_arr: {
                  without_refunds: 137.65,
                  with_refunds: 112.7,
                },
                net_arr_spread: {
                  without_refunds: 7.72,
                  with_refunds: 7.72,
                },
                gross_arr_spread: {
                  without_refunds: 8.58,
                  with_refunds: 8.58,
                },
                net_total: {
                  without_refunds: 12.46,
                  with_refunds: 12.15,
                },
                gross_total: {
                  without_refunds: 14.22,
                  with_refunds: 13.85,
                },
                net_total_spread: {
                  without_refunds: 9.19,
                  with_refunds: 9.15,
                },
                gross_total_spread: {
                  without_refunds: 10.31,
                  with_refunds: 10.27,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.35,
                    with_refunds: 37.58,
                  },
                  gross_mrr: {
                    without_refunds: 45.18,
                    with_refunds: 44.27,
                  },
                  net_arr: {
                    without_refunds: 92.71,
                    with_refunds: 78.33,
                  },
                  gross_arr: {
                    without_refunds: 102.53,
                    with_refunds: 87.07,
                  },
                  net_arr_spread: {
                    without_refunds: 55.21,
                    with_refunds: 55.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 61.24,
                    with_refunds: 61.24,
                  },
                  net_total: {
                    without_refunds: 53.31,
                    with_refunds: 48.91,
                  },
                  gross_total: {
                    without_refunds: 61.02,
                    with_refunds: 56.23,
                  },
                  net_total_spread: {
                    without_refunds: 42.78,
                    with_refunds: 42.18,
                  },
                  gross_total_spread: {
                    without_refunds: 49.49,
                    with_refunds: 48.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 90.36,
                    with_refunds: 88.27,
                  },
                  gross_mrr: {
                    without_refunds: 105.2,
                    with_refunds: 102.79,
                  },
                  net_arr: {
                    without_refunds: 847.02,
                    with_refunds: 556.17,
                  },
                  gross_arr: {
                    without_refunds: 1025.91,
                    with_refunds: 688.78,
                  },
                  net_arr_spread: {
                    without_refunds: 61.13,
                    with_refunds: 61.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 67.97,
                    with_refunds: 67.97,
                  },
                  net_total: {
                    without_refunds: 128.04,
                    with_refunds: 119.03,
                  },
                  gross_total: {
                    without_refunds: 147.3,
                    with_refunds: 137.29,
                  },
                  net_total_spread: {
                    without_refunds: 77.92,
                    with_refunds: 76.78,
                  },
                  gross_total_spread: {
                    without_refunds: 89.74,
                    with_refunds: 88.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3154,
                    with_refunds: 0.309,
                  },
                  gross_mrr: {
                    without_refunds: 0.3715,
                    with_refunds: 0.364,
                  },
                  net_arr: {
                    without_refunds: 0.7623,
                    with_refunds: 0.644,
                  },
                  gross_arr: {
                    without_refunds: 0.843,
                    with_refunds: 0.7159,
                  },
                  net_arr_spread: {
                    without_refunds: 0.454,
                    with_refunds: 0.454,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5036,
                    with_refunds: 0.5036,
                  },
                  net_total: {
                    without_refunds: 0.4383,
                    with_refunds: 0.4021,
                  },
                  gross_total: {
                    without_refunds: 0.5017,
                    with_refunds: 0.4624,
                  },
                  net_total_spread: {
                    without_refunds: 0.3517,
                    with_refunds: 0.3468,
                  },
                  gross_total_spread: {
                    without_refunds: 0.407,
                    with_refunds: 0.4012,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.743,
                    with_refunds: 0.7258,
                  },
                  gross_mrr: {
                    without_refunds: 0.865,
                    with_refunds: 0.8452,
                  },
                  net_arr: {
                    without_refunds: 6.9645,
                    with_refunds: 4.573,
                  },
                  gross_arr: {
                    without_refunds: 8.4354,
                    with_refunds: 5.6634,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5026,
                    with_refunds: 0.5026,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5588,
                    with_refunds: 0.5588,
                  },
                  net_total: {
                    without_refunds: 1.0527,
                    with_refunds: 0.9787,
                  },
                  gross_total: {
                    without_refunds: 1.2111,
                    with_refunds: 1.1289,
                  },
                  net_total_spread: {
                    without_refunds: 0.6407,
                    with_refunds: 0.6313,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7379,
                    with_refunds: 0.7269,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 488,
                  with_refunds: 489,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 844,
                  with_refunds: 844,
                },
                total_users: {
                  without_refunds: 493,
                  with_refunds: 494,
                },
                total_spread_users: {
                  without_refunds: 1332,
                  with_refunds: 1333,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.175,
                  with_refunds: 0.1753,
                },
                arr_users: {
                  without_refunds: 0.005,
                  with_refunds: 0.005,
                },
                arr_spread_users: {
                  without_refunds: 0.9953,
                  with_refunds: 0.9953,
                },
                total_users: {
                  without_refunds: 0.132,
                  with_refunds: 0.1323,
                },
                total_spread_users: {
                  without_refunds: 0.3705,
                  with_refunds: 0.3708,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8699,
                  with_refunds: 0.8701,
                },
                arr_users: {
                  without_refunds: 0.8333,
                  with_refunds: 0.8333,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8695,
                  with_refunds: 0.8697,
                },
                total_spread_users: {
                  without_refunds: 0.9514,
                  with_refunds: 0.9515,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5660.65,
                  with_refunds: 5631.26,
                },
                gross_mrr: {
                  without_refunds: 6403.03,
                  with_refunds: 6369.8,
                },
                net_arr: {
                  without_refunds: 579.17,
                  with_refunds: 554.87,
                },
                gross_arr: {
                  without_refunds: 706.03,
                  with_refunds: 666.12,
                },
                net_arr_spread: {
                  without_refunds: 6529.22,
                  with_refunds: 6529.22,
                },
                gross_arr_spread: {
                  without_refunds: 7260.75,
                  with_refunds: 7260.75,
                },
                net_total: {
                  without_refunds: 6239.82,
                  with_refunds: 6186.13,
                },
                gross_total: {
                  without_refunds: 7109.06,
                  with_refunds: 7035.92,
                },
                net_total_spread: {
                  without_refunds: 12189.87,
                  with_refunds: 12160.48,
                },
                gross_total_spread: {
                  without_refunds: 13663.77,
                  with_refunds: 13630.54,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2838,
                  with_refunds: 0.2877,
                },
                gross_mrr: {
                  without_refunds: 0.256,
                  with_refunds: 0.2596,
                },
                net_arr: {
                  without_refunds: 0.0147,
                  with_refunds: 0.0152,
                },
                gross_arr: {
                  without_refunds: 0.0157,
                  with_refunds: 0.0159,
                },
                net_arr_spread: {
                  without_refunds: 2.2607,
                  with_refunds: 2.2607,
                },
                gross_arr_spread: {
                  without_refunds: 2.1828,
                  with_refunds: 2.1828,
                },
                net_total: {
                  without_refunds: 0.1052,
                  with_refunds: 0.1102,
                },
                gross_total: {
                  without_refunds: 0.1015,
                  with_refunds: 0.1058,
                },
                net_total_spread: {
                  without_refunds: 0.5338,
                  with_refunds: 0.5414,
                },
                gross_total_spread: {
                  without_refunds: 0.4822,
                  with_refunds: 0.4892,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8867,
                  with_refunds: 0.8884,
                },
                gross_mrr: {
                  without_refunds: 0.8848,
                  with_refunds: 0.8861,
                },
                net_arr: {
                  without_refunds: 0.8543,
                  with_refunds: 0.985,
                },
                gross_arr: {
                  without_refunds: 0.8549,
                  with_refunds: 0.9851,
                },
                net_arr_spread: {
                  without_refunds: 1.0074,
                  with_refunds: 1.0074,
                },
                gross_arr_spread: {
                  without_refunds: 1.0082,
                  with_refunds: 1.0082,
                },
                net_total: {
                  without_refunds: 0.8836,
                  with_refunds: 0.8963,
                },
                gross_total: {
                  without_refunds: 0.8817,
                  with_refunds: 0.8946,
                },
                net_total_spread: {
                  without_refunds: 0.9475,
                  with_refunds: 0.9486,
                },
                gross_total_spread: {
                  without_refunds: 0.9463,
                  with_refunds: 0.9472,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.03,
                  with_refunds: 2.02,
                },
                gross_mrr: {
                  without_refunds: 2.3,
                  with_refunds: 2.28,
                },
                net_arr: {
                  without_refunds: 0.58,
                  with_refunds: 0.56,
                },
                gross_arr: {
                  without_refunds: 0.71,
                  with_refunds: 0.67,
                },
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.56,
                  with_refunds: 8.56,
                },
                net_total: {
                  without_refunds: 1.67,
                  with_refunds: 1.66,
                },
                gross_total: {
                  without_refunds: 1.9,
                  with_refunds: 1.88,
                },
                net_total_spread: {
                  without_refunds: 3.39,
                  with_refunds: 3.38,
                },
                gross_total_spread: {
                  without_refunds: 3.8,
                  with_refunds: 3.79,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.6,
                  with_refunds: 11.52,
                },
                gross_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 13.03,
                },
                net_arr: {
                  without_refunds: 115.83,
                  with_refunds: 110.97,
                },
                gross_arr: {
                  without_refunds: 141.21,
                  with_refunds: 133.22,
                },
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 12.66,
                  with_refunds: 12.52,
                },
                gross_total: {
                  without_refunds: 14.42,
                  with_refunds: 14.24,
                },
                net_total_spread: {
                  without_refunds: 9.15,
                  with_refunds: 9.12,
                },
                gross_total_spread: {
                  without_refunds: 10.26,
                  with_refunds: 10.23,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.38,
                    with_refunds: 39.6,
                  },
                  gross_mrr: {
                    without_refunds: 47.47,
                    with_refunds: 46.56,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 62.91,
                    with_refunds: 62.91,
                  },
                  gross_arr_spread: {
                    without_refunds: 69.81,
                    with_refunds: 69.81,
                  },
                  net_total: {
                    without_refunds: 54.98,
                    with_refunds: 50.56,
                  },
                  gross_total: {
                    without_refunds: 62.92,
                    with_refunds: 58.12,
                  },
                  net_total_spread: {
                    without_refunds: 46.17,
                    with_refunds: 45.56,
                  },
                  gross_total_spread: {
                    without_refunds: 53.3,
                    with_refunds: 52.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 101.96,
                    with_refunds: 99.79,
                  },
                  gross_mrr: {
                    without_refunds: 118.32,
                    with_refunds: 115.82,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 68.86,
                    with_refunds: 68.86,
                  },
                  gross_arr_spread: {
                    without_refunds: 76.57,
                    with_refunds: 76.57,
                  },
                  net_total: {
                    without_refunds: 140.69,
                    with_refunds: 131.55,
                  },
                  gross_total: {
                    without_refunds: 161.72,
                    with_refunds: 151.54,
                  },
                  net_total_spread: {
                    without_refunds: 87.08,
                    with_refunds: 85.9,
                  },
                  gross_total_spread: {
                    without_refunds: 100,
                    with_refunds: 98.63,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.332,
                    with_refunds: 0.3256,
                  },
                  gross_mrr: {
                    without_refunds: 0.3903,
                    with_refunds: 0.3828,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5173,
                    with_refunds: 0.5173,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.574,
                    with_refunds: 0.574,
                  },
                  net_total: {
                    without_refunds: 0.4521,
                    with_refunds: 0.4158,
                  },
                  gross_total: {
                    without_refunds: 0.5174,
                    with_refunds: 0.4779,
                  },
                  net_total_spread: {
                    without_refunds: 0.3796,
                    with_refunds: 0.3746,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4382,
                    with_refunds: 0.4324,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8383,
                    with_refunds: 0.8205,
                  },
                  gross_mrr: {
                    without_refunds: 0.9729,
                    with_refunds: 0.9523,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5662,
                    with_refunds: 0.5662,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6296,
                    with_refunds: 0.6296,
                  },
                  net_total: {
                    without_refunds: 1.1568,
                    with_refunds: 1.0816,
                  },
                  gross_total: {
                    without_refunds: 1.3297,
                    with_refunds: 1.246,
                  },
                  net_total_spread: {
                    without_refunds: 0.716,
                    with_refunds: 0.7063,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8222,
                    with_refunds: 0.811,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 415,
                  with_refunds: 416,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 844,
                  with_refunds: 844,
                },
                total_users: {
                  without_refunds: 415,
                  with_refunds: 416,
                },
                total_spread_users: {
                  without_refunds: 1259,
                  with_refunds: 1260,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1488,
                  with_refunds: 0.1492,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9953,
                  with_refunds: 0.9953,
                },
                total_users: {
                  without_refunds: 0.1111,
                  with_refunds: 0.1114,
                },
                total_spread_users: {
                  without_refunds: 0.3502,
                  with_refunds: 0.3505,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8504,
                  with_refunds: 0.8507,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8418,
                  with_refunds: 0.8421,
                },
                total_spread_users: {
                  without_refunds: 0.9452,
                  with_refunds: 0.9452,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4776.67,
                  with_refunds: 4759.68,
                },
                gross_mrr: {
                  without_refunds: 5380.26,
                  with_refunds: 5360.27,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6529.22,
                  with_refunds: 6529.22,
                },
                gross_arr_spread: {
                  without_refunds: 7260.75,
                  with_refunds: 7260.75,
                },
                net_total: {
                  without_refunds: 4776.67,
                  with_refunds: 4759.68,
                },
                gross_total: {
                  without_refunds: 5380.26,
                  with_refunds: 5360.27,
                },
                net_total_spread: {
                  without_refunds: 11305.89,
                  with_refunds: 11288.9,
                },
                gross_total_spread: {
                  without_refunds: 12641.01,
                  with_refunds: 12621.02,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2395,
                  with_refunds: 0.2432,
                },
                gross_mrr: {
                  without_refunds: 0.2151,
                  with_refunds: 0.2185,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.2607,
                  with_refunds: 2.2607,
                },
                gross_arr_spread: {
                  without_refunds: 2.1828,
                  with_refunds: 2.1828,
                },
                net_total: {
                  without_refunds: 0.0805,
                  with_refunds: 0.0848,
                },
                gross_total: {
                  without_refunds: 0.0768,
                  with_refunds: 0.0806,
                },
                net_total_spread: {
                  without_refunds: 0.4951,
                  with_refunds: 0.5026,
                },
                gross_total_spread: {
                  without_refunds: 0.4461,
                  with_refunds: 0.453,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8438,
                  with_refunds: 0.8452,
                },
                gross_mrr: {
                  without_refunds: 0.8403,
                  with_refunds: 0.8415,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.7655,
                  with_refunds: 0.7694,
                },
                gross_total: {
                  without_refunds: 0.7568,
                  with_refunds: 0.7618,
                },
                net_total_spread: {
                  without_refunds: 0.9275,
                  with_refunds: 0.9283,
                },
                gross_total_spread: {
                  without_refunds: 0.9251,
                  with_refunds: 0.9259,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.71,
                  with_refunds: 1.71,
                },
                gross_mrr: {
                  without_refunds: 1.93,
                  with_refunds: 1.92,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.56,
                  with_refunds: 8.56,
                },
                net_total: {
                  without_refunds: 1.28,
                  with_refunds: 1.27,
                },
                gross_total: {
                  without_refunds: 1.44,
                  with_refunds: 1.44,
                },
                net_total_spread: {
                  without_refunds: 3.14,
                  with_refunds: 3.14,
                },
                gross_total_spread: {
                  without_refunds: 3.52,
                  with_refunds: 3.51,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.51,
                  with_refunds: 11.44,
                },
                gross_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.89,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 11.51,
                  with_refunds: 11.44,
                },
                gross_total: {
                  without_refunds: 12.96,
                  with_refunds: 12.89,
                },
                net_total_spread: {
                  without_refunds: 8.98,
                  with_refunds: 8.96,
                },
                gross_total_spread: {
                  without_refunds: 10.04,
                  with_refunds: 10.02,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.1,
                    with_refunds: 41.31,
                  },
                  gross_mrr: {
                    without_refunds: 49.4,
                    with_refunds: 48.48,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 70.61,
                    with_refunds: 70.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.37,
                    with_refunds: 78.37,
                  },
                  net_total: {
                    without_refunds: 56.26,
                    with_refunds: 51.84,
                  },
                  gross_total: {
                    without_refunds: 64.37,
                    with_refunds: 59.55,
                  },
                  net_total_spread: {
                    without_refunds: 49.31,
                    with_refunds: 48.7,
                  },
                  gross_total_spread: {
                    without_refunds: 56.81,
                    with_refunds: 56.1,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 113.47,
                    with_refunds: 111.23,
                  },
                  gross_mrr: {
                    without_refunds: 131.29,
                    with_refunds: 128.71,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 76.6,
                    with_refunds: 76.6,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.17,
                    with_refunds: 85.17,
                  },
                  net_total: {
                    without_refunds: 152.2,
                    with_refunds: 142.99,
                  },
                  gross_total: {
                    without_refunds: 174.68,
                    with_refunds: 164.42,
                  },
                  net_total_spread: {
                    without_refunds: 96.06,
                    with_refunds: 94.86,
                  },
                  gross_total_spread: {
                    without_refunds: 110.04,
                    with_refunds: 108.65,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3461,
                    with_refunds: 0.3396,
                  },
                  gross_mrr: {
                    without_refunds: 0.4062,
                    with_refunds: 0.3986,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5806,
                    with_refunds: 0.5806,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6444,
                    with_refunds: 0.6444,
                  },
                  net_total: {
                    without_refunds: 0.4626,
                    with_refunds: 0.4262,
                  },
                  gross_total: {
                    without_refunds: 0.5292,
                    with_refunds: 0.4897,
                  },
                  net_total_spread: {
                    without_refunds: 0.4055,
                    with_refunds: 0.4004,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4671,
                    with_refunds: 0.4612,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.933,
                    with_refunds: 0.9146,
                  },
                  gross_mrr: {
                    without_refunds: 1.0795,
                    with_refunds: 1.0583,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6298,
                    with_refunds: 0.6298,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7003,
                    with_refunds: 0.7003,
                  },
                  net_total: {
                    without_refunds: 1.2515,
                    with_refunds: 1.1757,
                  },
                  gross_total: {
                    without_refunds: 1.4363,
                    with_refunds: 1.3519,
                  },
                  net_total_spread: {
                    without_refunds: 0.7898,
                    with_refunds: 0.78,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9048,
                    with_refunds: 0.8934,
                  },
                },
              },
            },
          },
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 124,
                  with_refunds: 124,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 844,
                  with_refunds: 844,
                },
                total_users: {
                  without_refunds: 124,
                  with_refunds: 124,
                },
                total_spread_users: {
                  without_refunds: 968,
                  with_refunds: 968,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0445,
                  with_refunds: 0.0445,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9953,
                  with_refunds: 0.9953,
                },
                total_users: {
                  without_refunds: 0.0332,
                  with_refunds: 0.0332,
                },
                total_spread_users: {
                  without_refunds: 0.2693,
                  with_refunds: 0.2693,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2988,
                  with_refunds: 0.2981,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.2988,
                  with_refunds: 0.2981,
                },
                total_spread_users: {
                  without_refunds: 0.7689,
                  with_refunds: 0.7683,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1421.04,
                  with_refunds: 1421.04,
                },
                gross_mrr: {
                  without_refunds: 1584.89,
                  with_refunds: 1584.89,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6529.22,
                  with_refunds: 6529.22,
                },
                gross_arr_spread: {
                  without_refunds: 7260.75,
                  with_refunds: 7260.75,
                },
                net_total: {
                  without_refunds: 1421.04,
                  with_refunds: 1421.04,
                },
                gross_total: {
                  without_refunds: 1584.89,
                  with_refunds: 1584.89,
                },
                net_total_spread: {
                  without_refunds: 7950.26,
                  with_refunds: 7950.26,
                },
                gross_total_spread: {
                  without_refunds: 8845.64,
                  with_refunds: 8845.64,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0712,
                  with_refunds: 0.0726,
                },
                gross_mrr: {
                  without_refunds: 0.0634,
                  with_refunds: 0.0646,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.2607,
                  with_refunds: 2.2607,
                },
                gross_arr_spread: {
                  without_refunds: 2.1828,
                  with_refunds: 2.1828,
                },
                net_total: {
                  without_refunds: 0.0239,
                  with_refunds: 0.0253,
                },
                gross_total: {
                  without_refunds: 0.0226,
                  with_refunds: 0.0238,
                },
                net_total_spread: {
                  without_refunds: 0.3482,
                  with_refunds: 0.3539,
                },
                gross_total_spread: {
                  without_refunds: 0.3122,
                  with_refunds: 0.3175,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2975,
                  with_refunds: 0.2986,
                },
                gross_mrr: {
                  without_refunds: 0.2946,
                  with_refunds: 0.2957,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.2975,
                  with_refunds: 0.2986,
                },
                gross_total: {
                  without_refunds: 0.2946,
                  with_refunds: 0.2957,
                },
                net_total_spread: {
                  without_refunds: 0.7032,
                  with_refunds: 0.7043,
                },
                gross_total_spread: {
                  without_refunds: 0.6998,
                  with_refunds: 0.7009,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.51,
                  with_refunds: 0.51,
                },
                gross_mrr: {
                  without_refunds: 0.57,
                  with_refunds: 0.57,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.56,
                  with_refunds: 8.56,
                },
                net_total: {
                  without_refunds: 0.38,
                  with_refunds: 0.38,
                },
                gross_total: {
                  without_refunds: 0.42,
                  with_refunds: 0.42,
                },
                net_total_spread: {
                  without_refunds: 2.21,
                  with_refunds: 2.21,
                },
                gross_total_spread: {
                  without_refunds: 2.46,
                  with_refunds: 2.46,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_mrr: {
                  without_refunds: 12.78,
                  with_refunds: 12.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_total: {
                  without_refunds: 12.78,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 8.21,
                  with_refunds: 8.21,
                },
                gross_total_spread: {
                  without_refunds: 9.14,
                  with_refunds: 9.14,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.61,
                    with_refunds: 41.82,
                  },
                  gross_mrr: {
                    without_refunds: 49.97,
                    with_refunds: 49.05,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 78.31,
                    with_refunds: 78.31,
                  },
                  gross_arr_spread: {
                    without_refunds: 86.93,
                    with_refunds: 86.93,
                  },
                  net_total: {
                    without_refunds: 56.64,
                    with_refunds: 52.22,
                  },
                  gross_total: {
                    without_refunds: 64.79,
                    with_refunds: 59.98,
                  },
                  net_total_spread: {
                    without_refunds: 51.53,
                    with_refunds: 50.91,
                  },
                  gross_total_spread: {
                    without_refunds: 59.27,
                    with_refunds: 58.56,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 124.93,
                    with_refunds: 122.69,
                  },
                  gross_mrr: {
                    without_refunds: 144.07,
                    with_refunds: 141.49,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 84.34,
                    with_refunds: 84.34,
                  },
                  gross_arr_spread: {
                    without_refunds: 93.78,
                    with_refunds: 93.78,
                  },
                  net_total: {
                    without_refunds: 163.66,
                    with_refunds: 154.45,
                  },
                  gross_total: {
                    without_refunds: 187.46,
                    with_refunds: 177.2,
                  },
                  net_total_spread: {
                    without_refunds: 104.27,
                    with_refunds: 103.07,
                  },
                  gross_total_spread: {
                    without_refunds: 119.18,
                    with_refunds: 117.79,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3503,
                    with_refunds: 0.3438,
                  },
                  gross_mrr: {
                    without_refunds: 0.4109,
                    with_refunds: 0.4033,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6439,
                    with_refunds: 0.6439,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7148,
                    with_refunds: 0.7148,
                  },
                  net_total: {
                    without_refunds: 0.4657,
                    with_refunds: 0.4294,
                  },
                  gross_total: {
                    without_refunds: 0.5327,
                    with_refunds: 0.4931,
                  },
                  net_total_spread: {
                    without_refunds: 0.4237,
                    with_refunds: 0.4186,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4874,
                    with_refunds: 0.4815,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0272,
                    with_refunds: 1.0088,
                  },
                  gross_mrr: {
                    without_refunds: 1.1846,
                    with_refunds: 1.1634,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6934,
                    with_refunds: 0.6934,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7711,
                    with_refunds: 0.7711,
                  },
                  net_total: {
                    without_refunds: 1.3457,
                    with_refunds: 1.27,
                  },
                  gross_total: {
                    without_refunds: 1.5414,
                    with_refunds: 1.457,
                  },
                  net_total_spread: {
                    without_refunds: 0.8573,
                    with_refunds: 0.8475,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9799,
                    with_refunds: 0.9685,
                  },
                },
              },
            },
          },
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 75.17,
                  with_refunds: 75.14,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 845.76,
                  with_refunds: 845.76,
                },
                total_users: {
                  without_refunds: 74.91,
                  with_refunds: 74.88,
                },
                total_spread_users: {
                  without_refunds: 707.86,
                  with_refunds: 707.56,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.027,
                  with_refunds: 0.0269,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9974,
                  with_refunds: 0.9974,
                },
                total_users: {
                  without_refunds: 0.0201,
                  with_refunds: 0.02,
                },
                total_spread_users: {
                  without_refunds: 0.1969,
                  with_refunds: 0.1968,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 866.15,
                  with_refunds: 871.99,
                },
                gross_mrr: {
                  without_refunds: 968.37,
                  with_refunds: 974.9,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6548.27,
                  with_refunds: 6548.27,
                },
                gross_arr_spread: {
                  without_refunds: 7277.37,
                  with_refunds: 7277.37,
                },
                net_total: {
                  without_refunds: 837.89,
                  with_refunds: 854.67,
                },
                gross_total: {
                  without_refunds: 929.62,
                  with_refunds: 952.18,
                },
                net_total_spread: {
                  without_refunds: 5481.81,
                  with_refunds: 5511.81,
                },
                gross_total_spread: {
                  without_refunds: 6275.73,
                  with_refunds: 6308.48,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0434,
                  with_refunds: 0.0445,
                },
                gross_mrr: {
                  without_refunds: 0.0387,
                  with_refunds: 0.0397,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.2673,
                  with_refunds: 2.2673,
                },
                gross_arr_spread: {
                  without_refunds: 2.1878,
                  with_refunds: 2.1878,
                },
                net_total: {
                  without_refunds: 0.0141,
                  with_refunds: 0.0152,
                },
                gross_total: {
                  without_refunds: 0.0133,
                  with_refunds: 0.0143,
                },
                net_total_spread: {
                  without_refunds: 0.2401,
                  with_refunds: 0.2454,
                },
                gross_total_spread: {
                  without_refunds: 0.2215,
                  with_refunds: 0.2264,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.31,
                  with_refunds: 0.31,
                },
                gross_mrr: {
                  without_refunds: 0.35,
                  with_refunds: 0.35,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.72,
                  with_refunds: 7.72,
                },
                gross_arr_spread: {
                  without_refunds: 8.58,
                  with_refunds: 8.58,
                },
                net_total: {
                  without_refunds: 0.22,
                  with_refunds: 0.23,
                },
                gross_total: {
                  without_refunds: 0.25,
                  with_refunds: 0.25,
                },
                net_total_spread: {
                  without_refunds: 1.52,
                  with_refunds: 1.53,
                },
                gross_total_spread: {
                  without_refunds: 1.75,
                  with_refunds: 1.75,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.52,
                  with_refunds: 11.61,
                },
                gross_mrr: {
                  without_refunds: 12.88,
                  with_refunds: 12.97,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 11.19,
                  with_refunds: 11.41,
                },
                gross_total: {
                  without_refunds: 12.41,
                  with_refunds: 12.72,
                },
                net_total_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.79,
                },
                gross_total_spread: {
                  without_refunds: 8.87,
                  with_refunds: 8.92,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 42.92,
                    with_refunds: 42.13,
                  },
                  gross_mrr: {
                    without_refunds: 50.32,
                    with_refunds: 49.4,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 86.03,
                    with_refunds: 86.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 95.51,
                    with_refunds: 95.51,
                  },
                  net_total: {
                    without_refunds: 56.87,
                    with_refunds: 52.45,
                  },
                  gross_total: {
                    without_refunds: 65.04,
                    with_refunds: 60.23,
                  },
                  net_total_spread: {
                    without_refunds: 53.05,
                    with_refunds: 52.45,
                  },
                  gross_total_spread: {
                    without_refunds: 61.02,
                    with_refunds: 60.31,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 136.45,
                    with_refunds: 134.29,
                  },
                  gross_mrr: {
                    without_refunds: 156.95,
                    with_refunds: 154.46,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 92.08,
                    with_refunds: 92.08,
                  },
                  gross_arr_spread: {
                    without_refunds: 102.38,
                    with_refunds: 102.38,
                  },
                  net_total: {
                    without_refunds: 174.85,
                    with_refunds: 165.86,
                  },
                  gross_total: {
                    without_refunds: 199.87,
                    with_refunds: 189.92,
                  },
                  net_total_spread: {
                    without_refunds: 112.01,
                    with_refunds: 110.86,
                  },
                  gross_total_spread: {
                    without_refunds: 128.04,
                    with_refunds: 126.7,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3529,
                    with_refunds: 0.3464,
                  },
                  gross_mrr: {
                    without_refunds: 0.4137,
                    with_refunds: 0.4062,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7074,
                    with_refunds: 0.7074,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7853,
                    with_refunds: 0.7853,
                  },
                  net_total: {
                    without_refunds: 0.4676,
                    with_refunds: 0.4312,
                  },
                  gross_total: {
                    without_refunds: 0.5348,
                    with_refunds: 0.4952,
                  },
                  net_total_spread: {
                    without_refunds: 0.4362,
                    with_refunds: 0.4312,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5017,
                    with_refunds: 0.4959,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.122,
                    with_refunds: 1.1042,
                  },
                  gross_mrr: {
                    without_refunds: 1.2905,
                    with_refunds: 1.27,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7571,
                    with_refunds: 0.7571,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8418,
                    with_refunds: 0.8418,
                  },
                  net_total: {
                    without_refunds: 1.4377,
                    with_refunds: 1.3638,
                  },
                  gross_total: {
                    without_refunds: 1.6434,
                    with_refunds: 1.5616,
                  },
                  net_total_spread: {
                    without_refunds: 0.921,
                    with_refunds: 0.9115,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0528,
                    with_refunds: 1.0418,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 22.59,
                  with_refunds: 22.54,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 287.77,
                  with_refunds: 287.77,
                },
                total_users: {
                  without_refunds: 22.47,
                  with_refunds: 22.42,
                },
                total_spread_users: {
                  without_refunds: 221.43,
                  with_refunds: 221.06,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0081,
                  with_refunds: 0.0081,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3393,
                  with_refunds: 0.3393,
                },
                total_users: {
                  without_refunds: 0.006,
                  with_refunds: 0.006,
                },
                total_spread_users: {
                  without_refunds: 0.0616,
                  with_refunds: 0.0615,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 259.03,
                  with_refunds: 261.26,
                },
                gross_mrr: {
                  without_refunds: 287.88,
                  with_refunds: 290.33,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2513.56,
                  with_refunds: 2513.56,
                },
                gross_arr_spread: {
                  without_refunds: 2612.21,
                  with_refunds: 2612.21,
                },
                net_total: {
                  without_refunds: 246.35,
                  with_refunds: 251.74,
                },
                gross_total: {
                  without_refunds: 271.39,
                  with_refunds: 278.46,
                },
                net_total_spread: {
                  without_refunds: 1731.4,
                  with_refunds: 1743.44,
                },
                gross_total_spread: {
                  without_refunds: 1962.68,
                  with_refunds: 1975.52,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.013,
                  with_refunds: 0.0133,
                },
                gross_mrr: {
                  without_refunds: 0.0115,
                  with_refunds: 0.0118,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.8703,
                  with_refunds: 0.8703,
                },
                gross_arr_spread: {
                  without_refunds: 0.7853,
                  with_refunds: 0.7853,
                },
                net_total: {
                  without_refunds: 0.0042,
                  with_refunds: 0.0045,
                },
                gross_total: {
                  without_refunds: 0.0039,
                  with_refunds: 0.0042,
                },
                net_total_spread: {
                  without_refunds: 0.0758,
                  with_refunds: 0.0776,
                },
                gross_total_spread: {
                  without_refunds: 0.0693,
                  with_refunds: 0.0709,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.09,
                  with_refunds: 0.09,
                },
                gross_mrr: {
                  without_refunds: 0.1,
                  with_refunds: 0.1,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.96,
                  with_refunds: 2.96,
                },
                gross_arr_spread: {
                  without_refunds: 3.08,
                  with_refunds: 3.08,
                },
                net_total: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                gross_total: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                net_total_spread: {
                  without_refunds: 0.48,
                  with_refunds: 0.48,
                },
                gross_total_spread: {
                  without_refunds: 0.55,
                  with_refunds: 0.55,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.47,
                  with_refunds: 11.59,
                },
                gross_mrr: {
                  without_refunds: 12.74,
                  with_refunds: 12.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.73,
                  with_refunds: 8.73,
                },
                gross_arr_spread: {
                  without_refunds: 9.08,
                  with_refunds: 9.08,
                },
                net_total: {
                  without_refunds: 10.96,
                  with_refunds: 11.23,
                },
                gross_total: {
                  without_refunds: 12.08,
                  with_refunds: 12.42,
                },
                net_total_spread: {
                  without_refunds: 7.82,
                  with_refunds: 7.89,
                },
                gross_total_spread: {
                  without_refunds: 8.86,
                  with_refunds: 8.94,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 43.01,
                    with_refunds: 42.22,
                  },
                  gross_mrr: {
                    without_refunds: 50.42,
                    with_refunds: 49.5,
                  },
                  net_arr: {
                    without_refunds: 93.29,
                    with_refunds: 78.88,
                  },
                  gross_arr: {
                    without_refunds: 103.24,
                    with_refunds: 87.74,
                  },
                  net_arr_spread: {
                    without_refunds: 89,
                    with_refunds: 89,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.59,
                    with_refunds: 98.59,
                  },
                  net_total: {
                    without_refunds: 56.93,
                    with_refunds: 52.51,
                  },
                  gross_total: {
                    without_refunds: 65.11,
                    with_refunds: 60.31,
                  },
                  net_total_spread: {
                    without_refunds: 53.53,
                    with_refunds: 52.93,
                  },
                  gross_total_spread: {
                    without_refunds: 61.56,
                    with_refunds: 60.86,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 147.92,
                    with_refunds: 145.88,
                  },
                  gross_mrr: {
                    without_refunds: 169.69,
                    with_refunds: 167.34,
                  },
                  net_arr: {
                    without_refunds: 962.85,
                    with_refunds: 667.15,
                  },
                  gross_arr: {
                    without_refunds: 1167.11,
                    with_refunds: 822,
                  },
                  net_arr_spread: {
                    without_refunds: 100.81,
                    with_refunds: 100.81,
                  },
                  gross_arr_spread: {
                    without_refunds: 111.46,
                    with_refunds: 111.46,
                  },
                  net_total: {
                    without_refunds: 185.81,
                    with_refunds: 177.09,
                  },
                  gross_total: {
                    without_refunds: 211.95,
                    with_refunds: 202.34,
                  },
                  net_total_spread: {
                    without_refunds: 119.83,
                    with_refunds: 118.75,
                  },
                  gross_total_spread: {
                    without_refunds: 136.91,
                    with_refunds: 135.64,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3536,
                    with_refunds: 0.3472,
                  },
                  gross_mrr: {
                    without_refunds: 0.4146,
                    with_refunds: 0.407,
                  },
                  net_arr: {
                    without_refunds: 0.7671,
                    with_refunds: 0.6486,
                  },
                  gross_arr: {
                    without_refunds: 0.8489,
                    with_refunds: 0.7215,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7318,
                    with_refunds: 0.7318,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8107,
                    with_refunds: 0.8107,
                  },
                  net_total: {
                    without_refunds: 0.4681,
                    with_refunds: 0.4318,
                  },
                  gross_total: {
                    without_refunds: 0.5354,
                    with_refunds: 0.4959,
                  },
                  net_total_spread: {
                    without_refunds: 0.4402,
                    with_refunds: 0.4352,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5062,
                    with_refunds: 0.5004,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2162,
                    with_refunds: 1.1995,
                  },
                  gross_mrr: {
                    without_refunds: 1.3953,
                    with_refunds: 1.3759,
                  },
                  net_arr: {
                    without_refunds: 7.9169,
                    with_refunds: 5.4855,
                  },
                  gross_arr: {
                    without_refunds: 9.5964,
                    with_refunds: 6.7588,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8289,
                    with_refunds: 0.8289,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9164,
                    with_refunds: 0.9164,
                  },
                  net_total: {
                    without_refunds: 1.5278,
                    with_refunds: 1.4561,
                  },
                  gross_total: {
                    without_refunds: 1.7427,
                    with_refunds: 1.6637,
                  },
                  net_total_spread: {
                    without_refunds: 0.9853,
                    with_refunds: 0.9764,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1257,
                    with_refunds: 1.1153,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 1849,
              with_refunds: 1849,
            },
            returning_users: {
              without_refunds: 4201,
              with_refunds: 4215,
            },
            paying_users: {
              without_refunds: 6050,
              with_refunds: 6064,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 441,
              with_refunds: 441,
            },
            returning_users: {
              without_refunds: 44,
              with_refunds: 45,
            },
            paying_users: {
              without_refunds: 485,
              with_refunds: 486,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2289,
              with_refunds: 2289,
            },
            returning_users: {
              without_refunds: 4245,
              with_refunds: 4258,
            },
            paying_users: {
              without_refunds: 6534,
              with_refunds: 6547,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 118826.99,
                with_refunds: 116624.39,
              },
              gross_mrr: {
                without_refunds: 139366.53,
                with_refunds: 136793.56,
              },
              net_arr: {
                without_refunds: 92730.9,
                with_refunds: 78411.43,
              },
              gross_arr: {
                without_refunds: 102621.89,
                with_refunds: 87217.35,
              },
              net_arr_spread: {
                without_refunds: 66409.13,
                with_refunds: 66409.13,
              },
              gross_arr_spread: {
                without_refunds: 73717.38,
                with_refunds: 73717.38,
              },
              net_total: {
                without_refunds: 211557.89,
                with_refunds: 195035.83,
              },
              gross_total: {
                without_refunds: 241988.42,
                with_refunds: 224010.91,
              },
              net_total_spread: {
                without_refunds: 185236.11,
                with_refunds: 183033.52,
              },
              gross_total_spread: {
                without_refunds: 213083.91,
                with_refunds: 210510.94,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 119952.17,
                with_refunds: 117757.64,
              },
              gross_mrr: {
                without_refunds: 140622.78,
                with_refunds: 138058.79,
              },
              net_arr: {
                without_refunds: 92730.9,
                with_refunds: 78411.43,
              },
              gross_arr: {
                without_refunds: 102621.89,
                with_refunds: 87217.35,
              },
              net_arr_spread: {
                without_refunds: 75470.96,
                with_refunds: 75470.96,
              },
              gross_arr_spread: {
                without_refunds: 83606.96,
                with_refunds: 83606.96,
              },
              net_total: {
                without_refunds: 212642.12,
                with_refunds: 196142.23,
              },
              gross_total: {
                without_refunds: 243189.44,
                with_refunds: 225241.55,
              },
              net_total_spread: {
                without_refunds: 192449.33,
                with_refunds: 190288.77,
              },
              gross_total_spread: {
                without_refunds: 221322.33,
                with_refunds: 218794.94,
              },
            },
          },
        },
      },
      cac: 121.62,
      mrr_ua_spend: {
        without_refunds: 339198.18,
        with_refunds: 339198.18,
      },
      arr_ua_spend: {
        without_refunds: 120890.28,
        with_refunds: 120890.28,
      },
      arr_spread_ua_spend: {
        without_refunds: 103133.76,
        with_refunds: 103133.76,
      },
      total_ua_spend: {
        without_refunds: 454250.7,
        with_refunds: 454250.7,
      },
      total_spread_ua_spend: {
        without_refunds: 437223.9,
        with_refunds: 437223.9,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 10,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 3435,
        with_refunds: 3435,
      },
      first_period_arr_users: {
        without_refunds: 1535,
        with_refunds: 1535,
      },
      first_period_arr_spread_users: {
        without_refunds: 1313,
        with_refunds: 1313,
      },
      first_period_total_users: {
        without_refunds: 4932,
        with_refunds: 4932,
      },
      first_period_total_spread_users: {
        without_refunds: 4721,
        with_refunds: 4721,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2411,
                  with_refunds: 2411,
                },
                arr_users: {
                  without_refunds: 805,
                  with_refunds: 805,
                },
                arr_spread_users: {
                  without_refunds: 700,
                  with_refunds: 700,
                },
                total_users: {
                  without_refunds: 3212,
                  with_refunds: 3212,
                },
                total_spread_users: {
                  without_refunds: 3109,
                  with_refunds: 3109,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7019,
                  with_refunds: 0.7019,
                },
                arr_users: {
                  without_refunds: 0.5244,
                  with_refunds: 0.5244,
                },
                arr_spread_users: {
                  without_refunds: 0.5331,
                  with_refunds: 0.5331,
                },
                total_users: {
                  without_refunds: 0.6513,
                  with_refunds: 0.6513,
                },
                total_spread_users: {
                  without_refunds: 0.6585,
                  with_refunds: 0.6585,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 26365.77,
                  with_refunds: 25603.16,
                },
                gross_mrr: {
                  without_refunds: 32554.89,
                  with_refunds: 31582.48,
                },
                net_arr: {
                  without_refunds: 73054.72,
                  with_refunds: 66118.89,
                },
                gross_arr: {
                  without_refunds: 82386.71,
                  with_refunds: 74813.32,
                },
                net_arr_spread: {
                  without_refunds: 5278.72,
                  with_refunds: 5278.72,
                },
                gross_arr_spread: {
                  without_refunds: 5974.17,
                  with_refunds: 5974.17,
                },
                net_total: {
                  without_refunds: 99420.48,
                  with_refunds: 91722.06,
                },
                gross_total: {
                  without_refunds: 114941.6,
                  with_refunds: 106395.79,
                },
                net_total_spread: {
                  without_refunds: 31644.48,
                  with_refunds: 30881.88,
                },
                gross_total_spread: {
                  without_refunds: 38529.06,
                  with_refunds: 37556.65,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.68,
                  with_refunds: 7.45,
                },
                gross_mrr: {
                  without_refunds: 9.48,
                  with_refunds: 9.19,
                },
                net_arr: {
                  without_refunds: 47.59,
                  with_refunds: 43.07,
                },
                gross_arr: {
                  without_refunds: 53.67,
                  with_refunds: 48.74,
                },
                net_arr_spread: {
                  without_refunds: 4.02,
                  with_refunds: 4.02,
                },
                gross_arr_spread: {
                  without_refunds: 4.55,
                  with_refunds: 4.55,
                },
                net_total: {
                  without_refunds: 20.16,
                  with_refunds: 18.6,
                },
                gross_total: {
                  without_refunds: 23.31,
                  with_refunds: 21.57,
                },
                net_total_spread: {
                  without_refunds: 6.7,
                  with_refunds: 6.54,
                },
                gross_total_spread: {
                  without_refunds: 8.16,
                  with_refunds: 7.96,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.94,
                  with_refunds: 10.62,
                },
                gross_mrr: {
                  without_refunds: 13.5,
                  with_refunds: 13.1,
                },
                net_arr: {
                  without_refunds: 90.75,
                  with_refunds: 82.14,
                },
                gross_arr: {
                  without_refunds: 102.34,
                  with_refunds: 92.94,
                },
                net_arr_spread: {
                  without_refunds: 7.54,
                  with_refunds: 7.54,
                },
                gross_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                net_total: {
                  without_refunds: 30.95,
                  with_refunds: 28.56,
                },
                gross_total: {
                  without_refunds: 35.79,
                  with_refunds: 33.12,
                },
                net_total_spread: {
                  without_refunds: 10.18,
                  with_refunds: 9.93,
                },
                gross_total_spread: {
                  without_refunds: 12.39,
                  with_refunds: 12.08,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 7.68,
                    with_refunds: 7.45,
                  },
                  gross_mrr: {
                    without_refunds: 9.48,
                    with_refunds: 9.19,
                  },
                  net_arr: {
                    without_refunds: 47.59,
                    with_refunds: 43.07,
                  },
                  gross_arr: {
                    without_refunds: 53.67,
                    with_refunds: 48.74,
                  },
                  net_arr_spread: {
                    without_refunds: 4.02,
                    with_refunds: 4.02,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.55,
                    with_refunds: 4.55,
                  },
                  net_total: {
                    without_refunds: 20.16,
                    with_refunds: 18.6,
                  },
                  gross_total: {
                    without_refunds: 23.31,
                    with_refunds: 21.57,
                  },
                  net_total_spread: {
                    without_refunds: 6.7,
                    with_refunds: 6.54,
                  },
                  gross_total_spread: {
                    without_refunds: 8.16,
                    with_refunds: 7.96,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.94,
                    with_refunds: 10.62,
                  },
                  gross_mrr: {
                    without_refunds: 13.5,
                    with_refunds: 13.1,
                  },
                  net_arr: {
                    without_refunds: 90.75,
                    with_refunds: 82.14,
                  },
                  gross_arr: {
                    without_refunds: 102.34,
                    with_refunds: 92.94,
                  },
                  net_arr_spread: {
                    without_refunds: 7.54,
                    with_refunds: 7.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.53,
                    with_refunds: 8.53,
                  },
                  net_total: {
                    without_refunds: 30.95,
                    with_refunds: 28.56,
                  },
                  gross_total: {
                    without_refunds: 35.79,
                    with_refunds: 33.12,
                  },
                  net_total_spread: {
                    without_refunds: 10.18,
                    with_refunds: 9.93,
                  },
                  gross_total_spread: {
                    without_refunds: 12.39,
                    with_refunds: 12.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1214,
                    with_refunds: 0.1179,
                  },
                  gross_mrr: {
                    without_refunds: 0.1499,
                    with_refunds: 0.1455,
                  },
                  net_arr: {
                    without_refunds: 0.7529,
                    with_refunds: 0.6814,
                  },
                  gross_arr: {
                    without_refunds: 0.8491,
                    with_refunds: 0.7711,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0636,
                    with_refunds: 0.0636,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.072,
                    with_refunds: 0.072,
                  },
                  net_total: {
                    without_refunds: 0.3189,
                    with_refunds: 0.2942,
                  },
                  gross_total: {
                    without_refunds: 0.3687,
                    with_refunds: 0.3413,
                  },
                  net_total_spread: {
                    without_refunds: 0.106,
                    with_refunds: 0.1035,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1291,
                    with_refunds: 0.1259,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.173,
                    with_refunds: 0.168,
                  },
                  gross_mrr: {
                    without_refunds: 0.2136,
                    with_refunds: 0.2072,
                  },
                  net_arr: {
                    without_refunds: 1.4357,
                    with_refunds: 1.2994,
                  },
                  gross_arr: {
                    without_refunds: 1.6191,
                    with_refunds: 1.4703,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1193,
                    with_refunds: 0.1193,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.135,
                    with_refunds: 0.135,
                  },
                  net_total: {
                    without_refunds: 0.4897,
                    with_refunds: 0.4518,
                  },
                  gross_total: {
                    without_refunds: 0.5661,
                    with_refunds: 0.524,
                  },
                  net_total_spread: {
                    without_refunds: 0.161,
                    with_refunds: 0.1571,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1961,
                    with_refunds: 0.1911,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2418,
                  with_refunds: 2434,
                },
                arr_users: {
                  without_refunds: 669,
                  with_refunds: 698,
                },
                arr_spread_users: {
                  without_refunds: 1256,
                  with_refunds: 1256,
                },
                total_users: {
                  without_refunds: 3084,
                  with_refunds: 3129,
                },
                total_spread_users: {
                  without_refunds: 3674,
                  with_refunds: 3690,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7039,
                  with_refunds: 0.7086,
                },
                arr_users: {
                  without_refunds: 0.4358,
                  with_refunds: 0.4547,
                },
                arr_spread_users: {
                  without_refunds: 0.9566,
                  with_refunds: 0.9566,
                },
                total_users: {
                  without_refunds: 0.6253,
                  with_refunds: 0.6344,
                },
                total_spread_users: {
                  without_refunds: 0.7782,
                  with_refunds: 0.7816,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0095,
                },
                arr_users: {
                  without_refunds: 0.8311,
                  with_refunds: 0.8671,
                },
                arr_spread_users: {
                  without_refunds: 1.7943,
                  with_refunds: 1.7943,
                },
                total_users: {
                  without_refunds: 0.9601,
                  with_refunds: 0.9742,
                },
                total_spread_users: {
                  without_refunds: 1.1817,
                  with_refunds: 1.1869,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 27121.71,
                  with_refunds: 26252.96,
                },
                gross_mrr: {
                  without_refunds: 32438.91,
                  with_refunds: 31407.36,
                },
                net_arr: {
                  without_refunds: 61926.95,
                  with_refunds: 49748.25,
                },
                gross_arr: {
                  without_refunds: 68169.04,
                  with_refunds: 54696.79,
                },
                net_arr_spread: {
                  without_refunds: 9553.66,
                  with_refunds: 9553.66,
                },
                gross_arr_spread: {
                  without_refunds: 10673.93,
                  with_refunds: 10673.93,
                },
                net_total: {
                  without_refunds: 89048.66,
                  with_refunds: 76001.22,
                },
                gross_total: {
                  without_refunds: 100607.95,
                  with_refunds: 86104.15,
                },
                net_total_spread: {
                  without_refunds: 36675.37,
                  with_refunds: 35806.62,
                },
                gross_total_spread: {
                  without_refunds: 43112.84,
                  with_refunds: 42081.29,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.0287,
                  with_refunds: 1.0254,
                },
                gross_mrr: {
                  without_refunds: 0.9964,
                  with_refunds: 0.9945,
                },
                net_arr: {
                  without_refunds: 0.8477,
                  with_refunds: 0.7524,
                },
                gross_arr: {
                  without_refunds: 0.8274,
                  with_refunds: 0.7311,
                },
                net_arr_spread: {
                  without_refunds: 1.8098,
                  with_refunds: 1.8098,
                },
                gross_arr_spread: {
                  without_refunds: 1.7867,
                  with_refunds: 1.7867,
                },
                net_total: {
                  without_refunds: 0.8957,
                  with_refunds: 0.8286,
                },
                gross_total: {
                  without_refunds: 0.8753,
                  with_refunds: 0.8093,
                },
                net_total_spread: {
                  without_refunds: 1.159,
                  with_refunds: 1.1595,
                },
                gross_total_spread: {
                  without_refunds: 1.119,
                  with_refunds: 1.1205,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.0287,
                  with_refunds: 1.0254,
                },
                gross_mrr: {
                  without_refunds: 0.9964,
                  with_refunds: 0.9945,
                },
                net_arr: {
                  without_refunds: 0.8477,
                  with_refunds: 0.7524,
                },
                gross_arr: {
                  without_refunds: 0.8274,
                  with_refunds: 0.7311,
                },
                net_arr_spread: {
                  without_refunds: 1.8098,
                  with_refunds: 1.8098,
                },
                gross_arr_spread: {
                  without_refunds: 1.7867,
                  with_refunds: 1.7867,
                },
                net_total: {
                  without_refunds: 0.8957,
                  with_refunds: 0.8286,
                },
                gross_total: {
                  without_refunds: 0.8753,
                  with_refunds: 0.8093,
                },
                net_total_spread: {
                  without_refunds: 1.159,
                  with_refunds: 1.1595,
                },
                gross_total_spread: {
                  without_refunds: 1.119,
                  with_refunds: 1.1205,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.9,
                  with_refunds: 7.64,
                },
                gross_mrr: {
                  without_refunds: 9.44,
                  with_refunds: 9.14,
                },
                net_arr: {
                  without_refunds: 40.34,
                  with_refunds: 32.41,
                },
                gross_arr: {
                  without_refunds: 44.41,
                  with_refunds: 35.63,
                },
                net_arr_spread: {
                  without_refunds: 7.28,
                  with_refunds: 7.28,
                },
                gross_arr_spread: {
                  without_refunds: 8.13,
                  with_refunds: 8.13,
                },
                net_total: {
                  without_refunds: 18.06,
                  with_refunds: 15.41,
                },
                gross_total: {
                  without_refunds: 20.4,
                  with_refunds: 17.46,
                },
                net_total_spread: {
                  without_refunds: 7.77,
                  with_refunds: 7.58,
                },
                gross_total_spread: {
                  without_refunds: 9.13,
                  with_refunds: 8.91,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.22,
                  with_refunds: 10.79,
                },
                gross_mrr: {
                  without_refunds: 13.42,
                  with_refunds: 12.9,
                },
                net_arr: {
                  without_refunds: 92.57,
                  with_refunds: 71.27,
                },
                gross_arr: {
                  without_refunds: 101.9,
                  with_refunds: 78.36,
                },
                net_arr_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.61,
                },
                gross_arr_spread: {
                  without_refunds: 8.5,
                  with_refunds: 8.5,
                },
                net_total: {
                  without_refunds: 28.87,
                  with_refunds: 24.29,
                },
                gross_total: {
                  without_refunds: 32.62,
                  with_refunds: 27.52,
                },
                net_total_spread: {
                  without_refunds: 9.98,
                  with_refunds: 9.7,
                },
                gross_total_spread: {
                  without_refunds: 11.73,
                  with_refunds: 11.4,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.57,
                    with_refunds: 15.1,
                  },
                  gross_mrr: {
                    without_refunds: 18.92,
                    with_refunds: 18.34,
                  },
                  net_arr: {
                    without_refunds: 87.94,
                    with_refunds: 75.48,
                  },
                  gross_arr: {
                    without_refunds: 98.08,
                    with_refunds: 84.37,
                  },
                  net_arr_spread: {
                    without_refunds: 11.3,
                    with_refunds: 11.3,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.68,
                    with_refunds: 12.68,
                  },
                  net_total: {
                    without_refunds: 38.21,
                    with_refunds: 34.01,
                  },
                  gross_total: {
                    without_refunds: 43.7,
                    with_refunds: 39.03,
                  },
                  net_total_spread: {
                    without_refunds: 14.47,
                    with_refunds: 14.13,
                  },
                  gross_total_spread: {
                    without_refunds: 17.29,
                    with_refunds: 16.87,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.15,
                    with_refunds: 21.41,
                  },
                  gross_mrr: {
                    without_refunds: 26.92,
                    with_refunds: 26,
                  },
                  net_arr: {
                    without_refunds: 183.32,
                    with_refunds: 153.41,
                  },
                  gross_arr: {
                    without_refunds: 204.24,
                    with_refunds: 171.3,
                  },
                  net_arr_spread: {
                    without_refunds: 15.15,
                    with_refunds: 15.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.03,
                    with_refunds: 17.03,
                  },
                  net_total: {
                    without_refunds: 59.83,
                    with_refunds: 52.85,
                  },
                  gross_total: {
                    without_refunds: 68.41,
                    with_refunds: 60.64,
                  },
                  net_total_spread: {
                    without_refunds: 20.16,
                    with_refunds: 19.64,
                  },
                  gross_total_spread: {
                    without_refunds: 24.13,
                    with_refunds: 23.48,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2463,
                    with_refunds: 0.2388,
                  },
                  gross_mrr: {
                    without_refunds: 0.2993,
                    with_refunds: 0.2901,
                  },
                  net_arr: {
                    without_refunds: 1.3912,
                    with_refunds: 1.1942,
                  },
                  gross_arr: {
                    without_refunds: 1.5517,
                    with_refunds: 1.3348,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1787,
                    with_refunds: 0.1787,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2006,
                    with_refunds: 0.2006,
                  },
                  net_total: {
                    without_refunds: 0.6045,
                    with_refunds: 0.538,
                  },
                  gross_total: {
                    without_refunds: 0.6914,
                    with_refunds: 0.6175,
                  },
                  net_total_spread: {
                    without_refunds: 0.2289,
                    with_refunds: 0.2235,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2736,
                    with_refunds: 0.2669,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3505,
                    with_refunds: 0.3386,
                  },
                  gross_mrr: {
                    without_refunds: 0.4259,
                    with_refunds: 0.4114,
                  },
                  net_arr: {
                    without_refunds: 2.9001,
                    with_refunds: 2.427,
                  },
                  gross_arr: {
                    without_refunds: 3.2311,
                    with_refunds: 2.71,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2396,
                    with_refunds: 0.2396,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2695,
                    with_refunds: 0.2695,
                  },
                  net_total: {
                    without_refunds: 0.9465,
                    with_refunds: 0.836,
                  },
                  gross_total: {
                    without_refunds: 1.0822,
                    with_refunds: 0.9594,
                  },
                  net_total_spread: {
                    without_refunds: 0.3189,
                    with_refunds: 0.3107,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3817,
                    with_refunds: 0.3715,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1754,
                  with_refunds: 1763,
                },
                arr_users: {
                  without_refunds: 19,
                  with_refunds: 27,
                },
                arr_spread_users: {
                  without_refunds: 1270,
                  with_refunds: 1270,
                },
                total_users: {
                  without_refunds: 1773,
                  with_refunds: 1790,
                },
                total_spread_users: {
                  without_refunds: 3024,
                  with_refunds: 3033,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5106,
                  with_refunds: 0.5132,
                },
                arr_users: {
                  without_refunds: 0.0124,
                  with_refunds: 0.0176,
                },
                arr_spread_users: {
                  without_refunds: 0.9673,
                  with_refunds: 0.9673,
                },
                total_users: {
                  without_refunds: 0.3595,
                  with_refunds: 0.3629,
                },
                total_spread_users: {
                  without_refunds: 0.6405,
                  with_refunds: 0.6424,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7254,
                  with_refunds: 0.7243,
                },
                arr_users: {
                  without_refunds: 0.0284,
                  with_refunds: 0.0387,
                },
                arr_spread_users: {
                  without_refunds: 1.0111,
                  with_refunds: 1.0111,
                },
                total_users: {
                  without_refunds: 0.5749,
                  with_refunds: 0.5721,
                },
                total_spread_users: {
                  without_refunds: 0.8231,
                  with_refunds: 0.822,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 19788.97,
                  with_refunds: 19333.65,
                },
                gross_mrr: {
                  without_refunds: 23563.2,
                  with_refunds: 23049.56,
                },
                net_arr: {
                  without_refunds: 1879.11,
                  with_refunds: 800.38,
                },
                gross_arr: {
                  without_refunds: 2330.28,
                  with_refunds: 1030.61,
                },
                net_arr_spread: {
                  without_refunds: 9670.08,
                  with_refunds: 9670.08,
                },
                gross_arr_spread: {
                  without_refunds: 10818.29,
                  with_refunds: 10818.29,
                },
                net_total: {
                  without_refunds: 21668.08,
                  with_refunds: 20134.03,
                },
                gross_total: {
                  without_refunds: 25893.48,
                  with_refunds: 24080.16,
                },
                net_total_spread: {
                  without_refunds: 29459.05,
                  with_refunds: 29003.73,
                },
                gross_total_spread: {
                  without_refunds: 34381.49,
                  with_refunds: 33867.85,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7506,
                  with_refunds: 0.7551,
                },
                gross_mrr: {
                  without_refunds: 0.7238,
                  with_refunds: 0.7298,
                },
                net_arr: {
                  without_refunds: 0.0257,
                  with_refunds: 0.0121,
                },
                gross_arr: {
                  without_refunds: 0.0283,
                  with_refunds: 0.0138,
                },
                net_arr_spread: {
                  without_refunds: 1.8319,
                  with_refunds: 1.8319,
                },
                gross_arr_spread: {
                  without_refunds: 1.8108,
                  with_refunds: 1.8108,
                },
                net_total: {
                  without_refunds: 0.2179,
                  with_refunds: 0.2195,
                },
                gross_total: {
                  without_refunds: 0.2253,
                  with_refunds: 0.2263,
                },
                net_total_spread: {
                  without_refunds: 0.9309,
                  with_refunds: 0.9392,
                },
                gross_total_spread: {
                  without_refunds: 0.8924,
                  with_refunds: 0.9018,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7296,
                  with_refunds: 0.7364,
                },
                gross_mrr: {
                  without_refunds: 0.7264,
                  with_refunds: 0.7339,
                },
                net_arr: {
                  without_refunds: 0.0303,
                  with_refunds: 0.0161,
                },
                gross_arr: {
                  without_refunds: 0.0342,
                  with_refunds: 0.0188,
                },
                net_arr_spread: {
                  without_refunds: 1.0122,
                  with_refunds: 1.0122,
                },
                gross_arr_spread: {
                  without_refunds: 1.0135,
                  with_refunds: 1.0135,
                },
                net_total: {
                  without_refunds: 0.2433,
                  with_refunds: 0.2649,
                },
                gross_total: {
                  without_refunds: 0.2574,
                  with_refunds: 0.2797,
                },
                net_total_spread: {
                  without_refunds: 0.8032,
                  with_refunds: 0.81,
                },
                gross_total_spread: {
                  without_refunds: 0.7975,
                  with_refunds: 0.8048,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.76,
                  with_refunds: 5.63,
                },
                gross_mrr: {
                  without_refunds: 6.86,
                  with_refunds: 6.71,
                },
                net_arr: {
                  without_refunds: 1.22,
                  with_refunds: 0.52,
                },
                gross_arr: {
                  without_refunds: 1.52,
                  with_refunds: 0.67,
                },
                net_arr_spread: {
                  without_refunds: 7.36,
                  with_refunds: 7.36,
                },
                gross_arr_spread: {
                  without_refunds: 8.24,
                  with_refunds: 8.24,
                },
                net_total: {
                  without_refunds: 4.39,
                  with_refunds: 4.08,
                },
                gross_total: {
                  without_refunds: 5.25,
                  with_refunds: 4.88,
                },
                net_total_spread: {
                  without_refunds: 6.24,
                  with_refunds: 6.14,
                },
                gross_total_spread: {
                  without_refunds: 7.28,
                  with_refunds: 7.17,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.28,
                  with_refunds: 10.97,
                },
                gross_mrr: {
                  without_refunds: 13.43,
                  with_refunds: 13.07,
                },
                net_arr: {
                  without_refunds: 98.9,
                  with_refunds: 29.64,
                },
                gross_arr: {
                  without_refunds: 122.65,
                  with_refunds: 38.17,
                },
                net_arr_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.61,
                },
                gross_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                net_total: {
                  without_refunds: 12.22,
                  with_refunds: 11.25,
                },
                gross_total: {
                  without_refunds: 14.6,
                  with_refunds: 13.45,
                },
                net_total_spread: {
                  without_refunds: 9.74,
                  with_refunds: 9.56,
                },
                gross_total_spread: {
                  without_refunds: 11.37,
                  with_refunds: 11.17,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.33,
                    with_refunds: 20.72,
                  },
                  gross_mrr: {
                    without_refunds: 25.78,
                    with_refunds: 25.05,
                  },
                  net_arr: {
                    without_refunds: 89.16,
                    with_refunds: 76,
                  },
                  gross_arr: {
                    without_refunds: 99.6,
                    with_refunds: 85.04,
                  },
                  net_arr_spread: {
                    without_refunds: 18.66,
                    with_refunds: 18.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 20.92,
                    with_refunds: 20.92,
                  },
                  net_total: {
                    without_refunds: 42.61,
                    with_refunds: 38.09,
                  },
                  gross_total: {
                    without_refunds: 48.95,
                    with_refunds: 43.91,
                  },
                  net_total_spread: {
                    without_refunds: 20.71,
                    with_refunds: 20.27,
                  },
                  gross_total_spread: {
                    without_refunds: 24.58,
                    with_refunds: 24.04,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.43,
                    with_refunds: 32.37,
                  },
                  gross_mrr: {
                    without_refunds: 40.35,
                    with_refunds: 39.08,
                  },
                  net_arr: {
                    without_refunds: 282.22,
                    with_refunds: 183.05,
                  },
                  gross_arr: {
                    without_refunds: 326.89,
                    with_refunds: 209.47,
                  },
                  net_arr_spread: {
                    without_refunds: 22.76,
                    with_refunds: 22.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.55,
                    with_refunds: 25.55,
                  },
                  net_total: {
                    without_refunds: 72.05,
                    with_refunds: 64.09,
                  },
                  gross_total: {
                    without_refunds: 83.01,
                    with_refunds: 74.1,
                  },
                  net_total_spread: {
                    without_refunds: 29.9,
                    with_refunds: 29.2,
                  },
                  gross_total_spread: {
                    without_refunds: 35.5,
                    with_refunds: 34.65,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3375,
                    with_refunds: 0.3279,
                  },
                  gross_mrr: {
                    without_refunds: 0.4079,
                    with_refunds: 0.3963,
                  },
                  net_arr: {
                    without_refunds: 1.4105,
                    with_refunds: 1.2024,
                  },
                  gross_arr: {
                    without_refunds: 1.5757,
                    with_refunds: 1.3454,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2952,
                    with_refunds: 0.2952,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3309,
                    with_refunds: 0.3309,
                  },
                  net_total: {
                    without_refunds: 0.6741,
                    with_refunds: 0.6026,
                  },
                  gross_total: {
                    without_refunds: 0.7745,
                    with_refunds: 0.6947,
                  },
                  net_total_spread: {
                    without_refunds: 0.3277,
                    with_refunds: 0.3207,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3888,
                    with_refunds: 0.3804,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5289,
                    with_refunds: 0.5121,
                  },
                  gross_mrr: {
                    without_refunds: 0.6384,
                    with_refunds: 0.6182,
                  },
                  net_arr: {
                    without_refunds: 4.4648,
                    with_refunds: 2.8959,
                  },
                  gross_arr: {
                    without_refunds: 5.1714,
                    with_refunds: 3.3139,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3601,
                    with_refunds: 0.3601,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4042,
                    with_refunds: 0.4042,
                  },
                  net_total: {
                    without_refunds: 1.1398,
                    with_refunds: 1.014,
                  },
                  gross_total: {
                    without_refunds: 1.3133,
                    with_refunds: 1.1722,
                  },
                  net_total_spread: {
                    without_refunds: 0.4731,
                    with_refunds: 0.4619,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5616,
                    with_refunds: 0.5482,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1124,
                  with_refunds: 1130,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 16,
                },
                arr_spread_users: {
                  without_refunds: 1279,
                  with_refunds: 1279,
                },
                total_users: {
                  without_refunds: 1134,
                  with_refunds: 1146,
                },
                total_spread_users: {
                  without_refunds: 2403,
                  with_refunds: 2409,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3272,
                  with_refunds: 0.329,
                },
                arr_users: {
                  without_refunds: 0.0065,
                  with_refunds: 0.0104,
                },
                arr_spread_users: {
                  without_refunds: 0.9741,
                  with_refunds: 0.9741,
                },
                total_users: {
                  without_refunds: 0.2299,
                  with_refunds: 0.2324,
                },
                total_spread_users: {
                  without_refunds: 0.509,
                  with_refunds: 0.5103,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6408,
                  with_refunds: 0.641,
                },
                arr_users: {
                  without_refunds: 0.5263,
                  with_refunds: 0.5926,
                },
                arr_spread_users: {
                  without_refunds: 1.0071,
                  with_refunds: 1.0071,
                },
                total_users: {
                  without_refunds: 0.6396,
                  with_refunds: 0.6402,
                },
                total_spread_users: {
                  without_refunds: 0.7946,
                  with_refunds: 0.7943,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 12918.81,
                  with_refunds: 12631.28,
                },
                gross_mrr: {
                  without_refunds: 14872.64,
                  with_refunds: 14551.08,
                },
                net_arr: {
                  without_refunds: 1115.22,
                  with_refunds: 454.88,
                },
                gross_arr: {
                  without_refunds: 1495.57,
                  with_refunds: 666.59,
                },
                net_arr_spread: {
                  without_refunds: 9753.57,
                  with_refunds: 9753.57,
                },
                gross_arr_spread: {
                  without_refunds: 10929.59,
                  with_refunds: 10929.59,
                },
                net_total: {
                  without_refunds: 14034.04,
                  with_refunds: 13086.16,
                },
                gross_total: {
                  without_refunds: 16368.21,
                  with_refunds: 15217.67,
                },
                net_total_spread: {
                  without_refunds: 22672.38,
                  with_refunds: 22384.85,
                },
                gross_total_spread: {
                  without_refunds: 25802.23,
                  with_refunds: 25480.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.49,
                  with_refunds: 0.4933,
                },
                gross_mrr: {
                  without_refunds: 0.4568,
                  with_refunds: 0.4607,
                },
                net_arr: {
                  without_refunds: 0.0153,
                  with_refunds: 0.0069,
                },
                gross_arr: {
                  without_refunds: 0.0182,
                  with_refunds: 0.0089,
                },
                net_arr_spread: {
                  without_refunds: 1.8477,
                  with_refunds: 1.8477,
                },
                gross_arr_spread: {
                  without_refunds: 1.8295,
                  with_refunds: 1.8295,
                },
                net_total: {
                  without_refunds: 0.1412,
                  with_refunds: 0.1427,
                },
                gross_total: {
                  without_refunds: 0.1424,
                  with_refunds: 0.143,
                },
                net_total_spread: {
                  without_refunds: 0.7165,
                  with_refunds: 0.7249,
                },
                gross_total_spread: {
                  without_refunds: 0.6697,
                  with_refunds: 0.6785,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6528,
                  with_refunds: 0.6533,
                },
                gross_mrr: {
                  without_refunds: 0.6312,
                  with_refunds: 0.6313,
                },
                net_arr: {
                  without_refunds: 0.5935,
                  with_refunds: 0.5683,
                },
                gross_arr: {
                  without_refunds: 0.6418,
                  with_refunds: 0.6468,
                },
                net_arr_spread: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                gross_arr_spread: {
                  without_refunds: 1.0103,
                  with_refunds: 1.0103,
                },
                net_total: {
                  without_refunds: 0.6477,
                  with_refunds: 0.65,
                },
                gross_total: {
                  without_refunds: 0.6321,
                  with_refunds: 0.632,
                },
                net_total_spread: {
                  without_refunds: 0.7696,
                  with_refunds: 0.7718,
                },
                gross_total_spread: {
                  without_refunds: 0.7505,
                  with_refunds: 0.7524,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.76,
                  with_refunds: 3.68,
                },
                gross_mrr: {
                  without_refunds: 4.33,
                  with_refunds: 4.24,
                },
                net_arr: {
                  without_refunds: 0.73,
                  with_refunds: 0.3,
                },
                gross_arr: {
                  without_refunds: 0.97,
                  with_refunds: 0.43,
                },
                net_arr_spread: {
                  without_refunds: 7.43,
                  with_refunds: 7.43,
                },
                gross_arr_spread: {
                  without_refunds: 8.32,
                  with_refunds: 8.32,
                },
                net_total: {
                  without_refunds: 2.85,
                  with_refunds: 2.65,
                },
                gross_total: {
                  without_refunds: 3.32,
                  with_refunds: 3.09,
                },
                net_total_spread: {
                  without_refunds: 4.8,
                  with_refunds: 4.74,
                },
                gross_total_spread: {
                  without_refunds: 5.47,
                  with_refunds: 5.4,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.49,
                  with_refunds: 11.18,
                },
                gross_mrr: {
                  without_refunds: 13.23,
                  with_refunds: 12.88,
                },
                net_arr: {
                  without_refunds: 111.52,
                  with_refunds: 28.43,
                },
                gross_arr: {
                  without_refunds: 149.56,
                  with_refunds: 41.66,
                },
                net_arr_spread: {
                  without_refunds: 7.63,
                  with_refunds: 7.63,
                },
                gross_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                net_total: {
                  without_refunds: 12.38,
                  with_refunds: 11.42,
                },
                gross_total: {
                  without_refunds: 14.43,
                  with_refunds: 13.28,
                },
                net_total_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.29,
                },
                gross_total_spread: {
                  without_refunds: 10.74,
                  with_refunds: 10.58,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.09,
                    with_refunds: 24.4,
                  },
                  gross_mrr: {
                    without_refunds: 30.11,
                    with_refunds: 29.28,
                  },
                  net_arr: {
                    without_refunds: 89.89,
                    with_refunds: 76.3,
                  },
                  gross_arr: {
                    without_refunds: 100.57,
                    with_refunds: 85.48,
                  },
                  net_arr_spread: {
                    without_refunds: 26.09,
                    with_refunds: 26.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 29.24,
                    with_refunds: 29.24,
                  },
                  net_total: {
                    without_refunds: 45.45,
                    with_refunds: 40.74,
                  },
                  gross_total: {
                    without_refunds: 52.27,
                    with_refunds: 47,
                  },
                  net_total_spread: {
                    without_refunds: 25.51,
                    with_refunds: 25.01,
                  },
                  gross_total_spread: {
                    without_refunds: 30.04,
                    with_refunds: 29.44,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 44.93,
                    with_refunds: 43.55,
                  },
                  gross_mrr: {
                    without_refunds: 53.58,
                    with_refunds: 51.95,
                  },
                  net_arr: {
                    without_refunds: 393.74,
                    with_refunds: 211.48,
                  },
                  gross_arr: {
                    without_refunds: 476.44,
                    with_refunds: 251.13,
                  },
                  net_arr_spread: {
                    without_refunds: 30.39,
                    with_refunds: 30.39,
                  },
                  gross_arr_spread: {
                    without_refunds: 34.1,
                    with_refunds: 34.1,
                  },
                  net_total: {
                    without_refunds: 84.42,
                    with_refunds: 75.51,
                  },
                  gross_total: {
                    without_refunds: 97.45,
                    with_refunds: 87.37,
                  },
                  net_total_spread: {
                    without_refunds: 39.34,
                    with_refunds: 38.49,
                  },
                  gross_total_spread: {
                    without_refunds: 46.23,
                    with_refunds: 45.23,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.397,
                    with_refunds: 0.386,
                  },
                  gross_mrr: {
                    without_refunds: 0.4764,
                    with_refunds: 0.4633,
                  },
                  net_arr: {
                    without_refunds: 1.422,
                    with_refunds: 1.2071,
                  },
                  gross_arr: {
                    without_refunds: 1.5911,
                    with_refunds: 1.3523,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4127,
                    with_refunds: 0.4127,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4626,
                    with_refunds: 0.4626,
                  },
                  net_total: {
                    without_refunds: 0.7191,
                    with_refunds: 0.6446,
                  },
                  gross_total: {
                    without_refunds: 0.827,
                    with_refunds: 0.7435,
                  },
                  net_total_spread: {
                    without_refunds: 0.4036,
                    with_refunds: 0.3957,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4753,
                    with_refunds: 0.4657,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7108,
                    with_refunds: 0.689,
                  },
                  gross_mrr: {
                    without_refunds: 0.8477,
                    with_refunds: 0.8219,
                  },
                  net_arr: {
                    without_refunds: 6.2291,
                    with_refunds: 3.3457,
                  },
                  gross_arr: {
                    without_refunds: 7.5375,
                    with_refunds: 3.973,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4807,
                    with_refunds: 0.4807,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5394,
                    with_refunds: 0.5394,
                  },
                  net_total: {
                    without_refunds: 1.3356,
                    with_refunds: 1.1946,
                  },
                  gross_total: {
                    without_refunds: 1.5416,
                    with_refunds: 1.3823,
                  },
                  net_total_spread: {
                    without_refunds: 0.6223,
                    with_refunds: 0.6089,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7314,
                    with_refunds: 0.7155,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 925,
                  with_refunds: 929,
                },
                arr_users: {
                  without_refunds: 9,
                  with_refunds: 9,
                },
                arr_spread_users: {
                  without_refunds: 1286,
                  with_refunds: 1286,
                },
                total_users: {
                  without_refunds: 933,
                  with_refunds: 935,
                },
                total_spread_users: {
                  without_refunds: 2210,
                  with_refunds: 2212,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2693,
                  with_refunds: 0.2705,
                },
                arr_users: {
                  without_refunds: 0.0059,
                  with_refunds: 0.0059,
                },
                arr_spread_users: {
                  without_refunds: 0.9794,
                  with_refunds: 0.9794,
                },
                total_users: {
                  without_refunds: 0.1892,
                  with_refunds: 0.1896,
                },
                total_spread_users: {
                  without_refunds: 0.4681,
                  with_refunds: 0.4685,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.823,
                  with_refunds: 0.8221,
                },
                arr_users: {
                  without_refunds: 0.9,
                  with_refunds: 0.5625,
                },
                arr_spread_users: {
                  without_refunds: 1.0055,
                  with_refunds: 1.0055,
                },
                total_users: {
                  without_refunds: 0.8228,
                  with_refunds: 0.8159,
                },
                total_spread_users: {
                  without_refunds: 0.9197,
                  with_refunds: 0.9182,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10670.03,
                  with_refunds: 10378.51,
                },
                gross_mrr: {
                  without_refunds: 12274.92,
                  with_refunds: 11948.91,
                },
                net_arr: {
                  without_refunds: 1046.83,
                  with_refunds: 932.52,
                },
                gross_arr: {
                  without_refunds: 1328.23,
                  with_refunds: 1168.2,
                },
                net_arr_spread: {
                  without_refunds: 9823.29,
                  with_refunds: 9823.29,
                },
                gross_arr_spread: {
                  without_refunds: 11018.69,
                  with_refunds: 11018.69,
                },
                net_total: {
                  without_refunds: 11716.86,
                  with_refunds: 11311.03,
                },
                gross_total: {
                  without_refunds: 13603.15,
                  with_refunds: 13117.11,
                },
                net_total_spread: {
                  without_refunds: 20493.32,
                  with_refunds: 20201.81,
                },
                gross_total_spread: {
                  without_refunds: 23293.61,
                  with_refunds: 22967.6,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4047,
                  with_refunds: 0.4054,
                },
                gross_mrr: {
                  without_refunds: 0.3771,
                  with_refunds: 0.3783,
                },
                net_arr: {
                  without_refunds: 0.0143,
                  with_refunds: 0.0141,
                },
                gross_arr: {
                  without_refunds: 0.0161,
                  with_refunds: 0.0156,
                },
                net_arr_spread: {
                  without_refunds: 1.8609,
                  with_refunds: 1.8609,
                },
                gross_arr_spread: {
                  without_refunds: 1.8444,
                  with_refunds: 1.8444,
                },
                net_total: {
                  without_refunds: 0.1179,
                  with_refunds: 0.1233,
                },
                gross_total: {
                  without_refunds: 0.1183,
                  with_refunds: 0.1233,
                },
                net_total_spread: {
                  without_refunds: 0.6476,
                  with_refunds: 0.6542,
                },
                gross_total_spread: {
                  without_refunds: 0.6046,
                  with_refunds: 0.6115,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8259,
                  with_refunds: 0.8217,
                },
                gross_mrr: {
                  without_refunds: 0.8253,
                  with_refunds: 0.8212,
                },
                net_arr: {
                  without_refunds: 0.9387,
                  with_refunds: 2.05,
                },
                gross_arr: {
                  without_refunds: 0.8881,
                  with_refunds: 1.7525,
                },
                net_arr_spread: {
                  without_refunds: 1.0071,
                  with_refunds: 1.0071,
                },
                gross_arr_spread: {
                  without_refunds: 1.0082,
                  with_refunds: 1.0082,
                },
                net_total: {
                  without_refunds: 0.8349,
                  with_refunds: 0.8644,
                },
                gross_total: {
                  without_refunds: 0.8311,
                  with_refunds: 0.862,
                },
                net_total_spread: {
                  without_refunds: 0.9039,
                  with_refunds: 0.9025,
                },
                gross_total_spread: {
                  without_refunds: 0.9028,
                  with_refunds: 0.9014,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.11,
                  with_refunds: 3.02,
                },
                gross_mrr: {
                  without_refunds: 3.57,
                  with_refunds: 3.48,
                },
                net_arr: {
                  without_refunds: 0.68,
                  with_refunds: 0.61,
                },
                gross_arr: {
                  without_refunds: 0.87,
                  with_refunds: 0.76,
                },
                net_arr_spread: {
                  without_refunds: 7.48,
                  with_refunds: 7.48,
                },
                gross_arr_spread: {
                  without_refunds: 8.39,
                  with_refunds: 8.39,
                },
                net_total: {
                  without_refunds: 2.38,
                  with_refunds: 2.29,
                },
                gross_total: {
                  without_refunds: 2.76,
                  with_refunds: 2.66,
                },
                net_total_spread: {
                  without_refunds: 4.34,
                  with_refunds: 4.28,
                },
                gross_total_spread: {
                  without_refunds: 4.93,
                  with_refunds: 4.86,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.54,
                  with_refunds: 11.17,
                },
                gross_mrr: {
                  without_refunds: 13.27,
                  with_refunds: 12.86,
                },
                net_arr: {
                  without_refunds: 116.31,
                  with_refunds: 103.61,
                },
                gross_arr: {
                  without_refunds: 147.58,
                  with_refunds: 129.8,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.57,
                  with_refunds: 8.57,
                },
                net_total: {
                  without_refunds: 12.56,
                  with_refunds: 12.1,
                },
                gross_total: {
                  without_refunds: 14.58,
                  with_refunds: 14.03,
                },
                net_total_spread: {
                  without_refunds: 9.27,
                  with_refunds: 9.13,
                },
                gross_total_spread: {
                  without_refunds: 10.54,
                  with_refunds: 10.38,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.2,
                    with_refunds: 27.42,
                  },
                  gross_mrr: {
                    without_refunds: 33.68,
                    with_refunds: 32.76,
                  },
                  net_arr: {
                    without_refunds: 90.57,
                    with_refunds: 76.91,
                  },
                  gross_arr: {
                    without_refunds: 101.44,
                    with_refunds: 86.24,
                  },
                  net_arr_spread: {
                    without_refunds: 33.57,
                    with_refunds: 33.57,
                  },
                  gross_arr_spread: {
                    without_refunds: 37.63,
                    with_refunds: 37.63,
                  },
                  net_total: {
                    without_refunds: 47.83,
                    with_refunds: 43.04,
                  },
                  gross_total: {
                    without_refunds: 55.03,
                    with_refunds: 49.66,
                  },
                  net_total_spread: {
                    without_refunds: 29.85,
                    with_refunds: 29.29,
                  },
                  gross_total_spread: {
                    without_refunds: 34.98,
                    with_refunds: 34.31,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 56.46,
                    with_refunds: 54.72,
                  },
                  gross_mrr: {
                    without_refunds: 66.85,
                    with_refunds: 64.82,
                  },
                  net_arr: {
                    without_refunds: 510.05,
                    with_refunds: 315.09,
                  },
                  gross_arr: {
                    without_refunds: 624.02,
                    with_refunds: 380.93,
                  },
                  net_arr_spread: {
                    without_refunds: 38.03,
                    with_refunds: 38.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.66,
                    with_refunds: 42.66,
                  },
                  net_total: {
                    without_refunds: 96.98,
                    with_refunds: 87.61,
                  },
                  gross_total: {
                    without_refunds: 112.03,
                    with_refunds: 101.4,
                  },
                  net_total_spread: {
                    without_refunds: 48.61,
                    with_refunds: 47.62,
                  },
                  gross_total_spread: {
                    without_refunds: 56.77,
                    with_refunds: 55.61,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4461,
                    with_refunds: 0.4338,
                  },
                  gross_mrr: {
                    without_refunds: 0.5329,
                    with_refunds: 0.5183,
                  },
                  net_arr: {
                    without_refunds: 1.4328,
                    with_refunds: 1.2167,
                  },
                  gross_arr: {
                    without_refunds: 1.6048,
                    with_refunds: 1.3643,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5311,
                    with_refunds: 0.5311,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5954,
                    with_refunds: 0.5954,
                  },
                  net_total: {
                    without_refunds: 0.7567,
                    with_refunds: 0.6808,
                  },
                  gross_total: {
                    without_refunds: 0.8706,
                    with_refunds: 0.7856,
                  },
                  net_total_spread: {
                    without_refunds: 0.4723,
                    with_refunds: 0.4634,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5533,
                    with_refunds: 0.5427,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8933,
                    with_refunds: 0.8657,
                  },
                  gross_mrr: {
                    without_refunds: 1.0577,
                    with_refunds: 1.0254,
                  },
                  net_arr: {
                    without_refunds: 8.0692,
                    with_refunds: 4.9849,
                  },
                  gross_arr: {
                    without_refunds: 9.8722,
                    with_refunds: 6.0264,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6016,
                    with_refunds: 0.6016,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.675,
                    with_refunds: 0.675,
                  },
                  net_total: {
                    without_refunds: 1.5343,
                    with_refunds: 1.386,
                  },
                  gross_total: {
                    without_refunds: 1.7723,
                    with_refunds: 1.6042,
                  },
                  net_total_spread: {
                    without_refunds: 0.769,
                    with_refunds: 0.7534,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8982,
                    with_refunds: 0.8798,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 785,
                  with_refunds: 788,
                },
                arr_users: {
                  without_refunds: 18,
                  with_refunds: 19,
                },
                arr_spread_users: {
                  without_refunds: 1300,
                  with_refunds: 1300,
                },
                total_users: {
                  without_refunds: 802,
                  with_refunds: 804,
                },
                total_spread_users: {
                  without_refunds: 2084,
                  with_refunds: 2086,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2285,
                  with_refunds: 0.2294,
                },
                arr_users: {
                  without_refunds: 0.0117,
                  with_refunds: 0.0124,
                },
                arr_spread_users: {
                  without_refunds: 0.9901,
                  with_refunds: 0.9901,
                },
                total_users: {
                  without_refunds: 0.1626,
                  with_refunds: 0.163,
                },
                total_spread_users: {
                  without_refunds: 0.4414,
                  with_refunds: 0.4419,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8486,
                  with_refunds: 0.8482,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2.1111,
                },
                arr_spread_users: {
                  without_refunds: 1.0109,
                  with_refunds: 1.0109,
                },
                total_users: {
                  without_refunds: 0.8596,
                  with_refunds: 0.8599,
                },
                total_spread_users: {
                  without_refunds: 0.943,
                  with_refunds: 0.943,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9105.42,
                  with_refunds: 8985.52,
                },
                gross_mrr: {
                  without_refunds: 10422.33,
                  with_refunds: 10286.2,
                },
                net_arr: {
                  without_refunds: 2170.21,
                  with_refunds: 1501.87,
                },
                gross_arr: {
                  without_refunds: 2718.24,
                  with_refunds: 1905.05,
                },
                net_arr_spread: {
                  without_refunds: 9955.33,
                  with_refunds: 9955.33,
                },
                gross_arr_spread: {
                  without_refunds: 11184.52,
                  with_refunds: 11184.52,
                },
                net_total: {
                  without_refunds: 11275.63,
                  with_refunds: 10487.39,
                },
                gross_total: {
                  without_refunds: 13140.57,
                  with_refunds: 12191.25,
                },
                net_total_spread: {
                  without_refunds: 19060.75,
                  with_refunds: 18940.85,
                },
                gross_total_spread: {
                  without_refunds: 21606.85,
                  with_refunds: 21470.73,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3454,
                  with_refunds: 0.351,
                },
                gross_mrr: {
                  without_refunds: 0.3201,
                  with_refunds: 0.3257,
                },
                net_arr: {
                  without_refunds: 0.0297,
                  with_refunds: 0.0227,
                },
                gross_arr: {
                  without_refunds: 0.033,
                  with_refunds: 0.0255,
                },
                net_arr_spread: {
                  without_refunds: 1.8859,
                  with_refunds: 1.8859,
                },
                gross_arr_spread: {
                  without_refunds: 1.8721,
                  with_refunds: 1.8721,
                },
                net_total: {
                  without_refunds: 0.1134,
                  with_refunds: 0.1143,
                },
                gross_total: {
                  without_refunds: 0.1143,
                  with_refunds: 0.1146,
                },
                net_total_spread: {
                  without_refunds: 0.6023,
                  with_refunds: 0.6133,
                },
                gross_total_spread: {
                  without_refunds: 0.5608,
                  with_refunds: 0.5717,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8534,
                  with_refunds: 0.8658,
                },
                gross_mrr: {
                  without_refunds: 0.8491,
                  with_refunds: 0.8608,
                },
                net_arr: {
                  without_refunds: 2.0731,
                  with_refunds: 1.6106,
                },
                gross_arr: {
                  without_refunds: 2.0465,
                  with_refunds: 1.6308,
                },
                net_arr_spread: {
                  without_refunds: 1.0134,
                  with_refunds: 1.0134,
                },
                gross_arr_spread: {
                  without_refunds: 1.0151,
                  with_refunds: 1.0151,
                },
                net_total: {
                  without_refunds: 0.9623,
                  with_refunds: 0.9272,
                },
                gross_total: {
                  without_refunds: 0.966,
                  with_refunds: 0.9294,
                },
                net_total_spread: {
                  without_refunds: 0.9301,
                  with_refunds: 0.9376,
                },
                gross_total_spread: {
                  without_refunds: 0.9276,
                  with_refunds: 0.9348,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.65,
                  with_refunds: 2.62,
                },
                gross_mrr: {
                  without_refunds: 3.03,
                  with_refunds: 2.99,
                },
                net_arr: {
                  without_refunds: 1.41,
                  with_refunds: 0.98,
                },
                gross_arr: {
                  without_refunds: 1.77,
                  with_refunds: 1.24,
                },
                net_arr_spread: {
                  without_refunds: 7.58,
                  with_refunds: 7.58,
                },
                gross_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                net_total: {
                  without_refunds: 2.29,
                  with_refunds: 2.13,
                },
                gross_total: {
                  without_refunds: 2.66,
                  with_refunds: 2.47,
                },
                net_total_spread: {
                  without_refunds: 4.04,
                  with_refunds: 4.01,
                },
                gross_total_spread: {
                  without_refunds: 4.58,
                  with_refunds: 4.55,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.6,
                  with_refunds: 11.4,
                },
                gross_mrr: {
                  without_refunds: 13.28,
                  with_refunds: 13.05,
                },
                net_arr: {
                  without_refunds: 120.57,
                  with_refunds: 79.05,
                },
                gross_arr: {
                  without_refunds: 151.01,
                  with_refunds: 100.27,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.6,
                  with_refunds: 8.6,
                },
                net_total: {
                  without_refunds: 14.06,
                  with_refunds: 13.04,
                },
                gross_total: {
                  without_refunds: 16.38,
                  with_refunds: 15.16,
                },
                net_total_spread: {
                  without_refunds: 9.15,
                  with_refunds: 9.08,
                },
                gross_total_spread: {
                  without_refunds: 10.37,
                  with_refunds: 10.29,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.85,
                    with_refunds: 30.04,
                  },
                  gross_mrr: {
                    without_refunds: 36.72,
                    with_refunds: 35.76,
                  },
                  net_arr: {
                    without_refunds: 91.98,
                    with_refunds: 77.89,
                  },
                  gross_arr: {
                    without_refunds: 103.21,
                    with_refunds: 87.48,
                  },
                  net_arr_spread: {
                    without_refunds: 41.15,
                    with_refunds: 41.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 46.15,
                    with_refunds: 46.15,
                  },
                  net_total: {
                    without_refunds: 50.11,
                    with_refunds: 45.16,
                  },
                  gross_total: {
                    without_refunds: 57.7,
                    with_refunds: 52.13,
                  },
                  net_total_spread: {
                    without_refunds: 33.89,
                    with_refunds: 33.3,
                  },
                  gross_total_spread: {
                    without_refunds: 39.55,
                    with_refunds: 38.85,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 68.06,
                    with_refunds: 66.12,
                  },
                  gross_mrr: {
                    without_refunds: 80.13,
                    with_refunds: 77.87,
                  },
                  net_arr: {
                    without_refunds: 630.62,
                    with_refunds: 394.14,
                  },
                  gross_arr: {
                    without_refunds: 775.04,
                    with_refunds: 481.2,
                  },
                  net_arr_spread: {
                    without_refunds: 45.68,
                    with_refunds: 45.68,
                  },
                  gross_arr_spread: {
                    without_refunds: 51.27,
                    with_refunds: 51.27,
                  },
                  net_total: {
                    without_refunds: 111.04,
                    with_refunds: 100.65,
                  },
                  gross_total: {
                    without_refunds: 128.41,
                    with_refunds: 116.57,
                  },
                  net_total_spread: {
                    without_refunds: 57.76,
                    with_refunds: 56.7,
                  },
                  gross_total_spread: {
                    without_refunds: 67.14,
                    with_refunds: 65.9,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4881,
                    with_refunds: 0.4752,
                  },
                  gross_mrr: {
                    without_refunds: 0.5809,
                    with_refunds: 0.5657,
                  },
                  net_arr: {
                    without_refunds: 1.4552,
                    with_refunds: 1.2322,
                  },
                  gross_arr: {
                    without_refunds: 1.6328,
                    with_refunds: 1.3839,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6511,
                    with_refunds: 0.6511,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7302,
                    with_refunds: 0.7302,
                  },
                  net_total: {
                    without_refunds: 0.7928,
                    with_refunds: 0.7145,
                  },
                  gross_total: {
                    without_refunds: 0.9128,
                    with_refunds: 0.8247,
                  },
                  net_total_spread: {
                    without_refunds: 0.5362,
                    with_refunds: 0.5269,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6257,
                    with_refunds: 0.6147,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0768,
                    with_refunds: 1.0461,
                  },
                  gross_mrr: {
                    without_refunds: 1.2677,
                    with_refunds: 1.2319,
                  },
                  net_arr: {
                    without_refunds: 9.9766,
                    with_refunds: 6.2354,
                  },
                  gross_arr: {
                    without_refunds: 12.2613,
                    with_refunds: 7.6127,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7227,
                    with_refunds: 0.7227,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8111,
                    with_refunds: 0.8111,
                  },
                  net_total: {
                    without_refunds: 1.7567,
                    with_refunds: 1.5924,
                  },
                  gross_total: {
                    without_refunds: 2.0315,
                    with_refunds: 1.8441,
                  },
                  net_total_spread: {
                    without_refunds: 0.9137,
                    with_refunds: 0.8971,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0622,
                    with_refunds: 1.0426,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 670,
                  with_refunds: 671,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 8,
                },
                arr_spread_users: {
                  without_refunds: 1307,
                  with_refunds: 1307,
                },
                total_users: {
                  without_refunds: 678,
                  with_refunds: 679,
                },
                total_spread_users: {
                  without_refunds: 1977,
                  with_refunds: 1978,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1951,
                  with_refunds: 0.1953,
                },
                arr_users: {
                  without_refunds: 0.0052,
                  with_refunds: 0.0052,
                },
                arr_spread_users: {
                  without_refunds: 0.9954,
                  with_refunds: 0.9954,
                },
                total_users: {
                  without_refunds: 0.1375,
                  with_refunds: 0.1377,
                },
                total_spread_users: {
                  without_refunds: 0.4188,
                  with_refunds: 0.419,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8535,
                  with_refunds: 0.8515,
                },
                arr_users: {
                  without_refunds: 0.4444,
                  with_refunds: 0.4211,
                },
                arr_spread_users: {
                  without_refunds: 1.0054,
                  with_refunds: 1.0054,
                },
                total_users: {
                  without_refunds: 0.8454,
                  with_refunds: 0.8445,
                },
                total_spread_users: {
                  without_refunds: 0.9487,
                  with_refunds: 0.9482,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7603.92,
                  with_refunds: 7534.29,
                },
                gross_mrr: {
                  without_refunds: 8698.59,
                  with_refunds: 8612.86,
                },
                net_arr: {
                  without_refunds: 958.99,
                  with_refunds: 958.99,
                },
                gross_arr: {
                  without_refunds: 1200.59,
                  with_refunds: 1200.59,
                },
                net_arr_spread: {
                  without_refunds: 10025.01,
                  with_refunds: 10025.01,
                },
                gross_arr_spread: {
                  without_refunds: 11271.56,
                  with_refunds: 11271.56,
                },
                net_total: {
                  without_refunds: 8562.91,
                  with_refunds: 8493.28,
                },
                gross_total: {
                  without_refunds: 9899.18,
                  with_refunds: 9813.45,
                },
                net_total_spread: {
                  without_refunds: 17628.93,
                  with_refunds: 17559.31,
                },
                gross_total_spread: {
                  without_refunds: 19970.15,
                  with_refunds: 19884.42,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2884,
                  with_refunds: 0.2943,
                },
                gross_mrr: {
                  without_refunds: 0.2672,
                  with_refunds: 0.2727,
                },
                net_arr: {
                  without_refunds: 0.0131,
                  with_refunds: 0.0145,
                },
                gross_arr: {
                  without_refunds: 0.0146,
                  with_refunds: 0.016,
                },
                net_arr_spread: {
                  without_refunds: 1.8991,
                  with_refunds: 1.8991,
                },
                gross_arr_spread: {
                  without_refunds: 1.8867,
                  with_refunds: 1.8867,
                },
                net_total: {
                  without_refunds: 0.0861,
                  with_refunds: 0.0926,
                },
                gross_total: {
                  without_refunds: 0.0861,
                  with_refunds: 0.0922,
                },
                net_total_spread: {
                  without_refunds: 0.5571,
                  with_refunds: 0.5686,
                },
                gross_total_spread: {
                  without_refunds: 0.5183,
                  with_refunds: 0.5295,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8351,
                  with_refunds: 0.8385,
                },
                gross_mrr: {
                  without_refunds: 0.8346,
                  with_refunds: 0.8373,
                },
                net_arr: {
                  without_refunds: 0.4419,
                  with_refunds: 0.6385,
                },
                gross_arr: {
                  without_refunds: 0.4417,
                  with_refunds: 0.6302,
                },
                net_arr_spread: {
                  without_refunds: 1.007,
                  with_refunds: 1.007,
                },
                gross_arr_spread: {
                  without_refunds: 1.0078,
                  with_refunds: 1.0078,
                },
                net_total: {
                  without_refunds: 0.7594,
                  with_refunds: 0.8099,
                },
                gross_total: {
                  without_refunds: 0.7533,
                  with_refunds: 0.805,
                },
                net_total_spread: {
                  without_refunds: 0.9249,
                  with_refunds: 0.9271,
                },
                gross_total_spread: {
                  without_refunds: 0.9243,
                  with_refunds: 0.9261,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.21,
                  with_refunds: 2.19,
                },
                gross_mrr: {
                  without_refunds: 2.53,
                  with_refunds: 2.51,
                },
                net_arr: {
                  without_refunds: 0.62,
                  with_refunds: 0.62,
                },
                gross_arr: {
                  without_refunds: 0.78,
                  with_refunds: 0.78,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.58,
                  with_refunds: 8.58,
                },
                net_total: {
                  without_refunds: 1.74,
                  with_refunds: 1.72,
                },
                gross_total: {
                  without_refunds: 2.01,
                  with_refunds: 1.99,
                },
                net_total_spread: {
                  without_refunds: 3.73,
                  with_refunds: 3.72,
                },
                gross_total_spread: {
                  without_refunds: 4.23,
                  with_refunds: 4.21,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.35,
                  with_refunds: 11.23,
                },
                gross_mrr: {
                  without_refunds: 12.98,
                  with_refunds: 12.84,
                },
                net_arr: {
                  without_refunds: 119.87,
                  with_refunds: 119.87,
                },
                gross_arr: {
                  without_refunds: 150.07,
                  with_refunds: 150.07,
                },
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 12.63,
                  with_refunds: 12.51,
                },
                gross_total: {
                  without_refunds: 14.6,
                  with_refunds: 14.45,
                },
                net_total_spread: {
                  without_refunds: 8.92,
                  with_refunds: 8.88,
                },
                gross_total_spread: {
                  without_refunds: 10.1,
                  with_refunds: 10.05,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 33.06,
                    with_refunds: 32.23,
                  },
                  gross_mrr: {
                    without_refunds: 39.25,
                    with_refunds: 38.26,
                  },
                  net_arr: {
                    without_refunds: 92.61,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 103.99,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 48.79,
                    with_refunds: 48.79,
                  },
                  gross_arr_spread: {
                    without_refunds: 54.74,
                    with_refunds: 54.74,
                  },
                  net_total: {
                    without_refunds: 51.85,
                    with_refunds: 46.88,
                  },
                  gross_total: {
                    without_refunds: 59.7,
                    with_refunds: 54.12,
                  },
                  net_total_spread: {
                    without_refunds: 37.63,
                    with_refunds: 37.02,
                  },
                  gross_total_spread: {
                    without_refunds: 43.78,
                    with_refunds: 43.06,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 79.41,
                    with_refunds: 77.35,
                  },
                  gross_mrr: {
                    without_refunds: 93.11,
                    with_refunds: 90.71,
                  },
                  net_arr: {
                    without_refunds: 750.5,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 925.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 53.35,
                    with_refunds: 53.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 59.89,
                    with_refunds: 59.89,
                  },
                  net_total: {
                    without_refunds: 123.67,
                    with_refunds: 113.16,
                  },
                  gross_total: {
                    without_refunds: 143.01,
                    with_refunds: 131.02,
                  },
                  net_total_spread: {
                    without_refunds: 66.67,
                    with_refunds: 65.58,
                  },
                  gross_total_spread: {
                    without_refunds: 77.24,
                    with_refunds: 75.96,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5231,
                    with_refunds: 0.5099,
                  },
                  gross_mrr: {
                    without_refunds: 0.621,
                    with_refunds: 0.6054,
                  },
                  net_arr: {
                    without_refunds: 1.4651,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6452,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7719,
                    with_refunds: 0.7719,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.866,
                    with_refunds: 0.866,
                  },
                  net_total: {
                    without_refunds: 0.8203,
                    with_refunds: 0.7417,
                  },
                  gross_total: {
                    without_refunds: 0.9445,
                    with_refunds: 0.8562,
                  },
                  net_total_spread: {
                    without_refunds: 0.5953,
                    with_refunds: 0.5857,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6926,
                    with_refunds: 0.6813,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2563,
                    with_refunds: 1.2237,
                  },
                  gross_mrr: {
                    without_refunds: 1.4731,
                    with_refunds: 1.435,
                  },
                  net_arr: {
                    without_refunds: 11.873,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 14.6355,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8441,
                    with_refunds: 0.8441,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9475,
                    with_refunds: 0.9475,
                  },
                  net_total: {
                    without_refunds: 1.9565,
                    with_refunds: 1.7903,
                  },
                  gross_total: {
                    without_refunds: 2.2625,
                    with_refunds: 2.0728,
                  },
                  net_total_spread: {
                    without_refunds: 1.0548,
                    with_refunds: 1.0375,
                  },
                  gross_total_spread: {
                    without_refunds: 1.222,
                    with_refunds: 1.2017,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 577,
                  with_refunds: 578,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 1308,
                  with_refunds: 1308,
                },
                total_users: {
                  without_refunds: 578,
                  with_refunds: 580,
                },
                total_spread_users: {
                  without_refunds: 1885,
                  with_refunds: 1886,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.168,
                  with_refunds: 0.1683,
                },
                arr_users: {
                  without_refunds: 0.0007,
                  with_refunds: 0.0013,
                },
                arr_spread_users: {
                  without_refunds: 0.9962,
                  with_refunds: 0.9962,
                },
                total_users: {
                  without_refunds: 0.1172,
                  with_refunds: 0.1176,
                },
                total_spread_users: {
                  without_refunds: 0.3993,
                  with_refunds: 0.3995,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8612,
                  with_refunds: 0.8614,
                },
                arr_users: {
                  without_refunds: 0.125,
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                total_users: {
                  without_refunds: 0.8525,
                  with_refunds: 0.8542,
                },
                total_spread_users: {
                  without_refunds: 0.9535,
                  with_refunds: 0.9535,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6679.88,
                  with_refunds: 6609.06,
                },
                gross_mrr: {
                  without_refunds: 7643.33,
                  with_refunds: 7565.04,
                },
                net_arr: {
                  without_refunds: 83.7,
                },
                gross_arr: {
                  without_refunds: 119,
                },
                net_arr_spread: {
                  without_refunds: 10031.99,
                  with_refunds: 10031.99,
                },
                gross_arr_spread: {
                  without_refunds: 11281.48,
                  with_refunds: 11281.48,
                },
                net_total: {
                  without_refunds: 6763.58,
                  with_refunds: 6609.06,
                },
                gross_total: {
                  without_refunds: 7762.33,
                  with_refunds: 7565.04,
                },
                net_total_spread: {
                  without_refunds: 16711.87,
                  with_refunds: 16641.05,
                },
                gross_total_spread: {
                  without_refunds: 18924.81,
                  with_refunds: 18846.52,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2534,
                  with_refunds: 0.2581,
                },
                gross_mrr: {
                  without_refunds: 0.2348,
                  with_refunds: 0.2395,
                },
                net_arr: {
                  without_refunds: 0.0011,
                },
                gross_arr: {
                  without_refunds: 0.0014,
                },
                net_arr_spread: {
                  without_refunds: 1.9005,
                  with_refunds: 1.9005,
                },
                gross_arr_spread: {
                  without_refunds: 1.8884,
                  with_refunds: 1.8884,
                },
                net_total: {
                  without_refunds: 0.068,
                  with_refunds: 0.0721,
                },
                gross_total: {
                  without_refunds: 0.0675,
                  with_refunds: 0.0711,
                },
                net_total_spread: {
                  without_refunds: 0.5281,
                  with_refunds: 0.5389,
                },
                gross_total_spread: {
                  without_refunds: 0.4912,
                  with_refunds: 0.5018,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8785,
                  with_refunds: 0.8772,
                },
                gross_mrr: {
                  without_refunds: 0.8787,
                  with_refunds: 0.8783,
                },
                net_arr: {
                  without_refunds: 0.0873,
                },
                gross_arr: {
                  without_refunds: 0.0991,
                },
                net_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                gross_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                net_total: {
                  without_refunds: 0.7899,
                  with_refunds: 0.7782,
                },
                gross_total: {
                  without_refunds: 0.7841,
                  with_refunds: 0.7709,
                },
                net_total_spread: {
                  without_refunds: 0.948,
                  with_refunds: 0.9477,
                },
                gross_total_spread: {
                  without_refunds: 0.9477,
                  with_refunds: 0.9478,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.94,
                  with_refunds: 1.92,
                },
                gross_mrr: {
                  without_refunds: 2.23,
                  with_refunds: 2.2,
                },
                net_arr: {
                  without_refunds: 0.05,
                },
                gross_arr: {
                  without_refunds: 0.08,
                },
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 1.37,
                  with_refunds: 1.34,
                },
                gross_total: {
                  without_refunds: 1.57,
                  with_refunds: 1.53,
                },
                net_total_spread: {
                  without_refunds: 3.54,
                  with_refunds: 3.52,
                },
                gross_total_spread: {
                  without_refunds: 4.01,
                  with_refunds: 3.99,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.58,
                  with_refunds: 11.43,
                },
                gross_mrr: {
                  without_refunds: 13.25,
                  with_refunds: 13.09,
                },
                net_arr: {
                  without_refunds: 83.7,
                },
                gross_arr: {
                  without_refunds: 119,
                },
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 11.7,
                  with_refunds: 11.39,
                },
                gross_total: {
                  without_refunds: 13.43,
                  with_refunds: 13.04,
                },
                net_total_spread: {
                  without_refunds: 8.87,
                  with_refunds: 8.82,
                },
                gross_total_spread: {
                  without_refunds: 10.04,
                  with_refunds: 9.99,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.01,
                    with_refunds: 34.16,
                  },
                  gross_mrr: {
                    without_refunds: 41.48,
                    with_refunds: 40.47,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 56.43,
                    with_refunds: 56.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 63.33,
                    with_refunds: 63.33,
                  },
                  net_total: {
                    without_refunds: 53.22,
                    with_refunds: 48.22,
                  },
                  gross_total: {
                    without_refunds: 61.28,
                    with_refunds: 55.65,
                  },
                  net_total_spread: {
                    without_refunds: 41.17,
                    with_refunds: 40.55,
                  },
                  gross_total_spread: {
                    without_refunds: 47.79,
                    with_refunds: 47.06,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 90.99,
                    with_refunds: 88.79,
                  },
                  gross_mrr: {
                    without_refunds: 106.36,
                    with_refunds: 103.79,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 61.02,
                    with_refunds: 61.02,
                  },
                  gross_arr_spread: {
                    without_refunds: 68.52,
                    with_refunds: 68.52,
                  },
                  net_total: {
                    without_refunds: 135.37,
                    with_refunds: 124.56,
                  },
                  gross_total: {
                    without_refunds: 156.44,
                    with_refunds: 144.06,
                  },
                  net_total_spread: {
                    without_refunds: 75.54,
                    with_refunds: 74.41,
                  },
                  gross_total_spread: {
                    without_refunds: 87.28,
                    with_refunds: 85.95,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5538,
                    with_refunds: 0.5404,
                  },
                  gross_mrr: {
                    without_refunds: 0.6562,
                    with_refunds: 0.6402,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8927,
                    with_refunds: 0.8927,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0019,
                    with_refunds: 1.0019,
                  },
                  net_total: {
                    without_refunds: 0.842,
                    with_refunds: 0.7629,
                  },
                  gross_total: {
                    without_refunds: 0.9694,
                    with_refunds: 0.8805,
                  },
                  net_total_spread: {
                    without_refunds: 0.6513,
                    with_refunds: 0.6415,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7561,
                    with_refunds: 0.7445,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.4395,
                    with_refunds: 1.4046,
                  },
                  gross_mrr: {
                    without_refunds: 1.6827,
                    with_refunds: 1.642,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9654,
                    with_refunds: 0.9654,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.084,
                    with_refunds: 1.084,
                  },
                  net_total: {
                    without_refunds: 2.1416,
                    with_refunds: 1.9705,
                  },
                  gross_total: {
                    without_refunds: 2.4749,
                    with_refunds: 2.2791,
                  },
                  net_total_spread: {
                    without_refunds: 1.1951,
                    with_refunds: 1.1771,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3808,
                    with_refunds: 1.3597,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 506,
                  with_refunds: 508,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1308,
                  with_refunds: 1308,
                },
                total_users: {
                  without_refunds: 506,
                  with_refunds: 508,
                },
                total_spread_users: {
                  without_refunds: 1814,
                  with_refunds: 1816,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1473,
                  with_refunds: 0.1479,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9962,
                  with_refunds: 0.9962,
                },
                total_users: {
                  without_refunds: 0.1026,
                  with_refunds: 0.103,
                },
                total_spread_users: {
                  without_refunds: 0.3842,
                  with_refunds: 0.3847,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8789,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8754,
                  with_refunds: 0.8759,
                },
                total_spread_users: {
                  without_refunds: 0.9623,
                  with_refunds: 0.9629,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5828.68,
                  with_refunds: 5716.74,
                },
                gross_mrr: {
                  without_refunds: 6665.57,
                  with_refunds: 6543.51,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10031.99,
                  with_refunds: 10031.99,
                },
                gross_arr_spread: {
                  without_refunds: 11281.48,
                  with_refunds: 11281.48,
                },
                net_total: {
                  without_refunds: 5828.68,
                  with_refunds: 5716.74,
                },
                gross_total: {
                  without_refunds: 6665.57,
                  with_refunds: 6543.51,
                },
                net_total_spread: {
                  without_refunds: 15860.67,
                  with_refunds: 15748.72,
                },
                gross_total_spread: {
                  without_refunds: 17947.05,
                  with_refunds: 17824.98,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2211,
                  with_refunds: 0.2233,
                },
                gross_mrr: {
                  without_refunds: 0.2047,
                  with_refunds: 0.2072,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9005,
                  with_refunds: 1.9005,
                },
                gross_arr_spread: {
                  without_refunds: 1.8884,
                  with_refunds: 1.8884,
                },
                net_total: {
                  without_refunds: 0.0586,
                  with_refunds: 0.0623,
                },
                gross_total: {
                  without_refunds: 0.058,
                  with_refunds: 0.0615,
                },
                net_total_spread: {
                  without_refunds: 0.5012,
                  with_refunds: 0.51,
                },
                gross_total_spread: {
                  without_refunds: 0.4658,
                  with_refunds: 0.4746,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8726,
                  with_refunds: 0.865,
                },
                gross_mrr: {
                  without_refunds: 0.8721,
                  with_refunds: 0.865,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8618,
                  with_refunds: 0.865,
                },
                gross_total: {
                  without_refunds: 0.8587,
                  with_refunds: 0.865,
                },
                net_total_spread: {
                  without_refunds: 0.9491,
                  with_refunds: 0.9464,
                },
                gross_total_spread: {
                  without_refunds: 0.9483,
                  with_refunds: 0.9458,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.7,
                  with_refunds: 1.66,
                },
                gross_mrr: {
                  without_refunds: 1.94,
                  with_refunds: 1.9,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 1.18,
                  with_refunds: 1.16,
                },
                gross_total: {
                  without_refunds: 1.35,
                  with_refunds: 1.33,
                },
                net_total_spread: {
                  without_refunds: 3.36,
                  with_refunds: 3.34,
                },
                gross_total_spread: {
                  without_refunds: 3.8,
                  with_refunds: 3.78,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.52,
                  with_refunds: 11.25,
                },
                gross_mrr: {
                  without_refunds: 13.17,
                  with_refunds: 12.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 11.52,
                  with_refunds: 11.25,
                },
                gross_total: {
                  without_refunds: 13.17,
                  with_refunds: 12.88,
                },
                net_total_spread: {
                  without_refunds: 8.74,
                  with_refunds: 8.67,
                },
                gross_total_spread: {
                  without_refunds: 9.89,
                  with_refunds: 9.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.71,
                    with_refunds: 35.82,
                  },
                  gross_mrr: {
                    without_refunds: 43.42,
                    with_refunds: 42.37,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 64.07,
                    with_refunds: 64.07,
                  },
                  gross_arr_spread: {
                    without_refunds: 71.92,
                    with_refunds: 71.92,
                  },
                  net_total: {
                    without_refunds: 54.4,
                    with_refunds: 49.38,
                  },
                  gross_total: {
                    without_refunds: 62.63,
                    with_refunds: 56.98,
                  },
                  net_total_spread: {
                    without_refunds: 44.53,
                    with_refunds: 43.88,
                  },
                  gross_total_spread: {
                    without_refunds: 51.59,
                    with_refunds: 50.83,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 102.51,
                    with_refunds: 100.04,
                  },
                  gross_mrr: {
                    without_refunds: 119.53,
                    with_refunds: 116.67,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 68.69,
                    with_refunds: 68.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 77.14,
                    with_refunds: 77.14,
                  },
                  net_total: {
                    without_refunds: 146.89,
                    with_refunds: 135.81,
                  },
                  gross_total: {
                    without_refunds: 169.61,
                    with_refunds: 156.94,
                  },
                  net_total_spread: {
                    without_refunds: 84.28,
                    with_refunds: 83.08,
                  },
                  gross_total_spread: {
                    without_refunds: 97.18,
                    with_refunds: 95.76,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5807,
                    with_refunds: 0.5667,
                  },
                  gross_mrr: {
                    without_refunds: 0.6869,
                    with_refunds: 0.6703,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0136,
                    with_refunds: 1.0136,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1378,
                    with_refunds: 1.1378,
                  },
                  net_total: {
                    without_refunds: 0.8607,
                    with_refunds: 0.7813,
                  },
                  gross_total: {
                    without_refunds: 0.9908,
                    with_refunds: 0.9014,
                  },
                  net_total_spread: {
                    without_refunds: 0.7044,
                    with_refunds: 0.6942,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8162,
                    with_refunds: 0.8042,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.6217,
                    with_refunds: 1.5827,
                  },
                  gross_mrr: {
                    without_refunds: 1.8911,
                    with_refunds: 1.8458,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0868,
                    with_refunds: 1.0868,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2204,
                    with_refunds: 1.2204,
                  },
                  net_total: {
                    without_refunds: 2.3239,
                    with_refunds: 2.1486,
                  },
                  gross_total: {
                    without_refunds: 2.6833,
                    with_refunds: 2.4829,
                  },
                  net_total_spread: {
                    without_refunds: 1.3334,
                    with_refunds: 1.3143,
                  },
                  gross_total_spread: {
                    without_refunds: 1.5374,
                    with_refunds: 1.515,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 118,
                  with_refunds: 119,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1308,
                  with_refunds: 1308,
                },
                total_users: {
                  without_refunds: 118,
                  with_refunds: 119,
                },
                total_spread_users: {
                  without_refunds: 1426,
                  with_refunds: 1427,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0344,
                  with_refunds: 0.0346,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9962,
                  with_refunds: 0.9962,
                },
                total_users: {
                  without_refunds: 0.0239,
                  with_refunds: 0.0241,
                },
                total_spread_users: {
                  without_refunds: 0.3021,
                  with_refunds: 0.3023,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2332,
                  with_refunds: 0.2343,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.2332,
                  with_refunds: 0.2343,
                },
                total_spread_users: {
                  without_refunds: 0.7861,
                  with_refunds: 0.7858,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1344.14,
                  with_refunds: 1332.79,
                },
                gross_mrr: {
                  without_refunds: 1531.05,
                  with_refunds: 1519.05,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10031.99,
                  with_refunds: 10031.99,
                },
                gross_arr_spread: {
                  without_refunds: 11281.48,
                  with_refunds: 11281.48,
                },
                net_total: {
                  without_refunds: 1344.14,
                  with_refunds: 1332.79,
                },
                gross_total: {
                  without_refunds: 1531.05,
                  with_refunds: 1519.05,
                },
                net_total_spread: {
                  without_refunds: 11376.13,
                  with_refunds: 11364.78,
                },
                gross_total_spread: {
                  without_refunds: 12812.53,
                  with_refunds: 12800.53,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.051,
                  with_refunds: 0.0521,
                },
                gross_mrr: {
                  without_refunds: 0.047,
                  with_refunds: 0.0481,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9005,
                  with_refunds: 1.9005,
                },
                gross_arr_spread: {
                  without_refunds: 1.8884,
                  with_refunds: 1.8884,
                },
                net_total: {
                  without_refunds: 0.0135,
                  with_refunds: 0.0145,
                },
                gross_total: {
                  without_refunds: 0.0133,
                  with_refunds: 0.0143,
                },
                net_total_spread: {
                  without_refunds: 0.3595,
                  with_refunds: 0.368,
                },
                gross_total_spread: {
                  without_refunds: 0.3325,
                  with_refunds: 0.3408,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2306,
                  with_refunds: 0.2331,
                },
                gross_mrr: {
                  without_refunds: 0.2297,
                  with_refunds: 0.2321,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.2306,
                  with_refunds: 0.2331,
                },
                gross_total: {
                  without_refunds: 0.2297,
                  with_refunds: 0.2321,
                },
                net_total_spread: {
                  without_refunds: 0.7173,
                  with_refunds: 0.7216,
                },
                gross_total_spread: {
                  without_refunds: 0.7139,
                  with_refunds: 0.7181,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.39,
                  with_refunds: 0.39,
                },
                gross_mrr: {
                  without_refunds: 0.45,
                  with_refunds: 0.44,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.64,
                  with_refunds: 7.64,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 0.27,
                  with_refunds: 0.27,
                },
                gross_total: {
                  without_refunds: 0.31,
                  with_refunds: 0.31,
                },
                net_total_spread: {
                  without_refunds: 2.41,
                  with_refunds: 2.41,
                },
                gross_total_spread: {
                  without_refunds: 2.71,
                  with_refunds: 2.71,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.39,
                  with_refunds: 11.2,
                },
                gross_mrr: {
                  without_refunds: 12.97,
                  with_refunds: 12.77,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                gross_arr_spread: {
                  without_refunds: 8.62,
                  with_refunds: 8.62,
                },
                net_total: {
                  without_refunds: 11.39,
                  with_refunds: 11.2,
                },
                gross_total: {
                  without_refunds: 12.97,
                  with_refunds: 12.77,
                },
                net_total_spread: {
                  without_refunds: 7.98,
                  with_refunds: 7.96,
                },
                gross_total_spread: {
                  without_refunds: 8.98,
                  with_refunds: 8.97,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.1,
                    with_refunds: 36.21,
                  },
                  gross_mrr: {
                    without_refunds: 43.86,
                    with_refunds: 42.81,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 71.71,
                    with_refunds: 71.71,
                  },
                  gross_arr_spread: {
                    without_refunds: 80.51,
                    with_refunds: 80.51,
                  },
                  net_total: {
                    without_refunds: 54.68,
                    with_refunds: 49.65,
                  },
                  gross_total: {
                    without_refunds: 62.94,
                    with_refunds: 57.29,
                  },
                  net_total_spread: {
                    without_refunds: 46.94,
                    with_refunds: 46.29,
                  },
                  gross_total_spread: {
                    without_refunds: 54.31,
                    with_refunds: 53.54,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 113.9,
                    with_refunds: 111.24,
                  },
                  gross_mrr: {
                    without_refunds: 132.51,
                    with_refunds: 129.44,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 76.36,
                    with_refunds: 76.36,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.77,
                    with_refunds: 85.77,
                  },
                  net_total: {
                    without_refunds: 158.28,
                    with_refunds: 147.01,
                  },
                  gross_total: {
                    without_refunds: 182.59,
                    with_refunds: 169.71,
                  },
                  net_total_spread: {
                    without_refunds: 92.26,
                    with_refunds: 91.04,
                  },
                  gross_total_spread: {
                    without_refunds: 106.16,
                    with_refunds: 104.74,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5869,
                    with_refunds: 0.5728,
                  },
                  gross_mrr: {
                    without_refunds: 0.6939,
                    with_refunds: 0.6773,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1345,
                    with_refunds: 1.1345,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2738,
                    with_refunds: 1.2738,
                  },
                  net_total: {
                    without_refunds: 0.865,
                    with_refunds: 0.7855,
                  },
                  gross_total: {
                    without_refunds: 0.9957,
                    with_refunds: 0.9063,
                  },
                  net_total_spread: {
                    without_refunds: 0.7425,
                    with_refunds: 0.7323,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8591,
                    with_refunds: 0.8471,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.8019,
                    with_refunds: 1.7599,
                  },
                  gross_mrr: {
                    without_refunds: 2.0963,
                    with_refunds: 2.0478,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2081,
                    with_refunds: 1.2081,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3569,
                    with_refunds: 1.3569,
                  },
                  net_total: {
                    without_refunds: 2.5041,
                    with_refunds: 2.3257,
                  },
                  gross_total: {
                    without_refunds: 2.8886,
                    with_refunds: 2.6848,
                  },
                  net_total_spread: {
                    without_refunds: 1.4596,
                    with_refunds: 1.4403,
                  },
                  gross_total_spread: {
                    without_refunds: 1.6795,
                    with_refunds: 1.6569,
                  },
                },
              },
            },
          },
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 83.36,
                  with_refunds: 83.94,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1315.34,
                  with_refunds: 1315.34,
                },
                total_users: {
                  without_refunds: 83.39,
                  with_refunds: 83.97,
                },
                total_spread_users: {
                  without_refunds: 1257.44,
                  with_refunds: 1257.07,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0243,
                  with_refunds: 0.0244,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0018,
                  with_refunds: 1.0018,
                },
                total_users: {
                  without_refunds: 0.0169,
                  with_refunds: 0.017,
                },
                total_spread_users: {
                  without_refunds: 0.2664,
                  with_refunds: 0.2663,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 948.38,
                  with_refunds: 944.73,
                },
                gross_mrr: {
                  without_refunds: 1078.3,
                  with_refunds: 1074.65,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10111.01,
                  with_refunds: 10111.01,
                },
                gross_arr_spread: {
                  without_refunds: 11357.11,
                  with_refunds: 11357.11,
                },
                net_total: {
                  without_refunds: 951.84,
                  with_refunds: 948.69,
                },
                gross_total: {
                  without_refunds: 1082.25,
                  with_refunds: 1079.04,
                },
                net_total_spread: {
                  without_refunds: 9704.59,
                  with_refunds: 9727.53,
                },
                gross_total_spread: {
                  without_refunds: 10949.23,
                  with_refunds: 10973.12,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.036,
                  with_refunds: 0.0369,
                },
                gross_mrr: {
                  without_refunds: 0.0331,
                  with_refunds: 0.034,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9154,
                  with_refunds: 1.9154,
                },
                gross_arr_spread: {
                  without_refunds: 1.901,
                  with_refunds: 1.901,
                },
                net_total: {
                  without_refunds: 0.0096,
                  with_refunds: 0.0103,
                },
                gross_total: {
                  without_refunds: 0.0094,
                  with_refunds: 0.0101,
                },
                net_total_spread: {
                  without_refunds: 0.3067,
                  with_refunds: 0.315,
                },
                gross_total_spread: {
                  without_refunds: 0.2842,
                  with_refunds: 0.2922,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.28,
                  with_refunds: 0.28,
                },
                gross_mrr: {
                  without_refunds: 0.31,
                  with_refunds: 0.31,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.65,
                  with_refunds: 8.65,
                },
                net_total: {
                  without_refunds: 0.19,
                  with_refunds: 0.19,
                },
                gross_total: {
                  without_refunds: 0.22,
                  with_refunds: 0.22,
                },
                net_total_spread: {
                  without_refunds: 2.06,
                  with_refunds: 2.06,
                },
                gross_total_spread: {
                  without_refunds: 2.32,
                  with_refunds: 2.32,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.38,
                  with_refunds: 11.26,
                },
                gross_mrr: {
                  without_refunds: 12.94,
                  with_refunds: 12.8,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.69,
                  with_refunds: 7.69,
                },
                gross_arr_spread: {
                  without_refunds: 8.63,
                  with_refunds: 8.63,
                },
                net_total: {
                  without_refunds: 11.41,
                  with_refunds: 11.3,
                },
                gross_total: {
                  without_refunds: 12.98,
                  with_refunds: 12.85,
                },
                net_total_spread: {
                  without_refunds: 7.72,
                  with_refunds: 7.74,
                },
                gross_total_spread: {
                  without_refunds: 8.71,
                  with_refunds: 8.73,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.37,
                    with_refunds: 36.48,
                  },
                  gross_mrr: {
                    without_refunds: 44.18,
                    with_refunds: 43.13,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 79.41,
                    with_refunds: 79.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 89.16,
                    with_refunds: 89.16,
                  },
                  net_total: {
                    without_refunds: 54.87,
                    with_refunds: 49.85,
                  },
                  gross_total: {
                    without_refunds: 63.16,
                    with_refunds: 57.51,
                  },
                  net_total_spread: {
                    without_refunds: 48.99,
                    with_refunds: 48.35,
                  },
                  gross_total_spread: {
                    without_refunds: 56.63,
                    with_refunds: 55.87,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 125.28,
                    with_refunds: 122.5,
                  },
                  gross_mrr: {
                    without_refunds: 145.44,
                    with_refunds: 142.24,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 84.05,
                    with_refunds: 84.05,
                  },
                  gross_arr_spread: {
                    without_refunds: 94.4,
                    with_refunds: 94.4,
                  },
                  net_total: {
                    without_refunds: 169.7,
                    with_refunds: 158.31,
                  },
                  gross_total: {
                    without_refunds: 195.57,
                    with_refunds: 182.56,
                  },
                  net_total_spread: {
                    without_refunds: 99.98,
                    with_refunds: 98.78,
                  },
                  gross_total_spread: {
                    without_refunds: 114.87,
                    with_refunds: 113.46,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5912,
                    with_refunds: 0.5772,
                  },
                  gross_mrr: {
                    without_refunds: 0.6989,
                    with_refunds: 0.6823,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2563,
                    with_refunds: 1.2563,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4106,
                    with_refunds: 1.4106,
                  },
                  net_total: {
                    without_refunds: 0.868,
                    with_refunds: 0.7886,
                  },
                  gross_total: {
                    without_refunds: 0.9992,
                    with_refunds: 0.9098,
                  },
                  net_total_spread: {
                    without_refunds: 0.7751,
                    with_refunds: 0.7649,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8958,
                    with_refunds: 0.8839,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.9819,
                    with_refunds: 1.9379,
                  },
                  gross_mrr: {
                    without_refunds: 2.301,
                    with_refunds: 2.2503,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3297,
                    with_refunds: 1.3297,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4935,
                    with_refunds: 1.4935,
                  },
                  net_total: {
                    without_refunds: 2.6847,
                    with_refunds: 2.5045,
                  },
                  gross_total: {
                    without_refunds: 3.0939,
                    with_refunds: 2.8881,
                  },
                  net_total_spread: {
                    without_refunds: 1.5817,
                    with_refunds: 1.5627,
                  },
                  gross_total_spread: {
                    without_refunds: 1.8173,
                    with_refunds: 1.795,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 50.53,
                  with_refunds: 50.86,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1318.08,
                  with_refunds: 1318.08,
                },
                total_users: {
                  without_refunds: 50.38,
                  with_refunds: 50.71,
                },
                total_spread_users: {
                  without_refunds: 919.52,
                  with_refunds: 918.86,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0147,
                  with_refunds: 0.0148,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0039,
                  with_refunds: 1.0039,
                },
                total_users: {
                  without_refunds: 0.0102,
                  with_refunds: 0.0103,
                },
                total_spread_users: {
                  without_refunds: 0.1948,
                  with_refunds: 0.1946,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 578.06,
                  with_refunds: 579.71,
                },
                gross_mrr: {
                  without_refunds: 658.84,
                  with_refunds: 661.04,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10140.5,
                  with_refunds: 10140.5,
                },
                gross_arr_spread: {
                  without_refunds: 11383.11,
                  with_refunds: 11383.11,
                },
                net_total: {
                  without_refunds: 561.23,
                  with_refunds: 570.58,
                },
                gross_total: {
                  without_refunds: 634.8,
                  with_refunds: 648.27,
                },
                net_total_spread: {
                  without_refunds: 6691.45,
                  with_refunds: 6743.96,
                },
                gross_total_spread: {
                  without_refunds: 7768.18,
                  with_refunds: 7825.75,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0219,
                  with_refunds: 0.0226,
                },
                gross_mrr: {
                  without_refunds: 0.0202,
                  with_refunds: 0.0209,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.921,
                  with_refunds: 1.921,
                },
                gross_arr_spread: {
                  without_refunds: 1.9054,
                  with_refunds: 1.9054,
                },
                net_total: {
                  without_refunds: 0.0056,
                  with_refunds: 0.0062,
                },
                gross_total: {
                  without_refunds: 0.0055,
                  with_refunds: 0.0061,
                },
                net_total_spread: {
                  without_refunds: 0.2115,
                  with_refunds: 0.2184,
                },
                gross_total_spread: {
                  without_refunds: 0.2016,
                  with_refunds: 0.2084,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                gross_mrr: {
                  without_refunds: 0.19,
                  with_refunds: 0.19,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.72,
                  with_refunds: 7.72,
                },
                gross_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                net_total: {
                  without_refunds: 0.11,
                  with_refunds: 0.12,
                },
                gross_total: {
                  without_refunds: 0.13,
                  with_refunds: 0.13,
                },
                net_total_spread: {
                  without_refunds: 1.42,
                  with_refunds: 1.43,
                },
                gross_total_spread: {
                  without_refunds: 1.65,
                  with_refunds: 1.66,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.44,
                  with_refunds: 11.4,
                },
                gross_mrr: {
                  without_refunds: 13.04,
                  with_refunds: 13,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.69,
                  with_refunds: 7.69,
                },
                gross_arr_spread: {
                  without_refunds: 8.64,
                  with_refunds: 8.64,
                },
                net_total: {
                  without_refunds: 11.14,
                  with_refunds: 11.25,
                },
                gross_total: {
                  without_refunds: 12.6,
                  with_refunds: 12.78,
                },
                net_total_spread: {
                  without_refunds: 7.28,
                  with_refunds: 7.34,
                },
                gross_total_spread: {
                  without_refunds: 8.45,
                  with_refunds: 8.52,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.54,
                    with_refunds: 36.65,
                  },
                  gross_mrr: {
                    without_refunds: 44.37,
                    with_refunds: 43.32,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 87.13,
                    with_refunds: 87.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 97.83,
                    with_refunds: 97.83,
                  },
                  net_total: {
                    without_refunds: 54.98,
                    with_refunds: 49.96,
                  },
                  gross_total: {
                    without_refunds: 63.29,
                    with_refunds: 57.64,
                  },
                  net_total_spread: {
                    without_refunds: 50.41,
                    with_refunds: 49.78,
                  },
                  gross_total_spread: {
                    without_refunds: 58.27,
                    with_refunds: 57.53,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 136.71,
                    with_refunds: 133.89,
                  },
                  gross_mrr: {
                    without_refunds: 158.48,
                    with_refunds: 155.24,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 91.74,
                    with_refunds: 91.74,
                  },
                  gross_arr_spread: {
                    without_refunds: 103.04,
                    with_refunds: 103.04,
                  },
                  net_total: {
                    without_refunds: 180.84,
                    with_refunds: 169.56,
                  },
                  gross_total: {
                    without_refunds: 208.17,
                    with_refunds: 195.34,
                  },
                  net_total_spread: {
                    without_refunds: 107.26,
                    with_refunds: 106.12,
                  },
                  gross_total_spread: {
                    without_refunds: 123.32,
                    with_refunds: 121.98,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5939,
                    with_refunds: 0.5799,
                  },
                  gross_mrr: {
                    without_refunds: 0.7019,
                    with_refunds: 0.6853,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3785,
                    with_refunds: 1.3785,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5478,
                    with_refunds: 1.5478,
                  },
                  net_total: {
                    without_refunds: 0.8698,
                    with_refunds: 0.7904,
                  },
                  gross_total: {
                    without_refunds: 1.0012,
                    with_refunds: 0.9119,
                  },
                  net_total_spread: {
                    without_refunds: 0.7975,
                    with_refunds: 0.7875,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9219,
                    with_refunds: 0.9101,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.1629,
                    with_refunds: 2.1182,
                  },
                  gross_mrr: {
                    without_refunds: 2.5072,
                    with_refunds: 2.4559,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4514,
                    with_refunds: 1.4514,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6301,
                    with_refunds: 1.6301,
                  },
                  net_total: {
                    without_refunds: 2.8609,
                    with_refunds: 2.6825,
                  },
                  gross_total: {
                    without_refunds: 3.2933,
                    with_refunds: 3.0904,
                  },
                  net_total_spread: {
                    without_refunds: 1.6968,
                    with_refunds: 1.6788,
                  },
                  gross_total_spread: {
                    without_refunds: 1.9509,
                    with_refunds: 1.9298,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 15.19,
                  with_refunds: 15.26,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 448.48,
                  with_refunds: 448.48,
                },
                total_users: {
                  without_refunds: 15.11,
                  with_refunds: 15.18,
                },
                total_spread_users: {
                  without_refunds: 287.64,
                  with_refunds: 287.07,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0044,
                  with_refunds: 0.0044,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3416,
                  with_refunds: 0.3416,
                },
                total_users: {
                  without_refunds: 0.0031,
                  with_refunds: 0.0031,
                },
                total_spread_users: {
                  without_refunds: 0.0609,
                  with_refunds: 0.0608,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 172.88,
                  with_refunds: 173.69,
                },
                gross_mrr: {
                  without_refunds: 195.86,
                  with_refunds: 196.86,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 3892.44,
                  with_refunds: 3892.44,
                },
                gross_arr_spread: {
                  without_refunds: 4085.96,
                  with_refunds: 4085.96,
                },
                net_total: {
                  without_refunds: 165.01,
                  with_refunds: 168.06,
                },
                gross_total: {
                  without_refunds: 185.32,
                  with_refunds: 189.58,
                },
                net_total_spread: {
                  without_refunds: 2113.46,
                  with_refunds: 2133.18,
                },
                gross_total_spread: {
                  without_refunds: 2429.43,
                  with_refunds: 2450.66,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0066,
                  with_refunds: 0.0068,
                },
                gross_mrr: {
                  without_refunds: 0.006,
                  with_refunds: 0.0062,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.7374,
                  with_refunds: 0.7374,
                },
                gross_arr_spread: {
                  without_refunds: 0.6839,
                  with_refunds: 0.6839,
                },
                net_total: {
                  without_refunds: 0.0017,
                  with_refunds: 0.0018,
                },
                gross_total: {
                  without_refunds: 0.0016,
                  with_refunds: 0.0018,
                },
                net_total_spread: {
                  without_refunds: 0.0668,
                  with_refunds: 0.0691,
                },
                gross_total_spread: {
                  without_refunds: 0.0631,
                  with_refunds: 0.0653,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                gross_mrr: {
                  without_refunds: 0.06,
                  with_refunds: 0.06,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.96,
                  with_refunds: 2.96,
                },
                gross_arr_spread: {
                  without_refunds: 3.11,
                  with_refunds: 3.11,
                },
                net_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                gross_total: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                net_total_spread: {
                  without_refunds: 0.45,
                  with_refunds: 0.45,
                },
                gross_total_spread: {
                  without_refunds: 0.51,
                  with_refunds: 0.52,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.38,
                  with_refunds: 11.38,
                },
                gross_mrr: {
                  without_refunds: 12.9,
                  with_refunds: 12.9,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.68,
                  with_refunds: 8.68,
                },
                gross_arr_spread: {
                  without_refunds: 9.11,
                  with_refunds: 9.11,
                },
                net_total: {
                  without_refunds: 10.92,
                  with_refunds: 11.07,
                },
                gross_total: {
                  without_refunds: 12.26,
                  with_refunds: 12.49,
                },
                net_total_spread: {
                  without_refunds: 7.35,
                  with_refunds: 7.43,
                },
                gross_total_spread: {
                  without_refunds: 8.45,
                  with_refunds: 8.54,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.59,
                    with_refunds: 36.7,
                  },
                  gross_mrr: {
                    without_refunds: 44.42,
                    with_refunds: 43.38,
                  },
                  net_arr: {
                    without_refunds: 92.66,
                    with_refunds: 78.51,
                  },
                  gross_arr: {
                    without_refunds: 104.07,
                    with_refunds: 88.26,
                  },
                  net_arr_spread: {
                    without_refunds: 90.1,
                    with_refunds: 90.1,
                  },
                  gross_arr_spread: {
                    without_refunds: 100.95,
                    with_refunds: 100.95,
                  },
                  net_total: {
                    without_refunds: 55.02,
                    with_refunds: 50,
                  },
                  gross_total: {
                    without_refunds: 63.32,
                    with_refunds: 57.68,
                  },
                  net_total_spread: {
                    without_refunds: 50.86,
                    with_refunds: 50.23,
                  },
                  gross_total_spread: {
                    without_refunds: 58.79,
                    with_refunds: 58.05,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 148.1,
                    with_refunds: 145.28,
                  },
                  gross_mrr: {
                    without_refunds: 171.38,
                    with_refunds: 168.14,
                  },
                  net_arr: {
                    without_refunds: 834.19,
                    with_refunds: 514.01,
                  },
                  gross_arr: {
                    without_refunds: 1044.11,
                    with_refunds: 631.27,
                  },
                  net_arr_spread: {
                    without_refunds: 100.42,
                    with_refunds: 100.42,
                  },
                  gross_arr_spread: {
                    without_refunds: 112.15,
                    with_refunds: 112.15,
                  },
                  net_total: {
                    without_refunds: 191.76,
                    with_refunds: 180.63,
                  },
                  gross_total: {
                    without_refunds: 220.43,
                    with_refunds: 207.83,
                  },
                  net_total_spread: {
                    without_refunds: 114.6,
                    with_refunds: 113.55,
                  },
                  gross_total_spread: {
                    without_refunds: 131.76,
                    with_refunds: 130.52,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5947,
                    with_refunds: 0.5807,
                  },
                  gross_mrr: {
                    without_refunds: 0.7028,
                    with_refunds: 0.6862,
                  },
                  net_arr: {
                    without_refunds: 1.4659,
                    with_refunds: 1.2421,
                  },
                  gross_arr: {
                    without_refunds: 1.6464,
                    with_refunds: 1.3963,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4254,
                    with_refunds: 1.4254,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.597,
                    with_refunds: 1.597,
                  },
                  net_total: {
                    without_refunds: 0.8704,
                    with_refunds: 0.791,
                  },
                  gross_total: {
                    without_refunds: 1.0018,
                    with_refunds: 0.9125,
                  },
                  net_total_spread: {
                    without_refunds: 0.8046,
                    with_refunds: 0.7947,
                  },
                  gross_total_spread: {
                    without_refunds: 0.93,
                    with_refunds: 0.9183,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.3429,
                    with_refunds: 2.2983,
                  },
                  gross_mrr: {
                    without_refunds: 2.7112,
                    with_refunds: 2.66,
                  },
                  net_arr: {
                    without_refunds: 13.1972,
                    with_refunds: 8.1318,
                  },
                  gross_arr: {
                    without_refunds: 16.5182,
                    with_refunds: 9.9869,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5887,
                    with_refunds: 1.5887,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.7742,
                    with_refunds: 1.7742,
                  },
                  net_total: {
                    without_refunds: 3.0336,
                    with_refunds: 2.8576,
                  },
                  gross_total: {
                    without_refunds: 3.4873,
                    with_refunds: 3.2879,
                  },
                  net_total_spread: {
                    without_refunds: 1.8131,
                    with_refunds: 1.7964,
                  },
                  gross_total_spread: {
                    without_refunds: 2.0845,
                    with_refunds: 2.0648,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 2411,
              with_refunds: 2411,
            },
            returning_users: {
              without_refunds: 5423,
              with_refunds: 5448,
            },
            paying_users: {
              without_refunds: 7834,
              with_refunds: 7859,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 805,
              with_refunds: 805,
            },
            returning_users: {
              without_refunds: 508,
              with_refunds: 531,
            },
            paying_users: {
              without_refunds: 1313,
              with_refunds: 1336,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 3212,
              with_refunds: 3212,
            },
            returning_users: {
              without_refunds: 5926,
              with_refunds: 5973,
            },
            paying_users: {
              without_refunds: 9138,
              with_refunds: 9185,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 127427.34,
                with_refunds: 124377.98,
              },
              gross_mrr: {
                without_refunds: 150665.42,
                with_refunds: 147066.04,
              },
              net_arr: {
                without_refunds: 142235.72,
                with_refunds: 120515.78,
              },
              gross_arr: {
                without_refunds: 159747.67,
                with_refunds: 135481.15,
              },
              net_arr_spread: {
                without_refunds: 94155.62,
                with_refunds: 94155.62,
              },
              gross_arr_spread: {
                without_refunds: 105715.18,
                with_refunds: 105715.18,
              },
              net_total: {
                without_refunds: 269663.06,
                with_refunds: 244893.76,
              },
              gross_total: {
                without_refunds: 310413.09,
                with_refunds: 282547.19,
              },
              net_total_spread: {
                without_refunds: 221582.96,
                with_refunds: 218533.6,
              },
              gross_total_spread: {
                without_refunds: 256380.61,
                with_refunds: 252781.22,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 129126.66,
                with_refunds: 126076.11,
              },
              gross_mrr: {
                without_refunds: 152598.43,
                with_refunds: 148998.59,
              },
              net_arr: {
                without_refunds: 142235.72,
                with_refunds: 120515.78,
              },
              gross_arr: {
                without_refunds: 159747.67,
                with_refunds: 135481.15,
              },
              net_arr_spread: {
                without_refunds: 118299.57,
                with_refunds: 118299.57,
              },
              gross_arr_spread: {
                without_refunds: 132541.37,
                with_refunds: 132541.37,
              },
              net_total: {
                without_refunds: 271341.14,
                with_refunds: 246581.09,
              },
              gross_total: {
                without_refunds: 312315.47,
                with_refunds: 284464.08,
              },
              net_total_spread: {
                without_refunds: 240092.45,
                with_refunds: 237138.28,
              },
              gross_total_spread: {
                without_refunds: 277527.44,
                with_refunds: 274030.75,
              },
            },
          },
        },
      },
      cac: 63.21,
      mrr_ua_spend: {
        without_refunds: 217126.35,
        with_refunds: 217126.35,
      },
      arr_ua_spend: {
        without_refunds: 97027.35,
        with_refunds: 97027.35,
      },
      arr_spread_ua_spend: {
        without_refunds: 82994.73,
        with_refunds: 82994.73,
      },
      total_ua_spend: {
        without_refunds: 311751.72,
        with_refunds: 311751.72,
      },
      total_spread_ua_spend: {
        without_refunds: 298414.41,
        with_refunds: 298414.41,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 11,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 2452,
        with_refunds: 2452,
      },
      first_period_arr_users: {
        without_refunds: 1588,
        with_refunds: 1588,
      },
      first_period_arr_spread_users: {
        without_refunds: 1365,
        with_refunds: 1365,
      },
      first_period_total_users: {
        without_refunds: 3993,
        with_refunds: 3993,
      },
      first_period_total_spread_users: {
        without_refunds: 3785,
        with_refunds: 3785,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1406,
                  with_refunds: 1406,
                },
                arr_users: {
                  without_refunds: 904,
                  with_refunds: 904,
                },
                arr_spread_users: {
                  without_refunds: 783,
                  with_refunds: 783,
                },
                total_users: {
                  without_refunds: 2304,
                  with_refunds: 2304,
                },
                total_spread_users: {
                  without_refunds: 2186,
                  with_refunds: 2186,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5734,
                  with_refunds: 0.5734,
                },
                arr_users: {
                  without_refunds: 0.5693,
                  with_refunds: 0.5693,
                },
                arr_spread_users: {
                  without_refunds: 0.5736,
                  with_refunds: 0.5736,
                },
                total_users: {
                  without_refunds: 0.577,
                  with_refunds: 0.577,
                },
                total_spread_users: {
                  without_refunds: 0.5775,
                  with_refunds: 0.5775,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 16425.96,
                  with_refunds: 15740.65,
                },
                gross_mrr: {
                  without_refunds: 19901.31,
                  with_refunds: 19041.88,
                },
                net_arr: {
                  without_refunds: 81233.27,
                  with_refunds: 73764.38,
                },
                gross_arr: {
                  without_refunds: 91386.65,
                  with_refunds: 82980.84,
                },
                net_arr_spread: {
                  without_refunds: 5845.79,
                  with_refunds: 5845.79,
                },
                gross_arr_spread: {
                  without_refunds: 6575.6,
                  with_refunds: 6575.6,
                },
                net_total: {
                  without_refunds: 97659.23,
                  with_refunds: 89505.04,
                },
                gross_total: {
                  without_refunds: 111287.97,
                  with_refunds: 102022.73,
                },
                net_total_spread: {
                  without_refunds: 22271.75,
                  with_refunds: 21586.45,
                },
                gross_total_spread: {
                  without_refunds: 26476.91,
                  with_refunds: 25617.48,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.7,
                  with_refunds: 6.42,
                },
                gross_mrr: {
                  without_refunds: 8.12,
                  with_refunds: 7.77,
                },
                net_arr: {
                  without_refunds: 51.15,
                  with_refunds: 46.45,
                },
                gross_arr: {
                  without_refunds: 57.55,
                  with_refunds: 52.25,
                },
                net_arr_spread: {
                  without_refunds: 4.28,
                  with_refunds: 4.28,
                },
                gross_arr_spread: {
                  without_refunds: 4.82,
                  with_refunds: 4.82,
                },
                net_total: {
                  without_refunds: 24.46,
                  with_refunds: 22.42,
                },
                gross_total: {
                  without_refunds: 27.87,
                  with_refunds: 25.55,
                },
                net_total_spread: {
                  without_refunds: 5.88,
                  with_refunds: 5.7,
                },
                gross_total_spread: {
                  without_refunds: 7,
                  with_refunds: 6.77,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.68,
                  with_refunds: 11.2,
                },
                gross_mrr: {
                  without_refunds: 14.15,
                  with_refunds: 13.54,
                },
                net_arr: {
                  without_refunds: 89.86,
                  with_refunds: 81.6,
                },
                gross_arr: {
                  without_refunds: 101.09,
                  with_refunds: 91.79,
                },
                net_arr_spread: {
                  without_refunds: 7.47,
                  with_refunds: 7.47,
                },
                gross_arr_spread: {
                  without_refunds: 8.4,
                  with_refunds: 8.4,
                },
                net_total: {
                  without_refunds: 42.39,
                  with_refunds: 38.85,
                },
                gross_total: {
                  without_refunds: 48.3,
                  with_refunds: 44.28,
                },
                net_total_spread: {
                  without_refunds: 10.19,
                  with_refunds: 9.87,
                },
                gross_total_spread: {
                  without_refunds: 12.11,
                  with_refunds: 11.72,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 6.7,
                    with_refunds: 6.42,
                  },
                  gross_mrr: {
                    without_refunds: 8.12,
                    with_refunds: 7.77,
                  },
                  net_arr: {
                    without_refunds: 51.15,
                    with_refunds: 46.45,
                  },
                  gross_arr: {
                    without_refunds: 57.55,
                    with_refunds: 52.25,
                  },
                  net_arr_spread: {
                    without_refunds: 4.28,
                    with_refunds: 4.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.82,
                    with_refunds: 4.82,
                  },
                  net_total: {
                    without_refunds: 24.46,
                    with_refunds: 22.42,
                  },
                  gross_total: {
                    without_refunds: 27.87,
                    with_refunds: 25.55,
                  },
                  net_total_spread: {
                    without_refunds: 5.88,
                    with_refunds: 5.7,
                  },
                  gross_total_spread: {
                    without_refunds: 7,
                    with_refunds: 6.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 11.68,
                    with_refunds: 11.2,
                  },
                  gross_mrr: {
                    without_refunds: 14.15,
                    with_refunds: 13.54,
                  },
                  net_arr: {
                    without_refunds: 89.86,
                    with_refunds: 81.6,
                  },
                  gross_arr: {
                    without_refunds: 101.09,
                    with_refunds: 91.79,
                  },
                  net_arr_spread: {
                    without_refunds: 7.47,
                    with_refunds: 7.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.4,
                    with_refunds: 8.4,
                  },
                  net_total: {
                    without_refunds: 42.39,
                    with_refunds: 38.85,
                  },
                  gross_total: {
                    without_refunds: 48.3,
                    with_refunds: 44.28,
                  },
                  net_total_spread: {
                    without_refunds: 10.19,
                    with_refunds: 9.87,
                  },
                  gross_total_spread: {
                    without_refunds: 12.11,
                    with_refunds: 11.72,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1503,
                    with_refunds: 0.144,
                  },
                  gross_mrr: {
                    without_refunds: 0.1821,
                    with_refunds: 0.1742,
                  },
                  net_arr: {
                    without_refunds: 1.1475,
                    with_refunds: 1.042,
                  },
                  gross_arr: {
                    without_refunds: 1.2909,
                    with_refunds: 1.1722,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0961,
                    with_refunds: 0.0961,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1081,
                    with_refunds: 0.1081,
                  },
                  net_total: {
                    without_refunds: 0.5486,
                    with_refunds: 0.5028,
                  },
                  gross_total: {
                    without_refunds: 0.6252,
                    with_refunds: 0.5731,
                  },
                  net_total_spread: {
                    without_refunds: 0.132,
                    with_refunds: 0.1279,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1569,
                    with_refunds: 0.1518,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2621,
                    with_refunds: 0.2511,
                  },
                  gross_mrr: {
                    without_refunds: 0.3175,
                    with_refunds: 0.3038,
                  },
                  net_arr: {
                    without_refunds: 2.0157,
                    with_refunds: 1.8304,
                  },
                  gross_arr: {
                    without_refunds: 2.2676,
                    with_refunds: 2.0591,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1675,
                    with_refunds: 0.1675,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1884,
                    with_refunds: 0.1884,
                  },
                  net_total: {
                    without_refunds: 0.9508,
                    with_refunds: 0.8714,
                  },
                  gross_total: {
                    without_refunds: 1.0835,
                    with_refunds: 0.9933,
                  },
                  net_total_spread: {
                    without_refunds: 0.2285,
                    with_refunds: 0.2215,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2717,
                    with_refunds: 0.2629,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1859,
                  with_refunds: 1864,
                },
                arr_users: {
                  without_refunds: 630,
                  with_refunds: 664,
                },
                arr_spread_users: {
                  without_refunds: 1316,
                  with_refunds: 1316,
                },
                total_users: {
                  without_refunds: 2486,
                  with_refunds: 2525,
                },
                total_spread_users: {
                  without_refunds: 3175,
                  with_refunds: 3180,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7582,
                  with_refunds: 0.7602,
                },
                arr_users: {
                  without_refunds: 0.3967,
                  with_refunds: 0.4181,
                },
                arr_spread_users: {
                  without_refunds: 0.9641,
                  with_refunds: 0.9641,
                },
                total_users: {
                  without_refunds: 0.6226,
                  with_refunds: 0.6324,
                },
                total_spread_users: {
                  without_refunds: 0.8388,
                  with_refunds: 0.8402,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.3222,
                  with_refunds: 1.3257,
                },
                arr_users: {
                  without_refunds: 0.6969,
                  with_refunds: 0.7345,
                },
                arr_spread_users: {
                  without_refunds: 1.6807,
                  with_refunds: 1.6807,
                },
                total_users: {
                  without_refunds: 1.079,
                  with_refunds: 1.0959,
                },
                total_spread_users: {
                  without_refunds: 1.4524,
                  with_refunds: 1.4547,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 22547.05,
                  with_refunds: 21580.29,
                },
                gross_mrr: {
                  without_refunds: 26691.42,
                  with_refunds: 25569.2,
                },
                net_arr: {
                  without_refunds: 60400.49,
                  with_refunds: 48913.87,
                },
                gross_arr: {
                  without_refunds: 67797.53,
                  with_refunds: 54949.77,
                },
                net_arr_spread: {
                  without_refunds: 10112.39,
                  with_refunds: 10112.39,
                },
                gross_arr_spread: {
                  without_refunds: 11378.61,
                  with_refunds: 11378.61,
                },
                net_total: {
                  without_refunds: 82947.54,
                  with_refunds: 70494.15,
                },
                gross_total: {
                  without_refunds: 94488.95,
                  with_refunds: 80518.97,
                },
                net_total_spread: {
                  without_refunds: 32659.44,
                  with_refunds: 31692.68,
                },
                gross_total_spread: {
                  without_refunds: 38070.03,
                  with_refunds: 36947.82,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.3726,
                  with_refunds: 1.371,
                },
                gross_mrr: {
                  without_refunds: 1.3412,
                  with_refunds: 1.3428,
                },
                net_arr: {
                  without_refunds: 0.7435,
                  with_refunds: 0.6631,
                },
                gross_arr: {
                  without_refunds: 0.7419,
                  with_refunds: 0.6622,
                },
                net_arr_spread: {
                  without_refunds: 1.7299,
                  with_refunds: 1.7299,
                },
                gross_arr_spread: {
                  without_refunds: 1.7304,
                  with_refunds: 1.7304,
                },
                net_total: {
                  without_refunds: 0.8494,
                  with_refunds: 0.7876,
                },
                gross_total: {
                  without_refunds: 0.849,
                  with_refunds: 0.7892,
                },
                net_total_spread: {
                  without_refunds: 1.4664,
                  with_refunds: 1.4682,
                },
                gross_total_spread: {
                  without_refunds: 1.4379,
                  with_refunds: 1.4423,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.3726,
                  with_refunds: 1.371,
                },
                gross_mrr: {
                  without_refunds: 1.3412,
                  with_refunds: 1.3428,
                },
                net_arr: {
                  without_refunds: 0.7435,
                  with_refunds: 0.6631,
                },
                gross_arr: {
                  without_refunds: 0.7419,
                  with_refunds: 0.6622,
                },
                net_arr_spread: {
                  without_refunds: 1.7299,
                  with_refunds: 1.7299,
                },
                gross_arr_spread: {
                  without_refunds: 1.7304,
                  with_refunds: 1.7304,
                },
                net_total: {
                  without_refunds: 0.8494,
                  with_refunds: 0.7876,
                },
                gross_total: {
                  without_refunds: 0.849,
                  with_refunds: 0.7892,
                },
                net_total_spread: {
                  without_refunds: 1.4664,
                  with_refunds: 1.4682,
                },
                gross_total_spread: {
                  without_refunds: 1.4379,
                  with_refunds: 1.4423,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 9.2,
                  with_refunds: 8.8,
                },
                gross_mrr: {
                  without_refunds: 10.89,
                  with_refunds: 10.43,
                },
                net_arr: {
                  without_refunds: 38.04,
                  with_refunds: 30.8,
                },
                gross_arr: {
                  without_refunds: 42.69,
                  with_refunds: 34.6,
                },
                net_arr_spread: {
                  without_refunds: 7.41,
                  with_refunds: 7.41,
                },
                gross_arr_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.34,
                },
                net_total: {
                  without_refunds: 20.77,
                  with_refunds: 17.65,
                },
                gross_total: {
                  without_refunds: 23.66,
                  with_refunds: 20.17,
                },
                net_total_spread: {
                  without_refunds: 8.63,
                  with_refunds: 8.37,
                },
                gross_total_spread: {
                  without_refunds: 10.06,
                  with_refunds: 9.76,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.13,
                  with_refunds: 11.58,
                },
                gross_mrr: {
                  without_refunds: 14.36,
                  with_refunds: 13.72,
                },
                net_arr: {
                  without_refunds: 95.87,
                  with_refunds: 73.67,
                },
                gross_arr: {
                  without_refunds: 107.62,
                  with_refunds: 82.76,
                },
                net_arr_spread: {
                  without_refunds: 7.68,
                  with_refunds: 7.68,
                },
                gross_arr_spread: {
                  without_refunds: 8.65,
                  with_refunds: 8.65,
                },
                net_total: {
                  without_refunds: 33.37,
                  with_refunds: 27.92,
                },
                gross_total: {
                  without_refunds: 38.01,
                  with_refunds: 31.89,
                },
                net_total_spread: {
                  without_refunds: 10.29,
                  with_refunds: 9.97,
                },
                gross_total_spread: {
                  without_refunds: 11.99,
                  with_refunds: 11.62,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.89,
                    with_refunds: 15.22,
                  },
                  gross_mrr: {
                    without_refunds: 19,
                    with_refunds: 18.19,
                  },
                  net_arr: {
                    without_refunds: 89.19,
                    with_refunds: 77.25,
                  },
                  gross_arr: {
                    without_refunds: 100.24,
                    with_refunds: 86.86,
                  },
                  net_arr_spread: {
                    without_refunds: 11.69,
                    with_refunds: 11.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.15,
                    with_refunds: 13.15,
                  },
                  net_total: {
                    without_refunds: 45.23,
                    with_refunds: 40.07,
                  },
                  gross_total: {
                    without_refunds: 51.53,
                    with_refunds: 45.72,
                  },
                  net_total_spread: {
                    without_refunds: 14.51,
                    with_refunds: 14.08,
                  },
                  gross_total_spread: {
                    without_refunds: 17.05,
                    with_refunds: 16.53,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.81,
                    with_refunds: 22.77,
                  },
                  gross_mrr: {
                    without_refunds: 28.51,
                    with_refunds: 27.26,
                  },
                  net_arr: {
                    without_refunds: 185.73,
                    with_refunds: 155.26,
                  },
                  gross_arr: {
                    without_refunds: 208.71,
                    with_refunds: 174.55,
                  },
                  net_arr_spread: {
                    without_refunds: 15.15,
                    with_refunds: 15.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.04,
                    with_refunds: 17.04,
                  },
                  net_total: {
                    without_refunds: 75.75,
                    with_refunds: 66.77,
                  },
                  gross_total: {
                    without_refunds: 86.31,
                    with_refunds: 76.17,
                  },
                  net_total_spread: {
                    without_refunds: 20.47,
                    with_refunds: 19.84,
                  },
                  gross_total_spread: {
                    without_refunds: 24.1,
                    with_refunds: 23.34,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3565,
                    with_refunds: 0.3414,
                  },
                  gross_mrr: {
                    without_refunds: 0.4262,
                    with_refunds: 0.4081,
                  },
                  net_arr: {
                    without_refunds: 2.0007,
                    with_refunds: 1.7329,
                  },
                  gross_arr: {
                    without_refunds: 2.2486,
                    with_refunds: 1.9484,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2622,
                    with_refunds: 0.2622,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.295,
                    with_refunds: 0.295,
                  },
                  net_total: {
                    without_refunds: 1.0146,
                    with_refunds: 0.8988,
                  },
                  gross_total: {
                    without_refunds: 1.156,
                    with_refunds: 1.0255,
                  },
                  net_total_spread: {
                    without_refunds: 0.3255,
                    with_refunds: 0.3158,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3825,
                    with_refunds: 0.3708,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5341,
                    with_refunds: 0.5108,
                  },
                  gross_mrr: {
                    without_refunds: 0.6396,
                    with_refunds: 0.6115,
                  },
                  net_arr: {
                    without_refunds: 4.1663,
                    with_refunds: 3.4828,
                  },
                  gross_arr: {
                    without_refunds: 4.6816,
                    with_refunds: 3.9154,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3398,
                    with_refunds: 0.3398,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3823,
                    with_refunds: 0.3823,
                  },
                  net_total: {
                    without_refunds: 1.6993,
                    with_refunds: 1.4977,
                  },
                  gross_total: {
                    without_refunds: 1.9361,
                    with_refunds: 1.7086,
                  },
                  net_total_spread: {
                    without_refunds: 0.4593,
                    with_refunds: 0.4451,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5407,
                    with_refunds: 0.5235,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1117,
                  with_refunds: 1125,
                },
                arr_users: {
                  without_refunds: 14,
                  with_refunds: 28,
                },
                arr_spread_users: {
                  without_refunds: 1323,
                  with_refunds: 1323,
                },
                total_users: {
                  without_refunds: 1131,
                  with_refunds: 1152,
                },
                total_spread_users: {
                  without_refunds: 2440,
                  with_refunds: 2447,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4555,
                  with_refunds: 0.4588,
                },
                arr_users: {
                  without_refunds: 0.0088,
                  with_refunds: 0.0176,
                },
                arr_spread_users: {
                  without_refunds: 0.9692,
                  with_refunds: 0.9692,
                },
                total_users: {
                  without_refunds: 0.2832,
                  with_refunds: 0.2885,
                },
                total_spread_users: {
                  without_refunds: 0.6446,
                  with_refunds: 0.6465,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6009,
                  with_refunds: 0.6035,
                },
                arr_users: {
                  without_refunds: 0.0222,
                  with_refunds: 0.0422,
                },
                arr_spread_users: {
                  without_refunds: 1.0053,
                  with_refunds: 1.0053,
                },
                total_users: {
                  without_refunds: 0.4549,
                  with_refunds: 0.4562,
                },
                total_spread_users: {
                  without_refunds: 0.7685,
                  with_refunds: 0.7695,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13464.37,
                  with_refunds: 13030.32,
                },
                gross_mrr: {
                  without_refunds: 15547.09,
                  with_refunds: 15058.92,
                },
                net_arr: {
                  without_refunds: 1532.47,
                  with_refunds: 195.58,
                },
                gross_arr: {
                  without_refunds: 1943.6,
                  with_refunds: 510.12,
                },
                net_arr_spread: {
                  without_refunds: 10184.78,
                  with_refunds: 10184.78,
                },
                gross_arr_spread: {
                  without_refunds: 11476.57,
                  with_refunds: 11476.57,
                },
                net_total: {
                  without_refunds: 14996.85,
                  with_refunds: 13225.9,
                },
                gross_total: {
                  without_refunds: 17490.69,
                  with_refunds: 15569.03,
                },
                net_total_spread: {
                  without_refunds: 23649.15,
                  with_refunds: 23215.09,
                },
                gross_total_spread: {
                  without_refunds: 27023.66,
                  with_refunds: 26535.49,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8197,
                  with_refunds: 0.8278,
                },
                gross_mrr: {
                  without_refunds: 0.7812,
                  with_refunds: 0.7908,
                },
                net_arr: {
                  without_refunds: 0.0189,
                  with_refunds: 0.0027,
                },
                gross_arr: {
                  without_refunds: 0.0213,
                  with_refunds: 0.0061,
                },
                net_arr_spread: {
                  without_refunds: 1.7422,
                  with_refunds: 1.7422,
                },
                gross_arr_spread: {
                  without_refunds: 1.7453,
                  with_refunds: 1.7453,
                },
                net_total: {
                  without_refunds: 0.1536,
                  with_refunds: 0.1478,
                },
                gross_total: {
                  without_refunds: 0.1572,
                  with_refunds: 0.1526,
                },
                net_total_spread: {
                  without_refunds: 1.0618,
                  with_refunds: 1.0754,
                },
                gross_total_spread: {
                  without_refunds: 1.0207,
                  with_refunds: 1.0358,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5972,
                  with_refunds: 0.6038,
                },
                gross_mrr: {
                  without_refunds: 0.5825,
                  with_refunds: 0.5889,
                },
                net_arr: {
                  without_refunds: 0.0254,
                  with_refunds: 0.004,
                },
                gross_arr: {
                  without_refunds: 0.0287,
                  with_refunds: 0.0093,
                },
                net_arr_spread: {
                  without_refunds: 1.0072,
                  with_refunds: 1.0072,
                },
                gross_arr_spread: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                net_total: {
                  without_refunds: 0.1808,
                  with_refunds: 0.1876,
                },
                gross_total: {
                  without_refunds: 0.1851,
                  with_refunds: 0.1934,
                },
                net_total_spread: {
                  without_refunds: 0.7241,
                  with_refunds: 0.7325,
                },
                gross_total_spread: {
                  without_refunds: 0.7098,
                  with_refunds: 0.7182,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.49,
                  with_refunds: 5.31,
                },
                gross_mrr: {
                  without_refunds: 6.34,
                  with_refunds: 6.14,
                },
                net_arr: {
                  without_refunds: 0.97,
                  with_refunds: 0.12,
                },
                gross_arr: {
                  without_refunds: 1.22,
                  with_refunds: 0.32,
                },
                net_arr_spread: {
                  without_refunds: 7.46,
                  with_refunds: 7.46,
                },
                gross_arr_spread: {
                  without_refunds: 8.41,
                  with_refunds: 8.41,
                },
                net_total: {
                  without_refunds: 3.76,
                  with_refunds: 3.31,
                },
                gross_total: {
                  without_refunds: 4.38,
                  with_refunds: 3.9,
                },
                net_total_spread: {
                  without_refunds: 6.25,
                  with_refunds: 6.13,
                },
                gross_total_spread: {
                  without_refunds: 7.14,
                  with_refunds: 7.01,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.05,
                  with_refunds: 11.58,
                },
                gross_mrr: {
                  without_refunds: 13.92,
                  with_refunds: 13.39,
                },
                net_arr: {
                  without_refunds: 109.46,
                  with_refunds: 6.98,
                },
                gross_arr: {
                  without_refunds: 138.83,
                  with_refunds: 18.22,
                },
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                net_total: {
                  without_refunds: 13.26,
                  with_refunds: 11.48,
                },
                gross_total: {
                  without_refunds: 15.46,
                  with_refunds: 13.51,
                },
                net_total_spread: {
                  without_refunds: 9.69,
                  with_refunds: 9.49,
                },
                gross_total_spread: {
                  without_refunds: 11.08,
                  with_refunds: 10.84,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.39,
                    with_refunds: 20.53,
                  },
                  gross_mrr: {
                    without_refunds: 25.34,
                    with_refunds: 24.34,
                  },
                  net_arr: {
                    without_refunds: 90.16,
                    with_refunds: 77.38,
                  },
                  gross_arr: {
                    without_refunds: 101.47,
                    with_refunds: 87.18,
                  },
                  net_arr_spread: {
                    without_refunds: 19.15,
                    with_refunds: 19.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 21.56,
                    with_refunds: 21.56,
                  },
                  net_total: {
                    without_refunds: 48.99,
                    with_refunds: 43.38,
                  },
                  gross_total: {
                    without_refunds: 55.91,
                    with_refunds: 49.61,
                  },
                  net_total_spread: {
                    without_refunds: 20.76,
                    with_refunds: 20.21,
                  },
                  gross_total_spread: {
                    without_refunds: 24.19,
                    with_refunds: 23.54,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.87,
                    with_refunds: 34.36,
                  },
                  gross_mrr: {
                    without_refunds: 42.43,
                    with_refunds: 40.65,
                  },
                  net_arr: {
                    without_refunds: 295.2,
                    with_refunds: 162.25,
                  },
                  gross_arr: {
                    without_refunds: 347.54,
                    with_refunds: 192.77,
                  },
                  net_arr_spread: {
                    without_refunds: 22.85,
                    with_refunds: 22.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.72,
                    with_refunds: 25.72,
                  },
                  net_total: {
                    without_refunds: 89.01,
                    with_refunds: 78.25,
                  },
                  gross_total: {
                    without_refunds: 101.78,
                    with_refunds: 89.68,
                  },
                  net_total_spread: {
                    without_refunds: 30.17,
                    with_refunds: 29.33,
                  },
                  gross_total_spread: {
                    without_refunds: 35.18,
                    with_refunds: 34.18,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4797,
                    with_refunds: 0.4606,
                  },
                  gross_mrr: {
                    without_refunds: 0.5685,
                    with_refunds: 0.5459,
                  },
                  net_arr: {
                    without_refunds: 2.0223,
                    with_refunds: 1.7357,
                  },
                  gross_arr: {
                    without_refunds: 2.276,
                    with_refunds: 1.9556,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4296,
                    with_refunds: 0.4296,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4836,
                    with_refunds: 0.4836,
                  },
                  net_total: {
                    without_refunds: 1.0988,
                    with_refunds: 0.9731,
                  },
                  gross_total: {
                    without_refunds: 1.2543,
                    with_refunds: 1.1129,
                  },
                  net_total_spread: {
                    without_refunds: 0.4657,
                    with_refunds: 0.4533,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5427,
                    with_refunds: 0.5281,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8045,
                    with_refunds: 0.7706,
                  },
                  gross_mrr: {
                    without_refunds: 0.9518,
                    with_refunds: 0.9118,
                  },
                  net_arr: {
                    without_refunds: 6.6217,
                    with_refunds: 3.6395,
                  },
                  gross_arr: {
                    without_refunds: 7.7958,
                    with_refunds: 4.3241,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5125,
                    with_refunds: 0.5125,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5769,
                    with_refunds: 0.5769,
                  },
                  net_total: {
                    without_refunds: 1.9967,
                    with_refunds: 1.7552,
                  },
                  gross_total: {
                    without_refunds: 2.283,
                    with_refunds: 2.0118,
                  },
                  net_total_spread: {
                    without_refunds: 0.6767,
                    with_refunds: 0.6579,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7891,
                    with_refunds: 0.7668,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 888,
                  with_refunds: 892,
                },
                arr_users: {
                  without_refunds: 12,
                  with_refunds: 17,
                },
                arr_spread_users: {
                  without_refunds: 1330,
                  with_refunds: 1330,
                },
                total_users: {
                  without_refunds: 898,
                  with_refunds: 903,
                },
                total_spread_users: {
                  without_refunds: 2218,
                  with_refunds: 2220,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3622,
                  with_refunds: 0.3638,
                },
                arr_users: {
                  without_refunds: 0.0076,
                  with_refunds: 0.0107,
                },
                arr_spread_users: {
                  without_refunds: 0.9744,
                  with_refunds: 0.9744,
                },
                total_users: {
                  without_refunds: 0.2249,
                  with_refunds: 0.2261,
                },
                total_spread_users: {
                  without_refunds: 0.586,
                  with_refunds: 0.5865,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.795,
                  with_refunds: 0.7929,
                },
                arr_users: {
                  without_refunds: 0.8571,
                  with_refunds: 0.6071,
                },
                arr_spread_users: {
                  without_refunds: 1.0053,
                  with_refunds: 1.0053,
                },
                total_users: {
                  without_refunds: 0.794,
                  with_refunds: 0.7839,
                },
                total_spread_users: {
                  without_refunds: 0.909,
                  with_refunds: 0.9072,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10683.92,
                  with_refunds: 10458.6,
                },
                gross_mrr: {
                  without_refunds: 12300.45,
                  with_refunds: 12039.33,
                },
                net_arr: {
                  without_refunds: 1341.48,
                  with_refunds: 654.54,
                },
                gross_arr: {
                  without_refunds: 1713.74,
                  with_refunds: 911.69,
                },
                net_arr_spread: {
                  without_refunds: 10254.1,
                  with_refunds: 10254.1,
                },
                gross_arr_spread: {
                  without_refunds: 11566.89,
                  with_refunds: 11566.89,
                },
                net_total: {
                  without_refunds: 12025.4,
                  with_refunds: 11113.14,
                },
                gross_total: {
                  without_refunds: 14014.19,
                  with_refunds: 12951.02,
                },
                net_total_spread: {
                  without_refunds: 20938.02,
                  with_refunds: 20712.7,
                },
                gross_total_spread: {
                  without_refunds: 23867.34,
                  with_refunds: 23606.22,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6504,
                  with_refunds: 0.6644,
                },
                gross_mrr: {
                  without_refunds: 0.6181,
                  with_refunds: 0.6323,
                },
                net_arr: {
                  without_refunds: 0.0165,
                  with_refunds: 0.0089,
                },
                gross_arr: {
                  without_refunds: 0.0188,
                  with_refunds: 0.011,
                },
                net_arr_spread: {
                  without_refunds: 1.7541,
                  with_refunds: 1.7541,
                },
                gross_arr_spread: {
                  without_refunds: 1.7591,
                  with_refunds: 1.7591,
                },
                net_total: {
                  without_refunds: 0.1231,
                  with_refunds: 0.1242,
                },
                gross_total: {
                  without_refunds: 0.1259,
                  with_refunds: 0.1269,
                },
                net_total_spread: {
                  without_refunds: 0.9401,
                  with_refunds: 0.9595,
                },
                gross_total_spread: {
                  without_refunds: 0.9014,
                  with_refunds: 0.9215,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7935,
                  with_refunds: 0.8026,
                },
                gross_mrr: {
                  without_refunds: 0.7912,
                  with_refunds: 0.7995,
                },
                net_arr: {
                  without_refunds: 0.8754,
                  with_refunds: 3.3467,
                },
                gross_arr: {
                  without_refunds: 0.8817,
                  with_refunds: 1.7872,
                },
                net_arr_spread: {
                  without_refunds: 1.0068,
                  with_refunds: 1.0068,
                },
                gross_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                net_total: {
                  without_refunds: 0.8019,
                  with_refunds: 0.8403,
                },
                gross_total: {
                  without_refunds: 0.8012,
                  with_refunds: 0.8318,
                },
                net_total_spread: {
                  without_refunds: 0.8854,
                  with_refunds: 0.8922,
                },
                gross_total_spread: {
                  without_refunds: 0.8832,
                  with_refunds: 0.8896,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.36,
                  with_refunds: 4.27,
                },
                gross_mrr: {
                  without_refunds: 5.02,
                  with_refunds: 4.91,
                },
                net_arr: {
                  without_refunds: 0.84,
                  with_refunds: 0.41,
                },
                gross_arr: {
                  without_refunds: 1.08,
                  with_refunds: 0.57,
                },
                net_arr_spread: {
                  without_refunds: 7.51,
                  with_refunds: 7.51,
                },
                gross_arr_spread: {
                  without_refunds: 8.47,
                  with_refunds: 8.47,
                },
                net_total: {
                  without_refunds: 3.01,
                  with_refunds: 2.78,
                },
                gross_total: {
                  without_refunds: 3.51,
                  with_refunds: 3.24,
                },
                net_total_spread: {
                  without_refunds: 5.53,
                  with_refunds: 5.47,
                },
                gross_total_spread: {
                  without_refunds: 6.31,
                  with_refunds: 6.24,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.03,
                  with_refunds: 11.72,
                },
                gross_mrr: {
                  without_refunds: 13.85,
                  with_refunds: 13.5,
                },
                net_arr: {
                  without_refunds: 111.79,
                  with_refunds: 38.5,
                },
                gross_arr: {
                  without_refunds: 142.81,
                  with_refunds: 53.63,
                },
                net_arr_spread: {
                  without_refunds: 7.71,
                  with_refunds: 7.71,
                },
                gross_arr_spread: {
                  without_refunds: 8.7,
                  with_refunds: 8.7,
                },
                net_total: {
                  without_refunds: 13.39,
                  with_refunds: 12.31,
                },
                gross_total: {
                  without_refunds: 15.61,
                  with_refunds: 14.34,
                },
                net_total_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.33,
                },
                gross_total_spread: {
                  without_refunds: 10.76,
                  with_refunds: 10.63,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.74,
                    with_refunds: 24.8,
                  },
                  gross_mrr: {
                    without_refunds: 30.36,
                    with_refunds: 29.25,
                  },
                  net_arr: {
                    without_refunds: 91,
                    with_refunds: 77.79,
                  },
                  gross_arr: {
                    without_refunds: 102.55,
                    with_refunds: 87.75,
                  },
                  net_arr_spread: {
                    without_refunds: 26.66,
                    with_refunds: 26.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.03,
                    with_refunds: 30.03,
                  },
                  net_total: {
                    without_refunds: 52,
                    with_refunds: 46.17,
                  },
                  gross_total: {
                    without_refunds: 59.42,
                    with_refunds: 52.86,
                  },
                  net_total_spread: {
                    without_refunds: 26.29,
                    with_refunds: 25.68,
                  },
                  gross_total_spread: {
                    without_refunds: 30.5,
                    with_refunds: 29.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 47.9,
                    with_refunds: 46.08,
                  },
                  gross_mrr: {
                    without_refunds: 56.28,
                    with_refunds: 54.14,
                  },
                  net_arr: {
                    without_refunds: 406.99,
                    with_refunds: 200.75,
                  },
                  gross_arr: {
                    without_refunds: 490.35,
                    with_refunds: 246.4,
                  },
                  net_arr_spread: {
                    without_refunds: 30.56,
                    with_refunds: 30.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 34.42,
                    with_refunds: 34.42,
                  },
                  net_total: {
                    without_refunds: 102.4,
                    with_refunds: 90.55,
                  },
                  gross_total: {
                    without_refunds: 117.38,
                    with_refunds: 104.03,
                  },
                  net_total_spread: {
                    without_refunds: 39.61,
                    with_refunds: 38.66,
                  },
                  gross_total_spread: {
                    without_refunds: 45.94,
                    with_refunds: 44.82,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5775,
                    with_refunds: 0.5563,
                  },
                  gross_mrr: {
                    without_refunds: 0.681,
                    with_refunds: 0.656,
                  },
                  net_arr: {
                    without_refunds: 2.0413,
                    with_refunds: 1.7449,
                  },
                  gross_arr: {
                    without_refunds: 2.3002,
                    with_refunds: 1.9684,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5981,
                    with_refunds: 0.5981,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6737,
                    with_refunds: 0.6737,
                  },
                  net_total: {
                    without_refunds: 1.1664,
                    with_refunds: 1.0356,
                  },
                  gross_total: {
                    without_refunds: 1.333,
                    with_refunds: 1.1857,
                  },
                  net_total_spread: {
                    without_refunds: 0.5898,
                    with_refunds: 0.5761,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6841,
                    with_refunds: 0.668,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0744,
                    with_refunds: 1.0337,
                  },
                  gross_mrr: {
                    without_refunds: 1.2625,
                    with_refunds: 1.2145,
                  },
                  net_arr: {
                    without_refunds: 9.1293,
                    with_refunds: 4.5032,
                  },
                  gross_arr: {
                    without_refunds: 10.9993,
                    with_refunds: 5.527,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6855,
                    with_refunds: 0.6855,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.772,
                    with_refunds: 0.772,
                  },
                  net_total: {
                    without_refunds: 2.2971,
                    with_refunds: 2.0313,
                  },
                  gross_total: {
                    without_refunds: 2.633,
                    with_refunds: 2.3335,
                  },
                  net_total_spread: {
                    without_refunds: 0.8885,
                    with_refunds: 0.8672,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0305,
                    with_refunds: 1.0053,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 748,
                  with_refunds: 749,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 13,
                },
                arr_spread_users: {
                  without_refunds: 1335,
                  with_refunds: 1335,
                },
                total_users: {
                  without_refunds: 758,
                  with_refunds: 762,
                },
                total_spread_users: {
                  without_refunds: 2083,
                  with_refunds: 2084,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3051,
                  with_refunds: 0.3055,
                },
                arr_users: {
                  without_refunds: 0.0063,
                  with_refunds: 0.0082,
                },
                arr_spread_users: {
                  without_refunds: 0.978,
                  with_refunds: 0.978,
                },
                total_users: {
                  without_refunds: 0.1898,
                  with_refunds: 0.1908,
                },
                total_spread_users: {
                  without_refunds: 0.5503,
                  with_refunds: 0.5506,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8423,
                  with_refunds: 0.8397,
                },
                arr_users: {
                  without_refunds: 0.8333,
                  with_refunds: 0.7647,
                },
                arr_spread_users: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                total_users: {
                  without_refunds: 0.8441,
                  with_refunds: 0.8439,
                },
                total_spread_users: {
                  without_refunds: 0.9391,
                  with_refunds: 0.9387,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8950.08,
                  with_refunds: 8836.89,
                },
                gross_mrr: {
                  without_refunds: 10249.98,
                  with_refunds: 10128.99,
                },
                net_arr: {
                  without_refunds: 1146.17,
                  with_refunds: 301.08,
                },
                gross_arr: {
                  without_refunds: 1469.18,
                  with_refunds: 384.89,
                },
                net_arr_spread: {
                  without_refunds: 10301.78,
                  with_refunds: 10301.78,
                },
                gross_arr_spread: {
                  without_refunds: 11625.89,
                  with_refunds: 11625.89,
                },
                net_total: {
                  without_refunds: 10096.25,
                  with_refunds: 9137.97,
                },
                gross_total: {
                  without_refunds: 11719.16,
                  with_refunds: 10513.88,
                },
                net_total_spread: {
                  without_refunds: 19251.86,
                  with_refunds: 19138.67,
                },
                gross_total_spread: {
                  without_refunds: 21875.87,
                  with_refunds: 21754.88,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5449,
                  with_refunds: 0.5614,
                },
                gross_mrr: {
                  without_refunds: 0.515,
                  with_refunds: 0.5319,
                },
                net_arr: {
                  without_refunds: 0.0141,
                  with_refunds: 0.0041,
                },
                gross_arr: {
                  without_refunds: 0.0161,
                  with_refunds: 0.0046,
                },
                net_arr_spread: {
                  without_refunds: 1.7623,
                  with_refunds: 1.7623,
                },
                gross_arr_spread: {
                  without_refunds: 1.768,
                  with_refunds: 1.768,
                },
                net_total: {
                  without_refunds: 0.1034,
                  with_refunds: 0.1021,
                },
                gross_total: {
                  without_refunds: 0.1053,
                  with_refunds: 0.1031,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8866,
                },
                gross_total_spread: {
                  without_refunds: 0.8262,
                  with_refunds: 0.8492,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8377,
                  with_refunds: 0.8449,
                },
                gross_mrr: {
                  without_refunds: 0.8333,
                  with_refunds: 0.8413,
                },
                net_arr: {
                  without_refunds: 0.8544,
                  with_refunds: 0.46,
                },
                gross_arr: {
                  without_refunds: 0.8573,
                  with_refunds: 0.4222,
                },
                net_arr_spread: {
                  without_refunds: 1.0046,
                  with_refunds: 1.0046,
                },
                gross_arr_spread: {
                  without_refunds: 1.0051,
                  with_refunds: 1.0051,
                },
                net_total: {
                  without_refunds: 0.8396,
                  with_refunds: 0.8223,
                },
                gross_total: {
                  without_refunds: 0.8362,
                  with_refunds: 0.8118,
                },
                net_total_spread: {
                  without_refunds: 0.9195,
                  with_refunds: 0.924,
                },
                gross_total_spread: {
                  without_refunds: 0.9166,
                  with_refunds: 0.9216,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.65,
                  with_refunds: 3.6,
                },
                gross_mrr: {
                  without_refunds: 4.18,
                  with_refunds: 4.13,
                },
                net_arr: {
                  without_refunds: 0.72,
                  with_refunds: 0.19,
                },
                gross_arr: {
                  without_refunds: 0.93,
                  with_refunds: 0.24,
                },
                net_arr_spread: {
                  without_refunds: 7.55,
                  with_refunds: 7.55,
                },
                gross_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                net_total: {
                  without_refunds: 2.53,
                  with_refunds: 2.29,
                },
                gross_total: {
                  without_refunds: 2.93,
                  with_refunds: 2.63,
                },
                net_total_spread: {
                  without_refunds: 5.09,
                  with_refunds: 5.06,
                },
                gross_total_spread: {
                  without_refunds: 5.78,
                  with_refunds: 5.75,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.97,
                  with_refunds: 11.8,
                },
                gross_mrr: {
                  without_refunds: 13.7,
                  with_refunds: 13.52,
                },
                net_arr: {
                  without_refunds: 114.62,
                  with_refunds: 23.16,
                },
                gross_arr: {
                  without_refunds: 146.92,
                  with_refunds: 29.61,
                },
                net_arr_spread: {
                  without_refunds: 7.72,
                  with_refunds: 7.72,
                },
                gross_arr_spread: {
                  without_refunds: 8.71,
                  with_refunds: 8.71,
                },
                net_total: {
                  without_refunds: 13.32,
                  with_refunds: 11.99,
                },
                gross_total: {
                  without_refunds: 15.46,
                  with_refunds: 13.8,
                },
                net_total_spread: {
                  without_refunds: 9.24,
                  with_refunds: 9.18,
                },
                gross_total_spread: {
                  without_refunds: 10.5,
                  with_refunds: 10.44,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 29.39,
                    with_refunds: 28.4,
                  },
                  gross_mrr: {
                    without_refunds: 34.54,
                    with_refunds: 33.38,
                  },
                  net_arr: {
                    without_refunds: 91.72,
                    with_refunds: 77.98,
                  },
                  gross_arr: {
                    without_refunds: 103.47,
                    with_refunds: 88,
                  },
                  net_arr_spread: {
                    without_refunds: 34.21,
                    with_refunds: 34.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 38.55,
                    with_refunds: 38.55,
                  },
                  net_total: {
                    without_refunds: 54.53,
                    with_refunds: 48.45,
                  },
                  gross_total: {
                    without_refunds: 62.36,
                    with_refunds: 55.49,
                  },
                  net_total_spread: {
                    without_refunds: 31.38,
                    with_refunds: 30.74,
                  },
                  gross_total_spread: {
                    without_refunds: 36.28,
                    with_refunds: 35.52,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 59.86,
                    with_refunds: 57.88,
                  },
                  gross_mrr: {
                    without_refunds: 69.99,
                    with_refunds: 67.67,
                  },
                  net_arr: {
                    without_refunds: 521.6,
                    with_refunds: 223.91,
                  },
                  gross_arr: {
                    without_refunds: 637.26,
                    with_refunds: 276,
                  },
                  net_arr_spread: {
                    without_refunds: 38.27,
                    with_refunds: 38.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 43.12,
                    with_refunds: 43.12,
                  },
                  net_total: {
                    without_refunds: 115.72,
                    with_refunds: 102.55,
                  },
                  gross_total: {
                    without_refunds: 132.84,
                    with_refunds: 117.82,
                  },
                  net_total_spread: {
                    without_refunds: 48.85,
                    with_refunds: 47.84,
                  },
                  gross_total_spread: {
                    without_refunds: 56.44,
                    with_refunds: 55.25,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6593,
                    with_refunds: 0.6371,
                  },
                  gross_mrr: {
                    without_refunds: 0.7748,
                    with_refunds: 0.7487,
                  },
                  net_arr: {
                    without_refunds: 2.0575,
                    with_refunds: 1.7492,
                  },
                  gross_arr: {
                    without_refunds: 2.321,
                    with_refunds: 1.9739,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7674,
                    with_refunds: 0.7674,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8648,
                    with_refunds: 0.8648,
                  },
                  net_total: {
                    without_refunds: 1.2231,
                    with_refunds: 1.0869,
                  },
                  gross_total: {
                    without_refunds: 1.3988,
                    with_refunds: 1.2448,
                  },
                  net_total_spread: {
                    without_refunds: 0.7039,
                    with_refunds: 0.6895,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8138,
                    with_refunds: 0.7969,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3428,
                    with_refunds: 1.2983,
                  },
                  gross_mrr: {
                    without_refunds: 1.5699,
                    with_refunds: 1.5179,
                  },
                  net_arr: {
                    without_refunds: 11.7004,
                    with_refunds: 5.0227,
                  },
                  gross_arr: {
                    without_refunds: 14.2949,
                    with_refunds: 6.1912,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8586,
                    with_refunds: 0.8586,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9673,
                    with_refunds: 0.9673,
                  },
                  net_total: {
                    without_refunds: 2.5959,
                    with_refunds: 2.3003,
                  },
                  gross_total: {
                    without_refunds: 2.9799,
                    with_refunds: 2.643,
                  },
                  net_total_spread: {
                    without_refunds: 1.0958,
                    with_refunds: 1.0732,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2661,
                    with_refunds: 1.2394,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 615,
                  with_refunds: 617,
                },
                arr_users: {
                  without_refunds: 14,
                  with_refunds: 14,
                },
                arr_spread_users: {
                  without_refunds: 1343,
                  with_refunds: 1343,
                },
                total_users: {
                  without_refunds: 629,
                  with_refunds: 631,
                },
                total_spread_users: {
                  without_refunds: 1958,
                  with_refunds: 1960,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2508,
                  with_refunds: 0.2516,
                },
                arr_users: {
                  without_refunds: 0.0088,
                  with_refunds: 0.0088,
                },
                arr_spread_users: {
                  without_refunds: 0.9839,
                  with_refunds: 0.9839,
                },
                total_users: {
                  without_refunds: 0.1575,
                  with_refunds: 0.158,
                },
                total_spread_users: {
                  without_refunds: 0.5173,
                  with_refunds: 0.5178,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8222,
                  with_refunds: 0.8238,
                },
                arr_users: {
                  without_refunds: 1.4,
                  with_refunds: 1.0769,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8298,
                  with_refunds: 0.8281,
                },
                total_spread_users: {
                  without_refunds: 0.94,
                  with_refunds: 0.9405,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7278.29,
                  with_refunds: 7120.05,
                },
                gross_mrr: {
                  without_refunds: 8345.58,
                  with_refunds: 8164.4,
                },
                net_arr: {
                  without_refunds: 1606.54,
                  with_refunds: 1003.67,
                },
                gross_arr: {
                  without_refunds: 2112.94,
                  with_refunds: 1294.92,
                },
                net_arr_spread: {
                  without_refunds: 10377.44,
                  with_refunds: 10377.44,
                },
                gross_arr_spread: {
                  without_refunds: 11725.55,
                  with_refunds: 11725.55,
                },
                net_total: {
                  without_refunds: 8884.83,
                  with_refunds: 8123.72,
                },
                gross_total: {
                  without_refunds: 10458.52,
                  with_refunds: 9459.31,
                },
                net_total_spread: {
                  without_refunds: 17655.73,
                  with_refunds: 17497.49,
                },
                gross_total_spread: {
                  without_refunds: 20071.14,
                  with_refunds: 19889.95,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4431,
                  with_refunds: 0.4523,
                },
                gross_mrr: {
                  without_refunds: 0.4193,
                  with_refunds: 0.4288,
                },
                net_arr: {
                  without_refunds: 0.0198,
                  with_refunds: 0.0136,
                },
                gross_arr: {
                  without_refunds: 0.0231,
                  with_refunds: 0.0156,
                },
                net_arr_spread: {
                  without_refunds: 1.7752,
                  with_refunds: 1.7752,
                },
                gross_arr_spread: {
                  without_refunds: 1.7832,
                  with_refunds: 1.7832,
                },
                net_total: {
                  without_refunds: 0.091,
                  with_refunds: 0.0908,
                },
                gross_total: {
                  without_refunds: 0.094,
                  with_refunds: 0.0927,
                },
                net_total_spread: {
                  without_refunds: 0.7927,
                  with_refunds: 0.8106,
                },
                gross_total_spread: {
                  without_refunds: 0.7581,
                  with_refunds: 0.7764,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8132,
                  with_refunds: 0.8057,
                },
                gross_mrr: {
                  without_refunds: 0.8142,
                  with_refunds: 0.806,
                },
                net_arr: {
                  without_refunds: 1.4017,
                  with_refunds: 3.3336,
                },
                gross_arr: {
                  without_refunds: 1.4382,
                  with_refunds: 3.3644,
                },
                net_arr_spread: {
                  without_refunds: 1.0073,
                  with_refunds: 1.0073,
                },
                gross_arr_spread: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                net_total: {
                  without_refunds: 0.88,
                  with_refunds: 0.889,
                },
                gross_total: {
                  without_refunds: 0.8924,
                  with_refunds: 0.8997,
                },
                net_total_spread: {
                  without_refunds: 0.9171,
                  with_refunds: 0.9142,
                },
                gross_total_spread: {
                  without_refunds: 0.9175,
                  with_refunds: 0.9143,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.97,
                  with_refunds: 2.9,
                },
                gross_mrr: {
                  without_refunds: 3.4,
                  with_refunds: 3.33,
                },
                net_arr: {
                  without_refunds: 1.01,
                  with_refunds: 0.63,
                },
                gross_arr: {
                  without_refunds: 1.33,
                  with_refunds: 0.82,
                },
                net_arr_spread: {
                  without_refunds: 7.6,
                  with_refunds: 7.6,
                },
                gross_arr_spread: {
                  without_refunds: 8.59,
                  with_refunds: 8.59,
                },
                net_total: {
                  without_refunds: 2.23,
                  with_refunds: 2.03,
                },
                gross_total: {
                  without_refunds: 2.62,
                  with_refunds: 2.37,
                },
                net_total_spread: {
                  without_refunds: 4.66,
                  with_refunds: 4.62,
                },
                gross_total_spread: {
                  without_refunds: 5.3,
                  with_refunds: 5.25,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.83,
                  with_refunds: 11.54,
                },
                gross_mrr: {
                  without_refunds: 13.57,
                  with_refunds: 13.23,
                },
                net_arr: {
                  without_refunds: 114.75,
                  with_refunds: 71.69,
                },
                gross_arr: {
                  without_refunds: 150.92,
                  with_refunds: 92.49,
                },
                net_arr_spread: {
                  without_refunds: 7.73,
                  with_refunds: 7.73,
                },
                gross_arr_spread: {
                  without_refunds: 8.73,
                  with_refunds: 8.73,
                },
                net_total: {
                  without_refunds: 14.13,
                  with_refunds: 12.87,
                },
                gross_total: {
                  without_refunds: 16.63,
                  with_refunds: 14.99,
                },
                net_total_spread: {
                  without_refunds: 9.02,
                  with_refunds: 8.93,
                },
                gross_total_spread: {
                  without_refunds: 10.25,
                  with_refunds: 10.15,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.36,
                    with_refunds: 31.31,
                  },
                  gross_mrr: {
                    without_refunds: 37.94,
                    with_refunds: 36.71,
                  },
                  net_arr: {
                    without_refunds: 92.73,
                    with_refunds: 78.61,
                  },
                  gross_arr: {
                    without_refunds: 104.8,
                    with_refunds: 88.81,
                  },
                  net_arr_spread: {
                    without_refunds: 41.81,
                    with_refunds: 41.81,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.14,
                    with_refunds: 47.14,
                  },
                  net_total: {
                    without_refunds: 56.75,
                    with_refunds: 50.49,
                  },
                  gross_total: {
                    without_refunds: 64.98,
                    with_refunds: 57.86,
                  },
                  net_total_spread: {
                    without_refunds: 36.04,
                    with_refunds: 35.36,
                  },
                  gross_total_spread: {
                    without_refunds: 41.58,
                    with_refunds: 40.78,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 71.7,
                    with_refunds: 69.42,
                  },
                  gross_mrr: {
                    without_refunds: 83.56,
                    with_refunds: 80.9,
                  },
                  net_arr: {
                    without_refunds: 636.36,
                    with_refunds: 295.6,
                  },
                  gross_arr: {
                    without_refunds: 788.19,
                    with_refunds: 368.5,
                  },
                  net_arr_spread: {
                    without_refunds: 46,
                    with_refunds: 46,
                  },
                  gross_arr_spread: {
                    without_refunds: 51.86,
                    with_refunds: 51.86,
                  },
                  net_total: {
                    without_refunds: 129.85,
                    with_refunds: 115.42,
                  },
                  gross_total: {
                    without_refunds: 149.47,
                    with_refunds: 132.82,
                  },
                  net_total_spread: {
                    without_refunds: 57.87,
                    with_refunds: 56.77,
                  },
                  gross_total_spread: {
                    without_refunds: 66.69,
                    with_refunds: 65.4,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7259,
                    with_refunds: 0.7023,
                  },
                  gross_mrr: {
                    without_refunds: 0.8511,
                    with_refunds: 0.8234,
                  },
                  net_arr: {
                    without_refunds: 2.0802,
                    with_refunds: 1.7634,
                  },
                  gross_arr: {
                    without_refunds: 2.3508,
                    with_refunds: 1.9922,
                  },
                  net_arr_spread: {
                    without_refunds: 0.938,
                    with_refunds: 0.938,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0575,
                    with_refunds: 1.0575,
                  },
                  net_total: {
                    without_refunds: 1.273,
                    with_refunds: 1.1325,
                  },
                  gross_total: {
                    without_refunds: 1.4576,
                    with_refunds: 1.2979,
                  },
                  net_total_spread: {
                    without_refunds: 0.8085,
                    with_refunds: 0.7932,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9327,
                    with_refunds: 0.9148,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.6083,
                    with_refunds: 1.5572,
                  },
                  gross_mrr: {
                    without_refunds: 1.8743,
                    with_refunds: 1.8147,
                  },
                  net_arr: {
                    without_refunds: 14.2745,
                    with_refunds: 6.6308,
                  },
                  gross_arr: {
                    without_refunds: 17.6803,
                    with_refunds: 8.266,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0319,
                    with_refunds: 1.0319,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1632,
                    with_refunds: 1.1632,
                  },
                  net_total: {
                    without_refunds: 2.9127,
                    with_refunds: 2.5891,
                  },
                  gross_total: {
                    without_refunds: 3.3528,
                    with_refunds: 2.9793,
                  },
                  net_total_spread: {
                    without_refunds: 1.298,
                    with_refunds: 1.2734,
                  },
                  gross_total_spread: {
                    without_refunds: 1.496,
                    with_refunds: 1.4671,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 534,
                  with_refunds: 535,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 10,
                },
                arr_spread_users: {
                  without_refunds: 1351,
                  with_refunds: 1351,
                },
                total_users: {
                  without_refunds: 542,
                  with_refunds: 545,
                },
                total_spread_users: {
                  without_refunds: 1885,
                  with_refunds: 1886,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2178,
                  with_refunds: 0.2182,
                },
                arr_users: {
                  without_refunds: 0.005,
                  with_refunds: 0.0063,
                },
                arr_spread_users: {
                  without_refunds: 0.9897,
                  with_refunds: 0.9897,
                },
                total_users: {
                  without_refunds: 0.1357,
                  with_refunds: 0.1365,
                },
                total_spread_users: {
                  without_refunds: 0.498,
                  with_refunds: 0.4983,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8683,
                  with_refunds: 0.8671,
                },
                arr_users: {
                  without_refunds: 0.5714,
                  with_refunds: 0.7143,
                },
                arr_spread_users: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                total_users: {
                  without_refunds: 0.8617,
                  with_refunds: 0.8637,
                },
                total_spread_users: {
                  without_refunds: 0.9627,
                  with_refunds: 0.9622,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6398.41,
                  with_refunds: 6333.77,
                },
                gross_mrr: {
                  without_refunds: 7320.76,
                  with_refunds: 7237.92,
                },
                net_arr: {
                  without_refunds: 991.91,
                  with_refunds: 780.64,
                },
                gross_arr: {
                  without_refunds: 1221.06,
                  with_refunds: 987.73,
                },
                net_arr_spread: {
                  without_refunds: 10451.24,
                  with_refunds: 10451.24,
                },
                gross_arr_spread: {
                  without_refunds: 11816.89,
                  with_refunds: 11816.89,
                },
                net_total: {
                  without_refunds: 7390.31,
                  with_refunds: 7114.41,
                },
                gross_total: {
                  without_refunds: 8541.82,
                  with_refunds: 8225.65,
                },
                net_total_spread: {
                  without_refunds: 16849.65,
                  with_refunds: 16785.01,
                },
                gross_total_spread: {
                  without_refunds: 19137.65,
                  with_refunds: 19054.81,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3895,
                  with_refunds: 0.4024,
                },
                gross_mrr: {
                  without_refunds: 0.3679,
                  with_refunds: 0.3801,
                },
                net_arr: {
                  without_refunds: 0.0122,
                  with_refunds: 0.0106,
                },
                gross_arr: {
                  without_refunds: 0.0134,
                  with_refunds: 0.0119,
                },
                net_arr_spread: {
                  without_refunds: 1.7878,
                  with_refunds: 1.7878,
                },
                gross_arr_spread: {
                  without_refunds: 1.7971,
                  with_refunds: 1.7971,
                },
                net_total: {
                  without_refunds: 0.0757,
                  with_refunds: 0.0795,
                },
                gross_total: {
                  without_refunds: 0.0768,
                  with_refunds: 0.0806,
                },
                net_total_spread: {
                  without_refunds: 0.7565,
                  with_refunds: 0.7776,
                },
                gross_total_spread: {
                  without_refunds: 0.7228,
                  with_refunds: 0.7438,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8791,
                  with_refunds: 0.8896,
                },
                gross_mrr: {
                  without_refunds: 0.8772,
                  with_refunds: 0.8865,
                },
                net_arr: {
                  without_refunds: 0.6174,
                  with_refunds: 0.7778,
                },
                gross_arr: {
                  without_refunds: 0.5779,
                  with_refunds: 0.7628,
                },
                net_arr_spread: {
                  without_refunds: 1.0071,
                  with_refunds: 1.0071,
                },
                gross_arr_spread: {
                  without_refunds: 1.0078,
                  with_refunds: 1.0078,
                },
                net_total: {
                  without_refunds: 0.8318,
                  with_refunds: 0.8758,
                },
                gross_total: {
                  without_refunds: 0.8167,
                  with_refunds: 0.8696,
                },
                net_total_spread: {
                  without_refunds: 0.9543,
                  with_refunds: 0.9593,
                },
                gross_total_spread: {
                  without_refunds: 0.9535,
                  with_refunds: 0.958,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.61,
                  with_refunds: 2.58,
                },
                gross_mrr: {
                  without_refunds: 2.99,
                  with_refunds: 2.95,
                },
                net_arr: {
                  without_refunds: 0.62,
                  with_refunds: 0.49,
                },
                gross_arr: {
                  without_refunds: 0.77,
                  with_refunds: 0.62,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                net_total: {
                  without_refunds: 1.85,
                  with_refunds: 1.78,
                },
                gross_total: {
                  without_refunds: 2.14,
                  with_refunds: 2.06,
                },
                net_total_spread: {
                  without_refunds: 4.45,
                  with_refunds: 4.43,
                },
                gross_total_spread: {
                  without_refunds: 5.06,
                  with_refunds: 5.03,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.98,
                  with_refunds: 11.84,
                },
                gross_mrr: {
                  without_refunds: 13.71,
                  with_refunds: 13.53,
                },
                net_arr: {
                  without_refunds: 123.99,
                  with_refunds: 78.06,
                },
                gross_arr: {
                  without_refunds: 152.63,
                  with_refunds: 98.77,
                },
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.75,
                  with_refunds: 8.75,
                },
                net_total: {
                  without_refunds: 13.64,
                  with_refunds: 13.05,
                },
                gross_total: {
                  without_refunds: 15.76,
                  with_refunds: 15.09,
                },
                net_total_spread: {
                  without_refunds: 8.94,
                  with_refunds: 8.9,
                },
                gross_total_spread: {
                  without_refunds: 10.15,
                  with_refunds: 10.1,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.97,
                    with_refunds: 33.89,
                  },
                  gross_mrr: {
                    without_refunds: 40.93,
                    with_refunds: 39.66,
                  },
                  net_arr: {
                    without_refunds: 93.36,
                    with_refunds: 79.1,
                  },
                  gross_arr: {
                    without_refunds: 105.57,
                    with_refunds: 89.43,
                  },
                  net_arr_spread: {
                    without_refunds: 49.47,
                    with_refunds: 49.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 55.8,
                    with_refunds: 55.8,
                  },
                  net_total: {
                    without_refunds: 58.6,
                    with_refunds: 52.27,
                  },
                  gross_total: {
                    without_refunds: 67.12,
                    with_refunds: 59.92,
                  },
                  net_total_spread: {
                    without_refunds: 40.5,
                    with_refunds: 39.8,
                  },
                  gross_total_spread: {
                    without_refunds: 46.64,
                    with_refunds: 45.81,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 83.68,
                    with_refunds: 81.26,
                  },
                  gross_mrr: {
                    without_refunds: 97.27,
                    with_refunds: 94.43,
                  },
                  net_arr: {
                    without_refunds: 760.34,
                    with_refunds: 373.66,
                  },
                  gross_arr: {
                    without_refunds: 940.82,
                    with_refunds: 467.27,
                  },
                  net_arr_spread: {
                    without_refunds: 53.74,
                    with_refunds: 53.74,
                  },
                  gross_arr_spread: {
                    without_refunds: 60.6,
                    with_refunds: 60.6,
                  },
                  net_total: {
                    without_refunds: 143.48,
                    with_refunds: 128.47,
                  },
                  gross_total: {
                    without_refunds: 165.23,
                    with_refunds: 147.91,
                  },
                  net_total_spread: {
                    without_refunds: 66.81,
                    with_refunds: 65.67,
                  },
                  gross_total_spread: {
                    without_refunds: 76.84,
                    with_refunds: 75.51,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7844,
                    with_refunds: 0.7602,
                  },
                  gross_mrr: {
                    without_refunds: 0.9181,
                    with_refunds: 0.8896,
                  },
                  net_arr: {
                    without_refunds: 2.0942,
                    with_refunds: 1.7744,
                  },
                  gross_arr: {
                    without_refunds: 2.3681,
                    with_refunds: 2.0061,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1097,
                    with_refunds: 1.1097,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2517,
                    with_refunds: 1.2517,
                  },
                  net_total: {
                    without_refunds: 1.3146,
                    with_refunds: 1.1725,
                  },
                  gross_total: {
                    without_refunds: 1.5056,
                    with_refunds: 1.3441,
                  },
                  net_total_spread: {
                    without_refunds: 0.9084,
                    with_refunds: 0.8927,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0462,
                    with_refunds: 1.0277,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.877,
                    with_refunds: 1.8227,
                  },
                  gross_mrr: {
                    without_refunds: 2.1818,
                    with_refunds: 2.1182,
                  },
                  net_arr: {
                    without_refunds: 17.0557,
                    with_refunds: 8.3819,
                  },
                  gross_arr: {
                    without_refunds: 21.1041,
                    with_refunds: 10.4816,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2054,
                    with_refunds: 1.2054,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3594,
                    with_refunds: 1.3594,
                  },
                  net_total: {
                    without_refunds: 3.2186,
                    with_refunds: 2.8819,
                  },
                  gross_total: {
                    without_refunds: 3.7063,
                    with_refunds: 3.3178,
                  },
                  net_total_spread: {
                    without_refunds: 1.4986,
                    with_refunds: 1.4731,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7237,
                    with_refunds: 1.6937,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 454,
                  with_refunds: 455,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1352,
                  with_refunds: 1352,
                },
                total_users: {
                  without_refunds: 455,
                  with_refunds: 456,
                },
                total_spread_users: {
                  without_refunds: 1806,
                  with_refunds: 1807,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1852,
                  with_refunds: 0.1856,
                },
                arr_users: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0006,
                },
                arr_spread_users: {
                  without_refunds: 0.9905,
                  with_refunds: 0.9905,
                },
                total_users: {
                  without_refunds: 0.1139,
                  with_refunds: 0.1142,
                },
                total_spread_users: {
                  without_refunds: 0.4771,
                  with_refunds: 0.4774,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8502,
                  with_refunds: 0.8505,
                },
                arr_users: {
                  without_refunds: 0.125,
                  with_refunds: 0.1,
                },
                arr_spread_users: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                total_users: {
                  without_refunds: 0.8395,
                  with_refunds: 0.8367,
                },
                total_spread_users: {
                  without_refunds: 0.9581,
                  with_refunds: 0.9581,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5448.09,
                  with_refunds: 5400.04,
                },
                gross_mrr: {
                  without_refunds: 6214.31,
                  with_refunds: 6157.87,
                },
                net_arr: {
                  without_refunds: 109.06,
                  with_refunds: 109.06,
                },
                gross_arr: {
                  without_refunds: 155.06,
                  with_refunds: 155.06,
                },
                net_arr_spread: {
                  without_refunds: 10460.33,
                  with_refunds: 10460.33,
                },
                gross_arr_spread: {
                  without_refunds: 11829.81,
                  with_refunds: 11829.81,
                },
                net_total: {
                  without_refunds: 5557.16,
                  with_refunds: 5509.1,
                },
                gross_total: {
                  without_refunds: 6369.38,
                  with_refunds: 6312.93,
                },
                net_total_spread: {
                  without_refunds: 15908.43,
                  with_refunds: 15860.37,
                },
                gross_total_spread: {
                  without_refunds: 18044.13,
                  with_refunds: 17987.68,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3317,
                  with_refunds: 0.3431,
                },
                gross_mrr: {
                  without_refunds: 0.3123,
                  with_refunds: 0.3234,
                },
                net_arr: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0015,
                },
                gross_arr: {
                  without_refunds: 0.0017,
                  with_refunds: 0.0019,
                },
                net_arr_spread: {
                  without_refunds: 1.7894,
                  with_refunds: 1.7894,
                },
                gross_arr_spread: {
                  without_refunds: 1.799,
                  with_refunds: 1.799,
                },
                net_total: {
                  without_refunds: 0.0569,
                  with_refunds: 0.0616,
                },
                gross_total: {
                  without_refunds: 0.0572,
                  with_refunds: 0.0619,
                },
                net_total_spread: {
                  without_refunds: 0.7143,
                  with_refunds: 0.7347,
                },
                gross_total_spread: {
                  without_refunds: 0.6815,
                  with_refunds: 0.7022,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8515,
                  with_refunds: 0.8526,
                },
                gross_mrr: {
                  without_refunds: 0.8489,
                  with_refunds: 0.8508,
                },
                net_arr: {
                  without_refunds: 0.11,
                  with_refunds: 0.1397,
                },
                gross_arr: {
                  without_refunds: 0.127,
                  with_refunds: 0.157,
                },
                net_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                gross_arr_spread: {
                  without_refunds: 1.0011,
                  with_refunds: 1.0011,
                },
                net_total: {
                  without_refunds: 0.752,
                  with_refunds: 0.7744,
                },
                gross_total: {
                  without_refunds: 0.7457,
                  with_refunds: 0.7675,
                },
                net_total_spread: {
                  without_refunds: 0.9441,
                  with_refunds: 0.9449,
                },
                gross_total_spread: {
                  without_refunds: 0.9429,
                  with_refunds: 0.944,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.22,
                  with_refunds: 2.2,
                },
                gross_mrr: {
                  without_refunds: 2.53,
                  with_refunds: 2.51,
                },
                net_arr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                gross_arr: {
                  without_refunds: 0.1,
                  with_refunds: 0.1,
                },
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                net_total: {
                  without_refunds: 1.39,
                  with_refunds: 1.38,
                },
                gross_total: {
                  without_refunds: 1.6,
                  with_refunds: 1.58,
                },
                net_total_spread: {
                  without_refunds: 4.2,
                  with_refunds: 4.19,
                },
                gross_total_spread: {
                  without_refunds: 4.77,
                  with_refunds: 4.75,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12,
                  with_refunds: 11.87,
                },
                gross_mrr: {
                  without_refunds: 13.69,
                  with_refunds: 13.53,
                },
                net_arr: {
                  without_refunds: 109.06,
                  with_refunds: 109.06,
                },
                gross_arr: {
                  without_refunds: 155.06,
                  with_refunds: 155.06,
                },
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.75,
                  with_refunds: 8.75,
                },
                net_total: {
                  without_refunds: 12.21,
                  with_refunds: 12.08,
                },
                gross_total: {
                  without_refunds: 14,
                  with_refunds: 13.84,
                },
                net_total_spread: {
                  without_refunds: 8.81,
                  with_refunds: 8.78,
                },
                gross_total_spread: {
                  without_refunds: 9.99,
                  with_refunds: 9.95,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.19,
                    with_refunds: 36.09,
                  },
                  gross_mrr: {
                    without_refunds: 43.46,
                    with_refunds: 42.17,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 57.13,
                    with_refunds: 57.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.47,
                    with_refunds: 64.47,
                  },
                  net_total: {
                    without_refunds: 59.99,
                    with_refunds: 53.65,
                  },
                  gross_total: {
                    without_refunds: 68.71,
                    with_refunds: 61.5,
                  },
                  net_total_spread: {
                    without_refunds: 44.7,
                    with_refunds: 43.99,
                  },
                  gross_total_spread: {
                    without_refunds: 51.4,
                    with_refunds: 50.57,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 95.68,
                    with_refunds: 93.13,
                  },
                  gross_mrr: {
                    without_refunds: 110.95,
                    with_refunds: 107.96,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 61.47,
                    with_refunds: 61.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 69.35,
                    with_refunds: 69.35,
                  },
                  net_total: {
                    without_refunds: 155.7,
                    with_refunds: 140.56,
                  },
                  gross_total: {
                    without_refunds: 179.23,
                    with_refunds: 161.75,
                  },
                  net_total_spread: {
                    without_refunds: 75.61,
                    with_refunds: 74.45,
                  },
                  gross_total_spread: {
                    without_refunds: 86.84,
                    with_refunds: 85.46,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8343,
                    with_refunds: 0.8096,
                  },
                  gross_mrr: {
                    without_refunds: 0.9749,
                    with_refunds: 0.9459,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2816,
                    with_refunds: 1.2816,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4461,
                    with_refunds: 1.4461,
                  },
                  net_total: {
                    without_refunds: 1.3458,
                    with_refunds: 1.2034,
                  },
                  gross_total: {
                    without_refunds: 1.5413,
                    with_refunds: 1.3796,
                  },
                  net_total_spread: {
                    without_refunds: 1.0027,
                    with_refunds: 0.9867,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1531,
                    with_refunds: 1.1343,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.1462,
                    with_refunds: 2.0889,
                  },
                  gross_mrr: {
                    without_refunds: 2.4889,
                    with_refunds: 2.4218,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 1.379,
                    with_refunds: 1.379,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5557,
                    with_refunds: 1.5557,
                  },
                  net_total: {
                    without_refunds: 3.4925,
                    with_refunds: 3.1529,
                  },
                  gross_total: {
                    without_refunds: 4.0204,
                    with_refunds: 3.6284,
                  },
                  net_total_spread: {
                    without_refunds: 1.6961,
                    with_refunds: 1.6699,
                  },
                  gross_total_spread: {
                    without_refunds: 1.9479,
                    with_refunds: 1.917,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 105,
                  with_refunds: 105,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1352,
                  with_refunds: 1352,
                },
                total_users: {
                  without_refunds: 105,
                  with_refunds: 105,
                },
                total_spread_users: {
                  without_refunds: 1457,
                  with_refunds: 1457,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0428,
                  with_refunds: 0.0428,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9905,
                  with_refunds: 0.9905,
                },
                total_users: {
                  without_refunds: 0.0263,
                  with_refunds: 0.0263,
                },
                total_spread_users: {
                  without_refunds: 0.3849,
                  with_refunds: 0.3849,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2313,
                  with_refunds: 0.2308,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.2308,
                  with_refunds: 0.2303,
                },
                total_spread_users: {
                  without_refunds: 0.8068,
                  with_refunds: 0.8063,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1269.15,
                  with_refunds: 1269.15,
                },
                gross_mrr: {
                  without_refunds: 1451.92,
                  with_refunds: 1451.92,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10460.33,
                  with_refunds: 10460.33,
                },
                gross_arr_spread: {
                  without_refunds: 11829.81,
                  with_refunds: 11829.81,
                },
                net_total: {
                  without_refunds: 1269.15,
                  with_refunds: 1269.15,
                },
                gross_total: {
                  without_refunds: 1451.92,
                  with_refunds: 1451.92,
                },
                net_total_spread: {
                  without_refunds: 11729.48,
                  with_refunds: 11729.48,
                },
                gross_total_spread: {
                  without_refunds: 13281.73,
                  with_refunds: 13281.73,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0773,
                  with_refunds: 0.0806,
                },
                gross_mrr: {
                  without_refunds: 0.073,
                  with_refunds: 0.0762,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.7894,
                  with_refunds: 1.7894,
                },
                gross_arr_spread: {
                  without_refunds: 1.799,
                  with_refunds: 1.799,
                },
                net_total: {
                  without_refunds: 0.013,
                  with_refunds: 0.0142,
                },
                gross_total: {
                  without_refunds: 0.013,
                  with_refunds: 0.0142,
                },
                net_total_spread: {
                  without_refunds: 0.5267,
                  with_refunds: 0.5434,
                },
                gross_total_spread: {
                  without_refunds: 0.5016,
                  with_refunds: 0.5185,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.233,
                  with_refunds: 0.235,
                },
                gross_mrr: {
                  without_refunds: 0.2336,
                  with_refunds: 0.2358,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.2284,
                  with_refunds: 0.2304,
                },
                gross_total: {
                  without_refunds: 0.228,
                  with_refunds: 0.23,
                },
                net_total_spread: {
                  without_refunds: 0.7373,
                  with_refunds: 0.7395,
                },
                gross_total_spread: {
                  without_refunds: 0.7361,
                  with_refunds: 0.7384,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.52,
                  with_refunds: 0.52,
                },
                gross_mrr: {
                  without_refunds: 0.59,
                  with_refunds: 0.59,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.66,
                  with_refunds: 7.66,
                },
                gross_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                net_total: {
                  without_refunds: 0.32,
                  with_refunds: 0.32,
                },
                gross_total: {
                  without_refunds: 0.36,
                  with_refunds: 0.36,
                },
                net_total_spread: {
                  without_refunds: 3.1,
                  with_refunds: 3.1,
                },
                gross_total_spread: {
                  without_refunds: 3.51,
                  with_refunds: 3.51,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.09,
                  with_refunds: 12.09,
                },
                gross_mrr: {
                  without_refunds: 13.83,
                  with_refunds: 13.83,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.74,
                  with_refunds: 7.74,
                },
                gross_arr_spread: {
                  without_refunds: 8.75,
                  with_refunds: 8.75,
                },
                net_total: {
                  without_refunds: 12.09,
                  with_refunds: 12.09,
                },
                gross_total: {
                  without_refunds: 13.83,
                  with_refunds: 13.83,
                },
                net_total_spread: {
                  without_refunds: 8.05,
                  with_refunds: 8.05,
                },
                gross_total_spread: {
                  without_refunds: 9.12,
                  with_refunds: 9.12,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 37.71,
                    with_refunds: 36.61,
                  },
                  gross_mrr: {
                    without_refunds: 44.05,
                    with_refunds: 42.76,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 64.8,
                    with_refunds: 64.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 73.13,
                    with_refunds: 73.13,
                  },
                  net_total: {
                    without_refunds: 60.31,
                    with_refunds: 53.97,
                  },
                  gross_total: {
                    without_refunds: 69.08,
                    with_refunds: 61.86,
                  },
                  net_total_spread: {
                    without_refunds: 47.8,
                    with_refunds: 47.09,
                  },
                  gross_total_spread: {
                    without_refunds: 54.91,
                    with_refunds: 54.08,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 107.77,
                    with_refunds: 105.21,
                  },
                  gross_mrr: {
                    without_refunds: 124.78,
                    with_refunds: 121.79,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 69.21,
                    with_refunds: 69.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.1,
                    with_refunds: 78.1,
                  },
                  net_total: {
                    without_refunds: 167.78,
                    with_refunds: 152.64,
                  },
                  gross_total: {
                    without_refunds: 193.06,
                    with_refunds: 175.58,
                  },
                  net_total_spread: {
                    without_refunds: 83.66,
                    with_refunds: 82.5,
                  },
                  gross_total_spread: {
                    without_refunds: 95.95,
                    with_refunds: 94.58,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8459,
                    with_refunds: 0.8212,
                  },
                  gross_mrr: {
                    without_refunds: 0.9882,
                    with_refunds: 0.9592,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4535,
                    with_refunds: 1.4535,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6405,
                    with_refunds: 1.6405,
                  },
                  net_total: {
                    without_refunds: 1.3529,
                    with_refunds: 1.2106,
                  },
                  gross_total: {
                    without_refunds: 1.5495,
                    with_refunds: 1.3877,
                  },
                  net_total_spread: {
                    without_refunds: 1.0722,
                    with_refunds: 1.0562,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2318,
                    with_refunds: 1.213,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.4174,
                    with_refunds: 2.3601,
                  },
                  gross_mrr: {
                    without_refunds: 2.799,
                    with_refunds: 2.7319,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5525,
                    with_refunds: 1.5525,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.7519,
                    with_refunds: 1.7519,
                  },
                  net_total: {
                    without_refunds: 3.7637,
                    with_refunds: 3.424,
                  },
                  gross_total: {
                    without_refunds: 4.3305,
                    with_refunds: 3.9385,
                  },
                  net_total_spread: {
                    without_refunds: 1.8767,
                    with_refunds: 1.8505,
                  },
                  gross_total_spread: {
                    without_refunds: 2.1523,
                    with_refunds: 2.1215,
                  },
                },
              },
            },
          },
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 69.64,
                  with_refunds: 69.7,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1355.84,
                  with_refunds: 1355.84,
                },
                total_users: {
                  without_refunds: 69.3,
                  with_refunds: 69.35,
                },
                total_spread_users: {
                  without_refunds: 1294.46,
                  with_refunds: 1294.37,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0284,
                  with_refunds: 0.0284,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9933,
                  with_refunds: 0.9933,
                },
                total_users: {
                  without_refunds: 0.0174,
                  with_refunds: 0.0174,
                },
                total_spread_users: {
                  without_refunds: 0.342,
                  with_refunds: 0.342,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 841.24,
                  with_refunds: 844.67,
                },
                gross_mrr: {
                  without_refunds: 960.62,
                  with_refunds: 964.25,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10503.93,
                  with_refunds: 10503.93,
                },
                gross_arr_spread: {
                  without_refunds: 11874.44,
                  with_refunds: 11874.44,
                },
                net_total: {
                  without_refunds: 801.64,
                  with_refunds: 805.98,
                },
                gross_total: {
                  without_refunds: 911.95,
                  with_refunds: 917.25,
                },
                net_total_spread: {
                  without_refunds: 10070.04,
                  with_refunds: 10104.41,
                },
                gross_total_spread: {
                  without_refunds: 11397.79,
                  with_refunds: 11433.7,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0512,
                  with_refunds: 0.0537,
                },
                gross_mrr: {
                  without_refunds: 0.0483,
                  with_refunds: 0.0506,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.7968,
                  with_refunds: 1.7968,
                },
                gross_arr_spread: {
                  without_refunds: 1.8058,
                  with_refunds: 1.8058,
                },
                net_total: {
                  without_refunds: 0.0082,
                  with_refunds: 0.009,
                },
                gross_total: {
                  without_refunds: 0.0082,
                  with_refunds: 0.009,
                },
                net_total_spread: {
                  without_refunds: 0.4521,
                  with_refunds: 0.4681,
                },
                gross_total_spread: {
                  without_refunds: 0.4305,
                  with_refunds: 0.4463,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.34,
                  with_refunds: 0.34,
                },
                gross_mrr: {
                  without_refunds: 0.39,
                  with_refunds: 0.39,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.7,
                  with_refunds: 7.7,
                },
                gross_arr_spread: {
                  without_refunds: 8.7,
                  with_refunds: 8.7,
                },
                net_total: {
                  without_refunds: 0.2,
                  with_refunds: 0.2,
                },
                gross_total: {
                  without_refunds: 0.23,
                  with_refunds: 0.23,
                },
                net_total_spread: {
                  without_refunds: 2.66,
                  with_refunds: 2.67,
                },
                gross_total_spread: {
                  without_refunds: 3.01,
                  with_refunds: 3.02,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.08,
                  with_refunds: 12.12,
                },
                gross_mrr: {
                  without_refunds: 13.79,
                  with_refunds: 13.84,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.75,
                  with_refunds: 7.75,
                },
                gross_arr_spread: {
                  without_refunds: 8.76,
                  with_refunds: 8.76,
                },
                net_total: {
                  without_refunds: 11.57,
                  with_refunds: 11.62,
                },
                gross_total: {
                  without_refunds: 13.16,
                  with_refunds: 13.23,
                },
                net_total_spread: {
                  without_refunds: 7.78,
                  with_refunds: 7.81,
                },
                gross_total_spread: {
                  without_refunds: 8.81,
                  with_refunds: 8.83,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.05,
                    with_refunds: 36.96,
                  },
                  gross_mrr: {
                    without_refunds: 44.45,
                    with_refunds: 43.15,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 72.49,
                    with_refunds: 72.49,
                  },
                  gross_arr_spread: {
                    without_refunds: 81.83,
                    with_refunds: 81.83,
                  },
                  net_total: {
                    without_refunds: 60.51,
                    with_refunds: 54.17,
                  },
                  gross_total: {
                    without_refunds: 69.3,
                    with_refunds: 62.09,
                  },
                  net_total_spread: {
                    without_refunds: 50.46,
                    with_refunds: 49.75,
                  },
                  gross_total_spread: {
                    without_refunds: 57.93,
                    with_refunds: 57.1,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 119.85,
                    with_refunds: 117.33,
                  },
                  gross_mrr: {
                    without_refunds: 138.57,
                    with_refunds: 135.62,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 76.96,
                    with_refunds: 76.96,
                  },
                  gross_arr_spread: {
                    without_refunds: 86.86,
                    with_refunds: 86.86,
                  },
                  net_total: {
                    without_refunds: 179.35,
                    with_refunds: 164.26,
                  },
                  gross_total: {
                    without_refunds: 206.21,
                    with_refunds: 188.81,
                  },
                  net_total_spread: {
                    without_refunds: 91.44,
                    with_refunds: 90.3,
                  },
                  gross_total_spread: {
                    without_refunds: 104.76,
                    with_refunds: 103.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8536,
                    with_refunds: 0.829,
                  },
                  gross_mrr: {
                    without_refunds: 0.997,
                    with_refunds: 0.968,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6261,
                    with_refunds: 1.6261,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8356,
                    with_refunds: 1.8356,
                  },
                  net_total: {
                    without_refunds: 1.3574,
                    with_refunds: 1.2151,
                  },
                  gross_total: {
                    without_refunds: 1.5546,
                    with_refunds: 1.3929,
                  },
                  net_total_spread: {
                    without_refunds: 1.1319,
                    with_refunds: 1.1161,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2994,
                    with_refunds: 1.2808,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.6883,
                    with_refunds: 2.6319,
                  },
                  gross_mrr: {
                    without_refunds: 3.1085,
                    with_refunds: 3.0423,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 1.7263,
                    with_refunds: 1.7263,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9484,
                    with_refunds: 1.9484,
                  },
                  net_total: {
                    without_refunds: 4.0231,
                    with_refunds: 3.6847,
                  },
                  gross_total: {
                    without_refunds: 4.6257,
                    with_refunds: 4.2352,
                  },
                  net_total_spread: {
                    without_refunds: 2.0512,
                    with_refunds: 2.0256,
                  },
                  gross_total_spread: {
                    without_refunds: 2.3498,
                    with_refunds: 2.3196,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 49.2,
                  with_refunds: 49.16,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1363.45,
                  with_refunds: 1363.45,
                },
                total_users: {
                  without_refunds: 48.98,
                  with_refunds: 48.94,
                },
                total_spread_users: {
                  without_refunds: 1141.45,
                  with_refunds: 1140.23,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0201,
                  with_refunds: 0.02,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9989,
                  with_refunds: 0.9989,
                },
                total_users: {
                  without_refunds: 0.0123,
                  with_refunds: 0.0123,
                },
                total_spread_users: {
                  without_refunds: 0.3016,
                  with_refunds: 0.3013,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 593.55,
                  with_refunds: 598.73,
                },
                gross_mrr: {
                  without_refunds: 676.56,
                  with_refunds: 682.16,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10586.66,
                  with_refunds: 10586.66,
                },
                gross_arr_spread: {
                  without_refunds: 11954.04,
                  with_refunds: 11954.04,
                },
                net_total: {
                  without_refunds: 567.67,
                  with_refunds: 573.7,
                },
                gross_total: {
                  without_refunds: 644.63,
                  with_refunds: 651.56,
                },
                net_total_spread: {
                  without_refunds: 8590.4,
                  with_refunds: 8648.73,
                },
                gross_total_spread: {
                  without_refunds: 9740.24,
                  with_refunds: 9801.43,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0361,
                  with_refunds: 0.038,
                },
                gross_mrr: {
                  without_refunds: 0.034,
                  with_refunds: 0.0358,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.811,
                  with_refunds: 1.811,
                },
                gross_arr_spread: {
                  without_refunds: 1.8179,
                  with_refunds: 1.8179,
                },
                net_total: {
                  without_refunds: 0.0058,
                  with_refunds: 0.0064,
                },
                gross_total: {
                  without_refunds: 0.0058,
                  with_refunds: 0.0064,
                },
                net_total_spread: {
                  without_refunds: 0.3857,
                  with_refunds: 0.4007,
                },
                gross_total_spread: {
                  without_refunds: 0.3679,
                  with_refunds: 0.3826,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.24,
                  with_refunds: 0.24,
                },
                gross_mrr: {
                  without_refunds: 0.28,
                  with_refunds: 0.28,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.76,
                  with_refunds: 7.76,
                },
                gross_arr_spread: {
                  without_refunds: 8.76,
                  with_refunds: 8.76,
                },
                net_total: {
                  without_refunds: 0.14,
                  with_refunds: 0.14,
                },
                gross_total: {
                  without_refunds: 0.16,
                  with_refunds: 0.16,
                },
                net_total_spread: {
                  without_refunds: 2.27,
                  with_refunds: 2.29,
                },
                gross_total_spread: {
                  without_refunds: 2.57,
                  with_refunds: 2.59,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.06,
                  with_refunds: 12.18,
                },
                gross_mrr: {
                  without_refunds: 13.75,
                  with_refunds: 13.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.76,
                  with_refunds: 7.76,
                },
                gross_arr_spread: {
                  without_refunds: 8.77,
                  with_refunds: 8.77,
                },
                net_total: {
                  without_refunds: 11.59,
                  with_refunds: 11.72,
                },
                gross_total: {
                  without_refunds: 13.16,
                  with_refunds: 13.31,
                },
                net_total_spread: {
                  without_refunds: 7.53,
                  with_refunds: 7.59,
                },
                gross_total_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.6,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.3,
                    with_refunds: 37.2,
                  },
                  gross_mrr: {
                    without_refunds: 44.72,
                    with_refunds: 43.43,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 80.25,
                    with_refunds: 80.25,
                  },
                  gross_arr_spread: {
                    without_refunds: 90.59,
                    with_refunds: 90.59,
                  },
                  net_total: {
                    without_refunds: 60.66,
                    with_refunds: 54.31,
                  },
                  gross_total: {
                    without_refunds: 69.47,
                    with_refunds: 62.26,
                  },
                  net_total_spread: {
                    without_refunds: 52.73,
                    with_refunds: 52.04,
                  },
                  gross_total_spread: {
                    without_refunds: 60.5,
                    with_refunds: 59.69,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 131.91,
                    with_refunds: 129.51,
                  },
                  gross_mrr: {
                    without_refunds: 152.33,
                    with_refunds: 149.5,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 84.72,
                    with_refunds: 84.72,
                  },
                  gross_arr_spread: {
                    without_refunds: 95.63,
                    with_refunds: 95.63,
                  },
                  net_total: {
                    without_refunds: 190.94,
                    with_refunds: 175.99,
                  },
                  gross_total: {
                    without_refunds: 219.38,
                    with_refunds: 202.12,
                  },
                  net_total_spread: {
                    without_refunds: 98.97,
                    with_refunds: 97.89,
                  },
                  gross_total_spread: {
                    without_refunds: 113.29,
                    with_refunds: 112.01,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.859,
                    with_refunds: 0.8344,
                  },
                  gross_mrr: {
                    without_refunds: 1.0032,
                    with_refunds: 0.9743,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 1.8001,
                    with_refunds: 1.8001,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.0321,
                    with_refunds: 2.0321,
                  },
                  net_total: {
                    without_refunds: 1.3606,
                    with_refunds: 1.2183,
                  },
                  gross_total: {
                    without_refunds: 1.5582,
                    with_refunds: 1.3965,
                  },
                  net_total_spread: {
                    without_refunds: 1.1828,
                    with_refunds: 1.1673,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3571,
                    with_refunds: 1.3388,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.9589,
                    with_refunds: 2.9051,
                  },
                  gross_mrr: {
                    without_refunds: 3.4169,
                    with_refunds: 3.3535,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 1.9005,
                    with_refunds: 1.9005,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.1451,
                    with_refunds: 2.1451,
                  },
                  net_total: {
                    without_refunds: 4.2831,
                    with_refunds: 3.9477,
                  },
                  gross_total: {
                    without_refunds: 4.921,
                    with_refunds: 4.5339,
                  },
                  net_total_spread: {
                    without_refunds: 2.22,
                    with_refunds: 2.1958,
                  },
                  gross_total_spread: {
                    without_refunds: 2.5413,
                    with_refunds: 2.5125,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 29.82,
                  with_refunds: 29.79,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1366.29,
                  with_refunds: 1366.29,
                },
                total_users: {
                  without_refunds: 29.59,
                  with_refunds: 29.55,
                },
                total_spread_users: {
                  without_refunds: 834.7,
                  with_refunds: 833.45,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0122,
                  with_refunds: 0.0121,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                total_users: {
                  without_refunds: 0.0074,
                  with_refunds: 0.0074,
                },
                total_spread_users: {
                  without_refunds: 0.2205,
                  with_refunds: 0.2202,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 361.78,
                  with_refunds: 367.4,
                },
                gross_mrr: {
                  without_refunds: 413.38,
                  with_refunds: 419.61,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10617.55,
                  with_refunds: 10617.55,
                },
                gross_arr_spread: {
                  without_refunds: 11981.42,
                  with_refunds: 11981.42,
                },
                net_total: {
                  without_refunds: 334.71,
                  with_refunds: 345.05,
                },
                gross_total: {
                  without_refunds: 378.11,
                  with_refunds: 391.45,
                },
                net_total_spread: {
                  without_refunds: 5923.2,
                  with_refunds: 5996.05,
                },
                gross_total_spread: {
                  without_refunds: 6910.43,
                  with_refunds: 6990.12,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.022,
                  with_refunds: 0.0233,
                },
                gross_mrr: {
                  without_refunds: 0.0208,
                  with_refunds: 0.022,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.8163,
                  with_refunds: 1.8163,
                },
                gross_arr_spread: {
                  without_refunds: 1.8221,
                  with_refunds: 1.8221,
                },
                net_total: {
                  without_refunds: 0.0034,
                  with_refunds: 0.0039,
                },
                gross_total: {
                  without_refunds: 0.0034,
                  with_refunds: 0.0038,
                },
                net_total_spread: {
                  without_refunds: 0.266,
                  with_refunds: 0.2778,
                },
                gross_total_spread: {
                  without_refunds: 0.261,
                  with_refunds: 0.2729,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.15,
                  with_refunds: 0.15,
                },
                gross_mrr: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.78,
                  with_refunds: 7.78,
                },
                gross_arr_spread: {
                  without_refunds: 8.78,
                  with_refunds: 8.78,
                },
                net_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.09,
                },
                gross_total: {
                  without_refunds: 0.09,
                  with_refunds: 0.1,
                },
                net_total_spread: {
                  without_refunds: 1.56,
                  with_refunds: 1.58,
                },
                gross_total_spread: {
                  without_refunds: 1.83,
                  with_refunds: 1.85,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.13,
                  with_refunds: 12.33,
                },
                gross_mrr: {
                  without_refunds: 13.86,
                  with_refunds: 14.09,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 7.77,
                  with_refunds: 7.77,
                },
                gross_arr_spread: {
                  without_refunds: 8.77,
                  with_refunds: 8.77,
                },
                net_total: {
                  without_refunds: 11.31,
                  with_refunds: 11.68,
                },
                gross_total: {
                  without_refunds: 12.78,
                  with_refunds: 13.25,
                },
                net_total_spread: {
                  without_refunds: 7.1,
                  with_refunds: 7.19,
                },
                gross_total_spread: {
                  without_refunds: 8.28,
                  with_refunds: 8.39,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.44,
                    with_refunds: 37.35,
                  },
                  gross_mrr: {
                    without_refunds: 44.89,
                    with_refunds: 43.6,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 88.03,
                    with_refunds: 88.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 99.37,
                    with_refunds: 99.37,
                  },
                  net_total: {
                    without_refunds: 60.74,
                    with_refunds: 54.4,
                  },
                  gross_total: {
                    without_refunds: 69.56,
                    with_refunds: 62.36,
                  },
                  net_total_spread: {
                    without_refunds: 54.29,
                    with_refunds: 53.62,
                  },
                  gross_total_spread: {
                    without_refunds: 62.32,
                    with_refunds: 61.53,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 144.04,
                    with_refunds: 141.84,
                  },
                  gross_mrr: {
                    without_refunds: 166.19,
                    with_refunds: 163.59,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 92.49,
                    with_refunds: 92.49,
                  },
                  gross_arr_spread: {
                    without_refunds: 104.4,
                    with_refunds: 104.4,
                  },
                  net_total: {
                    without_refunds: 202.26,
                    with_refunds: 187.66,
                  },
                  gross_total: {
                    without_refunds: 232.16,
                    with_refunds: 215.37,
                  },
                  net_total_spread: {
                    without_refunds: 106.07,
                    with_refunds: 105.08,
                  },
                  gross_total_spread: {
                    without_refunds: 121.57,
                    with_refunds: 120.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8623,
                    with_refunds: 0.8378,
                  },
                  gross_mrr: {
                    without_refunds: 1.007,
                    with_refunds: 0.9781,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 1.9746,
                    with_refunds: 1.9746,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.229,
                    with_refunds: 2.229,
                  },
                  net_total: {
                    without_refunds: 1.3625,
                    with_refunds: 1.2203,
                  },
                  gross_total: {
                    without_refunds: 1.5604,
                    with_refunds: 1.3987,
                  },
                  net_total_spread: {
                    without_refunds: 1.2179,
                    with_refunds: 1.2029,
                  },
                  gross_total_spread: {
                    without_refunds: 1.398,
                    with_refunds: 1.3803,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.231,
                    with_refunds: 3.1818,
                  },
                  gross_mrr: {
                    without_refunds: 3.7278,
                    with_refunds: 3.6695,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 2.0748,
                    with_refunds: 2.0748,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.3418,
                    with_refunds: 2.3418,
                  },
                  net_total: {
                    without_refunds: 4.5369,
                    with_refunds: 4.2096,
                  },
                  gross_total: {
                    without_refunds: 5.2076,
                    with_refunds: 4.831,
                  },
                  net_total_spread: {
                    without_refunds: 2.3792,
                    with_refunds: 2.3572,
                  },
                  gross_total_spread: {
                    without_refunds: 2.727,
                    with_refunds: 2.7006,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 8.96,
                  with_refunds: 8.94,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 464.88,
                  with_refunds: 464.88,
                },
                total_users: {
                  without_refunds: 8.88,
                  with_refunds: 8.85,
                },
                total_spread_users: {
                  without_refunds: 261.11,
                  with_refunds: 260.39,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0037,
                  with_refunds: 0.0036,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3406,
                  with_refunds: 0.3406,
                },
                total_users: {
                  without_refunds: 0.0022,
                  with_refunds: 0.0022,
                },
                total_spread_users: {
                  without_refunds: 0.069,
                  with_refunds: 0.0688,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 108.2,
                  with_refunds: 110.08,
                },
                gross_mrr: {
                  without_refunds: 122.89,
                  with_refunds: 124.96,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 4075.56,
                  with_refunds: 4075.56,
                },
                gross_arr_spread: {
                  without_refunds: 4300.72,
                  with_refunds: 4300.72,
                },
                net_total: {
                  without_refunds: 98.41,
                  with_refunds: 101.63,
                },
                gross_total: {
                  without_refunds: 110.39,
                  with_refunds: 114.48,
                },
                net_total_spread: {
                  without_refunds: 1870.81,
                  with_refunds: 1896.61,
                },
                gross_total_spread: {
                  without_refunds: 2161.17,
                  with_refunds: 2188.98,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0066,
                  with_refunds: 0.007,
                },
                gross_mrr: {
                  without_refunds: 0.0062,
                  with_refunds: 0.0066,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.6972,
                  with_refunds: 0.6972,
                },
                gross_arr_spread: {
                  without_refunds: 0.654,
                  with_refunds: 0.654,
                },
                net_total: {
                  without_refunds: 0.001,
                  with_refunds: 0.0011,
                },
                gross_total: {
                  without_refunds: 0.001,
                  with_refunds: 0.0011,
                },
                net_total_spread: {
                  without_refunds: 0.084,
                  with_refunds: 0.0879,
                },
                gross_total_spread: {
                  without_refunds: 0.0816,
                  with_refunds: 0.0854,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                gross_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.99,
                  with_refunds: 2.99,
                },
                gross_arr_spread: {
                  without_refunds: 3.15,
                  with_refunds: 3.15,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.03,
                },
                gross_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                net_total_spread: {
                  without_refunds: 0.49,
                  with_refunds: 0.5,
                },
                gross_total_spread: {
                  without_refunds: 0.57,
                  with_refunds: 0.58,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.07,
                  with_refunds: 12.32,
                },
                gross_mrr: {
                  without_refunds: 13.71,
                  with_refunds: 13.98,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.77,
                  with_refunds: 8.77,
                },
                gross_arr_spread: {
                  without_refunds: 9.25,
                  with_refunds: 9.25,
                },
                net_total: {
                  without_refunds: 11.09,
                  with_refunds: 11.48,
                },
                gross_total: {
                  without_refunds: 12.44,
                  with_refunds: 12.94,
                },
                net_total_spread: {
                  without_refunds: 7.16,
                  with_refunds: 7.28,
                },
                gross_total_spread: {
                  without_refunds: 8.28,
                  with_refunds: 8.41,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.49,
                    with_refunds: 37.39,
                  },
                  gross_mrr: {
                    without_refunds: 44.94,
                    with_refunds: 43.65,
                  },
                  net_arr: {
                    without_refunds: 93.43,
                    with_refunds: 79.17,
                  },
                  gross_arr: {
                    without_refunds: 105.67,
                    with_refunds: 89.53,
                  },
                  net_arr_spread: {
                    without_refunds: 91.01,
                    with_refunds: 91.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 102.52,
                    with_refunds: 102.52,
                  },
                  net_total: {
                    without_refunds: 60.76,
                    with_refunds: 54.42,
                  },
                  gross_total: {
                    without_refunds: 69.59,
                    with_refunds: 62.38,
                  },
                  net_total_spread: {
                    without_refunds: 54.79,
                    with_refunds: 54.13,
                  },
                  gross_total_spread: {
                    without_refunds: 62.9,
                    with_refunds: 62.11,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 156.11,
                    with_refunds: 154.16,
                  },
                  gross_mrr: {
                    without_refunds: 179.9,
                    with_refunds: 177.57,
                  },
                  net_arr: {
                    without_refunds: 869.41,
                    with_refunds: 482.73,
                  },
                  gross_arr: {
                    without_refunds: 1095.88,
                    with_refunds: 622.33,
                  },
                  net_arr_spread: {
                    without_refunds: 101.26,
                    with_refunds: 101.26,
                  },
                  gross_arr_spread: {
                    without_refunds: 113.65,
                    with_refunds: 113.65,
                  },
                  net_total: {
                    without_refunds: 213.34,
                    with_refunds: 199.15,
                  },
                  gross_total: {
                    without_refunds: 244.59,
                    with_refunds: 228.3,
                  },
                  net_total_spread: {
                    without_refunds: 113.23,
                    with_refunds: 112.37,
                  },
                  gross_total_spread: {
                    without_refunds: 129.85,
                    with_refunds: 128.8,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8633,
                    with_refunds: 0.8388,
                  },
                  gross_mrr: {
                    without_refunds: 1.0081,
                    with_refunds: 0.9792,
                  },
                  net_arr: {
                    without_refunds: 2.0957,
                    with_refunds: 1.7759,
                  },
                  gross_arr: {
                    without_refunds: 2.3703,
                    with_refunds: 2.0083,
                  },
                  net_arr_spread: {
                    without_refunds: 2.0416,
                    with_refunds: 2.0416,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.2996,
                    with_refunds: 2.2996,
                  },
                  net_total: {
                    without_refunds: 1.363,
                    with_refunds: 1.2208,
                  },
                  gross_total: {
                    without_refunds: 1.561,
                    with_refunds: 1.3994,
                  },
                  net_total_spread: {
                    without_refunds: 1.229,
                    with_refunds: 1.2141,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4108,
                    with_refunds: 1.3932,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.5018,
                    with_refunds: 3.4581,
                  },
                  gross_mrr: {
                    without_refunds: 4.0353,
                    with_refunds: 3.9832,
                  },
                  net_arr: {
                    without_refunds: 19.5022,
                    with_refunds: 10.8283,
                  },
                  gross_arr: {
                    without_refunds: 24.5824,
                    with_refunds: 13.9599,
                  },
                  net_arr_spread: {
                    without_refunds: 2.2715,
                    with_refunds: 2.2715,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.5493,
                    with_refunds: 2.5493,
                  },
                  net_total: {
                    without_refunds: 4.7856,
                    with_refunds: 4.4672,
                  },
                  gross_total: {
                    without_refunds: 5.4866,
                    with_refunds: 5.1212,
                  },
                  net_total_spread: {
                    without_refunds: 2.5399,
                    with_refunds: 2.5206,
                  },
                  gross_total_spread: {
                    without_refunds: 2.9126,
                    with_refunds: 2.8892,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 1406,
              with_refunds: 1406,
            },
            returning_users: {
              without_refunds: 6735,
              with_refunds: 6761,
            },
            paying_users: {
              without_refunds: 8141,
              with_refunds: 8167,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 904,
              with_refunds: 904,
            },
            returning_users: {
              without_refunds: 688,
              with_refunds: 725,
            },
            paying_users: {
              without_refunds: 1592,
              with_refunds: 1629,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 2304,
              with_refunds: 2304,
            },
            returning_users: {
              without_refunds: 7420,
              with_refunds: 7483,
            },
            paying_users: {
              without_refunds: 9724,
              with_refunds: 9787,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 92465.32,
                with_refunds: 89769.74,
              },
              gross_mrr: {
                without_refunds: 108022.83,
                with_refunds: 104850.43,
              },
              net_arr: {
                without_refunds: 148361.4,
                with_refunds: 125722.82,
              },
              gross_arr: {
                without_refunds: 167799.77,
                with_refunds: 142175.01,
              },
              net_arr_spread: {
                without_refunds: 88448.19,
                with_refunds: 88448.19,
              },
              gross_arr_spread: {
                without_refunds: 99825.64,
                with_refunds: 99825.64,
              },
              net_total: {
                without_refunds: 240826.72,
                with_refunds: 215492.56,
              },
              gross_total: {
                without_refunds: 275822.6,
                with_refunds: 247025.45,
              },
              net_total_spread: {
                without_refunds: 180913.51,
                with_refunds: 178217.93,
              },
              gross_total_spread: {
                without_refunds: 207848.47,
                with_refunds: 204676.07,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 94370.1,
                with_refunds: 91690.61,
              },
              gross_mrr: {
                without_refunds: 110196.28,
                with_refunds: 107041.42,
              },
              net_arr: {
                without_refunds: 148361.4,
                with_refunds: 125722.82,
              },
              gross_arr: {
                without_refunds: 167799.77,
                with_refunds: 142175.01,
              },
              net_arr_spread: {
                without_refunds: 124231.88,
                with_refunds: 124231.88,
              },
              gross_arr_spread: {
                without_refunds: 139936.25,
                with_refunds: 139936.25,
              },
              net_total: {
                without_refunds: 242629.15,
                with_refunds: 217318.93,
              },
              gross_total: {
                without_refunds: 277867.68,
                with_refunds: 249100.17,
              },
              net_total_spread: {
                without_refunds: 207367.96,
                with_refunds: 204863.73,
              },
              gross_total_spread: {
                without_refunds: 238058.11,
                with_refunds: 235090.3,
              },
            },
          },
        },
      },
      cac: 44.58,
      mrr_ua_spend: {
        without_refunds: 109310.16,
        with_refunds: 109310.16,
      },
      arr_ua_spend: {
        without_refunds: 70793.04,
        with_refunds: 70793.04,
      },
      arr_spread_ua_spend: {
        without_refunds: 60851.7,
        with_refunds: 60851.7,
      },
      total_ua_spend: {
        without_refunds: 178007.94,
        with_refunds: 178007.94,
      },
      total_spread_ua_spend: {
        without_refunds: 168735.3,
        with_refunds: 168735.3,
      },
    },
    {
      cohort_date: {
        year: 2023,
        month: 12,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 1273,
        with_refunds: 1273,
      },
      first_period_arr_users: {
        without_refunds: 1063,
        with_refunds: 1063,
      },
      first_period_arr_spread_users: {
        without_refunds: 794,
        with_refunds: 794,
      },
      first_period_total_users: {
        without_refunds: 2316,
        with_refunds: 2316,
      },
      first_period_total_spread_users: {
        without_refunds: 2057,
        with_refunds: 2057,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 925,
                  with_refunds: 925,
                },
                arr_users: {
                  without_refunds: 687,
                  with_refunds: 687,
                },
                arr_spread_users: {
                  without_refunds: 571,
                  with_refunds: 571,
                },
                total_users: {
                  without_refunds: 1610,
                  with_refunds: 1610,
                },
                total_spread_users: {
                  without_refunds: 1496,
                  with_refunds: 1496,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7266,
                  with_refunds: 0.7266,
                },
                arr_users: {
                  without_refunds: 0.6463,
                  with_refunds: 0.6463,
                },
                arr_spread_users: {
                  without_refunds: 0.7191,
                  with_refunds: 0.7191,
                },
                total_users: {
                  without_refunds: 0.6952,
                  with_refunds: 0.6952,
                },
                total_spread_users: {
                  without_refunds: 0.7273,
                  with_refunds: 0.7273,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 12218.1,
                  with_refunds: 11726.96,
                },
                gross_mrr: {
                  without_refunds: 14279.15,
                  with_refunds: 13723.35,
                },
                net_arr: {
                  without_refunds: 71272.18,
                  with_refunds: 62053.54,
                },
                gross_arr: {
                  without_refunds: 80446.46,
                  with_refunds: 70069.21,
                },
                net_arr_spread: {
                  without_refunds: 4940.04,
                  with_refunds: 4940.04,
                },
                gross_arr_spread: {
                  without_refunds: 5583.81,
                  with_refunds: 5583.81,
                },
                net_total: {
                  without_refunds: 83490.28,
                  with_refunds: 73780.5,
                },
                gross_total: {
                  without_refunds: 94725.61,
                  with_refunds: 83792.56,
                },
                net_total_spread: {
                  without_refunds: 17158.14,
                  with_refunds: 16667,
                },
                gross_total_spread: {
                  without_refunds: 19862.96,
                  with_refunds: 19307.16,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 9.6,
                  with_refunds: 9.21,
                },
                gross_mrr: {
                  without_refunds: 11.22,
                  with_refunds: 10.78,
                },
                net_arr: {
                  without_refunds: 67.05,
                  with_refunds: 58.38,
                },
                gross_arr: {
                  without_refunds: 75.68,
                  with_refunds: 65.92,
                },
                net_arr_spread: {
                  without_refunds: 6.22,
                  with_refunds: 6.22,
                },
                gross_arr_spread: {
                  without_refunds: 7.03,
                  with_refunds: 7.03,
                },
                net_total: {
                  without_refunds: 36.05,
                  with_refunds: 31.86,
                },
                gross_total: {
                  without_refunds: 40.9,
                  with_refunds: 36.18,
                },
                net_total_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.1,
                },
                gross_total_spread: {
                  without_refunds: 9.66,
                  with_refunds: 9.39,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.21,
                  with_refunds: 12.68,
                },
                gross_mrr: {
                  without_refunds: 15.44,
                  with_refunds: 14.84,
                },
                net_arr: {
                  without_refunds: 103.74,
                  with_refunds: 90.33,
                },
                gross_arr: {
                  without_refunds: 117.1,
                  with_refunds: 101.99,
                },
                net_arr_spread: {
                  without_refunds: 8.65,
                  with_refunds: 8.65,
                },
                gross_arr_spread: {
                  without_refunds: 9.78,
                  with_refunds: 9.78,
                },
                net_total: {
                  without_refunds: 51.86,
                  with_refunds: 45.83,
                },
                gross_total: {
                  without_refunds: 58.84,
                  with_refunds: 52.05,
                },
                net_total_spread: {
                  without_refunds: 11.47,
                  with_refunds: 11.14,
                },
                gross_total_spread: {
                  without_refunds: 13.28,
                  with_refunds: 12.91,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 9.6,
                    with_refunds: 9.21,
                  },
                  gross_mrr: {
                    without_refunds: 11.22,
                    with_refunds: 10.78,
                  },
                  net_arr: {
                    without_refunds: 67.05,
                    with_refunds: 58.38,
                  },
                  gross_arr: {
                    without_refunds: 75.68,
                    with_refunds: 65.92,
                  },
                  net_arr_spread: {
                    without_refunds: 6.22,
                    with_refunds: 6.22,
                  },
                  gross_arr_spread: {
                    without_refunds: 7.03,
                    with_refunds: 7.03,
                  },
                  net_total: {
                    without_refunds: 36.05,
                    with_refunds: 31.86,
                  },
                  gross_total: {
                    without_refunds: 40.9,
                    with_refunds: 36.18,
                  },
                  net_total_spread: {
                    without_refunds: 8.34,
                    with_refunds: 8.1,
                  },
                  gross_total_spread: {
                    without_refunds: 9.66,
                    with_refunds: 9.39,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 13.21,
                    with_refunds: 12.68,
                  },
                  gross_mrr: {
                    without_refunds: 15.44,
                    with_refunds: 14.84,
                  },
                  net_arr: {
                    without_refunds: 103.74,
                    with_refunds: 90.33,
                  },
                  gross_arr: {
                    without_refunds: 117.1,
                    with_refunds: 101.99,
                  },
                  net_arr_spread: {
                    without_refunds: 8.65,
                    with_refunds: 8.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.78,
                    with_refunds: 9.78,
                  },
                  net_total: {
                    without_refunds: 51.86,
                    with_refunds: 45.83,
                  },
                  gross_total: {
                    without_refunds: 58.84,
                    with_refunds: 52.05,
                  },
                  net_total_spread: {
                    without_refunds: 11.47,
                    with_refunds: 11.14,
                  },
                  gross_total_spread: {
                    without_refunds: 13.28,
                    with_refunds: 12.91,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1491,
                    with_refunds: 0.1431,
                  },
                  gross_mrr: {
                    without_refunds: 0.1743,
                    with_refunds: 0.1675,
                  },
                  net_arr: {
                    without_refunds: 1.0416,
                    with_refunds: 0.9069,
                  },
                  gross_arr: {
                    without_refunds: 1.1757,
                    with_refunds: 1.024,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0967,
                    with_refunds: 0.0967,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1093,
                    with_refunds: 0.1093,
                  },
                  net_total: {
                    without_refunds: 0.56,
                    with_refunds: 0.4949,
                  },
                  gross_total: {
                    without_refunds: 0.6354,
                    with_refunds: 0.5621,
                  },
                  net_total_spread: {
                    without_refunds: 0.1296,
                    with_refunds: 0.1259,
                  },
                  gross_total_spread: {
                    without_refunds: 0.15,
                    with_refunds: 0.1458,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2052,
                    with_refunds: 0.197,
                  },
                  gross_mrr: {
                    without_refunds: 0.2398,
                    with_refunds: 0.2305,
                  },
                  net_arr: {
                    without_refunds: 1.6117,
                    with_refunds: 1.4032,
                  },
                  gross_arr: {
                    without_refunds: 1.8191,
                    with_refunds: 1.5845,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1344,
                    with_refunds: 0.1344,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1519,
                    with_refunds: 0.1519,
                  },
                  net_total: {
                    without_refunds: 0.8056,
                    with_refunds: 0.7119,
                  },
                  gross_total: {
                    without_refunds: 0.914,
                    with_refunds: 0.8085,
                  },
                  net_total_spread: {
                    without_refunds: 0.1782,
                    with_refunds: 0.1731,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2063,
                    with_refunds: 0.2005,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 818,
                  with_refunds: 820,
                },
                arr_users: {
                  without_refunds: 351,
                  with_refunds: 375,
                },
                arr_spread_users: {
                  without_refunds: 769,
                  with_refunds: 769,
                },
                total_users: {
                  without_refunds: 1167,
                  with_refunds: 1193,
                },
                total_spread_users: {
                  without_refunds: 1587,
                  with_refunds: 1589,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6426,
                  with_refunds: 0.6441,
                },
                arr_users: {
                  without_refunds: 0.3302,
                  with_refunds: 0.3528,
                },
                arr_spread_users: {
                  without_refunds: 0.9685,
                  with_refunds: 0.9685,
                },
                total_users: {
                  without_refunds: 0.5039,
                  with_refunds: 0.5151,
                },
                total_spread_users: {
                  without_refunds: 0.7715,
                  with_refunds: 0.7725,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8843,
                  with_refunds: 0.8865,
                },
                arr_users: {
                  without_refunds: 0.5109,
                  with_refunds: 0.5459,
                },
                arr_spread_users: {
                  without_refunds: 1.3468,
                  with_refunds: 1.3468,
                },
                total_users: {
                  without_refunds: 0.7248,
                  with_refunds: 0.741,
                },
                total_spread_users: {
                  without_refunds: 1.0608,
                  with_refunds: 1.0622,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10684.92,
                  with_refunds: 9985.89,
                },
                gross_mrr: {
                  without_refunds: 12194.96,
                  with_refunds: 11446.5,
                },
                net_arr: {
                  without_refunds: 35254.14,
                  with_refunds: 19024.7,
                },
                gross_arr: {
                  without_refunds: 38844.09,
                  with_refunds: 21610.61,
                },
                net_arr_spread: {
                  without_refunds: 6633.68,
                  with_refunds: 6633.68,
                },
                gross_arr_spread: {
                  without_refunds: 7495.91,
                  with_refunds: 7495.91,
                },
                net_total: {
                  without_refunds: 45939.06,
                  with_refunds: 29010.6,
                },
                gross_total: {
                  without_refunds: 51039.05,
                  with_refunds: 33057.1,
                },
                net_total_spread: {
                  without_refunds: 17318.6,
                  with_refunds: 16619.57,
                },
                gross_total_spread: {
                  without_refunds: 19690.87,
                  with_refunds: 18942.4,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8745,
                  with_refunds: 0.8515,
                },
                gross_mrr: {
                  without_refunds: 0.854,
                  with_refunds: 0.8341,
                },
                net_arr: {
                  without_refunds: 0.4946,
                  with_refunds: 0.3066,
                },
                gross_arr: {
                  without_refunds: 0.4829,
                  with_refunds: 0.3084,
                },
                net_arr_spread: {
                  without_refunds: 1.3428,
                  with_refunds: 1.3428,
                },
                gross_arr_spread: {
                  without_refunds: 1.3424,
                  with_refunds: 1.3424,
                },
                net_total: {
                  without_refunds: 0.5502,
                  with_refunds: 0.3932,
                },
                gross_total: {
                  without_refunds: 0.5388,
                  with_refunds: 0.3945,
                },
                net_total_spread: {
                  without_refunds: 1.0094,
                  with_refunds: 0.9972,
                },
                gross_total_spread: {
                  without_refunds: 0.9913,
                  with_refunds: 0.9811,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8745,
                  with_refunds: 0.8515,
                },
                gross_mrr: {
                  without_refunds: 0.854,
                  with_refunds: 0.8341,
                },
                net_arr: {
                  without_refunds: 0.4946,
                  with_refunds: 0.3066,
                },
                gross_arr: {
                  without_refunds: 0.4829,
                  with_refunds: 0.3084,
                },
                net_arr_spread: {
                  without_refunds: 1.3428,
                  with_refunds: 1.3428,
                },
                gross_arr_spread: {
                  without_refunds: 1.3424,
                  with_refunds: 1.3424,
                },
                net_total: {
                  without_refunds: 0.5502,
                  with_refunds: 0.3932,
                },
                gross_total: {
                  without_refunds: 0.5388,
                  with_refunds: 0.3945,
                },
                net_total_spread: {
                  without_refunds: 1.0094,
                  with_refunds: 0.9972,
                },
                gross_total_spread: {
                  without_refunds: 0.9913,
                  with_refunds: 0.9811,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.39,
                  with_refunds: 7.84,
                },
                gross_mrr: {
                  without_refunds: 9.58,
                  with_refunds: 8.99,
                },
                net_arr: {
                  without_refunds: 33.16,
                  with_refunds: 17.9,
                },
                gross_arr: {
                  without_refunds: 36.54,
                  with_refunds: 20.33,
                },
                net_arr_spread: {
                  without_refunds: 8.35,
                  with_refunds: 8.35,
                },
                gross_arr_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.44,
                },
                net_total: {
                  without_refunds: 19.84,
                  with_refunds: 12.53,
                },
                gross_total: {
                  without_refunds: 22.04,
                  with_refunds: 14.27,
                },
                net_total_spread: {
                  without_refunds: 8.42,
                  with_refunds: 8.08,
                },
                gross_total_spread: {
                  without_refunds: 9.57,
                  with_refunds: 9.21,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.06,
                  with_refunds: 12.18,
                },
                gross_mrr: {
                  without_refunds: 14.91,
                  with_refunds: 13.96,
                },
                net_arr: {
                  without_refunds: 100.44,
                  with_refunds: 50.73,
                },
                gross_arr: {
                  without_refunds: 110.67,
                  with_refunds: 57.63,
                },
                net_arr_spread: {
                  without_refunds: 8.63,
                  with_refunds: 8.63,
                },
                gross_arr_spread: {
                  without_refunds: 9.75,
                  with_refunds: 9.75,
                },
                net_total: {
                  without_refunds: 39.37,
                  with_refunds: 24.32,
                },
                gross_total: {
                  without_refunds: 43.74,
                  with_refunds: 27.71,
                },
                net_total_spread: {
                  without_refunds: 10.91,
                  with_refunds: 10.46,
                },
                gross_total_spread: {
                  without_refunds: 12.41,
                  with_refunds: 11.92,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 17.99,
                    with_refunds: 17.06,
                  },
                  gross_mrr: {
                    without_refunds: 20.8,
                    with_refunds: 19.77,
                  },
                  net_arr: {
                    without_refunds: 100.21,
                    with_refunds: 76.27,
                  },
                  gross_arr: {
                    without_refunds: 112.22,
                    with_refunds: 86.25,
                  },
                  net_arr_spread: {
                    without_refunds: 14.58,
                    with_refunds: 14.58,
                  },
                  gross_arr_spread: {
                    without_refunds: 16.47,
                    with_refunds: 16.47,
                  },
                  net_total: {
                    without_refunds: 55.88,
                    with_refunds: 44.38,
                  },
                  gross_total: {
                    without_refunds: 62.94,
                    with_refunds: 50.45,
                  },
                  net_total_spread: {
                    without_refunds: 16.76,
                    with_refunds: 16.18,
                  },
                  gross_total_spread: {
                    without_refunds: 19.23,
                    with_refunds: 18.59,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 26.27,
                    with_refunds: 24.86,
                  },
                  gross_mrr: {
                    without_refunds: 30.35,
                    with_refunds: 28.8,
                  },
                  net_arr: {
                    without_refunds: 204.18,
                    with_refunds: 141.06,
                  },
                  gross_arr: {
                    without_refunds: 227.77,
                    with_refunds: 159.62,
                  },
                  net_arr_spread: {
                    without_refunds: 17.28,
                    with_refunds: 17.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 19.53,
                    with_refunds: 19.53,
                  },
                  net_total: {
                    without_refunds: 91.22,
                    with_refunds: 70.14,
                  },
                  gross_total: {
                    without_refunds: 102.57,
                    with_refunds: 79.75,
                  },
                  net_total_spread: {
                    without_refunds: 22.38,
                    with_refunds: 21.6,
                  },
                  gross_total_spread: {
                    without_refunds: 25.68,
                    with_refunds: 24.83,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2795,
                    with_refunds: 0.265,
                  },
                  gross_mrr: {
                    without_refunds: 0.3231,
                    with_refunds: 0.3072,
                  },
                  net_arr: {
                    without_refunds: 1.5568,
                    with_refunds: 1.1849,
                  },
                  gross_arr: {
                    without_refunds: 1.7434,
                    with_refunds: 1.3399,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2264,
                    with_refunds: 0.2264,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2559,
                    with_refunds: 0.2559,
                  },
                  net_total: {
                    without_refunds: 0.8682,
                    with_refunds: 0.6895,
                  },
                  gross_total: {
                    without_refunds: 0.9778,
                    with_refunds: 0.7838,
                  },
                  net_total_spread: {
                    without_refunds: 0.2604,
                    with_refunds: 0.2514,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2987,
                    with_refunds: 0.2889,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4081,
                    with_refunds: 0.3861,
                  },
                  gross_mrr: {
                    without_refunds: 0.4714,
                    with_refunds: 0.4473,
                  },
                  net_arr: {
                    without_refunds: 3.172,
                    with_refunds: 2.1914,
                  },
                  gross_arr: {
                    without_refunds: 3.5384,
                    with_refunds: 2.4797,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2684,
                    with_refunds: 0.2684,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3033,
                    with_refunds: 0.3033,
                  },
                  net_total: {
                    without_refunds: 1.4172,
                    with_refunds: 1.0897,
                  },
                  gross_total: {
                    without_refunds: 1.5935,
                    with_refunds: 1.239,
                  },
                  net_total_spread: {
                    without_refunds: 0.3477,
                    with_refunds: 0.3356,
                  },
                  gross_total_spread: {
                    without_refunds: 0.399,
                    with_refunds: 0.3857,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 566,
                  with_refunds: 567,
                },
                arr_users: {
                  without_refunds: 15,
                  with_refunds: 22,
                },
                arr_spread_users: {
                  without_refunds: 780,
                  with_refunds: 780,
                },
                total_users: {
                  without_refunds: 581,
                  with_refunds: 589,
                },
                total_spread_users: {
                  without_refunds: 1346,
                  with_refunds: 1347,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4446,
                  with_refunds: 0.4454,
                },
                arr_users: {
                  without_refunds: 0.0141,
                  with_refunds: 0.0207,
                },
                arr_spread_users: {
                  without_refunds: 0.9824,
                  with_refunds: 0.9824,
                },
                total_users: {
                  without_refunds: 0.2509,
                  with_refunds: 0.2543,
                },
                total_spread_users: {
                  without_refunds: 0.6544,
                  with_refunds: 0.6548,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6919,
                  with_refunds: 0.6915,
                },
                arr_users: {
                  without_refunds: 0.0427,
                  with_refunds: 0.0587,
                },
                arr_spread_users: {
                  without_refunds: 1.0143,
                  with_refunds: 1.0143,
                },
                total_users: {
                  without_refunds: 0.4979,
                  with_refunds: 0.4937,
                },
                total_spread_users: {
                  without_refunds: 0.8481,
                  with_refunds: 0.8477,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7376.73,
                  with_refunds: 7207.38,
                },
                gross_mrr: {
                  without_refunds: 8424.85,
                  with_refunds: 8240.48,
                },
                net_arr: {
                  without_refunds: 1676.33,
                  with_refunds: 597.79,
                },
                gross_arr: {
                  without_refunds: 2094.16,
                  with_refunds: 792.4,
                },
                net_arr_spread: {
                  without_refunds: 6738.74,
                  with_refunds: 6738.74,
                },
                gross_arr_spread: {
                  without_refunds: 7630.82,
                  with_refunds: 7630.82,
                },
                net_total: {
                  without_refunds: 9053.06,
                  with_refunds: 7805.16,
                },
                gross_total: {
                  without_refunds: 10519.01,
                  with_refunds: 9032.89,
                },
                net_total_spread: {
                  without_refunds: 14115.47,
                  with_refunds: 13946.12,
                },
                gross_total_spread: {
                  without_refunds: 16055.67,
                  with_refunds: 15871.3,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6038,
                  with_refunds: 0.6146,
                },
                gross_mrr: {
                  without_refunds: 0.59,
                  with_refunds: 0.6005,
                },
                net_arr: {
                  without_refunds: 0.0235,
                  with_refunds: 0.0096,
                },
                gross_arr: {
                  without_refunds: 0.026,
                  with_refunds: 0.0113,
                },
                net_arr_spread: {
                  without_refunds: 1.3641,
                  with_refunds: 1.3641,
                },
                gross_arr_spread: {
                  without_refunds: 1.3666,
                  with_refunds: 1.3666,
                },
                net_total: {
                  without_refunds: 0.1084,
                  with_refunds: 0.1058,
                },
                gross_total: {
                  without_refunds: 0.111,
                  with_refunds: 0.1078,
                },
                net_total_spread: {
                  without_refunds: 0.8227,
                  with_refunds: 0.8368,
                },
                gross_total_spread: {
                  without_refunds: 0.8083,
                  with_refunds: 0.822,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6904,
                  with_refunds: 0.7218,
                },
                gross_mrr: {
                  without_refunds: 0.6908,
                  with_refunds: 0.7199,
                },
                net_arr: {
                  without_refunds: 0.0475,
                  with_refunds: 0.0314,
                },
                gross_arr: {
                  without_refunds: 0.0539,
                  with_refunds: 0.0367,
                },
                net_arr_spread: {
                  without_refunds: 1.0158,
                  with_refunds: 1.0158,
                },
                gross_arr_spread: {
                  without_refunds: 1.018,
                  with_refunds: 1.018,
                },
                net_total: {
                  without_refunds: 0.1971,
                  with_refunds: 0.269,
                },
                gross_total: {
                  without_refunds: 0.2061,
                  with_refunds: 0.2733,
                },
                net_total_spread: {
                  without_refunds: 0.815,
                  with_refunds: 0.8391,
                },
                gross_total_spread: {
                  without_refunds: 0.8154,
                  with_refunds: 0.8379,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.79,
                  with_refunds: 5.66,
                },
                gross_mrr: {
                  without_refunds: 6.62,
                  with_refunds: 6.47,
                },
                net_arr: {
                  without_refunds: 1.58,
                  with_refunds: 0.56,
                },
                gross_arr: {
                  without_refunds: 1.97,
                  with_refunds: 0.75,
                },
                net_arr_spread: {
                  without_refunds: 8.49,
                  with_refunds: 8.49,
                },
                gross_arr_spread: {
                  without_refunds: 9.61,
                  with_refunds: 9.61,
                },
                net_total: {
                  without_refunds: 3.91,
                  with_refunds: 3.37,
                },
                gross_total: {
                  without_refunds: 4.54,
                  with_refunds: 3.9,
                },
                net_total_spread: {
                  without_refunds: 6.86,
                  with_refunds: 6.78,
                },
                gross_total_spread: {
                  without_refunds: 7.81,
                  with_refunds: 7.72,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.03,
                  with_refunds: 12.71,
                },
                gross_mrr: {
                  without_refunds: 14.88,
                  with_refunds: 14.53,
                },
                net_arr: {
                  without_refunds: 111.76,
                  with_refunds: 27.17,
                },
                gross_arr: {
                  without_refunds: 139.61,
                  with_refunds: 36.02,
                },
                net_arr_spread: {
                  without_refunds: 8.64,
                  with_refunds: 8.64,
                },
                gross_arr_spread: {
                  without_refunds: 9.78,
                  with_refunds: 9.78,
                },
                net_total: {
                  without_refunds: 15.58,
                  with_refunds: 13.25,
                },
                gross_total: {
                  without_refunds: 18.11,
                  with_refunds: 15.34,
                },
                net_total_spread: {
                  without_refunds: 10.49,
                  with_refunds: 10.35,
                },
                gross_total_spread: {
                  without_refunds: 11.93,
                  with_refunds: 11.78,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.79,
                    with_refunds: 22.72,
                  },
                  gross_mrr: {
                    without_refunds: 27.41,
                    with_refunds: 26.25,
                  },
                  net_arr: {
                    without_refunds: 101.79,
                    with_refunds: 76.84,
                  },
                  gross_arr: {
                    without_refunds: 114.19,
                    with_refunds: 86.99,
                  },
                  net_arr_spread: {
                    without_refunds: 23.06,
                    with_refunds: 23.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 26.08,
                    with_refunds: 26.08,
                  },
                  net_total: {
                    without_refunds: 59.79,
                    with_refunds: 47.75,
                  },
                  gross_total: {
                    without_refunds: 67.48,
                    with_refunds: 54.35,
                  },
                  net_total_spread: {
                    without_refunds: 23.62,
                    with_refunds: 22.96,
                  },
                  gross_total_spread: {
                    without_refunds: 27.03,
                    with_refunds: 26.31,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.3,
                    with_refunds: 37.57,
                  },
                  gross_mrr: {
                    without_refunds: 45.23,
                    with_refunds: 43.33,
                  },
                  net_arr: {
                    without_refunds: 315.94,
                    with_refunds: 168.23,
                  },
                  gross_arr: {
                    without_refunds: 367.38,
                    with_refunds: 195.64,
                  },
                  net_arr_spread: {
                    without_refunds: 25.92,
                    with_refunds: 25.92,
                  },
                  gross_arr_spread: {
                    without_refunds: 29.31,
                    with_refunds: 29.31,
                  },
                  net_total: {
                    without_refunds: 106.8,
                    with_refunds: 83.4,
                  },
                  gross_total: {
                    without_refunds: 120.68,
                    with_refunds: 95.09,
                  },
                  net_total_spread: {
                    without_refunds: 32.87,
                    with_refunds: 31.95,
                  },
                  gross_total_spread: {
                    without_refunds: 37.61,
                    with_refunds: 36.61,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3695,
                    with_refunds: 0.3529,
                  },
                  gross_mrr: {
                    without_refunds: 0.4259,
                    with_refunds: 0.4077,
                  },
                  net_arr: {
                    without_refunds: 1.5813,
                    with_refunds: 1.1937,
                  },
                  gross_arr: {
                    without_refunds: 1.774,
                    with_refunds: 1.3514,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3583,
                    with_refunds: 0.3583,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4052,
                    with_refunds: 0.4052,
                  },
                  net_total: {
                    without_refunds: 0.9289,
                    with_refunds: 0.7419,
                  },
                  gross_total: {
                    without_refunds: 1.0483,
                    with_refunds: 0.8444,
                  },
                  net_total_spread: {
                    without_refunds: 0.367,
                    with_refunds: 0.3567,
                  },
                  gross_total_spread: {
                    without_refunds: 0.42,
                    with_refunds: 0.4087,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6106,
                    with_refunds: 0.5836,
                  },
                  gross_mrr: {
                    without_refunds: 0.7027,
                    with_refunds: 0.6731,
                  },
                  net_arr: {
                    without_refunds: 4.9082,
                    with_refunds: 2.6135,
                  },
                  gross_arr: {
                    without_refunds: 5.7073,
                    with_refunds: 3.0393,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4026,
                    with_refunds: 0.4026,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4553,
                    with_refunds: 0.4553,
                  },
                  net_total: {
                    without_refunds: 1.6592,
                    with_refunds: 1.2956,
                  },
                  gross_total: {
                    without_refunds: 1.8747,
                    with_refunds: 1.4772,
                  },
                  net_total_spread: {
                    without_refunds: 0.5106,
                    with_refunds: 0.4964,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5843,
                    with_refunds: 0.5687,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 478,
                  with_refunds: 483,
                },
                arr_users: {
                  without_refunds: 8,
                  with_refunds: 14,
                },
                arr_spread_users: {
                  without_refunds: 785,
                  with_refunds: 785,
                },
                total_users: {
                  without_refunds: 486,
                  with_refunds: 496,
                },
                total_spread_users: {
                  without_refunds: 1263,
                  with_refunds: 1268,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3755,
                  with_refunds: 0.3794,
                },
                arr_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0132,
                },
                arr_spread_users: {
                  without_refunds: 0.9887,
                  with_refunds: 0.9887,
                },
                total_users: {
                  without_refunds: 0.2098,
                  with_refunds: 0.2142,
                },
                total_spread_users: {
                  without_refunds: 0.614,
                  with_refunds: 0.6164,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8445,
                  with_refunds: 0.8519,
                },
                arr_users: {
                  without_refunds: 0.5333,
                  with_refunds: 0.6364,
                },
                arr_spread_users: {
                  without_refunds: 1.0064,
                  with_refunds: 1.0064,
                },
                total_users: {
                  without_refunds: 0.8365,
                  with_refunds: 0.8421,
                },
                total_spread_users: {
                  without_refunds: 0.9383,
                  with_refunds: 0.9414,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6270.74,
                  with_refunds: 6100.34,
                },
                gross_mrr: {
                  without_refunds: 7099.76,
                  with_refunds: 6916.66,
                },
                net_arr: {
                  without_refunds: 931.75,
                  with_refunds: 128.96,
                },
                gross_arr: {
                  without_refunds: 1194.26,
                  with_refunds: 278.33,
                },
                net_arr_spread: {
                  without_refunds: 6785.25,
                  with_refunds: 6785.25,
                },
                gross_arr_spread: {
                  without_refunds: 7691.69,
                  with_refunds: 7691.69,
                },
                net_total: {
                  without_refunds: 7202.49,
                  with_refunds: 6229.3,
                },
                gross_total: {
                  without_refunds: 8294.01,
                  with_refunds: 7194.99,
                },
                net_total_spread: {
                  without_refunds: 13055.99,
                  with_refunds: 12885.59,
                },
                gross_total_spread: {
                  without_refunds: 14791.44,
                  with_refunds: 14608.35,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5132,
                  with_refunds: 0.5202,
                },
                gross_mrr: {
                  without_refunds: 0.4972,
                  with_refunds: 0.504,
                },
                net_arr: {
                  without_refunds: 0.0131,
                  with_refunds: 0.0021,
                },
                gross_arr: {
                  without_refunds: 0.0148,
                  with_refunds: 0.004,
                },
                net_arr_spread: {
                  without_refunds: 1.3735,
                  with_refunds: 1.3735,
                },
                gross_arr_spread: {
                  without_refunds: 1.3775,
                  with_refunds: 1.3775,
                },
                net_total: {
                  without_refunds: 0.0863,
                  with_refunds: 0.0844,
                },
                gross_total: {
                  without_refunds: 0.0876,
                  with_refunds: 0.0859,
                },
                net_total_spread: {
                  without_refunds: 0.7609,
                  with_refunds: 0.7731,
                },
                gross_total_spread: {
                  without_refunds: 0.7447,
                  with_refunds: 0.7566,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8501,
                  with_refunds: 0.8464,
                },
                gross_mrr: {
                  without_refunds: 0.8427,
                  with_refunds: 0.8394,
                },
                net_arr: {
                  without_refunds: 0.5558,
                  with_refunds: 0.2157,
                },
                gross_arr: {
                  without_refunds: 0.5703,
                  with_refunds: 0.3512,
                },
                net_arr_spread: {
                  without_refunds: 1.0069,
                  with_refunds: 1.0069,
                },
                gross_arr_spread: {
                  without_refunds: 1.008,
                  with_refunds: 1.008,
                },
                net_total: {
                  without_refunds: 0.7956,
                  with_refunds: 0.7981,
                },
                gross_total: {
                  without_refunds: 0.7885,
                  with_refunds: 0.7965,
                },
                net_total_spread: {
                  without_refunds: 0.9249,
                  with_refunds: 0.924,
                },
                gross_total_spread: {
                  without_refunds: 0.9213,
                  with_refunds: 0.9204,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.93,
                  with_refunds: 4.79,
                },
                gross_mrr: {
                  without_refunds: 5.58,
                  with_refunds: 5.43,
                },
                net_arr: {
                  without_refunds: 0.88,
                  with_refunds: 0.12,
                },
                gross_arr: {
                  without_refunds: 1.12,
                  with_refunds: 0.26,
                },
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.69,
                  with_refunds: 9.69,
                },
                net_total: {
                  without_refunds: 3.11,
                  with_refunds: 2.69,
                },
                gross_total: {
                  without_refunds: 3.58,
                  with_refunds: 3.11,
                },
                net_total_spread: {
                  without_refunds: 6.35,
                  with_refunds: 6.26,
                },
                gross_total_spread: {
                  without_refunds: 7.19,
                  with_refunds: 7.1,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 12.63,
                },
                gross_mrr: {
                  without_refunds: 14.85,
                  with_refunds: 14.32,
                },
                net_arr: {
                  without_refunds: 116.47,
                  with_refunds: 9.21,
                },
                gross_arr: {
                  without_refunds: 149.28,
                  with_refunds: 19.88,
                },
                net_arr_spread: {
                  without_refunds: 8.64,
                  with_refunds: 8.64,
                },
                gross_arr_spread: {
                  without_refunds: 9.8,
                  with_refunds: 9.8,
                },
                net_total: {
                  without_refunds: 14.82,
                  with_refunds: 12.56,
                },
                gross_total: {
                  without_refunds: 17.07,
                  with_refunds: 14.51,
                },
                net_total_spread: {
                  without_refunds: 10.34,
                  with_refunds: 10.16,
                },
                gross_total_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.52,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.71,
                    with_refunds: 27.51,
                  },
                  gross_mrr: {
                    without_refunds: 32.99,
                    with_refunds: 31.68,
                  },
                  net_arr: {
                    without_refunds: 102.67,
                    with_refunds: 76.96,
                  },
                  gross_arr: {
                    without_refunds: 115.31,
                    with_refunds: 87.25,
                  },
                  net_arr_spread: {
                    without_refunds: 31.61,
                    with_refunds: 31.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 35.77,
                    with_refunds: 35.77,
                  },
                  net_total: {
                    without_refunds: 62.9,
                    with_refunds: 50.44,
                  },
                  gross_total: {
                    without_refunds: 71.06,
                    with_refunds: 57.46,
                  },
                  net_total_spread: {
                    without_refunds: 29.97,
                    with_refunds: 29.23,
                  },
                  gross_total_spread: {
                    without_refunds: 34.23,
                    with_refunds: 33.41,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 52.42,
                    with_refunds: 50.2,
                  },
                  gross_mrr: {
                    without_refunds: 60.08,
                    with_refunds: 57.65,
                  },
                  net_arr: {
                    without_refunds: 432.41,
                    with_refunds: 177.44,
                  },
                  gross_arr: {
                    without_refunds: 516.66,
                    with_refunds: 215.52,
                  },
                  net_arr_spread: {
                    without_refunds: 34.56,
                    with_refunds: 34.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 39.11,
                    with_refunds: 39.11,
                  },
                  net_total: {
                    without_refunds: 121.62,
                    with_refunds: 95.95,
                  },
                  gross_total: {
                    without_refunds: 137.74,
                    with_refunds: 109.6,
                  },
                  net_total_spread: {
                    without_refunds: 43.21,
                    with_refunds: 42.12,
                  },
                  gross_total_spread: {
                    without_refunds: 49.32,
                    with_refunds: 48.13,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.446,
                    with_refunds: 0.4274,
                  },
                  gross_mrr: {
                    without_refunds: 0.5125,
                    with_refunds: 0.4921,
                  },
                  net_arr: {
                    without_refunds: 1.5949,
                    with_refunds: 1.1955,
                  },
                  gross_arr: {
                    without_refunds: 1.7914,
                    with_refunds: 1.3555,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4911,
                    with_refunds: 0.4911,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5557,
                    with_refunds: 0.5557,
                  },
                  net_total: {
                    without_refunds: 0.9772,
                    with_refunds: 0.7836,
                  },
                  gross_total: {
                    without_refunds: 1.1039,
                    with_refunds: 0.8927,
                  },
                  net_total_spread: {
                    without_refunds: 0.4656,
                    with_refunds: 0.454,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5317,
                    with_refunds: 0.5191,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8144,
                    with_refunds: 0.7798,
                  },
                  gross_mrr: {
                    without_refunds: 0.9334,
                    with_refunds: 0.8956,
                  },
                  net_arr: {
                    without_refunds: 6.7175,
                    with_refunds: 2.7566,
                  },
                  gross_arr: {
                    without_refunds: 8.0264,
                    with_refunds: 3.3481,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5369,
                    with_refunds: 0.5369,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6076,
                    with_refunds: 0.6076,
                  },
                  net_total: {
                    without_refunds: 1.8895,
                    with_refunds: 1.4907,
                  },
                  gross_total: {
                    without_refunds: 2.1398,
                    with_refunds: 1.7026,
                  },
                  net_total_spread: {
                    without_refunds: 0.6712,
                    with_refunds: 0.6543,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7663,
                    with_refunds: 0.7477,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 398,
                  with_refunds: 400,
                },
                arr_users: {
                  without_refunds: 5,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 403,
                  with_refunds: 405,
                },
                total_spread_users: {
                  without_refunds: 1182,
                  with_refunds: 1184,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3126,
                  with_refunds: 0.3142,
                },
                arr_users: {
                  without_refunds: 0.0047,
                  with_refunds: 0.0056,
                },
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.174,
                  with_refunds: 0.1749,
                },
                total_spread_users: {
                  without_refunds: 0.5746,
                  with_refunds: 0.5756,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8326,
                  with_refunds: 0.8282,
                },
                arr_users: {
                  without_refunds: 0.625,
                  with_refunds: 0.4286,
                },
                arr_spread_users: {
                  without_refunds: 0.9987,
                  with_refunds: 0.9987,
                },
                total_users: {
                  without_refunds: 0.8292,
                  with_refunds: 0.8165,
                },
                total_spread_users: {
                  without_refunds: 0.9359,
                  with_refunds: 0.9338,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5124.47,
                  with_refunds: 4990.55,
                },
                gross_mrr: {
                  without_refunds: 5818.38,
                  with_refunds: 5666.47,
                },
                net_arr: {
                  without_refunds: 597.01,
                  with_refunds: 353.68,
                },
                gross_arr: {
                  without_refunds: 828.23,
                  with_refunds: 504.25,
                },
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 5721.48,
                  with_refunds: 5344.24,
                },
                gross_total: {
                  without_refunds: 6646.61,
                  with_refunds: 6170.71,
                },
                net_total_spread: {
                  without_refunds: 11915.12,
                  with_refunds: 11781.19,
                },
                gross_total_spread: {
                  without_refunds: 13526.91,
                  with_refunds: 13374.99,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.4194,
                  with_refunds: 0.4256,
                },
                gross_mrr: {
                  without_refunds: 0.4075,
                  with_refunds: 0.4129,
                },
                net_arr: {
                  without_refunds: 0.0084,
                  with_refunds: 0.0057,
                },
                gross_arr: {
                  without_refunds: 0.0103,
                  with_refunds: 0.0072,
                },
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0685,
                  with_refunds: 0.0724,
                },
                gross_total: {
                  without_refunds: 0.0702,
                  with_refunds: 0.0736,
                },
                net_total_spread: {
                  without_refunds: 0.6944,
                  with_refunds: 0.7069,
                },
                gross_total_spread: {
                  without_refunds: 0.681,
                  with_refunds: 0.6927,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8172,
                  with_refunds: 0.8181,
                },
                gross_mrr: {
                  without_refunds: 0.8195,
                  with_refunds: 0.8192,
                },
                net_arr: {
                  without_refunds: 0.6407,
                  with_refunds: 2.7426,
                },
                gross_arr: {
                  without_refunds: 0.6935,
                  with_refunds: 1.8117,
                },
                net_arr_spread: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                gross_arr_spread: {
                  without_refunds: 1.0022,
                  with_refunds: 1.0022,
                },
                net_total: {
                  without_refunds: 0.7944,
                  with_refunds: 0.8579,
                },
                gross_total: {
                  without_refunds: 0.8014,
                  with_refunds: 0.8576,
                },
                net_total_spread: {
                  without_refunds: 0.9126,
                  with_refunds: 0.9143,
                },
                gross_total_spread: {
                  without_refunds: 0.9145,
                  with_refunds: 0.9156,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.03,
                  with_refunds: 3.92,
                },
                gross_mrr: {
                  without_refunds: 4.57,
                  with_refunds: 4.45,
                },
                net_arr: {
                  without_refunds: 0.56,
                  with_refunds: 0.33,
                },
                gross_arr: {
                  without_refunds: 0.78,
                  with_refunds: 0.47,
                },
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 2.47,
                  with_refunds: 2.31,
                },
                gross_total: {
                  without_refunds: 2.87,
                  with_refunds: 2.66,
                },
                net_total_spread: {
                  without_refunds: 5.79,
                  with_refunds: 5.73,
                },
                gross_total_spread: {
                  without_refunds: 6.58,
                  with_refunds: 6.5,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.88,
                  with_refunds: 12.48,
                },
                gross_mrr: {
                  without_refunds: 14.62,
                  with_refunds: 14.17,
                },
                net_arr: {
                  without_refunds: 119.4,
                  with_refunds: 58.95,
                },
                gross_arr: {
                  without_refunds: 165.65,
                  with_refunds: 84.04,
                },
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 14.2,
                  with_refunds: 13.2,
                },
                gross_total: {
                  without_refunds: 16.49,
                  with_refunds: 15.24,
                },
                net_total_spread: {
                  without_refunds: 10.08,
                  with_refunds: 9.95,
                },
                gross_total_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.3,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 32.74,
                    with_refunds: 31.43,
                  },
                  gross_mrr: {
                    without_refunds: 37.56,
                    with_refunds: 36.13,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 40.16,
                    with_refunds: 40.16,
                  },
                  gross_arr_spread: {
                    without_refunds: 45.48,
                    with_refunds: 45.48,
                  },
                  net_total: {
                    without_refunds: 65.37,
                    with_refunds: 52.75,
                  },
                  gross_total: {
                    without_refunds: 73.93,
                    with_refunds: 60.12,
                  },
                  net_total_spread: {
                    without_refunds: 35.76,
                    with_refunds: 34.95,
                  },
                  gross_total_spread: {
                    without_refunds: 40.8,
                    with_refunds: 39.91,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 65.3,
                    with_refunds: 62.67,
                  },
                  gross_mrr: {
                    without_refunds: 74.7,
                    with_refunds: 71.82,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 43.22,
                    with_refunds: 43.22,
                  },
                  gross_arr_spread: {
                    without_refunds: 48.94,
                    with_refunds: 48.94,
                  },
                  net_total: {
                    without_refunds: 135.82,
                    with_refunds: 109.15,
                  },
                  gross_total: {
                    without_refunds: 154.23,
                    with_refunds: 124.83,
                  },
                  net_total_spread: {
                    without_refunds: 53.29,
                    with_refunds: 52.07,
                  },
                  gross_total_spread: {
                    without_refunds: 60.77,
                    with_refunds: 59.43,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5086,
                    with_refunds: 0.4883,
                  },
                  gross_mrr: {
                    without_refunds: 0.5835,
                    with_refunds: 0.5613,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6239,
                    with_refunds: 0.6239,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7065,
                    with_refunds: 0.7065,
                  },
                  net_total: {
                    without_refunds: 1.0156,
                    with_refunds: 0.8195,
                  },
                  gross_total: {
                    without_refunds: 1.1485,
                    with_refunds: 0.934,
                  },
                  net_total_spread: {
                    without_refunds: 0.5556,
                    with_refunds: 0.543,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6339,
                    with_refunds: 0.6201,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0144,
                    with_refunds: 0.9736,
                  },
                  gross_mrr: {
                    without_refunds: 1.1605,
                    with_refunds: 1.1157,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6715,
                    with_refunds: 0.6715,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7603,
                    with_refunds: 0.7603,
                  },
                  net_total: {
                    without_refunds: 2.11,
                    with_refunds: 1.6957,
                  },
                  gross_total: {
                    without_refunds: 2.3961,
                    with_refunds: 1.9393,
                  },
                  net_total_spread: {
                    without_refunds: 0.8278,
                    with_refunds: 0.8089,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9441,
                    with_refunds: 0.9232,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 339,
                  with_refunds: 344,
                },
                arr_users: {
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 339,
                  with_refunds: 348,
                },
                total_spread_users: {
                  without_refunds: 1123,
                  with_refunds: 1128,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2663,
                  with_refunds: 0.2702,
                },
                arr_users: {
                  with_refunds: 0.0038,
                },
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.1464,
                  with_refunds: 0.1503,
                },
                total_spread_users: {
                  without_refunds: 0.5459,
                  with_refunds: 0.5484,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8518,
                  with_refunds: 0.86,
                },
                arr_users: {
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8412,
                  with_refunds: 0.8593,
                },
                total_spread_users: {
                  without_refunds: 0.9501,
                  with_refunds: 0.9527,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4405.01,
                  with_refunds: 4204.3,
                },
                gross_mrr: {
                  without_refunds: 4973.67,
                  with_refunds: 4761.68,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 4405.01,
                  with_refunds: 4204.3,
                },
                gross_total: {
                  without_refunds: 4973.67,
                  with_refunds: 4761.68,
                },
                net_total_spread: {
                  without_refunds: 11195.66,
                  with_refunds: 10994.95,
                },
                gross_total_spread: {
                  without_refunds: 12682.2,
                  with_refunds: 12470.21,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3605,
                  with_refunds: 0.3585,
                },
                gross_mrr: {
                  without_refunds: 0.3483,
                  with_refunds: 0.347,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0528,
                  with_refunds: 0.057,
                },
                gross_total: {
                  without_refunds: 0.0525,
                  with_refunds: 0.0568,
                },
                net_total_spread: {
                  without_refunds: 0.6525,
                  with_refunds: 0.6597,
                },
                gross_total_spread: {
                  without_refunds: 0.6385,
                  with_refunds: 0.6459,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8596,
                  with_refunds: 0.8425,
                },
                gross_mrr: {
                  without_refunds: 0.8548,
                  with_refunds: 0.8403,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.7699,
                  with_refunds: 0.7867,
                },
                gross_total: {
                  without_refunds: 0.7483,
                  with_refunds: 0.7717,
                },
                net_total_spread: {
                  without_refunds: 0.9396,
                  with_refunds: 0.9333,
                },
                gross_total_spread: {
                  without_refunds: 0.9376,
                  with_refunds: 0.9324,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.46,
                  with_refunds: 3.3,
                },
                gross_mrr: {
                  without_refunds: 3.91,
                  with_refunds: 3.74,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 1.9,
                  with_refunds: 1.82,
                },
                gross_total: {
                  without_refunds: 2.15,
                  with_refunds: 2.06,
                },
                net_total_spread: {
                  without_refunds: 5.44,
                  with_refunds: 5.35,
                },
                gross_total_spread: {
                  without_refunds: 6.17,
                  with_refunds: 6.06,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.99,
                  with_refunds: 12.22,
                },
                gross_mrr: {
                  without_refunds: 14.67,
                  with_refunds: 13.84,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 12.99,
                  with_refunds: 12.08,
                },
                gross_total: {
                  without_refunds: 14.67,
                  with_refunds: 13.68,
                },
                net_total_spread: {
                  without_refunds: 9.97,
                  with_refunds: 9.75,
                },
                gross_total_spread: {
                  without_refunds: 11.29,
                  with_refunds: 11.06,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.2,
                    with_refunds: 34.73,
                  },
                  gross_mrr: {
                    without_refunds: 41.47,
                    with_refunds: 39.87,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 48.71,
                    with_refunds: 48.71,
                  },
                  gross_arr_spread: {
                    without_refunds: 55.19,
                    with_refunds: 55.19,
                  },
                  net_total: {
                    without_refunds: 67.28,
                    with_refunds: 54.57,
                  },
                  gross_total: {
                    without_refunds: 76.08,
                    with_refunds: 62.18,
                  },
                  net_total_spread: {
                    without_refunds: 41.21,
                    with_refunds: 40.3,
                  },
                  gross_total_spread: {
                    without_refunds: 46.97,
                    with_refunds: 45.98,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 78.29,
                    with_refunds: 74.9,
                  },
                  gross_mrr: {
                    without_refunds: 89.37,
                    with_refunds: 85.66,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 51.88,
                    with_refunds: 51.88,
                  },
                  gross_arr_spread: {
                    without_refunds: 58.77,
                    with_refunds: 58.77,
                  },
                  net_total: {
                    without_refunds: 148.82,
                    with_refunds: 121.23,
                  },
                  gross_total: {
                    without_refunds: 168.91,
                    with_refunds: 138.52,
                  },
                  net_total_spread: {
                    without_refunds: 63.26,
                    with_refunds: 61.81,
                  },
                  gross_total_spread: {
                    without_refunds: 72.06,
                    with_refunds: 70.48,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5623,
                    with_refunds: 0.5396,
                  },
                  gross_mrr: {
                    without_refunds: 0.6442,
                    with_refunds: 0.6194,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7568,
                    with_refunds: 0.7568,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8574,
                    with_refunds: 0.8574,
                  },
                  net_total: {
                    without_refunds: 1.0451,
                    with_refunds: 0.8477,
                  },
                  gross_total: {
                    without_refunds: 1.1819,
                    with_refunds: 0.966,
                  },
                  net_total_spread: {
                    without_refunds: 0.6401,
                    with_refunds: 0.626,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7296,
                    with_refunds: 0.7143,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2163,
                    with_refunds: 1.1635,
                  },
                  gross_mrr: {
                    without_refunds: 1.3884,
                    with_refunds: 1.3307,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 0.806,
                    with_refunds: 0.806,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.913,
                    with_refunds: 0.913,
                  },
                  net_total: {
                    without_refunds: 2.3119,
                    with_refunds: 1.8834,
                  },
                  gross_total: {
                    without_refunds: 2.624,
                    with_refunds: 2.1519,
                  },
                  net_total_spread: {
                    without_refunds: 0.9827,
                    with_refunds: 0.9603,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1195,
                    with_refunds: 1.0949,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 288,
                  with_refunds: 291,
                },
                arr_users: {
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 288,
                  with_refunds: 292,
                },
                total_spread_users: {
                  without_refunds: 1072,
                  with_refunds: 1075,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2262,
                  with_refunds: 0.2286,
                },
                arr_users: {
                  with_refunds: 0.0009,
                },
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.1244,
                  with_refunds: 0.1261,
                },
                total_spread_users: {
                  without_refunds: 0.5211,
                  with_refunds: 0.5226,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8496,
                  with_refunds: 0.8459,
                },
                arr_users: {
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.8496,
                  with_refunds: 0.8391,
                },
                total_spread_users: {
                  without_refunds: 0.9546,
                  with_refunds: 0.953,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3789.25,
                  with_refunds: 3716.6,
                },
                gross_mrr: {
                  without_refunds: 4280.25,
                  with_refunds: 4198.62,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 3789.25,
                  with_refunds: 3716.6,
                },
                gross_total: {
                  without_refunds: 4280.25,
                  with_refunds: 4198.62,
                },
                net_total_spread: {
                  without_refunds: 10579.89,
                  with_refunds: 10507.24,
                },
                gross_total_spread: {
                  without_refunds: 11988.78,
                  with_refunds: 11907.15,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3101,
                  with_refunds: 0.3169,
                },
                gross_mrr: {
                  without_refunds: 0.2998,
                  with_refunds: 0.3059,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0454,
                  with_refunds: 0.0504,
                },
                gross_total: {
                  without_refunds: 0.0452,
                  with_refunds: 0.0501,
                },
                net_total_spread: {
                  without_refunds: 0.6166,
                  with_refunds: 0.6304,
                },
                gross_total_spread: {
                  without_refunds: 0.6036,
                  with_refunds: 0.6167,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8602,
                  with_refunds: 0.884,
                },
                gross_mrr: {
                  without_refunds: 0.8606,
                  with_refunds: 0.8818,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.8602,
                  with_refunds: 0.884,
                },
                gross_total: {
                  without_refunds: 0.8606,
                  with_refunds: 0.8818,
                },
                net_total_spread: {
                  without_refunds: 0.945,
                  with_refunds: 0.9556,
                },
                gross_total_spread: {
                  without_refunds: 0.9453,
                  with_refunds: 0.9548,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.98,
                  with_refunds: 2.92,
                },
                gross_mrr: {
                  without_refunds: 3.36,
                  with_refunds: 3.3,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 1.64,
                  with_refunds: 1.6,
                },
                gross_total: {
                  without_refunds: 1.85,
                  with_refunds: 1.81,
                },
                net_total_spread: {
                  without_refunds: 5.14,
                  with_refunds: 5.11,
                },
                gross_total_spread: {
                  without_refunds: 5.83,
                  with_refunds: 5.79,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.16,
                  with_refunds: 12.77,
                },
                gross_mrr: {
                  without_refunds: 14.86,
                  with_refunds: 14.43,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 13.16,
                  with_refunds: 12.73,
                },
                gross_total: {
                  without_refunds: 14.86,
                  with_refunds: 14.38,
                },
                net_total_spread: {
                  without_refunds: 9.87,
                  with_refunds: 9.77,
                },
                gross_total_spread: {
                  without_refunds: 11.18,
                  with_refunds: 11.08,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.17,
                    with_refunds: 37.65,
                  },
                  gross_mrr: {
                    without_refunds: 44.83,
                    with_refunds: 43.17,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 57.27,
                    with_refunds: 57.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.9,
                    with_refunds: 64.9,
                  },
                  net_total: {
                    without_refunds: 68.91,
                    with_refunds: 56.17,
                  },
                  gross_total: {
                    without_refunds: 77.93,
                    with_refunds: 63.99,
                  },
                  net_total_spread: {
                    without_refunds: 46.35,
                    with_refunds: 45.41,
                  },
                  gross_total_spread: {
                    without_refunds: 52.79,
                    with_refunds: 51.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 91.45,
                    with_refunds: 87.67,
                  },
                  gross_mrr: {
                    without_refunds: 104.24,
                    with_refunds: 100.09,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 60.55,
                    with_refunds: 60.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 68.6,
                    with_refunds: 68.6,
                  },
                  net_total: {
                    without_refunds: 161.97,
                    with_refunds: 133.96,
                  },
                  gross_total: {
                    without_refunds: 183.77,
                    with_refunds: 152.89,
                  },
                  net_total_spread: {
                    without_refunds: 73.13,
                    with_refunds: 71.59,
                  },
                  gross_total_spread: {
                    without_refunds: 83.25,
                    with_refunds: 81.56,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6086,
                    with_refunds: 0.5849,
                  },
                  gross_mrr: {
                    without_refunds: 0.6965,
                    with_refunds: 0.6706,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8896,
                    with_refunds: 0.8896,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0082,
                    with_refunds: 1.0082,
                  },
                  net_total: {
                    without_refunds: 1.0706,
                    with_refunds: 0.8726,
                  },
                  gross_total: {
                    without_refunds: 1.2106,
                    with_refunds: 0.9941,
                  },
                  net_total_spread: {
                    without_refunds: 0.72,
                    with_refunds: 0.7054,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8202,
                    with_refunds: 0.8042,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.4207,
                    with_refunds: 1.3619,
                  },
                  gross_mrr: {
                    without_refunds: 1.6193,
                    with_refunds: 1.5548,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9406,
                    with_refunds: 0.9406,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0658,
                    with_refunds: 1.0658,
                  },
                  net_total: {
                    without_refunds: 2.5163,
                    with_refunds: 2.0811,
                  },
                  gross_total: {
                    without_refunds: 2.8549,
                    with_refunds: 2.3752,
                  },
                  net_total_spread: {
                    without_refunds: 1.136,
                    with_refunds: 1.1121,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2932,
                    with_refunds: 1.267,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 48,
                  with_refunds: 49,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 784,
                  with_refunds: 784,
                },
                total_users: {
                  without_refunds: 48,
                  with_refunds: 49,
                },
                total_spread_users: {
                  without_refunds: 832,
                  with_refunds: 833,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0377,
                  with_refunds: 0.0385,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9874,
                  with_refunds: 0.9874,
                },
                total_users: {
                  without_refunds: 0.0207,
                  with_refunds: 0.0212,
                },
                total_spread_users: {
                  without_refunds: 0.4045,
                  with_refunds: 0.405,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1684,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1678,
                },
                total_spread_users: {
                  without_refunds: 0.7761,
                  with_refunds: 0.7749,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 627.58,
                  with_refunds: 548.13,
                },
                gross_mrr: {
                  without_refunds: 719.78,
                  with_refunds: 635.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6790.64,
                  with_refunds: 6790.64,
                },
                gross_arr_spread: {
                  without_refunds: 7708.53,
                  with_refunds: 7708.53,
                },
                net_total: {
                  without_refunds: 627.58,
                  with_refunds: 548.13,
                },
                gross_total: {
                  without_refunds: 719.78,
                  with_refunds: 635.78,
                },
                net_total_spread: {
                  without_refunds: 7418.22,
                  with_refunds: 7338.77,
                },
                gross_total_spread: {
                  without_refunds: 8428.31,
                  with_refunds: 8344.31,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0514,
                  with_refunds: 0.0467,
                },
                gross_mrr: {
                  without_refunds: 0.0504,
                  with_refunds: 0.0463,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3746,
                  with_refunds: 1.3746,
                },
                gross_arr_spread: {
                  without_refunds: 1.3805,
                  with_refunds: 1.3805,
                },
                net_total: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0074,
                },
                gross_total: {
                  without_refunds: 0.0076,
                  with_refunds: 0.0076,
                },
                net_total_spread: {
                  without_refunds: 0.4323,
                  with_refunds: 0.4403,
                },
                gross_total_spread: {
                  without_refunds: 0.4243,
                  with_refunds: 0.4322,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.1656,
                  with_refunds: 0.1475,
                },
                gross_mrr: {
                  without_refunds: 0.1682,
                  with_refunds: 0.1514,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.1656,
                  with_refunds: 0.1475,
                },
                gross_total: {
                  without_refunds: 0.1682,
                  with_refunds: 0.1514,
                },
                net_total_spread: {
                  without_refunds: 0.7012,
                  with_refunds: 0.6984,
                },
                gross_total_spread: {
                  without_refunds: 0.703,
                  with_refunds: 0.7008,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.49,
                  with_refunds: 0.43,
                },
                gross_mrr: {
                  without_refunds: 0.57,
                  with_refunds: 0.5,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                net_total: {
                  without_refunds: 0.27,
                  with_refunds: 0.24,
                },
                gross_total: {
                  without_refunds: 0.31,
                  with_refunds: 0.27,
                },
                net_total_spread: {
                  without_refunds: 3.61,
                  with_refunds: 3.57,
                },
                gross_total_spread: {
                  without_refunds: 4.1,
                  with_refunds: 4.06,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.07,
                  with_refunds: 11.19,
                },
                gross_mrr: {
                  without_refunds: 15,
                  with_refunds: 12.98,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.83,
                  with_refunds: 9.83,
                },
                net_total: {
                  without_refunds: 13.07,
                  with_refunds: 11.19,
                },
                gross_total: {
                  without_refunds: 15,
                  with_refunds: 12.98,
                },
                net_total_spread: {
                  without_refunds: 8.92,
                  with_refunds: 8.81,
                },
                gross_total_spread: {
                  without_refunds: 10.13,
                  with_refunds: 10.02,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.67,
                    with_refunds: 38.08,
                  },
                  gross_mrr: {
                    without_refunds: 45.4,
                    with_refunds: 43.67,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 65.82,
                    with_refunds: 65.82,
                  },
                  gross_arr_spread: {
                    without_refunds: 74.6,
                    with_refunds: 74.6,
                  },
                  net_total: {
                    without_refunds: 69.18,
                    with_refunds: 56.41,
                  },
                  gross_total: {
                    without_refunds: 78.24,
                    with_refunds: 64.27,
                  },
                  net_total_spread: {
                    without_refunds: 49.95,
                    with_refunds: 48.97,
                  },
                  gross_total_spread: {
                    without_refunds: 56.89,
                    with_refunds: 55.82,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 104.52,
                    with_refunds: 98.85,
                  },
                  gross_mrr: {
                    without_refunds: 119.23,
                    with_refunds: 113.06,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 69.21,
                    with_refunds: 69.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.44,
                    with_refunds: 78.44,
                  },
                  net_total: {
                    without_refunds: 175.05,
                    with_refunds: 145.15,
                  },
                  gross_total: {
                    without_refunds: 198.76,
                    with_refunds: 165.87,
                  },
                  net_total_spread: {
                    without_refunds: 82.04,
                    with_refunds: 80.4,
                  },
                  gross_total_spread: {
                    without_refunds: 93.38,
                    with_refunds: 91.58,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6162,
                    with_refunds: 0.5916,
                  },
                  gross_mrr: {
                    without_refunds: 0.7053,
                    with_refunds: 0.6784,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0225,
                    with_refunds: 1.0225,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.159,
                    with_refunds: 1.159,
                  },
                  net_total: {
                    without_refunds: 1.0748,
                    with_refunds: 0.8763,
                  },
                  gross_total: {
                    without_refunds: 1.2154,
                    with_refunds: 0.9984,
                  },
                  net_total_spread: {
                    without_refunds: 0.7761,
                    with_refunds: 0.7608,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8838,
                    with_refunds: 0.8672,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.6238,
                    with_refunds: 1.5357,
                  },
                  gross_mrr: {
                    without_refunds: 1.8523,
                    with_refunds: 1.7564,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0751,
                    with_refunds: 1.0751,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2185,
                    with_refunds: 1.2185,
                  },
                  net_total: {
                    without_refunds: 2.7194,
                    with_refunds: 2.2549,
                  },
                  gross_total: {
                    without_refunds: 3.0878,
                    with_refunds: 2.5768,
                  },
                  net_total_spread: {
                    without_refunds: 1.2745,
                    with_refunds: 1.249,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4506,
                    with_refunds: 1.4226,
                  },
                },
              },
            },
          },
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 31.65,
                  with_refunds: 32.34,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 785.56,
                  with_refunds: 785.56,
                },
                total_users: {
                  without_refunds: 31.61,
                  with_refunds: 32.27,
                },
                total_spread_users: {
                  without_refunds: 754.49,
                  with_refunds: 755.44,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0249,
                  with_refunds: 0.0254,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9894,
                  with_refunds: 0.9894,
                },
                total_users: {
                  without_refunds: 0.0136,
                  with_refunds: 0.0139,
                },
                total_spread_users: {
                  without_refunds: 0.3668,
                  with_refunds: 0.3673,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 416.75,
                  with_refunds: 363.3,
                },
                gross_mrr: {
                  without_refunds: 477.58,
                  with_refunds: 421.06,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6807.5,
                  with_refunds: 6807.5,
                },
                gross_arr_spread: {
                  without_refunds: 7729.52,
                  with_refunds: 7729.52,
                },
                net_total: {
                  without_refunds: 412.89,
                  with_refunds: 363.89,
                },
                gross_total: {
                  without_refunds: 472.27,
                  with_refunds: 421.64,
                },
                net_total_spread: {
                  without_refunds: 6512.92,
                  with_refunds: 6444.65,
                },
                gross_total_spread: {
                  without_refunds: 7390.88,
                  with_refunds: 7318.94,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0341,
                  with_refunds: 0.031,
                },
                gross_mrr: {
                  without_refunds: 0.0334,
                  with_refunds: 0.0307,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.378,
                  with_refunds: 1.378,
                },
                gross_arr_spread: {
                  without_refunds: 1.3843,
                  with_refunds: 1.3843,
                },
                net_total: {
                  without_refunds: 0.0049,
                  with_refunds: 0.0049,
                },
                gross_total: {
                  without_refunds: 0.005,
                  with_refunds: 0.005,
                },
                net_total_spread: {
                  without_refunds: 0.3796,
                  with_refunds: 0.3867,
                },
                gross_total_spread: {
                  without_refunds: 0.3721,
                  with_refunds: 0.3791,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.33,
                  with_refunds: 0.29,
                },
                gross_mrr: {
                  without_refunds: 0.38,
                  with_refunds: 0.33,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.57,
                  with_refunds: 8.57,
                },
                gross_arr_spread: {
                  without_refunds: 9.73,
                  with_refunds: 9.73,
                },
                net_total: {
                  without_refunds: 0.18,
                  with_refunds: 0.16,
                },
                gross_total: {
                  without_refunds: 0.2,
                  with_refunds: 0.18,
                },
                net_total_spread: {
                  without_refunds: 3.17,
                  with_refunds: 3.13,
                },
                gross_total_spread: {
                  without_refunds: 3.59,
                  with_refunds: 3.56,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.17,
                  with_refunds: 11.23,
                },
                gross_mrr: {
                  without_refunds: 15.09,
                  with_refunds: 13.02,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.67,
                },
                gross_arr_spread: {
                  without_refunds: 9.84,
                  with_refunds: 9.84,
                },
                net_total: {
                  without_refunds: 13.06,
                  with_refunds: 11.28,
                },
                gross_total: {
                  without_refunds: 14.94,
                  with_refunds: 13.07,
                },
                net_total_spread: {
                  without_refunds: 8.63,
                  with_refunds: 8.53,
                },
                gross_total_spread: {
                  without_refunds: 9.8,
                  with_refunds: 9.69,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.99,
                    with_refunds: 38.37,
                  },
                  gross_mrr: {
                    without_refunds: 45.77,
                    with_refunds: 44,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 74.39,
                    with_refunds: 74.39,
                  },
                  gross_arr_spread: {
                    without_refunds: 84.34,
                    with_refunds: 84.34,
                  },
                  net_total: {
                    without_refunds: 69.36,
                    with_refunds: 56.56,
                  },
                  gross_total: {
                    without_refunds: 78.44,
                    with_refunds: 64.45,
                  },
                  net_total_spread: {
                    without_refunds: 53.12,
                    with_refunds: 52.11,
                  },
                  gross_total_spread: {
                    without_refunds: 60.49,
                    with_refunds: 59.38,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 117.69,
                    with_refunds: 110.09,
                  },
                  gross_mrr: {
                    without_refunds: 134.32,
                    with_refunds: 126.08,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 77.87,
                    with_refunds: 77.87,
                  },
                  gross_arr_spread: {
                    without_refunds: 88.28,
                    with_refunds: 88.28,
                  },
                  net_total: {
                    without_refunds: 188.11,
                    with_refunds: 156.42,
                  },
                  gross_total: {
                    without_refunds: 213.7,
                    with_refunds: 178.93,
                  },
                  net_total_spread: {
                    without_refunds: 90.67,
                    with_refunds: 88.93,
                  },
                  gross_total_spread: {
                    without_refunds: 103.17,
                    with_refunds: 101.26,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6213,
                    with_refunds: 0.5961,
                  },
                  gross_mrr: {
                    without_refunds: 0.7111,
                    with_refunds: 0.6835,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1557,
                    with_refunds: 1.1557,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3102,
                    with_refunds: 1.3102,
                  },
                  net_total: {
                    without_refunds: 1.0775,
                    with_refunds: 0.8787,
                  },
                  gross_total: {
                    without_refunds: 1.2186,
                    with_refunds: 1.0012,
                  },
                  net_total_spread: {
                    without_refunds: 0.8252,
                    with_refunds: 0.8095,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9396,
                    with_refunds: 0.9225,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.8284,
                    with_refunds: 1.7102,
                  },
                  gross_mrr: {
                    without_refunds: 2.0867,
                    with_refunds: 1.9587,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2098,
                    with_refunds: 1.2098,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3714,
                    with_refunds: 1.3714,
                  },
                  net_total: {
                    without_refunds: 2.9223,
                    with_refunds: 2.43,
                  },
                  gross_total: {
                    without_refunds: 3.3199,
                    with_refunds: 2.7798,
                  },
                  net_total_spread: {
                    without_refunds: 1.4086,
                    with_refunds: 1.3815,
                  },
                  gross_total_spread: {
                    without_refunds: 1.6028,
                    with_refunds: 1.5732,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 20.99,
                  with_refunds: 21.46,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 787.79,
                  with_refunds: 787.79,
                },
                total_users: {
                  without_refunds: 20.86,
                  with_refunds: 21.32,
                },
                total_spread_users: {
                  without_refunds: 670.32,
                  with_refunds: 671.11,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0165,
                  with_refunds: 0.0169,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9922,
                  with_refunds: 0.9922,
                },
                total_users: {
                  without_refunds: 0.009,
                  with_refunds: 0.0092,
                },
                total_spread_users: {
                  without_refunds: 0.3259,
                  with_refunds: 0.3263,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 276.24,
                  with_refunds: 241.79,
                },
                gross_mrr: {
                  without_refunds: 315.98,
                  with_refunds: 279.64,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6835.87,
                  with_refunds: 6835.87,
                },
                gross_arr_spread: {
                  without_refunds: 7758.68,
                  with_refunds: 7758.68,
                },
                net_total: {
                  without_refunds: 260.79,
                  with_refunds: 231.09,
                },
                gross_total: {
                  without_refunds: 296.63,
                  with_refunds: 266.37,
                },
                net_total_spread: {
                  without_refunds: 5591.49,
                  with_refunds: 5551.77,
                },
                gross_total_spread: {
                  without_refunds: 6342.52,
                  with_refunds: 6300.58,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0226,
                  with_refunds: 0.0206,
                },
                gross_mrr: {
                  without_refunds: 0.0221,
                  with_refunds: 0.0204,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3838,
                  with_refunds: 1.3838,
                },
                gross_arr_spread: {
                  without_refunds: 1.3895,
                  with_refunds: 1.3895,
                },
                net_total: {
                  without_refunds: 0.0031,
                  with_refunds: 0.0031,
                },
                gross_total: {
                  without_refunds: 0.0031,
                  with_refunds: 0.0032,
                },
                net_total_spread: {
                  without_refunds: 0.3259,
                  with_refunds: 0.3331,
                },
                gross_total_spread: {
                  without_refunds: 0.3193,
                  with_refunds: 0.3263,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.22,
                  with_refunds: 0.19,
                },
                gross_mrr: {
                  without_refunds: 0.25,
                  with_refunds: 0.22,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.61,
                  with_refunds: 8.61,
                },
                gross_arr_spread: {
                  without_refunds: 9.77,
                  with_refunds: 9.77,
                },
                net_total: {
                  without_refunds: 0.11,
                  with_refunds: 0.1,
                },
                gross_total: {
                  without_refunds: 0.13,
                  with_refunds: 0.12,
                },
                net_total_spread: {
                  without_refunds: 2.72,
                  with_refunds: 2.7,
                },
                gross_total_spread: {
                  without_refunds: 3.08,
                  with_refunds: 3.06,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.16,
                  with_refunds: 11.26,
                },
                gross_mrr: {
                  without_refunds: 15.05,
                  with_refunds: 13.03,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.68,
                  with_refunds: 8.68,
                },
                gross_arr_spread: {
                  without_refunds: 9.85,
                  with_refunds: 9.85,
                },
                net_total: {
                  without_refunds: 12.5,
                  with_refunds: 10.84,
                },
                gross_total: {
                  without_refunds: 14.22,
                  with_refunds: 12.5,
                },
                net_total_spread: {
                  without_refunds: 8.34,
                  with_refunds: 8.27,
                },
                gross_total_spread: {
                  without_refunds: 9.46,
                  with_refunds: 9.39,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.21,
                    with_refunds: 38.56,
                  },
                  gross_mrr: {
                    without_refunds: 46.02,
                    with_refunds: 44.22,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 83,
                    with_refunds: 83,
                  },
                  gross_arr_spread: {
                    without_refunds: 94.11,
                    with_refunds: 94.11,
                  },
                  net_total: {
                    without_refunds: 69.47,
                    with_refunds: 56.66,
                  },
                  gross_total: {
                    without_refunds: 78.57,
                    with_refunds: 64.56,
                  },
                  net_total_spread: {
                    without_refunds: 55.84,
                    with_refunds: 54.81,
                  },
                  gross_total_spread: {
                    without_refunds: 63.57,
                    with_refunds: 62.44,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 130.85,
                    with_refunds: 121.35,
                  },
                  gross_mrr: {
                    without_refunds: 149.37,
                    with_refunds: 139.11,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 86.55,
                    with_refunds: 86.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.13,
                    with_refunds: 98.13,
                  },
                  net_total: {
                    without_refunds: 200.61,
                    with_refunds: 167.26,
                  },
                  gross_total: {
                    without_refunds: 227.92,
                    with_refunds: 191.43,
                  },
                  net_total_spread: {
                    without_refunds: 99.02,
                    with_refunds: 97.2,
                  },
                  gross_total_spread: {
                    without_refunds: 112.63,
                    with_refunds: 110.65,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6247,
                    with_refunds: 0.599,
                  },
                  gross_mrr: {
                    without_refunds: 0.7149,
                    with_refunds: 0.6869,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2895,
                    with_refunds: 1.2895,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.462,
                    with_refunds: 1.462,
                  },
                  net_total: {
                    without_refunds: 1.0793,
                    with_refunds: 0.8803,
                  },
                  gross_total: {
                    without_refunds: 1.2206,
                    with_refunds: 1.003,
                  },
                  net_total_spread: {
                    without_refunds: 0.8675,
                    with_refunds: 0.8514,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9875,
                    with_refunds: 0.9701,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.0328,
                    with_refunds: 1.8852,
                  },
                  gross_mrr: {
                    without_refunds: 2.3205,
                    with_refunds: 2.1611,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3446,
                    with_refunds: 1.3446,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5244,
                    with_refunds: 1.5244,
                  },
                  net_total: {
                    without_refunds: 3.1165,
                    with_refunds: 2.5985,
                  },
                  gross_total: {
                    without_refunds: 3.5408,
                    with_refunds: 2.9739,
                  },
                  net_total_spread: {
                    without_refunds: 1.5382,
                    with_refunds: 1.51,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7498,
                    with_refunds: 1.719,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 14.83,
                  with_refunds: 15.14,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 792.21,
                  with_refunds: 792.21,
                },
                total_users: {
                  without_refunds: 14.74,
                  with_refunds: 15.04,
                },
                total_spread_users: {
                  without_refunds: 591.09,
                  with_refunds: 591.2,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0116,
                  with_refunds: 0.0119,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9977,
                  with_refunds: 0.9977,
                },
                total_users: {
                  without_refunds: 0.0064,
                  with_refunds: 0.0065,
                },
                total_spread_users: {
                  without_refunds: 0.2874,
                  with_refunds: 0.2874,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 194.91,
                  with_refunds: 171.39,
                },
                gross_mrr: {
                  without_refunds: 222.54,
                  with_refunds: 197.83,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6889.72,
                  with_refunds: 6889.72,
                },
                gross_arr_spread: {
                  without_refunds: 7810.69,
                  with_refunds: 7810.69,
                },
                net_total: {
                  without_refunds: 184.68,
                  with_refunds: 164.49,
                },
                gross_total: {
                  without_refunds: 209.68,
                  with_refunds: 189.21,
                },
                net_total_spread: {
                  without_refunds: 4769.91,
                  with_refunds: 4751.96,
                },
                gross_total_spread: {
                  without_refunds: 5420.15,
                  with_refunds: 5401.11,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.016,
                  with_refunds: 0.0146,
                },
                gross_mrr: {
                  without_refunds: 0.0156,
                  with_refunds: 0.0144,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3947,
                  with_refunds: 1.3947,
                },
                gross_arr_spread: {
                  without_refunds: 1.3988,
                  with_refunds: 1.3988,
                },
                net_total: {
                  without_refunds: 0.0022,
                  with_refunds: 0.0022,
                },
                gross_total: {
                  without_refunds: 0.0022,
                  with_refunds: 0.0023,
                },
                net_total_spread: {
                  without_refunds: 0.278,
                  with_refunds: 0.2851,
                },
                gross_total_spread: {
                  without_refunds: 0.2729,
                  with_refunds: 0.2797,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.15,
                  with_refunds: 0.13,
                },
                gross_mrr: {
                  without_refunds: 0.17,
                  with_refunds: 0.16,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.68,
                  with_refunds: 8.68,
                },
                gross_arr_spread: {
                  without_refunds: 9.84,
                  with_refunds: 9.84,
                },
                net_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.07,
                },
                gross_total: {
                  without_refunds: 0.09,
                  with_refunds: 0.08,
                },
                net_total_spread: {
                  without_refunds: 2.32,
                  with_refunds: 2.31,
                },
                gross_total_spread: {
                  without_refunds: 2.63,
                  with_refunds: 2.63,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.14,
                  with_refunds: 11.32,
                },
                gross_mrr: {
                  without_refunds: 15.01,
                  with_refunds: 13.07,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.7,
                  with_refunds: 8.7,
                },
                gross_arr_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.86,
                },
                net_total: {
                  without_refunds: 12.53,
                  with_refunds: 10.94,
                },
                gross_total: {
                  without_refunds: 14.22,
                  with_refunds: 12.58,
                },
                net_total_spread: {
                  without_refunds: 8.07,
                  with_refunds: 8.04,
                },
                gross_total_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.14,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.37,
                    with_refunds: 38.69,
                  },
                  gross_mrr: {
                    without_refunds: 46.2,
                    with_refunds: 44.37,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 91.68,
                    with_refunds: 91.68,
                  },
                  gross_arr_spread: {
                    without_refunds: 103.95,
                    with_refunds: 103.95,
                  },
                  net_total: {
                    without_refunds: 69.55,
                    with_refunds: 56.74,
                  },
                  gross_total: {
                    without_refunds: 78.66,
                    with_refunds: 64.65,
                  },
                  net_total_spread: {
                    without_refunds: 58.16,
                    with_refunds: 57.12,
                  },
                  gross_total_spread: {
                    without_refunds: 66.2,
                    with_refunds: 65.07,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 143.99,
                    with_refunds: 132.67,
                  },
                  gross_mrr: {
                    without_refunds: 164.38,
                    with_refunds: 152.18,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 95.25,
                    with_refunds: 95.25,
                  },
                  gross_arr_spread: {
                    without_refunds: 107.98,
                    with_refunds: 107.98,
                  },
                  net_total: {
                    without_refunds: 213.13,
                    with_refunds: 178.2,
                  },
                  gross_total: {
                    without_refunds: 242.14,
                    with_refunds: 204.01,
                  },
                  net_total_spread: {
                    without_refunds: 107.09,
                    with_refunds: 105.24,
                  },
                  gross_total_spread: {
                    without_refunds: 121.8,
                    with_refunds: 119.79,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6271,
                    with_refunds: 0.6011,
                  },
                  gross_mrr: {
                    without_refunds: 0.7177,
                    with_refunds: 0.6894,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4243,
                    with_refunds: 1.4243,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6149,
                    with_refunds: 1.6149,
                  },
                  net_total: {
                    without_refunds: 1.0805,
                    with_refunds: 0.8814,
                  },
                  gross_total: {
                    without_refunds: 1.222,
                    with_refunds: 1.0043,
                  },
                  net_total_spread: {
                    without_refunds: 0.9035,
                    with_refunds: 0.8873,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0285,
                    with_refunds: 1.0109,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.237,
                    with_refunds: 2.0611,
                  },
                  gross_mrr: {
                    without_refunds: 2.5536,
                    with_refunds: 2.3641,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4797,
                    with_refunds: 1.4797,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6776,
                    with_refunds: 1.6776,
                  },
                  net_total: {
                    without_refunds: 3.3111,
                    with_refunds: 2.7683,
                  },
                  gross_total: {
                    without_refunds: 3.7617,
                    with_refunds: 3.1693,
                  },
                  net_total_spread: {
                    without_refunds: 1.6636,
                    with_refunds: 1.6349,
                  },
                  gross_total_spread: {
                    without_refunds: 1.8922,
                    with_refunds: 1.8609,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 8.99,
                  with_refunds: 9.17,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 793.86,
                  with_refunds: 793.86,
                },
                total_users: {
                  without_refunds: 8.91,
                  with_refunds: 9.08,
                },
                total_spread_users: {
                  without_refunds: 432.24,
                  with_refunds: 432.14,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0071,
                  with_refunds: 0.0072,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9998,
                  with_refunds: 0.9998,
                },
                total_users: {
                  without_refunds: 0.0038,
                  with_refunds: 0.0039,
                },
                total_spread_users: {
                  without_refunds: 0.2101,
                  with_refunds: 0.2101,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 118.8,
                  with_refunds: 105.17,
                },
                gross_mrr: {
                  without_refunds: 135.97,
                  with_refunds: 121.69,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6909.81,
                  with_refunds: 6909.81,
                },
                gross_arr_spread: {
                  without_refunds: 7828.57,
                  with_refunds: 7828.57,
                },
                net_total: {
                  without_refunds: 108.89,
                  with_refunds: 98.93,
                },
                gross_total: {
                  without_refunds: 122.99,
                  with_refunds: 113.68,
                },
                net_total_spread: {
                  without_refunds: 3288.92,
                  with_refunds: 3294.47,
                },
                gross_total_spread: {
                  without_refunds: 3845.44,
                  with_refunds: 3851.93,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0097,
                  with_refunds: 0.009,
                },
                gross_mrr: {
                  without_refunds: 0.0095,
                  with_refunds: 0.0089,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.3987,
                  with_refunds: 1.3987,
                },
                gross_arr_spread: {
                  without_refunds: 1.402,
                  with_refunds: 1.402,
                },
                net_total: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0013,
                },
                gross_total: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0014,
                },
                net_total_spread: {
                  without_refunds: 0.1917,
                  with_refunds: 0.1977,
                },
                gross_total_spread: {
                  without_refunds: 0.1936,
                  with_refunds: 0.1995,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.09,
                  with_refunds: 0.08,
                },
                gross_mrr: {
                  without_refunds: 0.11,
                  with_refunds: 0.1,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.7,
                  with_refunds: 8.7,
                },
                gross_arr_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.86,
                },
                net_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.04,
                },
                gross_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                net_total_spread: {
                  without_refunds: 1.6,
                  with_refunds: 1.6,
                },
                gross_total_spread: {
                  without_refunds: 1.87,
                  with_refunds: 1.87,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.21,
                  with_refunds: 11.46,
                },
                gross_mrr: {
                  without_refunds: 15.13,
                  with_refunds: 13.26,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.7,
                  with_refunds: 8.7,
                },
                gross_arr_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.86,
                },
                net_total: {
                  without_refunds: 12.22,
                  with_refunds: 10.89,
                },
                gross_total: {
                  without_refunds: 13.81,
                  with_refunds: 12.51,
                },
                net_total_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.62,
                },
                gross_total_spread: {
                  without_refunds: 8.9,
                  with_refunds: 8.91,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.46,
                    with_refunds: 38.78,
                  },
                  gross_mrr: {
                    without_refunds: 46.3,
                    with_refunds: 44.47,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 100.38,
                    with_refunds: 100.38,
                  },
                  gross_arr_spread: {
                    without_refunds: 113.81,
                    with_refunds: 113.81,
                  },
                  net_total: {
                    without_refunds: 69.6,
                    with_refunds: 56.78,
                  },
                  gross_total: {
                    without_refunds: 78.71,
                    with_refunds: 64.7,
                  },
                  net_total_spread: {
                    without_refunds: 59.76,
                    with_refunds: 58.72,
                  },
                  gross_total_spread: {
                    without_refunds: 68.07,
                    with_refunds: 66.94,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 157.21,
                    with_refunds: 144.14,
                  },
                  gross_mrr: {
                    without_refunds: 179.5,
                    with_refunds: 165.44,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 103.95,
                    with_refunds: 103.95,
                  },
                  gross_arr_spread: {
                    without_refunds: 117.85,
                    with_refunds: 117.85,
                  },
                  net_total: {
                    without_refunds: 225.36,
                    with_refunds: 189.09,
                  },
                  gross_total: {
                    without_refunds: 255.95,
                    with_refunds: 216.53,
                  },
                  net_total_spread: {
                    without_refunds: 114.69,
                    with_refunds: 112.86,
                  },
                  gross_total_spread: {
                    without_refunds: 130.7,
                    with_refunds: 128.7,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6285,
                    with_refunds: 0.6024,
                  },
                  gross_mrr: {
                    without_refunds: 0.7193,
                    with_refunds: 0.6908,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5595,
                    with_refunds: 1.5595,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.768,
                    with_refunds: 1.768,
                  },
                  net_total: {
                    without_refunds: 1.0813,
                    with_refunds: 0.8821,
                  },
                  gross_total: {
                    without_refunds: 1.2228,
                    with_refunds: 1.0051,
                  },
                  net_total_spread: {
                    without_refunds: 0.9283,
                    with_refunds: 0.9122,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0575,
                    with_refunds: 1.0399,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.4423,
                    with_refunds: 2.2392,
                  },
                  gross_mrr: {
                    without_refunds: 2.7886,
                    with_refunds: 2.5702,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6149,
                    with_refunds: 1.6149,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8308,
                    with_refunds: 1.8308,
                  },
                  net_total: {
                    without_refunds: 3.501,
                    with_refunds: 2.9376,
                  },
                  gross_total: {
                    without_refunds: 3.9762,
                    with_refunds: 3.3638,
                  },
                  net_total_spread: {
                    without_refunds: 1.7818,
                    with_refunds: 1.7533,
                  },
                  gross_total_spread: {
                    without_refunds: 2.0304,
                    with_refunds: 1.9994,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2.7,
                  with_refunds: 2.75,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 270.11,
                  with_refunds: 270.11,
                },
                total_users: {
                  without_refunds: 2.67,
                  with_refunds: 2.72,
                },
                total_spread_users: {
                  without_refunds: 135.21,
                  with_refunds: 135.01,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0021,
                  with_refunds: 0.0022,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.0012,
                  with_refunds: 0.0012,
                },
                total_spread_users: {
                  without_refunds: 0.0657,
                  with_refunds: 0.0656,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 35.53,
                  with_refunds: 31.51,
                },
                gross_mrr: {
                  without_refunds: 40.42,
                  with_refunds: 36.24,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2652.34,
                  with_refunds: 2652.34,
                },
                gross_arr_spread: {
                  without_refunds: 2810.06,
                  with_refunds: 2810.06,
                },
                net_total: {
                  without_refunds: 32.02,
                  with_refunds: 29.14,
                },
                gross_total: {
                  without_refunds: 35.91,
                  with_refunds: 33.24,
                },
                net_total_spread: {
                  without_refunds: 1038.79,
                  with_refunds: 1042.07,
                },
                gross_total_spread: {
                  without_refunds: 1202.63,
                  with_refunds: 1206.24,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0029,
                  with_refunds: 0.0027,
                },
                gross_mrr: {
                  without_refunds: 0.0028,
                  with_refunds: 0.0026,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.5369,
                  with_refunds: 0.5369,
                },
                gross_arr_spread: {
                  without_refunds: 0.5033,
                  with_refunds: 0.5033,
                },
                net_total: {
                  without_refunds: 0.0004,
                  with_refunds: 0.0004,
                },
                gross_total: {
                  without_refunds: 0.0004,
                  with_refunds: 0.0004,
                },
                net_total_spread: {
                  without_refunds: 0.0605,
                  with_refunds: 0.0625,
                },
                gross_total_spread: {
                  without_refunds: 0.0605,
                  with_refunds: 0.0625,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.03,
                  with_refunds: 0.02,
                },
                gross_mrr: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 3.34,
                  with_refunds: 3.34,
                },
                gross_arr_spread: {
                  without_refunds: 3.54,
                  with_refunds: 3.54,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 0.51,
                  with_refunds: 0.51,
                },
                gross_total_spread: {
                  without_refunds: 0.58,
                  with_refunds: 0.59,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.15,
                  with_refunds: 11.45,
                },
                gross_mrr: {
                  without_refunds: 14.96,
                  with_refunds: 13.17,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 9.82,
                  with_refunds: 9.82,
                },
                gross_arr_spread: {
                  without_refunds: 10.4,
                  with_refunds: 10.4,
                },
                net_total: {
                  without_refunds: 11.98,
                  with_refunds: 10.71,
                },
                gross_total: {
                  without_refunds: 13.44,
                  with_refunds: 12.22,
                },
                net_total_spread: {
                  without_refunds: 7.68,
                  with_refunds: 7.72,
                },
                gross_total_spread: {
                  without_refunds: 8.89,
                  with_refunds: 8.93,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.49,
                    with_refunds: 38.8,
                  },
                  gross_mrr: {
                    without_refunds: 46.33,
                    with_refunds: 44.5,
                  },
                  net_arr: {
                    without_refunds: 103.23,
                    with_refunds: 77.29,
                  },
                  gross_arr: {
                    without_refunds: 116.09,
                    with_refunds: 87.73,
                  },
                  net_arr_spread: {
                    without_refunds: 103.72,
                    with_refunds: 103.72,
                  },
                  gross_arr_spread: {
                    without_refunds: 117.35,
                    with_refunds: 117.35,
                  },
                  net_total: {
                    without_refunds: 69.61,
                    with_refunds: 56.79,
                  },
                  gross_total: {
                    without_refunds: 78.73,
                    with_refunds: 64.71,
                  },
                  net_total_spread: {
                    without_refunds: 60.26,
                    with_refunds: 59.22,
                  },
                  gross_total_spread: {
                    without_refunds: 68.66,
                    with_refunds: 67.53,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 170.36,
                    with_refunds: 155.59,
                  },
                  gross_mrr: {
                    without_refunds: 194.46,
                    with_refunds: 178.61,
                  },
                  net_arr: {
                    without_refunds: 551.81,
                    with_refunds: 236.39,
                  },
                  gross_arr: {
                    without_refunds: 682.3,
                    with_refunds: 299.56,
                  },
                  net_arr_spread: {
                    without_refunds: 113.77,
                    with_refunds: 113.77,
                  },
                  gross_arr_spread: {
                    without_refunds: 128.25,
                    with_refunds: 128.25,
                  },
                  net_total: {
                    without_refunds: 237.34,
                    with_refunds: 199.8,
                  },
                  gross_total: {
                    without_refunds: 269.39,
                    with_refunds: 228.75,
                  },
                  net_total_spread: {
                    without_refunds: 122.38,
                    with_refunds: 120.58,
                  },
                  gross_total_spread: {
                    without_refunds: 139.59,
                    with_refunds: 137.64,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.629,
                    with_refunds: 0.6028,
                  },
                  gross_mrr: {
                    without_refunds: 0.7198,
                    with_refunds: 0.6913,
                  },
                  net_arr: {
                    without_refunds: 1.6037,
                    with_refunds: 1.2007,
                  },
                  gross_arr: {
                    without_refunds: 1.8035,
                    with_refunds: 1.3629,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6113,
                    with_refunds: 1.6113,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.823,
                    with_refunds: 1.823,
                  },
                  net_total: {
                    without_refunds: 1.0815,
                    with_refunds: 0.8822,
                  },
                  gross_total: {
                    without_refunds: 1.2231,
                    with_refunds: 1.0053,
                  },
                  net_total_spread: {
                    without_refunds: 0.9362,
                    with_refunds: 0.9201,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0666,
                    with_refunds: 1.0491,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.6465,
                    with_refunds: 2.4171,
                  },
                  gross_mrr: {
                    without_refunds: 3.021,
                    with_refunds: 2.7747,
                  },
                  net_arr: {
                    without_refunds: 8.5725,
                    with_refunds: 3.6723,
                  },
                  gross_arr: {
                    without_refunds: 10.5997,
                    with_refunds: 4.6537,
                  },
                  net_arr_spread: {
                    without_refunds: 1.7674,
                    with_refunds: 1.7674,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9924,
                    with_refunds: 1.9924,
                  },
                  net_total: {
                    without_refunds: 3.6871,
                    with_refunds: 3.104,
                  },
                  gross_total: {
                    without_refunds: 4.185,
                    with_refunds: 3.5536,
                  },
                  net_total_spread: {
                    without_refunds: 1.9011,
                    with_refunds: 1.8733,
                  },
                  gross_total_spread: {
                    without_refunds: 2.1686,
                    with_refunds: 2.1382,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 925,
              with_refunds: 925,
            },
            returning_users: {
              without_refunds: 7238,
              with_refunds: 7263,
            },
            paying_users: {
              without_refunds: 8163,
              with_refunds: 8188,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 687,
              with_refunds: 687,
            },
            returning_users: {
              without_refunds: 656,
              with_refunds: 700,
            },
            paying_users: {
              without_refunds: 1343,
              with_refunds: 1387,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1610,
              with_refunds: 1610,
            },
            returning_users: {
              without_refunds: 7891,
              with_refunds: 7960,
            },
            paying_users: {
              without_refunds: 9501,
              with_refunds: 9570,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 50496.8,
                with_refunds: 48480.16,
              },
              gross_mrr: {
                without_refunds: 57790.79,
                with_refunds: 55589.54,
              },
              net_arr: {
                without_refunds: 109731.42,
                with_refunds: 82158.67,
              },
              gross_arr: {
                without_refunds: 123407.2,
                with_refunds: 93254.8,
              },
              net_arr_spread: {
                without_refunds: 52260.29,
                with_refunds: 52260.29,
              },
              gross_arr_spread: {
                without_refunds: 59236.33,
                with_refunds: 59236.33,
              },
              net_total: {
                without_refunds: 160228.22,
                with_refunds: 130638.83,
              },
              gross_total: {
                without_refunds: 181198,
                with_refunds: 148844.34,
              },
              net_total_spread: {
                without_refunds: 102757.09,
                with_refunds: 100740.44,
              },
              gross_total_spread: {
                without_refunds: 117027.13,
                with_refunds: 114825.88,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 51539.03,
                with_refunds: 49393.31,
              },
              gross_mrr: {
                without_refunds: 58983.28,
                with_refunds: 56646,
              },
              net_arr: {
                without_refunds: 109731.42,
                with_refunds: 82158.67,
              },
              gross_arr: {
                without_refunds: 123407.2,
                with_refunds: 93254.8,
              },
              net_arr_spread: {
                without_refunds: 82355.53,
                with_refunds: 82355.53,
              },
              gross_arr_spread: {
                without_refunds: 93173.85,
                with_refunds: 93173.85,
              },
              net_total: {
                without_refunds: 161227.49,
                with_refunds: 131526.37,
              },
              gross_total: {
                without_refunds: 182335.47,
                with_refunds: 149868.48,
              },
              net_total_spread: {
                without_refunds: 123959.12,
                with_refunds: 121825.38,
              },
              gross_total_spread: {
                without_refunds: 141228.75,
                with_refunds: 138904.68,
              },
            },
          },
        },
      },
      cac: 64.37,
      mrr_ua_spend: {
        without_refunds: 81943.01,
        with_refunds: 81943.01,
      },
      arr_ua_spend: {
        without_refunds: 68425.31,
        with_refunds: 68425.31,
      },
      arr_spread_ua_spend: {
        without_refunds: 51109.78,
        with_refunds: 51109.78,
      },
      total_ua_spend: {
        without_refunds: 149080.92,
        with_refunds: 149080.92,
      },
      total_spread_ua_spend: {
        without_refunds: 132409.09,
        with_refunds: 132409.09,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 1,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 2355,
        with_refunds: 2355,
      },
      first_period_arr_users: {
        without_refunds: 1439,
        with_refunds: 1439,
      },
      first_period_arr_spread_users: {
        without_refunds: 1253,
        with_refunds: 1253,
      },
      first_period_total_users: {
        without_refunds: 3750,
        with_refunds: 3750,
      },
      first_period_total_spread_users: {
        without_refunds: 3572,
        with_refunds: 3572,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 893,
                  with_refunds: 893,
                },
                arr_users: {
                  without_refunds: 556,
                  with_refunds: 556,
                },
                arr_spread_users: {
                  without_refunds: 493,
                  with_refunds: 493,
                },
                total_users: {
                  without_refunds: 1449,
                  with_refunds: 1449,
                },
                total_spread_users: {
                  without_refunds: 1386,
                  with_refunds: 1386,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3792,
                  with_refunds: 0.3792,
                },
                arr_users: {
                  without_refunds: 0.3864,
                  with_refunds: 0.3864,
                },
                arr_spread_users: {
                  without_refunds: 0.3935,
                  with_refunds: 0.3935,
                },
                total_users: {
                  without_refunds: 0.3864,
                  with_refunds: 0.3864,
                },
                total_spread_users: {
                  without_refunds: 0.388,
                  with_refunds: 0.388,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 11571.86,
                  with_refunds: 11258.35,
                },
                gross_mrr: {
                  without_refunds: 13292.75,
                  with_refunds: 12914.93,
                },
                net_arr: {
                  without_refunds: 57049.47,
                  with_refunds: 52855.83,
                },
                gross_arr: {
                  without_refunds: 63790.51,
                  with_refunds: 59045.8,
                },
                net_arr_spread: {
                  without_refunds: 4210.1,
                  with_refunds: 4210.1,
                },
                gross_arr_spread: {
                  without_refunds: 4695.13,
                  with_refunds: 4695.13,
                },
                net_total: {
                  without_refunds: 68621.33,
                  with_refunds: 64114.18,
                },
                gross_total: {
                  without_refunds: 77083.26,
                  with_refunds: 71960.73,
                },
                net_total_spread: {
                  without_refunds: 15781.96,
                  with_refunds: 15468.45,
                },
                gross_total_spread: {
                  without_refunds: 17987.88,
                  with_refunds: 17610.06,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.91,
                  with_refunds: 4.78,
                },
                gross_mrr: {
                  without_refunds: 5.64,
                  with_refunds: 5.48,
                },
                net_arr: {
                  without_refunds: 39.65,
                  with_refunds: 36.73,
                },
                gross_arr: {
                  without_refunds: 44.33,
                  with_refunds: 41.03,
                },
                net_arr_spread: {
                  without_refunds: 3.36,
                  with_refunds: 3.36,
                },
                gross_arr_spread: {
                  without_refunds: 3.75,
                  with_refunds: 3.75,
                },
                net_total: {
                  without_refunds: 18.3,
                  with_refunds: 17.1,
                },
                gross_total: {
                  without_refunds: 20.56,
                  with_refunds: 19.19,
                },
                net_total_spread: {
                  without_refunds: 4.42,
                  with_refunds: 4.33,
                },
                gross_total_spread: {
                  without_refunds: 5.04,
                  with_refunds: 4.93,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.96,
                  with_refunds: 12.61,
                },
                gross_mrr: {
                  without_refunds: 14.89,
                  with_refunds: 14.46,
                },
                net_arr: {
                  without_refunds: 102.61,
                  with_refunds: 95.06,
                },
                gross_arr: {
                  without_refunds: 114.73,
                  with_refunds: 106.2,
                },
                net_arr_spread: {
                  without_refunds: 8.54,
                  with_refunds: 8.54,
                },
                gross_arr_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.52,
                },
                net_total: {
                  without_refunds: 47.36,
                  with_refunds: 44.25,
                },
                gross_total: {
                  without_refunds: 53.2,
                  with_refunds: 49.66,
                },
                net_total_spread: {
                  without_refunds: 11.39,
                  with_refunds: 11.16,
                },
                gross_total_spread: {
                  without_refunds: 12.98,
                  with_refunds: 12.71,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 4.91,
                    with_refunds: 4.78,
                  },
                  gross_mrr: {
                    without_refunds: 5.64,
                    with_refunds: 5.48,
                  },
                  net_arr: {
                    without_refunds: 39.65,
                    with_refunds: 36.73,
                  },
                  gross_arr: {
                    without_refunds: 44.33,
                    with_refunds: 41.03,
                  },
                  net_arr_spread: {
                    without_refunds: 3.36,
                    with_refunds: 3.36,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.75,
                    with_refunds: 3.75,
                  },
                  net_total: {
                    without_refunds: 18.3,
                    with_refunds: 17.1,
                  },
                  gross_total: {
                    without_refunds: 20.56,
                    with_refunds: 19.19,
                  },
                  net_total_spread: {
                    without_refunds: 4.42,
                    with_refunds: 4.33,
                  },
                  gross_total_spread: {
                    without_refunds: 5.04,
                    with_refunds: 4.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 12.96,
                    with_refunds: 12.61,
                  },
                  gross_mrr: {
                    without_refunds: 14.89,
                    with_refunds: 14.46,
                  },
                  net_arr: {
                    without_refunds: 102.61,
                    with_refunds: 95.06,
                  },
                  gross_arr: {
                    without_refunds: 114.73,
                    with_refunds: 106.2,
                  },
                  net_arr_spread: {
                    without_refunds: 8.54,
                    with_refunds: 8.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.52,
                    with_refunds: 9.52,
                  },
                  net_total: {
                    without_refunds: 47.36,
                    with_refunds: 44.25,
                  },
                  gross_total: {
                    without_refunds: 53.2,
                    with_refunds: 49.66,
                  },
                  net_total_spread: {
                    without_refunds: 11.39,
                    with_refunds: 11.16,
                  },
                  gross_total_spread: {
                    without_refunds: 12.98,
                    with_refunds: 12.71,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0876,
                    with_refunds: 0.0852,
                  },
                  gross_mrr: {
                    without_refunds: 0.1007,
                    with_refunds: 0.0978,
                  },
                  net_arr: {
                    without_refunds: 0.7069,
                    with_refunds: 0.655,
                  },
                  gross_arr: {
                    without_refunds: 0.7905,
                    with_refunds: 0.7317,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0599,
                    with_refunds: 0.0599,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0668,
                    with_refunds: 0.0668,
                  },
                  net_total: {
                    without_refunds: 0.3263,
                    with_refunds: 0.3049,
                  },
                  gross_total: {
                    without_refunds: 0.3665,
                    with_refunds: 0.3422,
                  },
                  net_total_spread: {
                    without_refunds: 0.0788,
                    with_refunds: 0.0772,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0898,
                    with_refunds: 0.0879,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2311,
                    with_refunds: 0.2248,
                  },
                  gross_mrr: {
                    without_refunds: 0.2654,
                    with_refunds: 0.2579,
                  },
                  net_arr: {
                    without_refunds: 1.8297,
                    with_refunds: 1.6952,
                  },
                  gross_arr: {
                    without_refunds: 2.0458,
                    with_refunds: 1.8937,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1523,
                    with_refunds: 0.1523,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1698,
                    with_refunds: 0.1698,
                  },
                  net_total: {
                    without_refunds: 0.8445,
                    with_refunds: 0.789,
                  },
                  gross_total: {
                    without_refunds: 0.9486,
                    with_refunds: 0.8856,
                  },
                  net_total_spread: {
                    without_refunds: 0.203,
                    with_refunds: 0.199,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2314,
                    with_refunds: 0.2266,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1991,
                  with_refunds: 2003,
                },
                arr_users: {
                  without_refunds: 821,
                  with_refunds: 843,
                },
                arr_spread_users: {
                  without_refunds: 1204,
                  with_refunds: 1204,
                },
                total_users: {
                  without_refunds: 2809,
                  with_refunds: 2842,
                },
                total_spread_users: {
                  without_refunds: 3194,
                  with_refunds: 3206,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.8454,
                  with_refunds: 0.8505,
                },
                arr_users: {
                  without_refunds: 0.5705,
                  with_refunds: 0.5858,
                },
                arr_spread_users: {
                  without_refunds: 0.9609,
                  with_refunds: 0.9609,
                },
                total_users: {
                  without_refunds: 0.7491,
                  with_refunds: 0.7579,
                },
                total_spread_users: {
                  without_refunds: 0.8942,
                  with_refunds: 0.8975,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 2.2296,
                  with_refunds: 2.243,
                },
                arr_users: {
                  without_refunds: 1.4766,
                  with_refunds: 1.5162,
                },
                arr_spread_users: {
                  without_refunds: 2.4422,
                  with_refunds: 2.4422,
                },
                total_users: {
                  without_refunds: 1.9386,
                  with_refunds: 1.9614,
                },
                total_spread_users: {
                  without_refunds: 2.3045,
                  with_refunds: 2.3131,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 25952.71,
                  with_refunds: 25187.89,
                },
                gross_mrr: {
                  without_refunds: 29672.17,
                  with_refunds: 28793.59,
                },
                net_arr: {
                  without_refunds: 83443.91,
                  with_refunds: 71789.84,
                },
                gross_arr: {
                  without_refunds: 92465.1,
                  with_refunds: 79445.1,
                },
                net_arr_spread: {
                  without_refunds: 10222.34,
                  with_refunds: 10222.34,
                },
                gross_arr_spread: {
                  without_refunds: 11363.73,
                  with_refunds: 11363.73,
                },
                net_total: {
                  without_refunds: 109396.62,
                  with_refunds: 96977.73,
                },
                gross_total: {
                  without_refunds: 122137.27,
                  with_refunds: 108238.69,
                },
                net_total_spread: {
                  without_refunds: 36175.05,
                  with_refunds: 35410.23,
                },
                gross_total_spread: {
                  without_refunds: 41035.9,
                  with_refunds: 40157.32,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 2.2427,
                  with_refunds: 2.2373,
                },
                gross_mrr: {
                  without_refunds: 2.2322,
                  with_refunds: 2.2295,
                },
                net_arr: {
                  without_refunds: 1.4627,
                  with_refunds: 1.3582,
                },
                gross_arr: {
                  without_refunds: 1.4495,
                  with_refunds: 1.3455,
                },
                net_arr_spread: {
                  without_refunds: 2.428,
                  with_refunds: 2.428,
                },
                gross_arr_spread: {
                  without_refunds: 2.4203,
                  with_refunds: 2.4203,
                },
                net_total: {
                  without_refunds: 1.5942,
                  with_refunds: 1.5126,
                },
                gross_total: {
                  without_refunds: 1.5845,
                  with_refunds: 1.5041,
                },
                net_total_spread: {
                  without_refunds: 2.2922,
                  with_refunds: 2.2892,
                },
                gross_total_spread: {
                  without_refunds: 2.2813,
                  with_refunds: 2.2804,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 2.2427,
                  with_refunds: 2.2373,
                },
                gross_mrr: {
                  without_refunds: 2.2322,
                  with_refunds: 2.2295,
                },
                net_arr: {
                  without_refunds: 1.4627,
                  with_refunds: 1.3582,
                },
                gross_arr: {
                  without_refunds: 1.4495,
                  with_refunds: 1.3455,
                },
                net_arr_spread: {
                  without_refunds: 2.428,
                  with_refunds: 2.428,
                },
                gross_arr_spread: {
                  without_refunds: 2.4203,
                  with_refunds: 2.4203,
                },
                net_total: {
                  without_refunds: 1.5942,
                  with_refunds: 1.5126,
                },
                gross_total: {
                  without_refunds: 1.5845,
                  with_refunds: 1.5041,
                },
                net_total_spread: {
                  without_refunds: 2.2922,
                  with_refunds: 2.2892,
                },
                gross_total_spread: {
                  without_refunds: 2.2813,
                  with_refunds: 2.2804,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 11.02,
                  with_refunds: 10.7,
                },
                gross_mrr: {
                  without_refunds: 12.6,
                  with_refunds: 12.23,
                },
                net_arr: {
                  without_refunds: 57.99,
                  with_refunds: 49.89,
                },
                gross_arr: {
                  without_refunds: 64.26,
                  with_refunds: 55.21,
                },
                net_arr_spread: {
                  without_refunds: 8.16,
                  with_refunds: 8.16,
                },
                gross_arr_spread: {
                  without_refunds: 9.07,
                  with_refunds: 9.07,
                },
                net_total: {
                  without_refunds: 29.17,
                  with_refunds: 25.86,
                },
                gross_total: {
                  without_refunds: 32.57,
                  with_refunds: 28.86,
                },
                net_total_spread: {
                  without_refunds: 10.13,
                  with_refunds: 9.91,
                },
                gross_total_spread: {
                  without_refunds: 11.49,
                  with_refunds: 11.24,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.04,
                  with_refunds: 12.58,
                },
                gross_mrr: {
                  without_refunds: 14.9,
                  with_refunds: 14.38,
                },
                net_arr: {
                  without_refunds: 101.64,
                  with_refunds: 85.16,
                },
                gross_arr: {
                  without_refunds: 112.62,
                  with_refunds: 94.24,
                },
                net_arr_spread: {
                  without_refunds: 8.49,
                  with_refunds: 8.49,
                },
                gross_arr_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.44,
                },
                net_total: {
                  without_refunds: 38.95,
                  with_refunds: 34.12,
                },
                gross_total: {
                  without_refunds: 43.48,
                  with_refunds: 38.09,
                },
                net_total_spread: {
                  without_refunds: 11.33,
                  with_refunds: 11.04,
                },
                gross_total_spread: {
                  without_refunds: 12.85,
                  with_refunds: 12.53,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.93,
                    with_refunds: 15.48,
                  },
                  gross_mrr: {
                    without_refunds: 18.24,
                    with_refunds: 17.71,
                  },
                  net_arr: {
                    without_refunds: 97.63,
                    with_refunds: 86.62,
                  },
                  gross_arr: {
                    without_refunds: 108.59,
                    with_refunds: 96.24,
                  },
                  net_arr_spread: {
                    without_refunds: 11.52,
                    with_refunds: 11.52,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.82,
                    with_refunds: 12.82,
                  },
                  net_total: {
                    without_refunds: 47.47,
                    with_refunds: 42.96,
                  },
                  gross_total: {
                    without_refunds: 53.13,
                    with_refunds: 48.05,
                  },
                  net_total_spread: {
                    without_refunds: 14.55,
                    with_refunds: 14.24,
                  },
                  gross_total_spread: {
                    without_refunds: 16.52,
                    with_refunds: 16.17,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.99,
                    with_refunds: 25.18,
                  },
                  gross_mrr: {
                    without_refunds: 29.79,
                    with_refunds: 28.84,
                  },
                  net_arr: {
                    without_refunds: 204.24,
                    with_refunds: 180.22,
                  },
                  gross_arr: {
                    without_refunds: 227.36,
                    with_refunds: 200.44,
                  },
                  net_arr_spread: {
                    without_refunds: 17.03,
                    with_refunds: 17.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 18.96,
                    with_refunds: 18.96,
                  },
                  net_total: {
                    without_refunds: 86.3,
                    with_refunds: 78.37,
                  },
                  gross_total: {
                    without_refunds: 96.68,
                    with_refunds: 87.75,
                  },
                  net_total_spread: {
                    without_refunds: 22.71,
                    with_refunds: 22.21,
                  },
                  gross_total_spread: {
                    without_refunds: 25.83,
                    with_refunds: 25.23,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2841,
                    with_refunds: 0.276,
                  },
                  gross_mrr: {
                    without_refunds: 0.3253,
                    with_refunds: 0.3158,
                  },
                  net_arr: {
                    without_refunds: 1.741,
                    with_refunds: 1.5446,
                  },
                  gross_arr: {
                    without_refunds: 1.9363,
                    with_refunds: 1.7161,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2054,
                    with_refunds: 0.2054,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2285,
                    with_refunds: 0.2285,
                  },
                  net_total: {
                    without_refunds: 0.8465,
                    with_refunds: 0.766,
                  },
                  gross_total: {
                    without_refunds: 0.9473,
                    with_refunds: 0.8569,
                  },
                  net_total_spread: {
                    without_refunds: 0.2594,
                    with_refunds: 0.254,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2947,
                    with_refunds: 0.2884,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4635,
                    with_refunds: 0.449,
                  },
                  gross_mrr: {
                    without_refunds: 0.5312,
                    with_refunds: 0.5142,
                  },
                  net_arr: {
                    without_refunds: 3.642,
                    with_refunds: 3.2137,
                  },
                  gross_arr: {
                    without_refunds: 4.0541,
                    with_refunds: 3.5742,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3037,
                    with_refunds: 0.3037,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3381,
                    with_refunds: 0.3381,
                  },
                  net_total: {
                    without_refunds: 1.5389,
                    with_refunds: 1.3975,
                  },
                  gross_total: {
                    without_refunds: 1.7239,
                    with_refunds: 1.5647,
                  },
                  net_total_spread: {
                    without_refunds: 0.405,
                    with_refunds: 0.396,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4605,
                    with_refunds: 0.4499,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1472,
                  with_refunds: 1481,
                },
                arr_users: {
                  without_refunds: 36,
                  with_refunds: 55,
                },
                arr_spread_users: {
                  without_refunds: 1224,
                  with_refunds: 1224,
                },
                total_users: {
                  without_refunds: 1508,
                  with_refunds: 1536,
                },
                total_spread_users: {
                  without_refunds: 2696,
                  with_refunds: 2705,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6251,
                  with_refunds: 0.6289,
                },
                arr_users: {
                  without_refunds: 0.025,
                  with_refunds: 0.0382,
                },
                arr_spread_users: {
                  without_refunds: 0.9769,
                  with_refunds: 0.9769,
                },
                total_users: {
                  without_refunds: 0.4021,
                  with_refunds: 0.4096,
                },
                total_spread_users: {
                  without_refunds: 0.7548,
                  with_refunds: 0.7573,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7393,
                  with_refunds: 0.7394,
                },
                arr_users: {
                  without_refunds: 0.0438,
                  with_refunds: 0.0652,
                },
                arr_spread_users: {
                  without_refunds: 1.0166,
                  with_refunds: 1.0166,
                },
                total_users: {
                  without_refunds: 0.5368,
                  with_refunds: 0.5405,
                },
                total_spread_users: {
                  without_refunds: 0.8441,
                  with_refunds: 0.8437,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 18917.63,
                  with_refunds: 18293.27,
                },
                gross_mrr: {
                  without_refunds: 21423.54,
                  with_refunds: 20750.05,
                },
                net_arr: {
                  without_refunds: 4191.31,
                  with_refunds: 1028.41,
                },
                gross_arr: {
                  without_refunds: 5383.33,
                  with_refunds: 1695.68,
                },
                net_arr_spread: {
                  without_refunds: 10416.82,
                  with_refunds: 10416.82,
                },
                gross_arr_spread: {
                  without_refunds: 11616.35,
                  with_refunds: 11616.35,
                },
                net_total: {
                  without_refunds: 23108.94,
                  with_refunds: 19321.68,
                },
                gross_total: {
                  without_refunds: 26806.87,
                  with_refunds: 22445.73,
                },
                net_total_spread: {
                  without_refunds: 29334.46,
                  with_refunds: 28710.1,
                },
                gross_total_spread: {
                  without_refunds: 33039.89,
                  with_refunds: 32366.4,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.6348,
                  with_refunds: 1.6249,
                },
                gross_mrr: {
                  without_refunds: 1.6117,
                  with_refunds: 1.6067,
                },
                net_arr: {
                  without_refunds: 0.0735,
                  with_refunds: 0.0195,
                },
                gross_arr: {
                  without_refunds: 0.0844,
                  with_refunds: 0.0287,
                },
                net_arr_spread: {
                  without_refunds: 2.4742,
                  with_refunds: 2.4742,
                },
                gross_arr_spread: {
                  without_refunds: 2.4741,
                  with_refunds: 2.4741,
                },
                net_total: {
                  without_refunds: 0.3368,
                  with_refunds: 0.3014,
                },
                gross_total: {
                  without_refunds: 0.3478,
                  with_refunds: 0.3119,
                },
                net_total_spread: {
                  without_refunds: 1.8587,
                  with_refunds: 1.856,
                },
                gross_total_spread: {
                  without_refunds: 1.8368,
                  with_refunds: 1.8379,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7289,
                  with_refunds: 0.7263,
                },
                gross_mrr: {
                  without_refunds: 0.722,
                  with_refunds: 0.7206,
                },
                net_arr: {
                  without_refunds: 0.0502,
                  with_refunds: 0.0143,
                },
                gross_arr: {
                  without_refunds: 0.0582,
                  with_refunds: 0.0213,
                },
                net_arr_spread: {
                  without_refunds: 1.019,
                  with_refunds: 1.019,
                },
                gross_arr_spread: {
                  without_refunds: 1.0222,
                  with_refunds: 1.0222,
                },
                net_total: {
                  without_refunds: 0.2112,
                  with_refunds: 0.1992,
                },
                gross_total: {
                  without_refunds: 0.2195,
                  with_refunds: 0.2074,
                },
                net_total_spread: {
                  without_refunds: 0.8109,
                  with_refunds: 0.8108,
                },
                gross_total_spread: {
                  without_refunds: 0.8051,
                  with_refunds: 0.806,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.03,
                  with_refunds: 7.77,
                },
                gross_mrr: {
                  without_refunds: 9.1,
                  with_refunds: 8.81,
                },
                net_arr: {
                  without_refunds: 2.91,
                  with_refunds: 0.71,
                },
                gross_arr: {
                  without_refunds: 3.74,
                  with_refunds: 1.18,
                },
                net_arr_spread: {
                  without_refunds: 8.31,
                  with_refunds: 8.31,
                },
                gross_arr_spread: {
                  without_refunds: 9.27,
                  with_refunds: 9.27,
                },
                net_total: {
                  without_refunds: 6.16,
                  with_refunds: 5.15,
                },
                gross_total: {
                  without_refunds: 7.15,
                  with_refunds: 5.99,
                },
                net_total_spread: {
                  without_refunds: 8.21,
                  with_refunds: 8.04,
                },
                gross_total_spread: {
                  without_refunds: 9.25,
                  with_refunds: 9.06,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.85,
                  with_refunds: 12.35,
                },
                gross_mrr: {
                  without_refunds: 14.55,
                  with_refunds: 14.01,
                },
                net_arr: {
                  without_refunds: 116.43,
                  with_refunds: 18.7,
                },
                gross_arr: {
                  without_refunds: 149.54,
                  with_refunds: 30.83,
                },
                net_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                gross_arr_spread: {
                  without_refunds: 9.49,
                  with_refunds: 9.49,
                },
                net_total: {
                  without_refunds: 15.32,
                  with_refunds: 12.58,
                },
                gross_total: {
                  without_refunds: 17.78,
                  with_refunds: 14.61,
                },
                net_total_spread: {
                  without_refunds: 10.88,
                  with_refunds: 10.61,
                },
                gross_total_spread: {
                  without_refunds: 12.26,
                  with_refunds: 11.97,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.97,
                    with_refunds: 23.24,
                  },
                  gross_mrr: {
                    without_refunds: 27.34,
                    with_refunds: 26.52,
                  },
                  net_arr: {
                    without_refunds: 100.55,
                    with_refunds: 87.33,
                  },
                  gross_arr: {
                    without_refunds: 112.33,
                    with_refunds: 97.42,
                  },
                  net_arr_spread: {
                    without_refunds: 19.83,
                    with_refunds: 19.83,
                  },
                  gross_arr_spread: {
                    without_refunds: 22.09,
                    with_refunds: 22.09,
                  },
                  net_total: {
                    without_refunds: 53.63,
                    with_refunds: 48.11,
                  },
                  gross_total: {
                    without_refunds: 60.27,
                    with_refunds: 54.04,
                  },
                  net_total_spread: {
                    without_refunds: 22.76,
                    with_refunds: 22.28,
                  },
                  gross_total_spread: {
                    without_refunds: 25.77,
                    with_refunds: 25.23,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.85,
                    with_refunds: 37.53,
                  },
                  gross_mrr: {
                    without_refunds: 44.34,
                    with_refunds: 42.85,
                  },
                  net_arr: {
                    without_refunds: 320.67,
                    with_refunds: 198.92,
                  },
                  gross_arr: {
                    without_refunds: 376.89,
                    with_refunds: 231.27,
                  },
                  net_arr_spread: {
                    without_refunds: 25.54,
                    with_refunds: 25.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 28.45,
                    with_refunds: 28.45,
                  },
                  net_total: {
                    without_refunds: 101.63,
                    with_refunds: 90.95,
                  },
                  gross_total: {
                    without_refunds: 114.45,
                    with_refunds: 102.36,
                  },
                  net_total_spread: {
                    without_refunds: 33.59,
                    with_refunds: 32.82,
                  },
                  gross_total_spread: {
                    without_refunds: 38.08,
                    with_refunds: 37.2,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4274,
                    with_refunds: 0.4145,
                  },
                  gross_mrr: {
                    without_refunds: 0.4875,
                    with_refunds: 0.4729,
                  },
                  net_arr: {
                    without_refunds: 1.7929,
                    with_refunds: 1.5573,
                  },
                  gross_arr: {
                    without_refunds: 2.003,
                    with_refunds: 1.7372,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3536,
                    with_refunds: 0.3536,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3939,
                    with_refunds: 0.3939,
                  },
                  net_total: {
                    without_refunds: 0.9564,
                    with_refunds: 0.8579,
                  },
                  gross_total: {
                    without_refunds: 1.0748,
                    with_refunds: 0.9636,
                  },
                  net_total_spread: {
                    without_refunds: 0.4058,
                    with_refunds: 0.3973,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4596,
                    with_refunds: 0.45,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6927,
                    with_refunds: 0.6693,
                  },
                  gross_mrr: {
                    without_refunds: 0.7907,
                    with_refunds: 0.7641,
                  },
                  net_arr: {
                    without_refunds: 5.7181,
                    with_refunds: 3.5471,
                  },
                  gross_arr: {
                    without_refunds: 6.7206,
                    with_refunds: 4.1239,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4554,
                    with_refunds: 0.4554,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5074,
                    with_refunds: 0.5074,
                  },
                  net_total: {
                    without_refunds: 1.8122,
                    with_refunds: 1.6218,
                  },
                  gross_total: {
                    without_refunds: 2.0409,
                    with_refunds: 1.8253,
                  },
                  net_total_spread: {
                    without_refunds: 0.599,
                    with_refunds: 0.5852,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6791,
                    with_refunds: 0.6633,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1082,
                  with_refunds: 1086,
                },
                arr_users: {
                  without_refunds: 15,
                  with_refunds: 20,
                },
                arr_spread_users: {
                  without_refunds: 1237,
                  with_refunds: 1237,
                },
                total_users: {
                  without_refunds: 1097,
                  with_refunds: 1106,
                },
                total_spread_users: {
                  without_refunds: 2319,
                  with_refunds: 2323,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4594,
                  with_refunds: 0.4611,
                },
                arr_users: {
                  without_refunds: 0.0104,
                  with_refunds: 0.0139,
                },
                arr_spread_users: {
                  without_refunds: 0.9872,
                  with_refunds: 0.9872,
                },
                total_users: {
                  without_refunds: 0.2925,
                  with_refunds: 0.2949,
                },
                total_spread_users: {
                  without_refunds: 0.6492,
                  with_refunds: 0.6503,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7351,
                  with_refunds: 0.7333,
                },
                arr_users: {
                  without_refunds: 0.4167,
                  with_refunds: 0.3636,
                },
                arr_spread_users: {
                  without_refunds: 1.0106,
                  with_refunds: 1.0106,
                },
                total_users: {
                  without_refunds: 0.7275,
                  with_refunds: 0.7201,
                },
                total_spread_users: {
                  without_refunds: 0.8602,
                  with_refunds: 0.8588,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13674.48,
                  with_refunds: 13349.95,
                },
                gross_mrr: {
                  without_refunds: 15427.86,
                  with_refunds: 15065.79,
                },
                net_arr: {
                  without_refunds: 1751.86,
                  with_refunds: 965.54,
                },
                gross_arr: {
                  without_refunds: 2181.81,
                  with_refunds: 1219.81,
                },
                net_arr_spread: {
                  without_refunds: 10543.45,
                  with_refunds: 10543.45,
                },
                gross_arr_spread: {
                  without_refunds: 11773.16,
                  with_refunds: 11773.16,
                },
                net_total: {
                  without_refunds: 15426.34,
                  with_refunds: 14315.49,
                },
                gross_total: {
                  without_refunds: 17609.67,
                  with_refunds: 16285.61,
                },
                net_total_spread: {
                  without_refunds: 24217.93,
                  with_refunds: 23893.4,
                },
                gross_total_spread: {
                  without_refunds: 27201.03,
                  with_refunds: 26838.96,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.1817,
                  with_refunds: 1.1858,
                },
                gross_mrr: {
                  without_refunds: 1.1606,
                  with_refunds: 1.1665,
                },
                net_arr: {
                  without_refunds: 0.0307,
                  with_refunds: 0.0183,
                },
                gross_arr: {
                  without_refunds: 0.0342,
                  with_refunds: 0.0207,
                },
                net_arr_spread: {
                  without_refunds: 2.5043,
                  with_refunds: 2.5043,
                },
                gross_arr_spread: {
                  without_refunds: 2.5075,
                  with_refunds: 2.5075,
                },
                net_total: {
                  without_refunds: 0.2248,
                  with_refunds: 0.2233,
                },
                gross_total: {
                  without_refunds: 0.2285,
                  with_refunds: 0.2263,
                },
                net_total_spread: {
                  without_refunds: 1.5345,
                  with_refunds: 1.5447,
                },
                gross_total_spread: {
                  without_refunds: 1.5122,
                  with_refunds: 1.5241,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7228,
                  with_refunds: 0.7298,
                },
                gross_mrr: {
                  without_refunds: 0.7201,
                  with_refunds: 0.7261,
                },
                net_arr: {
                  without_refunds: 0.418,
                  with_refunds: 0.9389,
                },
                gross_arr: {
                  without_refunds: 0.4053,
                  with_refunds: 0.7194,
                },
                net_arr_spread: {
                  without_refunds: 1.0122,
                  with_refunds: 1.0122,
                },
                gross_arr_spread: {
                  without_refunds: 1.0135,
                  with_refunds: 1.0135,
                },
                net_total: {
                  without_refunds: 0.6675,
                  with_refunds: 0.7409,
                },
                gross_total: {
                  without_refunds: 0.6569,
                  with_refunds: 0.7256,
                },
                net_total_spread: {
                  without_refunds: 0.8256,
                  with_refunds: 0.8322,
                },
                gross_total_spread: {
                  without_refunds: 0.8233,
                  with_refunds: 0.8292,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.81,
                  with_refunds: 5.67,
                },
                gross_mrr: {
                  without_refunds: 6.55,
                  with_refunds: 6.4,
                },
                net_arr: {
                  without_refunds: 1.22,
                  with_refunds: 0.67,
                },
                gross_arr: {
                  without_refunds: 1.52,
                  with_refunds: 0.85,
                },
                net_arr_spread: {
                  without_refunds: 8.41,
                  with_refunds: 8.41,
                },
                gross_arr_spread: {
                  without_refunds: 9.4,
                  with_refunds: 9.4,
                },
                net_total: {
                  without_refunds: 4.11,
                  with_refunds: 3.82,
                },
                gross_total: {
                  without_refunds: 4.7,
                  with_refunds: 4.34,
                },
                net_total_spread: {
                  without_refunds: 6.78,
                  with_refunds: 6.69,
                },
                gross_total_spread: {
                  without_refunds: 7.62,
                  with_refunds: 7.51,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.64,
                  with_refunds: 12.29,
                },
                gross_mrr: {
                  without_refunds: 14.26,
                  with_refunds: 13.87,
                },
                net_arr: {
                  without_refunds: 116.79,
                  with_refunds: 48.28,
                },
                gross_arr: {
                  without_refunds: 145.45,
                  with_refunds: 60.99,
                },
                net_arr_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.52,
                },
                gross_arr_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.52,
                },
                net_total: {
                  without_refunds: 14.06,
                  with_refunds: 12.94,
                },
                gross_total: {
                  without_refunds: 16.05,
                  with_refunds: 14.72,
                },
                net_total_spread: {
                  without_refunds: 10.44,
                  with_refunds: 10.29,
                },
                gross_total_spread: {
                  without_refunds: 11.73,
                  with_refunds: 11.55,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 29.77,
                    with_refunds: 28.91,
                  },
                  gross_mrr: {
                    without_refunds: 33.89,
                    with_refunds: 32.92,
                  },
                  net_arr: {
                    without_refunds: 101.76,
                    with_refunds: 88.01,
                  },
                  gross_arr: {
                    without_refunds: 113.84,
                    with_refunds: 98.27,
                  },
                  net_arr_spread: {
                    without_refunds: 28.25,
                    with_refunds: 28.25,
                  },
                  gross_arr_spread: {
                    without_refunds: 31.48,
                    with_refunds: 31.48,
                  },
                  net_total: {
                    without_refunds: 57.75,
                    with_refunds: 51.93,
                  },
                  gross_total: {
                    without_refunds: 64.97,
                    with_refunds: 58.38,
                  },
                  net_total_spread: {
                    without_refunds: 29.54,
                    with_refunds: 28.97,
                  },
                  gross_total_spread: {
                    without_refunds: 33.39,
                    with_refunds: 32.75,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 51.48,
                    with_refunds: 49.83,
                  },
                  gross_mrr: {
                    without_refunds: 58.6,
                    with_refunds: 56.72,
                  },
                  net_arr: {
                    without_refunds: 437.46,
                    with_refunds: 247.2,
                  },
                  gross_arr: {
                    without_refunds: 522.35,
                    with_refunds: 292.26,
                  },
                  net_arr_spread: {
                    without_refunds: 34.06,
                    with_refunds: 34.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 37.97,
                    with_refunds: 37.97,
                  },
                  net_total: {
                    without_refunds: 115.69,
                    with_refunds: 103.89,
                  },
                  gross_total: {
                    without_refunds: 130.51,
                    with_refunds: 117.09,
                  },
                  net_total_spread: {
                    without_refunds: 44.04,
                    with_refunds: 43.1,
                  },
                  gross_total_spread: {
                    without_refunds: 49.81,
                    with_refunds: 48.75,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5309,
                    with_refunds: 0.5156,
                  },
                  gross_mrr: {
                    without_refunds: 0.6044,
                    with_refunds: 0.587,
                  },
                  net_arr: {
                    without_refunds: 1.8146,
                    with_refunds: 1.5693,
                  },
                  gross_arr: {
                    without_refunds: 2.03,
                    with_refunds: 1.7523,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5037,
                    with_refunds: 0.5037,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5614,
                    with_refunds: 0.5614,
                  },
                  net_total: {
                    without_refunds: 1.0297,
                    with_refunds: 0.926,
                  },
                  gross_total: {
                    without_refunds: 1.1585,
                    with_refunds: 1.041,
                  },
                  net_total_spread: {
                    without_refunds: 0.5267,
                    with_refunds: 0.5166,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5954,
                    with_refunds: 0.5839,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.918,
                    with_refunds: 0.8885,
                  },
                  gross_mrr: {
                    without_refunds: 1.045,
                    with_refunds: 1.0114,
                  },
                  net_arr: {
                    without_refunds: 7.8006,
                    with_refunds: 4.408,
                  },
                  gross_arr: {
                    without_refunds: 9.3143,
                    with_refunds: 5.2115,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6074,
                    with_refunds: 0.6074,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6771,
                    with_refunds: 0.6771,
                  },
                  net_total: {
                    without_refunds: 2.0629,
                    with_refunds: 1.8526,
                  },
                  gross_total: {
                    without_refunds: 2.3272,
                    with_refunds: 2.0878,
                  },
                  net_total_spread: {
                    without_refunds: 0.7852,
                    with_refunds: 0.7686,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8882,
                    with_refunds: 0.8693,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 868,
                  with_refunds: 872,
                },
                arr_users: {
                  without_refunds: 11,
                  with_refunds: 11,
                },
                arr_spread_users: {
                  without_refunds: 1248,
                  with_refunds: 1248,
                },
                total_users: {
                  without_refunds: 879,
                  with_refunds: 883,
                },
                total_spread_users: {
                  without_refunds: 2115,
                  with_refunds: 2119,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3686,
                  with_refunds: 0.3703,
                },
                arr_users: {
                  without_refunds: 0.0076,
                  with_refunds: 0.0076,
                },
                arr_spread_users: {
                  without_refunds: 0.996,
                  with_refunds: 0.996,
                },
                total_users: {
                  without_refunds: 0.2344,
                  with_refunds: 0.2355,
                },
                total_spread_users: {
                  without_refunds: 0.5921,
                  with_refunds: 0.5932,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8022,
                  with_refunds: 0.8029,
                },
                arr_users: {
                  without_refunds: 0.7333,
                  with_refunds: 0.55,
                },
                arr_spread_users: {
                  without_refunds: 1.0089,
                  with_refunds: 1.0089,
                },
                total_users: {
                  without_refunds: 0.8013,
                  with_refunds: 0.7984,
                },
                total_spread_users: {
                  without_refunds: 0.912,
                  with_refunds: 0.9122,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 11120.22,
                  with_refunds: 10917.32,
                },
                gross_mrr: {
                  without_refunds: 12549.58,
                  with_refunds: 12309.54,
                },
                net_arr: {
                  without_refunds: 1266.55,
                  with_refunds: 1266.55,
                },
                gross_arr: {
                  without_refunds: 1596.22,
                  with_refunds: 1596.22,
                },
                net_arr_spread: {
                  without_refunds: 10649,
                  with_refunds: 10649,
                },
                gross_arr_spread: {
                  without_refunds: 11906.18,
                  with_refunds: 11906.18,
                },
                net_total: {
                  without_refunds: 12386.78,
                  with_refunds: 12183.88,
                },
                gross_total: {
                  without_refunds: 14145.8,
                  with_refunds: 13905.76,
                },
                net_total_spread: {
                  without_refunds: 21769.22,
                  with_refunds: 21566.32,
                },
                gross_total_spread: {
                  without_refunds: 24455.76,
                  with_refunds: 24215.73,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.961,
                  with_refunds: 0.9697,
                },
                gross_mrr: {
                  without_refunds: 0.9441,
                  with_refunds: 0.9531,
                },
                net_arr: {
                  without_refunds: 0.0222,
                  with_refunds: 0.024,
                },
                gross_arr: {
                  without_refunds: 0.025,
                  with_refunds: 0.027,
                },
                net_arr_spread: {
                  without_refunds: 2.5294,
                  with_refunds: 2.5294,
                },
                gross_arr_spread: {
                  without_refunds: 2.5359,
                  with_refunds: 2.5359,
                },
                net_total: {
                  without_refunds: 0.1805,
                  with_refunds: 0.19,
                },
                gross_total: {
                  without_refunds: 0.1835,
                  with_refunds: 0.1932,
                },
                net_total_spread: {
                  without_refunds: 1.3794,
                  with_refunds: 1.3942,
                },
                gross_total_spread: {
                  without_refunds: 1.3596,
                  with_refunds: 1.3751,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8132,
                  with_refunds: 0.8178,
                },
                gross_mrr: {
                  without_refunds: 0.8134,
                  with_refunds: 0.8171,
                },
                net_arr: {
                  without_refunds: 0.723,
                  with_refunds: 1.3118,
                },
                gross_arr: {
                  without_refunds: 0.7316,
                  with_refunds: 1.3086,
                },
                net_arr_spread: {
                  without_refunds: 1.01,
                  with_refunds: 1.01,
                },
                gross_arr_spread: {
                  without_refunds: 1.0113,
                  with_refunds: 1.0113,
                },
                net_total: {
                  without_refunds: 0.803,
                  with_refunds: 0.8511,
                },
                gross_total: {
                  without_refunds: 0.8033,
                  with_refunds: 0.8539,
                },
                net_total_spread: {
                  without_refunds: 0.8989,
                  with_refunds: 0.9026,
                },
                gross_total_spread: {
                  without_refunds: 0.8991,
                  with_refunds: 0.9023,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.72,
                  with_refunds: 4.64,
                },
                gross_mrr: {
                  without_refunds: 5.33,
                  with_refunds: 5.23,
                },
                net_arr: {
                  without_refunds: 0.88,
                  with_refunds: 0.88,
                },
                gross_arr: {
                  without_refunds: 1.11,
                  with_refunds: 1.11,
                },
                net_arr_spread: {
                  without_refunds: 8.5,
                  with_refunds: 8.5,
                },
                gross_arr_spread: {
                  without_refunds: 9.5,
                  with_refunds: 9.5,
                },
                net_total: {
                  without_refunds: 3.3,
                  with_refunds: 3.25,
                },
                gross_total: {
                  without_refunds: 3.77,
                  with_refunds: 3.71,
                },
                net_total_spread: {
                  without_refunds: 6.09,
                  with_refunds: 6.04,
                },
                gross_total_spread: {
                  without_refunds: 6.85,
                  with_refunds: 6.78,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.81,
                  with_refunds: 12.52,
                },
                gross_mrr: {
                  without_refunds: 14.46,
                  with_refunds: 14.12,
                },
                net_arr: {
                  without_refunds: 115.14,
                  with_refunds: 115.14,
                },
                gross_arr: {
                  without_refunds: 145.11,
                  with_refunds: 145.11,
                },
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 14.09,
                  with_refunds: 13.8,
                },
                gross_total: {
                  without_refunds: 16.09,
                  with_refunds: 15.75,
                },
                net_total_spread: {
                  without_refunds: 10.29,
                  with_refunds: 10.18,
                },
                gross_total_spread: {
                  without_refunds: 11.56,
                  with_refunds: 11.43,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.5,
                    with_refunds: 33.55,
                  },
                  gross_mrr: {
                    without_refunds: 39.22,
                    with_refunds: 38.15,
                  },
                  net_arr: {
                    without_refunds: 102.64,
                    with_refunds: 88.89,
                  },
                  gross_arr: {
                    without_refunds: 114.95,
                    with_refunds: 99.38,
                  },
                  net_arr_spread: {
                    without_refunds: 36.75,
                    with_refunds: 36.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 40.99,
                    with_refunds: 40.99,
                  },
                  net_total: {
                    without_refunds: 61.05,
                    with_refunds: 55.18,
                  },
                  gross_total: {
                    without_refunds: 68.74,
                    with_refunds: 62.09,
                  },
                  net_total_spread: {
                    without_refunds: 35.63,
                    with_refunds: 35.01,
                  },
                  gross_total_spread: {
                    without_refunds: 40.24,
                    with_refunds: 39.53,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 64.29,
                    with_refunds: 62.35,
                  },
                  gross_mrr: {
                    without_refunds: 73.06,
                    with_refunds: 70.84,
                  },
                  net_arr: {
                    without_refunds: 552.6,
                    with_refunds: 362.34,
                  },
                  gross_arr: {
                    without_refunds: 667.46,
                    with_refunds: 437.37,
                  },
                  net_arr_spread: {
                    without_refunds: 42.6,
                    with_refunds: 42.6,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.51,
                    with_refunds: 47.51,
                  },
                  net_total: {
                    without_refunds: 129.78,
                    with_refunds: 117.69,
                  },
                  gross_total: {
                    without_refunds: 146.6,
                    with_refunds: 132.83,
                  },
                  net_total_spread: {
                    without_refunds: 54.33,
                    with_refunds: 53.28,
                  },
                  gross_total_spread: {
                    without_refunds: 61.37,
                    with_refunds: 60.18,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6151,
                    with_refunds: 0.5982,
                  },
                  gross_mrr: {
                    without_refunds: 0.6994,
                    with_refunds: 0.6802,
                  },
                  net_arr: {
                    without_refunds: 1.8303,
                    with_refunds: 1.585,
                  },
                  gross_arr: {
                    without_refunds: 2.0498,
                    with_refunds: 1.772,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6552,
                    with_refunds: 0.6552,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7308,
                    with_refunds: 0.7308,
                  },
                  net_total: {
                    without_refunds: 1.0886,
                    with_refunds: 0.9839,
                  },
                  gross_total: {
                    without_refunds: 1.2258,
                    with_refunds: 1.1072,
                  },
                  net_total_spread: {
                    without_refunds: 0.6354,
                    with_refunds: 0.6243,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7175,
                    with_refunds: 0.7048,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1465,
                    with_refunds: 1.1118,
                  },
                  gross_mrr: {
                    without_refunds: 1.3028,
                    with_refunds: 1.2632,
                  },
                  net_arr: {
                    without_refunds: 9.8538,
                    with_refunds: 6.4611,
                  },
                  gross_arr: {
                    without_refunds: 11.9019,
                    with_refunds: 7.799,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7596,
                    with_refunds: 0.7596,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8472,
                    with_refunds: 0.8472,
                  },
                  net_total: {
                    without_refunds: 2.3142,
                    with_refunds: 2.0986,
                  },
                  gross_total: {
                    without_refunds: 2.6141,
                    with_refunds: 2.3687,
                  },
                  net_total_spread: {
                    without_refunds: 0.9688,
                    with_refunds: 0.9501,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0944,
                    with_refunds: 1.0731,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 743,
                  with_refunds: 744,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 1249,
                  with_refunds: 1249,
                },
                total_users: {
                  without_refunds: 745,
                  with_refunds: 746,
                },
                total_spread_users: {
                  without_refunds: 1991,
                  with_refunds: 1992,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3155,
                  with_refunds: 0.3159,
                },
                arr_users: {
                  without_refunds: 0.0014,
                  with_refunds: 0.0014,
                },
                arr_spread_users: {
                  without_refunds: 0.9968,
                  with_refunds: 0.9968,
                },
                total_users: {
                  without_refunds: 0.1987,
                  with_refunds: 0.1989,
                },
                total_spread_users: {
                  without_refunds: 0.5574,
                  with_refunds: 0.5577,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.856,
                  with_refunds: 0.8532,
                },
                arr_users: {
                  without_refunds: 0.1818,
                  with_refunds: 0.1818,
                },
                arr_spread_users: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                total_users: {
                  without_refunds: 0.8476,
                  with_refunds: 0.8448,
                },
                total_spread_users: {
                  without_refunds: 0.9414,
                  with_refunds: 0.9401,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9412.25,
                  with_refunds: 9339.78,
                },
                gross_mrr: {
                  without_refunds: 10547.8,
                  with_refunds: 10461.68,
                },
                net_arr: {
                  without_refunds: 227.31,
                  with_refunds: 227.31,
                },
                gross_arr: {
                  without_refunds: 249.99,
                  with_refunds: 249.99,
                },
                net_arr_spread: {
                  without_refunds: 10658.68,
                  with_refunds: 10658.68,
                },
                gross_arr_spread: {
                  without_refunds: 11916.12,
                  with_refunds: 11916.12,
                },
                net_total: {
                  without_refunds: 9639.56,
                  with_refunds: 9567.09,
                },
                gross_total: {
                  without_refunds: 10797.79,
                  with_refunds: 10711.67,
                },
                net_total_spread: {
                  without_refunds: 20070.93,
                  with_refunds: 19998.46,
                },
                gross_total_spread: {
                  without_refunds: 22463.92,
                  with_refunds: 22377.8,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8134,
                  with_refunds: 0.8296,
                },
                gross_mrr: {
                  without_refunds: 0.7935,
                  with_refunds: 0.81,
                },
                net_arr: {
                  without_refunds: 0.004,
                  with_refunds: 0.0043,
                },
                gross_arr: {
                  without_refunds: 0.0039,
                  with_refunds: 0.0042,
                },
                net_arr_spread: {
                  without_refunds: 2.5317,
                  with_refunds: 2.5317,
                },
                gross_arr_spread: {
                  without_refunds: 2.538,
                  with_refunds: 2.538,
                },
                net_total: {
                  without_refunds: 0.1405,
                  with_refunds: 0.1492,
                },
                gross_total: {
                  without_refunds: 0.1401,
                  with_refunds: 0.1489,
                },
                net_total_spread: {
                  without_refunds: 1.2718,
                  with_refunds: 1.2929,
                },
                gross_total_spread: {
                  without_refunds: 1.2488,
                  with_refunds: 1.2707,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8464,
                  with_refunds: 0.8555,
                },
                gross_mrr: {
                  without_refunds: 0.8405,
                  with_refunds: 0.8499,
                },
                net_arr: {
                  without_refunds: 0.1795,
                  with_refunds: 0.1795,
                },
                gross_arr: {
                  without_refunds: 0.1566,
                  with_refunds: 0.1566,
                },
                net_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                gross_arr_spread: {
                  without_refunds: 1.0008,
                  with_refunds: 1.0008,
                },
                net_total: {
                  without_refunds: 0.7782,
                  with_refunds: 0.7852,
                },
                gross_total: {
                  without_refunds: 0.7633,
                  with_refunds: 0.7703,
                },
                net_total_spread: {
                  without_refunds: 0.922,
                  with_refunds: 0.9273,
                },
                gross_total_spread: {
                  without_refunds: 0.9186,
                  with_refunds: 0.9241,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4,
                  with_refunds: 3.97,
                },
                gross_mrr: {
                  without_refunds: 4.48,
                  with_refunds: 4.44,
                },
                net_arr: {
                  without_refunds: 0.16,
                  with_refunds: 0.16,
                },
                gross_arr: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                net_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                gross_arr_spread: {
                  without_refunds: 9.51,
                  with_refunds: 9.51,
                },
                net_total: {
                  without_refunds: 2.57,
                  with_refunds: 2.55,
                },
                gross_total: {
                  without_refunds: 2.88,
                  with_refunds: 2.86,
                },
                net_total_spread: {
                  without_refunds: 5.62,
                  with_refunds: 5.6,
                },
                gross_total_spread: {
                  without_refunds: 6.29,
                  with_refunds: 6.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.67,
                  with_refunds: 12.55,
                },
                gross_mrr: {
                  without_refunds: 14.2,
                  with_refunds: 14.06,
                },
                net_arr: {
                  without_refunds: 113.66,
                  with_refunds: 113.66,
                },
                gross_arr: {
                  without_refunds: 125,
                  with_refunds: 125,
                },
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 12.94,
                  with_refunds: 12.82,
                },
                gross_total: {
                  without_refunds: 14.49,
                  with_refunds: 14.36,
                },
                net_total_spread: {
                  without_refunds: 10.08,
                  with_refunds: 10.04,
                },
                gross_total_spread: {
                  without_refunds: 11.28,
                  with_refunds: 11.23,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.49,
                    with_refunds: 37.51,
                  },
                  gross_mrr: {
                    without_refunds: 43.7,
                    with_refunds: 42.59,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 45.25,
                    with_refunds: 45.25,
                  },
                  gross_arr_spread: {
                    without_refunds: 50.5,
                    with_refunds: 50.5,
                  },
                  net_total: {
                    without_refunds: 63.62,
                    with_refunds: 57.73,
                  },
                  gross_total: {
                    without_refunds: 71.62,
                    with_refunds: 64.95,
                  },
                  net_total_spread: {
                    without_refunds: 41.25,
                    with_refunds: 40.61,
                  },
                  gross_total_spread: {
                    without_refunds: 46.52,
                    with_refunds: 45.79,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 76.96,
                    with_refunds: 74.9,
                  },
                  gross_mrr: {
                    without_refunds: 87.26,
                    with_refunds: 84.9,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 51.13,
                    with_refunds: 51.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 57.05,
                    with_refunds: 57.05,
                  },
                  net_total: {
                    without_refunds: 142.72,
                    with_refunds: 130.52,
                  },
                  gross_total: {
                    without_refunds: 161.09,
                    with_refunds: 147.19,
                  },
                  net_total_spread: {
                    without_refunds: 64.41,
                    with_refunds: 63.32,
                  },
                  gross_total_spread: {
                    without_refunds: 72.66,
                    with_refunds: 71.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6864,
                    with_refunds: 0.6689,
                  },
                  gross_mrr: {
                    without_refunds: 0.7792,
                    with_refunds: 0.7594,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8069,
                    with_refunds: 0.8069,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9004,
                    with_refunds: 0.9004,
                  },
                  net_total: {
                    without_refunds: 1.1345,
                    with_refunds: 1.0294,
                  },
                  gross_total: {
                    without_refunds: 1.2771,
                    with_refunds: 1.1581,
                  },
                  net_total_spread: {
                    without_refunds: 0.7356,
                    with_refunds: 0.7241,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8296,
                    with_refunds: 0.8165,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3724,
                    with_refunds: 1.3356,
                  },
                  gross_mrr: {
                    without_refunds: 1.5559,
                    with_refunds: 1.5139,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9117,
                    with_refunds: 0.9117,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0173,
                    with_refunds: 1.0173,
                  },
                  net_total: {
                    without_refunds: 2.5449,
                    with_refunds: 2.3273,
                  },
                  gross_total: {
                    without_refunds: 2.8726,
                    with_refunds: 2.6247,
                  },
                  net_total_spread: {
                    without_refunds: 1.1485,
                    with_refunds: 1.1291,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2956,
                    with_refunds: 1.2734,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 240,
                  with_refunds: 240,
                },
                arr_users: {
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1249,
                  with_refunds: 1249,
                },
                total_users: {
                  without_refunds: 240,
                  with_refunds: 241,
                },
                total_spread_users: {
                  without_refunds: 1489,
                  with_refunds: 1489,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1019,
                  with_refunds: 0.1019,
                },
                arr_users: {
                  with_refunds: 0.0007,
                },
                arr_spread_users: {
                  without_refunds: 0.9968,
                  with_refunds: 0.9968,
                },
                total_users: {
                  without_refunds: 0.064,
                  with_refunds: 0.0643,
                },
                total_spread_users: {
                  without_refunds: 0.4169,
                  with_refunds: 0.4169,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.323,
                  with_refunds: 0.3226,
                },
                arr_users: {
                  with_refunds: 0.5,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.3221,
                  with_refunds: 0.3231,
                },
                total_spread_users: {
                  without_refunds: 0.7479,
                  with_refunds: 0.7475,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2974.31,
                  with_refunds: 2972.04,
                },
                gross_mrr: {
                  without_refunds: 3309.41,
                  with_refunds: 3306.74,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10658.68,
                  with_refunds: 10658.68,
                },
                gross_arr_spread: {
                  without_refunds: 11916.12,
                  with_refunds: 11916.12,
                },
                net_total: {
                  without_refunds: 2974.31,
                  with_refunds: 2972.04,
                },
                gross_total: {
                  without_refunds: 3309.41,
                  with_refunds: 3306.74,
                },
                net_total_spread: {
                  without_refunds: 13632.99,
                  with_refunds: 13630.72,
                },
                gross_total_spread: {
                  without_refunds: 15225.53,
                  with_refunds: 15222.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.257,
                  with_refunds: 0.264,
                },
                gross_mrr: {
                  without_refunds: 0.249,
                  with_refunds: 0.256,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.5317,
                  with_refunds: 2.5317,
                },
                gross_arr_spread: {
                  without_refunds: 2.538,
                  with_refunds: 2.538,
                },
                net_total: {
                  without_refunds: 0.0433,
                  with_refunds: 0.0464,
                },
                gross_total: {
                  without_refunds: 0.0429,
                  with_refunds: 0.046,
                },
                net_total_spread: {
                  without_refunds: 0.8638,
                  with_refunds: 0.8812,
                },
                gross_total_spread: {
                  without_refunds: 0.8464,
                  with_refunds: 0.8644,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.316,
                  with_refunds: 0.3182,
                },
                gross_mrr: {
                  without_refunds: 0.3138,
                  with_refunds: 0.3161,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 0.3086,
                  with_refunds: 0.3107,
                },
                gross_total: {
                  without_refunds: 0.3065,
                  with_refunds: 0.3087,
                },
                net_total_spread: {
                  without_refunds: 0.6792,
                  with_refunds: 0.6816,
                },
                gross_total_spread: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6803,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.26,
                  with_refunds: 1.26,
                },
                gross_mrr: {
                  without_refunds: 1.41,
                  with_refunds: 1.4,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                gross_arr_spread: {
                  without_refunds: 9.51,
                  with_refunds: 9.51,
                },
                net_total: {
                  without_refunds: 0.79,
                  with_refunds: 0.79,
                },
                gross_total: {
                  without_refunds: 0.88,
                  with_refunds: 0.88,
                },
                net_total_spread: {
                  without_refunds: 3.82,
                  with_refunds: 3.82,
                },
                gross_total_spread: {
                  without_refunds: 4.26,
                  with_refunds: 4.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.39,
                  with_refunds: 12.38,
                },
                gross_mrr: {
                  without_refunds: 13.79,
                  with_refunds: 13.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 12.39,
                  with_refunds: 12.33,
                },
                gross_total: {
                  without_refunds: 13.79,
                  with_refunds: 13.72,
                },
                net_total_spread: {
                  without_refunds: 9.16,
                  with_refunds: 9.15,
                },
                gross_total_spread: {
                  without_refunds: 10.23,
                  with_refunds: 10.22,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.76,
                    with_refunds: 38.78,
                  },
                  gross_mrr: {
                    without_refunds: 45.11,
                    with_refunds: 43.99,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 53.76,
                    with_refunds: 53.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 60.01,
                    with_refunds: 60.01,
                  },
                  net_total: {
                    without_refunds: 64.41,
                    with_refunds: 58.52,
                  },
                  gross_total: {
                    without_refunds: 72.5,
                    with_refunds: 65.83,
                  },
                  net_total_spread: {
                    without_refunds: 45.07,
                    with_refunds: 44.42,
                  },
                  gross_total_spread: {
                    without_refunds: 50.79,
                    with_refunds: 50.05,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 89.36,
                    with_refunds: 87.28,
                  },
                  gross_mrr: {
                    without_refunds: 101.04,
                    with_refunds: 98.68,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 59.66,
                    with_refunds: 59.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 66.59,
                    with_refunds: 66.59,
                  },
                  net_total: {
                    without_refunds: 155.11,
                    with_refunds: 142.85,
                  },
                  gross_total: {
                    without_refunds: 174.88,
                    with_refunds: 160.91,
                  },
                  net_total_spread: {
                    without_refunds: 73.57,
                    with_refunds: 72.48,
                  },
                  gross_total_spread: {
                    without_refunds: 82.88,
                    with_refunds: 81.64,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7089,
                    with_refunds: 0.6914,
                  },
                  gross_mrr: {
                    without_refunds: 0.8043,
                    with_refunds: 0.7845,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9586,
                    with_refunds: 0.9586,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.07,
                    with_refunds: 1.07,
                  },
                  net_total: {
                    without_refunds: 1.1486,
                    with_refunds: 1.0435,
                  },
                  gross_total: {
                    without_refunds: 1.2929,
                    with_refunds: 1.1738,
                  },
                  net_total_spread: {
                    without_refunds: 0.8036,
                    with_refunds: 0.7921,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9056,
                    with_refunds: 0.8925,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.5934,
                    with_refunds: 1.5564,
                  },
                  gross_mrr: {
                    without_refunds: 1.8018,
                    with_refunds: 1.7596,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0639,
                    with_refunds: 1.0639,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1874,
                    with_refunds: 1.1874,
                  },
                  net_total: {
                    without_refunds: 2.7659,
                    with_refunds: 2.5472,
                  },
                  gross_total: {
                    without_refunds: 3.1185,
                    with_refunds: 2.8694,
                  },
                  net_total_spread: {
                    without_refunds: 1.3118,
                    with_refunds: 1.2924,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4779,
                    with_refunds: 1.4557,
                  },
                },
              },
            },
          },
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 150.24,
                  with_refunds: 150.42,
                },
                arr_users: {
                  with_refunds: 0.12,
                },
                arr_spread_users: {
                  without_refunds: 1249.63,
                  with_refunds: 1249.63,
                },
                total_users: {
                  without_refunds: 148.69,
                  with_refunds: 149.32,
                },
                total_spread_users: {
                  without_refunds: 1333.98,
                  with_refunds: 1333.39,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0638,
                  with_refunds: 0.0639,
                },
                arr_users: {
                  with_refunds: 0.0001,
                },
                arr_spread_users: {
                  without_refunds: 0.9973,
                  with_refunds: 0.9973,
                },
                total_users: {
                  without_refunds: 0.0397,
                  with_refunds: 0.0398,
                },
                total_spread_users: {
                  without_refunds: 0.3735,
                  with_refunds: 0.3733,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.626,
                  with_refunds: 0.6268,
                },
                arr_users: {
                  with_refunds: 0.1167,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                total_spread_users: {
                  without_refunds: 0.8959,
                  with_refunds: 0.8955,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1879.34,
                  with_refunds: 1859.76,
                },
                gross_mrr: {
                  without_refunds: 2091.23,
                  with_refunds: 2072.83,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10664.24,
                  with_refunds: 10664.24,
                },
                gross_arr_spread: {
                  without_refunds: 11923.96,
                  with_refunds: 11923.96,
                },
                net_total: {
                  without_refunds: 1692.82,
                  with_refunds: 1684.15,
                },
                gross_total: {
                  without_refunds: 1873.1,
                  with_refunds: 1862.56,
                },
                net_total_spread: {
                  without_refunds: 11784.73,
                  with_refunds: 11772.7,
                },
                gross_total_spread: {
                  without_refunds: 13162.63,
                  with_refunds: 13155.51,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1624,
                  with_refunds: 0.1652,
                },
                gross_mrr: {
                  without_refunds: 0.1573,
                  with_refunds: 0.1605,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.533,
                  with_refunds: 2.533,
                },
                gross_arr_spread: {
                  without_refunds: 2.5396,
                  with_refunds: 2.5396,
                },
                net_total: {
                  without_refunds: 0.0247,
                  with_refunds: 0.0263,
                },
                gross_total: {
                  without_refunds: 0.0243,
                  with_refunds: 0.0259,
                },
                net_total_spread: {
                  without_refunds: 0.7467,
                  with_refunds: 0.7611,
                },
                gross_total_spread: {
                  without_refunds: 0.7318,
                  with_refunds: 0.747,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6258,
                },
                gross_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6268,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.5691,
                  with_refunds: 0.5667,
                },
                gross_total: {
                  without_refunds: 0.566,
                  with_refunds: 0.5633,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8637,
                },
                gross_total_spread: {
                  without_refunds: 0.8645,
                  with_refunds: 0.8642,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.8,
                  with_refunds: 0.79,
                },
                gross_mrr: {
                  without_refunds: 0.89,
                  with_refunds: 0.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.51,
                  with_refunds: 8.51,
                },
                gross_arr_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.52,
                },
                net_total: {
                  without_refunds: 0.45,
                  with_refunds: 0.45,
                },
                gross_total: {
                  without_refunds: 0.5,
                  with_refunds: 0.5,
                },
                net_total_spread: {
                  without_refunds: 3.3,
                  with_refunds: 3.3,
                },
                gross_total_spread: {
                  without_refunds: 3.68,
                  with_refunds: 3.68,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.51,
                  with_refunds: 12.36,
                },
                gross_mrr: {
                  without_refunds: 13.92,
                  with_refunds: 13.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 11.38,
                  with_refunds: 11.28,
                },
                gross_total: {
                  without_refunds: 12.6,
                  with_refunds: 12.47,
                },
                net_total_spread: {
                  without_refunds: 8.83,
                  with_refunds: 8.83,
                },
                gross_total_spread: {
                  without_refunds: 9.87,
                  with_refunds: 9.87,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 40.55,
                    with_refunds: 39.57,
                  },
                  gross_mrr: {
                    without_refunds: 45.99,
                    with_refunds: 44.87,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 62.27,
                    with_refunds: 62.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 69.52,
                    with_refunds: 69.52,
                  },
                  net_total: {
                    without_refunds: 64.87,
                    with_refunds: 58.97,
                  },
                  gross_total: {
                    without_refunds: 73,
                    with_refunds: 66.32,
                  },
                  net_total_spread: {
                    without_refunds: 48.37,
                    with_refunds: 47.72,
                  },
                  gross_total_spread: {
                    without_refunds: 54.47,
                    with_refunds: 53.74,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 101.86,
                    with_refunds: 99.65,
                  },
                  gross_mrr: {
                    without_refunds: 114.96,
                    with_refunds: 112.46,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 68.2,
                    with_refunds: 68.2,
                  },
                  gross_arr_spread: {
                    without_refunds: 76.13,
                    with_refunds: 76.13,
                  },
                  net_total: {
                    without_refunds: 166.5,
                    with_refunds: 154.13,
                  },
                  gross_total: {
                    without_refunds: 187.48,
                    with_refunds: 173.39,
                  },
                  net_total_spread: {
                    without_refunds: 82.4,
                    with_refunds: 81.31,
                  },
                  gross_total_spread: {
                    without_refunds: 92.75,
                    with_refunds: 91.5,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7231,
                    with_refunds: 0.7055,
                  },
                  gross_mrr: {
                    without_refunds: 0.8201,
                    with_refunds: 0.8002,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1104,
                    with_refunds: 1.1104,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2397,
                    with_refunds: 1.2397,
                  },
                  net_total: {
                    without_refunds: 1.1567,
                    with_refunds: 1.0515,
                  },
                  gross_total: {
                    without_refunds: 1.3018,
                    with_refunds: 1.1827,
                  },
                  net_total_spread: {
                    without_refunds: 0.8625,
                    with_refunds: 0.8509,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9713,
                    with_refunds: 0.9582,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.8164,
                    with_refunds: 1.7769,
                  },
                  gross_mrr: {
                    without_refunds: 2.05,
                    with_refunds: 2.0053,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2161,
                    with_refunds: 1.2161,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3576,
                    with_refunds: 1.3576,
                  },
                  net_total: {
                    without_refunds: 2.9689,
                    with_refunds: 2.7483,
                  },
                  gross_total: {
                    without_refunds: 3.3431,
                    with_refunds: 3.0918,
                  },
                  net_total_spread: {
                    without_refunds: 1.4693,
                    with_refunds: 1.4498,
                  },
                  gross_total_spread: {
                    without_refunds: 1.6539,
                    with_refunds: 1.6316,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 99.07,
                  with_refunds: 99.27,
                },
                arr_users: {
                  with_refunds: 0.03,
                },
                arr_spread_users: {
                  without_refunds: 1252.11,
                  with_refunds: 1252.11,
                },
                total_users: {
                  without_refunds: 97.92,
                  with_refunds: 98.34,
                },
                total_spread_users: {
                  without_refunds: 1209.71,
                  with_refunds: 1209.23,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0421,
                  with_refunds: 0.0422,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9993,
                  with_refunds: 0.9993,
                },
                total_users: {
                  without_refunds: 0.0261,
                  with_refunds: 0.0262,
                },
                total_spread_users: {
                  without_refunds: 0.3387,
                  with_refunds: 0.3385,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {
                  with_refunds: 0.2778,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1248,
                  with_refunds: 1232.64,
                },
                gross_mrr: {
                  without_refunds: 1387.54,
                  with_refunds: 1372.78,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10690.71,
                  with_refunds: 10690.71,
                },
                gross_arr_spread: {
                  without_refunds: 11956.43,
                  with_refunds: 11956.43,
                },
                net_total: {
                  without_refunds: 1113.71,
                  with_refunds: 1118.06,
                },
                gross_total: {
                  without_refunds: 1228.99,
                  with_refunds: 1235.21,
                },
                net_total_spread: {
                  without_refunds: 10346.55,
                  with_refunds: 10338.38,
                },
                gross_total_spread: {
                  without_refunds: 11542.46,
                  with_refunds: 11538.93,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1078,
                  with_refunds: 0.1095,
                },
                gross_mrr: {
                  without_refunds: 0.1044,
                  with_refunds: 0.1063,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.5393,
                  with_refunds: 2.5393,
                },
                gross_arr_spread: {
                  without_refunds: 2.5466,
                  with_refunds: 2.5466,
                },
                net_total: {
                  without_refunds: 0.0162,
                  with_refunds: 0.0174,
                },
                gross_total: {
                  without_refunds: 0.0159,
                  with_refunds: 0.0172,
                },
                net_total_spread: {
                  without_refunds: 0.6556,
                  with_refunds: 0.6684,
                },
                gross_total_spread: {
                  without_refunds: 0.6417,
                  with_refunds: 0.6552,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.53,
                  with_refunds: 0.52,
                },
                gross_mrr: {
                  without_refunds: 0.59,
                  with_refunds: 0.58,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                net_total: {
                  without_refunds: 0.3,
                  with_refunds: 0.3,
                },
                gross_total: {
                  without_refunds: 0.33,
                  with_refunds: 0.33,
                },
                net_total_spread: {
                  without_refunds: 2.9,
                  with_refunds: 2.89,
                },
                gross_total_spread: {
                  without_refunds: 3.23,
                  with_refunds: 3.23,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.6,
                  with_refunds: 12.42,
                },
                gross_mrr: {
                  without_refunds: 14.01,
                  with_refunds: 13.83,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.54,
                  with_refunds: 8.54,
                },
                gross_arr_spread: {
                  without_refunds: 9.55,
                  with_refunds: 9.55,
                },
                net_total: {
                  without_refunds: 11.37,
                  with_refunds: 11.37,
                },
                gross_total: {
                  without_refunds: 12.55,
                  with_refunds: 12.56,
                },
                net_total_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_total_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 41.08,
                    with_refunds: 40.09,
                  },
                  gross_mrr: {
                    without_refunds: 46.58,
                    with_refunds: 45.46,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 70.8,
                    with_refunds: 70.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 79.06,
                    with_refunds: 79.06,
                  },
                  net_total: {
                    without_refunds: 65.16,
                    with_refunds: 59.27,
                  },
                  gross_total: {
                    without_refunds: 73.33,
                    with_refunds: 66.65,
                  },
                  net_total_spread: {
                    without_refunds: 51.26,
                    with_refunds: 50.61,
                  },
                  gross_total_spread: {
                    without_refunds: 57.7,
                    with_refunds: 56.97,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 114.46,
                    with_refunds: 112.07,
                  },
                  gross_mrr: {
                    without_refunds: 128.97,
                    with_refunds: 126.29,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 76.74,
                    with_refunds: 76.74,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.68,
                    with_refunds: 85.68,
                  },
                  net_total: {
                    without_refunds: 177.87,
                    with_refunds: 165.5,
                  },
                  gross_total: {
                    without_refunds: 200.03,
                    with_refunds: 185.95,
                  },
                  net_total_spread: {
                    without_refunds: 90.95,
                    with_refunds: 89.85,
                  },
                  gross_total_spread: {
                    without_refunds: 102.29,
                    with_refunds: 101.04,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7326,
                    with_refunds: 0.7149,
                  },
                  gross_mrr: {
                    without_refunds: 0.8306,
                    with_refunds: 0.8105,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2625,
                    with_refunds: 1.2625,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4098,
                    with_refunds: 1.4098,
                  },
                  net_total: {
                    without_refunds: 1.162,
                    with_refunds: 1.0568,
                  },
                  gross_total: {
                    without_refunds: 1.3076,
                    with_refunds: 1.1886,
                  },
                  net_total_spread: {
                    without_refunds: 0.9141,
                    with_refunds: 0.9025,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0289,
                    with_refunds: 1.0158,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.041,
                    with_refunds: 1.9983,
                  },
                  gross_mrr: {
                    without_refunds: 2.2997,
                    with_refunds: 2.2519,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3683,
                    with_refunds: 1.3683,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5279,
                    with_refunds: 1.5279,
                  },
                  net_total: {
                    without_refunds: 3.1717,
                    with_refunds: 2.9511,
                  },
                  gross_total: {
                    without_refunds: 3.5669,
                    with_refunds: 3.3158,
                  },
                  net_total_spread: {
                    without_refunds: 1.6218,
                    with_refunds: 1.6023,
                  },
                  gross_total_spread: {
                    without_refunds: 1.824,
                    with_refunds: 1.8018,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 65.71,
                  with_refunds: 65.89,
                },
                arr_users: {
                  with_refunds: 0.01,
                },
                arr_spread_users: {
                  without_refunds: 1255.66,
                  with_refunds: 1255.66,
                },
                total_users: {
                  without_refunds: 64.63,
                  with_refunds: 64.96,
                },
                total_spread_users: {
                  without_refunds: 1074.76,
                  with_refunds: 1074.26,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0279,
                  with_refunds: 0.028,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.0172,
                  with_refunds: 0.0173,
                },
                total_spread_users: {
                  without_refunds: 0.3009,
                  with_refunds: 0.3007,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {
                  with_refunds: 0.2222,
                },
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 827.23,
                  with_refunds: 820.37,
                },
                gross_mrr: {
                  without_refunds: 918.03,
                  with_refunds: 911.69,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10735.27,
                  with_refunds: 10735.27,
                },
                gross_arr_spread: {
                  without_refunds: 12001.53,
                  with_refunds: 12001.53,
                },
                net_total: {
                  without_refunds: 703.46,
                  with_refunds: 710.03,
                },
                gross_total: {
                  without_refunds: 771.93,
                  with_refunds: 780.34,
                },
                net_total_spread: {
                  without_refunds: 8882.75,
                  with_refunds: 8906.04,
                },
                gross_total_spread: {
                  without_refunds: 9905.23,
                  with_refunds: 9933.39,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0715,
                  with_refunds: 0.0729,
                },
                gross_mrr: {
                  without_refunds: 0.0691,
                  with_refunds: 0.0706,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.5499,
                  with_refunds: 2.5499,
                },
                gross_arr_spread: {
                  without_refunds: 2.5562,
                  with_refunds: 2.5562,
                },
                net_total: {
                  without_refunds: 0.0103,
                  with_refunds: 0.0111,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.0108,
                },
                net_total_spread: {
                  without_refunds: 0.5628,
                  with_refunds: 0.5758,
                },
                gross_total_spread: {
                  without_refunds: 0.5507,
                  with_refunds: 0.5641,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.35,
                  with_refunds: 0.35,
                },
                gross_mrr: {
                  without_refunds: 0.39,
                  with_refunds: 0.39,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.57,
                  with_refunds: 8.57,
                },
                gross_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                net_total: {
                  without_refunds: 0.19,
                  with_refunds: 0.19,
                },
                gross_total: {
                  without_refunds: 0.21,
                  with_refunds: 0.21,
                },
                net_total_spread: {
                  without_refunds: 2.49,
                  with_refunds: 2.49,
                },
                gross_total_spread: {
                  without_refunds: 2.77,
                  with_refunds: 2.78,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.59,
                  with_refunds: 12.45,
                },
                gross_mrr: {
                  without_refunds: 13.97,
                  with_refunds: 13.84,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.55,
                  with_refunds: 8.55,
                },
                gross_arr_spread: {
                  without_refunds: 9.56,
                  with_refunds: 9.56,
                },
                net_total: {
                  without_refunds: 10.88,
                  with_refunds: 10.93,
                },
                gross_total: {
                  without_refunds: 11.94,
                  with_refunds: 12.01,
                },
                net_total_spread: {
                  without_refunds: 8.26,
                  with_refunds: 8.29,
                },
                gross_total_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.25,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 41.43,
                    with_refunds: 40.44,
                  },
                  gross_mrr: {
                    without_refunds: 46.97,
                    with_refunds: 45.84,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 79.37,
                    with_refunds: 79.37,
                  },
                  gross_arr_spread: {
                    without_refunds: 88.64,
                    with_refunds: 88.64,
                  },
                  net_total: {
                    without_refunds: 65.35,
                    with_refunds: 59.46,
                  },
                  gross_total: {
                    without_refunds: 73.54,
                    with_refunds: 66.86,
                  },
                  net_total_spread: {
                    without_refunds: 53.75,
                    with_refunds: 53.11,
                  },
                  gross_total_spread: {
                    without_refunds: 60.48,
                    with_refunds: 59.75,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 127.05,
                    with_refunds: 124.52,
                  },
                  gross_mrr: {
                    without_refunds: 142.94,
                    with_refunds: 140.12,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 85.29,
                    with_refunds: 85.29,
                  },
                  gross_arr_spread: {
                    without_refunds: 95.24,
                    with_refunds: 95.24,
                  },
                  net_total: {
                    without_refunds: 188.76,
                    with_refunds: 176.43,
                  },
                  gross_total: {
                    without_refunds: 211.97,
                    with_refunds: 197.96,
                  },
                  net_total_spread: {
                    without_refunds: 99.22,
                    with_refunds: 98.15,
                  },
                  gross_total_spread: {
                    without_refunds: 111.51,
                    with_refunds: 110.29,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7388,
                    with_refunds: 0.7211,
                  },
                  gross_mrr: {
                    without_refunds: 0.8376,
                    with_refunds: 0.8175,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4153,
                    with_refunds: 1.4153,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5806,
                    with_refunds: 1.5806,
                  },
                  net_total: {
                    without_refunds: 1.1653,
                    with_refunds: 1.0602,
                  },
                  gross_total: {
                    without_refunds: 1.3113,
                    with_refunds: 1.1923,
                  },
                  net_total_spread: {
                    without_refunds: 0.9585,
                    with_refunds: 0.947,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0784,
                    with_refunds: 1.0654,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.2655,
                    with_refunds: 2.2203,
                  },
                  gross_mrr: {
                    without_refunds: 2.5489,
                    with_refunds: 2.4986,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5208,
                    with_refunds: 1.5208,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6983,
                    with_refunds: 1.6983,
                  },
                  net_total: {
                    without_refunds: 3.3658,
                    with_refunds: 3.146,
                  },
                  gross_total: {
                    without_refunds: 3.7799,
                    with_refunds: 3.53,
                  },
                  net_total_spread: {
                    without_refunds: 1.7692,
                    with_refunds: 1.7501,
                  },
                  gross_total_spread: {
                    without_refunds: 1.9884,
                    with_refunds: 1.9667,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 46.42,
                  with_refunds: 46.48,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1262.71,
                  with_refunds: 1262.71,
                },
                total_users: {
                  without_refunds: 45.68,
                  with_refunds: 45.83,
                },
                total_spread_users: {
                  without_refunds: 947.72,
                  with_refunds: 946.33,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0197,
                  with_refunds: 0.0197,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0077,
                  with_refunds: 1.0077,
                },
                total_users: {
                  without_refunds: 0.0122,
                  with_refunds: 0.0122,
                },
                total_spread_users: {
                  without_refunds: 0.2653,
                  with_refunds: 0.2649,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 583.66,
                  with_refunds: 581.51,
                },
                gross_mrr: {
                  without_refunds: 646.56,
                  with_refunds: 644.98,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10819.83,
                  with_refunds: 10819.83,
                },
                gross_arr_spread: {
                  without_refunds: 12081.99,
                  with_refunds: 12081.99,
                },
                net_total: {
                  without_refunds: 498.14,
                  with_refunds: 505.4,
                },
                gross_total: {
                  without_refunds: 545.66,
                  with_refunds: 554.31,
                },
                net_total_spread: {
                  without_refunds: 7577.57,
                  with_refunds: 7623,
                },
                gross_total_spread: {
                  without_refunds: 8464.73,
                  with_refunds: 8515.3,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0504,
                  with_refunds: 0.0517,
                },
                gross_mrr: {
                  without_refunds: 0.0486,
                  with_refunds: 0.0499,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.57,
                  with_refunds: 2.57,
                },
                gross_arr_spread: {
                  without_refunds: 2.5733,
                  with_refunds: 2.5733,
                },
                net_total: {
                  without_refunds: 0.0073,
                  with_refunds: 0.0079,
                },
                gross_total: {
                  without_refunds: 0.0071,
                  with_refunds: 0.0077,
                },
                net_total_spread: {
                  without_refunds: 0.4801,
                  with_refunds: 0.4928,
                },
                gross_total_spread: {
                  without_refunds: 0.4706,
                  with_refunds: 0.4835,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.25,
                  with_refunds: 0.25,
                },
                gross_mrr: {
                  without_refunds: 0.27,
                  with_refunds: 0.27,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.64,
                  with_refunds: 8.64,
                },
                gross_arr_spread: {
                  without_refunds: 9.64,
                  with_refunds: 9.64,
                },
                net_total: {
                  without_refunds: 0.13,
                  with_refunds: 0.13,
                },
                gross_total: {
                  without_refunds: 0.15,
                  with_refunds: 0.15,
                },
                net_total_spread: {
                  without_refunds: 2.12,
                  with_refunds: 2.13,
                },
                gross_total_spread: {
                  without_refunds: 2.37,
                  with_refunds: 2.38,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.57,
                  with_refunds: 12.51,
                },
                gross_mrr: {
                  without_refunds: 13.93,
                  with_refunds: 13.88,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.57,
                  with_refunds: 8.57,
                },
                gross_arr_spread: {
                  without_refunds: 9.57,
                  with_refunds: 9.57,
                },
                net_total: {
                  without_refunds: 10.91,
                  with_refunds: 11.03,
                },
                gross_total: {
                  without_refunds: 11.95,
                  with_refunds: 12.09,
                },
                net_total_spread: {
                  without_refunds: 8,
                  with_refunds: 8.06,
                },
                gross_total_spread: {
                  without_refunds: 8.93,
                  with_refunds: 9,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 41.68,
                    with_refunds: 40.68,
                  },
                  gross_mrr: {
                    without_refunds: 47.25,
                    with_refunds: 46.12,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 88,
                    with_refunds: 88,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.28,
                    with_refunds: 98.28,
                  },
                  net_total: {
                    without_refunds: 65.48,
                    with_refunds: 59.59,
                  },
                  gross_total: {
                    without_refunds: 73.68,
                    with_refunds: 67.01,
                  },
                  net_total_spread: {
                    without_refunds: 55.87,
                    with_refunds: 55.24,
                  },
                  gross_total_spread: {
                    without_refunds: 62.85,
                    with_refunds: 62.13,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 139.62,
                    with_refunds: 137.03,
                  },
                  gross_mrr: {
                    without_refunds: 156.87,
                    with_refunds: 154,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 93.85,
                    with_refunds: 93.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 104.81,
                    with_refunds: 104.81,
                  },
                  net_total: {
                    without_refunds: 199.66,
                    with_refunds: 187.45,
                  },
                  gross_total: {
                    without_refunds: 223.92,
                    with_refunds: 210.06,
                  },
                  net_total_spread: {
                    without_refunds: 107.21,
                    with_refunds: 106.2,
                  },
                  gross_total_spread: {
                    without_refunds: 120.44,
                    with_refunds: 119.29,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7433,
                    with_refunds: 0.7255,
                  },
                  gross_mrr: {
                    without_refunds: 0.8425,
                    with_refunds: 0.8223,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5693,
                    with_refunds: 1.5693,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.7526,
                    with_refunds: 1.7526,
                  },
                  net_total: {
                    without_refunds: 1.1677,
                    with_refunds: 1.0626,
                  },
                  gross_total: {
                    without_refunds: 1.3139,
                    with_refunds: 1.1949,
                  },
                  net_total_spread: {
                    without_refunds: 0.9963,
                    with_refunds: 0.985,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1206,
                    with_refunds: 1.1079,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.4897,
                    with_refunds: 2.4434,
                  },
                  gross_mrr: {
                    without_refunds: 2.7972,
                    with_refunds: 2.7461,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6736,
                    with_refunds: 1.6736,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8689,
                    with_refunds: 1.8689,
                  },
                  net_total: {
                    without_refunds: 3.5603,
                    with_refunds: 3.3426,
                  },
                  gross_total: {
                    without_refunds: 3.9929,
                    with_refunds: 3.7456,
                  },
                  net_total_spread: {
                    without_refunds: 1.9118,
                    with_refunds: 1.8937,
                  },
                  gross_total_spread: {
                    without_refunds: 2.1476,
                    with_refunds: 2.1271,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 28.14,
                  with_refunds: 28.16,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1265.34,
                  with_refunds: 1265.34,
                },
                total_users: {
                  without_refunds: 27.59,
                  with_refunds: 27.68,
                },
                total_spread_users: {
                  without_refunds: 693.03,
                  with_refunds: 691.72,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0119,
                  with_refunds: 0.012,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0098,
                  with_refunds: 1.0098,
                },
                total_users: {
                  without_refunds: 0.0074,
                  with_refunds: 0.0074,
                },
                total_spread_users: {
                  without_refunds: 0.194,
                  with_refunds: 0.1937,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 355.75,
                  with_refunds: 356.83,
                },
                gross_mrr: {
                  without_refunds: 395.05,
                  with_refunds: 396.74,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10851.39,
                  with_refunds: 10851.39,
                },
                gross_arr_spread: {
                  without_refunds: 12109.65,
                  with_refunds: 12109.65,
                },
                net_total: {
                  without_refunds: 293.72,
                  with_refunds: 303.97,
                },
                gross_total: {
                  without_refunds: 320.06,
                  with_refunds: 333.02,
                },
                net_total_spread: {
                  without_refunds: 5224.84,
                  with_refunds: 5284.92,
                },
                gross_total_spread: {
                  without_refunds: 6005.49,
                  with_refunds: 6072.89,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0307,
                  with_refunds: 0.0317,
                },
                gross_mrr: {
                  without_refunds: 0.0297,
                  with_refunds: 0.0307,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.5775,
                  with_refunds: 2.5775,
                },
                gross_arr_spread: {
                  without_refunds: 2.5792,
                  with_refunds: 2.5792,
                },
                net_total: {
                  without_refunds: 0.0043,
                  with_refunds: 0.0047,
                },
                gross_total: {
                  without_refunds: 0.0042,
                  with_refunds: 0.0046,
                },
                net_total_spread: {
                  without_refunds: 0.3311,
                  with_refunds: 0.3417,
                },
                gross_total_spread: {
                  without_refunds: 0.3339,
                  with_refunds: 0.3449,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.15,
                  with_refunds: 0.15,
                },
                gross_mrr: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.66,
                },
                gross_arr_spread: {
                  without_refunds: 9.66,
                  with_refunds: 9.66,
                },
                net_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.08,
                },
                gross_total: {
                  without_refunds: 0.09,
                  with_refunds: 0.09,
                },
                net_total_spread: {
                  without_refunds: 1.46,
                  with_refunds: 1.48,
                },
                gross_total_spread: {
                  without_refunds: 1.68,
                  with_refunds: 1.7,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.64,
                  with_refunds: 12.67,
                },
                gross_mrr: {
                  without_refunds: 14.04,
                  with_refunds: 14.09,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8.58,
                  with_refunds: 8.58,
                },
                gross_arr_spread: {
                  without_refunds: 9.57,
                  with_refunds: 9.57,
                },
                net_total: {
                  without_refunds: 10.64,
                  with_refunds: 10.98,
                },
                gross_total: {
                  without_refunds: 11.6,
                  with_refunds: 12.03,
                },
                net_total_spread: {
                  without_refunds: 7.54,
                  with_refunds: 7.64,
                },
                gross_total_spread: {
                  without_refunds: 8.67,
                  with_refunds: 8.78,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 41.83,
                    with_refunds: 40.84,
                  },
                  gross_mrr: {
                    without_refunds: 47.41,
                    with_refunds: 46.29,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 96.66,
                    with_refunds: 96.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 107.95,
                    with_refunds: 107.95,
                  },
                  net_total: {
                    without_refunds: 65.56,
                    with_refunds: 59.67,
                  },
                  gross_total: {
                    without_refunds: 73.77,
                    with_refunds: 67.1,
                  },
                  net_total_spread: {
                    without_refunds: 57.33,
                    with_refunds: 56.72,
                  },
                  gross_total_spread: {
                    without_refunds: 64.53,
                    with_refunds: 63.83,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 152.27,
                    with_refunds: 149.7,
                  },
                  gross_mrr: {
                    without_refunds: 170.91,
                    with_refunds: 168.09,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 102.43,
                    with_refunds: 102.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 114.38,
                    with_refunds: 114.38,
                  },
                  net_total: {
                    without_refunds: 210.31,
                    with_refunds: 198.44,
                  },
                  gross_total: {
                    without_refunds: 235.52,
                    with_refunds: 222.09,
                  },
                  net_total_spread: {
                    without_refunds: 114.75,
                    with_refunds: 113.84,
                  },
                  gross_total_spread: {
                    without_refunds: 129.1,
                    with_refunds: 128.07,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.746,
                    with_refunds: 0.7282,
                  },
                  gross_mrr: {
                    without_refunds: 0.8455,
                    with_refunds: 0.8253,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 1.7237,
                    with_refunds: 1.7237,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9249,
                    with_refunds: 1.9249,
                  },
                  net_total: {
                    without_refunds: 1.1691,
                    with_refunds: 1.0641,
                  },
                  gross_total: {
                    without_refunds: 1.3154,
                    with_refunds: 1.1965,
                  },
                  net_total_spread: {
                    without_refunds: 1.0224,
                    with_refunds: 1.0114,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1506,
                    with_refunds: 1.1382,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.7152,
                    with_refunds: 2.6694,
                  },
                  gross_mrr: {
                    without_refunds: 3.0476,
                    with_refunds: 2.9973,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.8265,
                    with_refunds: 1.8265,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.0396,
                    with_refunds: 2.0396,
                  },
                  net_total: {
                    without_refunds: 3.7501,
                    with_refunds: 3.5384,
                  },
                  gross_total: {
                    without_refunds: 4.1997,
                    with_refunds: 3.9602,
                  },
                  net_total_spread: {
                    without_refunds: 2.0462,
                    with_refunds: 2.03,
                  },
                  gross_total_spread: {
                    without_refunds: 2.3021,
                    with_refunds: 2.2837,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 8.46,
                  with_refunds: 8.45,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 430.53,
                  with_refunds: 430.53,
                },
                total_users: {
                  without_refunds: 8.28,
                  with_refunds: 8.29,
                },
                total_spread_users: {
                  without_refunds: 216.79,
                  with_refunds: 216.11,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0036,
                  with_refunds: 0.0036,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3436,
                  with_refunds: 0.3436,
                },
                total_users: {
                  without_refunds: 0.0022,
                  with_refunds: 0.0022,
                },
                total_spread_users: {
                  without_refunds: 0.0607,
                  with_refunds: 0.0605,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 106.39,
                  with_refunds: 106.91,
                },
                gross_mrr: {
                  without_refunds: 117.44,
                  with_refunds: 118.15,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 4165.32,
                  with_refunds: 4165.32,
                },
                gross_arr_spread: {
                  without_refunds: 4346.75,
                  with_refunds: 4346.75,
                },
                net_total: {
                  without_refunds: 86.36,
                  with_refunds: 89.53,
                },
                gross_total: {
                  without_refunds: 93.44,
                  with_refunds: 97.39,
                },
                net_total_spread: {
                  without_refunds: 1650.24,
                  with_refunds: 1671.67,
                },
                gross_total_spread: {
                  without_refunds: 1878.16,
                  with_refunds: 1901.74,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0092,
                  with_refunds: 0.0095,
                },
                gross_mrr: {
                  without_refunds: 0.0088,
                  with_refunds: 0.0091,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.9894,
                  with_refunds: 0.9894,
                },
                gross_arr_spread: {
                  without_refunds: 0.9258,
                  with_refunds: 0.9258,
                },
                net_total: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0014,
                },
                gross_total: {
                  without_refunds: 0.0012,
                  with_refunds: 0.0014,
                },
                net_total_spread: {
                  without_refunds: 0.1046,
                  with_refunds: 0.1081,
                },
                gross_total_spread: {
                  without_refunds: 0.1044,
                  with_refunds: 0.108,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                gross_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 3.32,
                  with_refunds: 3.32,
                },
                gross_arr_spread: {
                  without_refunds: 3.47,
                  with_refunds: 3.47,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.03,
                },
                net_total_spread: {
                  without_refunds: 0.46,
                  with_refunds: 0.47,
                },
                gross_total_spread: {
                  without_refunds: 0.53,
                  with_refunds: 0.53,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.58,
                  with_refunds: 12.65,
                },
                gross_mrr: {
                  without_refunds: 13.89,
                  with_refunds: 13.98,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 9.67,
                  with_refunds: 9.67,
                },
                gross_arr_spread: {
                  without_refunds: 10.1,
                  with_refunds: 10.1,
                },
                net_total: {
                  without_refunds: 10.43,
                  with_refunds: 10.8,
                },
                gross_total: {
                  without_refunds: 11.29,
                  with_refunds: 11.75,
                },
                net_total_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.74,
                },
                gross_total_spread: {
                  without_refunds: 8.66,
                  with_refunds: 8.8,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 41.88,
                    with_refunds: 40.88,
                  },
                  gross_mrr: {
                    without_refunds: 47.46,
                    with_refunds: 46.34,
                  },
                  net_arr: {
                    without_refunds: 102.8,
                    with_refunds: 89.04,
                  },
                  gross_arr: {
                    without_refunds: 115.13,
                    with_refunds: 99.55,
                  },
                  net_arr_spread: {
                    without_refunds: 99.99,
                    with_refunds: 99.99,
                  },
                  gross_arr_spread: {
                    without_refunds: 111.42,
                    with_refunds: 111.42,
                  },
                  net_total: {
                    without_refunds: 65.58,
                    with_refunds: 59.7,
                  },
                  gross_total: {
                    without_refunds: 73.79,
                    with_refunds: 67.12,
                  },
                  net_total_spread: {
                    without_refunds: 57.8,
                    with_refunds: 57.19,
                  },
                  gross_total_spread: {
                    without_refunds: 65.05,
                    with_refunds: 64.36,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 164.85,
                    with_refunds: 162.35,
                  },
                  gross_mrr: {
                    without_refunds: 184.8,
                    with_refunds: 182.07,
                  },
                  net_arr: {
                    without_refunds: 666.26,
                    with_refunds: 476,
                  },
                  gross_arr: {
                    without_refunds: 792.45,
                    with_refunds: 562.37,
                  },
                  net_arr_spread: {
                    without_refunds: 112.11,
                    with_refunds: 112.11,
                  },
                  gross_arr_spread: {
                    without_refunds: 124.47,
                    with_refunds: 124.47,
                  },
                  net_total: {
                    without_refunds: 220.74,
                    with_refunds: 209.24,
                  },
                  gross_total: {
                    without_refunds: 246.81,
                    with_refunds: 233.84,
                  },
                  net_total_spread: {
                    without_refunds: 122.36,
                    with_refunds: 121.58,
                  },
                  gross_total_spread: {
                    without_refunds: 137.77,
                    with_refunds: 136.87,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7468,
                    with_refunds: 0.729,
                  },
                  gross_mrr: {
                    without_refunds: 0.8464,
                    with_refunds: 0.8262,
                  },
                  net_arr: {
                    without_refunds: 1.8331,
                    with_refunds: 1.5878,
                  },
                  gross_arr: {
                    without_refunds: 2.0529,
                    with_refunds: 1.7751,
                  },
                  net_arr_spread: {
                    without_refunds: 1.783,
                    with_refunds: 1.783,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9868,
                    with_refunds: 1.9868,
                  },
                  net_total: {
                    without_refunds: 1.1695,
                    with_refunds: 1.0645,
                  },
                  gross_total: {
                    without_refunds: 1.3158,
                    with_refunds: 1.1969,
                  },
                  net_total_spread: {
                    without_refunds: 1.0306,
                    with_refunds: 1.0198,
                  },
                  gross_total_spread: {
                    without_refunds: 1.16,
                    with_refunds: 1.1477,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.9395,
                    with_refunds: 2.895,
                  },
                  gross_mrr: {
                    without_refunds: 3.2952,
                    with_refunds: 3.2467,
                  },
                  net_arr: {
                    without_refunds: 11.8805,
                    with_refunds: 8.4878,
                  },
                  gross_arr: {
                    without_refunds: 14.1308,
                    with_refunds: 10.0279,
                  },
                  net_arr_spread: {
                    without_refunds: 1.999,
                    with_refunds: 1.999,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.2196,
                    with_refunds: 2.2196,
                  },
                  net_total: {
                    without_refunds: 3.9361,
                    with_refunds: 3.7311,
                  },
                  gross_total: {
                    without_refunds: 4.401,
                    with_refunds: 4.1697,
                  },
                  net_total_spread: {
                    without_refunds: 2.182,
                    with_refunds: 2.1679,
                  },
                  gross_total_spread: {
                    without_refunds: 2.4566,
                    with_refunds: 2.4406,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 893,
              with_refunds: 893,
            },
            returning_users: {
              without_refunds: 5761,
              with_refunds: 5787,
            },
            paying_users: {
              without_refunds: 6654,
              with_refunds: 6680,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 556,
              with_refunds: 556,
            },
            returning_users: {
              without_refunds: 393,
              with_refunds: 438,
            },
            paying_users: {
              without_refunds: 949,
              with_refunds: 994,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1449,
              with_refunds: 1449,
            },
            returning_users: {
              without_refunds: 6151,
              with_refunds: 6221,
            },
            paying_users: {
              without_refunds: 7600,
              with_refunds: 7670,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 93623.47,
                with_refunds: 91318.61,
              },
              gross_mrr: {
                without_refunds: 106223.11,
                with_refunds: 103602.34,
              },
              net_arr: {
                without_refunds: 147930.42,
                with_refunds: 128133.49,
              },
              gross_arr: {
                without_refunds: 165666.95,
                with_refunds: 143252.6,
              },
              net_arr_spread: {
                without_refunds: 67359.07,
                with_refunds: 67359.07,
              },
              gross_arr_spread: {
                without_refunds: 75186.79,
                with_refunds: 75186.79,
              },
              net_total: {
                without_refunds: 241553.89,
                with_refunds: 219452.09,
              },
              gross_total: {
                without_refunds: 271890.06,
                with_refunds: 246854.93,
              },
              net_total_spread: {
                without_refunds: 160982.54,
                with_refunds: 158677.68,
              },
              gross_total_spread: {
                without_refunds: 181409.9,
                with_refunds: 178789.12,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 98623.85,
                with_refunds: 96276.62,
              },
              gross_mrr: {
                without_refunds: 111778.95,
                with_refunds: 109119.51,
              },
              net_arr: {
                without_refunds: 147930.42,
                with_refunds: 128133.49,
              },
              gross_arr: {
                without_refunds: 165666.95,
                with_refunds: 143252.6,
              },
              net_arr_spread: {
                without_refunds: 125285.83,
                with_refunds: 125285.83,
              },
              gross_arr_spread: {
                without_refunds: 139607.09,
                with_refunds: 139607.09,
              },
              net_total: {
                without_refunds: 245942.1,
                with_refunds: 223863.24,
              },
              gross_total: {
                without_refunds: 276723.24,
                with_refunds: 251717.76,
              },
              net_total_spread: {
                without_refunds: 206449.22,
                with_refunds: 204274.39,
              },
              gross_total_spread: {
                without_refunds: 232368.61,
                with_refunds: 229906.89,
              },
            },
          },
        },
      },
      cac: 56.08,
      mrr_ua_spend: {
        without_refunds: 132068.4,
        with_refunds: 132068.4,
      },
      arr_ua_spend: {
        without_refunds: 80699.12,
        with_refunds: 80699.12,
      },
      arr_spread_ua_spend: {
        without_refunds: 70268.24,
        with_refunds: 70268.24,
      },
      total_ua_spend: {
        without_refunds: 210300,
        with_refunds: 210300,
      },
      total_spread_ua_spend: {
        without_refunds: 200317.76,
        with_refunds: 200317.76,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 2,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 1395,
        with_refunds: 1395,
      },
      first_period_arr_users: {
        without_refunds: 1890,
        with_refunds: 1890,
      },
      first_period_arr_spread_users: {
        without_refunds: 1401,
        with_refunds: 1401,
      },
      first_period_total_users: {
        without_refunds: 3266,
        with_refunds: 3266,
      },
      first_period_total_spread_users: {
        without_refunds: 2781,
        with_refunds: 2781,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 872,
                  with_refunds: 872,
                },
                arr_users: {
                  without_refunds: 850,
                  with_refunds: 850,
                },
                arr_spread_users: {
                  without_refunds: 647,
                  with_refunds: 647,
                },
                total_users: {
                  without_refunds: 1719,
                  with_refunds: 1719,
                },
                total_spread_users: {
                  without_refunds: 1517,
                  with_refunds: 1517,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6251,
                  with_refunds: 0.6251,
                },
                arr_users: {
                  without_refunds: 0.4497,
                  with_refunds: 0.4497,
                },
                arr_spread_users: {
                  without_refunds: 0.4618,
                  with_refunds: 0.4618,
                },
                total_users: {
                  without_refunds: 0.5263,
                  with_refunds: 0.5263,
                },
                total_spread_users: {
                  without_refunds: 0.5455,
                  with_refunds: 0.5455,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 11224.61,
                  with_refunds: 10852.4,
                },
                gross_mrr: {
                  without_refunds: 12879.26,
                  with_refunds: 12454.84,
                },
                net_arr: {
                  without_refunds: 92177.74,
                  with_refunds: 82338.46,
                },
                gross_arr: {
                  without_refunds: 104274.54,
                  with_refunds: 93008.01,
                },
                net_arr_spread: {
                  without_refunds: 5841.52,
                  with_refunds: 5841.52,
                },
                gross_arr_spread: {
                  without_refunds: 6656.81,
                  with_refunds: 6656.81,
                },
                net_total: {
                  without_refunds: 103402.35,
                  with_refunds: 93190.86,
                },
                gross_total: {
                  without_refunds: 117153.79,
                  with_refunds: 105462.84,
                },
                net_total_spread: {
                  without_refunds: 17066.13,
                  with_refunds: 16693.92,
                },
                gross_total_spread: {
                  without_refunds: 19536.07,
                  with_refunds: 19111.65,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.05,
                  with_refunds: 7.78,
                },
                gross_mrr: {
                  without_refunds: 9.23,
                  with_refunds: 8.93,
                },
                net_arr: {
                  without_refunds: 48.77,
                  with_refunds: 43.57,
                },
                gross_arr: {
                  without_refunds: 55.17,
                  with_refunds: 49.21,
                },
                net_arr_spread: {
                  without_refunds: 4.17,
                  with_refunds: 4.17,
                },
                gross_arr_spread: {
                  without_refunds: 4.75,
                  with_refunds: 4.75,
                },
                net_total: {
                  without_refunds: 31.66,
                  with_refunds: 28.53,
                },
                gross_total: {
                  without_refunds: 35.87,
                  with_refunds: 32.29,
                },
                net_total_spread: {
                  without_refunds: 6.14,
                  with_refunds: 6,
                },
                gross_total_spread: {
                  without_refunds: 7.02,
                  with_refunds: 6.87,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.87,
                  with_refunds: 12.45,
                },
                gross_mrr: {
                  without_refunds: 14.77,
                  with_refunds: 14.28,
                },
                net_arr: {
                  without_refunds: 108.44,
                  with_refunds: 96.87,
                },
                gross_arr: {
                  without_refunds: 122.68,
                  with_refunds: 109.42,
                },
                net_arr_spread: {
                  without_refunds: 9.03,
                  with_refunds: 9.03,
                },
                gross_arr_spread: {
                  without_refunds: 10.29,
                  with_refunds: 10.29,
                },
                net_total: {
                  without_refunds: 60.15,
                  with_refunds: 54.21,
                },
                gross_total: {
                  without_refunds: 68.15,
                  with_refunds: 61.35,
                },
                net_total_spread: {
                  without_refunds: 11.25,
                  with_refunds: 11,
                },
                gross_total_spread: {
                  without_refunds: 12.88,
                  with_refunds: 12.6,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 8.05,
                    with_refunds: 7.78,
                  },
                  gross_mrr: {
                    without_refunds: 9.23,
                    with_refunds: 8.93,
                  },
                  net_arr: {
                    without_refunds: 48.77,
                    with_refunds: 43.57,
                  },
                  gross_arr: {
                    without_refunds: 55.17,
                    with_refunds: 49.21,
                  },
                  net_arr_spread: {
                    without_refunds: 4.17,
                    with_refunds: 4.17,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.75,
                    with_refunds: 4.75,
                  },
                  net_total: {
                    without_refunds: 31.66,
                    with_refunds: 28.53,
                  },
                  gross_total: {
                    without_refunds: 35.87,
                    with_refunds: 32.29,
                  },
                  net_total_spread: {
                    without_refunds: 6.14,
                    with_refunds: 6,
                  },
                  gross_total_spread: {
                    without_refunds: 7.02,
                    with_refunds: 6.87,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 12.87,
                    with_refunds: 12.45,
                  },
                  gross_mrr: {
                    without_refunds: 14.77,
                    with_refunds: 14.28,
                  },
                  net_arr: {
                    without_refunds: 108.44,
                    with_refunds: 96.87,
                  },
                  gross_arr: {
                    without_refunds: 122.68,
                    with_refunds: 109.42,
                  },
                  net_arr_spread: {
                    without_refunds: 9.03,
                    with_refunds: 9.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.29,
                    with_refunds: 10.29,
                  },
                  net_total: {
                    without_refunds: 60.15,
                    with_refunds: 54.21,
                  },
                  gross_total: {
                    without_refunds: 68.15,
                    with_refunds: 61.35,
                  },
                  net_total_spread: {
                    without_refunds: 11.25,
                    with_refunds: 11,
                  },
                  gross_total_spread: {
                    without_refunds: 12.88,
                    with_refunds: 12.6,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1216,
                    with_refunds: 0.1176,
                  },
                  gross_mrr: {
                    without_refunds: 0.1395,
                    with_refunds: 0.1349,
                  },
                  net_arr: {
                    without_refunds: 0.7369,
                    with_refunds: 0.6583,
                  },
                  gross_arr: {
                    without_refunds: 0.8337,
                    with_refunds: 0.7436,
                  },
                  net_arr_spread: {
                    without_refunds: 0.063,
                    with_refunds: 0.063,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0718,
                    with_refunds: 0.0718,
                  },
                  net_total: {
                    without_refunds: 0.4784,
                    with_refunds: 0.4312,
                  },
                  gross_total: {
                    without_refunds: 0.542,
                    with_refunds: 0.4879,
                  },
                  net_total_spread: {
                    without_refunds: 0.0927,
                    with_refunds: 0.0907,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1061,
                    with_refunds: 0.1038,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1945,
                    with_refunds: 0.1881,
                  },
                  gross_mrr: {
                    without_refunds: 0.2232,
                    with_refunds: 0.2158,
                  },
                  net_arr: {
                    without_refunds: 1.6386,
                    with_refunds: 1.4637,
                  },
                  gross_arr: {
                    without_refunds: 1.8537,
                    with_refunds: 1.6534,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1364,
                    with_refunds: 0.1364,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1555,
                    with_refunds: 0.1555,
                  },
                  net_total: {
                    without_refunds: 0.9089,
                    with_refunds: 0.8192,
                  },
                  gross_total: {
                    without_refunds: 1.0298,
                    with_refunds: 0.927,
                  },
                  net_total_spread: {
                    without_refunds: 0.17,
                    with_refunds: 0.1663,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1946,
                    with_refunds: 0.1904,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1074,
                  with_refunds: 1086,
                },
                arr_users: {
                  without_refunds: 1000,
                  with_refunds: 1099,
                },
                arr_spread_users: {
                  without_refunds: 1365,
                  with_refunds: 1365,
                },
                total_users: {
                  without_refunds: 2073,
                  with_refunds: 2182,
                },
                total_spread_users: {
                  without_refunds: 2439,
                  with_refunds: 2451,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7699,
                  with_refunds: 0.7785,
                },
                arr_users: {
                  without_refunds: 0.5291,
                  with_refunds: 0.5815,
                },
                arr_spread_users: {
                  without_refunds: 0.9743,
                  with_refunds: 0.9743,
                },
                total_users: {
                  without_refunds: 0.6347,
                  with_refunds: 0.6681,
                },
                total_spread_users: {
                  without_refunds: 0.877,
                  with_refunds: 0.8813,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.2317,
                  with_refunds: 1.2454,
                },
                arr_users: {
                  without_refunds: 1.1765,
                  with_refunds: 1.2929,
                },
                arr_spread_users: {
                  without_refunds: 2.1097,
                  with_refunds: 2.1097,
                },
                total_users: {
                  without_refunds: 1.2059,
                  with_refunds: 1.2693,
                },
                total_spread_users: {
                  without_refunds: 1.6078,
                  with_refunds: 1.6157,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 14112.97,
                  with_refunds: 12891.29,
                },
                gross_mrr: {
                  without_refunds: 16192.1,
                  with_refunds: 14866.82,
                },
                net_arr: {
                  without_refunds: 121714.35,
                  with_refunds: 81946.43,
                },
                gross_arr: {
                  without_refunds: 134820.98,
                  with_refunds: 91484.32,
                },
                net_arr_spread: {
                  without_refunds: 13073.9,
                  with_refunds: 13073.9,
                },
                gross_arr_spread: {
                  without_refunds: 14714.98,
                  with_refunds: 14714.98,
                },
                net_total: {
                  without_refunds: 135827.33,
                  with_refunds: 94837.72,
                },
                gross_total: {
                  without_refunds: 151013.08,
                  with_refunds: 106351.15,
                },
                net_total_spread: {
                  without_refunds: 27186.87,
                  with_refunds: 25965.19,
                },
                gross_total_spread: {
                  without_refunds: 30907.08,
                  with_refunds: 29581.81,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.2573,
                  with_refunds: 1.1879,
                },
                gross_mrr: {
                  without_refunds: 1.2572,
                  with_refunds: 1.1937,
                },
                net_arr: {
                  without_refunds: 1.3204,
                  with_refunds: 0.9952,
                },
                gross_arr: {
                  without_refunds: 1.2929,
                  with_refunds: 0.9836,
                },
                net_arr_spread: {
                  without_refunds: 2.2381,
                  with_refunds: 2.2381,
                },
                gross_arr_spread: {
                  without_refunds: 2.2105,
                  with_refunds: 2.2105,
                },
                net_total: {
                  without_refunds: 1.3136,
                  with_refunds: 1.0177,
                },
                gross_total: {
                  without_refunds: 1.289,
                  with_refunds: 1.0084,
                },
                net_total_spread: {
                  without_refunds: 1.593,
                  with_refunds: 1.5554,
                },
                gross_total_spread: {
                  without_refunds: 1.5821,
                  with_refunds: 1.5478,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.2573,
                  with_refunds: 1.1879,
                },
                gross_mrr: {
                  without_refunds: 1.2572,
                  with_refunds: 1.1937,
                },
                net_arr: {
                  without_refunds: 1.3204,
                  with_refunds: 0.9952,
                },
                gross_arr: {
                  without_refunds: 1.2929,
                  with_refunds: 0.9836,
                },
                net_arr_spread: {
                  without_refunds: 2.2381,
                  with_refunds: 2.2381,
                },
                gross_arr_spread: {
                  without_refunds: 2.2105,
                  with_refunds: 2.2105,
                },
                net_total: {
                  without_refunds: 1.3136,
                  with_refunds: 1.0177,
                },
                gross_total: {
                  without_refunds: 1.289,
                  with_refunds: 1.0084,
                },
                net_total_spread: {
                  without_refunds: 1.593,
                  with_refunds: 1.5554,
                },
                gross_total_spread: {
                  without_refunds: 1.5821,
                  with_refunds: 1.5478,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 10.12,
                  with_refunds: 9.24,
                },
                gross_mrr: {
                  without_refunds: 11.61,
                  with_refunds: 10.66,
                },
                net_arr: {
                  without_refunds: 64.4,
                  with_refunds: 43.36,
                },
                gross_arr: {
                  without_refunds: 71.33,
                  with_refunds: 48.4,
                },
                net_arr_spread: {
                  without_refunds: 9.33,
                  with_refunds: 9.33,
                },
                gross_arr_spread: {
                  without_refunds: 10.5,
                  with_refunds: 10.5,
                },
                net_total: {
                  without_refunds: 41.59,
                  with_refunds: 29.04,
                },
                gross_total: {
                  without_refunds: 46.24,
                  with_refunds: 32.56,
                },
                net_total_spread: {
                  without_refunds: 9.78,
                  with_refunds: 9.34,
                },
                gross_total_spread: {
                  without_refunds: 11.11,
                  with_refunds: 10.64,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.14,
                  with_refunds: 11.87,
                },
                gross_mrr: {
                  without_refunds: 15.08,
                  with_refunds: 13.69,
                },
                net_arr: {
                  without_refunds: 121.71,
                  with_refunds: 74.56,
                },
                gross_arr: {
                  without_refunds: 134.82,
                  with_refunds: 83.24,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.78,
                  with_refunds: 10.78,
                },
                net_total: {
                  without_refunds: 65.52,
                  with_refunds: 43.46,
                },
                gross_total: {
                  without_refunds: 72.85,
                  with_refunds: 48.74,
                },
                net_total_spread: {
                  without_refunds: 11.15,
                  with_refunds: 10.59,
                },
                gross_total_spread: {
                  without_refunds: 12.67,
                  with_refunds: 12.07,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.16,
                    with_refunds: 17.02,
                  },
                  gross_mrr: {
                    without_refunds: 20.84,
                    with_refunds: 19.59,
                  },
                  net_arr: {
                    without_refunds: 113.17,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 126.51,
                    with_refunds: 97.61,
                  },
                  net_arr_spread: {
                    without_refunds: 13.5,
                    with_refunds: 13.5,
                  },
                  gross_arr_spread: {
                    without_refunds: 15.25,
                    with_refunds: 15.25,
                  },
                  net_total: {
                    without_refunds: 73.25,
                    with_refunds: 57.57,
                  },
                  gross_total: {
                    without_refunds: 82.11,
                    with_refunds: 64.85,
                  },
                  net_total_spread: {
                    without_refunds: 15.91,
                    with_refunds: 15.34,
                  },
                  gross_total_spread: {
                    without_refunds: 18.14,
                    with_refunds: 17.51,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 26.01,
                    with_refunds: 24.32,
                  },
                  gross_mrr: {
                    without_refunds: 29.85,
                    with_refunds: 27.97,
                  },
                  net_arr: {
                    without_refunds: 230.16,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 257.5,
                    with_refunds: 192.66,
                  },
                  net_arr_spread: {
                    without_refunds: 18.61,
                    with_refunds: 18.61,
                  },
                  gross_arr_spread: {
                    without_refunds: 21.07,
                    with_refunds: 21.07,
                  },
                  net_total: {
                    without_refunds: 125.67,
                    with_refunds: 97.68,
                  },
                  gross_total: {
                    without_refunds: 141,
                    with_refunds: 110.09,
                  },
                  net_total_spread: {
                    without_refunds: 22.4,
                    with_refunds: 21.6,
                  },
                  gross_total_spread: {
                    without_refunds: 25.55,
                    with_refunds: 24.67,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2745,
                    with_refunds: 0.2572,
                  },
                  gross_mrr: {
                    without_refunds: 0.3149,
                    with_refunds: 0.2959,
                  },
                  net_arr: {
                    without_refunds: 1.71,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9115,
                    with_refunds: 1.475,
                  },
                  net_arr_spread: {
                    without_refunds: 0.204,
                    with_refunds: 0.204,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2305,
                    with_refunds: 0.2305,
                  },
                  net_total: {
                    without_refunds: 1.1068,
                    with_refunds: 0.8699,
                  },
                  gross_total: {
                    without_refunds: 1.2407,
                    with_refunds: 0.98,
                  },
                  net_total_spread: {
                    without_refunds: 0.2404,
                    with_refunds: 0.2318,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2741,
                    with_refunds: 0.2646,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3931,
                    with_refunds: 0.3674,
                  },
                  gross_mrr: {
                    without_refunds: 0.451,
                    with_refunds: 0.4227,
                  },
                  net_arr: {
                    without_refunds: 3.4778,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 3.8909,
                    with_refunds: 2.9112,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2812,
                    with_refunds: 0.2812,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3184,
                    with_refunds: 0.3184,
                  },
                  net_total: {
                    without_refunds: 1.899,
                    with_refunds: 1.4759,
                  },
                  gross_total: {
                    without_refunds: 2.1306,
                    with_refunds: 1.6635,
                  },
                  net_total_spread: {
                    without_refunds: 0.3384,
                    with_refunds: 0.3264,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3861,
                    with_refunds: 0.3727,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 725,
                  with_refunds: 739,
                },
                arr_users: {
                  without_refunds: 31,
                  with_refunds: 89,
                },
                arr_spread_users: {
                  without_refunds: 1385,
                  with_refunds: 1385,
                },
                total_users: {
                  without_refunds: 756,
                  with_refunds: 828,
                },
                total_spread_users: {
                  without_refunds: 2110,
                  with_refunds: 2124,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5197,
                  with_refunds: 0.5297,
                },
                arr_users: {
                  without_refunds: 0.0164,
                  with_refunds: 0.0471,
                },
                arr_spread_users: {
                  without_refunds: 0.9886,
                  with_refunds: 0.9886,
                },
                total_users: {
                  without_refunds: 0.2315,
                  with_refunds: 0.2535,
                },
                total_spread_users: {
                  without_refunds: 0.7587,
                  with_refunds: 0.7638,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.675,
                  with_refunds: 0.6805,
                },
                arr_users: {
                  without_refunds: 0.031,
                  with_refunds: 0.081,
                },
                arr_spread_users: {
                  without_refunds: 1.0147,
                  with_refunds: 1.0147,
                },
                total_users: {
                  without_refunds: 0.3647,
                  with_refunds: 0.3795,
                },
                total_spread_users: {
                  without_refunds: 0.8651,
                  with_refunds: 0.8666,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9241.67,
                  with_refunds: 8919.06,
                },
                gross_mrr: {
                  without_refunds: 10531.11,
                  with_refunds: 10185.39,
                },
                net_arr: {
                  without_refunds: 3631.79,
                },
                gross_arr: {
                  without_refunds: 4470.52,
                },
                net_arr_spread: {
                  without_refunds: 13272.69,
                  with_refunds: 13272.69,
                },
                gross_arr_spread: {
                  without_refunds: 14965.28,
                  with_refunds: 14965.28,
                },
                net_total: {
                  without_refunds: 12873.46,
                  with_refunds: 8919.06,
                },
                gross_total: {
                  without_refunds: 15001.63,
                  with_refunds: 10185.39,
                },
                net_total_spread: {
                  without_refunds: 22514.36,
                  with_refunds: 22191.75,
                },
                gross_total_spread: {
                  without_refunds: 25496.39,
                  with_refunds: 25150.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8233,
                  with_refunds: 0.8219,
                },
                gross_mrr: {
                  without_refunds: 0.8177,
                  with_refunds: 0.8178,
                },
                net_arr: {
                  without_refunds: 0.0394,
                },
                gross_arr: {
                  without_refunds: 0.0429,
                },
                net_arr_spread: {
                  without_refunds: 2.2721,
                  with_refunds: 2.2721,
                },
                gross_arr_spread: {
                  without_refunds: 2.2481,
                  with_refunds: 2.2481,
                },
                net_total: {
                  without_refunds: 0.1245,
                  with_refunds: 0.0957,
                },
                gross_total: {
                  without_refunds: 0.1281,
                  with_refunds: 0.0966,
                },
                net_total_spread: {
                  without_refunds: 1.3192,
                  with_refunds: 1.3293,
                },
                gross_total_spread: {
                  without_refunds: 1.3051,
                  with_refunds: 1.316,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6548,
                  with_refunds: 0.6919,
                },
                gross_mrr: {
                  without_refunds: 0.6504,
                  with_refunds: 0.6851,
                },
                net_arr: {
                  without_refunds: 0.0298,
                },
                gross_arr: {
                  without_refunds: 0.0332,
                },
                net_arr_spread: {
                  without_refunds: 1.0152,
                  with_refunds: 1.0152,
                },
                gross_arr_spread: {
                  without_refunds: 1.017,
                  with_refunds: 1.017,
                },
                net_total: {
                  without_refunds: 0.0948,
                  with_refunds: 0.094,
                },
                gross_total: {
                  without_refunds: 0.0993,
                  with_refunds: 0.0958,
                },
                net_total_spread: {
                  without_refunds: 0.8281,
                  with_refunds: 0.8547,
                },
                gross_total_spread: {
                  without_refunds: 0.8249,
                  with_refunds: 0.8502,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.62,
                  with_refunds: 6.39,
                },
                gross_mrr: {
                  without_refunds: 7.55,
                  with_refunds: 7.3,
                },
                net_arr: {
                  without_refunds: 1.92,
                },
                gross_arr: {
                  without_refunds: 2.37,
                },
                net_arr_spread: {
                  without_refunds: 9.47,
                  with_refunds: 9.47,
                },
                gross_arr_spread: {
                  without_refunds: 10.68,
                  with_refunds: 10.68,
                },
                net_total: {
                  without_refunds: 3.94,
                  with_refunds: 2.73,
                },
                gross_total: {
                  without_refunds: 4.59,
                  with_refunds: 3.12,
                },
                net_total_spread: {
                  without_refunds: 8.1,
                  with_refunds: 7.98,
                },
                gross_total_spread: {
                  without_refunds: 9.17,
                  with_refunds: 9.04,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.75,
                  with_refunds: 12.07,
                },
                gross_mrr: {
                  without_refunds: 14.53,
                  with_refunds: 13.78,
                },
                net_arr: {
                  without_refunds: 117.15,
                },
                gross_arr: {
                  without_refunds: 144.21,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 17.03,
                  with_refunds: 10.77,
                },
                gross_total: {
                  without_refunds: 19.84,
                  with_refunds: 12.3,
                },
                net_total_spread: {
                  without_refunds: 10.67,
                  with_refunds: 10.45,
                },
                gross_total_spread: {
                  without_refunds: 12.08,
                  with_refunds: 11.84,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.79,
                    with_refunds: 23.41,
                  },
                  gross_mrr: {
                    without_refunds: 28.39,
                    with_refunds: 26.89,
                  },
                  net_arr: {
                    without_refunds: 115.09,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 128.87,
                    with_refunds: 97.61,
                  },
                  net_arr_spread: {
                    without_refunds: 22.98,
                    with_refunds: 22.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 25.94,
                    with_refunds: 25.94,
                  },
                  net_total: {
                    without_refunds: 77.19,
                    with_refunds: 60.3,
                  },
                  gross_total: {
                    without_refunds: 86.7,
                    with_refunds: 67.97,
                  },
                  net_total_spread: {
                    without_refunds: 24.01,
                    with_refunds: 23.32,
                  },
                  gross_total_spread: {
                    without_refunds: 27.31,
                    with_refunds: 26.55,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 38.76,
                    with_refunds: 36.38,
                  },
                  gross_mrr: {
                    without_refunds: 44.37,
                    with_refunds: 41.76,
                  },
                  net_arr: {
                    without_refunds: 347.31,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 401.71,
                    with_refunds: 192.66,
                  },
                  net_arr_spread: {
                    without_refunds: 28.19,
                    with_refunds: 28.19,
                  },
                  gross_arr_spread: {
                    without_refunds: 31.87,
                    with_refunds: 31.87,
                  },
                  net_total: {
                    without_refunds: 142.7,
                    with_refunds: 108.45,
                  },
                  gross_total: {
                    without_refunds: 160.84,
                    with_refunds: 122.39,
                  },
                  net_total_spread: {
                    without_refunds: 33.07,
                    with_refunds: 32.05,
                  },
                  gross_total_spread: {
                    without_refunds: 37.63,
                    with_refunds: 36.51,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3746,
                    with_refunds: 0.3538,
                  },
                  gross_mrr: {
                    without_refunds: 0.429,
                    with_refunds: 0.4063,
                  },
                  net_arr: {
                    without_refunds: 1.7391,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9473,
                    with_refunds: 1.475,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3472,
                    with_refunds: 0.3472,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3919,
                    with_refunds: 0.3919,
                  },
                  net_total: {
                    without_refunds: 1.1664,
                    with_refunds: 0.9112,
                  },
                  gross_total: {
                    without_refunds: 1.3101,
                    with_refunds: 1.0271,
                  },
                  net_total_spread: {
                    without_refunds: 0.3628,
                    with_refunds: 0.3524,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4126,
                    with_refunds: 0.4012,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5857,
                    with_refunds: 0.5498,
                  },
                  gross_mrr: {
                    without_refunds: 0.6705,
                    with_refunds: 0.6309,
                  },
                  net_arr: {
                    without_refunds: 5.248,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 6.0699,
                    with_refunds: 2.9112,
                  },
                  net_arr_spread: {
                    without_refunds: 0.426,
                    with_refunds: 0.426,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4816,
                    with_refunds: 0.4816,
                  },
                  net_total: {
                    without_refunds: 2.1563,
                    with_refunds: 1.6387,
                  },
                  gross_total: {
                    without_refunds: 2.4304,
                    with_refunds: 1.8494,
                  },
                  net_total_spread: {
                    without_refunds: 0.4997,
                    with_refunds: 0.4842,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5687,
                    with_refunds: 0.5517,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 567,
                  with_refunds: 568,
                },
                arr_users: {
                  without_refunds: 10,
                  with_refunds: 16,
                },
                arr_spread_users: {
                  without_refunds: 1389,
                  with_refunds: 1389,
                },
                total_users: {
                  without_refunds: 577,
                  with_refunds: 584,
                },
                total_spread_users: {
                  without_refunds: 1956,
                  with_refunds: 1957,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4065,
                  with_refunds: 0.4072,
                },
                arr_users: {
                  without_refunds: 0.0053,
                  with_refunds: 0.0085,
                },
                arr_spread_users: {
                  without_refunds: 0.9914,
                  with_refunds: 0.9914,
                },
                total_users: {
                  without_refunds: 0.1767,
                  with_refunds: 0.1788,
                },
                total_spread_users: {
                  without_refunds: 0.7033,
                  with_refunds: 0.7037,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7821,
                  with_refunds: 0.7686,
                },
                arr_users: {
                  without_refunds: 0.3226,
                  with_refunds: 0.1798,
                },
                arr_spread_users: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                total_users: {
                  without_refunds: 0.7632,
                  with_refunds: 0.7053,
                },
                total_spread_users: {
                  without_refunds: 0.927,
                  with_refunds: 0.9214,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 7352.83,
                  with_refunds: 7169.13,
                },
                gross_mrr: {
                  without_refunds: 8393.29,
                  with_refunds: 8183.55,
                },
                net_arr: {
                  without_refunds: 1121.42,
                },
                gross_arr: {
                  without_refunds: 1447.69,
                  with_refunds: 120.17,
                },
                net_arr_spread: {
                  without_refunds: 13307.44,
                  with_refunds: 13307.44,
                },
                gross_arr_spread: {
                  without_refunds: 15012.32,
                  with_refunds: 15012.32,
                },
                net_total: {
                  without_refunds: 8474.25,
                  with_refunds: 7169.13,
                },
                gross_total: {
                  without_refunds: 9840.98,
                  with_refunds: 8303.71,
                },
                net_total_spread: {
                  without_refunds: 20660.27,
                  with_refunds: 20476.57,
                },
                gross_total_spread: {
                  without_refunds: 23405.6,
                  with_refunds: 23195.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.6551,
                  with_refunds: 0.6606,
                },
                gross_mrr: {
                  without_refunds: 0.6517,
                  with_refunds: 0.6571,
                },
                net_arr: {
                  without_refunds: 0.0122,
                },
                gross_arr: {
                  without_refunds: 0.0139,
                  with_refunds: 0.0013,
                },
                net_arr_spread: {
                  without_refunds: 2.2781,
                  with_refunds: 2.2781,
                },
                gross_arr_spread: {
                  without_refunds: 2.2552,
                  with_refunds: 2.2552,
                },
                net_total: {
                  without_refunds: 0.082,
                  with_refunds: 0.0769,
                },
                gross_total: {
                  without_refunds: 0.084,
                  with_refunds: 0.0787,
                },
                net_total_spread: {
                  without_refunds: 1.2106,
                  with_refunds: 1.2266,
                },
                gross_total_spread: {
                  without_refunds: 1.1981,
                  with_refunds: 1.2137,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7956,
                  with_refunds: 0.8038,
                },
                gross_mrr: {
                  without_refunds: 0.797,
                  with_refunds: 0.8035,
                },
                net_arr: {
                  without_refunds: 0.3088,
                },
                gross_arr: {
                  without_refunds: 0.3238,
                  with_refunds: 0.0013,
                },
                net_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                gross_arr_spread: {
                  without_refunds: 1.0031,
                  with_refunds: 1.0031,
                },
                net_total: {
                  without_refunds: 0.6583,
                  with_refunds: 0.8038,
                },
                gross_total: {
                  without_refunds: 0.656,
                  with_refunds: 0.8153,
                },
                net_total_spread: {
                  without_refunds: 0.9176,
                  with_refunds: 0.9227,
                },
                gross_total_spread: {
                  without_refunds: 0.918,
                  with_refunds: 0.9223,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.27,
                  with_refunds: 5.14,
                },
                gross_mrr: {
                  without_refunds: 6.02,
                  with_refunds: 5.87,
                },
                net_arr: {
                  without_refunds: 0.59,
                },
                gross_arr: {
                  without_refunds: 0.77,
                  with_refunds: 0.06,
                },
                net_arr_spread: {
                  without_refunds: 9.5,
                  with_refunds: 9.5,
                },
                gross_arr_spread: {
                  without_refunds: 10.72,
                  with_refunds: 10.72,
                },
                net_total: {
                  without_refunds: 2.59,
                  with_refunds: 2.2,
                },
                gross_total: {
                  without_refunds: 3.01,
                  with_refunds: 2.54,
                },
                net_total_spread: {
                  without_refunds: 7.43,
                  with_refunds: 7.36,
                },
                gross_total_spread: {
                  without_refunds: 8.42,
                  with_refunds: 8.34,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.97,
                  with_refunds: 12.62,
                },
                gross_mrr: {
                  without_refunds: 14.8,
                  with_refunds: 14.41,
                },
                net_arr: {
                  without_refunds: 112.14,
                },
                gross_arr: {
                  without_refunds: 144.77,
                  with_refunds: 7.51,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 14.69,
                  with_refunds: 12.28,
                },
                gross_total: {
                  without_refunds: 17.06,
                  with_refunds: 14.22,
                },
                net_total_spread: {
                  without_refunds: 10.56,
                  with_refunds: 10.46,
                },
                gross_total_spread: {
                  without_refunds: 11.97,
                  with_refunds: 11.85,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.06,
                    with_refunds: 28.55,
                  },
                  gross_mrr: {
                    without_refunds: 34.41,
                    with_refunds: 32.75,
                  },
                  net_arr: {
                    without_refunds: 115.69,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 129.64,
                    with_refunds: 97.68,
                  },
                  net_arr_spread: {
                    without_refunds: 32.47,
                    with_refunds: 32.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.65,
                    with_refunds: 36.65,
                  },
                  net_total: {
                    without_refunds: 79.78,
                    with_refunds: 62.5,
                  },
                  gross_total: {
                    without_refunds: 89.72,
                    with_refunds: 70.52,
                  },
                  net_total_spread: {
                    without_refunds: 31.44,
                    with_refunds: 30.68,
                  },
                  gross_total_spread: {
                    without_refunds: 35.72,
                    with_refunds: 34.89,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 51.73,
                    with_refunds: 49.01,
                  },
                  gross_mrr: {
                    without_refunds: 59.17,
                    with_refunds: 56.16,
                  },
                  net_arr: {
                    without_refunds: 459.46,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 546.48,
                    with_refunds: 200.17,
                  },
                  net_arr_spread: {
                    without_refunds: 37.77,
                    with_refunds: 37.77,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.68,
                    with_refunds: 42.68,
                  },
                  net_total: {
                    without_refunds: 157.39,
                    with_refunds: 120.72,
                  },
                  gross_total: {
                    without_refunds: 177.9,
                    with_refunds: 136.61,
                  },
                  net_total_spread: {
                    without_refunds: 43.63,
                    with_refunds: 42.51,
                  },
                  gross_total_spread: {
                    without_refunds: 49.6,
                    with_refunds: 48.36,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4542,
                    with_refunds: 0.4314,
                  },
                  gross_mrr: {
                    without_refunds: 0.5199,
                    with_refunds: 0.4949,
                  },
                  net_arr: {
                    without_refunds: 1.748,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9589,
                    with_refunds: 1.476,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4907,
                    with_refunds: 0.4907,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5538,
                    with_refunds: 0.5538,
                  },
                  net_total: {
                    without_refunds: 1.2056,
                    with_refunds: 0.9444,
                  },
                  gross_total: {
                    without_refunds: 1.3556,
                    with_refunds: 1.0655,
                  },
                  net_total_spread: {
                    without_refunds: 0.475,
                    with_refunds: 0.4636,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5398,
                    with_refunds: 0.5273,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7816,
                    with_refunds: 0.7405,
                  },
                  gross_mrr: {
                    without_refunds: 0.8942,
                    with_refunds: 0.8486,
                  },
                  net_arr: {
                    without_refunds: 6.9425,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 8.2574,
                    with_refunds: 3.0247,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5707,
                    with_refunds: 0.5707,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6449,
                    with_refunds: 0.6449,
                  },
                  net_total: {
                    without_refunds: 2.3782,
                    with_refunds: 1.8242,
                  },
                  gross_total: {
                    without_refunds: 2.6881,
                    with_refunds: 2.0642,
                  },
                  net_total_spread: {
                    without_refunds: 0.6593,
                    with_refunds: 0.6423,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7495,
                    with_refunds: 0.7308,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 456,
                  with_refunds: 458,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 4,
                },
                arr_spread_users: {
                  without_refunds: 1391,
                  with_refunds: 1391,
                },
                total_users: {
                  without_refunds: 458,
                  with_refunds: 462,
                },
                total_spread_users: {
                  without_refunds: 1847,
                  with_refunds: 1849,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.3269,
                  with_refunds: 0.3283,
                },
                arr_users: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0021,
                },
                arr_spread_users: {
                  without_refunds: 0.9929,
                  with_refunds: 0.9929,
                },
                total_users: {
                  without_refunds: 0.1402,
                  with_refunds: 0.1415,
                },
                total_spread_users: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6649,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8042,
                  with_refunds: 0.8063,
                },
                arr_users: {
                  without_refunds: 0.2,
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0014,
                  with_refunds: 1.0014,
                },
                total_users: {
                  without_refunds: 0.7938,
                  with_refunds: 0.7911,
                },
                total_spread_users: {
                  without_refunds: 0.9443,
                  with_refunds: 0.9448,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5849.2,
                  with_refunds: 5649.99,
                },
                gross_mrr: {
                  without_refunds: 6653.73,
                  with_refunds: 6434.24,
                },
                net_arr: {
                  without_refunds: 206.06,
                },
                gross_arr: {
                  without_refunds: 224.99,
                },
                net_arr_spread: {
                  without_refunds: 13324.61,
                  with_refunds: 13324.61,
                },
                gross_arr_spread: {
                  without_refunds: 15031.07,
                  with_refunds: 15031.07,
                },
                net_total: {
                  without_refunds: 6055.26,
                  with_refunds: 5649.99,
                },
                gross_total: {
                  without_refunds: 6878.72,
                  with_refunds: 6434.24,
                },
                net_total_spread: {
                  without_refunds: 19173.81,
                  with_refunds: 18974.6,
                },
                gross_total_spread: {
                  without_refunds: 21684.79,
                  with_refunds: 21465.31,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5211,
                  with_refunds: 0.5206,
                },
                gross_mrr: {
                  without_refunds: 0.5166,
                  with_refunds: 0.5166,
                },
                net_arr: {
                  without_refunds: 0.0022,
                },
                gross_arr: {
                  without_refunds: 0.0022,
                },
                net_arr_spread: {
                  without_refunds: 2.281,
                  with_refunds: 2.281,
                },
                gross_arr_spread: {
                  without_refunds: 2.258,
                  with_refunds: 2.258,
                },
                net_total: {
                  without_refunds: 0.0586,
                  with_refunds: 0.0606,
                },
                gross_total: {
                  without_refunds: 0.0587,
                  with_refunds: 0.061,
                },
                net_total_spread: {
                  without_refunds: 1.1235,
                  with_refunds: 1.1366,
                },
                gross_total_spread: {
                  without_refunds: 1.11,
                  with_refunds: 1.1232,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7955,
                  with_refunds: 0.7881,
                },
                gross_mrr: {
                  without_refunds: 0.7927,
                  with_refunds: 0.7862,
                },
                net_arr: {
                  without_refunds: 0.1837,
                },
                gross_arr: {
                  without_refunds: 0.1554,
                },
                net_arr_spread: {
                  without_refunds: 1.0013,
                  with_refunds: 1.0013,
                },
                gross_arr_spread: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                net_total: {
                  without_refunds: 0.7145,
                  with_refunds: 0.7881,
                },
                gross_total: {
                  without_refunds: 0.699,
                  with_refunds: 0.7749,
                },
                net_total_spread: {
                  without_refunds: 0.9281,
                  with_refunds: 0.9266,
                },
                gross_total_spread: {
                  without_refunds: 0.9265,
                  with_refunds: 0.9254,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.19,
                  with_refunds: 4.05,
                },
                gross_mrr: {
                  without_refunds: 4.77,
                  with_refunds: 4.61,
                },
                net_arr: {
                  without_refunds: 0.11,
                },
                gross_arr: {
                  without_refunds: 0.12,
                },
                net_arr_spread: {
                  without_refunds: 9.51,
                  with_refunds: 9.51,
                },
                gross_arr_spread: {
                  without_refunds: 10.73,
                  with_refunds: 10.73,
                },
                net_total: {
                  without_refunds: 1.85,
                  with_refunds: 1.73,
                },
                gross_total: {
                  without_refunds: 2.11,
                  with_refunds: 1.97,
                },
                net_total_spread: {
                  without_refunds: 6.89,
                  with_refunds: 6.82,
                },
                gross_total_spread: {
                  without_refunds: 7.8,
                  with_refunds: 7.72,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.83,
                  with_refunds: 12.34,
                },
                gross_mrr: {
                  without_refunds: 14.59,
                  with_refunds: 14.05,
                },
                net_arr: {
                  without_refunds: 103.03,
                },
                gross_arr: {
                  without_refunds: 112.5,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 13.22,
                  with_refunds: 12.23,
                },
                gross_total: {
                  without_refunds: 15.02,
                  with_refunds: 13.93,
                },
                net_total_spread: {
                  without_refunds: 10.38,
                  with_refunds: 10.26,
                },
                gross_total_spread: {
                  without_refunds: 11.74,
                  with_refunds: 11.61,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 34.25,
                    with_refunds: 32.6,
                  },
                  gross_mrr: {
                    without_refunds: 39.18,
                    with_refunds: 37.37,
                  },
                  net_arr: {
                    without_refunds: 115.79,
                    with_refunds: 86.92,
                  },
                  gross_arr: {
                    without_refunds: 129.76,
                    with_refunds: 97.68,
                  },
                  net_arr_spread: {
                    without_refunds: 41.98,
                    with_refunds: 41.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.38,
                    with_refunds: 47.38,
                  },
                  net_total: {
                    without_refunds: 81.64,
                    with_refunds: 64.23,
                  },
                  gross_total: {
                    without_refunds: 91.82,
                    with_refunds: 72.49,
                  },
                  net_total_spread: {
                    without_refunds: 38.33,
                    with_refunds: 37.51,
                  },
                  gross_total_spread: {
                    without_refunds: 43.52,
                    with_refunds: 42.61,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 64.56,
                    with_refunds: 61.34,
                  },
                  gross_mrr: {
                    without_refunds: 73.77,
                    with_refunds: 70.21,
                  },
                  net_arr: {
                    without_refunds: 562.49,
                    with_refunds: 171.43,
                  },
                  gross_arr: {
                    without_refunds: 658.97,
                    with_refunds: 200.17,
                  },
                  net_arr_spread: {
                    without_refunds: 47.35,
                    with_refunds: 47.35,
                  },
                  gross_arr_spread: {
                    without_refunds: 53.49,
                    with_refunds: 53.49,
                  },
                  net_total: {
                    without_refunds: 170.61,
                    with_refunds: 132.95,
                  },
                  gross_total: {
                    without_refunds: 192.92,
                    with_refunds: 150.54,
                  },
                  net_total_spread: {
                    without_refunds: 54.01,
                    with_refunds: 52.77,
                  },
                  gross_total_spread: {
                    without_refunds: 61.34,
                    with_refunds: 59.97,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5176,
                    with_refunds: 0.4926,
                  },
                  gross_mrr: {
                    without_refunds: 0.5919,
                    with_refunds: 0.5646,
                  },
                  net_arr: {
                    without_refunds: 1.7497,
                    with_refunds: 1.3134,
                  },
                  gross_arr: {
                    without_refunds: 1.9607,
                    with_refunds: 1.476,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6344,
                    with_refunds: 0.6344,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7159,
                    with_refunds: 0.7159,
                  },
                  net_total: {
                    without_refunds: 1.2336,
                    with_refunds: 0.9705,
                  },
                  gross_total: {
                    without_refunds: 1.3874,
                    with_refunds: 1.0953,
                  },
                  net_total_spread: {
                    without_refunds: 0.5792,
                    with_refunds: 0.5667,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6576,
                    with_refunds: 0.6439,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9754,
                    with_refunds: 0.9269,
                  },
                  gross_mrr: {
                    without_refunds: 1.1146,
                    with_refunds: 1.0609,
                  },
                  net_arr: {
                    without_refunds: 8.4993,
                    with_refunds: 2.5904,
                  },
                  gross_arr: {
                    without_refunds: 9.9573,
                    with_refunds: 3.0247,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7155,
                    with_refunds: 0.7155,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8082,
                    with_refunds: 0.8082,
                  },
                  net_total: {
                    without_refunds: 2.578,
                    with_refunds: 2.009,
                  },
                  gross_total: {
                    without_refunds: 2.915,
                    with_refunds: 2.2747,
                  },
                  net_total_spread: {
                    without_refunds: 0.8161,
                    with_refunds: 0.7974,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9269,
                    with_refunds: 0.9062,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 88,
                  with_refunds: 88,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1392,
                  with_refunds: 1392,
                },
                total_users: {
                  without_refunds: 89,
                  with_refunds: 89,
                },
                total_spread_users: {
                  without_refunds: 1480,
                  with_refunds: 1480,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0631,
                  with_refunds: 0.0631,
                },
                arr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0005,
                },
                arr_spread_users: {
                  without_refunds: 0.9936,
                  with_refunds: 0.9936,
                },
                total_users: {
                  without_refunds: 0.0273,
                  with_refunds: 0.0273,
                },
                total_spread_users: {
                  without_refunds: 0.5322,
                  with_refunds: 0.5322,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.193,
                  with_refunds: 0.1921,
                },
                arr_users: {
                  without_refunds: 0.5,
                  with_refunds: 0.25,
                },
                arr_spread_users: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                total_users: {
                  without_refunds: 0.1943,
                  with_refunds: 0.1926,
                },
                total_spread_users: {
                  without_refunds: 0.8013,
                  with_refunds: 0.8004,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1113.29,
                  with_refunds: 1113.29,
                },
                gross_mrr: {
                  without_refunds: 1266.55,
                  with_refunds: 1266.55,
                },
                net_arr: {
                  without_refunds: 98.1,
                  with_refunds: 98.1,
                },
                gross_arr: {
                  without_refunds: 124.94,
                  with_refunds: 124.94,
                },
                net_arr_spread: {
                  without_refunds: 13332.79,
                  with_refunds: 13332.79,
                },
                gross_arr_spread: {
                  without_refunds: 15041.48,
                  with_refunds: 15041.48,
                },
                net_total: {
                  without_refunds: 1211.39,
                  with_refunds: 1211.39,
                },
                gross_total: {
                  without_refunds: 1391.48,
                  with_refunds: 1391.48,
                },
                net_total_spread: {
                  without_refunds: 14446.08,
                  with_refunds: 14446.08,
                },
                gross_total_spread: {
                  without_refunds: 16308.02,
                  with_refunds: 16308.02,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0992,
                  with_refunds: 0.1026,
                },
                gross_mrr: {
                  without_refunds: 0.0983,
                  with_refunds: 0.1017,
                },
                net_arr: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0012,
                },
                gross_arr: {
                  without_refunds: 0.0012,
                  with_refunds: 0.0013,
                },
                net_arr_spread: {
                  without_refunds: 2.2824,
                  with_refunds: 2.2824,
                },
                gross_arr_spread: {
                  without_refunds: 2.2596,
                  with_refunds: 2.2596,
                },
                net_total: {
                  without_refunds: 0.0117,
                  with_refunds: 0.013,
                },
                gross_total: {
                  without_refunds: 0.0119,
                  with_refunds: 0.0132,
                },
                net_total_spread: {
                  without_refunds: 0.8465,
                  with_refunds: 0.8653,
                },
                gross_total_spread: {
                  without_refunds: 0.8348,
                  with_refunds: 0.8533,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.1903,
                  with_refunds: 0.197,
                },
                gross_mrr: {
                  without_refunds: 0.1904,
                  with_refunds: 0.1968,
                },
                net_arr: {
                  without_refunds: 0.4761,
                  with_refunds: 0.0012,
                },
                gross_arr: {
                  without_refunds: 0.5553,
                  with_refunds: 1.0397,
                },
                net_arr_spread: {
                  without_refunds: 1.0006,
                  with_refunds: 1.0006,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.2001,
                  with_refunds: 0.2144,
                },
                gross_total: {
                  without_refunds: 0.2023,
                  with_refunds: 0.2163,
                },
                net_total_spread: {
                  without_refunds: 0.7534,
                  with_refunds: 0.7613,
                },
                gross_total_spread: {
                  without_refunds: 0.752,
                  with_refunds: 0.7597,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.8,
                  with_refunds: 0.8,
                },
                gross_mrr: {
                  without_refunds: 0.91,
                  with_refunds: 0.91,
                },
                net_arr: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                gross_arr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                net_arr_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.52,
                },
                gross_arr_spread: {
                  without_refunds: 10.74,
                  with_refunds: 10.74,
                },
                net_total: {
                  without_refunds: 0.37,
                  with_refunds: 0.37,
                },
                gross_total: {
                  without_refunds: 0.43,
                  with_refunds: 0.43,
                },
                net_total_spread: {
                  without_refunds: 5.19,
                  with_refunds: 5.19,
                },
                gross_total_spread: {
                  without_refunds: 5.86,
                  with_refunds: 5.86,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.65,
                  with_refunds: 12.65,
                },
                gross_mrr: {
                  without_refunds: 14.39,
                  with_refunds: 14.39,
                },
                net_arr: {
                  without_refunds: 98.1,
                  with_refunds: 98.1,
                },
                gross_arr: {
                  without_refunds: 124.94,
                  with_refunds: 124.94,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 13.61,
                  with_refunds: 13.61,
                },
                gross_total: {
                  without_refunds: 15.63,
                  with_refunds: 15.63,
                },
                net_total_spread: {
                  without_refunds: 9.76,
                  with_refunds: 9.76,
                },
                gross_total_spread: {
                  without_refunds: 11.02,
                  with_refunds: 11.02,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.05,
                    with_refunds: 33.4,
                  },
                  gross_mrr: {
                    without_refunds: 40.08,
                    with_refunds: 38.27,
                  },
                  net_arr: {
                    without_refunds: 115.85,
                    with_refunds: 86.98,
                  },
                  gross_arr: {
                    without_refunds: 129.82,
                    with_refunds: 97.74,
                  },
                  net_arr_spread: {
                    without_refunds: 51.5,
                    with_refunds: 51.5,
                  },
                  gross_arr_spread: {
                    without_refunds: 58.12,
                    with_refunds: 58.12,
                  },
                  net_total: {
                    without_refunds: 82.01,
                    with_refunds: 64.6,
                  },
                  gross_total: {
                    without_refunds: 92.25,
                    with_refunds: 72.91,
                  },
                  net_total_spread: {
                    without_refunds: 43.53,
                    with_refunds: 42.7,
                  },
                  gross_total_spread: {
                    without_refunds: 49.38,
                    with_refunds: 48.48,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 77.21,
                    with_refunds: 73.99,
                  },
                  gross_mrr: {
                    without_refunds: 88.16,
                    with_refunds: 84.6,
                  },
                  net_arr: {
                    without_refunds: 660.59,
                    with_refunds: 269.53,
                  },
                  gross_arr: {
                    without_refunds: 783.91,
                    with_refunds: 325.11,
                  },
                  net_arr_spread: {
                    without_refunds: 56.93,
                    with_refunds: 56.93,
                  },
                  gross_arr_spread: {
                    without_refunds: 64.29,
                    with_refunds: 64.29,
                  },
                  net_total: {
                    without_refunds: 184.22,
                    with_refunds: 146.56,
                  },
                  gross_total: {
                    without_refunds: 208.55,
                    with_refunds: 166.17,
                  },
                  net_total_spread: {
                    without_refunds: 63.77,
                    with_refunds: 62.53,
                  },
                  gross_total_spread: {
                    without_refunds: 72.36,
                    with_refunds: 70.99,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5296,
                    with_refunds: 0.5047,
                  },
                  gross_mrr: {
                    without_refunds: 0.6057,
                    with_refunds: 0.5783,
                  },
                  net_arr: {
                    without_refunds: 1.7505,
                    with_refunds: 1.3142,
                  },
                  gross_arr: {
                    without_refunds: 1.9617,
                    with_refunds: 1.477,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7782,
                    with_refunds: 0.7782,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8782,
                    with_refunds: 0.8782,
                  },
                  net_total: {
                    without_refunds: 1.2392,
                    with_refunds: 0.9761,
                  },
                  gross_total: {
                    without_refunds: 1.3939,
                    with_refunds: 1.1017,
                  },
                  net_total_spread: {
                    without_refunds: 0.6577,
                    with_refunds: 0.6452,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7462,
                    with_refunds: 0.7325,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1666,
                    with_refunds: 1.1181,
                  },
                  gross_mrr: {
                    without_refunds: 1.3321,
                    with_refunds: 1.2784,
                  },
                  net_arr: {
                    without_refunds: 9.9817,
                    with_refunds: 4.0727,
                  },
                  gross_arr: {
                    without_refunds: 11.8451,
                    with_refunds: 4.9125,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8602,
                    with_refunds: 0.8602,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9715,
                    with_refunds: 0.9715,
                  },
                  net_total: {
                    without_refunds: 2.7837,
                    with_refunds: 2.2146,
                  },
                  gross_total: {
                    without_refunds: 3.1513,
                    with_refunds: 2.5109,
                  },
                  net_total_spread: {
                    without_refunds: 0.9636,
                    with_refunds: 0.9449,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0934,
                    with_refunds: 1.0727,
                  },
                },
              },
            },
          },
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 59.87,
                  with_refunds: 59.71,
                },
                arr_users: {
                  without_refunds: 0.19,
                  with_refunds: 0.49,
                },
                arr_spread_users: {
                  without_refunds: 1394.76,
                  with_refunds: 1394.76,
                },
                total_users: {
                  without_refunds: 60.32,
                  with_refunds: 60.1,
                },
                total_spread_users: {
                  without_refunds: 1314.82,
                  with_refunds: 1313.62,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0429,
                  with_refunds: 0.0428,
                },
                arr_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0003,
                },
                arr_spread_users: {
                  without_refunds: 0.9955,
                  with_refunds: 0.9955,
                },
                total_users: {
                  without_refunds: 0.0185,
                  with_refunds: 0.0184,
                },
                total_spread_users: {
                  without_refunds: 0.4728,
                  with_refunds: 0.4724,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6803,
                  with_refunds: 0.6785,
                },
                arr_users: {
                  without_refunds: 0.1905,
                  with_refunds: 0.4881,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6753,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8876,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 762.73,
                  with_refunds: 776.25,
                },
                gross_mrr: {
                  without_refunds: 866.12,
                  with_refunds: 879.98,
                },
                net_arr: {
                  without_refunds: 20.19,
                  with_refunds: 25.43,
                },
                gross_arr: {
                  without_refunds: 24.07,
                  with_refunds: 31.77,
                },
                net_arr_spread: {
                  without_refunds: 13364.39,
                  with_refunds: 13364.39,
                },
                gross_arr_spread: {
                  without_refunds: 15080.53,
                  with_refunds: 15080.53,
                },
                net_total: {
                  without_refunds: 807.82,
                  with_refunds: 836.03,
                },
                gross_total: {
                  without_refunds: 920.14,
                  with_refunds: 955.5,
                },
                net_total_spread: {
                  without_refunds: 12416.81,
                  with_refunds: 12503.14,
                },
                gross_total_spread: {
                  without_refunds: 14006.37,
                  with_refunds: 14096.25,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.068,
                  with_refunds: 0.0715,
                },
                gross_mrr: {
                  without_refunds: 0.0672,
                  with_refunds: 0.0707,
                },
                net_arr: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0003,
                },
                gross_arr: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0003,
                },
                net_arr_spread: {
                  without_refunds: 2.2878,
                  with_refunds: 2.2878,
                },
                gross_arr_spread: {
                  without_refunds: 2.2654,
                  with_refunds: 2.2654,
                },
                net_total: {
                  without_refunds: 0.0078,
                  with_refunds: 0.009,
                },
                gross_total: {
                  without_refunds: 0.0079,
                  with_refunds: 0.0091,
                },
                net_total_spread: {
                  without_refunds: 0.7276,
                  with_refunds: 0.749,
                },
                gross_total_spread: {
                  without_refunds: 0.7169,
                  with_refunds: 0.7376,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6851,
                  with_refunds: 0.6973,
                },
                gross_mrr: {
                  without_refunds: 0.6838,
                  with_refunds: 0.6948,
                },
                net_arr: {
                  without_refunds: 0.2058,
                  with_refunds: 0.2593,
                },
                gross_arr: {
                  without_refunds: 0.1926,
                  with_refunds: 0.2543,
                },
                net_arr_spread: {
                  without_refunds: 1.0024,
                  with_refunds: 1.0024,
                },
                gross_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                net_total: {
                  without_refunds: 0.6669,
                  with_refunds: 0.6901,
                },
                gross_total: {
                  without_refunds: 0.6613,
                  with_refunds: 0.6867,
                },
                net_total_spread: {
                  without_refunds: 0.8595,
                  with_refunds: 0.8655,
                },
                gross_total_spread: {
                  without_refunds: 0.8589,
                  with_refunds: 0.8644,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.55,
                  with_refunds: 0.56,
                },
                gross_mrr: {
                  without_refunds: 0.62,
                  with_refunds: 0.63,
                },
                net_arr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_arr: {
                  without_refunds: 0.01,
                  with_refunds: 0.02,
                },
                net_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                gross_arr_spread: {
                  without_refunds: 10.76,
                  with_refunds: 10.76,
                },
                net_total: {
                  without_refunds: 0.25,
                  with_refunds: 0.26,
                },
                gross_total: {
                  without_refunds: 0.28,
                  with_refunds: 0.29,
                },
                net_total_spread: {
                  without_refunds: 4.46,
                  with_refunds: 4.5,
                },
                gross_total_spread: {
                  without_refunds: 5.04,
                  with_refunds: 5.07,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.74,
                  with_refunds: 13,
                },
                gross_mrr: {
                  without_refunds: 14.47,
                  with_refunds: 14.74,
                },
                net_arr: {
                  without_refunds: 106,
                  with_refunds: 52.11,
                },
                gross_arr: {
                  without_refunds: 126.35,
                  with_refunds: 65.08,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 13.39,
                  with_refunds: 13.91,
                },
                gross_total: {
                  without_refunds: 15.25,
                  with_refunds: 15.9,
                },
                net_total_spread: {
                  without_refunds: 9.44,
                  with_refunds: 9.52,
                },
                gross_total_spread: {
                  without_refunds: 10.65,
                  with_refunds: 10.73,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.6,
                    with_refunds: 33.96,
                  },
                  gross_mrr: {
                    without_refunds: 40.7,
                    with_refunds: 38.9,
                  },
                  net_arr: {
                    without_refunds: 115.86,
                    with_refunds: 86.99,
                  },
                  gross_arr: {
                    without_refunds: 129.83,
                    with_refunds: 97.76,
                  },
                  net_arr_spread: {
                    without_refunds: 61.04,
                    with_refunds: 61.04,
                  },
                  gross_arr_spread: {
                    without_refunds: 68.88,
                    with_refunds: 68.88,
                  },
                  net_total: {
                    without_refunds: 82.26,
                    with_refunds: 64.85,
                  },
                  gross_total: {
                    without_refunds: 92.53,
                    with_refunds: 73.2,
                  },
                  net_total_spread: {
                    without_refunds: 47.99,
                    with_refunds: 47.2,
                  },
                  gross_total_spread: {
                    without_refunds: 54.42,
                    with_refunds: 53.55,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 89.95,
                    with_refunds: 86.99,
                  },
                  gross_mrr: {
                    without_refunds: 102.63,
                    with_refunds: 99.34,
                  },
                  net_arr: {
                    without_refunds: 766.58,
                    with_refunds: 321.64,
                  },
                  gross_arr: {
                    without_refunds: 910.26,
                    with_refunds: 390.19,
                  },
                  net_arr_spread: {
                    without_refunds: 66.51,
                    with_refunds: 66.51,
                  },
                  gross_arr_spread: {
                    without_refunds: 75.11,
                    with_refunds: 75.11,
                  },
                  net_total: {
                    without_refunds: 197.61,
                    with_refunds: 160.47,
                  },
                  gross_total: {
                    without_refunds: 223.81,
                    with_refunds: 182.07,
                  },
                  net_total_spread: {
                    without_refunds: 73.22,
                    with_refunds: 72.05,
                  },
                  gross_total_spread: {
                    without_refunds: 83.01,
                    with_refunds: 81.72,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5379,
                    with_refunds: 0.5131,
                  },
                  gross_mrr: {
                    without_refunds: 0.6151,
                    with_refunds: 0.5879,
                  },
                  net_arr: {
                    without_refunds: 1.7506,
                    with_refunds: 1.3144,
                  },
                  gross_arr: {
                    without_refunds: 1.9618,
                    with_refunds: 1.4772,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9223,
                    with_refunds: 0.9223,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0408,
                    with_refunds: 1.0408,
                  },
                  net_total: {
                    without_refunds: 1.2429,
                    with_refunds: 0.98,
                  },
                  gross_total: {
                    without_refunds: 1.3981,
                    with_refunds: 1.1061,
                  },
                  net_total_spread: {
                    without_refunds: 0.7252,
                    with_refunds: 0.7131,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8223,
                    with_refunds: 0.8091,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3591,
                    with_refunds: 1.3145,
                  },
                  gross_mrr: {
                    without_refunds: 1.5507,
                    with_refunds: 1.5011,
                  },
                  net_arr: {
                    without_refunds: 11.5833,
                    with_refunds: 4.8601,
                  },
                  gross_arr: {
                    without_refunds: 13.7543,
                    with_refunds: 5.8959,
                  },
                  net_arr_spread: {
                    without_refunds: 1.005,
                    with_refunds: 1.005,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1349,
                    with_refunds: 1.1349,
                  },
                  net_total: {
                    without_refunds: 2.986,
                    with_refunds: 2.4248,
                  },
                  gross_total: {
                    without_refunds: 3.3818,
                    with_refunds: 2.7512,
                  },
                  net_total_spread: {
                    without_refunds: 1.1063,
                    with_refunds: 1.0887,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2543,
                    with_refunds: 1.2348,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 37.48,
                  with_refunds: 37.42,
                },
                arr_users: {
                  without_refunds: 0.02,
                  with_refunds: 0.06,
                },
                arr_spread_users: {
                  without_refunds: 1395.46,
                  with_refunds: 1395.46,
                },
                total_users: {
                  without_refunds: 37.37,
                  with_refunds: 37.24,
                },
                total_spread_users: {
                  without_refunds: 1177.93,
                  with_refunds: 1176.34,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0269,
                  with_refunds: 0.0268,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.996,
                  with_refunds: 0.996,
                },
                total_users: {
                  without_refunds: 0.0114,
                  with_refunds: 0.0114,
                },
                total_spread_users: {
                  without_refunds: 0.4236,
                  with_refunds: 0.423,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.626,
                  with_refunds: 0.6268,
                },
                arr_users: {
                  without_refunds: 0.0833,
                  with_refunds: 0.1167,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                total_spread_users: {
                  without_refunds: 0.8959,
                  with_refunds: 0.8955,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 481.93,
                  with_refunds: 485.74,
                },
                gross_mrr: {
                  without_refunds: 547.31,
                  with_refunds: 551.61,
                },
                net_arr: {
                  without_refunds: 1.33,
                  with_refunds: 1.18,
                },
                gross_arr: {
                  without_refunds: 1.81,
                  with_refunds: 1.66,
                },
                net_arr_spread: {
                  without_refunds: 13371.37,
                  with_refunds: 13371.37,
                },
                gross_arr_spread: {
                  without_refunds: 15090.45,
                  with_refunds: 15090.45,
                },
                net_total: {
                  without_refunds: 459.77,
                  with_refunds: 473.75,
                },
                gross_total: {
                  without_refunds: 520.79,
                  with_refunds: 538.2,
                },
                net_total_spread: {
                  without_refunds: 10733.43,
                  with_refunds: 10798.82,
                },
                gross_total_spread: {
                  without_refunds: 12108.66,
                  with_refunds: 12181.9,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0429,
                  with_refunds: 0.0448,
                },
                gross_mrr: {
                  without_refunds: 0.0425,
                  with_refunds: 0.0443,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.289,
                  with_refunds: 2.289,
                },
                gross_arr_spread: {
                  without_refunds: 2.2669,
                  with_refunds: 2.2669,
                },
                net_total: {
                  without_refunds: 0.0044,
                  with_refunds: 0.0051,
                },
                gross_total: {
                  without_refunds: 0.0044,
                  with_refunds: 0.0051,
                },
                net_total_spread: {
                  without_refunds: 0.6289,
                  with_refunds: 0.6469,
                },
                gross_total_spread: {
                  without_refunds: 0.6198,
                  with_refunds: 0.6374,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6258,
                },
                gross_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6268,
                },
                net_arr: {
                  without_refunds: 0.0657,
                  with_refunds: 0.0466,
                },
                gross_arr: {
                  without_refunds: 0.0754,
                  with_refunds: 0.0523,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.5691,
                  with_refunds: 0.5667,
                },
                gross_total: {
                  without_refunds: 0.566,
                  with_refunds: 0.5633,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8637,
                },
                gross_total_spread: {
                  without_refunds: 0.8645,
                  with_refunds: 0.8642,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.35,
                  with_refunds: 0.35,
                },
                gross_mrr: {
                  without_refunds: 0.39,
                  with_refunds: 0.4,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.54,
                },
                gross_arr_spread: {
                  without_refunds: 10.77,
                  with_refunds: 10.77,
                },
                net_total: {
                  without_refunds: 0.14,
                  with_refunds: 0.15,
                },
                gross_total: {
                  without_refunds: 0.16,
                  with_refunds: 0.16,
                },
                net_total_spread: {
                  without_refunds: 3.86,
                  with_refunds: 3.88,
                },
                gross_total_spread: {
                  without_refunds: 4.35,
                  with_refunds: 4.38,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.86,
                  with_refunds: 12.98,
                },
                gross_mrr: {
                  without_refunds: 14.6,
                  with_refunds: 14.74,
                },
                net_arr: {
                  without_refunds: 83.62,
                  with_refunds: 20.8,
                },
                gross_arr: {
                  without_refunds: 114.28,
                  with_refunds: 29.19,
                },
                net_arr_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.58,
                },
                gross_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                net_total: {
                  without_refunds: 12.3,
                  with_refunds: 12.72,
                },
                gross_total: {
                  without_refunds: 13.93,
                  with_refunds: 14.45,
                },
                net_total_spread: {
                  without_refunds: 9.11,
                  with_refunds: 9.18,
                },
                gross_total_spread: {
                  without_refunds: 10.28,
                  with_refunds: 10.36,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.94,
                    with_refunds: 34.31,
                  },
                  gross_mrr: {
                    without_refunds: 41.1,
                    with_refunds: 39.3,
                  },
                  net_arr: {
                    without_refunds: 115.86,
                    with_refunds: 86.99,
                  },
                  gross_arr: {
                    without_refunds: 129.84,
                    with_refunds: 97.76,
                  },
                  net_arr_spread: {
                    without_refunds: 70.58,
                    with_refunds: 70.58,
                  },
                  gross_arr_spread: {
                    without_refunds: 79.65,
                    with_refunds: 79.65,
                  },
                  net_total: {
                    without_refunds: 82.4,
                    with_refunds: 65,
                  },
                  gross_total: {
                    without_refunds: 92.69,
                    with_refunds: 73.37,
                  },
                  net_total_spread: {
                    without_refunds: 51.85,
                    with_refunds: 51.08,
                  },
                  gross_total_spread: {
                    without_refunds: 58.77,
                    with_refunds: 57.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 102.81,
                    with_refunds: 99.97,
                  },
                  gross_mrr: {
                    without_refunds: 117.23,
                    with_refunds: 114.08,
                  },
                  net_arr: {
                    without_refunds: 850.2,
                    with_refunds: 342.44,
                  },
                  gross_arr: {
                    without_refunds: 1024.53,
                    with_refunds: 419.38,
                  },
                  net_arr_spread: {
                    without_refunds: 76.09,
                    with_refunds: 76.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.92,
                    with_refunds: 85.92,
                  },
                  net_total: {
                    without_refunds: 209.92,
                    with_refunds: 173.2,
                  },
                  gross_total: {
                    without_refunds: 237.74,
                    with_refunds: 196.53,
                  },
                  net_total_spread: {
                    without_refunds: 82.33,
                    with_refunds: 81.23,
                  },
                  gross_total_spread: {
                    without_refunds: 93.29,
                    with_refunds: 92.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5431,
                    with_refunds: 0.5184,
                  },
                  gross_mrr: {
                    without_refunds: 0.621,
                    with_refunds: 0.5938,
                  },
                  net_arr: {
                    without_refunds: 1.7506,
                    with_refunds: 1.3144,
                  },
                  gross_arr: {
                    without_refunds: 1.9619,
                    with_refunds: 1.4772,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0666,
                    with_refunds: 1.0666,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2036,
                    with_refunds: 1.2036,
                  },
                  net_total: {
                    without_refunds: 1.2451,
                    with_refunds: 0.9822,
                  },
                  gross_total: {
                    without_refunds: 1.4006,
                    with_refunds: 1.1086,
                  },
                  net_total_spread: {
                    without_refunds: 0.7835,
                    with_refunds: 0.7718,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8881,
                    with_refunds: 0.8753,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.5534,
                    with_refunds: 1.5106,
                  },
                  gross_mrr: {
                    without_refunds: 1.7714,
                    with_refunds: 1.7238,
                  },
                  net_arr: {
                    without_refunds: 12.8469,
                    with_refunds: 5.1744,
                  },
                  gross_arr: {
                    without_refunds: 15.481,
                    with_refunds: 6.337,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1498,
                    with_refunds: 1.1498,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2983,
                    with_refunds: 1.2983,
                  },
                  net_total: {
                    without_refunds: 3.1719,
                    with_refunds: 2.6171,
                  },
                  gross_total: {
                    without_refunds: 3.5923,
                    with_refunds: 2.9696,
                  },
                  net_total_spread: {
                    without_refunds: 1.244,
                    with_refunds: 1.2274,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4097,
                    with_refunds: 1.3913,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 24.71,
                  with_refunds: 24.7,
                },
                arr_users: {
                  with_refunds: 0.02,
                },
                arr_spread_users: {
                  without_refunds: 1398.24,
                  with_refunds: 1398.24,
                },
                total_users: {
                  without_refunds: 24.61,
                  with_refunds: 24.52,
                },
                total_spread_users: {
                  without_refunds: 1068.2,
                  with_refunds: 1066.81,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0177,
                  with_refunds: 0.0177,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.998,
                  with_refunds: 0.998,
                },
                total_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0075,
                },
                total_spread_users: {
                  without_refunds: 0.3841,
                  with_refunds: 0.3836,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {
                  without_refunds: 0.2778,
                  with_refunds: 0.2778,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 320.03,
                  with_refunds: 321.95,
                },
                gross_mrr: {
                  without_refunds: 363.14,
                  with_refunds: 365.32,
                },
                net_arr: {
                  without_refunds: 0.38,
                  with_refunds: 0.39,
                },
                gross_arr: {
                  without_refunds: 0.52,
                  with_refunds: 0.55,
                },
                net_arr_spread: {
                  without_refunds: 13404.56,
                  with_refunds: 13404.56,
                },
                gross_arr_spread: {
                  without_refunds: 15131.55,
                  with_refunds: 15131.55,
                },
                net_total: {
                  without_refunds: 302.48,
                  with_refunds: 314.51,
                },
                gross_total: {
                  without_refunds: 341.71,
                  with_refunds: 356.92,
                },
                net_total_spread: {
                  without_refunds: 9423.55,
                  with_refunds: 9483.15,
                },
                gross_total_spread: {
                  without_refunds: 10618.22,
                  with_refunds: 10684.96,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0285,
                  with_refunds: 0.0297,
                },
                gross_mrr: {
                  without_refunds: 0.0282,
                  with_refunds: 0.0293,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.2947,
                  with_refunds: 2.2947,
                },
                gross_arr_spread: {
                  without_refunds: 2.2731,
                  with_refunds: 2.2731,
                },
                net_total: {
                  without_refunds: 0.0029,
                  with_refunds: 0.0034,
                },
                gross_total: {
                  without_refunds: 0.0029,
                  with_refunds: 0.0034,
                },
                net_total_spread: {
                  without_refunds: 0.5522,
                  with_refunds: 0.5681,
                },
                gross_total_spread: {
                  without_refunds: 0.5435,
                  with_refunds: 0.5591,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {
                  without_refunds: 0.2848,
                  with_refunds: 0.3283,
                },
                gross_arr: {
                  without_refunds: 0.285,
                  with_refunds: 0.3284,
                },
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.23,
                  with_refunds: 0.23,
                },
                gross_mrr: {
                  without_refunds: 0.26,
                  with_refunds: 0.26,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 9.57,
                  with_refunds: 9.57,
                },
                gross_arr_spread: {
                  without_refunds: 10.8,
                  with_refunds: 10.8,
                },
                net_total: {
                  without_refunds: 0.09,
                  with_refunds: 0.1,
                },
                gross_total: {
                  without_refunds: 0.1,
                  with_refunds: 0.11,
                },
                net_total_spread: {
                  without_refunds: 3.39,
                  with_refunds: 3.41,
                },
                gross_total_spread: {
                  without_refunds: 3.82,
                  with_refunds: 3.84,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.95,
                  with_refunds: 13.04,
                },
                gross_mrr: {
                  without_refunds: 14.7,
                  with_refunds: 14.79,
                },
                net_arr: {
                  without_refunds: 85.73,
                  with_refunds: 24.59,
                },
                gross_arr: {
                  without_refunds: 117.23,
                  with_refunds: 34.51,
                },
                net_arr_spread: {
                  without_refunds: 9.59,
                  with_refunds: 9.59,
                },
                gross_arr_spread: {
                  without_refunds: 10.82,
                  with_refunds: 10.82,
                },
                net_total: {
                  without_refunds: 12.29,
                  with_refunds: 12.82,
                },
                gross_total: {
                  without_refunds: 13.88,
                  with_refunds: 14.55,
                },
                net_total_spread: {
                  without_refunds: 8.82,
                  with_refunds: 8.89,
                },
                gross_total_spread: {
                  without_refunds: 9.94,
                  with_refunds: 10.02,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.17,
                    with_refunds: 34.54,
                  },
                  gross_mrr: {
                    without_refunds: 41.36,
                    with_refunds: 39.56,
                  },
                  net_arr: {
                    without_refunds: 115.86,
                    with_refunds: 86.99,
                  },
                  gross_arr: {
                    without_refunds: 129.84,
                    with_refunds: 97.76,
                  },
                  net_arr_spread: {
                    without_refunds: 80.15,
                    with_refunds: 80.15,
                  },
                  gross_arr_spread: {
                    without_refunds: 90.45,
                    with_refunds: 90.45,
                  },
                  net_total: {
                    without_refunds: 82.49,
                    with_refunds: 65.1,
                  },
                  gross_total: {
                    without_refunds: 92.79,
                    with_refunds: 73.48,
                  },
                  net_total_spread: {
                    without_refunds: 55.24,
                    with_refunds: 54.49,
                  },
                  gross_total_spread: {
                    without_refunds: 62.59,
                    with_refunds: 61.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 115.76,
                    with_refunds: 113.01,
                  },
                  gross_mrr: {
                    without_refunds: 131.93,
                    with_refunds: 128.87,
                  },
                  net_arr: {
                    without_refunds: 935.93,
                    with_refunds: 367.03,
                  },
                  gross_arr: {
                    without_refunds: 1141.76,
                    with_refunds: 453.89,
                  },
                  net_arr_spread: {
                    without_refunds: 85.68,
                    with_refunds: 85.68,
                  },
                  gross_arr_spread: {
                    without_refunds: 96.74,
                    with_refunds: 96.74,
                  },
                  net_total: {
                    without_refunds: 222.2,
                    with_refunds: 186.02,
                  },
                  gross_total: {
                    without_refunds: 251.62,
                    with_refunds: 211.08,
                  },
                  net_total_spread: {
                    without_refunds: 91.15,
                    with_refunds: 90.12,
                  },
                  gross_total_spread: {
                    without_refunds: 103.23,
                    with_refunds: 102.09,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5466,
                    with_refunds: 0.5219,
                  },
                  gross_mrr: {
                    without_refunds: 0.6249,
                    with_refunds: 0.5978,
                  },
                  net_arr: {
                    without_refunds: 1.7506,
                    with_refunds: 1.3144,
                  },
                  gross_arr: {
                    without_refunds: 1.9619,
                    with_refunds: 1.4772,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2111,
                    with_refunds: 1.2111,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3668,
                    with_refunds: 1.3668,
                  },
                  net_total: {
                    without_refunds: 1.2465,
                    with_refunds: 0.9836,
                  },
                  gross_total: {
                    without_refunds: 1.4021,
                    with_refunds: 1.1103,
                  },
                  net_total_spread: {
                    without_refunds: 0.8347,
                    with_refunds: 0.8233,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9458,
                    with_refunds: 0.9333,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.7491,
                    with_refunds: 1.7076,
                  },
                  gross_mrr: {
                    without_refunds: 1.9934,
                    with_refunds: 1.9473,
                  },
                  net_arr: {
                    without_refunds: 14.1422,
                    with_refunds: 5.5459,
                  },
                  gross_arr: {
                    without_refunds: 17.2524,
                    with_refunds: 6.8584,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2946,
                    with_refunds: 1.2946,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4618,
                    with_refunds: 1.4618,
                  },
                  net_total: {
                    without_refunds: 3.3576,
                    with_refunds: 2.8108,
                  },
                  gross_total: {
                    without_refunds: 3.8021,
                    with_refunds: 3.1895,
                  },
                  net_total_spread: {
                    without_refunds: 1.3773,
                    with_refunds: 1.3617,
                  },
                  gross_total_spread: {
                    without_refunds: 1.5599,
                    with_refunds: 1.5426,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 16.39,
                  with_refunds: 16.39,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1402.2,
                  with_refunds: 1402.2,
                },
                total_users: {
                  without_refunds: 16.25,
                  with_refunds: 16.2,
                },
                total_spread_users: {
                  without_refunds: 949.03,
                  with_refunds: 947.73,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0117,
                  with_refunds: 0.0118,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                total_users: {
                  without_refunds: 0.005,
                  with_refunds: 0.005,
                },
                total_spread_users: {
                  without_refunds: 0.3413,
                  with_refunds: 0.3408,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {
                  without_refunds: 0.2222,
                  with_refunds: 0.2222,
                },
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 212.13,
                  with_refunds: 214.27,
                },
                gross_mrr: {
                  without_refunds: 240.26,
                  with_refunds: 242.62,
                },
                net_arr: {
                  without_refunds: 0.08,
                  with_refunds: 0.08,
                },
                gross_arr: {
                  without_refunds: 0.11,
                  with_refunds: 0.11,
                },
                net_arr_spread: {
                  without_refunds: 13460.43,
                  with_refunds: 13460.43,
                },
                gross_arr_spread: {
                  without_refunds: 15188.62,
                  with_refunds: 15188.62,
                },
                net_total: {
                  without_refunds: 191.06,
                  with_refunds: 199.73,
                },
                gross_total: {
                  without_refunds: 214.63,
                  with_refunds: 225.48,
                },
                net_total_spread: {
                  without_refunds: 8090.34,
                  with_refunds: 8169.3,
                },
                gross_total_spread: {
                  without_refunds: 9112.08,
                  with_refunds: 9198.24,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0189,
                  with_refunds: 0.0197,
                },
                gross_mrr: {
                  without_refunds: 0.0187,
                  with_refunds: 0.0195,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.3043,
                  with_refunds: 2.3043,
                },
                gross_arr_spread: {
                  without_refunds: 2.2817,
                  with_refunds: 2.2817,
                },
                net_total: {
                  without_refunds: 0.0018,
                  with_refunds: 0.0021,
                },
                gross_total: {
                  without_refunds: 0.0018,
                  with_refunds: 0.0021,
                },
                net_total_spread: {
                  without_refunds: 0.4741,
                  with_refunds: 0.4894,
                },
                gross_total_spread: {
                  without_refunds: 0.4664,
                  with_refunds: 0.4813,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {
                  without_refunds: 0.2001,
                  with_refunds: 0.2001,
                },
                gross_arr: {
                  without_refunds: 0.2041,
                  with_refunds: 0.2041,
                },
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.15,
                  with_refunds: 0.15,
                },
                gross_mrr: {
                  without_refunds: 0.17,
                  with_refunds: 0.17,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 9.61,
                  with_refunds: 9.61,
                },
                gross_arr_spread: {
                  without_refunds: 10.84,
                  with_refunds: 10.84,
                },
                net_total: {
                  without_refunds: 0.06,
                  with_refunds: 0.06,
                },
                gross_total: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                net_total_spread: {
                  without_refunds: 2.91,
                  with_refunds: 2.94,
                },
                gross_total_spread: {
                  without_refunds: 3.28,
                  with_refunds: 3.31,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.94,
                  with_refunds: 13.07,
                },
                gross_mrr: {
                  without_refunds: 14.66,
                  with_refunds: 14.8,
                },
                net_arr: {
                  without_refunds: 77.2,
                  with_refunds: 22.14,
                },
                gross_arr: {
                  without_refunds: 107.65,
                  with_refunds: 31.69,
                },
                net_arr_spread: {
                  without_refunds: 9.6,
                  with_refunds: 9.6,
                },
                gross_arr_spread: {
                  without_refunds: 10.83,
                  with_refunds: 10.83,
                },
                net_total: {
                  without_refunds: 11.76,
                  with_refunds: 12.33,
                },
                gross_total: {
                  without_refunds: 13.21,
                  with_refunds: 13.92,
                },
                net_total_spread: {
                  without_refunds: 8.52,
                  with_refunds: 8.62,
                },
                gross_total_spread: {
                  without_refunds: 9.6,
                  with_refunds: 9.71,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.32,
                    with_refunds: 34.69,
                  },
                  gross_mrr: {
                    without_refunds: 41.53,
                    with_refunds: 39.74,
                  },
                  net_arr: {
                    without_refunds: 115.86,
                    with_refunds: 86.99,
                  },
                  gross_arr: {
                    without_refunds: 129.84,
                    with_refunds: 97.76,
                  },
                  net_arr_spread: {
                    without_refunds: 89.76,
                    with_refunds: 89.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 101.29,
                    with_refunds: 101.29,
                  },
                  net_total: {
                    without_refunds: 82.55,
                    with_refunds: 65.16,
                  },
                  gross_total: {
                    without_refunds: 92.86,
                    with_refunds: 73.55,
                  },
                  net_total_spread: {
                    without_refunds: 58.15,
                    with_refunds: 57.43,
                  },
                  gross_total_spread: {
                    without_refunds: 65.87,
                    with_refunds: 65.08,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 128.7,
                    with_refunds: 126.08,
                  },
                  gross_mrr: {
                    without_refunds: 146.59,
                    with_refunds: 143.67,
                  },
                  net_arr: {
                    without_refunds: 1013.14,
                    with_refunds: 389.17,
                  },
                  gross_arr: {
                    without_refunds: 1249.41,
                    with_refunds: 485.58,
                  },
                  net_arr_spread: {
                    without_refunds: 95.28,
                    with_refunds: 95.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 107.57,
                    with_refunds: 107.57,
                  },
                  net_total: {
                    without_refunds: 233.97,
                    with_refunds: 198.35,
                  },
                  gross_total: {
                    without_refunds: 264.84,
                    with_refunds: 225,
                  },
                  net_total_spread: {
                    without_refunds: 99.67,
                    with_refunds: 98.74,
                  },
                  gross_total_spread: {
                    without_refunds: 112.83,
                    with_refunds: 111.8,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5489,
                    with_refunds: 0.5242,
                  },
                  gross_mrr: {
                    without_refunds: 0.6275,
                    with_refunds: 0.6004,
                  },
                  net_arr: {
                    without_refunds: 1.7506,
                    with_refunds: 1.3144,
                  },
                  gross_arr: {
                    without_refunds: 1.9619,
                    with_refunds: 1.4772,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3563,
                    with_refunds: 1.3563,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5306,
                    with_refunds: 1.5306,
                  },
                  net_total: {
                    without_refunds: 1.2473,
                    with_refunds: 0.9845,
                  },
                  gross_total: {
                    without_refunds: 1.4031,
                    with_refunds: 1.1113,
                  },
                  net_total_spread: {
                    without_refunds: 0.8786,
                    with_refunds: 0.8677,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9953,
                    with_refunds: 0.9833,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.9447,
                    with_refunds: 1.9051,
                  },
                  gross_mrr: {
                    without_refunds: 2.2149,
                    with_refunds: 2.1709,
                  },
                  net_arr: {
                    without_refunds: 15.3088,
                    with_refunds: 5.8805,
                  },
                  gross_arr: {
                    without_refunds: 18.879,
                    with_refunds: 7.3372,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4397,
                    with_refunds: 1.4397,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6255,
                    with_refunds: 1.6255,
                  },
                  net_total: {
                    without_refunds: 3.5353,
                    with_refunds: 2.9972,
                  },
                  gross_total: {
                    without_refunds: 4.0017,
                    with_refunds: 3.3998,
                  },
                  net_total_spread: {
                    without_refunds: 1.5061,
                    with_refunds: 1.492,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7049,
                    with_refunds: 1.6893,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 11.58,
                  with_refunds: 11.56,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1410.07,
                  with_refunds: 1410.07,
                },
                total_users: {
                  without_refunds: 11.48,
                  with_refunds: 11.43,
                },
                total_spread_users: {
                  without_refunds: 836.85,
                  with_refunds: 834.87,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0083,
                  with_refunds: 0.0083,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0065,
                  with_refunds: 1.0065,
                },
                total_users: {
                  without_refunds: 0.0035,
                  with_refunds: 0.0035,
                },
                total_spread_users: {
                  without_refunds: 0.3009,
                  with_refunds: 0.3002,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 149.67,
                  with_refunds: 151.88,
                },
                gross_mrr: {
                  without_refunds: 169.21,
                  with_refunds: 171.64,
                },
                net_arr: {
                  without_refunds: 0.05,
                  with_refunds: 0.06,
                },
                gross_arr: {
                  without_refunds: 0.07,
                  with_refunds: 0.08,
                },
                net_arr_spread: {
                  without_refunds: 13566.45,
                  with_refunds: 13566.45,
                },
                gross_arr_spread: {
                  without_refunds: 15290.45,
                  with_refunds: 15290.45,
                },
                net_total: {
                  without_refunds: 135.3,
                  with_refunds: 142.17,
                },
                gross_total: {
                  without_refunds: 151.71,
                  with_refunds: 160.17,
                },
                net_total_spread: {
                  without_refunds: 6901.59,
                  with_refunds: 6992.4,
                },
                gross_total_spread: {
                  without_refunds: 7786.93,
                  with_refunds: 7885.1,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0133,
                  with_refunds: 0.014,
                },
                gross_mrr: {
                  without_refunds: 0.0131,
                  with_refunds: 0.0138,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.3224,
                  with_refunds: 2.3224,
                },
                gross_arr_spread: {
                  without_refunds: 2.297,
                  with_refunds: 2.297,
                },
                net_total: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0015,
                },
                gross_total: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0015,
                },
                net_total_spread: {
                  without_refunds: 0.4044,
                  with_refunds: 0.4189,
                },
                gross_total_spread: {
                  without_refunds: 0.3986,
                  with_refunds: 0.4126,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {
                  without_refunds: 0.6705,
                  with_refunds: 0.7274,
                },
                gross_arr: {
                  without_refunds: 0.6582,
                  with_refunds: 0.7127,
                },
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.11,
                  with_refunds: 0.11,
                },
                gross_mrr: {
                  without_refunds: 0.12,
                  with_refunds: 0.12,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 9.68,
                  with_refunds: 9.68,
                },
                gross_arr_spread: {
                  without_refunds: 10.91,
                  with_refunds: 10.91,
                },
                net_total: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                gross_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                net_total_spread: {
                  without_refunds: 2.48,
                  with_refunds: 2.51,
                },
                gross_total_spread: {
                  without_refunds: 2.8,
                  with_refunds: 2.84,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.93,
                  with_refunds: 13.13,
                },
                gross_mrr: {
                  without_refunds: 14.61,
                  with_refunds: 14.84,
                },
                net_arr: {
                  without_refunds: 77.65,
                  with_refunds: 24.16,
                },
                gross_arr: {
                  without_refunds: 106.29,
                  with_refunds: 33.88,
                },
                net_arr_spread: {
                  without_refunds: 9.62,
                  with_refunds: 9.62,
                },
                gross_arr_spread: {
                  without_refunds: 10.84,
                  with_refunds: 10.84,
                },
                net_total: {
                  without_refunds: 11.78,
                  with_refunds: 12.44,
                },
                gross_total: {
                  without_refunds: 13.21,
                  with_refunds: 14.01,
                },
                net_total_spread: {
                  without_refunds: 8.25,
                  with_refunds: 8.38,
                },
                gross_total_spread: {
                  without_refunds: 9.31,
                  with_refunds: 9.44,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.43,
                    with_refunds: 34.8,
                  },
                  gross_mrr: {
                    without_refunds: 41.65,
                    with_refunds: 39.86,
                  },
                  net_arr: {
                    without_refunds: 115.86,
                    with_refunds: 86.99,
                  },
                  gross_arr: {
                    without_refunds: 129.84,
                    with_refunds: 97.76,
                  },
                  net_arr_spread: {
                    without_refunds: 99.44,
                    with_refunds: 99.44,
                  },
                  gross_arr_spread: {
                    without_refunds: 112.21,
                    with_refunds: 112.21,
                  },
                  net_total: {
                    without_refunds: 82.59,
                    with_refunds: 65.2,
                  },
                  gross_total: {
                    without_refunds: 92.91,
                    with_refunds: 73.6,
                  },
                  net_total_spread: {
                    without_refunds: 60.63,
                    with_refunds: 59.94,
                  },
                  gross_total_spread: {
                    without_refunds: 68.67,
                    with_refunds: 67.91,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 141.63,
                    with_refunds: 139.21,
                  },
                  gross_mrr: {
                    without_refunds: 161.2,
                    with_refunds: 158.52,
                  },
                  net_arr: {
                    without_refunds: 1090.78,
                    with_refunds: 413.33,
                  },
                  gross_arr: {
                    without_refunds: 1355.7,
                    with_refunds: 519.46,
                  },
                  net_arr_spread: {
                    without_refunds: 104.9,
                    with_refunds: 104.9,
                  },
                  gross_arr_spread: {
                    without_refunds: 118.42,
                    with_refunds: 118.42,
                  },
                  net_total: {
                    without_refunds: 245.75,
                    with_refunds: 210.79,
                  },
                  gross_total: {
                    without_refunds: 278.05,
                    with_refunds: 239.01,
                  },
                  net_total_spread: {
                    without_refunds: 107.92,
                    with_refunds: 107.12,
                  },
                  gross_total_spread: {
                    without_refunds: 122.14,
                    with_refunds: 121.24,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5505,
                    with_refunds: 0.5258,
                  },
                  gross_mrr: {
                    without_refunds: 0.6293,
                    with_refunds: 0.6023,
                  },
                  net_arr: {
                    without_refunds: 1.7506,
                    with_refunds: 1.3144,
                  },
                  gross_arr: {
                    without_refunds: 1.9619,
                    with_refunds: 1.4772,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5026,
                    with_refunds: 1.5026,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6955,
                    with_refunds: 1.6955,
                  },
                  net_total: {
                    without_refunds: 1.248,
                    with_refunds: 0.9852,
                  },
                  gross_total: {
                    without_refunds: 1.4038,
                    with_refunds: 1.1121,
                  },
                  net_total_spread: {
                    without_refunds: 0.9161,
                    with_refunds: 0.9057,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0376,
                    with_refunds: 1.0262,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.14,
                    with_refunds: 2.1036,
                  },
                  gross_mrr: {
                    without_refunds: 2.4358,
                    with_refunds: 2.3952,
                  },
                  net_arr: {
                    without_refunds: 16.4821,
                    with_refunds: 6.2455,
                  },
                  gross_arr: {
                    without_refunds: 20.485,
                    with_refunds: 7.8491,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5851,
                    with_refunds: 1.5851,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.7893,
                    with_refunds: 1.7893,
                  },
                  net_total: {
                    without_refunds: 3.7134,
                    with_refunds: 3.1851,
                  },
                  gross_total: {
                    without_refunds: 4.2014,
                    with_refunds: 3.6115,
                  },
                  net_total_spread: {
                    without_refunds: 1.6307,
                    with_refunds: 1.6185,
                  },
                  gross_total_spread: {
                    without_refunds: 1.8455,
                    with_refunds: 1.832,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 7.02,
                  with_refunds: 7.01,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1413.01,
                  with_refunds: 1413.01,
                },
                total_users: {
                  without_refunds: 6.94,
                  with_refunds: 6.9,
                },
                total_spread_users: {
                  without_refunds: 611.96,
                  with_refunds: 610.25,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.005,
                  with_refunds: 0.005,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                total_users: {
                  without_refunds: 0.0021,
                  with_refunds: 0.0021,
                },
                total_spread_users: {
                  without_refunds: 0.2201,
                  with_refunds: 0.2194,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 91.23,
                  with_refunds: 93.2,
                },
                gross_mrr: {
                  without_refunds: 103.39,
                  with_refunds: 105.58,
                },
                net_arr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_arr: {
                  without_refunds: 0.01,
                  with_refunds: 0.02,
                },
                net_arr_spread: {
                  without_refunds: 13606.03,
                  with_refunds: 13606.03,
                },
                gross_arr_spread: {
                  without_refunds: 15325.46,
                  with_refunds: 15325.46,
                },
                net_total: {
                  without_refunds: 79.77,
                  with_refunds: 85.51,
                },
                gross_total: {
                  without_refunds: 88.99,
                  with_refunds: 96.23,
                },
                net_total_spread: {
                  without_refunds: 4758.74,
                  with_refunds: 4847.74,
                },
                gross_total_spread: {
                  without_refunds: 5524.61,
                  with_refunds: 5623.45,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0081,
                  with_refunds: 0.0086,
                },
                gross_mrr: {
                  without_refunds: 0.008,
                  with_refunds: 0.0085,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.3292,
                  with_refunds: 2.3292,
                },
                gross_arr_spread: {
                  without_refunds: 2.3022,
                  with_refunds: 2.3022,
                },
                net_total: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0009,
                },
                gross_total: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0009,
                },
                net_total_spread: {
                  without_refunds: 0.2788,
                  with_refunds: 0.2904,
                },
                gross_total_spread: {
                  without_refunds: 0.2828,
                  with_refunds: 0.2942,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {
                  without_refunds: 0.155,
                  with_refunds: 0.2422,
                },
                gross_arr: {
                  without_refunds: 0.138,
                  with_refunds: 0.229,
                },
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                gross_mrr: {
                  without_refunds: 0.07,
                  with_refunds: 0.08,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 9.71,
                  with_refunds: 9.71,
                },
                gross_arr_spread: {
                  without_refunds: 10.94,
                  with_refunds: 10.94,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.03,
                },
                gross_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                net_total_spread: {
                  without_refunds: 1.71,
                  with_refunds: 1.74,
                },
                gross_total_spread: {
                  without_refunds: 1.99,
                  with_refunds: 2.02,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13,
                  with_refunds: 13.3,
                },
                gross_mrr: {
                  without_refunds: 14.73,
                  with_refunds: 15.07,
                },
                net_arr: {
                  without_refunds: 72.21,
                  with_refunds: 35.1,
                },
                gross_arr: {
                  without_refunds: 88,
                  with_refunds: 46.55,
                },
                net_arr_spread: {
                  without_refunds: 9.63,
                  with_refunds: 9.63,
                },
                gross_arr_spread: {
                  without_refunds: 10.85,
                  with_refunds: 10.85,
                },
                net_total: {
                  without_refunds: 11.5,
                  with_refunds: 12.39,
                },
                gross_total: {
                  without_refunds: 12.83,
                  with_refunds: 13.94,
                },
                net_total_spread: {
                  without_refunds: 7.78,
                  with_refunds: 7.94,
                },
                gross_total_spread: {
                  without_refunds: 9.03,
                  with_refunds: 9.21,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.5,
                    with_refunds: 34.87,
                  },
                  gross_mrr: {
                    without_refunds: 41.72,
                    with_refunds: 39.93,
                  },
                  net_arr: {
                    without_refunds: 115.86,
                    with_refunds: 86.99,
                  },
                  gross_arr: {
                    without_refunds: 129.84,
                    with_refunds: 97.76,
                  },
                  net_arr_spread: {
                    without_refunds: 109.16,
                    with_refunds: 109.16,
                  },
                  gross_arr_spread: {
                    without_refunds: 123.15,
                    with_refunds: 123.15,
                  },
                  net_total: {
                    without_refunds: 82.61,
                    with_refunds: 65.23,
                  },
                  gross_total: {
                    without_refunds: 92.93,
                    with_refunds: 73.63,
                  },
                  net_total_spread: {
                    without_refunds: 62.34,
                    with_refunds: 61.68,
                  },
                  gross_total_spread: {
                    without_refunds: 70.66,
                    with_refunds: 69.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 154.62,
                    with_refunds: 152.52,
                  },
                  gross_mrr: {
                    without_refunds: 175.93,
                    with_refunds: 173.58,
                  },
                  net_arr: {
                    without_refunds: 1162.99,
                    with_refunds: 448.43,
                  },
                  gross_arr: {
                    without_refunds: 1443.7,
                    with_refunds: 566,
                  },
                  net_arr_spread: {
                    without_refunds: 114.53,
                    with_refunds: 114.53,
                  },
                  gross_arr_spread: {
                    without_refunds: 129.26,
                    with_refunds: 129.26,
                  },
                  net_total: {
                    without_refunds: 257.25,
                    with_refunds: 223.18,
                  },
                  gross_total: {
                    without_refunds: 290.88,
                    with_refunds: 252.95,
                  },
                  net_total_spread: {
                    without_refunds: 115.7,
                    with_refunds: 115.06,
                  },
                  gross_total_spread: {
                    without_refunds: 131.17,
                    with_refunds: 130.46,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5515,
                    with_refunds: 0.5268,
                  },
                  gross_mrr: {
                    without_refunds: 0.6305,
                    with_refunds: 0.6034,
                  },
                  net_arr: {
                    without_refunds: 1.7506,
                    with_refunds: 1.3144,
                  },
                  gross_arr: {
                    without_refunds: 1.9619,
                    with_refunds: 1.4772,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6494,
                    with_refunds: 1.6494,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8608,
                    with_refunds: 1.8608,
                  },
                  net_total: {
                    without_refunds: 1.2483,
                    with_refunds: 0.9856,
                  },
                  gross_total: {
                    without_refunds: 1.4042,
                    with_refunds: 1.1125,
                  },
                  net_total_spread: {
                    without_refunds: 0.942,
                    with_refunds: 0.9321,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0676,
                    with_refunds: 1.0567,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.3364,
                    with_refunds: 2.3046,
                  },
                  gross_mrr: {
                    without_refunds: 2.6583,
                    with_refunds: 2.6229,
                  },
                  net_arr: {
                    without_refunds: 17.5731,
                    with_refunds: 6.7759,
                  },
                  gross_arr: {
                    without_refunds: 21.8147,
                    with_refunds: 8.5525,
                  },
                  net_arr_spread: {
                    without_refunds: 1.7306,
                    with_refunds: 1.7306,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9532,
                    with_refunds: 1.9532,
                  },
                  net_total: {
                    without_refunds: 3.8872,
                    with_refunds: 3.3723,
                  },
                  gross_total: {
                    without_refunds: 4.3953,
                    with_refunds: 3.8222,
                  },
                  net_total_spread: {
                    without_refunds: 1.7482,
                    with_refunds: 1.7386,
                  },
                  gross_total_spread: {
                    without_refunds: 1.982,
                    with_refunds: 1.9712,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2.11,
                  with_refunds: 2.1,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 480.78,
                  with_refunds: 480.78,
                },
                total_users: {
                  without_refunds: 2.08,
                  with_refunds: 2.07,
                },
                total_spread_users: {
                  without_refunds: 191.43,
                  with_refunds: 190.65,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0015,
                  with_refunds: 0.0015,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3432,
                  with_refunds: 0.3432,
                },
                total_users: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0006,
                },
                total_spread_users: {
                  without_refunds: 0.0688,
                  with_refunds: 0.0686,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 27.28,
                  with_refunds: 27.92,
                },
                gross_mrr: {
                  without_refunds: 30.74,
                  with_refunds: 31.44,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 5222.69,
                  with_refunds: 5222.69,
                },
                gross_arr_spread: {
                  without_refunds: 5501.06,
                  with_refunds: 5501.06,
                },
                net_total: {
                  without_refunds: 23.45,
                  with_refunds: 25.19,
                },
                gross_total: {
                  without_refunds: 25.98,
                  with_refunds: 28.14,
                },
                net_total_spread: {
                  without_refunds: 1503.02,
                  with_refunds: 1533.39,
                },
                gross_total_spread: {
                  without_refunds: 1727.77,
                  with_refunds: 1761,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0024,
                  with_refunds: 0.0026,
                },
                gross_mrr: {
                  without_refunds: 0.0024,
                  with_refunds: 0.0025,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.8941,
                  with_refunds: 0.8941,
                },
                gross_arr_spread: {
                  without_refunds: 0.8264,
                  with_refunds: 0.8264,
                },
                net_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0003,
                },
                gross_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0003,
                },
                net_total_spread: {
                  without_refunds: 0.0881,
                  with_refunds: 0.0919,
                },
                gross_total_spread: {
                  without_refunds: 0.0884,
                  with_refunds: 0.0921,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                gross_mrr: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 3.73,
                  with_refunds: 3.73,
                },
                gross_arr_spread: {
                  without_refunds: 3.93,
                  with_refunds: 3.93,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 0.54,
                  with_refunds: 0.55,
                },
                gross_total_spread: {
                  without_refunds: 0.62,
                  with_refunds: 0.63,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.93,
                  with_refunds: 13.28,
                },
                gross_mrr: {
                  without_refunds: 14.57,
                  with_refunds: 14.96,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10.86,
                  with_refunds: 10.86,
                },
                gross_arr_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.44,
                },
                net_total: {
                  without_refunds: 11.27,
                  with_refunds: 12.18,
                },
                gross_total: {
                  without_refunds: 12.49,
                  with_refunds: 13.62,
                },
                net_total_spread: {
                  without_refunds: 7.85,
                  with_refunds: 8.04,
                },
                gross_total_spread: {
                  without_refunds: 9.03,
                  with_refunds: 9.24,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.52,
                    with_refunds: 34.89,
                  },
                  gross_mrr: {
                    without_refunds: 41.75,
                    with_refunds: 39.96,
                  },
                  net_arr: {
                    without_refunds: 115.86,
                    with_refunds: 86.99,
                  },
                  gross_arr: {
                    without_refunds: 129.84,
                    with_refunds: 97.76,
                  },
                  net_arr_spread: {
                    without_refunds: 112.88,
                    with_refunds: 112.88,
                  },
                  gross_arr_spread: {
                    without_refunds: 127.07,
                    with_refunds: 127.07,
                  },
                  net_total: {
                    without_refunds: 82.62,
                    with_refunds: 65.23,
                  },
                  gross_total: {
                    without_refunds: 92.94,
                    with_refunds: 73.63,
                  },
                  net_total_spread: {
                    without_refunds: 62.88,
                    with_refunds: 62.24,
                  },
                  gross_total_spread: {
                    without_refunds: 71.28,
                    with_refunds: 70.57,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 167.56,
                    with_refunds: 165.8,
                  },
                  gross_mrr: {
                    without_refunds: 190.5,
                    with_refunds: 188.54,
                  },
                  net_arr: {
                    without_refunds: 1162.99,
                    with_refunds: 448.43,
                  },
                  gross_arr: {
                    without_refunds: 1443.7,
                    with_refunds: 566,
                  },
                  net_arr_spread: {
                    without_refunds: 125.39,
                    with_refunds: 125.39,
                  },
                  gross_arr_spread: {
                    without_refunds: 140.71,
                    with_refunds: 140.71,
                  },
                  net_total: {
                    without_refunds: 268.53,
                    with_refunds: 235.36,
                  },
                  gross_total: {
                    without_refunds: 303.37,
                    with_refunds: 266.57,
                  },
                  net_total_spread: {
                    without_refunds: 123.55,
                    with_refunds: 123.1,
                  },
                  gross_total_spread: {
                    without_refunds: 140.19,
                    with_refunds: 139.69,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5518,
                    with_refunds: 0.5271,
                  },
                  gross_mrr: {
                    without_refunds: 0.6308,
                    with_refunds: 0.6038,
                  },
                  net_arr: {
                    without_refunds: 1.7506,
                    with_refunds: 1.3144,
                  },
                  gross_arr: {
                    without_refunds: 1.9619,
                    with_refunds: 1.4772,
                  },
                  net_arr_spread: {
                    without_refunds: 1.7057,
                    with_refunds: 1.7057,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.9201,
                    with_refunds: 1.9201,
                  },
                  net_total: {
                    without_refunds: 1.2484,
                    with_refunds: 0.9857,
                  },
                  gross_total: {
                    without_refunds: 1.4044,
                    with_refunds: 1.1126,
                  },
                  net_total_spread: {
                    without_refunds: 0.9502,
                    with_refunds: 0.9404,
                  },
                  gross_total_spread: {
                    without_refunds: 1.077,
                    with_refunds: 1.0663,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.5318,
                    with_refunds: 2.5053,
                  },
                  gross_mrr: {
                    without_refunds: 2.8785,
                    with_refunds: 2.8489,
                  },
                  net_arr: {
                    without_refunds: 17.5731,
                    with_refunds: 6.7759,
                  },
                  gross_arr: {
                    without_refunds: 21.8147,
                    with_refunds: 8.5525,
                  },
                  net_arr_spread: {
                    without_refunds: 1.8947,
                    with_refunds: 1.8947,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.1261,
                    with_refunds: 2.1261,
                  },
                  net_total: {
                    without_refunds: 4.0575,
                    with_refunds: 3.5564,
                  },
                  gross_total: {
                    without_refunds: 4.584,
                    with_refunds: 4.0279,
                  },
                  net_total_spread: {
                    without_refunds: 1.8669,
                    with_refunds: 1.8601,
                  },
                  gross_total_spread: {
                    without_refunds: 2.1183,
                    with_refunds: 2.1108,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 872,
              with_refunds: 872,
            },
            returning_users: {
              without_refunds: 6671,
              with_refunds: 6706,
            },
            paying_users: {
              without_refunds: 7543,
              with_refunds: 7578,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 850,
              with_refunds: 850,
            },
            returning_users: {
              without_refunds: 888,
              with_refunds: 922,
            },
            paying_users: {
              without_refunds: 1738,
              with_refunds: 1772,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1719,
              with_refunds: 1719,
            },
            returning_users: {
              without_refunds: 7548,
              with_refunds: 7601,
            },
            paying_users: {
              without_refunds: 9267,
              with_refunds: 9320,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 48894.58,
                with_refunds: 46595.16,
              },
              gross_mrr: {
                without_refunds: 55916.02,
                with_refunds: 53391.38,
              },
              net_arr: {
                without_refunds: 218949.46,
                with_refunds: 164382.99,
              },
              gross_arr: {
                without_refunds: 245363.66,
                with_refunds: 184737.43,
              },
              net_arr_spread: {
                without_refunds: 72152.94,
                with_refunds: 72152.94,
              },
              gross_arr_spread: {
                without_refunds: 81421.94,
                with_refunds: 81421.94,
              },
              net_total: {
                without_refunds: 267844.04,
                with_refunds: 210978.15,
              },
              gross_total: {
                without_refunds: 301279.67,
                with_refunds: 238128.81,
              },
              net_total_spread: {
                without_refunds: 121047.52,
                with_refunds: 118748.1,
              },
              gross_total_spread: {
                without_refunds: 137337.96,
                with_refunds: 134813.32,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 50939.59,
                with_refunds: 48666.37,
              },
              gross_mrr: {
                without_refunds: 58236.18,
                with_refunds: 55739.56,
              },
              net_arr: {
                without_refunds: 218971.49,
                with_refunds: 164410.14,
              },
              gross_arr: {
                without_refunds: 245390.24,
                with_refunds: 184771.61,
              },
              net_arr_spread: {
                without_refunds: 158148.85,
                with_refunds: 158148.85,
              },
              gross_arr_spread: {
                without_refunds: 178030.06,
                with_refunds: 178030.06,
              },
              net_total: {
                without_refunds: 269843.69,
                with_refunds: 213055.02,
              },
              gross_total: {
                without_refunds: 303543.62,
                with_refunds: 240489.45,
              },
              net_total_spread: {
                without_refunds: 174874.99,
                with_refunds: 173076.04,
              },
              gross_total_spread: {
                without_refunds: 198222.6,
                with_refunds: 196244.22,
              },
            },
          },
        },
      },
      cac: 66.18,
      mrr_ua_spend: {
        without_refunds: 92321.1,
        with_refunds: 92321.1,
      },
      arr_ua_spend: {
        without_refunds: 125080.2,
        with_refunds: 125080.2,
      },
      arr_spread_ua_spend: {
        without_refunds: 92718.18,
        with_refunds: 92718.18,
      },
      total_ua_spend: {
        without_refunds: 216143.88,
        with_refunds: 216143.88,
      },
      total_spread_ua_spend: {
        without_refunds: 184046.58,
        with_refunds: 184046.58,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 3,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 421,
        with_refunds: 421,
      },
      first_period_arr_users: {
        without_refunds: 1951,
        with_refunds: 1951,
      },
      first_period_arr_spread_users: {
        without_refunds: 1471,
        with_refunds: 1471,
      },
      first_period_total_users: {
        without_refunds: 2362,
        with_refunds: 2362,
      },
      first_period_total_spread_users: {
        without_refunds: 1887,
        with_refunds: 1887,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 328,
                  with_refunds: 328,
                },
                arr_users: {
                  without_refunds: 1071,
                  with_refunds: 1071,
                },
                arr_spread_users: {
                  without_refunds: 770,
                  with_refunds: 770,
                },
                total_users: {
                  without_refunds: 1397,
                  with_refunds: 1397,
                },
                total_spread_users: {
                  without_refunds: 1097,
                  with_refunds: 1097,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7791,
                  with_refunds: 0.7791,
                },
                arr_users: {
                  without_refunds: 0.5489,
                  with_refunds: 0.5489,
                },
                arr_spread_users: {
                  without_refunds: 0.5235,
                  with_refunds: 0.5235,
                },
                total_users: {
                  without_refunds: 0.5914,
                  with_refunds: 0.5914,
                },
                total_spread_users: {
                  without_refunds: 0.5813,
                  with_refunds: 0.5813,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3689,
                  with_refunds: 3439.79,
                },
                gross_mrr: {
                  without_refunds: 4039.63,
                  with_refunds: 3769.65,
                },
                net_arr: {
                  without_refunds: 131949.58,
                  with_refunds: 104461.85,
                },
                gross_arr: {
                  without_refunds: 147789.71,
                  with_refunds: 117444.96,
                },
                net_arr_spread: {
                  without_refunds: 7799.79,
                  with_refunds: 7799.79,
                },
                gross_arr_spread: {
                  without_refunds: 8808.63,
                  with_refunds: 8808.63,
                },
                net_total: {
                  without_refunds: 135638.58,
                  with_refunds: 107901.64,
                },
                gross_total: {
                  without_refunds: 151829.35,
                  with_refunds: 121214.61,
                },
                net_total_spread: {
                  without_refunds: 11488.79,
                  with_refunds: 11239.58,
                },
                gross_total_spread: {
                  without_refunds: 12848.26,
                  with_refunds: 12578.28,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 8.76,
                  with_refunds: 8.17,
                },
                gross_mrr: {
                  without_refunds: 9.6,
                  with_refunds: 8.95,
                },
                net_arr: {
                  without_refunds: 67.63,
                  with_refunds: 53.54,
                },
                gross_arr: {
                  without_refunds: 75.75,
                  with_refunds: 60.2,
                },
                net_arr_spread: {
                  without_refunds: 5.3,
                  with_refunds: 5.3,
                },
                gross_arr_spread: {
                  without_refunds: 5.99,
                  with_refunds: 5.99,
                },
                net_total: {
                  without_refunds: 57.43,
                  with_refunds: 45.68,
                },
                gross_total: {
                  without_refunds: 64.28,
                  with_refunds: 51.32,
                },
                net_total_spread: {
                  without_refunds: 6.09,
                  with_refunds: 5.96,
                },
                gross_total_spread: {
                  without_refunds: 6.81,
                  with_refunds: 6.67,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.25,
                  with_refunds: 10.49,
                },
                gross_mrr: {
                  without_refunds: 12.32,
                  with_refunds: 11.49,
                },
                net_arr: {
                  without_refunds: 123.2,
                  with_refunds: 97.54,
                },
                gross_arr: {
                  without_refunds: 137.99,
                  with_refunds: 109.66,
                },
                net_arr_spread: {
                  without_refunds: 10.13,
                  with_refunds: 10.13,
                },
                gross_arr_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.44,
                },
                net_total: {
                  without_refunds: 97.09,
                  with_refunds: 77.24,
                },
                gross_total: {
                  without_refunds: 108.68,
                  with_refunds: 86.77,
                },
                net_total_spread: {
                  without_refunds: 10.47,
                  with_refunds: 10.25,
                },
                gross_total_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.47,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 8.76,
                    with_refunds: 8.17,
                  },
                  gross_mrr: {
                    without_refunds: 9.6,
                    with_refunds: 8.95,
                  },
                  net_arr: {
                    without_refunds: 67.63,
                    with_refunds: 53.54,
                  },
                  gross_arr: {
                    without_refunds: 75.75,
                    with_refunds: 60.2,
                  },
                  net_arr_spread: {
                    without_refunds: 5.3,
                    with_refunds: 5.3,
                  },
                  gross_arr_spread: {
                    without_refunds: 5.99,
                    with_refunds: 5.99,
                  },
                  net_total: {
                    without_refunds: 57.43,
                    with_refunds: 45.68,
                  },
                  gross_total: {
                    without_refunds: 64.28,
                    with_refunds: 51.32,
                  },
                  net_total_spread: {
                    without_refunds: 6.09,
                    with_refunds: 5.96,
                  },
                  gross_total_spread: {
                    without_refunds: 6.81,
                    with_refunds: 6.67,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 11.25,
                    with_refunds: 10.49,
                  },
                  gross_mrr: {
                    without_refunds: 12.32,
                    with_refunds: 11.49,
                  },
                  net_arr: {
                    without_refunds: 123.2,
                    with_refunds: 97.54,
                  },
                  gross_arr: {
                    without_refunds: 137.99,
                    with_refunds: 109.66,
                  },
                  net_arr_spread: {
                    without_refunds: 10.13,
                    with_refunds: 10.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 11.44,
                    with_refunds: 11.44,
                  },
                  net_total: {
                    without_refunds: 97.09,
                    with_refunds: 77.24,
                  },
                  gross_total: {
                    without_refunds: 108.68,
                    with_refunds: 86.77,
                  },
                  net_total_spread: {
                    without_refunds: 10.47,
                    with_refunds: 10.25,
                  },
                  gross_total_spread: {
                    without_refunds: 11.71,
                    with_refunds: 11.47,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0669,
                    with_refunds: 0.0624,
                  },
                  gross_mrr: {
                    without_refunds: 0.0733,
                    with_refunds: 0.0684,
                  },
                  net_arr: {
                    without_refunds: 0.5163,
                    with_refunds: 0.4088,
                  },
                  gross_arr: {
                    without_refunds: 0.5783,
                    with_refunds: 0.4596,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0405,
                    with_refunds: 0.0405,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0457,
                    with_refunds: 0.0457,
                  },
                  net_total: {
                    without_refunds: 0.4384,
                    with_refunds: 0.3487,
                  },
                  gross_total: {
                    without_refunds: 0.4907,
                    with_refunds: 0.3918,
                  },
                  net_total_spread: {
                    without_refunds: 0.0465,
                    with_refunds: 0.0455,
                  },
                  gross_total_spread: {
                    without_refunds: 0.052,
                    with_refunds: 0.0509,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0859,
                    with_refunds: 0.0801,
                  },
                  gross_mrr: {
                    without_refunds: 0.094,
                    with_refunds: 0.0877,
                  },
                  net_arr: {
                    without_refunds: 0.9405,
                    with_refunds: 0.7446,
                  },
                  gross_arr: {
                    without_refunds: 1.0535,
                    with_refunds: 0.8372,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0773,
                    with_refunds: 0.0773,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0873,
                    with_refunds: 0.0873,
                  },
                  net_total: {
                    without_refunds: 0.7412,
                    with_refunds: 0.5896,
                  },
                  gross_total: {
                    without_refunds: 0.8297,
                    with_refunds: 0.6624,
                  },
                  net_total_spread: {
                    without_refunds: 0.08,
                    with_refunds: 0.0782,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0894,
                    with_refunds: 0.0875,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 271,
                  with_refunds: 276,
                },
                arr_users: {
                  without_refunds: 862,
                  with_refunds: 932,
                },
                arr_spread_users: {
                  without_refunds: 1452,
                  with_refunds: 1452,
                },
                total_users: {
                  without_refunds: 1132,
                  with_refunds: 1207,
                },
                total_spread_users: {
                  without_refunds: 1723,
                  with_refunds: 1728,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6437,
                  with_refunds: 0.6556,
                },
                arr_users: {
                  without_refunds: 0.4418,
                  with_refunds: 0.4777,
                },
                arr_spread_users: {
                  without_refunds: 0.9871,
                  with_refunds: 0.9871,
                },
                total_users: {
                  without_refunds: 0.4793,
                  with_refunds: 0.511,
                },
                total_spread_users: {
                  without_refunds: 0.9131,
                  with_refunds: 0.9157,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8262,
                  with_refunds: 0.8415,
                },
                arr_users: {
                  without_refunds: 0.8049,
                  with_refunds: 0.8702,
                },
                arr_spread_users: {
                  without_refunds: 1.8857,
                  with_refunds: 1.8857,
                },
                total_users: {
                  without_refunds: 0.8103,
                  with_refunds: 0.864,
                },
                total_spread_users: {
                  without_refunds: 1.5706,
                  with_refunds: 1.5752,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3141.71,
                  with_refunds: 3018.84,
                },
                gross_mrr: {
                  without_refunds: 3506.29,
                  with_refunds: 3365.43,
                },
                net_arr: {
                  without_refunds: 120857.28,
                  with_refunds: 88945.07,
                },
                gross_arr: {
                  without_refunds: 133454.49,
                  with_refunds: 98651.39,
                },
                net_arr_spread: {
                  without_refunds: 15791.12,
                  with_refunds: 15791.12,
                },
                gross_arr_spread: {
                  without_refunds: 17645.47,
                  with_refunds: 17645.47,
                },
                net_total: {
                  without_refunds: 123998.99,
                  with_refunds: 91963.91,
                },
                gross_total: {
                  without_refunds: 136960.78,
                  with_refunds: 102016.82,
                },
                net_total_spread: {
                  without_refunds: 18932.83,
                  with_refunds: 18809.96,
                },
                gross_total_spread: {
                  without_refunds: 21151.76,
                  with_refunds: 21010.9,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.8516,
                  with_refunds: 0.8776,
                },
                gross_mrr: {
                  without_refunds: 0.868,
                  with_refunds: 0.8928,
                },
                net_arr: {
                  without_refunds: 0.9159,
                  with_refunds: 0.8515,
                },
                gross_arr: {
                  without_refunds: 0.903,
                  with_refunds: 0.84,
                },
                net_arr_spread: {
                  without_refunds: 2.0246,
                  with_refunds: 2.0246,
                },
                gross_arr_spread: {
                  without_refunds: 2.0032,
                  with_refunds: 2.0032,
                },
                net_total: {
                  without_refunds: 0.9142,
                  with_refunds: 0.8523,
                },
                gross_total: {
                  without_refunds: 0.9021,
                  with_refunds: 0.8416,
                },
                net_total_spread: {
                  without_refunds: 1.6479,
                  with_refunds: 1.6735,
                },
                gross_total_spread: {
                  without_refunds: 1.6463,
                  with_refunds: 1.6704,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8516,
                  with_refunds: 0.8776,
                },
                gross_mrr: {
                  without_refunds: 0.868,
                  with_refunds: 0.8928,
                },
                net_arr: {
                  without_refunds: 0.9159,
                  with_refunds: 0.8515,
                },
                gross_arr: {
                  without_refunds: 0.903,
                  with_refunds: 0.84,
                },
                net_arr_spread: {
                  without_refunds: 2.0246,
                  with_refunds: 2.0246,
                },
                gross_arr_spread: {
                  without_refunds: 2.0032,
                  with_refunds: 2.0032,
                },
                net_total: {
                  without_refunds: 0.9142,
                  with_refunds: 0.8523,
                },
                gross_total: {
                  without_refunds: 0.9021,
                  with_refunds: 0.8416,
                },
                net_total_spread: {
                  without_refunds: 1.6479,
                  with_refunds: 1.6735,
                },
                gross_total_spread: {
                  without_refunds: 1.6463,
                  with_refunds: 1.6704,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 7.46,
                  with_refunds: 7.17,
                },
                gross_mrr: {
                  without_refunds: 8.33,
                  with_refunds: 7.99,
                },
                net_arr: {
                  without_refunds: 61.95,
                  with_refunds: 45.59,
                },
                gross_arr: {
                  without_refunds: 68.4,
                  with_refunds: 50.56,
                },
                net_arr_spread: {
                  without_refunds: 10.73,
                  with_refunds: 10.73,
                },
                gross_arr_spread: {
                  without_refunds: 12,
                  with_refunds: 12,
                },
                net_total: {
                  without_refunds: 52.5,
                  with_refunds: 38.93,
                },
                gross_total: {
                  without_refunds: 57.99,
                  with_refunds: 43.19,
                },
                net_total_spread: {
                  without_refunds: 10.03,
                  with_refunds: 9.97,
                },
                gross_total_spread: {
                  without_refunds: 11.21,
                  with_refunds: 11.13,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.59,
                  with_refunds: 10.94,
                },
                gross_mrr: {
                  without_refunds: 12.94,
                  with_refunds: 12.19,
                },
                net_arr: {
                  without_refunds: 140.21,
                  with_refunds: 95.43,
                },
                gross_arr: {
                  without_refunds: 154.82,
                  with_refunds: 105.85,
                },
                net_arr_spread: {
                  without_refunds: 10.88,
                  with_refunds: 10.88,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 109.54,
                  with_refunds: 76.19,
                },
                gross_total: {
                  without_refunds: 120.99,
                  with_refunds: 84.52,
                },
                net_total_spread: {
                  without_refunds: 10.99,
                  with_refunds: 10.89,
                },
                gross_total_spread: {
                  without_refunds: 12.28,
                  with_refunds: 12.16,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 16.22,
                    with_refunds: 15.34,
                  },
                  gross_mrr: {
                    without_refunds: 17.92,
                    with_refunds: 16.95,
                  },
                  net_arr: {
                    without_refunds: 129.58,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 144.15,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 16.04,
                    with_refunds: 16.04,
                  },
                  gross_arr_spread: {
                    without_refunds: 17.98,
                    with_refunds: 17.98,
                  },
                  net_total: {
                    without_refunds: 109.92,
                    with_refunds: 84.62,
                  },
                  gross_total: {
                    without_refunds: 122.27,
                    with_refunds: 94.51,
                  },
                  net_total_spread: {
                    without_refunds: 16.12,
                    with_refunds: 15.92,
                  },
                  gross_total_spread: {
                    without_refunds: 18.02,
                    with_refunds: 17.8,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.84,
                    with_refunds: 21.42,
                  },
                  gross_mrr: {
                    without_refunds: 25.25,
                    with_refunds: 23.69,
                  },
                  net_arr: {
                    without_refunds: 263.41,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 292.81,
                    with_refunds: 215.51,
                  },
                  net_arr_spread: {
                    without_refunds: 21.01,
                    with_refunds: 21.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 23.59,
                    with_refunds: 23.59,
                  },
                  net_total: {
                    without_refunds: 206.63,
                    with_refunds: 153.43,
                  },
                  gross_total: {
                    without_refunds: 229.67,
                    with_refunds: 171.29,
                  },
                  net_total_spread: {
                    without_refunds: 21.46,
                    with_refunds: 21.13,
                  },
                  gross_total_spread: {
                    without_refunds: 23.99,
                    with_refunds: 23.63,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1239,
                    with_refunds: 0.1171,
                  },
                  gross_mrr: {
                    without_refunds: 0.1368,
                    with_refunds: 0.1294,
                  },
                  net_arr: {
                    without_refunds: 0.9892,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1005,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1224,
                    with_refunds: 0.1224,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1373,
                    with_refunds: 0.1373,
                  },
                  net_total: {
                    without_refunds: 0.8392,
                    with_refunds: 0.646,
                  },
                  gross_total: {
                    without_refunds: 0.9334,
                    with_refunds: 0.7215,
                  },
                  net_total_spread: {
                    without_refunds: 0.1231,
                    with_refunds: 0.1216,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1376,
                    with_refunds: 0.1359,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1744,
                    with_refunds: 0.1636,
                  },
                  gross_mrr: {
                    without_refunds: 0.1928,
                    with_refunds: 0.1808,
                  },
                  net_arr: {
                    without_refunds: 2.0109,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 2.2354,
                    with_refunds: 1.6452,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1604,
                    with_refunds: 0.1604,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1801,
                    with_refunds: 0.1801,
                  },
                  net_total: {
                    without_refunds: 1.5775,
                    with_refunds: 1.1713,
                  },
                  gross_total: {
                    without_refunds: 1.7534,
                    with_refunds: 1.3076,
                  },
                  net_total_spread: {
                    without_refunds: 0.1638,
                    with_refunds: 0.1613,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1831,
                    with_refunds: 0.1804,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 211,
                  with_refunds: 213,
                },
                arr_users: {
                  without_refunds: 17,
                  with_refunds: 41,
                },
                arr_spread_users: {
                  without_refunds: 1462,
                  with_refunds: 1462,
                },
                total_users: {
                  without_refunds: 228,
                  with_refunds: 254,
                },
                total_spread_users: {
                  without_refunds: 1673,
                  with_refunds: 1675,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5012,
                  with_refunds: 0.5059,
                },
                arr_users: {
                  without_refunds: 0.0087,
                  with_refunds: 0.021,
                },
                arr_spread_users: {
                  without_refunds: 0.9939,
                  with_refunds: 0.9939,
                },
                total_users: {
                  without_refunds: 0.0965,
                  with_refunds: 0.1075,
                },
                total_spread_users: {
                  without_refunds: 0.8866,
                  with_refunds: 0.8877,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7786,
                  with_refunds: 0.7717,
                },
                arr_users: {
                  without_refunds: 0.0197,
                  with_refunds: 0.044,
                },
                arr_spread_users: {
                  without_refunds: 1.0069,
                  with_refunds: 1.0069,
                },
                total_users: {
                  without_refunds: 0.2014,
                  with_refunds: 0.2104,
                },
                total_spread_users: {
                  without_refunds: 0.971,
                  with_refunds: 0.9693,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2595.96,
                  with_refunds: 2459.7,
                },
                gross_mrr: {
                  without_refunds: 2955.2,
                  with_refunds: 2789.39,
                },
                net_arr: {
                  without_refunds: 2256.74,
                },
                gross_arr: {
                  without_refunds: 2683.58,
                },
                net_arr_spread: {
                  without_refunds: 15887.05,
                  with_refunds: 15887.05,
                },
                gross_arr_spread: {
                  without_refunds: 17763.93,
                  with_refunds: 17763.93,
                },
                net_total: {
                  without_refunds: 4852.69,
                  with_refunds: 2459.7,
                },
                gross_total: {
                  without_refunds: 5638.78,
                  with_refunds: 2789.39,
                },
                net_total_spread: {
                  without_refunds: 18483.01,
                  with_refunds: 18346.75,
                },
                gross_total_spread: {
                  without_refunds: 20719.13,
                  with_refunds: 20553.32,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.7037,
                  with_refunds: 0.7151,
                },
                gross_mrr: {
                  without_refunds: 0.7316,
                  with_refunds: 0.74,
                },
                net_arr: {
                  without_refunds: 0.0171,
                },
                gross_arr: {
                  without_refunds: 0.0182,
                },
                net_arr_spread: {
                  without_refunds: 2.0369,
                  with_refunds: 2.0369,
                },
                gross_arr_spread: {
                  without_refunds: 2.0167,
                  with_refunds: 2.0167,
                },
                net_total: {
                  without_refunds: 0.0358,
                  with_refunds: 0.0228,
                },
                gross_total: {
                  without_refunds: 0.0371,
                  with_refunds: 0.023,
                },
                net_total_spread: {
                  without_refunds: 1.6088,
                  with_refunds: 1.6323,
                },
                gross_total_spread: {
                  without_refunds: 1.6126,
                  with_refunds: 1.634,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.8263,
                  with_refunds: 0.8148,
                },
                gross_mrr: {
                  without_refunds: 0.8428,
                  with_refunds: 0.8288,
                },
                net_arr: {
                  without_refunds: 0.0187,
                },
                gross_arr: {
                  without_refunds: 0.0201,
                },
                net_arr_spread: {
                  without_refunds: 1.0061,
                  with_refunds: 1.0061,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.0391,
                  with_refunds: 0.0267,
                },
                gross_total: {
                  without_refunds: 0.0412,
                  with_refunds: 0.0273,
                },
                net_total_spread: {
                  without_refunds: 0.9762,
                  with_refunds: 0.9754,
                },
                gross_total_spread: {
                  without_refunds: 0.9795,
                  with_refunds: 0.9782,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.17,
                  with_refunds: 5.84,
                },
                gross_mrr: {
                  without_refunds: 7.02,
                  with_refunds: 6.63,
                },
                net_arr: {
                  without_refunds: 1.16,
                },
                gross_arr: {
                  without_refunds: 1.38,
                },
                net_arr_spread: {
                  without_refunds: 10.8,
                  with_refunds: 10.8,
                },
                gross_arr_spread: {
                  without_refunds: 12.08,
                  with_refunds: 12.08,
                },
                net_total: {
                  without_refunds: 2.05,
                  with_refunds: 1.04,
                },
                gross_total: {
                  without_refunds: 2.39,
                  with_refunds: 1.18,
                },
                net_total_spread: {
                  without_refunds: 9.79,
                  with_refunds: 9.72,
                },
                gross_total_spread: {
                  without_refunds: 10.98,
                  with_refunds: 10.89,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.3,
                  with_refunds: 11.55,
                },
                gross_mrr: {
                  without_refunds: 14.01,
                  with_refunds: 13.1,
                },
                net_arr: {
                  without_refunds: 132.75,
                },
                gross_arr: {
                  without_refunds: 157.86,
                },
                net_arr_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.87,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 21.28,
                  with_refunds: 9.68,
                },
                gross_total: {
                  without_refunds: 24.73,
                  with_refunds: 10.98,
                },
                net_total_spread: {
                  without_refunds: 11.05,
                  with_refunds: 10.95,
                },
                gross_total_spread: {
                  without_refunds: 12.38,
                  with_refunds: 12.27,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.39,
                    with_refunds: 21.18,
                  },
                  gross_mrr: {
                    without_refunds: 24.94,
                    with_refunds: 23.57,
                  },
                  net_arr: {
                    without_refunds: 130.73,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.53,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 26.84,
                    with_refunds: 26.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.06,
                    with_refunds: 30.06,
                  },
                  net_total: {
                    without_refunds: 111.98,
                    with_refunds: 85.66,
                  },
                  gross_total: {
                    without_refunds: 124.65,
                    with_refunds: 95.69,
                  },
                  net_total_spread: {
                    without_refunds: 25.92,
                    with_refunds: 25.65,
                  },
                  gross_total_spread: {
                    without_refunds: 29,
                    with_refunds: 28.69,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 35.14,
                    with_refunds: 32.97,
                  },
                  gross_mrr: {
                    without_refunds: 39.26,
                    with_refunds: 36.78,
                  },
                  net_arr: {
                    without_refunds: 396.16,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 450.67,
                    with_refunds: 215.51,
                  },
                  net_arr_spread: {
                    without_refunds: 31.87,
                    with_refunds: 31.87,
                  },
                  gross_arr_spread: {
                    without_refunds: 35.74,
                    with_refunds: 35.74,
                  },
                  net_total: {
                    without_refunds: 227.92,
                    with_refunds: 163.11,
                  },
                  gross_total: {
                    without_refunds: 254.4,
                    with_refunds: 182.27,
                  },
                  net_total_spread: {
                    without_refunds: 32.51,
                    with_refunds: 32.08,
                  },
                  gross_total_spread: {
                    without_refunds: 36.37,
                    with_refunds: 35.9,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1709,
                    with_refunds: 0.1617,
                  },
                  gross_mrr: {
                    without_refunds: 0.1904,
                    with_refunds: 0.18,
                  },
                  net_arr: {
                    without_refunds: 0.9981,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.111,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2049,
                    with_refunds: 0.2049,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2295,
                    with_refunds: 0.2295,
                  },
                  net_total: {
                    without_refunds: 0.8549,
                    with_refunds: 0.6539,
                  },
                  gross_total: {
                    without_refunds: 0.9516,
                    with_refunds: 0.7305,
                  },
                  net_total_spread: {
                    without_refunds: 0.1979,
                    with_refunds: 0.1958,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2214,
                    with_refunds: 0.219,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2683,
                    with_refunds: 0.2517,
                  },
                  gross_mrr: {
                    without_refunds: 0.2997,
                    with_refunds: 0.2808,
                  },
                  net_arr: {
                    without_refunds: 3.0243,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 3.4405,
                    with_refunds: 1.6452,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2433,
                    with_refunds: 0.2433,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2729,
                    with_refunds: 0.2729,
                  },
                  net_total: {
                    without_refunds: 1.74,
                    with_refunds: 1.2452,
                  },
                  gross_total: {
                    without_refunds: 1.9422,
                    with_refunds: 1.3915,
                  },
                  net_total_spread: {
                    without_refunds: 0.2482,
                    with_refunds: 0.2449,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2777,
                    with_refunds: 0.274,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 172,
                  with_refunds: 173,
                },
                arr_users: {
                  without_refunds: 3,
                  with_refunds: 6,
                },
                arr_spread_users: {
                  without_refunds: 1464,
                  with_refunds: 1464,
                },
                total_users: {
                  without_refunds: 175,
                  with_refunds: 179,
                },
                total_spread_users: {
                  without_refunds: 1636,
                  with_refunds: 1637,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4086,
                  with_refunds: 0.4109,
                },
                arr_users: {
                  without_refunds: 0.0015,
                  with_refunds: 0.0031,
                },
                arr_spread_users: {
                  without_refunds: 0.9952,
                  with_refunds: 0.9952,
                },
                total_users: {
                  without_refunds: 0.0741,
                  with_refunds: 0.0758,
                },
                total_spread_users: {
                  without_refunds: 0.867,
                  with_refunds: 0.8675,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8152,
                  with_refunds: 0.8122,
                },
                arr_users: {
                  without_refunds: 0.1765,
                  with_refunds: 0.1463,
                },
                arr_spread_users: {
                  without_refunds: 1.0014,
                  with_refunds: 1.0014,
                },
                total_users: {
                  without_refunds: 0.7675,
                  with_refunds: 0.7047,
                },
                total_spread_users: {
                  without_refunds: 0.9779,
                  with_refunds: 0.9773,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2152.03,
                  with_refunds: 2130.02,
                },
                gross_mrr: {
                  without_refunds: 2410.43,
                  with_refunds: 2384.53,
                },
                net_arr: {
                  without_refunds: 298.92,
                },
                gross_arr: {
                  without_refunds: 401.08,
                  with_refunds: 5.34,
                },
                net_arr_spread: {
                  without_refunds: 15900.74,
                  with_refunds: 15900.74,
                },
                gross_arr_spread: {
                  without_refunds: 17785.77,
                  with_refunds: 17785.77,
                },
                net_total: {
                  without_refunds: 2450.95,
                  with_refunds: 2130.02,
                },
                gross_total: {
                  without_refunds: 2811.51,
                  with_refunds: 2389.87,
                },
                net_total_spread: {
                  without_refunds: 18052.78,
                  with_refunds: 18030.76,
                },
                gross_total_spread: {
                  without_refunds: 20196.2,
                  with_refunds: 20170.29,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5834,
                  with_refunds: 0.6192,
                },
                gross_mrr: {
                  without_refunds: 0.5967,
                  with_refunds: 0.6326,
                },
                net_arr: {
                  without_refunds: 0.0023,
                },
                gross_arr: {
                  without_refunds: 0.0027,
                },
                net_arr_spread: {
                  without_refunds: 2.0386,
                  with_refunds: 2.0386,
                },
                gross_arr_spread: {
                  without_refunds: 2.0191,
                  with_refunds: 2.0191,
                },
                net_total: {
                  without_refunds: 0.0181,
                  with_refunds: 0.0197,
                },
                gross_total: {
                  without_refunds: 0.0185,
                  with_refunds: 0.0197,
                },
                net_total_spread: {
                  without_refunds: 1.5713,
                  with_refunds: 1.6042,
                },
                gross_total_spread: {
                  without_refunds: 1.5719,
                  with_refunds: 1.6036,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.829,
                  with_refunds: 0.866,
                },
                gross_mrr: {
                  without_refunds: 0.8157,
                  with_refunds: 0.8549,
                },
                net_arr: {
                  without_refunds: 0.1325,
                },
                gross_arr: {
                  without_refunds: 0.1495,
                  with_refunds: 0.0001,
                },
                net_arr_spread: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                gross_arr_spread: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                net_total: {
                  without_refunds: 0.5051,
                  with_refunds: 0.866,
                },
                gross_total: {
                  without_refunds: 0.4986,
                  with_refunds: 0.8568,
                },
                net_total_spread: {
                  without_refunds: 0.9767,
                  with_refunds: 0.9828,
                },
                gross_total_spread: {
                  without_refunds: 0.9748,
                  with_refunds: 0.9814,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.11,
                  with_refunds: 5.06,
                },
                gross_mrr: {
                  without_refunds: 5.73,
                  with_refunds: 5.66,
                },
                net_arr: {
                  without_refunds: 0.15,
                },
                gross_arr: {
                  without_refunds: 0.21,
                },
                net_arr_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.81,
                },
                gross_arr_spread: {
                  without_refunds: 12.09,
                  with_refunds: 12.09,
                },
                net_total: {
                  without_refunds: 1.04,
                  with_refunds: 0.9,
                },
                gross_total: {
                  without_refunds: 1.19,
                  with_refunds: 1.01,
                },
                net_total_spread: {
                  without_refunds: 9.57,
                  with_refunds: 9.56,
                },
                gross_total_spread: {
                  without_refunds: 10.7,
                  with_refunds: 10.69,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.51,
                  with_refunds: 12.31,
                },
                gross_mrr: {
                  without_refunds: 14.01,
                  with_refunds: 13.78,
                },
                net_arr: {
                  without_refunds: 99.64,
                },
                gross_arr: {
                  without_refunds: 133.69,
                  with_refunds: 0.89,
                },
                net_arr_spread: {
                  without_refunds: 10.86,
                  with_refunds: 10.86,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 14.01,
                  with_refunds: 11.9,
                },
                gross_total: {
                  without_refunds: 16.07,
                  with_refunds: 13.35,
                },
                net_total_spread: {
                  without_refunds: 11.03,
                  with_refunds: 11.01,
                },
                gross_total_spread: {
                  without_refunds: 12.34,
                  with_refunds: 12.32,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.5,
                    with_refunds: 26.24,
                  },
                  gross_mrr: {
                    without_refunds: 30.67,
                    with_refunds: 29.24,
                  },
                  net_arr: {
                    without_refunds: 130.89,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.73,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 37.65,
                    with_refunds: 37.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.15,
                    with_refunds: 42.15,
                  },
                  net_total: {
                    without_refunds: 113.01,
                    with_refunds: 86.56,
                  },
                  gross_total: {
                    without_refunds: 125.84,
                    with_refunds: 96.7,
                  },
                  net_total_spread: {
                    without_refunds: 35.48,
                    with_refunds: 35.2,
                  },
                  gross_total_spread: {
                    without_refunds: 39.7,
                    with_refunds: 39.38,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 47.65,
                    with_refunds: 45.29,
                  },
                  gross_mrr: {
                    without_refunds: 53.27,
                    with_refunds: 50.57,
                  },
                  net_arr: {
                    without_refunds: 495.8,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 584.36,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 42.73,
                    with_refunds: 42.73,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.89,
                    with_refunds: 47.89,
                  },
                  net_total: {
                    without_refunds: 241.92,
                    with_refunds: 175.01,
                  },
                  gross_total: {
                    without_refunds: 270.47,
                    with_refunds: 195.62,
                  },
                  net_total_spread: {
                    without_refunds: 43.54,
                    with_refunds: 43.1,
                  },
                  gross_total_spread: {
                    without_refunds: 48.72,
                    with_refunds: 48.22,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.21,
                    with_refunds: 0.2003,
                  },
                  gross_mrr: {
                    without_refunds: 0.2341,
                    with_refunds: 0.2232,
                  },
                  net_arr: {
                    without_refunds: 0.9992,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1126,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2874,
                    with_refunds: 0.2874,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3218,
                    with_refunds: 0.3218,
                  },
                  net_total: {
                    without_refunds: 0.8628,
                    with_refunds: 0.6608,
                  },
                  gross_total: {
                    without_refunds: 0.9607,
                    with_refunds: 0.7382,
                  },
                  net_total_spread: {
                    without_refunds: 0.2709,
                    with_refunds: 0.2687,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3031,
                    with_refunds: 0.3006,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3638,
                    with_refunds: 0.3457,
                  },
                  gross_mrr: {
                    without_refunds: 0.4067,
                    with_refunds: 0.386,
                  },
                  net_arr: {
                    without_refunds: 3.785,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 4.4611,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.3262,
                    with_refunds: 0.3262,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3656,
                    with_refunds: 0.3656,
                  },
                  net_total: {
                    without_refunds: 1.8469,
                    with_refunds: 1.3361,
                  },
                  gross_total: {
                    without_refunds: 2.0648,
                    with_refunds: 1.4934,
                  },
                  net_total_spread: {
                    without_refunds: 0.3324,
                    with_refunds: 0.329,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3719,
                    with_refunds: 0.3681,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 15,
                  with_refunds: 15,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 1465,
                  with_refunds: 1465,
                },
                total_users: {
                  without_refunds: 16,
                  with_refunds: 17,
                },
                total_spread_users: {
                  without_refunds: 1480,
                  with_refunds: 1480,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0356,
                  with_refunds: 0.0356,
                },
                arr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.001,
                },
                arr_spread_users: {
                  without_refunds: 0.9959,
                  with_refunds: 0.9959,
                },
                total_users: {
                  without_refunds: 0.0068,
                  with_refunds: 0.0072,
                },
                total_spread_users: {
                  without_refunds: 0.7843,
                  with_refunds: 0.7843,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.0872,
                  with_refunds: 0.0867,
                },
                arr_users: {
                  without_refunds: 0.3333,
                  with_refunds: 0.3333,
                },
                arr_spread_users: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                total_users: {
                  without_refunds: 0.0914,
                  with_refunds: 0.095,
                },
                total_spread_users: {
                  without_refunds: 0.9046,
                  with_refunds: 0.9041,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 177.84,
                  with_refunds: 177.84,
                },
                gross_mrr: {
                  without_refunds: 218.16,
                  with_refunds: 218.16,
                },
                net_arr: {
                  without_refunds: 134.67,
                },
                gross_arr: {
                  without_refunds: 139,
                },
                net_arr_spread: {
                  without_refunds: 15911.96,
                  with_refunds: 15911.96,
                },
                gross_arr_spread: {
                  without_refunds: 17797.35,
                  with_refunds: 17797.35,
                },
                net_total: {
                  without_refunds: 312.51,
                  with_refunds: 177.84,
                },
                gross_total: {
                  without_refunds: 357.16,
                  with_refunds: 218.16,
                },
                net_total_spread: {
                  without_refunds: 16089.8,
                  with_refunds: 16089.8,
                },
                gross_total_spread: {
                  without_refunds: 18015.51,
                  with_refunds: 18015.51,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0482,
                  with_refunds: 0.0517,
                },
                gross_mrr: {
                  without_refunds: 0.054,
                  with_refunds: 0.0579,
                },
                net_arr: {
                  without_refunds: 0.001,
                },
                gross_arr: {
                  without_refunds: 0.0009,
                },
                net_arr_spread: {
                  without_refunds: 2.0401,
                  with_refunds: 2.0401,
                },
                gross_arr_spread: {
                  without_refunds: 2.0204,
                  with_refunds: 2.0204,
                },
                net_total: {
                  without_refunds: 0.0023,
                  with_refunds: 0.0016,
                },
                gross_total: {
                  without_refunds: 0.0024,
                  with_refunds: 0.0018,
                },
                net_total_spread: {
                  without_refunds: 1.4005,
                  with_refunds: 1.4315,
                },
                gross_total_spread: {
                  without_refunds: 1.4022,
                  with_refunds: 1.4323,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.0826,
                  with_refunds: 0.0835,
                },
                gross_mrr: {
                  without_refunds: 0.0905,
                  with_refunds: 0.0915,
                },
                net_arr: {
                  without_refunds: 0.4505,
                },
                gross_arr: {
                  without_refunds: 0.3466,
                },
                net_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.1275,
                  with_refunds: 0.0835,
                },
                gross_total: {
                  without_refunds: 0.127,
                  with_refunds: 0.0913,
                },
                net_total_spread: {
                  without_refunds: 0.8913,
                  with_refunds: 0.8924,
                },
                gross_total_spread: {
                  without_refunds: 0.892,
                  with_refunds: 0.8932,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.42,
                  with_refunds: 0.42,
                },
                gross_mrr: {
                  without_refunds: 0.52,
                  with_refunds: 0.52,
                },
                net_arr: {
                  without_refunds: 0.07,
                },
                gross_arr: {
                  without_refunds: 0.07,
                },
                net_arr_spread: {
                  without_refunds: 10.82,
                  with_refunds: 10.82,
                },
                gross_arr_spread: {
                  without_refunds: 12.1,
                  with_refunds: 12.1,
                },
                net_total: {
                  without_refunds: 0.13,
                  with_refunds: 0.08,
                },
                gross_total: {
                  without_refunds: 0.15,
                  with_refunds: 0.09,
                },
                net_total_spread: {
                  without_refunds: 8.53,
                  with_refunds: 8.53,
                },
                gross_total_spread: {
                  without_refunds: 9.55,
                  with_refunds: 9.55,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.86,
                  with_refunds: 11.86,
                },
                gross_mrr: {
                  without_refunds: 14.54,
                  with_refunds: 14.54,
                },
                net_arr: {
                  without_refunds: 134.67,
                },
                gross_arr: {
                  without_refunds: 139,
                },
                net_arr_spread: {
                  without_refunds: 10.86,
                  with_refunds: 10.86,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 19.53,
                  with_refunds: 10.46,
                },
                gross_total: {
                  without_refunds: 22.32,
                  with_refunds: 12.83,
                },
                net_total_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.87,
                },
                gross_total_spread: {
                  without_refunds: 12.17,
                  with_refunds: 12.17,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.93,
                    with_refunds: 26.67,
                  },
                  gross_mrr: {
                    without_refunds: 31.19,
                    with_refunds: 29.76,
                  },
                  net_arr: {
                    without_refunds: 130.96,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.81,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 48.46,
                    with_refunds: 48.46,
                  },
                  gross_arr_spread: {
                    without_refunds: 54.25,
                    with_refunds: 54.25,
                  },
                  net_total: {
                    without_refunds: 113.15,
                    with_refunds: 86.64,
                  },
                  gross_total: {
                    without_refunds: 125.99,
                    with_refunds: 96.79,
                  },
                  net_total_spread: {
                    without_refunds: 44.01,
                    with_refunds: 43.73,
                  },
                  gross_total_spread: {
                    without_refunds: 49.25,
                    with_refunds: 48.93,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 59.51,
                    with_refunds: 57.14,
                  },
                  gross_mrr: {
                    without_refunds: 67.82,
                    with_refunds: 65.11,
                  },
                  net_arr: {
                    without_refunds: 630.47,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 723.36,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 53.59,
                    with_refunds: 53.59,
                  },
                  gross_arr_spread: {
                    without_refunds: 60.04,
                    with_refunds: 60.04,
                  },
                  net_total: {
                    without_refunds: 261.45,
                    with_refunds: 185.47,
                  },
                  gross_total: {
                    without_refunds: 292.79,
                    with_refunds: 208.45,
                  },
                  net_total_spread: {
                    without_refunds: 54.42,
                    with_refunds: 53.97,
                  },
                  gross_total_spread: {
                    without_refunds: 60.89,
                    with_refunds: 60.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2132,
                    with_refunds: 0.2036,
                  },
                  gross_mrr: {
                    without_refunds: 0.2381,
                    with_refunds: 0.2272,
                  },
                  net_arr: {
                    without_refunds: 0.9997,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1131,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.37,
                    with_refunds: 0.37,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4142,
                    with_refunds: 0.4142,
                  },
                  net_total: {
                    without_refunds: 0.8638,
                    with_refunds: 0.6614,
                  },
                  gross_total: {
                    without_refunds: 0.9619,
                    with_refunds: 0.7389,
                  },
                  net_total_spread: {
                    without_refunds: 0.336,
                    with_refunds: 0.3338,
                  },
                  gross_total_spread: {
                    without_refunds: 0.376,
                    with_refunds: 0.3735,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4543,
                    with_refunds: 0.4362,
                  },
                  gross_mrr: {
                    without_refunds: 0.5177,
                    with_refunds: 0.4971,
                  },
                  net_arr: {
                    without_refunds: 4.8131,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 5.5223,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4091,
                    with_refunds: 0.4091,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4584,
                    with_refunds: 0.4584,
                  },
                  net_total: {
                    without_refunds: 1.996,
                    with_refunds: 1.4159,
                  },
                  gross_total: {
                    without_refunds: 2.2352,
                    with_refunds: 1.5914,
                  },
                  net_total_spread: {
                    without_refunds: 0.4154,
                    with_refunds: 0.412,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4648,
                    with_refunds: 0.461,
                  },
                },
              },
            },
          },
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 9.5,
                  with_refunds: 9.53,
                },
                arr_users: {
                  without_refunds: 0.23,
                  with_refunds: 0.73,
                },
                arr_spread_users: {
                  without_refunds: 1465.74,
                  with_refunds: 1465.74,
                },
                total_users: {
                  without_refunds: 10.04,
                  with_refunds: 10.75,
                },
                total_spread_users: {
                  without_refunds: 1328.43,
                  with_refunds: 1328.65,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0226,
                  with_refunds: 0.0226,
                },
                arr_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0004,
                },
                arr_spread_users: {
                  without_refunds: 0.9964,
                  with_refunds: 0.9964,
                },
                total_users: {
                  without_refunds: 0.0043,
                  with_refunds: 0.0046,
                },
                total_spread_users: {
                  without_refunds: 0.704,
                  with_refunds: 0.7041,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6336,
                  with_refunds: 0.6351,
                },
                arr_users: {
                  without_refunds: 0.2273,
                  with_refunds: 0.3662,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6277,
                  with_refunds: 0.6322,
                },
                total_spread_users: {
                  without_refunds: 0.8976,
                  with_refunds: 0.8977,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 112.41,
                  with_refunds: 112.33,
                },
                gross_mrr: {
                  without_refunds: 137.13,
                  with_refunds: 137.23,
                },
                net_arr: {
                  without_refunds: 29.43,
                },
                gross_arr: {
                  without_refunds: 32.99,
                },
                net_arr_spread: {
                  without_refunds: 15920.04,
                  with_refunds: 15920.04,
                },
                gross_arr_spread: {
                  without_refunds: 17806.41,
                  with_refunds: 17806.41,
                },
                net_total: {
                  without_refunds: 182.11,
                  with_refunds: 105.89,
                },
                gross_total: {
                  without_refunds: 204.05,
                  with_refunds: 127.86,
                },
                net_total_spread: {
                  without_refunds: 14025.12,
                  with_refunds: 14061.96,
                },
                gross_total_spread: {
                  without_refunds: 15662.42,
                  with_refunds: 15710.69,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0305,
                  with_refunds: 0.0327,
                },
                gross_mrr: {
                  without_refunds: 0.0339,
                  with_refunds: 0.0364,
                },
                net_arr: {
                  without_refunds: 0.0002,
                },
                gross_arr: {
                  without_refunds: 0.0002,
                },
                net_arr_spread: {
                  without_refunds: 2.0411,
                  with_refunds: 2.0411,
                },
                gross_arr_spread: {
                  without_refunds: 2.0215,
                  with_refunds: 2.0215,
                },
                net_total: {
                  without_refunds: 0.0013,
                  with_refunds: 0.001,
                },
                gross_total: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0011,
                },
                net_total_spread: {
                  without_refunds: 1.2208,
                  with_refunds: 1.2511,
                },
                gross_total_spread: {
                  without_refunds: 1.219,
                  with_refunds: 1.249,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6321,
                  with_refunds: 0.6317,
                },
                gross_mrr: {
                  without_refunds: 0.6286,
                  with_refunds: 0.629,
                },
                net_arr: {
                  without_refunds: 0.2185,
                },
                gross_arr: {
                  without_refunds: 0.2373,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                net_total: {
                  without_refunds: 0.5827,
                  with_refunds: 0.5954,
                },
                gross_total: {
                  without_refunds: 0.5713,
                  with_refunds: 0.5861,
                },
                net_total_spread: {
                  without_refunds: 0.8717,
                  with_refunds: 0.874,
                },
                gross_total_spread: {
                  without_refunds: 0.8694,
                  with_refunds: 0.8721,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.27,
                  with_refunds: 0.27,
                },
                gross_mrr: {
                  without_refunds: 0.33,
                  with_refunds: 0.33,
                },
                net_arr: {
                  without_refunds: 0.02,
                },
                gross_arr: {
                  without_refunds: 0.02,
                },
                net_arr_spread: {
                  without_refunds: 10.82,
                  with_refunds: 10.82,
                },
                gross_arr_spread: {
                  without_refunds: 12.1,
                  with_refunds: 12.1,
                },
                net_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.04,
                },
                gross_total: {
                  without_refunds: 0.09,
                  with_refunds: 0.05,
                },
                net_total_spread: {
                  without_refunds: 7.43,
                  with_refunds: 7.45,
                },
                gross_total_spread: {
                  without_refunds: 8.3,
                  with_refunds: 8.33,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.83,
                  with_refunds: 11.79,
                },
                gross_mrr: {
                  without_refunds: 14.43,
                  with_refunds: 14.4,
                },
                net_arr: {
                  without_refunds: 129.48,
                },
                gross_arr: {
                  without_refunds: 145.13,
                },
                net_arr_spread: {
                  without_refunds: 10.86,
                  with_refunds: 10.86,
                },
                gross_arr_spread: {
                  without_refunds: 12.15,
                  with_refunds: 12.15,
                },
                net_total: {
                  without_refunds: 18.13,
                  with_refunds: 9.85,
                },
                gross_total: {
                  without_refunds: 20.32,
                  with_refunds: 11.9,
                },
                net_total_spread: {
                  without_refunds: 10.56,
                  with_refunds: 10.58,
                },
                gross_total_spread: {
                  without_refunds: 11.79,
                  with_refunds: 11.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.19,
                    with_refunds: 26.93,
                  },
                  gross_mrr: {
                    without_refunds: 31.51,
                    with_refunds: 30.08,
                  },
                  net_arr: {
                    without_refunds: 130.97,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.82,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 59.29,
                    with_refunds: 59.29,
                  },
                  gross_arr_spread: {
                    without_refunds: 66.35,
                    with_refunds: 66.35,
                  },
                  net_total: {
                    without_refunds: 113.22,
                    with_refunds: 86.68,
                  },
                  gross_total: {
                    without_refunds: 126.08,
                    with_refunds: 96.85,
                  },
                  net_total_spread: {
                    without_refunds: 51.44,
                    with_refunds: 51.18,
                  },
                  gross_total_spread: {
                    without_refunds: 57.55,
                    with_refunds: 57.25,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 71.34,
                    with_refunds: 68.93,
                  },
                  gross_mrr: {
                    without_refunds: 82.25,
                    with_refunds: 79.51,
                  },
                  net_arr: {
                    without_refunds: 759.95,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 868.5,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 64.46,
                    with_refunds: 64.46,
                  },
                  gross_arr_spread: {
                    without_refunds: 72.19,
                    with_refunds: 72.19,
                  },
                  net_total: {
                    without_refunds: 279.59,
                    with_refunds: 195.33,
                  },
                  gross_total: {
                    without_refunds: 313.11,
                    with_refunds: 220.35,
                  },
                  net_total_spread: {
                    without_refunds: 64.97,
                    with_refunds: 64.55,
                  },
                  gross_total_spread: {
                    without_refunds: 72.68,
                    with_refunds: 72.21,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2152,
                    with_refunds: 0.2056,
                  },
                  gross_mrr: {
                    without_refunds: 0.2406,
                    with_refunds: 0.2296,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1132,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4526,
                    with_refunds: 0.4526,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5066,
                    with_refunds: 0.5066,
                  },
                  net_total: {
                    without_refunds: 0.8644,
                    with_refunds: 0.6617,
                  },
                  gross_total: {
                    without_refunds: 0.9625,
                    with_refunds: 0.7394,
                  },
                  net_total_spread: {
                    without_refunds: 0.3927,
                    with_refunds: 0.3907,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4393,
                    with_refunds: 0.4371,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.5446,
                    with_refunds: 0.5262,
                  },
                  gross_mrr: {
                    without_refunds: 0.6279,
                    with_refunds: 0.607,
                  },
                  net_arr: {
                    without_refunds: 5.8016,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 6.6303,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4921,
                    with_refunds: 0.4921,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5511,
                    with_refunds: 0.5511,
                  },
                  net_total: {
                    without_refunds: 2.1344,
                    with_refunds: 1.4912,
                  },
                  gross_total: {
                    without_refunds: 2.3903,
                    with_refunds: 1.6822,
                  },
                  net_total_spread: {
                    without_refunds: 0.496,
                    with_refunds: 0.4928,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5549,
                    with_refunds: 0.5513,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 6.47,
                  with_refunds: 6.46,
                },
                arr_users: {
                  without_refunds: 0.04,
                  with_refunds: 0.36,
                },
                arr_spread_users: {
                  without_refunds: 1468.65,
                  with_refunds: 1468.65,
                },
                total_users: {
                  without_refunds: 6.81,
                  with_refunds: 7.26,
                },
                total_spread_users: {
                  without_refunds: 1180.16,
                  with_refunds: 1179.28,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0154,
                  with_refunds: 0.0154,
                },
                arr_users: {
                  with_refunds: 0.0002,
                },
                arr_spread_users: {
                  without_refunds: 0.9984,
                  with_refunds: 0.9984,
                },
                total_users: {
                  without_refunds: 0.0029,
                  with_refunds: 0.0031,
                },
                total_spread_users: {
                  without_refunds: 0.6254,
                  with_refunds: 0.625,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6803,
                  with_refunds: 0.6785,
                },
                arr_users: {
                  without_refunds: 0.1905,
                  with_refunds: 0.4881,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6753,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8876,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 77.02,
                  with_refunds: 78.33,
                },
                gross_mrr: {
                  without_refunds: 93.77,
                  with_refunds: 95.34,
                },
                net_arr: {
                  without_refunds: 6.06,
                },
                gross_arr: {
                  without_refunds: 6.35,
                },
                net_arr_spread: {
                  without_refunds: 15957.78,
                  with_refunds: 15957.78,
                },
                gross_arr_spread: {
                  without_refunds: 17852.65,
                  with_refunds: 17852.65,
                },
                net_total: {
                  without_refunds: 121.44,
                  with_refunds: 73.08,
                },
                gross_total: {
                  without_refunds: 134.93,
                  with_refunds: 87.8,
                },
                net_total_spread: {
                  without_refunds: 12054.99,
                  with_refunds: 12170.68,
                },
                gross_total_spread: {
                  without_refunds: 13451.89,
                  with_refunds: 13579.93,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0209,
                  with_refunds: 0.0228,
                },
                gross_mrr: {
                  without_refunds: 0.0232,
                  with_refunds: 0.0253,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.0459,
                  with_refunds: 2.0459,
                },
                gross_arr_spread: {
                  without_refunds: 2.0267,
                  with_refunds: 2.0267,
                },
                net_total: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0007,
                },
                gross_total: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0007,
                },
                net_total_spread: {
                  without_refunds: 1.0493,
                  with_refunds: 1.0828,
                },
                gross_total_spread: {
                  without_refunds: 1.047,
                  with_refunds: 1.0796,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6851,
                  with_refunds: 0.6973,
                },
                gross_mrr: {
                  without_refunds: 0.6838,
                  with_refunds: 0.6948,
                },
                net_arr: {
                  without_refunds: 0.2058,
                },
                gross_arr: {
                  without_refunds: 0.1926,
                },
                net_arr_spread: {
                  without_refunds: 1.0024,
                  with_refunds: 1.0024,
                },
                gross_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                net_total: {
                  without_refunds: 0.6669,
                  with_refunds: 0.6901,
                },
                gross_total: {
                  without_refunds: 0.6613,
                  with_refunds: 0.6867,
                },
                net_total_spread: {
                  without_refunds: 0.8595,
                  with_refunds: 0.8655,
                },
                gross_total_spread: {
                  without_refunds: 0.8589,
                  with_refunds: 0.8644,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.18,
                  with_refunds: 0.19,
                },
                gross_mrr: {
                  without_refunds: 0.22,
                  with_refunds: 0.23,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10.85,
                  with_refunds: 10.85,
                },
                gross_arr_spread: {
                  without_refunds: 12.14,
                  with_refunds: 12.14,
                },
                net_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.03,
                },
                gross_total: {
                  without_refunds: 0.06,
                  with_refunds: 0.04,
                },
                net_total_spread: {
                  without_refunds: 6.39,
                  with_refunds: 6.45,
                },
                gross_total_spread: {
                  without_refunds: 7.13,
                  with_refunds: 7.2,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 11.91,
                  with_refunds: 12.12,
                },
                gross_mrr: {
                  without_refunds: 14.5,
                  with_refunds: 14.75,
                },
                net_arr: {
                  without_refunds: 139.9,
                },
                gross_arr: {
                  without_refunds: 146.78,
                },
                net_arr_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.87,
                },
                gross_arr_spread: {
                  without_refunds: 12.16,
                  with_refunds: 12.16,
                },
                net_total: {
                  without_refunds: 17.84,
                  with_refunds: 10.07,
                },
                gross_total: {
                  without_refunds: 19.82,
                  with_refunds: 12.1,
                },
                net_total_spread: {
                  without_refunds: 10.21,
                  with_refunds: 10.32,
                },
                gross_total_spread: {
                  without_refunds: 11.4,
                  with_refunds: 11.52,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.38,
                    with_refunds: 27.12,
                  },
                  gross_mrr: {
                    without_refunds: 31.74,
                    with_refunds: 30.31,
                  },
                  net_arr: {
                    without_refunds: 130.98,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.83,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 70.13,
                    with_refunds: 70.13,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.49,
                    with_refunds: 78.49,
                  },
                  net_total: {
                    without_refunds: 113.28,
                    with_refunds: 86.71,
                  },
                  gross_total: {
                    without_refunds: 126.14,
                    with_refunds: 96.89,
                  },
                  net_total_spread: {
                    without_refunds: 57.83,
                    with_refunds: 57.63,
                  },
                  gross_total_spread: {
                    without_refunds: 64.68,
                    with_refunds: 64.45,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 83.25,
                    with_refunds: 81.05,
                  },
                  gross_mrr: {
                    without_refunds: 96.75,
                    with_refunds: 94.26,
                  },
                  net_arr: {
                    without_refunds: 899.85,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 1015.27,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 75.32,
                    with_refunds: 75.32,
                  },
                  gross_arr_spread: {
                    without_refunds: 84.34,
                    with_refunds: 84.34,
                  },
                  net_total: {
                    without_refunds: 297.43,
                    with_refunds: 205.4,
                  },
                  gross_total: {
                    without_refunds: 332.93,
                    with_refunds: 232.45,
                  },
                  net_total_spread: {
                    without_refunds: 75.19,
                    with_refunds: 74.87,
                  },
                  gross_total_spread: {
                    without_refunds: 84.08,
                    with_refunds: 83.73,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2166,
                    with_refunds: 0.207,
                  },
                  gross_mrr: {
                    without_refunds: 0.2423,
                    with_refunds: 0.2314,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1133,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5354,
                    with_refunds: 0.5354,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5992,
                    with_refunds: 0.5992,
                  },
                  net_total: {
                    without_refunds: 0.8648,
                    with_refunds: 0.662,
                  },
                  gross_total: {
                    without_refunds: 0.963,
                    with_refunds: 0.7396,
                  },
                  net_total_spread: {
                    without_refunds: 0.4415,
                    with_refunds: 0.44,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4938,
                    with_refunds: 0.492,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6356,
                    with_refunds: 0.6187,
                  },
                  gross_mrr: {
                    without_refunds: 0.7386,
                    with_refunds: 0.7196,
                  },
                  net_arr: {
                    without_refunds: 6.8696,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 7.7508,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.575,
                    with_refunds: 0.575,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6439,
                    with_refunds: 0.6439,
                  },
                  net_total: {
                    without_refunds: 2.2706,
                    with_refunds: 1.568,
                  },
                  gross_total: {
                    without_refunds: 2.5417,
                    with_refunds: 1.7745,
                  },
                  net_total_spread: {
                    without_refunds: 0.574,
                    with_refunds: 0.5716,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6419,
                    with_refunds: 0.6392,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 4.05,
                  with_refunds: 4.05,
                },
                arr_users: {
                  with_refunds: 0.04,
                },
                arr_spread_users: {
                  without_refunds: 1469.39,
                  with_refunds: 1469.39,
                },
                total_users: {
                  without_refunds: 4.22,
                  with_refunds: 4.5,
                },
                total_spread_users: {
                  without_refunds: 1057.29,
                  with_refunds: 1056.04,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0096,
                  with_refunds: 0.0096,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.9989,
                  with_refunds: 0.9989,
                },
                total_users: {
                  without_refunds: 0.0018,
                  with_refunds: 0.0019,
                },
                total_spread_users: {
                  without_refunds: 0.5603,
                  with_refunds: 0.5596,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.626,
                  with_refunds: 0.6268,
                },
                arr_users: {
                  without_refunds: 0.0833,
                  with_refunds: 0.1167,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                total_spread_users: {
                  without_refunds: 0.8959,
                  with_refunds: 0.8955,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 48.66,
                  with_refunds: 49.01,
                },
                gross_mrr: {
                  without_refunds: 59.26,
                  with_refunds: 59.77,
                },
                net_arr: {
                  without_refunds: 0.4,
                },
                gross_arr: {
                  without_refunds: 0.48,
                },
                net_arr_spread: {
                  without_refunds: 15966.11,
                  with_refunds: 15966.11,
                },
                gross_arr_spread: {
                  without_refunds: 17864.39,
                  with_refunds: 17864.39,
                },
                net_total: {
                  without_refunds: 69.12,
                  with_refunds: 41.41,
                },
                gross_total: {
                  without_refunds: 76.37,
                  with_refunds: 49.45,
                },
                net_total_spread: {
                  without_refunds: 10420.66,
                  with_refunds: 10511.68,
                },
                gross_total_spread: {
                  without_refunds: 11629.3,
                  with_refunds: 11735.69,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0132,
                  with_refunds: 0.0142,
                },
                gross_mrr: {
                  without_refunds: 0.0147,
                  with_refunds: 0.0159,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.047,
                  with_refunds: 2.047,
                },
                gross_arr_spread: {
                  without_refunds: 2.0281,
                  with_refunds: 2.0281,
                },
                net_total: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0004,
                },
                gross_total: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0004,
                },
                net_total_spread: {
                  without_refunds: 0.907,
                  with_refunds: 0.9352,
                },
                gross_total_spread: {
                  without_refunds: 0.9051,
                  with_refunds: 0.933,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6258,
                },
                gross_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6268,
                },
                net_arr: {
                  without_refunds: 0.0657,
                },
                gross_arr: {
                  without_refunds: 0.0754,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.5691,
                  with_refunds: 0.5667,
                },
                gross_total: {
                  without_refunds: 0.566,
                  with_refunds: 0.5633,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8637,
                },
                gross_total_spread: {
                  without_refunds: 0.8645,
                  with_refunds: 0.8642,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.12,
                  with_refunds: 0.12,
                },
                gross_mrr: {
                  without_refunds: 0.14,
                  with_refunds: 0.14,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10.85,
                  with_refunds: 10.85,
                },
                gross_arr_spread: {
                  without_refunds: 12.14,
                  with_refunds: 12.14,
                },
                net_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.02,
                },
                net_total_spread: {
                  without_refunds: 5.52,
                  with_refunds: 5.57,
                },
                gross_total_spread: {
                  without_refunds: 6.16,
                  with_refunds: 6.22,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.02,
                  with_refunds: 12.1,
                },
                gross_mrr: {
                  without_refunds: 14.64,
                  with_refunds: 14.75,
                },
                net_arr: {
                  without_refunds: 110.37,
                },
                gross_arr: {
                  without_refunds: 132.75,
                },
                net_arr_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.87,
                },
                gross_arr_spread: {
                  without_refunds: 12.16,
                  with_refunds: 12.16,
                },
                net_total: {
                  without_refunds: 16.39,
                  with_refunds: 9.21,
                },
                gross_total: {
                  without_refunds: 18.11,
                  with_refunds: 11,
                },
                net_total_spread: {
                  without_refunds: 9.86,
                  with_refunds: 9.95,
                },
                gross_total_spread: {
                  without_refunds: 11,
                  with_refunds: 11.11,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.49,
                    with_refunds: 27.23,
                  },
                  gross_mrr: {
                    without_refunds: 31.88,
                    with_refunds: 30.45,
                  },
                  net_arr: {
                    without_refunds: 130.98,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.83,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 80.99,
                    with_refunds: 80.99,
                  },
                  gross_arr_spread: {
                    without_refunds: 90.64,
                    with_refunds: 90.64,
                  },
                  net_total: {
                    without_refunds: 113.3,
                    with_refunds: 86.73,
                  },
                  gross_total: {
                    without_refunds: 126.17,
                    with_refunds: 96.91,
                  },
                  net_total_spread: {
                    without_refunds: 63.35,
                    with_refunds: 63.2,
                  },
                  gross_total_spread: {
                    without_refunds: 70.84,
                    with_refunds: 70.67,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 95.28,
                    with_refunds: 93.15,
                  },
                  gross_mrr: {
                    without_refunds: 111.39,
                    with_refunds: 109.02,
                  },
                  net_arr: {
                    without_refunds: 1010.22,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 1148.02,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 86.19,
                    with_refunds: 86.19,
                  },
                  gross_arr_spread: {
                    without_refunds: 96.5,
                    with_refunds: 96.5,
                  },
                  net_total: {
                    without_refunds: 313.81,
                    with_refunds: 214.6,
                  },
                  gross_total: {
                    without_refunds: 351.04,
                    with_refunds: 243.44,
                  },
                  net_total_spread: {
                    without_refunds: 85.04,
                    with_refunds: 84.83,
                  },
                  gross_total_spread: {
                    without_refunds: 95.08,
                    with_refunds: 94.84,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2175,
                    with_refunds: 0.2079,
                  },
                  gross_mrr: {
                    without_refunds: 0.2433,
                    with_refunds: 0.2325,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1133,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6183,
                    with_refunds: 0.6183,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6919,
                    with_refunds: 0.6919,
                  },
                  net_total: {
                    without_refunds: 0.865,
                    with_refunds: 0.6621,
                  },
                  gross_total: {
                    without_refunds: 0.9632,
                    with_refunds: 0.7398,
                  },
                  net_total_spread: {
                    without_refunds: 0.4837,
                    with_refunds: 0.4825,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5408,
                    with_refunds: 0.5395,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.7273,
                    with_refunds: 0.7111,
                  },
                  gross_mrr: {
                    without_refunds: 0.8504,
                    with_refunds: 0.8322,
                  },
                  net_arr: {
                    without_refunds: 7.7122,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 8.7642,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.658,
                    with_refunds: 0.658,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7367,
                    with_refunds: 0.7367,
                  },
                  net_total: {
                    without_refunds: 2.3957,
                    with_refunds: 1.6383,
                  },
                  gross_total: {
                    without_refunds: 2.6799,
                    with_refunds: 1.8585,
                  },
                  net_total_spread: {
                    without_refunds: 0.6492,
                    with_refunds: 0.6476,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7258,
                    with_refunds: 0.724,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2.67,
                  with_refunds: 2.67,
                },
                arr_users: {
                  with_refunds: 0.01,
                },
                arr_spread_users: {
                  without_refunds: 1472.31,
                  with_refunds: 1472.31,
                },
                total_users: {
                  without_refunds: 2.78,
                  with_refunds: 2.96,
                },
                total_spread_users: {
                  without_refunds: 958.8,
                  with_refunds: 957.71,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0063,
                  with_refunds: 0.0064,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0009,
                  with_refunds: 1.0009,
                },
                total_users: {
                  without_refunds: 0.0012,
                  with_refunds: 0.0013,
                },
                total_spread_users: {
                  without_refunds: 0.5081,
                  with_refunds: 0.5075,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {
                  without_refunds: 0.2778,
                  with_refunds: 0.2778,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 32.32,
                  with_refunds: 32.49,
                },
                gross_mrr: {
                  without_refunds: 39.32,
                  with_refunds: 39.58,
                },
                net_arr: {
                  without_refunds: 0.11,
                },
                gross_arr: {
                  without_refunds: 0.14,
                },
                net_arr_spread: {
                  without_refunds: 16005.74,
                  with_refunds: 16005.74,
                },
                gross_arr_spread: {
                  without_refunds: 17913.04,
                  with_refunds: 17913.04,
                },
                net_total: {
                  without_refunds: 45.47,
                  with_refunds: 27.49,
                },
                gross_total: {
                  without_refunds: 50.11,
                  with_refunds: 32.8,
                },
                net_total_spread: {
                  without_refunds: 9148.94,
                  with_refunds: 9231,
                },
                gross_total_spread: {
                  without_refunds: 10197.86,
                  with_refunds: 10293.59,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0088,
                  with_refunds: 0.0094,
                },
                gross_mrr: {
                  without_refunds: 0.0097,
                  with_refunds: 0.0105,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.0521,
                  with_refunds: 2.0521,
                },
                gross_arr_spread: {
                  without_refunds: 2.0336,
                  with_refunds: 2.0336,
                },
                net_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0003,
                },
                gross_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0003,
                },
                net_total_spread: {
                  without_refunds: 0.7963,
                  with_refunds: 0.8213,
                },
                gross_total_spread: {
                  without_refunds: 0.7937,
                  with_refunds: 0.8184,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {
                  without_refunds: 0.2848,
                },
                gross_arr: {
                  without_refunds: 0.285,
                },
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.08,
                  with_refunds: 0.08,
                },
                gross_mrr: {
                  without_refunds: 0.09,
                  with_refunds: 0.09,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10.88,
                  with_refunds: 10.88,
                },
                gross_arr_spread: {
                  without_refunds: 12.18,
                  with_refunds: 12.18,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 4.85,
                  with_refunds: 4.89,
                },
                gross_total_spread: {
                  without_refunds: 5.4,
                  with_refunds: 5.46,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.11,
                  with_refunds: 12.15,
                },
                gross_mrr: {
                  without_refunds: 14.73,
                  with_refunds: 14.8,
                },
                net_arr: {
                  without_refunds: 113.15,
                },
                gross_arr: {
                  without_refunds: 136.18,
                },
                net_arr_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.87,
                },
                gross_arr_spread: {
                  without_refunds: 12.17,
                  with_refunds: 12.17,
                },
                net_total: {
                  without_refunds: 16.37,
                  with_refunds: 9.28,
                },
                gross_total: {
                  without_refunds: 18.04,
                  with_refunds: 11.07,
                },
                net_total_spread: {
                  without_refunds: 9.54,
                  with_refunds: 9.64,
                },
                gross_total_spread: {
                  without_refunds: 10.64,
                  with_refunds: 10.75,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.57,
                    with_refunds: 27.31,
                  },
                  gross_mrr: {
                    without_refunds: 31.97,
                    with_refunds: 30.54,
                  },
                  net_arr: {
                    without_refunds: 130.98,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.83,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 91.87,
                    with_refunds: 91.87,
                  },
                  gross_arr_spread: {
                    without_refunds: 102.81,
                    with_refunds: 102.81,
                  },
                  net_total: {
                    without_refunds: 113.32,
                    with_refunds: 86.74,
                  },
                  gross_total: {
                    without_refunds: 126.19,
                    with_refunds: 96.92,
                  },
                  net_total_spread: {
                    without_refunds: 68.2,
                    with_refunds: 68.09,
                  },
                  gross_total_spread: {
                    without_refunds: 76.24,
                    with_refunds: 76.13,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 107.38,
                    with_refunds: 105.3,
                  },
                  gross_mrr: {
                    without_refunds: 126.12,
                    with_refunds: 123.82,
                  },
                  net_arr: {
                    without_refunds: 1123.37,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 1284.21,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 97.06,
                    with_refunds: 97.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 108.67,
                    with_refunds: 108.67,
                  },
                  net_total: {
                    without_refunds: 330.19,
                    with_refunds: 223.89,
                  },
                  gross_total: {
                    without_refunds: 369.08,
                    with_refunds: 254.52,
                  },
                  net_total_spread: {
                    without_refunds: 94.59,
                    with_refunds: 94.47,
                  },
                  gross_total_spread: {
                    without_refunds: 105.71,
                    with_refunds: 105.59,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2181,
                    with_refunds: 0.2085,
                  },
                  gross_mrr: {
                    without_refunds: 0.2441,
                    with_refunds: 0.2332,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1133,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7013,
                    with_refunds: 0.7013,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7849,
                    with_refunds: 0.7849,
                  },
                  net_total: {
                    without_refunds: 0.8651,
                    with_refunds: 0.6622,
                  },
                  gross_total: {
                    without_refunds: 0.9634,
                    with_refunds: 0.7399,
                  },
                  net_total_spread: {
                    without_refunds: 0.5207,
                    with_refunds: 0.5198,
                  },
                  gross_total_spread: {
                    without_refunds: 0.5821,
                    with_refunds: 0.5812,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8198,
                    with_refunds: 0.8039,
                  },
                  gross_mrr: {
                    without_refunds: 0.9629,
                    with_refunds: 0.9453,
                  },
                  net_arr: {
                    without_refunds: 8.576,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 9.8039,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.741,
                    with_refunds: 0.741,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8296,
                    with_refunds: 0.8296,
                  },
                  net_total: {
                    without_refunds: 2.5207,
                    with_refunds: 1.7092,
                  },
                  gross_total: {
                    without_refunds: 2.8176,
                    with_refunds: 1.943,
                  },
                  net_total_spread: {
                    without_refunds: 0.7221,
                    with_refunds: 0.7212,
                  },
                  gross_total_spread: {
                    without_refunds: 0.807,
                    with_refunds: 0.8061,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.77,
                  with_refunds: 1.77,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1476.49,
                  with_refunds: 1476.49,
                },
                total_users: {
                  without_refunds: 1.83,
                  with_refunds: 1.96,
                },
                total_spread_users: {
                  without_refunds: 851.84,
                  with_refunds: 850.81,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0042,
                  with_refunds: 0.0042,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0037,
                  with_refunds: 1.0037,
                },
                total_users: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0008,
                },
                total_spread_users: {
                  without_refunds: 0.4514,
                  with_refunds: 0.4509,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {
                  without_refunds: 0.2222,
                  with_refunds: 0.2222,
                },
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 21.42,
                  with_refunds: 21.62,
                },
                gross_mrr: {
                  without_refunds: 26.01,
                  with_refunds: 26.29,
                },
                net_arr: {
                  without_refunds: 0.02,
                },
                gross_arr: {
                  without_refunds: 0.03,
                },
                net_arr_spread: {
                  without_refunds: 16072.45,
                  with_refunds: 16072.45,
                },
                gross_arr_spread: {
                  without_refunds: 17980.61,
                  with_refunds: 17980.61,
                },
                net_total: {
                  without_refunds: 28.72,
                  with_refunds: 17.46,
                },
                gross_total: {
                  without_refunds: 31.47,
                  with_refunds: 20.72,
                },
                net_total_spread: {
                  without_refunds: 7854.58,
                  with_refunds: 7952.08,
                },
                gross_total_spread: {
                  without_refunds: 8751.35,
                  with_refunds: 8861.33,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0058,
                  with_refunds: 0.0063,
                },
                gross_mrr: {
                  without_refunds: 0.0064,
                  with_refunds: 0.007,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.0606,
                  with_refunds: 2.0606,
                },
                gross_arr_spread: {
                  without_refunds: 2.0413,
                  with_refunds: 2.0413,
                },
                net_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0002,
                },
                gross_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0002,
                },
                net_total_spread: {
                  without_refunds: 0.6837,
                  with_refunds: 0.7075,
                },
                gross_total_spread: {
                  without_refunds: 0.6811,
                  with_refunds: 0.7045,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {
                  without_refunds: 0.2001,
                },
                gross_arr: {
                  without_refunds: 0.2041,
                },
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                gross_mrr: {
                  without_refunds: 0.06,
                  with_refunds: 0.06,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 10.93,
                  with_refunds: 10.93,
                },
                gross_arr_spread: {
                  without_refunds: 12.22,
                  with_refunds: 12.22,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 4.16,
                  with_refunds: 4.21,
                },
                gross_total_spread: {
                  without_refunds: 4.64,
                  with_refunds: 4.7,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.1,
                  with_refunds: 12.18,
                },
                gross_mrr: {
                  without_refunds: 14.7,
                  with_refunds: 14.81,
                },
                net_arr: {
                  without_refunds: 101.9,
                },
                gross_arr: {
                  without_refunds: 125.06,
                },
                net_arr_spread: {
                  without_refunds: 10.89,
                  with_refunds: 10.89,
                },
                gross_arr_spread: {
                  without_refunds: 12.18,
                  with_refunds: 12.18,
                },
                net_total: {
                  without_refunds: 15.67,
                  with_refunds: 8.92,
                },
                gross_total: {
                  without_refunds: 17.17,
                  with_refunds: 10.59,
                },
                net_total_spread: {
                  without_refunds: 9.22,
                  with_refunds: 9.35,
                },
                gross_total_spread: {
                  without_refunds: 10.27,
                  with_refunds: 10.42,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.62,
                    with_refunds: 27.36,
                  },
                  gross_mrr: {
                    without_refunds: 32.03,
                    with_refunds: 30.61,
                  },
                  net_arr: {
                    without_refunds: 130.98,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.83,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 102.8,
                    with_refunds: 102.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 115.04,
                    with_refunds: 115.04,
                  },
                  net_total: {
                    without_refunds: 113.34,
                    with_refunds: 86.75,
                  },
                  gross_total: {
                    without_refunds: 126.2,
                    with_refunds: 96.93,
                  },
                  net_total_spread: {
                    without_refunds: 72.36,
                    with_refunds: 72.31,
                  },
                  gross_total_spread: {
                    without_refunds: 80.88,
                    with_refunds: 80.82,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 119.49,
                    with_refunds: 117.48,
                  },
                  gross_mrr: {
                    without_refunds: 140.82,
                    with_refunds: 138.63,
                  },
                  net_arr: {
                    without_refunds: 1225.27,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 1409.27,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 107.94,
                    with_refunds: 107.94,
                  },
                  gross_arr_spread: {
                    without_refunds: 120.85,
                    with_refunds: 120.85,
                  },
                  net_total: {
                    without_refunds: 345.85,
                    with_refunds: 232.81,
                  },
                  gross_total: {
                    without_refunds: 386.25,
                    with_refunds: 265.11,
                  },
                  net_total_spread: {
                    without_refunds: 103.81,
                    with_refunds: 103.81,
                  },
                  gross_total_spread: {
                    without_refunds: 115.99,
                    with_refunds: 116.01,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2185,
                    with_refunds: 0.2089,
                  },
                  gross_mrr: {
                    without_refunds: 0.2445,
                    with_refunds: 0.2337,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1133,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7848,
                    with_refunds: 0.7848,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8782,
                    with_refunds: 0.8782,
                  },
                  net_total: {
                    without_refunds: 0.8652,
                    with_refunds: 0.6622,
                  },
                  gross_total: {
                    without_refunds: 0.9635,
                    with_refunds: 0.74,
                  },
                  net_total_spread: {
                    without_refunds: 0.5524,
                    with_refunds: 0.552,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6175,
                    with_refunds: 0.617,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.9122,
                    with_refunds: 0.8969,
                  },
                  gross_mrr: {
                    without_refunds: 1.075,
                    with_refunds: 1.0583,
                  },
                  net_arr: {
                    without_refunds: 9.3539,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 10.7586,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8241,
                    with_refunds: 0.8241,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9226,
                    with_refunds: 0.9226,
                  },
                  net_total: {
                    without_refunds: 2.6403,
                    with_refunds: 1.7773,
                  },
                  gross_total: {
                    without_refunds: 2.9487,
                    with_refunds: 2.0239,
                  },
                  net_total_spread: {
                    without_refunds: 0.7925,
                    with_refunds: 0.7925,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8855,
                    with_refunds: 0.8856,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.25,
                  with_refunds: 1.25,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1484.77,
                  with_refunds: 1484.77,
                },
                total_users: {
                  without_refunds: 1.3,
                  with_refunds: 1.38,
                },
                total_spread_users: {
                  without_refunds: 751.15,
                  with_refunds: 749.49,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.003,
                  with_refunds: 0.003,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0094,
                  with_refunds: 1.0094,
                },
                total_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0006,
                },
                total_spread_users: {
                  without_refunds: 0.3981,
                  with_refunds: 0.3972,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 15.11,
                  with_refunds: 15.33,
                },
                gross_mrr: {
                  without_refunds: 18.32,
                  with_refunds: 18.6,
                },
                net_arr: {
                  without_refunds: 0.02,
                },
                gross_arr: {
                  without_refunds: 0.02,
                },
                net_arr_spread: {
                  without_refunds: 16199.05,
                  with_refunds: 16199.05,
                },
                gross_arr_spread: {
                  without_refunds: 18101.15,
                  with_refunds: 18101.15,
                },
                net_total: {
                  without_refunds: 20.34,
                  with_refunds: 12.43,
                },
                gross_total: {
                  without_refunds: 22.25,
                  with_refunds: 14.72,
                },
                net_total_spread: {
                  without_refunds: 6700.48,
                  with_refunds: 6806.47,
                },
                gross_total_spread: {
                  without_refunds: 7478.66,
                  with_refunds: 7596.29,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0041,
                  with_refunds: 0.0045,
                },
                gross_mrr: {
                  without_refunds: 0.0045,
                  with_refunds: 0.0049,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.0769,
                  with_refunds: 2.0769,
                },
                gross_arr_spread: {
                  without_refunds: 2.0549,
                  with_refunds: 2.0549,
                },
                net_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.5832,
                  with_refunds: 0.6056,
                },
                gross_total_spread: {
                  without_refunds: 0.5821,
                  with_refunds: 0.6039,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {
                  without_refunds: 0.6705,
                },
                gross_arr: {
                  without_refunds: 0.6582,
                },
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                gross_mrr: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.01,
                  with_refunds: 11.01,
                },
                gross_arr_spread: {
                  without_refunds: 12.31,
                  with_refunds: 12.31,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 3.55,
                  with_refunds: 3.61,
                },
                gross_total_spread: {
                  without_refunds: 3.96,
                  with_refunds: 4.03,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.09,
                  with_refunds: 12.24,
                },
                gross_mrr: {
                  without_refunds: 14.65,
                  with_refunds: 14.86,
                },
                net_arr: {
                  without_refunds: 102.49,
                },
                gross_arr: {
                  without_refunds: 123.47,
                },
                net_arr_spread: {
                  without_refunds: 10.91,
                  with_refunds: 10.91,
                },
                gross_arr_spread: {
                  without_refunds: 12.19,
                  with_refunds: 12.19,
                },
                net_total: {
                  without_refunds: 15.7,
                  with_refunds: 9,
                },
                gross_total: {
                  without_refunds: 17.17,
                  with_refunds: 10.66,
                },
                net_total_spread: {
                  without_refunds: 8.92,
                  with_refunds: 9.08,
                },
                gross_total_spread: {
                  without_refunds: 9.96,
                  with_refunds: 10.14,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.65,
                    with_refunds: 27.4,
                  },
                  gross_mrr: {
                    without_refunds: 32.07,
                    with_refunds: 30.65,
                  },
                  net_arr: {
                    without_refunds: 130.98,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.83,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 113.81,
                    with_refunds: 113.81,
                  },
                  gross_arr_spread: {
                    without_refunds: 127.34,
                    with_refunds: 127.34,
                  },
                  net_total: {
                    without_refunds: 113.35,
                    with_refunds: 86.75,
                  },
                  gross_total: {
                    without_refunds: 126.21,
                    with_refunds: 96.94,
                  },
                  net_total_spread: {
                    without_refunds: 75.92,
                    with_refunds: 75.91,
                  },
                  gross_total_spread: {
                    without_refunds: 84.84,
                    with_refunds: 84.85,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 131.57,
                    with_refunds: 129.72,
                  },
                  gross_mrr: {
                    without_refunds: 155.47,
                    with_refunds: 153.49,
                  },
                  net_arr: {
                    without_refunds: 1327.76,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 1532.73,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 118.85,
                    with_refunds: 118.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 133.04,
                    with_refunds: 133.04,
                  },
                  net_total: {
                    without_refunds: 361.55,
                    with_refunds: 241.82,
                  },
                  gross_total: {
                    without_refunds: 403.42,
                    with_refunds: 275.77,
                  },
                  net_total_spread: {
                    without_refunds: 112.73,
                    with_refunds: 112.89,
                  },
                  gross_total_spread: {
                    without_refunds: 125.94,
                    with_refunds: 126.14,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2188,
                    with_refunds: 0.2092,
                  },
                  gross_mrr: {
                    without_refunds: 0.2449,
                    with_refunds: 0.234,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1133,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8688,
                    with_refunds: 0.8688,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.9721,
                    with_refunds: 0.9721,
                  },
                  net_total: {
                    without_refunds: 0.8653,
                    with_refunds: 0.6623,
                  },
                  gross_total: {
                    without_refunds: 0.9635,
                    with_refunds: 0.74,
                  },
                  net_total_spread: {
                    without_refunds: 0.5795,
                    with_refunds: 0.5795,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6477,
                    with_refunds: 0.6477,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0044,
                    with_refunds: 0.9903,
                  },
                  gross_mrr: {
                    without_refunds: 1.1869,
                    with_refunds: 1.1718,
                  },
                  net_arr: {
                    without_refunds: 10.1363,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 11.7012,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9074,
                    with_refunds: 0.9074,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0156,
                    with_refunds: 1.0156,
                  },
                  net_total: {
                    without_refunds: 2.7602,
                    with_refunds: 1.8461,
                  },
                  gross_total: {
                    without_refunds: 3.0798,
                    with_refunds: 2.1053,
                  },
                  net_total_spread: {
                    without_refunds: 0.8606,
                    with_refunds: 0.8619,
                  },
                  gross_total_spread: {
                    without_refunds: 0.9615,
                    with_refunds: 0.963,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.76,
                  with_refunds: 0.76,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1487.87,
                  with_refunds: 1487.87,
                },
                total_users: {
                  without_refunds: 0.78,
                  with_refunds: 0.83,
                },
                total_spread_users: {
                  without_refunds: 549.29,
                  with_refunds: 547.84,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0018,
                  with_refunds: 0.0018,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0115,
                  with_refunds: 1.0115,
                },
                total_users: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0004,
                },
                total_spread_users: {
                  without_refunds: 0.2911,
                  with_refunds: 0.2903,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9.21,
                  with_refunds: 9.4,
                },
                gross_mrr: {
                  without_refunds: 11.19,
                  with_refunds: 11.44,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 16246.3,
                  with_refunds: 16246.3,
                },
                gross_arr_spread: {
                  without_refunds: 18142.6,
                  with_refunds: 18142.6,
                },
                net_total: {
                  without_refunds: 11.99,
                  with_refunds: 7.47,
                },
                gross_total: {
                  without_refunds: 13.05,
                  with_refunds: 8.84,
                },
                net_total_spread: {
                  without_refunds: 4620.07,
                  with_refunds: 4718.84,
                },
                gross_total_spread: {
                  without_refunds: 5305.9,
                  with_refunds: 5417.47,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0025,
                  with_refunds: 0.0027,
                },
                gross_mrr: {
                  without_refunds: 0.0028,
                  with_refunds: 0.003,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2.0829,
                  with_refunds: 2.0829,
                },
                gross_arr_spread: {
                  without_refunds: 2.0596,
                  with_refunds: 2.0596,
                },
                net_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.4021,
                  with_refunds: 0.4198,
                },
                gross_total_spread: {
                  without_refunds: 0.413,
                  with_refunds: 0.4307,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {
                  without_refunds: 0.155,
                },
                gross_arr: {
                  without_refunds: 0.138,
                },
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                gross_mrr: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.04,
                  with_refunds: 11.04,
                },
                gross_arr_spread: {
                  without_refunds: 12.33,
                  with_refunds: 12.33,
                },
                net_total: {
                  without_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 2.45,
                  with_refunds: 2.5,
                },
                gross_total_spread: {
                  without_refunds: 2.81,
                  with_refunds: 2.87,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.15,
                  with_refunds: 12.4,
                },
                gross_mrr: {
                  without_refunds: 14.77,
                  with_refunds: 15.08,
                },
                net_arr: {
                  without_refunds: 95.31,
                },
                gross_arr: {
                  without_refunds: 102.23,
                },
                net_arr_spread: {
                  without_refunds: 10.92,
                  with_refunds: 10.92,
                },
                gross_arr_spread: {
                  without_refunds: 12.19,
                  with_refunds: 12.19,
                },
                net_total: {
                  without_refunds: 15.32,
                  with_refunds: 8.97,
                },
                gross_total: {
                  without_refunds: 16.67,
                  with_refunds: 10.61,
                },
                net_total_spread: {
                  without_refunds: 8.41,
                  with_refunds: 8.61,
                },
                gross_total_spread: {
                  without_refunds: 9.66,
                  with_refunds: 9.89,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.68,
                    with_refunds: 27.42,
                  },
                  gross_mrr: {
                    without_refunds: 32.1,
                    with_refunds: 30.68,
                  },
                  net_arr: {
                    without_refunds: 130.98,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.83,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 124.85,
                    with_refunds: 124.85,
                  },
                  gross_arr_spread: {
                    without_refunds: 139.68,
                    with_refunds: 139.68,
                  },
                  net_total: {
                    without_refunds: 113.35,
                    with_refunds: 86.76,
                  },
                  gross_total: {
                    without_refunds: 126.22,
                    with_refunds: 96.94,
                  },
                  net_total_spread: {
                    without_refunds: 78.36,
                    with_refunds: 78.42,
                  },
                  gross_total_spread: {
                    without_refunds: 87.66,
                    with_refunds: 87.72,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 143.72,
                    with_refunds: 142.12,
                  },
                  gross_mrr: {
                    without_refunds: 170.24,
                    with_refunds: 168.57,
                  },
                  net_arr: {
                    without_refunds: 1423.06,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 1634.96,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 129.77,
                    with_refunds: 129.77,
                  },
                  gross_arr_spread: {
                    without_refunds: 145.23,
                    with_refunds: 145.23,
                  },
                  net_total: {
                    without_refunds: 376.88,
                    with_refunds: 250.78,
                  },
                  gross_total: {
                    without_refunds: 420.1,
                    with_refunds: 286.38,
                  },
                  net_total_spread: {
                    without_refunds: 121.14,
                    with_refunds: 121.51,
                  },
                  gross_total_spread: {
                    without_refunds: 135.6,
                    with_refunds: 136.03,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2189,
                    with_refunds: 0.2093,
                  },
                  gross_mrr: {
                    without_refunds: 0.2451,
                    with_refunds: 0.2342,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1133,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9531,
                    with_refunds: 0.9531,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0663,
                    with_refunds: 1.0663,
                  },
                  net_total: {
                    without_refunds: 0.8653,
                    with_refunds: 0.6623,
                  },
                  gross_total: {
                    without_refunds: 0.9636,
                    with_refunds: 0.7401,
                  },
                  net_total_spread: {
                    without_refunds: 0.5982,
                    with_refunds: 0.5986,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6692,
                    with_refunds: 0.6697,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0972,
                    with_refunds: 1.085,
                  },
                  gross_mrr: {
                    without_refunds: 1.2996,
                    with_refunds: 1.2869,
                  },
                  net_arr: {
                    without_refunds: 10.8639,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 12.4816,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9907,
                    with_refunds: 0.9907,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1087,
                    with_refunds: 1.1087,
                  },
                  net_total: {
                    without_refunds: 2.8771,
                    with_refunds: 1.9145,
                  },
                  gross_total: {
                    without_refunds: 3.2071,
                    with_refunds: 2.1863,
                  },
                  net_total_spread: {
                    without_refunds: 0.9248,
                    with_refunds: 0.9276,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0352,
                    with_refunds: 1.0385,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.23,
                  with_refunds: 0.23,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 506.25,
                  with_refunds: 506.25,
                },
                total_users: {
                  without_refunds: 0.23,
                  with_refunds: 0.25,
                },
                total_spread_users: {
                  without_refunds: 171.83,
                  with_refunds: 171.16,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0005,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3442,
                  with_refunds: 0.3442,
                },
                total_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                total_spread_users: {
                  without_refunds: 0.0911,
                  with_refunds: 0.0907,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2.75,
                  with_refunds: 2.82,
                },
                gross_mrr: {
                  without_refunds: 3.33,
                  with_refunds: 3.41,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6236.16,
                  with_refunds: 6236.16,
                },
                gross_arr_spread: {
                  without_refunds: 6512.27,
                  with_refunds: 6512.27,
                },
                net_total: {
                  without_refunds: 3.53,
                  with_refunds: 2.2,
                },
                gross_total: {
                  without_refunds: 3.81,
                  with_refunds: 2.59,
                },
                net_total_spread: {
                  without_refunds: 1459.22,
                  with_refunds: 1492.62,
                },
                gross_total_spread: {
                  without_refunds: 1659.37,
                  with_refunds: 1696.5,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0007,
                  with_refunds: 0.0008,
                },
                gross_mrr: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0009,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.7995,
                  with_refunds: 0.7995,
                },
                gross_arr_spread: {
                  without_refunds: 0.7393,
                  with_refunds: 0.7393,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 0.127,
                  with_refunds: 0.1328,
                },
                gross_total_spread: {
                  without_refunds: 0.1292,
                  with_refunds: 0.1349,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_mrr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 4.24,
                  with_refunds: 4.24,
                },
                gross_arr_spread: {
                  without_refunds: 4.43,
                  with_refunds: 4.43,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 0.77,
                  with_refunds: 0.79,
                },
                gross_total_spread: {
                  without_refunds: 0.88,
                  with_refunds: 0.9,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.09,
                  with_refunds: 12.38,
                },
                gross_mrr: {
                  without_refunds: 14.61,
                  with_refunds: 14.97,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 12.32,
                  with_refunds: 12.32,
                },
                gross_arr_spread: {
                  without_refunds: 12.86,
                  with_refunds: 12.86,
                },
                net_total: {
                  without_refunds: 15.02,
                  with_refunds: 8.82,
                },
                gross_total: {
                  without_refunds: 16.23,
                  with_refunds: 10.36,
                },
                net_total_spread: {
                  without_refunds: 8.49,
                  with_refunds: 8.72,
                },
                gross_total_spread: {
                  without_refunds: 9.66,
                  with_refunds: 9.91,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 28.68,
                    with_refunds: 27.43,
                  },
                  gross_mrr: {
                    without_refunds: 32.11,
                    with_refunds: 30.69,
                  },
                  net_arr: {
                    without_refunds: 130.98,
                    with_refunds: 99.13,
                  },
                  gross_arr: {
                    without_refunds: 145.83,
                    with_refunds: 110.76,
                  },
                  net_arr_spread: {
                    without_refunds: 129.09,
                    with_refunds: 129.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 144.1,
                    with_refunds: 144.1,
                  },
                  net_total: {
                    without_refunds: 113.35,
                    with_refunds: 86.76,
                  },
                  gross_total: {
                    without_refunds: 126.22,
                    with_refunds: 96.94,
                  },
                  net_total_spread: {
                    without_refunds: 79.14,
                    with_refunds: 79.21,
                  },
                  gross_total_spread: {
                    without_refunds: 88.54,
                    with_refunds: 88.62,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 155.82,
                    with_refunds: 154.5,
                  },
                  gross_mrr: {
                    without_refunds: 184.85,
                    with_refunds: 183.54,
                  },
                  net_arr: {
                    without_refunds: 1423.06,
                    with_refunds: 192.97,
                  },
                  gross_arr: {
                    without_refunds: 1634.96,
                    with_refunds: 216.4,
                  },
                  net_arr_spread: {
                    without_refunds: 142.09,
                    with_refunds: 142.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 158.1,
                    with_refunds: 158.1,
                  },
                  net_total: {
                    without_refunds: 391.9,
                    with_refunds: 259.6,
                  },
                  gross_total: {
                    without_refunds: 436.32,
                    with_refunds: 296.74,
                  },
                  net_total_spread: {
                    without_refunds: 129.63,
                    with_refunds: 130.23,
                  },
                  gross_total_spread: {
                    without_refunds: 145.26,
                    with_refunds: 145.94,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.219,
                    with_refunds: 0.2094,
                  },
                  gross_mrr: {
                    without_refunds: 0.2451,
                    with_refunds: 0.2343,
                  },
                  net_arr: {
                    without_refunds: 0.9999,
                    with_refunds: 0.7568,
                  },
                  gross_arr: {
                    without_refunds: 1.1133,
                    with_refunds: 0.8456,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9855,
                    with_refunds: 0.9855,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1001,
                    with_refunds: 1.1001,
                  },
                  net_total: {
                    without_refunds: 0.8653,
                    with_refunds: 0.6623,
                  },
                  gross_total: {
                    without_refunds: 0.9636,
                    with_refunds: 0.7401,
                  },
                  net_total_spread: {
                    without_refunds: 0.6041,
                    with_refunds: 0.6047,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6759,
                    with_refunds: 0.6765,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.1895,
                    with_refunds: 1.1795,
                  },
                  gross_mrr: {
                    without_refunds: 1.4111,
                    with_refunds: 1.4012,
                  },
                  net_arr: {
                    without_refunds: 10.8639,
                    with_refunds: 1.4732,
                  },
                  gross_arr: {
                    without_refunds: 12.4816,
                    with_refunds: 1.652,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0848,
                    with_refunds: 1.0848,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2069,
                    with_refunds: 1.2069,
                  },
                  net_total: {
                    without_refunds: 2.9918,
                    with_refunds: 1.9818,
                  },
                  gross_total: {
                    without_refunds: 3.331,
                    with_refunds: 2.2654,
                  },
                  net_total_spread: {
                    without_refunds: 0.9896,
                    with_refunds: 0.9942,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1089,
                    with_refunds: 1.1141,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 328,
              with_refunds: 328,
            },
            returning_users: {
              without_refunds: 6613,
              with_refunds: 6654,
            },
            paying_users: {
              without_refunds: 6941,
              with_refunds: 6982,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 1071,
              with_refunds: 1071,
            },
            returning_users: {
              without_refunds: 1101,
              with_refunds: 1230,
            },
            paying_users: {
              without_refunds: 2172,
              with_refunds: 2301,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 1397,
              with_refunds: 1397,
            },
            returning_users: {
              without_refunds: 7708,
              with_refunds: 7866,
            },
            paying_users: {
              without_refunds: 9105,
              with_refunds: 9263,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 11756.53,
                with_refunds: 11226.18,
              },
              gross_mrr: {
                without_refunds: 13129.71,
                with_refunds: 12527.16,
              },
              net_arr: {
                without_refunds: 255497.19,
                with_refunds: 193406.92,
              },
              gross_arr: {
                without_refunds: 284467.86,
                with_refunds: 216101.69,
              },
              net_arr_spread: {
                without_refunds: 71290.67,
                with_refunds: 71290.67,
              },
              gross_arr_spread: {
                without_refunds: 79801.15,
                with_refunds: 79801.15,
              },
              net_total: {
                without_refunds: 267253.72,
                with_refunds: 204633.1,
              },
              gross_total: {
                without_refunds: 297597.57,
                with_refunds: 228628.84,
              },
              net_total_spread: {
                without_refunds: 83047.21,
                with_refunds: 82516.85,
              },
              gross_total_spread: {
                without_refunds: 92930.86,
                with_refunds: 92328.3,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 12075.44,
                with_refunds: 11547.51,
              },
              gross_mrr: {
                without_refunds: 13518.04,
                with_refunds: 12918.81,
              },
              net_arr: {
                without_refunds: 255533.22,
                with_refunds: 193406.92,
              },
              gross_arr: {
                without_refunds: 284507.86,
                with_refunds: 216101.69,
              },
              net_arr_spread: {
                without_refunds: 189894.31,
                with_refunds: 189894.31,
              },
              gross_arr_spread: {
                without_refunds: 211974.26,
                with_refunds: 211974.26,
              },
              net_total: {
                without_refunds: 267736.43,
                with_refunds: 204920.54,
              },
              gross_total: {
                without_refunds: 298133.61,
                with_refunds: 228973.62,
              },
              net_total_spread: {
                without_refunds: 149331.27,
                with_refunds: 149462.17,
              },
              gross_total_spread: {
                without_refunds: 167067.62,
                with_refunds: 167219.78,
              },
            },
          },
        },
      },
      cac: 130.99,
      mrr_ua_spend: {
        without_refunds: 55146.79,
        with_refunds: 55146.79,
      },
      arr_ua_spend: {
        without_refunds: 255561.49,
        with_refunds: 255561.49,
      },
      arr_spread_ua_spend: {
        without_refunds: 192686.29,
        with_refunds: 192686.29,
      },
      total_ua_spend: {
        without_refunds: 309398.38,
        with_refunds: 309398.38,
      },
      total_spread_ua_spend: {
        without_refunds: 247178.13,
        with_refunds: 247178.13,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 4,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 37,
        with_refunds: 37,
      },
      first_period_arr_users: {
        without_refunds: 928,
        with_refunds: 928,
      },
      first_period_arr_spread_users: {
        without_refunds: 750,
        with_refunds: 750,
      },
      first_period_total_users: {
        without_refunds: 963,
        with_refunds: 963,
      },
      first_period_total_spread_users: {
        without_refunds: 787,
        with_refunds: 787,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 20,
                  with_refunds: 20,
                },
                arr_users: {
                  without_refunds: 535,
                  with_refunds: 535,
                },
                arr_spread_users: {
                  without_refunds: 426,
                  with_refunds: 426,
                },
                total_users: {
                  without_refunds: 555,
                  with_refunds: 555,
                },
                total_spread_users: {
                  without_refunds: 446,
                  with_refunds: 446,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.5405,
                  with_refunds: 0.5405,
                },
                arr_users: {
                  without_refunds: 0.5765,
                  with_refunds: 0.5765,
                },
                arr_spread_users: {
                  without_refunds: 0.568,
                  with_refunds: 0.568,
                },
                total_users: {
                  without_refunds: 0.5763,
                  with_refunds: 0.5763,
                },
                total_spread_users: {
                  without_refunds: 0.5667,
                  with_refunds: 0.5667,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 202.93,
                  with_refunds: 202.93,
                },
                gross_mrr: {
                  without_refunds: 266.22,
                  with_refunds: 266.22,
                },
                net_arr: {
                  without_refunds: 71477.46,
                  with_refunds: 59301.72,
                },
                gross_arr: {
                  without_refunds: 78904.9,
                  with_refunds: 65461.69,
                },
                net_arr_spread: {
                  without_refunds: 4710.41,
                  with_refunds: 4710.41,
                },
                gross_arr_spread: {
                  without_refunds: 5208.09,
                  with_refunds: 5208.09,
                },
                net_total: {
                  without_refunds: 71680.39,
                  with_refunds: 59504.65,
                },
                gross_total: {
                  without_refunds: 79171.12,
                  with_refunds: 65727.91,
                },
                net_total_spread: {
                  without_refunds: 4913.34,
                  with_refunds: 4913.34,
                },
                gross_total_spread: {
                  without_refunds: 5474.3,
                  with_refunds: 5474.3,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 5.48,
                  with_refunds: 5.48,
                },
                gross_mrr: {
                  without_refunds: 7.2,
                  with_refunds: 7.2,
                },
                net_arr: {
                  without_refunds: 77.02,
                  with_refunds: 63.9,
                },
                gross_arr: {
                  without_refunds: 85.03,
                  with_refunds: 70.54,
                },
                net_arr_spread: {
                  without_refunds: 6.28,
                  with_refunds: 6.28,
                },
                gross_arr_spread: {
                  without_refunds: 6.94,
                  with_refunds: 6.94,
                },
                net_total: {
                  without_refunds: 74.43,
                  with_refunds: 61.79,
                },
                gross_total: {
                  without_refunds: 82.21,
                  with_refunds: 68.25,
                },
                net_total_spread: {
                  without_refunds: 6.24,
                  with_refunds: 6.24,
                },
                gross_total_spread: {
                  without_refunds: 6.96,
                  with_refunds: 6.96,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 10.15,
                  with_refunds: 10.15,
                },
                gross_mrr: {
                  without_refunds: 13.31,
                  with_refunds: 13.31,
                },
                net_arr: {
                  without_refunds: 133.6,
                  with_refunds: 110.84,
                },
                gross_arr: {
                  without_refunds: 147.49,
                  with_refunds: 122.36,
                },
                net_arr_spread: {
                  without_refunds: 11.06,
                  with_refunds: 11.06,
                },
                gross_arr_spread: {
                  without_refunds: 12.23,
                  with_refunds: 12.23,
                },
                net_total: {
                  without_refunds: 129.15,
                  with_refunds: 107.22,
                },
                gross_total: {
                  without_refunds: 142.65,
                  with_refunds: 118.43,
                },
                net_total_spread: {
                  without_refunds: 11.02,
                  with_refunds: 11.02,
                },
                gross_total_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 5.48,
                    with_refunds: 5.48,
                  },
                  gross_mrr: {
                    without_refunds: 7.2,
                    with_refunds: 7.2,
                  },
                  net_arr: {
                    without_refunds: 77.02,
                    with_refunds: 63.9,
                  },
                  gross_arr: {
                    without_refunds: 85.03,
                    with_refunds: 70.54,
                  },
                  net_arr_spread: {
                    without_refunds: 6.28,
                    with_refunds: 6.28,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.94,
                    with_refunds: 6.94,
                  },
                  net_total: {
                    without_refunds: 74.43,
                    with_refunds: 61.79,
                  },
                  gross_total: {
                    without_refunds: 82.21,
                    with_refunds: 68.25,
                  },
                  net_total_spread: {
                    without_refunds: 6.24,
                    with_refunds: 6.24,
                  },
                  gross_total_spread: {
                    without_refunds: 6.96,
                    with_refunds: 6.96,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 10.15,
                    with_refunds: 10.15,
                  },
                  gross_mrr: {
                    without_refunds: 13.31,
                    with_refunds: 13.31,
                  },
                  net_arr: {
                    without_refunds: 133.6,
                    with_refunds: 110.84,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 122.36,
                  },
                  net_arr_spread: {
                    without_refunds: 11.06,
                    with_refunds: 11.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.23,
                    with_refunds: 12.23,
                  },
                  net_total: {
                    without_refunds: 129.15,
                    with_refunds: 107.22,
                  },
                  gross_total: {
                    without_refunds: 142.65,
                    with_refunds: 118.43,
                  },
                  net_total_spread: {
                    without_refunds: 11.02,
                    with_refunds: 11.02,
                  },
                  gross_total_spread: {
                    without_refunds: 12.27,
                    with_refunds: 12.27,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.0677,
                    with_refunds: 0.0677,
                  },
                  gross_mrr: {
                    without_refunds: 0.0888,
                    with_refunds: 0.0888,
                  },
                  net_arr: {
                    without_refunds: 0.9504,
                    with_refunds: 0.7885,
                  },
                  gross_arr: {
                    without_refunds: 1.0492,
                    with_refunds: 0.8704,
                  },
                  net_arr_spread: {
                    without_refunds: 0.0775,
                    with_refunds: 0.0775,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.0857,
                    with_refunds: 0.0857,
                  },
                  net_total: {
                    without_refunds: 0.9185,
                    with_refunds: 0.7625,
                  },
                  gross_total: {
                    without_refunds: 1.0145,
                    with_refunds: 0.8422,
                  },
                  net_total_spread: {
                    without_refunds: 0.077,
                    with_refunds: 0.077,
                  },
                  gross_total_spread: {
                    without_refunds: 0.0858,
                    with_refunds: 0.0858,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1252,
                    with_refunds: 0.1252,
                  },
                  gross_mrr: {
                    without_refunds: 0.1643,
                    with_refunds: 0.1643,
                  },
                  net_arr: {
                    without_refunds: 1.6486,
                    with_refunds: 1.3678,
                  },
                  gross_arr: {
                    without_refunds: 1.8199,
                    with_refunds: 1.5099,
                  },
                  net_arr_spread: {
                    without_refunds: 0.1364,
                    with_refunds: 0.1364,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.1509,
                    with_refunds: 0.1509,
                  },
                  net_total: {
                    without_refunds: 1.5937,
                    with_refunds: 1.323,
                  },
                  gross_total: {
                    without_refunds: 1.7603,
                    with_refunds: 1.4614,
                  },
                  net_total_spread: {
                    without_refunds: 0.1359,
                    with_refunds: 0.1359,
                  },
                  gross_total_spread: {
                    without_refunds: 0.1515,
                    with_refunds: 0.1515,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 26,
                  with_refunds: 26,
                },
                arr_users: {
                  without_refunds: 387,
                  with_refunds: 407,
                },
                arr_spread_users: {
                  without_refunds: 744,
                  with_refunds: 744,
                },
                total_users: {
                  without_refunds: 413,
                  with_refunds: 433,
                },
                total_spread_users: {
                  without_refunds: 770,
                  with_refunds: 770,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.7027,
                  with_refunds: 0.7027,
                },
                arr_users: {
                  without_refunds: 0.417,
                  with_refunds: 0.4386,
                },
                arr_spread_users: {
                  without_refunds: 0.992,
                  with_refunds: 0.992,
                },
                total_users: {
                  without_refunds: 0.4289,
                  with_refunds: 0.4496,
                },
                total_spread_users: {
                  without_refunds: 0.9784,
                  with_refunds: 0.9784,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.3,
                  with_refunds: 1.3,
                },
                arr_users: {
                  without_refunds: 0.7234,
                  with_refunds: 0.7607,
                },
                arr_spread_users: {
                  without_refunds: 1.7465,
                  with_refunds: 1.7465,
                },
                total_users: {
                  without_refunds: 0.7441,
                  with_refunds: 0.7802,
                },
                total_spread_users: {
                  without_refunds: 1.7265,
                  with_refunds: 1.7265,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 337.74,
                  with_refunds: 337.74,
                },
                gross_mrr: {
                  without_refunds: 430.64,
                  with_refunds: 430.64,
                },
                net_arr: {
                  without_refunds: 57064.13,
                  with_refunds: 43980.13,
                },
                gross_arr: {
                  without_refunds: 60618.29,
                  with_refunds: 46716.71,
                },
                net_arr_spread: {
                  without_refunds: 8527.52,
                  with_refunds: 8527.52,
                },
                gross_arr_spread: {
                  without_refunds: 9267.03,
                  with_refunds: 9267.03,
                },
                net_total: {
                  without_refunds: 57401.87,
                  with_refunds: 44317.87,
                },
                gross_total: {
                  without_refunds: 61048.93,
                  with_refunds: 47147.35,
                },
                net_total_spread: {
                  without_refunds: 8865.26,
                  with_refunds: 8865.26,
                },
                gross_total_spread: {
                  without_refunds: 9697.67,
                  with_refunds: 9697.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.6643,
                  with_refunds: 1.6643,
                },
                gross_mrr: {
                  without_refunds: 1.6176,
                  with_refunds: 1.6176,
                },
                net_arr: {
                  without_refunds: 0.7984,
                  with_refunds: 0.7416,
                },
                gross_arr: {
                  without_refunds: 0.7682,
                  with_refunds: 0.7136,
                },
                net_arr_spread: {
                  without_refunds: 1.8104,
                  with_refunds: 1.8104,
                },
                gross_arr_spread: {
                  without_refunds: 1.7794,
                  with_refunds: 1.7794,
                },
                net_total: {
                  without_refunds: 0.8008,
                  with_refunds: 0.7448,
                },
                gross_total: {
                  without_refunds: 0.7711,
                  with_refunds: 0.7173,
                },
                net_total_spread: {
                  without_refunds: 1.8043,
                  with_refunds: 1.8043,
                },
                gross_total_spread: {
                  without_refunds: 1.7715,
                  with_refunds: 1.7715,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.6643,
                  with_refunds: 1.6643,
                },
                gross_mrr: {
                  without_refunds: 1.6176,
                  with_refunds: 1.6176,
                },
                net_arr: {
                  without_refunds: 0.7984,
                  with_refunds: 0.7416,
                },
                gross_arr: {
                  without_refunds: 0.7682,
                  with_refunds: 0.7136,
                },
                net_arr_spread: {
                  without_refunds: 1.8104,
                  with_refunds: 1.8104,
                },
                gross_arr_spread: {
                  without_refunds: 1.7794,
                  with_refunds: 1.7794,
                },
                net_total: {
                  without_refunds: 0.8008,
                  with_refunds: 0.7448,
                },
                gross_total: {
                  without_refunds: 0.7711,
                  with_refunds: 0.7173,
                },
                net_total_spread: {
                  without_refunds: 1.8043,
                  with_refunds: 1.8043,
                },
                gross_total_spread: {
                  without_refunds: 1.7715,
                  with_refunds: 1.7715,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 9.13,
                  with_refunds: 9.13,
                },
                gross_mrr: {
                  without_refunds: 11.64,
                  with_refunds: 11.64,
                },
                net_arr: {
                  without_refunds: 61.49,
                  with_refunds: 47.39,
                },
                gross_arr: {
                  without_refunds: 65.32,
                  with_refunds: 50.34,
                },
                net_arr_spread: {
                  without_refunds: 11.37,
                  with_refunds: 11.37,
                },
                gross_arr_spread: {
                  without_refunds: 12.36,
                  with_refunds: 12.36,
                },
                net_total: {
                  without_refunds: 59.61,
                  with_refunds: 46.02,
                },
                gross_total: {
                  without_refunds: 63.39,
                  with_refunds: 48.96,
                },
                net_total_spread: {
                  without_refunds: 11.26,
                  with_refunds: 11.26,
                },
                gross_total_spread: {
                  without_refunds: 12.32,
                  with_refunds: 12.32,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 12.99,
                  with_refunds: 12.99,
                },
                gross_mrr: {
                  without_refunds: 16.56,
                  with_refunds: 16.56,
                },
                net_arr: {
                  without_refunds: 147.45,
                  with_refunds: 108.06,
                },
                gross_arr: {
                  without_refunds: 156.64,
                  with_refunds: 114.78,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.46,
                  with_refunds: 12.46,
                },
                net_total: {
                  without_refunds: 138.99,
                  with_refunds: 102.35,
                },
                gross_total: {
                  without_refunds: 147.82,
                  with_refunds: 108.89,
                },
                net_total_spread: {
                  without_refunds: 11.51,
                  with_refunds: 11.51,
                },
                gross_total_spread: {
                  without_refunds: 12.59,
                  with_refunds: 12.59,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 14.61,
                    with_refunds: 14.61,
                  },
                  gross_mrr: {
                    without_refunds: 18.83,
                    with_refunds: 18.83,
                  },
                  net_arr: {
                    without_refunds: 138.51,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 150.35,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 17.65,
                    with_refunds: 17.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 19.3,
                    with_refunds: 19.3,
                  },
                  net_total: {
                    without_refunds: 134.04,
                    with_refunds: 107.81,
                  },
                  gross_total: {
                    without_refunds: 145.61,
                    with_refunds: 117.21,
                  },
                  net_total_spread: {
                    without_refunds: 17.51,
                    with_refunds: 17.51,
                  },
                  gross_total_spread: {
                    without_refunds: 19.28,
                    with_refunds: 19.28,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.14,
                    with_refunds: 23.14,
                  },
                  gross_mrr: {
                    without_refunds: 29.87,
                    with_refunds: 29.87,
                  },
                  net_arr: {
                    without_refunds: 281.06,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 304.12,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 22.52,
                    with_refunds: 22.52,
                  },
                  gross_arr_spread: {
                    without_refunds: 24.68,
                    with_refunds: 24.68,
                  },
                  net_total: {
                    without_refunds: 268.14,
                    with_refunds: 209.57,
                  },
                  gross_total: {
                    without_refunds: 290.47,
                    with_refunds: 227.31,
                  },
                  net_total_spread: {
                    without_refunds: 22.53,
                    with_refunds: 22.53,
                  },
                  gross_total_spread: {
                    without_refunds: 24.87,
                    with_refunds: 24.87,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.1803,
                    with_refunds: 0.1803,
                  },
                  gross_mrr: {
                    without_refunds: 0.2324,
                    with_refunds: 0.2324,
                  },
                  net_arr: {
                    without_refunds: 1.7092,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8552,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2178,
                    with_refunds: 0.2178,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.2382,
                    with_refunds: 0.2382,
                  },
                  net_total: {
                    without_refunds: 1.654,
                    with_refunds: 1.3303,
                  },
                  gross_total: {
                    without_refunds: 1.7967,
                    with_refunds: 1.4463,
                  },
                  net_total_spread: {
                    without_refunds: 0.216,
                    with_refunds: 0.216,
                  },
                  gross_total_spread: {
                    without_refunds: 0.2379,
                    with_refunds: 0.2379,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2855,
                    with_refunds: 0.2855,
                  },
                  gross_mrr: {
                    without_refunds: 0.3686,
                    with_refunds: 0.3686,
                  },
                  net_arr: {
                    without_refunds: 3.4681,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 3.7527,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.2779,
                    with_refunds: 0.2779,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3046,
                    with_refunds: 0.3046,
                  },
                  net_total: {
                    without_refunds: 3.3088,
                    with_refunds: 2.586,
                  },
                  gross_total: {
                    without_refunds: 3.5843,
                    with_refunds: 2.805,
                  },
                  net_total_spread: {
                    without_refunds: 0.278,
                    with_refunds: 0.278,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3069,
                    with_refunds: 0.3069,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 17,
                  with_refunds: 17,
                },
                arr_users: {
                  without_refunds: 6,
                  with_refunds: 12,
                },
                arr_spread_users: {
                  without_refunds: 749,
                  with_refunds: 749,
                },
                total_users: {
                  without_refunds: 23,
                  with_refunds: 29,
                },
                total_spread_users: {
                  without_refunds: 766,
                  with_refunds: 766,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4595,
                  with_refunds: 0.4595,
                },
                arr_users: {
                  without_refunds: 0.0065,
                  with_refunds: 0.0129,
                },
                arr_spread_users: {
                  without_refunds: 0.9987,
                  with_refunds: 0.9987,
                },
                total_users: {
                  without_refunds: 0.0239,
                  with_refunds: 0.0301,
                },
                total_spread_users: {
                  without_refunds: 0.9733,
                  with_refunds: 0.9733,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6538,
                  with_refunds: 0.6538,
                },
                arr_users: {
                  without_refunds: 0.0155,
                  with_refunds: 0.0295,
                },
                arr_spread_users: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                total_users: {
                  without_refunds: 0.0557,
                  with_refunds: 0.067,
                },
                total_spread_users: {
                  without_refunds: 0.9948,
                  with_refunds: 0.9948,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 230.92,
                  with_refunds: 230.92,
                },
                gross_mrr: {
                  without_refunds: 283.88,
                  with_refunds: 283.88,
                },
                net_arr: {
                  without_refunds: 764.49,
                },
                gross_arr: {
                  without_refunds: 804.22,
                },
                net_arr_spread: {
                  without_refunds: 8580,
                  with_refunds: 8580,
                },
                gross_arr_spread: {
                  without_refunds: 9322.47,
                  with_refunds: 9322.47,
                },
                net_total: {
                  without_refunds: 995.41,
                  with_refunds: 230.92,
                },
                gross_total: {
                  without_refunds: 1088.1,
                  with_refunds: 283.88,
                },
                net_total_spread: {
                  without_refunds: 8810.93,
                  with_refunds: 8810.93,
                },
                gross_total_spread: {
                  without_refunds: 9606.34,
                  with_refunds: 9606.34,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.1379,
                  with_refunds: 1.1379,
                },
                gross_mrr: {
                  without_refunds: 1.0663,
                  with_refunds: 1.0663,
                },
                net_arr: {
                  without_refunds: 0.0107,
                },
                gross_arr: {
                  without_refunds: 0.0102,
                },
                net_arr_spread: {
                  without_refunds: 1.8215,
                  with_refunds: 1.8215,
                },
                gross_arr_spread: {
                  without_refunds: 1.79,
                  with_refunds: 1.79,
                },
                net_total: {
                  without_refunds: 0.0139,
                  with_refunds: 0.0039,
                },
                gross_total: {
                  without_refunds: 0.0137,
                  with_refunds: 0.0043,
                },
                net_total_spread: {
                  without_refunds: 1.7933,
                  with_refunds: 1.7933,
                },
                gross_total_spread: {
                  without_refunds: 1.7548,
                  with_refunds: 1.7548,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6837,
                  with_refunds: 0.6837,
                },
                gross_mrr: {
                  without_refunds: 0.6592,
                  with_refunds: 0.6592,
                },
                net_arr: {
                  without_refunds: 0.0134,
                },
                gross_arr: {
                  without_refunds: 0.0133,
                },
                net_arr_spread: {
                  without_refunds: 1.0062,
                  with_refunds: 1.0062,
                },
                gross_arr_spread: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                net_total: {
                  without_refunds: 0.0173,
                  with_refunds: 0.0052,
                },
                gross_total: {
                  without_refunds: 0.0178,
                  with_refunds: 0.006,
                },
                net_total_spread: {
                  without_refunds: 0.9939,
                  with_refunds: 0.9939,
                },
                gross_total_spread: {
                  without_refunds: 0.9906,
                  with_refunds: 0.9906,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.24,
                  with_refunds: 6.24,
                },
                gross_mrr: {
                  without_refunds: 7.67,
                  with_refunds: 7.67,
                },
                net_arr: {
                  without_refunds: 0.82,
                },
                gross_arr: {
                  without_refunds: 0.87,
                },
                net_arr_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.44,
                },
                gross_arr_spread: {
                  without_refunds: 12.43,
                  with_refunds: 12.43,
                },
                net_total: {
                  without_refunds: 1.03,
                  with_refunds: 0.24,
                },
                gross_total: {
                  without_refunds: 1.13,
                  with_refunds: 0.29,
                },
                net_total_spread: {
                  without_refunds: 11.2,
                  with_refunds: 11.2,
                },
                gross_total_spread: {
                  without_refunds: 12.21,
                  with_refunds: 12.21,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 13.58,
                  with_refunds: 13.58,
                },
                gross_mrr: {
                  without_refunds: 16.7,
                  with_refunds: 16.7,
                },
                net_arr: {
                  without_refunds: 127.41,
                },
                gross_arr: {
                  without_refunds: 134.04,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.45,
                  with_refunds: 12.45,
                },
                net_total: {
                  without_refunds: 43.28,
                  with_refunds: 7.96,
                },
                gross_total: {
                  without_refunds: 47.31,
                  with_refunds: 9.79,
                },
                net_total_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.5,
                },
                gross_total_spread: {
                  without_refunds: 12.54,
                  with_refunds: 12.54,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 20.85,
                    with_refunds: 20.85,
                  },
                  gross_mrr: {
                    without_refunds: 26.51,
                    with_refunds: 26.51,
                  },
                  net_arr: {
                    without_refunds: 139.34,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.21,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 29.09,
                    with_refunds: 29.09,
                  },
                  gross_arr_spread: {
                    without_refunds: 31.73,
                    with_refunds: 31.73,
                  },
                  net_total: {
                    without_refunds: 135.08,
                    with_refunds: 108.05,
                  },
                  gross_total: {
                    without_refunds: 146.74,
                    with_refunds: 117.51,
                  },
                  net_total_spread: {
                    without_refunds: 28.7,
                    with_refunds: 28.7,
                  },
                  gross_total_spread: {
                    without_refunds: 31.48,
                    with_refunds: 31.48,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.72,
                    with_refunds: 36.72,
                  },
                  gross_mrr: {
                    without_refunds: 46.57,
                    with_refunds: 46.57,
                  },
                  net_arr: {
                    without_refunds: 408.47,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 438.16,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 33.97,
                    with_refunds: 33.97,
                  },
                  gross_arr_spread: {
                    without_refunds: 37.13,
                    with_refunds: 37.13,
                  },
                  net_total: {
                    without_refunds: 311.42,
                    with_refunds: 217.53,
                  },
                  gross_total: {
                    without_refunds: 337.78,
                    with_refunds: 237.1,
                  },
                  net_total_spread: {
                    without_refunds: 34.03,
                    with_refunds: 34.03,
                  },
                  gross_total_spread: {
                    without_refunds: 37.41,
                    with_refunds: 37.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2573,
                    with_refunds: 0.2573,
                  },
                  gross_mrr: {
                    without_refunds: 0.3271,
                    with_refunds: 0.3271,
                  },
                  net_arr: {
                    without_refunds: 1.7194,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8659,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.359,
                    with_refunds: 0.359,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.3915,
                    with_refunds: 0.3915,
                  },
                  net_total: {
                    without_refunds: 1.6668,
                    with_refunds: 1.3333,
                  },
                  gross_total: {
                    without_refunds: 1.8107,
                    with_refunds: 1.45,
                  },
                  net_total_spread: {
                    without_refunds: 0.3542,
                    with_refunds: 0.3542,
                  },
                  gross_total_spread: {
                    without_refunds: 0.3885,
                    with_refunds: 0.3885,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.4531,
                    with_refunds: 0.4531,
                  },
                  gross_mrr: {
                    without_refunds: 0.5747,
                    with_refunds: 0.5747,
                  },
                  net_arr: {
                    without_refunds: 5.0404,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 5.4067,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.4192,
                    with_refunds: 0.4192,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.4581,
                    with_refunds: 0.4581,
                  },
                  net_total: {
                    without_refunds: 3.8428,
                    with_refunds: 2.6842,
                  },
                  gross_total: {
                    without_refunds: 4.168,
                    with_refunds: 2.9258,
                  },
                  net_total_spread: {
                    without_refunds: 0.4199,
                    with_refunds: 0.4199,
                  },
                  gross_total_spread: {
                    without_refunds: 0.4616,
                    with_refunds: 0.4616,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 4,
                  with_refunds: 4,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 2,
                },
                arr_spread_users: {
                  without_refunds: 750,
                  with_refunds: 750,
                },
                total_users: {
                  without_refunds: 5,
                  with_refunds: 6,
                },
                total_spread_users: {
                  without_refunds: 754,
                  with_refunds: 754,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1081,
                  with_refunds: 0.1081,
                },
                arr_users: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0022,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.0052,
                  with_refunds: 0.0062,
                },
                total_spread_users: {
                  without_refunds: 0.9581,
                  with_refunds: 0.9581,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2353,
                  with_refunds: 0.2353,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0013,
                  with_refunds: 1.0013,
                },
                total_users: {
                  without_refunds: 0.2174,
                  with_refunds: 0.2069,
                },
                total_spread_users: {
                  without_refunds: 0.9843,
                  with_refunds: 0.9843,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 61.02,
                  with_refunds: 44.03,
                },
                gross_mrr: {
                  without_refunds: 77.07,
                  with_refunds: 57.08,
                },
                net_arr: {
                  without_refunds: 121.07,
                },
                gross_arr: {
                  without_refunds: 125,
                },
                net_arr_spread: {
                  without_refunds: 8590.09,
                  with_refunds: 8590.09,
                },
                gross_arr_spread: {
                  without_refunds: 9332.88,
                  with_refunds: 9332.88,
                },
                net_total: {
                  without_refunds: 182.09,
                  with_refunds: 44.03,
                },
                gross_total: {
                  without_refunds: 202.07,
                  with_refunds: 57.08,
                },
                net_total_spread: {
                  without_refunds: 8651.12,
                  with_refunds: 8634.13,
                },
                gross_total_spread: {
                  without_refunds: 9409.96,
                  with_refunds: 9389.97,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3007,
                  with_refunds: 0.217,
                },
                gross_mrr: {
                  without_refunds: 0.2895,
                  with_refunds: 0.2144,
                },
                net_arr: {
                  without_refunds: 0.0017,
                },
                gross_arr: {
                  without_refunds: 0.0016,
                },
                net_arr_spread: {
                  without_refunds: 1.8236,
                  with_refunds: 1.8236,
                },
                gross_arr_spread: {
                  without_refunds: 1.792,
                  with_refunds: 1.792,
                },
                net_total: {
                  without_refunds: 0.0025,
                  with_refunds: 0.0007,
                },
                gross_total: {
                  without_refunds: 0.0026,
                  with_refunds: 0.0009,
                },
                net_total_spread: {
                  without_refunds: 1.7607,
                  with_refunds: 1.7573,
                },
                gross_total_spread: {
                  without_refunds: 1.7189,
                  with_refunds: 1.7153,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2643,
                  with_refunds: 0.1907,
                },
                gross_mrr: {
                  without_refunds: 0.2715,
                  with_refunds: 0.2011,
                },
                net_arr: {
                  without_refunds: 0.1584,
                },
                gross_arr: {
                  without_refunds: 0.1554,
                },
                net_arr_spread: {
                  without_refunds: 1.0012,
                  with_refunds: 1.0012,
                },
                gross_arr_spread: {
                  without_refunds: 1.0011,
                  with_refunds: 1.0011,
                },
                net_total: {
                  without_refunds: 0.1829,
                  with_refunds: 0.1907,
                },
                gross_total: {
                  without_refunds: 0.1857,
                  with_refunds: 0.2011,
                },
                net_total_spread: {
                  without_refunds: 0.9819,
                  with_refunds: 0.9799,
                },
                gross_total_spread: {
                  without_refunds: 0.9796,
                  with_refunds: 0.9775,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.65,
                  with_refunds: 1.19,
                },
                gross_mrr: {
                  without_refunds: 2.08,
                  with_refunds: 1.54,
                },
                net_arr: {
                  without_refunds: 0.13,
                },
                gross_arr: {
                  without_refunds: 0.13,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.44,
                  with_refunds: 12.44,
                },
                net_total: {
                  without_refunds: 0.19,
                  with_refunds: 0.05,
                },
                gross_total: {
                  without_refunds: 0.21,
                  with_refunds: 0.06,
                },
                net_total_spread: {
                  without_refunds: 10.99,
                  with_refunds: 10.97,
                },
                gross_total_spread: {
                  without_refunds: 11.96,
                  with_refunds: 11.93,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.26,
                  with_refunds: 11.01,
                },
                gross_mrr: {
                  without_refunds: 19.27,
                  with_refunds: 14.27,
                },
                net_arr: {
                  without_refunds: 121.07,
                },
                gross_arr: {
                  without_refunds: 125,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.44,
                  with_refunds: 12.44,
                },
                net_total: {
                  without_refunds: 36.42,
                  with_refunds: 7.34,
                },
                gross_total: {
                  without_refunds: 40.41,
                  with_refunds: 9.51,
                },
                net_total_spread: {
                  without_refunds: 11.47,
                  with_refunds: 11.45,
                },
                gross_total_spread: {
                  without_refunds: 12.48,
                  with_refunds: 12.45,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.5,
                    with_refunds: 22.04,
                  },
                  gross_mrr: {
                    without_refunds: 28.59,
                    with_refunds: 28.05,
                  },
                  net_arr: {
                    without_refunds: 139.47,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.35,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 40.54,
                    with_refunds: 40.54,
                  },
                  gross_arr_spread: {
                    without_refunds: 44.17,
                    with_refunds: 44.17,
                  },
                  net_total: {
                    without_refunds: 135.26,
                    with_refunds: 108.1,
                  },
                  gross_total: {
                    without_refunds: 146.95,
                    with_refunds: 117.57,
                  },
                  net_total_spread: {
                    without_refunds: 39.7,
                    with_refunds: 39.67,
                  },
                  gross_total_spread: {
                    without_refunds: 43.44,
                    with_refunds: 43.42,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 51.98,
                    with_refunds: 47.73,
                  },
                  gross_mrr: {
                    without_refunds: 65.84,
                    with_refunds: 60.84,
                  },
                  net_arr: {
                    without_refunds: 529.54,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 563.16,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 45.43,
                    with_refunds: 45.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 49.57,
                    with_refunds: 49.57,
                  },
                  net_total: {
                    without_refunds: 347.84,
                    with_refunds: 224.87,
                  },
                  gross_total: {
                    without_refunds: 378.19,
                    with_refunds: 246.62,
                  },
                  net_total_spread: {
                    without_refunds: 45.51,
                    with_refunds: 45.48,
                  },
                  gross_total_spread: {
                    without_refunds: 49.89,
                    with_refunds: 49.86,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2777,
                    with_refunds: 0.272,
                  },
                  gross_mrr: {
                    without_refunds: 0.3528,
                    with_refunds: 0.3461,
                  },
                  net_arr: {
                    without_refunds: 1.721,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8676,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5003,
                    with_refunds: 0.5003,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.5451,
                    with_refunds: 0.5451,
                  },
                  net_total: {
                    without_refunds: 1.6691,
                    with_refunds: 1.3339,
                  },
                  gross_total: {
                    without_refunds: 1.8133,
                    with_refunds: 1.4507,
                  },
                  net_total_spread: {
                    without_refunds: 0.4898,
                    with_refunds: 0.4896,
                  },
                  gross_total_spread: {
                    without_refunds: 0.536,
                    with_refunds: 0.5357,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.6414,
                    with_refunds: 0.5889,
                  },
                  gross_mrr: {
                    without_refunds: 0.8125,
                    with_refunds: 0.7508,
                  },
                  net_arr: {
                    without_refunds: 6.5343,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 6.9491,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5606,
                    with_refunds: 0.5606,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6117,
                    with_refunds: 0.6117,
                  },
                  net_total: {
                    without_refunds: 4.2922,
                    with_refunds: 2.7748,
                  },
                  gross_total: {
                    without_refunds: 4.6667,
                    with_refunds: 3.0432,
                  },
                  net_total_spread: {
                    without_refunds: 0.5615,
                    with_refunds: 0.5612,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6156,
                    with_refunds: 0.6153,
                  },
                },
              },
            },
          },
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2.26,
                  with_refunds: 2.26,
                },
                arr_users: {
                  without_refunds: 0.42,
                  with_refunds: 0.76,
                },
                arr_spread_users: {
                  without_refunds: 752.75,
                  with_refunds: 752.75,
                },
                total_users: {
                  without_refunds: 2.81,
                  with_refunds: 3.37,
                },
                total_spread_users: {
                  without_refunds: 693.92,
                  with_refunds: 693.95,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.061,
                  with_refunds: 0.0611,
                },
                arr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0008,
                },
                arr_spread_users: {
                  without_refunds: 1.0037,
                  with_refunds: 1.0037,
                },
                total_users: {
                  without_refunds: 0.0029,
                  with_refunds: 0.0035,
                },
                total_spread_users: {
                  without_refunds: 0.8817,
                  with_refunds: 0.8818,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.5646,
                  with_refunds: 0.5653,
                },
                arr_users: {
                  without_refunds: 0.4222,
                  with_refunds: 0.3778,
                },
                arr_spread_users: {
                  without_refunds: 1.0037,
                  with_refunds: 1.0037,
                },
                total_users: {
                  without_refunds: 0.5622,
                  with_refunds: 0.5615,
                },
                total_spread_users: {
                  without_refunds: 0.9203,
                  with_refunds: 0.9204,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 34.4,
                  with_refunds: 24.8,
                },
                gross_mrr: {
                  without_refunds: 43.59,
                  with_refunds: 32.25,
                },
                net_arr: {
                  without_refunds: 54.77,
                },
                gross_arr: {
                  without_refunds: 51.4,
                },
                net_arr_spread: {
                  without_refunds: 8624.47,
                  with_refunds: 8624.47,
                },
                gross_arr_spread: {
                  without_refunds: 9373.94,
                  with_refunds: 9373.94,
                },
                net_total: {
                  without_refunds: 99.85,
                  with_refunds: 25.29,
                },
                gross_total: {
                  without_refunds: 109.75,
                  with_refunds: 32.73,
                },
                net_total_spread: {
                  without_refunds: 7838.5,
                  with_refunds: 7832.9,
                },
                gross_total_spread: {
                  without_refunds: 8524.1,
                  with_refunds: 8516.15,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1695,
                  with_refunds: 0.1222,
                },
                gross_mrr: {
                  without_refunds: 0.1637,
                  with_refunds: 0.1211,
                },
                net_arr: {
                  without_refunds: 0.0008,
                },
                gross_arr: {
                  without_refunds: 0.0007,
                },
                net_arr_spread: {
                  without_refunds: 1.8309,
                  with_refunds: 1.8309,
                },
                gross_arr_spread: {
                  without_refunds: 1.7999,
                  with_refunds: 1.7999,
                },
                net_total: {
                  without_refunds: 0.0014,
                  with_refunds: 0.0004,
                },
                gross_total: {
                  without_refunds: 0.0014,
                  with_refunds: 0.0005,
                },
                net_total_spread: {
                  without_refunds: 1.5954,
                  with_refunds: 1.5942,
                },
                gross_total_spread: {
                  without_refunds: 1.5571,
                  with_refunds: 1.5557,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5638,
                  with_refunds: 0.5631,
                },
                gross_mrr: {
                  without_refunds: 0.5656,
                  with_refunds: 0.5649,
                },
                net_arr: {
                  without_refunds: 0.4524,
                },
                gross_arr: {
                  without_refunds: 0.4112,
                },
                net_arr_spread: {
                  without_refunds: 1.004,
                  with_refunds: 1.004,
                },
                gross_arr_spread: {
                  without_refunds: 1.0044,
                  with_refunds: 1.0044,
                },
                net_total: {
                  without_refunds: 0.5483,
                  with_refunds: 0.5742,
                },
                gross_total: {
                  without_refunds: 0.5431,
                  with_refunds: 0.5733,
                },
                net_total_spread: {
                  without_refunds: 0.9061,
                  with_refunds: 0.9072,
                },
                gross_total_spread: {
                  without_refunds: 0.9059,
                  with_refunds: 0.9069,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.93,
                  with_refunds: 0.67,
                },
                gross_mrr: {
                  without_refunds: 1.18,
                  with_refunds: 0.87,
                },
                net_arr: {
                  without_refunds: 0.06,
                },
                gross_arr: {
                  without_refunds: 0.06,
                },
                net_arr_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.5,
                },
                gross_arr_spread: {
                  without_refunds: 12.5,
                  with_refunds: 12.5,
                },
                net_total: {
                  without_refunds: 0.1,
                  with_refunds: 0.03,
                },
                gross_total: {
                  without_refunds: 0.11,
                  with_refunds: 0.03,
                },
                net_total_spread: {
                  without_refunds: 9.96,
                  with_refunds: 9.95,
                },
                gross_total_spread: {
                  without_refunds: 10.83,
                  with_refunds: 10.82,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.24,
                  with_refunds: 10.97,
                },
                gross_mrr: {
                  without_refunds: 19.3,
                  with_refunds: 14.26,
                },
                net_arr: {
                  without_refunds: 129.73,
                },
                gross_arr: {
                  without_refunds: 121.73,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.45,
                  with_refunds: 12.45,
                },
                net_total: {
                  without_refunds: 35.52,
                  with_refunds: 7.51,
                },
                gross_total: {
                  without_refunds: 39.05,
                  with_refunds: 9.72,
                },
                net_total_spread: {
                  without_refunds: 11.3,
                  with_refunds: 11.29,
                },
                gross_total_spread: {
                  without_refunds: 12.28,
                  with_refunds: 12.27,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.43,
                    with_refunds: 22.71,
                  },
                  gross_mrr: {
                    without_refunds: 29.77,
                    with_refunds: 28.92,
                  },
                  net_arr: {
                    without_refunds: 139.53,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.4,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 52.04,
                    with_refunds: 52.04,
                  },
                  gross_arr_spread: {
                    without_refunds: 56.67,
                    with_refunds: 56.67,
                  },
                  net_total: {
                    without_refunds: 135.37,
                    with_refunds: 108.12,
                  },
                  gross_total: {
                    without_refunds: 147.06,
                    with_refunds: 117.6,
                  },
                  net_total_spread: {
                    without_refunds: 49.66,
                    with_refunds: 49.63,
                  },
                  gross_total_spread: {
                    without_refunds: 54.27,
                    with_refunds: 54.24,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 67.21,
                    with_refunds: 58.69,
                  },
                  gross_mrr: {
                    without_refunds: 85.14,
                    with_refunds: 75.1,
                  },
                  net_arr: {
                    without_refunds: 659.27,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 684.89,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 56.88,
                    with_refunds: 56.88,
                  },
                  gross_arr_spread: {
                    without_refunds: 62.02,
                    with_refunds: 62.02,
                  },
                  net_total: {
                    without_refunds: 383.36,
                    with_refunds: 232.37,
                  },
                  gross_total: {
                    without_refunds: 417.24,
                    with_refunds: 256.33,
                  },
                  net_total_spread: {
                    without_refunds: 56.8,
                    with_refunds: 56.77,
                  },
                  gross_total_spread: {
                    without_refunds: 62.17,
                    with_refunds: 62.13,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2892,
                    with_refunds: 0.2803,
                  },
                  gross_mrr: {
                    without_refunds: 0.3673,
                    with_refunds: 0.3569,
                  },
                  net_arr: {
                    without_refunds: 1.7217,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8683,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.6422,
                    with_refunds: 0.6422,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.6993,
                    with_refunds: 0.6993,
                  },
                  net_total: {
                    without_refunds: 1.6704,
                    with_refunds: 1.3342,
                  },
                  gross_total: {
                    without_refunds: 1.8147,
                    with_refunds: 1.4511,
                  },
                  net_total_spread: {
                    without_refunds: 0.6127,
                    with_refunds: 0.6124,
                  },
                  gross_total_spread: {
                    without_refunds: 0.6697,
                    with_refunds: 0.6693,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.8294,
                    with_refunds: 0.7243,
                  },
                  gross_mrr: {
                    without_refunds: 1.0506,
                    with_refunds: 0.9268,
                  },
                  net_arr: {
                    without_refunds: 8.1351,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 8.4513,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7019,
                    with_refunds: 0.7019,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.7654,
                    with_refunds: 0.7654,
                  },
                  net_total: {
                    without_refunds: 4.7305,
                    with_refunds: 2.8674,
                  },
                  gross_total: {
                    without_refunds: 5.1485,
                    with_refunds: 3.163,
                  },
                  net_total_spread: {
                    without_refunds: 0.7009,
                    with_refunds: 0.7005,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7672,
                    with_refunds: 0.7667,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.43,
                  with_refunds: 1.44,
                },
                arr_users: {
                  without_refunds: 0.1,
                  with_refunds: 0.28,
                },
                arr_spread_users: {
                  without_refunds: 753.14,
                  with_refunds: 753.14,
                },
                total_users: {
                  without_refunds: 1.76,
                  with_refunds: 2.13,
                },
                total_spread_users: {
                  without_refunds: 622.85,
                  with_refunds: 622.99,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0387,
                  with_refunds: 0.0388,
                },
                arr_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0003,
                },
                arr_spread_users: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                total_users: {
                  without_refunds: 0.0018,
                  with_refunds: 0.0022,
                },
                total_spread_users: {
                  without_refunds: 0.7914,
                  with_refunds: 0.7916,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6336,
                  with_refunds: 0.6351,
                },
                arr_users: {
                  without_refunds: 0.2273,
                  with_refunds: 0.3662,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6277,
                  with_refunds: 0.6322,
                },
                total_spread_users: {
                  without_refunds: 0.8976,
                  with_refunds: 0.8977,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 21.75,
                  with_refunds: 15.66,
                },
                gross_mrr: {
                  without_refunds: 27.4,
                  with_refunds: 20.29,
                },
                net_arr: {
                  without_refunds: 11.97,
                },
                gross_arr: {
                  without_refunds: 12.2,
                },
                net_arr_spread: {
                  without_refunds: 8628.85,
                  with_refunds: 8628.85,
                },
                gross_arr_spread: {
                  without_refunds: 9378.71,
                  with_refunds: 9378.71,
                },
                net_total: {
                  without_refunds: 58.18,
                  with_refunds: 15.06,
                },
                gross_total: {
                  without_refunds: 62.7,
                  with_refunds: 19.18,
                },
                net_total_spread: {
                  without_refunds: 6832.65,
                  with_refunds: 6845.7,
                },
                gross_total_spread: {
                  without_refunds: 7410.72,
                  with_refunds: 7426.63,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1072,
                  with_refunds: 0.0772,
                },
                gross_mrr: {
                  without_refunds: 0.1029,
                  with_refunds: 0.0762,
                },
                net_arr: {
                  without_refunds: 0.0002,
                },
                gross_arr: {
                  without_refunds: 0.0002,
                },
                net_arr_spread: {
                  without_refunds: 1.8319,
                  with_refunds: 1.8319,
                },
                gross_arr_spread: {
                  without_refunds: 1.8008,
                  with_refunds: 1.8008,
                },
                net_total: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0003,
                },
                gross_total: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0003,
                },
                net_total_spread: {
                  without_refunds: 1.3906,
                  with_refunds: 1.3933,
                },
                gross_total_spread: {
                  without_refunds: 1.3537,
                  with_refunds: 1.3566,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6321,
                  with_refunds: 0.6317,
                },
                gross_mrr: {
                  without_refunds: 0.6286,
                  with_refunds: 0.629,
                },
                net_arr: {
                  without_refunds: 0.2185,
                },
                gross_arr: {
                  without_refunds: 0.2373,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                net_total: {
                  without_refunds: 0.5827,
                  with_refunds: 0.5954,
                },
                gross_total: {
                  without_refunds: 0.5713,
                  with_refunds: 0.5861,
                },
                net_total_spread: {
                  without_refunds: 0.8717,
                  with_refunds: 0.874,
                },
                gross_total_spread: {
                  without_refunds: 0.8694,
                  with_refunds: 0.8721,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.59,
                  with_refunds: 0.42,
                },
                gross_mrr: {
                  without_refunds: 0.74,
                  with_refunds: 0.55,
                },
                net_arr: {
                  without_refunds: 0.01,
                },
                gross_arr: {
                  without_refunds: 0.01,
                },
                net_arr_spread: {
                  without_refunds: 11.51,
                  with_refunds: 11.51,
                },
                gross_arr_spread: {
                  without_refunds: 12.5,
                  with_refunds: 12.5,
                },
                net_total: {
                  without_refunds: 0.06,
                  with_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.07,
                  with_refunds: 0.02,
                },
                net_total_spread: {
                  without_refunds: 8.68,
                  with_refunds: 8.7,
                },
                gross_total_spread: {
                  without_refunds: 9.42,
                  with_refunds: 9.44,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.2,
                  with_refunds: 10.91,
                },
                gross_mrr: {
                  without_refunds: 19.15,
                  with_refunds: 14.12,
                },
                net_arr: {
                  without_refunds: 124.73,
                },
                gross_arr: {
                  without_refunds: 127.11,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.45,
                  with_refunds: 12.45,
                },
                net_total: {
                  without_refunds: 32.98,
                  with_refunds: 7.07,
                },
                gross_total: {
                  without_refunds: 35.54,
                  with_refunds: 9.01,
                },
                net_total_spread: {
                  without_refunds: 10.97,
                  with_refunds: 10.99,
                },
                gross_total_spread: {
                  without_refunds: 11.9,
                  with_refunds: 11.92,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.02,
                    with_refunds: 23.14,
                  },
                  gross_mrr: {
                    without_refunds: 30.51,
                    with_refunds: 29.47,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 63.55,
                    with_refunds: 63.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 69.18,
                    with_refunds: 69.18,
                  },
                  net_total: {
                    without_refunds: 135.43,
                    with_refunds: 108.14,
                  },
                  gross_total: {
                    without_refunds: 147.13,
                    with_refunds: 117.62,
                  },
                  net_total_spread: {
                    without_refunds: 58.34,
                    with_refunds: 58.33,
                  },
                  gross_total_spread: {
                    without_refunds: 63.69,
                    with_refunds: 63.67,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 82.41,
                    with_refunds: 69.6,
                  },
                  gross_mrr: {
                    without_refunds: 104.29,
                    with_refunds: 89.23,
                  },
                  net_arr: {
                    without_refunds: 784,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 812,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 68.34,
                    with_refunds: 68.34,
                  },
                  gross_arr_spread: {
                    without_refunds: 74.48,
                    with_refunds: 74.48,
                  },
                  net_total: {
                    without_refunds: 416.34,
                    with_refunds: 239.44,
                  },
                  gross_total: {
                    without_refunds: 452.78,
                    with_refunds: 265.34,
                  },
                  net_total_spread: {
                    without_refunds: 67.77,
                    with_refunds: 67.76,
                  },
                  gross_total_spread: {
                    without_refunds: 74.07,
                    with_refunds: 74.06,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.2964,
                    with_refunds: 0.2855,
                  },
                  gross_mrr: {
                    without_refunds: 0.3765,
                    with_refunds: 0.3636,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8684,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.7842,
                    with_refunds: 0.7842,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.8536,
                    with_refunds: 0.8536,
                  },
                  net_total: {
                    without_refunds: 1.6711,
                    with_refunds: 1.3344,
                  },
                  gross_total: {
                    without_refunds: 1.8155,
                    with_refunds: 1.4514,
                  },
                  net_total_spread: {
                    without_refunds: 0.7199,
                    with_refunds: 0.7197,
                  },
                  gross_total_spread: {
                    without_refunds: 0.7859,
                    with_refunds: 0.7857,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.0169,
                    with_refunds: 0.8588,
                  },
                  gross_mrr: {
                    without_refunds: 1.287,
                    with_refunds: 1.101,
                  },
                  net_arr: {
                    without_refunds: 9.6742,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 10.0198,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.8433,
                    with_refunds: 0.8433,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.919,
                    with_refunds: 0.919,
                  },
                  net_total: {
                    without_refunds: 5.1375,
                    with_refunds: 2.9546,
                  },
                  gross_total: {
                    without_refunds: 5.5871,
                    with_refunds: 3.2742,
                  },
                  net_total_spread: {
                    without_refunds: 0.8363,
                    with_refunds: 0.8361,
                  },
                  gross_total_spread: {
                    without_refunds: 0.914,
                    with_refunds: 0.9138,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.97,
                  with_refunds: 0.97,
                },
                arr_users: {
                  without_refunds: 0.02,
                  with_refunds: 0.14,
                },
                arr_spread_users: {
                  without_refunds: 754.63,
                  with_refunds: 754.63,
                },
                total_users: {
                  without_refunds: 1.2,
                  with_refunds: 1.44,
                },
                total_spread_users: {
                  without_refunds: 553.33,
                  with_refunds: 552.95,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0263,
                  with_refunds: 0.0263,
                },
                arr_users: {
                  with_refunds: 0.0001,
                },
                arr_spread_users: {
                  without_refunds: 1.0062,
                  with_refunds: 1.0062,
                },
                total_users: {
                  without_refunds: 0.0012,
                  with_refunds: 0.0015,
                },
                total_spread_users: {
                  without_refunds: 0.7031,
                  with_refunds: 0.7026,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6803,
                  with_refunds: 0.6785,
                },
                arr_users: {
                  without_refunds: 0.1905,
                  with_refunds: 0.4881,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6753,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8876,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 14.9,
                  with_refunds: 10.92,
                },
                gross_mrr: {
                  without_refunds: 18.74,
                  with_refunds: 14.09,
                },
                net_arr: {
                  without_refunds: 2.46,
                },
                gross_arr: {
                  without_refunds: 2.35,
                },
                net_arr_spread: {
                  without_refunds: 8649.31,
                  with_refunds: 8649.31,
                },
                gross_arr_spread: {
                  without_refunds: 9403.07,
                  with_refunds: 9403.07,
                },
                net_total: {
                  without_refunds: 38.8,
                  with_refunds: 10.39,
                },
                gross_total: {
                  without_refunds: 41.46,
                  with_refunds: 13.17,
                },
                net_total_spread: {
                  without_refunds: 5872.85,
                  with_refunds: 5924.98,
                },
                gross_total_spread: {
                  without_refunds: 6364.8,
                  with_refunds: 6419.4,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0734,
                  with_refunds: 0.0538,
                },
                gross_mrr: {
                  without_refunds: 0.0704,
                  with_refunds: 0.0529,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.8362,
                  with_refunds: 1.8362,
                },
                gross_arr_spread: {
                  without_refunds: 1.8055,
                  with_refunds: 1.8055,
                },
                net_total: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0002,
                },
                gross_total: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0002,
                },
                net_total_spread: {
                  without_refunds: 1.1953,
                  with_refunds: 1.2059,
                },
                gross_total_spread: {
                  without_refunds: 1.1627,
                  with_refunds: 1.1726,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6851,
                  with_refunds: 0.6973,
                },
                gross_mrr: {
                  without_refunds: 0.6838,
                  with_refunds: 0.6948,
                },
                net_arr: {
                  without_refunds: 0.2058,
                },
                gross_arr: {
                  without_refunds: 0.1926,
                },
                net_arr_spread: {
                  without_refunds: 1.0024,
                  with_refunds: 1.0024,
                },
                gross_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                net_total: {
                  without_refunds: 0.6669,
                  with_refunds: 0.6901,
                },
                gross_total: {
                  without_refunds: 0.6613,
                  with_refunds: 0.6867,
                },
                net_total_spread: {
                  without_refunds: 0.8595,
                  with_refunds: 0.8655,
                },
                gross_total_spread: {
                  without_refunds: 0.8589,
                  with_refunds: 0.8644,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.4,
                  with_refunds: 0.3,
                },
                gross_mrr: {
                  without_refunds: 0.51,
                  with_refunds: 0.38,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.53,
                  with_refunds: 11.53,
                },
                gross_arr_spread: {
                  without_refunds: 12.54,
                  with_refunds: 12.54,
                },
                net_total: {
                  without_refunds: 0.04,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.04,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 7.46,
                  with_refunds: 7.53,
                },
                gross_total_spread: {
                  without_refunds: 8.09,
                  with_refunds: 8.16,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.31,
                  with_refunds: 11.21,
                },
                gross_mrr: {
                  without_refunds: 19.25,
                  with_refunds: 14.46,
                },
                net_arr: {
                  without_refunds: 134.77,
                },
                gross_arr: {
                  without_refunds: 128.55,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.46,
                  with_refunds: 12.46,
                },
                net_total: {
                  without_refunds: 32.45,
                  with_refunds: 7.22,
                },
                gross_total: {
                  without_refunds: 34.67,
                  with_refunds: 9.16,
                },
                net_total_spread: {
                  without_refunds: 10.61,
                  with_refunds: 10.72,
                },
                gross_total_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.61,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.42,
                    with_refunds: 23.43,
                  },
                  gross_mrr: {
                    without_refunds: 31.01,
                    with_refunds: 29.85,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 75.08,
                    with_refunds: 75.08,
                  },
                  gross_arr_spread: {
                    without_refunds: 81.71,
                    with_refunds: 81.71,
                  },
                  net_total: {
                    without_refunds: 135.47,
                    with_refunds: 108.15,
                  },
                  gross_total: {
                    without_refunds: 147.17,
                    with_refunds: 117.63,
                  },
                  net_total_spread: {
                    without_refunds: 65.8,
                    with_refunds: 65.85,
                  },
                  gross_total_spread: {
                    without_refunds: 71.78,
                    with_refunds: 71.83,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 97.72,
                    with_refunds: 80.81,
                  },
                  gross_mrr: {
                    without_refunds: 123.54,
                    with_refunds: 103.69,
                  },
                  net_arr: {
                    without_refunds: 918.77,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 940.55,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 79.8,
                    with_refunds: 79.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 86.94,
                    with_refunds: 86.94,
                  },
                  net_total: {
                    without_refunds: 448.79,
                    with_refunds: 246.67,
                  },
                  gross_total: {
                    without_refunds: 487.45,
                    with_refunds: 274.5,
                  },
                  net_total_spread: {
                    without_refunds: 78.39,
                    with_refunds: 78.47,
                  },
                  gross_total_spread: {
                    without_refunds: 85.57,
                    with_refunds: 85.67,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3014,
                    with_refunds: 0.2891,
                  },
                  gross_mrr: {
                    without_refunds: 0.3827,
                    with_refunds: 0.3683,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8685,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9265,
                    with_refunds: 0.9265,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0083,
                    with_refunds: 1.0083,
                  },
                  net_total: {
                    without_refunds: 1.6716,
                    with_refunds: 1.3345,
                  },
                  gross_total: {
                    without_refunds: 1.816,
                    with_refunds: 1.4516,
                  },
                  net_total_spread: {
                    without_refunds: 0.8119,
                    with_refunds: 0.8126,
                  },
                  gross_total_spread: {
                    without_refunds: 0.8857,
                    with_refunds: 0.8864,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2058,
                    with_refunds: 0.9971,
                  },
                  gross_mrr: {
                    without_refunds: 1.5245,
                    with_refunds: 1.2795,
                  },
                  net_arr: {
                    without_refunds: 11.3372,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 11.606,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 0.9847,
                    with_refunds: 0.9847,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.0728,
                    with_refunds: 1.0728,
                  },
                  net_total: {
                    without_refunds: 5.5379,
                    with_refunds: 3.0438,
                  },
                  gross_total: {
                    without_refunds: 6.0149,
                    with_refunds: 3.3872,
                  },
                  net_total_spread: {
                    without_refunds: 0.9672,
                    with_refunds: 0.9683,
                  },
                  gross_total_spread: {
                    without_refunds: 1.056,
                    with_refunds: 1.0571,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.61,
                  with_refunds: 0.61,
                },
                arr_users: {
                  with_refunds: 0.02,
                },
                arr_spread_users: {
                  without_refunds: 755.01,
                  with_refunds: 755.01,
                },
                total_users: {
                  without_refunds: 0.74,
                  with_refunds: 0.89,
                },
                total_spread_users: {
                  without_refunds: 495.73,
                  with_refunds: 495.17,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0165,
                  with_refunds: 0.0165,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                total_users: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0009,
                },
                total_spread_users: {
                  without_refunds: 0.6299,
                  with_refunds: 0.6292,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.626,
                  with_refunds: 0.6268,
                },
                arr_users: {
                  without_refunds: 0.0833,
                  with_refunds: 0.1167,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                total_spread_users: {
                  without_refunds: 0.8959,
                  with_refunds: 0.8955,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 9.41,
                  with_refunds: 6.83,
                },
                gross_mrr: {
                  without_refunds: 11.84,
                  with_refunds: 8.83,
                },
                net_arr: {
                  without_refunds: 0.16,
                },
                gross_arr: {
                  without_refunds: 0.18,
                },
                net_arr_spread: {
                  without_refunds: 8653.82,
                  with_refunds: 8653.82,
                },
                gross_arr_spread: {
                  without_refunds: 9409.25,
                  with_refunds: 9409.25,
                },
                net_total: {
                  without_refunds: 22.08,
                  with_refunds: 5.89,
                },
                gross_total: {
                  without_refunds: 23.47,
                  with_refunds: 7.42,
                },
                net_total_spread: {
                  without_refunds: 5076.65,
                  with_refunds: 5117.34,
                },
                gross_total_spread: {
                  without_refunds: 5502.44,
                  with_refunds: 5547.6,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0464,
                  with_refunds: 0.0337,
                },
                gross_mrr: {
                  without_refunds: 0.0445,
                  with_refunds: 0.0332,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.8372,
                  with_refunds: 1.8372,
                },
                gross_arr_spread: {
                  without_refunds: 1.8067,
                  with_refunds: 1.8067,
                },
                net_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 1.0332,
                  with_refunds: 1.0415,
                },
                gross_total_spread: {
                  without_refunds: 1.0051,
                  with_refunds: 1.0134,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6258,
                },
                gross_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6268,
                },
                net_arr: {
                  without_refunds: 0.0657,
                },
                gross_arr: {
                  without_refunds: 0.0754,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.5691,
                  with_refunds: 0.5667,
                },
                gross_total: {
                  without_refunds: 0.566,
                  with_refunds: 0.5633,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8637,
                },
                gross_total_spread: {
                  without_refunds: 0.8645,
                  with_refunds: 0.8642,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.25,
                  with_refunds: 0.18,
                },
                gross_mrr: {
                  without_refunds: 0.32,
                  with_refunds: 0.24,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.54,
                  with_refunds: 11.54,
                },
                gross_arr_spread: {
                  without_refunds: 12.55,
                  with_refunds: 12.55,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 6.45,
                  with_refunds: 6.5,
                },
                gross_total_spread: {
                  without_refunds: 6.99,
                  with_refunds: 7.05,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.45,
                  with_refunds: 11.19,
                },
                gross_mrr: {
                  without_refunds: 19.43,
                  with_refunds: 14.46,
                },
                net_arr: {
                  without_refunds: 106.32,
                },
                gross_arr: {
                  without_refunds: 116.26,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.46,
                  with_refunds: 12.46,
                },
                net_total: {
                  without_refunds: 29.81,
                  with_refunds: 6.61,
                },
                gross_total: {
                  without_refunds: 31.67,
                  with_refunds: 8.33,
                },
                net_total_spread: {
                  without_refunds: 10.24,
                  with_refunds: 10.33,
                },
                gross_total_spread: {
                  without_refunds: 11.1,
                  with_refunds: 11.2,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.68,
                    with_refunds: 23.62,
                  },
                  gross_mrr: {
                    without_refunds: 31.33,
                    with_refunds: 30.09,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 86.62,
                    with_refunds: 86.62,
                  },
                  gross_arr_spread: {
                    without_refunds: 94.26,
                    with_refunds: 94.26,
                  },
                  net_total: {
                    without_refunds: 135.49,
                    with_refunds: 108.16,
                  },
                  gross_total: {
                    without_refunds: 147.19,
                    with_refunds: 117.64,
                  },
                  net_total_spread: {
                    without_refunds: 72.25,
                    with_refunds: 72.36,
                  },
                  gross_total_spread: {
                    without_refunds: 78.77,
                    with_refunds: 78.88,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 113.17,
                    with_refunds: 91.99,
                  },
                  gross_mrr: {
                    without_refunds: 142.98,
                    with_refunds: 118.16,
                  },
                  net_arr: {
                    without_refunds: 1025.09,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 1056.81,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 91.27,
                    with_refunds: 91.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 99.4,
                    with_refunds: 99.4,
                  },
                  net_total: {
                    without_refunds: 478.59,
                    with_refunds: 253.27,
                  },
                  gross_total: {
                    without_refunds: 519.12,
                    with_refunds: 282.82,
                  },
                  net_total_spread: {
                    without_refunds: 88.63,
                    with_refunds: 88.81,
                  },
                  gross_total_spread: {
                    without_refunds: 96.67,
                    with_refunds: 96.87,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3045,
                    with_refunds: 0.2914,
                  },
                  gross_mrr: {
                    without_refunds: 0.3867,
                    with_refunds: 0.3713,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8685,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 1.0688,
                    with_refunds: 1.0688,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.1631,
                    with_refunds: 1.1631,
                  },
                  net_total: {
                    without_refunds: 1.6719,
                    with_refunds: 1.3346,
                  },
                  gross_total: {
                    without_refunds: 1.8163,
                    with_refunds: 1.4516,
                  },
                  net_total_spread: {
                    without_refunds: 0.8915,
                    with_refunds: 0.8928,
                  },
                  gross_total_spread: {
                    without_refunds: 0.972,
                    with_refunds: 0.9733,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.3965,
                    with_refunds: 1.1352,
                  },
                  gross_mrr: {
                    without_refunds: 1.7643,
                    with_refunds: 1.458,
                  },
                  net_arr: {
                    without_refunds: 12.6492,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 13.0406,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1262,
                    with_refunds: 1.1262,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2266,
                    with_refunds: 1.2266,
                  },
                  net_total: {
                    without_refunds: 5.9057,
                    with_refunds: 3.1253,
                  },
                  gross_total: {
                    without_refunds: 6.4057,
                    with_refunds: 3.4899,
                  },
                  net_total_spread: {
                    without_refunds: 1.0936,
                    with_refunds: 1.0959,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1929,
                    with_refunds: 1.1953,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.4,
                  with_refunds: 0.4,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 756.51,
                  with_refunds: 756.51,
                },
                total_users: {
                  without_refunds: 0.49,
                  with_refunds: 0.59,
                },
                total_spread_users: {
                  without_refunds: 449.55,
                  with_refunds: 449.06,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0109,
                  with_refunds: 0.0109,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0087,
                  with_refunds: 1.0087,
                },
                total_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0006,
                },
                total_spread_users: {
                  without_refunds: 0.5712,
                  with_refunds: 0.5706,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {
                  without_refunds: 0.2778,
                  with_refunds: 0.2778,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 6.25,
                  with_refunds: 4.53,
                },
                gross_mrr: {
                  without_refunds: 7.86,
                  with_refunds: 5.85,
                },
                net_arr: {
                  without_refunds: 0.05,
                },
                gross_arr: {
                  without_refunds: 0.05,
                },
                net_arr_spread: {
                  without_refunds: 8675.3,
                  with_refunds: 8675.3,
                },
                gross_arr_spread: {
                  without_refunds: 9434.88,
                  with_refunds: 9434.88,
                },
                net_total: {
                  without_refunds: 14.53,
                  with_refunds: 3.91,
                },
                gross_total: {
                  without_refunds: 15.4,
                  with_refunds: 4.92,
                },
                net_total_spread: {
                  without_refunds: 4457.11,
                  with_refunds: 4493.87,
                },
                gross_total_spread: {
                  without_refunds: 4825.15,
                  with_refunds: 4865.9,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0308,
                  with_refunds: 0.0223,
                },
                gross_mrr: {
                  without_refunds: 0.0295,
                  with_refunds: 0.022,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.8417,
                  with_refunds: 1.8417,
                },
                gross_arr_spread: {
                  without_refunds: 1.8116,
                  with_refunds: 1.8116,
                },
                net_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.9071,
                  with_refunds: 0.9146,
                },
                gross_total_spread: {
                  without_refunds: 0.8814,
                  with_refunds: 0.8889,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {
                  without_refunds: 0.2848,
                },
                gross_arr: {
                  without_refunds: 0.285,
                },
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.17,
                  with_refunds: 0.12,
                },
                gross_mrr: {
                  without_refunds: 0.21,
                  with_refunds: 0.16,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.57,
                  with_refunds: 11.57,
                },
                gross_arr_spread: {
                  without_refunds: 12.58,
                  with_refunds: 12.58,
                },
                net_total: {
                  without_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 5.66,
                  with_refunds: 5.71,
                },
                gross_total_spread: {
                  without_refunds: 6.13,
                  with_refunds: 6.18,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.56,
                  with_refunds: 11.24,
                },
                gross_mrr: {
                  without_refunds: 19.55,
                  with_refunds: 14.52,
                },
                net_arr: {
                  without_refunds: 109,
                },
                gross_arr: {
                  without_refunds: 119.27,
                },
                net_arr_spread: {
                  without_refunds: 11.47,
                  with_refunds: 11.47,
                },
                gross_arr_spread: {
                  without_refunds: 12.47,
                  with_refunds: 12.47,
                },
                net_total: {
                  without_refunds: 29.78,
                  with_refunds: 6.66,
                },
                gross_total: {
                  without_refunds: 31.56,
                  with_refunds: 8.38,
                },
                net_total_spread: {
                  without_refunds: 9.91,
                  with_refunds: 10.01,
                },
                gross_total_spread: {
                  without_refunds: 10.73,
                  with_refunds: 10.84,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.85,
                    with_refunds: 23.74,
                  },
                  gross_mrr: {
                    without_refunds: 31.55,
                    with_refunds: 30.25,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 98.19,
                    with_refunds: 98.19,
                  },
                  gross_arr_spread: {
                    without_refunds: 106.84,
                    with_refunds: 106.84,
                  },
                  net_total: {
                    without_refunds: 135.51,
                    with_refunds: 108.16,
                  },
                  gross_total: {
                    without_refunds: 147.21,
                    with_refunds: 117.65,
                  },
                  net_total_spread: {
                    without_refunds: 77.91,
                    with_refunds: 78.07,
                  },
                  gross_total_spread: {
                    without_refunds: 84.9,
                    with_refunds: 85.06,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 128.73,
                    with_refunds: 103.23,
                  },
                  gross_mrr: {
                    without_refunds: 162.53,
                    with_refunds: 132.67,
                  },
                  net_arr: {
                    without_refunds: 1134.09,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 1176.08,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 102.73,
                    with_refunds: 102.73,
                  },
                  gross_arr_spread: {
                    without_refunds: 111.87,
                    with_refunds: 111.87,
                  },
                  net_total: {
                    without_refunds: 508.37,
                    with_refunds: 259.93,
                  },
                  gross_total: {
                    without_refunds: 550.68,
                    with_refunds: 291.2,
                  },
                  net_total_spread: {
                    without_refunds: 98.54,
                    with_refunds: 98.82,
                  },
                  gross_total_spread: {
                    without_refunds: 107.41,
                    with_refunds: 107.7,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3066,
                    with_refunds: 0.2929,
                  },
                  gross_mrr: {
                    without_refunds: 0.3893,
                    with_refunds: 0.3732,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8685,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2116,
                    with_refunds: 1.2116,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3184,
                    with_refunds: 1.3184,
                  },
                  net_total: {
                    without_refunds: 1.6721,
                    with_refunds: 1.3346,
                  },
                  gross_total: {
                    without_refunds: 1.8165,
                    with_refunds: 1.4517,
                  },
                  net_total_spread: {
                    without_refunds: 0.9614,
                    with_refunds: 0.9633,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0476,
                    with_refunds: 1.0496,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.5885,
                    with_refunds: 1.2738,
                  },
                  gross_mrr: {
                    without_refunds: 2.0055,
                    with_refunds: 1.6371,
                  },
                  net_arr: {
                    without_refunds: 13.9942,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 14.5123,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 1.2677,
                    with_refunds: 1.2677,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.3805,
                    with_refunds: 1.3805,
                  },
                  net_total: {
                    without_refunds: 6.2731,
                    with_refunds: 3.2075,
                  },
                  gross_total: {
                    without_refunds: 6.7951,
                    with_refunds: 3.5933,
                  },
                  net_total_spread: {
                    without_refunds: 1.216,
                    with_refunds: 1.2194,
                  },
                  gross_total_spread: {
                    without_refunds: 1.3254,
                    with_refunds: 1.329,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.27,
                  with_refunds: 0.27,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 758.66,
                  with_refunds: 758.66,
                },
                total_users: {
                  without_refunds: 0.32,
                  with_refunds: 0.39,
                },
                total_spread_users: {
                  without_refunds: 399.4,
                  with_refunds: 398.93,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0072,
                  with_refunds: 0.0072,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0115,
                  with_refunds: 1.0115,
                },
                total_users: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0004,
                },
                total_spread_users: {
                  without_refunds: 0.5075,
                  with_refunds: 0.5069,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {
                  without_refunds: 0.2222,
                  with_refunds: 0.2222,
                },
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4.14,
                  with_refunds: 3.01,
                },
                gross_mrr: {
                  without_refunds: 5.2,
                  with_refunds: 3.89,
                },
                net_arr: {
                  without_refunds: 0.01,
                },
                gross_arr: {
                  without_refunds: 0.01,
                },
                net_arr_spread: {
                  without_refunds: 8711.46,
                  with_refunds: 8711.46,
                },
                gross_arr_spread: {
                  without_refunds: 9470.46,
                  with_refunds: 9470.46,
                },
                net_total: {
                  without_refunds: 9.18,
                  with_refunds: 2.48,
                },
                gross_total: {
                  without_refunds: 9.67,
                  with_refunds: 3.11,
                },
                net_total_spread: {
                  without_refunds: 3826.54,
                  with_refunds: 3871.26,
                },
                gross_total_spread: {
                  without_refunds: 4140.73,
                  with_refunds: 4188.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0204,
                  with_refunds: 0.0149,
                },
                gross_mrr: {
                  without_refunds: 0.0195,
                  with_refunds: 0.0146,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.8494,
                  with_refunds: 1.8494,
                },
                gross_arr_spread: {
                  without_refunds: 1.8184,
                  with_refunds: 1.8184,
                },
                net_total: {
                  without_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.7788,
                  with_refunds: 0.7879,
                },
                gross_total_spread: {
                  without_refunds: 0.7564,
                  with_refunds: 0.7652,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {
                  without_refunds: 0.2001,
                },
                gross_arr: {
                  without_refunds: 0.2041,
                },
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.11,
                  with_refunds: 0.08,
                },
                gross_mrr: {
                  without_refunds: 0.14,
                  with_refunds: 0.11,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.62,
                  with_refunds: 11.62,
                },
                gross_arr_spread: {
                  without_refunds: 12.63,
                  with_refunds: 12.63,
                },
                net_total: {
                  without_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 4.86,
                  with_refunds: 4.92,
                },
                gross_total_spread: {
                  without_refunds: 5.26,
                  with_refunds: 5.32,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.55,
                  with_refunds: 11.27,
                },
                gross_mrr: {
                  without_refunds: 19.5,
                  with_refunds: 14.52,
                },
                net_arr: {
                  without_refunds: 98.16,
                },
                gross_arr: {
                  without_refunds: 109.52,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.48,
                  with_refunds: 12.48,
                },
                net_total: {
                  without_refunds: 28.5,
                  with_refunds: 6.4,
                },
                gross_total: {
                  without_refunds: 30.03,
                  with_refunds: 8.02,
                },
                net_total_spread: {
                  without_refunds: 9.58,
                  with_refunds: 9.7,
                },
                gross_total_spread: {
                  without_refunds: 10.37,
                  with_refunds: 10.5,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.96,
                    with_refunds: 23.82,
                  },
                  gross_mrr: {
                    without_refunds: 31.69,
                    with_refunds: 30.35,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 109.8,
                    with_refunds: 109.8,
                  },
                  gross_arr_spread: {
                    without_refunds: 119.47,
                    with_refunds: 119.47,
                  },
                  net_total: {
                    without_refunds: 135.52,
                    with_refunds: 108.16,
                  },
                  gross_total: {
                    without_refunds: 147.22,
                    with_refunds: 117.65,
                  },
                  net_total_spread: {
                    without_refunds: 82.78,
                    with_refunds: 82.99,
                  },
                  gross_total_spread: {
                    without_refunds: 90.16,
                    with_refunds: 90.38,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 144.28,
                    with_refunds: 114.5,
                  },
                  gross_mrr: {
                    without_refunds: 182.03,
                    with_refunds: 147.2,
                  },
                  net_arr: {
                    without_refunds: 1232.25,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 1285.6,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 114.22,
                    with_refunds: 114.22,
                  },
                  gross_arr_spread: {
                    without_refunds: 124.36,
                    with_refunds: 124.36,
                  },
                  net_total: {
                    without_refunds: 536.87,
                    with_refunds: 266.34,
                  },
                  gross_total: {
                    without_refunds: 580.71,
                    with_refunds: 299.22,
                  },
                  net_total_spread: {
                    without_refunds: 108.12,
                    with_refunds: 108.52,
                  },
                  gross_total_spread: {
                    without_refunds: 117.77,
                    with_refunds: 118.2,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.308,
                    with_refunds: 0.2939,
                  },
                  gross_mrr: {
                    without_refunds: 0.391,
                    with_refunds: 0.3745,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8685,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 1.3549,
                    with_refunds: 1.3549,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.4742,
                    with_refunds: 1.4742,
                  },
                  net_total: {
                    without_refunds: 1.6722,
                    with_refunds: 1.3347,
                  },
                  gross_total: {
                    without_refunds: 1.8166,
                    with_refunds: 1.4517,
                  },
                  net_total_spread: {
                    without_refunds: 1.0214,
                    with_refunds: 1.024,
                  },
                  gross_total_spread: {
                    without_refunds: 1.1125,
                    with_refunds: 1.1153,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.7803,
                    with_refunds: 1.4129,
                  },
                  gross_mrr: {
                    without_refunds: 2.2462,
                    with_refunds: 1.8163,
                  },
                  net_arr: {
                    without_refunds: 15.2054,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 15.8638,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4094,
                    with_refunds: 1.4094,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.5345,
                    with_refunds: 1.5345,
                  },
                  net_total: {
                    without_refunds: 6.6247,
                    with_refunds: 3.2865,
                  },
                  gross_total: {
                    without_refunds: 7.1657,
                    with_refunds: 3.6923,
                  },
                  net_total_spread: {
                    without_refunds: 1.3342,
                    with_refunds: 1.3391,
                  },
                  gross_total_spread: {
                    without_refunds: 1.4533,
                    with_refunds: 1.4586,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.19,
                  with_refunds: 0.19,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 762.91,
                  with_refunds: 762.91,
                },
                total_users: {
                  without_refunds: 0.23,
                  with_refunds: 0.27,
                },
                total_spread_users: {
                  without_refunds: 352.19,
                  with_refunds: 351.43,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0051,
                  with_refunds: 0.0051,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0172,
                  with_refunds: 1.0172,
                },
                total_users: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0003,
                },
                total_spread_users: {
                  without_refunds: 0.4475,
                  with_refunds: 0.4465,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2.92,
                  with_refunds: 2.14,
                },
                gross_mrr: {
                  without_refunds: 3.66,
                  with_refunds: 2.75,
                },
                net_arr: {
                  without_refunds: 0.01,
                },
                gross_arr: {
                  without_refunds: 0.01,
                },
                net_arr_spread: {
                  without_refunds: 8780.08,
                  with_refunds: 8780.08,
                },
                gross_arr_spread: {
                  without_refunds: 9533.95,
                  with_refunds: 9533.95,
                },
                net_total: {
                  without_refunds: 6.5,
                  with_refunds: 1.77,
                },
                gross_total: {
                  without_refunds: 6.84,
                  with_refunds: 2.21,
                },
                net_total_spread: {
                  without_refunds: 3264.29,
                  with_refunds: 3313.55,
                },
                gross_total_spread: {
                  without_refunds: 3538.55,
                  with_refunds: 3590.86,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0144,
                  with_refunds: 0.0105,
                },
                gross_mrr: {
                  without_refunds: 0.0138,
                  with_refunds: 0.0103,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.864,
                  with_refunds: 1.864,
                },
                gross_arr_spread: {
                  without_refunds: 1.8306,
                  with_refunds: 1.8306,
                },
                net_total: {
                  without_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.6644,
                  with_refunds: 0.6744,
                },
                gross_total_spread: {
                  without_refunds: 0.6464,
                  with_refunds: 0.6559,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {
                  without_refunds: 0.6705,
                },
                gross_arr: {
                  without_refunds: 0.6582,
                },
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.08,
                  with_refunds: 0.06,
                },
                gross_mrr: {
                  without_refunds: 0.1,
                  with_refunds: 0.07,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.71,
                },
                gross_arr_spread: {
                  without_refunds: 12.71,
                  with_refunds: 12.71,
                },
                net_total: {
                  without_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 4.15,
                  with_refunds: 4.21,
                },
                gross_total_spread: {
                  without_refunds: 4.5,
                  with_refunds: 4.56,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.53,
                  with_refunds: 11.32,
                },
                gross_mrr: {
                  without_refunds: 19.44,
                  with_refunds: 14.57,
                },
                net_arr: {
                  without_refunds: 98.73,
                },
                gross_arr: {
                  without_refunds: 108.13,
                },
                net_arr_spread: {
                  without_refunds: 11.51,
                  with_refunds: 11.51,
                },
                gross_arr_spread: {
                  without_refunds: 12.5,
                  with_refunds: 12.5,
                },
                net_total: {
                  without_refunds: 28.55,
                  with_refunds: 6.46,
                },
                gross_total: {
                  without_refunds: 30.04,
                  with_refunds: 8.07,
                },
                net_total_spread: {
                  without_refunds: 9.27,
                  with_refunds: 9.43,
                },
                gross_total_spread: {
                  without_refunds: 10.05,
                  with_refunds: 10.22,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.04,
                    with_refunds: 23.88,
                  },
                  gross_mrr: {
                    without_refunds: 31.79,
                    with_refunds: 30.43,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 121.51,
                    with_refunds: 121.51,
                  },
                  gross_arr_spread: {
                    without_refunds: 132.18,
                    with_refunds: 132.18,
                  },
                  net_total: {
                    without_refunds: 135.52,
                    with_refunds: 108.16,
                  },
                  gross_total: {
                    without_refunds: 147.23,
                    with_refunds: 117.65,
                  },
                  net_total_spread: {
                    without_refunds: 86.92,
                    with_refunds: 87.2,
                  },
                  gross_total_spread: {
                    without_refunds: 94.66,
                    with_refunds: 94.95,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 159.81,
                    with_refunds: 125.82,
                  },
                  gross_mrr: {
                    without_refunds: 201.48,
                    with_refunds: 161.76,
                  },
                  net_arr: {
                    without_refunds: 1330.97,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 1393.73,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 125.72,
                    with_refunds: 125.72,
                  },
                  gross_arr_spread: {
                    without_refunds: 136.85,
                    with_refunds: 136.85,
                  },
                  net_total: {
                    without_refunds: 565.42,
                    with_refunds: 272.8,
                  },
                  gross_total: {
                    without_refunds: 610.75,
                    with_refunds: 307.29,
                  },
                  net_total_spread: {
                    without_refunds: 117.39,
                    with_refunds: 117.95,
                  },
                  gross_total_spread: {
                    without_refunds: 127.82,
                    with_refunds: 128.42,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.309,
                    with_refunds: 0.2947,
                  },
                  gross_mrr: {
                    without_refunds: 0.3922,
                    with_refunds: 0.3754,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8685,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 1.4994,
                    with_refunds: 1.4994,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.631,
                    with_refunds: 1.631,
                  },
                  net_total: {
                    without_refunds: 1.6723,
                    with_refunds: 1.3347,
                  },
                  gross_total: {
                    without_refunds: 1.8167,
                    with_refunds: 1.4518,
                  },
                  net_total_spread: {
                    without_refunds: 1.0726,
                    with_refunds: 1.076,
                  },
                  gross_total_spread: {
                    without_refunds: 1.168,
                    with_refunds: 1.1716,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.972,
                    with_refunds: 1.5526,
                  },
                  gross_mrr: {
                    without_refunds: 2.4862,
                    with_refunds: 1.9961,
                  },
                  net_arr: {
                    without_refunds: 16.4236,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 17.1981,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 1.5514,
                    with_refunds: 1.5514,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.6887,
                    with_refunds: 1.6887,
                  },
                  net_total: {
                    without_refunds: 6.9771,
                    with_refunds: 3.3662,
                  },
                  gross_total: {
                    without_refunds: 7.5364,
                    with_refunds: 3.7919,
                  },
                  net_total_spread: {
                    without_refunds: 1.4485,
                    with_refunds: 1.4554,
                  },
                  gross_total_spread: {
                    without_refunds: 1.5773,
                    with_refunds: 1.5847,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.11,
                  with_refunds: 0.11,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 764.5,
                  with_refunds: 764.5,
                },
                total_users: {
                  without_refunds: 0.14,
                  with_refunds: 0.17,
                },
                total_spread_users: {
                  without_refunds: 257.54,
                  with_refunds: 256.88,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0031,
                  with_refunds: 0.0031,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0193,
                  with_refunds: 1.0193,
                },
                total_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0002,
                },
                total_spread_users: {
                  without_refunds: 0.3272,
                  with_refunds: 0.3264,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1.78,
                  with_refunds: 1.31,
                },
                gross_mrr: {
                  without_refunds: 2.24,
                  with_refunds: 1.69,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 8805.69,
                  with_refunds: 8805.69,
                },
                gross_arr_spread: {
                  without_refunds: 9555.79,
                  with_refunds: 9555.79,
                },
                net_total: {
                  without_refunds: 3.83,
                  with_refunds: 1.06,
                },
                gross_total: {
                  without_refunds: 4.01,
                  with_refunds: 1.33,
                },
                net_total_spread: {
                  without_refunds: 2250.77,
                  with_refunds: 2297.24,
                },
                gross_total_spread: {
                  without_refunds: 2510.51,
                  with_refunds: 2560.9,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0088,
                  with_refunds: 0.0065,
                },
                gross_mrr: {
                  without_refunds: 0.0084,
                  with_refunds: 0.0064,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.8694,
                  with_refunds: 1.8694,
                },
                gross_arr_spread: {
                  without_refunds: 1.8348,
                  with_refunds: 1.8348,
                },
                net_total: {
                  without_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.4581,
                  with_refunds: 0.4676,
                },
                gross_total_spread: {
                  without_refunds: 0.4586,
                  with_refunds: 0.4678,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {
                  without_refunds: 0.155,
                },
                gross_arr: {
                  without_refunds: 0.138,
                },
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.04,
                },
                gross_mrr: {
                  without_refunds: 0.06,
                  with_refunds: 0.05,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.74,
                  with_refunds: 11.74,
                },
                gross_arr_spread: {
                  without_refunds: 12.74,
                  with_refunds: 12.74,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 2.86,
                  with_refunds: 2.92,
                },
                gross_total_spread: {
                  without_refunds: 3.19,
                  with_refunds: 3.25,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.62,
                  with_refunds: 11.47,
                },
                gross_mrr: {
                  without_refunds: 19.6,
                  with_refunds: 14.79,
                },
                net_arr: {
                  without_refunds: 91.81,
                },
                gross_arr: {
                  without_refunds: 89.53,
                },
                net_arr_spread: {
                  without_refunds: 11.52,
                  with_refunds: 11.52,
                },
                gross_arr_spread: {
                  without_refunds: 12.5,
                  with_refunds: 12.5,
                },
                net_total: {
                  without_refunds: 27.87,
                  with_refunds: 6.43,
                },
                gross_total: {
                  without_refunds: 29.16,
                  with_refunds: 8.03,
                },
                net_total_spread: {
                  without_refunds: 8.74,
                  with_refunds: 8.94,
                },
                gross_total_spread: {
                  without_refunds: 9.75,
                  with_refunds: 9.97,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.09,
                    with_refunds: 23.91,
                  },
                  gross_mrr: {
                    without_refunds: 31.85,
                    with_refunds: 30.47,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 133.25,
                    with_refunds: 133.25,
                  },
                  gross_arr_spread: {
                    without_refunds: 144.92,
                    with_refunds: 144.92,
                  },
                  net_total: {
                    without_refunds: 135.53,
                    with_refunds: 108.17,
                  },
                  gross_total: {
                    without_refunds: 147.23,
                    with_refunds: 117.65,
                  },
                  net_total_spread: {
                    without_refunds: 89.78,
                    with_refunds: 90.11,
                  },
                  gross_total_spread: {
                    without_refunds: 97.85,
                    with_refunds: 98.2,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 175.43,
                    with_refunds: 137.29,
                  },
                  gross_mrr: {
                    without_refunds: 221.08,
                    with_refunds: 176.55,
                  },
                  net_arr: {
                    without_refunds: 1422.78,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 1483.26,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 137.24,
                    with_refunds: 137.24,
                  },
                  gross_arr_spread: {
                    without_refunds: 149.35,
                    with_refunds: 149.35,
                  },
                  net_total: {
                    without_refunds: 593.29,
                    with_refunds: 279.23,
                  },
                  gross_total: {
                    without_refunds: 639.91,
                    with_refunds: 315.32,
                  },
                  net_total_spread: {
                    without_refunds: 126.13,
                    with_refunds: 126.89,
                  },
                  gross_total_spread: {
                    without_refunds: 137.57,
                    with_refunds: 138.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3095,
                    with_refunds: 0.2951,
                  },
                  gross_mrr: {
                    without_refunds: 0.393,
                    with_refunds: 0.376,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8685,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6442,
                    with_refunds: 1.6442,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.7883,
                    with_refunds: 1.7883,
                  },
                  net_total: {
                    without_refunds: 1.6723,
                    with_refunds: 1.3347,
                  },
                  gross_total: {
                    without_refunds: 1.8168,
                    with_refunds: 1.4518,
                  },
                  net_total_spread: {
                    without_refunds: 1.1079,
                    with_refunds: 1.112,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2074,
                    with_refunds: 1.2118,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.1647,
                    with_refunds: 1.6941,
                  },
                  gross_mrr: {
                    without_refunds: 2.728,
                    with_refunds: 2.1786,
                  },
                  net_arr: {
                    without_refunds: 17.5565,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 18.3028,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6935,
                    with_refunds: 1.6935,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8429,
                    with_refunds: 1.8429,
                  },
                  net_total: {
                    without_refunds: 7.321,
                    with_refunds: 3.4456,
                  },
                  gross_total: {
                    without_refunds: 7.8962,
                    with_refunds: 3.891,
                  },
                  net_total_spread: {
                    without_refunds: 1.5564,
                    with_refunds: 1.5658,
                  },
                  gross_total_spread: {
                    without_refunds: 1.6976,
                    with_refunds: 1.7077,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 260.12,
                  with_refunds: 260.12,
                },
                total_users: {
                  without_refunds: 0.04,
                  with_refunds: 0.05,
                },
                total_spread_users: {
                  without_refunds: 80.56,
                  with_refunds: 80.25,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0009,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3468,
                  with_refunds: 0.3468,
                },
                total_users: {
                  with_refunds: 0.0001,
                },
                total_spread_users: {
                  without_refunds: 0.1024,
                  with_refunds: 0.102,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 0.53,
                  with_refunds: 0.39,
                },
                gross_mrr: {
                  without_refunds: 0.66,
                  with_refunds: 0.5,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 3380.07,
                  with_refunds: 3380.07,
                },
                gross_arr_spread: {
                  without_refunds: 3430.04,
                  with_refunds: 3430.04,
                },
                net_total: {
                  without_refunds: 1.13,
                  with_refunds: 0.31,
                },
                gross_total: {
                  without_refunds: 1.17,
                  with_refunds: 0.39,
                },
                net_total_spread: {
                  without_refunds: 710.89,
                  with_refunds: 726.64,
                },
                gross_total_spread: {
                  without_refunds: 785.14,
                  with_refunds: 801.96,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0026,
                  with_refunds: 0.0019,
                },
                gross_mrr: {
                  without_refunds: 0.0025,
                  with_refunds: 0.0019,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.7176,
                  with_refunds: 0.7176,
                },
                gross_arr_spread: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 0.1447,
                  with_refunds: 0.1479,
                },
                gross_total_spread: {
                  without_refunds: 0.1434,
                  with_refunds: 0.1465,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_mrr: {
                  without_refunds: 0.02,
                  with_refunds: 0.01,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 4.51,
                  with_refunds: 4.51,
                },
                gross_arr_spread: {
                  without_refunds: 4.57,
                  with_refunds: 4.57,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 0.9,
                  with_refunds: 0.92,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1.02,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 15.54,
                  with_refunds: 11.45,
                },
                gross_mrr: {
                  without_refunds: 19.39,
                  with_refunds: 14.68,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 12.99,
                  with_refunds: 12.99,
                },
                gross_arr_spread: {
                  without_refunds: 13.19,
                  with_refunds: 13.19,
                },
                net_total: {
                  without_refunds: 27.31,
                  with_refunds: 6.33,
                },
                gross_total: {
                  without_refunds: 28.38,
                  with_refunds: 7.84,
                },
                net_total_spread: {
                  without_refunds: 8.82,
                  with_refunds: 9.05,
                },
                gross_total_spread: {
                  without_refunds: 9.75,
                  with_refunds: 9.99,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.1,
                    with_refunds: 23.92,
                  },
                  gross_mrr: {
                    without_refunds: 31.86,
                    with_refunds: 30.49,
                  },
                  net_arr: {
                    without_refunds: 139.54,
                    with_refunds: 111.3,
                  },
                  gross_arr: {
                    without_refunds: 151.42,
                    with_refunds: 120.88,
                  },
                  net_arr_spread: {
                    without_refunds: 137.76,
                    with_refunds: 137.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 149.49,
                    with_refunds: 149.49,
                  },
                  net_total: {
                    without_refunds: 135.53,
                    with_refunds: 108.17,
                  },
                  gross_total: {
                    without_refunds: 147.23,
                    with_refunds: 117.65,
                  },
                  net_total_spread: {
                    without_refunds: 90.69,
                    with_refunds: 91.04,
                  },
                  gross_total_spread: {
                    without_refunds: 98.84,
                    with_refunds: 99.22,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 190.96,
                    with_refunds: 148.74,
                  },
                  gross_mrr: {
                    without_refunds: 240.46,
                    with_refunds: 191.23,
                  },
                  net_arr: {
                    without_refunds: 1422.78,
                    with_refunds: 218.9,
                  },
                  gross_arr: {
                    without_refunds: 1483.26,
                    with_refunds: 237.14,
                  },
                  net_arr_spread: {
                    without_refunds: 150.24,
                    with_refunds: 150.24,
                  },
                  gross_arr_spread: {
                    without_refunds: 162.54,
                    with_refunds: 162.54,
                  },
                  net_total: {
                    without_refunds: 620.6,
                    with_refunds: 285.56,
                  },
                  gross_total: {
                    without_refunds: 668.29,
                    with_refunds: 323.17,
                  },
                  net_total_spread: {
                    without_refunds: 134.95,
                    with_refunds: 135.95,
                  },
                  gross_total_spread: {
                    without_refunds: 147.32,
                    with_refunds: 148.38,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 0.3097,
                    with_refunds: 0.2952,
                  },
                  gross_mrr: {
                    without_refunds: 0.3932,
                    with_refunds: 0.3762,
                  },
                  net_arr: {
                    without_refunds: 1.7219,
                    with_refunds: 1.3733,
                  },
                  gross_arr: {
                    without_refunds: 1.8685,
                    with_refunds: 1.4916,
                  },
                  net_arr_spread: {
                    without_refunds: 1.6999,
                    with_refunds: 1.6999,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8447,
                    with_refunds: 1.8447,
                  },
                  net_total: {
                    without_refunds: 1.6724,
                    with_refunds: 1.3347,
                  },
                  gross_total: {
                    without_refunds: 1.8168,
                    with_refunds: 1.4518,
                  },
                  net_total_spread: {
                    without_refunds: 1.119,
                    with_refunds: 1.1234,
                  },
                  gross_total_spread: {
                    without_refunds: 1.2197,
                    with_refunds: 1.2243,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.3564,
                    with_refunds: 1.8354,
                  },
                  gross_mrr: {
                    without_refunds: 2.9672,
                    with_refunds: 2.3597,
                  },
                  net_arr: {
                    without_refunds: 17.5565,
                    with_refunds: 2.7012,
                  },
                  gross_arr: {
                    without_refunds: 18.3028,
                    with_refunds: 2.9262,
                  },
                  net_arr_spread: {
                    without_refunds: 1.8539,
                    with_refunds: 1.8539,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.0056,
                    with_refunds: 2.0056,
                  },
                  net_total: {
                    without_refunds: 7.658,
                    with_refunds: 3.5237,
                  },
                  gross_total: {
                    without_refunds: 8.2465,
                    with_refunds: 3.9877,
                  },
                  net_total_spread: {
                    without_refunds: 1.6653,
                    with_refunds: 1.6775,
                  },
                  gross_total_spread: {
                    without_refunds: 1.8178,
                    with_refunds: 1.831,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 20,
              with_refunds: 20,
            },
            returning_users: {
              without_refunds: 5585,
              with_refunds: 5618,
            },
            paying_users: {
              without_refunds: 5605,
              with_refunds: 5638,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 535,
              with_refunds: 535,
            },
            returning_users: {
              without_refunds: 947,
              with_refunds: 1081,
            },
            paying_users: {
              without_refunds: 1482,
              with_refunds: 1616,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 555,
              with_refunds: 555,
            },
            returning_users: {
              without_refunds: 6531,
              with_refunds: 6697,
            },
            paying_users: {
              without_refunds: 7086,
              with_refunds: 7252,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 832.61,
                with_refunds: 815.62,
              },
              gross_mrr: {
                without_refunds: 1057.81,
                with_refunds: 1037.82,
              },
              net_arr: {
                without_refunds: 129427.15,
                with_refunds: 103281.86,
              },
              gross_arr: {
                without_refunds: 140452.41,
                with_refunds: 112178.4,
              },
              net_arr_spread: {
                without_refunds: 30408.03,
                with_refunds: 30408.03,
              },
              gross_arr_spread: {
                without_refunds: 33130.46,
                with_refunds: 33130.46,
              },
              net_total: {
                without_refunds: 130259.77,
                with_refunds: 104097.48,
              },
              gross_total: {
                without_refunds: 141510.22,
                with_refunds: 113216.22,
              },
              net_total_spread: {
                without_refunds: 31240.64,
                with_refunds: 31223.65,
              },
              gross_total_spread: {
                without_refunds: 34188.28,
                with_refunds: 34168.29,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 928.71,
                with_refunds: 885.22,
              },
              gross_mrr: {
                without_refunds: 1178.99,
                with_refunds: 1127.96,
              },
              net_arr: {
                without_refunds: 129496.59,
                with_refunds: 103281.86,
              },
              gross_arr: {
                without_refunds: 140518.6,
                with_refunds: 112178.4,
              },
              net_arr_spread: {
                without_refunds: 103317.08,
                with_refunds: 103317.08,
              },
              gross_arr_spread: {
                without_refunds: 112120.56,
                with_refunds: 112120.56,
              },
              net_total: {
                without_refunds: 130513.85,
                with_refunds: 104163.63,
              },
              gross_total: {
                without_refunds: 141784.68,
                with_refunds: 113300.67,
              },
              net_total_spread: {
                without_refunds: 71370.9,
                with_refunds: 71647.14,
              },
              gross_total_spread: {
                without_refunds: 77790.43,
                with_refunds: 78086.54,
              },
            },
          },
        },
      },
      cac: 81.04,
      mrr_ua_spend: {
        without_refunds: 2998.48,
        with_refunds: 2998.48,
      },
      arr_ua_spend: {
        without_refunds: 75205.12,
        with_refunds: 75205.12,
      },
      arr_spread_ua_spend: {
        without_refunds: 60780,
        with_refunds: 60780,
      },
      total_ua_spend: {
        without_refunds: 78041.52,
        with_refunds: 78041.52,
      },
      total_spread_ua_spend: {
        without_refunds: 63778.48,
        with_refunds: 63778.48,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 5,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 337,
        with_refunds: 337,
      },
      first_period_arr_users: {
        without_refunds: 386,
        with_refunds: 386,
      },
      first_period_arr_spread_users: {
        without_refunds: 340,
        with_refunds: 340,
      },
      first_period_total_users: {
        without_refunds: 719,
        with_refunds: 719,
      },
      first_period_total_spread_users: {
        without_refunds: 676,
        with_refunds: 676,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                arr_users: {
                  without_refunds: 208,
                  with_refunds: 208,
                },
                arr_spread_users: {
                  without_refunds: 182,
                  with_refunds: 182,
                },
                total_users: {
                  without_refunds: 418,
                  with_refunds: 418,
                },
                total_spread_users: {
                  without_refunds: 394,
                  with_refunds: 394,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6291,
                  with_refunds: 0.6291,
                },
                arr_users: {
                  without_refunds: 0.5389,
                  with_refunds: 0.5389,
                },
                arr_spread_users: {
                  without_refunds: 0.5353,
                  with_refunds: 0.5353,
                },
                total_users: {
                  without_refunds: 0.5814,
                  with_refunds: 0.5814,
                },
                total_spread_users: {
                  without_refunds: 0.5828,
                  with_refunds: 0.5828,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4153.19,
                  with_refunds: 4004.05,
                },
                gross_mrr: {
                  without_refunds: 4867.1,
                  with_refunds: 4682.43,
                },
                net_arr: {
                  without_refunds: 28025.45,
                  with_refunds: 25295.94,
                },
                gross_arr: {
                  without_refunds: 30255.55,
                  with_refunds: 27346.05,
                },
                net_arr_spread: {
                  without_refunds: 2030.32,
                  with_refunds: 2030.32,
                },
                gross_arr_spread: {
                  without_refunds: 2198.63,
                  with_refunds: 2198.63,
                },
                net_total: {
                  without_refunds: 32178.64,
                  with_refunds: 29299.99,
                },
                gross_total: {
                  without_refunds: 35122.65,
                  with_refunds: 32028.48,
                },
                net_total_spread: {
                  without_refunds: 6183.51,
                  with_refunds: 6034.36,
                },
                gross_total_spread: {
                  without_refunds: 7065.73,
                  with_refunds: 6881.06,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 12.32,
                  with_refunds: 11.88,
                },
                gross_mrr: {
                  without_refunds: 14.44,
                  with_refunds: 13.89,
                },
                net_arr: {
                  without_refunds: 72.6,
                  with_refunds: 65.53,
                },
                gross_arr: {
                  without_refunds: 78.38,
                  with_refunds: 70.84,
                },
                net_arr_spread: {
                  without_refunds: 5.97,
                  with_refunds: 5.97,
                },
                gross_arr_spread: {
                  without_refunds: 6.47,
                  with_refunds: 6.47,
                },
                net_total: {
                  without_refunds: 44.75,
                  with_refunds: 40.75,
                },
                gross_total: {
                  without_refunds: 48.85,
                  with_refunds: 44.55,
                },
                net_total_spread: {
                  without_refunds: 9.15,
                  with_refunds: 8.93,
                },
                gross_total_spread: {
                  without_refunds: 10.45,
                  with_refunds: 10.18,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.59,
                  with_refunds: 18.89,
                },
                gross_mrr: {
                  without_refunds: 22.96,
                  with_refunds: 22.09,
                },
                net_arr: {
                  without_refunds: 134.74,
                  with_refunds: 121.62,
                },
                gross_arr: {
                  without_refunds: 145.46,
                  with_refunds: 131.47,
                },
                net_arr_spread: {
                  without_refunds: 11.16,
                  with_refunds: 11.16,
                },
                gross_arr_spread: {
                  without_refunds: 12.08,
                  with_refunds: 12.08,
                },
                net_total: {
                  without_refunds: 76.98,
                  with_refunds: 70.1,
                },
                gross_total: {
                  without_refunds: 84.03,
                  with_refunds: 76.62,
                },
                net_total_spread: {
                  without_refunds: 15.69,
                  with_refunds: 15.32,
                },
                gross_total_spread: {
                  without_refunds: 17.93,
                  with_refunds: 17.46,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 12.32,
                    with_refunds: 11.88,
                  },
                  gross_mrr: {
                    without_refunds: 14.44,
                    with_refunds: 13.89,
                  },
                  net_arr: {
                    without_refunds: 72.6,
                    with_refunds: 65.53,
                  },
                  gross_arr: {
                    without_refunds: 78.38,
                    with_refunds: 70.84,
                  },
                  net_arr_spread: {
                    without_refunds: 5.97,
                    with_refunds: 5.97,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.47,
                    with_refunds: 6.47,
                  },
                  net_total: {
                    without_refunds: 44.75,
                    with_refunds: 40.75,
                  },
                  gross_total: {
                    without_refunds: 48.85,
                    with_refunds: 44.55,
                  },
                  net_total_spread: {
                    without_refunds: 9.15,
                    with_refunds: 8.93,
                  },
                  gross_total_spread: {
                    without_refunds: 10.45,
                    with_refunds: 10.18,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 19.59,
                    with_refunds: 18.89,
                  },
                  gross_mrr: {
                    without_refunds: 22.96,
                    with_refunds: 22.09,
                  },
                  net_arr: {
                    without_refunds: 134.74,
                    with_refunds: 121.62,
                  },
                  gross_arr: {
                    without_refunds: 145.46,
                    with_refunds: 131.47,
                  },
                  net_arr_spread: {
                    without_refunds: 11.16,
                    with_refunds: 11.16,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.08,
                    with_refunds: 12.08,
                  },
                  net_total: {
                    without_refunds: 76.98,
                    with_refunds: 70.1,
                  },
                  gross_total: {
                    without_refunds: 84.03,
                    with_refunds: 76.62,
                  },
                  net_total_spread: {
                    without_refunds: 15.69,
                    with_refunds: 15.32,
                  },
                  gross_total_spread: {
                    without_refunds: 17.93,
                    with_refunds: 17.46,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.2349,
                    with_refunds: 1.1905,
                  },
                  gross_mrr: {
                    without_refunds: 1.4471,
                    with_refunds: 1.3922,
                  },
                  net_arr: {
                    without_refunds: 7.275,
                    with_refunds: 6.5665,
                  },
                  gross_arr: {
                    without_refunds: 7.8539,
                    with_refunds: 7.0987,
                  },
                  net_arr_spread: {
                    without_refunds: 0.5983,
                    with_refunds: 0.5983,
                  },
                  gross_arr_spread: {
                    without_refunds: 0.648,
                    with_refunds: 0.648,
                  },
                  net_total: {
                    without_refunds: 4.4844,
                    with_refunds: 4.0833,
                  },
                  gross_total: {
                    without_refunds: 4.8947,
                    with_refunds: 4.4635,
                  },
                  net_total_spread: {
                    without_refunds: 0.9166,
                    with_refunds: 0.8944,
                  },
                  gross_total_spread: {
                    without_refunds: 1.0473,
                    with_refunds: 1.0199,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 1.963,
                    with_refunds: 1.8925,
                  },
                  gross_mrr: {
                    without_refunds: 2.3004,
                    with_refunds: 2.2131,
                  },
                  net_arr: {
                    without_refunds: 13.5008,
                    with_refunds: 12.1859,
                  },
                  gross_arr: {
                    without_refunds: 14.5751,
                    with_refunds: 13.1735,
                  },
                  net_arr_spread: {
                    without_refunds: 1.1178,
                    with_refunds: 1.1178,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.2105,
                    with_refunds: 1.2105,
                  },
                  net_total: {
                    without_refunds: 7.7137,
                    with_refunds: 7.0236,
                  },
                  gross_total: {
                    without_refunds: 8.4194,
                    with_refunds: 7.6777,
                  },
                  net_total_spread: {
                    without_refunds: 1.5726,
                    with_refunds: 1.5346,
                  },
                  gross_total_spread: {
                    without_refunds: 1.7969,
                    with_refunds: 1.75,
                  },
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 225,
                  with_refunds: 225,
                },
                arr_users: {
                  without_refunds: 176,
                  with_refunds: 182,
                },
                arr_spread_users: {
                  without_refunds: 338,
                  with_refunds: 338,
                },
                total_users: {
                  without_refunds: 400,
                  with_refunds: 406,
                },
                total_spread_users: {
                  without_refunds: 563,
                  with_refunds: 563,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.6677,
                  with_refunds: 0.6677,
                },
                arr_users: {
                  without_refunds: 0.456,
                  with_refunds: 0.4715,
                },
                arr_spread_users: {
                  without_refunds: 0.9941,
                  with_refunds: 0.9941,
                },
                total_users: {
                  without_refunds: 0.5563,
                  with_refunds: 0.5647,
                },
                total_spread_users: {
                  without_refunds: 0.8328,
                  with_refunds: 0.8328,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1.0613,
                  with_refunds: 1.0613,
                },
                arr_users: {
                  without_refunds: 0.8462,
                  with_refunds: 0.875,
                },
                arr_spread_users: {
                  without_refunds: 1.8571,
                  with_refunds: 1.8571,
                },
                total_users: {
                  without_refunds: 0.9569,
                  with_refunds: 0.9713,
                },
                total_spread_users: {
                  without_refunds: 1.4289,
                  with_refunds: 1.4289,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4421.45,
                  with_refunds: 4253.36,
                },
                gross_mrr: {
                  without_refunds: 5016.77,
                  with_refunds: 4817.45,
                },
                net_arr: {
                  without_refunds: 25128.71,
                  with_refunds: 22005.52,
                },
                gross_arr: {
                  without_refunds: 26320.39,
                  with_refunds: 23096.39,
                },
                net_arr_spread: {
                  without_refunds: 3886.34,
                  with_refunds: 3886.34,
                },
                gross_arr_spread: {
                  without_refunds: 4146.33,
                  with_refunds: 4146.33,
                },
                net_total: {
                  without_refunds: 29550.16,
                  with_refunds: 26258.89,
                },
                gross_total: {
                  without_refunds: 31337.17,
                  with_refunds: 27913.84,
                },
                net_total_spread: {
                  without_refunds: 8307.79,
                  with_refunds: 8139.7,
                },
                gross_total_spread: {
                  without_refunds: 9163.1,
                  with_refunds: 8963.78,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1.0646,
                  with_refunds: 1.0623,
                },
                gross_mrr: {
                  without_refunds: 1.0308,
                  with_refunds: 1.0288,
                },
                net_arr: {
                  without_refunds: 0.8966,
                  with_refunds: 0.8699,
                },
                gross_arr: {
                  without_refunds: 0.8699,
                  with_refunds: 0.8446,
                },
                net_arr_spread: {
                  without_refunds: 1.9142,
                  with_refunds: 1.9142,
                },
                gross_arr_spread: {
                  without_refunds: 1.8859,
                  with_refunds: 1.8859,
                },
                net_total: {
                  without_refunds: 0.9183,
                  with_refunds: 0.8962,
                },
                gross_total: {
                  without_refunds: 0.8922,
                  with_refunds: 0.8715,
                },
                net_total_spread: {
                  without_refunds: 1.3435,
                  with_refunds: 1.3489,
                },
                gross_total_spread: {
                  without_refunds: 1.2968,
                  with_refunds: 1.3027,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1.0646,
                  with_refunds: 1.0623,
                },
                gross_mrr: {
                  without_refunds: 1.0308,
                  with_refunds: 1.0288,
                },
                net_arr: {
                  without_refunds: 0.8966,
                  with_refunds: 0.8699,
                },
                gross_arr: {
                  without_refunds: 0.8699,
                  with_refunds: 0.8446,
                },
                net_arr_spread: {
                  without_refunds: 1.9142,
                  with_refunds: 1.9142,
                },
                gross_arr_spread: {
                  without_refunds: 1.8859,
                  with_refunds: 1.8859,
                },
                net_total: {
                  without_refunds: 0.9183,
                  with_refunds: 0.8962,
                },
                gross_total: {
                  without_refunds: 0.8922,
                  with_refunds: 0.8715,
                },
                net_total_spread: {
                  without_refunds: 1.3435,
                  with_refunds: 1.3489,
                },
                gross_total_spread: {
                  without_refunds: 1.2968,
                  with_refunds: 1.3027,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 13.12,
                  with_refunds: 12.62,
                },
                gross_mrr: {
                  without_refunds: 14.89,
                  with_refunds: 14.3,
                },
                net_arr: {
                  without_refunds: 65.1,
                  with_refunds: 57.01,
                },
                gross_arr: {
                  without_refunds: 68.19,
                  with_refunds: 59.84,
                },
                net_arr_spread: {
                  without_refunds: 11.43,
                  with_refunds: 11.43,
                },
                gross_arr_spread: {
                  without_refunds: 12.2,
                  with_refunds: 12.2,
                },
                net_total: {
                  without_refunds: 41.1,
                  with_refunds: 36.52,
                },
                gross_total: {
                  without_refunds: 43.58,
                  with_refunds: 38.82,
                },
                net_total_spread: {
                  without_refunds: 12.29,
                  with_refunds: 12.04,
                },
                gross_total_spread: {
                  without_refunds: 13.55,
                  with_refunds: 13.26,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.65,
                  with_refunds: 18.9,
                },
                gross_mrr: {
                  without_refunds: 22.3,
                  with_refunds: 21.41,
                },
                net_arr: {
                  without_refunds: 142.78,
                  with_refunds: 120.91,
                },
                gross_arr: {
                  without_refunds: 149.55,
                  with_refunds: 126.9,
                },
                net_arr_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.5,
                },
                gross_arr_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
                net_total: {
                  without_refunds: 73.88,
                  with_refunds: 64.68,
                },
                gross_total: {
                  without_refunds: 78.34,
                  with_refunds: 68.75,
                },
                net_total_spread: {
                  without_refunds: 14.76,
                  with_refunds: 14.46,
                },
                gross_total_spread: {
                  without_refunds: 16.28,
                  with_refunds: 15.92,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.44,
                    with_refunds: 24.5,
                  },
                  gross_mrr: {
                    without_refunds: 29.33,
                    with_refunds: 28.19,
                  },
                  net_arr: {
                    without_refunds: 137.71,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 146.57,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 17.4,
                    with_refunds: 17.4,
                  },
                  gross_arr_spread: {
                    without_refunds: 18.66,
                    with_refunds: 18.66,
                  },
                  net_total: {
                    without_refunds: 85.85,
                    with_refunds: 77.27,
                  },
                  gross_total: {
                    without_refunds: 92.43,
                    with_refunds: 83.37,
                  },
                  net_total_spread: {
                    without_refunds: 21.44,
                    with_refunds: 20.97,
                  },
                  gross_total_spread: {
                    without_refunds: 24.01,
                    with_refunds: 23.44,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.24,
                    with_refunds: 37.79,
                  },
                  gross_mrr: {
                    without_refunds: 45.25,
                    with_refunds: 43.5,
                  },
                  net_arr: {
                    without_refunds: 277.51,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 295.01,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 22.65,
                    with_refunds: 22.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 24.35,
                    with_refunds: 24.35,
                  },
                  net_total: {
                    without_refunds: 150.86,
                    with_refunds: 134.77,
                  },
                  gross_total: {
                    without_refunds: 162.37,
                    with_refunds: 145.38,
                  },
                  net_total_spread: {
                    without_refunds: 30.45,
                    with_refunds: 29.77,
                  },
                  gross_total_spread: {
                    without_refunds: 34.21,
                    with_refunds: 33.39,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.5495,
                    with_refunds: 2.4552,
                  },
                  gross_mrr: {
                    without_refunds: 2.9388,
                    with_refunds: 2.8246,
                  },
                  net_arr: {
                    without_refunds: 13.7981,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.6864,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 1.7437,
                    with_refunds: 1.7437,
                  },
                  gross_arr_spread: {
                    without_refunds: 1.8699,
                    with_refunds: 1.8699,
                  },
                  net_total: {
                    without_refunds: 8.6026,
                    with_refunds: 7.7427,
                  },
                  gross_total: {
                    without_refunds: 9.2619,
                    with_refunds: 8.3536,
                  },
                  net_total_spread: {
                    without_refunds: 2.148,
                    with_refunds: 2.101,
                  },
                  gross_total_spread: {
                    without_refunds: 2.4055,
                    with_refunds: 2.3486,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.932,
                    with_refunds: 3.7867,
                  },
                  gross_mrr: {
                    without_refunds: 4.5345,
                    with_refunds: 4.3585,
                  },
                  net_arr: {
                    without_refunds: 27.8071,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 29.5598,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 2.2699,
                    with_refunds: 2.2699,
                  },
                  gross_arr_spread: {
                    without_refunds: 2.4396,
                    with_refunds: 2.4396,
                  },
                  net_total: {
                    without_refunds: 15.116,
                    with_refunds: 13.5043,
                  },
                  gross_total: {
                    without_refunds: 16.2694,
                    with_refunds: 14.5668,
                  },
                  net_total_spread: {
                    without_refunds: 3.0512,
                    with_refunds: 2.9833,
                  },
                  gross_total_spread: {
                    without_refunds: 3.4277,
                    with_refunds: 3.3453,
                  },
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 41,
                  with_refunds: 42,
                },
                arr_users: {
                  without_refunds: 2,
                  with_refunds: 5,
                },
                arr_spread_users: {
                  without_refunds: 340,
                  with_refunds: 340,
                },
                total_users: {
                  without_refunds: 43,
                  with_refunds: 47,
                },
                total_spread_users: {
                  without_refunds: 381,
                  with_refunds: 382,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1217,
                  with_refunds: 0.1246,
                },
                arr_users: {
                  without_refunds: 0.0052,
                  with_refunds: 0.013,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.0598,
                  with_refunds: 0.0654,
                },
                total_spread_users: {
                  without_refunds: 0.5636,
                  with_refunds: 0.5651,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.1822,
                  with_refunds: 0.1867,
                },
                arr_users: {
                  without_refunds: 0.0114,
                  with_refunds: 0.0275,
                },
                arr_spread_users: {
                  without_refunds: 1.0059,
                  with_refunds: 1.0059,
                },
                total_users: {
                  without_refunds: 0.1075,
                  with_refunds: 0.1158,
                },
                total_spread_users: {
                  without_refunds: 0.6767,
                  with_refunds: 0.6785,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 781.09,
                  with_refunds: 726.09,
                },
                gross_mrr: {
                  without_refunds: 846.33,
                  with_refunds: 785.03,
                },
                net_arr: {
                  without_refunds: 218.25,
                },
                gross_arr: {
                  without_refunds: 267.91,
                },
                net_arr_spread: {
                  without_refunds: 3904.53,
                  with_refunds: 3904.53,
                },
                gross_arr_spread: {
                  without_refunds: 4168.65,
                  with_refunds: 4168.65,
                },
                net_total: {
                  without_refunds: 999.34,
                  with_refunds: 726.09,
                },
                gross_total: {
                  without_refunds: 1114.24,
                  with_refunds: 785.03,
                },
                net_total_spread: {
                  without_refunds: 4685.62,
                  with_refunds: 4630.62,
                },
                gross_total_spread: {
                  without_refunds: 5014.98,
                  with_refunds: 4953.69,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1881,
                  with_refunds: 0.1813,
                },
                gross_mrr: {
                  without_refunds: 0.1739,
                  with_refunds: 0.1677,
                },
                net_arr: {
                  without_refunds: 0.0078,
                },
                gross_arr: {
                  without_refunds: 0.0089,
                },
                net_arr_spread: {
                  without_refunds: 1.9231,
                  with_refunds: 1.9231,
                },
                gross_arr_spread: {
                  without_refunds: 1.896,
                  with_refunds: 1.896,
                },
                net_total: {
                  without_refunds: 0.0311,
                  with_refunds: 0.0248,
                },
                gross_total: {
                  without_refunds: 0.0317,
                  with_refunds: 0.0245,
                },
                net_total_spread: {
                  without_refunds: 0.7578,
                  with_refunds: 0.7674,
                },
                gross_total_spread: {
                  without_refunds: 0.7098,
                  with_refunds: 0.7199,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.1767,
                  with_refunds: 0.1707,
                },
                gross_mrr: {
                  without_refunds: 0.1687,
                  with_refunds: 0.163,
                },
                net_arr: {
                  without_refunds: 0.0087,
                },
                gross_arr: {
                  without_refunds: 0.0102,
                },
                net_arr_spread: {
                  without_refunds: 1.0047,
                  with_refunds: 1.0047,
                },
                gross_arr_spread: {
                  without_refunds: 1.0054,
                  with_refunds: 1.0054,
                },
                net_total: {
                  without_refunds: 0.0338,
                  with_refunds: 0.0277,
                },
                gross_total: {
                  without_refunds: 0.0356,
                  with_refunds: 0.0281,
                },
                net_total_spread: {
                  without_refunds: 0.564,
                  with_refunds: 0.5689,
                },
                gross_total_spread: {
                  without_refunds: 0.5473,
                  with_refunds: 0.5526,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.32,
                  with_refunds: 2.15,
                },
                gross_mrr: {
                  without_refunds: 2.51,
                  with_refunds: 2.33,
                },
                net_arr: {
                  without_refunds: 0.57,
                },
                gross_arr: {
                  without_refunds: 0.69,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 1.39,
                  with_refunds: 1.01,
                },
                gross_total: {
                  without_refunds: 1.55,
                  with_refunds: 1.09,
                },
                net_total_spread: {
                  without_refunds: 6.93,
                  with_refunds: 6.85,
                },
                gross_total_spread: {
                  without_refunds: 7.42,
                  with_refunds: 7.33,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.05,
                  with_refunds: 17.29,
                },
                gross_mrr: {
                  without_refunds: 20.64,
                  with_refunds: 18.69,
                },
                net_arr: {
                  without_refunds: 109.12,
                },
                gross_arr: {
                  without_refunds: 133.95,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 23.24,
                  with_refunds: 15.45,
                },
                gross_total: {
                  without_refunds: 25.91,
                  with_refunds: 16.7,
                },
                net_total_spread: {
                  without_refunds: 12.3,
                  with_refunds: 12.12,
                },
                gross_total_spread: {
                  without_refunds: 13.16,
                  with_refunds: 12.97,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 27.76,
                    with_refunds: 26.66,
                  },
                  gross_mrr: {
                    without_refunds: 31.84,
                    with_refunds: 30.52,
                  },
                  net_arr: {
                    without_refunds: 138.27,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.26,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 28.89,
                    with_refunds: 28.89,
                  },
                  gross_arr_spread: {
                    without_refunds: 30.92,
                    with_refunds: 30.92,
                  },
                  net_total: {
                    without_refunds: 87.24,
                    with_refunds: 78.28,
                  },
                  gross_total: {
                    without_refunds: 93.98,
                    with_refunds: 84.46,
                  },
                  net_total_spread: {
                    without_refunds: 28.37,
                    with_refunds: 27.82,
                  },
                  gross_total_spread: {
                    without_refunds: 31.43,
                    with_refunds: 30.77,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 58.29,
                    with_refunds: 55.08,
                  },
                  gross_mrr: {
                    without_refunds: 65.9,
                    with_refunds: 62.19,
                  },
                  net_arr: {
                    without_refunds: 386.64,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 428.96,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 34.14,
                    with_refunds: 34.14,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.61,
                    with_refunds: 36.61,
                  },
                  net_total: {
                    without_refunds: 174.1,
                    with_refunds: 150.22,
                  },
                  gross_total: {
                    without_refunds: 188.28,
                    with_refunds: 162.08,
                  },
                  net_total_spread: {
                    without_refunds: 42.75,
                    with_refunds: 41.9,
                  },
                  gross_total_spread: {
                    without_refunds: 47.37,
                    with_refunds: 46.35,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.7817,
                    with_refunds: 2.6711,
                  },
                  gross_mrr: {
                    without_refunds: 3.1904,
                    with_refunds: 3.058,
                  },
                  net_arr: {
                    without_refunds: 13.8548,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7559,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 2.8944,
                    with_refunds: 2.8944,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.0984,
                    with_refunds: 3.0984,
                  },
                  net_total: {
                    without_refunds: 8.7418,
                    with_refunds: 7.8439,
                  },
                  gross_total: {
                    without_refunds: 9.4172,
                    with_refunds: 8.463,
                  },
                  net_total_spread: {
                    without_refunds: 2.8425,
                    with_refunds: 2.7873,
                  },
                  gross_total_spread: {
                    without_refunds: 3.1489,
                    with_refunds: 3.0829,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 5.8409,
                    with_refunds: 5.5189,
                  },
                  gross_mrr: {
                    without_refunds: 6.6029,
                    with_refunds: 6.2314,
                  },
                  net_arr: {
                    without_refunds: 38.7414,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 42.9821,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 3.4206,
                    with_refunds: 3.4206,
                  },
                  gross_arr_spread: {
                    without_refunds: 3.6682,
                    with_refunds: 3.6682,
                  },
                  net_total: {
                    without_refunds: 17.4447,
                    with_refunds: 15.0523,
                  },
                  gross_total: {
                    without_refunds: 18.8658,
                    with_refunds: 16.2404,
                  },
                  net_total_spread: {
                    without_refunds: 4.2834,
                    with_refunds: 4.1979,
                  },
                  gross_total_spread: {
                    without_refunds: 4.7466,
                    with_refunds: 4.6447,
                  },
                },
              },
            },
          },
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 25.04,
                  with_refunds: 25.43,
                },
                arr_users: {
                  without_refunds: 0.44,
                  with_refunds: 0.82,
                },
                arr_spread_users: {
                  without_refunds: 340.63,
                  with_refunds: 340.63,
                },
                total_users: {
                  without_refunds: 25.06,
                  with_refunds: 25.33,
                },
                total_spread_users: {
                  without_refunds: 366.93,
                  with_refunds: 367.1,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0743,
                  with_refunds: 0.0754,
                },
                arr_users: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0021,
                },
                arr_spread_users: {
                  without_refunds: 1.0019,
                  with_refunds: 1.0019,
                },
                total_users: {
                  without_refunds: 0.0348,
                  with_refunds: 0.0352,
                },
                total_spread_users: {
                  without_refunds: 0.5428,
                  with_refunds: 0.5431,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6108,
                  with_refunds: 0.6054,
                },
                arr_users: {
                  without_refunds: 0.2219,
                  with_refunds: 0.1643,
                },
                arr_spread_users: {
                  without_refunds: 1.0019,
                  with_refunds: 1.0019,
                },
                total_users: {
                  without_refunds: 0.5827,
                  with_refunds: 0.539,
                },
                total_spread_users: {
                  without_refunds: 0.9631,
                  with_refunds: 0.961,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 491.79,
                  with_refunds: 450.29,
                },
                gross_mrr: {
                  without_refunds: 531.54,
                  with_refunds: 486.56,
                },
                net_arr: {
                  without_refunds: 43.62,
                },
                gross_arr: {
                  without_refunds: 56.15,
                },
                net_arr_spread: {
                  without_refunds: 3910.59,
                  with_refunds: 3910.59,
                },
                gross_arr_spread: {
                  without_refunds: 4176.28,
                  with_refunds: 4176.28,
                },
                net_total: {
                  without_refunds: 448.46,
                  with_refunds: 450.29,
                },
                gross_total: {
                  without_refunds: 497.81,
                  with_refunds: 490.15,
                },
                net_total_spread: {
                  without_refunds: 4492.31,
                  with_refunds: 4453.76,
                },
                gross_total_spread: {
                  without_refunds: 4801.54,
                  with_refunds: 4757.38,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1184,
                  with_refunds: 0.1125,
                },
                gross_mrr: {
                  without_refunds: 0.1092,
                  with_refunds: 0.1039,
                },
                net_arr: {
                  without_refunds: 0.0016,
                },
                gross_arr: {
                  without_refunds: 0.0019,
                },
                net_arr_spread: {
                  without_refunds: 1.9261,
                  with_refunds: 1.9261,
                },
                gross_arr_spread: {
                  without_refunds: 1.8995,
                  with_refunds: 1.8995,
                },
                net_total: {
                  without_refunds: 0.0139,
                  with_refunds: 0.0154,
                },
                gross_total: {
                  without_refunds: 0.0142,
                  with_refunds: 0.0153,
                },
                net_total_spread: {
                  without_refunds: 0.7265,
                  with_refunds: 0.7381,
                },
                gross_total_spread: {
                  without_refunds: 0.6796,
                  with_refunds: 0.6914,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6296,
                  with_refunds: 0.6202,
                },
                gross_mrr: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6198,
                },
                net_arr: {
                  without_refunds: 0.1999,
                },
                gross_arr: {
                  without_refunds: 0.2096,
                },
                net_arr_spread: {
                  without_refunds: 1.0016,
                  with_refunds: 1.0016,
                },
                gross_arr_spread: {
                  without_refunds: 1.0018,
                  with_refunds: 1.0018,
                },
                net_total: {
                  without_refunds: 0.4488,
                  with_refunds: 0.6202,
                },
                gross_total: {
                  without_refunds: 0.4468,
                  with_refunds: 0.6244,
                },
                net_total_spread: {
                  without_refunds: 0.9587,
                  with_refunds: 0.9618,
                },
                gross_total_spread: {
                  without_refunds: 0.9574,
                  with_refunds: 0.9604,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.46,
                  with_refunds: 1.34,
                },
                gross_mrr: {
                  without_refunds: 1.58,
                  with_refunds: 1.44,
                },
                net_arr: {
                  without_refunds: 0.11,
                },
                gross_arr: {
                  without_refunds: 0.15,
                },
                net_arr_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.5,
                },
                gross_arr_spread: {
                  without_refunds: 12.28,
                  with_refunds: 12.28,
                },
                net_total: {
                  without_refunds: 0.62,
                  with_refunds: 0.63,
                },
                gross_total: {
                  without_refunds: 0.69,
                  with_refunds: 0.68,
                },
                net_total_spread: {
                  without_refunds: 6.65,
                  with_refunds: 6.59,
                },
                gross_total_spread: {
                  without_refunds: 7.1,
                  with_refunds: 7.04,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.64,
                  with_refunds: 17.71,
                },
                gross_mrr: {
                  without_refunds: 21.22,
                  with_refunds: 19.14,
                },
                net_arr: {
                  without_refunds: 98.29,
                },
                gross_arr: {
                  without_refunds: 126.51,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 17.9,
                  with_refunds: 17.78,
                },
                gross_total: {
                  without_refunds: 19.87,
                  with_refunds: 19.35,
                },
                net_total_spread: {
                  without_refunds: 12.24,
                  with_refunds: 12.13,
                },
                gross_total_spread: {
                  without_refunds: 13.09,
                  with_refunds: 12.96,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 29.22,
                    with_refunds: 27.99,
                  },
                  gross_mrr: {
                    without_refunds: 33.42,
                    with_refunds: 31.96,
                  },
                  net_arr: {
                    without_refunds: 138.38,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.41,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 40.39,
                    with_refunds: 40.39,
                  },
                  gross_arr_spread: {
                    without_refunds: 43.21,
                    with_refunds: 43.21,
                  },
                  net_total: {
                    without_refunds: 87.87,
                    with_refunds: 78.91,
                  },
                  gross_total: {
                    without_refunds: 94.68,
                    with_refunds: 85.14,
                  },
                  net_total_spread: {
                    without_refunds: 35.01,
                    with_refunds: 34.41,
                  },
                  gross_total_spread: {
                    without_refunds: 38.53,
                    with_refunds: 37.8,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 77.93,
                    with_refunds: 72.79,
                  },
                  gross_mrr: {
                    without_refunds: 87.12,
                    with_refunds: 81.33,
                  },
                  net_arr: {
                    without_refunds: 484.93,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 555.47,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 45.62,
                    with_refunds: 45.62,
                  },
                  gross_arr_spread: {
                    without_refunds: 48.87,
                    with_refunds: 48.87,
                  },
                  net_total: {
                    without_refunds: 192,
                    with_refunds: 168,
                  },
                  gross_total: {
                    without_refunds: 208.15,
                    with_refunds: 181.43,
                  },
                  net_total_spread: {
                    without_refunds: 54.99,
                    with_refunds: 54.03,
                  },
                  gross_total_spread: {
                    without_refunds: 60.46,
                    with_refunds: 59.31,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 2.928,
                    with_refunds: 2.805,
                  },
                  gross_mrr: {
                    without_refunds: 3.3485,
                    with_refunds: 3.2027,
                  },
                  net_arr: {
                    without_refunds: 13.8661,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7705,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 4.0469,
                    with_refunds: 4.0469,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.3292,
                    with_refunds: 4.3292,
                  },
                  net_total: {
                    without_refunds: 8.8043,
                    with_refunds: 7.9067,
                  },
                  gross_total: {
                    without_refunds: 9.4865,
                    with_refunds: 8.5313,
                  },
                  net_total_spread: {
                    without_refunds: 3.5084,
                    with_refunds: 3.4475,
                  },
                  gross_total_spread: {
                    without_refunds: 3.8606,
                    with_refunds: 3.788,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 7.8085,
                    with_refunds: 7.2935,
                  },
                  gross_mrr: {
                    without_refunds: 8.7295,
                    with_refunds: 8.1489,
                  },
                  net_arr: {
                    without_refunds: 48.5897,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 55.6585,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 4.5709,
                    with_refunds: 4.5709,
                  },
                  gross_arr_spread: {
                    without_refunds: 4.8967,
                    with_refunds: 4.8967,
                  },
                  net_total: {
                    without_refunds: 19.2381,
                    with_refunds: 16.8334,
                  },
                  gross_total: {
                    without_refunds: 20.8565,
                    with_refunds: 18.1792,
                  },
                  net_total_spread: {
                    without_refunds: 5.5102,
                    with_refunds: 5.4136,
                  },
                  gross_total_spread: {
                    without_refunds: 6.0578,
                    with_refunds: 5.9432,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 14.14,
                  with_refunds: 14.37,
                },
                arr_users: {
                  without_refunds: 0.19,
                  with_refunds: 0.31,
                },
                arr_spread_users: {
                  without_refunds: 341.88,
                  with_refunds: 341.88,
                },
                total_users: {
                  without_refunds: 14.09,
                  with_refunds: 14.22,
                },
                total_spread_users: {
                  without_refunds: 337.69,
                  with_refunds: 337.87,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.042,
                  with_refunds: 0.0427,
                },
                arr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0008,
                },
                arr_spread_users: {
                  without_refunds: 1.0055,
                  with_refunds: 1.0055,
                },
                total_users: {
                  without_refunds: 0.0196,
                  with_refunds: 0.0198,
                },
                total_spread_users: {
                  without_refunds: 0.4995,
                  with_refunds: 0.4998,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.5646,
                  with_refunds: 0.5653,
                },
                arr_users: {
                  without_refunds: 0.4222,
                  with_refunds: 0.3778,
                },
                arr_spread_users: {
                  without_refunds: 1.0037,
                  with_refunds: 1.0037,
                },
                total_users: {
                  without_refunds: 0.5622,
                  with_refunds: 0.5615,
                },
                total_spread_users: {
                  without_refunds: 0.9203,
                  with_refunds: 0.9204,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 277.27,
                  with_refunds: 253.57,
                },
                gross_mrr: {
                  without_refunds: 300.62,
                  with_refunds: 274.87,
                },
                net_arr: {
                  without_refunds: 19.73,
                },
                gross_arr: {
                  without_refunds: 23.09,
                },
                net_arr_spread: {
                  without_refunds: 3926.24,
                  with_refunds: 3926.24,
                },
                gross_arr_spread: {
                  without_refunds: 4194.66,
                  with_refunds: 4194.66,
                },
                net_total: {
                  without_refunds: 245.91,
                  with_refunds: 258.57,
                },
                gross_total: {
                  without_refunds: 270.36,
                  with_refunds: 281.02,
                },
                net_total_spread: {
                  without_refunds: 4070.34,
                  with_refunds: 4040.47,
                },
                gross_total_spread: {
                  without_refunds: 4349.52,
                  with_refunds: 4314.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0668,
                  with_refunds: 0.0633,
                },
                gross_mrr: {
                  without_refunds: 0.0618,
                  with_refunds: 0.0587,
                },
                net_arr: {
                  without_refunds: 0.0007,
                },
                gross_arr: {
                  without_refunds: 0.0008,
                },
                net_arr_spread: {
                  without_refunds: 1.9338,
                  with_refunds: 1.9338,
                },
                gross_arr_spread: {
                  without_refunds: 1.9079,
                  with_refunds: 1.9079,
                },
                net_total: {
                  without_refunds: 0.0076,
                  with_refunds: 0.0088,
                },
                gross_total: {
                  without_refunds: 0.0077,
                  with_refunds: 0.0088,
                },
                net_total_spread: {
                  without_refunds: 0.6583,
                  with_refunds: 0.6696,
                },
                gross_total_spread: {
                  without_refunds: 0.6156,
                  with_refunds: 0.627,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5638,
                  with_refunds: 0.5631,
                },
                gross_mrr: {
                  without_refunds: 0.5656,
                  with_refunds: 0.5649,
                },
                net_arr: {
                  without_refunds: 0.4524,
                },
                gross_arr: {
                  without_refunds: 0.4112,
                },
                net_arr_spread: {
                  without_refunds: 1.004,
                  with_refunds: 1.004,
                },
                gross_arr_spread: {
                  without_refunds: 1.0044,
                  with_refunds: 1.0044,
                },
                net_total: {
                  without_refunds: 0.5483,
                  with_refunds: 0.5742,
                },
                gross_total: {
                  without_refunds: 0.5431,
                  with_refunds: 0.5733,
                },
                net_total_spread: {
                  without_refunds: 0.9061,
                  with_refunds: 0.9072,
                },
                gross_total_spread: {
                  without_refunds: 0.9059,
                  with_refunds: 0.9069,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.82,
                  with_refunds: 0.75,
                },
                gross_mrr: {
                  without_refunds: 0.89,
                  with_refunds: 0.82,
                },
                net_arr: {
                  without_refunds: 0.05,
                },
                gross_arr: {
                  without_refunds: 0.06,
                },
                net_arr_spread: {
                  without_refunds: 11.55,
                  with_refunds: 11.55,
                },
                gross_arr_spread: {
                  without_refunds: 12.34,
                  with_refunds: 12.34,
                },
                net_total: {
                  without_refunds: 0.34,
                  with_refunds: 0.36,
                },
                gross_total: {
                  without_refunds: 0.38,
                  with_refunds: 0.39,
                },
                net_total_spread: {
                  without_refunds: 6.02,
                  with_refunds: 5.98,
                },
                gross_total_spread: {
                  without_refunds: 6.43,
                  with_refunds: 6.38,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.61,
                  with_refunds: 17.64,
                },
                gross_mrr: {
                  without_refunds: 21.26,
                  with_refunds: 19.12,
                },
                net_arr: {
                  without_refunds: 105.32,
                },
                gross_arr: {
                  without_refunds: 123.21,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
                net_total: {
                  without_refunds: 17.46,
                  with_refunds: 18.18,
                },
                gross_total: {
                  without_refunds: 19.19,
                  with_refunds: 19.76,
                },
                net_total_spread: {
                  without_refunds: 12.05,
                  with_refunds: 11.96,
                },
                gross_total_spread: {
                  without_refunds: 12.88,
                  with_refunds: 12.77,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.04,
                    with_refunds: 28.75,
                  },
                  gross_mrr: {
                    without_refunds: 34.31,
                    with_refunds: 32.78,
                  },
                  net_arr: {
                    without_refunds: 138.43,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.47,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 51.94,
                    with_refunds: 51.94,
                  },
                  gross_arr_spread: {
                    without_refunds: 55.54,
                    with_refunds: 55.54,
                  },
                  net_total: {
                    without_refunds: 88.21,
                    with_refunds: 79.27,
                  },
                  gross_total: {
                    without_refunds: 95.05,
                    with_refunds: 85.53,
                  },
                  net_total_spread: {
                    without_refunds: 41.03,
                    with_refunds: 40.38,
                  },
                  gross_total_spread: {
                    without_refunds: 44.96,
                    with_refunds: 44.19,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 97.54,
                    with_refunds: 90.43,
                  },
                  gross_mrr: {
                    without_refunds: 108.38,
                    with_refunds: 100.45,
                  },
                  net_arr: {
                    without_refunds: 590.24,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 678.68,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 57.1,
                    with_refunds: 57.1,
                  },
                  gross_arr_spread: {
                    without_refunds: 61.14,
                    with_refunds: 61.14,
                  },
                  net_total: {
                    without_refunds: 209.45,
                    with_refunds: 186.18,
                  },
                  gross_total: {
                    without_refunds: 227.34,
                    with_refunds: 201.19,
                  },
                  net_total_spread: {
                    without_refunds: 67.04,
                    with_refunds: 65.99,
                  },
                  gross_total_spread: {
                    without_refunds: 73.34,
                    with_refunds: 72.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.0104,
                    with_refunds: 2.8803,
                  },
                  gross_mrr: {
                    without_refunds: 3.4378,
                    with_refunds: 3.2844,
                  },
                  net_arr: {
                    without_refunds: 13.8712,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7765,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 5.2039,
                    with_refunds: 5.2039,
                  },
                  gross_arr_spread: {
                    without_refunds: 5.5654,
                    with_refunds: 5.5654,
                  },
                  net_total: {
                    without_refunds: 8.8386,
                    with_refunds: 7.9427,
                  },
                  gross_total: {
                    without_refunds: 9.5242,
                    with_refunds: 8.5705,
                  },
                  net_total_spread: {
                    without_refunds: 4.1117,
                    with_refunds: 4.0464,
                  },
                  gross_total_spread: {
                    without_refunds: 4.5053,
                    with_refunds: 4.4276,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 9.7735,
                    with_refunds: 9.0611,
                  },
                  gross_mrr: {
                    without_refunds: 10.86,
                    with_refunds: 10.065,
                  },
                  net_arr: {
                    without_refunds: 59.1424,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 68.0037,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 5.7216,
                    with_refunds: 5.7216,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.126,
                    with_refunds: 6.126,
                  },
                  net_total: {
                    without_refunds: 20.9874,
                    with_refunds: 18.6549,
                  },
                  gross_total: {
                    without_refunds: 22.7797,
                    with_refunds: 20.1589,
                  },
                  net_total_spread: {
                    without_refunds: 6.7179,
                    with_refunds: 6.6118,
                  },
                  gross_total_spread: {
                    without_refunds: 7.3484,
                    with_refunds: 7.2228,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 8.96,
                  with_refunds: 9.13,
                },
                arr_users: {
                  without_refunds: 0.04,
                  with_refunds: 0.11,
                },
                arr_spread_users: {
                  without_refunds: 342.06,
                  with_refunds: 342.06,
                },
                total_users: {
                  without_refunds: 8.84,
                  with_refunds: 8.99,
                },
                total_spread_users: {
                  without_refunds: 303.11,
                  with_refunds: 303.32,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0266,
                  with_refunds: 0.0271,
                },
                arr_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0003,
                },
                arr_spread_users: {
                  without_refunds: 1.0061,
                  with_refunds: 1.0061,
                },
                total_users: {
                  without_refunds: 0.0123,
                  with_refunds: 0.0125,
                },
                total_spread_users: {
                  without_refunds: 0.4484,
                  with_refunds: 0.4487,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6336,
                  with_refunds: 0.6351,
                },
                arr_users: {
                  without_refunds: 0.2273,
                  with_refunds: 0.3662,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6277,
                  with_refunds: 0.6322,
                },
                total_spread_users: {
                  without_refunds: 0.8976,
                  with_refunds: 0.8977,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 175.26,
                  with_refunds: 160.17,
                },
                gross_mrr: {
                  without_refunds: 188.96,
                  with_refunds: 172.9,
                },
                net_arr: {
                  without_refunds: 4.31,
                },
                gross_arr: {
                  without_refunds: 5.48,
                },
                net_arr_spread: {
                  without_refunds: 3928.23,
                  with_refunds: 3928.23,
                },
                gross_arr_spread: {
                  without_refunds: 4196.79,
                  with_refunds: 4196.79,
                },
                net_total: {
                  without_refunds: 143.3,
                  with_refunds: 153.96,
                },
                gross_total: {
                  without_refunds: 154.46,
                  with_refunds: 164.7,
                },
                net_total_spread: {
                  without_refunds: 3548.03,
                  with_refunds: 3531.23,
                },
                gross_total_spread: {
                  without_refunds: 3781.41,
                  with_refunds: 3762.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0422,
                  with_refunds: 0.04,
                },
                gross_mrr: {
                  without_refunds: 0.0388,
                  with_refunds: 0.0369,
                },
                net_arr: {
                  without_refunds: 0.0002,
                },
                gross_arr: {
                  without_refunds: 0.0002,
                },
                net_arr_spread: {
                  without_refunds: 1.9348,
                  with_refunds: 1.9348,
                },
                gross_arr_spread: {
                  without_refunds: 1.9088,
                  with_refunds: 1.9088,
                },
                net_total: {
                  without_refunds: 0.0045,
                  with_refunds: 0.0053,
                },
                gross_total: {
                  without_refunds: 0.0044,
                  with_refunds: 0.0051,
                },
                net_total_spread: {
                  without_refunds: 0.5738,
                  with_refunds: 0.5852,
                },
                gross_total_spread: {
                  without_refunds: 0.5352,
                  with_refunds: 0.5468,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6321,
                  with_refunds: 0.6317,
                },
                gross_mrr: {
                  without_refunds: 0.6286,
                  with_refunds: 0.629,
                },
                net_arr: {
                  without_refunds: 0.2185,
                },
                gross_arr: {
                  without_refunds: 0.2373,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                net_total: {
                  without_refunds: 0.5827,
                  with_refunds: 0.5954,
                },
                gross_total: {
                  without_refunds: 0.5713,
                  with_refunds: 0.5861,
                },
                net_total_spread: {
                  without_refunds: 0.8717,
                  with_refunds: 0.874,
                },
                gross_total_spread: {
                  without_refunds: 0.8694,
                  with_refunds: 0.8721,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.52,
                  with_refunds: 0.48,
                },
                gross_mrr: {
                  without_refunds: 0.56,
                  with_refunds: 0.51,
                },
                net_arr: {
                  without_refunds: 0.01,
                },
                gross_arr: {
                  without_refunds: 0.01,
                },
                net_arr_spread: {
                  without_refunds: 11.55,
                  with_refunds: 11.55,
                },
                gross_arr_spread: {
                  without_refunds: 12.34,
                  with_refunds: 12.34,
                },
                net_total: {
                  without_refunds: 0.2,
                  with_refunds: 0.21,
                },
                gross_total: {
                  without_refunds: 0.21,
                  with_refunds: 0.23,
                },
                net_total_spread: {
                  without_refunds: 5.25,
                  with_refunds: 5.22,
                },
                gross_total_spread: {
                  without_refunds: 5.59,
                  with_refunds: 5.57,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.56,
                  with_refunds: 17.55,
                },
                gross_mrr: {
                  without_refunds: 21.09,
                  with_refunds: 18.94,
                },
                net_arr: {
                  without_refunds: 101.26,
                },
                gross_arr: {
                  without_refunds: 128.64,
                },
                net_arr_spread: {
                  without_refunds: 11.48,
                  with_refunds: 11.48,
                },
                gross_arr_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
                net_total: {
                  without_refunds: 16.21,
                  with_refunds: 17.12,
                },
                gross_total: {
                  without_refunds: 17.47,
                  with_refunds: 18.31,
                },
                net_total_spread: {
                  without_refunds: 11.71,
                  with_refunds: 11.64,
                },
                gross_total_spread: {
                  without_refunds: 12.48,
                  with_refunds: 12.41,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.56,
                    with_refunds: 29.22,
                  },
                  gross_mrr: {
                    without_refunds: 34.87,
                    with_refunds: 33.29,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.48,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 63.49,
                    with_refunds: 63.49,
                  },
                  gross_arr_spread: {
                    without_refunds: 67.89,
                    with_refunds: 67.89,
                  },
                  net_total: {
                    without_refunds: 88.41,
                    with_refunds: 79.48,
                  },
                  gross_total: {
                    without_refunds: 95.27,
                    with_refunds: 85.76,
                  },
                  net_total_spread: {
                    without_refunds: 46.28,
                    with_refunds: 45.61,
                  },
                  gross_total_spread: {
                    without_refunds: 50.56,
                    with_refunds: 49.75,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 117.1,
                    with_refunds: 107.97,
                  },
                  gross_mrr: {
                    without_refunds: 129.48,
                    with_refunds: 119.39,
                  },
                  net_arr: {
                    without_refunds: 691.5,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 807.32,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 68.59,
                    with_refunds: 68.59,
                  },
                  gross_arr_spread: {
                    without_refunds: 73.41,
                    with_refunds: 73.41,
                  },
                  net_total: {
                    without_refunds: 225.66,
                    with_refunds: 203.3,
                  },
                  gross_total: {
                    without_refunds: 244.81,
                    with_refunds: 219.5,
                  },
                  net_total_spread: {
                    without_refunds: 78.75,
                    with_refunds: 77.63,
                  },
                  gross_total_spread: {
                    without_refunds: 85.81,
                    with_refunds: 84.49,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.0625,
                    with_refunds: 2.928,
                  },
                  gross_mrr: {
                    without_refunds: 3.494,
                    with_refunds: 3.3358,
                  },
                  net_arr: {
                    without_refunds: 13.8723,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7779,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 6.3616,
                    with_refunds: 6.3616,
                  },
                  gross_arr_spread: {
                    without_refunds: 6.8022,
                    with_refunds: 6.8022,
                  },
                  net_total: {
                    without_refunds: 8.8586,
                    with_refunds: 7.9642,
                  },
                  gross_total: {
                    without_refunds: 9.5458,
                    with_refunds: 8.5934,
                  },
                  net_total_spread: {
                    without_refunds: 4.6376,
                    with_refunds: 4.5698,
                  },
                  gross_total_spread: {
                    without_refunds: 5.0658,
                    with_refunds: 4.9853,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 11.7339,
                    with_refunds: 10.8191,
                  },
                  gross_mrr: {
                    without_refunds: 12.9735,
                    with_refunds: 11.9628,
                  },
                  net_arr: {
                    without_refunds: 69.2885,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 80.8937,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 6.8724,
                    with_refunds: 6.8724,
                  },
                  gross_arr_spread: {
                    without_refunds: 7.3554,
                    with_refunds: 7.3554,
                  },
                  net_total: {
                    without_refunds: 22.6113,
                    with_refunds: 20.3704,
                  },
                  gross_total: {
                    without_refunds: 24.5302,
                    with_refunds: 21.9941,
                  },
                  net_total_spread: {
                    without_refunds: 7.8908,
                    with_refunds: 7.7784,
                  },
                  gross_total_spread: {
                    without_refunds: 8.5985,
                    with_refunds: 8.4658,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 6.09,
                  with_refunds: 6.19,
                },
                arr_users: {
                  without_refunds: 0.01,
                  with_refunds: 0.06,
                },
                arr_spread_users: {
                  without_refunds: 342.74,
                  with_refunds: 342.74,
                },
                total_users: {
                  without_refunds: 5.99,
                  with_refunds: 6.07,
                },
                total_spread_users: {
                  without_refunds: 269.28,
                  with_refunds: 269.22,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0181,
                  with_refunds: 0.0184,
                },
                arr_users: {
                  with_refunds: 0.0001,
                },
                arr_spread_users: {
                  without_refunds: 1.008,
                  with_refunds: 1.008,
                },
                total_users: {
                  without_refunds: 0.0083,
                  with_refunds: 0.0084,
                },
                total_spread_users: {
                  without_refunds: 0.3983,
                  with_refunds: 0.3983,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6803,
                  with_refunds: 0.6785,
                },
                arr_users: {
                  without_refunds: 0.1905,
                  with_refunds: 0.4881,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6753,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8876,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 120.07,
                  with_refunds: 111.68,
                },
                gross_mrr: {
                  without_refunds: 129.22,
                  with_refunds: 120.13,
                },
                net_arr: {
                  without_refunds: 0.89,
                },
                gross_arr: {
                  without_refunds: 1.06,
                },
                net_arr_spread: {
                  without_refunds: 3937.54,
                  with_refunds: 3937.54,
                },
                gross_arr_spread: {
                  without_refunds: 4207.69,
                  with_refunds: 4207.69,
                },
                net_total: {
                  without_refunds: 95.56,
                  with_refunds: 106.26,
                },
                gross_total: {
                  without_refunds: 102.14,
                  with_refunds: 113.1,
                },
                net_total_spread: {
                  without_refunds: 3049.63,
                  with_refunds: 3056.3,
                },
                gross_total_spread: {
                  without_refunds: 3247.71,
                  with_refunds: 3252.36,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0289,
                  with_refunds: 0.0279,
                },
                gross_mrr: {
                  without_refunds: 0.0265,
                  with_refunds: 0.0257,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9394,
                  with_refunds: 1.9394,
                },
                gross_arr_spread: {
                  without_refunds: 1.9138,
                  with_refunds: 1.9138,
                },
                net_total: {
                  without_refunds: 0.003,
                  with_refunds: 0.0036,
                },
                gross_total: {
                  without_refunds: 0.0029,
                  with_refunds: 0.0035,
                },
                net_total_spread: {
                  without_refunds: 0.4932,
                  with_refunds: 0.5065,
                },
                gross_total_spread: {
                  without_refunds: 0.4596,
                  with_refunds: 0.4727,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6851,
                  with_refunds: 0.6973,
                },
                gross_mrr: {
                  without_refunds: 0.6838,
                  with_refunds: 0.6948,
                },
                net_arr: {
                  without_refunds: 0.2058,
                },
                gross_arr: {
                  without_refunds: 0.1926,
                },
                net_arr_spread: {
                  without_refunds: 1.0024,
                  with_refunds: 1.0024,
                },
                gross_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                net_total: {
                  without_refunds: 0.6669,
                  with_refunds: 0.6901,
                },
                gross_total: {
                  without_refunds: 0.6613,
                  with_refunds: 0.6867,
                },
                net_total_spread: {
                  without_refunds: 0.8595,
                  with_refunds: 0.8655,
                },
                gross_total_spread: {
                  without_refunds: 0.8589,
                  with_refunds: 0.8644,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.36,
                  with_refunds: 0.33,
                },
                gross_mrr: {
                  without_refunds: 0.38,
                  with_refunds: 0.36,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.58,
                  with_refunds: 11.58,
                },
                gross_arr_spread: {
                  without_refunds: 12.38,
                  with_refunds: 12.38,
                },
                net_total: {
                  without_refunds: 0.13,
                  with_refunds: 0.15,
                },
                gross_total: {
                  without_refunds: 0.14,
                  with_refunds: 0.16,
                },
                net_total_spread: {
                  without_refunds: 4.51,
                  with_refunds: 4.52,
                },
                gross_total_spread: {
                  without_refunds: 4.8,
                  with_refunds: 4.81,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.7,
                  with_refunds: 18.03,
                },
                gross_mrr: {
                  without_refunds: 21.2,
                  with_refunds: 19.39,
                },
                net_arr: {
                  without_refunds: 109.41,
                },
                gross_arr: {
                  without_refunds: 130.1,
                },
                net_arr_spread: {
                  without_refunds: 11.49,
                  with_refunds: 11.49,
                },
                gross_arr_spread: {
                  without_refunds: 12.28,
                  with_refunds: 12.28,
                },
                net_total: {
                  without_refunds: 15.95,
                  with_refunds: 17.5,
                },
                gross_total: {
                  without_refunds: 17.04,
                  with_refunds: 18.62,
                },
                net_total_spread: {
                  without_refunds: 11.33,
                  with_refunds: 11.35,
                },
                gross_total_spread: {
                  without_refunds: 12.06,
                  with_refunds: 12.08,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 30.92,
                    with_refunds: 29.55,
                  },
                  gross_mrr: {
                    without_refunds: 35.25,
                    with_refunds: 33.65,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 75.07,
                    with_refunds: 75.07,
                  },
                  gross_arr_spread: {
                    without_refunds: 80.26,
                    with_refunds: 80.26,
                  },
                  net_total: {
                    without_refunds: 88.54,
                    with_refunds: 79.63,
                  },
                  gross_total: {
                    without_refunds: 95.41,
                    with_refunds: 85.92,
                  },
                  net_total_spread: {
                    without_refunds: 50.79,
                    with_refunds: 50.13,
                  },
                  gross_total_spread: {
                    without_refunds: 55.36,
                    with_refunds: 54.56,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 136.81,
                    with_refunds: 126,
                  },
                  gross_mrr: {
                    without_refunds: 150.68,
                    with_refunds: 138.78,
                  },
                  net_arr: {
                    without_refunds: 800.91,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 937.42,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 80.07,
                    with_refunds: 80.07,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.68,
                    with_refunds: 85.68,
                  },
                  net_total: {
                    without_refunds: 241.61,
                    with_refunds: 220.79,
                  },
                  gross_total: {
                    without_refunds: 261.86,
                    with_refunds: 238.13,
                  },
                  net_total_spread: {
                    without_refunds: 90.08,
                    with_refunds: 88.98,
                  },
                  gross_total_spread: {
                    without_refunds: 97.87,
                    with_refunds: 96.57,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.0982,
                    with_refunds: 2.9612,
                  },
                  gross_mrr: {
                    without_refunds: 3.5324,
                    with_refunds: 3.3715,
                  },
                  net_arr: {
                    without_refunds: 13.8726,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7782,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 7.522,
                    with_refunds: 7.522,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.0423,
                    with_refunds: 8.0423,
                  },
                  net_total: {
                    without_refunds: 8.8719,
                    with_refunds: 7.979,
                  },
                  gross_total: {
                    without_refunds: 9.56,
                    with_refunds: 8.6092,
                  },
                  net_total_spread: {
                    without_refunds: 5.0897,
                    with_refunds: 5.0228,
                  },
                  gross_total_spread: {
                    without_refunds: 5.5472,
                    with_refunds: 5.4674,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 13.7081,
                    with_refunds: 12.6256,
                  },
                  gross_mrr: {
                    without_refunds: 15.0981,
                    with_refunds: 13.906,
                  },
                  net_arr: {
                    without_refunds: 80.2512,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 93.9296,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 8.0235,
                    with_refunds: 8.0235,
                  },
                  gross_arr_spread: {
                    without_refunds: 8.5856,
                    with_refunds: 8.5856,
                  },
                  net_total: {
                    without_refunds: 24.2091,
                    with_refunds: 22.1237,
                  },
                  gross_total: {
                    without_refunds: 26.238,
                    with_refunds: 23.8602,
                  },
                  net_total_spread: {
                    without_refunds: 9.0256,
                    with_refunds: 8.9159,
                  },
                  gross_total_spread: {
                    without_refunds: 9.8069,
                    with_refunds: 9.6763,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 3.82,
                  with_refunds: 3.88,
                },
                arr_users: {
                  with_refunds: 0.01,
                },
                arr_spread_users: {
                  without_refunds: 342.91,
                  with_refunds: 342.91,
                },
                total_users: {
                  without_refunds: 3.71,
                  with_refunds: 3.76,
                },
                total_spread_users: {
                  without_refunds: 241.24,
                  with_refunds: 241.08,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0113,
                  with_refunds: 0.0115,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0086,
                  with_refunds: 1.0086,
                },
                total_users: {
                  without_refunds: 0.0052,
                  with_refunds: 0.0052,
                },
                total_spread_users: {
                  without_refunds: 0.3569,
                  with_refunds: 0.3566,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.626,
                  with_refunds: 0.6268,
                },
                arr_users: {
                  without_refunds: 0.0833,
                  with_refunds: 0.1167,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                total_spread_users: {
                  without_refunds: 0.8959,
                  with_refunds: 0.8955,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 75.87,
                  with_refunds: 69.88,
                },
                gross_mrr: {
                  without_refunds: 81.65,
                  with_refunds: 75.3,
                },
                net_arr: {
                  without_refunds: 0.06,
                },
                gross_arr: {
                  without_refunds: 0.08,
                },
                net_arr_spread: {
                  without_refunds: 3939.6,
                  with_refunds: 3939.6,
                },
                gross_arr_spread: {
                  without_refunds: 4210.46,
                  with_refunds: 4210.46,
                },
                net_total: {
                  without_refunds: 54.39,
                  with_refunds: 60.21,
                },
                gross_total: {
                  without_refunds: 57.81,
                  with_refunds: 63.7,
                },
                net_total_spread: {
                  without_refunds: 2636.18,
                  with_refunds: 2639.69,
                },
                gross_total_spread: {
                  without_refunds: 2807.68,
                  with_refunds: 2810.67,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0183,
                  with_refunds: 0.0175,
                },
                gross_mrr: {
                  without_refunds: 0.0168,
                  with_refunds: 0.0161,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9404,
                  with_refunds: 1.9404,
                },
                gross_arr_spread: {
                  without_refunds: 1.915,
                  with_refunds: 1.915,
                },
                net_total: {
                  without_refunds: 0.0017,
                  with_refunds: 0.0021,
                },
                gross_total: {
                  without_refunds: 0.0016,
                  with_refunds: 0.002,
                },
                net_total_spread: {
                  without_refunds: 0.4263,
                  with_refunds: 0.4374,
                },
                gross_total_spread: {
                  without_refunds: 0.3974,
                  with_refunds: 0.4085,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6258,
                },
                gross_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6268,
                },
                net_arr: {
                  without_refunds: 0.0657,
                },
                gross_arr: {
                  without_refunds: 0.0754,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.5691,
                  with_refunds: 0.5667,
                },
                gross_total: {
                  without_refunds: 0.566,
                  with_refunds: 0.5633,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8637,
                },
                gross_total_spread: {
                  without_refunds: 0.8645,
                  with_refunds: 0.8642,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.23,
                  with_refunds: 0.21,
                },
                gross_mrr: {
                  without_refunds: 0.24,
                  with_refunds: 0.22,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.59,
                  with_refunds: 11.59,
                },
                gross_arr_spread: {
                  without_refunds: 12.38,
                  with_refunds: 12.38,
                },
                net_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.08,
                },
                gross_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.09,
                },
                net_total_spread: {
                  without_refunds: 3.9,
                  with_refunds: 3.9,
                },
                gross_total_spread: {
                  without_refunds: 4.15,
                  with_refunds: 4.16,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.89,
                  with_refunds: 18,
                },
                gross_mrr: {
                  without_refunds: 21.4,
                  with_refunds: 19.4,
                },
                net_arr: {
                  without_refunds: 86.31,
                },
                gross_arr: {
                  without_refunds: 117.67,
                },
                net_arr_spread: {
                  without_refunds: 11.49,
                  with_refunds: 11.49,
                },
                gross_arr_spread: {
                  without_refunds: 12.28,
                  with_refunds: 12.28,
                },
                net_total: {
                  without_refunds: 14.65,
                  with_refunds: 16,
                },
                gross_total: {
                  without_refunds: 15.57,
                  with_refunds: 16.93,
                },
                net_total_spread: {
                  without_refunds: 10.93,
                  with_refunds: 10.95,
                },
                gross_total_spread: {
                  without_refunds: 11.64,
                  with_refunds: 11.66,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 31.15,
                    with_refunds: 29.76,
                  },
                  gross_mrr: {
                    without_refunds: 35.5,
                    with_refunds: 33.87,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 86.66,
                    with_refunds: 86.66,
                  },
                  gross_arr_spread: {
                    without_refunds: 92.65,
                    with_refunds: 92.65,
                  },
                  net_total: {
                    without_refunds: 88.62,
                    with_refunds: 79.71,
                  },
                  gross_total: {
                    without_refunds: 95.49,
                    with_refunds: 86.01,
                  },
                  net_total_spread: {
                    without_refunds: 54.69,
                    with_refunds: 54.03,
                  },
                  gross_total_spread: {
                    without_refunds: 59.51,
                    with_refunds: 58.72,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 156.69,
                    with_refunds: 144,
                  },
                  gross_mrr: {
                    without_refunds: 172.08,
                    with_refunds: 158.18,
                  },
                  net_arr: {
                    without_refunds: 887.22,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 1055.08,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 91.56,
                    with_refunds: 91.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 97.96,
                    with_refunds: 97.96,
                  },
                  net_total: {
                    without_refunds: 256.25,
                    with_refunds: 236.8,
                  },
                  gross_total: {
                    without_refunds: 277.43,
                    with_refunds: 255.06,
                  },
                  net_total_spread: {
                    without_refunds: 101,
                    with_refunds: 99.93,
                  },
                  gross_total_spread: {
                    without_refunds: 109.51,
                    with_refunds: 108.23,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.1208,
                    with_refunds: 2.982,
                  },
                  gross_mrr: {
                    without_refunds: 3.5567,
                    with_refunds: 3.3939,
                  },
                  net_arr: {
                    without_refunds: 13.8726,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7782,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 8.6831,
                    with_refunds: 8.6831,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.2831,
                    with_refunds: 9.2831,
                  },
                  net_total: {
                    without_refunds: 8.8795,
                    with_refunds: 7.9874,
                  },
                  gross_total: {
                    without_refunds: 9.568,
                    with_refunds: 8.6181,
                  },
                  net_total_spread: {
                    without_refunds: 5.4804,
                    with_refunds: 5.4141,
                  },
                  gross_total_spread: {
                    without_refunds: 5.9634,
                    with_refunds: 5.884,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.7008,
                    with_refunds: 14.4293,
                  },
                  gross_mrr: {
                    without_refunds: 17.2427,
                    with_refunds: 15.8495,
                  },
                  net_arr: {
                    without_refunds: 88.8998,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 105.7197,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 9.1747,
                    with_refunds: 9.1747,
                  },
                  gross_arr_spread: {
                    without_refunds: 9.8159,
                    with_refunds: 9.8159,
                  },
                  net_total: {
                    without_refunds: 25.6768,
                    with_refunds: 23.7272,
                  },
                  gross_total: {
                    without_refunds: 27.7981,
                    with_refunds: 25.5568,
                  },
                  net_total_spread: {
                    without_refunds: 10.1205,
                    with_refunds: 10.013,
                  },
                  gross_total_spread: {
                    without_refunds: 10.9731,
                    with_refunds: 10.8444,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2.52,
                  with_refunds: 2.56,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 343.59,
                  with_refunds: 343.59,
                },
                total_users: {
                  without_refunds: 2.45,
                  with_refunds: 2.48,
                },
                total_spread_users: {
                  without_refunds: 218.77,
                  with_refunds: 218.64,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0075,
                  with_refunds: 0.0076,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0106,
                  with_refunds: 1.0106,
                },
                total_users: {
                  without_refunds: 0.0034,
                  with_refunds: 0.0034,
                },
                total_spread_users: {
                  without_refunds: 0.3236,
                  with_refunds: 0.3234,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {
                  without_refunds: 0.2778,
                  with_refunds: 0.2778,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 50.38,
                  with_refunds: 46.32,
                },
                gross_mrr: {
                  without_refunds: 54.18,
                  with_refunds: 49.87,
                },
                net_arr: {
                  without_refunds: 0.02,
                },
                gross_arr: {
                  without_refunds: 0.02,
                },
                net_arr_spread: {
                  without_refunds: 3949.38,
                  with_refunds: 3949.38,
                },
                gross_arr_spread: {
                  without_refunds: 4221.92,
                  with_refunds: 4221.92,
                },
                net_total: {
                  without_refunds: 35.78,
                  with_refunds: 39.97,
                },
                gross_total: {
                  without_refunds: 37.93,
                  with_refunds: 42.25,
                },
                net_total_spread: {
                  without_refunds: 2314.47,
                  with_refunds: 2318.08,
                },
                gross_total_spread: {
                  without_refunds: 2462.09,
                  with_refunds: 2465.29,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0121,
                  with_refunds: 0.0116,
                },
                gross_mrr: {
                  without_refunds: 0.0111,
                  with_refunds: 0.0107,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9452,
                  with_refunds: 1.9452,
                },
                gross_arr_spread: {
                  without_refunds: 1.9203,
                  with_refunds: 1.9203,
                },
                net_total: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0014,
                },
                gross_total: {
                  without_refunds: 0.0011,
                  with_refunds: 0.0013,
                },
                net_total_spread: {
                  without_refunds: 0.3743,
                  with_refunds: 0.3841,
                },
                gross_total_spread: {
                  without_refunds: 0.3485,
                  with_refunds: 0.3583,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {
                  without_refunds: 0.2848,
                },
                gross_arr: {
                  without_refunds: 0.285,
                },
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.15,
                  with_refunds: 0.14,
                },
                gross_mrr: {
                  without_refunds: 0.16,
                  with_refunds: 0.15,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.62,
                  with_refunds: 11.62,
                },
                gross_arr_spread: {
                  without_refunds: 12.42,
                  with_refunds: 12.42,
                },
                net_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.06,
                },
                gross_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.06,
                },
                net_total_spread: {
                  without_refunds: 3.42,
                  with_refunds: 3.43,
                },
                gross_total_spread: {
                  without_refunds: 3.64,
                  with_refunds: 3.65,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 20.03,
                  with_refunds: 18.08,
                },
                gross_mrr: {
                  without_refunds: 21.54,
                  with_refunds: 19.47,
                },
                net_arr: {
                  without_refunds: 88.49,
                },
                gross_arr: {
                  without_refunds: 120.71,
                },
                net_arr_spread: {
                  without_refunds: 11.49,
                  with_refunds: 11.49,
                },
                gross_arr_spread: {
                  without_refunds: 12.29,
                  with_refunds: 12.29,
                },
                net_total: {
                  without_refunds: 14.63,
                  with_refunds: 16.13,
                },
                gross_total: {
                  without_refunds: 15.51,
                  with_refunds: 17.05,
                },
                net_total_spread: {
                  without_refunds: 10.58,
                  with_refunds: 10.6,
                },
                gross_total_spread: {
                  without_refunds: 11.25,
                  with_refunds: 11.28,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 31.29,
                    with_refunds: 29.9,
                  },
                  gross_mrr: {
                    without_refunds: 35.66,
                    with_refunds: 34.02,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 98.27,
                    with_refunds: 98.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 105.06,
                    with_refunds: 105.06,
                  },
                  net_total: {
                    without_refunds: 88.67,
                    with_refunds: 79.77,
                  },
                  gross_total: {
                    without_refunds: 95.54,
                    with_refunds: 86.07,
                  },
                  net_total_spread: {
                    without_refunds: 58.12,
                    with_refunds: 57.46,
                  },
                  gross_total_spread: {
                    without_refunds: 63.16,
                    with_refunds: 62.37,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 176.72,
                    with_refunds: 162.08,
                  },
                  gross_mrr: {
                    without_refunds: 193.62,
                    with_refunds: 177.64,
                  },
                  net_arr: {
                    without_refunds: 975.71,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 1175.79,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 103.06,
                    with_refunds: 103.06,
                  },
                  gross_arr_spread: {
                    without_refunds: 110.25,
                    with_refunds: 110.25,
                  },
                  net_total: {
                    without_refunds: 270.89,
                    with_refunds: 252.93,
                  },
                  gross_total: {
                    without_refunds: 292.94,
                    with_refunds: 272.11,
                  },
                  net_total_spread: {
                    without_refunds: 111.58,
                    with_refunds: 110.53,
                  },
                  gross_total_spread: {
                    without_refunds: 120.77,
                    with_refunds: 119.5,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.1358,
                    with_refunds: 2.9957,
                  },
                  gross_mrr: {
                    without_refunds: 3.5728,
                    with_refunds: 3.4088,
                  },
                  net_arr: {
                    without_refunds: 13.8726,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7782,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 9.847,
                    with_refunds: 9.847,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.5274,
                    with_refunds: 10.5274,
                  },
                  net_total: {
                    without_refunds: 8.8845,
                    with_refunds: 7.9929,
                  },
                  gross_total: {
                    without_refunds: 9.5733,
                    with_refunds: 8.624,
                  },
                  net_total_spread: {
                    without_refunds: 5.8235,
                    with_refunds: 5.7577,
                  },
                  gross_total_spread: {
                    without_refunds: 6.3283,
                    with_refunds: 6.2494,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 17.7077,
                    with_refunds: 16.2408,
                  },
                  gross_mrr: {
                    without_refunds: 19.4007,
                    with_refunds: 17.7999,
                  },
                  net_arr: {
                    without_refunds: 97.7663,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 117.8148,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 10.3264,
                    with_refunds: 10.3264,
                  },
                  gross_arr_spread: {
                    without_refunds: 11.0471,
                    with_refunds: 11.0471,
                  },
                  net_total: {
                    without_refunds: 27.1431,
                    with_refunds: 25.3435,
                  },
                  gross_total: {
                    without_refunds: 29.3525,
                    with_refunds: 27.2651,
                  },
                  net_total_spread: {
                    without_refunds: 11.1806,
                    with_refunds: 11.0754,
                  },
                  gross_total_spread: {
                    without_refunds: 12.1008,
                    with_refunds: 11.9743,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.67,
                  with_refunds: 1.7,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 344.56,
                  with_refunds: 344.56,
                },
                total_users: {
                  without_refunds: 1.61,
                  with_refunds: 1.64,
                },
                total_spread_users: {
                  without_refunds: 194.37,
                  with_refunds: 194.23,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.005,
                  with_refunds: 0.005,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0134,
                  with_refunds: 1.0134,
                },
                total_users: {
                  without_refunds: 0.0022,
                  with_refunds: 0.0023,
                },
                total_spread_users: {
                  without_refunds: 0.2875,
                  with_refunds: 0.2873,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {
                  without_refunds: 0.2222,
                  with_refunds: 0.2222,
                },
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 33.4,
                  with_refunds: 30.83,
                },
                gross_mrr: {
                  without_refunds: 35.84,
                  with_refunds: 33.12,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 3965.84,
                  with_refunds: 3965.84,
                },
                gross_arr_spread: {
                  without_refunds: 4237.85,
                  with_refunds: 4237.85,
                },
                net_total: {
                  without_refunds: 22.6,
                  with_refunds: 25.38,
                },
                gross_total: {
                  without_refunds: 23.82,
                  with_refunds: 26.69,
                },
                net_total_spread: {
                  without_refunds: 1987.03,
                  with_refunds: 1996.92,
                },
                gross_total_spread: {
                  without_refunds: 2112.85,
                  with_refunds: 2122.26,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.008,
                  with_refunds: 0.0077,
                },
                gross_mrr: {
                  without_refunds: 0.0074,
                  with_refunds: 0.0071,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9533,
                  with_refunds: 1.9533,
                },
                gross_arr_spread: {
                  without_refunds: 1.9275,
                  with_refunds: 1.9275,
                },
                net_total: {
                  without_refunds: 0.0007,
                  with_refunds: 0.0009,
                },
                gross_total: {
                  without_refunds: 0.0007,
                  with_refunds: 0.0008,
                },
                net_total_spread: {
                  without_refunds: 0.3213,
                  with_refunds: 0.3309,
                },
                gross_total_spread: {
                  without_refunds: 0.299,
                  with_refunds: 0.3084,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {
                  without_refunds: 0.2001,
                },
                gross_arr: {
                  without_refunds: 0.2041,
                },
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.1,
                  with_refunds: 0.09,
                },
                gross_mrr: {
                  without_refunds: 0.11,
                  with_refunds: 0.1,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.66,
                  with_refunds: 11.66,
                },
                gross_arr_spread: {
                  without_refunds: 12.46,
                  with_refunds: 12.46,
                },
                net_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.04,
                },
                gross_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.04,
                },
                net_total_spread: {
                  without_refunds: 2.94,
                  with_refunds: 2.95,
                },
                gross_total_spread: {
                  without_refunds: 3.13,
                  with_refunds: 3.14,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 20.02,
                  with_refunds: 18.13,
                },
                gross_mrr: {
                  without_refunds: 21.48,
                  with_refunds: 19.48,
                },
                net_arr: {
                  without_refunds: 79.69,
                },
                gross_arr: {
                  without_refunds: 110.85,
                },
                net_arr_spread: {
                  without_refunds: 11.51,
                  with_refunds: 11.51,
                },
                gross_arr_spread: {
                  without_refunds: 12.3,
                  with_refunds: 12.3,
                },
                net_total: {
                  without_refunds: 14,
                  with_refunds: 15.51,
                },
                gross_total: {
                  without_refunds: 14.76,
                  with_refunds: 16.31,
                },
                net_total_spread: {
                  without_refunds: 10.22,
                  with_refunds: 10.28,
                },
                gross_total_spread: {
                  without_refunds: 10.87,
                  with_refunds: 10.93,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 31.39,
                    with_refunds: 29.99,
                  },
                  gross_mrr: {
                    without_refunds: 35.76,
                    with_refunds: 34.12,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 109.94,
                    with_refunds: 109.94,
                  },
                  gross_arr_spread: {
                    without_refunds: 117.53,
                    with_refunds: 117.53,
                  },
                  net_total: {
                    without_refunds: 88.7,
                    with_refunds: 79.8,
                  },
                  gross_total: {
                    without_refunds: 95.57,
                    with_refunds: 86.1,
                  },
                  net_total_spread: {
                    without_refunds: 61.06,
                    with_refunds: 60.42,
                  },
                  gross_total_spread: {
                    without_refunds: 66.28,
                    with_refunds: 65.51,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 196.74,
                    with_refunds: 180.21,
                  },
                  gross_mrr: {
                    without_refunds: 215.1,
                    with_refunds: 197.12,
                  },
                  net_arr: {
                    without_refunds: 1055.39,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 1286.64,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 114.57,
                    with_refunds: 114.57,
                  },
                  gross_arr_spread: {
                    without_refunds: 122.55,
                    with_refunds: 122.55,
                  },
                  net_total: {
                    without_refunds: 284.89,
                    with_refunds: 268.44,
                  },
                  gross_total: {
                    without_refunds: 307.7,
                    with_refunds: 288.41,
                  },
                  net_total_spread: {
                    without_refunds: 121.81,
                    with_refunds: 120.81,
                  },
                  gross_total_spread: {
                    without_refunds: 131.64,
                    with_refunds: 130.43,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.1457,
                    with_refunds: 3.0049,
                  },
                  gross_mrr: {
                    without_refunds: 3.5835,
                    with_refunds: 3.4186,
                  },
                  net_arr: {
                    without_refunds: 13.8726,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7782,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 11.0157,
                    with_refunds: 11.0157,
                  },
                  gross_arr_spread: {
                    without_refunds: 11.7763,
                    with_refunds: 11.7763,
                  },
                  net_total: {
                    without_refunds: 8.8876,
                    with_refunds: 7.9965,
                  },
                  gross_total: {
                    without_refunds: 9.5766,
                    with_refunds: 8.6277,
                  },
                  net_total_spread: {
                    without_refunds: 6.118,
                    with_refunds: 6.0537,
                  },
                  gross_total_spread: {
                    without_refunds: 6.6415,
                    with_refunds: 6.564,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 19.7133,
                    with_refunds: 18.0571,
                  },
                  gross_mrr: {
                    without_refunds: 21.5534,
                    with_refunds: 19.7514,
                  },
                  net_arr: {
                    without_refunds: 105.7509,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 128.9216,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 11.4797,
                    with_refunds: 11.4797,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.2795,
                    with_refunds: 12.2795,
                  },
                  net_total: {
                    without_refunds: 28.5464,
                    with_refunds: 26.8976,
                  },
                  gross_total: {
                    without_refunds: 30.8317,
                    with_refunds: 28.8989,
                  },
                  net_total_spread: {
                    without_refunds: 12.205,
                    with_refunds: 12.1056,
                  },
                  gross_total_spread: {
                    without_refunds: 13.19,
                    with_refunds: 13.0691,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.18,
                  with_refunds: 1.2,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 346.5,
                  with_refunds: 346.5,
                },
                total_users: {
                  without_refunds: 1.14,
                  with_refunds: 1.15,
                },
                total_spread_users: {
                  without_refunds: 171.39,
                  with_refunds: 171.1,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0035,
                  with_refunds: 0.0036,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0191,
                  with_refunds: 1.0191,
                },
                total_users: {
                  without_refunds: 0.0016,
                  with_refunds: 0.0016,
                },
                total_spread_users: {
                  without_refunds: 0.2535,
                  with_refunds: 0.2531,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 23.56,
                  with_refunds: 21.85,
                },
                gross_mrr: {
                  without_refunds: 25.24,
                  with_refunds: 23.43,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 3997.08,
                  with_refunds: 3997.08,
                },
                gross_arr_spread: {
                  without_refunds: 4266.26,
                  with_refunds: 4266.26,
                },
                net_total: {
                  without_refunds: 16,
                  with_refunds: 18.07,
                },
                gross_total: {
                  without_refunds: 16.84,
                  with_refunds: 18.96,
                },
                net_total_spread: {
                  without_refunds: 1695.06,
                  with_refunds: 1709.24,
                },
                gross_total_spread: {
                  without_refunds: 1805.59,
                  with_refunds: 1819.29,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0057,
                  with_refunds: 0.0055,
                },
                gross_mrr: {
                  without_refunds: 0.0052,
                  with_refunds: 0.005,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9687,
                  with_refunds: 1.9687,
                },
                gross_arr_spread: {
                  without_refunds: 1.9404,
                  with_refunds: 1.9404,
                },
                net_total: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0006,
                },
                gross_total: {
                  without_refunds: 0.0005,
                  with_refunds: 0.0006,
                },
                net_total_spread: {
                  without_refunds: 0.2741,
                  with_refunds: 0.2833,
                },
                gross_total_spread: {
                  without_refunds: 0.2555,
                  with_refunds: 0.2644,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {
                  without_refunds: 0.6705,
                },
                gross_arr: {
                  without_refunds: 0.6582,
                },
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.07,
                  with_refunds: 0.06,
                },
                gross_mrr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.76,
                  with_refunds: 11.76,
                },
                gross_arr_spread: {
                  without_refunds: 12.55,
                  with_refunds: 12.55,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.03,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.03,
                },
                net_total_spread: {
                  without_refunds: 2.51,
                  with_refunds: 2.53,
                },
                gross_total_spread: {
                  without_refunds: 2.67,
                  with_refunds: 2.69,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.99,
                  with_refunds: 18.22,
                },
                gross_mrr: {
                  without_refunds: 21.42,
                  with_refunds: 19.53,
                },
                net_arr: {
                  without_refunds: 80.15,
                },
                gross_arr: {
                  without_refunds: 109.44,
                },
                net_arr_spread: {
                  without_refunds: 11.54,
                  with_refunds: 11.54,
                },
                gross_arr_spread: {
                  without_refunds: 12.31,
                  with_refunds: 12.31,
                },
                net_total: {
                  without_refunds: 14.03,
                  with_refunds: 15.65,
                },
                gross_total: {
                  without_refunds: 14.77,
                  with_refunds: 16.42,
                },
                net_total_spread: {
                  without_refunds: 9.89,
                  with_refunds: 9.99,
                },
                gross_total_spread: {
                  without_refunds: 10.53,
                  with_refunds: 10.63,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 31.46,
                    with_refunds: 30.05,
                  },
                  gross_mrr: {
                    without_refunds: 35.84,
                    with_refunds: 34.19,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 121.69,
                    with_refunds: 121.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 130.08,
                    with_refunds: 130.08,
                  },
                  net_total: {
                    without_refunds: 88.72,
                    with_refunds: 79.83,
                  },
                  gross_total: {
                    without_refunds: 95.6,
                    with_refunds: 86.13,
                  },
                  net_total_spread: {
                    without_refunds: 63.57,
                    with_refunds: 62.94,
                  },
                  gross_total_spread: {
                    without_refunds: 68.95,
                    with_refunds: 68.2,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 216.73,
                    with_refunds: 198.43,
                  },
                  gross_mrr: {
                    without_refunds: 236.52,
                    with_refunds: 216.65,
                  },
                  net_arr: {
                    without_refunds: 1135.54,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 1396.08,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 126.1,
                    with_refunds: 126.1,
                  },
                  gross_arr_spread: {
                    without_refunds: 134.86,
                    with_refunds: 134.86,
                  },
                  net_total: {
                    without_refunds: 298.93,
                    with_refunds: 284.08,
                  },
                  gross_total: {
                    without_refunds: 322.47,
                    with_refunds: 304.83,
                  },
                  net_total_spread: {
                    without_refunds: 131.7,
                    with_refunds: 130.8,
                  },
                  gross_total_spread: {
                    without_refunds: 142.17,
                    with_refunds: 141.06,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.1527,
                    with_refunds: 3.0114,
                  },
                  gross_mrr: {
                    without_refunds: 3.591,
                    with_refunds: 3.4256,
                  },
                  net_arr: {
                    without_refunds: 13.8726,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7782,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 12.1937,
                    with_refunds: 12.1937,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.0336,
                    with_refunds: 13.0336,
                  },
                  net_total: {
                    without_refunds: 8.8898,
                    with_refunds: 7.999,
                  },
                  gross_total: {
                    without_refunds: 9.579,
                    with_refunds: 8.6303,
                  },
                  net_total_spread: {
                    without_refunds: 6.3692,
                    with_refunds: 6.3071,
                  },
                  gross_total_spread: {
                    without_refunds: 6.9091,
                    with_refunds: 6.8337,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.7164,
                    with_refunds: 19.8824,
                  },
                  gross_mrr: {
                    without_refunds: 23.6994,
                    with_refunds: 21.7086,
                  },
                  net_arr: {
                    without_refunds: 113.7817,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 139.8874,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 12.6356,
                    with_refunds: 12.6356,
                  },
                  gross_arr_spread: {
                    without_refunds: 13.5132,
                    with_refunds: 13.5132,
                  },
                  net_total: {
                    without_refunds: 29.9524,
                    with_refunds: 28.4652,
                  },
                  gross_total: {
                    without_refunds: 32.3113,
                    with_refunds: 30.5438,
                  },
                  net_total_spread: {
                    without_refunds: 13.196,
                    with_refunds: 13.1065,
                  },
                  gross_total_spread: {
                    without_refunds: 14.2456,
                    with_refunds: 14.1345,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.71,
                  with_refunds: 0.73,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 347.22,
                  with_refunds: 347.22,
                },
                total_users: {
                  without_refunds: 0.69,
                  with_refunds: 0.7,
                },
                total_spread_users: {
                  without_refunds: 125.33,
                  with_refunds: 125.07,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0021,
                  with_refunds: 0.0022,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0212,
                  with_refunds: 1.0212,
                },
                total_users: {
                  without_refunds: 0.001,
                  with_refunds: 0.001,
                },
                total_spread_users: {
                  without_refunds: 0.1854,
                  with_refunds: 0.185,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 14.36,
                  with_refunds: 13.41,
                },
                gross_mrr: {
                  without_refunds: 15.42,
                  with_refunds: 14.41,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 4008.74,
                  with_refunds: 4008.74,
                },
                gross_arr_spread: {
                  without_refunds: 4276.03,
                  with_refunds: 4276.03,
                },
                net_total: {
                  without_refunds: 9.44,
                  with_refunds: 10.87,
                },
                gross_total: {
                  without_refunds: 9.88,
                  with_refunds: 11.39,
                },
                net_total_spread: {
                  without_refunds: 1168.77,
                  with_refunds: 1184.99,
                },
                gross_total_spread: {
                  without_refunds: 1281.01,
                  with_refunds: 1297.47,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0035,
                  with_refunds: 0.0033,
                },
                gross_mrr: {
                  without_refunds: 0.0032,
                  with_refunds: 0.0031,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.9744,
                  with_refunds: 1.9744,
                },
                gross_arr_spread: {
                  without_refunds: 1.9449,
                  with_refunds: 1.9449,
                },
                net_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0004,
                },
                gross_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0004,
                },
                net_total_spread: {
                  without_refunds: 0.189,
                  with_refunds: 0.1964,
                },
                gross_total_spread: {
                  without_refunds: 0.1813,
                  with_refunds: 0.1886,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {
                  without_refunds: 0.155,
                },
                gross_arr: {
                  without_refunds: 0.138,
                },
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                gross_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.04,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.79,
                  with_refunds: 11.79,
                },
                gross_arr_spread: {
                  without_refunds: 12.58,
                  with_refunds: 12.58,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.02,
                },
                net_total_spread: {
                  without_refunds: 1.73,
                  with_refunds: 1.75,
                },
                gross_total_spread: {
                  without_refunds: 1.89,
                  with_refunds: 1.92,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 20.1,
                  with_refunds: 18.45,
                },
                gross_mrr: {
                  without_refunds: 21.59,
                  with_refunds: 19.83,
                },
                net_arr: {
                  without_refunds: 74.53,
                },
                gross_arr: {
                  without_refunds: 90.61,
                },
                net_arr_spread: {
                  without_refunds: 11.55,
                  with_refunds: 11.55,
                },
                gross_arr_spread: {
                  without_refunds: 12.32,
                  with_refunds: 12.32,
                },
                net_total: {
                  without_refunds: 13.7,
                  with_refunds: 15.58,
                },
                gross_total: {
                  without_refunds: 14.34,
                  with_refunds: 16.33,
                },
                net_total_spread: {
                  without_refunds: 9.33,
                  with_refunds: 9.47,
                },
                gross_total_spread: {
                  without_refunds: 10.22,
                  with_refunds: 10.37,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 31.51,
                    with_refunds: 30.09,
                  },
                  gross_mrr: {
                    without_refunds: 35.88,
                    with_refunds: 34.23,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 133.48,
                    with_refunds: 133.48,
                  },
                  gross_arr_spread: {
                    without_refunds: 142.65,
                    with_refunds: 142.65,
                  },
                  net_total: {
                    without_refunds: 88.73,
                    with_refunds: 79.84,
                  },
                  gross_total: {
                    without_refunds: 95.61,
                    with_refunds: 86.15,
                  },
                  net_total_spread: {
                    without_refunds: 65.29,
                    with_refunds: 64.7,
                  },
                  gross_total_spread: {
                    without_refunds: 70.85,
                    with_refunds: 70.12,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 236.83,
                    with_refunds: 216.87,
                  },
                  gross_mrr: {
                    without_refunds: 258.11,
                    with_refunds: 236.48,
                  },
                  net_arr: {
                    without_refunds: 1210.07,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 1486.69,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 137.65,
                    with_refunds: 137.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 147.18,
                    with_refunds: 147.18,
                  },
                  net_total: {
                    without_refunds: 312.62,
                    with_refunds: 299.66,
                  },
                  gross_total: {
                    without_refunds: 336.8,
                    with_refunds: 321.16,
                  },
                  net_total_spread: {
                    without_refunds: 141.02,
                    with_refunds: 140.28,
                  },
                  gross_total_spread: {
                    without_refunds: 152.39,
                    with_refunds: 151.44,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.157,
                    with_refunds: 3.0154,
                  },
                  gross_mrr: {
                    without_refunds: 3.5956,
                    with_refunds: 3.4299,
                  },
                  net_arr: {
                    without_refunds: 13.8726,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7782,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 13.3751,
                    with_refunds: 13.3751,
                  },
                  gross_arr_spread: {
                    without_refunds: 14.2937,
                    with_refunds: 14.2937,
                  },
                  net_total: {
                    without_refunds: 8.8912,
                    with_refunds: 8.0005,
                  },
                  gross_total: {
                    without_refunds: 9.5804,
                    with_refunds: 8.6319,
                  },
                  net_total_spread: {
                    without_refunds: 6.5425,
                    with_refunds: 6.4827,
                  },
                  gross_total_spread: {
                    without_refunds: 7.099,
                    with_refunds: 7.026,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.7305,
                    with_refunds: 21.7308,
                  },
                  gross_mrr: {
                    without_refunds: 25.8625,
                    with_refunds: 23.6955,
                  },
                  net_arr: {
                    without_refunds: 121.2498,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 148.9666,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 13.7924,
                    with_refunds: 13.7924,
                  },
                  gross_arr_spread: {
                    without_refunds: 14.7472,
                    with_refunds: 14.7472,
                  },
                  net_total: {
                    without_refunds: 31.3248,
                    with_refunds: 30.0265,
                  },
                  gross_total: {
                    without_refunds: 33.7478,
                    with_refunds: 32.1801,
                  },
                  net_total_spread: {
                    without_refunds: 14.1304,
                    with_refunds: 14.0559,
                  },
                  gross_total_spread: {
                    without_refunds: 15.2698,
                    with_refunds: 15.174,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.21,
                  with_refunds: 0.22,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 118.14,
                  with_refunds: 118.14,
                },
                total_users: {
                  without_refunds: 0.21,
                  with_refunds: 0.21,
                },
                total_spread_users: {
                  without_refunds: 39.21,
                  with_refunds: 39.07,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0006,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3475,
                  with_refunds: 0.3475,
                },
                total_users: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0003,
                },
                total_spread_users: {
                  without_refunds: 0.058,
                  with_refunds: 0.0578,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4.3,
                  with_refunds: 4.02,
                },
                gross_mrr: {
                  without_refunds: 4.59,
                  with_refunds: 4.29,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1538.76,
                  with_refunds: 1538.76,
                },
                gross_arr_spread: {
                  without_refunds: 1534.88,
                  with_refunds: 1534.88,
                },
                net_total: {
                  without_refunds: 2.77,
                  with_refunds: 3.2,
                },
                gross_total: {
                  without_refunds: 2.88,
                  with_refunds: 3.33,
                },
                net_total_spread: {
                  without_refunds: 369.15,
                  with_refunds: 374.83,
                },
                gross_total_spread: {
                  without_refunds: 400.63,
                  with_refunds: 406.31,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.001,
                  with_refunds: 0.001,
                },
                gross_mrr: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0009,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.7579,
                  with_refunds: 0.7579,
                },
                gross_arr_spread: {
                  without_refunds: 0.6981,
                  with_refunds: 0.6981,
                },
                net_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.0597,
                  with_refunds: 0.0621,
                },
                gross_total_spread: {
                  without_refunds: 0.0567,
                  with_refunds: 0.059,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_mrr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 4.53,
                  with_refunds: 4.53,
                },
                gross_arr_spread: {
                  without_refunds: 4.51,
                  with_refunds: 4.51,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 0.55,
                  with_refunds: 0.55,
                },
                gross_total_spread: {
                  without_refunds: 0.59,
                  with_refunds: 0.6,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 20,
                  with_refunds: 18.42,
                },
                gross_mrr: {
                  without_refunds: 21.35,
                  with_refunds: 19.68,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 13.02,
                  with_refunds: 13.02,
                },
                gross_arr_spread: {
                  without_refunds: 12.99,
                  with_refunds: 12.99,
                },
                net_total: {
                  without_refunds: 13.42,
                  with_refunds: 15.33,
                },
                gross_total: {
                  without_refunds: 13.95,
                  with_refunds: 15.95,
                },
                net_total_spread: {
                  without_refunds: 9.42,
                  with_refunds: 9.59,
                },
                gross_total_spread: {
                  without_refunds: 10.22,
                  with_refunds: 10.4,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 31.52,
                    with_refunds: 30.11,
                  },
                  gross_mrr: {
                    without_refunds: 35.9,
                    with_refunds: 34.24,
                  },
                  net_arr: {
                    without_refunds: 138.45,
                    with_refunds: 122.54,
                  },
                  gross_arr: {
                    without_refunds: 147.49,
                    with_refunds: 130.68,
                  },
                  net_arr_spread: {
                    without_refunds: 138.01,
                    with_refunds: 138.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 147.17,
                    with_refunds: 147.17,
                  },
                  net_total: {
                    without_refunds: 88.74,
                    with_refunds: 79.85,
                  },
                  gross_total: {
                    without_refunds: 95.62,
                    with_refunds: 86.15,
                  },
                  net_total_spread: {
                    without_refunds: 65.84,
                    with_refunds: 65.25,
                  },
                  gross_total_spread: {
                    without_refunds: 71.44,
                    with_refunds: 70.72,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 256.83,
                    with_refunds: 235.3,
                  },
                  gross_mrr: {
                    without_refunds: 279.46,
                    with_refunds: 256.16,
                  },
                  net_arr: {
                    without_refunds: 1210.07,
                    with_refunds: 242.52,
                  },
                  gross_arr: {
                    without_refunds: 1486.69,
                    with_refunds: 258.37,
                  },
                  net_arr_spread: {
                    without_refunds: 150.67,
                    with_refunds: 150.67,
                  },
                  gross_arr_spread: {
                    without_refunds: 160.17,
                    with_refunds: 160.17,
                  },
                  net_total: {
                    without_refunds: 326.04,
                    with_refunds: 314.99,
                  },
                  gross_total: {
                    without_refunds: 350.75,
                    with_refunds: 337.11,
                  },
                  net_total_spread: {
                    without_refunds: 150.44,
                    with_refunds: 149.87,
                  },
                  gross_total_spread: {
                    without_refunds: 162.61,
                    with_refunds: 161.84,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 3.1582,
                    with_refunds: 3.0166,
                  },
                  gross_mrr: {
                    without_refunds: 3.5969,
                    with_refunds: 3.4311,
                  },
                  net_arr: {
                    without_refunds: 13.8726,
                    with_refunds: 12.2788,
                  },
                  gross_arr: {
                    without_refunds: 14.7782,
                    with_refunds: 13.0942,
                  },
                  net_arr_spread: {
                    without_refunds: 13.8286,
                    with_refunds: 13.8286,
                  },
                  gross_arr_spread: {
                    without_refunds: 14.7461,
                    with_refunds: 14.7461,
                  },
                  net_total: {
                    without_refunds: 8.8915,
                    with_refunds: 8.0009,
                  },
                  gross_total: {
                    without_refunds: 9.5808,
                    with_refunds: 8.6324,
                  },
                  net_total_spread: {
                    without_refunds: 6.5972,
                    with_refunds: 6.5383,
                  },
                  gross_total_spread: {
                    without_refunds: 7.1584,
                    with_refunds: 7.0862,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 25.7346,
                    with_refunds: 23.5768,
                  },
                  gross_mrr: {
                    without_refunds: 28.0021,
                    with_refunds: 25.6678,
                  },
                  net_arr: {
                    without_refunds: 121.2498,
                    with_refunds: 24.3011,
                  },
                  gross_arr: {
                    without_refunds: 148.9666,
                    with_refunds: 25.8892,
                  },
                  net_arr_spread: {
                    without_refunds: 15.0975,
                    with_refunds: 15.0975,
                  },
                  gross_arr_spread: {
                    without_refunds: 16.049,
                    with_refunds: 16.049,
                  },
                  net_total: {
                    without_refunds: 32.6698,
                    with_refunds: 31.5623,
                  },
                  gross_total: {
                    without_refunds: 35.1458,
                    with_refunds: 33.7782,
                  },
                  net_total_spread: {
                    without_refunds: 15.0738,
                    with_refunds: 15.0171,
                  },
                  gross_total_spread: {
                    without_refunds: 16.2937,
                    with_refunds: 16.2159,
                  },
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 212,
              with_refunds: 212,
            },
            returning_users: {
              without_refunds: 4767,
              with_refunds: 4784,
            },
            paying_users: {
              without_refunds: 4979,
              with_refunds: 4996,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 208,
              with_refunds: 208,
            },
            returning_users: {
              without_refunds: 444,
              with_refunds: 501,
            },
            paying_users: {
              without_refunds: 652,
              with_refunds: 709,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 418,
              with_refunds: 418,
            },
            returning_users: {
              without_refunds: 5211,
              with_refunds: 5285,
            },
            paying_users: {
              without_refunds: 5629,
              with_refunds: 5703,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 9355.74,
                with_refunds: 8983.5,
              },
              gross_mrr: {
                without_refunds: 10730.2,
                with_refunds: 10284.91,
              },
              net_arr: {
                without_refunds: 53372.41,
                with_refunds: 47301.47,
              },
              gross_arr: {
                without_refunds: 56843.85,
                with_refunds: 50442.44,
              },
              net_arr_spread: {
                without_refunds: 9821.18,
                with_refunds: 9821.18,
              },
              gross_arr_spread: {
                without_refunds: 10513.61,
                with_refunds: 10513.61,
              },
              net_total: {
                without_refunds: 62728.14,
                with_refunds: 56284.97,
              },
              gross_total: {
                without_refunds: 67574.05,
                with_refunds: 60727.35,
              },
              net_total_spread: {
                without_refunds: 19176.92,
                with_refunds: 18804.69,
              },
              gross_total_spread: {
                without_refunds: 21243.81,
                with_refunds: 20798.52,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 10622,
                with_refunds: 10145.51,
              },
              gross_mrr: {
                without_refunds: 12097.46,
                with_refunds: 11539.81,
              },
              net_arr: {
                without_refunds: 53441.04,
                with_refunds: 47301.47,
              },
              gross_arr: {
                without_refunds: 56929.73,
                with_refunds: 50442.44,
              },
              net_arr_spread: {
                without_refunds: 46923.17,
                with_refunds: 46923.17,
              },
              gross_arr_spread: {
                without_refunds: 50036.42,
                with_refunds: 50036.42,
              },
              net_total: {
                without_refunds: 63802.36,
                with_refunds: 57411.75,
              },
              gross_total: {
                without_refunds: 68747.99,
                with_refunds: 61942.64,
              },
              net_total_spread: {
                without_refunds: 44507.89,
                with_refunds: 44110.2,
              },
              gross_total_spread: {
                without_refunds: 48293.84,
                with_refunds: 47806.88,
              },
            },
          },
        },
      },
      cac: 9.98,
      mrr_ua_spend: {
        without_refunds: 3363.26,
        with_refunds: 3363.26,
      },
      arr_ua_spend: {
        without_refunds: 3852.28,
        with_refunds: 3852.28,
      },
      arr_spread_ua_spend: {
        without_refunds: 3393.2,
        with_refunds: 3393.2,
      },
      total_ua_spend: {
        without_refunds: 7175.62,
        with_refunds: 7175.62,
      },
      total_spread_ua_spend: {
        without_refunds: 6746.48,
        with_refunds: 6746.48,
      },
    },
    {
      cohort_date: {
        year: 2024,
        month: 6,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 263,
        with_refunds: 263,
      },
      first_period_arr_users: {
        without_refunds: 199,
        with_refunds: 199,
      },
      first_period_arr_spread_users: {
        without_refunds: 180,
        with_refunds: 180,
      },
      first_period_total_users: {
        without_refunds: 462,
        with_refunds: 462,
      },
      first_period_total_spread_users: {
        without_refunds: 443,
        with_refunds: 443,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 212,
                  with_refunds: 212,
                },
                arr_users: {
                  without_refunds: 168,
                  with_refunds: 168,
                },
                arr_spread_users: {
                  without_refunds: 149,
                  with_refunds: 149,
                },
                total_users: {
                  without_refunds: 380,
                  with_refunds: 380,
                },
                total_spread_users: {
                  without_refunds: 361,
                  with_refunds: 361,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.8061,
                  with_refunds: 0.8061,
                },
                arr_users: {
                  without_refunds: 0.8442,
                  with_refunds: 0.8442,
                },
                arr_spread_users: {
                  without_refunds: 0.8278,
                  with_refunds: 0.8278,
                },
                total_users: {
                  without_refunds: 0.8225,
                  with_refunds: 0.8225,
                },
                total_spread_users: {
                  without_refunds: 0.8149,
                  with_refunds: 0.8149,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3957.64,
                  with_refunds: 3687.91,
                },
                gross_mrr: {
                  without_refunds: 4535.94,
                  with_refunds: 4246.68,
                },
                net_arr: {
                  without_refunds: 22899.94,
                  with_refunds: 21124.35,
                },
                gross_arr: {
                  without_refunds: 24525.26,
                  with_refunds: 22677.31,
                },
                net_arr_spread: {
                  without_refunds: 1700.48,
                  with_refunds: 1700.48,
                },
                gross_arr_spread: {
                  without_refunds: 1826.59,
                  with_refunds: 1826.59,
                },
                net_total: {
                  without_refunds: 26857.58,
                  with_refunds: 24812.26,
                },
                gross_total: {
                  without_refunds: 29061.21,
                  with_refunds: 26923.99,
                },
                net_total_spread: {
                  without_refunds: 5658.12,
                  with_refunds: 5388.39,
                },
                gross_total_spread: {
                  without_refunds: 6362.53,
                  with_refunds: 6073.27,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 15.05,
                  with_refunds: 14.02,
                },
                gross_mrr: {
                  without_refunds: 17.25,
                  with_refunds: 16.15,
                },
                net_arr: {
                  without_refunds: 115.08,
                  with_refunds: 106.15,
                },
                gross_arr: {
                  without_refunds: 123.24,
                  with_refunds: 113.96,
                },
                net_arr_spread: {
                  without_refunds: 9.45,
                  with_refunds: 9.45,
                },
                gross_arr_spread: {
                  without_refunds: 10.15,
                  with_refunds: 10.15,
                },
                net_total: {
                  without_refunds: 58.13,
                  with_refunds: 53.71,
                },
                gross_total: {
                  without_refunds: 62.9,
                  with_refunds: 58.28,
                },
                net_total_spread: {
                  without_refunds: 12.77,
                  with_refunds: 12.16,
                },
                gross_total_spread: {
                  without_refunds: 14.36,
                  with_refunds: 13.71,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 18.67,
                  with_refunds: 17.4,
                },
                gross_mrr: {
                  without_refunds: 21.4,
                  with_refunds: 20.03,
                },
                net_arr: {
                  without_refunds: 136.31,
                  with_refunds: 125.74,
                },
                gross_arr: {
                  without_refunds: 145.98,
                  with_refunds: 134.98,
                },
                net_arr_spread: {
                  without_refunds: 11.41,
                  with_refunds: 11.41,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 70.68,
                  with_refunds: 65.3,
                },
                gross_total: {
                  without_refunds: 76.48,
                  with_refunds: 70.85,
                },
                net_total_spread: {
                  without_refunds: 15.67,
                  with_refunds: 14.93,
                },
                gross_total_spread: {
                  without_refunds: 17.62,
                  with_refunds: 16.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 15.05,
                    with_refunds: 14.02,
                  },
                  gross_mrr: {
                    without_refunds: 17.25,
                    with_refunds: 16.15,
                  },
                  net_arr: {
                    without_refunds: 115.08,
                    with_refunds: 106.15,
                  },
                  gross_arr: {
                    without_refunds: 123.24,
                    with_refunds: 113.96,
                  },
                  net_arr_spread: {
                    without_refunds: 9.45,
                    with_refunds: 9.45,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.15,
                    with_refunds: 10.15,
                  },
                  net_total: {
                    without_refunds: 58.13,
                    with_refunds: 53.71,
                  },
                  gross_total: {
                    without_refunds: 62.9,
                    with_refunds: 58.28,
                  },
                  net_total_spread: {
                    without_refunds: 12.77,
                    with_refunds: 12.16,
                  },
                  gross_total_spread: {
                    without_refunds: 14.36,
                    with_refunds: 13.71,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.67,
                    with_refunds: 17.4,
                  },
                  gross_mrr: {
                    without_refunds: 21.4,
                    with_refunds: 20.03,
                  },
                  net_arr: {
                    without_refunds: 136.31,
                    with_refunds: 125.74,
                  },
                  gross_arr: {
                    without_refunds: 145.98,
                    with_refunds: 134.98,
                  },
                  net_arr_spread: {
                    without_refunds: 11.41,
                    with_refunds: 11.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 12.26,
                    with_refunds: 12.26,
                  },
                  net_total: {
                    without_refunds: 70.68,
                    with_refunds: 65.3,
                  },
                  gross_total: {
                    without_refunds: 76.48,
                    with_refunds: 70.85,
                  },
                  net_total_spread: {
                    without_refunds: 15.67,
                    with_refunds: 14.93,
                  },
                  gross_total_spread: {
                    without_refunds: 17.62,
                    with_refunds: 16.82,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 58,
                  with_refunds: 59,
                },
                arr_users: {
                  without_refunds: 31,
                  with_refunds: 37,
                },
                arr_spread_users: {
                  without_refunds: 180,
                  with_refunds: 180,
                },
                total_users: {
                  without_refunds: 89,
                  with_refunds: 96,
                },
                total_spread_users: {
                  without_refunds: 238,
                  with_refunds: 239,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2205,
                  with_refunds: 0.2243,
                },
                arr_users: {
                  without_refunds: 0.1558,
                  with_refunds: 0.1859,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 0.1926,
                  with_refunds: 0.2078,
                },
                total_spread_users: {
                  without_refunds: 0.5372,
                  with_refunds: 0.5395,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.2736,
                  with_refunds: 0.2783,
                },
                arr_users: {
                  without_refunds: 0.1845,
                  with_refunds: 0.2202,
                },
                arr_spread_users: {
                  without_refunds: 1.2081,
                  with_refunds: 1.2081,
                },
                total_users: {
                  without_refunds: 0.2342,
                  with_refunds: 0.2526,
                },
                total_spread_users: {
                  without_refunds: 0.6593,
                  with_refunds: 0.662,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 1053.89,
                  with_refunds: 1036.9,
                },
                gross_mrr: {
                  without_refunds: 1166.68,
                  with_refunds: 1146.69,
                },
                net_arr: {
                  without_refunds: 4340.72,
                  with_refunds: 3641.23,
                },
                gross_arr: {
                  without_refunds: 4558.05,
                  with_refunds: 3819.8,
                },
                net_arr_spread: {
                  without_refunds: 2062.21,
                  with_refunds: 2062.21,
                },
                gross_arr_spread: {
                  without_refunds: 2206.43,
                  with_refunds: 2206.43,
                },
                net_total: {
                  without_refunds: 5394.61,
                  with_refunds: 4678.13,
                },
                gross_total: {
                  without_refunds: 5724.73,
                  with_refunds: 4966.5,
                },
                net_total_spread: {
                  without_refunds: 3116.1,
                  with_refunds: 3099.11,
                },
                gross_total_spread: {
                  without_refunds: 3373.11,
                  with_refunds: 3353.12,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.2663,
                  with_refunds: 0.2812,
                },
                gross_mrr: {
                  without_refunds: 0.2572,
                  with_refunds: 0.27,
                },
                net_arr: {
                  without_refunds: 0.1896,
                  with_refunds: 0.1724,
                },
                gross_arr: {
                  without_refunds: 0.1859,
                  with_refunds: 0.1684,
                },
                net_arr_spread: {
                  without_refunds: 1.2127,
                  with_refunds: 1.2127,
                },
                gross_arr_spread: {
                  without_refunds: 1.2079,
                  with_refunds: 1.2079,
                },
                net_total: {
                  without_refunds: 0.2009,
                  with_refunds: 0.1885,
                },
                gross_total: {
                  without_refunds: 0.197,
                  with_refunds: 0.1845,
                },
                net_total_spread: {
                  without_refunds: 0.5507,
                  with_refunds: 0.5751,
                },
                gross_total_spread: {
                  without_refunds: 0.5302,
                  with_refunds: 0.5521,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2663,
                  with_refunds: 0.2812,
                },
                gross_mrr: {
                  without_refunds: 0.2572,
                  with_refunds: 0.27,
                },
                net_arr: {
                  without_refunds: 0.1896,
                  with_refunds: 0.1724,
                },
                gross_arr: {
                  without_refunds: 0.1859,
                  with_refunds: 0.1684,
                },
                net_arr_spread: {
                  without_refunds: 1.2127,
                  with_refunds: 1.2127,
                },
                gross_arr_spread: {
                  without_refunds: 1.2079,
                  with_refunds: 1.2079,
                },
                net_total: {
                  without_refunds: 0.2009,
                  with_refunds: 0.1885,
                },
                gross_total: {
                  without_refunds: 0.197,
                  with_refunds: 0.1845,
                },
                net_total_spread: {
                  without_refunds: 0.5507,
                  with_refunds: 0.5751,
                },
                gross_total_spread: {
                  without_refunds: 0.5302,
                  with_refunds: 0.5521,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 4.01,
                  with_refunds: 3.94,
                },
                gross_mrr: {
                  without_refunds: 4.44,
                  with_refunds: 4.36,
                },
                net_arr: {
                  without_refunds: 21.81,
                  with_refunds: 18.3,
                },
                gross_arr: {
                  without_refunds: 22.9,
                  with_refunds: 19.19,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 11.68,
                  with_refunds: 10.13,
                },
                gross_total: {
                  without_refunds: 12.39,
                  with_refunds: 10.75,
                },
                net_total_spread: {
                  without_refunds: 7.03,
                  with_refunds: 7,
                },
                gross_total_spread: {
                  without_refunds: 7.61,
                  with_refunds: 7.57,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 18.17,
                  with_refunds: 17.57,
                },
                gross_mrr: {
                  without_refunds: 20.12,
                  with_refunds: 19.44,
                },
                net_arr: {
                  without_refunds: 140.02,
                  with_refunds: 98.41,
                },
                gross_arr: {
                  without_refunds: 147.03,
                  with_refunds: 103.24,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 60.61,
                  with_refunds: 48.73,
                },
                gross_total: {
                  without_refunds: 64.32,
                  with_refunds: 51.73,
                },
                net_total_spread: {
                  without_refunds: 13.09,
                  with_refunds: 12.97,
                },
                gross_total_spread: {
                  without_refunds: 14.17,
                  with_refunds: 14.03,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 19.06,
                    with_refunds: 17.97,
                  },
                  gross_mrr: {
                    without_refunds: 21.68,
                    with_refunds: 20.51,
                  },
                  net_arr: {
                    without_refunds: 136.89,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.15,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 20.9,
                    with_refunds: 20.9,
                  },
                  gross_arr_spread: {
                    without_refunds: 22.41,
                    with_refunds: 22.41,
                  },
                  net_total: {
                    without_refunds: 69.81,
                    with_refunds: 63.83,
                  },
                  gross_total: {
                    without_refunds: 75.29,
                    with_refunds: 69.03,
                  },
                  net_total_spread: {
                    without_refunds: 19.81,
                    with_refunds: 19.16,
                  },
                  gross_total_spread: {
                    without_refunds: 21.98,
                    with_refunds: 21.28,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.84,
                    with_refunds: 34.97,
                  },
                  gross_mrr: {
                    without_refunds: 41.51,
                    with_refunds: 39.47,
                  },
                  net_arr: {
                    without_refunds: 276.33,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 293.02,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 22.87,
                    with_refunds: 22.87,
                  },
                  gross_arr_spread: {
                    without_refunds: 24.52,
                    with_refunds: 24.52,
                  },
                  net_total: {
                    without_refunds: 131.29,
                    with_refunds: 114.03,
                  },
                  gross_total: {
                    without_refunds: 140.8,
                    with_refunds: 122.59,
                  },
                  net_total_spread: {
                    without_refunds: 28.77,
                    with_refunds: 27.89,
                  },
                  gross_total_spread: {
                    without_refunds: 31.8,
                    with_refunds: 30.85,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 31.22,
                  with_refunds: 31.71,
                },
                arr_users: {
                  without_refunds: 0.48,
                  with_refunds: 1.25,
                },
                arr_spread_users: {
                  without_refunds: 181.17,
                  with_refunds: 181.17,
                },
                total_users: {
                  without_refunds: 10.82,
                  with_refunds: 12.58,
                },
                total_spread_users: {
                  without_refunds: 209.64,
                  with_refunds: 210.53,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1187,
                  with_refunds: 0.1206,
                },
                arr_users: {
                  without_refunds: 0.0024,
                  with_refunds: 0.0063,
                },
                arr_spread_users: {
                  without_refunds: 1.0065,
                  with_refunds: 1.0065,
                },
                total_users: {
                  without_refunds: 0.0234,
                  with_refunds: 0.0272,
                },
                total_spread_users: {
                  without_refunds: 0.4732,
                  with_refunds: 0.4752,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.5382,
                  with_refunds: 0.5374,
                },
                arr_users: {
                  without_refunds: 0.0155,
                  with_refunds: 0.0336,
                },
                arr_spread_users: {
                  without_refunds: 1.0065,
                  with_refunds: 1.0065,
                },
                total_users: {
                  without_refunds: 0.1215,
                  with_refunds: 0.1311,
                },
                total_spread_users: {
                  without_refunds: 0.8808,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 592.53,
                  with_refunds: 576.94,
                },
                gross_mrr: {
                  without_refunds: 649.74,
                  with_refunds: 631.06,
                },
                net_arr: {
                  without_refunds: 58.97,
                },
                gross_arr: {
                  without_refunds: 66.17,
                },
                net_arr_spread: {
                  without_refunds: 2073.83,
                  with_refunds: 2073.83,
                },
                gross_arr_spread: {
                  without_refunds: 2219.72,
                  with_refunds: 2219.72,
                },
                net_total: {
                  without_refunds: 162.37,
                  with_refunds: 92.95,
                },
                gross_total: {
                  without_refunds: 180.43,
                  with_refunds: 101.79,
                },
                net_total_spread: {
                  without_refunds: 2632.19,
                  with_refunds: 2621.99,
                },
                gross_total_spread: {
                  without_refunds: 2830.52,
                  with_refunds: 2818.23,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1497,
                  with_refunds: 0.1564,
                },
                gross_mrr: {
                  without_refunds: 0.1432,
                  with_refunds: 0.1486,
                },
                net_arr: {
                  without_refunds: 0.0026,
                },
                gross_arr: {
                  without_refunds: 0.0027,
                },
                net_arr_spread: {
                  without_refunds: 1.2196,
                  with_refunds: 1.2196,
                },
                gross_arr_spread: {
                  without_refunds: 1.2152,
                  with_refunds: 1.2152,
                },
                net_total: {
                  without_refunds: 0.006,
                  with_refunds: 0.0037,
                },
                gross_total: {
                  without_refunds: 0.0062,
                  with_refunds: 0.0038,
                },
                net_total_spread: {
                  without_refunds: 0.4652,
                  with_refunds: 0.4866,
                },
                gross_total_spread: {
                  without_refunds: 0.4449,
                  with_refunds: 0.464,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5622,
                  with_refunds: 0.5564,
                },
                gross_mrr: {
                  without_refunds: 0.5569,
                  with_refunds: 0.5503,
                },
                net_arr: {
                  without_refunds: 0.0136,
                },
                gross_arr: {
                  without_refunds: 0.0145,
                },
                net_arr_spread: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                gross_arr_spread: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                net_total: {
                  without_refunds: 0.0301,
                  with_refunds: 0.0199,
                },
                gross_total: {
                  without_refunds: 0.0315,
                  with_refunds: 0.0205,
                },
                net_total_spread: {
                  without_refunds: 0.8447,
                  with_refunds: 0.846,
                },
                gross_total_spread: {
                  without_refunds: 0.8391,
                  with_refunds: 0.8405,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.25,
                  with_refunds: 2.19,
                },
                gross_mrr: {
                  without_refunds: 2.47,
                  with_refunds: 2.4,
                },
                net_arr: {
                  without_refunds: 0.3,
                },
                gross_arr: {
                  without_refunds: 0.33,
                },
                net_arr_spread: {
                  without_refunds: 11.52,
                  with_refunds: 11.52,
                },
                gross_arr_spread: {
                  without_refunds: 12.33,
                  with_refunds: 12.33,
                },
                net_total: {
                  without_refunds: 0.35,
                  with_refunds: 0.2,
                },
                gross_total: {
                  without_refunds: 0.39,
                  with_refunds: 0.22,
                },
                net_total_spread: {
                  without_refunds: 5.94,
                  with_refunds: 5.92,
                },
                gross_total_spread: {
                  without_refunds: 6.39,
                  with_refunds: 6.36,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 18.98,
                  with_refunds: 18.2,
                },
                gross_mrr: {
                  without_refunds: 20.81,
                  with_refunds: 19.9,
                },
                net_arr: {
                  without_refunds: 122.49,
                },
                gross_arr: {
                  without_refunds: 137.46,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.25,
                  with_refunds: 12.25,
                },
                net_total: {
                  without_refunds: 15.01,
                  with_refunds: 7.39,
                },
                gross_total: {
                  without_refunds: 16.68,
                  with_refunds: 8.09,
                },
                net_total_spread: {
                  without_refunds: 12.56,
                  with_refunds: 12.45,
                },
                gross_total_spread: {
                  without_refunds: 13.5,
                  with_refunds: 13.39,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 21.31,
                    with_refunds: 20.16,
                  },
                  gross_mrr: {
                    without_refunds: 24.15,
                    with_refunds: 22.91,
                  },
                  net_arr: {
                    without_refunds: 137.18,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.48,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 32.43,
                    with_refunds: 32.43,
                  },
                  gross_arr_spread: {
                    without_refunds: 34.74,
                    with_refunds: 34.74,
                  },
                  net_total: {
                    without_refunds: 70.16,
                    with_refunds: 64.03,
                  },
                  gross_total: {
                    without_refunds: 75.68,
                    with_refunds: 69.25,
                  },
                  net_total_spread: {
                    without_refunds: 25.75,
                    with_refunds: 25.08,
                  },
                  gross_total_spread: {
                    without_refunds: 28.37,
                    with_refunds: 27.64,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 55.82,
                    with_refunds: 53.17,
                  },
                  gross_mrr: {
                    without_refunds: 62.32,
                    with_refunds: 59.37,
                  },
                  net_arr: {
                    without_refunds: 398.82,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 430.47,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 34.32,
                    with_refunds: 34.32,
                  },
                  gross_arr_spread: {
                    without_refunds: 36.77,
                    with_refunds: 36.77,
                  },
                  net_total: {
                    without_refunds: 146.3,
                    with_refunds: 121.41,
                  },
                  gross_total: {
                    without_refunds: 157.48,
                    with_refunds: 130.68,
                  },
                  net_total_spread: {
                    without_refunds: 41.32,
                    with_refunds: 40.35,
                  },
                  gross_total_spread: {
                    without_refunds: 45.3,
                    with_refunds: 44.24,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 19.07,
                  with_refunds: 19.19,
                },
                arr_users: {
                  without_refunds: 0.11,
                  with_refunds: 0.2,
                },
                arr_spread_users: {
                  without_refunds: 181.51,
                  with_refunds: 181.51,
                },
                total_users: {
                  without_refunds: 6.3,
                  with_refunds: 6.78,
                },
                total_spread_users: {
                  without_refunds: 201.9,
                  with_refunds: 202.32,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0725,
                  with_refunds: 0.073,
                },
                arr_users: {
                  without_refunds: 0.0005,
                  with_refunds: 0.001,
                },
                arr_spread_users: {
                  without_refunds: 1.0084,
                  with_refunds: 1.0084,
                },
                total_users: {
                  without_refunds: 0.0136,
                  with_refunds: 0.0147,
                },
                total_spread_users: {
                  without_refunds: 0.4558,
                  with_refunds: 0.4567,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6108,
                  with_refunds: 0.6054,
                },
                arr_users: {
                  without_refunds: 0.2219,
                  with_refunds: 0.1643,
                },
                arr_spread_users: {
                  without_refunds: 1.0019,
                  with_refunds: 1.0019,
                },
                total_users: {
                  without_refunds: 0.5827,
                  with_refunds: 0.539,
                },
                total_spread_users: {
                  without_refunds: 0.9631,
                  with_refunds: 0.961,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 373.07,
                  with_refunds: 357.79,
                },
                gross_mrr: {
                  without_refunds: 408.07,
                  with_refunds: 391.13,
                },
                net_arr: {
                  without_refunds: 11.79,
                },
                gross_arr: {
                  without_refunds: 13.87,
                },
                net_arr_spread: {
                  without_refunds: 2077.05,
                  with_refunds: 2077.05,
                },
                gross_arr_spread: {
                  without_refunds: 2223.79,
                  with_refunds: 2223.79,
                },
                net_total: {
                  without_refunds: 72.86,
                  with_refunds: 57.64,
                },
                gross_total: {
                  without_refunds: 80.61,
                  with_refunds: 63.56,
                },
                net_total_spread: {
                  without_refunds: 2523.59,
                  with_refunds: 2521.85,
                },
                gross_total_spread: {
                  without_refunds: 2710.05,
                  with_refunds: 2706.55,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0943,
                  with_refunds: 0.097,
                },
                gross_mrr: {
                  without_refunds: 0.09,
                  with_refunds: 0.0921,
                },
                net_arr: {
                  without_refunds: 0.0005,
                },
                gross_arr: {
                  without_refunds: 0.0006,
                },
                net_arr_spread: {
                  without_refunds: 1.2214,
                  with_refunds: 1.2214,
                },
                gross_arr_spread: {
                  without_refunds: 1.2175,
                  with_refunds: 1.2175,
                },
                net_total: {
                  without_refunds: 0.0027,
                  with_refunds: 0.0023,
                },
                gross_total: {
                  without_refunds: 0.0028,
                  with_refunds: 0.0024,
                },
                net_total_spread: {
                  without_refunds: 0.446,
                  with_refunds: 0.468,
                },
                gross_total_spread: {
                  without_refunds: 0.4259,
                  with_refunds: 0.4456,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6296,
                  with_refunds: 0.6202,
                },
                gross_mrr: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6198,
                },
                net_arr: {
                  without_refunds: 0.1999,
                },
                gross_arr: {
                  without_refunds: 0.2096,
                },
                net_arr_spread: {
                  without_refunds: 1.0016,
                  with_refunds: 1.0016,
                },
                gross_arr_spread: {
                  without_refunds: 1.0018,
                  with_refunds: 1.0018,
                },
                net_total: {
                  without_refunds: 0.4488,
                  with_refunds: 0.6202,
                },
                gross_total: {
                  without_refunds: 0.4468,
                  with_refunds: 0.6244,
                },
                net_total_spread: {
                  without_refunds: 0.9587,
                  with_refunds: 0.9618,
                },
                gross_total_spread: {
                  without_refunds: 0.9574,
                  with_refunds: 0.9604,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.42,
                  with_refunds: 1.36,
                },
                gross_mrr: {
                  without_refunds: 1.55,
                  with_refunds: 1.49,
                },
                net_arr: {
                  without_refunds: 0.06,
                },
                gross_arr: {
                  without_refunds: 0.07,
                },
                net_arr_spread: {
                  without_refunds: 11.54,
                  with_refunds: 11.54,
                },
                gross_arr_spread: {
                  without_refunds: 12.35,
                  with_refunds: 12.35,
                },
                net_total: {
                  without_refunds: 0.16,
                  with_refunds: 0.12,
                },
                gross_total: {
                  without_refunds: 0.17,
                  with_refunds: 0.14,
                },
                net_total_spread: {
                  without_refunds: 5.7,
                  with_refunds: 5.69,
                },
                gross_total_spread: {
                  without_refunds: 6.12,
                  with_refunds: 6.11,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.56,
                  with_refunds: 18.64,
                },
                gross_mrr: {
                  without_refunds: 21.4,
                  with_refunds: 20.38,
                },
                net_arr: {
                  without_refunds: 110.32,
                },
                gross_arr: {
                  without_refunds: 129.82,
                },
                net_arr_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.44,
                },
                gross_arr_spread: {
                  without_refunds: 12.25,
                  with_refunds: 12.25,
                },
                net_total: {
                  without_refunds: 11.56,
                  with_refunds: 8.5,
                },
                gross_total: {
                  without_refunds: 12.79,
                  with_refunds: 9.37,
                },
                net_total_spread: {
                  without_refunds: 12.5,
                  with_refunds: 12.46,
                },
                gross_total_spread: {
                  without_refunds: 13.42,
                  with_refunds: 13.38,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 22.73,
                    with_refunds: 21.52,
                  },
                  gross_mrr: {
                    without_refunds: 25.71,
                    with_refunds: 24.39,
                  },
                  net_arr: {
                    without_refunds: 137.24,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.55,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 43.96,
                    with_refunds: 43.96,
                  },
                  gross_arr_spread: {
                    without_refunds: 47.09,
                    with_refunds: 47.09,
                  },
                  net_total: {
                    without_refunds: 70.32,
                    with_refunds: 64.16,
                  },
                  gross_total: {
                    without_refunds: 75.86,
                    with_refunds: 69.38,
                  },
                  net_total_spread: {
                    without_refunds: 31.44,
                    with_refunds: 30.77,
                  },
                  gross_total_spread: {
                    without_refunds: 34.48,
                    with_refunds: 33.75,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 75.38,
                    with_refunds: 71.81,
                  },
                  gross_mrr: {
                    without_refunds: 83.72,
                    with_refunds: 79.75,
                  },
                  net_arr: {
                    without_refunds: 509.14,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 560.29,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 45.76,
                    with_refunds: 45.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 49.02,
                    with_refunds: 49.02,
                  },
                  net_total: {
                    without_refunds: 157.86,
                    with_refunds: 129.91,
                  },
                  gross_total: {
                    without_refunds: 170.27,
                    with_refunds: 140.05,
                  },
                  net_total_spread: {
                    without_refunds: 53.82,
                    with_refunds: 52.81,
                  },
                  gross_total_spread: {
                    without_refunds: 58.72,
                    with_refunds: 57.62,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 10.77,
                  with_refunds: 10.85,
                },
                arr_users: {
                  without_refunds: 0.05,
                  with_refunds: 0.08,
                },
                arr_spread_users: {
                  without_refunds: 182.18,
                  with_refunds: 182.18,
                },
                total_users: {
                  without_refunds: 3.54,
                  with_refunds: 3.81,
                },
                total_spread_users: {
                  without_refunds: 185.81,
                  with_refunds: 186.21,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0409,
                  with_refunds: 0.0413,
                },
                arr_users: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0004,
                },
                arr_spread_users: {
                  without_refunds: 1.0121,
                  with_refunds: 1.0121,
                },
                total_users: {
                  without_refunds: 0.0077,
                  with_refunds: 0.0082,
                },
                total_spread_users: {
                  without_refunds: 0.4194,
                  with_refunds: 0.4203,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.5646,
                  with_refunds: 0.5653,
                },
                arr_users: {
                  without_refunds: 0.4222,
                  with_refunds: 0.3778,
                },
                arr_spread_users: {
                  without_refunds: 1.0037,
                  with_refunds: 1.0037,
                },
                total_users: {
                  without_refunds: 0.5622,
                  with_refunds: 0.5615,
                },
                total_spread_users: {
                  without_refunds: 0.9203,
                  with_refunds: 0.9204,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 210.33,
                  with_refunds: 201.48,
                },
                gross_mrr: {
                  without_refunds: 230.79,
                  with_refunds: 220.96,
                },
                net_arr: {
                  without_refunds: 5.33,
                },
                gross_arr: {
                  without_refunds: 5.7,
                },
                net_arr_spread: {
                  without_refunds: 2085.36,
                  with_refunds: 2085.36,
                },
                gross_arr_spread: {
                  without_refunds: 2233.57,
                  with_refunds: 2233.57,
                },
                net_total: {
                  without_refunds: 39.95,
                  with_refunds: 33.1,
                },
                gross_total: {
                  without_refunds: 43.78,
                  with_refunds: 36.44,
                },
                net_total_spread: {
                  without_refunds: 2286.55,
                  with_refunds: 2287.83,
                },
                gross_total_spread: {
                  without_refunds: 2454.93,
                  with_refunds: 2454.68,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0531,
                  with_refunds: 0.0546,
                },
                gross_mrr: {
                  without_refunds: 0.0509,
                  with_refunds: 0.052,
                },
                net_arr: {
                  without_refunds: 0.0002,
                },
                gross_arr: {
                  without_refunds: 0.0002,
                },
                net_arr_spread: {
                  without_refunds: 1.2263,
                  with_refunds: 1.2263,
                },
                gross_arr_spread: {
                  without_refunds: 1.2228,
                  with_refunds: 1.2228,
                },
                net_total: {
                  without_refunds: 0.0015,
                  with_refunds: 0.0013,
                },
                gross_total: {
                  without_refunds: 0.0015,
                  with_refunds: 0.0014,
                },
                net_total_spread: {
                  without_refunds: 0.4041,
                  with_refunds: 0.4246,
                },
                gross_total_spread: {
                  without_refunds: 0.3858,
                  with_refunds: 0.4042,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5638,
                  with_refunds: 0.5631,
                },
                gross_mrr: {
                  without_refunds: 0.5656,
                  with_refunds: 0.5649,
                },
                net_arr: {
                  without_refunds: 0.4524,
                },
                gross_arr: {
                  without_refunds: 0.4112,
                },
                net_arr_spread: {
                  without_refunds: 1.004,
                  with_refunds: 1.004,
                },
                gross_arr_spread: {
                  without_refunds: 1.0044,
                  with_refunds: 1.0044,
                },
                net_total: {
                  without_refunds: 0.5483,
                  with_refunds: 0.5742,
                },
                gross_total: {
                  without_refunds: 0.5431,
                  with_refunds: 0.5733,
                },
                net_total_spread: {
                  without_refunds: 0.9061,
                  with_refunds: 0.9072,
                },
                gross_total_spread: {
                  without_refunds: 0.9059,
                  with_refunds: 0.9069,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.8,
                  with_refunds: 0.77,
                },
                gross_mrr: {
                  without_refunds: 0.88,
                  with_refunds: 0.84,
                },
                net_arr: {
                  without_refunds: 0.03,
                },
                gross_arr: {
                  without_refunds: 0.03,
                },
                net_arr_spread: {
                  without_refunds: 11.59,
                  with_refunds: 11.59,
                },
                gross_arr_spread: {
                  without_refunds: 12.41,
                  with_refunds: 12.41,
                },
                net_total: {
                  without_refunds: 0.09,
                  with_refunds: 0.07,
                },
                gross_total: {
                  without_refunds: 0.09,
                  with_refunds: 0.08,
                },
                net_total_spread: {
                  without_refunds: 5.16,
                  with_refunds: 5.16,
                },
                gross_total_spread: {
                  without_refunds: 5.54,
                  with_refunds: 5.54,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.54,
                  with_refunds: 18.57,
                },
                gross_mrr: {
                  without_refunds: 21.44,
                  with_refunds: 20.36,
                },
                net_arr: {
                  without_refunds: 118.21,
                },
                gross_arr: {
                  without_refunds: 126.43,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 11.28,
                  with_refunds: 8.69,
                },
                gross_total: {
                  without_refunds: 12.36,
                  with_refunds: 9.57,
                },
                net_total_spread: {
                  without_refunds: 12.31,
                  with_refunds: 12.29,
                },
                gross_total_spread: {
                  without_refunds: 13.21,
                  with_refunds: 13.18,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 23.53,
                    with_refunds: 22.29,
                  },
                  gross_mrr: {
                    without_refunds: 26.58,
                    with_refunds: 25.23,
                  },
                  net_arr: {
                    without_refunds: 137.27,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.58,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 55.55,
                    with_refunds: 55.55,
                  },
                  gross_arr_spread: {
                    without_refunds: 59.5,
                    with_refunds: 59.5,
                  },
                  net_total: {
                    without_refunds: 70.41,
                    with_refunds: 64.23,
                  },
                  gross_total: {
                    without_refunds: 75.95,
                    with_refunds: 69.46,
                  },
                  net_total_spread: {
                    without_refunds: 36.61,
                    with_refunds: 35.93,
                  },
                  gross_total_spread: {
                    without_refunds: 40.03,
                    with_refunds: 39.29,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 94.92,
                    with_refunds: 90.37,
                  },
                  gross_mrr: {
                    without_refunds: 105.16,
                    with_refunds: 100.11,
                  },
                  net_arr: {
                    without_refunds: 627.36,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 686.72,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 57.21,
                    with_refunds: 57.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 61.28,
                    with_refunds: 61.28,
                  },
                  net_total: {
                    without_refunds: 169.14,
                    with_refunds: 138.61,
                  },
                  gross_total: {
                    without_refunds: 182.62,
                    with_refunds: 149.62,
                  },
                  net_total_spread: {
                    without_refunds: 66.13,
                    with_refunds: 65.1,
                  },
                  gross_total_spread: {
                    without_refunds: 71.93,
                    with_refunds: 70.8,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 6.82,
                  with_refunds: 6.89,
                },
                arr_users: {
                  without_refunds: 0.01,
                  with_refunds: 0.03,
                },
                arr_spread_users: {
                  without_refunds: 182.27,
                  with_refunds: 182.27,
                },
                total_users: {
                  without_refunds: 2.22,
                  with_refunds: 2.41,
                },
                total_spread_users: {
                  without_refunds: 166.78,
                  with_refunds: 167.17,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0259,
                  with_refunds: 0.0262,
                },
                arr_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                arr_spread_users: {
                  without_refunds: 1.0126,
                  with_refunds: 1.0126,
                },
                total_users: {
                  without_refunds: 0.0048,
                  with_refunds: 0.0052,
                },
                total_spread_users: {
                  without_refunds: 0.3765,
                  with_refunds: 0.3774,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6336,
                  with_refunds: 0.6351,
                },
                arr_users: {
                  without_refunds: 0.2273,
                  with_refunds: 0.3662,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6277,
                  with_refunds: 0.6322,
                },
                total_spread_users: {
                  without_refunds: 0.8976,
                  with_refunds: 0.8977,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 132.95,
                  with_refunds: 127.27,
                },
                gross_mrr: {
                  without_refunds: 145.06,
                  with_refunds: 138.99,
                },
                net_arr: {
                  without_refunds: 1.17,
                },
                gross_arr: {
                  without_refunds: 1.35,
                },
                net_arr_spread: {
                  without_refunds: 2086.42,
                  with_refunds: 2086.42,
                },
                gross_arr_spread: {
                  without_refunds: 2234.71,
                  with_refunds: 2234.71,
                },
                net_total: {
                  without_refunds: 23.28,
                  with_refunds: 19.71,
                },
                gross_total: {
                  without_refunds: 25.01,
                  with_refunds: 21.36,
                },
                net_total_spread: {
                  without_refunds: 1993.13,
                  with_refunds: 1999.49,
                },
                gross_total_spread: {
                  without_refunds: 2134.28,
                  with_refunds: 2140.64,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0336,
                  with_refunds: 0.0345,
                },
                gross_mrr: {
                  without_refunds: 0.032,
                  with_refunds: 0.0327,
                },
                net_arr: {
                  without_refunds: 0.0001,
                },
                gross_arr: {
                  without_refunds: 0.0001,
                },
                net_arr_spread: {
                  without_refunds: 1.227,
                  with_refunds: 1.227,
                },
                gross_arr_spread: {
                  without_refunds: 1.2234,
                  with_refunds: 1.2234,
                },
                net_total: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0008,
                },
                gross_total: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0008,
                },
                net_total_spread: {
                  without_refunds: 0.3523,
                  with_refunds: 0.3711,
                },
                gross_total_spread: {
                  without_refunds: 0.3354,
                  with_refunds: 0.3525,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6321,
                  with_refunds: 0.6317,
                },
                gross_mrr: {
                  without_refunds: 0.6286,
                  with_refunds: 0.629,
                },
                net_arr: {
                  without_refunds: 0.2185,
                },
                gross_arr: {
                  without_refunds: 0.2373,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                net_total: {
                  without_refunds: 0.5827,
                  with_refunds: 0.5954,
                },
                gross_total: {
                  without_refunds: 0.5713,
                  with_refunds: 0.5861,
                },
                net_total_spread: {
                  without_refunds: 0.8717,
                  with_refunds: 0.874,
                },
                gross_total_spread: {
                  without_refunds: 0.8694,
                  with_refunds: 0.8721,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.51,
                  with_refunds: 0.48,
                },
                gross_mrr: {
                  without_refunds: 0.55,
                  with_refunds: 0.53,
                },
                net_arr: {
                  without_refunds: 0.01,
                },
                gross_arr: {
                  without_refunds: 0.01,
                },
                net_arr_spread: {
                  without_refunds: 11.59,
                  with_refunds: 11.59,
                },
                gross_arr_spread: {
                  without_refunds: 12.42,
                  with_refunds: 12.42,
                },
                net_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.04,
                },
                gross_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                net_total_spread: {
                  without_refunds: 4.5,
                  with_refunds: 4.51,
                },
                gross_total_spread: {
                  without_refunds: 4.82,
                  with_refunds: 4.83,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.49,
                  with_refunds: 18.47,
                },
                gross_mrr: {
                  without_refunds: 21.27,
                  with_refunds: 20.17,
                },
                net_arr: {
                  without_refunds: 113.66,
                },
                gross_arr: {
                  without_refunds: 132,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.26,
                  with_refunds: 12.26,
                },
                net_total: {
                  without_refunds: 10.47,
                  with_refunds: 8.19,
                },
                gross_total: {
                  without_refunds: 11.25,
                  with_refunds: 8.87,
                },
                net_total_spread: {
                  without_refunds: 11.95,
                  with_refunds: 11.96,
                },
                gross_total_spread: {
                  without_refunds: 12.8,
                  with_refunds: 12.81,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.03,
                    with_refunds: 22.77,
                  },
                  gross_mrr: {
                    without_refunds: 27.13,
                    with_refunds: 25.76,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.58,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 67.14,
                    with_refunds: 67.14,
                  },
                  gross_arr_spread: {
                    without_refunds: 71.92,
                    with_refunds: 71.92,
                  },
                  net_total: {
                    without_refunds: 70.46,
                    with_refunds: 64.27,
                  },
                  gross_total: {
                    without_refunds: 76.01,
                    with_refunds: 69.51,
                  },
                  net_total_spread: {
                    without_refunds: 41.11,
                    with_refunds: 40.45,
                  },
                  gross_total_spread: {
                    without_refunds: 44.84,
                    with_refunds: 44.12,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 114.41,
                    with_refunds: 108.84,
                  },
                  gross_mrr: {
                    without_refunds: 126.43,
                    with_refunds: 120.28,
                  },
                  net_arr: {
                    without_refunds: 741.02,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 818.72,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 68.65,
                    with_refunds: 68.65,
                  },
                  gross_arr_spread: {
                    without_refunds: 73.54,
                    with_refunds: 73.54,
                  },
                  net_total: {
                    without_refunds: 179.61,
                    with_refunds: 146.8,
                  },
                  gross_total: {
                    without_refunds: 193.87,
                    with_refunds: 158.49,
                  },
                  net_total_spread: {
                    without_refunds: 78.08,
                    with_refunds: 77.06,
                  },
                  gross_total_spread: {
                    without_refunds: 84.73,
                    with_refunds: 83.6,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 4.64,
                  with_refunds: 4.68,
                },
                arr_users: {
                  with_refunds: 0.01,
                },
                arr_spread_users: {
                  without_refunds: 182.63,
                  with_refunds: 182.63,
                },
                total_users: {
                  without_refunds: 1.51,
                  with_refunds: 1.63,
                },
                total_spread_users: {
                  without_refunds: 148.17,
                  with_refunds: 148.37,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0176,
                  with_refunds: 0.0178,
                },
                arr_users: {
                  with_refunds: 0.0001,
                },
                arr_spread_users: {
                  without_refunds: 1.0146,
                  with_refunds: 1.0146,
                },
                total_users: {
                  without_refunds: 0.0033,
                  with_refunds: 0.0035,
                },
                total_spread_users: {
                  without_refunds: 0.3345,
                  with_refunds: 0.3349,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6803,
                  with_refunds: 0.6785,
                },
                arr_users: {
                  without_refunds: 0.1905,
                  with_refunds: 0.4881,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6753,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8876,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 91.09,
                  with_refunds: 88.74,
                },
                gross_mrr: {
                  without_refunds: 99.2,
                  with_refunds: 96.57,
                },
                net_arr: {
                  without_refunds: 0.24,
                },
                gross_arr: {
                  without_refunds: 0.26,
                },
                net_arr_spread: {
                  without_refunds: 2091.37,
                  with_refunds: 2091.37,
                },
                gross_arr_spread: {
                  without_refunds: 2240.51,
                  with_refunds: 2240.51,
                },
                net_total: {
                  without_refunds: 15.53,
                  with_refunds: 13.6,
                },
                gross_total: {
                  without_refunds: 16.54,
                  with_refunds: 14.66,
                },
                net_total_spread: {
                  without_refunds: 1713.15,
                  with_refunds: 1730.56,
                },
                gross_total_spread: {
                  without_refunds: 1833.05,
                  with_refunds: 1850.32,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.023,
                  with_refunds: 0.0241,
                },
                gross_mrr: {
                  without_refunds: 0.0219,
                  with_refunds: 0.0227,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2299,
                  with_refunds: 1.2299,
                },
                gross_arr_spread: {
                  without_refunds: 1.2266,
                  with_refunds: 1.2266,
                },
                net_total: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0005,
                },
                gross_total: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0005,
                },
                net_total_spread: {
                  without_refunds: 0.3028,
                  with_refunds: 0.3212,
                },
                gross_total_spread: {
                  without_refunds: 0.2881,
                  with_refunds: 0.3047,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6851,
                  with_refunds: 0.6973,
                },
                gross_mrr: {
                  without_refunds: 0.6838,
                  with_refunds: 0.6948,
                },
                net_arr: {
                  without_refunds: 0.2058,
                },
                gross_arr: {
                  without_refunds: 0.1926,
                },
                net_arr_spread: {
                  without_refunds: 1.0024,
                  with_refunds: 1.0024,
                },
                gross_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                net_total: {
                  without_refunds: 0.6669,
                  with_refunds: 0.6901,
                },
                gross_total: {
                  without_refunds: 0.6613,
                  with_refunds: 0.6867,
                },
                net_total_spread: {
                  without_refunds: 0.8595,
                  with_refunds: 0.8655,
                },
                gross_total_spread: {
                  without_refunds: 0.8589,
                  with_refunds: 0.8644,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.35,
                  with_refunds: 0.34,
                },
                gross_mrr: {
                  without_refunds: 0.38,
                  with_refunds: 0.37,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.62,
                  with_refunds: 11.62,
                },
                gross_arr_spread: {
                  without_refunds: 12.45,
                  with_refunds: 12.45,
                },
                net_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                gross_total: {
                  without_refunds: 0.04,
                  with_refunds: 0.03,
                },
                net_total_spread: {
                  without_refunds: 3.87,
                  with_refunds: 3.91,
                },
                gross_total_spread: {
                  without_refunds: 4.14,
                  with_refunds: 4.18,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.63,
                  with_refunds: 18.98,
                },
                gross_mrr: {
                  without_refunds: 21.38,
                  with_refunds: 20.65,
                },
                net_arr: {
                  without_refunds: 122.81,
                },
                gross_arr: {
                  without_refunds: 133.5,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
                net_total: {
                  without_refunds: 10.3,
                  with_refunds: 8.37,
                },
                gross_total: {
                  without_refunds: 10.97,
                  with_refunds: 9.02,
                },
                net_total_spread: {
                  without_refunds: 11.56,
                  with_refunds: 11.66,
                },
                gross_total_spread: {
                  without_refunds: 12.37,
                  with_refunds: 12.47,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.38,
                    with_refunds: 23.11,
                  },
                  gross_mrr: {
                    without_refunds: 27.51,
                    with_refunds: 26.13,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.59,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 78.76,
                    with_refunds: 78.76,
                  },
                  gross_arr_spread: {
                    without_refunds: 84.36,
                    with_refunds: 84.36,
                  },
                  net_total: {
                    without_refunds: 70.49,
                    with_refunds: 64.3,
                  },
                  gross_total: {
                    without_refunds: 76.04,
                    with_refunds: 69.54,
                  },
                  net_total_spread: {
                    without_refunds: 44.97,
                    with_refunds: 44.35,
                  },
                  gross_total_spread: {
                    without_refunds: 48.98,
                    with_refunds: 48.3,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 134.05,
                    with_refunds: 127.82,
                  },
                  gross_mrr: {
                    without_refunds: 147.81,
                    with_refunds: 140.93,
                  },
                  net_arr: {
                    without_refunds: 863.82,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 952.22,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 80.1,
                    with_refunds: 80.1,
                  },
                  gross_arr_spread: {
                    without_refunds: 85.81,
                    with_refunds: 85.81,
                  },
                  net_total: {
                    without_refunds: 189.91,
                    with_refunds: 155.16,
                  },
                  gross_total: {
                    without_refunds: 204.84,
                    with_refunds: 167.51,
                  },
                  net_total_spread: {
                    without_refunds: 89.64,
                    with_refunds: 88.72,
                  },
                  gross_total_spread: {
                    without_refunds: 97.1,
                    with_refunds: 96.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2.9,
                  with_refunds: 2.93,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 182.72,
                  with_refunds: 182.72,
                },
                total_users: {
                  without_refunds: 0.93,
                  with_refunds: 1.01,
                },
                total_spread_users: {
                  without_refunds: 132.74,
                  with_refunds: 132.87,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.011,
                  with_refunds: 0.0111,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0151,
                  with_refunds: 1.0151,
                },
                total_users: {
                  without_refunds: 0.002,
                  with_refunds: 0.0022,
                },
                total_spread_users: {
                  without_refunds: 0.2996,
                  with_refunds: 0.2999,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.626,
                  with_refunds: 0.6268,
                },
                arr_users: {
                  without_refunds: 0.0833,
                  with_refunds: 0.1167,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                total_spread_users: {
                  without_refunds: 0.8959,
                  with_refunds: 0.8955,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 57.55,
                  with_refunds: 55.53,
                },
                gross_mrr: {
                  without_refunds: 62.69,
                  with_refunds: 60.53,
                },
                net_arr: {
                  without_refunds: 0.02,
                },
                gross_arr: {
                  without_refunds: 0.02,
                },
                net_arr_spread: {
                  without_refunds: 2092.46,
                  with_refunds: 2092.46,
                },
                gross_arr_spread: {
                  without_refunds: 2241.98,
                  with_refunds: 2241.98,
                },
                net_total: {
                  without_refunds: 8.84,
                  with_refunds: 7.71,
                },
                gross_total: {
                  without_refunds: 9.36,
                  with_refunds: 8.26,
                },
                net_total_spread: {
                  without_refunds: 1480.9,
                  with_refunds: 1494.67,
                },
                gross_total_spread: {
                  without_refunds: 1584.69,
                  with_refunds: 1599.03,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0145,
                  with_refunds: 0.0151,
                },
                gross_mrr: {
                  without_refunds: 0.0138,
                  with_refunds: 0.0143,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2305,
                  with_refunds: 1.2305,
                },
                gross_arr_spread: {
                  without_refunds: 1.2274,
                  with_refunds: 1.2274,
                },
                net_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0003,
                },
                gross_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0003,
                },
                net_total_spread: {
                  without_refunds: 0.2617,
                  with_refunds: 0.2774,
                },
                gross_total_spread: {
                  without_refunds: 0.2491,
                  with_refunds: 0.2633,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6258,
                },
                gross_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6268,
                },
                net_arr: {
                  without_refunds: 0.0657,
                },
                gross_arr: {
                  without_refunds: 0.0754,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.5691,
                  with_refunds: 0.5667,
                },
                gross_total: {
                  without_refunds: 0.566,
                  with_refunds: 0.5633,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8637,
                },
                gross_total_spread: {
                  without_refunds: 0.8645,
                  with_refunds: 0.8642,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.22,
                  with_refunds: 0.21,
                },
                gross_mrr: {
                  without_refunds: 0.24,
                  with_refunds: 0.23,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.62,
                  with_refunds: 11.62,
                },
                gross_arr_spread: {
                  without_refunds: 12.46,
                  with_refunds: 12.46,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                net_total_spread: {
                  without_refunds: 3.34,
                  with_refunds: 3.37,
                },
                gross_total_spread: {
                  without_refunds: 3.58,
                  with_refunds: 3.61,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.81,
                  with_refunds: 18.95,
                },
                gross_mrr: {
                  without_refunds: 21.58,
                  with_refunds: 20.65,
                },
                net_arr: {
                  without_refunds: 96.88,
                },
                gross_arr: {
                  without_refunds: 120.74,
                },
                net_arr_spread: {
                  without_refunds: 11.45,
                  with_refunds: 11.45,
                },
                gross_arr_spread: {
                  without_refunds: 12.27,
                  with_refunds: 12.27,
                },
                net_total: {
                  without_refunds: 9.46,
                  with_refunds: 7.65,
                },
                gross_total: {
                  without_refunds: 10.02,
                  with_refunds: 8.2,
                },
                net_total_spread: {
                  without_refunds: 11.16,
                  with_refunds: 11.25,
                },
                gross_total_spread: {
                  without_refunds: 11.94,
                  with_refunds: 12.03,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.6,
                    with_refunds: 23.32,
                  },
                  gross_mrr: {
                    without_refunds: 27.75,
                    with_refunds: 26.36,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.59,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 90.38,
                    with_refunds: 90.38,
                  },
                  gross_arr_spread: {
                    without_refunds: 96.82,
                    with_refunds: 96.82,
                  },
                  net_total: {
                    without_refunds: 70.51,
                    with_refunds: 64.32,
                  },
                  gross_total: {
                    without_refunds: 76.06,
                    with_refunds: 69.56,
                  },
                  net_total_spread: {
                    without_refunds: 48.32,
                    with_refunds: 47.73,
                  },
                  gross_total_spread: {
                    without_refunds: 52.56,
                    with_refunds: 51.91,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 153.86,
                    with_refunds: 146.76,
                  },
                  gross_mrr: {
                    without_refunds: 169.39,
                    with_refunds: 161.58,
                  },
                  net_arr: {
                    without_refunds: 960.71,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 1072.96,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 91.56,
                    with_refunds: 91.56,
                  },
                  gross_arr_spread: {
                    without_refunds: 98.08,
                    with_refunds: 98.08,
                  },
                  net_total: {
                    without_refunds: 199.37,
                    with_refunds: 162.82,
                  },
                  gross_total: {
                    without_refunds: 214.86,
                    with_refunds: 175.71,
                  },
                  net_total_spread: {
                    without_refunds: 100.8,
                    with_refunds: 99.97,
                  },
                  gross_total_spread: {
                    without_refunds: 109.04,
                    with_refunds: 108.11,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.92,
                  with_refunds: 1.93,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 183.08,
                  with_refunds: 183.08,
                },
                total_users: {
                  without_refunds: 0.62,
                  with_refunds: 0.66,
                },
                total_spread_users: {
                  without_refunds: 120.38,
                  with_refunds: 120.5,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0073,
                  with_refunds: 0.0074,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0171,
                  with_refunds: 1.0171,
                },
                total_users: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0014,
                },
                total_spread_users: {
                  without_refunds: 0.2717,
                  with_refunds: 0.272,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {
                  without_refunds: 0.2778,
                  with_refunds: 0.2778,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 38.22,
                  with_refunds: 36.8,
                },
                gross_mrr: {
                  without_refunds: 41.59,
                  with_refunds: 40.09,
                },
                net_arr: {},
                gross_arr: {
                  without_refunds: 0.01,
                },
                net_arr_spread: {
                  without_refunds: 2097.65,
                  with_refunds: 2097.65,
                },
                gross_arr_spread: {
                  without_refunds: 2248.09,
                  with_refunds: 2248.09,
                },
                net_total: {
                  without_refunds: 5.81,
                  with_refunds: 5.12,
                },
                gross_total: {
                  without_refunds: 6.14,
                  with_refunds: 5.48,
                },
                net_total_spread: {
                  without_refunds: 1300.17,
                  with_refunds: 1312.57,
                },
                gross_total_spread: {
                  without_refunds: 1389.64,
                  with_refunds: 1402.54,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0097,
                  with_refunds: 0.01,
                },
                gross_mrr: {
                  without_refunds: 0.0092,
                  with_refunds: 0.0094,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2336,
                  with_refunds: 1.2336,
                },
                gross_arr_spread: {
                  without_refunds: 1.2308,
                  with_refunds: 1.2308,
                },
                net_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0002,
                },
                gross_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0002,
                },
                net_total_spread: {
                  without_refunds: 0.2298,
                  with_refunds: 0.2436,
                },
                gross_total_spread: {
                  without_refunds: 0.2184,
                  with_refunds: 0.2309,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {
                  without_refunds: 0.2848,
                },
                gross_arr: {
                  without_refunds: 0.285,
                },
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.15,
                  with_refunds: 0.14,
                },
                gross_mrr: {
                  without_refunds: 0.16,
                  with_refunds: 0.15,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.65,
                  with_refunds: 11.65,
                },
                gross_arr_spread: {
                  without_refunds: 12.49,
                  with_refunds: 12.49,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 2.93,
                  with_refunds: 2.96,
                },
                gross_total_spread: {
                  without_refunds: 3.14,
                  with_refunds: 3.17,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.96,
                  with_refunds: 19.03,
                },
                gross_mrr: {
                  without_refunds: 21.72,
                  with_refunds: 20.73,
                },
                net_arr: {
                  without_refunds: 99.32,
                },
                gross_arr: {
                  without_refunds: 123.86,
                },
                net_arr_spread: {
                  without_refunds: 11.46,
                  with_refunds: 11.46,
                },
                gross_arr_spread: {
                  without_refunds: 12.28,
                  with_refunds: 12.28,
                },
                net_total: {
                  without_refunds: 9.45,
                  with_refunds: 7.71,
                },
                gross_total: {
                  without_refunds: 9.99,
                  with_refunds: 8.26,
                },
                net_total_spread: {
                  without_refunds: 10.8,
                  with_refunds: 10.89,
                },
                gross_total_spread: {
                  without_refunds: 11.54,
                  with_refunds: 11.64,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.74,
                    with_refunds: 23.46,
                  },
                  gross_mrr: {
                    without_refunds: 27.91,
                    with_refunds: 26.51,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.59,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 102.04,
                    with_refunds: 102.04,
                  },
                  gross_arr_spread: {
                    without_refunds: 109.31,
                    with_refunds: 109.31,
                  },
                  net_total: {
                    without_refunds: 70.52,
                    with_refunds: 64.33,
                  },
                  gross_total: {
                    without_refunds: 76.08,
                    with_refunds: 69.57,
                  },
                  net_total_spread: {
                    without_refunds: 51.25,
                    with_refunds: 50.69,
                  },
                  gross_total_spread: {
                    without_refunds: 55.69,
                    with_refunds: 55.08,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 173.81,
                    with_refunds: 165.79,
                  },
                  gross_mrr: {
                    without_refunds: 191.11,
                    with_refunds: 182.31,
                  },
                  net_arr: {
                    without_refunds: 1060.03,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 1196.82,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 103.01,
                    with_refunds: 103.01,
                  },
                  gross_arr_spread: {
                    without_refunds: 110.36,
                    with_refunds: 110.36,
                  },
                  net_total: {
                    without_refunds: 208.82,
                    with_refunds: 170.53,
                  },
                  gross_total: {
                    without_refunds: 224.85,
                    with_refunds: 183.97,
                  },
                  net_total_spread: {
                    without_refunds: 111.6,
                    with_refunds: 110.87,
                  },
                  gross_total_spread: {
                    without_refunds: 120.58,
                    with_refunds: 119.75,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.27,
                  with_refunds: 1.28,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 183.6,
                  with_refunds: 183.6,
                },
                total_users: {
                  without_refunds: 0.41,
                  with_refunds: 0.44,
                },
                total_spread_users: {
                  without_refunds: 106.95,
                  with_refunds: 107.05,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0048,
                  with_refunds: 0.0049,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.02,
                  with_refunds: 1.02,
                },
                total_users: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0009,
                },
                total_spread_users: {
                  without_refunds: 0.2414,
                  with_refunds: 0.2416,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {
                  without_refunds: 0.2222,
                  with_refunds: 0.2222,
                },
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 25.33,
                  with_refunds: 24.49,
                },
                gross_mrr: {
                  without_refunds: 27.52,
                  with_refunds: 26.62,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2106.39,
                  with_refunds: 2106.39,
                },
                gross_arr_spread: {
                  without_refunds: 2256.57,
                  with_refunds: 2256.57,
                },
                net_total: {
                  without_refunds: 3.67,
                  with_refunds: 3.25,
                },
                gross_total: {
                  without_refunds: 3.86,
                  with_refunds: 3.46,
                },
                net_total_spread: {
                  without_refunds: 1116.23,
                  with_refunds: 1130.72,
                },
                gross_total_spread: {
                  without_refunds: 1192.52,
                  with_refunds: 1207.39,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0064,
                  with_refunds: 0.0066,
                },
                gross_mrr: {
                  without_refunds: 0.0061,
                  with_refunds: 0.0063,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2387,
                  with_refunds: 1.2387,
                },
                gross_arr_spread: {
                  without_refunds: 1.2354,
                  with_refunds: 1.2354,
                },
                net_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.1973,
                  with_refunds: 0.2098,
                },
                gross_total_spread: {
                  without_refunds: 0.1874,
                  with_refunds: 0.1988,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {
                  without_refunds: 0.2001,
                },
                gross_arr: {
                  without_refunds: 0.2041,
                },
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.1,
                  with_refunds: 0.09,
                },
                gross_mrr: {
                  without_refunds: 0.1,
                  with_refunds: 0.1,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.7,
                  with_refunds: 11.7,
                },
                gross_arr_spread: {
                  without_refunds: 12.54,
                  with_refunds: 12.54,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 2.52,
                  with_refunds: 2.55,
                },
                gross_total_spread: {
                  without_refunds: 2.69,
                  with_refunds: 2.73,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.94,
                  with_refunds: 19.08,
                },
                gross_mrr: {
                  without_refunds: 21.66,
                  with_refunds: 20.74,
                },
                net_arr: {
                  without_refunds: 89.45,
                },
                gross_arr: {
                  without_refunds: 113.74,
                },
                net_arr_spread: {
                  without_refunds: 11.47,
                  with_refunds: 11.47,
                },
                gross_arr_spread: {
                  without_refunds: 12.29,
                  with_refunds: 12.29,
                },
                net_total: {
                  without_refunds: 9.05,
                  with_refunds: 7.42,
                },
                gross_total: {
                  without_refunds: 9.5,
                  with_refunds: 7.9,
                },
                net_total_spread: {
                  without_refunds: 10.44,
                  with_refunds: 10.56,
                },
                gross_total_spread: {
                  without_refunds: 11.15,
                  with_refunds: 11.28,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.84,
                    with_refunds: 23.55,
                  },
                  gross_mrr: {
                    without_refunds: 28.01,
                    with_refunds: 26.61,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.59,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 113.74,
                    with_refunds: 113.74,
                  },
                  gross_arr_spread: {
                    without_refunds: 121.84,
                    with_refunds: 121.84,
                  },
                  net_total: {
                    without_refunds: 70.53,
                    with_refunds: 64.34,
                  },
                  gross_total: {
                    without_refunds: 76.09,
                    with_refunds: 69.58,
                  },
                  net_total_spread: {
                    without_refunds: 53.77,
                    with_refunds: 53.24,
                  },
                  gross_total_spread: {
                    without_refunds: 58.39,
                    with_refunds: 57.8,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 193.76,
                    with_refunds: 184.87,
                  },
                  gross_mrr: {
                    without_refunds: 212.77,
                    with_refunds: 203.04,
                  },
                  net_arr: {
                    without_refunds: 1149.48,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 1310.57,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 114.49,
                    with_refunds: 114.49,
                  },
                  gross_arr_spread: {
                    without_refunds: 122.65,
                    with_refunds: 122.65,
                  },
                  net_total: {
                    without_refunds: 217.86,
                    with_refunds: 177.95,
                  },
                  gross_total: {
                    without_refunds: 234.35,
                    with_refunds: 191.87,
                  },
                  net_total_spread: {
                    without_refunds: 122.03,
                    with_refunds: 121.43,
                  },
                  gross_total_spread: {
                    without_refunds: 131.74,
                    with_refunds: 131.03,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.9,
                  with_refunds: 0.91,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 184.63,
                  with_refunds: 184.63,
                },
                total_users: {
                  without_refunds: 0.29,
                  with_refunds: 0.31,
                },
                total_spread_users: {
                  without_refunds: 94.31,
                  with_refunds: 94.3,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0034,
                  with_refunds: 0.0034,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0257,
                  with_refunds: 1.0257,
                },
                total_users: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0007,
                },
                total_spread_users: {
                  without_refunds: 0.2129,
                  with_refunds: 0.2129,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 17.87,
                  with_refunds: 17.36,
                },
                gross_mrr: {
                  without_refunds: 19.38,
                  with_refunds: 18.84,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2122.98,
                  with_refunds: 2122.98,
                },
                gross_arr_spread: {
                  without_refunds: 2271.7,
                  with_refunds: 2271.7,
                },
                net_total: {
                  without_refunds: 2.6,
                  with_refunds: 2.31,
                },
                gross_total: {
                  without_refunds: 2.73,
                  with_refunds: 2.46,
                },
                net_total_spread: {
                  without_refunds: 952.22,
                  with_refunds: 967.82,
                },
                gross_total_spread: {
                  without_refunds: 1019.1,
                  with_refunds: 1035.02,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0045,
                  with_refunds: 0.0047,
                },
                gross_mrr: {
                  without_refunds: 0.0043,
                  with_refunds: 0.0044,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2485,
                  with_refunds: 1.2485,
                },
                gross_arr_spread: {
                  without_refunds: 1.2437,
                  with_refunds: 1.2437,
                },
                net_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.1683,
                  with_refunds: 0.1796,
                },
                gross_total_spread: {
                  without_refunds: 0.1602,
                  with_refunds: 0.1704,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {
                  without_refunds: 0.6705,
                },
                gross_arr: {
                  without_refunds: 0.6582,
                },
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                gross_mrr: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.79,
                  with_refunds: 11.79,
                },
                gross_arr_spread: {
                  without_refunds: 12.62,
                  with_refunds: 12.62,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 2.15,
                  with_refunds: 2.18,
                },
                gross_total_spread: {
                  without_refunds: 2.3,
                  with_refunds: 2.34,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.92,
                  with_refunds: 19.17,
                },
                gross_mrr: {
                  without_refunds: 21.6,
                  with_refunds: 20.8,
                },
                net_arr: {
                  without_refunds: 89.96,
                },
                gross_arr: {
                  without_refunds: 112.3,
                },
                net_arr_spread: {
                  without_refunds: 11.5,
                  with_refunds: 11.5,
                },
                gross_arr_spread: {
                  without_refunds: 12.3,
                  with_refunds: 12.3,
                },
                net_total: {
                  without_refunds: 9.06,
                  with_refunds: 7.48,
                },
                gross_total: {
                  without_refunds: 9.51,
                  with_refunds: 7.95,
                },
                net_total_spread: {
                  without_refunds: 10.1,
                  with_refunds: 10.26,
                },
                gross_total_spread: {
                  without_refunds: 10.81,
                  with_refunds: 10.98,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.91,
                    with_refunds: 23.62,
                  },
                  gross_mrr: {
                    without_refunds: 28.09,
                    with_refunds: 26.69,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.59,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 125.53,
                    with_refunds: 125.53,
                  },
                  gross_arr_spread: {
                    without_refunds: 134.46,
                    with_refunds: 134.46,
                  },
                  net_total: {
                    without_refunds: 70.53,
                    with_refunds: 64.34,
                  },
                  gross_total: {
                    without_refunds: 76.09,
                    with_refunds: 69.58,
                  },
                  net_total_spread: {
                    without_refunds: 55.92,
                    with_refunds: 55.43,
                  },
                  gross_total_spread: {
                    without_refunds: 60.69,
                    with_refunds: 60.14,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 213.67,
                    with_refunds: 204.04,
                  },
                  gross_mrr: {
                    without_refunds: 234.37,
                    with_refunds: 223.84,
                  },
                  net_arr: {
                    without_refunds: 1239.44,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 1422.87,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 125.98,
                    with_refunds: 125.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 134.95,
                    with_refunds: 134.95,
                  },
                  net_total: {
                    without_refunds: 226.93,
                    with_refunds: 185.43,
                  },
                  gross_total: {
                    without_refunds: 243.86,
                    with_refunds: 199.82,
                  },
                  net_total_spread: {
                    without_refunds: 132.13,
                    with_refunds: 131.69,
                  },
                  gross_total_spread: {
                    without_refunds: 142.54,
                    with_refunds: 142.01,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.54,
                  with_refunds: 0.55,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 185.02,
                  with_refunds: 185.02,
                },
                total_users: {
                  without_refunds: 0.17,
                  with_refunds: 0.19,
                },
                total_spread_users: {
                  without_refunds: 68.96,
                  with_refunds: 68.93,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0021,
                  with_refunds: 0.0021,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.0279,
                  with_refunds: 1.0279,
                },
                total_users: {
                  without_refunds: 0.0004,
                  with_refunds: 0.0004,
                },
                total_spread_users: {
                  without_refunds: 0.1557,
                  with_refunds: 0.1556,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 10.89,
                  with_refunds: 10.65,
                },
                gross_mrr: {
                  without_refunds: 11.84,
                  with_refunds: 11.59,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 2129.18,
                  with_refunds: 2129.18,
                },
                gross_arr_spread: {
                  without_refunds: 2276.9,
                  with_refunds: 2276.9,
                },
                net_total: {
                  without_refunds: 1.53,
                  with_refunds: 1.39,
                },
                gross_total: {
                  without_refunds: 1.6,
                  with_refunds: 1.48,
                },
                net_total_spread: {
                  without_refunds: 656.57,
                  with_refunds: 670.98,
                },
                gross_total_spread: {
                  without_refunds: 723.02,
                  with_refunds: 738.15,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0028,
                  with_refunds: 0.0029,
                },
                gross_mrr: {
                  without_refunds: 0.0026,
                  with_refunds: 0.0027,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.2521,
                  with_refunds: 1.2521,
                },
                gross_arr_spread: {
                  without_refunds: 1.2465,
                  with_refunds: 1.2465,
                },
                net_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.116,
                  with_refunds: 0.1245,
                },
                gross_total_spread: {
                  without_refunds: 0.1136,
                  with_refunds: 0.1215,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {
                  without_refunds: 0.155,
                },
                gross_arr: {
                  without_refunds: 0.138,
                },
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                gross_mrr: {
                  without_refunds: 0.05,
                  with_refunds: 0.04,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.83,
                  with_refunds: 11.83,
                },
                gross_arr_spread: {
                  without_refunds: 12.65,
                  with_refunds: 12.65,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 1.48,
                  with_refunds: 1.51,
                },
                gross_total_spread: {
                  without_refunds: 1.63,
                  with_refunds: 1.67,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 20.03,
                  with_refunds: 19.42,
                },
                gross_mrr: {
                  without_refunds: 21.77,
                  with_refunds: 21.11,
                },
                net_arr: {
                  without_refunds: 83.66,
                },
                gross_arr: {
                  without_refunds: 92.98,
                },
                net_arr_spread: {
                  without_refunds: 11.51,
                  with_refunds: 11.51,
                },
                gross_arr_spread: {
                  without_refunds: 12.31,
                  with_refunds: 12.31,
                },
                net_total: {
                  without_refunds: 8.85,
                  with_refunds: 7.45,
                },
                gross_total: {
                  without_refunds: 9.23,
                  with_refunds: 7.91,
                },
                net_total_spread: {
                  without_refunds: 9.52,
                  with_refunds: 9.73,
                },
                gross_total_spread: {
                  without_refunds: 10.48,
                  with_refunds: 10.71,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.95,
                    with_refunds: 23.66,
                  },
                  gross_mrr: {
                    without_refunds: 28.13,
                    with_refunds: 26.73,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.59,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 137.36,
                    with_refunds: 137.36,
                  },
                  gross_arr_spread: {
                    without_refunds: 147.11,
                    with_refunds: 147.11,
                  },
                  net_total: {
                    without_refunds: 70.54,
                    with_refunds: 64.34,
                  },
                  gross_total: {
                    without_refunds: 76.1,
                    with_refunds: 69.59,
                  },
                  net_total_spread: {
                    without_refunds: 57.4,
                    with_refunds: 56.94,
                  },
                  gross_total_spread: {
                    without_refunds: 62.32,
                    with_refunds: 61.8,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 233.7,
                    with_refunds: 223.46,
                  },
                  gross_mrr: {
                    without_refunds: 256.13,
                    with_refunds: 244.96,
                  },
                  net_arr: {
                    without_refunds: 1323.1,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 1515.85,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 137.49,
                    with_refunds: 137.49,
                  },
                  gross_arr_spread: {
                    without_refunds: 147.26,
                    with_refunds: 147.26,
                  },
                  net_total: {
                    without_refunds: 235.77,
                    with_refunds: 192.88,
                  },
                  gross_total: {
                    without_refunds: 253.09,
                    with_refunds: 207.73,
                  },
                  net_total_spread: {
                    without_refunds: 141.65,
                    with_refunds: 141.43,
                  },
                  gross_total_spread: {
                    without_refunds: 153.03,
                    with_refunds: 152.71,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.16,
                  with_refunds: 0.16,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 62.95,
                  with_refunds: 62.95,
                },
                total_users: {
                  without_refunds: 0.05,
                  with_refunds: 0.06,
                },
                total_spread_users: {
                  without_refunds: 21.57,
                  with_refunds: 21.53,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0006,
                  with_refunds: 0.0006,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3497,
                  with_refunds: 0.3497,
                },
                total_users: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                total_spread_users: {
                  without_refunds: 0.0487,
                  with_refunds: 0.0486,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 3.26,
                  with_refunds: 3.19,
                },
                gross_mrr: {
                  without_refunds: 3.52,
                  with_refunds: 3.45,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 817.29,
                  with_refunds: 817.29,
                },
                gross_arr_spread: {
                  without_refunds: 817.29,
                  with_refunds: 817.29,
                },
                net_total: {
                  without_refunds: 0.45,
                  with_refunds: 0.41,
                },
                gross_total: {
                  without_refunds: 0.47,
                  with_refunds: 0.43,
                },
                net_total_spread: {
                  without_refunds: 207.37,
                  with_refunds: 212.24,
                },
                gross_total_spread: {
                  without_refunds: 226.12,
                  with_refunds: 231.15,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0009,
                },
                gross_mrr: {
                  without_refunds: 0.0008,
                  with_refunds: 0.0008,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.4806,
                  with_refunds: 0.4806,
                },
                gross_arr_spread: {
                  without_refunds: 0.4474,
                  with_refunds: 0.4474,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 0.0367,
                  with_refunds: 0.0394,
                },
                gross_total_spread: {
                  without_refunds: 0.0355,
                  with_refunds: 0.0381,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_mrr: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 4.54,
                  with_refunds: 4.54,
                },
                gross_arr_spread: {
                  without_refunds: 4.54,
                  with_refunds: 4.54,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 0.47,
                  with_refunds: 0.48,
                },
                gross_total_spread: {
                  without_refunds: 0.51,
                  with_refunds: 0.52,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 19.93,
                  with_refunds: 19.39,
                },
                gross_mrr: {
                  without_refunds: 21.53,
                  with_refunds: 20.96,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 12.98,
                  with_refunds: 12.98,
                },
                gross_arr_spread: {
                  without_refunds: 12.98,
                  with_refunds: 12.98,
                },
                net_total: {
                  without_refunds: 8.67,
                  with_refunds: 7.33,
                },
                gross_total: {
                  without_refunds: 8.98,
                  with_refunds: 7.73,
                },
                net_total_spread: {
                  without_refunds: 9.61,
                  with_refunds: 9.86,
                },
                gross_total_spread: {
                  without_refunds: 10.48,
                  with_refunds: 10.73,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 24.96,
                    with_refunds: 23.67,
                  },
                  gross_mrr: {
                    without_refunds: 28.14,
                    with_refunds: 26.74,
                  },
                  net_arr: {
                    without_refunds: 137.28,
                    with_refunds: 124.45,
                  },
                  gross_arr: {
                    without_refunds: 146.59,
                    with_refunds: 133.15,
                  },
                  net_arr_spread: {
                    without_refunds: 141.9,
                    with_refunds: 141.9,
                  },
                  gross_arr_spread: {
                    without_refunds: 151.65,
                    with_refunds: 151.65,
                  },
                  net_total: {
                    without_refunds: 70.54,
                    with_refunds: 64.35,
                  },
                  gross_total: {
                    without_refunds: 76.1,
                    with_refunds: 69.59,
                  },
                  net_total_spread: {
                    without_refunds: 57.87,
                    with_refunds: 57.42,
                  },
                  gross_total_spread: {
                    without_refunds: 62.83,
                    with_refunds: 62.33,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 253.63,
                    with_refunds: 242.85,
                  },
                  gross_mrr: {
                    without_refunds: 277.66,
                    with_refunds: 265.92,
                  },
                  net_arr: {
                    without_refunds: 1323.1,
                    with_refunds: 224.15,
                  },
                  gross_arr: {
                    without_refunds: 1515.85,
                    with_refunds: 238.22,
                  },
                  net_arr_spread: {
                    without_refunds: 150.47,
                    with_refunds: 150.47,
                  },
                  gross_arr_spread: {
                    without_refunds: 160.24,
                    with_refunds: 160.24,
                  },
                  net_total: {
                    without_refunds: 244.44,
                    with_refunds: 200.21,
                  },
                  gross_total: {
                    without_refunds: 262.07,
                    with_refunds: 215.46,
                  },
                  net_total_spread: {
                    without_refunds: 151.27,
                    with_refunds: 151.28,
                  },
                  gross_total_spread: {
                    without_refunds: 163.51,
                    with_refunds: 163.45,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 212,
              with_refunds: 212,
            },
            returning_users: {
              without_refunds: 4325,
              with_refunds: 4338,
            },
            paying_users: {
              without_refunds: 4537,
              with_refunds: 4550,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 168,
              with_refunds: 168,
            },
            returning_users: {
              without_refunds: 193,
              with_refunds: 211,
            },
            paying_users: {
              without_refunds: 361,
              with_refunds: 379,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 380,
              with_refunds: 380,
            },
            returning_users: {
              without_refunds: 4517,
              with_refunds: 4548,
            },
            paying_users: {
              without_refunds: 4897,
              with_refunds: 4928,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 5011.53,
                with_refunds: 4724.81,
              },
              gross_mrr: {
                without_refunds: 5702.63,
                with_refunds: 5393.37,
              },
              net_arr: {
                without_refunds: 27240.66,
                with_refunds: 24765.58,
              },
              gross_arr: {
                without_refunds: 29083.31,
                with_refunds: 26497.12,
              },
              net_arr_spread: {
                without_refunds: 3762.69,
                with_refunds: 3762.69,
              },
              gross_arr_spread: {
                without_refunds: 4033.02,
                with_refunds: 4033.02,
              },
              net_total: {
                without_refunds: 32252.2,
                with_refunds: 29490.4,
              },
              gross_total: {
                without_refunds: 34785.94,
                with_refunds: 31890.48,
              },
              net_total_spread: {
                without_refunds: 8774.22,
                with_refunds: 8487.5,
              },
              gross_total_spread: {
                without_refunds: 9735.64,
                with_refunds: 9426.39,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 6564.63,
                with_refunds: 6225.07,
              },
              gross_mrr: {
                without_refunds: 7402.03,
                with_refunds: 7033.2,
              },
              net_arr: {
                without_refunds: 27318.17,
                with_refunds: 24765.58,
              },
              gross_arr: {
                without_refunds: 29170.7,
                with_refunds: 26497.12,
              },
              net_arr_spread: {
                without_refunds: 25542.66,
                with_refunds: 25542.66,
              },
              gross_arr_spread: {
                without_refunds: 27297.84,
                with_refunds: 27297.84,
              },
              net_total: {
                without_refunds: 32589.1,
                with_refunds: 29727.59,
              },
              gross_total: {
                without_refunds: 35156.46,
                with_refunds: 32149.86,
              },
              net_total_spread: {
                without_refunds: 25636.29,
                with_refunds: 25438.2,
              },
              gross_total_spread: {
                without_refunds: 27833.56,
                with_refunds: 27610.08,
              },
            },
          },
        },
      },
      mrr_ua_spend: {},
      arr_ua_spend: {},
      arr_spread_ua_spend: {},
      total_ua_spend: {},
      total_spread_ua_spend: {},
    },
    {
      cohort_date: {
        year: 2024,
        month: 7,
        day: 1,
      },
      first_period_mrr_users: {
        without_refunds: 13,
        with_refunds: 13,
      },
      first_period_arr_users: {
        without_refunds: 11,
        with_refunds: 11,
      },
      first_period_arr_spread_users: {
        without_refunds: 10,
        with_refunds: 10,
      },
      first_period_total_users: {
        without_refunds: 24,
        with_refunds: 24,
      },
      first_period_total_spread_users: {
        without_refunds: 23,
        with_refunds: 23,
      },
      cells: [
        {
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 13,
                  with_refunds: 13,
                },
                arr_users: {
                  without_refunds: 11,
                  with_refunds: 11,
                },
                arr_spread_users: {
                  without_refunds: 10,
                  with_refunds: 10,
                },
                total_users: {
                  without_refunds: 24,
                  with_refunds: 24,
                },
                total_spread_users: {
                  without_refunds: 23,
                  with_refunds: 23,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                arr_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                total_spread_users: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 239.75,
                  with_refunds: 222.25,
                },
                gross_mrr: {
                  without_refunds: 265.48,
                  with_refunds: 240.71,
                },
                net_arr: {
                  without_refunds: 1302.11,
                  with_refunds: 1181.04,
                },
                gross_arr: {
                  without_refunds: 1374.98,
                  with_refunds: 1249.98,
                },
                net_arr_spread: {
                  without_refunds: 98.42,
                  with_refunds: 98.42,
                },
                gross_arr_spread: {
                  without_refunds: 104.16,
                  with_refunds: 104.16,
                },
                net_total: {
                  without_refunds: 1541.86,
                  with_refunds: 1403.29,
                },
                gross_total: {
                  without_refunds: 1640.46,
                  with_refunds: 1490.69,
                },
                net_total_spread: {
                  without_refunds: 338.17,
                  with_refunds: 320.67,
                },
                gross_total_spread: {
                  without_refunds: 369.65,
                  with_refunds: 344.87,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_mrr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_arr_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                net_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
                gross_total_spread: {
                  without_refunds: 1,
                  with_refunds: 1,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 18.44,
                  with_refunds: 17.1,
                },
                gross_mrr: {
                  without_refunds: 20.42,
                  with_refunds: 18.52,
                },
                net_arr: {
                  without_refunds: 118.37,
                  with_refunds: 107.37,
                },
                gross_arr: {
                  without_refunds: 125,
                  with_refunds: 113.63,
                },
                net_arr_spread: {
                  without_refunds: 9.84,
                  with_refunds: 9.84,
                },
                gross_arr_spread: {
                  without_refunds: 10.42,
                  with_refunds: 10.42,
                },
                net_total: {
                  without_refunds: 64.24,
                  with_refunds: 58.47,
                },
                gross_total: {
                  without_refunds: 68.35,
                  with_refunds: 62.11,
                },
                net_total_spread: {
                  without_refunds: 14.7,
                  with_refunds: 13.94,
                },
                gross_total_spread: {
                  without_refunds: 16.07,
                  with_refunds: 14.99,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 18.44,
                  with_refunds: 17.1,
                },
                gross_mrr: {
                  without_refunds: 20.42,
                  with_refunds: 18.52,
                },
                net_arr: {
                  without_refunds: 118.37,
                  with_refunds: 107.37,
                },
                gross_arr: {
                  without_refunds: 125,
                  with_refunds: 113.63,
                },
                net_arr_spread: {
                  without_refunds: 9.84,
                  with_refunds: 9.84,
                },
                gross_arr_spread: {
                  without_refunds: 10.42,
                  with_refunds: 10.42,
                },
                net_total: {
                  without_refunds: 64.24,
                  with_refunds: 58.47,
                },
                gross_total: {
                  without_refunds: 68.35,
                  with_refunds: 62.11,
                },
                net_total_spread: {
                  without_refunds: 14.7,
                  with_refunds: 13.94,
                },
                gross_total_spread: {
                  without_refunds: 16.07,
                  with_refunds: 14.99,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.44,
                    with_refunds: 17.1,
                  },
                  gross_mrr: {
                    without_refunds: 20.42,
                    with_refunds: 18.52,
                  },
                  net_arr: {
                    without_refunds: 118.37,
                    with_refunds: 107.37,
                  },
                  gross_arr: {
                    without_refunds: 125,
                    with_refunds: 113.63,
                  },
                  net_arr_spread: {
                    without_refunds: 9.84,
                    with_refunds: 9.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.42,
                    with_refunds: 10.42,
                  },
                  net_total: {
                    without_refunds: 64.24,
                    with_refunds: 58.47,
                  },
                  gross_total: {
                    without_refunds: 68.35,
                    with_refunds: 62.11,
                  },
                  net_total_spread: {
                    without_refunds: 14.7,
                    with_refunds: 13.94,
                  },
                  gross_total_spread: {
                    without_refunds: 16.07,
                    with_refunds: 14.99,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 18.44,
                    with_refunds: 17.1,
                  },
                  gross_mrr: {
                    without_refunds: 20.42,
                    with_refunds: 18.52,
                  },
                  net_arr: {
                    without_refunds: 118.37,
                    with_refunds: 107.37,
                  },
                  gross_arr: {
                    without_refunds: 125,
                    with_refunds: 113.63,
                  },
                  net_arr_spread: {
                    without_refunds: 9.84,
                    with_refunds: 9.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 10.42,
                    with_refunds: 10.42,
                  },
                  net_total: {
                    without_refunds: 64.24,
                    with_refunds: 58.47,
                  },
                  gross_total: {
                    without_refunds: 68.35,
                    with_refunds: 62.11,
                  },
                  net_total_spread: {
                    without_refunds: 14.7,
                    with_refunds: 13.94,
                  },
                  gross_total_spread: {
                    without_refunds: 16.07,
                    with_refunds: 14.99,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
        },
        {
          period: 1,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 11.42,
                  with_refunds: 11.44,
                },
                arr_users: {
                  without_refunds: 6.43,
                  with_refunds: 6.81,
                },
                arr_spread_users: {
                  without_refunds: 16.04,
                  with_refunds: 16.04,
                },
                total_users: {
                  without_refunds: 15.48,
                  with_refunds: 16.03,
                },
                total_spread_users: {
                  without_refunds: 29.25,
                  with_refunds: 29.27,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.8783,
                  with_refunds: 0.8799,
                },
                arr_users: {
                  without_refunds: 0.5847,
                  with_refunds: 0.6187,
                },
                arr_spread_users: {
                  without_refunds: 1.6039,
                  with_refunds: 1.6039,
                },
                total_users: {
                  without_refunds: 0.6451,
                  with_refunds: 0.668,
                },
                total_spread_users: {
                  without_refunds: 1.2716,
                  with_refunds: 1.2725,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.8783,
                  with_refunds: 0.8799,
                },
                arr_users: {
                  without_refunds: 0.5847,
                  with_refunds: 0.6187,
                },
                arr_spread_users: {
                  without_refunds: 1.6039,
                  with_refunds: 1.6039,
                },
                total_users: {
                  without_refunds: 0.6451,
                  with_refunds: 0.668,
                },
                total_spread_users: {
                  without_refunds: 1.2716,
                  with_refunds: 1.2725,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 239.37,
                  with_refunds: 222.82,
                },
                gross_mrr: {
                  without_refunds: 257.13,
                  with_refunds: 234,
                },
                net_arr: {
                  without_refunds: 817.96,
                  with_refunds: 702.3,
                },
                gross_arr: {
                  without_refunds: 836,
                  with_refunds: 719.44,
                },
                net_arr_spread: {
                  without_refunds: 161.97,
                  with_refunds: 161.97,
                },
                gross_arr_spread: {
                  without_refunds: 169.2,
                  with_refunds: 169.2,
                },
                net_total: {
                  without_refunds: 986.78,
                  with_refunds: 855.78,
                },
                gross_total: {
                  without_refunds: 1017.26,
                  with_refunds: 881.15,
                },
                net_total_spread: {
                  without_refunds: 416.92,
                  with_refunds: 398.52,
                },
                gross_total_spread: {
                  without_refunds: 443.39,
                  with_refunds: 416.87,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.9984,
                  with_refunds: 1.0026,
                },
                gross_mrr: {
                  without_refunds: 0.9685,
                  with_refunds: 0.9722,
                },
                net_arr: {
                  without_refunds: 0.6282,
                  with_refunds: 0.5946,
                },
                gross_arr: {
                  without_refunds: 0.608,
                  with_refunds: 0.5756,
                },
                net_arr_spread: {
                  without_refunds: 1.6457,
                  with_refunds: 1.6457,
                },
                gross_arr_spread: {
                  without_refunds: 1.6244,
                  with_refunds: 1.6244,
                },
                net_total: {
                  without_refunds: 0.64,
                  with_refunds: 0.6098,
                },
                gross_total: {
                  without_refunds: 0.6201,
                  with_refunds: 0.5911,
                },
                net_total_spread: {
                  without_refunds: 1.2329,
                  with_refunds: 1.2428,
                },
                gross_total_spread: {
                  without_refunds: 1.1995,
                  with_refunds: 1.2088,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.9984,
                  with_refunds: 1.0026,
                },
                gross_mrr: {
                  without_refunds: 0.9685,
                  with_refunds: 0.9722,
                },
                net_arr: {
                  without_refunds: 0.6282,
                  with_refunds: 0.5946,
                },
                gross_arr: {
                  without_refunds: 0.608,
                  with_refunds: 0.5756,
                },
                net_arr_spread: {
                  without_refunds: 1.6457,
                  with_refunds: 1.6457,
                },
                gross_arr_spread: {
                  without_refunds: 1.6244,
                  with_refunds: 1.6244,
                },
                net_total: {
                  without_refunds: 0.64,
                  with_refunds: 0.6098,
                },
                gross_total: {
                  without_refunds: 0.6201,
                  with_refunds: 0.5911,
                },
                net_total_spread: {
                  without_refunds: 1.2329,
                  with_refunds: 1.2428,
                },
                gross_total_spread: {
                  without_refunds: 1.1995,
                  with_refunds: 1.2088,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 18.41,
                  with_refunds: 17.14,
                },
                gross_mrr: {
                  without_refunds: 19.78,
                  with_refunds: 18,
                },
                net_arr: {
                  without_refunds: 74.36,
                  with_refunds: 63.85,
                },
                gross_arr: {
                  without_refunds: 76,
                  with_refunds: 65.4,
                },
                net_arr_spread: {
                  without_refunds: 16.2,
                  with_refunds: 16.2,
                },
                gross_arr_spread: {
                  without_refunds: 16.92,
                  with_refunds: 16.92,
                },
                net_total: {
                  without_refunds: 41.12,
                  with_refunds: 35.66,
                },
                gross_total: {
                  without_refunds: 42.39,
                  with_refunds: 36.71,
                },
                net_total_spread: {
                  without_refunds: 18.13,
                  with_refunds: 17.33,
                },
                gross_total_spread: {
                  without_refunds: 19.28,
                  with_refunds: 18.12,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 20.96,
                  with_refunds: 19.48,
                },
                gross_mrr: {
                  without_refunds: 22.52,
                  with_refunds: 20.46,
                },
                net_arr: {
                  without_refunds: 127.18,
                  with_refunds: 103.2,
                },
                gross_arr: {
                  without_refunds: 129.99,
                  with_refunds: 105.72,
                },
                net_arr_spread: {
                  without_refunds: 10.1,
                  with_refunds: 10.1,
                },
                gross_arr_spread: {
                  without_refunds: 10.55,
                  with_refunds: 10.55,
                },
                net_total: {
                  without_refunds: 63.74,
                  with_refunds: 53.38,
                },
                gross_total: {
                  without_refunds: 65.7,
                  with_refunds: 54.96,
                },
                net_total_spread: {
                  without_refunds: 14.26,
                  with_refunds: 13.62,
                },
                gross_total_spread: {
                  without_refunds: 15.16,
                  with_refunds: 14.24,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 36.86,
                    with_refunds: 34.24,
                  },
                  gross_mrr: {
                    without_refunds: 40.2,
                    with_refunds: 36.52,
                  },
                  net_arr: {
                    without_refunds: 192.73,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 201,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 26.04,
                    with_refunds: 26.04,
                  },
                  gross_arr_spread: {
                    without_refunds: 27.34,
                    with_refunds: 27.34,
                  },
                  net_total: {
                    without_refunds: 105.36,
                    with_refunds: 94.13,
                  },
                  gross_total: {
                    without_refunds: 110.74,
                    with_refunds: 98.83,
                  },
                  net_total_spread: {
                    without_refunds: 32.83,
                    with_refunds: 31.27,
                  },
                  gross_total_spread: {
                    without_refunds: 35.35,
                    with_refunds: 33.12,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 39.41,
                    with_refunds: 36.58,
                  },
                  gross_mrr: {
                    without_refunds: 42.94,
                    with_refunds: 38.97,
                  },
                  net_arr: {
                    without_refunds: 245.55,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 254.98,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 19.94,
                    with_refunds: 19.94,
                  },
                  gross_arr_spread: {
                    without_refunds: 20.97,
                    with_refunds: 20.97,
                  },
                  net_total: {
                    without_refunds: 127.98,
                    with_refunds: 111.85,
                  },
                  gross_total: {
                    without_refunds: 134.06,
                    with_refunds: 117.07,
                  },
                  net_total_spread: {
                    without_refunds: 28.96,
                    with_refunds: 27.56,
                  },
                  gross_total_spread: {
                    without_refunds: 31.23,
                    with_refunds: 29.24,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 2,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 6.15,
                  with_refunds: 6.15,
                },
                arr_users: {
                  without_refunds: 0.1,
                  with_refunds: 0.23,
                },
                arr_spread_users: {
                  without_refunds: 16.14,
                  with_refunds: 16.14,
                },
                total_users: {
                  without_refunds: 1.88,
                  with_refunds: 2.1,
                },
                total_spread_users: {
                  without_refunds: 25.76,
                  with_refunds: 25.78,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.4727,
                  with_refunds: 0.4729,
                },
                arr_users: {
                  without_refunds: 0.0091,
                  with_refunds: 0.0208,
                },
                arr_spread_users: {
                  without_refunds: 1.6143,
                  with_refunds: 1.6143,
                },
                total_users: {
                  without_refunds: 0.0784,
                  with_refunds: 0.0876,
                },
                total_spread_users: {
                  without_refunds: 1.12,
                  with_refunds: 1.1209,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.5382,
                  with_refunds: 0.5374,
                },
                arr_users: {
                  without_refunds: 0.0155,
                  with_refunds: 0.0336,
                },
                arr_spread_users: {
                  without_refunds: 1.0065,
                  with_refunds: 1.0065,
                },
                total_users: {
                  without_refunds: 0.1215,
                  with_refunds: 0.1311,
                },
                total_spread_users: {
                  without_refunds: 0.8808,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 134.58,
                  with_refunds: 123.98,
                },
                gross_mrr: {
                  without_refunds: 143.2,
                  with_refunds: 128.78,
                },
                net_arr: {
                  without_refunds: 11.11,
                },
                gross_arr: {
                  without_refunds: 12.14,
                },
                net_arr_spread: {
                  without_refunds: 162.89,
                  with_refunds: 162.89,
                },
                gross_arr_spread: {
                  without_refunds: 170.22,
                  with_refunds: 170.22,
                },
                net_total: {
                  without_refunds: 29.7,
                  with_refunds: 17,
                },
                gross_total: {
                  without_refunds: 32.06,
                  with_refunds: 18.06,
                },
                net_total_spread: {
                  without_refunds: 352.17,
                  with_refunds: 337.17,
                },
                gross_total_spread: {
                  without_refunds: 372.07,
                  with_refunds: 350.37,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.5613,
                  with_refunds: 0.5578,
                },
                gross_mrr: {
                  without_refunds: 0.5394,
                  with_refunds: 0.535,
                },
                net_arr: {
                  without_refunds: 0.0085,
                },
                gross_arr: {
                  without_refunds: 0.0088,
                },
                net_arr_spread: {
                  without_refunds: 1.655,
                  with_refunds: 1.655,
                },
                gross_arr_spread: {
                  without_refunds: 1.6342,
                  with_refunds: 1.6342,
                },
                net_total: {
                  without_refunds: 0.0193,
                  with_refunds: 0.0121,
                },
                gross_total: {
                  without_refunds: 0.0195,
                  with_refunds: 0.0121,
                },
                net_total_spread: {
                  without_refunds: 1.0414,
                  with_refunds: 1.0515,
                },
                gross_total_spread: {
                  without_refunds: 1.0065,
                  with_refunds: 1.0159,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5622,
                  with_refunds: 0.5564,
                },
                gross_mrr: {
                  without_refunds: 0.5569,
                  with_refunds: 0.5503,
                },
                net_arr: {
                  without_refunds: 0.0136,
                },
                gross_arr: {
                  without_refunds: 0.0145,
                },
                net_arr_spread: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                gross_arr_spread: {
                  without_refunds: 1.006,
                  with_refunds: 1.006,
                },
                net_total: {
                  without_refunds: 0.0301,
                  with_refunds: 0.0199,
                },
                gross_total: {
                  without_refunds: 0.0315,
                  with_refunds: 0.0205,
                },
                net_total_spread: {
                  without_refunds: 0.8447,
                  with_refunds: 0.846,
                },
                gross_total_spread: {
                  without_refunds: 0.8391,
                  with_refunds: 0.8405,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 10.35,
                  with_refunds: 9.54,
                },
                gross_mrr: {
                  without_refunds: 11.02,
                  with_refunds: 9.91,
                },
                net_arr: {
                  without_refunds: 1.01,
                },
                gross_arr: {
                  without_refunds: 1.1,
                },
                net_arr_spread: {
                  without_refunds: 16.29,
                  with_refunds: 16.29,
                },
                gross_arr_spread: {
                  without_refunds: 17.02,
                  with_refunds: 17.02,
                },
                net_total: {
                  without_refunds: 1.24,
                  with_refunds: 0.71,
                },
                gross_total: {
                  without_refunds: 1.34,
                  with_refunds: 0.75,
                },
                net_total_spread: {
                  without_refunds: 15.31,
                  with_refunds: 14.66,
                },
                gross_total_spread: {
                  without_refunds: 16.18,
                  with_refunds: 15.23,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 21.9,
                  with_refunds: 20.17,
                },
                gross_mrr: {
                  without_refunds: 23.3,
                  with_refunds: 20.95,
                },
                net_arr: {
                  without_refunds: 111.25,
                },
                gross_arr: {
                  without_refunds: 121.52,
                },
                net_arr_spread: {
                  without_refunds: 10.09,
                  with_refunds: 10.09,
                },
                gross_arr_spread: {
                  without_refunds: 10.54,
                  with_refunds: 10.54,
                },
                net_total: {
                  without_refunds: 15.78,
                  with_refunds: 8.09,
                },
                gross_total: {
                  without_refunds: 17.04,
                  with_refunds: 8.59,
                },
                net_total_spread: {
                  without_refunds: 13.67,
                  with_refunds: 13.08,
                },
                gross_total_spread: {
                  without_refunds: 14.44,
                  with_refunds: 13.59,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 47.21,
                    with_refunds: 43.77,
                  },
                  gross_mrr: {
                    without_refunds: 51.22,
                    with_refunds: 46.42,
                  },
                  net_arr: {
                    without_refunds: 193.74,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.1,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 42.33,
                    with_refunds: 42.33,
                  },
                  gross_arr_spread: {
                    without_refunds: 44.36,
                    with_refunds: 44.36,
                  },
                  net_total: {
                    without_refunds: 106.6,
                    with_refunds: 94.84,
                  },
                  gross_total: {
                    without_refunds: 112.07,
                    with_refunds: 99.58,
                  },
                  net_total_spread: {
                    without_refunds: 48.14,
                    with_refunds: 45.93,
                  },
                  gross_total_spread: {
                    without_refunds: 51.53,
                    with_refunds: 48.35,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 61.31,
                    with_refunds: 56.74,
                  },
                  gross_mrr: {
                    without_refunds: 66.24,
                    with_refunds: 59.92,
                  },
                  net_arr: {
                    without_refunds: 356.81,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 376.5,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 30.03,
                    with_refunds: 30.03,
                  },
                  gross_arr_spread: {
                    without_refunds: 31.51,
                    with_refunds: 31.51,
                  },
                  net_total: {
                    without_refunds: 143.76,
                    with_refunds: 119.94,
                  },
                  gross_total: {
                    without_refunds: 151.1,
                    with_refunds: 125.67,
                  },
                  net_total_spread: {
                    without_refunds: 42.63,
                    with_refunds: 40.64,
                  },
                  gross_total_spread: {
                    without_refunds: 45.68,
                    with_refunds: 42.83,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 3,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 3.75,
                  with_refunds: 3.72,
                },
                arr_users: {
                  without_refunds: 0.02,
                  with_refunds: 0.04,
                },
                arr_spread_users: {
                  without_refunds: 16.17,
                  with_refunds: 16.17,
                },
                total_users: {
                  without_refunds: 1.1,
                  with_refunds: 1.13,
                },
                total_spread_users: {
                  without_refunds: 24.81,
                  with_refunds: 24.78,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.2888,
                  with_refunds: 0.2863,
                },
                arr_users: {
                  without_refunds: 0.002,
                  with_refunds: 0.0034,
                },
                arr_spread_users: {
                  without_refunds: 1.6173,
                  with_refunds: 1.6173,
                },
                total_users: {
                  without_refunds: 0.0457,
                  with_refunds: 0.0472,
                },
                total_spread_users: {
                  without_refunds: 1.0787,
                  with_refunds: 1.0772,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6108,
                  with_refunds: 0.6054,
                },
                arr_users: {
                  without_refunds: 0.2219,
                  with_refunds: 0.1643,
                },
                arr_spread_users: {
                  without_refunds: 1.0019,
                  with_refunds: 1.0019,
                },
                total_users: {
                  without_refunds: 0.5827,
                  with_refunds: 0.539,
                },
                total_spread_users: {
                  without_refunds: 0.9631,
                  with_refunds: 0.961,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 84.73,
                  with_refunds: 76.89,
                },
                gross_mrr: {
                  without_refunds: 89.94,
                  with_refunds: 79.82,
                },
                net_arr: {
                  without_refunds: 2.22,
                },
                gross_arr: {
                  without_refunds: 2.54,
                },
                net_arr_spread: {
                  without_refunds: 163.14,
                  with_refunds: 163.14,
                },
                gross_arr_spread: {
                  without_refunds: 170.54,
                  with_refunds: 170.54,
                },
                net_total: {
                  without_refunds: 13.33,
                  with_refunds: 10.54,
                },
                gross_total: {
                  without_refunds: 14.32,
                  with_refunds: 11.28,
                },
                net_total_spread: {
                  without_refunds: 337.64,
                  with_refunds: 324.29,
                },
                gross_total_spread: {
                  without_refunds: 356.23,
                  with_refunds: 336.48,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.3534,
                  with_refunds: 0.3459,
                },
                gross_mrr: {
                  without_refunds: 0.3388,
                  with_refunds: 0.3316,
                },
                net_arr: {
                  without_refunds: 0.0017,
                },
                gross_arr: {
                  without_refunds: 0.0018,
                },
                net_arr_spread: {
                  without_refunds: 1.6576,
                  with_refunds: 1.6576,
                },
                gross_arr_spread: {
                  without_refunds: 1.6372,
                  with_refunds: 1.6372,
                },
                net_total: {
                  without_refunds: 0.0086,
                  with_refunds: 0.0075,
                },
                gross_total: {
                  without_refunds: 0.0087,
                  with_refunds: 0.0076,
                },
                net_total_spread: {
                  without_refunds: 0.9984,
                  with_refunds: 1.0113,
                },
                gross_total_spread: {
                  without_refunds: 0.9637,
                  with_refunds: 0.9757,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6296,
                  with_refunds: 0.6202,
                },
                gross_mrr: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6198,
                },
                net_arr: {
                  without_refunds: 0.1999,
                },
                gross_arr: {
                  without_refunds: 0.2096,
                },
                net_arr_spread: {
                  without_refunds: 1.0016,
                  with_refunds: 1.0016,
                },
                gross_arr_spread: {
                  without_refunds: 1.0018,
                  with_refunds: 1.0018,
                },
                net_total: {
                  without_refunds: 0.4488,
                  with_refunds: 0.6202,
                },
                gross_total: {
                  without_refunds: 0.4468,
                  with_refunds: 0.6244,
                },
                net_total_spread: {
                  without_refunds: 0.9587,
                  with_refunds: 0.9618,
                },
                gross_total_spread: {
                  without_refunds: 0.9574,
                  with_refunds: 0.9604,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 6.52,
                  with_refunds: 5.91,
                },
                gross_mrr: {
                  without_refunds: 6.92,
                  with_refunds: 6.14,
                },
                net_arr: {
                  without_refunds: 0.2,
                },
                gross_arr: {
                  without_refunds: 0.23,
                },
                net_arr_spread: {
                  without_refunds: 16.31,
                  with_refunds: 16.31,
                },
                gross_arr_spread: {
                  without_refunds: 17.05,
                  with_refunds: 17.05,
                },
                net_total: {
                  without_refunds: 0.56,
                  with_refunds: 0.44,
                },
                gross_total: {
                  without_refunds: 0.6,
                  with_refunds: 0.47,
                },
                net_total_spread: {
                  without_refunds: 14.68,
                  with_refunds: 14.1,
                },
                gross_total_spread: {
                  without_refunds: 15.49,
                  with_refunds: 14.63,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 22.57,
                  with_refunds: 20.66,
                },
                gross_mrr: {
                  without_refunds: 23.96,
                  with_refunds: 21.45,
                },
                net_arr: {
                  without_refunds: 100.21,
                },
                gross_arr: {
                  without_refunds: 114.76,
                },
                net_arr_spread: {
                  without_refunds: 10.09,
                  with_refunds: 10.09,
                },
                gross_arr_spread: {
                  without_refunds: 10.54,
                  with_refunds: 10.54,
                },
                net_total: {
                  without_refunds: 12.16,
                  with_refunds: 9.31,
                },
                gross_total: {
                  without_refunds: 13.06,
                  with_refunds: 9.96,
                },
                net_total_spread: {
                  without_refunds: 13.61,
                  with_refunds: 13.09,
                },
                gross_total_spread: {
                  without_refunds: 14.36,
                  with_refunds: 13.58,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 53.73,
                    with_refunds: 49.69,
                  },
                  gross_mrr: {
                    without_refunds: 58.13,
                    with_refunds: 52.56,
                  },
                  net_arr: {
                    without_refunds: 193.95,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.33,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 58.64,
                    with_refunds: 58.64,
                  },
                  gross_arr_spread: {
                    without_refunds: 61.41,
                    with_refunds: 61.41,
                  },
                  net_total: {
                    without_refunds: 107.15,
                    with_refunds: 95.28,
                  },
                  gross_total: {
                    without_refunds: 112.67,
                    with_refunds: 100.05,
                  },
                  net_total_spread: {
                    without_refunds: 62.82,
                    with_refunds: 60.03,
                  },
                  gross_total_spread: {
                    without_refunds: 67.01,
                    with_refunds: 62.98,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 83.88,
                    with_refunds: 77.41,
                  },
                  gross_mrr: {
                    without_refunds: 90.2,
                    with_refunds: 81.37,
                  },
                  net_arr: {
                    without_refunds: 457.01,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 491.27,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 40.12,
                    with_refunds: 40.12,
                  },
                  gross_arr_spread: {
                    without_refunds: 42.05,
                    with_refunds: 42.05,
                  },
                  net_total: {
                    without_refunds: 155.92,
                    with_refunds: 129.25,
                  },
                  gross_total: {
                    without_refunds: 164.16,
                    with_refunds: 135.62,
                  },
                  net_total_spread: {
                    without_refunds: 56.24,
                    with_refunds: 53.73,
                  },
                  gross_total_spread: {
                    without_refunds: 60.03,
                    with_refunds: 56.41,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 4,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 2.12,
                  with_refunds: 2.1,
                },
                arr_users: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                arr_spread_users: {
                  without_refunds: 16.23,
                  with_refunds: 16.23,
                },
                total_users: {
                  without_refunds: 0.62,
                  with_refunds: 0.64,
                },
                total_spread_users: {
                  without_refunds: 22.83,
                  with_refunds: 22.8,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.163,
                  with_refunds: 0.1618,
                },
                arr_users: {
                  without_refunds: 0.0009,
                  with_refunds: 0.0013,
                },
                arr_spread_users: {
                  without_refunds: 1.6233,
                  with_refunds: 1.6233,
                },
                total_users: {
                  without_refunds: 0.0257,
                  with_refunds: 0.0265,
                },
                total_spread_users: {
                  without_refunds: 0.9927,
                  with_refunds: 0.9914,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.5646,
                  with_refunds: 0.5653,
                },
                arr_users: {
                  without_refunds: 0.4222,
                  with_refunds: 0.3778,
                },
                arr_spread_users: {
                  without_refunds: 1.0037,
                  with_refunds: 1.0037,
                },
                total_users: {
                  without_refunds: 0.5622,
                  with_refunds: 0.5615,
                },
                total_spread_users: {
                  without_refunds: 0.9203,
                  with_refunds: 0.9204,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 47.77,
                  with_refunds: 43.3,
                },
                gross_mrr: {
                  without_refunds: 50.86,
                  with_refunds: 45.09,
                },
                net_arr: {
                  without_refunds: 1,
                },
                gross_arr: {
                  without_refunds: 1.05,
                },
                net_arr_spread: {
                  without_refunds: 163.79,
                  with_refunds: 163.79,
                },
                gross_arr_spread: {
                  without_refunds: 171.29,
                  with_refunds: 171.29,
                },
                net_total: {
                  without_refunds: 7.31,
                  with_refunds: 6.06,
                },
                gross_total: {
                  without_refunds: 7.78,
                  with_refunds: 6.46,
                },
                net_total_spread: {
                  without_refunds: 305.93,
                  with_refunds: 294.2,
                },
                gross_total_spread: {
                  without_refunds: 322.7,
                  with_refunds: 305.17,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.1993,
                  with_refunds: 0.1948,
                },
                gross_mrr: {
                  without_refunds: 0.1916,
                  with_refunds: 0.1873,
                },
                net_arr: {
                  without_refunds: 0.0008,
                },
                gross_arr: {
                  without_refunds: 0.0008,
                },
                net_arr_spread: {
                  without_refunds: 1.6642,
                  with_refunds: 1.6642,
                },
                gross_arr_spread: {
                  without_refunds: 1.6444,
                  with_refunds: 1.6444,
                },
                net_total: {
                  without_refunds: 0.0047,
                  with_refunds: 0.0043,
                },
                gross_total: {
                  without_refunds: 0.0047,
                  with_refunds: 0.0043,
                },
                net_total_spread: {
                  without_refunds: 0.9047,
                  with_refunds: 0.9175,
                },
                gross_total_spread: {
                  without_refunds: 0.873,
                  with_refunds: 0.8849,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.5638,
                  with_refunds: 0.5631,
                },
                gross_mrr: {
                  without_refunds: 0.5656,
                  with_refunds: 0.5649,
                },
                net_arr: {
                  without_refunds: 0.4524,
                },
                gross_arr: {
                  without_refunds: 0.4112,
                },
                net_arr_spread: {
                  without_refunds: 1.004,
                  with_refunds: 1.004,
                },
                gross_arr_spread: {
                  without_refunds: 1.0044,
                  with_refunds: 1.0044,
                },
                net_total: {
                  without_refunds: 0.5483,
                  with_refunds: 0.5742,
                },
                gross_total: {
                  without_refunds: 0.5431,
                  with_refunds: 0.5733,
                },
                net_total_spread: {
                  without_refunds: 0.9061,
                  with_refunds: 0.9072,
                },
                gross_total_spread: {
                  without_refunds: 0.9059,
                  with_refunds: 0.9069,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 3.67,
                  with_refunds: 3.33,
                },
                gross_mrr: {
                  without_refunds: 3.91,
                  with_refunds: 3.47,
                },
                net_arr: {
                  without_refunds: 0.09,
                },
                gross_arr: {
                  without_refunds: 0.1,
                },
                net_arr_spread: {
                  without_refunds: 16.38,
                  with_refunds: 16.38,
                },
                gross_arr_spread: {
                  without_refunds: 17.13,
                  with_refunds: 17.13,
                },
                net_total: {
                  without_refunds: 0.3,
                  with_refunds: 0.25,
                },
                gross_total: {
                  without_refunds: 0.32,
                  with_refunds: 0.27,
                },
                net_total_spread: {
                  without_refunds: 13.3,
                  with_refunds: 12.79,
                },
                gross_total_spread: {
                  without_refunds: 14.03,
                  with_refunds: 13.27,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 22.54,
                  with_refunds: 20.58,
                },
                gross_mrr: {
                  without_refunds: 24,
                  with_refunds: 21.43,
                },
                net_arr: {
                  without_refunds: 107.37,
                },
                gross_arr: {
                  without_refunds: 111.77,
                },
                net_arr_spread: {
                  without_refunds: 10.09,
                  with_refunds: 10.09,
                },
                gross_arr_spread: {
                  without_refunds: 10.55,
                  with_refunds: 10.55,
                },
                net_total: {
                  without_refunds: 11.86,
                  with_refunds: 9.52,
                },
                gross_total: {
                  without_refunds: 12.62,
                  with_refunds: 10.17,
                },
                net_total_spread: {
                  without_refunds: 13.4,
                  with_refunds: 12.9,
                },
                gross_total_spread: {
                  without_refunds: 14.13,
                  with_refunds: 13.38,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 57.4,
                    with_refunds: 53.02,
                  },
                  gross_mrr: {
                    without_refunds: 62.05,
                    with_refunds: 56.03,
                  },
                  net_arr: {
                    without_refunds: 194.04,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.43,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 75.02,
                    with_refunds: 75.02,
                  },
                  gross_arr_spread: {
                    without_refunds: 78.54,
                    with_refunds: 78.54,
                  },
                  net_total: {
                    without_refunds: 107.46,
                    with_refunds: 95.53,
                  },
                  gross_total: {
                    without_refunds: 113,
                    with_refunds: 100.32,
                  },
                  net_total_spread: {
                    without_refunds: 76.12,
                    with_refunds: 72.82,
                  },
                  gross_total_spread: {
                    without_refunds: 81.05,
                    with_refunds: 76.25,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 106.42,
                    with_refunds: 97.99,
                  },
                  gross_mrr: {
                    without_refunds: 114.2,
                    with_refunds: 102.81,
                  },
                  net_arr: {
                    without_refunds: 564.39,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 603.03,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 50.21,
                    with_refunds: 50.21,
                  },
                  gross_arr_spread: {
                    without_refunds: 52.61,
                    with_refunds: 52.61,
                  },
                  net_total: {
                    without_refunds: 167.78,
                    with_refunds: 138.77,
                  },
                  gross_total: {
                    without_refunds: 176.78,
                    with_refunds: 145.79,
                  },
                  net_total_spread: {
                    without_refunds: 69.64,
                    with_refunds: 66.63,
                  },
                  gross_total_spread: {
                    without_refunds: 74.17,
                    with_refunds: 69.79,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 5,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 1.34,
                  with_refunds: 1.34,
                },
                arr_users: {
                  with_refunds: 0.01,
                },
                arr_spread_users: {
                  without_refunds: 16.24,
                  with_refunds: 16.24,
                },
                total_users: {
                  without_refunds: 0.39,
                  with_refunds: 0.4,
                },
                total_spread_users: {
                  without_refunds: 20.49,
                  with_refunds: 20.47,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.1033,
                  with_refunds: 0.1028,
                },
                arr_users: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0005,
                },
                arr_spread_users: {
                  without_refunds: 1.6241,
                  with_refunds: 1.6241,
                },
                total_users: {
                  without_refunds: 0.0161,
                  with_refunds: 0.0168,
                },
                total_spread_users: {
                  without_refunds: 0.8911,
                  with_refunds: 0.89,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6336,
                  with_refunds: 0.6351,
                },
                arr_users: {
                  without_refunds: 0.2273,
                  with_refunds: 0.3662,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6277,
                  with_refunds: 0.6322,
                },
                total_spread_users: {
                  without_refunds: 0.8976,
                  with_refunds: 0.8977,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 30.2,
                  with_refunds: 27.35,
                },
                gross_mrr: {
                  without_refunds: 31.97,
                  with_refunds: 28.36,
                },
                net_arr: {
                  without_refunds: 0.22,
                },
                gross_arr: {
                  without_refunds: 0.25,
                },
                net_arr_spread: {
                  without_refunds: 163.88,
                  with_refunds: 163.88,
                },
                gross_arr_spread: {
                  without_refunds: 171.37,
                  with_refunds: 171.37,
                },
                net_total: {
                  without_refunds: 4.26,
                  with_refunds: 3.61,
                },
                gross_total: {
                  without_refunds: 4.44,
                  with_refunds: 3.79,
                },
                net_total_spread: {
                  without_refunds: 266.67,
                  with_refunds: 257.12,
                },
                gross_total_spread: {
                  without_refunds: 280.55,
                  with_refunds: 266.13,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.126,
                  with_refunds: 0.1231,
                },
                gross_mrr: {
                  without_refunds: 0.1204,
                  with_refunds: 0.1178,
                },
                net_arr: {
                  without_refunds: 0.0002,
                },
                gross_arr: {
                  without_refunds: 0.0002,
                },
                net_arr_spread: {
                  without_refunds: 1.6651,
                  with_refunds: 1.6651,
                },
                gross_arr_spread: {
                  without_refunds: 1.6452,
                  with_refunds: 1.6452,
                },
                net_total: {
                  without_refunds: 0.0028,
                  with_refunds: 0.0026,
                },
                gross_total: {
                  without_refunds: 0.0027,
                  with_refunds: 0.0025,
                },
                net_total_spread: {
                  without_refunds: 0.7886,
                  with_refunds: 0.8018,
                },
                gross_total_spread: {
                  without_refunds: 0.759,
                  with_refunds: 0.7717,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6321,
                  with_refunds: 0.6317,
                },
                gross_mrr: {
                  without_refunds: 0.6286,
                  with_refunds: 0.629,
                },
                net_arr: {
                  without_refunds: 0.2185,
                },
                gross_arr: {
                  without_refunds: 0.2373,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                net_total: {
                  without_refunds: 0.5827,
                  with_refunds: 0.5954,
                },
                gross_total: {
                  without_refunds: 0.5713,
                  with_refunds: 0.5861,
                },
                net_total_spread: {
                  without_refunds: 0.8717,
                  with_refunds: 0.874,
                },
                gross_total_spread: {
                  without_refunds: 0.8694,
                  with_refunds: 0.8721,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 2.32,
                  with_refunds: 2.1,
                },
                gross_mrr: {
                  without_refunds: 2.46,
                  with_refunds: 2.18,
                },
                net_arr: {
                  without_refunds: 0.02,
                },
                gross_arr: {
                  without_refunds: 0.02,
                },
                net_arr_spread: {
                  without_refunds: 16.39,
                  with_refunds: 16.39,
                },
                gross_arr_spread: {
                  without_refunds: 17.14,
                  with_refunds: 17.14,
                },
                net_total: {
                  without_refunds: 0.18,
                  with_refunds: 0.15,
                },
                gross_total: {
                  without_refunds: 0.19,
                  with_refunds: 0.16,
                },
                net_total_spread: {
                  without_refunds: 11.59,
                  with_refunds: 11.18,
                },
                gross_total_spread: {
                  without_refunds: 12.2,
                  with_refunds: 11.57,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 22.49,
                  with_refunds: 20.47,
                },
                gross_mrr: {
                  without_refunds: 23.81,
                  with_refunds: 21.23,
                },
                net_arr: {
                  without_refunds: 103.23,
                },
                gross_arr: {
                  without_refunds: 116.7,
                },
                net_arr_spread: {
                  without_refunds: 10.09,
                  with_refunds: 10.09,
                },
                gross_arr_spread: {
                  without_refunds: 10.55,
                  with_refunds: 10.55,
                },
                net_total: {
                  without_refunds: 11.01,
                  with_refunds: 8.97,
                },
                gross_total: {
                  without_refunds: 11.49,
                  with_refunds: 9.42,
                },
                net_total_spread: {
                  without_refunds: 13.01,
                  with_refunds: 12.56,
                },
                gross_total_spread: {
                  without_refunds: 13.69,
                  with_refunds: 13,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 59.72,
                    with_refunds: 55.12,
                  },
                  gross_mrr: {
                    without_refunds: 64.51,
                    with_refunds: 58.21,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.45,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 91.41,
                    with_refunds: 91.41,
                  },
                  gross_arr_spread: {
                    without_refunds: 95.68,
                    with_refunds: 95.68,
                  },
                  net_total: {
                    without_refunds: 107.63,
                    with_refunds: 95.68,
                  },
                  gross_total: {
                    without_refunds: 113.18,
                    with_refunds: 100.48,
                  },
                  net_total_spread: {
                    without_refunds: 87.72,
                    with_refunds: 84,
                  },
                  gross_total_spread: {
                    without_refunds: 93.24,
                    with_refunds: 87.82,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 128.91,
                    with_refunds: 118.45,
                  },
                  gross_mrr: {
                    without_refunds: 138.01,
                    with_refunds: 124.03,
                  },
                  net_arr: {
                    without_refunds: 667.62,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 719.73,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 60.3,
                    with_refunds: 60.3,
                  },
                  gross_arr_spread: {
                    without_refunds: 63.16,
                    with_refunds: 63.16,
                  },
                  net_total: {
                    without_refunds: 178.78,
                    with_refunds: 147.74,
                  },
                  gross_total: {
                    without_refunds: 188.27,
                    with_refunds: 155.21,
                  },
                  net_total_spread: {
                    without_refunds: 82.65,
                    with_refunds: 79.19,
                  },
                  gross_total_spread: {
                    without_refunds: 87.86,
                    with_refunds: 82.79,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 6,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.91,
                  with_refunds: 0.91,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 16.27,
                  with_refunds: 16.27,
                },
                total_users: {
                  without_refunds: 0.26,
                  with_refunds: 0.27,
                },
                total_spread_users: {
                  without_refunds: 18.21,
                  with_refunds: 18.17,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0703,
                  with_refunds: 0.0697,
                },
                arr_users: {
                  with_refunds: 0.0002,
                },
                arr_spread_users: {
                  without_refunds: 1.6273,
                  with_refunds: 1.6273,
                },
                total_users: {
                  without_refunds: 0.0109,
                  with_refunds: 0.0113,
                },
                total_spread_users: {
                  without_refunds: 0.7916,
                  with_refunds: 0.79,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6803,
                  with_refunds: 0.6785,
                },
                arr_users: {
                  without_refunds: 0.1905,
                  with_refunds: 0.4881,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6778,
                  with_refunds: 0.6753,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8876,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 20.69,
                  with_refunds: 19.07,
                },
                gross_mrr: {
                  without_refunds: 21.86,
                  with_refunds: 19.71,
                },
                net_arr: {
                  without_refunds: 0.05,
                },
                gross_arr: {
                  without_refunds: 0.05,
                },
                net_arr_spread: {
                  without_refunds: 164.26,
                  with_refunds: 164.26,
                },
                gross_arr_spread: {
                  without_refunds: 171.82,
                  with_refunds: 171.82,
                },
                net_total: {
                  without_refunds: 2.84,
                  with_refunds: 2.49,
                },
                gross_total: {
                  without_refunds: 2.94,
                  with_refunds: 2.6,
                },
                net_total_spread: {
                  without_refunds: 229.21,
                  with_refunds: 222.54,
                },
                gross_total_spread: {
                  without_refunds: 240.95,
                  with_refunds: 230.03,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0863,
                  with_refunds: 0.0858,
                },
                gross_mrr: {
                  without_refunds: 0.0824,
                  with_refunds: 0.0819,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.669,
                  with_refunds: 1.669,
                },
                gross_arr_spread: {
                  without_refunds: 1.6495,
                  with_refunds: 1.6495,
                },
                net_total: {
                  without_refunds: 0.0018,
                  with_refunds: 0.0018,
                },
                gross_total: {
                  without_refunds: 0.0018,
                  with_refunds: 0.0017,
                },
                net_total_spread: {
                  without_refunds: 0.6778,
                  with_refunds: 0.694,
                },
                gross_total_spread: {
                  without_refunds: 0.6518,
                  with_refunds: 0.667,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6851,
                  with_refunds: 0.6973,
                },
                gross_mrr: {
                  without_refunds: 0.6838,
                  with_refunds: 0.6948,
                },
                net_arr: {
                  without_refunds: 0.2058,
                },
                gross_arr: {
                  without_refunds: 0.1926,
                },
                net_arr_spread: {
                  without_refunds: 1.0024,
                  with_refunds: 1.0024,
                },
                gross_arr_spread: {
                  without_refunds: 1.0026,
                  with_refunds: 1.0026,
                },
                net_total: {
                  without_refunds: 0.6669,
                  with_refunds: 0.6901,
                },
                gross_total: {
                  without_refunds: 0.6613,
                  with_refunds: 0.6867,
                },
                net_total_spread: {
                  without_refunds: 0.8595,
                  with_refunds: 0.8655,
                },
                gross_total_spread: {
                  without_refunds: 0.8589,
                  with_refunds: 0.8644,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.59,
                  with_refunds: 1.47,
                },
                gross_mrr: {
                  without_refunds: 1.68,
                  with_refunds: 1.52,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 16.43,
                  with_refunds: 16.43,
                },
                gross_arr_spread: {
                  without_refunds: 17.18,
                  with_refunds: 17.18,
                },
                net_total: {
                  without_refunds: 0.12,
                  with_refunds: 0.1,
                },
                gross_total: {
                  without_refunds: 0.12,
                  with_refunds: 0.11,
                },
                net_total_spread: {
                  without_refunds: 9.97,
                  with_refunds: 9.68,
                },
                gross_total_spread: {
                  without_refunds: 10.48,
                  with_refunds: 10,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 22.65,
                  with_refunds: 21.03,
                },
                gross_mrr: {
                  without_refunds: 23.94,
                  with_refunds: 21.74,
                },
                net_arr: {
                  without_refunds: 111.54,
                },
                gross_arr: {
                  without_refunds: 118.02,
                },
                net_arr_spread: {
                  without_refunds: 10.09,
                  with_refunds: 10.09,
                },
                gross_arr_spread: {
                  without_refunds: 10.56,
                  with_refunds: 10.56,
                },
                net_total: {
                  without_refunds: 10.83,
                  with_refunds: 9.17,
                },
                gross_total: {
                  without_refunds: 11.21,
                  with_refunds: 9.58,
                },
                net_total_spread: {
                  without_refunds: 12.59,
                  with_refunds: 12.25,
                },
                gross_total_spread: {
                  without_refunds: 13.23,
                  with_refunds: 12.66,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 61.31,
                    with_refunds: 56.59,
                  },
                  gross_mrr: {
                    without_refunds: 66.19,
                    with_refunds: 59.73,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.46,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 107.84,
                    with_refunds: 107.84,
                  },
                  gross_arr_spread: {
                    without_refunds: 112.86,
                    with_refunds: 112.86,
                  },
                  net_total: {
                    without_refunds: 107.75,
                    with_refunds: 95.78,
                  },
                  gross_total: {
                    without_refunds: 113.3,
                    with_refunds: 100.58,
                  },
                  net_total_spread: {
                    without_refunds: 97.68,
                    with_refunds: 93.67,
                  },
                  gross_total_spread: {
                    without_refunds: 103.72,
                    with_refunds: 97.82,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 151.56,
                    with_refunds: 139.49,
                  },
                  gross_mrr: {
                    without_refunds: 161.95,
                    with_refunds: 145.77,
                  },
                  net_arr: {
                    without_refunds: 779.16,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 837.75,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 70.39,
                    with_refunds: 70.39,
                  },
                  gross_arr_spread: {
                    without_refunds: 73.72,
                    with_refunds: 73.72,
                  },
                  net_total: {
                    without_refunds: 189.61,
                    with_refunds: 156.91,
                  },
                  gross_total: {
                    without_refunds: 199.47,
                    with_refunds: 164.8,
                  },
                  net_total_spread: {
                    without_refunds: 95.24,
                    with_refunds: 91.44,
                  },
                  gross_total_spread: {
                    without_refunds: 101.09,
                    with_refunds: 95.45,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 7,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.57,
                  with_refunds: 0.57,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 16.28,
                  with_refunds: 16.28,
                },
                total_users: {
                  without_refunds: 0.16,
                  with_refunds: 0.17,
                },
                total_spread_users: {
                  without_refunds: 16.31,
                  with_refunds: 16.27,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.044,
                  with_refunds: 0.0437,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.6281,
                  with_refunds: 1.6281,
                },
                total_users: {
                  without_refunds: 0.0068,
                  with_refunds: 0.007,
                },
                total_spread_users: {
                  without_refunds: 0.7092,
                  with_refunds: 0.7074,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.626,
                  with_refunds: 0.6268,
                },
                arr_users: {
                  without_refunds: 0.0833,
                  with_refunds: 0.1167,
                },
                arr_spread_users: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                total_users: {
                  without_refunds: 0.6196,
                  with_refunds: 0.6196,
                },
                total_spread_users: {
                  without_refunds: 0.8959,
                  with_refunds: 0.8955,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 13.07,
                  with_refunds: 11.93,
                },
                gross_mrr: {
                  without_refunds: 13.82,
                  with_refunds: 12.35,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 164.35,
                  with_refunds: 164.35,
                },
                gross_arr_spread: {
                  without_refunds: 171.93,
                  with_refunds: 171.93,
                },
                net_total: {
                  without_refunds: 1.62,
                  with_refunds: 1.41,
                },
                gross_total: {
                  without_refunds: 1.66,
                  with_refunds: 1.47,
                },
                net_total_spread: {
                  without_refunds: 198.14,
                  with_refunds: 192.2,
                },
                gross_total_spread: {
                  without_refunds: 208.31,
                  with_refunds: 198.79,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0545,
                  with_refunds: 0.0537,
                },
                gross_mrr: {
                  without_refunds: 0.052,
                  with_refunds: 0.0513,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.6699,
                  with_refunds: 1.6699,
                },
                gross_arr_spread: {
                  without_refunds: 1.6506,
                  with_refunds: 1.6506,
                },
                net_total: {
                  without_refunds: 0.001,
                  with_refunds: 0.001,
                },
                gross_total: {
                  without_refunds: 0.001,
                  with_refunds: 0.001,
                },
                net_total_spread: {
                  without_refunds: 0.5859,
                  with_refunds: 0.5994,
                },
                gross_total_spread: {
                  without_refunds: 0.5635,
                  with_refunds: 0.5764,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6258,
                },
                gross_mrr: {
                  without_refunds: 0.6319,
                  with_refunds: 0.6268,
                },
                net_arr: {
                  without_refunds: 0.0657,
                },
                gross_arr: {
                  without_refunds: 0.0754,
                },
                net_arr_spread: {
                  without_refunds: 1.0005,
                  with_refunds: 1.0005,
                },
                gross_arr_spread: {
                  without_refunds: 1.0007,
                  with_refunds: 1.0007,
                },
                net_total: {
                  without_refunds: 0.5691,
                  with_refunds: 0.5667,
                },
                gross_total: {
                  without_refunds: 0.566,
                  with_refunds: 0.5633,
                },
                net_total_spread: {
                  without_refunds: 0.8644,
                  with_refunds: 0.8637,
                },
                gross_total_spread: {
                  without_refunds: 0.8645,
                  with_refunds: 0.8642,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 1.01,
                  with_refunds: 0.92,
                },
                gross_mrr: {
                  without_refunds: 1.06,
                  with_refunds: 0.95,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 16.44,
                  with_refunds: 16.44,
                },
                gross_arr_spread: {
                  without_refunds: 17.19,
                  with_refunds: 17.19,
                },
                net_total: {
                  without_refunds: 0.07,
                  with_refunds: 0.06,
                },
                gross_total: {
                  without_refunds: 0.07,
                  with_refunds: 0.06,
                },
                net_total_spread: {
                  without_refunds: 8.61,
                  with_refunds: 8.36,
                },
                gross_total_spread: {
                  without_refunds: 9.06,
                  with_refunds: 8.64,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 22.86,
                  with_refunds: 21,
                },
                gross_mrr: {
                  without_refunds: 24.16,
                  with_refunds: 21.74,
                },
                net_arr: {
                  without_refunds: 88,
                },
                gross_arr: {
                  without_refunds: 106.74,
                },
                net_arr_spread: {
                  without_refunds: 10.09,
                  with_refunds: 10.09,
                },
                gross_arr_spread: {
                  without_refunds: 10.56,
                  with_refunds: 10.56,
                },
                net_total: {
                  without_refunds: 9.95,
                  with_refunds: 8.38,
                },
                gross_total: {
                  without_refunds: 10.24,
                  with_refunds: 8.71,
                },
                net_total_spread: {
                  without_refunds: 12.15,
                  with_refunds: 11.81,
                },
                gross_total_spread: {
                  without_refunds: 12.77,
                  with_refunds: 12.22,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 62.32,
                    with_refunds: 57.51,
                  },
                  gross_mrr: {
                    without_refunds: 67.25,
                    with_refunds: 60.68,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.46,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 124.27,
                    with_refunds: 124.27,
                  },
                  gross_arr_spread: {
                    without_refunds: 130.05,
                    with_refunds: 130.05,
                  },
                  net_total: {
                    without_refunds: 107.82,
                    with_refunds: 95.84,
                  },
                  gross_total: {
                    without_refunds: 113.37,
                    with_refunds: 100.65,
                  },
                  net_total_spread: {
                    without_refunds: 106.3,
                    with_refunds: 102.03,
                  },
                  gross_total_spread: {
                    without_refunds: 112.78,
                    with_refunds: 106.47,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 174.42,
                    with_refunds: 160.49,
                  },
                  gross_mrr: {
                    without_refunds: 186.11,
                    with_refunds: 167.51,
                  },
                  net_arr: {
                    without_refunds: 867.16,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 944.5,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 80.49,
                    with_refunds: 80.49,
                  },
                  gross_arr_spread: {
                    without_refunds: 84.28,
                    with_refunds: 84.28,
                  },
                  net_total: {
                    without_refunds: 199.56,
                    with_refunds: 165.29,
                  },
                  gross_total: {
                    without_refunds: 209.71,
                    with_refunds: 173.51,
                  },
                  net_total_spread: {
                    without_refunds: 107.39,
                    with_refunds: 103.25,
                  },
                  gross_total_spread: {
                    without_refunds: 113.86,
                    with_refunds: 107.67,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 8,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.38,
                  with_refunds: 0.37,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 16.31,
                  with_refunds: 16.31,
                },
                total_users: {
                  without_refunds: 0.11,
                  with_refunds: 0.11,
                },
                total_spread_users: {
                  without_refunds: 14.79,
                  with_refunds: 14.76,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.029,
                  with_refunds: 0.0288,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.6314,
                  with_refunds: 1.6314,
                },
                total_users: {
                  without_refunds: 0.0045,
                  with_refunds: 0.0046,
                },
                total_spread_users: {
                  without_refunds: 0.6431,
                  with_refunds: 0.6415,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6594,
                  with_refunds: 0.6599,
                },
                arr_users: {
                  without_refunds: 0.2778,
                  with_refunds: 0.2778,
                },
                arr_spread_users: {
                  without_refunds: 1.002,
                  with_refunds: 1.002,
                },
                total_users: {
                  without_refunds: 0.6586,
                  with_refunds: 0.6586,
                },
                total_spread_users: {
                  without_refunds: 0.9068,
                  with_refunds: 0.9069,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 8.68,
                  with_refunds: 7.91,
                },
                gross_mrr: {
                  without_refunds: 9.17,
                  with_refunds: 8.18,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 164.76,
                  with_refunds: 164.76,
                },
                gross_arr_spread: {
                  without_refunds: 172.4,
                  with_refunds: 172.4,
                },
                net_total: {
                  without_refunds: 1.06,
                  with_refunds: 0.94,
                },
                gross_total: {
                  without_refunds: 1.09,
                  with_refunds: 0.97,
                },
                net_total_spread: {
                  without_refunds: 173.96,
                  with_refunds: 168.79,
                },
                gross_total_spread: {
                  without_refunds: 182.67,
                  with_refunds: 174.37,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0362,
                  with_refunds: 0.0356,
                },
                gross_mrr: {
                  without_refunds: 0.0345,
                  with_refunds: 0.034,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.674,
                  with_refunds: 1.674,
                },
                gross_arr_spread: {
                  without_refunds: 1.6551,
                  with_refunds: 1.6551,
                },
                net_total: {
                  without_refunds: 0.0007,
                  with_refunds: 0.0007,
                },
                gross_total: {
                  without_refunds: 0.0007,
                  with_refunds: 0.0007,
                },
                net_total_spread: {
                  without_refunds: 0.5144,
                  with_refunds: 0.5264,
                },
                gross_total_spread: {
                  without_refunds: 0.4942,
                  with_refunds: 0.5056,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6641,
                  with_refunds: 0.6628,
                },
                gross_mrr: {
                  without_refunds: 0.6635,
                  with_refunds: 0.6623,
                },
                net_arr: {
                  without_refunds: 0.2848,
                },
                gross_arr: {
                  without_refunds: 0.285,
                },
                net_arr_spread: {
                  without_refunds: 1.0025,
                  with_refunds: 1.0025,
                },
                gross_arr_spread: {
                  without_refunds: 1.0027,
                  with_refunds: 1.0027,
                },
                net_total: {
                  without_refunds: 0.6579,
                  with_refunds: 0.6639,
                },
                gross_total: {
                  without_refunds: 0.6561,
                  with_refunds: 0.6632,
                },
                net_total_spread: {
                  without_refunds: 0.878,
                  with_refunds: 0.8782,
                },
                gross_total_spread: {
                  without_refunds: 0.8769,
                  with_refunds: 0.8771,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.67,
                  with_refunds: 0.61,
                },
                gross_mrr: {
                  without_refunds: 0.71,
                  with_refunds: 0.63,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 16.48,
                  with_refunds: 16.48,
                },
                gross_arr_spread: {
                  without_refunds: 17.24,
                  with_refunds: 17.24,
                },
                net_total: {
                  without_refunds: 0.04,
                  with_refunds: 0.04,
                },
                gross_total: {
                  without_refunds: 0.05,
                  with_refunds: 0.04,
                },
                net_total_spread: {
                  without_refunds: 7.56,
                  with_refunds: 7.34,
                },
                gross_total_spread: {
                  without_refunds: 7.94,
                  with_refunds: 7.58,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 23.02,
                  with_refunds: 21.09,
                },
                gross_mrr: {
                  without_refunds: 24.31,
                  with_refunds: 21.82,
                },
                net_arr: {
                  without_refunds: 90.21,
                },
                gross_arr: {
                  without_refunds: 109.5,
                },
                net_arr_spread: {
                  without_refunds: 10.1,
                  with_refunds: 10.1,
                },
                gross_arr_spread: {
                  without_refunds: 10.57,
                  with_refunds: 10.57,
                },
                net_total: {
                  without_refunds: 9.94,
                  with_refunds: 8.45,
                },
                gross_total: {
                  without_refunds: 10.2,
                  with_refunds: 8.77,
                },
                net_total_spread: {
                  without_refunds: 11.76,
                  with_refunds: 11.44,
                },
                gross_total_spread: {
                  without_refunds: 12.35,
                  with_refunds: 11.82,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 62.99,
                    with_refunds: 58.11,
                  },
                  gross_mrr: {
                    without_refunds: 67.96,
                    with_refunds: 61.31,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.46,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 140.75,
                    with_refunds: 140.75,
                  },
                  gross_arr_spread: {
                    without_refunds: 147.29,
                    with_refunds: 147.29,
                  },
                  net_total: {
                    without_refunds: 107.86,
                    with_refunds: 95.88,
                  },
                  gross_total: {
                    without_refunds: 113.42,
                    with_refunds: 100.69,
                  },
                  net_total_spread: {
                    without_refunds: 113.86,
                    with_refunds: 109.37,
                  },
                  gross_total_spread: {
                    without_refunds: 120.72,
                    with_refunds: 114.05,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 197.44,
                    with_refunds: 181.58,
                  },
                  gross_mrr: {
                    without_refunds: 210.42,
                    with_refunds: 189.33,
                  },
                  net_arr: {
                    without_refunds: 957.38,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 1054,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 90.59,
                    with_refunds: 90.59,
                  },
                  gross_arr_spread: {
                    without_refunds: 94.84,
                    with_refunds: 94.84,
                  },
                  net_total: {
                    without_refunds: 209.5,
                    with_refunds: 173.74,
                  },
                  gross_total: {
                    without_refunds: 219.91,
                    with_refunds: 182.28,
                  },
                  net_total_spread: {
                    without_refunds: 119.15,
                    with_refunds: 114.69,
                  },
                  gross_total_spread: {
                    without_refunds: 126.21,
                    with_refunds: 119.49,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 9,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.25,
                  with_refunds: 0.25,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 16.36,
                  with_refunds: 16.36,
                },
                total_users: {
                  without_refunds: 0.07,
                  with_refunds: 0.07,
                },
                total_spread_users: {
                  without_refunds: 13.14,
                  with_refunds: 13.11,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0192,
                  with_refunds: 0.0191,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.636,
                  with_refunds: 1.636,
                },
                total_users: {
                  without_refunds: 0.0029,
                  with_refunds: 0.003,
                },
                total_spread_users: {
                  without_refunds: 0.5714,
                  with_refunds: 0.5699,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6633,
                  with_refunds: 0.6638,
                },
                arr_users: {
                  without_refunds: 0.2222,
                  with_refunds: 0.2222,
                },
                arr_spread_users: {
                  without_refunds: 1.0028,
                  with_refunds: 1.0028,
                },
                total_users: {
                  without_refunds: 0.66,
                  with_refunds: 0.6605,
                },
                total_spread_users: {
                  without_refunds: 0.8884,
                  with_refunds: 0.8884,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 5.75,
                  with_refunds: 5.26,
                },
                gross_mrr: {
                  without_refunds: 6.06,
                  with_refunds: 5.43,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 165.44,
                  with_refunds: 165.44,
                },
                gross_arr_spread: {
                  without_refunds: 173.05,
                  with_refunds: 173.05,
                },
                net_total: {
                  without_refunds: 0.67,
                  with_refunds: 0.59,
                },
                gross_total: {
                  without_refunds: 0.69,
                  with_refunds: 0.61,
                },
                net_total_spread: {
                  without_refunds: 149.35,
                  with_refunds: 145.4,
                },
                gross_total_spread: {
                  without_refunds: 156.76,
                  with_refunds: 150.1,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.024,
                  with_refunds: 0.0237,
                },
                gross_mrr: {
                  without_refunds: 0.0228,
                  with_refunds: 0.0226,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.681,
                  with_refunds: 1.681,
                },
                gross_arr_spread: {
                  without_refunds: 1.6613,
                  with_refunds: 1.6613,
                },
                net_total: {
                  without_refunds: 0.0004,
                  with_refunds: 0.0004,
                },
                gross_total: {
                  without_refunds: 0.0004,
                  with_refunds: 0.0004,
                },
                net_total_spread: {
                  without_refunds: 0.4416,
                  with_refunds: 0.4534,
                },
                gross_total_spread: {
                  without_refunds: 0.4241,
                  with_refunds: 0.4352,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6628,
                  with_refunds: 0.6655,
                },
                gross_mrr: {
                  without_refunds: 0.6616,
                  with_refunds: 0.6641,
                },
                net_arr: {
                  without_refunds: 0.2001,
                },
                gross_arr: {
                  without_refunds: 0.2041,
                },
                net_arr_spread: {
                  without_refunds: 1.0042,
                  with_refunds: 1.0042,
                },
                gross_arr_spread: {
                  without_refunds: 1.0038,
                  with_refunds: 1.0038,
                },
                net_total: {
                  without_refunds: 0.6316,
                  with_refunds: 0.6351,
                },
                gross_total: {
                  without_refunds: 0.6281,
                  with_refunds: 0.6317,
                },
                net_total_spread: {
                  without_refunds: 0.8585,
                  with_refunds: 0.8615,
                },
                gross_total_spread: {
                  without_refunds: 0.8582,
                  with_refunds: 0.8609,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.44,
                  with_refunds: 0.4,
                },
                gross_mrr: {
                  without_refunds: 0.47,
                  with_refunds: 0.42,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 16.54,
                  with_refunds: 16.54,
                },
                gross_arr_spread: {
                  without_refunds: 17.3,
                  with_refunds: 17.3,
                },
                net_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                net_total_spread: {
                  without_refunds: 6.49,
                  with_refunds: 6.32,
                },
                gross_total_spread: {
                  without_refunds: 6.82,
                  with_refunds: 6.53,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 23.01,
                  with_refunds: 21.15,
                },
                gross_mrr: {
                  without_refunds: 24.25,
                  with_refunds: 21.83,
                },
                net_arr: {
                  without_refunds: 81.24,
                },
                gross_arr: {
                  without_refunds: 100.55,
                },
                net_arr_spread: {
                  without_refunds: 10.11,
                  with_refunds: 10.11,
                },
                gross_arr_spread: {
                  without_refunds: 10.58,
                  with_refunds: 10.58,
                },
                net_total: {
                  without_refunds: 9.51,
                  with_refunds: 8.12,
                },
                gross_total: {
                  without_refunds: 9.71,
                  with_refunds: 8.39,
                },
                net_total_spread: {
                  without_refunds: 11.36,
                  with_refunds: 11.09,
                },
                gross_total_spread: {
                  without_refunds: 11.93,
                  with_refunds: 11.45,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 63.43,
                    with_refunds: 58.52,
                  },
                  gross_mrr: {
                    without_refunds: 68.42,
                    with_refunds: 61.73,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.46,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 157.29,
                    with_refunds: 157.29,
                  },
                  gross_arr_spread: {
                    without_refunds: 164.6,
                    with_refunds: 164.6,
                  },
                  net_total: {
                    without_refunds: 107.89,
                    with_refunds: 95.9,
                  },
                  gross_total: {
                    without_refunds: 113.45,
                    with_refunds: 100.71,
                  },
                  net_total_spread: {
                    without_refunds: 120.35,
                    with_refunds: 115.69,
                  },
                  gross_total_spread: {
                    without_refunds: 127.53,
                    with_refunds: 120.57,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 220.45,
                    with_refunds: 202.73,
                  },
                  gross_mrr: {
                    without_refunds: 234.67,
                    with_refunds: 211.15,
                  },
                  net_arr: {
                    without_refunds: 1038.62,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 1154.55,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 100.7,
                    with_refunds: 100.7,
                  },
                  gross_arr_spread: {
                    without_refunds: 105.42,
                    with_refunds: 105.42,
                  },
                  net_total: {
                    without_refunds: 219.01,
                    with_refunds: 181.86,
                  },
                  gross_total: {
                    without_refunds: 229.62,
                    with_refunds: 190.67,
                  },
                  net_total_spread: {
                    without_refunds: 130.51,
                    with_refunds: 125.78,
                  },
                  gross_total_spread: {
                    without_refunds: 138.14,
                    with_refunds: 130.94,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 10,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.18,
                  with_refunds: 0.18,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 16.45,
                  with_refunds: 16.45,
                },
                total_users: {
                  without_refunds: 0.05,
                  with_refunds: 0.05,
                },
                total_spread_users: {
                  without_refunds: 11.59,
                  with_refunds: 11.55,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0136,
                  with_refunds: 0.0135,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.6452,
                  with_refunds: 1.6452,
                },
                total_users: {
                  without_refunds: 0.0021,
                  with_refunds: 0.0022,
                },
                total_spread_users: {
                  without_refunds: 0.5038,
                  with_refunds: 0.5021,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.7065,
                  with_refunds: 0.7054,
                },
                arr_users: {
                  without_refunds: 0.6667,
                  with_refunds: 0.6667,
                },
                arr_spread_users: {
                  without_refunds: 1.0056,
                  with_refunds: 1.0056,
                },
                total_users: {
                  without_refunds: 0.7067,
                  with_refunds: 0.7056,
                },
                total_spread_users: {
                  without_refunds: 0.8818,
                  with_refunds: 0.8809,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 4.06,
                  with_refunds: 3.73,
                },
                gross_mrr: {
                  without_refunds: 4.27,
                  with_refunds: 3.84,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 166.75,
                  with_refunds: 166.75,
                },
                gross_arr_spread: {
                  without_refunds: 174.21,
                  with_refunds: 174.21,
                },
                net_total: {
                  without_refunds: 0.48,
                  with_refunds: 0.42,
                },
                gross_total: {
                  without_refunds: 0.48,
                  with_refunds: 0.44,
                },
                net_total_spread: {
                  without_refunds: 127.4,
                  with_refunds: 124.45,
                },
                gross_total_spread: {
                  without_refunds: 133.96,
                  with_refunds: 128.68,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0169,
                  with_refunds: 0.0168,
                },
                gross_mrr: {
                  without_refunds: 0.0161,
                  with_refunds: 0.016,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.6942,
                  with_refunds: 1.6942,
                },
                gross_arr_spread: {
                  without_refunds: 1.6724,
                  with_refunds: 1.6724,
                },
                net_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0003,
                },
                gross_total: {
                  without_refunds: 0.0003,
                  with_refunds: 0.0003,
                },
                net_total_spread: {
                  without_refunds: 0.3767,
                  with_refunds: 0.3881,
                },
                gross_total_spread: {
                  without_refunds: 0.3624,
                  with_refunds: 0.3731,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.7056,
                  with_refunds: 0.7088,
                },
                gross_mrr: {
                  without_refunds: 0.7043,
                  with_refunds: 0.7075,
                },
                net_arr: {
                  without_refunds: 0.6705,
                },
                gross_arr: {
                  without_refunds: 0.6582,
                },
                net_arr_spread: {
                  without_refunds: 1.0079,
                  with_refunds: 1.0079,
                },
                gross_arr_spread: {
                  without_refunds: 1.0067,
                  with_refunds: 1.0067,
                },
                net_total: {
                  without_refunds: 0.7081,
                  with_refunds: 0.7118,
                },
                gross_total: {
                  without_refunds: 0.7069,
                  with_refunds: 0.7103,
                },
                net_total_spread: {
                  without_refunds: 0.8531,
                  with_refunds: 0.8559,
                },
                gross_total_spread: {
                  without_refunds: 0.8546,
                  with_refunds: 0.8572,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.31,
                  with_refunds: 0.29,
                },
                gross_mrr: {
                  without_refunds: 0.33,
                  with_refunds: 0.3,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 16.67,
                  with_refunds: 16.67,
                },
                gross_arr_spread: {
                  without_refunds: 17.42,
                  with_refunds: 17.42,
                },
                net_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                gross_total: {
                  without_refunds: 0.02,
                  with_refunds: 0.02,
                },
                net_total_spread: {
                  without_refunds: 5.54,
                  with_refunds: 5.41,
                },
                gross_total_spread: {
                  without_refunds: 5.82,
                  with_refunds: 5.59,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 22.98,
                  with_refunds: 21.25,
                },
                gross_mrr: {
                  without_refunds: 24.18,
                  with_refunds: 21.89,
                },
                net_arr: {
                  without_refunds: 81.71,
                },
                gross_arr: {
                  without_refunds: 99.28,
                },
                net_arr_spread: {
                  without_refunds: 10.14,
                  with_refunds: 10.14,
                },
                gross_arr_spread: {
                  without_refunds: 10.59,
                  with_refunds: 10.59,
                },
                net_total: {
                  without_refunds: 9.53,
                  with_refunds: 8.2,
                },
                gross_total: {
                  without_refunds: 9.71,
                  with_refunds: 8.45,
                },
                net_total_spread: {
                  without_refunds: 10.99,
                  with_refunds: 10.78,
                },
                gross_total_spread: {
                  without_refunds: 11.56,
                  with_refunds: 11.14,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 63.74,
                    with_refunds: 58.81,
                  },
                  gross_mrr: {
                    without_refunds: 68.75,
                    with_refunds: 62.02,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.46,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 173.97,
                    with_refunds: 173.97,
                  },
                  gross_arr_spread: {
                    without_refunds: 182.02,
                    with_refunds: 182.02,
                  },
                  net_total: {
                    without_refunds: 107.91,
                    with_refunds: 95.92,
                  },
                  gross_total: {
                    without_refunds: 113.47,
                    with_refunds: 100.73,
                  },
                  net_total_spread: {
                    without_refunds: 125.89,
                    with_refunds: 121.1,
                  },
                  gross_total_spread: {
                    without_refunds: 133.36,
                    with_refunds: 126.17,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 243.43,
                    with_refunds: 223.98,
                  },
                  gross_mrr: {
                    without_refunds: 258.85,
                    with_refunds: 233.05,
                  },
                  net_arr: {
                    without_refunds: 1120.33,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 1253.83,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 110.83,
                    with_refunds: 110.83,
                  },
                  gross_arr_spread: {
                    without_refunds: 116.01,
                    with_refunds: 116.01,
                  },
                  net_total: {
                    without_refunds: 228.54,
                    with_refunds: 190.06,
                  },
                  gross_total: {
                    without_refunds: 239.33,
                    with_refunds: 199.12,
                  },
                  net_total_spread: {
                    without_refunds: 141.5,
                    with_refunds: 136.56,
                  },
                  gross_total_spread: {
                    without_refunds: 149.7,
                    with_refunds: 142.08,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 11,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.11,
                  with_refunds: 0.11,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 16.49,
                  with_refunds: 16.49,
                },
                total_users: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                total_spread_users: {
                  without_refunds: 8.47,
                  with_refunds: 8.44,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0082,
                  with_refunds: 0.0082,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 1.6486,
                  with_refunds: 1.6486,
                },
                total_users: {
                  without_refunds: 0.0013,
                  with_refunds: 0.0013,
                },
                total_spread_users: {
                  without_refunds: 0.3684,
                  with_refunds: 0.367,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.6062,
                  with_refunds: 0.606,
                },
                arr_users: {
                  without_refunds: 0.1667,
                  with_refunds: 0.1667,
                },
                arr_spread_users: {
                  without_refunds: 1.0021,
                  with_refunds: 1.0021,
                },
                total_users: {
                  without_refunds: 0.6041,
                  with_refunds: 0.6039,
                },
                total_spread_users: {
                  without_refunds: 0.7313,
                  with_refunds: 0.731,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 2.47,
                  with_refunds: 2.29,
                },
                gross_mrr: {
                  without_refunds: 2.61,
                  with_refunds: 2.36,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 167.23,
                  with_refunds: 167.23,
                },
                gross_arr_spread: {
                  without_refunds: 174.61,
                  with_refunds: 174.61,
                },
                net_total: {
                  without_refunds: 0.28,
                  with_refunds: 0.25,
                },
                gross_total: {
                  without_refunds: 0.28,
                  with_refunds: 0.26,
                },
                net_total_spread: {
                  without_refunds: 87.84,
                  with_refunds: 86.28,
                },
                gross_total_spread: {
                  without_refunds: 95.04,
                  with_refunds: 91.77,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0103,
                  with_refunds: 0.0103,
                },
                gross_mrr: {
                  without_refunds: 0.0098,
                  with_refunds: 0.0098,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 1.6992,
                  with_refunds: 1.6992,
                },
                gross_arr_spread: {
                  without_refunds: 1.6763,
                  with_refunds: 1.6763,
                },
                net_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0002,
                },
                gross_total: {
                  without_refunds: 0.0002,
                  with_refunds: 0.0002,
                },
                net_total_spread: {
                  without_refunds: 0.2598,
                  with_refunds: 0.2691,
                },
                gross_total_spread: {
                  without_refunds: 0.2571,
                  with_refunds: 0.2661,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.6095,
                  with_refunds: 0.6136,
                },
                gross_mrr: {
                  without_refunds: 0.611,
                  with_refunds: 0.6151,
                },
                net_arr: {
                  without_refunds: 0.155,
                },
                gross_arr: {
                  without_refunds: 0.138,
                },
                net_arr_spread: {
                  without_refunds: 1.0029,
                  with_refunds: 1.0029,
                },
                gross_arr_spread: {
                  without_refunds: 1.0023,
                  with_refunds: 1.0023,
                },
                net_total: {
                  without_refunds: 0.5896,
                  with_refunds: 0.6014,
                },
                gross_total: {
                  without_refunds: 0.5866,
                  with_refunds: 0.6008,
                },
                net_total_spread: {
                  without_refunds: 0.6895,
                  with_refunds: 0.6933,
                },
                gross_total_spread: {
                  without_refunds: 0.7095,
                  with_refunds: 0.7132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.19,
                  with_refunds: 0.18,
                },
                gross_mrr: {
                  without_refunds: 0.2,
                  with_refunds: 0.18,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 16.72,
                  with_refunds: 16.72,
                },
                gross_arr_spread: {
                  without_refunds: 17.46,
                  with_refunds: 17.46,
                },
                net_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                gross_total: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                net_total_spread: {
                  without_refunds: 3.82,
                  with_refunds: 3.75,
                },
                gross_total_spread: {
                  without_refunds: 4.13,
                  with_refunds: 3.99,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 23.11,
                  with_refunds: 21.52,
                },
                gross_mrr: {
                  without_refunds: 24.37,
                  with_refunds: 22.22,
                },
                net_arr: {
                  without_refunds: 75.99,
                },
                gross_arr: {
                  without_refunds: 82.2,
                },
                net_arr_spread: {
                  without_refunds: 10.14,
                  with_refunds: 10.14,
                },
                gross_arr_spread: {
                  without_refunds: 10.59,
                  with_refunds: 10.59,
                },
                net_total: {
                  without_refunds: 9.3,
                  with_refunds: 8.16,
                },
                gross_total: {
                  without_refunds: 9.43,
                  with_refunds: 8.4,
                },
                net_total_spread: {
                  without_refunds: 10.37,
                  with_refunds: 10.22,
                },
                gross_total_spread: {
                  without_refunds: 11.22,
                  with_refunds: 10.87,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 63.93,
                    with_refunds: 58.98,
                  },
                  gross_mrr: {
                    without_refunds: 68.95,
                    with_refunds: 62.2,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.46,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 190.69,
                    with_refunds: 190.69,
                  },
                  gross_arr_spread: {
                    without_refunds: 199.48,
                    with_refunds: 199.48,
                  },
                  net_total: {
                    without_refunds: 107.92,
                    with_refunds: 95.93,
                  },
                  gross_total: {
                    without_refunds: 113.48,
                    with_refunds: 100.74,
                  },
                  net_total_spread: {
                    without_refunds: 129.71,
                    with_refunds: 124.85,
                  },
                  gross_total_spread: {
                    without_refunds: 137.49,
                    with_refunds: 130.16,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 266.53,
                    with_refunds: 245.5,
                  },
                  gross_mrr: {
                    without_refunds: 283.22,
                    with_refunds: 255.27,
                  },
                  net_arr: {
                    without_refunds: 1196.32,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 1336.03,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 120.98,
                    with_refunds: 120.98,
                  },
                  gross_arr_spread: {
                    without_refunds: 126.6,
                    with_refunds: 126.6,
                  },
                  net_total: {
                    without_refunds: 237.85,
                    with_refunds: 198.22,
                  },
                  gross_total: {
                    without_refunds: 248.76,
                    with_refunds: 207.52,
                  },
                  net_total_spread: {
                    without_refunds: 151.87,
                    with_refunds: 146.78,
                  },
                  gross_total_spread: {
                    without_refunds: 160.91,
                    with_refunds: 152.95,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
        {
          period: 12,
          metrics: {
            user_retention: {
              base: {
                mrr_users: {
                  without_refunds: 0.03,
                  with_refunds: 0.03,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 5.61,
                  with_refunds: 5.61,
                },
                total_users: {
                  without_refunds: 0.01,
                  with_refunds: 0.01,
                },
                total_spread_users: {
                  without_refunds: 2.65,
                  with_refunds: 2.64,
                },
              },
              start_over_month: {
                mrr_users: {
                  without_refunds: 0.0025,
                  with_refunds: 0.0025,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.5609,
                  with_refunds: 0.5609,
                },
                total_users: {
                  without_refunds: 0.0004,
                  with_refunds: 0.0004,
                },
                total_spread_users: {
                  without_refunds: 0.1153,
                  with_refunds: 0.1147,
                },
              },
              month_over_month: {
                mrr_users: {
                  without_refunds: 0.3006,
                  with_refunds: 0.3,
                },
                arr_users: {},
                arr_spread_users: {
                  without_refunds: 0.3402,
                  with_refunds: 0.3402,
                },
                total_users: {
                  without_refunds: 0.3,
                  with_refunds: 0.2994,
                },
                total_spread_users: {
                  without_refunds: 0.3128,
                  with_refunds: 0.3124,
                },
              },
            },
            revenue: {
              base: {
                net_mrr: {
                  without_refunds: 0.74,
                  with_refunds: 0.69,
                },
                gross_mrr: {
                  without_refunds: 0.78,
                  with_refunds: 0.7,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 64.19,
                  with_refunds: 64.19,
                },
                gross_arr_spread: {
                  without_refunds: 62.68,
                  with_refunds: 62.68,
                },
                net_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.07,
                },
                gross_total: {
                  without_refunds: 0.08,
                  with_refunds: 0.08,
                },
                net_total_spread: {
                  without_refunds: 27.75,
                  with_refunds: 27.29,
                },
                gross_total_spread: {
                  without_refunds: 29.72,
                  with_refunds: 28.74,
                },
              },
              start_over_month: {
                net_mrr: {
                  without_refunds: 0.0031,
                  with_refunds: 0.0031,
                },
                gross_mrr: {
                  without_refunds: 0.0029,
                  with_refunds: 0.0029,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.6522,
                  with_refunds: 0.6522,
                },
                gross_arr_spread: {
                  without_refunds: 0.6017,
                  with_refunds: 0.6017,
                },
                net_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                gross_total: {
                  without_refunds: 0.0001,
                  with_refunds: 0.0001,
                },
                net_total_spread: {
                  without_refunds: 0.082,
                  with_refunds: 0.0851,
                },
                gross_total_spread: {
                  without_refunds: 0.0804,
                  with_refunds: 0.0833,
                },
              },
              month_over_month: {
                net_mrr: {
                  without_refunds: 0.2991,
                  with_refunds: 0.2996,
                },
                gross_mrr: {
                  without_refunds: 0.2973,
                  with_refunds: 0.2978,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 0.3839,
                  with_refunds: 0.3839,
                },
                gross_arr_spread: {
                  without_refunds: 0.3589,
                  with_refunds: 0.3589,
                },
                net_total: {
                  without_refunds: 0.294,
                  with_refunds: 0.2945,
                },
                gross_total: {
                  without_refunds: 0.2919,
                  with_refunds: 0.2924,
                },
                net_total_spread: {
                  without_refunds: 0.3158,
                  with_refunds: 0.3163,
                },
                gross_total_spread: {
                  without_refunds: 0.3127,
                  with_refunds: 0.3132,
                },
              },
            },
            arpu: {
              base: {
                net_mrr: {
                  without_refunds: 0.06,
                  with_refunds: 0.05,
                },
                gross_mrr: {
                  without_refunds: 0.06,
                  with_refunds: 0.05,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 6.42,
                  with_refunds: 6.42,
                },
                gross_arr_spread: {
                  without_refunds: 6.27,
                  with_refunds: 6.27,
                },
                net_total: {},
                gross_total: {},
                net_total_spread: {
                  without_refunds: 1.21,
                  with_refunds: 1.19,
                },
                gross_total_spread: {
                  without_refunds: 1.29,
                  with_refunds: 1.25,
                },
              },
            },
            arppu: {
              base: {
                net_mrr: {
                  without_refunds: 22.99,
                  with_refunds: 21.49,
                },
                gross_mrr: {
                  without_refunds: 24.1,
                  with_refunds: 22.06,
                },
                net_arr: {},
                gross_arr: {},
                net_arr_spread: {
                  without_refunds: 11.44,
                  with_refunds: 11.44,
                },
                gross_arr_spread: {
                  without_refunds: 11.17,
                  with_refunds: 11.17,
                },
                net_total: {
                  without_refunds: 9.12,
                  with_refunds: 8.03,
                },
                gross_total: {
                  without_refunds: 9.17,
                  with_refunds: 8.21,
                },
                net_total_spread: {
                  without_refunds: 10.47,
                  with_refunds: 10.35,
                },
                gross_total_spread: {
                  without_refunds: 11.21,
                  with_refunds: 10.9,
                },
              },
            },
            cumulative: {
              arpu: {
                base: {
                  net_mrr: {
                    without_refunds: 63.99,
                    with_refunds: 59.04,
                  },
                  gross_mrr: {
                    without_refunds: 69.01,
                    with_refunds: 62.26,
                  },
                  net_arr: {
                    without_refunds: 194.06,
                    with_refunds: 171.21,
                  },
                  gross_arr: {
                    without_refunds: 202.46,
                    with_refunds: 179.04,
                  },
                  net_arr_spread: {
                    without_refunds: 197.11,
                    with_refunds: 197.11,
                  },
                  gross_arr_spread: {
                    without_refunds: 205.75,
                    with_refunds: 205.75,
                  },
                  net_total: {
                    without_refunds: 107.93,
                    with_refunds: 95.94,
                  },
                  gross_total: {
                    without_refunds: 113.48,
                    with_refunds: 100.74,
                  },
                  net_total_spread: {
                    without_refunds: 130.92,
                    with_refunds: 126.04,
                  },
                  gross_total_spread: {
                    without_refunds: 138.78,
                    with_refunds: 131.41,
                  },
                },
              },
              arppu: {
                base: {
                  net_mrr: {
                    without_refunds: 289.52,
                    with_refunds: 266.99,
                  },
                  gross_mrr: {
                    without_refunds: 307.32,
                    with_refunds: 277.33,
                  },
                  net_arr: {
                    without_refunds: 1196.32,
                    with_refunds: 210.57,
                  },
                  gross_arr: {
                    without_refunds: 1336.03,
                    with_refunds: 219.35,
                  },
                  net_arr_spread: {
                    without_refunds: 132.42,
                    with_refunds: 132.42,
                  },
                  gross_arr_spread: {
                    without_refunds: 137.78,
                    with_refunds: 137.78,
                  },
                  net_total: {
                    without_refunds: 246.96,
                    with_refunds: 206.25,
                  },
                  gross_total: {
                    without_refunds: 257.93,
                    with_refunds: 215.73,
                  },
                  net_total_spread: {
                    without_refunds: 162.34,
                    with_refunds: 157.13,
                  },
                  gross_total_spread: {
                    without_refunds: 172.13,
                    with_refunds: 163.85,
                  },
                },
              },
            },
            roas: {
              arpu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
              arppu: {
                base: {
                  net_mrr: {},
                  gross_mrr: {},
                  net_arr: {},
                  gross_arr: {},
                  net_arr_spread: {},
                  gross_arr_spread: {},
                  net_total: {},
                  gross_total: {},
                  net_total_spread: {},
                  gross_total_spread: {},
                },
              },
            },
          },
          isPredicted: true,
        },
      ],
      metrics: {
        users: {
          mrr: {
            first_period_users: {
              without_refunds: 13,
              with_refunds: 13,
            },
            returning_users: {
              without_refunds: 1153,
              with_refunds: 1157,
            },
            paying_users: {
              without_refunds: 1166,
              with_refunds: 1170,
            },
          },
          arr: {
            first_period_users: {
              without_refunds: 11,
              with_refunds: 11,
            },
            returning_users: {
              without_refunds: 36,
              with_refunds: 48,
            },
            paying_users: {
              without_refunds: 47,
              with_refunds: 59,
            },
          },
          total: {
            first_period_users: {
              without_refunds: 24,
              with_refunds: 24,
            },
            returning_users: {
              without_refunds: 1189,
              with_refunds: 1205,
            },
            paying_users: {
              without_refunds: 1213,
              with_refunds: 1229,
            },
          },
        },
        revenue: {
          total: {
            actual: {
              net_mrr: {
                without_refunds: 239.75,
                with_refunds: 222.25,
              },
              gross_mrr: {
                without_refunds: 265.48,
                with_refunds: 240.71,
              },
              net_arr: {
                without_refunds: 1302.11,
                with_refunds: 1181.04,
              },
              gross_arr: {
                without_refunds: 1374.98,
                with_refunds: 1249.98,
              },
              net_arr_spread: {
                without_refunds: 98.42,
                with_refunds: 98.42,
              },
              gross_arr_spread: {
                without_refunds: 104.16,
                with_refunds: 104.16,
              },
              net_total: {
                without_refunds: 1541.86,
                with_refunds: 1403.29,
              },
              gross_total: {
                without_refunds: 1640.46,
                with_refunds: 1490.69,
              },
              net_total_spread: {
                without_refunds: 338.17,
                with_refunds: 320.67,
              },
              gross_total_spread: {
                without_refunds: 369.65,
                with_refunds: 344.87,
              },
            },
            predicted: {
              net_mrr: {
                without_refunds: 831.87,
                with_refunds: 767.46,
              },
              gross_mrr: {
                without_refunds: 897.15,
                with_refunds: 809.35,
              },
              net_arr: {
                without_refunds: 2134.68,
                with_refunds: 1883.34,
              },
              gross_arr: {
                without_refunds: 2227.01,
                with_refunds: 1969.42,
              },
              net_arr_spread: {
                without_refunds: 1971.08,
                with_refunds: 1971.08,
              },
              gross_arr_spread: {
                without_refunds: 2057.48,
                with_refunds: 2057.48,
              },
              net_total: {
                without_refunds: 2590.26,
                with_refunds: 2302.46,
              },
              gross_total: {
                without_refunds: 2723.56,
                with_refunds: 2417.85,
              },
              net_total_spread: {
                without_refunds: 3011.14,
                with_refunds: 2898.91,
              },
              gross_total_spread: {
                without_refunds: 3191.99,
                with_refunds: 3022.36,
              },
            },
          },
        },
      },
      mrr_ua_spend: {},
      arr_ua_spend: {},
      arr_spread_ua_spend: {},
      total_ua_spend: {},
      total_spread_ua_spend: {},
    },
  ],
  vectors: [
    {
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.63,
                with_refunds: 0.63,
              },
              arr_users: {
                without_refunds: 0.5267,
                with_refunds: 0.5267,
              },
              arr_spread_users: {
                without_refunds: 0.5341,
                with_refunds: 0.5341,
              },
              total_users: {
                without_refunds: 0.5965,
                with_refunds: 0.5965,
              },
              total_spread_users: {
                without_refunds: 0.6029,
                with_refunds: 0.6029,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.7129,
                with_refunds: 0.7129,
              },
              arr_users: {
                without_refunds: 0.6493,
                with_refunds: 0.6493,
              },
              arr_spread_users: {
                without_refunds: 0.6434,
                with_refunds: 0.6434,
              },
              total_users: {
                without_refunds: 0.6822,
                with_refunds: 0.6822,
              },
              total_spread_users: {
                without_refunds: 0.6813,
                with_refunds: 0.6813,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.6719,
                with_refunds: 0.6719,
              },
              arr_users: {
                without_refunds: 0.5299,
                with_refunds: 0.5299,
              },
              arr_spread_users: {
                without_refunds: 0.526,
                with_refunds: 0.526,
              },
              total_users: {
                without_refunds: 0.5763,
                with_refunds: 0.5763,
              },
              total_spread_users: {
                without_refunds: 0.5818,
                with_refunds: 0.5818,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              arr_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
              total_spread_users: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_mrr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_arr_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total: {
                without_refunds: 1,
                with_refunds: 1,
              },
              net_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
              gross_total_spread: {
                without_refunds: 1,
                with_refunds: 1,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {},
            gross_mrr: {},
            net_arr: {},
            gross_arr: {},
            net_arr_spread: {},
            gross_arr_spread: {},
            net_total: {},
            gross_total: {},
            net_total_spread: {},
            gross_total_spread: {},
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 7.19,
                with_refunds: 6.97,
              },
              gross_mrr: {
                without_refunds: 8.89,
                with_refunds: 8.62,
              },
              net_arr: {
                without_refunds: 55.52,
                with_refunds: 48.62,
              },
              gross_arr: {
                without_refunds: 63.03,
                with_refunds: 55.31,
              },
              net_arr_spread: {
                without_refunds: 4.63,
                with_refunds: 4.63,
              },
              gross_arr_spread: {
                without_refunds: 5.28,
                with_refunds: 5.28,
              },
              net_total: {
                without_refunds: 25.34,
                with_refunds: 22.62,
              },
              gross_total: {
                without_refunds: 29.22,
                with_refunds: 26.16,
              },
              net_total_spread: {
                without_refunds: 6.41,
                with_refunds: 6.26,
              },
              gross_total_spread: {
                without_refunds: 7.77,
                with_refunds: 7.59,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 13.62,
                with_refunds: 12.91,
              },
              gross_mrr: {
                without_refunds: 15.77,
                with_refunds: 14.96,
              },
              net_arr: {
                without_refunds: 87.63,
                with_refunds: 79.87,
              },
              gross_arr: {
                without_refunds: 94.22,
                with_refunds: 86.03,
              },
              net_arr_spread: {
                without_refunds: 7.22,
                with_refunds: 7.22,
              },
              gross_arr_spread: {
                without_refunds: 7.79,
                with_refunds: 7.79,
              },
              net_total: {
                without_refunds: 50.27,
                with_refunds: 46.07,
              },
              gross_total: {
                without_refunds: 54.63,
                with_refunds: 50.16,
              },
              net_total_spread: {
                without_refunds: 10.67,
                with_refunds: 10.28,
              },
              gross_total_spread: {
                without_refunds: 12.08,
                with_refunds: 11.65,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 9.52,
                with_refunds: 9.09,
              },
              gross_mrr: {
                without_refunds: 10.89,
                with_refunds: 10.41,
              },
              net_arr: {
                without_refunds: 64.83,
                with_refunds: 54.74,
              },
              gross_arr: {
                without_refunds: 72.16,
                with_refunds: 60.99,
              },
              net_arr_spread: {
                without_refunds: 5.34,
                with_refunds: 5.34,
              },
              gross_arr_spread: {
                without_refunds: 5.97,
                with_refunds: 5.97,
              },
              net_total: {
                without_refunds: 47.63,
                with_refunds: 40.55,
              },
              gross_total: {
                without_refunds: 53.1,
                with_refunds: 45.26,
              },
              net_total_spread: {
                without_refunds: 6.92,
                with_refunds: 6.76,
              },
              gross_total_spread: {
                without_refunds: 7.83,
                with_refunds: 7.65,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 9.71,
                with_refunds: 9.2,
              },
              gross_mrr: {
                without_refunds: 11.24,
                with_refunds: 10.66,
              },
              net_arr: {
                without_refunds: 56.9,
                with_refunds: 51.86,
              },
              gross_arr: {
                without_refunds: 61.18,
                with_refunds: 55.86,
              },
              net_arr_spread: {
                without_refunds: 4.65,
                with_refunds: 4.65,
              },
              gross_arr_spread: {
                without_refunds: 5.01,
                with_refunds: 5.01,
              },
              net_total: {
                without_refunds: 34.29,
                with_refunds: 31.43,
              },
              gross_total: {
                without_refunds: 37.26,
                with_refunds: 34.22,
              },
              net_total_spread: {
                without_refunds: 7.27,
                with_refunds: 7.01,
              },
              gross_total_spread: {
                without_refunds: 8.23,
                with_refunds: 7.93,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 6.39,
                with_refunds: 6.11,
              },
              gross_mrr: {
                without_refunds: 7.32,
                with_refunds: 6.99,
              },
              net_arr: {
                without_refunds: 34.36,
                with_refunds: 29.01,
              },
              gross_arr: {
                without_refunds: 38.24,
                with_refunds: 32.32,
              },
              net_arr_spread: {
                without_refunds: 2.81,
                with_refunds: 2.81,
              },
              gross_arr_spread: {
                without_refunds: 3.14,
                with_refunds: 3.14,
              },
              net_total: {
                without_refunds: 27.45,
                with_refunds: 23.37,
              },
              gross_total: {
                without_refunds: 30.6,
                with_refunds: 26.08,
              },
              net_total_spread: {
                without_refunds: 4.03,
                with_refunds: 3.93,
              },
              gross_total_spread: {
                without_refunds: 4.56,
                with_refunds: 4.45,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.47,
                with_refunds: 11.12,
              },
              gross_mrr: {
                without_refunds: 14.14,
                with_refunds: 13.71,
              },
              net_arr: {
                without_refunds: 105.14,
                with_refunds: 92.31,
              },
              gross_arr: {
                without_refunds: 119.14,
                with_refunds: 104.79,
              },
              net_arr_spread: {
                without_refunds: 8.68,
                with_refunds: 8.68,
              },
              gross_arr_spread: {
                without_refunds: 9.87,
                with_refunds: 9.87,
              },
              net_total: {
                without_refunds: 43.6,
                with_refunds: 38.94,
              },
              gross_total: {
                without_refunds: 50.15,
                with_refunds: 44.92,
              },
              net_total_spread: {
                without_refunds: 10.65,
                with_refunds: 10.41,
              },
              gross_total_spread: {
                without_refunds: 12.87,
                with_refunds: 12.57,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.17,
                with_refunds: 18.21,
              },
              gross_mrr: {
                without_refunds: 22.23,
                with_refunds: 21.13,
              },
              net_arr: {
                without_refunds: 134.96,
                with_refunds: 122.73,
              },
              gross_arr: {
                without_refunds: 145.26,
                with_refunds: 132.32,
              },
              net_arr_spread: {
                without_refunds: 11.22,
                with_refunds: 11.22,
              },
              gross_arr_spread: {
                without_refunds: 12.11,
                with_refunds: 12.11,
              },
              net_total: {
                without_refunds: 74.31,
                with_refunds: 68.02,
              },
              gross_total: {
                without_refunds: 80.82,
                with_refunds: 74.12,
              },
              net_total_spread: {
                without_refunds: 15.67,
                with_refunds: 15.14,
              },
              gross_total_spread: {
                without_refunds: 17.78,
                with_refunds: 17.17,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.12,
                with_refunds: 13.51,
              },
              gross_mrr: {
                without_refunds: 16.18,
                with_refunds: 15.49,
              },
              net_arr: {
                without_refunds: 121.11,
                with_refunds: 102.4,
              },
              gross_arr: {
                without_refunds: 135.05,
                with_refunds: 114.29,
              },
              net_arr_spread: {
                without_refunds: 10.06,
                with_refunds: 10.06,
              },
              gross_arr_spread: {
                without_refunds: 11.28,
                with_refunds: 11.28,
              },
              net_total: {
                without_refunds: 82.06,
                with_refunds: 69.87,
              },
              gross_total: {
                without_refunds: 91.59,
                with_refunds: 78.08,
              },
              net_total_spread: {
                without_refunds: 11.76,
                with_refunds: 11.5,
              },
              gross_total_spread: {
                without_refunds: 13.32,
                with_refunds: 13.03,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 9.71,
                with_refunds: 9.2,
              },
              gross_mrr: {
                without_refunds: 11.24,
                with_refunds: 10.66,
              },
              net_arr: {
                without_refunds: 56.9,
                with_refunds: 51.86,
              },
              gross_arr: {
                without_refunds: 61.18,
                with_refunds: 55.86,
              },
              net_arr_spread: {
                without_refunds: 4.65,
                with_refunds: 4.65,
              },
              gross_arr_spread: {
                without_refunds: 5.01,
                with_refunds: 5.01,
              },
              net_total: {
                without_refunds: 34.29,
                with_refunds: 31.43,
              },
              gross_total: {
                without_refunds: 37.26,
                with_refunds: 34.22,
              },
              net_total_spread: {
                without_refunds: 7.27,
                with_refunds: 7.01,
              },
              gross_total_spread: {
                without_refunds: 8.23,
                with_refunds: 7.93,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 6.39,
                with_refunds: 6.11,
              },
              gross_mrr: {
                without_refunds: 7.32,
                with_refunds: 6.99,
              },
              net_arr: {
                without_refunds: 34.36,
                with_refunds: 29.01,
              },
              gross_arr: {
                without_refunds: 38.24,
                with_refunds: 32.32,
              },
              net_arr_spread: {
                without_refunds: 2.81,
                with_refunds: 2.81,
              },
              gross_arr_spread: {
                without_refunds: 3.14,
                with_refunds: 3.14,
              },
              net_total: {
                without_refunds: 27.45,
                with_refunds: 23.37,
              },
              gross_total: {
                without_refunds: 30.6,
                with_refunds: 26.08,
              },
              net_total_spread: {
                without_refunds: 4.03,
                with_refunds: 3.93,
              },
              gross_total_spread: {
                without_refunds: 4.56,
                with_refunds: 4.45,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 7.19,
                  with_refunds: 6.97,
                },
                gross_mrr: {
                  without_refunds: 8.89,
                  with_refunds: 8.62,
                },
                net_arr: {
                  without_refunds: 55.52,
                  with_refunds: 48.62,
                },
                gross_arr: {
                  without_refunds: 63.03,
                  with_refunds: 55.31,
                },
                net_arr_spread: {
                  without_refunds: 4.63,
                  with_refunds: 4.63,
                },
                gross_arr_spread: {
                  without_refunds: 5.28,
                  with_refunds: 5.28,
                },
                net_total: {
                  without_refunds: 25.34,
                  with_refunds: 22.62,
                },
                gross_total: {
                  without_refunds: 29.22,
                  with_refunds: 26.16,
                },
                net_total_spread: {
                  without_refunds: 6.41,
                  with_refunds: 6.26,
                },
                gross_total_spread: {
                  without_refunds: 7.77,
                  with_refunds: 7.59,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 13.62,
                  with_refunds: 12.91,
                },
                gross_mrr: {
                  without_refunds: 15.77,
                  with_refunds: 14.96,
                },
                net_arr: {
                  without_refunds: 87.63,
                  with_refunds: 79.87,
                },
                gross_arr: {
                  without_refunds: 94.22,
                  with_refunds: 86.03,
                },
                net_arr_spread: {
                  without_refunds: 7.22,
                  with_refunds: 7.22,
                },
                gross_arr_spread: {
                  without_refunds: 7.79,
                  with_refunds: 7.79,
                },
                net_total: {
                  without_refunds: 50.27,
                  with_refunds: 46.07,
                },
                gross_total: {
                  without_refunds: 54.63,
                  with_refunds: 50.16,
                },
                net_total_spread: {
                  without_refunds: 10.67,
                  with_refunds: 10.28,
                },
                gross_total_spread: {
                  without_refunds: 12.08,
                  with_refunds: 11.65,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 9.52,
                  with_refunds: 9.09,
                },
                gross_mrr: {
                  without_refunds: 10.89,
                  with_refunds: 10.41,
                },
                net_arr: {
                  without_refunds: 64.83,
                  with_refunds: 54.74,
                },
                gross_arr: {
                  without_refunds: 72.16,
                  with_refunds: 60.99,
                },
                net_arr_spread: {
                  without_refunds: 5.34,
                  with_refunds: 5.34,
                },
                gross_arr_spread: {
                  without_refunds: 5.97,
                  with_refunds: 5.97,
                },
                net_total: {
                  without_refunds: 47.63,
                  with_refunds: 40.55,
                },
                gross_total: {
                  without_refunds: 53.1,
                  with_refunds: 45.26,
                },
                net_total_spread: {
                  without_refunds: 6.92,
                  with_refunds: 6.76,
                },
                gross_total_spread: {
                  without_refunds: 7.83,
                  with_refunds: 7.65,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 11.47,
                  with_refunds: 11.12,
                },
                gross_mrr: {
                  without_refunds: 14.14,
                  with_refunds: 13.71,
                },
                net_arr: {
                  without_refunds: 105.14,
                  with_refunds: 92.31,
                },
                gross_arr: {
                  without_refunds: 119.14,
                  with_refunds: 104.79,
                },
                net_arr_spread: {
                  without_refunds: 8.68,
                  with_refunds: 8.68,
                },
                gross_arr_spread: {
                  without_refunds: 9.87,
                  with_refunds: 9.87,
                },
                net_total: {
                  without_refunds: 43.6,
                  with_refunds: 38.94,
                },
                gross_total: {
                  without_refunds: 50.15,
                  with_refunds: 44.92,
                },
                net_total_spread: {
                  without_refunds: 10.65,
                  with_refunds: 10.41,
                },
                gross_total_spread: {
                  without_refunds: 12.87,
                  with_refunds: 12.57,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 19.17,
                  with_refunds: 18.21,
                },
                gross_mrr: {
                  without_refunds: 22.23,
                  with_refunds: 21.13,
                },
                net_arr: {
                  without_refunds: 134.96,
                  with_refunds: 122.73,
                },
                gross_arr: {
                  without_refunds: 145.26,
                  with_refunds: 132.32,
                },
                net_arr_spread: {
                  without_refunds: 11.22,
                  with_refunds: 11.22,
                },
                gross_arr_spread: {
                  without_refunds: 12.11,
                  with_refunds: 12.11,
                },
                net_total: {
                  without_refunds: 74.31,
                  with_refunds: 68.02,
                },
                gross_total: {
                  without_refunds: 80.82,
                  with_refunds: 74.12,
                },
                net_total_spread: {
                  without_refunds: 15.67,
                  with_refunds: 15.14,
                },
                gross_total_spread: {
                  without_refunds: 17.78,
                  with_refunds: 17.17,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 14.12,
                  with_refunds: 13.51,
                },
                gross_mrr: {
                  without_refunds: 16.18,
                  with_refunds: 15.49,
                },
                net_arr: {
                  without_refunds: 121.11,
                  with_refunds: 102.4,
                },
                gross_arr: {
                  without_refunds: 135.05,
                  with_refunds: 114.29,
                },
                net_arr_spread: {
                  without_refunds: 10.06,
                  with_refunds: 10.06,
                },
                gross_arr_spread: {
                  without_refunds: 11.28,
                  with_refunds: 11.28,
                },
                net_total: {
                  without_refunds: 82.06,
                  with_refunds: 69.87,
                },
                gross_total: {
                  without_refunds: 91.59,
                  with_refunds: 78.08,
                },
                net_total_spread: {
                  without_refunds: 11.76,
                  with_refunds: 11.5,
                },
                gross_total_spread: {
                  without_refunds: 13.32,
                  with_refunds: 13.03,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.1249,
                  with_refunds: 0.121,
                },
                gross_mrr: {
                  without_refunds: 0.1535,
                  with_refunds: 0.1487,
                },
                net_arr: {
                  without_refunds: 0.9526,
                  with_refunds: 0.8466,
                },
                gross_arr: {
                  without_refunds: 1.0746,
                  with_refunds: 0.9564,
                },
                net_arr_spread: {
                  without_refunds: 0.0814,
                  with_refunds: 0.0814,
                },
                gross_arr_spread: {
                  without_refunds: 0.0921,
                  with_refunds: 0.0921,
                },
                net_total: {
                  without_refunds: 0.4357,
                  with_refunds: 0.3936,
                },
                gross_total: {
                  without_refunds: 0.4995,
                  with_refunds: 0.4522,
                },
                net_total_spread: {
                  without_refunds: 0.1116,
                  with_refunds: 0.1089,
                },
                gross_total_spread: {
                  without_refunds: 0.1346,
                  with_refunds: 0.1313,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6789,
                  with_refunds: 0.6545,
                },
                gross_mrr: {
                  without_refunds: 0.7956,
                  with_refunds: 0.7654,
                },
                net_arr: {
                  without_refunds: 4.7117,
                  with_refunds: 4.2528,
                },
                gross_arr: {
                  without_refunds: 5.0866,
                  with_refunds: 4.5975,
                },
                net_arr_spread: {
                  without_refunds: 0.3838,
                  with_refunds: 0.3838,
                },
                gross_arr_spread: {
                  without_refunds: 0.4157,
                  with_refunds: 0.4157,
                },
                net_total: {
                  without_refunds: 2.6758,
                  with_refunds: 2.4364,
                },
                gross_total: {
                  without_refunds: 2.9206,
                  with_refunds: 2.6633,
                },
                net_total_spread: {
                  without_refunds: 0.5425,
                  with_refunds: 0.5295,
                },
                gross_total_spread: {
                  without_refunds: 0.62,
                  with_refunds: 0.6038,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.25,
                  with_refunds: 0.2409,
                },
                gross_mrr: {
                  without_refunds: 0.2905,
                  with_refunds: 0.2796,
                },
                net_arr: {
                  without_refunds: 1.1352,
                  with_refunds: 0.9894,
                },
                gross_arr: {
                  without_refunds: 1.2505,
                  with_refunds: 1.0904,
                },
                net_arr_spread: {
                  without_refunds: 0.0986,
                  with_refunds: 0.0986,
                },
                gross_arr_spread: {
                  without_refunds: 0.109,
                  with_refunds: 0.109,
                },
                net_total: {
                  without_refunds: 0.8603,
                  with_refunds: 0.7571,
                },
                gross_total: {
                  without_refunds: 0.9525,
                  with_refunds: 0.8388,
                },
                net_total_spread: {
                  without_refunds: 0.1555,
                  with_refunds: 0.1521,
                },
                gross_total_spread: {
                  without_refunds: 0.1772,
                  with_refunds: 0.1731,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.2045,
                  with_refunds: 0.1982,
                },
                gross_mrr: {
                  without_refunds: 0.2507,
                  with_refunds: 0.2428,
                },
                net_arr: {
                  without_refunds: 1.8191,
                  with_refunds: 1.6201,
                },
                gross_arr: {
                  without_refunds: 2.0472,
                  with_refunds: 1.8254,
                },
                net_arr_spread: {
                  without_refunds: 0.1536,
                  with_refunds: 0.1536,
                },
                gross_arr_spread: {
                  without_refunds: 0.1734,
                  with_refunds: 0.1734,
                },
                net_total: {
                  without_refunds: 0.7634,
                  with_refunds: 0.6903,
                },
                gross_total: {
                  without_refunds: 0.8729,
                  with_refunds: 0.7908,
                },
                net_total_spread: {
                  without_refunds: 0.1899,
                  with_refunds: 0.1854,
                },
                gross_total_spread: {
                  without_refunds: 0.228,
                  with_refunds: 0.2225,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.0792,
                  with_refunds: 1.0404,
                },
                gross_mrr: {
                  without_refunds: 1.2647,
                  with_refunds: 1.2167,
                },
                net_arr: {
                  without_refunds: 8.7438,
                  with_refunds: 7.8922,
                },
                gross_arr: {
                  without_refunds: 9.4396,
                  with_refunds: 8.5318,
                },
                net_arr_spread: {
                  without_refunds: 0.7171,
                  with_refunds: 0.7171,
                },
                gross_arr_spread: {
                  without_refunds: 0.7765,
                  with_refunds: 0.7765,
                },
                net_total: {
                  without_refunds: 4.6026,
                  with_refunds: 4.1909,
                },
                gross_total: {
                  without_refunds: 5.0237,
                  with_refunds: 4.5811,
                },
                net_total_spread: {
                  without_refunds: 0.9309,
                  with_refunds: 0.9084,
                },
                gross_total_spread: {
                  without_refunds: 1.0637,
                  with_refunds: 1.0359,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.3948,
                  with_refunds: 0.3806,
                },
                gross_mrr: {
                  without_refunds: 0.4591,
                  with_refunds: 0.442,
                },
                net_arr: {
                  without_refunds: 2.1758,
                  with_refunds: 1.8998,
                },
                gross_arr: {
                  without_refunds: 2.3996,
                  with_refunds: 2.0959,
                },
                net_arr_spread: {
                  without_refunds: 0.1896,
                  with_refunds: 0.1896,
                },
                gross_arr_spread: {
                  without_refunds: 0.2098,
                  with_refunds: 0.2098,
                },
                net_total: {
                  without_refunds: 1.5136,
                  with_refunds: 1.333,
                },
                gross_total: {
                  without_refunds: 1.6767,
                  with_refunds: 1.4777,
                },
                net_total_spread: {
                  without_refunds: 0.2719,
                  with_refunds: 0.2659,
                },
                gross_total_spread: {
                  without_refunds: 0.3098,
                  with_refunds: 0.3027,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 1,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.7386,
                with_refunds: 0.7427,
              },
              arr_users: {
                without_refunds: 0.4407,
                with_refunds: 0.4674,
              },
              arr_spread_users: {
                without_refunds: 0.9649,
                with_refunds: 0.9649,
              },
              total_users: {
                without_refunds: 0.6333,
                with_refunds: 0.6458,
              },
              total_spread_users: {
                without_refunds: 0.8179,
                with_refunds: 0.8207,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1.2336,
                with_refunds: 1.2405,
              },
              arr_users: {
                without_refunds: 0.8894,
                with_refunds: 0.9422,
              },
              arr_spread_users: {
                without_refunds: 1.8635,
                with_refunds: 1.8635,
              },
              total_users: {
                without_refunds: 1.1081,
                with_refunds: 1.1302,
              },
              total_spread_users: {
                without_refunds: 1.415,
                with_refunds: 1.4198,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.4803,
                with_refunds: 0.482,
              },
              arr_users: {
                without_refunds: 0.3581,
                with_refunds: 0.3789,
              },
              arr_spread_users: {
                without_refunds: 1.0076,
                with_refunds: 1.0076,
              },
              total_users: {
                without_refunds: 0.4187,
                with_refunds: 0.4299,
              },
              total_spread_users: {
                without_refunds: 0.727,
                with_refunds: 0.7279,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7195,
                with_refunds: 0.7215,
              },
              arr_users: {
                without_refunds: 0.6204,
                with_refunds: 0.6516,
              },
              arr_spread_users: {
                without_refunds: 1.6319,
                with_refunds: 1.6319,
              },
              total_users: {
                without_refunds: 0.6736,
                with_refunds: 0.6897,
              },
              total_spread_users: {
                without_refunds: 1.1272,
                with_refunds: 1.1283,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.6754,
                with_refunds: 0.6827,
              },
              arr_users: {
                without_refunds: 0.459,
                with_refunds: 0.4965,
              },
              arr_spread_users: {
                without_refunds: 0.9863,
                with_refunds: 0.9863,
              },
              total_users: {
                without_refunds: 0.5288,
                with_refunds: 0.5567,
              },
              total_spread_users: {
                without_refunds: 0.8735,
                with_refunds: 0.8762,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 1.0361,
                with_refunds: 1.047,
              },
              arr_users: {
                without_refunds: 0.9012,
                with_refunds: 0.9759,
              },
              arr_spread_users: {
                without_refunds: 1.9038,
                with_refunds: 1.9038,
              },
              total_users: {
                without_refunds: 0.9468,
                with_refunds: 0.9965,
              },
              total_spread_users: {
                without_refunds: 1.5281,
                with_refunds: 1.533,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1.2804,
                with_refunds: 1.272,
              },
              gross_mrr: {
                without_refunds: 1.2223,
                with_refunds: 1.2151,
              },
              net_arr: {
                without_refunds: 0.946,
                with_refunds: 0.8183,
              },
              gross_arr: {
                without_refunds: 0.924,
                with_refunds: 0.8011,
              },
              net_arr_spread: {
                without_refunds: 1.92,
                with_refunds: 1.92,
              },
              gross_arr_spread: {
                without_refunds: 1.8981,
                with_refunds: 1.8981,
              },
              net_total: {
                without_refunds: 1.0017,
                with_refunds: 0.9174,
              },
              gross_total: {
                without_refunds: 0.96,
                with_refunds: 0.8798,
              },
              net_total_spread: {
                without_refunds: 1.4292,
                with_refunds: 1.4259,
              },
              gross_total_spread: {
                without_refunds: 1.379,
                with_refunds: 1.3767,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1.2804,
                with_refunds: 1.272,
              },
              gross_mrr: {
                without_refunds: 1.2223,
                with_refunds: 1.2151,
              },
              net_arr: {
                without_refunds: 0.946,
                with_refunds: 0.8183,
              },
              gross_arr: {
                without_refunds: 0.924,
                with_refunds: 0.8011,
              },
              net_arr_spread: {
                without_refunds: 1.92,
                with_refunds: 1.92,
              },
              gross_arr_spread: {
                without_refunds: 1.8981,
                with_refunds: 1.8981,
              },
              net_total: {
                without_refunds: 1.0017,
                with_refunds: 0.9174,
              },
              gross_total: {
                without_refunds: 0.96,
                with_refunds: 0.8798,
              },
              net_total_spread: {
                without_refunds: 1.4292,
                with_refunds: 1.4259,
              },
              gross_total_spread: {
                without_refunds: 1.379,
                with_refunds: 1.3767,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.7207,
                with_refunds: 0.7259,
              },
              gross_mrr: {
                without_refunds: 0.6976,
                with_refunds: 0.7021,
              },
              net_arr: {
                without_refunds: 0.6556,
                with_refunds: 0.6319,
              },
              gross_arr: {
                without_refunds: 0.6367,
                with_refunds: 0.6139,
              },
              net_arr_spread: {
                without_refunds: 1.6709,
                with_refunds: 1.6709,
              },
              gross_arr_spread: {
                without_refunds: 1.6507,
                with_refunds: 1.6507,
              },
              net_total: {
                without_refunds: 0.6377,
                with_refunds: 0.6192,
              },
              gross_total: {
                without_refunds: 0.6202,
                with_refunds: 0.6025,
              },
              net_total_spread: {
                without_refunds: 1.0338,
                with_refunds: 1.0466,
              },
              gross_total_spread: {
                without_refunds: 0.9975,
                with_refunds: 1.0096,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7207,
                with_refunds: 0.7259,
              },
              gross_mrr: {
                without_refunds: 0.6976,
                with_refunds: 0.7021,
              },
              net_arr: {
                without_refunds: 0.6556,
                with_refunds: 0.6319,
              },
              gross_arr: {
                without_refunds: 0.6367,
                with_refunds: 0.6139,
              },
              net_arr_spread: {
                without_refunds: 1.6709,
                with_refunds: 1.6709,
              },
              gross_arr_spread: {
                without_refunds: 1.6507,
                with_refunds: 1.6507,
              },
              net_total: {
                without_refunds: 0.6377,
                with_refunds: 0.6192,
              },
              gross_total: {
                without_refunds: 0.6202,
                with_refunds: 0.6025,
              },
              net_total_spread: {
                without_refunds: 1.0338,
                with_refunds: 1.0466,
              },
              gross_total_spread: {
                without_refunds: 0.9975,
                with_refunds: 1.0096,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 1.0608,
                with_refunds: 1.0272,
              },
              gross_mrr: {
                without_refunds: 1.0571,
                with_refunds: 1.0265,
              },
              net_arr: {
                without_refunds: 1.0092,
                with_refunds: 0.8587,
              },
              gross_arr: {
                without_refunds: 0.9875,
                with_refunds: 0.8436,
              },
              net_arr_spread: {
                without_refunds: 2.0128,
                with_refunds: 2.0128,
              },
              gross_arr_spread: {
                without_refunds: 1.9877,
                with_refunds: 1.9877,
              },
              net_total: {
                without_refunds: 1.0248,
                with_refunds: 0.8723,
              },
              gross_total: {
                without_refunds: 1.0044,
                with_refunds: 0.8592,
              },
              net_total_spread: {
                without_refunds: 1.5371,
                with_refunds: 1.5308,
              },
              gross_total_spread: {
                without_refunds: 1.5218,
                with_refunds: 1.5164,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 1.0608,
                with_refunds: 1.0272,
              },
              gross_mrr: {
                without_refunds: 1.0571,
                with_refunds: 1.0265,
              },
              net_arr: {
                without_refunds: 1.0092,
                with_refunds: 0.8587,
              },
              gross_arr: {
                without_refunds: 0.9875,
                with_refunds: 0.8436,
              },
              net_arr_spread: {
                without_refunds: 2.0128,
                with_refunds: 2.0128,
              },
              gross_arr_spread: {
                without_refunds: 1.9877,
                with_refunds: 1.9877,
              },
              net_total: {
                without_refunds: 1.0248,
                with_refunds: 0.8723,
              },
              gross_total: {
                without_refunds: 1.0044,
                with_refunds: 0.8592,
              },
              net_total_spread: {
                without_refunds: 1.5371,
                with_refunds: 1.5308,
              },
              gross_total_spread: {
                without_refunds: 1.5218,
                with_refunds: 1.5164,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 7700.5198,
              with_refunds: 7564.2377,
            },
            gross_mrr: {
              without_refunds: 8635.5996,
              with_refunds: 8484.0386,
            },
            net_arr: {
              without_refunds: 1024.9413,
              with_refunds: 727.7302,
            },
            gross_arr: {
              without_refunds: 1066.774,
              with_refunds: 751.2078,
            },
            net_arr_spread: {
              without_refunds: 89264.7471,
              with_refunds: 89264.7471,
            },
            gross_arr_spread: {
              without_refunds: 99774.7894,
              with_refunds: 99774.7894,
            },
            net_total: {
              without_refunds: 7891.1636,
              with_refunds: 7531.2277,
            },
            gross_total: {
              without_refunds: 8762.5602,
              with_refunds: 8386.75,
            },
            net_total_spread: {
              without_refunds: 86392.9911,
              with_refunds: 86451.9317,
            },
            gross_total_spread: {
              without_refunds: 96733.8025,
              with_refunds: 96796.2041,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 7940.2691,
              with_refunds: 7786.4849,
            },
            gross_mrr: {
              without_refunds: 8901.0834,
              with_refunds: 8724.7443,
            },
            net_arr: {
              without_refunds: 2327.0513,
              with_refunds: 1908.7702,
            },
            gross_arr: {
              without_refunds: 2441.754,
              with_refunds: 2001.1878,
            },
            net_arr_spread: {
              without_refunds: 89363.1671,
              with_refunds: 89363.1671,
            },
            gross_arr_spread: {
              without_refunds: 99878.9544,
              with_refunds: 99878.9544,
            },
            net_total: {
              without_refunds: 9433.0229,
              with_refunds: 8934.5149,
            },
            gross_total: {
              without_refunds: 10403.024,
              with_refunds: 9877.4357,
            },
            net_total_spread: {
              without_refunds: 86731.1604,
              with_refunds: 86772.5989,
            },
            gross_total_spread: {
              without_refunds: 97103.4513,
              with_refunds: 97141.0747,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 8.75,
                with_refunds: 8.43,
              },
              gross_mrr: {
                without_refunds: 10.28,
                with_refunds: 9.91,
              },
              net_arr: {
                without_refunds: 49.86,
                with_refunds: 37.85,
              },
              gross_arr: {
                without_refunds: 54.87,
                with_refunds: 41.8,
              },
              net_arr_spread: {
                without_refunds: 8.67,
                with_refunds: 8.67,
              },
              gross_arr_spread: {
                without_refunds: 9.75,
                with_refunds: 9.75,
              },
              net_total: {
                without_refunds: 24.2,
                with_refunds: 19.51,
              },
              gross_total: {
                without_refunds: 27.05,
                with_refunds: 21.93,
              },
              net_total_spread: {
                without_refunds: 8.79,
                with_refunds: 8.57,
              },
              gross_total_spread: {
                without_refunds: 10.18,
                with_refunds: 9.93,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 9.32,
                with_refunds: 8.99,
              },
              gross_mrr: {
                without_refunds: 10.51,
                with_refunds: 10.11,
              },
              net_arr: {
                without_refunds: 50.82,
                with_refunds: 44.21,
              },
              gross_arr: {
                without_refunds: 53.21,
                with_refunds: 46.37,
              },
              net_arr_spread: {
                without_refunds: 11.53,
                with_refunds: 11.53,
              },
              gross_arr_spread: {
                without_refunds: 12.31,
                with_refunds: 12.31,
              },
              net_total: {
                without_refunds: 29.82,
                with_refunds: 26.38,
              },
              gross_total: {
                without_refunds: 31.6,
                with_refunds: 28.02,
              },
              net_total_spread: {
                without_refunds: 10.37,
                with_refunds: 10.19,
              },
              gross_total_spread: {
                without_refunds: 11.37,
                with_refunds: 11.15,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 9.45,
                with_refunds: 8.82,
              },
              gross_mrr: {
                without_refunds: 10.77,
                with_refunds: 10.08,
              },
              net_arr: {
                without_refunds: 61.5,
                with_refunds: 44.96,
              },
              gross_arr: {
                without_refunds: 67.21,
                with_refunds: 49.3,
              },
              net_arr_spread: {
                without_refunds: 10.48,
                with_refunds: 10.48,
              },
              gross_arr_spread: {
                without_refunds: 11.6,
                with_refunds: 11.6,
              },
              net_total: {
                without_refunds: 45.3,
                with_refunds: 33.72,
              },
              gross_total: {
                without_refunds: 49.65,
                with_refunds: 37.11,
              },
              net_total_spread: {
                without_refunds: 10.13,
                with_refunds: 9.9,
              },
              gross_total_spread: {
                without_refunds: 11.33,
                with_refunds: 11.07,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 16.25,
                with_refunds: 15.43,
              },
              gross_mrr: {
                without_refunds: 18.82,
                with_refunds: 17.87,
              },
              net_arr: {
                without_refunds: 88.28,
                with_refunds: 82.12,
              },
              gross_arr: {
                without_refunds: 94.92,
                with_refunds: 88.45,
              },
              net_arr_spread: {
                without_refunds: 11.93,
                with_refunds: 11.93,
              },
              gross_arr_spread: {
                without_refunds: 12.86,
                with_refunds: 12.86,
              },
              net_total: {
                without_refunds: 55.34,
                with_refunds: 51.23,
              },
              gross_total: {
                without_refunds: 60.13,
                with_refunds: 55.78,
              },
              net_total_spread: {
                without_refunds: 15.02,
                with_refunds: 14.49,
              },
              gross_total_spread: {
                without_refunds: 17.02,
                with_refunds: 16.41,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 12.82,
                with_refunds: 12.31,
              },
              gross_mrr: {
                without_refunds: 14.67,
                with_refunds: 14.1,
              },
              net_arr: {
                without_refunds: 64.11,
                with_refunds: 56.19,
              },
              gross_arr: {
                without_refunds: 71.36,
                with_refunds: 62.6,
              },
              net_arr_spread: {
                without_refunds: 8.08,
                with_refunds: 8.08,
              },
              gross_arr_spread: {
                without_refunds: 9.03,
                with_refunds: 9.03,
              },
              net_total: {
                without_refunds: 52.63,
                with_refunds: 45.94,
              },
              gross_total: {
                without_refunds: 58.68,
                with_refunds: 51.28,
              },
              net_total_spread: {
                without_refunds: 10.07,
                with_refunds: 9.85,
              },
              gross_total_spread: {
                without_refunds: 11.4,
                with_refunds: 11.15,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.89,
                with_refunds: 11.4,
              },
              gross_mrr: {
                without_refunds: 13.97,
                with_refunds: 13.4,
              },
              net_arr: {
                without_refunds: 112.18,
                with_refunds: 80.11,
              },
              gross_arr: {
                without_refunds: 124.17,
                with_refunds: 89.1,
              },
              net_arr_spread: {
                without_refunds: 8.95,
                with_refunds: 8.95,
              },
              gross_arr_spread: {
                without_refunds: 10.07,
                with_refunds: 10.07,
              },
              net_total: {
                without_refunds: 41.42,
                with_refunds: 32.03,
              },
              gross_total: {
                without_refunds: 46.13,
                with_refunds: 35.89,
              },
              net_total_spread: {
                without_refunds: 10.73,
                with_refunds: 10.43,
              },
              gross_total_spread: {
                without_refunds: 12.45,
                with_refunds: 12.1,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.04,
                with_refunds: 18.35,
              },
              gross_mrr: {
                without_refunds: 21.37,
                with_refunds: 20.54,
              },
              net_arr: {
                without_refunds: 141.57,
                with_refunds: 113.07,
              },
              gross_arr: {
                without_refunds: 148.35,
                with_refunds: 118.61,
              },
              net_arr_spread: {
                without_refunds: 11.46,
                with_refunds: 11.46,
              },
              gross_arr_spread: {
                without_refunds: 12.23,
                with_refunds: 12.23,
              },
              net_total: {
                without_refunds: 68.59,
                with_refunds: 58.34,
              },
              gross_total: {
                without_refunds: 72.72,
                with_refunds: 61.95,
              },
              net_total_spread: {
                without_refunds: 14.1,
                with_refunds: 13.86,
              },
              gross_total_spread: {
                without_refunds: 15.44,
                with_refunds: 15.15,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.34,
                with_refunds: 13.34,
              },
              gross_mrr: {
                without_refunds: 16.3,
                with_refunds: 15.18,
              },
              net_arr: {
                without_refunds: 135.1,
                with_refunds: 92.23,
              },
              gross_arr: {
                without_refunds: 147.37,
                with_refunds: 100.85,
              },
              net_arr_spread: {
                without_refunds: 10.62,
                with_refunds: 10.62,
              },
              gross_arr_spread: {
                without_refunds: 11.75,
                with_refunds: 11.75,
              },
              net_total: {
                without_refunds: 88.41,
                with_refunds: 62.95,
              },
              gross_total: {
                without_refunds: 96.67,
                with_refunds: 69.05,
              },
              net_total_spread: {
                without_refunds: 11.66,
                with_refunds: 11.35,
              },
              gross_total_spread: {
                without_refunds: 13.03,
                with_refunds: 12.69,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 16.25,
                with_refunds: 15.43,
              },
              gross_mrr: {
                without_refunds: 18.82,
                with_refunds: 17.87,
              },
              net_arr: {
                without_refunds: 88.28,
                with_refunds: 82.12,
              },
              gross_arr: {
                without_refunds: 94.92,
                with_refunds: 88.45,
              },
              net_arr_spread: {
                without_refunds: 11.93,
                with_refunds: 11.93,
              },
              gross_arr_spread: {
                without_refunds: 12.86,
                with_refunds: 12.86,
              },
              net_total: {
                without_refunds: 55.34,
                with_refunds: 51.23,
              },
              gross_total: {
                without_refunds: 60.13,
                with_refunds: 55.78,
              },
              net_total_spread: {
                without_refunds: 15.02,
                with_refunds: 14.49,
              },
              gross_total_spread: {
                without_refunds: 17.02,
                with_refunds: 16.41,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 12.82,
                with_refunds: 12.31,
              },
              gross_mrr: {
                without_refunds: 14.67,
                with_refunds: 14.1,
              },
              net_arr: {
                without_refunds: 64.11,
                with_refunds: 56.19,
              },
              gross_arr: {
                without_refunds: 71.36,
                with_refunds: 62.6,
              },
              net_arr_spread: {
                without_refunds: 8.08,
                with_refunds: 8.08,
              },
              gross_arr_spread: {
                without_refunds: 9.03,
                with_refunds: 9.03,
              },
              net_total: {
                without_refunds: 52.63,
                with_refunds: 45.94,
              },
              gross_total: {
                without_refunds: 58.68,
                with_refunds: 51.28,
              },
              net_total_spread: {
                without_refunds: 10.07,
                with_refunds: 9.85,
              },
              gross_total_spread: {
                without_refunds: 11.4,
                with_refunds: 11.15,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 15.94,
                  with_refunds: 15.4,
                },
                gross_mrr: {
                  without_refunds: 19.17,
                  with_refunds: 18.53,
                },
                net_arr: {
                  without_refunds: 105.38,
                  with_refunds: 86.48,
                },
                gross_arr: {
                  without_refunds: 117.9,
                  with_refunds: 97.11,
                },
                net_arr_spread: {
                  without_refunds: 13.31,
                  with_refunds: 13.31,
                },
                gross_arr_spread: {
                  without_refunds: 15.03,
                  with_refunds: 15.03,
                },
                net_total: {
                  without_refunds: 49.54,
                  with_refunds: 42.13,
                },
                gross_total: {
                  without_refunds: 56.28,
                  with_refunds: 48.1,
                },
                net_total_spread: {
                  without_refunds: 15.19,
                  with_refunds: 14.83,
                },
                gross_total_spread: {
                  without_refunds: 17.96,
                  with_refunds: 17.52,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 22.95,
                  with_refunds: 21.9,
                },
                gross_mrr: {
                  without_refunds: 26.28,
                  with_refunds: 25.07,
                },
                net_arr: {
                  without_refunds: 138.45,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 147.43,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 18.75,
                  with_refunds: 18.75,
                },
                gross_arr_spread: {
                  without_refunds: 20.1,
                  with_refunds: 20.1,
                },
                net_total: {
                  without_refunds: 80.09,
                  with_refunds: 72.46,
                },
                gross_total: {
                  without_refunds: 86.23,
                  with_refunds: 78.18,
                },
                net_total_spread: {
                  without_refunds: 21.03,
                  with_refunds: 20.47,
                },
                gross_total_spread: {
                  without_refunds: 23.45,
                  with_refunds: 22.8,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 18.97,
                  with_refunds: 17.91,
                },
                gross_mrr: {
                  without_refunds: 21.66,
                  with_refunds: 20.49,
                },
                net_arr: {
                  without_refunds: 126.33,
                  with_refunds: 99.71,
                },
                gross_arr: {
                  without_refunds: 139.37,
                  with_refunds: 110.28,
                },
                net_arr_spread: {
                  without_refunds: 15.82,
                  with_refunds: 15.82,
                },
                gross_arr_spread: {
                  without_refunds: 17.57,
                  with_refunds: 17.57,
                },
                net_total: {
                  without_refunds: 92.93,
                  with_refunds: 74.27,
                },
                gross_total: {
                  without_refunds: 102.76,
                  with_refunds: 82.37,
                },
                net_total_spread: {
                  without_refunds: 17.05,
                  with_refunds: 16.65,
                },
                gross_total_spread: {
                  without_refunds: 19.16,
                  with_refunds: 18.72,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 23.37,
                  with_refunds: 22.52,
                },
                gross_mrr: {
                  without_refunds: 28.11,
                  with_refunds: 27.11,
                },
                net_arr: {
                  without_refunds: 217.32,
                  with_refunds: 172.42,
                },
                gross_arr: {
                  without_refunds: 243.31,
                  with_refunds: 193.89,
                },
                net_arr_spread: {
                  without_refunds: 17.63,
                  with_refunds: 17.63,
                },
                gross_arr_spread: {
                  without_refunds: 19.95,
                  with_refunds: 19.95,
                },
                net_total: {
                  without_refunds: 85.02,
                  with_refunds: 70.97,
                },
                gross_total: {
                  without_refunds: 96.28,
                  with_refunds: 80.8,
                },
                net_total_spread: {
                  without_refunds: 21.38,
                  with_refunds: 20.83,
                },
                gross_total_spread: {
                  without_refunds: 25.32,
                  with_refunds: 24.67,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 38.21,
                  with_refunds: 36.56,
                },
                gross_mrr: {
                  without_refunds: 43.6,
                  with_refunds: 41.67,
                },
                net_arr: {
                  without_refunds: 276.53,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 293.6,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 22.68,
                  with_refunds: 22.68,
                },
                gross_arr_spread: {
                  without_refunds: 24.34,
                  with_refunds: 24.34,
                },
                net_total: {
                  without_refunds: 142.9,
                  with_refunds: 126.36,
                },
                gross_total: {
                  without_refunds: 153.54,
                  with_refunds: 136.08,
                },
                net_total_spread: {
                  without_refunds: 29.77,
                  with_refunds: 29,
                },
                gross_total_spread: {
                  without_refunds: 33.21,
                  with_refunds: 32.32,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 28.46,
                  with_refunds: 26.85,
                },
                gross_mrr: {
                  without_refunds: 32.48,
                  with_refunds: 30.67,
                },
                net_arr: {
                  without_refunds: 256.21,
                  with_refunds: 194.63,
                },
                gross_arr: {
                  without_refunds: 282.42,
                  with_refunds: 215.14,
                },
                net_arr_spread: {
                  without_refunds: 20.68,
                  with_refunds: 20.68,
                },
                gross_arr_spread: {
                  without_refunds: 23.03,
                  with_refunds: 23.03,
                },
                net_total: {
                  without_refunds: 170.46,
                  with_refunds: 132.82,
                },
                gross_total: {
                  without_refunds: 188.27,
                  with_refunds: 147.13,
                },
                net_total_spread: {
                  without_refunds: 23.42,
                  with_refunds: 22.86,
                },
                gross_total_spread: {
                  without_refunds: 26.35,
                  with_refunds: 25.72,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.2807,
                  with_refunds: 0.2709,
                },
                gross_mrr: {
                  without_refunds: 0.3363,
                  with_refunds: 0.3246,
                },
                net_arr: {
                  without_refunds: 1.8194,
                  with_refunds: 1.5282,
                },
                gross_arr: {
                  without_refunds: 2.0226,
                  with_refunds: 1.7034,
                },
                net_arr_spread: {
                  without_refunds: 0.2348,
                  with_refunds: 0.2348,
                },
                gross_arr_spread: {
                  without_refunds: 0.2633,
                  with_refunds: 0.2633,
                },
                net_total: {
                  without_refunds: 0.8589,
                  with_refunds: 0.7437,
                },
                gross_total: {
                  without_refunds: 0.9702,
                  with_refunds: 0.8434,
                },
                net_total_spread: {
                  without_refunds: 0.2677,
                  with_refunds: 0.2611,
                },
                gross_total_spread: {
                  without_refunds: 0.3148,
                  with_refunds: 0.3069,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.4016,
                  with_refunds: 1.3497,
                },
                gross_mrr: {
                  without_refunds: 1.6156,
                  with_refunds: 1.5528,
                },
                net_arr: {
                  without_refunds: 8.9364,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5116,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 1.1186,
                  with_refunds: 1.1186,
                },
                gross_arr_spread: {
                  without_refunds: 1.1996,
                  with_refunds: 1.1996,
                },
                net_total: {
                  without_refunds: 5.133,
                  with_refunds: 4.6199,
                },
                gross_total: {
                  without_refunds: 5.5264,
                  with_refunds: 4.9844,
                },
                net_total_spread: {
                  without_refunds: 1.2715,
                  with_refunds: 1.2436,
                },
                gross_total_spread: {
                  without_refunds: 1.4239,
                  with_refunds: 1.3902,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.5275,
                  with_refunds: 0.5037,
                },
                gross_mrr: {
                  without_refunds: 0.6066,
                  with_refunds: 0.579,
                },
                net_arr: {
                  without_refunds: 2.2505,
                  with_refunds: 1.8589,
                },
                gross_arr: {
                  without_refunds: 2.4512,
                  with_refunds: 2.0272,
                },
                net_arr_spread: {
                  without_refunds: 0.2943,
                  with_refunds: 0.2943,
                },
                gross_arr_spread: {
                  without_refunds: 0.3226,
                  with_refunds: 0.3226,
                },
                net_total: {
                  without_refunds: 1.7156,
                  with_refunds: 1.4386,
                },
                gross_total: {
                  without_refunds: 1.8787,
                  with_refunds: 1.5782,
                },
                net_total_spread: {
                  without_refunds: 0.3824,
                  with_refunds: 0.3735,
                },
                gross_total_spread: {
                  without_refunds: 0.4298,
                  with_refunds: 0.4194,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.4157,
                  with_refunds: 0.4003,
                },
                gross_mrr: {
                  without_refunds: 0.4983,
                  with_refunds: 0.48,
                },
                net_arr: {
                  without_refunds: 3.7445,
                  with_refunds: 3.0554,
                },
                gross_arr: {
                  without_refunds: 4.1662,
                  with_refunds: 3.4094,
                },
                net_arr_spread: {
                  without_refunds: 0.3115,
                  with_refunds: 0.3115,
                },
                gross_arr_spread: {
                  without_refunds: 0.3498,
                  with_refunds: 0.3498,
                },
                net_total: {
                  without_refunds: 1.4702,
                  with_refunds: 1.2572,
                },
                gross_total: {
                  without_refunds: 1.6566,
                  with_refunds: 1.4222,
                },
                net_total_spread: {
                  without_refunds: 0.3796,
                  with_refunds: 0.3697,
                },
                gross_total_spread: {
                  without_refunds: 0.4474,
                  with_refunds: 0.4355,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 2.1616,
                  with_refunds: 2.0817,
                },
                gross_mrr: {
                  without_refunds: 2.4929,
                  with_refunds: 2.3961,
                },
                net_arr: {
                  without_refunds: 18.0093,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 19.1445,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 1.4562,
                  with_refunds: 1.4562,
                },
                gross_arr_spread: {
                  without_refunds: 1.5651,
                  with_refunds: 1.5651,
                },
                net_total: {
                  without_refunds: 9.0194,
                  with_refunds: 8.0577,
                },
                gross_total: {
                  without_refunds: 9.7076,
                  with_refunds: 8.6917,
                },
                net_total_spread: {
                  without_refunds: 1.8061,
                  with_refunds: 1.7659,
                },
                gross_total_spread: {
                  without_refunds: 2.029,
                  with_refunds: 1.9802,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7937,
                  with_refunds: 0.7575,
                },
                gross_mrr: {
                  without_refunds: 0.9132,
                  with_refunds: 0.8711,
                },
                net_arr: {
                  without_refunds: 4.557,
                  with_refunds: 3.6639,
                },
                gross_arr: {
                  without_refunds: 4.9595,
                  with_refunds: 3.9927,
                },
                net_arr_spread: {
                  without_refunds: 0.3878,
                  with_refunds: 0.3878,
                },
                gross_arr_spread: {
                  without_refunds: 0.426,
                  with_refunds: 0.426,
                },
                net_total: {
                  without_refunds: 3.0763,
                  with_refunds: 2.5381,
                },
                gross_total: {
                  without_refunds: 3.367,
                  with_refunds: 2.783,
                },
                net_total_spread: {
                  without_refunds: 0.5353,
                  with_refunds: 0.5226,
                },
                gross_total_spread: {
                  without_refunds: 0.603,
                  with_refunds: 0.5881,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 2,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.5254,
                with_refunds: 0.5283,
              },
              arr_users: {
                without_refunds: 0.0136,
                with_refunds: 0.0255,
              },
              arr_spread_users: {
                without_refunds: 0.9759,
                with_refunds: 0.9759,
              },
              total_users: {
                without_refunds: 0.3379,
                with_refunds: 0.3441,
              },
              total_spread_users: {
                without_refunds: 0.6765,
                with_refunds: 0.6784,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.709,
                with_refunds: 0.7088,
              },
              arr_users: {
                without_refunds: 0.0343,
                with_refunds: 0.057,
              },
              arr_spread_users: {
                without_refunds: 1.0116,
                with_refunds: 1.0116,
              },
              total_users: {
                without_refunds: 0.5118,
                with_refunds: 0.5138,
              },
              total_spread_users: {
                without_refunds: 0.8246,
                with_refunds: 0.8241,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1278,
                with_refunds: 0.1303,
              },
              arr_users: {
                without_refunds: 0.0043,
                with_refunds: 0.0109,
              },
              arr_spread_users: {
                without_refunds: 1.0138,
                with_refunds: 1.0138,
              },
              total_users: {
                without_refunds: 0.0462,
                with_refunds: 0.0512,
              },
              total_spread_users: {
                without_refunds: 0.5398,
                with_refunds: 0.5414,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3425,
                with_refunds: 0.3446,
              },
              arr_users: {
                without_refunds: 0.0128,
                with_refunds: 0.0296,
              },
              arr_spread_users: {
                without_refunds: 1.0061,
                with_refunds: 1.0061,
              },
              total_users: {
                without_refunds: 0.1132,
                with_refunds: 0.1219,
              },
              total_spread_users: {
                without_refunds: 0.76,
                with_refunds: 0.7611,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.4182,
                with_refunds: 0.4253,
              },
              arr_users: {
                without_refunds: 0.0105,
                with_refunds: 0.0277,
              },
              arr_spread_users: {
                without_refunds: 0.9955,
                with_refunds: 0.9955,
              },
              total_users: {
                without_refunds: 0.1363,
                with_refunds: 0.1504,
              },
              total_spread_users: {
                without_refunds: 0.783,
                with_refunds: 0.7857,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6097,
                with_refunds: 0.6122,
              },
              arr_users: {
                without_refunds: 0.0222,
                with_refunds: 0.0529,
              },
              arr_spread_users: {
                without_refunds: 1.0094,
                with_refunds: 1.0094,
              },
              total_users: {
                without_refunds: 0.2382,
                with_refunds: 0.2498,
              },
              total_spread_users: {
                without_refunds: 0.8927,
                with_refunds: 0.893,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.9155,
                with_refunds: 0.9198,
              },
              gross_mrr: {
                without_refunds: 0.8571,
                with_refunds: 0.8617,
              },
              net_arr: {
                without_refunds: 0.0304,
                with_refunds: 0.007,
              },
              gross_arr: {
                without_refunds: 0.0331,
                with_refunds: 0.0088,
              },
              net_arr_spread: {
                without_refunds: 1.9436,
                with_refunds: 1.9436,
              },
              gross_arr_spread: {
                without_refunds: 1.9239,
                with_refunds: 1.9239,
              },
              net_total: {
                without_refunds: 0.2759,
                with_refunds: 0.2686,
              },
              gross_total: {
                without_refunds: 0.2561,
                with_refunds: 0.2482,
              },
              net_total_spread: {
                without_refunds: 1.1541,
                with_refunds: 1.1629,
              },
              gross_total_spread: {
                without_refunds: 1.102,
                with_refunds: 1.1111,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7137,
                with_refunds: 0.7225,
              },
              gross_mrr: {
                without_refunds: 0.7013,
                with_refunds: 0.71,
              },
              net_arr: {
                without_refunds: 0.0364,
                with_refunds: 0.0145,
              },
              gross_arr: {
                without_refunds: 0.0386,
                with_refunds: 0.0159,
              },
              net_arr_spread: {
                without_refunds: 1.0124,
                with_refunds: 1.0124,
              },
              gross_arr_spread: {
                without_refunds: 1.0135,
                with_refunds: 1.0135,
              },
              net_total: {
                without_refunds: 0.2871,
                with_refunds: 0.2974,
              },
              gross_total: {
                without_refunds: 0.2851,
                with_refunds: 0.2951,
              },
              net_total_spread: {
                without_refunds: 0.804,
                with_refunds: 0.8126,
              },
              gross_total_spread: {
                without_refunds: 0.7941,
                with_refunds: 0.8026,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.1795,
                with_refunds: 0.1786,
              },
              gross_mrr: {
                without_refunds: 0.1685,
                with_refunds: 0.1673,
              },
              net_arr: {
                without_refunds: 0.0061,
              },
              gross_arr: {
                without_refunds: 0.0068,
              },
              net_arr_spread: {
                without_refunds: 1.6791,
                with_refunds: 1.6791,
              },
              gross_arr_spread: {
                without_refunds: 1.6599,
                with_refunds: 1.6599,
              },
              net_total: {
                without_refunds: 0.0212,
                with_refunds: 0.0165,
              },
              gross_total: {
                without_refunds: 0.0217,
                with_refunds: 0.0163,
              },
              net_total_spread: {
                without_refunds: 0.65,
                with_refunds: 0.6642,
              },
              gross_total_spread: {
                without_refunds: 0.613,
                with_refunds: 0.6266,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.3503,
                with_refunds: 0.3444,
              },
              gross_mrr: {
                without_refunds: 0.3435,
                with_refunds: 0.3374,
              },
              net_arr: {
                without_refunds: 0.0104,
              },
              gross_arr: {
                without_refunds: 0.0117,
              },
              net_arr_spread: {
                without_refunds: 1.005,
                with_refunds: 1.005,
              },
              gross_arr_spread: {
                without_refunds: 1.0056,
                with_refunds: 1.0056,
              },
              net_total: {
                without_refunds: 0.0323,
                with_refunds: 0.0245,
              },
              gross_total: {
                without_refunds: 0.0339,
                with_refunds: 0.025,
              },
              net_total_spread: {
                without_refunds: 0.6785,
                with_refunds: 0.682,
              },
              gross_total_spread: {
                without_refunds: 0.6664,
                with_refunds: 0.6701,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.6476,
                with_refunds: 0.6485,
              },
              gross_mrr: {
                without_refunds: 0.6453,
                with_refunds: 0.6465,
              },
              net_arr: {
                without_refunds: 0.0226,
              },
              gross_arr: {
                without_refunds: 0.0242,
              },
              net_arr_spread: {
                without_refunds: 2.0317,
                with_refunds: 2.0317,
              },
              gross_arr_spread: {
                without_refunds: 2.0083,
                with_refunds: 2.0083,
              },
              net_total: {
                without_refunds: 0.068,
                with_refunds: 0.05,
              },
              gross_total: {
                without_refunds: 0.0699,
                with_refunds: 0.0505,
              },
              net_total_spread: {
                without_refunds: 1.3428,
                with_refunds: 1.3562,
              },
              gross_total_spread: {
                without_refunds: 1.3269,
                with_refunds: 1.34,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6088,
                with_refunds: 0.6263,
              },
              gross_mrr: {
                without_refunds: 0.6071,
                with_refunds: 0.6228,
              },
              net_arr: {
                without_refunds: 0.0208,
              },
              gross_arr: {
                without_refunds: 0.0226,
              },
              net_arr_spread: {
                without_refunds: 1.009,
                with_refunds: 1.009,
              },
              gross_arr_spread: {
                without_refunds: 1.0099,
                with_refunds: 1.0099,
              },
              net_total: {
                without_refunds: 0.0587,
                with_refunds: 0.0519,
              },
              gross_total: {
                without_refunds: 0.0615,
                with_refunds: 0.053,
              },
              net_total_spread: {
                without_refunds: 0.8644,
                with_refunds: 0.8759,
              },
              gross_total_spread: {
                without_refunds: 0.8615,
                with_refunds: 0.8724,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 12021.0173,
              with_refunds: 11793.4333,
            },
            gross_mrr: {
              without_refunds: 13482.7554,
              with_refunds: 13232.0147,
            },
            net_arr: {
              without_refunds: 1086.9267,
              with_refunds: 728.9146,
            },
            gross_arr: {
              without_refunds: 1136.2315,
              with_refunds: 752.8701,
            },
            net_arr_spread: {
              without_refunds: 174156.2294,
              with_refunds: 174156.2294,
            },
            gross_arr_spread: {
              without_refunds: 194349.7375,
              with_refunds: 194349.7375,
            },
            net_total: {
              without_refunds: 11628.7836,
              with_refunds: 11171.4922,
            },
            gross_total: {
              without_refunds: 12940.4679,
              with_refunds: 12474.4337,
            },
            net_total_spread: {
              without_refunds: 155911.452,
              with_refunds: 156192.9013,
            },
            gross_total_spread: {
              without_refunds: 174492.788,
              with_refunds: 174798.4412,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 12739.8828,
              with_refunds: 12460.7497,
            },
            gross_mrr: {
              without_refunds: 14270.8506,
              with_refunds: 13947.4295,
            },
            net_arr: {
              without_refunds: 4509.1071,
              with_refunds: 3793.2912,
            },
            gross_arr: {
              without_refunds: 4722.1941,
              with_refunds: 3972.2719,
            },
            net_arr_spread: {
              without_refunds: 174515.0435,
              with_refunds: 174515.0435,
            },
            gross_arr_spread: {
              without_refunds: 194727.2722,
              with_refunds: 194727.2722,
            },
            net_total: {
              without_refunds: 15699.2814,
              with_refunds: 14833.8514,
            },
            gross_total: {
              without_refunds: 17238.6531,
              with_refunds: 16336.9525,
            },
            net_total_spread: {
              without_refunds: 157004.7077,
              with_refunds: 157232.7568,
            },
            gross_total_spread: {
              without_refunds: 175675.4768,
              with_refunds: 175905.0478,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 6.19,
                with_refunds: 6.04,
              },
              gross_mrr: {
                without_refunds: 7.16,
                with_refunds: 6.99,
              },
              net_arr: {
                without_refunds: 1.5,
                with_refunds: 0.3,
              },
              gross_arr: {
                without_refunds: 1.86,
                with_refunds: 0.45,
              },
              net_arr_spread: {
                without_refunds: 8.77,
                with_refunds: 8.77,
              },
              gross_arr_spread: {
                without_refunds: 9.87,
                with_refunds: 9.87,
              },
              net_total: {
                without_refunds: 4.47,
                with_refunds: 3.93,
              },
              gross_total: {
                without_refunds: 5.22,
                with_refunds: 4.59,
              },
              net_total_spread: {
                without_refunds: 7.08,
                with_refunds: 6.98,
              },
              gross_total_spread: {
                without_refunds: 8.1,
                with_refunds: 7.98,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 2.46,
                with_refunds: 2.33,
              },
              gross_mrr: {
                without_refunds: 2.67,
                with_refunds: 2.52,
              },
              net_arr: {
                without_refunds: 0.48,
              },
              gross_arr: {
                without_refunds: 0.58,
              },
              net_arr_spread: {
                without_refunds: 11.59,
                with_refunds: 11.59,
              },
              gross_arr_spread: {
                without_refunds: 12.37,
                with_refunds: 12.37,
              },
              net_total: {
                without_refunds: 0.99,
                with_refunds: 0.69,
              },
              gross_total: {
                without_refunds: 1.1,
                with_refunds: 0.75,
              },
              net_total_spread: {
                without_refunds: 6.72,
                with_refunds: 6.65,
              },
              gross_total_spread: {
                without_refunds: 7.2,
                with_refunds: 7.11,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 5.51,
                with_refunds: 5.29,
              },
              gross_mrr: {
                without_refunds: 6.25,
                with_refunds: 6,
              },
              net_arr: {
                without_refunds: 1.29,
              },
              gross_arr: {
                without_refunds: 1.55,
              },
              net_arr_spread: {
                without_refunds: 10.57,
                with_refunds: 10.57,
              },
              gross_arr_spread: {
                without_refunds: 11.71,
                with_refunds: 11.71,
              },
              net_total: {
                without_refunds: 2.55,
                with_refunds: 1.6,
              },
              gross_total: {
                without_refunds: 2.96,
                with_refunds: 1.82,
              },
              net_total_spread: {
                without_refunds: 8.71,
                with_refunds: 8.63,
              },
              gross_total_spread: {
                without_refunds: 9.71,
                with_refunds: 9.62,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 18,
                with_refunds: 17.11,
              },
              gross_mrr: {
                without_refunds: 20.84,
                with_refunds: 19.82,
              },
              net_arr: {
                without_refunds: 88.66,
                with_refunds: 82.99,
              },
              gross_arr: {
                without_refunds: 95.33,
                with_refunds: 89.39,
              },
              net_arr_spread: {
                without_refunds: 19.25,
                with_refunds: 19.25,
              },
              gross_arr_spread: {
                without_refunds: 20.76,
                with_refunds: 20.76,
              },
              net_total: {
                without_refunds: 57.66,
                with_refunds: 53.59,
              },
              gross_total: {
                without_refunds: 62.66,
                with_refunds: 58.35,
              },
              net_total_spread: {
                without_refunds: 20.78,
                with_refunds: 20.06,
              },
              gross_total_spread: {
                without_refunds: 23.54,
                with_refunds: 22.72,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 16.8,
                with_refunds: 16.17,
              },
              gross_mrr: {
                without_refunds: 19.23,
                with_refunds: 18.52,
              },
              net_arr: {
                without_refunds: 64.8,
                with_refunds: 57.71,
              },
              gross_arr: {
                without_refunds: 72.12,
                with_refunds: 64.29,
              },
              net_arr_spread: {
                without_refunds: 13.4,
                with_refunds: 13.4,
              },
              gross_arr_spread: {
                without_refunds: 14.98,
                with_refunds: 14.98,
              },
              net_total: {
                without_refunds: 59.13,
                with_refunds: 52.04,
              },
              gross_total: {
                without_refunds: 65.92,
                with_refunds: 58.09,
              },
              net_total_spread: {
                without_refunds: 15.49,
                with_refunds: 15.17,
              },
              gross_total_spread: {
                without_refunds: 17.53,
                with_refunds: 17.16,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 11.95,
                with_refunds: 11.58,
              },
              gross_mrr: {
                without_refunds: 13.79,
                with_refunds: 13.38,
              },
              net_arr: {
                without_refunds: 113.12,
                with_refunds: 12.07,
              },
              gross_arr: {
                without_refunds: 138.17,
                with_refunds: 18.12,
              },
              net_arr_spread: {
                without_refunds: 8.96,
                with_refunds: 8.96,
              },
              gross_arr_spread: {
                without_refunds: 10.09,
                with_refunds: 10.09,
              },
              net_total: {
                without_refunds: 15.02,
                with_refunds: 11.3,
              },
              gross_total: {
                without_refunds: 17.41,
                with_refunds: 13.15,
              },
              net_total_spread: {
                without_refunds: 10.44,
                with_refunds: 10.26,
              },
              gross_total_spread: {
                without_refunds: 11.96,
                with_refunds: 11.76,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.08,
                with_refunds: 17.74,
              },
              gross_mrr: {
                without_refunds: 20.77,
                with_refunds: 19.26,
              },
              net_arr: {
                without_refunds: 113.63,
              },
              gross_arr: {
                without_refunds: 134.89,
              },
              net_arr_spread: {
                without_refunds: 11.44,
                with_refunds: 11.44,
              },
              gross_arr_spread: {
                without_refunds: 12.23,
                with_refunds: 12.23,
              },
              net_total: {
                without_refunds: 19.94,
                with_refunds: 12.21,
              },
              gross_total: {
                without_refunds: 22.2,
                with_refunds: 13.24,
              },
              net_total_spread: {
                without_refunds: 12.43,
                with_refunds: 12.27,
              },
              gross_total_spread: {
                without_refunds: 13.32,
                with_refunds: 13.14,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.26,
                with_refunds: 13.41,
              },
              gross_mrr: {
                without_refunds: 16.02,
                with_refunds: 15.07,
              },
              net_arr: {
                without_refunds: 124.21,
              },
              gross_arr: {
                without_refunds: 146.38,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.76,
                with_refunds: 11.76,
              },
              net_total: {
                without_refunds: 22.01,
                with_refunds: 10.32,
              },
              gross_total: {
                without_refunds: 25.08,
                with_refunds: 11.74,
              },
              net_total_spread: {
                without_refunds: 11.18,
                with_refunds: 11.03,
              },
              gross_total_spread: {
                without_refunds: 12.44,
                with_refunds: 12.27,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 18,
                with_refunds: 17.11,
              },
              gross_mrr: {
                without_refunds: 20.84,
                with_refunds: 19.82,
              },
              net_arr: {
                without_refunds: 88.66,
                with_refunds: 82.99,
              },
              gross_arr: {
                without_refunds: 95.33,
                with_refunds: 89.39,
              },
              net_arr_spread: {
                without_refunds: 19.25,
                with_refunds: 19.25,
              },
              gross_arr_spread: {
                without_refunds: 20.76,
                with_refunds: 20.76,
              },
              net_total: {
                without_refunds: 57.66,
                with_refunds: 53.59,
              },
              gross_total: {
                without_refunds: 62.66,
                with_refunds: 58.35,
              },
              net_total_spread: {
                without_refunds: 20.78,
                with_refunds: 20.06,
              },
              gross_total_spread: {
                without_refunds: 23.54,
                with_refunds: 22.72,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 16.8,
                with_refunds: 16.17,
              },
              gross_mrr: {
                without_refunds: 19.23,
                with_refunds: 18.52,
              },
              net_arr: {
                without_refunds: 64.8,
                with_refunds: 57.71,
              },
              gross_arr: {
                without_refunds: 72.12,
                with_refunds: 64.29,
              },
              net_arr_spread: {
                without_refunds: 13.4,
                with_refunds: 13.4,
              },
              gross_arr_spread: {
                without_refunds: 14.98,
                with_refunds: 14.98,
              },
              net_total: {
                without_refunds: 59.13,
                with_refunds: 52.04,
              },
              gross_total: {
                without_refunds: 65.92,
                with_refunds: 58.09,
              },
              net_total_spread: {
                without_refunds: 15.49,
                with_refunds: 15.17,
              },
              gross_total_spread: {
                without_refunds: 17.53,
                with_refunds: 17.16,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 22.12,
                  with_refunds: 21.44,
                },
                gross_mrr: {
                  without_refunds: 26.33,
                  with_refunds: 25.52,
                },
                net_arr: {
                  without_refunds: 106.87,
                  with_refunds: 86.77,
                },
                gross_arr: {
                  without_refunds: 119.75,
                  with_refunds: 97.56,
                },
                net_arr_spread: {
                  without_refunds: 22.08,
                  with_refunds: 22.08,
                },
                gross_arr_spread: {
                  without_refunds: 24.9,
                  with_refunds: 24.9,
                },
                net_total: {
                  without_refunds: 54.01,
                  with_refunds: 46.06,
                },
                gross_total: {
                  without_refunds: 61.5,
                  with_refunds: 52.68,
                },
                net_total_spread: {
                  without_refunds: 22.27,
                  with_refunds: 21.8,
                },
                gross_total_spread: {
                  without_refunds: 26.05,
                  with_refunds: 25.51,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 25.41,
                  with_refunds: 24.23,
                },
                gross_mrr: {
                  without_refunds: 28.95,
                  with_refunds: 27.59,
                },
                net_arr: {
                  without_refunds: 138.93,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.01,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 30.34,
                  with_refunds: 30.34,
                },
                gross_arr_spread: {
                  without_refunds: 32.47,
                  with_refunds: 32.47,
                },
                net_total: {
                  without_refunds: 81.08,
                  with_refunds: 73.15,
                },
                gross_total: {
                  without_refunds: 87.33,
                  with_refunds: 78.93,
                },
                net_total_spread: {
                  without_refunds: 27.75,
                  with_refunds: 27.12,
                },
                gross_total_spread: {
                  without_refunds: 30.64,
                  with_refunds: 29.91,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 24.47,
                  with_refunds: 23.2,
                },
                gross_mrr: {
                  without_refunds: 27.91,
                  with_refunds: 26.49,
                },
                net_arr: {
                  without_refunds: 127.62,
                  with_refunds: 99.71,
                },
                gross_arr: {
                  without_refunds: 140.92,
                  with_refunds: 110.28,
                },
                net_arr_spread: {
                  without_refunds: 26.39,
                  with_refunds: 26.39,
                },
                gross_arr_spread: {
                  without_refunds: 29.28,
                  with_refunds: 29.28,
                },
                net_total: {
                  without_refunds: 95.48,
                  with_refunds: 75.87,
                },
                gross_total: {
                  without_refunds: 105.71,
                  with_refunds: 84.18,
                },
                net_total_spread: {
                  without_refunds: 25.76,
                  with_refunds: 25.29,
                },
                gross_total_spread: {
                  without_refunds: 28.87,
                  with_refunds: 28.33,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 35.31,
                  with_refunds: 34.1,
                },
                gross_mrr: {
                  without_refunds: 41.91,
                  with_refunds: 40.48,
                },
                net_arr: {
                  without_refunds: 330.44,
                  with_refunds: 184.49,
                },
                gross_arr: {
                  without_refunds: 381.48,
                  with_refunds: 212.01,
                },
                net_arr_spread: {
                  without_refunds: 26.58,
                  with_refunds: 26.58,
                },
                gross_arr_spread: {
                  without_refunds: 30.04,
                  with_refunds: 30.04,
                },
                net_total: {
                  without_refunds: 100.03,
                  with_refunds: 82.27,
                },
                gross_total: {
                  without_refunds: 113.69,
                  with_refunds: 93.96,
                },
                net_total_spread: {
                  without_refunds: 31.82,
                  with_refunds: 31.1,
                },
                gross_total_spread: {
                  without_refunds: 37.28,
                  with_refunds: 36.43,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 57.3,
                  with_refunds: 54.29,
                },
                gross_mrr: {
                  without_refunds: 64.37,
                  with_refunds: 60.93,
                },
                net_arr: {
                  without_refunds: 390.16,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 428.5,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 34.12,
                  with_refunds: 34.12,
                },
                gross_arr_spread: {
                  without_refunds: 36.57,
                  with_refunds: 36.57,
                },
                net_total: {
                  without_refunds: 162.84,
                  with_refunds: 138.57,
                },
                gross_total: {
                  without_refunds: 175.73,
                  with_refunds: 149.31,
                },
                net_total_spread: {
                  without_refunds: 42.19,
                  with_refunds: 41.27,
                },
                gross_total_spread: {
                  without_refunds: 46.53,
                  with_refunds: 45.46,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 42.72,
                  with_refunds: 40.26,
                },
                gross_mrr: {
                  without_refunds: 48.5,
                  with_refunds: 45.75,
                },
                net_arr: {
                  without_refunds: 380.41,
                  with_refunds: 194.63,
                },
                gross_arr: {
                  without_refunds: 428.79,
                  with_refunds: 215.14,
                },
                net_arr_spread: {
                  without_refunds: 31.3,
                  with_refunds: 31.3,
                },
                gross_arr_spread: {
                  without_refunds: 34.79,
                  with_refunds: 34.79,
                },
                net_total: {
                  without_refunds: 192.47,
                  with_refunds: 143.14,
                },
                gross_total: {
                  without_refunds: 213.35,
                  with_refunds: 158.87,
                },
                net_total_spread: {
                  without_refunds: 34.6,
                  with_refunds: 33.89,
                },
                gross_total_spread: {
                  without_refunds: 38.79,
                  with_refunds: 37.99,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.3807,
                  with_refunds: 0.3683,
                },
                gross_mrr: {
                  without_refunds: 0.4517,
                  with_refunds: 0.4372,
                },
                net_arr: {
                  without_refunds: 1.8432,
                  with_refunds: 1.5332,
                },
                gross_arr: {
                  without_refunds: 2.0523,
                  with_refunds: 1.711,
                },
                net_arr_spread: {
                  without_refunds: 0.3899,
                  with_refunds: 0.3899,
                },
                gross_arr_spread: {
                  without_refunds: 0.4364,
                  with_refunds: 0.4364,
                },
                net_total: {
                  without_refunds: 0.9313,
                  with_refunds: 0.8075,
                },
                gross_total: {
                  without_refunds: 1.0546,
                  with_refunds: 0.9177,
                },
                net_total_spread: {
                  without_refunds: 0.3865,
                  with_refunds: 0.3781,
                },
                gross_total_spread: {
                  without_refunds: 0.45,
                  with_refunds: 0.4401,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.5293,
                  with_refunds: 1.4684,
                },
                gross_mrr: {
                  without_refunds: 1.7539,
                  with_refunds: 1.6812,
                },
                net_arr: {
                  without_refunds: 8.973,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5567,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 1.8568,
                  with_refunds: 1.8568,
                },
                gross_arr_spread: {
                  without_refunds: 1.9877,
                  with_refunds: 1.9877,
                },
                net_total: {
                  without_refunds: 5.2161,
                  with_refunds: 4.6803,
                },
                gross_total: {
                  without_refunds: 5.619,
                  with_refunds: 5.0497,
                },
                net_total_spread: {
                  without_refunds: 1.6826,
                  with_refunds: 1.6499,
                },
                gross_total_spread: {
                  without_refunds: 1.864,
                  with_refunds: 1.8249,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6251,
                  with_refunds: 0.5966,
                },
                gross_mrr: {
                  without_refunds: 0.7161,
                  with_refunds: 0.6834,
                },
                net_arr: {
                  without_refunds: 2.2698,
                  with_refunds: 1.8589,
                },
                gross_arr: {
                  without_refunds: 2.4744,
                  with_refunds: 2.0272,
                },
                net_arr_spread: {
                  without_refunds: 0.4916,
                  with_refunds: 0.4916,
                },
                gross_arr_spread: {
                  without_refunds: 0.538,
                  with_refunds: 0.538,
                },
                net_total: {
                  without_refunds: 1.7597,
                  with_refunds: 1.468,
                },
                gross_total: {
                  without_refunds: 1.9293,
                  with_refunds: 1.6112,
                },
                net_total_spread: {
                  without_refunds: 0.5431,
                  with_refunds: 0.5324,
                },
                gross_total_spread: {
                  without_refunds: 0.6063,
                  with_refunds: 0.594,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6264,
                  with_refunds: 0.6029,
                },
                gross_mrr: {
                  without_refunds: 0.7399,
                  with_refunds: 0.7124,
                },
                net_arr: {
                  without_refunds: 5.6299,
                  with_refunds: 3.2573,
                },
                gross_arr: {
                  without_refunds: 6.4923,
                  with_refunds: 3.7173,
                },
                net_arr_spread: {
                  without_refunds: 0.4695,
                  with_refunds: 0.4695,
                },
                gross_arr_spread: {
                  without_refunds: 0.5265,
                  with_refunds: 0.5265,
                },
                net_total: {
                  without_refunds: 1.7335,
                  with_refunds: 1.4589,
                },
                gross_total: {
                  without_refunds: 1.9604,
                  with_refunds: 1.6551,
                },
                net_total_spread: {
                  without_refunds: 0.56,
                  with_refunds: 0.5469,
                },
                gross_total_spread: {
                  without_refunds: 0.6527,
                  with_refunds: 0.6373,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 3.2111,
                  with_refunds: 3.0341,
                },
                gross_mrr: {
                  without_refunds: 3.63,
                  with_refunds: 3.4257,
                },
                net_arr: {
                  without_refunds: 25.0909,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 27.8374,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 2.1943,
                  with_refunds: 2.1943,
                },
                gross_arr_spread: {
                  without_refunds: 2.3532,
                  with_refunds: 2.3532,
                },
                net_total: {
                  without_refunds: 10.4089,
                  with_refunds: 8.9814,
                },
                gross_total: {
                  without_refunds: 11.2569,
                  with_refunds: 9.6903,
                },
                net_total_spread: {
                  without_refunds: 2.5356,
                  with_refunds: 2.4849,
                },
                gross_total_spread: {
                  without_refunds: 2.8097,
                  with_refunds: 2.7494,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.1821,
                  with_refunds: 1.115,
                },
                gross_mrr: {
                  without_refunds: 1.3414,
                  with_refunds: 1.265,
                },
                net_arr: {
                  without_refunds: 6.6078,
                  with_refunds: 3.6639,
                },
                gross_arr: {
                  without_refunds: 7.4172,
                  with_refunds: 3.9927,
                },
                net_arr_spread: {
                  without_refunds: 0.5858,
                  with_refunds: 0.5858,
                },
                gross_arr_spread: {
                  without_refunds: 0.6423,
                  with_refunds: 0.6423,
                },
                net_total: {
                  without_refunds: 3.5141,
                  with_refunds: 2.7836,
                },
                gross_total: {
                  without_refunds: 3.8614,
                  with_refunds: 3.0556,
                },
                net_total_spread: {
                  without_refunds: 0.7706,
                  with_refunds: 0.7545,
                },
                gross_total_spread: {
                  without_refunds: 0.8606,
                  with_refunds: 0.842,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 3,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.4046,
                with_refunds: 0.4062,
              },
              arr_users: {
                without_refunds: 0.0058,
                with_refunds: 0.0086,
              },
              arr_spread_users: {
                without_refunds: 0.9809,
                with_refunds: 0.9809,
              },
              total_users: {
                without_refunds: 0.2585,
                with_refunds: 0.2605,
              },
              total_spread_users: {
                without_refunds: 0.5962,
                with_refunds: 0.5972,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7665,
                with_refunds: 0.7654,
              },
              arr_users: {
                without_refunds: 0.4114,
                with_refunds: 0.3634,
              },
              arr_spread_users: {
                without_refunds: 1.0052,
                with_refunds: 1.0052,
              },
              total_users: {
                without_refunds: 0.7418,
                with_refunds: 0.728,
              },
              total_spread_users: {
                without_refunds: 0.8771,
                with_refunds: 0.8763,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0781,
                with_refunds: 0.0789,
              },
              arr_users: {
                without_refunds: 0.001,
                with_refunds: 0.0018,
              },
              arr_spread_users: {
                without_refunds: 1.0157,
                with_refunds: 1.0157,
              },
              total_users: {
                without_refunds: 0.0269,
                with_refunds: 0.0276,
              },
              total_spread_users: {
                without_refunds: 0.5198,
                with_refunds: 0.5203,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6108,
                with_refunds: 0.6054,
              },
              arr_users: {
                without_refunds: 0.2219,
                with_refunds: 0.1643,
              },
              arr_spread_users: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              total_users: {
                without_refunds: 0.5827,
                with_refunds: 0.539,
              },
              total_spread_users: {
                without_refunds: 0.9631,
                with_refunds: 0.961,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.3207,
                with_refunds: 0.3217,
              },
              arr_users: {
                without_refunds: 0.0027,
                with_refunds: 0.0047,
              },
              arr_spread_users: {
                without_refunds: 0.9974,
                with_refunds: 0.9974,
              },
              total_users: {
                without_refunds: 0.1013,
                with_refunds: 0.1029,
              },
              total_spread_users: {
                without_refunds: 0.7488,
                with_refunds: 0.7492,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.737,
                with_refunds: 0.7275,
              },
              arr_users: {
                without_refunds: 0.2313,
                with_refunds: 0.1636,
              },
              arr_spread_users: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              total_users: {
                without_refunds: 0.6692,
                with_refunds: 0.6179,
              },
              total_spread_users: {
                without_refunds: 0.9546,
                with_refunds: 0.9517,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.7081,
                with_refunds: 0.7134,
              },
              gross_mrr: {
                without_refunds: 0.6543,
                with_refunds: 0.6598,
              },
              net_arr: {
                without_refunds: 0.0131,
                with_refunds: 0.0057,
              },
              gross_arr: {
                without_refunds: 0.0144,
                with_refunds: 0.0067,
              },
              net_arr_spread: {
                without_refunds: 1.9547,
                with_refunds: 1.9547,
              },
              gross_arr_spread: {
                without_refunds: 1.9363,
                with_refunds: 1.9363,
              },
              net_total: {
                without_refunds: 0.2097,
                with_refunds: 0.2115,
              },
              gross_total: {
                without_refunds: 0.1905,
                with_refunds: 0.1918,
              },
              net_total_spread: {
                without_refunds: 0.9998,
                with_refunds: 1.0108,
              },
              gross_total_spread: {
                without_refunds: 0.95,
                with_refunds: 0.9611,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7728,
                with_refunds: 0.7743,
              },
              gross_mrr: {
                without_refunds: 0.7643,
                with_refunds: 0.7659,
              },
              net_arr: {
                without_refunds: 0.4188,
                with_refunds: 0.722,
              },
              gross_arr: {
                without_refunds: 0.4294,
                with_refunds: 0.4905,
              },
              net_arr_spread: {
                without_refunds: 1.006,
                with_refunds: 1.006,
              },
              gross_arr_spread: {
                without_refunds: 1.0066,
                with_refunds: 1.0066,
              },
              net_total: {
                without_refunds: 0.6982,
                with_refunds: 0.7613,
              },
              gross_total: {
                without_refunds: 0.6907,
                with_refunds: 0.7537,
              },
              net_total_spread: {
                without_refunds: 0.8631,
                with_refunds: 0.8653,
              },
              gross_total_spread: {
                without_refunds: 0.8572,
                with_refunds: 0.8594,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.113,
                with_refunds: 0.1108,
              },
              gross_mrr: {
                without_refunds: 0.1058,
                with_refunds: 0.1037,
              },
              net_arr: {
                without_refunds: 0.0012,
              },
              gross_arr: {
                without_refunds: 0.0014,
              },
              net_arr_spread: {
                without_refunds: 1.6817,
                with_refunds: 1.6817,
              },
              gross_arr_spread: {
                without_refunds: 1.6629,
                with_refunds: 1.6629,
              },
              net_total: {
                without_refunds: 0.0095,
                with_refunds: 0.0102,
              },
              gross_total: {
                without_refunds: 0.0097,
                with_refunds: 0.0102,
              },
              net_total_spread: {
                without_refunds: 0.6232,
                with_refunds: 0.6388,
              },
              gross_total_spread: {
                without_refunds: 0.5869,
                with_refunds: 0.6018,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6296,
                with_refunds: 0.6202,
              },
              gross_mrr: {
                without_refunds: 0.6281,
                with_refunds: 0.6198,
              },
              net_arr: {
                without_refunds: 0.1999,
              },
              gross_arr: {
                without_refunds: 0.2096,
              },
              net_arr_spread: {
                without_refunds: 1.0016,
                with_refunds: 1.0016,
              },
              gross_arr_spread: {
                without_refunds: 1.0018,
                with_refunds: 1.0018,
              },
              net_total: {
                without_refunds: 0.4488,
                with_refunds: 0.6202,
              },
              gross_total: {
                without_refunds: 0.4468,
                with_refunds: 0.6244,
              },
              net_total_spread: {
                without_refunds: 0.9587,
                with_refunds: 0.9618,
              },
              gross_total_spread: {
                without_refunds: 0.9574,
                with_refunds: 0.9604,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.5028,
                with_refunds: 0.5102,
              },
              gross_mrr: {
                without_refunds: 0.5012,
                with_refunds: 0.5087,
              },
              net_arr: {
                without_refunds: 0.0055,
              },
              gross_arr: {
                without_refunds: 0.0063,
                with_refunds: 0.0005,
              },
              net_arr_spread: {
                without_refunds: 2.035,
                with_refunds: 2.035,
              },
              gross_arr_spread: {
                without_refunds: 2.0123,
                with_refunds: 2.0123,
              },
              net_total: {
                without_refunds: 0.0416,
                with_refunds: 0.0399,
              },
              gross_total: {
                without_refunds: 0.0426,
                with_refunds: 0.0406,
              },
              net_total_spread: {
                without_refunds: 1.2777,
                with_refunds: 1.2962,
              },
              gross_total_spread: {
                without_refunds: 1.2613,
                with_refunds: 1.2791,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7521,
                with_refunds: 0.7596,
              },
              gross_mrr: {
                without_refunds: 0.7503,
                with_refunds: 0.7575,
              },
              net_arr: {
                without_refunds: 0.2065,
              },
              gross_arr: {
                without_refunds: 0.2186,
                with_refunds: 0.0005,
              },
              net_arr_spread: {
                without_refunds: 1.0016,
                with_refunds: 1.0016,
              },
              gross_arr_spread: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              net_total: {
                without_refunds: 0.5208,
                with_refunds: 0.7185,
              },
              gross_total: {
                without_refunds: 0.5179,
                with_refunds: 0.7225,
              },
              net_total_spread: {
                without_refunds: 0.9493,
                with_refunds: 0.9535,
              },
              gross_total_spread: {
                without_refunds: 0.9484,
                with_refunds: 0.9524,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 14431.7324,
              with_refunds: 14146.692,
            },
            gross_mrr: {
              without_refunds: 16183.3704,
              with_refunds: 15870.867,
            },
            net_arr: {
              without_refunds: 1102.0317,
              with_refunds: 729.3035,
            },
            gross_arr: {
              without_refunds: 1153.3017,
              with_refunds: 753.4159,
            },
            net_arr_spread: {
              without_refunds: 250487.9101,
              with_refunds: 250487.9101,
            },
            gross_arr_spread: {
              without_refunds: 279229.2316,
              with_refunds: 279229.2316,
            },
            net_total: {
              without_refunds: 13623.6214,
              with_refunds: 13123.0411,
            },
            gross_total: {
              without_refunds: 15157.6161,
              with_refunds: 14656.978,
            },
            net_total_spread: {
              without_refunds: 209490.051,
              with_refunds: 210043.3002,
            },
            gross_total_spread: {
              without_refunds: 234362.9014,
              with_refunds: 234967.5983,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 15764.2922,
              with_refunds: 15383.0574,
            },
            gross_mrr: {
              without_refunds: 17637.2737,
              with_refunds: 17189.7704,
            },
            net_arr: {
              without_refunds: 6655.3944,
              with_refunds: 5677.0166,
            },
            gross_arr: {
              without_refunds: 6962.384,
              with_refunds: 5942.2396,
            },
            net_arr_spread: {
              without_refunds: 251270.0054,
              with_refunds: 251270.0054,
            },
            gross_arr_spread: {
              without_refunds: 280050.3604,
              with_refunds: 280050.3604,
            },
            net_total: {
              without_refunds: 20252.458,
              with_refunds: 19061.4765,
            },
            gross_total: {
              without_refunds: 22145.5834,
              with_refunds: 20909.3895,
            },
            net_total_spread: {
              without_refunds: 211690.5651,
              with_refunds: 212139.5113,
            },
            gross_total_spread: {
              without_refunds: 236730.699,
              with_refunds: 237186.3075,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 4.79,
                with_refunds: 4.69,
              },
              gross_mrr: {
                without_refunds: 5.48,
                with_refunds: 5.37,
              },
              net_arr: {
                without_refunds: 0.63,
                with_refunds: 0.24,
              },
              gross_arr: {
                without_refunds: 0.81,
                with_refunds: 0.34,
              },
              net_arr_spread: {
                without_refunds: 8.82,
                with_refunds: 8.82,
              },
              gross_arr_spread: {
                without_refunds: 9.93,
                with_refunds: 9.93,
              },
              net_total: {
                without_refunds: 3.27,
                with_refunds: 3.06,
              },
              gross_total: {
                without_refunds: 3.77,
                with_refunds: 3.52,
              },
              net_total_spread: {
                without_refunds: 6.15,
                with_refunds: 6.08,
              },
              gross_total_spread: {
                without_refunds: 6.99,
                with_refunds: 6.91,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 1.55,
                with_refunds: 1.44,
              },
              gross_mrr: {
                without_refunds: 1.68,
                with_refunds: 1.56,
              },
              net_arr: {
                without_refunds: 0.1,
              },
              gross_arr: {
                without_refunds: 0.12,
              },
              net_arr_spread: {
                without_refunds: 11.61,
                with_refunds: 11.61,
              },
              gross_arr_spread: {
                without_refunds: 12.4,
                with_refunds: 12.4,
              },
              net_total: {
                without_refunds: 0.44,
                with_refunds: 0.43,
              },
              gross_total: {
                without_refunds: 0.49,
                with_refunds: 0.47,
              },
              net_total_spread: {
                without_refunds: 6.44,
                with_refunds: 6.39,
              },
              gross_total_spread: {
                without_refunds: 6.89,
                with_refunds: 6.83,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 4.26,
                with_refunds: 4.15,
              },
              gross_mrr: {
                without_refunds: 4.83,
                with_refunds: 4.7,
              },
              net_arr: {
                without_refunds: 0.3,
              },
              gross_arr: {
                without_refunds: 0.38,
                with_refunds: 0.02,
              },
              net_arr_spread: {
                without_refunds: 10.59,
                with_refunds: 10.59,
              },
              gross_arr_spread: {
                without_refunds: 11.73,
                with_refunds: 11.73,
              },
              net_total: {
                without_refunds: 1.49,
                with_refunds: 1.26,
              },
              gross_total: {
                without_refunds: 1.72,
                with_refunds: 1.45,
              },
              net_total_spread: {
                without_refunds: 8.29,
                with_refunds: 8.25,
              },
              gross_total_spread: {
                without_refunds: 9.23,
                with_refunds: 9.18,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.06,
                with_refunds: 18.13,
              },
              gross_mrr: {
                without_refunds: 22.07,
                with_refunds: 21,
              },
              net_arr: {
                without_refunds: 88.75,
                with_refunds: 83.13,
              },
              gross_arr: {
                without_refunds: 95.42,
                with_refunds: 89.54,
              },
              net_arr_spread: {
                without_refunds: 26.59,
                with_refunds: 26.59,
              },
              gross_arr_spread: {
                without_refunds: 28.68,
                with_refunds: 28.68,
              },
              net_total: {
                without_refunds: 59.02,
                with_refunds: 54.86,
              },
              gross_total: {
                without_refunds: 64.13,
                with_refunds: 59.73,
              },
              net_total_spread: {
                without_refunds: 26.32,
                with_refunds: 25.41,
              },
              gross_total_spread: {
                without_refunds: 29.82,
                with_refunds: 28.78,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 19.85,
                with_refunds: 19.1,
              },
              gross_mrr: {
                without_refunds: 22.72,
                with_refunds: 21.87,
              },
              net_arr: {
                without_refunds: 64.97,
                with_refunds: 57.96,
              },
              gross_arr: {
                without_refunds: 72.31,
                with_refunds: 64.57,
              },
              net_arr_spread: {
                without_refunds: 18.73,
                with_refunds: 18.73,
              },
              gross_arr_spread: {
                without_refunds: 20.94,
                with_refunds: 20.94,
              },
              net_total: {
                without_refunds: 63.95,
                with_refunds: 56.21,
              },
              gross_total: {
                without_refunds: 71.3,
                with_refunds: 62.75,
              },
              net_total_spread: {
                without_refunds: 20.67,
                with_refunds: 20.23,
              },
              gross_total_spread: {
                without_refunds: 23.39,
                with_refunds: 22.89,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.05,
                with_refunds: 11.71,
              },
              gross_mrr: {
                without_refunds: 13.76,
                with_refunds: 13.38,
              },
              net_arr: {
                without_refunds: 109.8,
                with_refunds: 19.42,
              },
              gross_arr: {
                without_refunds: 138.86,
                with_refunds: 28.06,
              },
              net_arr_spread: {
                without_refunds: 8.96,
                with_refunds: 8.96,
              },
              gross_arr_spread: {
                without_refunds: 10.1,
                with_refunds: 10.1,
              },
              net_total: {
                without_refunds: 13.73,
                with_refunds: 11.8,
              },
              gross_total: {
                without_refunds: 15.79,
                with_refunds: 13.59,
              },
              net_total_spread: {
                without_refunds: 10.27,
                with_refunds: 10.13,
              },
              gross_total_spread: {
                without_refunds: 11.69,
                with_refunds: 11.53,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.67,
                with_refunds: 18.17,
              },
              gross_mrr: {
                without_refunds: 21.36,
                with_refunds: 19.72,
              },
              net_arr: {
                without_refunds: 102.34,
              },
              gross_arr: {
                without_refunds: 127.4,
              },
              net_arr_spread: {
                without_refunds: 11.44,
                with_refunds: 11.44,
              },
              gross_arr_spread: {
                without_refunds: 12.23,
                with_refunds: 12.23,
              },
              net_total: {
                without_refunds: 15.35,
                with_refunds: 14.05,
              },
              gross_total: {
                without_refunds: 17.02,
                with_refunds: 15.34,
              },
              net_total_spread: {
                without_refunds: 12.37,
                with_refunds: 12.28,
              },
              gross_total_spread: {
                without_refunds: 13.24,
                with_refunds: 13.13,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.59,
                with_refunds: 13.92,
              },
              gross_mrr: {
                without_refunds: 16.36,
                with_refunds: 15.62,
              },
              net_arr: {
                without_refunds: 108.05,
              },
              gross_arr: {
                without_refunds: 135.39,
                with_refunds: 2.97,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.76,
                with_refunds: 11.76,
              },
              net_total: {
                without_refunds: 17.27,
                with_refunds: 11.83,
              },
              gross_total: {
                without_refunds: 19.64,
                with_refunds: 13.55,
              },
              net_total_spread: {
                without_refunds: 11.12,
                with_refunds: 11.05,
              },
              gross_total_spread: {
                without_refunds: 12.36,
                with_refunds: 12.28,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.06,
                with_refunds: 18.13,
              },
              gross_mrr: {
                without_refunds: 22.07,
                with_refunds: 21,
              },
              net_arr: {
                without_refunds: 88.75,
                with_refunds: 83.13,
              },
              gross_arr: {
                without_refunds: 95.42,
                with_refunds: 89.54,
              },
              net_arr_spread: {
                without_refunds: 26.59,
                with_refunds: 26.59,
              },
              gross_arr_spread: {
                without_refunds: 28.68,
                with_refunds: 28.68,
              },
              net_total: {
                without_refunds: 59.02,
                with_refunds: 54.86,
              },
              gross_total: {
                without_refunds: 64.13,
                with_refunds: 59.73,
              },
              net_total_spread: {
                without_refunds: 26.32,
                with_refunds: 25.41,
              },
              gross_total_spread: {
                without_refunds: 29.82,
                with_refunds: 28.78,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 19.85,
                with_refunds: 19.1,
              },
              gross_mrr: {
                without_refunds: 22.72,
                with_refunds: 21.87,
              },
              net_arr: {
                without_refunds: 64.97,
                with_refunds: 57.96,
              },
              gross_arr: {
                without_refunds: 72.31,
                with_refunds: 64.57,
              },
              net_arr_spread: {
                without_refunds: 18.73,
                with_refunds: 18.73,
              },
              gross_arr_spread: {
                without_refunds: 20.94,
                with_refunds: 20.94,
              },
              net_total: {
                without_refunds: 63.95,
                with_refunds: 56.21,
              },
              gross_total: {
                without_refunds: 71.3,
                with_refunds: 62.75,
              },
              net_total_spread: {
                without_refunds: 20.67,
                with_refunds: 20.23,
              },
              gross_total_spread: {
                without_refunds: 23.39,
                with_refunds: 22.89,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 26.92,
                  with_refunds: 26.13,
                },
                gross_mrr: {
                  without_refunds: 31.81,
                  with_refunds: 30.89,
                },
                net_arr: {
                  without_refunds: 107.51,
                  with_refunds: 87.01,
                },
                gross_arr: {
                  without_refunds: 120.57,
                  with_refunds: 97.9,
                },
                net_arr_spread: {
                  without_refunds: 30.9,
                  with_refunds: 30.9,
                },
                gross_arr_spread: {
                  without_refunds: 34.84,
                  with_refunds: 34.84,
                },
                net_total: {
                  without_refunds: 57.28,
                  with_refunds: 49.11,
                },
                gross_total: {
                  without_refunds: 65.27,
                  with_refunds: 56.21,
                },
                net_total_spread: {
                  without_refunds: 28.43,
                  with_refunds: 27.89,
                },
                gross_total_spread: {
                  without_refunds: 33.04,
                  with_refunds: 32.41,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 26.95,
                  with_refunds: 25.68,
                },
                gross_mrr: {
                  without_refunds: 30.63,
                  with_refunds: 29.15,
                },
                net_arr: {
                  without_refunds: 139.03,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.14,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 41.95,
                  with_refunds: 41.95,
                },
                gross_arr_spread: {
                  without_refunds: 44.87,
                  with_refunds: 44.87,
                },
                net_total: {
                  without_refunds: 81.52,
                  with_refunds: 73.58,
                },
                gross_total: {
                  without_refunds: 87.82,
                  with_refunds: 79.4,
                },
                net_total_spread: {
                  without_refunds: 34.19,
                  with_refunds: 33.51,
                },
                gross_total_spread: {
                  without_refunds: 37.53,
                  with_refunds: 36.74,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 28.74,
                  with_refunds: 27.35,
                },
                gross_mrr: {
                  without_refunds: 32.74,
                  with_refunds: 31.19,
                },
                net_arr: {
                  without_refunds: 127.92,
                  with_refunds: 99.71,
                },
                gross_arr: {
                  without_refunds: 141.3,
                  with_refunds: 110.31,
                },
                net_arr_spread: {
                  without_refunds: 36.97,
                  with_refunds: 36.97,
                },
                gross_arr_spread: {
                  without_refunds: 41.01,
                  with_refunds: 41.01,
                },
                net_total: {
                  without_refunds: 96.97,
                  with_refunds: 77.13,
                },
                gross_total: {
                  without_refunds: 107.44,
                  with_refunds: 85.63,
                },
                net_total_spread: {
                  without_refunds: 34.06,
                  with_refunds: 33.54,
                },
                gross_total_spread: {
                  without_refunds: 38.09,
                  with_refunds: 37.51,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 47.36,
                  with_refunds: 45.81,
                },
                gross_mrr: {
                  without_refunds: 55.66,
                  with_refunds: 53.87,
                },
                net_arr: {
                  without_refunds: 440.24,
                  with_refunds: 203.91,
                },
                gross_arr: {
                  without_refunds: 520.34,
                  with_refunds: 240.07,
                },
                net_arr_spread: {
                  without_refunds: 35.54,
                  with_refunds: 35.54,
                },
                gross_arr_spread: {
                  without_refunds: 40.14,
                  with_refunds: 40.14,
                },
                net_total: {
                  without_refunds: 113.76,
                  with_refunds: 94.06,
                },
                gross_total: {
                  without_refunds: 129.48,
                  with_refunds: 107.55,
                },
                net_total_spread: {
                  without_refunds: 42.1,
                  with_refunds: 41.23,
                },
                gross_total_spread: {
                  without_refunds: 48.97,
                  with_refunds: 47.96,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 76.96,
                  with_refunds: 72.47,
                },
                gross_mrr: {
                  without_refunds: 85.73,
                  with_refunds: 80.65,
                },
                net_arr: {
                  without_refunds: 492.5,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 555.9,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 45.56,
                  with_refunds: 45.56,
                },
                gross_arr_spread: {
                  without_refunds: 48.79,
                  with_refunds: 48.79,
                },
                net_total: {
                  without_refunds: 178.19,
                  with_refunds: 152.62,
                },
                gross_total: {
                  without_refunds: 192.75,
                  with_refunds: 164.65,
                },
                net_total_spread: {
                  without_refunds: 54.56,
                  with_refunds: 53.55,
                },
                gross_total_spread: {
                  without_refunds: 59.78,
                  with_refunds: 58.6,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 57.31,
                  with_refunds: 54.18,
                },
                gross_mrr: {
                  without_refunds: 64.87,
                  with_refunds: 61.36,
                },
                net_arr: {
                  without_refunds: 488.46,
                  with_refunds: 194.63,
                },
                gross_arr: {
                  without_refunds: 564.19,
                  with_refunds: 218.11,
                },
                net_arr_spread: {
                  without_refunds: 41.91,
                  with_refunds: 41.91,
                },
                gross_arr_spread: {
                  without_refunds: 46.55,
                  with_refunds: 46.55,
                },
                net_total: {
                  without_refunds: 209.74,
                  with_refunds: 154.97,
                },
                gross_total: {
                  without_refunds: 232.98,
                  with_refunds: 172.41,
                },
                net_total_spread: {
                  without_refunds: 45.72,
                  with_refunds: 44.94,
                },
                gross_total_spread: {
                  without_refunds: 51.14,
                  with_refunds: 50.27,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.4577,
                  with_refunds: 0.4435,
                },
                gross_mrr: {
                  without_refunds: 0.5394,
                  with_refunds: 0.5229,
                },
                net_arr: {
                  without_refunds: 1.8541,
                  with_refunds: 1.5375,
                },
                gross_arr: {
                  without_refunds: 2.0663,
                  with_refunds: 1.717,
                },
                net_arr_spread: {
                  without_refunds: 0.5457,
                  with_refunds: 0.5457,
                },
                gross_arr_spread: {
                  without_refunds: 0.6107,
                  with_refunds: 0.6107,
                },
                net_total: {
                  without_refunds: 0.984,
                  with_refunds: 0.8569,
                },
                gross_total: {
                  without_refunds: 1.1153,
                  with_refunds: 0.9744,
                },
                net_total_spread: {
                  without_refunds: 0.4901,
                  with_refunds: 0.4805,
                },
                gross_total_spread: {
                  without_refunds: 0.567,
                  with_refunds: 0.5557,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.6097,
                  with_refunds: 1.542,
                },
                gross_mrr: {
                  without_refunds: 1.8408,
                  with_refunds: 1.7607,
                },
                net_arr: {
                  without_refunds: 8.9804,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5661,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 2.5961,
                  with_refunds: 2.5961,
                },
                gross_arr_spread: {
                  without_refunds: 2.7772,
                  with_refunds: 2.7772,
                },
                net_total: {
                  without_refunds: 5.2534,
                  with_refunds: 4.7178,
                },
                gross_total: {
                  without_refunds: 5.6604,
                  with_refunds: 5.0905,
                },
                net_total_spread: {
                  without_refunds: 2.0768,
                  with_refunds: 2.0407,
                },
                gross_total_spread: {
                  without_refunds: 2.2852,
                  with_refunds: 2.2423,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.6971,
                  with_refunds: 0.6657,
                },
                gross_mrr: {
                  without_refunds: 0.797,
                  with_refunds: 0.7609,
                },
                net_arr: {
                  without_refunds: 2.2745,
                  with_refunds: 1.8589,
                },
                gross_arr: {
                  without_refunds: 2.4804,
                  with_refunds: 2.0276,
                },
                net_arr_spread: {
                  without_refunds: 0.6892,
                  with_refunds: 0.6892,
                },
                gross_arr_spread: {
                  without_refunds: 0.7539,
                  with_refunds: 0.7539,
                },
                net_total: {
                  without_refunds: 1.7846,
                  with_refunds: 1.4898,
                },
                gross_total: {
                  without_refunds: 1.9579,
                  with_refunds: 1.6361,
                },
                net_total_spread: {
                  without_refunds: 0.6957,
                  with_refunds: 0.684,
                },
                gross_total_spread: {
                  without_refunds: 0.7738,
                  with_refunds: 0.7603,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.8396,
                  with_refunds: 0.8082,
                },
                gross_mrr: {
                  without_refunds: 0.9814,
                  with_refunds: 0.9453,
                },
                net_arr: {
                  without_refunds: 7.4769,
                  with_refunds: 3.5928,
                },
                gross_arr: {
                  without_refunds: 8.8398,
                  with_refunds: 4.1968,
                },
                net_arr_spread: {
                  without_refunds: 0.6276,
                  with_refunds: 0.6276,
                },
                gross_arr_spread: {
                  without_refunds: 0.7034,
                  with_refunds: 0.7034,
                },
                net_total: {
                  without_refunds: 1.9732,
                  with_refunds: 1.672,
                },
                gross_total: {
                  without_refunds: 2.2347,
                  with_refunds: 1.8985,
                },
                net_total_spread: {
                  without_refunds: 0.7376,
                  with_refunds: 0.722,
                },
                gross_total_spread: {
                  without_refunds: 0.8534,
                  with_refunds: 0.8352,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 4.2928,
                  with_refunds: 4.0096,
                },
                gross_mrr: {
                  without_refunds: 4.7991,
                  with_refunds: 4.4799,
                },
                net_arr: {
                  without_refunds: 31.4692,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 36.0473,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 2.9323,
                  with_refunds: 2.9323,
                },
                gross_arr_spread: {
                  without_refunds: 3.1413,
                  with_refunds: 3.1413,
                },
                net_total: {
                  without_refunds: 11.479,
                  with_refunds: 10.0441,
                },
                gross_total: {
                  without_refunds: 12.4447,
                  with_refunds: 10.8472,
                },
                net_total_spread: {
                  without_refunds: 3.2617,
                  with_refunds: 3.2045,
                },
                gross_total_spread: {
                  without_refunds: 3.5859,
                  with_refunds: 3.518,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.581,
                  with_refunds: 1.4835,
                },
                gross_mrr: {
                  without_refunds: 1.7804,
                  with_refunds: 1.6709,
                },
                net_arr: {
                  without_refunds: 8.4483,
                  with_refunds: 3.6639,
                },
                gross_arr: {
                  without_refunds: 9.7378,
                  with_refunds: 4.0351,
                },
                net_arr_spread: {
                  without_refunds: 0.7837,
                  with_refunds: 0.7837,
                },
                gross_arr_spread: {
                  without_refunds: 0.8586,
                  with_refunds: 0.8586,
                },
                net_total: {
                  without_refunds: 3.8603,
                  with_refunds: 3.0642,
                },
                gross_total: {
                  without_refunds: 4.2517,
                  with_refunds: 3.3698,
                },
                net_total_spread: {
                  without_refunds: 1.0046,
                  with_refunds: 0.9866,
                },
                gross_total_spread: {
                  without_refunds: 1.1165,
                  with_refunds: 1.0958,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 4,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.321,
                with_refunds: 0.3221,
              },
              arr_users: {
                without_refunds: 0.0037,
                with_refunds: 0.0044,
              },
              arr_spread_users: {
                without_refunds: 0.9844,
                with_refunds: 0.9844,
              },
              total_users: {
                without_refunds: 0.2049,
                with_refunds: 0.2058,
              },
              total_spread_users: {
                without_refunds: 0.5375,
                with_refunds: 0.5382,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7884,
                with_refunds: 0.7879,
              },
              arr_users: {
                without_refunds: 0.6761,
                with_refunds: 0.5316,
              },
              arr_spread_users: {
                without_refunds: 1.0036,
                with_refunds: 1.0036,
              },
              total_users: {
                without_refunds: 0.7414,
                with_refunds: 0.739,
              },
              total_spread_users: {
                without_refunds: 0.899,
                with_refunds: 0.8986,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0441,
                with_refunds: 0.0446,
              },
              arr_users: {
                without_refunds: 0.0004,
                with_refunds: 0.0007,
              },
              arr_spread_users: {
                without_refunds: 1.0194,
                with_refunds: 1.0194,
              },
              total_users: {
                without_refunds: 0.0151,
                with_refunds: 0.0155,
              },
              total_spread_users: {
                without_refunds: 0.4784,
                with_refunds: 0.4789,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.5646,
                with_refunds: 0.5653,
              },
              arr_users: {
                without_refunds: 0.4222,
                with_refunds: 0.3778,
              },
              arr_spread_users: {
                without_refunds: 1.0037,
                with_refunds: 1.0037,
              },
              total_users: {
                without_refunds: 0.5622,
                with_refunds: 0.5615,
              },
              total_spread_users: {
                without_refunds: 0.9203,
                with_refunds: 0.9204,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.2029,
                with_refunds: 0.2038,
              },
              arr_users: {
                without_refunds: 0.0007,
                with_refunds: 0.0013,
              },
              arr_spread_users: {
                without_refunds: 0.9993,
                with_refunds: 0.9993,
              },
              total_users: {
                without_refunds: 0.0635,
                with_refunds: 0.0643,
              },
              total_spread_users: {
                without_refunds: 0.6923,
                with_refunds: 0.6927,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6186,
                with_refunds: 0.62,
              },
              arr_users: {
                without_refunds: 0.3116,
                with_refunds: 0.3166,
              },
              arr_spread_users: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              total_users: {
                without_refunds: 0.5166,
                with_refunds: 0.5163,
              },
              total_spread_users: {
                without_refunds: 0.9259,
                with_refunds: 0.926,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.5668,
                with_refunds: 0.5711,
              },
              gross_mrr: {
                without_refunds: 0.5199,
                with_refunds: 0.5243,
              },
              net_arr: {
                without_refunds: 0.0091,
                with_refunds: 0.0067,
              },
              gross_arr: {
                without_refunds: 0.0102,
                with_refunds: 0.0077,
              },
              net_arr_spread: {
                without_refunds: 1.9638,
                with_refunds: 1.9638,
              },
              gross_arr_spread: {
                without_refunds: 1.9465,
                with_refunds: 1.9465,
              },
              net_total: {
                without_refunds: 0.169,
                with_refunds: 0.1731,
              },
              gross_total: {
                without_refunds: 0.152,
                with_refunds: 0.1557,
              },
              net_total_spread: {
                without_refunds: 0.8875,
                with_refunds: 0.8985,
              },
              gross_total_spread: {
                without_refunds: 0.8425,
                with_refunds: 0.8534,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.791,
                with_refunds: 0.7908,
              },
              gross_mrr: {
                without_refunds: 0.7875,
                with_refunds: 0.7874,
              },
              net_arr: {
                without_refunds: 0.7142,
                with_refunds: 1.0023,
              },
              gross_arr: {
                without_refunds: 0.7033,
                with_refunds: 0.8772,
              },
              net_arr_spread: {
                without_refunds: 1.0044,
                with_refunds: 1.0044,
              },
              gross_arr_spread: {
                without_refunds: 1.005,
                with_refunds: 1.005,
              },
              net_total: {
                without_refunds: 0.7371,
                with_refunds: 0.7544,
              },
              gross_total: {
                without_refunds: 0.7323,
                with_refunds: 0.75,
              },
              net_total_spread: {
                without_refunds: 0.8849,
                with_refunds: 0.8858,
              },
              gross_total_spread: {
                without_refunds: 0.8828,
                with_refunds: 0.8837,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0637,
                with_refunds: 0.0624,
              },
              gross_mrr: {
                without_refunds: 0.0598,
                with_refunds: 0.0586,
              },
              net_arr: {
                without_refunds: 0.0005,
              },
              gross_arr: {
                without_refunds: 0.0006,
              },
              net_arr_spread: {
                without_refunds: 1.6884,
                with_refunds: 1.6884,
              },
              gross_arr_spread: {
                without_refunds: 1.6702,
                with_refunds: 1.6702,
              },
              net_total: {
                without_refunds: 0.0052,
                with_refunds: 0.0059,
              },
              gross_total: {
                without_refunds: 0.0053,
                with_refunds: 0.0058,
              },
              net_total_spread: {
                without_refunds: 0.5646,
                with_refunds: 0.5795,
              },
              gross_total_spread: {
                without_refunds: 0.5316,
                with_refunds: 0.5458,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.5638,
                with_refunds: 0.5631,
              },
              gross_mrr: {
                without_refunds: 0.5656,
                with_refunds: 0.5649,
              },
              net_arr: {
                without_refunds: 0.4524,
              },
              gross_arr: {
                without_refunds: 0.4112,
              },
              net_arr_spread: {
                without_refunds: 1.004,
                with_refunds: 1.004,
              },
              gross_arr_spread: {
                without_refunds: 1.0044,
                with_refunds: 1.0044,
              },
              net_total: {
                without_refunds: 0.5483,
                with_refunds: 0.5742,
              },
              gross_total: {
                without_refunds: 0.5431,
                with_refunds: 0.5733,
              },
              net_total_spread: {
                without_refunds: 0.9061,
                with_refunds: 0.9072,
              },
              gross_total_spread: {
                without_refunds: 0.9059,
                with_refunds: 0.9069,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.3214,
                with_refunds: 0.3207,
              },
              gross_mrr: {
                without_refunds: 0.3188,
                with_refunds: 0.3185,
              },
              net_arr: {
                without_refunds: 0.0014,
              },
              gross_arr: {
                without_refunds: 0.0013,
              },
              net_arr_spread: {
                without_refunds: 2.0387,
                with_refunds: 2.0387,
              },
              gross_arr_spread: {
                without_refunds: 2.0161,
                with_refunds: 2.0161,
              },
              net_total: {
                without_refunds: 0.0262,
                with_refunds: 0.0269,
              },
              gross_total: {
                without_refunds: 0.0263,
                with_refunds: 0.0271,
              },
              net_total_spread: {
                without_refunds: 1.1623,
                with_refunds: 1.1791,
              },
              gross_total_spread: {
                without_refunds: 1.1468,
                with_refunds: 1.1632,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6127,
                with_refunds: 0.6085,
              },
              gross_mrr: {
                without_refunds: 0.613,
                with_refunds: 0.6093,
              },
              net_arr: {
                without_refunds: 0.3571,
              },
              gross_arr: {
                without_refunds: 0.2976,
              },
              net_arr_spread: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              gross_arr_spread: {
                without_refunds: 1.002,
                with_refunds: 1.002,
              },
              net_total: {
                without_refunds: 0.4905,
                with_refunds: 0.5151,
              },
              gross_total: {
                without_refunds: 0.4824,
                with_refunds: 0.5117,
              },
              net_total_spread: {
                without_refunds: 0.9111,
                with_refunds: 0.9112,
              },
              gross_total_spread: {
                without_refunds: 0.9106,
                with_refunds: 0.9108,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 15797.0506,
              with_refunds: 15479.2741,
            },
            gross_mrr: {
              without_refunds: 17705.3494,
              with_refunds: 17357.7375,
            },
            net_arr: {
              without_refunds: 1105.4401,
              with_refunds: 729.3813,
            },
            gross_arr: {
              without_refunds: 1157.1752,
              with_refunds: 753.5273,
            },
            net_arr_spread: {
              without_refunds: 316600.8529,
              with_refunds: 316600.8529,
            },
            gross_arr_spread: {
              without_refunds: 352565.1085,
              with_refunds: 352565.1085,
            },
            net_total: {
              without_refunds: 14713.829,
              with_refunds: 14194.1381,
            },
            gross_total: {
              without_refunds: 16359.7794,
              with_refunds: 15846.0535,
            },
            net_total_spread: {
              without_refunds: 249891.9794,
              with_refunds: 250724.9963,
            },
            gross_total_spread: {
              without_refunds: 279351.323,
              with_refunds: 280261.4065,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 17828.0383,
              with_refunds: 17361.5747,
            },
            gross_mrr: {
              without_refunds: 19914.9962,
              with_refunds: 19359.9472,
            },
            net_arr: {
              without_refunds: 8792.2061,
              with_refunds: 7560.431,
            },
            gross_arr: {
              without_refunds: 9191.9208,
              with_refunds: 7911.7729,
            },
            net_arr_spread: {
              without_refunds: 317969.3691,
              with_refunds: 317969.3691,
            },
            gross_arr_spread: {
              without_refunds: 354000.3673,
              with_refunds: 354000.3673,
            },
            net_total: {
              without_refunds: 23914.3324,
              with_refunds: 22419.1944,
            },
            gross_total: {
              without_refunds: 26051.8526,
              with_refunds: 24499.6339,
            },
            net_total_spread: {
              without_refunds: 253537.3948,
              with_refunds: 254201.8529,
            },
            gross_total_spread: {
              without_refunds: 283260.4623,
              with_refunds: 283928.7006,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 3.81,
                with_refunds: 3.73,
              },
              gross_mrr: {
                without_refunds: 4.34,
                with_refunds: 4.25,
              },
              net_arr: {
                without_refunds: 0.43,
                with_refunds: 0.27,
              },
              gross_arr: {
                without_refunds: 0.54,
                with_refunds: 0.35,
              },
              net_arr_spread: {
                without_refunds: 8.86,
                with_refunds: 8.86,
              },
              gross_arr_spread: {
                without_refunds: 9.98,
                with_refunds: 9.98,
              },
              net_total: {
                without_refunds: 2.57,
                with_refunds: 2.46,
              },
              gross_total: {
                without_refunds: 2.95,
                with_refunds: 2.82,
              },
              net_total_spread: {
                without_refunds: 5.46,
                with_refunds: 5.4,
              },
              gross_total_spread: {
                without_refunds: 6.18,
                with_refunds: 6.12,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.87,
                with_refunds: 0.81,
              },
              gross_mrr: {
                without_refunds: 0.95,
                with_refunds: 0.88,
              },
              net_arr: {
                without_refunds: 0.04,
              },
              gross_arr: {
                without_refunds: 0.05,
              },
              net_arr_spread: {
                without_refunds: 11.65,
                with_refunds: 11.65,
              },
              gross_arr_spread: {
                without_refunds: 12.45,
                with_refunds: 12.45,
              },
              net_total: {
                without_refunds: 0.24,
                with_refunds: 0.25,
              },
              gross_total: {
                without_refunds: 0.27,
                with_refunds: 0.27,
              },
              net_total_spread: {
                without_refunds: 5.83,
                with_refunds: 5.8,
              },
              gross_total_spread: {
                without_refunds: 6.24,
                with_refunds: 6.19,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 2.68,
                with_refunds: 2.58,
              },
              gross_mrr: {
                without_refunds: 3.04,
                with_refunds: 2.93,
              },
              net_arr: {
                without_refunds: 0.08,
              },
              gross_arr: {
                without_refunds: 0.08,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.75,
                with_refunds: 11.75,
              },
              net_total: {
                without_refunds: 0.87,
                with_refunds: 0.79,
              },
              gross_total: {
                without_refunds: 0.98,
                with_refunds: 0.9,
              },
              net_total_spread: {
                without_refunds: 7.54,
                with_refunds: 7.51,
              },
              gross_total_spread: {
                without_refunds: 8.39,
                with_refunds: 8.35,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.66,
                with_refunds: 18.7,
              },
              gross_mrr: {
                without_refunds: 22.76,
                with_refunds: 21.67,
              },
              net_arr: {
                without_refunds: 88.78,
                with_refunds: 83.18,
              },
              gross_arr: {
                without_refunds: 95.46,
                with_refunds: 89.6,
              },
              net_arr_spread: {
                without_refunds: 33.96,
                with_refunds: 33.96,
              },
              gross_arr_spread: {
                without_refunds: 36.62,
                with_refunds: 36.62,
              },
              net_total: {
                without_refunds: 59.78,
                with_refunds: 55.58,
              },
              gross_total: {
                without_refunds: 64.96,
                with_refunds: 60.51,
              },
              net_total_spread: {
                without_refunds: 31.42,
                with_refunds: 30.33,
              },
              gross_total_spread: {
                without_refunds: 35.6,
                with_refunds: 34.35,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 21.78,
                with_refunds: 20.95,
              },
              gross_mrr: {
                without_refunds: 24.93,
                with_refunds: 23.99,
              },
              net_arr: {
                without_refunds: 65.02,
                with_refunds: 58.04,
              },
              gross_arr: {
                without_refunds: 72.36,
                with_refunds: 64.65,
              },
              net_arr_spread: {
                without_refunds: 24.06,
                with_refunds: 24.06,
              },
              gross_arr_spread: {
                without_refunds: 26.91,
                with_refunds: 26.91,
              },
              net_total: {
                without_refunds: 66.97,
                with_refunds: 58.82,
              },
              gross_total: {
                without_refunds: 74.67,
                with_refunds: 65.66,
              },
              net_total_spread: {
                without_refunds: 25.46,
                with_refunds: 24.91,
              },
              gross_total_spread: {
                without_refunds: 28.81,
                with_refunds: 28.19,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.07,
                with_refunds: 11.74,
              },
              gross_mrr: {
                without_refunds: 13.75,
                with_refunds: 13.38,
              },
              net_arr: {
                without_refunds: 114.45,
                with_refunds: 39.63,
              },
              gross_arr: {
                without_refunds: 134.71,
                with_refunds: 51.11,
              },
              net_arr_spread: {
                without_refunds: 8.97,
                with_refunds: 8.97,
              },
              gross_arr_spread: {
                without_refunds: 10.12,
                with_refunds: 10.12,
              },
              net_total: {
                without_refunds: 13.97,
                with_refunds: 11.95,
              },
              gross_total: {
                without_refunds: 15.96,
                with_refunds: 13.76,
              },
              net_total_spread: {
                without_refunds: 10.11,
                with_refunds: 9.99,
              },
              gross_total_spread: {
                without_refunds: 11.48,
                with_refunds: 11.34,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.64,
                with_refunds: 18.1,
              },
              gross_mrr: {
                without_refunds: 21.4,
                with_refunds: 19.7,
              },
              net_arr: {
                without_refunds: 109.66,
              },
              gross_arr: {
                without_refunds: 124.07,
              },
              net_arr_spread: {
                without_refunds: 11.45,
                with_refunds: 11.45,
              },
              gross_arr_spread: {
                without_refunds: 12.23,
                with_refunds: 12.23,
              },
              net_total: {
                without_refunds: 14.98,
                with_refunds: 14.37,
              },
              gross_total: {
                without_refunds: 16.44,
                with_refunds: 15.66,
              },
              net_total_spread: {
                without_refunds: 12.18,
                with_refunds: 12.1,
              },
              gross_total_spread: {
                without_refunds: 13.03,
                with_refunds: 12.94,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.39,
                with_refunds: 13.67,
              },
              gross_mrr: {
                without_refunds: 16.35,
                with_refunds: 15.54,
              },
              net_arr: {
                without_refunds: 119.89,
              },
              gross_arr: {
                without_refunds: 125.02,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.76,
                with_refunds: 11.76,
              },
              net_total: {
                without_refunds: 18.16,
                with_refunds: 11.44,
              },
              gross_total: {
                without_refunds: 20.42,
                with_refunds: 13.34,
              },
              net_total_spread: {
                without_refunds: 10.94,
                with_refunds: 10.88,
              },
              gross_total_spread: {
                without_refunds: 12.15,
                with_refunds: 12.08,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.66,
                with_refunds: 18.7,
              },
              gross_mrr: {
                without_refunds: 22.76,
                with_refunds: 21.67,
              },
              net_arr: {
                without_refunds: 88.78,
                with_refunds: 83.18,
              },
              gross_arr: {
                without_refunds: 95.46,
                with_refunds: 89.6,
              },
              net_arr_spread: {
                without_refunds: 33.96,
                with_refunds: 33.96,
              },
              gross_arr_spread: {
                without_refunds: 36.62,
                with_refunds: 36.62,
              },
              net_total: {
                without_refunds: 59.78,
                with_refunds: 55.58,
              },
              gross_total: {
                without_refunds: 64.96,
                with_refunds: 60.51,
              },
              net_total_spread: {
                without_refunds: 31.42,
                with_refunds: 30.33,
              },
              gross_total_spread: {
                without_refunds: 35.6,
                with_refunds: 34.35,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 21.78,
                with_refunds: 20.95,
              },
              gross_mrr: {
                without_refunds: 24.93,
                with_refunds: 23.99,
              },
              net_arr: {
                without_refunds: 65.02,
                with_refunds: 58.04,
              },
              gross_arr: {
                without_refunds: 72.36,
                with_refunds: 64.65,
              },
              net_arr_spread: {
                without_refunds: 24.06,
                with_refunds: 24.06,
              },
              gross_arr_spread: {
                without_refunds: 26.91,
                with_refunds: 26.91,
              },
              net_total: {
                without_refunds: 66.97,
                with_refunds: 58.82,
              },
              gross_total: {
                without_refunds: 74.67,
                with_refunds: 65.66,
              },
              net_total_spread: {
                without_refunds: 25.46,
                with_refunds: 24.91,
              },
              gross_total_spread: {
                without_refunds: 28.81,
                with_refunds: 28.19,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 30.73,
                  with_refunds: 29.86,
                },
                gross_mrr: {
                  without_refunds: 36.14,
                  with_refunds: 35.13,
                },
                net_arr: {
                  without_refunds: 107.94,
                  with_refunds: 87.29,
                },
                gross_arr: {
                  without_refunds: 121.11,
                  with_refunds: 98.25,
                },
                net_arr_spread: {
                  without_refunds: 39.76,
                  with_refunds: 39.76,
                },
                gross_arr_spread: {
                  without_refunds: 44.81,
                  with_refunds: 44.81,
                },
                net_total: {
                  without_refunds: 59.85,
                  with_refunds: 51.58,
                },
                gross_total: {
                  without_refunds: 68.21,
                  with_refunds: 59.03,
                },
                net_total_spread: {
                  without_refunds: 33.88,
                  with_refunds: 33.29,
                },
                gross_total_spread: {
                  without_refunds: 39.22,
                  with_refunds: 38.53,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 27.83,
                  with_refunds: 26.49,
                },
                gross_mrr: {
                  without_refunds: 31.58,
                  with_refunds: 30.03,
                },
                net_arr: {
                  without_refunds: 139.07,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.19,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 53.6,
                  with_refunds: 53.6,
                },
                gross_arr_spread: {
                  without_refunds: 57.32,
                  with_refunds: 57.32,
                },
                net_total: {
                  without_refunds: 81.77,
                  with_refunds: 73.83,
                },
                gross_total: {
                  without_refunds: 88.09,
                  with_refunds: 79.67,
                },
                net_total_spread: {
                  without_refunds: 40.02,
                  with_refunds: 39.31,
                },
                gross_total_spread: {
                  without_refunds: 43.77,
                  with_refunds: 42.93,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 31.41,
                  with_refunds: 29.92,
                },
                gross_mrr: {
                  without_refunds: 35.78,
                  with_refunds: 34.12,
                },
                net_arr: {
                  without_refunds: 128,
                  with_refunds: 99.71,
                },
                gross_arr: {
                  without_refunds: 141.38,
                  with_refunds: 110.31,
                },
                net_arr_spread: {
                  without_refunds: 47.58,
                  with_refunds: 47.58,
                },
                gross_arr_spread: {
                  without_refunds: 52.76,
                  with_refunds: 52.76,
                },
                net_total: {
                  without_refunds: 97.84,
                  with_refunds: 77.92,
                },
                gross_total: {
                  without_refunds: 108.42,
                  with_refunds: 86.53,
                },
                net_total_spread: {
                  without_refunds: 41.6,
                  with_refunds: 41.04,
                },
                gross_total_spread: {
                  without_refunds: 46.49,
                  with_refunds: 45.86,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 59.44,
                  with_refunds: 57.55,
                },
                gross_mrr: {
                  without_refunds: 69.41,
                  with_refunds: 67.24,
                },
                net_arr: {
                  without_refunds: 554.69,
                  with_refunds: 243.54,
                },
                gross_arr: {
                  without_refunds: 655.05,
                  with_refunds: 291.18,
                },
                net_arr_spread: {
                  without_refunds: 44.51,
                  with_refunds: 44.51,
                },
                gross_arr_spread: {
                  without_refunds: 50.26,
                  with_refunds: 50.26,
                },
                net_total: {
                  without_refunds: 127.73,
                  with_refunds: 106.01,
                },
                gross_total: {
                  without_refunds: 145.43,
                  with_refunds: 121.31,
                },
                net_total_spread: {
                  without_refunds: 52.21,
                  with_refunds: 51.22,
                },
                gross_total_spread: {
                  without_refunds: 60.45,
                  with_refunds: 59.3,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 96.6,
                  with_refunds: 90.57,
                },
                gross_mrr: {
                  without_refunds: 107.12,
                  with_refunds: 100.35,
                },
                net_arr: {
                  without_refunds: 602.16,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 679.96,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 57.01,
                  with_refunds: 57.01,
                },
                gross_arr_spread: {
                  without_refunds: 61.03,
                  with_refunds: 61.03,
                },
                net_total: {
                  without_refunds: 193.17,
                  with_refunds: 166.99,
                },
                gross_total: {
                  without_refunds: 209.19,
                  with_refunds: 180.31,
                },
                net_total_spread: {
                  without_refunds: 66.74,
                  with_refunds: 65.65,
                },
                gross_total_spread: {
                  without_refunds: 72.81,
                  with_refunds: 71.54,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 71.7,
                  with_refunds: 67.85,
                },
                gross_mrr: {
                  without_refunds: 81.21,
                  with_refunds: 76.91,
                },
                net_arr: {
                  without_refunds: 608.35,
                  with_refunds: 194.63,
                },
                gross_arr: {
                  without_refunds: 689.2,
                  with_refunds: 218.11,
                },
                net_arr_spread: {
                  without_refunds: 52.52,
                  with_refunds: 52.52,
                },
                gross_arr_spread: {
                  without_refunds: 58.31,
                  with_refunds: 58.31,
                },
                net_total: {
                  without_refunds: 227.9,
                  with_refunds: 166.41,
                },
                gross_total: {
                  without_refunds: 253.4,
                  with_refunds: 185.76,
                },
                net_total_spread: {
                  without_refunds: 56.66,
                  with_refunds: 55.82,
                },
                gross_total_spread: {
                  without_refunds: 63.3,
                  with_refunds: 62.35,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.5199,
                  with_refunds: 0.5043,
                },
                gross_mrr: {
                  without_refunds: 0.61,
                  with_refunds: 0.592,
                },
                net_arr: {
                  without_refunds: 1.8612,
                  with_refunds: 1.5419,
                },
                gross_arr: {
                  without_refunds: 2.0753,
                  with_refunds: 1.7226,
                },
                net_arr_spread: {
                  without_refunds: 0.7023,
                  with_refunds: 0.7023,
                },
                gross_arr_spread: {
                  without_refunds: 0.7858,
                  with_refunds: 0.7858,
                },
                net_total: {
                  without_refunds: 1.026,
                  with_refunds: 0.8971,
                },
                gross_total: {
                  without_refunds: 1.1634,
                  with_refunds: 1.0204,
                },
                net_total_spread: {
                  without_refunds: 0.5828,
                  with_refunds: 0.5722,
                },
                gross_total_spread: {
                  without_refunds: 0.6714,
                  with_refunds: 0.6591,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.655,
                  with_refunds: 1.5835,
                },
                gross_mrr: {
                  without_refunds: 1.89,
                  with_refunds: 1.8056,
                },
                net_arr: {
                  without_refunds: 8.9837,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.57,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 3.3384,
                  with_refunds: 3.3384,
                },
                gross_arr_spread: {
                  without_refunds: 3.5703,
                  with_refunds: 3.5703,
                },
                net_total: {
                  without_refunds: 5.2738,
                  with_refunds: 4.7393,
                },
                gross_total: {
                  without_refunds: 5.6829,
                  with_refunds: 5.1138,
                },
                net_total_spread: {
                  without_refunds: 2.4339,
                  with_refunds: 2.3952,
                },
                gross_total_spread: {
                  without_refunds: 2.6669,
                  with_refunds: 2.6209,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7449,
                  with_refunds: 0.7113,
                },
                gross_mrr: {
                  without_refunds: 0.8508,
                  with_refunds: 0.8124,
                },
                net_arr: {
                  without_refunds: 2.2758,
                  with_refunds: 1.8589,
                },
                gross_arr: {
                  without_refunds: 2.4818,
                  with_refunds: 2.0276,
                },
                net_arr_spread: {
                  without_refunds: 0.8873,
                  with_refunds: 0.8873,
                },
                gross_arr_spread: {
                  without_refunds: 0.9704,
                  with_refunds: 0.9704,
                },
                net_total: {
                  without_refunds: 1.8,
                  with_refunds: 1.5043,
                },
                gross_total: {
                  without_refunds: 1.9752,
                  with_refunds: 1.6524,
                },
                net_total_spread: {
                  without_refunds: 0.8347,
                  with_refunds: 0.8221,
                },
                gross_total_spread: {
                  without_refunds: 0.9263,
                  with_refunds: 0.9118,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.0531,
                  with_refunds: 1.0142,
                },
                gross_mrr: {
                  without_refunds: 1.2229,
                  with_refunds: 1.1784,
                },
                net_arr: {
                  without_refunds: 9.3774,
                  with_refunds: 4.2208,
                },
                gross_arr: {
                  without_refunds: 11.1174,
                  with_refunds: 5.004,
                },
                net_arr_spread: {
                  without_refunds: 0.7858,
                  with_refunds: 0.7858,
                },
                gross_arr_spread: {
                  without_refunds: 0.8806,
                  with_refunds: 0.8806,
                },
                net_total: {
                  without_refunds: 2.2122,
                  with_refunds: 1.8884,
                },
                gross_total: {
                  without_refunds: 2.5066,
                  with_refunds: 2.145,
                },
                net_total_spread: {
                  without_refunds: 0.9126,
                  with_refunds: 0.895,
                },
                gross_total_spread: {
                  without_refunds: 1.0509,
                  with_refunds: 1.0304,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 5.373,
                  with_refunds: 4.9814,
                },
                gross_mrr: {
                  without_refunds: 5.9703,
                  with_refunds: 5.5333,
                },
                net_arr: {
                  without_refunds: 38.3036,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 44.0426,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 3.6705,
                  with_refunds: 3.6705,
                },
                gross_arr_spread: {
                  without_refunds: 3.9299,
                  with_refunds: 3.9299,
                },
                net_total: {
                  without_refunds: 12.5227,
                  with_refunds: 11.131,
                },
                gross_total: {
                  without_refunds: 13.5922,
                  with_refunds: 12.0284,
                },
                net_total_spread: {
                  without_refunds: 3.9766,
                  with_refunds: 3.9138,
                },
                gross_total_spread: {
                  without_refunds: 4.3498,
                  with_refunds: 4.2755,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.9774,
                  with_refunds: 1.848,
                },
                gross_mrr: {
                  without_refunds: 2.2188,
                  with_refunds: 2.0744,
                },
                net_arr: {
                  without_refunds: 10.4068,
                  with_refunds: 3.6639,
                },
                gross_arr: {
                  without_refunds: 11.8705,
                  with_refunds: 4.0351,
                },
                net_arr_spread: {
                  without_refunds: 0.9817,
                  with_refunds: 0.9817,
                },
                gross_arr_spread: {
                  without_refunds: 1.075,
                  with_refunds: 1.075,
                },
                net_total: {
                  without_refunds: 4.2047,
                  with_refunds: 3.3453,
                },
                gross_total: {
                  without_refunds: 4.6353,
                  with_refunds: 3.685,
                },
                net_total_spread: {
                  without_refunds: 1.2349,
                  with_refunds: 1.2151,
                },
                gross_total_spread: {
                  without_refunds: 1.3682,
                  with_refunds: 1.3455,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 5,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.2543,
                with_refunds: 0.2554,
              },
              arr_users: {
                without_refunds: 0.0043,
                with_refunds: 0.0048,
              },
              arr_spread_users: {
                without_refunds: 0.9885,
                with_refunds: 0.9885,
              },
              total_users: {
                without_refunds: 0.1628,
                with_refunds: 0.1636,
              },
              total_spread_users: {
                without_refunds: 0.4849,
                with_refunds: 0.4856,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7845,
                with_refunds: 0.7853,
              },
              arr_users: {
                without_refunds: 0.8067,
                with_refunds: 0.8226,
              },
              arr_spread_users: {
                without_refunds: 1.0042,
                with_refunds: 1.0042,
              },
              total_users: {
                without_refunds: 0.7485,
                with_refunds: 0.7496,
              },
              total_spread_users: {
                without_refunds: 0.9028,
                with_refunds: 0.9029,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0279,
                with_refunds: 0.0283,
              },
              arr_users: {
                without_refunds: 0.0001,
                with_refunds: 0.0002,
              },
              arr_spread_users: {
                without_refunds: 1.0199,
                with_refunds: 1.0199,
              },
              total_users: {
                without_refunds: 0.0095,
                with_refunds: 0.0098,
              },
              total_spread_users: {
                without_refunds: 0.4294,
                with_refunds: 0.4299,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6336,
                with_refunds: 0.6351,
              },
              arr_users: {
                without_refunds: 0.2273,
                with_refunds: 0.3662,
              },
              arr_spread_users: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              total_users: {
                without_refunds: 0.6277,
                with_refunds: 0.6322,
              },
              total_spread_users: {
                without_refunds: 0.8976,
                with_refunds: 0.8977,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0471,
                with_refunds: 0.0472,
              },
              arr_users: {
                without_refunds: 0.0003,
                with_refunds: 0.0004,
              },
              arr_spread_users: {
                without_refunds: 0.9999,
                with_refunds: 0.9999,
              },
              total_users: {
                without_refunds: 0.0144,
                with_refunds: 0.0146,
              },
              total_spread_users: {
                without_refunds: 0.5945,
                with_refunds: 0.5946,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3843,
                with_refunds: 0.3845,
              },
              arr_users: {
                without_refunds: 0.3233,
                with_refunds: 0.3252,
              },
              arr_spread_users: {
                without_refunds: 1.0006,
                with_refunds: 1.0006,
              },
              total_users: {
                without_refunds: 0.4461,
                with_refunds: 0.4481,
              },
              total_spread_users: {
                without_refunds: 0.857,
                with_refunds: 0.8567,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.4538,
                with_refunds: 0.4572,
              },
              gross_mrr: {
                without_refunds: 0.4122,
                with_refunds: 0.4158,
              },
              net_arr: {
                without_refunds: 0.011,
                with_refunds: 0.009,
              },
              gross_arr: {
                without_refunds: 0.0117,
                with_refunds: 0.0096,
              },
              net_arr_spread: {
                without_refunds: 1.9737,
                with_refunds: 1.9737,
              },
              gross_arr_spread: {
                without_refunds: 1.957,
                with_refunds: 1.957,
              },
              net_total: {
                without_refunds: 0.1432,
                with_refunds: 0.1455,
              },
              gross_total: {
                without_refunds: 0.1278,
                with_refunds: 0.1299,
              },
              net_total_spread: {
                without_refunds: 0.7838,
                with_refunds: 0.7949,
              },
              gross_total_spread: {
                without_refunds: 0.7422,
                with_refunds: 0.7535,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7866,
                with_refunds: 0.7868,
              },
              gross_mrr: {
                without_refunds: 0.7824,
                with_refunds: 0.7827,
              },
              net_arr: {
                without_refunds: 0.8539,
                with_refunds: 0.8484,
              },
              gross_arr: {
                without_refunds: 0.8766,
                with_refunds: 1,
              },
              net_arr_spread: {
                without_refunds: 1.0055,
                with_refunds: 1.0055,
              },
              gross_arr_spread: {
                without_refunds: 1.0058,
                with_refunds: 1.0058,
              },
              net_total: {
                without_refunds: 0.7651,
                with_refunds: 0.7656,
              },
              gross_total: {
                without_refunds: 0.762,
                with_refunds: 0.7626,
              },
              net_total_spread: {
                without_refunds: 0.8855,
                with_refunds: 0.8865,
              },
              gross_total_spread: {
                without_refunds: 0.8835,
                with_refunds: 0.8847,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0403,
                with_refunds: 0.0394,
              },
              gross_mrr: {
                without_refunds: 0.0376,
                with_refunds: 0.0368,
              },
              net_arr: {
                without_refunds: 0.0001,
              },
              gross_arr: {
                without_refunds: 0.0001,
              },
              net_arr_spread: {
                without_refunds: 1.6893,
                with_refunds: 1.6893,
              },
              gross_arr_spread: {
                without_refunds: 1.6711,
                with_refunds: 1.6711,
              },
              net_total: {
                without_refunds: 0.003,
                with_refunds: 0.0035,
              },
              gross_total: {
                without_refunds: 0.003,
                with_refunds: 0.0034,
              },
              net_total_spread: {
                without_refunds: 0.4922,
                with_refunds: 0.5065,
              },
              gross_total_spread: {
                without_refunds: 0.4622,
                with_refunds: 0.476,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6321,
                with_refunds: 0.6317,
              },
              gross_mrr: {
                without_refunds: 0.6286,
                with_refunds: 0.629,
              },
              net_arr: {
                without_refunds: 0.2185,
              },
              gross_arr: {
                without_refunds: 0.2373,
              },
              net_arr_spread: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              gross_arr_spread: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              net_total: {
                without_refunds: 0.5827,
                with_refunds: 0.5954,
              },
              gross_total: {
                without_refunds: 0.5713,
                with_refunds: 0.5861,
              },
              net_total_spread: {
                without_refunds: 0.8717,
                with_refunds: 0.874,
              },
              gross_total_spread: {
                without_refunds: 0.8694,
                with_refunds: 0.8721,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0729,
                with_refunds: 0.0746,
              },
              gross_mrr: {
                without_refunds: 0.0723,
                with_refunds: 0.074,
              },
              net_arr: {
                without_refunds: 0.0005,
                with_refunds: 0.0004,
              },
              gross_arr: {
                without_refunds: 0.0005,
                with_refunds: 0.0005,
              },
              net_arr_spread: {
                without_refunds: 2.0398,
                with_refunds: 2.0398,
              },
              gross_arr_spread: {
                without_refunds: 2.0172,
                with_refunds: 2.0172,
              },
              net_total: {
                without_refunds: 0.0059,
                with_refunds: 0.0063,
              },
              gross_total: {
                without_refunds: 0.0059,
                with_refunds: 0.0064,
              },
              net_total_spread: {
                without_refunds: 0.9571,
                with_refunds: 0.9766,
              },
              gross_total_spread: {
                without_refunds: 0.9421,
                with_refunds: 0.9612,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.3822,
                with_refunds: 0.3858,
              },
              gross_mrr: {
                without_refunds: 0.3807,
                with_refunds: 0.3845,
              },
              net_arr: {
                without_refunds: 0.3093,
                with_refunds: 0.0004,
              },
              gross_arr: {
                without_refunds: 0.3493,
                with_refunds: 0.3663,
              },
              net_arr_spread: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              gross_arr_spread: {
                without_refunds: 1.0006,
                with_refunds: 1.0006,
              },
              net_total: {
                without_refunds: 0.4224,
                with_refunds: 0.4358,
              },
              gross_total: {
                without_refunds: 0.4167,
                with_refunds: 0.4311,
              },
              net_total_spread: {
                without_refunds: 0.8218,
                with_refunds: 0.8265,
              },
              gross_total_spread: {
                without_refunds: 0.8199,
                with_refunds: 0.8247,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 16562.8326,
              with_refunds: 16231.6132,
            },
            gross_mrr: {
              without_refunds: 18556.7226,
              with_refunds: 18194.7259,
            },
            net_arr: {
              without_refunds: 1106.0773,
              with_refunds: 729.438,
            },
            gross_arr: {
              without_refunds: 1157.9114,
              with_refunds: 753.6067,
            },
            net_arr_spread: {
              without_refunds: 374613.6247,
              with_refunds: 374613.6247,
            },
            gross_arr_spread: {
              without_refunds: 416813.0927,
              with_refunds: 416813.0927,
            },
            net_total: {
              without_refunds: 15292.2819,
              with_refunds: 14768.2028,
            },
            gross_total: {
              without_refunds: 16993.1178,
              with_refunds: 16480.2827,
            },
            net_total_spread: {
              without_refunds: 279984.8991,
              with_refunds: 281117.713,
            },
            gross_total_spread: {
              without_refunds: 312844.165,
              with_refunds: 314079.984,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 19340.0195,
              with_refunds: 18803.1452,
            },
            gross_mrr: {
              without_refunds: 21572.9769,
              with_refunds: 20925.333,
            },
            net_arr: {
              without_refunds: 10927.2513,
              with_refunds: 9443.8241,
            },
            gross_arr: {
              without_refunds: 11419.3663,
              with_refunds: 9881.2742,
            },
            net_arr_spread: {
              without_refunds: 376732.3547,
              with_refunds: 376732.3547,
            },
            gross_arr_spread: {
              without_refunds: 419033.7677,
              with_refunds: 419033.7677,
            },
            net_total: {
              without_refunds: 27071.7607,
              with_refunds: 25285.9355,
            },
            gross_total: {
              without_refunds: 29397.0762,
              with_refunds: 27541.4968,
            },
            net_total_spread: {
              without_refunds: 285381.1435,
              with_refunds: 286269.4117,
            },
            gross_total_spread: {
              without_refunds: 318617.3429,
              with_refunds: 319501.0328,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 3.01,
                with_refunds: 2.95,
              },
              gross_mrr: {
                without_refunds: 3.41,
                with_refunds: 3.34,
              },
              net_arr: {
                without_refunds: 0.51,
                with_refunds: 0.38,
              },
              gross_arr: {
                without_refunds: 0.64,
                with_refunds: 0.48,
              },
              net_arr_spread: {
                without_refunds: 8.9,
                with_refunds: 8.9,
              },
              gross_arr_spread: {
                without_refunds: 10.03,
                with_refunds: 10.03,
              },
              net_total: {
                without_refunds: 2.1,
                with_refunds: 2.01,
              },
              gross_total: {
                without_refunds: 2.4,
                with_refunds: 2.29,
              },
              net_total_spread: {
                without_refunds: 4.81,
                with_refunds: 4.77,
              },
              gross_total_spread: {
                without_refunds: 5.44,
                with_refunds: 5.39,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.55,
                with_refunds: 0.51,
              },
              gross_mrr: {
                without_refunds: 0.6,
                with_refunds: 0.56,
              },
              net_arr: {
                without_refunds: 0.01,
              },
              gross_arr: {
                without_refunds: 0.01,
              },
              net_arr_spread: {
                without_refunds: 11.66,
                with_refunds: 11.66,
              },
              gross_arr_spread: {
                without_refunds: 12.46,
                with_refunds: 12.46,
              },
              net_total: {
                without_refunds: 0.14,
                with_refunds: 0.15,
              },
              gross_total: {
                without_refunds: 0.15,
                with_refunds: 0.16,
              },
              net_total_spread: {
                without_refunds: 5.09,
                with_refunds: 5.07,
              },
              gross_total_spread: {
                without_refunds: 5.43,
                with_refunds: 5.4,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.64,
                with_refunds: 0.63,
              },
              gross_mrr: {
                without_refunds: 0.73,
                with_refunds: 0.72,
              },
              net_arr: {
                without_refunds: 0.03,
                with_refunds: 0.02,
              },
              gross_arr: {
                without_refunds: 0.03,
                with_refunds: 0.02,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.76,
                with_refunds: 11.76,
              },
              net_total: {
                without_refunds: 0.21,
                with_refunds: 0.19,
              },
              gross_total: {
                without_refunds: 0.24,
                with_refunds: 0.22,
              },
              net_total_spread: {
                without_refunds: 6.23,
                with_refunds: 6.24,
              },
              gross_total_spread: {
                without_refunds: 6.91,
                with_refunds: 6.91,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.04,
                with_refunds: 19.07,
              },
              gross_mrr: {
                without_refunds: 23.2,
                with_refunds: 22.09,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.2,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.62,
              },
              net_arr_spread: {
                without_refunds: 41.33,
                with_refunds: 41.33,
              },
              gross_arr_spread: {
                without_refunds: 44.57,
                with_refunds: 44.57,
              },
              net_total: {
                without_refunds: 60.26,
                with_refunds: 56.03,
              },
              gross_total: {
                without_refunds: 65.48,
                with_refunds: 61,
              },
              net_total_spread: {
                without_refunds: 36,
                with_refunds: 34.75,
              },
              gross_total_spread: {
                without_refunds: 40.79,
                with_refunds: 39.36,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.23,
                with_refunds: 21.38,
              },
              gross_mrr: {
                without_refunds: 25.44,
                with_refunds: 24.48,
              },
              net_arr: {
                without_refunds: 65.03,
                with_refunds: 58.06,
              },
              gross_arr: {
                without_refunds: 72.38,
                with_refunds: 64.68,
              },
              net_arr_spread: {
                without_refunds: 29.41,
                with_refunds: 29.41,
              },
              gross_arr_spread: {
                without_refunds: 32.88,
                with_refunds: 32.88,
              },
              net_total: {
                without_refunds: 67.66,
                with_refunds: 59.41,
              },
              gross_total: {
                without_refunds: 75.44,
                with_refunds: 66.32,
              },
              net_total_spread: {
                without_refunds: 29.57,
                with_refunds: 28.93,
              },
              gross_total_spread: {
                without_refunds: 33.47,
                with_refunds: 32.74,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.09,
                with_refunds: 11.77,
              },
              gross_mrr: {
                without_refunds: 13.7,
                with_refunds: 13.34,
              },
              net_arr: {
                without_refunds: 105.96,
                with_refunds: 59.1,
              },
              gross_arr: {
                without_refunds: 127,
                with_refunds: 72.97,
              },
              net_arr_spread: {
                without_refunds: 8.98,
                with_refunds: 8.98,
              },
              gross_arr_spread: {
                without_refunds: 10.13,
                with_refunds: 10.13,
              },
              net_total: {
                without_refunds: 14.09,
                with_refunds: 12.24,
              },
              gross_total: {
                without_refunds: 16.03,
                with_refunds: 14.04,
              },
              net_total_spread: {
                without_refunds: 9.92,
                with_refunds: 9.8,
              },
              gross_total_spread: {
                without_refunds: 11.23,
                with_refunds: 11.1,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.6,
                with_refunds: 18,
              },
              gross_mrr: {
                without_refunds: 21.23,
                with_refunds: 19.51,
              },
              net_arr: {
                without_refunds: 105.44,
              },
              gross_arr: {
                without_refunds: 129.54,
              },
              net_arr_spread: {
                without_refunds: 11.45,
                with_refunds: 11.45,
              },
              gross_arr_spread: {
                without_refunds: 12.23,
                with_refunds: 12.23,
              },
              net_total: {
                without_refunds: 13.9,
                with_refunds: 13.53,
              },
              gross_total: {
                without_refunds: 14.96,
                with_refunds: 14.52,
              },
              net_total_spread: {
                without_refunds: 11.83,
                with_refunds: 11.78,
              },
              gross_total_spread: {
                without_refunds: 12.62,
                with_refunds: 12.57,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.28,
                with_refunds: 13.81,
              },
              gross_mrr: {
                without_refunds: 16.17,
                with_refunds: 15.66,
              },
              net_arr: {
                without_refunds: 114.93,
                with_refunds: 34.56,
              },
              gross_arr: {
                without_refunds: 133.17,
                with_refunds: 44.01,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.76,
                with_refunds: 11.76,
              },
              net_total: {
                without_refunds: 17.42,
                with_refunds: 11.65,
              },
              gross_total: {
                without_refunds: 19.41,
                with_refunds: 13.51,
              },
              net_total_spread: {
                without_refunds: 10.49,
                with_refunds: 10.49,
              },
              gross_total_spread: {
                without_refunds: 11.62,
                with_refunds: 11.63,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.04,
                with_refunds: 19.07,
              },
              gross_mrr: {
                without_refunds: 23.2,
                with_refunds: 22.09,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.2,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.62,
              },
              net_arr_spread: {
                without_refunds: 41.33,
                with_refunds: 41.33,
              },
              gross_arr_spread: {
                without_refunds: 44.57,
                with_refunds: 44.57,
              },
              net_total: {
                without_refunds: 60.26,
                with_refunds: 56.03,
              },
              gross_total: {
                without_refunds: 65.48,
                with_refunds: 61,
              },
              net_total_spread: {
                without_refunds: 36,
                with_refunds: 34.75,
              },
              gross_total_spread: {
                without_refunds: 40.79,
                with_refunds: 39.36,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.23,
                with_refunds: 21.38,
              },
              gross_mrr: {
                without_refunds: 25.44,
                with_refunds: 24.48,
              },
              net_arr: {
                without_refunds: 65.03,
                with_refunds: 58.06,
              },
              gross_arr: {
                without_refunds: 72.38,
                with_refunds: 64.68,
              },
              net_arr_spread: {
                without_refunds: 29.41,
                with_refunds: 29.41,
              },
              gross_arr_spread: {
                without_refunds: 32.88,
                with_refunds: 32.88,
              },
              net_total: {
                without_refunds: 67.66,
                with_refunds: 59.41,
              },
              gross_total: {
                without_refunds: 75.44,
                with_refunds: 66.32,
              },
              net_total_spread: {
                without_refunds: 29.57,
                with_refunds: 28.93,
              },
              gross_total_spread: {
                without_refunds: 33.47,
                with_refunds: 32.74,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 33.74,
                  with_refunds: 32.8,
                },
                gross_mrr: {
                  without_refunds: 39.55,
                  with_refunds: 38.47,
                },
                net_arr: {
                  without_refunds: 108.45,
                  with_refunds: 87.66,
                },
                gross_arr: {
                  without_refunds: 121.75,
                  with_refunds: 98.72,
                },
                net_arr_spread: {
                  without_refunds: 48.65,
                  with_refunds: 48.65,
                },
                gross_arr_spread: {
                  without_refunds: 54.84,
                  with_refunds: 54.84,
                },
                net_total: {
                  without_refunds: 61.95,
                  with_refunds: 53.59,
                },
                gross_total: {
                  without_refunds: 70.61,
                  with_refunds: 61.32,
                },
                net_total_spread: {
                  without_refunds: 38.7,
                  with_refunds: 38.06,
                },
                gross_total_spread: {
                  without_refunds: 44.66,
                  with_refunds: 43.92,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 28.38,
                  with_refunds: 27,
                },
                gross_mrr: {
                  without_refunds: 32.18,
                  with_refunds: 30.59,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 65.26,
                  with_refunds: 65.26,
                },
                gross_arr_spread: {
                  without_refunds: 69.78,
                  with_refunds: 69.78,
                },
                net_total: {
                  without_refunds: 81.91,
                  with_refunds: 73.97,
                },
                gross_total: {
                  without_refunds: 88.24,
                  with_refunds: 79.82,
                },
                net_total_spread: {
                  without_refunds: 45.11,
                  with_refunds: 44.38,
                },
                gross_total_spread: {
                  without_refunds: 49.2,
                  with_refunds: 48.34,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 32.06,
                  with_refunds: 30.55,
                },
                gross_mrr: {
                  without_refunds: 36.51,
                  with_refunds: 34.83,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.72,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.33,
                },
                net_arr_spread: {
                  without_refunds: 58.19,
                  with_refunds: 58.19,
                },
                gross_arr_spread: {
                  without_refunds: 64.52,
                  with_refunds: 64.52,
                },
                net_total: {
                  without_refunds: 98.05,
                  with_refunds: 78.12,
                },
                gross_total: {
                  without_refunds: 108.66,
                  with_refunds: 86.75,
                },
                net_total_spread: {
                  without_refunds: 47.83,
                  with_refunds: 47.28,
                },
                gross_total_spread: {
                  without_refunds: 53.39,
                  with_refunds: 52.78,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 71.53,
                  with_refunds: 69.32,
                },
                gross_mrr: {
                  without_refunds: 83.11,
                  with_refunds: 80.58,
                },
                net_arr: {
                  without_refunds: 660.65,
                  with_refunds: 302.63,
                },
                gross_arr: {
                  without_refunds: 782.05,
                  with_refunds: 364.15,
                },
                net_arr_spread: {
                  without_refunds: 53.49,
                  with_refunds: 53.49,
                },
                gross_arr_spread: {
                  without_refunds: 60.39,
                  with_refunds: 60.39,
                },
                net_total: {
                  without_refunds: 141.82,
                  with_refunds: 118.25,
                },
                gross_total: {
                  without_refunds: 161.47,
                  with_refunds: 135.36,
                },
                net_total_spread: {
                  without_refunds: 62.13,
                  with_refunds: 61.02,
                },
                gross_total_spread: {
                  without_refunds: 71.68,
                  with_refunds: 70.4,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 116.2,
                  with_refunds: 108.57,
                },
                gross_mrr: {
                  without_refunds: 128.35,
                  with_refunds: 119.87,
                },
                net_arr: {
                  without_refunds: 707.59,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 809.51,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 68.45,
                  with_refunds: 68.45,
                },
                gross_arr_spread: {
                  without_refunds: 73.26,
                  with_refunds: 73.26,
                },
                net_total: {
                  without_refunds: 207.07,
                  with_refunds: 180.53,
                },
                gross_total: {
                  without_refunds: 224.15,
                  with_refunds: 194.83,
                },
                net_total_spread: {
                  without_refunds: 78.57,
                  with_refunds: 77.44,
                },
                gross_total_spread: {
                  without_refunds: 85.43,
                  with_refunds: 84.11,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 85.98,
                  with_refunds: 81.66,
                },
                gross_mrr: {
                  without_refunds: 97.38,
                  with_refunds: 92.57,
                },
                net_arr: {
                  without_refunds: 723.29,
                  with_refunds: 229.19,
                },
                gross_arr: {
                  without_refunds: 822.37,
                  with_refunds: 262.12,
                },
                net_arr_spread: {
                  without_refunds: 63.13,
                  with_refunds: 63.13,
                },
                gross_arr_spread: {
                  without_refunds: 70.07,
                  with_refunds: 70.07,
                },
                net_total: {
                  without_refunds: 245.32,
                  with_refunds: 178.06,
                },
                gross_total: {
                  without_refunds: 272.81,
                  with_refunds: 199.27,
                },
                net_total_spread: {
                  without_refunds: 67.15,
                  with_refunds: 66.31,
                },
                gross_total_spread: {
                  without_refunds: 74.92,
                  with_refunds: 73.98,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.5696,
                  with_refunds: 0.553,
                },
                gross_mrr: {
                  without_refunds: 0.6663,
                  with_refunds: 0.647,
                },
                net_arr: {
                  without_refunds: 1.8694,
                  with_refunds: 1.5479,
                },
                gross_arr: {
                  without_refunds: 2.0858,
                  with_refunds: 1.7301,
                },
                net_arr_spread: {
                  without_refunds: 0.8595,
                  with_refunds: 0.8595,
                },
                gross_arr_spread: {
                  without_refunds: 0.9617,
                  with_refunds: 0.9617,
                },
                net_total: {
                  without_refunds: 1.0606,
                  with_refunds: 0.9302,
                },
                gross_total: {
                  without_refunds: 1.2029,
                  with_refunds: 1.0581,
                },
                net_total_spread: {
                  without_refunds: 0.6651,
                  with_refunds: 0.6537,
                },
                gross_total_spread: {
                  without_refunds: 0.7639,
                  with_refunds: 0.7508,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.6836,
                  with_refunds: 1.6097,
                },
                gross_mrr: {
                  without_refunds: 1.9209,
                  with_refunds: 1.8339,
                },
                net_arr: {
                  without_refunds: 8.9844,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5709,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 4.081,
                  with_refunds: 4.081,
                },
                gross_arr_spread: {
                  without_refunds: 4.3637,
                  with_refunds: 4.3637,
                },
                net_total: {
                  without_refunds: 5.2857,
                  with_refunds: 4.7521,
                },
                gross_total: {
                  without_refunds: 5.6958,
                  with_refunds: 5.1275,
                },
                net_total_spread: {
                  without_refunds: 2.7452,
                  with_refunds: 2.7051,
                },
                gross_total_spread: {
                  without_refunds: 2.9987,
                  with_refunds: 2.951,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7593,
                  with_refunds: 0.725,
                },
                gross_mrr: {
                  without_refunds: 0.8668,
                  with_refunds: 0.8277,
                },
                net_arr: {
                  without_refunds: 2.2762,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4823,
                  with_refunds: 2.0279,
                },
                net_arr_spread: {
                  without_refunds: 1.0855,
                  with_refunds: 1.0855,
                },
                gross_arr_spread: {
                  without_refunds: 1.187,
                  with_refunds: 1.187,
                },
                net_total: {
                  without_refunds: 1.8044,
                  with_refunds: 1.5087,
                },
                gross_total: {
                  without_refunds: 1.9802,
                  with_refunds: 1.6574,
                },
                net_total_spread: {
                  without_refunds: 0.9507,
                  with_refunds: 0.9379,
                },
                gross_total_spread: {
                  without_refunds: 1.0531,
                  with_refunds: 1.0384,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.2666,
                  with_refunds: 1.2202,
                },
                gross_mrr: {
                  without_refunds: 1.4633,
                  with_refunds: 1.4107,
                },
                net_arr: {
                  without_refunds: 11.1342,
                  with_refunds: 5.1765,
                },
                gross_arr: {
                  without_refunds: 13.2667,
                  with_refunds: 6.1822,
                },
                net_arr_spread: {
                  without_refunds: 0.9442,
                  with_refunds: 0.9442,
                },
                gross_arr_spread: {
                  without_refunds: 1.0579,
                  with_refunds: 1.0579,
                },
                net_total: {
                  without_refunds: 2.4518,
                  with_refunds: 2.1077,
                },
                gross_total: {
                  without_refunds: 2.7782,
                  with_refunds: 2.3939,
                },
                net_total_spread: {
                  without_refunds: 1.0841,
                  with_refunds: 1.0645,
                },
                gross_total_spread: {
                  without_refunds: 1.2441,
                  with_refunds: 1.2214,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 6.4507,
                  with_refunds: 5.9479,
                },
                gross_mrr: {
                  without_refunds: 7.1323,
                  with_refunds: 6.5766,
                },
                net_arr: {
                  without_refunds: 44.8748,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 52.3909,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 4.4087,
                  with_refunds: 4.4087,
                },
                gross_arr_spread: {
                  without_refunds: 4.7186,
                  with_refunds: 4.7186,
                },
                net_total: {
                  without_refunds: 13.4917,
                  with_refunds: 12.1546,
                },
                gross_total: {
                  without_refunds: 14.6367,
                  with_refunds: 13.1234,
                },
                net_total_spread: {
                  without_refunds: 4.6709,
                  with_refunds: 4.6044,
                },
                gross_total_spread: {
                  without_refunds: 5.0898,
                  with_refunds: 5.0113,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 2.3717,
                  with_refunds: 2.2137,
                },
                gross_mrr: {
                  without_refunds: 2.653,
                  with_refunds: 2.4781,
                },
                net_arr: {
                  without_refunds: 12.2847,
                  with_refunds: 4.1861,
                },
                gross_arr: {
                  without_refunds: 14.1372,
                  with_refunds: 4.7002,
                },
                net_arr_spread: {
                  without_refunds: 1.1797,
                  with_refunds: 1.1797,
                },
                gross_arr_spread: {
                  without_refunds: 1.2914,
                  with_refunds: 1.2914,
                },
                net_total: {
                  without_refunds: 4.5328,
                  with_refunds: 3.6232,
                },
                gross_total: {
                  without_refunds: 4.9969,
                  with_refunds: 3.9945,
                },
                net_total_spread: {
                  without_refunds: 1.4564,
                  with_refunds: 1.4361,
                },
                gross_total_spread: {
                  without_refunds: 1.6098,
                  with_refunds: 1.5864,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 6,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.196,
                with_refunds: 0.1968,
              },
              arr_users: {
                without_refunds: 0.0043,
                with_refunds: 0.0046,
              },
              arr_spread_users: {
                without_refunds: 0.9933,
                with_refunds: 0.9933,
              },
              total_users: {
                without_refunds: 0.1258,
                with_refunds: 0.1263,
              },
              total_spread_users: {
                without_refunds: 0.4338,
                with_refunds: 0.434,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.772,
                with_refunds: 0.7714,
              },
              arr_users: {
                without_refunds: 0.4274,
                with_refunds: 0.649,
              },
              arr_spread_users: {
                without_refunds: 1.005,
                with_refunds: 1.005,
              },
              total_users: {
                without_refunds: 0.7526,
                with_refunds: 0.7511,
              },
              total_spread_users: {
                without_refunds: 0.8967,
                with_refunds: 0.8958,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.019,
                with_refunds: 0.0192,
              },
              arr_users: {
                with_refunds: 0.0001,
              },
              arr_spread_users: {
                without_refunds: 1.022,
                with_refunds: 1.022,
              },
              total_users: {
                without_refunds: 0.0064,
                with_refunds: 0.0066,
              },
              total_spread_users: {
                without_refunds: 0.3815,
                with_refunds: 0.3816,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6803,
                with_refunds: 0.6785,
              },
              arr_users: {
                without_refunds: 0.1905,
                with_refunds: 0.4881,
              },
              arr_spread_users: {
                without_refunds: 1.002,
                with_refunds: 1.002,
              },
              total_users: {
                without_refunds: 0.6778,
                with_refunds: 0.6753,
              },
              total_spread_users: {
                without_refunds: 0.8884,
                with_refunds: 0.8876,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.032,
                with_refunds: 0.032,
              },
              arr_users: {
                with_refunds: 0.0002,
              },
              arr_spread_users: {
                without_refunds: 1.0019,
                with_refunds: 1.0019,
              },
              total_users: {
                without_refunds: 0.0098,
                with_refunds: 0.0098,
              },
              total_spread_users: {
                without_refunds: 0.5281,
                with_refunds: 0.5278,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6803,
                with_refunds: 0.6785,
              },
              arr_users: {
                without_refunds: 0.1905,
                with_refunds: 0.4881,
              },
              arr_spread_users: {
                without_refunds: 1.002,
                with_refunds: 1.002,
              },
              total_users: {
                without_refunds: 0.6778,
                with_refunds: 0.6753,
              },
              total_spread_users: {
                without_refunds: 0.8884,
                with_refunds: 0.8876,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.3335,
                with_refunds: 0.3362,
              },
              gross_mrr: {
                without_refunds: 0.2983,
                with_refunds: 0.301,
              },
              net_arr: {
                without_refunds: 0.0112,
                with_refunds: 0.0097,
              },
              gross_arr: {
                without_refunds: 0.0112,
                with_refunds: 0.0099,
              },
              net_arr_spread: {
                without_refunds: 1.9857,
                with_refunds: 1.9857,
              },
              gross_arr_spread: {
                without_refunds: 1.9693,
                with_refunds: 1.9693,
              },
              net_total: {
                without_refunds: 0.1162,
                with_refunds: 0.1169,
              },
              gross_total: {
                without_refunds: 0.1026,
                with_refunds: 0.1033,
              },
              net_total_spread: {
                without_refunds: 0.6699,
                with_refunds: 0.6817,
              },
              gross_total_spread: {
                without_refunds: 0.6327,
                with_refunds: 0.6445,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.77,
                with_refunds: 0.7738,
              },
              gross_mrr: {
                without_refunds: 0.7665,
                with_refunds: 0.7697,
              },
              net_arr: {
                without_refunds: 0.4411,
                with_refunds: 0.4303,
              },
              gross_arr: {
                without_refunds: 0.4413,
                with_refunds: 0.436,
              },
              net_arr_spread: {
                without_refunds: 1.0069,
                with_refunds: 1.0069,
              },
              gross_arr_spread: {
                without_refunds: 1.007,
                with_refunds: 1.007,
              },
              net_total: {
                without_refunds: 0.754,
                with_refunds: 0.7671,
              },
              gross_total: {
                without_refunds: 0.7522,
                with_refunds: 0.7653,
              },
              net_total_spread: {
                without_refunds: 0.873,
                with_refunds: 0.8757,
              },
              gross_total_spread: {
                without_refunds: 0.872,
                with_refunds: 0.8745,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0276,
                with_refunds: 0.0275,
              },
              gross_mrr: {
                without_refunds: 0.0257,
                with_refunds: 0.0256,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 1.6933,
                with_refunds: 1.6933,
              },
              gross_arr_spread: {
                without_refunds: 1.6754,
                with_refunds: 1.6754,
              },
              net_total: {
                without_refunds: 0.002,
                with_refunds: 0.0024,
              },
              gross_total: {
                without_refunds: 0.002,
                with_refunds: 0.0024,
              },
              net_total_spread: {
                without_refunds: 0.423,
                with_refunds: 0.4384,
              },
              gross_total_spread: {
                without_refunds: 0.397,
                with_refunds: 0.4114,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6851,
                with_refunds: 0.6973,
              },
              gross_mrr: {
                without_refunds: 0.6838,
                with_refunds: 0.6948,
              },
              net_arr: {
                without_refunds: 0.2058,
              },
              gross_arr: {
                without_refunds: 0.1926,
              },
              net_arr_spread: {
                without_refunds: 1.0024,
                with_refunds: 1.0024,
              },
              gross_arr_spread: {
                without_refunds: 1.0026,
                with_refunds: 1.0026,
              },
              net_total: {
                without_refunds: 0.6669,
                with_refunds: 0.6901,
              },
              gross_total: {
                without_refunds: 0.6613,
                with_refunds: 0.6867,
              },
              net_total_spread: {
                without_refunds: 0.8595,
                with_refunds: 0.8655,
              },
              gross_total_spread: {
                without_refunds: 0.8589,
                with_refunds: 0.8644,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.05,
                with_refunds: 0.052,
              },
              gross_mrr: {
                without_refunds: 0.0495,
                with_refunds: 0.0514,
              },
              net_arr: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              gross_arr: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              net_arr_spread: {
                without_refunds: 2.0447,
                with_refunds: 2.0447,
              },
              gross_arr_spread: {
                without_refunds: 2.0225,
                with_refunds: 2.0225,
              },
              net_total: {
                without_refunds: 0.0039,
                with_refunds: 0.0044,
              },
              gross_total: {
                without_refunds: 0.0039,
                with_refunds: 0.0044,
              },
              net_total_spread: {
                without_refunds: 0.8227,
                with_refunds: 0.8452,
              },
              gross_total_spread: {
                without_refunds: 0.8091,
                with_refunds: 0.8309,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6851,
                with_refunds: 0.6973,
              },
              gross_mrr: {
                without_refunds: 0.6838,
                with_refunds: 0.6948,
              },
              net_arr: {
                without_refunds: 0.2058,
                with_refunds: 0.0913,
              },
              gross_arr: {
                without_refunds: 0.1926,
                with_refunds: 0.0896,
              },
              net_arr_spread: {
                without_refunds: 1.0024,
                with_refunds: 1.0024,
              },
              gross_arr_spread: {
                without_refunds: 1.0026,
                with_refunds: 1.0026,
              },
              net_total: {
                without_refunds: 0.6669,
                with_refunds: 0.6901,
              },
              gross_total: {
                without_refunds: 0.6613,
                with_refunds: 0.6867,
              },
              net_total_spread: {
                without_refunds: 0.8595,
                with_refunds: 0.8655,
              },
              gross_total_spread: {
                without_refunds: 0.8589,
                with_refunds: 0.8644,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 16908.3364,
              with_refunds: 16570.9775,
            },
            gross_mrr: {
              without_refunds: 18939.7953,
              with_refunds: 18571.0487,
            },
            net_arr: {
              without_refunds: 1106.1872,
              with_refunds: 729.4517,
            },
            gross_arr: {
              without_refunds: 1158.0397,
              with_refunds: 753.6248,
            },
            net_arr_spread: {
              without_refunds: 423501.575,
              with_refunds: 423501.575,
            },
            gross_arr_spread: {
              without_refunds: 470692.6412,
              with_refunds: 470692.6412,
            },
            net_total: {
              without_refunds: 15535.387,
              with_refunds: 15008.3196,
            },
            gross_total: {
              without_refunds: 17257.6919,
              with_refunds: 16744.8349,
            },
            net_total_spread: {
              without_refunds: 300945.4654,
              with_refunds: 302350.1469,
            },
            gross_total_spread: {
              without_refunds: 336154.071,
              with_refunds: 337684.6715,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 20461.9195,
              with_refunds: 19859.0899,
            },
            gross_mrr: {
              without_refunds: 22794.6279,
              with_refunds: 22058.4165,
            },
            net_arr: {
              without_refunds: 13061.9888,
              with_refunds: 11327.1744,
            },
            gross_arr: {
              without_refunds: 13646.4521,
              with_refunds: 11850.7142,
            },
            net_arr_spread: {
              without_refunds: 426534.3946,
              with_refunds: 426534.3946,
            },
            gross_arr_spread: {
              without_refunds: 473870.1058,
              with_refunds: 473870.1058,
            },
            net_total: {
              without_refunds: 29898.0999,
              with_refunds: 27822.3341,
            },
            gross_total: {
              without_refunds: 32377.98,
              with_refunds: 30217.4716,
            },
            net_total_spread: {
              without_refunds: 308359.209,
              with_refunds: 309433.806,
            },
            gross_total_spread: {
              without_refunds: 344071.8354,
              with_refunds: 345125.6028,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 2.3,
                with_refunds: 2.25,
              },
              gross_mrr: {
                without_refunds: 2.59,
                with_refunds: 2.54,
              },
              net_arr: {
                without_refunds: 0.51,
                with_refunds: 0.41,
              },
              gross_arr: {
                without_refunds: 0.65,
                with_refunds: 0.52,
              },
              net_arr_spread: {
                without_refunds: 8.94,
                with_refunds: 8.94,
              },
              gross_arr_spread: {
                without_refunds: 10.09,
                with_refunds: 10.09,
              },
              net_total: {
                without_refunds: 1.65,
                with_refunds: 1.58,
              },
              gross_total: {
                without_refunds: 1.89,
                with_refunds: 1.81,
              },
              net_total_spread: {
                without_refunds: 4.17,
                with_refunds: 4.15,
              },
              gross_total_spread: {
                without_refunds: 4.71,
                with_refunds: 4.68,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.38,
                with_refunds: 0.36,
              },
              gross_mrr: {
                without_refunds: 0.41,
                with_refunds: 0.39,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 11.69,
                with_refunds: 11.69,
              },
              gross_arr_spread: {
                without_refunds: 12.49,
                with_refunds: 12.49,
              },
              net_total: {
                without_refunds: 0.09,
                with_refunds: 0.1,
              },
              gross_total: {
                without_refunds: 0.1,
                with_refunds: 0.11,
              },
              net_total_spread: {
                without_refunds: 4.37,
                with_refunds: 4.39,
              },
              gross_total_spread: {
                without_refunds: 4.66,
                with_refunds: 4.67,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.44,
                with_refunds: 0.44,
              },
              gross_mrr: {
                without_refunds: 0.5,
                with_refunds: 0.5,
              },
              net_arr: {
                without_refunds: 0.01,
              },
              gross_arr: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              net_arr_spread: {
                without_refunds: 10.64,
                with_refunds: 10.64,
              },
              gross_arr_spread: {
                without_refunds: 11.79,
                with_refunds: 11.79,
              },
              net_total: {
                without_refunds: 0.14,
                with_refunds: 0.13,
              },
              gross_total: {
                without_refunds: 0.16,
                with_refunds: 0.15,
              },
              net_total_spread: {
                without_refunds: 5.36,
                with_refunds: 5.4,
              },
              gross_total_spread: {
                without_refunds: 5.93,
                with_refunds: 5.98,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.3,
                with_refunds: 19.32,
              },
              gross_mrr: {
                without_refunds: 23.5,
                with_refunds: 22.38,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 48.71,
                with_refunds: 48.71,
              },
              gross_arr_spread: {
                without_refunds: 52.53,
                with_refunds: 52.53,
              },
              net_total: {
                without_refunds: 60.58,
                with_refunds: 56.33,
              },
              gross_total: {
                without_refunds: 65.83,
                with_refunds: 61.33,
              },
              net_total_spread: {
                without_refunds: 40.07,
                with_refunds: 38.68,
              },
              gross_total_spread: {
                without_refunds: 45.39,
                with_refunds: 43.8,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.54,
                with_refunds: 21.67,
              },
              gross_mrr: {
                without_refunds: 25.79,
                with_refunds: 24.81,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.38,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 34.76,
                with_refunds: 34.76,
              },
              gross_arr_spread: {
                without_refunds: 38.87,
                with_refunds: 38.87,
              },
              net_total: {
                without_refunds: 68.12,
                with_refunds: 59.81,
              },
              gross_total: {
                without_refunds: 75.95,
                with_refunds: 66.76,
              },
              net_total_spread: {
                without_refunds: 33.23,
                with_refunds: 32.5,
              },
              gross_total_spread: {
                without_refunds: 37.6,
                with_refunds: 36.78,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.05,
                with_refunds: 11.8,
              },
              gross_mrr: {
                without_refunds: 13.58,
                with_refunds: 13.31,
              },
              net_arr: {
                without_refunds: 97.88,
                with_refunds: 43.46,
              },
              gross_arr: {
                without_refunds: 113.92,
                with_refunds: 54.88,
              },
              net_arr_spread: {
                without_refunds: 8.99,
                with_refunds: 8.99,
              },
              gross_arr_spread: {
                without_refunds: 10.15,
                with_refunds: 10.15,
              },
              net_total: {
                without_refunds: 14,
                with_refunds: 12.42,
              },
              gross_total: {
                without_refunds: 15.86,
                with_refunds: 14.21,
              },
              net_total_spread: {
                without_refunds: 9.63,
                with_refunds: 9.56,
              },
              gross_total_spread: {
                without_refunds: 10.9,
                with_refunds: 10.81,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.73,
                with_refunds: 18.5,
              },
              gross_mrr: {
                without_refunds: 21.34,
                with_refunds: 19.98,
              },
              net_arr: {
                without_refunds: 113.92,
              },
              gross_arr: {
                without_refunds: 131.01,
              },
              net_arr_spread: {
                without_refunds: 11.45,
                with_refunds: 11.45,
              },
              gross_arr_spread: {
                without_refunds: 12.24,
                with_refunds: 12.24,
              },
              net_total: {
                without_refunds: 13.68,
                with_refunds: 13.83,
              },
              gross_total: {
                without_refunds: 14.6,
                with_refunds: 14.76,
              },
              net_total_spread: {
                without_refunds: 11.44,
                with_refunds: 11.49,
              },
              gross_total_spread: {
                without_refunds: 12.2,
                with_refunds: 12.24,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.38,
                with_refunds: 14.19,
              },
              gross_mrr: {
                without_refunds: 16.25,
                with_refunds: 16.04,
              },
              net_arr: {
                without_refunds: 124.18,
                with_refunds: 18.36,
              },
              gross_arr: {
                without_refunds: 134.68,
                with_refunds: 22.93,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.77,
                with_refunds: 11.77,
              },
              net_total: {
                without_refunds: 17.14,
                with_refunds: 11.91,
              },
              gross_total: {
                without_refunds: 18.94,
                with_refunds: 13.74,
              },
              net_total_spread: {
                without_refunds: 10.15,
                with_refunds: 10.23,
              },
              gross_total_spread: {
                without_refunds: 11.24,
                with_refunds: 11.32,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.3,
                with_refunds: 19.32,
              },
              gross_mrr: {
                without_refunds: 23.5,
                with_refunds: 22.38,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 48.71,
                with_refunds: 48.71,
              },
              gross_arr_spread: {
                without_refunds: 52.53,
                with_refunds: 52.53,
              },
              net_total: {
                without_refunds: 60.58,
                with_refunds: 56.33,
              },
              gross_total: {
                without_refunds: 65.83,
                with_refunds: 61.33,
              },
              net_total_spread: {
                without_refunds: 40.07,
                with_refunds: 38.68,
              },
              gross_total_spread: {
                without_refunds: 45.39,
                with_refunds: 43.8,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.54,
                with_refunds: 21.67,
              },
              gross_mrr: {
                without_refunds: 25.79,
                with_refunds: 24.81,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.38,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 34.76,
                with_refunds: 34.76,
              },
              gross_arr_spread: {
                without_refunds: 38.87,
                with_refunds: 38.87,
              },
              net_total: {
                without_refunds: 68.12,
                with_refunds: 59.81,
              },
              gross_total: {
                without_refunds: 75.95,
                with_refunds: 66.76,
              },
              net_total_spread: {
                without_refunds: 33.23,
                with_refunds: 32.5,
              },
              gross_total_spread: {
                without_refunds: 37.6,
                with_refunds: 36.78,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 36.04,
                  with_refunds: 35.06,
                },
                gross_mrr: {
                  without_refunds: 42.14,
                  with_refunds: 41.01,
                },
                net_arr: {
                  without_refunds: 108.95,
                  with_refunds: 88.07,
                },
                gross_arr: {
                  without_refunds: 122.4,
                  with_refunds: 99.25,
                },
                net_arr_spread: {
                  without_refunds: 57.6,
                  with_refunds: 57.6,
                },
                gross_arr_spread: {
                  without_refunds: 64.93,
                  with_refunds: 64.93,
                },
                net_total: {
                  without_refunds: 63.6,
                  with_refunds: 55.17,
                },
                gross_total: {
                  without_refunds: 72.5,
                  with_refunds: 63.13,
                },
                net_total_spread: {
                  without_refunds: 42.87,
                  with_refunds: 42.21,
                },
                gross_total_spread: {
                  without_refunds: 49.36,
                  with_refunds: 48.6,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 28.76,
                  with_refunds: 27.36,
                },
                gross_mrr: {
                  without_refunds: 32.59,
                  with_refunds: 30.98,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 76.94,
                  with_refunds: 76.94,
                },
                gross_arr_spread: {
                  without_refunds: 82.27,
                  with_refunds: 82.27,
                },
                net_total: {
                  without_refunds: 82,
                  with_refunds: 74.07,
                },
                gross_total: {
                  without_refunds: 88.34,
                  with_refunds: 79.93,
                },
                net_total_spread: {
                  without_refunds: 49.48,
                  with_refunds: 48.77,
                },
                gross_total_spread: {
                  without_refunds: 53.86,
                  with_refunds: 53.01,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 32.5,
                  with_refunds: 30.99,
                },
                gross_mrr: {
                  without_refunds: 37.01,
                  with_refunds: 35.33,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.73,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.34,
                },
                net_arr_spread: {
                  without_refunds: 68.83,
                  with_refunds: 68.83,
                },
                gross_arr_spread: {
                  without_refunds: 76.31,
                  with_refunds: 76.31,
                },
                net_total: {
                  without_refunds: 98.19,
                  with_refunds: 78.25,
                },
                gross_total: {
                  without_refunds: 108.81,
                  with_refunds: 86.91,
                },
                net_total_spread: {
                  without_refunds: 53.19,
                  with_refunds: 52.68,
                },
                gross_total_spread: {
                  without_refunds: 59.33,
                  with_refunds: 58.75,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 83.57,
                  with_refunds: 81.12,
                },
                gross_mrr: {
                  without_refunds: 96.69,
                  with_refunds: 93.89,
                },
                net_arr: {
                  without_refunds: 758.53,
                  with_refunds: 346.09,
                },
                gross_arr: {
                  without_refunds: 895.97,
                  with_refunds: 419.03,
                },
                net_arr_spread: {
                  without_refunds: 62.48,
                  with_refunds: 62.48,
                },
                gross_arr_spread: {
                  without_refunds: 70.53,
                  with_refunds: 70.53,
                },
                net_total: {
                  without_refunds: 155.82,
                  with_refunds: 130.66,
                },
                gross_total: {
                  without_refunds: 177.33,
                  with_refunds: 149.56,
                },
                net_total_spread: {
                  without_refunds: 71.76,
                  with_refunds: 70.58,
                },
                gross_total_spread: {
                  without_refunds: 82.58,
                  with_refunds: 81.22,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 135.94,
                  with_refunds: 127.07,
                },
                gross_mrr: {
                  without_refunds: 149.69,
                  with_refunds: 139.85,
                },
                net_arr: {
                  without_refunds: 821.51,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 940.52,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 79.9,
                  with_refunds: 79.9,
                },
                gross_arr_spread: {
                  without_refunds: 85.5,
                  with_refunds: 85.5,
                },
                net_total: {
                  without_refunds: 220.75,
                  with_refunds: 194.36,
                },
                gross_total: {
                  without_refunds: 238.75,
                  with_refunds: 209.59,
                },
                net_total_spread: {
                  without_refunds: 90.01,
                  with_refunds: 88.93,
                },
                gross_total_spread: {
                  without_refunds: 97.64,
                  with_refunds: 96.36,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 100.35,
                  with_refunds: 95.85,
                },
                gross_mrr: {
                  without_refunds: 113.64,
                  with_refunds: 108.61,
                },
                net_arr: {
                  without_refunds: 847.47,
                  with_refunds: 247.54,
                },
                gross_arr: {
                  without_refunds: 957.05,
                  with_refunds: 285.05,
                },
                net_arr_spread: {
                  without_refunds: 73.74,
                  with_refunds: 73.74,
                },
                gross_arr_spread: {
                  without_refunds: 81.84,
                  with_refunds: 81.84,
                },
                net_total: {
                  without_refunds: 262.46,
                  with_refunds: 189.97,
                },
                gross_total: {
                  without_refunds: 291.75,
                  with_refunds: 213,
                },
                net_total_spread: {
                  without_refunds: 77.3,
                  with_refunds: 76.55,
                },
                gross_total_spread: {
                  without_refunds: 86.15,
                  with_refunds: 85.3,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6068,
                  with_refunds: 0.5894,
                },
                gross_mrr: {
                  without_refunds: 0.7081,
                  with_refunds: 0.688,
                },
                net_arr: {
                  without_refunds: 1.8764,
                  with_refunds: 1.5534,
                },
                gross_arr: {
                  without_refunds: 2.0947,
                  with_refunds: 1.7373,
                },
                net_arr_spread: {
                  without_refunds: 1.0174,
                  with_refunds: 1.0174,
                },
                gross_arr_spread: {
                  without_refunds: 1.1385,
                  with_refunds: 1.1385,
                },
                net_total: {
                  without_refunds: 1.0868,
                  with_refunds: 0.9554,
                },
                gross_total: {
                  without_refunds: 1.2327,
                  with_refunds: 1.0868,
                },
                net_total_spread: {
                  without_refunds: 0.7359,
                  with_refunds: 0.7241,
                },
                gross_total_spread: {
                  without_refunds: 0.8434,
                  with_refunds: 0.8298,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7033,
                  with_refunds: 1.6279,
                },
                gross_mrr: {
                  without_refunds: 1.942,
                  with_refunds: 1.8535,
                },
                net_arr: {
                  without_refunds: 8.9846,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5711,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 4.8255,
                  with_refunds: 4.8255,
                },
                gross_arr_spread: {
                  without_refunds: 5.1592,
                  with_refunds: 5.1592,
                },
                net_total: {
                  without_refunds: 5.2937,
                  with_refunds: 4.7609,
                },
                gross_total: {
                  without_refunds: 5.7043,
                  with_refunds: 5.1369,
                },
                net_total_spread: {
                  without_refunds: 3.0128,
                  with_refunds: 2.9732,
                },
                gross_total_spread: {
                  without_refunds: 3.2836,
                  with_refunds: 3.2364,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7692,
                  with_refunds: 0.7346,
                },
                gross_mrr: {
                  without_refunds: 0.8778,
                  with_refunds: 0.8383,
                },
                net_arr: {
                  without_refunds: 2.2763,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4824,
                  with_refunds: 2.028,
                },
                net_arr_spread: {
                  without_refunds: 1.2842,
                  with_refunds: 1.2842,
                },
                gross_arr_spread: {
                  without_refunds: 1.4042,
                  with_refunds: 1.4042,
                },
                net_total: {
                  without_refunds: 1.8074,
                  with_refunds: 1.5118,
                },
                gross_total: {
                  without_refunds: 1.9835,
                  with_refunds: 1.6608,
                },
                net_total_spread: {
                  without_refunds: 1.0504,
                  with_refunds: 1.0381,
                },
                gross_total_spread: {
                  without_refunds: 1.162,
                  with_refunds: 1.1478,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.4795,
                  with_refunds: 1.4271,
                },
                gross_mrr: {
                  without_refunds: 1.7019,
                  with_refunds: 1.6426,
                },
                net_arr: {
                  without_refunds: 12.7467,
                  with_refunds: 5.8679,
                },
                gross_arr: {
                  without_refunds: 15.179,
                  with_refunds: 7.0549,
                },
                net_arr_spread: {
                  without_refunds: 1.1027,
                  with_refunds: 1.1027,
                },
                gross_arr_spread: {
                  without_refunds: 1.2356,
                  with_refunds: 1.2356,
                },
                net_total: {
                  without_refunds: 2.6877,
                  with_refunds: 2.3289,
                },
                gross_total: {
                  without_refunds: 3.0437,
                  with_refunds: 2.6438,
                },
                net_total_spread: {
                  without_refunds: 1.2505,
                  with_refunds: 1.2295,
                },
                gross_total_spread: {
                  without_refunds: 1.4313,
                  with_refunds: 1.407,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 7.5361,
                  with_refunds: 6.941,
                },
                gross_mrr: {
                  without_refunds: 8.3003,
                  with_refunds: 7.6449,
                },
                net_arr: {
                  without_refunds: 51.9747,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 60.8336,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 5.1472,
                  with_refunds: 5.1472,
                },
                gross_arr_spread: {
                  without_refunds: 5.5077,
                  with_refunds: 5.5077,
                },
                net_total: {
                  without_refunds: 14.4451,
                  with_refunds: 13.2008,
                },
                gross_total: {
                  without_refunds: 15.6557,
                  with_refunds: 14.2369,
                },
                net_total_spread: {
                  without_refunds: 5.3427,
                  with_refunds: 5.2777,
                },
                gross_total_spread: {
                  without_refunds: 5.8052,
                  with_refunds: 5.7278,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 2.7688,
                  with_refunds: 2.5896,
                },
                gross_mrr: {
                  without_refunds: 3.0895,
                  with_refunds: 2.8916,
                },
                net_arr: {
                  without_refunds: 14.3137,
                  with_refunds: 4.4635,
                },
                gross_arr: {
                  without_refunds: 16.4295,
                  with_refunds: 5.0466,
                },
                net_arr_spread: {
                  without_refunds: 1.3777,
                  with_refunds: 1.3777,
                },
                gross_arr_spread: {
                  without_refunds: 1.5079,
                  with_refunds: 1.5079,
                },
                net_total: {
                  without_refunds: 4.8557,
                  with_refunds: 3.9073,
                },
                gross_total: {
                  without_refunds: 5.3496,
                  with_refunds: 4.3091,
                },
                net_total_spread: {
                  without_refunds: 1.6708,
                  with_refunds: 1.6516,
                },
                gross_total_spread: {
                  without_refunds: 1.8433,
                  with_refunds: 1.821,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 7,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1576,
                with_refunds: 0.1582,
              },
              arr_users: {
                without_refunds: 0.0017,
                with_refunds: 0.0019,
              },
              arr_spread_users: {
                without_refunds: 0.9953,
                with_refunds: 0.9953,
              },
              total_users: {
                without_refunds: 0.1005,
                with_refunds: 0.1008,
              },
              total_spread_users: {
                without_refunds: 0.3956,
                with_refunds: 0.3957,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7736,
                with_refunds: 0.7733,
              },
              arr_users: {
                without_refunds: 0.1268,
                with_refunds: 0.168,
              },
              arr_spread_users: {
                without_refunds: 1.0021,
                with_refunds: 1.0021,
              },
              total_users: {
                without_refunds: 0.7306,
                with_refunds: 0.7309,
              },
              total_spread_users: {
                without_refunds: 0.9136,
                with_refunds: 0.9136,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0119,
                with_refunds: 0.012,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0225,
                with_refunds: 1.0225,
              },
              total_users: {
                without_refunds: 0.004,
                with_refunds: 0.0041,
              },
              total_spread_users: {
                without_refunds: 0.3418,
                with_refunds: 0.3417,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.626,
                with_refunds: 0.6268,
              },
              arr_users: {
                without_refunds: 0.0833,
                with_refunds: 0.1167,
              },
              arr_spread_users: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              total_users: {
                without_refunds: 0.6196,
                with_refunds: 0.6196,
              },
              total_spread_users: {
                without_refunds: 0.8959,
                with_refunds: 0.8955,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.02,
                with_refunds: 0.0201,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0024,
                with_refunds: 1.0024,
              },
              total_users: {
                without_refunds: 0.006,
                with_refunds: 0.0061,
              },
              total_spread_users: {
                without_refunds: 0.4731,
                with_refunds: 0.4726,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.626,
                with_refunds: 0.6268,
              },
              arr_users: {
                without_refunds: 0.0833,
                with_refunds: 0.1167,
              },
              arr_spread_users: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              total_users: {
                without_refunds: 0.6196,
                with_refunds: 0.6196,
              },
              total_spread_users: {
                without_refunds: 0.8959,
                with_refunds: 0.8955,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2699,
                with_refunds: 0.2727,
              },
              gross_mrr: {
                without_refunds: 0.2394,
                with_refunds: 0.2421,
              },
              net_arr: {
                without_refunds: 0.0043,
                with_refunds: 0.0035,
              },
              gross_arr: {
                without_refunds: 0.0039,
                with_refunds: 0.0032,
              },
              net_arr_spread: {
                without_refunds: 1.9907,
                with_refunds: 1.9907,
              },
              gross_arr_spread: {
                without_refunds: 1.9739,
                with_refunds: 1.9739,
              },
              net_total: {
                without_refunds: 0.0917,
                with_refunds: 0.0934,
              },
              gross_total: {
                without_refunds: 0.0791,
                with_refunds: 0.0808,
              },
              net_total_spread: {
                without_refunds: 0.5956,
                with_refunds: 0.607,
              },
              gross_total_spread: {
                without_refunds: 0.562,
                with_refunds: 0.5734,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7771,
                with_refunds: 0.7782,
              },
              gross_mrr: {
                without_refunds: 0.7761,
                with_refunds: 0.7777,
              },
              net_arr: {
                without_refunds: 0.1113,
                with_refunds: 0.0908,
              },
              gross_arr: {
                without_refunds: 0.1148,
                with_refunds: 0.0888,
              },
              net_arr_spread: {
                without_refunds: 1.0029,
                with_refunds: 1.0029,
              },
              gross_arr_spread: {
                without_refunds: 1.0027,
                with_refunds: 1.0027,
              },
              net_total: {
                without_refunds: 0.6767,
                with_refunds: 0.6818,
              },
              gross_total: {
                without_refunds: 0.6696,
                with_refunds: 0.6752,
              },
              net_total_spread: {
                without_refunds: 0.892,
                with_refunds: 0.8939,
              },
              gross_total_spread: {
                without_refunds: 0.8924,
                with_refunds: 0.8945,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0174,
                with_refunds: 0.0172,
              },
              gross_mrr: {
                without_refunds: 0.0163,
                with_refunds: 0.016,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 1.6942,
                with_refunds: 1.6942,
              },
              gross_arr_spread: {
                without_refunds: 1.6765,
                with_refunds: 1.6765,
              },
              net_total: {
                without_refunds: 0.0012,
                with_refunds: 0.0014,
              },
              gross_total: {
                without_refunds: 0.0011,
                with_refunds: 0.0013,
              },
              net_total_spread: {
                without_refunds: 0.3657,
                with_refunds: 0.3786,
              },
              gross_total_spread: {
                without_refunds: 0.3432,
                with_refunds: 0.3555,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6319,
                with_refunds: 0.6258,
              },
              gross_mrr: {
                without_refunds: 0.6319,
                with_refunds: 0.6268,
              },
              net_arr: {
                without_refunds: 0.0657,
              },
              gross_arr: {
                without_refunds: 0.0754,
              },
              net_arr_spread: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              gross_arr_spread: {
                without_refunds: 1.0007,
                with_refunds: 1.0007,
              },
              net_total: {
                without_refunds: 0.5691,
                with_refunds: 0.5667,
              },
              gross_total: {
                without_refunds: 0.566,
                with_refunds: 0.5633,
              },
              net_total_spread: {
                without_refunds: 0.8644,
                with_refunds: 0.8637,
              },
              gross_total_spread: {
                without_refunds: 0.8645,
                with_refunds: 0.8642,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0316,
                with_refunds: 0.0325,
              },
              gross_mrr: {
                without_refunds: 0.0313,
                with_refunds: 0.0322,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.0457,
                with_refunds: 2.0457,
              },
              gross_arr_spread: {
                without_refunds: 2.0238,
                with_refunds: 2.0238,
              },
              net_total: {
                without_refunds: 0.0022,
                with_refunds: 0.0025,
              },
              gross_total: {
                without_refunds: 0.0022,
                with_refunds: 0.0025,
              },
              net_total_spread: {
                without_refunds: 0.7111,
                with_refunds: 0.73,
              },
              gross_total_spread: {
                without_refunds: 0.6995,
                with_refunds: 0.718,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6319,
                with_refunds: 0.6258,
              },
              gross_mrr: {
                without_refunds: 0.6319,
                with_refunds: 0.6268,
              },
              net_arr: {
                without_refunds: 0.0657,
                with_refunds: 0.0164,
              },
              gross_arr: {
                without_refunds: 0.0754,
                with_refunds: 0.0184,
              },
              net_arr_spread: {
                without_refunds: 1.0005,
                with_refunds: 1.0005,
              },
              gross_arr_spread: {
                without_refunds: 1.0007,
                with_refunds: 1.0007,
              },
              net_total: {
                without_refunds: 0.5691,
                with_refunds: 0.5667,
              },
              gross_total: {
                without_refunds: 0.566,
                with_refunds: 0.5633,
              },
              net_total_spread: {
                without_refunds: 0.8644,
                with_refunds: 0.8637,
              },
              gross_total_spread: {
                without_refunds: 0.8645,
                with_refunds: 0.8642,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 17032.4422,
              with_refunds: 16690.0051,
            },
            gross_mrr: {
              without_refunds: 19076.6373,
              with_refunds: 18702.2417,
            },
            net_arr: {
              without_refunds: 1106.2065,
              with_refunds: 729.4517,
            },
            gross_arr: {
              without_refunds: 1158.0628,
              with_refunds: 753.6248,
            },
            net_arr_spread: {
              without_refunds: 459978.4834,
              with_refunds: 459978.4834,
            },
            gross_arr_spread: {
              without_refunds: 510528.1223,
              with_refunds: 510528.1223,
            },
            net_total: {
              without_refunds: 15607.3629,
              with_refunds: 15074.6582,
            },
            gross_total: {
              without_refunds: 17335.1862,
              with_refunds: 16817.6589,
            },
            net_total_spread: {
              without_refunds: 313818.1784,
              with_refunds: 315417.6156,
            },
            gross_total_spread: {
              without_refunds: 350437.0986,
              with_refunds: 352177.5974,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 21383.1099,
              with_refunds: 20713.7672,
            },
            gross_mrr: {
              without_refunds: 23791.9114,
              with_refunds: 22966.0766,
            },
            net_arr: {
              without_refunds: 15196.681,
              with_refunds: 13210.5109,
            },
            gross_arr: {
              without_refunds: 15873.4805,
              with_refunds: 13820.1361,
            },
            net_arr_spread: {
              without_refunds: 464089.657,
              with_refunds: 464089.657,
            },
            gross_arr_spread: {
              without_refunds: 514834.1948,
              with_refunds: 514834.1948,
            },
            net_total: {
              without_refunds: 32556.1499,
              with_refunds: 30187.4428,
            },
            gross_total: {
              without_refunds: 35174.7428,
              with_refunds: 32704.32,
            },
            net_total_spread: {
              without_refunds: 323478.6318,
              with_refunds: 324655.7719,
            },
            gross_total_spread: {
              without_refunds: 360740.402,
              with_refunds: 361868.4454,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 1.84,
                with_refunds: 1.81,
              },
              gross_mrr: {
                without_refunds: 2.07,
                with_refunds: 2.03,
              },
              net_arr: {
                without_refunds: 0.19,
                with_refunds: 0.15,
              },
              gross_arr: {
                without_refunds: 0.23,
                with_refunds: 0.18,
              },
              net_arr_spread: {
                without_refunds: 8.96,
                with_refunds: 8.96,
              },
              gross_arr_spread: {
                without_refunds: 10.11,
                with_refunds: 10.11,
              },
              net_total: {
                without_refunds: 1.23,
                with_refunds: 1.19,
              },
              gross_total: {
                without_refunds: 1.39,
                with_refunds: 1.35,
              },
              net_total_spread: {
                without_refunds: 3.7,
                with_refunds: 3.68,
              },
              gross_total_spread: {
                without_refunds: 4.17,
                with_refunds: 4.16,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.24,
                with_refunds: 0.22,
              },
              gross_mrr: {
                without_refunds: 0.26,
                with_refunds: 0.24,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 11.69,
                with_refunds: 11.69,
              },
              gross_arr_spread: {
                without_refunds: 12.5,
                with_refunds: 12.5,
              },
              net_total: {
                without_refunds: 0.05,
                with_refunds: 0.06,
              },
              gross_total: {
                without_refunds: 0.06,
                with_refunds: 0.06,
              },
              net_total_spread: {
                without_refunds: 3.78,
                with_refunds: 3.79,
              },
              gross_total_spread: {
                without_refunds: 4.03,
                with_refunds: 4.04,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.28,
                with_refunds: 0.28,
              },
              gross_mrr: {
                without_refunds: 0.31,
                with_refunds: 0.31,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 10.64,
                with_refunds: 10.64,
              },
              gross_arr_spread: {
                without_refunds: 11.8,
                with_refunds: 11.8,
              },
              net_total: {
                without_refunds: 0.08,
                with_refunds: 0.08,
              },
              gross_total: {
                without_refunds: 0.09,
                with_refunds: 0.09,
              },
              net_total_spread: {
                without_refunds: 4.63,
                with_refunds: 4.66,
              },
              gross_total_spread: {
                without_refunds: 5.13,
                with_refunds: 5.17,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.46,
                with_refunds: 19.47,
              },
              gross_mrr: {
                without_refunds: 23.69,
                with_refunds: 22.56,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 56.1,
                with_refunds: 56.1,
              },
              gross_arr_spread: {
                without_refunds: 60.49,
                with_refunds: 60.49,
              },
              net_total: {
                without_refunds: 60.78,
                with_refunds: 56.52,
              },
              gross_total: {
                without_refunds: 66.05,
                with_refunds: 61.54,
              },
              net_total_spread: {
                without_refunds: 43.72,
                with_refunds: 42.19,
              },
              gross_total_spread: {
                without_refunds: 49.52,
                with_refunds: 47.78,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.73,
                with_refunds: 21.85,
              },
              gross_mrr: {
                without_refunds: 26.01,
                with_refunds: 25.02,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.38,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 40.11,
                with_refunds: 40.11,
              },
              gross_arr_spread: {
                without_refunds: 44.85,
                with_refunds: 44.85,
              },
              net_total: {
                without_refunds: 68.41,
                with_refunds: 60.06,
              },
              gross_total: {
                without_refunds: 76.27,
                with_refunds: 67.04,
              },
              net_total_spread: {
                without_refunds: 36.5,
                with_refunds: 35.69,
              },
              gross_total_spread: {
                without_refunds: 41.31,
                with_refunds: 40.39,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.1,
                with_refunds: 11.78,
              },
              gross_mrr: {
                without_refunds: 13.64,
                with_refunds: 13.3,
              },
              net_arr: {
                without_refunds: 80,
                with_refunds: 28.24,
              },
              gross_arr: {
                without_refunds: 103.3,
                with_refunds: 37.57,
              },
              net_arr_spread: {
                without_refunds: 8.99,
                with_refunds: 8.99,
              },
              gross_arr_spread: {
                without_refunds: 10.15,
                with_refunds: 10.15,
              },
              net_total: {
                without_refunds: 12.99,
                with_refunds: 11.51,
              },
              gross_total: {
                without_refunds: 14.59,
                with_refunds: 13.07,
              },
              net_total_spread: {
                without_refunds: 9.4,
                with_refunds: 9.34,
              },
              gross_total_spread: {
                without_refunds: 10.64,
                with_refunds: 10.58,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 19.92,
                with_refunds: 18.47,
              },
              gross_mrr: {
                without_refunds: 21.54,
                with_refunds: 19.99,
              },
              net_arr: {
                without_refunds: 89.87,
              },
              gross_arr: {
                without_refunds: 118.49,
              },
              net_arr_spread: {
                without_refunds: 11.45,
                with_refunds: 11.45,
              },
              gross_arr_spread: {
                without_refunds: 12.24,
                with_refunds: 12.24,
              },
              net_total: {
                without_refunds: 12.57,
                with_refunds: 12.65,
              },
              gross_total: {
                without_refunds: 13.34,
                with_refunds: 13.42,
              },
              net_total_spread: {
                without_refunds: 11.04,
                with_refunds: 11.08,
              },
              gross_total_spread: {
                without_refunds: 11.78,
                with_refunds: 11.82,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.51,
                with_refunds: 14.17,
              },
              gross_mrr: {
                without_refunds: 16.41,
                with_refunds: 16.04,
              },
              net_arr: {
                without_refunds: 97.97,
                with_refunds: 7.33,
              },
              gross_arr: {
                without_refunds: 121.81,
                with_refunds: 10.28,
              },
              net_arr_spread: {
                without_refunds: 10.61,
                with_refunds: 10.61,
              },
              gross_arr_spread: {
                without_refunds: 11.77,
                with_refunds: 11.77,
              },
              net_total: {
                without_refunds: 15.74,
                with_refunds: 10.89,
              },
              gross_total: {
                without_refunds: 17.3,
                with_refunds: 12.49,
              },
              net_total_spread: {
                without_refunds: 9.79,
                with_refunds: 9.87,
              },
              gross_total_spread: {
                without_refunds: 10.84,
                with_refunds: 10.93,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.46,
                with_refunds: 19.47,
              },
              gross_mrr: {
                without_refunds: 23.69,
                with_refunds: 22.56,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 56.1,
                with_refunds: 56.1,
              },
              gross_arr_spread: {
                without_refunds: 60.49,
                with_refunds: 60.49,
              },
              net_total: {
                without_refunds: 60.78,
                with_refunds: 56.52,
              },
              gross_total: {
                without_refunds: 66.05,
                with_refunds: 61.54,
              },
              net_total_spread: {
                without_refunds: 43.72,
                with_refunds: 42.19,
              },
              gross_total_spread: {
                without_refunds: 49.52,
                with_refunds: 47.78,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.73,
                with_refunds: 21.85,
              },
              gross_mrr: {
                without_refunds: 26.01,
                with_refunds: 25.02,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.38,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 40.11,
                with_refunds: 40.11,
              },
              gross_arr_spread: {
                without_refunds: 44.85,
                with_refunds: 44.85,
              },
              net_total: {
                without_refunds: 68.41,
                with_refunds: 60.06,
              },
              gross_total: {
                without_refunds: 76.27,
                with_refunds: 67.04,
              },
              net_total_spread: {
                without_refunds: 36.5,
                with_refunds: 35.69,
              },
              gross_total_spread: {
                without_refunds: 41.31,
                with_refunds: 40.39,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 37.87,
                  with_refunds: 36.86,
                },
                gross_mrr: {
                  without_refunds: 44.21,
                  with_refunds: 43.04,
                },
                net_arr: {
                  without_refunds: 109.14,
                  with_refunds: 88.22,
                },
                gross_arr: {
                  without_refunds: 122.63,
                  with_refunds: 99.43,
                },
                net_arr_spread: {
                  without_refunds: 66.56,
                  with_refunds: 66.56,
                },
                gross_arr_spread: {
                  without_refunds: 75.04,
                  with_refunds: 75.04,
                },
                net_total: {
                  without_refunds: 64.83,
                  with_refunds: 56.36,
                },
                gross_total: {
                  without_refunds: 73.89,
                  with_refunds: 64.47,
                },
                net_total_spread: {
                  without_refunds: 46.56,
                  with_refunds: 45.89,
                },
                gross_total_spread: {
                  without_refunds: 53.54,
                  with_refunds: 52.76,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 29,
                  with_refunds: 27.58,
                },
                gross_mrr: {
                  without_refunds: 32.85,
                  with_refunds: 31.22,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 88.63,
                  with_refunds: 88.63,
                },
                gross_arr_spread: {
                  without_refunds: 94.77,
                  with_refunds: 94.77,
                },
                net_total: {
                  without_refunds: 82.06,
                  with_refunds: 74.13,
                },
                gross_total: {
                  without_refunds: 88.4,
                  with_refunds: 79.99,
                },
                net_total_spread: {
                  without_refunds: 53.26,
                  with_refunds: 52.55,
                },
                gross_total_spread: {
                  without_refunds: 57.89,
                  with_refunds: 57.04,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 32.77,
                  with_refunds: 31.27,
                },
                gross_mrr: {
                  without_refunds: 37.32,
                  with_refunds: 35.64,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.73,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.34,
                },
                net_arr_spread: {
                  without_refunds: 79.47,
                  with_refunds: 79.47,
                },
                gross_arr_spread: {
                  without_refunds: 88.11,
                  with_refunds: 88.11,
                },
                net_total: {
                  without_refunds: 98.27,
                  with_refunds: 78.33,
                },
                gross_total: {
                  without_refunds: 108.9,
                  with_refunds: 86.99,
                },
                net_total_spread: {
                  without_refunds: 57.82,
                  with_refunds: 57.34,
                },
                gross_total_spread: {
                  without_refunds: 64.46,
                  with_refunds: 63.92,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 95.67,
                  with_refunds: 92.9,
                },
                gross_mrr: {
                  without_refunds: 110.34,
                  with_refunds: 107.19,
                },
                net_arr: {
                  without_refunds: 838.53,
                  with_refunds: 374.33,
                },
                gross_arr: {
                  without_refunds: 999.27,
                  with_refunds: 456.6,
                },
                net_arr_spread: {
                  without_refunds: 71.47,
                  with_refunds: 71.47,
                },
                gross_arr_spread: {
                  without_refunds: 80.69,
                  with_refunds: 80.69,
                },
                net_total: {
                  without_refunds: 168.82,
                  with_refunds: 142.18,
                },
                gross_total: {
                  without_refunds: 191.92,
                  with_refunds: 162.63,
                },
                net_total_spread: {
                  without_refunds: 81.16,
                  with_refunds: 79.92,
                },
                gross_total_spread: {
                  without_refunds: 93.22,
                  with_refunds: 91.79,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 155.85,
                  with_refunds: 145.54,
                },
                gross_mrr: {
                  without_refunds: 171.23,
                  with_refunds: 159.84,
                },
                net_arr: {
                  without_refunds: 911.39,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 1059.01,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 91.35,
                  with_refunds: 91.35,
                },
                gross_arr_spread: {
                  without_refunds: 97.74,
                  with_refunds: 97.74,
                },
                net_total: {
                  without_refunds: 233.31,
                  with_refunds: 207.01,
                },
                gross_total: {
                  without_refunds: 252.09,
                  with_refunds: 223.01,
                },
                net_total_spread: {
                  without_refunds: 101.05,
                  with_refunds: 100.01,
                },
                gross_total_spread: {
                  without_refunds: 109.42,
                  with_refunds: 108.17,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 114.86,
                  with_refunds: 110.01,
                },
                gross_mrr: {
                  without_refunds: 130.04,
                  with_refunds: 124.65,
                },
                net_arr: {
                  without_refunds: 945.44,
                  with_refunds: 254.87,
                },
                gross_arr: {
                  without_refunds: 1078.85,
                  with_refunds: 295.33,
                },
                net_arr_spread: {
                  without_refunds: 84.36,
                  with_refunds: 84.36,
                },
                gross_arr_spread: {
                  without_refunds: 93.61,
                  with_refunds: 93.61,
                },
                net_total: {
                  without_refunds: 278.2,
                  with_refunds: 200.86,
                },
                gross_total: {
                  without_refunds: 309.04,
                  with_refunds: 225.49,
                },
                net_total_spread: {
                  without_refunds: 87.1,
                  with_refunds: 86.42,
                },
                gross_total_spread: {
                  without_refunds: 97,
                  with_refunds: 96.23,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6363,
                  with_refunds: 0.6183,
                },
                gross_mrr: {
                  without_refunds: 0.7413,
                  with_refunds: 0.7205,
                },
                net_arr: {
                  without_refunds: 1.8789,
                  with_refunds: 1.5555,
                },
                gross_arr: {
                  without_refunds: 2.0977,
                  with_refunds: 1.7398,
                },
                net_arr_spread: {
                  without_refunds: 1.1756,
                  with_refunds: 1.1756,
                },
                gross_arr_spread: {
                  without_refunds: 1.3156,
                  with_refunds: 1.3156,
                },
                net_total: {
                  without_refunds: 1.1063,
                  with_refunds: 0.9744,
                },
                gross_total: {
                  without_refunds: 1.2547,
                  with_refunds: 1.1082,
                },
                net_total_spread: {
                  without_refunds: 0.7985,
                  with_refunds: 0.7865,
                },
                gross_total_spread: {
                  without_refunds: 0.9138,
                  with_refunds: 0.8999,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7157,
                  with_refunds: 1.6393,
                },
                gross_mrr: {
                  without_refunds: 1.9553,
                  with_refunds: 1.8658,
                },
                net_arr: {
                  without_refunds: 8.9846,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5711,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 5.5703,
                  with_refunds: 5.5703,
                },
                gross_arr_spread: {
                  without_refunds: 5.9552,
                  with_refunds: 5.9552,
                },
                net_total: {
                  without_refunds: 5.2982,
                  with_refunds: 4.7659,
                },
                gross_total: {
                  without_refunds: 5.7091,
                  with_refunds: 5.1422,
                },
                net_total_spread: {
                  without_refunds: 3.2441,
                  with_refunds: 3.2048,
                },
                gross_total_spread: {
                  without_refunds: 3.53,
                  with_refunds: 3.483,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7754,
                  with_refunds: 0.7406,
                },
                gross_mrr: {
                  without_refunds: 0.8847,
                  with_refunds: 0.845,
                },
                net_arr: {
                  without_refunds: 2.2763,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4824,
                  with_refunds: 2.028,
                },
                net_arr_spread: {
                  without_refunds: 1.483,
                  with_refunds: 1.483,
                },
                gross_arr_spread: {
                  without_refunds: 1.6215,
                  with_refunds: 1.6215,
                },
                net_total: {
                  without_refunds: 1.8091,
                  with_refunds: 1.5136,
                },
                gross_total: {
                  without_refunds: 1.9854,
                  with_refunds: 1.6627,
                },
                net_total_spread: {
                  without_refunds: 1.1366,
                  with_refunds: 1.1247,
                },
                gross_total_spread: {
                  without_refunds: 1.2561,
                  with_refunds: 1.2424,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.6937,
                  with_refunds: 1.6338,
                },
                gross_mrr: {
                  without_refunds: 1.9419,
                  with_refunds: 1.8746,
                },
                net_arr: {
                  without_refunds: 14.0587,
                  with_refunds: 6.3551,
                },
                gross_arr: {
                  without_refunds: 16.924,
                  with_refunds: 7.7171,
                },
                net_arr_spread: {
                  without_refunds: 1.2613,
                  with_refunds: 1.2613,
                },
                gross_arr_spread: {
                  without_refunds: 1.4132,
                  with_refunds: 1.4132,
                },
                net_total: {
                  without_refunds: 2.9072,
                  with_refunds: 2.5346,
                },
                gross_total: {
                  without_refunds: 3.2889,
                  with_refunds: 2.8748,
                },
                net_total_spread: {
                  without_refunds: 1.4126,
                  with_refunds: 1.3907,
                },
                gross_total_spread: {
                  without_refunds: 1.6139,
                  with_refunds: 1.5886,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 8.6316,
                  with_refunds: 7.9326,
                },
                gross_mrr: {
                  without_refunds: 9.4793,
                  with_refunds: 8.7133,
                },
                net_arr: {
                  without_refunds: 57.5761,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 68.4695,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 5.8856,
                  with_refunds: 5.8856,
                },
                gross_arr_spread: {
                  without_refunds: 6.297,
                  with_refunds: 6.297,
                },
                net_total: {
                  without_refunds: 15.3209,
                  with_refunds: 14.1576,
                },
                gross_total: {
                  without_refunds: 16.5866,
                  with_refunds: 15.2492,
                },
                net_total_spread: {
                  without_refunds: 5.9908,
                  with_refunds: 5.9271,
                },
                gross_total_spread: {
                  without_refunds: 6.4955,
                  with_refunds: 6.4193,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 3.1695,
                  with_refunds: 2.9649,
                },
                gross_mrr: {
                  without_refunds: 3.5301,
                  with_refunds: 3.3051,
                },
                net_arr: {
                  without_refunds: 15.9144,
                  with_refunds: 4.5742,
                },
                gross_arr: {
                  without_refunds: 18.5028,
                  with_refunds: 5.202,
                },
                net_arr_spread: {
                  without_refunds: 1.5758,
                  with_refunds: 1.5758,
                },
                gross_arr_spread: {
                  without_refunds: 1.7244,
                  with_refunds: 1.7244,
                },
                net_total: {
                  without_refunds: 5.1522,
                  with_refunds: 4.1671,
                },
                gross_total: {
                  without_refunds: 5.6719,
                  with_refunds: 4.5952,
                },
                net_total_spread: {
                  without_refunds: 1.8776,
                  with_refunds: 1.8594,
                },
                gross_total_spread: {
                  without_refunds: 2.0686,
                  with_refunds: 2.0475,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 8,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.1224,
                with_refunds: 0.1228,
              },
              arr_users: {
                without_refunds: 0.0011,
                with_refunds: 0.0011,
              },
              arr_spread_users: {
                without_refunds: 0.9978,
                with_refunds: 0.9978,
              },
              total_users: {
                without_refunds: 0.0781,
                with_refunds: 0.0782,
              },
              total_spread_users: {
                without_refunds: 0.359,
                with_refunds: 0.3591,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7421,
                with_refunds: 0.7421,
              },
              arr_users: {
                without_refunds: 0.2185,
                with_refunds: 0.2495,
              },
              arr_spread_users: {
                without_refunds: 1.0025,
                with_refunds: 1.0025,
              },
              total_users: {
                without_refunds: 0.7102,
                with_refunds: 0.7099,
              },
              total_spread_users: {
                without_refunds: 0.9108,
                with_refunds: 0.9106,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0078,
                with_refunds: 0.0079,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0245,
                with_refunds: 1.0245,
              },
              total_users: {
                without_refunds: 0.0026,
                with_refunds: 0.0027,
              },
              total_spread_users: {
                without_refunds: 0.3099,
                with_refunds: 0.3099,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6594,
                with_refunds: 0.6599,
              },
              arr_users: {
                without_refunds: 0.2778,
                with_refunds: 0.2778,
              },
              arr_spread_users: {
                without_refunds: 1.002,
                with_refunds: 1.002,
              },
              total_users: {
                without_refunds: 0.6586,
                with_refunds: 0.6586,
              },
              total_spread_users: {
                without_refunds: 0.9068,
                with_refunds: 0.9069,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0132,
                with_refunds: 0.0132,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0043,
                with_refunds: 1.0043,
              },
              total_users: {
                without_refunds: 0.004,
                with_refunds: 0.004,
              },
              total_spread_users: {
                without_refunds: 0.4291,
                with_refunds: 0.4286,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6594,
                with_refunds: 0.6599,
              },
              arr_users: {
                without_refunds: 0.2778,
                with_refunds: 0.2778,
              },
              arr_spread_users: {
                without_refunds: 1.002,
                with_refunds: 1.002,
              },
              total_users: {
                without_refunds: 0.6586,
                with_refunds: 0.6586,
              },
              total_spread_users: {
                without_refunds: 0.9068,
                with_refunds: 0.9069,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.2082,
                with_refunds: 0.211,
              },
              gross_mrr: {
                without_refunds: 0.1826,
                with_refunds: 0.1852,
              },
              net_arr: {
                without_refunds: 0.003,
                with_refunds: 0.0031,
              },
              gross_arr: {
                without_refunds: 0.0027,
                with_refunds: 0.0028,
              },
              net_arr_spread: {
                without_refunds: 1.9972,
                with_refunds: 1.9972,
              },
              gross_arr_spread: {
                without_refunds: 1.9804,
                with_refunds: 1.9804,
              },
              net_total: {
                without_refunds: 0.0749,
                with_refunds: 0.0777,
              },
              gross_total: {
                without_refunds: 0.0639,
                with_refunds: 0.0665,
              },
              net_total_spread: {
                without_refunds: 0.5238,
                with_refunds: 0.5348,
              },
              gross_total_spread: {
                without_refunds: 0.4935,
                with_refunds: 0.5043,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7445,
                with_refunds: 0.7469,
              },
              gross_mrr: {
                without_refunds: 0.744,
                with_refunds: 0.7464,
              },
              net_arr: {
                without_refunds: 0.2237,
                with_refunds: 0.1663,
              },
              gross_arr: {
                without_refunds: 0.2266,
                with_refunds: 0.1694,
              },
              net_arr_spread: {
                without_refunds: 1.0035,
                with_refunds: 1.0035,
              },
              gross_arr_spread: {
                without_refunds: 1.0035,
                with_refunds: 1.0035,
              },
              net_total: {
                without_refunds: 0.7059,
                with_refunds: 0.7159,
              },
              gross_total: {
                without_refunds: 0.7051,
                with_refunds: 0.7163,
              },
              net_total_spread: {
                without_refunds: 0.8857,
                with_refunds: 0.888,
              },
              gross_total_spread: {
                without_refunds: 0.8859,
                with_refunds: 0.8882,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0116,
                with_refunds: 0.0114,
              },
              gross_mrr: {
                without_refunds: 0.0108,
                with_refunds: 0.0106,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 1.6984,
                with_refunds: 1.6984,
              },
              gross_arr_spread: {
                without_refunds: 1.6811,
                with_refunds: 1.6811,
              },
              net_total: {
                without_refunds: 0.0008,
                with_refunds: 0.0009,
              },
              gross_total: {
                without_refunds: 0.0007,
                with_refunds: 0.0009,
              },
              net_total_spread: {
                without_refunds: 0.3211,
                with_refunds: 0.3325,
              },
              gross_total_spread: {
                without_refunds: 0.3009,
                with_refunds: 0.3118,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6641,
                with_refunds: 0.6628,
              },
              gross_mrr: {
                without_refunds: 0.6635,
                with_refunds: 0.6623,
              },
              net_arr: {
                without_refunds: 0.2848,
              },
              gross_arr: {
                without_refunds: 0.285,
              },
              net_arr_spread: {
                without_refunds: 1.0025,
                with_refunds: 1.0025,
              },
              gross_arr_spread: {
                without_refunds: 1.0027,
                with_refunds: 1.0027,
              },
              net_total: {
                without_refunds: 0.6579,
                with_refunds: 0.6639,
              },
              gross_total: {
                without_refunds: 0.6561,
                with_refunds: 0.6632,
              },
              net_total_spread: {
                without_refunds: 0.878,
                with_refunds: 0.8782,
              },
              gross_total_spread: {
                without_refunds: 0.8769,
                with_refunds: 0.8771,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.021,
                with_refunds: 0.0216,
              },
              gross_mrr: {
                without_refunds: 0.0207,
                with_refunds: 0.0214,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.0508,
                with_refunds: 2.0508,
              },
              gross_arr_spread: {
                without_refunds: 2.0293,
                with_refunds: 2.0293,
              },
              net_total: {
                without_refunds: 0.0015,
                with_refunds: 0.0016,
              },
              gross_total: {
                without_refunds: 0.0015,
                with_refunds: 0.0016,
              },
              net_total_spread: {
                without_refunds: 0.6244,
                with_refunds: 0.6411,
              },
              gross_total_spread: {
                without_refunds: 0.6134,
                with_refunds: 0.6298,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6641,
                with_refunds: 0.6628,
              },
              gross_mrr: {
                without_refunds: 0.6635,
                with_refunds: 0.6623,
              },
              net_arr: {
                without_refunds: 0.2848,
                with_refunds: 0.1157,
              },
              gross_arr: {
                without_refunds: 0.285,
                with_refunds: 0.1157,
              },
              net_arr_spread: {
                without_refunds: 1.0025,
                with_refunds: 1.0025,
              },
              gross_arr_spread: {
                without_refunds: 1.0027,
                with_refunds: 1.0027,
              },
              net_total: {
                without_refunds: 0.6579,
                with_refunds: 0.6639,
              },
              gross_total: {
                without_refunds: 0.6561,
                with_refunds: 0.6632,
              },
              net_total_spread: {
                without_refunds: 0.878,
                with_refunds: 0.8782,
              },
              gross_total_spread: {
                without_refunds: 0.8769,
                with_refunds: 0.8771,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 17094.556,
              with_refunds: 16748.3884,
            },
            gross_mrr: {
              without_refunds: 19144.1313,
              with_refunds: 18765.5757,
            },
            net_arr: {
              without_refunds: 1106.2115,
              with_refunds: 729.4517,
            },
            gross_arr: {
              without_refunds: 1158.069,
              with_refunds: 753.6248,
            },
            net_arr_spread: {
              without_refunds: 481288.5635,
              with_refunds: 481288.5635,
            },
            gross_arr_spread: {
              without_refunds: 533291.4035,
              with_refunds: 533291.4035,
            },
            net_total: {
              without_refunds: 15635.4602,
              with_refunds: 15100.1771,
            },
            gross_total: {
              without_refunds: 17364.7955,
              with_refunds: 16844.962,
            },
            net_total_spread: {
              without_refunds: 320513.4203,
              with_refunds: 322216.213,
            },
            gross_total_spread: {
              without_refunds: 357787.7543,
              with_refunds: 359636.0444,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 22255.3802,
              with_refunds: 21519.7327,
            },
            gross_mrr: {
              without_refunds: 24733.6622,
              with_refunds: 23818.2305,
            },
            net_arr: {
              without_refunds: 17331.3617,
              with_refunds: 15093.8474,
            },
            gross_arr: {
              without_refunds: 18100.4955,
              with_refunds: 15789.558,
            },
            net_arr_spread: {
              without_refunds: 486642.4413,
              with_refunds: 486642.4413,
            },
            gross_arr_spread: {
              without_refunds: 538898.0154,
              with_refunds: 538898.0154,
            },
            net_total: {
              without_refunds: 35171.9377,
              with_refunds: 32513.142,
            },
            gross_total: {
              without_refunds: 37925.284,
              with_refunds: 35147.1131,
            },
            net_total_spread: {
              without_refunds: 332618.7194,
              with_refunds: 333801.0691,
            },
            gross_total_spread: {
              without_refunds: 370684.9028,
              with_refunds: 371775.6032,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 1.42,
                with_refunds: 1.4,
              },
              gross_mrr: {
                without_refunds: 1.6,
                with_refunds: 1.58,
              },
              net_arr: {
                without_refunds: 0.13,
                with_refunds: 0.13,
              },
              gross_arr: {
                without_refunds: 0.16,
                with_refunds: 0.16,
              },
              net_arr_spread: {
                without_refunds: 8.99,
                with_refunds: 8.99,
              },
              gross_arr_spread: {
                without_refunds: 10.15,
                with_refunds: 10.15,
              },
              net_total: {
                without_refunds: 0.95,
                with_refunds: 0.93,
              },
              gross_total: {
                without_refunds: 1.07,
                with_refunds: 1.06,
              },
              net_total_spread: {
                without_refunds: 3.26,
                with_refunds: 3.25,
              },
              gross_total_spread: {
                without_refunds: 3.68,
                with_refunds: 3.67,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.16,
                with_refunds: 0.15,
              },
              gross_mrr: {
                without_refunds: 0.17,
                with_refunds: 0.16,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 11.72,
                with_refunds: 11.72,
              },
              gross_arr_spread: {
                without_refunds: 12.53,
                with_refunds: 12.53,
              },
              net_total: {
                without_refunds: 0.04,
                with_refunds: 0.04,
              },
              gross_total: {
                without_refunds: 0.04,
                with_refunds: 0.04,
              },
              net_total_spread: {
                without_refunds: 3.32,
                with_refunds: 3.33,
              },
              gross_total_spread: {
                without_refunds: 3.53,
                with_refunds: 3.54,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.18,
                with_refunds: 0.18,
              },
              gross_mrr: {
                without_refunds: 0.21,
                with_refunds: 0.21,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 10.67,
                with_refunds: 10.67,
              },
              gross_arr_spread: {
                without_refunds: 11.83,
                with_refunds: 11.83,
              },
              net_total: {
                without_refunds: 0.05,
                with_refunds: 0.05,
              },
              gross_total: {
                without_refunds: 0.06,
                with_refunds: 0.06,
              },
              net_total_spread: {
                without_refunds: 4.07,
                with_refunds: 4.09,
              },
              gross_total_spread: {
                without_refunds: 4.5,
                with_refunds: 4.53,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.57,
                with_refunds: 19.57,
              },
              gross_mrr: {
                without_refunds: 23.81,
                with_refunds: 22.68,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 63.5,
                with_refunds: 63.5,
              },
              gross_arr_spread: {
                without_refunds: 68.48,
                with_refunds: 68.48,
              },
              net_total: {
                without_refunds: 60.91,
                with_refunds: 56.65,
              },
              gross_total: {
                without_refunds: 66.19,
                with_refunds: 61.67,
              },
              net_total_spread: {
                without_refunds: 47.02,
                with_refunds: 45.38,
              },
              gross_total_spread: {
                without_refunds: 53.27,
                with_refunds: 51.39,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.85,
                with_refunds: 21.97,
              },
              gross_mrr: {
                without_refunds: 26.15,
                with_refunds: 25.16,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 45.48,
                with_refunds: 45.48,
              },
              gross_arr_spread: {
                without_refunds: 50.85,
                with_refunds: 50.85,
              },
              net_total: {
                without_refunds: 68.6,
                with_refunds: 60.22,
              },
              gross_total: {
                without_refunds: 76.49,
                with_refunds: 67.22,
              },
              net_total_spread: {
                without_refunds: 39.47,
                with_refunds: 38.59,
              },
              gross_total_spread: {
                without_refunds: 44.66,
                with_refunds: 43.67,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.15,
                with_refunds: 11.87,
              },
              gross_mrr: {
                without_refunds: 13.69,
                with_refunds: 13.39,
              },
              net_arr: {
                without_refunds: 57.26,
                with_refunds: 17.52,
              },
              gross_arr: {
                without_refunds: 71.23,
                with_refunds: 22.24,
              },
              net_arr_spread: {
                without_refunds: 9,
                with_refunds: 9,
              },
              gross_arr_spread: {
                without_refunds: 10.16,
                with_refunds: 10.16,
              },
              net_total: {
                without_refunds: 12.92,
                with_refunds: 11.6,
              },
              gross_total: {
                without_refunds: 14.48,
                with_refunds: 13.17,
              },
              net_total_spread: {
                without_refunds: 9.13,
                with_refunds: 9.1,
              },
              gross_total_spread: {
                without_refunds: 10.34,
                with_refunds: 10.31,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.06,
                with_refunds: 18.55,
              },
              gross_mrr: {
                without_refunds: 21.67,
                with_refunds: 20.06,
              },
              net_arr: {
                without_refunds: 92.14,
              },
              gross_arr: {
                without_refunds: 121.56,
              },
              net_arr_spread: {
                without_refunds: 11.46,
                with_refunds: 11.46,
              },
              gross_arr_spread: {
                without_refunds: 12.25,
                with_refunds: 12.25,
              },
              net_total: {
                without_refunds: 12.55,
                with_refunds: 12.75,
              },
              gross_total: {
                without_refunds: 13.29,
                with_refunds: 13.51,
              },
              net_total_spread: {
                without_refunds: 10.69,
                with_refunds: 10.73,
              },
              gross_total_spread: {
                without_refunds: 11.39,
                with_refunds: 11.43,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.61,
                with_refunds: 14.23,
              },
              gross_mrr: {
                without_refunds: 16.51,
                with_refunds: 16.1,
              },
              net_arr: {
                without_refunds: 100.44,
                with_refunds: 8.66,
              },
              gross_arr: {
                without_refunds: 124.96,
                with_refunds: 12.16,
              },
              net_arr_spread: {
                without_refunds: 10.62,
                with_refunds: 10.62,
              },
              gross_arr_spread: {
                without_refunds: 11.78,
                with_refunds: 11.78,
              },
              net_total: {
                without_refunds: 15.73,
                with_refunds: 10.98,
              },
              gross_total: {
                without_refunds: 17.23,
                with_refunds: 12.58,
              },
              net_total_spread: {
                without_refunds: 9.48,
                with_refunds: 9.56,
              },
              gross_total_spread: {
                without_refunds: 10.48,
                with_refunds: 10.57,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.57,
                with_refunds: 19.57,
              },
              gross_mrr: {
                without_refunds: 23.81,
                with_refunds: 22.68,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 63.5,
                with_refunds: 63.5,
              },
              gross_arr_spread: {
                without_refunds: 68.48,
                with_refunds: 68.48,
              },
              net_total: {
                without_refunds: 60.91,
                with_refunds: 56.65,
              },
              gross_total: {
                without_refunds: 66.19,
                with_refunds: 61.67,
              },
              net_total_spread: {
                without_refunds: 47.02,
                with_refunds: 45.38,
              },
              gross_total_spread: {
                without_refunds: 53.27,
                with_refunds: 51.39,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.85,
                with_refunds: 21.97,
              },
              gross_mrr: {
                without_refunds: 26.15,
                with_refunds: 25.16,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 45.48,
                with_refunds: 45.48,
              },
              gross_arr_spread: {
                without_refunds: 50.85,
                with_refunds: 50.85,
              },
              net_total: {
                without_refunds: 68.6,
                with_refunds: 60.22,
              },
              gross_total: {
                without_refunds: 76.49,
                with_refunds: 67.22,
              },
              net_total_spread: {
                without_refunds: 39.47,
                with_refunds: 38.59,
              },
              gross_total_spread: {
                without_refunds: 44.66,
                with_refunds: 43.67,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 39.3,
                  with_refunds: 38.27,
                },
                gross_mrr: {
                  without_refunds: 45.81,
                  with_refunds: 44.62,
                },
                net_arr: {
                  without_refunds: 109.28,
                  with_refunds: 88.35,
                },
                gross_arr: {
                  without_refunds: 122.8,
                  with_refunds: 99.59,
                },
                net_arr_spread: {
                  without_refunds: 75.55,
                  with_refunds: 75.55,
                },
                gross_arr_spread: {
                  without_refunds: 85.19,
                  with_refunds: 85.19,
                },
                net_total: {
                  without_refunds: 65.77,
                  with_refunds: 57.3,
                },
                gross_total: {
                  without_refunds: 74.96,
                  with_refunds: 65.53,
                },
                net_total_spread: {
                  without_refunds: 49.82,
                  with_refunds: 49.15,
                },
                gross_total_spread: {
                  without_refunds: 57.22,
                  with_refunds: 56.44,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 29.16,
                  with_refunds: 27.73,
                },
                gross_mrr: {
                  without_refunds: 33.02,
                  with_refunds: 31.38,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 100.35,
                  with_refunds: 100.35,
                },
                gross_arr_spread: {
                  without_refunds: 107.3,
                  with_refunds: 107.3,
                },
                net_total: {
                  without_refunds: 82.09,
                  with_refunds: 74.17,
                },
                gross_total: {
                  without_refunds: 88.44,
                  with_refunds: 80.03,
                },
                net_total_spread: {
                  without_refunds: 56.58,
                  with_refunds: 55.88,
                },
                gross_total_spread: {
                  without_refunds: 61.42,
                  with_refunds: 60.58,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 32.96,
                  with_refunds: 31.45,
                },
                gross_mrr: {
                  without_refunds: 37.53,
                  with_refunds: 35.85,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.73,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.34,
                },
                net_arr_spread: {
                  without_refunds: 90.14,
                  with_refunds: 90.14,
                },
                gross_arr_spread: {
                  without_refunds: 99.94,
                  with_refunds: 99.94,
                },
                net_total: {
                  without_refunds: 98.32,
                  with_refunds: 78.38,
                },
                gross_total: {
                  without_refunds: 108.96,
                  with_refunds: 87.05,
                },
                net_total_spread: {
                  without_refunds: 61.88,
                  with_refunds: 61.43,
                },
                gross_total_spread: {
                  without_refunds: 68.96,
                  with_refunds: 68.45,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 107.82,
                  with_refunds: 104.77,
                },
                gross_mrr: {
                  without_refunds: 124.03,
                  with_refunds: 120.58,
                },
                net_arr: {
                  without_refunds: 895.79,
                  with_refunds: 391.85,
                },
                gross_arr: {
                  without_refunds: 1070.5,
                  with_refunds: 478.84,
                },
                net_arr_spread: {
                  without_refunds: 80.48,
                  with_refunds: 80.48,
                },
                gross_arr_spread: {
                  without_refunds: 90.85,
                  with_refunds: 90.85,
                },
                net_total: {
                  without_refunds: 181.73,
                  with_refunds: 153.78,
                },
                gross_total: {
                  without_refunds: 206.4,
                  with_refunds: 175.81,
                },
                net_total_spread: {
                  without_refunds: 90.29,
                  with_refunds: 89.02,
                },
                gross_total_spread: {
                  without_refunds: 103.56,
                  with_refunds: 102.11,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 175.91,
                  with_refunds: 164.09,
                },
                gross_mrr: {
                  without_refunds: 192.9,
                  with_refunds: 179.89,
                },
                net_arr: {
                  without_refunds: 1003.52,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 1180.57,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 102.81,
                  with_refunds: 102.81,
                },
                gross_arr_spread: {
                  without_refunds: 110,
                  with_refunds: 110,
                },
                net_total: {
                  without_refunds: 245.87,
                  with_refunds: 219.76,
                },
                gross_total: {
                  without_refunds: 265.38,
                  with_refunds: 236.53,
                },
                net_total_spread: {
                  without_refunds: 111.74,
                  with_refunds: 110.75,
                },
                gross_total_spread: {
                  without_refunds: 120.81,
                  with_refunds: 119.6,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 129.48,
                  with_refunds: 124.24,
                },
                gross_mrr: {
                  without_refunds: 146.55,
                  with_refunds: 140.75,
                },
                net_arr: {
                  without_refunds: 1045.88,
                  with_refunds: 263.53,
                },
                gross_arr: {
                  without_refunds: 1203.81,
                  with_refunds: 307.49,
                },
                net_arr_spread: {
                  without_refunds: 94.98,
                  with_refunds: 94.98,
                },
                gross_arr_spread: {
                  without_refunds: 105.39,
                  with_refunds: 105.39,
                },
                net_total: {
                  without_refunds: 293.93,
                  with_refunds: 211.84,
                },
                gross_total: {
                  without_refunds: 326.28,
                  with_refunds: 238.07,
                },
                net_total_spread: {
                  without_refunds: 96.58,
                  with_refunds: 95.97,
                },
                gross_total_spread: {
                  without_refunds: 107.48,
                  with_refunds: 106.79,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6581,
                  with_refunds: 0.6398,
                },
                gross_mrr: {
                  without_refunds: 0.7657,
                  with_refunds: 0.7446,
                },
                net_arr: {
                  without_refunds: 1.8808,
                  with_refunds: 1.5574,
                },
                gross_arr: {
                  without_refunds: 2.1002,
                  with_refunds: 1.7422,
                },
                net_arr_spread: {
                  without_refunds: 1.3342,
                  with_refunds: 1.3342,
                },
                gross_arr_spread: {
                  without_refunds: 1.4933,
                  with_refunds: 1.4933,
                },
                net_total: {
                  without_refunds: 1.1207,
                  with_refunds: 0.9887,
                },
                gross_total: {
                  without_refunds: 1.271,
                  with_refunds: 1.1243,
                },
                net_total_spread: {
                  without_refunds: 0.853,
                  with_refunds: 0.8409,
                },
                gross_total_spread: {
                  without_refunds: 0.9752,
                  with_refunds: 0.9612,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7239,
                  with_refunds: 1.6469,
                },
                gross_mrr: {
                  without_refunds: 1.9642,
                  with_refunds: 1.874,
                },
                net_arr: {
                  without_refunds: 8.9846,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5711,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 6.3169,
                  with_refunds: 6.3169,
                },
                gross_arr_spread: {
                  without_refunds: 6.7534,
                  with_refunds: 6.7534,
                },
                net_total: {
                  without_refunds: 5.3012,
                  with_refunds: 4.7692,
                },
                gross_total: {
                  without_refunds: 5.7122,
                  with_refunds: 5.1458,
                },
                net_total_spread: {
                  without_refunds: 3.4472,
                  with_refunds: 3.4082,
                },
                gross_total_spread: {
                  without_refunds: 3.746,
                  with_refunds: 3.6993,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7795,
                  with_refunds: 0.7446,
                },
                gross_mrr: {
                  without_refunds: 0.8893,
                  with_refunds: 0.8494,
                },
                net_arr: {
                  without_refunds: 2.2763,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4824,
                  with_refunds: 2.028,
                },
                net_arr_spread: {
                  without_refunds: 1.6824,
                  with_refunds: 1.6824,
                },
                gross_arr_spread: {
                  without_refunds: 1.8395,
                  with_refunds: 1.8395,
                },
                net_total: {
                  without_refunds: 1.8102,
                  with_refunds: 1.5147,
                },
                gross_total: {
                  without_refunds: 1.9866,
                  with_refunds: 1.664,
                },
                net_total_spread: {
                  without_refunds: 1.2122,
                  with_refunds: 1.2007,
                },
                gross_total_spread: {
                  without_refunds: 1.3386,
                  with_refunds: 1.3253,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 1.9089,
                  with_refunds: 1.8421,
                },
                gross_mrr: {
                  without_refunds: 2.1829,
                  with_refunds: 2.1082,
                },
                net_arr: {
                  without_refunds: 14.9182,
                  with_refunds: 6.564,
                },
                gross_arr: {
                  without_refunds: 18.0312,
                  with_refunds: 7.986,
                },
                net_arr_spread: {
                  without_refunds: 1.42,
                  with_refunds: 1.42,
                },
                gross_arr_spread: {
                  without_refunds: 1.5911,
                  with_refunds: 1.5911,
                },
                net_total: {
                  without_refunds: 3.1258,
                  with_refunds: 2.742,
                },
                gross_total: {
                  without_refunds: 3.5328,
                  with_refunds: 3.1076,
                },
                net_total_spread: {
                  without_refunds: 1.5697,
                  with_refunds: 1.5473,
                },
                gross_total_spread: {
                  without_refunds: 1.7908,
                  with_refunds: 1.765,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 9.7349,
                  with_refunds: 8.9285,
                },
                gross_mrr: {
                  without_refunds: 10.6656,
                  with_refunds: 9.7856,
                },
                net_arr: {
                  without_refunds: 63.3184,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 76.3029,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 6.6245,
                  with_refunds: 6.6245,
                },
                gross_arr_spread: {
                  without_refunds: 7.0868,
                  with_refunds: 7.0868,
                },
                net_total: {
                  without_refunds: 16.1958,
                  with_refunds: 15.122,
                },
                gross_total: {
                  without_refunds: 17.5141,
                  with_refunds: 16.2685,
                },
                net_total_spread: {
                  without_refunds: 6.6183,
                  with_refunds: 6.556,
                },
                gross_total_spread: {
                  without_refunds: 7.163,
                  with_refunds: 7.0881,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 3.5732,
                  with_refunds: 3.3418,
                },
                gross_mrr: {
                  without_refunds: 3.9734,
                  with_refunds: 3.72,
                },
                net_arr: {
                  without_refunds: 17.5555,
                  with_refunds: 4.7051,
                },
                gross_arr: {
                  without_refunds: 20.6297,
                  with_refunds: 5.3857,
                },
                net_arr_spread: {
                  without_refunds: 1.774,
                  with_refunds: 1.774,
                },
                gross_arr_spread: {
                  without_refunds: 1.9412,
                  with_refunds: 1.9412,
                },
                net_total: {
                  without_refunds: 5.4485,
                  with_refunds: 4.429,
                },
                gross_total: {
                  without_refunds: 5.993,
                  with_refunds: 4.8833,
                },
                net_total_spread: {
                  without_refunds: 2.0779,
                  with_refunds: 2.0607,
                },
                gross_total_spread: {
                  without_refunds: 2.2865,
                  with_refunds: 2.2664,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 9,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0902,
                with_refunds: 0.0905,
              },
              arr_users: {
                without_refunds: 0.0004,
                with_refunds: 0.0004,
              },
              arr_spread_users: {
                without_refunds: 1.0003,
                with_refunds: 1.0003,
              },
              total_users: {
                without_refunds: 0.0573,
                with_refunds: 0.0575,
              },
              total_spread_users: {
                without_refunds: 0.3177,
                with_refunds: 0.3178,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6904,
                with_refunds: 0.6909,
              },
              arr_users: {
                without_refunds: 0.12,
                with_refunds: 0.1455,
              },
              arr_spread_users: {
                without_refunds: 1.0025,
                with_refunds: 1.0025,
              },
              total_users: {
                without_refunds: 0.6667,
                with_refunds: 0.6675,
              },
              total_spread_users: {
                without_refunds: 0.8884,
                with_refunds: 0.8886,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0052,
                with_refunds: 0.0053,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0274,
                with_refunds: 1.0274,
              },
              total_users: {
                without_refunds: 0.0017,
                with_refunds: 0.0018,
              },
              total_spread_users: {
                without_refunds: 0.2754,
                with_refunds: 0.2753,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6633,
                with_refunds: 0.6638,
              },
              arr_users: {
                without_refunds: 0.2222,
                with_refunds: 0.2222,
              },
              arr_spread_users: {
                without_refunds: 1.0028,
                with_refunds: 1.0028,
              },
              total_users: {
                without_refunds: 0.66,
                with_refunds: 0.6605,
              },
              total_spread_users: {
                without_refunds: 0.8884,
                with_refunds: 0.8884,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0088,
                with_refunds: 0.0088,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0072,
                with_refunds: 1.0072,
              },
              total_users: {
                without_refunds: 0.0026,
                with_refunds: 0.0027,
              },
              total_spread_users: {
                without_refunds: 0.3812,
                with_refunds: 0.3808,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6633,
                with_refunds: 0.6638,
              },
              arr_users: {
                without_refunds: 0.2222,
                with_refunds: 0.2222,
              },
              arr_spread_users: {
                without_refunds: 1.0028,
                with_refunds: 1.0028,
              },
              total_users: {
                without_refunds: 0.66,
                with_refunds: 0.6605,
              },
              total_spread_users: {
                without_refunds: 0.8884,
                with_refunds: 0.8884,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.1548,
                with_refunds: 0.1569,
              },
              gross_mrr: {
                without_refunds: 0.1338,
                with_refunds: 0.1357,
              },
              net_arr: {
                without_refunds: 0.0009,
                with_refunds: 0.0007,
              },
              gross_arr: {
                without_refunds: 0.0008,
                with_refunds: 0.0006,
              },
              net_arr_spread: {
                without_refunds: 2.0043,
                with_refunds: 2.0043,
              },
              gross_arr_spread: {
                without_refunds: 1.9868,
                with_refunds: 1.9868,
              },
              net_total: {
                without_refunds: 0.0574,
                with_refunds: 0.0589,
              },
              gross_total: {
                without_refunds: 0.0478,
                with_refunds: 0.0491,
              },
              net_total_spread: {
                without_refunds: 0.4483,
                with_refunds: 0.4588,
              },
              gross_total_spread: {
                without_refunds: 0.4219,
                with_refunds: 0.4321,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6879,
                with_refunds: 0.6891,
              },
              gross_mrr: {
                without_refunds: 0.6867,
                with_refunds: 0.6877,
              },
              net_arr: {
                without_refunds: 0.1082,
                with_refunds: 0.0483,
              },
              gross_arr: {
                without_refunds: 0.1106,
                with_refunds: 0.0491,
              },
              net_arr_spread: {
                without_refunds: 1.0036,
                with_refunds: 1.0036,
              },
              gross_arr_spread: {
                without_refunds: 1.0033,
                with_refunds: 1.0033,
              },
              net_total: {
                without_refunds: 0.6373,
                with_refunds: 0.6379,
              },
              gross_total: {
                without_refunds: 0.6338,
                with_refunds: 0.634,
              },
              net_total_spread: {
                without_refunds: 0.8576,
                with_refunds: 0.8596,
              },
              gross_total_spread: {
                without_refunds: 0.8576,
                with_refunds: 0.8594,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0077,
                with_refunds: 0.0076,
              },
              gross_mrr: {
                without_refunds: 0.0071,
                with_refunds: 0.0071,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 1.7055,
                with_refunds: 1.7055,
              },
              gross_arr_spread: {
                without_refunds: 1.6874,
                with_refunds: 1.6874,
              },
              net_total: {
                without_refunds: 0.0005,
                with_refunds: 0.0006,
              },
              gross_total: {
                without_refunds: 0.0005,
                with_refunds: 0.0006,
              },
              net_total_spread: {
                without_refunds: 0.2756,
                with_refunds: 0.2864,
              },
              gross_total_spread: {
                without_refunds: 0.2583,
                with_refunds: 0.2685,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6628,
                with_refunds: 0.6655,
              },
              gross_mrr: {
                without_refunds: 0.6616,
                with_refunds: 0.6641,
              },
              net_arr: {
                without_refunds: 0.2001,
              },
              gross_arr: {
                without_refunds: 0.2041,
              },
              net_arr_spread: {
                without_refunds: 1.0042,
                with_refunds: 1.0042,
              },
              gross_arr_spread: {
                without_refunds: 1.0038,
                with_refunds: 1.0038,
              },
              net_total: {
                without_refunds: 0.6316,
                with_refunds: 0.6351,
              },
              gross_total: {
                without_refunds: 0.6281,
                with_refunds: 0.6317,
              },
              net_total_spread: {
                without_refunds: 0.8585,
                with_refunds: 0.8615,
              },
              gross_total_spread: {
                without_refunds: 0.8582,
                with_refunds: 0.8609,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0139,
                with_refunds: 0.0144,
              },
              gross_mrr: {
                without_refunds: 0.0137,
                with_refunds: 0.0142,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.0593,
                with_refunds: 2.0593,
              },
              gross_arr_spread: {
                without_refunds: 2.037,
                with_refunds: 2.037,
              },
              net_total: {
                without_refunds: 0.0009,
                with_refunds: 0.001,
              },
              gross_total: {
                without_refunds: 0.0009,
                with_refunds: 0.001,
              },
              net_total_spread: {
                without_refunds: 0.536,
                with_refunds: 0.5522,
              },
              gross_total_spread: {
                without_refunds: 0.5264,
                with_refunds: 0.5422,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6628,
                with_refunds: 0.6655,
              },
              gross_mrr: {
                without_refunds: 0.6616,
                with_refunds: 0.6641,
              },
              net_arr: {
                without_refunds: 0.2001,
                with_refunds: 0.0705,
              },
              gross_arr: {
                without_refunds: 0.2041,
                with_refunds: 0.0719,
              },
              net_arr_spread: {
                without_refunds: 1.0042,
                with_refunds: 1.0042,
              },
              gross_arr_spread: {
                without_refunds: 1.0038,
                with_refunds: 1.0038,
              },
              net_total: {
                without_refunds: 0.6316,
                with_refunds: 0.6351,
              },
              gross_total: {
                without_refunds: 0.6281,
                with_refunds: 0.6317,
              },
              net_total_spread: {
                without_refunds: 0.8585,
                with_refunds: 0.8615,
              },
              gross_total_spread: {
                without_refunds: 0.8582,
                with_refunds: 0.8609,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 17133.0793,
              with_refunds: 16784.8159,
            },
            gross_mrr: {
              without_refunds: 19185.6664,
              with_refunds: 18804.7608,
            },
            net_arr: {
              without_refunds: 1106.2126,
              with_refunds: 729.4517,
            },
            gross_arr: {
              without_refunds: 1158.0704,
              with_refunds: 753.6248,
            },
            net_arr_spread: {
              without_refunds: 490965.8038,
              with_refunds: 490965.8038,
            },
            gross_arr_spread: {
              without_refunds: 543442.2178,
              with_refunds: 543442.2178,
            },
            net_total: {
              without_refunds: 15649.2954,
              with_refunds: 15114.2651,
            },
            gross_total: {
              without_refunds: 17379.2565,
              with_refunds: 16859.812,
            },
            net_total_spread: {
              without_refunds: 323494.6445,
              with_refunds: 325241.0676,
            },
            gross_total_spread: {
              without_refunds: 361029.7595,
              with_refunds: 362920.5958,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 23112.7407,
              with_refunds: 22311.6514,
            },
            gross_mrr: {
              without_refunds: 25658.6207,
              with_refunds: 24654.4165,
            },
            net_arr: {
              without_refunds: 19466.0394,
              with_refunds: 16977.184,
            },
            gross_arr: {
              without_refunds: 20327.5068,
              with_refunds: 17758.9798,
            },
            net_arr_spread: {
              without_refunds: 497727.1439,
              with_refunds: 497727.1439,
            },
            gross_arr_spread: {
              without_refunds: 550521.7685,
              with_refunds: 550521.7685,
            },
            net_total: {
              without_refunds: 37774.5268,
              with_refunds: 34828.3463,
            },
            gross_total: {
              without_refunds: 40661.7684,
              with_refunds: 37578.4249,
            },
            net_total_spread: {
              without_refunds: 338218.7452,
              with_refunds: 339341.4091,
            },
            gross_total_spread: {
              without_refunds: 376703.4189,
              with_refunds: 377683.2314,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 1.04,
                with_refunds: 1.03,
              },
              gross_mrr: {
                without_refunds: 1.16,
                with_refunds: 1.15,
              },
              net_arr: {
                without_refunds: 0.04,
                with_refunds: 0.03,
              },
              gross_arr: {
                without_refunds: 0.06,
                with_refunds: 0.04,
              },
              net_arr_spread: {
                without_refunds: 9.02,
                with_refunds: 9.02,
              },
              gross_arr_spread: {
                without_refunds: 10.18,
                with_refunds: 10.18,
              },
              net_total: {
                without_refunds: 0.67,
                with_refunds: 0.66,
              },
              gross_total: {
                without_refunds: 0.75,
                with_refunds: 0.74,
              },
              net_total_spread: {
                without_refunds: 2.79,
                with_refunds: 2.79,
              },
              gross_total_spread: {
                without_refunds: 3.15,
                with_refunds: 3.15,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.11,
                with_refunds: 0.1,
              },
              gross_mrr: {
                without_refunds: 0.11,
                with_refunds: 0.11,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 11.77,
                with_refunds: 11.77,
              },
              gross_arr_spread: {
                without_refunds: 12.58,
                with_refunds: 12.58,
              },
              net_total: {
                without_refunds: 0.02,
                with_refunds: 0.02,
              },
              gross_total: {
                without_refunds: 0.02,
                with_refunds: 0.03,
              },
              net_total_spread: {
                without_refunds: 2.85,
                with_refunds: 2.87,
              },
              gross_total_spread: {
                without_refunds: 3.03,
                with_refunds: 3.05,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.12,
                with_refunds: 0.12,
              },
              gross_mrr: {
                without_refunds: 0.14,
                with_refunds: 0.14,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 10.71,
                with_refunds: 10.71,
              },
              gross_arr_spread: {
                without_refunds: 11.88,
                with_refunds: 11.88,
              },
              net_total: {
                without_refunds: 0.03,
                with_refunds: 0.03,
              },
              gross_total: {
                without_refunds: 0.04,
                with_refunds: 0.04,
              },
              net_total_spread: {
                without_refunds: 3.49,
                with_refunds: 3.53,
              },
              gross_total_spread: {
                without_refunds: 3.86,
                with_refunds: 3.9,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.64,
                with_refunds: 19.64,
              },
              gross_mrr: {
                without_refunds: 23.9,
                with_refunds: 22.76,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 70.92,
                with_refunds: 70.92,
              },
              gross_arr_spread: {
                without_refunds: 76.48,
                with_refunds: 76.48,
              },
              net_total: {
                without_refunds: 61,
                with_refunds: 56.73,
              },
              gross_total: {
                without_refunds: 66.28,
                with_refunds: 61.76,
              },
              net_total_spread: {
                without_refunds: 49.96,
                with_refunds: 48.21,
              },
              gross_total_spread: {
                without_refunds: 56.6,
                with_refunds: 54.6,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.94,
                with_refunds: 22.05,
              },
              gross_mrr: {
                without_refunds: 26.25,
                with_refunds: 25.25,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 50.86,
                with_refunds: 50.86,
              },
              gross_arr_spread: {
                without_refunds: 56.87,
                with_refunds: 56.87,
              },
              net_total: {
                without_refunds: 68.73,
                with_refunds: 60.33,
              },
              gross_total: {
                without_refunds: 76.63,
                with_refunds: 67.34,
              },
              net_total_spread: {
                without_refunds: 42.11,
                with_refunds: 41.16,
              },
              gross_total_spread: {
                without_refunds: 47.65,
                with_refunds: 46.58,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.1,
                with_refunds: 11.84,
              },
              gross_mrr: {
                without_refunds: 13.61,
                with_refunds: 13.33,
              },
              net_arr: {
                without_refunds: 43.32,
                with_refunds: 6.9,
              },
              gross_arr: {
                without_refunds: 55.23,
                with_refunds: 9.42,
              },
              net_arr_spread: {
                without_refunds: 9.01,
                with_refunds: 9.01,
              },
              gross_arr_spread: {
                without_refunds: 10.17,
                with_refunds: 10.17,
              },
              net_total: {
                without_refunds: 12.38,
                with_refunds: 11.13,
              },
              gross_total: {
                without_refunds: 13.81,
                with_refunds: 12.57,
              },
              net_total_spread: {
                without_refunds: 8.81,
                with_refunds: 8.8,
              },
              gross_total_spread: {
                without_refunds: 9.98,
                with_refunds: 9.97,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.05,
                with_refunds: 18.6,
              },
              gross_mrr: {
                without_refunds: 21.62,
                with_refunds: 20.07,
              },
              net_arr: {
                without_refunds: 82.97,
              },
              gross_arr: {
                without_refunds: 111.62,
              },
              net_arr_spread: {
                without_refunds: 11.47,
                with_refunds: 11.47,
              },
              gross_arr_spread: {
                without_refunds: 12.26,
                with_refunds: 12.26,
              },
              net_total: {
                without_refunds: 12.01,
                with_refunds: 12.26,
              },
              gross_total: {
                without_refunds: 12.65,
                with_refunds: 12.92,
              },
              net_total_spread: {
                without_refunds: 10.33,
                with_refunds: 10.41,
              },
              gross_total_spread: {
                without_refunds: 11,
                with_refunds: 11.07,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.6,
                with_refunds: 14.27,
              },
              gross_mrr: {
                without_refunds: 16.47,
                with_refunds: 16.11,
              },
              net_arr: {
                without_refunds: 90.45,
                with_refunds: 7.8,
              },
              gross_arr: {
                without_refunds: 114.75,
                with_refunds: 11.16,
              },
              net_arr_spread: {
                without_refunds: 10.63,
                with_refunds: 10.63,
              },
              gross_arr_spread: {
                without_refunds: 11.79,
                with_refunds: 11.79,
              },
              net_total: {
                without_refunds: 15.05,
                with_refunds: 10.56,
              },
              gross_total: {
                without_refunds: 16.4,
                with_refunds: 12.03,
              },
              net_total_spread: {
                without_refunds: 9.16,
                with_refunds: 9.27,
              },
              gross_total_spread: {
                without_refunds: 10.13,
                with_refunds: 10.24,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.64,
                with_refunds: 19.64,
              },
              gross_mrr: {
                without_refunds: 23.9,
                with_refunds: 22.76,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 70.92,
                with_refunds: 70.92,
              },
              gross_arr_spread: {
                without_refunds: 76.48,
                with_refunds: 76.48,
              },
              net_total: {
                without_refunds: 61,
                with_refunds: 56.73,
              },
              gross_total: {
                without_refunds: 66.28,
                with_refunds: 61.76,
              },
              net_total_spread: {
                without_refunds: 49.96,
                with_refunds: 48.21,
              },
              gross_total_spread: {
                without_refunds: 56.6,
                with_refunds: 54.6,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 22.94,
                with_refunds: 22.05,
              },
              gross_mrr: {
                without_refunds: 26.25,
                with_refunds: 25.25,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 50.86,
                with_refunds: 50.86,
              },
              gross_arr_spread: {
                without_refunds: 56.87,
                with_refunds: 56.87,
              },
              net_total: {
                without_refunds: 68.73,
                with_refunds: 60.33,
              },
              gross_total: {
                without_refunds: 76.63,
                with_refunds: 67.34,
              },
              net_total_spread: {
                without_refunds: 42.11,
                with_refunds: 41.16,
              },
              gross_total_spread: {
                without_refunds: 47.65,
                with_refunds: 46.58,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 40.34,
                  with_refunds: 39.29,
                },
                gross_mrr: {
                  without_refunds: 46.97,
                  with_refunds: 45.77,
                },
                net_arr: {
                  without_refunds: 109.32,
                  with_refunds: 88.38,
                },
                gross_arr: {
                  without_refunds: 122.85,
                  with_refunds: 99.63,
                },
                net_arr_spread: {
                  without_refunds: 84.58,
                  with_refunds: 84.58,
                },
                gross_arr_spread: {
                  without_refunds: 95.37,
                  with_refunds: 95.37,
                },
                net_total: {
                  without_refunds: 66.44,
                  with_refunds: 57.95,
                },
                gross_total: {
                  without_refunds: 75.71,
                  with_refunds: 66.27,
                },
                net_total_spread: {
                  without_refunds: 52.61,
                  with_refunds: 51.93,
                },
                gross_total_spread: {
                  without_refunds: 60.36,
                  with_refunds: 59.59,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 29.26,
                  with_refunds: 27.83,
                },
                gross_mrr: {
                  without_refunds: 33.13,
                  with_refunds: 31.48,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 112.12,
                  with_refunds: 112.12,
                },
                gross_arr_spread: {
                  without_refunds: 119.88,
                  with_refunds: 119.88,
                },
                net_total: {
                  without_refunds: 82.11,
                  with_refunds: 74.19,
                },
                gross_total: {
                  without_refunds: 88.46,
                  with_refunds: 80.06,
                },
                net_total_spread: {
                  without_refunds: 59.42,
                  with_refunds: 58.75,
                },
                gross_total_spread: {
                  without_refunds: 64.45,
                  with_refunds: 63.63,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 33.08,
                  with_refunds: 31.57,
                },
                gross_mrr: {
                  without_refunds: 37.67,
                  with_refunds: 35.98,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.73,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.34,
                },
                net_arr_spread: {
                  without_refunds: 100.85,
                  with_refunds: 100.85,
                },
                gross_arr_spread: {
                  without_refunds: 111.82,
                  with_refunds: 111.82,
                },
                net_total: {
                  without_refunds: 98.35,
                  with_refunds: 78.41,
                },
                gross_total: {
                  without_refunds: 109,
                  with_refunds: 87.09,
                },
                net_total_spread: {
                  without_refunds: 65.37,
                  with_refunds: 64.96,
                },
                gross_total_spread: {
                  without_refunds: 72.82,
                  with_refunds: 72.35,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 119.92,
                  with_refunds: 116.62,
                },
                gross_mrr: {
                  without_refunds: 137.63,
                  with_refunds: 133.91,
                },
                net_arr: {
                  without_refunds: 939.12,
                  with_refunds: 398.75,
                },
                gross_arr: {
                  without_refunds: 1125.73,
                  with_refunds: 488.26,
                },
                net_arr_spread: {
                  without_refunds: 89.49,
                  with_refunds: 89.49,
                },
                gross_arr_spread: {
                  without_refunds: 101.02,
                  with_refunds: 101.02,
                },
                net_total: {
                  without_refunds: 194.11,
                  with_refunds: 164.91,
                },
                gross_total: {
                  without_refunds: 220.21,
                  with_refunds: 188.38,
                },
                net_total_spread: {
                  without_refunds: 99.1,
                  with_refunds: 97.82,
                },
                gross_total_spread: {
                  without_refunds: 113.54,
                  with_refunds: 112.08,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 195.96,
                  with_refunds: 182.69,
                },
                gross_mrr: {
                  without_refunds: 214.52,
                  with_refunds: 199.96,
                },
                net_arr: {
                  without_refunds: 1086.5,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 1292.19,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 114.28,
                  with_refunds: 114.28,
                },
                gross_arr_spread: {
                  without_refunds: 122.26,
                  with_refunds: 122.26,
                },
                net_total: {
                  without_refunds: 257.88,
                  with_refunds: 232.02,
                },
                gross_total: {
                  without_refunds: 278.02,
                  with_refunds: 249.45,
                },
                net_total_spread: {
                  without_refunds: 122.07,
                  with_refunds: 121.15,
                },
                gross_total_spread: {
                  without_refunds: 131.81,
                  with_refunds: 130.67,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 144.08,
                  with_refunds: 138.51,
                },
                gross_mrr: {
                  without_refunds: 163.02,
                  with_refunds: 156.86,
                },
                net_arr: {
                  without_refunds: 1136.33,
                  with_refunds: 271.33,
                },
                gross_arr: {
                  without_refunds: 1318.56,
                  with_refunds: 318.65,
                },
                net_arr_spread: {
                  without_refunds: 105.61,
                  with_refunds: 105.61,
                },
                gross_arr_spread: {
                  without_refunds: 117.18,
                  with_refunds: 117.18,
                },
                net_total: {
                  without_refunds: 308.98,
                  with_refunds: 222.39,
                },
                gross_total: {
                  without_refunds: 342.68,
                  with_refunds: 250.1,
                },
                net_total_spread: {
                  without_refunds: 105.74,
                  with_refunds: 105.24,
                },
                gross_total_spread: {
                  without_refunds: 117.61,
                  with_refunds: 117.03,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6738,
                  with_refunds: 0.6552,
                },
                gross_mrr: {
                  without_refunds: 0.7831,
                  with_refunds: 0.7618,
                },
                net_arr: {
                  without_refunds: 1.8816,
                  with_refunds: 1.5579,
                },
                gross_arr: {
                  without_refunds: 2.1012,
                  with_refunds: 1.7428,
                },
                net_arr_spread: {
                  without_refunds: 1.4935,
                  with_refunds: 1.4935,
                },
                gross_arr_spread: {
                  without_refunds: 1.6717,
                  with_refunds: 1.6717,
                },
                net_total: {
                  without_refunds: 1.1308,
                  with_refunds: 0.9986,
                },
                gross_total: {
                  without_refunds: 1.2823,
                  with_refunds: 1.1354,
                },
                net_total_spread: {
                  without_refunds: 0.8995,
                  with_refunds: 0.8874,
                },
                gross_total_spread: {
                  without_refunds: 1.0274,
                  with_refunds: 1.0134,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7294,
                  with_refunds: 1.652,
                },
                gross_mrr: {
                  without_refunds: 1.97,
                  with_refunds: 1.8794,
                },
                net_arr: {
                  without_refunds: 8.9846,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5711,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 7.0667,
                  with_refunds: 7.0667,
                },
                gross_arr_spread: {
                  without_refunds: 7.5546,
                  with_refunds: 7.5546,
                },
                net_total: {
                  without_refunds: 5.3031,
                  with_refunds: 4.7713,
                },
                gross_total: {
                  without_refunds: 5.7142,
                  with_refunds: 5.148,
                },
                net_total_spread: {
                  without_refunds: 3.6215,
                  with_refunds: 3.5834,
                },
                gross_total_spread: {
                  without_refunds: 3.9314,
                  with_refunds: 3.8855,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7823,
                  with_refunds: 0.7472,
                },
                gross_mrr: {
                  without_refunds: 0.8923,
                  with_refunds: 0.8523,
                },
                net_arr: {
                  without_refunds: 2.2763,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4824,
                  with_refunds: 2.028,
                },
                net_arr_spread: {
                  without_refunds: 1.8825,
                  with_refunds: 1.8825,
                },
                gross_arr_spread: {
                  without_refunds: 2.0582,
                  with_refunds: 2.0582,
                },
                net_total: {
                  without_refunds: 1.8109,
                  with_refunds: 1.5155,
                },
                gross_total: {
                  without_refunds: 1.9873,
                  with_refunds: 1.6648,
                },
                net_total_spread: {
                  without_refunds: 1.2772,
                  with_refunds: 1.2662,
                },
                gross_total_spread: {
                  without_refunds: 1.4095,
                  with_refunds: 1.3967,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 2.123,
                  with_refunds: 2.0497,
                },
                gross_mrr: {
                  without_refunds: 2.4222,
                  with_refunds: 2.3404,
                },
                net_arr: {
                  without_refunds: 15.6251,
                  with_refunds: 6.6653,
                },
                gross_arr: {
                  without_refunds: 18.9659,
                  with_refunds: 8.1237,
                },
                net_arr_spread: {
                  without_refunds: 1.5789,
                  with_refunds: 1.5789,
                },
                gross_arr_spread: {
                  without_refunds: 1.7691,
                  with_refunds: 1.7691,
                },
                net_total: {
                  without_refunds: 3.3355,
                  with_refunds: 2.941,
                },
                gross_total: {
                  without_refunds: 3.7656,
                  with_refunds: 3.3297,
                },
                net_total_spread: {
                  without_refunds: 1.7211,
                  with_refunds: 1.6984,
                },
                gross_total_spread: {
                  without_refunds: 1.9614,
                  with_refunds: 1.9353,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 10.8375,
                  with_refunds: 9.927,
                },
                gross_mrr: {
                  without_refunds: 11.8491,
                  with_refunds: 10.8584,
                },
                net_arr: {
                  without_refunds: 68.4897,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 83.4962,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 7.3643,
                  with_refunds: 7.3643,
                },
                gross_arr_spread: {
                  without_refunds: 7.8774,
                  with_refunds: 7.8774,
                },
                net_total: {
                  without_refunds: 17.0331,
                  with_refunds: 16.0492,
                },
                gross_total: {
                  without_refunds: 18.3967,
                  with_refunds: 17.2434,
                },
                net_total_spread: {
                  without_refunds: 7.2247,
                  with_refunds: 7.1658,
                },
                gross_total_spread: {
                  without_refunds: 7.8078,
                  with_refunds: 7.7362,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 3.9765,
                  with_refunds: 3.7197,
                },
                gross_mrr: {
                  without_refunds: 4.4157,
                  with_refunds: 4.1352,
                },
                net_arr: {
                  without_refunds: 19.0333,
                  with_refunds: 4.8229,
                },
                gross_arr: {
                  without_refunds: 22.5828,
                  with_refunds: 5.5544,
                },
                net_arr_spread: {
                  without_refunds: 1.9724,
                  with_refunds: 1.9724,
                },
                gross_arr_spread: {
                  without_refunds: 2.1581,
                  with_refunds: 2.1581,
                },
                net_total: {
                  without_refunds: 5.7321,
                  with_refunds: 4.6807,
                },
                gross_total: {
                  without_refunds: 6.2985,
                  with_refunds: 5.1588,
                },
                net_total_spread: {
                  without_refunds: 2.2714,
                  with_refunds: 2.2559,
                },
                gross_total_spread: {
                  without_refunds: 2.497,
                  with_refunds: 2.4787,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 10,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0673,
                with_refunds: 0.0675,
              },
              arr_users: {
                without_refunds: 0.0004,
                with_refunds: 0.0004,
              },
              arr_spread_users: {
                without_refunds: 1.0056,
                with_refunds: 1.0056,
              },
              total_users: {
                without_refunds: 0.0429,
                with_refunds: 0.0429,
              },
              total_spread_users: {
                without_refunds: 0.2787,
                with_refunds: 0.2785,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7152,
                with_refunds: 0.714,
              },
              arr_users: {
                without_refunds: 0.3281,
                with_refunds: 0.4048,
              },
              arr_spread_users: {
                without_refunds: 1.0053,
                with_refunds: 1.0053,
              },
              total_users: {
                without_refunds: 0.7035,
                with_refunds: 0.7024,
              },
              total_spread_users: {
                without_refunds: 0.8813,
                with_refunds: 0.8804,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0037,
                with_refunds: 0.0037,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0332,
                with_refunds: 1.0332,
              },
              total_users: {
                without_refunds: 0.0012,
                with_refunds: 0.0013,
              },
              total_spread_users: {
                without_refunds: 0.2428,
                with_refunds: 0.2425,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7065,
                with_refunds: 0.7054,
              },
              arr_users: {
                without_refunds: 0.6667,
                with_refunds: 0.6667,
              },
              arr_spread_users: {
                without_refunds: 1.0056,
                with_refunds: 1.0056,
              },
              total_users: {
                without_refunds: 0.7067,
                with_refunds: 0.7056,
              },
              total_spread_users: {
                without_refunds: 0.8818,
                with_refunds: 0.8809,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0062,
                with_refunds: 0.0062,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0128,
                with_refunds: 1.0128,
              },
              total_users: {
                without_refunds: 0.0019,
                with_refunds: 0.0019,
              },
              total_spread_users: {
                without_refunds: 0.3361,
                with_refunds: 0.3354,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.7065,
                with_refunds: 0.7054,
              },
              arr_users: {
                without_refunds: 0.6667,
                with_refunds: 0.6667,
              },
              arr_spread_users: {
                without_refunds: 1.0056,
                with_refunds: 1.0056,
              },
              total_users: {
                without_refunds: 0.7067,
                with_refunds: 0.7056,
              },
              total_spread_users: {
                without_refunds: 0.8818,
                with_refunds: 0.8809,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.1166,
                with_refunds: 0.1188,
              },
              gross_mrr: {
                without_refunds: 0.0996,
                with_refunds: 0.1015,
              },
              net_arr: {
                without_refunds: 0.0009,
                with_refunds: 0.0008,
              },
              gross_arr: {
                without_refunds: 0.0008,
                with_refunds: 0.0006,
              },
              net_arr_spread: {
                without_refunds: 2.0189,
                with_refunds: 2.0189,
              },
              gross_arr_spread: {
                without_refunds: 1.9991,
                with_refunds: 1.9991,
              },
              net_total: {
                without_refunds: 0.0461,
                with_refunds: 0.0473,
              },
              gross_total: {
                without_refunds: 0.0375,
                with_refunds: 0.0385,
              },
              net_total_spread: {
                without_refunds: 0.3802,
                with_refunds: 0.3904,
              },
              gross_total_spread: {
                without_refunds: 0.3583,
                with_refunds: 0.3681,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7143,
                with_refunds: 0.7177,
              },
              gross_mrr: {
                without_refunds: 0.7131,
                with_refunds: 0.7164,
              },
              net_arr: {
                without_refunds: 0.33,
                with_refunds: 0.156,
              },
              gross_arr: {
                without_refunds: 0.3239,
                with_refunds: 0.1528,
              },
              net_arr_spread: {
                without_refunds: 1.0074,
                with_refunds: 1.0074,
              },
              gross_arr_spread: {
                without_refunds: 1.0063,
                with_refunds: 1.0063,
              },
              net_total: {
                without_refunds: 0.7049,
                with_refunds: 0.7086,
              },
              gross_total: {
                without_refunds: 0.7037,
                with_refunds: 0.7072,
              },
              net_total_spread: {
                without_refunds: 0.8525,
                with_refunds: 0.8554,
              },
              gross_total_spread: {
                without_refunds: 0.8539,
                with_refunds: 0.8566,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0054,
                with_refunds: 0.0054,
              },
              gross_mrr: {
                without_refunds: 0.005,
                with_refunds: 0.005,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 1.7189,
                with_refunds: 1.7189,
              },
              gross_arr_spread: {
                without_refunds: 1.6987,
                with_refunds: 1.6987,
              },
              net_total: {
                without_refunds: 0.0003,
                with_refunds: 0.0004,
              },
              gross_total: {
                without_refunds: 0.0003,
                with_refunds: 0.0004,
              },
              net_total_spread: {
                without_refunds: 0.2351,
                with_refunds: 0.2452,
              },
              gross_total_spread: {
                without_refunds: 0.2207,
                with_refunds: 0.2301,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7056,
                with_refunds: 0.7088,
              },
              gross_mrr: {
                without_refunds: 0.7043,
                with_refunds: 0.7075,
              },
              net_arr: {
                without_refunds: 0.6705,
              },
              gross_arr: {
                without_refunds: 0.6582,
              },
              net_arr_spread: {
                without_refunds: 1.0079,
                with_refunds: 1.0079,
              },
              gross_arr_spread: {
                without_refunds: 1.0067,
                with_refunds: 1.0067,
              },
              net_total: {
                without_refunds: 0.7081,
                with_refunds: 0.7118,
              },
              gross_total: {
                without_refunds: 0.7069,
                with_refunds: 0.7103,
              },
              net_total_spread: {
                without_refunds: 0.8531,
                with_refunds: 0.8559,
              },
              gross_total_spread: {
                without_refunds: 0.8546,
                with_refunds: 0.8572,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0098,
                with_refunds: 0.0102,
              },
              gross_mrr: {
                without_refunds: 0.0097,
                with_refunds: 0.01,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.0756,
                with_refunds: 2.0756,
              },
              gross_arr_spread: {
                without_refunds: 2.0506,
                with_refunds: 2.0506,
              },
              net_total: {
                without_refunds: 0.0007,
                with_refunds: 0.0007,
              },
              gross_total: {
                without_refunds: 0.0006,
                with_refunds: 0.0007,
              },
              net_total_spread: {
                without_refunds: 0.4573,
                with_refunds: 0.4727,
              },
              gross_total_spread: {
                without_refunds: 0.4498,
                with_refunds: 0.4648,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.7056,
                with_refunds: 0.7088,
              },
              gross_mrr: {
                without_refunds: 0.7043,
                with_refunds: 0.7075,
              },
              net_arr: {
                without_refunds: 0.6705,
                with_refunds: 0.2563,
              },
              gross_arr: {
                without_refunds: 0.6582,
                with_refunds: 0.2511,
              },
              net_arr_spread: {
                without_refunds: 1.0079,
                with_refunds: 1.0079,
              },
              gross_arr_spread: {
                without_refunds: 1.0067,
                with_refunds: 1.0067,
              },
              net_total: {
                without_refunds: 0.7081,
                with_refunds: 0.7118,
              },
              gross_total: {
                without_refunds: 0.7069,
                with_refunds: 0.7103,
              },
              net_total_spread: {
                without_refunds: 0.8531,
                with_refunds: 0.8559,
              },
              gross_total_spread: {
                without_refunds: 0.8546,
                with_refunds: 0.8572,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 17152.329,
              with_refunds: 16803.2184,
            },
            gross_mrr: {
              without_refunds: 19206.3649,
              with_refunds: 18824.4828,
            },
            net_arr: {
              without_refunds: 1106.2128,
              with_refunds: 729.4517,
            },
            gross_arr: {
              without_refunds: 1158.0706,
              with_refunds: 753.6248,
            },
            net_arr_spread: {
              without_refunds: 494800.4881,
              with_refunds: 494800.4881,
            },
            gross_arr_spread: {
              without_refunds: 547428.2022,
              with_refunds: 547428.2022,
            },
            net_total: {
              without_refunds: 15654.0788,
              with_refunds: 15119.2804,
            },
            gross_total: {
              without_refunds: 17384.2244,
              with_refunds: 16865.0559,
            },
            net_total_spread: {
              without_refunds: 324647.7614,
              with_refunds: 326411.3235,
            },
            gross_total_spread: {
              without_refunds: 362287.3655,
              with_refunds: 364193.7288,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 23956.5814,
              with_refunds: 23090.8087,
            },
            gross_mrr: {
              without_refunds: 26568.8073,
              with_refunds: 25476.5726,
            },
            net_arr: {
              without_refunds: 21600.7164,
              with_refunds: 18860.5205,
            },
            gross_arr: {
              without_refunds: 22554.5171,
              with_refunds: 19728.4017,
            },
            net_arr_spread: {
              without_refunds: 503134.7351,
              with_refunds: 503134.7351,
            },
            gross_arr_spread: {
              without_refunds: 556153.7417,
              with_refunds: 556153.7417,
            },
            net_total: {
              without_refunds: 40368.7357,
              with_refunds: 37135.0723,
            },
            gross_total: {
              without_refunds: 43389.4451,
              with_refunds: 40000.7446,
            },
            net_total_spread: {
              without_refunds: 342140.0088,
              with_refunds: 343172.5516,
            },
            gross_total_spread: {
              without_refunds: 380894.2915,
              with_refunds: 381729.5456,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 0.77,
                with_refunds: 0.77,
              },
              gross_mrr: {
                without_refunds: 0.86,
                with_refunds: 0.86,
              },
              net_arr: {
                without_refunds: 0.04,
                with_refunds: 0.03,
              },
              gross_arr: {
                without_refunds: 0.06,
                with_refunds: 0.04,
              },
              net_arr_spread: {
                without_refunds: 9.09,
                with_refunds: 9.09,
              },
              gross_arr_spread: {
                without_refunds: 10.25,
                with_refunds: 10.25,
              },
              net_total: {
                without_refunds: 0.5,
                with_refunds: 0.5,
              },
              gross_total: {
                without_refunds: 0.56,
                with_refunds: 0.55,
              },
              net_total_spread: {
                without_refunds: 2.36,
                with_refunds: 2.37,
              },
              gross_total_spread: {
                without_refunds: 2.67,
                with_refunds: 2.68,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.07,
                with_refunds: 0.07,
              },
              gross_mrr: {
                without_refunds: 0.08,
                with_refunds: 0.08,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 11.86,
                with_refunds: 11.86,
              },
              gross_arr_spread: {
                without_refunds: 12.66,
                with_refunds: 12.66,
              },
              net_total: {
                without_refunds: 0.02,
                with_refunds: 0.02,
              },
              gross_total: {
                without_refunds: 0.02,
                with_refunds: 0.02,
              },
              net_total_spread: {
                without_refunds: 2.43,
                with_refunds: 2.45,
              },
              gross_total_spread: {
                without_refunds: 2.59,
                with_refunds: 2.61,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.09,
                with_refunds: 0.09,
              },
              gross_mrr: {
                without_refunds: 0.1,
                with_refunds: 0.1,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 10.8,
                with_refunds: 10.8,
              },
              gross_arr_spread: {
                without_refunds: 11.96,
                with_refunds: 11.96,
              },
              net_total: {
                without_refunds: 0.02,
                with_refunds: 0.02,
              },
              gross_total: {
                without_refunds: 0.03,
                with_refunds: 0.03,
              },
              net_total_spread: {
                without_refunds: 2.98,
                with_refunds: 3.02,
              },
              gross_total_spread: {
                without_refunds: 3.3,
                with_refunds: 3.34,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.69,
                with_refunds: 19.69,
              },
              gross_mrr: {
                without_refunds: 23.95,
                with_refunds: 22.81,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 78.39,
                with_refunds: 78.39,
              },
              gross_arr_spread: {
                without_refunds: 84.53,
                with_refunds: 84.53,
              },
              net_total: {
                without_refunds: 61.06,
                with_refunds: 56.79,
              },
              gross_total: {
                without_refunds: 66.35,
                with_refunds: 61.83,
              },
              net_total_spread: {
                without_refunds: 52.55,
                with_refunds: 50.7,
              },
              gross_total_spread: {
                without_refunds: 59.53,
                with_refunds: 57.42,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 23,
                with_refunds: 22.11,
              },
              gross_mrr: {
                without_refunds: 26.31,
                with_refunds: 25.32,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 56.27,
                with_refunds: 56.27,
              },
              gross_arr_spread: {
                without_refunds: 62.92,
                with_refunds: 62.92,
              },
              net_total: {
                without_refunds: 68.81,
                with_refunds: 60.4,
              },
              gross_total: {
                without_refunds: 76.72,
                with_refunds: 67.42,
              },
              net_total_spread: {
                without_refunds: 44.43,
                with_refunds: 43.43,
              },
              gross_total_spread: {
                without_refunds: 50.28,
                with_refunds: 49.15,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.08,
                with_refunds: 11.9,
              },
              gross_mrr: {
                without_refunds: 13.55,
                with_refunds: 13.36,
              },
              net_arr: {
                without_refunds: 43.56,
                with_refunds: 7.34,
              },
              gross_arr: {
                without_refunds: 54.52,
                with_refunds: 9.8,
              },
              net_arr_spread: {
                without_refunds: 9.03,
                with_refunds: 9.03,
              },
              gross_arr_spread: {
                without_refunds: 10.18,
                with_refunds: 10.18,
              },
              net_total: {
                without_refunds: 12.4,
                with_refunds: 11.23,
              },
              gross_total: {
                without_refunds: 13.8,
                with_refunds: 12.64,
              },
              net_total_spread: {
                without_refunds: 8.52,
                with_refunds: 8.55,
              },
              gross_total_spread: {
                without_refunds: 9.67,
                with_refunds: 9.7,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.02,
                with_refunds: 18.69,
              },
              gross_mrr: {
                without_refunds: 21.55,
                with_refunds: 20.13,
              },
              net_arr: {
                without_refunds: 83.45,
              },
              gross_arr: {
                without_refunds: 110.21,
              },
              net_arr_spread: {
                without_refunds: 11.5,
                with_refunds: 11.5,
              },
              gross_arr_spread: {
                without_refunds: 12.28,
                with_refunds: 12.28,
              },
              net_total: {
                without_refunds: 12.04,
                with_refunds: 12.37,
              },
              gross_total: {
                without_refunds: 12.65,
                with_refunds: 13.01,
              },
              net_total_spread: {
                without_refunds: 9.99,
                with_refunds: 10.11,
              },
              gross_total_spread: {
                without_refunds: 10.66,
                with_refunds: 10.78,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.59,
                with_refunds: 14.34,
              },
              gross_mrr: {
                without_refunds: 16.42,
                with_refunds: 16.15,
              },
              net_arr: {
                without_refunds: 90.97,
                with_refunds: 8.51,
              },
              gross_arr: {
                without_refunds: 113.29,
                with_refunds: 11.93,
              },
              net_arr_spread: {
                without_refunds: 10.66,
                with_refunds: 10.66,
              },
              gross_arr_spread: {
                without_refunds: 11.8,
                with_refunds: 11.8,
              },
              net_total: {
                without_refunds: 15.08,
                with_refunds: 10.65,
              },
              gross_total: {
                without_refunds: 16.4,
                with_refunds: 12.11,
              },
              net_total_spread: {
                without_refunds: 8.86,
                with_refunds: 9,
              },
              gross_total_spread: {
                without_refunds: 9.81,
                with_refunds: 9.96,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.69,
                with_refunds: 19.69,
              },
              gross_mrr: {
                without_refunds: 23.95,
                with_refunds: 22.81,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 78.39,
                with_refunds: 78.39,
              },
              gross_arr_spread: {
                without_refunds: 84.53,
                with_refunds: 84.53,
              },
              net_total: {
                without_refunds: 61.06,
                with_refunds: 56.79,
              },
              gross_total: {
                without_refunds: 66.35,
                with_refunds: 61.83,
              },
              net_total_spread: {
                without_refunds: 52.55,
                with_refunds: 50.7,
              },
              gross_total_spread: {
                without_refunds: 59.53,
                with_refunds: 57.42,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 23,
                with_refunds: 22.11,
              },
              gross_mrr: {
                without_refunds: 26.31,
                with_refunds: 25.32,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 56.27,
                with_refunds: 56.27,
              },
              gross_arr_spread: {
                without_refunds: 62.92,
                with_refunds: 62.92,
              },
              net_total: {
                without_refunds: 68.81,
                with_refunds: 60.4,
              },
              gross_total: {
                without_refunds: 76.72,
                with_refunds: 67.42,
              },
              net_total_spread: {
                without_refunds: 44.43,
                with_refunds: 43.43,
              },
              gross_total_spread: {
                without_refunds: 50.28,
                with_refunds: 49.15,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 41.11,
                  with_refunds: 40.06,
                },
                gross_mrr: {
                  without_refunds: 47.83,
                  with_refunds: 46.62,
                },
                net_arr: {
                  without_refunds: 109.36,
                  with_refunds: 88.41,
                },
                gross_arr: {
                  without_refunds: 122.91,
                  with_refunds: 99.67,
                },
                net_arr_spread: {
                  without_refunds: 93.67,
                  with_refunds: 93.67,
                },
                gross_arr_spread: {
                  without_refunds: 105.62,
                  with_refunds: 105.62,
                },
                net_total: {
                  without_refunds: 66.95,
                  with_refunds: 58.45,
                },
                gross_total: {
                  without_refunds: 76.27,
                  with_refunds: 66.82,
                },
                net_total_spread: {
                  without_refunds: 54.97,
                  with_refunds: 54.3,
                },
                gross_total_spread: {
                  without_refunds: 63.04,
                  with_refunds: 62.27,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 29.34,
                  with_refunds: 27.9,
                },
                gross_mrr: {
                  without_refunds: 33.21,
                  with_refunds: 31.56,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 123.98,
                  with_refunds: 123.98,
                },
                gross_arr_spread: {
                  without_refunds: 132.55,
                  with_refunds: 132.55,
                },
                net_total: {
                  without_refunds: 82.13,
                  with_refunds: 74.21,
                },
                gross_total: {
                  without_refunds: 88.48,
                  with_refunds: 80.08,
                },
                net_total_spread: {
                  without_refunds: 61.85,
                  with_refunds: 61.2,
                },
                gross_total_spread: {
                  without_refunds: 67.04,
                  with_refunds: 66.24,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 33.17,
                  with_refunds: 31.66,
                },
                gross_mrr: {
                  without_refunds: 37.77,
                  with_refunds: 36.08,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.73,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.34,
                },
                net_arr_spread: {
                  without_refunds: 111.65,
                  with_refunds: 111.65,
                },
                gross_arr_spread: {
                  without_refunds: 123.77,
                  with_refunds: 123.77,
                },
                net_total: {
                  without_refunds: 98.38,
                  with_refunds: 78.43,
                },
                gross_total: {
                  without_refunds: 109.02,
                  with_refunds: 87.11,
                },
                net_total_spread: {
                  without_refunds: 68.35,
                  with_refunds: 67.98,
                },
                gross_total_spread: {
                  without_refunds: 76.12,
                  with_refunds: 75.69,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 132.01,
                  with_refunds: 128.51,
                },
                gross_mrr: {
                  without_refunds: 151.19,
                  with_refunds: 147.27,
                },
                net_arr: {
                  without_refunds: 982.68,
                  with_refunds: 406.1,
                },
                gross_arr: {
                  without_refunds: 1180.25,
                  with_refunds: 498.06,
                },
                net_arr_spread: {
                  without_refunds: 98.52,
                  with_refunds: 98.52,
                },
                gross_arr_spread: {
                  without_refunds: 111.2,
                  with_refunds: 111.2,
                },
                net_total: {
                  without_refunds: 206.52,
                  with_refunds: 176.14,
                },
                gross_total: {
                  without_refunds: 234,
                  with_refunds: 201.02,
                },
                net_total_spread: {
                  without_refunds: 107.62,
                  with_refunds: 106.37,
                },
                gross_total_spread: {
                  without_refunds: 123.21,
                  with_refunds: 121.78,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 215.98,
                  with_refunds: 201.38,
                },
                gross_mrr: {
                  without_refunds: 236.07,
                  with_refunds: 220.08,
                },
                net_arr: {
                  without_refunds: 1169.95,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 1402.4,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 125.77,
                  with_refunds: 125.77,
                },
                gross_arr_spread: {
                  without_refunds: 134.54,
                  with_refunds: 134.54,
                },
                net_total: {
                  without_refunds: 269.92,
                  with_refunds: 244.39,
                },
                gross_total: {
                  without_refunds: 290.67,
                  with_refunds: 262.46,
                },
                net_total_spread: {
                  without_refunds: 132.06,
                  with_refunds: 131.26,
                },
                gross_total_spread: {
                  without_refunds: 142.47,
                  with_refunds: 141.45,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 158.67,
                  with_refunds: 152.85,
                },
                gross_mrr: {
                  without_refunds: 179.44,
                  with_refunds: 173.01,
                },
                net_arr: {
                  without_refunds: 1227.3,
                  with_refunds: 279.84,
                },
                gross_arr: {
                  without_refunds: 1431.84,
                  with_refunds: 330.58,
                },
                net_arr_spread: {
                  without_refunds: 116.27,
                  with_refunds: 116.27,
                },
                gross_arr_spread: {
                  without_refunds: 128.98,
                  with_refunds: 128.98,
                },
                net_total: {
                  without_refunds: 324.06,
                  with_refunds: 233.04,
                },
                gross_total: {
                  without_refunds: 359.08,
                  with_refunds: 262.21,
                },
                net_total_spread: {
                  without_refunds: 114.6,
                  with_refunds: 114.24,
                },
                gross_total_spread: {
                  without_refunds: 127.42,
                  with_refunds: 127,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6864,
                  with_refunds: 0.6677,
                },
                gross_mrr: {
                  without_refunds: 0.7972,
                  with_refunds: 0.7757,
                },
                net_arr: {
                  without_refunds: 1.8824,
                  with_refunds: 1.5585,
                },
                gross_arr: {
                  without_refunds: 2.1022,
                  with_refunds: 1.7435,
                },
                net_arr_spread: {
                  without_refunds: 1.654,
                  with_refunds: 1.654,
                },
                gross_arr_spread: {
                  without_refunds: 1.8512,
                  with_refunds: 1.8512,
                },
                net_total: {
                  without_refunds: 1.139,
                  with_refunds: 1.0066,
                },
                gross_total: {
                  without_refunds: 1.2915,
                  with_refunds: 1.1443,
                },
                net_total_spread: {
                  without_refunds: 0.9394,
                  with_refunds: 0.9274,
                },
                gross_total_spread: {
                  without_refunds: 1.0724,
                  with_refunds: 1.0585,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7332,
                  with_refunds: 1.6555,
                },
                gross_mrr: {
                  without_refunds: 1.9742,
                  with_refunds: 1.8832,
                },
                net_arr: {
                  without_refunds: 8.9846,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5711,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 7.8224,
                  with_refunds: 7.8224,
                },
                gross_arr_spread: {
                  without_refunds: 8.3612,
                  with_refunds: 8.3612,
                },
                net_total: {
                  without_refunds: 5.3044,
                  with_refunds: 4.7728,
                },
                gross_total: {
                  without_refunds: 5.7156,
                  with_refunds: 5.1495,
                },
                net_total_spread: {
                  without_refunds: 3.7702,
                  with_refunds: 3.7334,
                },
                gross_total_spread: {
                  without_refunds: 4.0898,
                  with_refunds: 4.0451,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7842,
                  with_refunds: 0.7491,
                },
                gross_mrr: {
                  without_refunds: 0.8944,
                  with_refunds: 0.8544,
                },
                net_arr: {
                  without_refunds: 2.2763,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4824,
                  with_refunds: 2.028,
                },
                net_arr_spread: {
                  without_refunds: 2.0842,
                  with_refunds: 2.0842,
                },
                gross_arr_spread: {
                  without_refunds: 2.2784,
                  with_refunds: 2.2784,
                },
                net_total: {
                  without_refunds: 1.8114,
                  with_refunds: 1.516,
                },
                gross_total: {
                  without_refunds: 1.9879,
                  with_refunds: 1.6654,
                },
                net_total_spread: {
                  without_refunds: 1.3326,
                  with_refunds: 1.3222,
                },
                gross_total_spread: {
                  without_refunds: 1.47,
                  with_refunds: 1.4579,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 2.3371,
                  with_refunds: 2.2586,
                },
                gross_mrr: {
                  without_refunds: 2.6609,
                  with_refunds: 2.5738,
                },
                net_arr: {
                  without_refunds: 16.3382,
                  with_refunds: 6.7766,
                },
                gross_arr: {
                  without_refunds: 19.8921,
                  with_refunds: 8.2718,
                },
                net_arr_spread: {
                  without_refunds: 1.7381,
                  with_refunds: 1.7381,
                },
                gross_arr_spread: {
                  without_refunds: 1.9472,
                  with_refunds: 1.9472,
                },
                net_total: {
                  without_refunds: 3.5459,
                  with_refunds: 3.1421,
                },
                gross_total: {
                  without_refunds: 3.9986,
                  with_refunds: 3.5536,
                },
                net_total_spread: {
                  without_refunds: 1.8679,
                  with_refunds: 1.8458,
                },
                gross_total_spread: {
                  without_refunds: 2.1273,
                  with_refunds: 2.1017,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 11.9387,
                  with_refunds: 10.9305,
                },
                gross_mrr: {
                  without_refunds: 13.0289,
                  with_refunds: 11.9344,
                },
                net_arr: {
                  without_refunds: 73.6908,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 90.5982,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 8.1058,
                  with_refunds: 8.1058,
                },
                gross_arr_spread: {
                  without_refunds: 8.6689,
                  with_refunds: 8.6689,
                },
                net_total: {
                  without_refunds: 17.872,
                  with_refunds: 16.9847,
                },
                gross_total: {
                  without_refunds: 19.2795,
                  with_refunds: 18.2249,
                },
                net_total_spread: {
                  without_refunds: 7.8113,
                  with_refunds: 7.7583,
                },
                gross_total_spread: {
                  without_refunds: 8.4326,
                  with_refunds: 8.3668,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 4.3794,
                  with_refunds: 4.0994,
                },
                gross_mrr: {
                  without_refunds: 4.8566,
                  with_refunds: 4.5516,
                },
                net_arr: {
                  without_refunds: 20.5197,
                  with_refunds: 4.9516,
                },
                gross_arr: {
                  without_refunds: 24.5111,
                  with_refunds: 5.7347,
                },
                net_arr_spread: {
                  without_refunds: 2.1712,
                  with_refunds: 2.1712,
                },
                gross_arr_spread: {
                  without_refunds: 2.3752,
                  with_refunds: 2.3752,
                },
                net_total: {
                  without_refunds: 6.0162,
                  with_refunds: 4.9347,
                },
                gross_total: {
                  without_refunds: 6.6041,
                  with_refunds: 5.4362,
                },
                net_total_spread: {
                  without_refunds: 2.4586,
                  with_refunds: 2.4455,
                },
                gross_total_spread: {
                  without_refunds: 2.7009,
                  with_refunds: 2.6852,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 11,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.042,
                with_refunds: 0.042,
              },
              arr_users: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              arr_spread_users: {
                without_refunds: 1.0077,
                with_refunds: 1.0077,
              },
              total_users: {
                without_refunds: 0.0266,
                with_refunds: 0.0267,
              },
              total_spread_users: {
                without_refunds: 0.2043,
                with_refunds: 0.2041,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6095,
                with_refunds: 0.6093,
              },
              arr_users: {
                without_refunds: 0.0785,
                with_refunds: 0.0976,
              },
              arr_spread_users: {
                without_refunds: 1.0021,
                with_refunds: 1.0021,
              },
              total_users: {
                without_refunds: 0.6062,
                with_refunds: 0.606,
              },
              total_spread_users: {
                without_refunds: 0.7328,
                with_refunds: 0.7325,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0022,
                with_refunds: 0.0023,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.0353,
                with_refunds: 1.0353,
              },
              total_users: {
                without_refunds: 0.0007,
                with_refunds: 0.0008,
              },
              total_spread_users: {
                without_refunds: 0.1776,
                with_refunds: 0.1773,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6062,
                with_refunds: 0.606,
              },
              arr_users: {
                without_refunds: 0.1667,
                with_refunds: 0.1667,
              },
              arr_spread_users: {
                without_refunds: 1.0021,
                with_refunds: 1.0021,
              },
              total_users: {
                without_refunds: 0.6041,
                with_refunds: 0.6039,
              },
              total_spread_users: {
                without_refunds: 0.7313,
                with_refunds: 0.731,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0038,
                with_refunds: 0.0038,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 1.015,
                with_refunds: 1.015,
              },
              total_users: {
                without_refunds: 0.0011,
                with_refunds: 0.0011,
              },
              total_spread_users: {
                without_refunds: 0.2458,
                with_refunds: 0.2452,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.6062,
                with_refunds: 0.606,
              },
              arr_users: {
                without_refunds: 0.1667,
                with_refunds: 0.1667,
              },
              arr_spread_users: {
                without_refunds: 1.0021,
                with_refunds: 1.0021,
              },
              total_users: {
                without_refunds: 0.6041,
                with_refunds: 0.6039,
              },
              total_spread_users: {
                without_refunds: 0.7313,
                with_refunds: 0.731,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0748,
                with_refunds: 0.0767,
              },
              gross_mrr: {
                without_refunds: 0.0638,
                with_refunds: 0.0654,
              },
              net_arr: {
                without_refunds: 0.0002,
                with_refunds: 0.0002,
              },
              gross_arr: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              net_arr_spread: {
                without_refunds: 2.0248,
                with_refunds: 2.0248,
              },
              gross_arr_spread: {
                without_refunds: 2.0037,
                with_refunds: 2.0037,
              },
              net_total: {
                without_refunds: 0.0288,
                with_refunds: 0.0301,
              },
              gross_total: {
                without_refunds: 0.0232,
                with_refunds: 0.0243,
              },
              net_total_spread: {
                without_refunds: 0.2644,
                with_refunds: 0.273,
              },
              gross_total_spread: {
                without_refunds: 0.256,
                with_refunds: 0.2644,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6128,
                with_refunds: 0.6169,
              },
              gross_mrr: {
                without_refunds: 0.6143,
                with_refunds: 0.6184,
              },
              net_arr: {
                without_refunds: 0.073,
                with_refunds: 0.0468,
              },
              gross_arr: {
                without_refunds: 0.065,
                with_refunds: 0.0442,
              },
              net_arr_spread: {
                without_refunds: 1.0029,
                with_refunds: 1.0029,
              },
              gross_arr_spread: {
                without_refunds: 1.0023,
                with_refunds: 1.0023,
              },
              net_total: {
                without_refunds: 0.5917,
                with_refunds: 0.6035,
              },
              gross_total: {
                without_refunds: 0.5886,
                with_refunds: 0.6029,
              },
              net_total_spread: {
                without_refunds: 0.6913,
                with_refunds: 0.6951,
              },
              gross_total_spread: {
                without_refunds: 0.7112,
                with_refunds: 0.7149,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0033,
                with_refunds: 0.0033,
              },
              gross_mrr: {
                without_refunds: 0.0031,
                with_refunds: 0.0031,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 1.7239,
                with_refunds: 1.7239,
              },
              gross_arr_spread: {
                without_refunds: 1.7026,
                with_refunds: 1.7026,
              },
              net_total: {
                without_refunds: 0.0002,
                with_refunds: 0.0002,
              },
              gross_total: {
                without_refunds: 0.0002,
                with_refunds: 0.0002,
              },
              net_total_spread: {
                without_refunds: 0.1621,
                with_refunds: 0.17,
              },
              gross_total_spread: {
                without_refunds: 0.1566,
                with_refunds: 0.1641,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6095,
                with_refunds: 0.6136,
              },
              gross_mrr: {
                without_refunds: 0.611,
                with_refunds: 0.6151,
              },
              net_arr: {
                without_refunds: 0.155,
              },
              gross_arr: {
                without_refunds: 0.138,
              },
              net_arr_spread: {
                without_refunds: 1.0029,
                with_refunds: 1.0029,
              },
              gross_arr_spread: {
                without_refunds: 1.0023,
                with_refunds: 1.0023,
              },
              net_total: {
                without_refunds: 0.5896,
                with_refunds: 0.6014,
              },
              gross_total: {
                without_refunds: 0.5866,
                with_refunds: 0.6008,
              },
              net_total_spread: {
                without_refunds: 0.6895,
                with_refunds: 0.6933,
              },
              gross_total_spread: {
                without_refunds: 0.7095,
                with_refunds: 0.7132,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.006,
                with_refunds: 0.0062,
              },
              gross_mrr: {
                without_refunds: 0.0059,
                with_refunds: 0.0062,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 2.0816,
                with_refunds: 2.0816,
              },
              gross_arr_spread: {
                without_refunds: 2.0553,
                with_refunds: 2.0553,
              },
              net_total: {
                without_refunds: 0.0004,
                with_refunds: 0.0004,
              },
              gross_total: {
                without_refunds: 0.0004,
                with_refunds: 0.0004,
              },
              net_total_spread: {
                without_refunds: 0.3153,
                with_refunds: 0.3277,
              },
              gross_total_spread: {
                without_refunds: 0.3192,
                with_refunds: 0.3315,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.6095,
                with_refunds: 0.6136,
              },
              gross_mrr: {
                without_refunds: 0.611,
                with_refunds: 0.6151,
              },
              net_arr: {
                without_refunds: 0.155,
                with_refunds: 0.0853,
              },
              gross_arr: {
                without_refunds: 0.138,
                with_refunds: 0.0807,
              },
              net_arr_spread: {
                without_refunds: 1.0029,
                with_refunds: 1.0029,
              },
              gross_arr_spread: {
                without_refunds: 1.0023,
                with_refunds: 1.0023,
              },
              net_total: {
                without_refunds: 0.5896,
                with_refunds: 0.6014,
              },
              gross_total: {
                without_refunds: 0.5866,
                with_refunds: 0.6008,
              },
              net_total_spread: {
                without_refunds: 0.6895,
                with_refunds: 0.6933,
              },
              gross_total_spread: {
                without_refunds: 0.7095,
                with_refunds: 0.7132,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 17158.0617,
              with_refunds: 16808.7,
            },
            gross_mrr: {
              without_refunds: 19212.4951,
              with_refunds: 18830.2975,
            },
            net_arr: {
              without_refunds: 1106.2128,
              with_refunds: 729.4517,
            },
            gross_arr: {
              without_refunds: 1158.0706,
              with_refunds: 753.6248,
            },
            net_arr_spread: {
              without_refunds: 495785.0102,
              with_refunds: 495785.0102,
            },
            gross_arr_spread: {
              without_refunds: 548420.1017,
              with_refunds: 548420.1017,
            },
            net_total: {
              without_refunds: 15654.81,
              with_refunds: 15119.9447,
            },
            gross_total: {
              without_refunds: 17384.9756,
              with_refunds: 16865.7499,
            },
            net_total_spread: {
              without_refunds: 324942.9792,
              with_refunds: 326709.8425,
            },
            gross_total_spread: {
              without_refunds: 362608.5241,
              with_refunds: 364516.651,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 24790.965,
              with_refunds: 23860.7762,
            },
            gross_mrr: {
              without_refunds: 27468.697,
              with_refunds: 26288.6652,
            },
            net_arr: {
              without_refunds: 23735.3933,
              with_refunds: 20743.857,
            },
            gross_arr: {
              without_refunds: 24781.5274,
              with_refunds: 21697.8236,
            },
            net_arr_spread: {
              without_refunds: 505858.912,
              with_refunds: 505858.912,
            },
            gross_arr_spread: {
              without_refunds: 558965.84,
              with_refunds: 558965.84,
            },
            net_total: {
              without_refunds: 42959.3681,
              with_refunds: 39437.8705,
            },
            gross_total: {
              without_refunds: 46113.3897,
              with_refunds: 42418.9505,
            },
            net_total_spread: {
              without_refunds: 345330.7746,
              with_refunds: 346256.4112,
            },
            gross_total_spread: {
              without_refunds: 384282.6758,
              with_refunds: 384954.3247,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 0.49,
                with_refunds: 0.49,
              },
              gross_mrr: {
                without_refunds: 0.54,
                with_refunds: 0.54,
              },
              net_arr: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              gross_arr: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              net_arr_spread: {
                without_refunds: 9.12,
                with_refunds: 9.12,
              },
              gross_arr_spread: {
                without_refunds: 10.27,
                with_refunds: 10.27,
              },
              net_total: {
                without_refunds: 0.31,
                with_refunds: 0.31,
              },
              gross_total: {
                without_refunds: 0.34,
                with_refunds: 0.34,
              },
              net_total_spread: {
                without_refunds: 1.64,
                with_refunds: 1.65,
              },
              gross_total_spread: {
                without_refunds: 1.91,
                with_refunds: 1.92,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.05,
                with_refunds: 0.04,
              },
              gross_mrr: {
                without_refunds: 0.05,
                with_refunds: 0.05,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 11.9,
                with_refunds: 11.9,
              },
              gross_arr_spread: {
                without_refunds: 12.69,
                with_refunds: 12.69,
              },
              net_total: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              gross_total: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              net_total_spread: {
                without_refunds: 1.68,
                with_refunds: 1.7,
              },
              gross_total_spread: {
                without_refunds: 1.84,
                with_refunds: 1.86,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.05,
                with_refunds: 0.05,
              },
              gross_mrr: {
                without_refunds: 0.06,
                with_refunds: 0.06,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 10.83,
                with_refunds: 10.83,
              },
              gross_arr_spread: {
                without_refunds: 11.98,
                with_refunds: 11.98,
              },
              net_total: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              gross_total: {
                without_refunds: 0.02,
                with_refunds: 0.02,
              },
              net_total_spread: {
                without_refunds: 2.05,
                with_refunds: 2.09,
              },
              gross_total_spread: {
                without_refunds: 2.34,
                with_refunds: 2.38,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.72,
                with_refunds: 19.72,
              },
              gross_mrr: {
                without_refunds: 23.99,
                with_refunds: 22.85,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 85.87,
                with_refunds: 85.87,
              },
              gross_arr_spread: {
                without_refunds: 92.6,
                with_refunds: 92.6,
              },
              net_total: {
                without_refunds: 61.1,
                with_refunds: 56.82,
              },
              gross_total: {
                without_refunds: 66.39,
                with_refunds: 61.86,
              },
              net_total_spread: {
                without_refunds: 54.44,
                with_refunds: 52.53,
              },
              gross_total_spread: {
                without_refunds: 61.67,
                with_refunds: 59.48,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 23.03,
                with_refunds: 22.14,
              },
              gross_mrr: {
                without_refunds: 26.35,
                with_refunds: 25.36,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 61.69,
                with_refunds: 61.69,
              },
              gross_arr_spread: {
                without_refunds: 68.98,
                with_refunds: 68.98,
              },
              net_total: {
                without_refunds: 68.87,
                with_refunds: 60.45,
              },
              gross_total: {
                without_refunds: 76.78,
                with_refunds: 67.47,
              },
              net_total_spread: {
                without_refunds: 46.13,
                with_refunds: 45.09,
              },
              gross_total_spread: {
                without_refunds: 52.21,
                with_refunds: 51.03,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.16,
                with_refunds: 12.06,
              },
              gross_mrr: {
                without_refunds: 13.68,
                with_refunds: 13.58,
              },
              net_arr: {
                without_refunds: 38.5,
                with_refunds: 7.54,
              },
              gross_arr: {
                without_refunds: 42.84,
                with_refunds: 9.66,
              },
              net_arr_spread: {
                without_refunds: 9.04,
                with_refunds: 9.04,
              },
              gross_arr_spread: {
                without_refunds: 10.18,
                with_refunds: 10.18,
              },
              net_total: {
                without_refunds: 12.11,
                with_refunds: 11.18,
              },
              gross_total: {
                without_refunds: 13.41,
                with_refunds: 12.57,
              },
              net_total_spread: {
                without_refunds: 7.99,
                with_refunds: 8.07,
              },
              gross_total_spread: {
                without_refunds: 9.36,
                with_refunds: 9.44,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.13,
                with_refunds: 18.93,
              },
              gross_mrr: {
                without_refunds: 21.72,
                with_refunds: 20.43,
              },
              net_arr: {
                without_refunds: 77.61,
              },
              gross_arr: {
                without_refunds: 91.25,
              },
              net_arr_spread: {
                without_refunds: 11.51,
                with_refunds: 11.51,
              },
              gross_arr_spread: {
                without_refunds: 12.28,
                with_refunds: 12.28,
              },
              net_total: {
                without_refunds: 11.75,
                with_refunds: 12.32,
              },
              gross_total: {
                without_refunds: 12.28,
                with_refunds: 12.94,
              },
              net_total_spread: {
                without_refunds: 9.42,
                with_refunds: 9.59,
              },
              gross_total_spread: {
                without_refunds: 10.34,
                with_refunds: 10.51,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.67,
                with_refunds: 14.52,
              },
              gross_mrr: {
                without_refunds: 16.55,
                with_refunds: 16.4,
              },
              net_arr: {
                without_refunds: 84.6,
                with_refunds: 12.37,
              },
              gross_arr: {
                without_refunds: 93.8,
                with_refunds: 16.4,
              },
              net_arr_spread: {
                without_refunds: 10.67,
                with_refunds: 10.67,
              },
              gross_arr_spread: {
                without_refunds: 11.81,
                with_refunds: 11.81,
              },
              net_total: {
                without_refunds: 14.72,
                with_refunds: 10.6,
              },
              gross_total: {
                without_refunds: 15.93,
                with_refunds: 12.05,
              },
              net_total_spread: {
                without_refunds: 8.36,
                with_refunds: 8.54,
              },
              gross_total_spread: {
                without_refunds: 9.52,
                with_refunds: 9.72,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.72,
                with_refunds: 19.72,
              },
              gross_mrr: {
                without_refunds: 23.99,
                with_refunds: 22.85,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 85.87,
                with_refunds: 85.87,
              },
              gross_arr_spread: {
                without_refunds: 92.6,
                with_refunds: 92.6,
              },
              net_total: {
                without_refunds: 61.1,
                with_refunds: 56.82,
              },
              gross_total: {
                without_refunds: 66.39,
                with_refunds: 61.86,
              },
              net_total_spread: {
                without_refunds: 54.44,
                with_refunds: 52.53,
              },
              gross_total_spread: {
                without_refunds: 61.67,
                with_refunds: 59.48,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 23.03,
                with_refunds: 22.14,
              },
              gross_mrr: {
                without_refunds: 26.35,
                with_refunds: 25.36,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 61.69,
                with_refunds: 61.69,
              },
              gross_arr_spread: {
                without_refunds: 68.98,
                with_refunds: 68.98,
              },
              net_total: {
                without_refunds: 68.87,
                with_refunds: 60.45,
              },
              gross_total: {
                without_refunds: 76.78,
                with_refunds: 67.47,
              },
              net_total_spread: {
                without_refunds: 46.13,
                with_refunds: 45.09,
              },
              gross_total_spread: {
                without_refunds: 52.21,
                with_refunds: 51.03,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 41.6,
                  with_refunds: 40.55,
                },
                gross_mrr: {
                  without_refunds: 48.37,
                  with_refunds: 47.16,
                },
                net_arr: {
                  without_refunds: 109.37,
                  with_refunds: 88.42,
                },
                gross_arr: {
                  without_refunds: 122.92,
                  with_refunds: 99.68,
                },
                net_arr_spread: {
                  without_refunds: 102.79,
                  with_refunds: 102.79,
                },
                gross_arr_spread: {
                  without_refunds: 115.89,
                  with_refunds: 115.89,
                },
                net_total: {
                  without_refunds: 67.26,
                  with_refunds: 58.76,
                },
                gross_total: {
                  without_refunds: 76.61,
                  with_refunds: 67.16,
                },
                net_total_spread: {
                  without_refunds: 56.61,
                  with_refunds: 55.96,
                },
                gross_total_spread: {
                  without_refunds: 64.94,
                  with_refunds: 64.19,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 29.38,
                  with_refunds: 27.94,
                },
                gross_mrr: {
                  without_refunds: 33.26,
                  with_refunds: 31.61,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 135.88,
                  with_refunds: 135.88,
                },
                gross_arr_spread: {
                  without_refunds: 145.24,
                  with_refunds: 145.24,
                },
                net_total: {
                  without_refunds: 82.14,
                  with_refunds: 74.22,
                },
                gross_total: {
                  without_refunds: 88.49,
                  with_refunds: 80.09,
                },
                net_total_spread: {
                  without_refunds: 63.53,
                  with_refunds: 62.9,
                },
                gross_total_spread: {
                  without_refunds: 68.88,
                  with_refunds: 68.1,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 33.22,
                  with_refunds: 31.71,
                },
                gross_mrr: {
                  without_refunds: 37.83,
                  with_refunds: 36.14,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.73,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.34,
                },
                net_arr_spread: {
                  without_refunds: 122.48,
                  with_refunds: 122.48,
                },
                gross_arr_spread: {
                  without_refunds: 135.76,
                  with_refunds: 135.76,
                },
                net_total: {
                  without_refunds: 98.39,
                  with_refunds: 78.44,
                },
                gross_total: {
                  without_refunds: 109.04,
                  with_refunds: 87.13,
                },
                net_total_spread: {
                  without_refunds: 70.4,
                  with_refunds: 70.07,
                },
                gross_total_spread: {
                  without_refunds: 78.46,
                  with_refunds: 78.08,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 144.16,
                  with_refunds: 140.57,
                },
                gross_mrr: {
                  without_refunds: 164.86,
                  with_refunds: 160.84,
                },
                net_arr: {
                  without_refunds: 1021.18,
                  with_refunds: 413.63,
                },
                gross_arr: {
                  without_refunds: 1223.09,
                  with_refunds: 507.72,
                },
                net_arr_spread: {
                  without_refunds: 107.56,
                  with_refunds: 107.56,
                },
                gross_arr_spread: {
                  without_refunds: 121.38,
                  with_refunds: 121.38,
                },
                net_total: {
                  without_refunds: 218.63,
                  with_refunds: 187.32,
                },
                gross_total: {
                  without_refunds: 247.41,
                  with_refunds: 213.59,
                },
                net_total_spread: {
                  without_refunds: 115.61,
                  with_refunds: 114.44,
                },
                gross_total_spread: {
                  without_refunds: 132.57,
                  with_refunds: 131.22,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 236.12,
                  with_refunds: 220.3,
                },
                gross_mrr: {
                  without_refunds: 257.79,
                  with_refunds: 240.52,
                },
                net_arr: {
                  without_refunds: 1247.56,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 1493.64,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 137.28,
                  with_refunds: 137.28,
                },
                gross_arr_spread: {
                  without_refunds: 146.82,
                  with_refunds: 146.82,
                },
                net_total: {
                  without_refunds: 281.67,
                  with_refunds: 256.7,
                },
                gross_total: {
                  without_refunds: 302.95,
                  with_refunds: 275.41,
                },
                net_total_spread: {
                  without_refunds: 141.48,
                  with_refunds: 140.85,
                },
                gross_total_spread: {
                  without_refunds: 152.81,
                  with_refunds: 151.96,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 173.33,
                  with_refunds: 167.36,
                },
                gross_mrr: {
                  without_refunds: 195.98,
                  with_refunds: 189.41,
                },
                net_arr: {
                  without_refunds: 1311.9,
                  with_refunds: 292.21,
                },
                gross_arr: {
                  without_refunds: 1525.64,
                  with_refunds: 346.98,
                },
                net_arr_spread: {
                  without_refunds: 126.94,
                  with_refunds: 126.94,
                },
                gross_arr_spread: {
                  without_refunds: 140.79,
                  with_refunds: 140.79,
                },
                net_total: {
                  without_refunds: 338.78,
                  with_refunds: 243.65,
                },
                gross_total: {
                  without_refunds: 375.01,
                  with_refunds: 274.26,
                },
                net_total_spread: {
                  without_refunds: 122.96,
                  with_refunds: 122.78,
                },
                gross_total_spread: {
                  without_refunds: 136.95,
                  with_refunds: 136.72,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6955,
                  with_refunds: 0.6768,
                },
                gross_mrr: {
                  without_refunds: 0.8073,
                  with_refunds: 0.7858,
                },
                net_arr: {
                  without_refunds: 1.8826,
                  with_refunds: 1.5587,
                },
                gross_arr: {
                  without_refunds: 2.1024,
                  with_refunds: 1.7437,
                },
                net_arr_spread: {
                  without_refunds: 1.815,
                  with_refunds: 1.815,
                },
                gross_arr_spread: {
                  without_refunds: 2.0311,
                  with_refunds: 2.0311,
                },
                net_total: {
                  without_refunds: 1.1448,
                  with_refunds: 1.0124,
                },
                gross_total: {
                  without_refunds: 1.2978,
                  with_refunds: 1.1508,
                },
                net_total_spread: {
                  without_refunds: 0.9678,
                  with_refunds: 0.9561,
                },
                gross_total_spread: {
                  without_refunds: 1.1053,
                  with_refunds: 1.0917,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7356,
                  with_refunds: 1.6577,
                },
                gross_mrr: {
                  without_refunds: 1.9767,
                  with_refunds: 1.8856,
                },
                net_arr: {
                  without_refunds: 8.9846,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5711,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 8.5803,
                  with_refunds: 8.5803,
                },
                gross_arr_spread: {
                  without_refunds: 9.1696,
                  with_refunds: 9.1696,
                },
                net_total: {
                  without_refunds: 5.3052,
                  with_refunds: 4.7737,
                },
                gross_total: {
                  without_refunds: 5.7164,
                  with_refunds: 5.1505,
                },
                net_total_spread: {
                  without_refunds: 3.8728,
                  with_refunds: 3.8374,
                },
                gross_total_spread: {
                  without_refunds: 4.2022,
                  with_refunds: 4.159,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7854,
                  with_refunds: 0.7503,
                },
                gross_mrr: {
                  without_refunds: 0.8958,
                  with_refunds: 0.8557,
                },
                net_arr: {
                  without_refunds: 2.2763,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4824,
                  with_refunds: 2.028,
                },
                net_arr_spread: {
                  without_refunds: 2.2865,
                  with_refunds: 2.2865,
                },
                gross_arr_spread: {
                  without_refunds: 2.4992,
                  with_refunds: 2.4992,
                },
                net_total: {
                  without_refunds: 1.8117,
                  with_refunds: 1.5163,
                },
                gross_total: {
                  without_refunds: 1.9882,
                  with_refunds: 1.6657,
                },
                net_total_spread: {
                  without_refunds: 1.3708,
                  with_refunds: 1.3611,
                },
                gross_total_spread: {
                  without_refunds: 1.513,
                  with_refunds: 1.5015,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 2.5523,
                  with_refunds: 2.4703,
                },
                gross_mrr: {
                  without_refunds: 2.9016,
                  with_refunds: 2.8107,
                },
                net_arr: {
                  without_refunds: 16.9817,
                  with_refunds: 6.9075,
                },
                gross_arr: {
                  without_refunds: 20.6364,
                  with_refunds: 8.4378,
                },
                net_arr_spread: {
                  without_refunds: 1.8975,
                  with_refunds: 1.8975,
                },
                gross_arr_spread: {
                  without_refunds: 2.1255,
                  with_refunds: 2.1255,
                },
                net_total: {
                  without_refunds: 3.7511,
                  with_refunds: 3.3423,
                },
                gross_total: {
                  without_refunds: 4.2248,
                  with_refunds: 3.7763,
                },
                net_total_spread: {
                  without_refunds: 2.0068,
                  with_refunds: 1.986,
                },
                gross_total_spread: {
                  without_refunds: 2.2884,
                  with_refunds: 2.2643,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 13.046,
                  with_refunds: 11.9466,
                },
                gross_mrr: {
                  without_refunds: 14.2181,
                  with_refunds: 13.0267,
                },
                net_arr: {
                  without_refunds: 78.5275,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 96.4784,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 8.848,
                  with_refunds: 8.848,
                },
                gross_arr_spread: {
                  without_refunds: 9.4605,
                  with_refunds: 9.4605,
                },
                net_total: {
                  without_refunds: 18.6909,
                  with_refunds: 17.9163,
                },
                gross_total: {
                  without_refunds: 20.1367,
                  with_refunds: 19.2013,
                },
                net_total_spread: {
                  without_refunds: 8.3644,
                  with_refunds: 8.3203,
                },
                gross_total_spread: {
                  without_refunds: 9.0388,
                  with_refunds: 8.9822,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 4.7845,
                  with_refunds: 4.484,
                },
                gross_mrr: {
                  without_refunds: 5.3009,
                  with_refunds: 4.9743,
                },
                net_arr: {
                  without_refunds: 21.9019,
                  with_refunds: 5.1384,
                },
                gross_arr: {
                  without_refunds: 26.1076,
                  with_refunds: 5.9825,
                },
                net_arr_spread: {
                  without_refunds: 2.3703,
                  with_refunds: 2.3703,
                },
                gross_arr_spread: {
                  without_refunds: 2.5924,
                  with_refunds: 2.5924,
                },
                net_total: {
                  without_refunds: 6.2935,
                  with_refunds: 5.1877,
                },
                gross_total: {
                  without_refunds: 6.9008,
                  with_refunds: 5.7121,
                },
                net_total_spread: {
                  without_refunds: 2.6351,
                  with_refunds: 2.6254,
                },
                gross_total_spread: {
                  without_refunds: 2.8988,
                  with_refunds: 2.8867,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
    {
      period: 12,
      user_retention: {
        avg: {
          base: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0126,
                with_refunds: 0.0126,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3429,
                with_refunds: 0.3429,
              },
              total_users: {
                without_refunds: 0.008,
                with_refunds: 0.008,
              },
              total_spread_users: {
                without_refunds: 0.0639,
                with_refunds: 0.0638,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3006,
                with_refunds: 0.3,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.3,
                with_refunds: 0.2994,
              },
              total_spread_users: {
                without_refunds: 0.3128,
                with_refunds: 0.3124,
              },
            },
          },
          three_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0007,
                with_refunds: 0.0007,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3523,
                with_refunds: 0.3523,
              },
              total_users: {
                without_refunds: 0.0002,
                with_refunds: 0.0002,
              },
              total_spread_users: {
                without_refunds: 0.0555,
                with_refunds: 0.0554,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3006,
                with_refunds: 0.3,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.3,
                with_refunds: 0.2994,
              },
              total_spread_users: {
                without_refunds: 0.3128,
                with_refunds: 0.3124,
              },
            },
          },
          six_months: {
            start_over_month: {
              mrr_users: {
                without_refunds: 0.0011,
                with_refunds: 0.0011,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3453,
                with_refunds: 0.3453,
              },
              total_users: {
                without_refunds: 0.0003,
                with_refunds: 0.0003,
              },
              total_spread_users: {
                without_refunds: 0.0769,
                with_refunds: 0.0766,
              },
            },
            month_over_month: {
              mrr_users: {
                without_refunds: 0.3006,
                with_refunds: 0.3,
              },
              arr_users: {},
              arr_spread_users: {
                without_refunds: 0.3402,
                with_refunds: 0.3402,
              },
              total_users: {
                without_refunds: 0.3,
                with_refunds: 0.2994,
              },
              total_spread_users: {
                without_refunds: 0.3128,
                with_refunds: 0.3124,
              },
            },
          },
        },
      },
      revenue: {
        avg: {
          base: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0224,
                with_refunds: 0.023,
              },
              gross_mrr: {
                without_refunds: 0.019,
                with_refunds: 0.0195,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.7772,
                with_refunds: 0.7772,
              },
              gross_arr_spread: {
                without_refunds: 0.7192,
                with_refunds: 0.7192,
              },
              net_total: {
                without_refunds: 0.0085,
                with_refunds: 0.0089,
              },
              gross_total: {
                without_refunds: 0.0068,
                with_refunds: 0.0071,
              },
              net_total_spread: {
                without_refunds: 0.0835,
                with_refunds: 0.0864,
              },
              gross_total_spread: {
                without_refunds: 0.0801,
                with_refunds: 0.0828,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.2991,
                with_refunds: 0.2996,
              },
              gross_mrr: {
                without_refunds: 0.2973,
                with_refunds: 0.2978,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.3839,
                with_refunds: 0.3839,
              },
              gross_arr_spread: {
                without_refunds: 0.3589,
                with_refunds: 0.3589,
              },
              net_total: {
                without_refunds: 0.294,
                with_refunds: 0.2945,
              },
              gross_total: {
                without_refunds: 0.2919,
                with_refunds: 0.2924,
              },
              net_total_spread: {
                without_refunds: 0.3158,
                with_refunds: 0.3163,
              },
              gross_total_spread: {
                without_refunds: 0.3127,
                with_refunds: 0.3132,
              },
            },
          },
          three_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.001,
                with_refunds: 0.001,
              },
              gross_mrr: {
                without_refunds: 0.0009,
                with_refunds: 0.0009,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.6617,
                with_refunds: 0.6617,
              },
              gross_arr_spread: {
                without_refunds: 0.6112,
                with_refunds: 0.6112,
              },
              net_total: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              gross_total: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              net_total_spread: {
                without_refunds: 0.0512,
                with_refunds: 0.0538,
              },
              gross_total_spread: {
                without_refunds: 0.049,
                with_refunds: 0.0514,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.2991,
                with_refunds: 0.2996,
              },
              gross_mrr: {
                without_refunds: 0.2973,
                with_refunds: 0.2978,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.3839,
                with_refunds: 0.3839,
              },
              gross_arr_spread: {
                without_refunds: 0.3589,
                with_refunds: 0.3589,
              },
              net_total: {
                without_refunds: 0.294,
                with_refunds: 0.2945,
              },
              gross_total: {
                without_refunds: 0.2919,
                with_refunds: 0.2924,
              },
              net_total_spread: {
                without_refunds: 0.3158,
                with_refunds: 0.3163,
              },
              gross_total_spread: {
                without_refunds: 0.3127,
                with_refunds: 0.3132,
              },
            },
          },
          six_months: {
            start_over_month: {
              net_mrr: {
                without_refunds: 0.0018,
                with_refunds: 0.0019,
              },
              gross_mrr: {
                without_refunds: 0.0018,
                with_refunds: 0.0018,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.799,
                with_refunds: 0.799,
              },
              gross_arr_spread: {
                without_refunds: 0.7378,
                with_refunds: 0.7378,
              },
              net_total: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              gross_total: {
                without_refunds: 0.0001,
                with_refunds: 0.0001,
              },
              net_total_spread: {
                without_refunds: 0.0996,
                with_refunds: 0.1037,
              },
              gross_total_spread: {
                without_refunds: 0.0998,
                with_refunds: 0.1038,
              },
            },
            month_over_month: {
              net_mrr: {
                without_refunds: 0.2991,
                with_refunds: 0.2996,
              },
              gross_mrr: {
                without_refunds: 0.2973,
                with_refunds: 0.2978,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 0.3839,
                with_refunds: 0.3839,
              },
              gross_arr_spread: {
                without_refunds: 0.3589,
                with_refunds: 0.3589,
              },
              net_total: {
                without_refunds: 0.294,
                with_refunds: 0.2945,
              },
              gross_total: {
                without_refunds: 0.2919,
                with_refunds: 0.2924,
              },
              net_total_spread: {
                without_refunds: 0.3158,
                with_refunds: 0.3163,
              },
              gross_total_spread: {
                without_refunds: 0.3127,
                with_refunds: 0.3132,
              },
            },
          },
        },
        ltv: {
          three_months: {},
          six_months: {},
        },
        runoff_prospective: {
          base: {
            net_mrr: {
              without_refunds: 17158.8018,
              with_refunds: 16809.3859,
            },
            gross_mrr: {
              without_refunds: 19213.2709,
              with_refunds: 18831.0016,
            },
            net_arr: {
              without_refunds: 1106.2128,
              with_refunds: 729.4517,
            },
            gross_arr: {
              without_refunds: 1158.0706,
              with_refunds: 753.6248,
            },
            net_arr_spread: {
              without_refunds: 495849.2033,
              with_refunds: 495849.2033,
            },
            gross_arr_spread: {
              without_refunds: 548482.7774,
              with_refunds: 548482.7774,
            },
            net_total: {
              without_refunds: 15654.8925,
              with_refunds: 15120.0197,
            },
            gross_total: {
              without_refunds: 17385.0586,
              with_refunds: 16865.8265,
            },
            net_total_spread: {
              without_refunds: 324970.7245,
              with_refunds: 326737.1345,
            },
            gross_total_spread: {
              without_refunds: 362638.2471,
              with_refunds: 364545.3885,
            },
          },
        },
        runway_prospective: {
          base: {
            net_mrr: {
              without_refunds: 25622.8303,
              with_refunds: 24628.2375,
            },
            gross_mrr: {
              without_refunds: 28365.8421,
              with_refunds: 27098.0114,
            },
            net_arr: {
              without_refunds: 25870.0702,
              with_refunds: 22627.1936,
            },
            gross_arr: {
              without_refunds: 27008.5377,
              with_refunds: 23667.2454,
            },
            net_arr_spread: {
              without_refunds: 507829.9944,
              with_refunds: 507829.9944,
            },
            gross_arr_spread: {
              without_refunds: 561023.3235,
              with_refunds: 561023.3235,
            },
            net_total: {
              without_refunds: 45549.6323,
              with_refunds: 41740.3339,
            },
            gross_total: {
              without_refunds: 48836.9504,
              with_refunds: 44836.801,
            },
            net_total_spread: {
              without_refunds: 348341.9128,
              with_refunds: 349155.3261,
            },
            gross_total_spread: {
              without_refunds: 387474.6647,
              with_refunds: 387976.687,
            },
          },
        },
      },
      arpu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 0.15,
                with_refunds: 0.15,
              },
              gross_mrr: {
                without_refunds: 0.16,
                with_refunds: 0.16,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 3.5,
                with_refunds: 3.5,
              },
              gross_arr_spread: {
                without_refunds: 3.69,
                with_refunds: 3.69,
              },
              net_total: {
                without_refunds: 0.09,
                with_refunds: 0.09,
              },
              gross_total: {
                without_refunds: 0.1,
                with_refunds: 0.1,
              },
              net_total_spread: {
                without_refunds: 0.52,
                with_refunds: 0.52,
              },
              gross_total_spread: {
                without_refunds: 0.6,
                with_refunds: 0.6,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              gross_mrr: {
                without_refunds: 0.01,
                with_refunds: 0.01,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 4.57,
                with_refunds: 4.57,
              },
              gross_arr_spread: {
                without_refunds: 4.56,
                with_refunds: 4.56,
              },
              net_total: {},
              gross_total: {},
              net_total_spread: {
                without_refunds: 0.53,
                with_refunds: 0.54,
              },
              gross_total_spread: {
                without_refunds: 0.57,
                with_refunds: 0.58,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 0.02,
                with_refunds: 0.02,
              },
              gross_mrr: {
                without_refunds: 0.02,
                with_refunds: 0.02,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 4.16,
                with_refunds: 4.16,
              },
              gross_arr_spread: {
                without_refunds: 4.3,
                with_refunds: 4.3,
              },
              net_total: {},
              gross_total: {},
              net_total_spread: {
                without_refunds: 0.65,
                with_refunds: 0.66,
              },
              gross_total_spread: {
                without_refunds: 0.73,
                with_refunds: 0.75,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.73,
                with_refunds: 19.73,
              },
              gross_mrr: {
                without_refunds: 24,
                with_refunds: 22.86,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 88.41,
                with_refunds: 88.41,
              },
              gross_arr_spread: {
                without_refunds: 95.34,
                with_refunds: 95.34,
              },
              net_total: {
                without_refunds: 61.11,
                with_refunds: 56.83,
              },
              gross_total: {
                without_refunds: 66.4,
                with_refunds: 61.88,
              },
              net_total_spread: {
                without_refunds: 55.03,
                with_refunds: 53.1,
              },
              gross_total_spread: {
                without_refunds: 62.35,
                with_refunds: 60.13,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 23.04,
                with_refunds: 22.15,
              },
              gross_mrr: {
                without_refunds: 26.37,
                with_refunds: 25.37,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 63.54,
                with_refunds: 63.54,
              },
              gross_arr_spread: {
                without_refunds: 71.05,
                with_refunds: 71.05,
              },
              net_total: {
                without_refunds: 68.88,
                with_refunds: 60.46,
              },
              gross_total: {
                without_refunds: 76.8,
                with_refunds: 67.49,
              },
              net_total_spread: {
                without_refunds: 46.67,
                with_refunds: 45.6,
              },
              gross_total_spread: {
                without_refunds: 52.81,
                with_refunds: 51.61,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      arppu: {
        avg: {
          base: {
            base: {
              net_mrr: {
                without_refunds: 12.1,
                with_refunds: 12.04,
              },
              gross_mrr: {
                without_refunds: 13.53,
                with_refunds: 13.48,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 10.2,
                with_refunds: 10.2,
              },
              gross_arr_spread: {
                without_refunds: 10.74,
                with_refunds: 10.74,
              },
              net_total: {
                without_refunds: 11.87,
                with_refunds: 10.99,
              },
              gross_total: {
                without_refunds: 13.05,
                with_refunds: 12.28,
              },
              net_total_spread: {
                without_refunds: 8.07,
                with_refunds: 8.17,
              },
              gross_total_spread: {
                without_refunds: 9.35,
                with_refunds: 9.46,
              },
            },
          },
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.03,
                with_refunds: 18.9,
              },
              gross_mrr: {
                without_refunds: 21.49,
                with_refunds: 20.28,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 12.98,
                with_refunds: 12.98,
              },
              gross_arr_spread: {
                without_refunds: 12.95,
                with_refunds: 12.95,
              },
              net_total: {
                without_refunds: 11.51,
                with_refunds: 12.12,
              },
              gross_total: {
                without_refunds: 11.95,
                with_refunds: 12.64,
              },
              net_total_spread: {
                without_refunds: 9.51,
                with_refunds: 9.71,
              },
              gross_total_spread: {
                without_refunds: 10.34,
                with_refunds: 10.54,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 14.59,
                with_refunds: 14.5,
              },
              gross_mrr: {
                without_refunds: 16.37,
                with_refunds: 16.28,
              },
              net_arr: {},
              gross_arr: {},
              net_arr_spread: {
                without_refunds: 12.03,
                with_refunds: 12.03,
              },
              gross_arr_spread: {
                without_refunds: 12.45,
                with_refunds: 12.45,
              },
              net_total: {
                without_refunds: 14.43,
                with_refunds: 10.43,
              },
              gross_total: {
                without_refunds: 15.5,
                with_refunds: 11.77,
              },
              net_total_spread: {
                without_refunds: 8.44,
                with_refunds: 8.65,
              },
              gross_total_spread: {
                without_refunds: 9.52,
                with_refunds: 9.75,
              },
            },
          },
        },
        ltv: {
          three_months: {
            base: {
              net_mrr: {
                without_refunds: 20.73,
                with_refunds: 19.73,
              },
              gross_mrr: {
                without_refunds: 24,
                with_refunds: 22.86,
              },
              net_arr: {
                without_refunds: 88.79,
                with_refunds: 83.21,
              },
              gross_arr: {
                without_refunds: 95.47,
                with_refunds: 89.63,
              },
              net_arr_spread: {
                without_refunds: 88.41,
                with_refunds: 88.41,
              },
              gross_arr_spread: {
                without_refunds: 95.34,
                with_refunds: 95.34,
              },
              net_total: {
                without_refunds: 61.11,
                with_refunds: 56.83,
              },
              gross_total: {
                without_refunds: 66.4,
                with_refunds: 61.88,
              },
              net_total_spread: {
                without_refunds: 55.03,
                with_refunds: 53.1,
              },
              gross_total_spread: {
                without_refunds: 62.35,
                with_refunds: 60.13,
              },
            },
          },
          six_months: {
            base: {
              net_mrr: {
                without_refunds: 23.04,
                with_refunds: 22.15,
              },
              gross_mrr: {
                without_refunds: 26.37,
                with_refunds: 25.37,
              },
              net_arr: {
                without_refunds: 65.04,
                with_refunds: 58.07,
              },
              gross_arr: {
                without_refunds: 72.39,
                with_refunds: 64.69,
              },
              net_arr_spread: {
                without_refunds: 63.54,
                with_refunds: 63.54,
              },
              gross_arr_spread: {
                without_refunds: 71.05,
                with_refunds: 71.05,
              },
              net_total: {
                without_refunds: 68.88,
                with_refunds: 60.46,
              },
              gross_total: {
                without_refunds: 76.8,
                with_refunds: 67.49,
              },
              net_total_spread: {
                without_refunds: 46.67,
                with_refunds: 45.6,
              },
              gross_total_spread: {
                without_refunds: 52.81,
                with_refunds: 51.61,
              },
            },
          },
        },
        runoff_prospective: {},
        runway_prospective: {},
      },
      cumulative: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 41.74,
                  with_refunds: 40.69,
                },
                gross_mrr: {
                  without_refunds: 48.53,
                  with_refunds: 47.33,
                },
                net_arr: {
                  without_refunds: 109.37,
                  with_refunds: 88.42,
                },
                gross_arr: {
                  without_refunds: 122.92,
                  with_refunds: 99.68,
                },
                net_arr_spread: {
                  without_refunds: 106.29,
                  with_refunds: 106.29,
                },
                gross_arr_spread: {
                  without_refunds: 119.58,
                  with_refunds: 119.58,
                },
                net_total: {
                  without_refunds: 67.35,
                  with_refunds: 58.85,
                },
                gross_total: {
                  without_refunds: 76.71,
                  with_refunds: 67.26,
                },
                net_total_spread: {
                  without_refunds: 57.12,
                  with_refunds: 56.48,
                },
                gross_total_spread: {
                  without_refunds: 65.54,
                  with_refunds: 64.79,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 29.39,
                  with_refunds: 27.96,
                },
                gross_mrr: {
                  without_refunds: 33.27,
                  with_refunds: 31.62,
                },
                net_arr: {
                  without_refunds: 139.08,
                  with_refunds: 124.08,
                },
                gross_arr: {
                  without_refunds: 148.2,
                  with_refunds: 132.4,
                },
                net_arr_spread: {
                  without_refunds: 140.45,
                  with_refunds: 140.45,
                },
                gross_arr_spread: {
                  without_refunds: 149.8,
                  with_refunds: 149.8,
                },
                net_total: {
                  without_refunds: 82.14,
                  with_refunds: 74.23,
                },
                gross_total: {
                  without_refunds: 88.49,
                  with_refunds: 80.09,
                },
                net_total_spread: {
                  without_refunds: 64.06,
                  with_refunds: 63.44,
                },
                gross_total_spread: {
                  without_refunds: 69.46,
                  with_refunds: 68.69,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 33.24,
                  with_refunds: 31.73,
                },
                gross_mrr: {
                  without_refunds: 37.85,
                  with_refunds: 36.16,
                },
                net_arr: {
                  without_refunds: 128.03,
                  with_refunds: 99.73,
                },
                gross_arr: {
                  without_refunds: 141.42,
                  with_refunds: 110.34,
                },
                net_arr_spread: {
                  without_refunds: 126.64,
                  with_refunds: 126.64,
                },
                gross_arr_spread: {
                  without_refunds: 140.06,
                  with_refunds: 140.06,
                },
                net_total: {
                  without_refunds: 98.39,
                  with_refunds: 78.45,
                },
                gross_total: {
                  without_refunds: 109.04,
                  with_refunds: 87.13,
                },
                net_total_spread: {
                  without_refunds: 71.05,
                  with_refunds: 70.73,
                },
                gross_total_spread: {
                  without_refunds: 79.19,
                  with_refunds: 78.82,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 156.26,
                  with_refunds: 152.62,
                },
                gross_mrr: {
                  without_refunds: 178.39,
                  with_refunds: 174.32,
                },
                net_arr: {
                  without_refunds: 1021.18,
                  with_refunds: 413.63,
                },
                gross_arr: {
                  without_refunds: 1223.09,
                  with_refunds: 507.72,
                },
                net_arr_spread: {
                  without_refunds: 117.76,
                  with_refunds: 117.76,
                },
                gross_arr_spread: {
                  without_refunds: 132.13,
                  with_refunds: 132.13,
                },
                net_total: {
                  without_refunds: 230.49,
                  with_refunds: 198.31,
                },
                gross_total: {
                  without_refunds: 260.45,
                  with_refunds: 225.86,
                },
                net_total_spread: {
                  without_refunds: 123.68,
                  with_refunds: 122.61,
                },
                gross_total_spread: {
                  without_refunds: 141.92,
                  with_refunds: 140.68,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 256.15,
                  with_refunds: 239.21,
                },
                gross_mrr: {
                  without_refunds: 279.28,
                  with_refunds: 260.8,
                },
                net_arr: {
                  without_refunds: 1247.56,
                  with_refunds: 235.8,
                },
                gross_arr: {
                  without_refunds: 1493.64,
                  with_refunds: 250.93,
                },
                net_arr_spread: {
                  without_refunds: 150.26,
                  with_refunds: 150.26,
                },
                gross_arr_spread: {
                  without_refunds: 159.77,
                  with_refunds: 159.77,
                },
                net_total: {
                  without_refunds: 293.18,
                  with_refunds: 268.82,
                },
                gross_total: {
                  without_refunds: 314.9,
                  with_refunds: 288.05,
                },
                net_total_spread: {
                  without_refunds: 151,
                  with_refunds: 150.56,
                },
                gross_total_spread: {
                  without_refunds: 163.15,
                  with_refunds: 162.5,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 187.93,
                  with_refunds: 181.86,
                },
                gross_mrr: {
                  without_refunds: 212.35,
                  with_refunds: 205.69,
                },
                net_arr: {
                  without_refunds: 1311.9,
                  with_refunds: 292.21,
                },
                gross_arr: {
                  without_refunds: 1525.64,
                  with_refunds: 346.98,
                },
                net_arr_spread: {
                  without_refunds: 138.97,
                  with_refunds: 138.97,
                },
                gross_arr_spread: {
                  without_refunds: 153.24,
                  with_refunds: 153.24,
                },
                net_total: {
                  without_refunds: 353.21,
                  with_refunds: 254.08,
                },
                gross_total: {
                  without_refunds: 390.51,
                  with_refunds: 286.02,
                },
                net_total_spread: {
                  without_refunds: 131.4,
                  with_refunds: 131.43,
                },
                gross_total_spread: {
                  without_refunds: 146.47,
                  with_refunds: 146.47,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
      roas: {
        arpu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 0.6982,
                  with_refunds: 0.6795,
                },
                gross_mrr: {
                  without_refunds: 0.8103,
                  with_refunds: 0.7888,
                },
                net_arr: {
                  without_refunds: 1.8826,
                  with_refunds: 1.5587,
                },
                gross_arr: {
                  without_refunds: 2.1024,
                  with_refunds: 1.7437,
                },
                net_arr_spread: {
                  without_refunds: 1.8768,
                  with_refunds: 1.8768,
                },
                gross_arr_spread: {
                  without_refunds: 2.0957,
                  with_refunds: 2.0957,
                },
                net_total: {
                  without_refunds: 1.1465,
                  with_refunds: 1.0141,
                },
                gross_total: {
                  without_refunds: 1.2997,
                  with_refunds: 1.1526,
                },
                net_total_spread: {
                  without_refunds: 0.9768,
                  with_refunds: 0.9652,
                },
                gross_total_spread: {
                  without_refunds: 1.1156,
                  with_refunds: 1.1021,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 1.7363,
                  with_refunds: 1.6584,
                },
                gross_mrr: {
                  without_refunds: 1.9774,
                  with_refunds: 1.8863,
                },
                net_arr: {
                  without_refunds: 8.9846,
                  with_refunds: 7.9524,
                },
                gross_arr: {
                  without_refunds: 9.5711,
                  with_refunds: 8.4805,
                },
                net_arr_spread: {
                  without_refunds: 8.8712,
                  with_refunds: 8.8712,
                },
                gross_arr_spread: {
                  without_refunds: 9.4598,
                  with_refunds: 9.4598,
                },
                net_total: {
                  without_refunds: 5.3054,
                  with_refunds: 4.774,
                },
                gross_total: {
                  without_refunds: 5.7167,
                  with_refunds: 5.1508,
                },
                net_total_spread: {
                  without_refunds: 3.9052,
                  with_refunds: 3.8703,
                },
                gross_total_spread: {
                  without_refunds: 4.2374,
                  with_refunds: 4.1946,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 0.7858,
                  with_refunds: 0.7506,
                },
                gross_mrr: {
                  without_refunds: 0.8961,
                  with_refunds: 0.8561,
                },
                net_arr: {
                  without_refunds: 2.2763,
                  with_refunds: 1.8592,
                },
                gross_arr: {
                  without_refunds: 2.4824,
                  with_refunds: 2.028,
                },
                net_arr_spread: {
                  without_refunds: 2.3642,
                  with_refunds: 2.3642,
                },
                gross_arr_spread: {
                  without_refunds: 2.5784,
                  with_refunds: 2.5784,
                },
                net_total: {
                  without_refunds: 1.8118,
                  with_refunds: 1.5164,
                },
                gross_total: {
                  without_refunds: 1.9883,
                  with_refunds: 1.6658,
                },
                net_total_spread: {
                  without_refunds: 1.3829,
                  with_refunds: 1.3734,
                },
                gross_total_spread: {
                  without_refunds: 1.5264,
                  with_refunds: 1.5152,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
        arppu: {
          avg: {
            base: {
              base: {
                net_mrr: {
                  without_refunds: 2.7665,
                  with_refunds: 2.6816,
                },
                gross_mrr: {
                  without_refunds: 3.1397,
                  with_refunds: 3.0459,
                },
                net_arr: {
                  without_refunds: 16.9817,
                  with_refunds: 6.9075,
                },
                gross_arr: {
                  without_refunds: 20.6364,
                  with_refunds: 8.4378,
                },
                net_arr_spread: {
                  without_refunds: 2.0773,
                  with_refunds: 2.0773,
                },
                gross_arr_spread: {
                  without_refunds: 2.3135,
                  with_refunds: 2.3135,
                },
                net_total: {
                  without_refunds: 3.9523,
                  with_refunds: 3.5392,
                },
                gross_total: {
                  without_refunds: 4.445,
                  with_refunds: 3.9938,
                },
                net_total_spread: {
                  without_refunds: 2.147,
                  with_refunds: 2.1279,
                },
                gross_total_spread: {
                  without_refunds: 2.4496,
                  with_refunds: 2.4273,
                },
              },
            },
            three_months: {
              base: {
                net_mrr: {
                  without_refunds: 14.1477,
                  with_refunds: 12.9615,
                },
                gross_mrr: {
                  without_refunds: 15.3943,
                  with_refunds: 14.111,
                },
                net_arr: {
                  without_refunds: 78.5275,
                  with_refunds: 15.7386,
                },
                gross_arr: {
                  without_refunds: 96.4784,
                  with_refunds: 16.7672,
                },
                net_arr_spread: {
                  without_refunds: 9.6852,
                  with_refunds: 9.6852,
                },
                gross_arr_spread: {
                  without_refunds: 10.2956,
                  with_refunds: 10.2956,
                },
                net_total: {
                  without_refunds: 19.4934,
                  with_refunds: 18.8326,
                },
                gross_total: {
                  without_refunds: 20.9708,
                  with_refunds: 20.1548,
                },
                net_total_spread: {
                  without_refunds: 8.9228,
                  with_refunds: 8.8893,
                },
                gross_total_spread: {
                  without_refunds: 9.6449,
                  with_refunds: 9.5989,
                },
              },
            },
            six_months: {
              base: {
                net_mrr: {
                  without_refunds: 5.1875,
                  with_refunds: 4.8681,
                },
                gross_mrr: {
                  without_refunds: 5.7405,
                  with_refunds: 5.394,
                },
                net_arr: {
                  without_refunds: 21.9019,
                  with_refunds: 5.1384,
                },
                gross_arr: {
                  without_refunds: 26.1076,
                  with_refunds: 5.9825,
                },
                net_arr_spread: {
                  without_refunds: 2.5948,
                  with_refunds: 2.5948,
                },
                gross_arr_spread: {
                  without_refunds: 2.8215,
                  with_refunds: 2.8215,
                },
                net_total: {
                  without_refunds: 6.5652,
                  with_refunds: 5.4365,
                },
                gross_total: {
                  without_refunds: 7.1896,
                  with_refunds: 5.9816,
                },
                net_total_spread: {
                  without_refunds: 2.8133,
                  with_refunds: 2.8075,
                },
                gross_total_spread: {
                  without_refunds: 3.0967,
                  with_refunds: 3.0886,
                },
              },
            },
          },
          ltv: {
            three_months: {},
            six_months: {},
          },
          runoff_prospective: {},
          runway_prospective: {},
        },
      },
    },
  ],
  kpis: {
    sum_ltv: {
      arpu: {
        ltv_3_months: {
          net_mrr: {
            without_refunds: 29.39,
            with_refunds: 27.96,
          },
          gross_mrr: {
            without_refunds: 33.27,
            with_refunds: 31.62,
          },
          net_arr: {
            without_refunds: 139.08,
            with_refunds: 124.08,
          },
          gross_arr: {
            without_refunds: 148.2,
            with_refunds: 132.4,
          },
          net_arr_spread: {
            without_refunds: 140.45,
            with_refunds: 140.45,
          },
          gross_arr_spread: {
            without_refunds: 149.8,
            with_refunds: 149.8,
          },
          net_total: {
            without_refunds: 82.14,
            with_refunds: 74.23,
          },
          gross_total: {
            without_refunds: 88.49,
            with_refunds: 80.09,
          },
          net_total_spread: {
            without_refunds: 64.06,
            with_refunds: 63.44,
          },
          gross_total_spread: {
            without_refunds: 69.46,
            with_refunds: 68.69,
          },
        },
        ltv_6_months: {
          net_mrr: {
            without_refunds: 33.24,
            with_refunds: 31.73,
          },
          gross_mrr: {
            without_refunds: 37.85,
            with_refunds: 36.16,
          },
          net_arr: {
            without_refunds: 128.03,
            with_refunds: 99.73,
          },
          gross_arr: {
            without_refunds: 141.42,
            with_refunds: 110.34,
          },
          net_arr_spread: {
            without_refunds: 126.64,
            with_refunds: 126.64,
          },
          gross_arr_spread: {
            without_refunds: 140.06,
            with_refunds: 140.06,
          },
          net_total: {
            without_refunds: 98.39,
            with_refunds: 78.45,
          },
          gross_total: {
            without_refunds: 109.04,
            with_refunds: 87.13,
          },
          net_total_spread: {
            without_refunds: 71.05,
            with_refunds: 70.73,
          },
          gross_total_spread: {
            without_refunds: 79.19,
            with_refunds: 78.82,
          },
        },
      },
    },
    roas_100: {
      roas: {
        arpu: {
          avg_3_months: {
            net_mrr: {
              without_refunds: 2,
              with_refunds: 2,
            },
            gross_mrr: {
              without_refunds: 2,
              with_refunds: 2,
            },
            net_arr: {
              without_refunds: 1,
              with_refunds: 1,
            },
            gross_arr: {
              without_refunds: 1,
              with_refunds: 1,
            },
            net_arr_spread: {
              without_refunds: 2,
              with_refunds: 2,
            },
            gross_arr_spread: {
              without_refunds: 2,
              with_refunds: 2,
            },
            net_total: {
              without_refunds: 1,
              with_refunds: 1,
            },
            gross_total: {
              without_refunds: 1,
              with_refunds: 1,
            },
            net_total_spread: {
              without_refunds: 2,
              with_refunds: 2,
            },
            gross_total_spread: {
              without_refunds: 2,
              with_refunds: 2,
            },
          },
        },
      },
    },
    duration: {
      percent_25: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.09,
              with_refunds: 0.09,
            },
            gross_mrr: {
              without_refunds: 0.07,
              with_refunds: 0.08,
            },
            net_arr: {
              without_refunds: 0.01,
              with_refunds: 0.02,
            },
            gross_arr: {
              without_refunds: 0.01,
              with_refunds: 0.01,
            },
            net_arr_spread: {
              without_refunds: 0.18,
              with_refunds: 0.18,
            },
            gross_arr_spread: {
              without_refunds: 0.17,
              with_refunds: 0.17,
            },
            net_total: {
              without_refunds: 0.02,
              with_refunds: 0.03,
            },
            gross_total: {
              without_refunds: 0.02,
              with_refunds: 0.02,
            },
            net_total_spread: {
              without_refunds: 0.11,
              with_refunds: 0.12,
            },
            gross_total_spread: {
              without_refunds: 0.1,
              with_refunds: 0.1,
            },
          },
        },
      },
      percent_50: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.17,
              with_refunds: 0.18,
            },
            gross_mrr: {
              without_refunds: 0.15,
              with_refunds: 0.16,
            },
            net_arr: {
              without_refunds: 0.03,
              with_refunds: 0.03,
            },
            gross_arr: {
              without_refunds: 0.03,
              with_refunds: 0.03,
            },
            net_arr_spread: {
              without_refunds: 0.36,
              with_refunds: 0.36,
            },
            gross_arr_spread: {
              without_refunds: 0.33,
              with_refunds: 0.33,
            },
            net_total: {
              without_refunds: 0.05,
              with_refunds: 0.05,
            },
            gross_total: {
              without_refunds: 0.04,
              with_refunds: 0.05,
            },
            net_total_spread: {
              without_refunds: 0.23,
              with_refunds: 0.24,
            },
            gross_total_spread: {
              without_refunds: 0.2,
              with_refunds: 0.21,
            },
          },
        },
      },
      percent_75: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.26,
              with_refunds: 0.27,
            },
            gross_mrr: {
              without_refunds: 0.22,
              with_refunds: 0.23,
            },
            net_arr: {
              without_refunds: 0.04,
              with_refunds: 0.05,
            },
            gross_arr: {
              without_refunds: 0.04,
              with_refunds: 0.04,
            },
            net_arr_spread: {
              without_refunds: 0.54,
              with_refunds: 0.54,
            },
            gross_arr_spread: {
              without_refunds: 0.5,
              with_refunds: 0.5,
            },
            net_total: {
              without_refunds: 0.07,
              with_refunds: 0.08,
            },
            gross_total: {
              without_refunds: 0.07,
              with_refunds: 0.07,
            },
            net_total_spread: {
              without_refunds: 0.34,
              with_refunds: 0.36,
            },
            gross_total_spread: {
              without_refunds: 0.3,
              with_refunds: 0.31,
            },
          },
        },
      },
      percent_100: {
        arpu: {
          ltv_3_months: {
            net_mrr: {
              without_refunds: 0.34,
              with_refunds: 0.36,
            },
            gross_mrr: {
              without_refunds: 0.3,
              with_refunds: 0.31,
            },
            net_arr: {
              without_refunds: 0.06,
              with_refunds: 0.06,
            },
            gross_arr: {
              without_refunds: 0.05,
              with_refunds: 0.06,
            },
            net_arr_spread: {
              without_refunds: 0.72,
              with_refunds: 0.72,
            },
            gross_arr_spread: {
              without_refunds: 0.66,
              with_refunds: 0.66,
            },
            net_total: {
              without_refunds: 0.1,
              with_refunds: 0.11,
            },
            gross_total: {
              without_refunds: 0.09,
              with_refunds: 0.1,
            },
            net_total_spread: {
              without_refunds: 0.46,
              with_refunds: 0.47,
            },
            gross_total_spread: {
              without_refunds: 0.4,
              with_refunds: 0.42,
            },
          },
        },
      },
    },
    current: {
      arpu: {
        avg_3_months: {
          net_mrr: {
            without_refunds: 9.71,
            with_refunds: 9.2,
          },
          gross_mrr: {
            without_refunds: 11.24,
            with_refunds: 10.66,
          },
          net_arr: {
            without_refunds: 56.9,
            with_refunds: 51.86,
          },
          gross_arr: {
            without_refunds: 61.18,
            with_refunds: 55.86,
          },
          net_arr_spread: {
            without_refunds: 4.65,
            with_refunds: 4.65,
          },
          gross_arr_spread: {
            without_refunds: 5.01,
            with_refunds: 5.01,
          },
          net_total: {
            without_refunds: 34.29,
            with_refunds: 31.43,
          },
          gross_total: {
            without_refunds: 37.26,
            with_refunds: 34.22,
          },
          net_total_spread: {
            without_refunds: 7.27,
            with_refunds: 7.01,
          },
          gross_total_spread: {
            without_refunds: 8.23,
            with_refunds: 7.93,
          },
        },
        avg_6_months: {
          net_mrr: {
            without_refunds: 6.39,
            with_refunds: 6.11,
          },
          gross_mrr: {
            without_refunds: 7.32,
            with_refunds: 6.99,
          },
          net_arr: {
            without_refunds: 34.36,
            with_refunds: 29.01,
          },
          gross_arr: {
            without_refunds: 38.24,
            with_refunds: 32.32,
          },
          net_arr_spread: {
            without_refunds: 2.81,
            with_refunds: 2.81,
          },
          gross_arr_spread: {
            without_refunds: 3.14,
            with_refunds: 3.14,
          },
          net_total: {
            without_refunds: 27.45,
            with_refunds: 23.37,
          },
          gross_total: {
            without_refunds: 30.6,
            with_refunds: 26.08,
          },
          net_total_spread: {
            without_refunds: 4.03,
            with_refunds: 3.93,
          },
          gross_total_spread: {
            without_refunds: 4.56,
            with_refunds: 4.45,
          },
        },
      },
    },
    cac_avg_3_months: 3.33,
    first_period_retention: {
      start_over_month: {
        mrr_users: {
          without_refunds: 0.48,
          with_refunds: 0.48,
        },
        arr_users: {
          without_refunds: 0.36,
          with_refunds: 0.38,
        },
        arr_spread_users: {
          without_refunds: 1.01,
          with_refunds: 1.01,
        },
        total_users: {
          without_refunds: 0.42,
          with_refunds: 0.43,
        },
        total_spread_users: {
          without_refunds: 0.73,
          with_refunds: 0.73,
        },
      },
      month_over_month: {
        mrr_users: {
          without_refunds: 0.72,
          with_refunds: 0.72,
        },
        arr_users: {
          without_refunds: 0.62,
          with_refunds: 0.65,
        },
        arr_spread_users: {
          without_refunds: 1.63,
          with_refunds: 1.63,
        },
        total_users: {
          without_refunds: 0.67,
          with_refunds: 0.69,
        },
        total_spread_users: {
          without_refunds: 1.13,
          with_refunds: 1.13,
        },
      },
    },
  },
};

export const compareSalesKpisMockData = {
  revenueKpis: {
    number_of_users: {
      combined: 12641,
      monthly: 7618,
      annually: 5023,
    },
    percent_of_users: {
      monthly: 0.6026421960287952,
      annually: 0.3973578039712048,
    },
    last_1_day: {
      combined: {
        net: 128803.42306721746,
        gross: 149863.29668864384,
      },
      monthly: {
        net: 90173.79687582681,
        gross: 105116.46549480215,
      },
      annually: {
        net: 38629.62619139062,
        gross: 44746.831193841565,
      },
    },
    avg_last_7_days: {
      combined: {
        net: 128026.40031665281,
        gross: 148981.13172524408,
      },
      monthly: {
        net: 90354.46648567585,
        gross: 105333.60659205375,
      },
      annually: {
        net: 37671.93383097697,
        gross: 43647.52513319033,
      },
    },
    annual_run_rate_1_day: {
      combined: {
        net: 1545641.0768066095,
        gross: 1798359.560263726,
      },
      monthly: {
        net: 1082085.5625099218,
        gross: 1261397.5859376257,
      },
      annually: {
        net: 463555.51429668744,
        gross: 536961.9743260988,
      },
    },
    annual_run_rate_7_days: {
      combined: {
        net: 1536316.8037998336,
        gross: 1787773.5807029288,
      },
      monthly: {
        net: 1084253.5978281102,
        gross: 1264003.279104645,
      },
      annually: {
        net: 452063.2059717236,
        gross: 523770.3015982839,
      },
    },
    annual_run_rate_30_days: {
      combined: {
        net: 1564195.1548192957,
        gross: 1819940.9903770583,
      },
      monthly: {
        net: 1135183.21327139,
        gross: 1322843.91680557,
      },
      annually: {
        net: 429011.9415479057,
        gross: 497097.0735714884,
      },
    },
    avg_last_30_days: {
      combined: {
        net: 130349.5962349413,
        gross: 151661.7491980882,
      },
      monthly: {
        net: 94598.60110594916,
        gross: 110236.99306713084,
      },
      annually: {
        net: 35750.99512899214,
        gross: 41424.756130957365,
      },
    },
    change_in_mrr_last_30_days: {
      combined: {
        net: -6266.139873560198,
        gross: -7393.091297240404,
      },
      monthly: {
        net: -11790.710709375126,
        gross: -13783.425132858509,
      },
      annually: {
        net: 5524.5708358149495,
        gross: 6390.333835617945,
      },
    },
    change_in_mrr_last_30_days_percentage: {
      combined: {
        net: -0.04639194602493559,
        gross: -0.04701297919868303,
      },
      monthly: {
        net: -0.11563544010177033,
        gross: -0.11592462415311892,
      },
      annually: {
        net: 0.16687997577640304,
        gross: 0.16660368583545493,
      },
    },
    change_in_mrr_last_12_months: {
      combined: {
        net: 128717.57205957884,
        gross: 149734.8613001734,
      },
      monthly: {
        net: 90090.75253485487,
        gross: 104990.94677299837,
      },
      annually: {
        net: 38626.81952472396,
        gross: 44743.9145271749,
      },
    },
    change_in_mrr_last_12_months_percentage: {
      combined: {
        net: 1499.3134687644776,
        gross: 1165.8380379690968,
      },
      monthly: {
        net: 1084.851194921106,
        gross: 836.4564685189806,
      },
      annually: {
        net: 13762.524771279319,
        gross: 15340.770695031395,
      },
    },
    cagr_6_months: {
      combined: {
        net: 0.2781170461553464,
        gross: 0.25814925309002357,
      },
      monthly: {
        net: 0.21329528545406728,
        gross: 0.19655510340496019,
      },
      annually: {
        net: 0.7646628771104718,
        gross: 0.683190054160177,
      },
    },
  },
  cashflowKpis: {
    total_last_30_days_users: {
      monthly: {
        without_refunds: 7867,
        with_refunds: 23874,
      },
      annually: {
        without_refunds: 895,
        with_refunds: 169636,
      },
      total: {
        without_refunds: 8760,
        with_refunds: 193502,
      },
    },
    total_last_30_days_users_percent: {
      monthly: {
        without_refunds: 0.8980593607305936,
        with_refunds: 0.12337856973054542,
      },
      annually: {
        without_refunds: 0.1021689497716895,
        with_refunds: 0.8766627735113849,
      },
    },
    last_1_day: {
      monthly: {
        net_without_refunds: 3558.9885140692895,
        net_with_refunds: 3475.7552144578176,
        gross_without_refunds: 4125.392328996112,
        gross_with_refunds: 4028.1454667588937,
      },
      annually: {
        net_without_refunds: 5911.801736559119,
        net_with_refunds: 5184.676168413068,
        gross_without_refunds: 6872.0563756764095,
        gross_with_refunds: 5991.517088207338,
      },
      total: {
        net_without_refunds: 9470.790250628412,
        net_with_refunds: 8660.431382870887,
        gross_without_refunds: 10997.448704672523,
        gross_with_refunds: 10019.662554966231,
      },
    },
    annual_last_1_day: {
      monthly: {
        net_without_refunds: 1299030.8076352908,
        net_with_refunds: 1268650.6532771033,
        gross_without_refunds: 1505768.200083581,
        gross_with_refunds: 1470273.0953669962,
      },
      annually: {
        net_without_refunds: 2157807.633844078,
        net_with_refunds: 1892406.8014707698,
        gross_without_refunds: 2508300.5771218897,
        gross_with_refunds: 2186903.7371956785,
      },
      total: {
        net_without_refunds: 3456838.4414793705,
        net_with_refunds: 3161057.454747874,
        gross_without_refunds: 4014068.777205471,
        gross_with_refunds: 3657176.8325626743,
      },
    },
    month_to_date: {
      monthly: {
        net_without_refunds: 76533.53135763899,
        net_with_refunds: 73932.29893098825,
        gross_without_refunds: 89130.51195982227,
        gross_with_refunds: 86247.42129981154,
      },
      annually: {
        net_without_refunds: 70658.96836495245,
        net_with_refunds: 51378.27909084819,
        gross_without_refunds: 80671.51313880534,
        gross_with_refunds: 59732.16120136212,
      },
      total: {
        net_without_refunds: 147197.5912225932,
        net_with_refunds: 125315.66952183736,
        gross_without_refunds: 169808.61509862766,
        gross_with_refunds: 145986.17250117374,
      },
    },
    avg_last_7_days: {
      monthly: {
        net_without_refunds: 3445.729935242082,
        net_with_refunds: 3322.2481013644783,
        gross_without_refunds: 3996.05213377774,
        gross_with_refunds: 3859.645008287534,
      },
      annually: {
        net_without_refunds: 3720.7929748630863,
        net_with_refunds: 3012.981507378984,
        gross_without_refunds: 4229.1714303921235,
        gross_with_refunds: 3440.5109170043447,
      },
      total: {
        net_without_refunds: 7166.522910105151,
        net_with_refunds: 6335.229608743446,
        gross_without_refunds: 8225.223564169853,
        gross_with_refunds: 7300.15592529187,
      },
    },
    annual_last_7_days: {
      monthly: {
        net_without_refunds: 1257691.42636336,
        net_with_refunds: 1212620.5569980345,
        gross_without_refunds: 1458559.0288288752,
        gross_with_refunds: 1408770.42802495,
      },
      annually: {
        net_without_refunds: 1358089.4358250266,
        net_with_refunds: 1099738.2501933293,
        gross_without_refunds: 1543647.572093125,
        gross_with_refunds: 1255786.4847065858,
      },
      total: {
        net_without_refunds: 2615780.86218838,
        net_with_refunds: 2312358.807191358,
        gross_without_refunds: 3002206.6009219964,
        gross_with_refunds: 2664556.9127315325,
      },
    },
    avg_last_30_days: {
      monthly: {
        net_without_refunds: 3124.138154632595,
        net_with_refunds: 3005.0507356064145,
        gross_without_refunds: 3633.8380172612788,
        gross_with_refunds: 3502.5842522178373,
      },
      annually: {
        net_without_refunds: 3126.5909243537453,
        net_with_refunds: 2277.6952263315097,
        gross_without_refunds: 3560.785550337171,
        gross_with_refunds: 2633.9550959429735,
      },
      total: {
        net_without_refunds: 6251.06851231975,
        net_with_refunds: 5283.085395271319,
        gross_without_refunds: 7195.0429009318,
        gross_with_refunds: 6136.958681494146,
      },
    },
    annual_last_30_days: {
      monthly: {
        net_without_refunds: 1140310.4264408972,
        net_with_refunds: 1096843.5184963413,
        gross_without_refunds: 1326350.8763003668,
        gross_with_refunds: 1278443.2520595107,
      },
      annually: {
        net_without_refunds: 1141205.687389117,
        net_with_refunds: 831358.757611001,
        gross_without_refunds: 1299686.7258730675,
        gross_with_refunds: 961393.6100191853,
      },
      total: {
        net_without_refunds: 2281640.006996709,
        net_with_refunds: 1928326.1692740314,
        gross_without_refunds: 2626190.6588401073,
        gross_with_refunds: 2239989.918745363,
      },
    },
    change_last_30_days: {
      monthly: {
        net_without_refunds: -0.13644130397378915,
        net_with_refunds: -0.14755838738848248,
        gross_without_refunds: -0.13895939976990332,
        gross_with_refunds: -0.14841653925291928,
      },
      annually: {
        net_without_refunds: -0.37725948354015254,
        net_with_refunds: -0.47164815948489347,
        gross_without_refunds: -0.36777335625797586,
        gross_with_refunds: -0.45502817558802144,
      },
      total: {
        net_without_refunds: -0.276394865497778,
        net_with_refunds: -0.3258370584772194,
        gross_without_refunds: -0.2697493724209906,
        gross_with_refunds: -0.31404873918194076,
      },
    },
    total_last_30_days: {
      monthly: {
        net_without_refunds: 93724.14463897786,
        net_with_refunds: 90151.52206819244,
        gross_without_refunds: 109015.14051783836,
        gross_with_refunds: 105077.52756653512,
      },
      annually: {
        net_without_refunds: 93797.72773061236,
        net_with_refunds: 68330.85678994529,
        gross_without_refunds: 106823.56651011514,
        gross_with_refunds: 79018.6528782892,
      },
      total: {
        net_without_refunds: 187532.0553695925,
        net_with_refunds: 158492.56185813955,
        gross_without_refunds: 215851.287027954,
        gross_with_refunds: 184108.76044482438,
      },
    },
    total_last_12_months: {
      monthly: {
        net_without_refunds: 582492.323111451,
        net_with_refunds: 566778.4654779325,
        gross_without_refunds: 692279.1544368217,
        gross_with_refunds: 673932.0117849745,
      },
      annually: {
        net_without_refunds: 552308.5791012269,
        net_with_refunds: 467156.8021716972,
        gross_without_refunds: 636356.4104136322,
        gross_with_refunds: 541020.6148179208,
      },
      total: {
        net_without_refunds: 1135443.2677126462,
        net_with_refunds: 1034577.6331495956,
        gross_without_refunds: 1329351.7848505215,
        gross_with_refunds: 1215668.8466029565,
      },
    },
    change_last_12_months: {
      monthly: {
        net_without_refunds: 2142.419613857589,
        net_with_refunds: 2265.0311871846375,
        gross_without_refunds: 1713.3576253979995,
        gross_with_refunds: 1806.2359523994944,
      },
      annually: {
        net_without_refunds: 16397.710780915288,
        net_with_refunds: 13869.451370893623,
        gross_without_refunds: 18180.611726103776,
        gross_with_refunds: 15456.731851940596,
      },
      total: {
        net_without_refunds: 3477.6500554834256,
        net_with_refunds: 3393.6795508729733,
        gross_without_refunds: 2827.6333350715186,
        gross_with_refunds: 2767.8140729283987,
      },
    },
    cagr_6_months: {
      monthly: {
        net_without_refunds: 0.39962368412804405,
        net_with_refunds: 0.3989430001061125,
        gross_without_refunds: 0.38733380856744426,
        gross_with_refunds: 0.3869527028394031,
      },
      annually: {
        net_without_refunds: 0.7883475016020456,
        net_with_refunds: 0.7624797011923106,
        gross_without_refunds: 0.6974961007865912,
        gross_with_refunds: 0.6727812858233337,
      },
      total: {
        net_without_refunds: 0.5388594228559924,
        net_with_refunds: 0.5209071010692419,
        gross_without_refunds: 0.5065033903533505,
        gross_with_refunds: 0.49005836409229664,
      },
    },
  },
};

export const cohortsHistogramMockData = {
  "periods": [
      {
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_arr": {
                      "without_refunds": 9,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 3,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 35,
                      "with_refunds": 35
                  },
                  "gross_mrr": {
                      "without_refunds": 35,
                      "with_refunds": 35
                  },
                  "net_arr": {
                      "without_refunds": 28,
                      "with_refunds": 30
                  },
                  "gross_arr": {
                      "without_refunds": 27,
                      "with_refunds": 28
                  },
                  "net_arr_spread": {
                      "without_refunds": 37,
                      "with_refunds": 37
                  },
                  "gross_arr_spread": {
                      "without_refunds": 37,
                      "with_refunds": 37
                  },
                  "net_total": {
                      "without_refunds": 35,
                      "with_refunds": 35
                  },
                  "gross_total": {
                      "without_refunds": 34,
                      "with_refunds": 35
                  },
                  "net_total_spread": {
                      "without_refunds": 36,
                      "with_refunds": 36
                  },
                  "gross_total_spread": {
                      "without_refunds": 35,
                      "with_refunds": 35
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              }
          }
      },
      {
          "period": 1,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 4
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  },
                  "gross_mrr": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  },
                  "net_arr": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "gross_arr": {
                      "without_refunds": 21,
                      "with_refunds": 22
                  },
                  "net_arr_spread": {
                      "without_refunds": 34,
                      "with_refunds": 34
                  },
                  "gross_arr_spread": {
                      "without_refunds": 34,
                      "with_refunds": 34
                  },
                  "net_total": {
                      "without_refunds": 30,
                      "with_refunds": 32
                  },
                  "gross_total": {
                      "without_refunds": 29,
                      "with_refunds": 30
                  },
                  "net_total_spread": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  },
                  "gross_total_spread": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              }
          }
      },
      {
          "period": 2,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 4
                  },
                  "net_arr": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_arr": {
                      "without_refunds": 14,
                      "with_refunds": 13
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 4
                  },
                  "gross_total": {
                      "without_refunds": 11,
                      "with_refunds": 9
                  },
                  "net_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 4
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  },
                  "gross_mrr": {
                      "without_refunds": 30,
                      "with_refunds": 31
                  },
                  "net_arr": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "gross_arr": {
                      "without_refunds": 21,
                      "with_refunds": 22
                  },
                  "net_arr_spread": {
                      "without_refunds": 34,
                      "with_refunds": 34
                  },
                  "gross_arr_spread": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  },
                  "net_total": {
                      "without_refunds": 28,
                      "with_refunds": 31
                  },
                  "gross_total": {
                      "without_refunds": 24,
                      "with_refunds": 26
                  },
                  "net_total_spread": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  },
                  "gross_total_spread": {
                      "without_refunds": 30,
                      "with_refunds": 31
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              }
          }
      },
      {
          "period": 3,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_arr": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total": {
                      "without_refunds": 9,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 29,
                      "with_refunds": 29
                  },
                  "gross_mrr": {
                      "without_refunds": 28,
                      "with_refunds": 28
                  },
                  "net_arr": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "gross_arr": {
                      "without_refunds": 21,
                      "with_refunds": 22
                  },
                  "net_arr_spread": {
                      "without_refunds": 33,
                      "with_refunds": 33
                  },
                  "gross_arr_spread": {
                      "without_refunds": 29,
                      "with_refunds": 29
                  },
                  "net_total": {
                      "without_refunds": 25,
                      "with_refunds": 27
                  },
                  "gross_total": {
                      "without_refunds": 24,
                      "with_refunds": 25
                  },
                  "net_total_spread": {
                      "without_refunds": 29,
                      "with_refunds": 29
                  },
                  "gross_total_spread": {
                      "without_refunds": 28,
                      "with_refunds": 28
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  }
              }
          }
      },
      {
          "period": 4,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_arr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total": {
                      "without_refunds": 10,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 27,
                      "with_refunds": 27
                  },
                  "gross_mrr": {
                      "without_refunds": 27,
                      "with_refunds": 27
                  },
                  "net_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_arr_spread": {
                      "without_refunds": 30,
                      "with_refunds": 30
                  },
                  "gross_arr_spread": {
                      "without_refunds": 27,
                      "with_refunds": 27
                  },
                  "net_total": {
                      "without_refunds": 23,
                      "with_refunds": 26
                  },
                  "gross_total": {
                      "without_refunds": 23,
                      "with_refunds": 24
                  },
                  "net_total_spread": {
                      "without_refunds": 27,
                      "with_refunds": 27
                  },
                  "gross_total_spread": {
                      "without_refunds": 27,
                      "with_refunds": 27
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 3,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  }
              }
          }
      },
      {
          "period": 5,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_arr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total": {
                      "without_refunds": 9,
                      "with_refunds": 8
                  },
                  "gross_total": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 26,
                      "with_refunds": 26
                  },
                  "gross_mrr": {
                      "without_refunds": 26,
                      "with_refunds": 26
                  },
                  "net_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_arr_spread": {
                      "without_refunds": 26,
                      "with_refunds": 26
                  },
                  "gross_arr_spread": {
                      "without_refunds": 24,
                      "with_refunds": 24
                  },
                  "net_total": {
                      "without_refunds": 23,
                      "with_refunds": 24
                  },
                  "gross_total": {
                      "without_refunds": 22,
                      "with_refunds": 23
                  },
                  "net_total_spread": {
                      "without_refunds": 26,
                      "with_refunds": 26
                  },
                  "gross_total_spread": {
                      "without_refunds": 26,
                      "with_refunds": 26
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 4,
                      "with_refunds": 3
                  },
                  "gross_arr": {
                      "without_refunds": 4,
                      "with_refunds": 3
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 3,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_mrr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 3
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 6,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_arr": {
                      "without_refunds": 11,
                      "with_refunds": 10
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_total": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 9,
                      "with_refunds": 8
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 25,
                      "with_refunds": 25
                  },
                  "gross_mrr": {
                      "without_refunds": 24,
                      "with_refunds": 25
                  },
                  "net_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr": {
                      "without_refunds": 20,
                      "with_refunds": 21
                  },
                  "net_arr_spread": {
                      "without_refunds": 24,
                      "with_refunds": 24
                  },
                  "gross_arr_spread": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "net_total": {
                      "without_refunds": 23,
                      "with_refunds": 24
                  },
                  "gross_total": {
                      "without_refunds": 22,
                      "with_refunds": 23
                  },
                  "net_total_spread": {
                      "without_refunds": 25,
                      "with_refunds": 25
                  },
                  "gross_total_spread": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 5,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 5,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  }
              }
          }
      },
      {
          "period": 7,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_arr": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 24,
                      "with_refunds": 24
                  },
                  "gross_mrr": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  },
                  "net_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr": {
                      "without_refunds": 20,
                      "with_refunds": 21
                  },
                  "net_arr_spread": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  },
                  "gross_arr_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_total": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  },
                  "gross_total": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "net_total_spread": {
                      "without_refunds": 23,
                      "with_refunds": 24
                  },
                  "gross_total_spread": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 6,
                      "with_refunds": 5
                  },
                  "gross_arr": {
                      "without_refunds": 6,
                      "with_refunds": 5
                  },
                  "net_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 4
                  },
                  "gross_total": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 3
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  }
              }
          }
      },
      {
          "period": 8,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_arr": {
                      "without_refunds": 9,
                      "with_refunds": 8
                  },
                  "net_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  },
                  "gross_mrr": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "net_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr": {
                      "without_refunds": 20,
                      "with_refunds": 21
                  },
                  "net_arr_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_total": {
                      "without_refunds": 22,
                      "with_refunds": 23
                  },
                  "gross_total": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "net_total_spread": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  },
                  "gross_total_spread": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "gross_arr": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "net_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 5
                  },
                  "gross_total": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 2
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 3
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  }
              }
          }
      },
      {
          "period": 9,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 21,
                      "with_refunds": 22
                  },
                  "gross_mrr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_arr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr": {
                      "without_refunds": 20,
                      "with_refunds": 21
                  },
                  "net_arr_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_total": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_total": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_total_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_total_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 2
                  },
                  "net_arr": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 5
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 4
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 3
                  },
                  "net_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  }
              }
          }
      },
      {
          "period": 10,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_mrr": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "net_arr": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 20
                  },
                  "net_arr_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_arr_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "net_total": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_total": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "net_total_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_total_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 5
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 2,
                      "with_refunds": 3
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 3
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "gross_total": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 11,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 9,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 5
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  }
              }
          }
      },
      {
          "period": 12,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_arr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 2
                  },
                  "gross_mrr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_arr": {
                      "without_refunds": 9,
                      "with_refunds": 8
                  },
                  "gross_arr": {
                      "without_refunds": 10,
                      "with_refunds": 8
                  },
                  "net_arr_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_arr_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "net_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 13,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_arr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_arr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_arr": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "gross_arr": {
                      "without_refunds": 11,
                      "with_refunds": 9
                  },
                  "net_arr_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_arr_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_total": {
                      "without_refunds": 8,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 9,
                      "with_refunds": 8
                  },
                  "net_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_total_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 14,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_mrr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_arr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 11,
                      "with_refunds": 10
                  },
                  "gross_arr": {
                      "without_refunds": 12,
                      "with_refunds": 10
                  },
                  "net_arr_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_arr_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_total": {
                      "without_refunds": 9,
                      "with_refunds": 8
                  },
                  "gross_total": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "net_total_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_total_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 15,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_total": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 12,
                      "with_refunds": 11
                  },
                  "gross_arr": {
                      "without_refunds": 13,
                      "with_refunds": 11
                  },
                  "net_arr_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_arr_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_total": {
                      "without_refunds": 10,
                      "with_refunds": 9
                  },
                  "gross_total": {
                      "without_refunds": 11,
                      "with_refunds": 10
                  },
                  "net_total_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_total_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 16,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_arr": {
                      "without_refunds": 14,
                      "with_refunds": 12
                  },
                  "net_arr_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_arr_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total": {
                      "without_refunds": 11,
                      "with_refunds": 10
                  },
                  "gross_total": {
                      "without_refunds": 12,
                      "with_refunds": 11
                  },
                  "net_total_spread": {
                      "without_refunds": 12,
                      "with_refunds": 11
                  },
                  "gross_total_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 17,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_arr": {
                      "without_refunds": 14,
                      "with_refunds": 13
                  },
                  "gross_arr": {
                      "without_refunds": 15,
                      "with_refunds": 13
                  },
                  "net_arr_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_total": {
                      "without_refunds": 12,
                      "with_refunds": 11
                  },
                  "gross_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "net_total_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_total_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 18,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  }
              }
          }
      },
      {
          "period": 19,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_mrr": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "net_arr": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_arr": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "net_arr_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_arr_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "net_total": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_total": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "net_total_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_total_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_arr": {
                      "without_refunds": 4,
                      "with_refunds": 5
                  },
                  "gross_arr": {
                      "without_refunds": 3,
                      "with_refunds": 5
                  },
                  "net_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          }
      },
      {
          "period": 20,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_mrr": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "net_arr": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_arr": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "net_arr_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_arr_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "net_total": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_total": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "net_total_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_total_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 5,
                      "with_refunds": 6
                  },
                  "gross_arr": {
                      "without_refunds": 4,
                      "with_refunds": 6
                  },
                  "net_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 8
                  },
                  "gross_total": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  }
              }
          }
      },
      {
          "period": 21,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_mrr": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_arr": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_total": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_total_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_mrr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_arr": {
                      "without_refunds": 6,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 5,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total": {
                      "without_refunds": 8,
                      "with_refunds": 9
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              }
          }
      },
      {
          "period": 22,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_mrr": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_total": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_total": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_mrr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_arr": {
                      "without_refunds": 7,
                      "with_refunds": 8
                  },
                  "gross_arr": {
                      "without_refunds": 6,
                      "with_refunds": 8
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total": {
                      "without_refunds": 9,
                      "with_refunds": 10
                  },
                  "gross_total": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  }
              }
          }
      },
      {
          "period": 23,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_mrr": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_arr": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_arr_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_mrr": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_arr": {
                      "without_refunds": 8,
                      "with_refunds": 9
                  },
                  "gross_arr": {
                      "without_refunds": 7,
                      "with_refunds": 9
                  },
                  "net_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total": {
                      "without_refunds": 10,
                      "with_refunds": 11
                  },
                  "gross_total": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_total_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  }
              }
          }
      },
      {
          "period": 24,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_mrr": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_arr": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_arr": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_arr_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_arr_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_total": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_total_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_total_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_mrr": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_arr": {
                      "without_refunds": 9,
                      "with_refunds": 10
                  },
                  "gross_arr": {
                      "without_refunds": 8,
                      "with_refunds": 10
                  },
                  "net_arr_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total": {
                      "without_refunds": 11,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_total_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_total_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  }
              }
          }
      },
      {
          "period": 25,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_mrr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_arr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_arr": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_arr_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_arr_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_total": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_total_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_total_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_mrr": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_arr": {
                      "without_refunds": 10,
                      "with_refunds": 11
                  },
                  "gross_arr": {
                      "without_refunds": 9,
                      "with_refunds": 11
                  },
                  "net_arr_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_arr_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_total": {
                      "without_refunds": 12,
                      "with_refunds": 13
                  },
                  "gross_total": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_total_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_total_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  }
              }
          }
      },
      {
          "period": 26,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_mrr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_arr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_arr": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_arr_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_arr_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_total": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_total": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_total_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_total_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_mrr": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_arr": {
                      "without_refunds": 11,
                      "with_refunds": 12
                  },
                  "gross_arr": {
                      "without_refunds": 10,
                      "with_refunds": 12
                  },
                  "net_arr_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "gross_arr_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 14
                  },
                  "gross_total": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_total_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_total_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  }
              }
          }
      },
      {
          "period": 27,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_arr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_arr_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_total": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_total": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_total_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_total_spread": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_mrr": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "net_arr": {
                      "without_refunds": 12,
                      "with_refunds": 13
                  },
                  "gross_arr": {
                      "without_refunds": 11,
                      "with_refunds": 13
                  },
                  "net_arr_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "gross_arr_spread": {
                      "without_refunds": 11,
                      "with_refunds": 11
                  },
                  "net_total": {
                      "without_refunds": 14,
                      "with_refunds": 15
                  },
                  "gross_total": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_total_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_total_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  }
              }
          }
      },
      {
          "period": 28,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_mrr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_arr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_arr": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_arr_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_arr_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_total": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_total": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "net_total_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  },
                  "gross_total_spread": {
                      "without_refunds": 9,
                      "with_refunds": 9
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_mrr": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "net_arr": {
                      "without_refunds": 13,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 12,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "gross_arr_spread": {
                      "without_refunds": 12,
                      "with_refunds": 12
                  },
                  "net_total": {
                      "without_refunds": 15,
                      "with_refunds": 16
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  }
              }
          }
      },
      {
          "period": 29,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_mrr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_arr": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_arr_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_total": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "net_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  },
                  "gross_total_spread": {
                      "without_refunds": 8,
                      "with_refunds": 8
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_mrr": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_arr": {
                      "without_refunds": 14,
                      "with_refunds": 15
                  },
                  "gross_arr": {
                      "without_refunds": 13,
                      "with_refunds": 15
                  },
                  "net_arr_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_arr_spread": {
                      "without_refunds": 13,
                      "with_refunds": 13
                  },
                  "net_total": {
                      "without_refunds": 16,
                      "with_refunds": 17
                  },
                  "gross_total": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  }
              }
          }
      },
      {
          "period": 30,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_mrr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_arr_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "net_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  },
                  "gross_total_spread": {
                      "without_refunds": 7,
                      "with_refunds": 7
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_mrr": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 16
                  },
                  "gross_arr": {
                      "without_refunds": 14,
                      "with_refunds": 16
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total": {
                      "without_refunds": 17,
                      "with_refunds": 18
                  },
                  "gross_total": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              }
          }
      },
      {
          "period": 31,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_mrr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_arr": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_arr_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "net_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  },
                  "gross_total_spread": {
                      "without_refunds": 6,
                      "with_refunds": 6
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_mrr": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_arr": {
                      "without_refunds": 16,
                      "with_refunds": 17
                  },
                  "gross_arr": {
                      "without_refunds": 15,
                      "with_refunds": 17
                  },
                  "net_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "gross_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "net_total": {
                      "without_refunds": 18,
                      "with_refunds": 19
                  },
                  "gross_total": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "net_total_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_total_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  }
              }
          }
      },
      {
          "period": 32,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_mrr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_arr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_arr": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_arr_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "net_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  },
                  "gross_total_spread": {
                      "without_refunds": 5,
                      "with_refunds": 5
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "gross_mrr": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "net_arr": {
                      "without_refunds": 17,
                      "with_refunds": 18
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 18
                  },
                  "net_arr_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 19,
                      "with_refunds": 20
                  },
                  "gross_total": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "net_total_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_total_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  }
              }
          }
      },
      {
          "period": 33,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_mrr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_arr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_arr": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_arr_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "net_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  },
                  "gross_total_spread": {
                      "without_refunds": 4,
                      "with_refunds": 4
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  },
                  "gross_mrr": {
                      "without_refunds": 23,
                      "with_refunds": 23
                  },
                  "net_arr": {
                      "without_refunds": 18,
                      "with_refunds": 19
                  },
                  "gross_arr": {
                      "without_refunds": 17,
                      "with_refunds": 19
                  },
                  "net_arr_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "gross_arr_spread": {
                      "without_refunds": 17,
                      "with_refunds": 17
                  },
                  "net_total": {
                      "without_refunds": 20,
                      "with_refunds": 21
                  },
                  "gross_total": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_total_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  }
              }
          }
      },
      {
          "period": 34,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_mrr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_arr_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_total": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "net_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  },
                  "gross_total_spread": {
                      "without_refunds": 3,
                      "with_refunds": 3
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 24,
                      "with_refunds": 24
                  },
                  "gross_mrr": {
                      "without_refunds": 24,
                      "with_refunds": 24
                  },
                  "net_arr": {
                      "without_refunds": 19,
                      "with_refunds": 20
                  },
                  "gross_arr": {
                      "without_refunds": 18,
                      "with_refunds": 20
                  },
                  "net_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "gross_arr_spread": {
                      "without_refunds": 18,
                      "with_refunds": 18
                  },
                  "net_total": {
                      "without_refunds": 21,
                      "with_refunds": 22
                  },
                  "gross_total": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "net_total_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_total_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  }
              }
          }
      },
      {
          "period": 35,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_mrr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_arr_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "net_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  },
                  "gross_total_spread": {
                      "without_refunds": 2,
                      "with_refunds": 2
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 25,
                      "with_refunds": 25
                  },
                  "gross_mrr": {
                      "without_refunds": 25,
                      "with_refunds": 25
                  },
                  "net_arr": {
                      "without_refunds": 20,
                      "with_refunds": 21
                  },
                  "gross_arr": {
                      "without_refunds": 19,
                      "with_refunds": 21
                  },
                  "net_arr_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "gross_arr_spread": {
                      "without_refunds": 19,
                      "with_refunds": 19
                  },
                  "net_total": {
                      "without_refunds": 22,
                      "with_refunds": 23
                  },
                  "gross_total": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "net_total_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_total_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  }
              }
          }
      },
      {
          "period": 36,
          "actual": {
              "over_percent_target": {
                  "net_mrr": {},
                  "gross_mrr": {},
                  "net_arr": {},
                  "gross_arr": {},
                  "net_arr_spread": {},
                  "gross_arr_spread": {},
                  "net_total": {},
                  "gross_total": {},
                  "net_total_spread": {},
                  "gross_total_spread": {}
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_mrr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_arr_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "net_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  },
                  "gross_total_spread": {
                      "without_refunds": 1,
                      "with_refunds": 1
                  }
              }
          },
          "predicted": {
              "over_percent_target": {
                  "net_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "gross_mrr": {
                      "without_refunds": 10,
                      "with_refunds": 10
                  },
                  "net_arr": {
                      "without_refunds": 15,
                      "with_refunds": 14
                  },
                  "gross_arr": {
                      "without_refunds": 16,
                      "with_refunds": 14
                  },
                  "net_arr_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  },
                  "gross_arr_spread": {
                      "without_refunds": 16,
                      "with_refunds": 16
                  },
                  "net_total": {
                      "without_refunds": 13,
                      "with_refunds": 12
                  },
                  "gross_total": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "net_total_spread": {
                      "without_refunds": 14,
                      "with_refunds": 14
                  },
                  "gross_total_spread": {
                      "without_refunds": 15,
                      "with_refunds": 15
                  }
              },
              "under_percent_target": {
                  "net_mrr": {
                      "without_refunds": 26,
                      "with_refunds": 26
                  },
                  "gross_mrr": {
                      "without_refunds": 26,
                      "with_refunds": 26
                  },
                  "net_arr": {
                      "without_refunds": 21,
                      "with_refunds": 22
                  },
                  "gross_arr": {
                      "without_refunds": 20,
                      "with_refunds": 22
                  },
                  "net_arr_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  },
                  "gross_arr_spread": {
                      "without_refunds": 20,
                      "with_refunds": 20
                  },
                  "net_total": {
                      "without_refunds": 23,
                      "with_refunds": 24
                  },
                  "gross_total": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "net_total_spread": {
                      "without_refunds": 22,
                      "with_refunds": 22
                  },
                  "gross_total_spread": {
                      "without_refunds": 21,
                      "with_refunds": 21
                  }
              }
          }
      }
  ],
  "cohorts": {
      "total_cohorts": 37,
      "cohorts_number": {
          "over_percent_target": {
              "net_mrr": {
                  "without_refunds": 10,
                  "with_refunds": 10
              },
              "gross_mrr": {
                  "without_refunds": 10,
                  "with_refunds": 10
              },
              "net_arr": {
                  "without_refunds": 15,
                  "with_refunds": 14
              },
              "gross_arr": {
                  "without_refunds": 16,
                  "with_refunds": 14
              },
              "net_arr_spread": {
                  "without_refunds": 15,
                  "with_refunds": 15
              },
              "gross_arr_spread": {
                  "without_refunds": 16,
                  "with_refunds": 16
              },
              "net_total": {
                  "without_refunds": 13,
                  "with_refunds": 12
              },
              "gross_total": {
                  "without_refunds": 14,
                  "with_refunds": 14
              },
              "net_total_spread": {
                  "without_refunds": 14,
                  "with_refunds": 14
              },
              "gross_total_spread": {
                  "without_refunds": 15,
                  "with_refunds": 15
              }
          },
          "under_percent_target": {
              "net_mrr": {
                  "without_refunds": 27,
                  "with_refunds": 27
              },
              "gross_mrr": {
                  "without_refunds": 27,
                  "with_refunds": 27
              },
              "net_arr": {
                  "without_refunds": 22,
                  "with_refunds": 23
              },
              "gross_arr": {
                  "without_refunds": 21,
                  "with_refunds": 23
              },
              "net_arr_spread": {
                  "without_refunds": 22,
                  "with_refunds": 22
              },
              "gross_arr_spread": {
                  "without_refunds": 21,
                  "with_refunds": 21
              },
              "net_total": {
                  "without_refunds": 24,
                  "with_refunds": 25
              },
              "gross_total": {
                  "without_refunds": 23,
                  "with_refunds": 23
              },
              "net_total_spread": {
                  "without_refunds": 23,
                  "with_refunds": 23
              },
              "gross_total_spread": {
                  "without_refunds": 22,
                  "with_refunds": 22
              }
          }
      },
      "avg_months": {
          "over_percent_target": {
              "net_mrr": {
                  "without_refunds": 4.8,
                  "with_refunds": 5
              },
              "gross_mrr": {
                  "without_refunds": 3.3,
                  "with_refunds": 3.7
              },
              "net_arr": {
                  "without_refunds": 0.67,
                  "with_refunds": 0.5
              },
              "gross_arr": {
                  "without_refunds": 0.69,
                  "with_refunds": 0.36
              },
              "net_arr_spread": {
                  "without_refunds": 5.8,
                  "with_refunds": 5.8
              },
              "gross_arr_spread": {
                  "without_refunds": 5.06,
                  "with_refunds": 5.06
              },
              "net_total": {
                  "without_refunds": 2.23,
                  "with_refunds": 3
              },
              "gross_total": {
                  "without_refunds": 2,
                  "with_refunds": 3.21
              },
              "net_total_spread": {
                  "without_refunds": 6,
                  "with_refunds": 6.29
              },
              "gross_total_spread": {
                  "without_refunds": 5.6,
                  "with_refunds": 5.67
              }
          },
          "under_percent_target": {
              "net_mrr": {
                  "without_refunds": 21.56,
                  "with_refunds": 21.56
              },
              "gross_mrr": {
                  "without_refunds": 21.56,
                  "with_refunds": 21.56
              },
              "net_arr": {
                  "without_refunds": 25.18,
                  "with_refunds": 24.26
              },
              "gross_arr": {
                  "without_refunds": 25.86,
                  "with_refunds": 24.26
              },
              "net_arr_spread": {
                  "without_refunds": 25.18,
                  "with_refunds": 25.18
              },
              "gross_arr_spread": {
                  "without_refunds": 25.86,
                  "with_refunds": 25.86
              },
              "net_total": {
                  "without_refunds": 23.63,
                  "with_refunds": 22.96
              },
              "gross_total": {
                  "without_refunds": 24.26,
                  "with_refunds": 24.26
              },
              "net_total_spread": {
                  "without_refunds": 24.26,
                  "with_refunds": 24.26
              },
              "gross_total_spread": {
                  "without_refunds": 25.18,
                  "with_refunds": 25.18
              }
          }
      },
      "std_deviation": {
          "over_percent_target": {
              "net_mrr": {
                  "without_refunds": 4.56,
                  "with_refunds": 4.82
              },
              "gross_mrr": {
                  "without_refunds": 3.07,
                  "with_refunds": 3.47
              },
              "net_arr": {
                  "without_refunds": 1.25,
                  "with_refunds": 0.5
              },
              "gross_arr": {
                  "without_refunds": 1.45,
                  "with_refunds": 0.48
              },
              "net_arr_spread": {
                  "without_refunds": 2.69,
                  "with_refunds": 2.69
              },
              "gross_arr_spread": {
                  "without_refunds": 3.03,
                  "with_refunds": 3.03
              },
              "net_total": {
                  "without_refunds": 2.04,
                  "with_refunds": 2.52
              },
              "gross_total": {
                  "without_refunds": 2.3,
                  "with_refunds": 4.49
              },
              "net_total_spread": {
                  "without_refunds": 4.42,
                  "with_refunds": 4.7
              },
              "gross_total_spread": {
                  "without_refunds": 4.83,
                  "with_refunds": 4.78
              }
          },
          "under_percent_target": {
              "net_mrr": {
                  "without_refunds": 11.09,
                  "with_refunds": 11.09
              },
              "gross_mrr": {
                  "without_refunds": 11.09,
                  "with_refunds": 11.09
              },
              "net_arr": {
                  "without_refunds": 8.89,
                  "with_refunds": 9.71
              },
              "gross_arr": {
                  "without_refunds": 8.53,
                  "with_refunds": 9.71
              },
              "net_arr_spread": {
                  "without_refunds": 8.89,
                  "with_refunds": 8.89
              },
              "gross_arr_spread": {
                  "without_refunds": 8.53,
                  "with_refunds": 8.53
              },
              "net_total": {
                  "without_refunds": 9.98,
                  "with_refunds": 10.31
              },
              "gross_total": {
                  "without_refunds": 9.71,
                  "with_refunds": 9.71
              },
              "net_total_spread": {
                  "without_refunds": 9.71,
                  "with_refunds": 9.71
              },
              "gross_total_spread": {
                  "without_refunds": 8.89,
                  "with_refunds": 8.89
              }
          }
      }
  }
}