import axios from 'axios';

export const getCustomerBusinessInfoApi = async ({ customerId }) => {
  const response = await axios.get(
    `/admin/customer/${customerId}/business-info`
  );
  return response.data;
};

export const upsertCustomerBusinessInfoApi = async ({ customerId, data }) => {
  const response = await axios.post(
    `/admin/customer/${customerId}/business-info`,
    data
  );
  return response.data;
};

export const getUnderwritingListApi = async ({ customerId }) => {
  const response = await axios.get(
    `/admin/customer/${customerId}/underwriting`
  );
  return response.data;
};

export const createUnderwritingApi = async ({ customerId, data }) => {
  const response = await axios.post(
    `/admin/customer/${customerId}/underwriting`,
    data
  );
  return response.data;
}

export const updateUnderwritingApi = async ({ customerId, underwritingId, data }) => {
  const response = await axios.put(
    `/admin/customer/${customerId}/underwriting/${underwritingId}`,
    data
  );
  return response.data;
}

export const getUnderwritingApi = async ({ customerId, underwritingId }) => {
  const response = await axios.get(
    `/admin/customer/${customerId}/underwriting/${underwritingId}`
  );
  return response.data;
}

export const deleteUnderwritingApi = async ({ customerId, underwritingId }) => {
  const response = await axios.delete(
    `/admin/customer/${customerId}/underwriting/${underwritingId}`
  );
  return response.data;
}
