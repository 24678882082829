export const isDemoApp = () => {
  return (
    !!process.env.REACT_APP_APP_TYPE &&
    process.env.REACT_APP_APP_TYPE === 'demo'
  );
};

export const isCustomerApp = () => {
  return (
    !!process.env.REACT_APP_APP_TYPE &&
    process.env.REACT_APP_APP_TYPE === 'customer'
  );
};

export const isLenderApp = () => {
  return (
    !!process.env.REACT_APP_APP_TYPE &&
    process.env.REACT_APP_APP_TYPE === 'lender'
  );
};

export const isCockpitApp = () => {
  return (
    !!process.env.REACT_APP_APP_TYPE &&
    process.env.REACT_APP_APP_TYPE === 'cockpit'
  );
};
