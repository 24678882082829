import { getCockpitUsersApi } from 'api/adminApi';
import { createContext, useEffect } from 'react';

import { useState } from 'react';

export const CockpitUsersContext = createContext();

const CockpitUsersProvider = ({ children }) => {
  const [users, setUsers] = useState(null);

  useEffect(() => {
    const init = async () => {
      await getUsers();
    };

    init();
  }, []);

  const getUsers = async () => {
    try {
      const _users = await getCockpitUsersApi();
      setUsers(_users);
    } catch (error) {
      throw error;
    }
  };

  const getUserName = (userId) => {
    const user = users[userId];
    return user ? user.name : '';
  };

  return (
    <CockpitUsersContext.Provider
      value={{
        getUserName,
      }}
    >
      {users && children}
    </CockpitUsersContext.Provider>
  );
};

export default CockpitUsersProvider;
