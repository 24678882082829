// Chakra imports
import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react';
// Assets
import { MdOutlineMoreHoriz } from 'react-icons/md';

import PropTypes from 'prop-types';

export default function TwMenu(props) {
  const { items, ...rest } = props;

  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  );
  const iconColor = useColorModeValue('brand.500', 'white');
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgShadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
    'unset'
  );
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const btnHoverStyles = useColorModeValue(
    { bg: 'transparent', color: 'brand.500' },
    { bg: 'whiteAlpha.50', color: 'white' }
  );


  // Ellipsis modals
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Menu isOpen={isOpen} onClose={onClose}>
      <MenuButton
        color={'secondaryGray.600'}
        align="center"
        justifyContent="center"
        bg={'transparent'}
        _hover={btnHoverStyles}
        _active={btnHoverStyles}
        w={10}
        h={10}
        lineHeight="100%"
        onClick={(event) => {
          event.stopPropagation();
          onOpen();
        }}
        borderRadius="10px"
        {...rest}
      >
        <Icon as={MdOutlineMoreHoriz} w="24px" h="24px" />
      </MenuButton>
      <MenuList
        minW="unset"
        border="transparent"
        backdropFilter="blur(63px)"
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius="16px"
        p={0}
      >
          {items.map((item, index) => (
            <MenuItem
              key={index}
              transition="0.2s linear"
              _hover={{
                bg: bgButton,
              }}
              borderRadius="16px"
              // _active={{
              //   bg: 'transparent',
              // }}
              // _focus={{
              //   bg: 'transparent',
              // }}
              whiteSpace={'nowrap'}
              px={4}
              py={3}
              onClick={item.onClick}
            >
              <Flex align="center">
                {item.icon && (
                  <Icon as={item.icon} h="16px" w="16px" me="8px" />
                )}
                <Text fontSize="sm" fontWeight="400">
                  {item.label}
                </Text>
              </Flex>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
}

TwMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
};
