import { Box, VStack, useToast } from '@chakra-ui/react';
import {
  getSalesCohortsApi,
  getSalesCohortsHistogramApi,
  getSalesKpisApi,
  getSalesRecurringRevenueApi,
  getSalesSettingsApi,
  updateSalesSettingsApi,
} from 'api/salesApi';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import cloneDeep from 'lodash.clonedeep';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Arpu from './Arpu';
import CohortTrends from './CohortTrends';
import Highlights from './Highlights';
import Ltv from './Ltv';
import RevenueCashflow from './RevenueCashflow';
import Roas from './Roas';
import Users from './Users';
import SalesSettings from './components/SalesSettings';

import {
  COMPARISON_PERIOD_OPTIONS,
  ERROR_CODES,
  MONEY_TYPE,
  SUBSCRIPTION_TYPE,
  TIME_PERIOD
} from 'constants/salesConstants';
import KpiCardExpandProvider from 'context/KpiCardExpandContext';
import { Element } from 'react-scroll';
import {
  transformCohorts,
  transformCohortsHistogramData,
} from '../../../../../transformers/cohortsTransformer';

import 'assets/scss/ScrollLinks.scss';
import { BUSINESS_MODEL_TYPE } from 'constants/underwritingConstants';
import { CockpitCustomerContext } from 'context/CockpitCustomerContext';
import moment from 'moment';
import DateInputHeader from '../DateInputHeader';
import CohortHistogram from './CohortHistogram';

const Sales = ({ isPlayground }) => {
  const urlParams = useParams();
  let [searchParams] = useSearchParams();

  const customerId = isPlayground
    ? searchParams.get('customerId')
    : urlParams.customerId;

  const toast = useToast();

  const [data, setData] = useState({
    cohortsRawData: null,
    cohortsData: null,
    revenueKpis: null,
    cashflowKpis: null,
    recurringRevenue: null,
    compareCohortsRawData: null,
    compareCohortsData: null,
    compareRevenueKpis: null,
    compareCashflowKpis: null,
    cohortsHistogramRawData: null,
    cohortsHistogramData: null,
  });

  const [loading, setLoading] = useState(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);
  const [date, setDate] = useState(null);
  const [defaultSettings, setDefaultSettings] = useState(null);
  const [settings, setSettings] = useState(null);
  const { customer } = useContext(CockpitCustomerContext);

  useEffect(() => {
    const init = async () => {
      const _settings = await getSalesSettings();
      if (!isSubscription) {
        _settings.moneyType = MONEY_TYPE.CASHFLOW;
        _settings.subscriptionType = SUBSCRIPTION_TYPE.ALL;
      }
      setDefaultSettings(_settings);
      setSettings(_settings);
    };

    init();
  }, []);

  const moneyTypeKpis = useMemo(() => {
    return settings
      ? settings.moneyType === MONEY_TYPE.REVENUE
        ? { actual: data.revenueKpis, compare: data.compareRevenueKpis }
        : { actual: data.cashflowKpis, compare: data.compareCashflowKpis }
      : null;
  }, [settings, data]);

  const onSettingsApply = (newSettings) => {
    const doGetCohorts =
      settings.timePeriod !== newSettings.timePeriod ||
      settings.withPrediction !== newSettings.withPrediction ||
      settings.currentMonthType !== newSettings.currentMonthType ||
      settings.cohortsMonthsBack !== newSettings.cohortsMonthsBack;

    const doGetCompare =
      doGetCohorts ||
      settings.comparisonPeriod !== newSettings.comparisonPeriod;

    loadData({
      _settings: newSettings,
      _date: date,
      doGetCohorts,
      doGetCompare,
    });
    setSettings(newSettings);
  };

  const onDateChange = (newDate) => {
    setDate(newDate);
    loadData({
      _settings: settings,
      _date: newDate,
      doGetCohorts: true,
      doGetKpi: true,
      doGetCompare: true,
    });
  };

  const isSubscription = useMemo(() => {
    return customer?.model === BUSINESS_MODEL_TYPE.SUBSCRIPTION;
  }, [customer]);

  const loadData = async ({
    _settings,
    _date,
    doGetCohorts,
    doGetKpi,
    doGetCompare,
  }) => {
    if (!customerId) {
      return;
    }

    let _data = cloneDeep(data);

    let promises = [];
    for (let i = 0; i < 6; i++) {
      promises.push(null);
    }
    if (doGetCohorts) {
      promises[0] = getCohortsData(_settings, _date, false);
      promises[5] =
        _settings.timePeriod === TIME_PERIOD.MONTHLY
          ? getCohortsHistogramData(_settings, _date, false)
          : null;
    }

    if (doGetKpi) {
      promises[1] = getSalesKpis(_date);
      promises[2] = getRecurringRevenueChartData(_date);
    }

    if (doGetCompare) {
      const _dateCompare = moment(_date)
        .clone()
        .subtract(_settings.comparisonPeriod, 'months')
        .format('YYYY-MM-DD');
      promises[3] = getCohortsData(_settings, _dateCompare, true);
      promises[4] = getSalesKpis(_dateCompare, true);
    }

    if (promises.length > 0) {
      try {
        setLoading(true);
        const [
          cohortsRes,
          salesKpisRes,
          recurringRevenueRes,
          compareCohortsRes,
          compareSalesKpisRes,
          cohortsHistogramRes,
        ] = await Promise.all(promises);

        if (cohortsRes) {
          _data.cohortsRawData = cohortsRes;
        }

        if (salesKpisRes) {
          _data.revenueKpis = salesKpisRes.revenueKpis;
          _data.cashflowKpis = salesKpisRes.cashflowKpis;
        }

        if (recurringRevenueRes) {
          _data.recurringRevenue = recurringRevenueRes;
        }

        if (compareCohortsRes) {
          _data.compareCohortsRawData = compareCohortsRes;
        }

        if (compareSalesKpisRes) {
          _data.compareRevenueKpis = compareSalesKpisRes.revenueKpis;
          _data.compareCashflowKpis = compareSalesKpisRes.cashflowKpis;
        }

        if (!!promises[5]) {
          _data.cohortsHistogramRawData = cohortsHistogramRes ? cohortsHistogramRes : null;
        }
      } finally {
        setLoading(false);
      }
    }

    _data.cohortsData = transformCohorts(_data.cohortsRawData, _settings);
    _data.compareCohortsData = transformCohorts(
      _data.compareCohortsRawData,
      _settings
    );
    _data.cohortsHistogramData = _data.cohortsHistogramRawData
      ? transformCohortsHistogramData(_data.cohortsHistogramRawData, _settings)
      : null;

    setData(_data);
  };

  const getSalesSettings = async () => {
    setLoading(true);
    try {
      let _salesSettings = await getSalesSettingsApi({ customerId });
      _salesSettings.comparisonPeriod = COMPARISON_PERIOD_OPTIONS[0].value;
      if (isPlayground) {
        _salesSettings.cohortsMonthsBack = 12;
      }
      return _salesSettings;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load sales preferences',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateDefaultSalesSettings = async (newSettings) => {
    setIsSavingSettings(true);
    try {
      await updateSalesSettingsApi({ customerId, data: newSettings });
      setDefaultSettings(newSettings);
      toast({
        title: 'Success',
        description: 'Default preferences saved successfully',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to save default preferences',
        status: 'error',
      });
    } finally {
      setIsSavingSettings(false);
    }
  };

  const getCohortsData = async (_settings, _date, onlyKpis) => {
    try {
      const cohortData = await getSalesCohortsApi({
        customerId,
        onlyKpis: !!onlyKpis,
        date: _date,
        timePeriod: _settings.timePeriod,
        withPrediction: _settings.withPrediction,
        currentMonthType: _settings.currentMonthType,
        cohortsMonthsBack: _settings.cohortsMonthsBack,
      });
      return cohortData;
    } catch (error) {
      const description =
        error?.response?.data?.errorCode === ERROR_CODES.MISSING_CAC
          ? 'Missing CAC in marketing segment'
          : 'Error getting cohorts';

      toast({
        title: 'Error',
        description,
        status: 'error',
      });

      throw error;
    }
  };

  const getCohortsHistogramData = async (_settings, _date, onlyKpis) => {
    try {
      const cohortData = await getSalesCohortsHistogramApi({
        customerId,
        date: _date,
        timePeriod: _settings.timePeriod,
        currentMonthType: _settings.currentMonthType,
      });
      return cohortData;
    } catch (error) {
      const description =
        error?.response?.data?.errorCode === ERROR_CODES.MISSING_CAC
          ? 'Missing CAC in marketing segment'
          : 'Error getting cohorts';

      toast({
        title: 'Error',
        description,
        status: 'error',
      });

      throw error;
    }
  };

  const getSalesKpis = async (_date, isCompare) => {
    try {
      const kpis = await getSalesKpisApi({
        customerId,
        date: _date,
        isCompare,
        onlyCashflow: !isSubscription,
      });
      return kpis;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error getting Sales Kpis',
        status: 'error',
      });
      throw error;
    }
  };

  const getRecurringRevenueChartData = async (_date) => {
    try {
      const recurringRevenue = await getSalesRecurringRevenueApi({
        customerId,
        date: _date,
      });
      return recurringRevenue;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error getting Recurring Revenue',
        status: 'error',
      });
      throw error;
    }
  };

  const withPrediction = useMemo(() => {
    return settings?.withPrediction
  }, [settings]);

  return (
    <KpiCardExpandProvider>
      <OverlaySpinnerWrapper show={loading} isFixed={true}>
        <Box minW={'container.lg'} maxW={'max'} pb={500}>
          <DateInputHeader
            date={date}
            onDateChange={onDateChange}
            showCustomerSelect={isPlayground}
            rightButtonElm={
              <SalesSettings
                date={date}
                loading={isSavingSettings}
                isSubscription={isSubscription}
                initialSettings={settings}
                defaultSettings={defaultSettings}
                onSettingsApply={onSettingsApply}
                onSaveAsDefault={(newDefaultSettings) =>
                  updateDefaultSalesSettings(newDefaultSettings)
                }
                isPlayground={isPlayground}
              />
            }
          />

          {data.cohortsRawData && data.recurringRevenue && (
            <VStack spacing={'60px'}>
              <Element name={'highlights'} className={'scroll-target-element'}>
                <Highlights
                  settings={settings}
                  moneyTypeKpis={moneyTypeKpis}
                  cohortsKpis={data?.cohortsData?.kpis}
                  compareCohortsKpis={data?.compareCohortsData?.kpis}
                  recurringRevenue={data.recurringRevenue}
                  isSubscription={isSubscription}
                />
              </Element>
              <Element
                name={'cohortTrends'}
                className={'scroll-target-element'}
              >
                <CohortTrends cohortsData={data.cohortsData} />
              </Element>
              {settings.timePeriod === TIME_PERIOD.MONTHLY && (
                <Element
                  name={'cohortHistogram'}
                  className={'scroll-target-element'}
                >
                  <CohortHistogram
                    data={data.cohortsHistogramData}
                    withPrediction={withPrediction}
                  />
                </Element>
              )}
              <Element name={'revenue'} className={'scroll-target-element'}>
                <RevenueCashflow
                  settings={settings}
                  moneyTypeKpis={moneyTypeKpis}
                  cohortsKpis={data?.cohortsData?.kpis}
                  compareCohortsKpis={data?.compareCohortsData?.kpis}
                  cohortsData={data.cohortsData}
                  isSubscription={isSubscription}
                />
              </Element>
              <Element name={'users'} className={'scroll-target-element'}>
                <Users
                  settings={settings}
                  moneyTypeKpis={moneyTypeKpis}
                  cohortsKpis={data?.cohortsData?.kpis}
                  compareCohortsKpis={data?.compareCohortsData?.kpis}
                  cohortsData={data.cohortsData}
                  isSubscription={isSubscription}
                />
              </Element>
              <Element name={'arpu'} className={'scroll-target-element'}>
                <Arpu
                  cohortsData={data.cohortsData}
                />
              </Element>
              <Element name={'roas'} className={'scroll-target-element'}>
                <Roas
                  cohortsData={data.cohortsData}
                />
              </Element>
              <Element name={'ltv'} className={'scroll-target-element'}>
                <Ltv
                  cohortsData={data.cohortsData}
                />
              </Element>
            </VStack>
          )}
        </Box>
      </OverlaySpinnerWrapper>
    </KpiCardExpandProvider>
  );
};

Sales.propTypes = {};

export default Sales;
