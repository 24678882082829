import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { createExternalTearsheetApi } from 'api/tearsheetApi';
import { TwDatePicker } from 'components/DatePicker';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import DrawerCloseButton from 'components/drawer/DrawerCloseButton';
import FileInput from 'components/inputs/FileInput';
import SelectInput from 'components/inputs/SelectInput';
import { useCompanyRankOptions } from 'hooks/useCompanyRankOptions';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const UploadTearsheet = ({ onUploadSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState();
  const [companyRank, setCompanyRank] = useState();
  const [file, setFile] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { companyRankOptions } =
    useCompanyRankOptions();

  const { customerId } = useParams();
  const toast = useToast();

  const createExternalTearsheet = async () => {
    setLoading(true);
    try {
      await createExternalTearsheetApi({
        customerId,
        date,
        companyRank,
        file,
      });
      toast({
        title: 'Success',
        description: 'Tearsheet uploaded successfully.',
        status: 'success',
      });
      onUploadSuccess();
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to upload tearsheet.',
        status: 'error',
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const onDrawerClose = () => {
    setDate();
    setCompanyRank();
    setFile();
    onClose();
  };

  return (
    <OverlaySpinnerWrapper show={loading}>
      <Button variant={'brand'} onClick={onOpen}>
        Upload Tearsheet
      </Button>
      <Drawer
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onDrawerClose}
      >
        <DrawerContent
          maxH={'max'}
          maxW={{ base: 'calc(100vw - 32px)', md: '427px' }}
        >
          <DrawerCloseButton onClose={onDrawerClose} />
          <DrawerBody>
            <SimpleGrid
              gridTemplateColumns={'min-content min-content'}
              whiteSpace={'nowrap'}
              justifyContent={'space-between'}
              spacingY={4}
              alignItems={'center'}
              fontSize={'sm'}
            >
              <Text fontSize={'16px'} fontWeight={800}>
                Upload Tearsheet
              </Text>
              <Box></Box>
              <Text>Date</Text>
              <TwDatePicker w={200} value={date} onChange={setDate} />
              <Text>Company Rank</Text>
              <SelectInput
                w={200}
                options={companyRankOptions}
                value={companyRank}
                onChange={setCompanyRank}
                placeholder={'Select Company Rank'}
              />
              <Text>Tearsheet File</Text>
              <FileInput
                w={200}
                onSelectFiles={(files) => setFile(files[0])}
                // allowedFileTypes={[
                //   'application/vnd.ms-excel',
                //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                // ]}
              />
            </SimpleGrid>
          </DrawerBody>
          <DrawerFooter pt={20}>
            <Button
              variant={'brand'}
              onClick={createExternalTearsheet}
              isDisabled={!date || !companyRank || !file}
            >
              Upload
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </OverlaySpinnerWrapper>
  );
};

UploadTearsheet.propTypes = {
  onUploadSuccess: PropTypes.func,
};

export default UploadTearsheet;
