
import { Decimal } from "decimal.js";
import { unionBy as _unionBy } from 'lodash';

export const transformAmortizationData = (data) => {
    data.sort((a, b) => {
      return new Date(a.transferDate) - new Date(b.transferDate);
    });
    let newData = [];
    let dates = data.map((item) => item.amortization.map((a) => a.date));

    dates = _unionBy(dates.flat(), (date) => date).sort();
    let totals = {
      amountSold: new Decimal(0),
      purchasePrice: new Decimal(0),
      amortization: dates.map((date) => {
        return { date, amount: new Decimal(0) };
      }),
    };

    for (const item of data) {
      let row = {
        transferDate: item.transferDate,
        amountSold: item.amountSold,
        purchasePrice: item.purchasePrice,
        dealType: item.dealType,
      };

      totals.amountSold = totals.amountSold.plus(item.amountSold);
      totals.purchasePrice = totals.purchasePrice.plus(item.purchasePrice);

      const amortizationAmounts = dates.map((date, index) => {
        const amortization = item.amortization.find((a) => a.date === date);
        if (!!amortization) {
          totals.amortization[index].amount = totals.amortization[
            index
          ].amount.plus(
            amortization.amountToPay === null
              ? amortization.minAmount
              : amortization.amountToPay
          );
          return amortization;
        }

        return null;
      });

      row.amortization = amortizationAmounts;
      newData.push(row);
    }

    return {
      dates,
      data: newData,
      totals,
    };
  };