import { DownloadIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { currencyFormat, numberFormat } from 'components/Number';
import {
  MONEY_TYPE,
  SUBSCRIPTION_TYPE,
  TIME_PERIOD,
  TOTAL_TYPE,
  TREND_TYPE,
} from 'constants/salesConstants';
import { useState } from 'react';
import { CSVLink } from 'react-csv';
import { transformCohorts } from '../../../transformers/cohortsTransformer';

const DetailsCsv = ({ cohortsRawData, roasVector }) => {
  const [csvData, setCsvData] = useState([]);

  const cohortsData = transformCohorts(cohortsRawData, {
    withRefunds: true,
    moneyType: MONEY_TYPE.REVENUE,
    totalType: TOTAL_TYPE.NET,
    subscriptionType: SUBSCRIPTION_TYPE.MONTHLY,
    timePeriod: TIME_PERIOD.MONTHLY,
    trendType: TREND_TYPE.MOM,
  });

  const csvHeaders = cohortsData.header.revenue;

  const csvCohortsData = cohortsData.rows.map((item) => {
    return {
      prefixColumns: item.prefixColumns,
      postfixColumns: item.postfixColumns.revenue,
      data: item.data.revenue,
    };
  });

  const generateCsvData = () => {

    const length = csvHeaders.length;

    let result = [];

    csvCohortsData.forEach((row) => {
      const data = row.data.map((item) =>
        item ? currencyFormat(item.value) : ''
      );
      result.push([...row.prefixColumns, ...data, ...row.postfixColumns]);
    });

    const emptyRow = Array(length).fill('');
    result.push(emptyRow);

    let roasVectorRow  = Array(csvCohortsData[0].prefixColumns.length - 1).fill('');
    roasVectorRow.push('ROAS 100');
    roasVectorRow.push(...roasVector.map((item) => numberFormat(item.amount, {maximumFractionDigits: 2})));
    result.push(roasVectorRow);

    setCsvData(result);
  };

  return (
    <Button variant={'outline'} leftIcon={<DownloadIcon />}>
      <CSVLink
        data={csvData}
        headers={csvHeaders}
        filename="details.csv"
        onClick={generateCsvData}
        asyncOnClick={true}
      >
        Details
      </CSVLink>
    </Button>
  );
};

DetailsCsv.propTypes = {};

export default DetailsCsv;
