import { Box, Flex, Text } from '@chakra-ui/react';
import { currencyFormat } from 'components/Number';
import {
  MONEY_TYPE,
  SUBSCRIPTION_TYPE,
  TOTAL_TYPE,
} from 'constants/salesConstants';
import { get as _get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const totalTypePropMap = {
  [TOTAL_TYPE.GROSS]: 'gross',
  [TOTAL_TYPE.NET]: 'net',
};

const refundPropMap = {
  true: 'with_refunds',
  false: 'without_refunds',
};

const subscriptionTypePropMap = {
  [SUBSCRIPTION_TYPE.ALL]: 'combined',
  [SUBSCRIPTION_TYPE.MONTHLY]: 'monthly',
  [SUBSCRIPTION_TYPE.ANNUALLY]: 'annually',
};

const moneyTypePropMap = {
  [MONEY_TYPE.REVENUE]: 'revenue',
  [MONEY_TYPE.CASHFLOW]: 'cash_flow',
};

const HighlightsCharts = ({ settings, recurringRevenue }) => {
  const [chartData, setChartData] = useState(null);
  const CHART_TYPE = {
    MONTHLY: 'monthly',
    DAILY: 'daily',
  };

  const CHART_CONFIG = {
    [CHART_TYPE.MONTHLY]: {
      parseDataProp: 'monthly',
      barChartDataProp: 'actual.{{subscriptionType}}',
      barChartName:
        settings?.moneyType === MONEY_TYPE.REVENUE
          ? 'Recurring Revenue'
          : 'Cashflow',
      barChartColor: '#4318ff',
      barChartBorderRadius: 12,
      isPredictionColor: '#C9FE37',
      getCategoryLabel: (mDate, index) => {
        if (index === 0 || mDate.month() === 0) {
          return [mDate.format('MMM'), mDate.format('YYYY')];
        }

        return mDate.format('MMM');
      },
      tooltipFormatter: (value, { dataPointIndex }) => {
        return chartData.monthly.dates[dataPointIndex].format('MMM YYYY');
      },
    },
    [CHART_TYPE.DAILY]: {
      parseDataProp: 'daily',
      barChartDataProp: 'data.{{subscriptionType}}.actual',
      barChartName:
      settings?.moneyType === MONEY_TYPE.REVENUE
        ? 'Recurring Revenue'
        : 'Cashflow',
      barChartColor: '#6ad2ff',
      barChartBorderRadius: 6,
      lineChartDataProp: settings?.moneyType === MONEY_TYPE.CASHFLOW ? 'data.{{subscriptionType}}.avg_calc' : null,
      lineChartName: 'Avg. Daily',
      lineChartColor: '#df43f0',
      isPredictionColor: '#C9FE37',
      getCategoryLabel: (mDate, index) => {
        if (index === 0) {
          return [mDate.format('D'), mDate.format('MMMM')];
        }

        return mDate.format('D');
      },
      tooltipFormatter: (value, { dataPointIndex }) => {
        return chartData.daily.dates[dataPointIndex].format('D MMMM');
      },
    },
  };

  useEffect(() => {
    if (recurringRevenue) {
      const monthlyChartData = generateChartData({
        chartType: CHART_TYPE.MONTHLY,
        settings,
        recurringRevenue,
      });

      const dailyChartData = generateChartData({
        chartType: CHART_TYPE.DAILY,
        settings,
        recurringRevenue,
      });

      setChartData({
        [CHART_TYPE.MONTHLY]: monthlyChartData,
        [CHART_TYPE.DAILY]: dailyChartData,
      });
    }
  }, [recurringRevenue, settings]);

  const generateChartData = ({ chartType, settings, recurringRevenue }) => {
    const config = CHART_CONFIG[chartType];
    const {
      parseDataProp,
      barChartDataProp,
      barChartName,
      barChartColor,
      lineChartDataProp,
      lineChartName,
      lineChartColor,
      getCategoryLabel,
      isPredictionColor,
    } = config;

    const moneyTypeProp = moneyTypePropMap[settings.moneyType];
    const subscriptionTypeProp =
      subscriptionTypePropMap[settings.subscriptionType];
    const totalTypeProp = `${totalTypePropMap[settings.totalType]}_${
      refundPropMap[settings.withRefunds]
    }`;

    const rawData = recurringRevenue[parseDataProp][moneyTypeProp];

    if (!rawData) {
      return null;
    }

    let barData = [];
    let barPredictionData = [];
    let lineData = lineChartDataProp ? [] : null;
    let dates = [];

    rawData.forEach((item, index) => {
      const mDate = moment({
        year: item.anchor_date.year,
        month: item.anchor_date.month - 1,
        day: item.anchor_date.day,
      });

      const barDataItem = _get(
        item,
        barChartDataProp.replace('{{subscriptionType}}', subscriptionTypeProp)
      );

      barData.push({
        x: getCategoryLabel(mDate, index),
        y: barDataItem[totalTypeProp] || 0,
        fillColor: item.is_prediction ? isPredictionColor : barChartColor,
        fill: {
          type: 'gradient',
          gradient: {
            type: 'vertical',
            gradientToColors: [
              item.is_prediction ? isPredictionColor : barChartColor,
            ],
            opacityFrom: 1,
            opacityTo: 0.28,
            stops: [0, 100],
          },
        },
      });

      barPredictionData.push({
        x: getCategoryLabel(mDate, index),
        y: item.prediction
          ? item['prediction'][subscriptionTypeProp][totalTypeProp] || 0
          : null,
        fillColor: isPredictionColor,
      });

      if (lineData) {
        const lineDataItem = _get(
          item,
          lineChartDataProp.replace(
            '{{subscriptionType}}',
            subscriptionTypeProp
          )
        );
        lineData.push({
          x: getCategoryLabel(mDate, index),
          y: lineDataItem[totalTypeProp],
        });
      }

      dates.push(mDate);
    });

    let series = [
      {
        name: barChartName,
        data: barData,
        type: 'bar',
        color: barChartColor,
      },
      {
        name: 'Prediction',
        data: barPredictionData,
        type: 'bar',
        color: isPredictionColor,
      },
    ];

    if (lineData) {
      series.push({
        name: lineChartName,
        data: lineData,
        type: 'line',
        color: lineChartColor,
      });
    }

    return {
      series: series,
      options: {
        chart: {
          fontFamily: 'DM Sans',
          stacked: true,
          stackOnlyBar: true,
          offsetX: -15,
          toolbar: {
            offsetX: -15,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
        },
        stroke: {
          width: [0, 0, 2],
        },
        legend: {
          show: false,
        },
        xaxis: {
          type: 'category',
          labels: {
            hideOverlappingLabels: false,
            minHeight: 50,
            style: {
              fontSize: 10,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: 0,
          labels: {
            formatter: (value) => {
              if (value === null) {
                return null;
              }
              return currencyFormat(value);
            },
          },
        },
      },
      dates,
    };
  };

  const getOptions = (chartType) => {
    let options = chartData[chartType].options;
    options.tooltip = {
      //   enabledOnSeries: [0, 2],
      hideEmptySeries: true,
      x: {
        formatter: CHART_CONFIG[chartType].tooltipFormatter,
      },
    };

    return options;
  };

  return (
    <>
      {chartData && (
        <Flex w={'100%'} gap={4}>
          {chartData[CHART_TYPE.MONTHLY] && (
            <Box w={'50%'}>
              <Text mb={30} fontWeight={800}>
                {settings?.moneyType === MONEY_TYPE.REVENUE
                  ? 'Monthly Recurring revenue (Monthly)'
                  : 'Cashflow Monthly'}
              </Text>
              <Chart
                type={'line'}
                height={240}
                series={chartData[CHART_TYPE.MONTHLY].series}
                options={getOptions(CHART_TYPE.MONTHLY)}
              />
            </Box>
          )}

          {chartData[CHART_TYPE.DAILY] && (
            <Box w={'50%'}>
              <Text mb={30} fontWeight={800}>
              {settings?.moneyType === MONEY_TYPE.REVENUE
                  ? 'Monthly Recurring revenue (Daily)'
                  : 'Cashflow Daily'}
              </Text>
              <Chart
                type={'line'}
                height={240}
                series={chartData[CHART_TYPE.DAILY].series}
                options={getOptions(CHART_TYPE.DAILY)}
              />
            </Box>
          )}
        </Flex>
      )}
    </>
  );
};

HighlightsCharts.propTypes = {
  recurringRevenue: PropTypes.object,
};

export default HighlightsCharts;
