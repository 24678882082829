import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { useUser } from '@descope/react-sdk';
import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from 'components/Number';
import KpiCollection from 'components/kpi/KpiCollection';
import { CockpitUsersContext } from 'context/CockpitUsersContext';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useContext, useMemo, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import EditKpiDrawer from './EditKpiDrawer';

const EditButton = ({ onClick }) => {
  return (
    <Tooltip label={'Edit'}>
      <IconButton
        icon={<HiDotsHorizontal size={20} />}
        borderRadius={12}
        _hover={{ bg: 'secondaryGray.300', color: 'brand.300' }}
        onClick={onClick}
      />
    </Tooltip>
  );
};

const IsOverrideIcon = () => (
  <Box
    top={'50%'}
    left={'-20px'}
    mt={'-8px'}
    bg={'#d1ed61'}
    position={'absolute'}
    borderRadius={'12px'}
    borderTopRightRadius={0}
    borderBottomLeftRadius={0}
    w={'16px'}
    h={'16px'}
    boxShadow={'14px 17px 40px 0px rgba(112, 144, 176, 0.08)'}
  ></Box>
);

const EditKpiCollection = ({ kpis, config, onKpiSave, hasOriginalValues, hideEditButton }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedKpiItem, setSelectedKpiItem] = useState(null);
  const { user } = useUser();
  const { getUserName } = useContext(CockpitUsersContext);

  const getFormatAndPrefix = (func) => {
    if (func == currencyFormat) {
      return {
        format: numberFormat,
        signPrefix: '$',
      };
    }

    if (func == percentageFormat) {
      return {
        format: (value) => numberFormat(value * 100),
        signPrefix: '%',
      };
    }

    return {
      format: func,
    };
  };

  const createKpiItems = () => {
    const kpiCards = config.map((configItem) => {
      const { displayName, items, tooltip, row } = configItem;
      const kpiItems = items.map((item, index) => {
        const { label, format, kpiProp, fieldElement } = item;
        const kpiObj = kpis[kpiProp];
        const value = kpiObj ? kpiObj.value : null;
        const buttonElm = hideEditButton ? null : (
          <EditButton
            onClick={() =>
              onEditClick({
                kpiProp,
                displayName,
                displayLabel: items.length > 1 ? label : '',
                formatFunc: format,
                fieldElement,
                kpiObj,
              })
            }
          />
        );

        return {
          label,
          value,
          valueIcon: kpiObj?.isManual && hasOriginalValues ? (
            <IsOverrideIcon />
          ): null,
          format,
          buttonElm,
        };
      });

      return {
        title: displayName,
        tooltip,
        items: kpiItems,
        row,
      };
    });

    return kpiCards;
  };

  const kpiCards = useMemo(() => {
    if (!kpis) {
      return [];
    }
    return createKpiItems();
  }, [kpis]);

  const onEditClick = ({
    kpiProp,
    displayName,
    displayLabel,
    formatFunc,
    fieldElement,
    kpiObj,
  }) => {
    const { format, signPrefix } = getFormatAndPrefix(formatFunc);
    let { value, comments, updatedAtUnix, userId, isManual, originalValue } =
      kpiObj || {};

    const _selectedKpiItem = {
      kpiProp,
      value,
      originalValue,
      comments,
      isManual,
      userName: getUserName(userId),
      lastOverrideAt: updatedAtUnix,
      kpiName: displayName,
      kpiLabel: displayLabel,
      format,
      signPrefix,
      fieldElement,
    };
    setSelectedKpiItem(_selectedKpiItem);
    setIsDrawerOpen(true);
  };

  const onDrawerSave = ({ kpiProp, newValue, originalValue, newComments }) => {
    let value =
      newValue !== undefined && newValue !== null
        ? newValue
        : selectedKpiItem.value;
    const comments = newComments || selectedKpiItem.comments;

    const kpi = {
      value,
      originalValue,
      comments,
      isManual: true,
      userId: user?.userId,
      updatedAtUnix: moment().utc().valueOf(),
    };
    onKpiSave({ kpiProp, kpi });
  };

  const onDrawerClose = () => {
    setSelectedKpiItem(null);
    setIsDrawerOpen(false);
  };

  return (
    <>
      <KpiCollection kpis={kpiCards} twoLinesTitle={true} />
      <EditKpiDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        kpiItem={selectedKpiItem}
        onSave={onDrawerSave}
        showOriginalValue={hasOriginalValues}
      />
    </>
  );
};

EditKpiCollection.propTypes = {
  kpis: PropTypes.object,
  onKpiSave: PropTypes.func,
  hasOriginalValues: PropTypes.bool,
  hideEditButton: PropTypes.bool,
  config: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      row: PropTypes.number,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          kpiProp: PropTypes.string,
          format: PropTypes.func,
          buttonElm: PropTypes.element,
        })
      ),
    })
  ),
};

export default EditKpiCollection;
