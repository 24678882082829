import {
  Box,
  Center,
  Flex,
  FormLabel,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { currencyFormat, Number, numberFormat } from 'components/Number';
import ChartTooltip from 'components/charts/ChartTooltip';
import SelectInput from 'components/inputs/SelectInput';
import { generateSelectOptions } from 'helpers/inputHelper';
import { toScientificNotation } from 'helpers/numberHelper';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';
import regression from 'regression';

const REGRESSION_TYPE = {
  LINEAR: 'linear',
  EXPONENTIAL: 'exponential',
  LOGARITHMIC: 'logarithmic',
  POWER: 'power',
  POLYNOMIAL: 'polynomial',
};

const REGRESSION_TYPE_META = {
  [REGRESSION_TYPE.LINEAR]: {
    label: 'Linear',
  },
  [REGRESSION_TYPE.EXPONENTIAL]: {
    label: 'Exponential',
  },
  [REGRESSION_TYPE.LOGARITHMIC]: {
    label: 'Logarithmic',
  },
  [REGRESSION_TYPE.POWER]: {
    label: 'Power',
  },
  [REGRESSION_TYPE.POLYNOMIAL]: {
    label: 'Polynomial',
  },
};

const getRegressionData = (regressionType, data) => {
  data = data.filter((point) => point[0] > 0 && point[1] > 0);

  let regressionFunc = null;
  switch (regressionType) {
    case REGRESSION_TYPE.LINEAR:
      regressionFunc = regression.linear;
      break;
    case REGRESSION_TYPE.EXPONENTIAL:
      regressionFunc = regression.exponential;
      break;
    case REGRESSION_TYPE.LOGARITHMIC:
      regressionFunc = regression.logarithmic;
      break;
    case REGRESSION_TYPE.POWER:
      regressionFunc = regression.power;
      break;
    case REGRESSION_TYPE.POLYNOMIAL:
      regressionFunc = regression.polynomial;
      break;
    default:
      regressionFunc = regression.linear;
      break;
  }
  return regressionFunc(data, {
    precision: 10,
  });
};

const formatRegressionEquation = (regressionData, regressionType) => {
  const { equation } = regressionData;

  if (regressionType === REGRESSION_TYPE.LINEAR) {
    const gradient = equation[0];
    const yIntercept = equation[1];

    const { mantissa, exponent } = toScientificNotation(gradient);

    const x = `${mantissa}*${numberFormat(Math.pow(10, Math.abs(exponent)))}`;
    const y = numberFormat(yIntercept);

    return `y = ${x}x + ${y}`;
  }

  return regressionData.string;
};

const calculateCacSensitivity = (gradient) => {
  return 1 / gradient;
};

const CacPredictionChart = ({ cohortsData }) => {
  const [chartData, setChartData] = useState(null);
  const [regressionType, setRegressionType] = useState(REGRESSION_TYPE.LINEAR);

  useEffect(() => {
    if (cohortsData) {
      const chartData = generateChartData();
      setChartData(chartData);
    }
  }, [cohortsData, regressionType]);

  const generateChartData = () => {
    const cacToUaSeriesData = cohortsData.rows.map((row) => [
      row.data.totalUa,
      row.data.cac,
    ]);

    const regressionData = getRegressionData(regressionType, cacToUaSeriesData);

    const regressionSeriesData = regressionData.points.sort(
      (a, b) => a[0] - b[0]
    );

    const cacToUaSeries = {
      name: 'Cac To Ua',
      data: cacToUaSeriesData,
      type: 'scatter',
      color: '#775ffc',
    };

    const regressionSeries = {
      name: 'Regression',
      data: regressionSeriesData,
      type: 'line',
      color: '#c9fe37',
    };

    return {
      series: [cacToUaSeries, regressionSeries],
      options: getChartOptions(),
      regressionData,
    };
  };

  const getChartOptions = () => {
    return {
      chart: {
        type: 'line',
        fontFamily: 'DM Sans',
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: false,
        intersect: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const point = w.config.series[0].data[dataPointIndex];

          const totalUa = point[0];
          const cac = point[1];

          const items = [
            {
              label: 'CAC',
              value: currencyFormat(cac, { maximumFractionDigits: 1 }),
            },
            {
              label: 'Total UA',
              value: currencyFormat(totalUa),
            },
          ];

          return renderToString(
            <ChartTooltip items={items} hideMarkers={true} />
          );
        },
      },
      legend: {
        show: false,
      },
      fill: {
        type: 'solid',
      },
      markers: {
        size: [8, 0],
      },
      yaxis: {
        title: {
          text: 'CAC',
        },
        labels: {
          formatter: (value) => {
            return currencyFormat(value);
          },
        },
      },
      xaxis: {
        title:{
          text: 'Total UA',
        },
        tooltip: {
          enabled: false,
        },
        labels: {
          formatter: (value) => {
            return currencyFormat(value);
          },
        },
      },
    };
  };

  if (!chartData) {
    return null;
  }

  return (
    <>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Text fontWeight={'800'}>Cac Prediction</Text>
        <Flex align={'center'} gap={1}>
          <FormLabel m={0}>Regression: </FormLabel>
          <SelectInput
            options={generateSelectOptions({ metaObj: REGRESSION_TYPE_META })}
            value={regressionType}
            onChange={setRegressionType}
            w={200}
          />
        </Flex>
      </Flex>
      <Box mt={4}>
        <Box>
          <Center>
            <SimpleGrid columns={2} spacingY={2} spacingX={10}>
              <Text>
                <b>Equation: </b>{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatRegressionEquation(
                      chartData.regressionData,
                      regressionType
                    ),
                  }}
                ></span>
              </Text>
              <Text>
                <b>
                  R<sup>2</sup>
                </b>{' '}
                : {chartData.regressionData.r2}
              </Text>
              {regressionType === REGRESSION_TYPE.LINEAR && (
                <>
                  <Text>
                    <b>CAC Sensitivity: </b>
                    <Number
                      value={calculateCacSensitivity(
                        chartData.regressionData.equation[0]
                      )}
                    />
                  </Text>
                  <Box></Box>
                </>
              )}
            </SimpleGrid>
          </Center>
        </Box>
        <Box h={350} mt={-4}>
          <Chart
            type="line"
            width={'100%'}
            height={'100%'}
            series={chartData.series}
            options={chartData.options}
          />
        </Box>
      </Box>
    </>
  );
};

CacPredictionChart.propTypes = {
  cohortsData: PropTypes.object,
};

export default CacPredictionChart;
