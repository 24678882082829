import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { deleteConnectorApi, getConnectorsApi } from 'api/connectorsApi';
import DataTable from 'components/DataTable';
import { DestructiveDialog } from 'components/Dialog';
import StatusTag from 'components/StatusTag';
import Card from 'components/card/Card';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CONNECTOR_INTEGRATION_META,
  CONNECTOR_INTEGRATION_TYPE,
  CONNECTOR_META_DATA,
  CONNECTOR_STATUS,
  CONNECTOR_STATUS_META,
} from '../../../../constants/connectorConstants';
import BankAccountDetailsModal from './BankAccountDetailsModal';

const columnHelper = createColumnHelper();
const defaultSorting = [{ id: 'createdAtMillis', desc: true }];

const createColumns = (onRemoveConnector) => {
  const columns = [
    columnHelper.display({
      id: 'name',
      cell: (info) => {
        const connector = info.row.original;
        return <ConnectorInfo connector={connector} />;
      },
      enableSorting: false,
    }),
    columnHelper.accessor('setupStatus', {
      cell: (info) => {
        const statusMeta = CONNECTOR_STATUS_META[info.getValue()];
        return <StatusTag level={statusMeta.level} label={statusMeta.text} />;
      },
      header: 'Status',
    }),
    columnHelper.accessor('createdAtMillis', {
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
      header: 'Created At',
    }),
    columnHelper.accessor('lastSyncMillis', {
      cell: (info) => {
        const connector = info.row.original;
        if (
          !CONNECTOR_INTEGRATION_META[connector.integrationType].hasLastSync
        ) {
          return 'N/A';
        }
        return info.getValue() ? moment(info.getValue()).format('LLL') : ' - ';
      },
      header: 'Last Sync',
    }),
    columnHelper.display({
      id: 'remove',
      cell: (info) => {
        const connector = info.row.original;
        if (CONNECTOR_INTEGRATION_META[connector.integrationType].canRemove) {
          return (
            <Button
              variant={'link'}
              size={'sm'}
              color={'red.500'}
              onClick={() => onRemoveConnector(connector)}
            >
              Remove
            </Button>
          );
        }
      },
      enableSorting: false,
    }),
  ];

  return columns;
};

function ConnectorInfo({ connector }) {
  const {
    type,
    name,
    integrationType,
    plaidAccounts,
    logoBase64,
    setupStatus,
  } = connector;
  const isPlaid = integrationType === CONNECTOR_INTEGRATION_TYPE.PLAID;

  let { displayName, icon } = CONNECTOR_META_DATA[type] || {};
  const { customerId } = useParams();

  if (name) {
    displayName = name;
  }

  const BankIcon = () => {
    const imgSrc = logoBase64 ? `data:image/jpeg;base64,${logoBase64}` : null;
    return imgSrc ? (
      <Image src={imgSrc} boxSize={6} />
    ) : (
      <Icon as={icon} boxSize={6} />
    );
  };

  return (
    <>
      <Flex gap={2} align={'center'}>
        <BankIcon />
        <Flex gap={1}>
          <Text fontSize={'md'}>{displayName}</Text>
          {isPlaid && (
            <BankAccountDetailsModal
              customerId={customerId}
              itemId={connector.id}
              bankIcon={<BankIcon />}
              bankName={displayName}
              isConnectionBroken={setupStatus === CONNECTOR_STATUS.BROKEN}
            />
          )}
        </Flex>
      </Flex>
      {isPlaid && (
        <Box ml={8}>
          {plaidAccounts.map((account) => (
            <Box key={account.id} fontSize={'xs'} color={'gray.main'}>
              {account.name} - {account.mask}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

function ConnectorList() {
  const [loadingConnectors, setLoadingConnectors] = useState(false);
  const [connectors, setConnectors] = useState([]);
  const [columns, setColumns] = useState([]);
  const [connectorToRemove, setConnectorToRemove] = useState(null);
  const toast = useToast();

  const { customerId } = useParams();

  useEffect(() => {
    loadConnectors();
    setColumns(createColumns(onRemoveConnectorClick));
  }, []);

  const loadConnectors = async () => {
    setLoadingConnectors(true);
    try {
      const data = await getConnectorsApi(customerId);
      handleConnectorsApiResult(data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Error loading connectors',
        status: 'error',
      });
    } finally {
      setLoadingConnectors(false);
    }
  };

  const handleConnectorsApiResult = (data) => {
    const { connectors, failedIntegrations } = data;
    setConnectors(connectors);
    if (failedIntegrations?.length > 0) {
      toast({
        title: 'Error',
        description: `Error loading connectors from ${failedIntegrations.join(
          ', '
        )} `,
        status: 'error',
      });
    }
  };

  const onRemoveConnectorClose = () => {
    setConnectorToRemove(null);
  };

  const onRemoveConnector = async () => {
    try {
      setLoadingConnectors(true);
      await deleteConnectorApi(
        customerId,
        connectorToRemove.type,
        connectorToRemove.id
      );
      toast({
        title: 'Success',
        description: 'Connector removed successfully',
        status: 'success',
      });
      loadConnectors();
    } catch (error) {
      setLoadingConnectors(false);
      toast({
        title: 'Error',
        description: 'Error removing connector',
        status: 'error',
      });
    }
  };

  const onRemoveConnectorClick = (connector) => {
    setConnectorToRemove(connector);
  };

  if (loadingConnectors) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <>
      <Box minW={'container.lg'} maxW={'max'}>
        <Card>
          <Heading variant={'sectionHeader'} borderColor={'secondaryGray.600'}>
            Connectors
          </Heading>
          <Box mt={4} minH={300}>
            {loadingConnectors ? (
              ''
            ) : connectors.length === 0 ? (
              <Center>
                <Text fontSize={'xl'}>No connectors have been set up yet</Text>
              </Center>
            ) : (
              <DataTable
                columns={columns}
                data={connectors}
                defaultSorting={defaultSorting}
              />
            )}
          </Box>
        </Card>
      </Box>
      <DestructiveDialog
        header={'Remove Plaid Item'}
        body={
          connectorToRemove
            ? `Are you sure you want to remove the Plaid item for ${connectorToRemove.name} ?`
            : null
        }
        deleteButtonText={'Remove'}
        show={!!connectorToRemove}
        onDelete={onRemoveConnector}
        onDialogClose={onRemoveConnectorClose}
      />
    </>
  );
}

export default ConnectorList;
