import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Spinner,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { getTearsheetApi } from 'api/tearsheetApi';
import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from 'components/Number';
import Card from 'components/card/Card';
import BackLink from 'components/links/BackLink';
import { MONEY_TYPE } from 'constants/salesConstants';
import { get as _get } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CircularSlider from 'react-circular-slider-svg';
import { IoCheckmarkSharp, IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import generatePDF, { Margin } from 'react-to-pdf';
import CompanyScoreDetails from './CompanyScoreDetails';
import MaxExposureSection from './MaxExposureSection';

const formatDate = (date, formatString) => {
  if (!date) {
    return null;
  }

  const { year, month, day } = date;
  return moment({ year, month: month - 1, day }).format(formatString);
};

const formatBoolean = (value) => {
  const icon = value ? IoCheckmarkSharp : IoClose;
  return (
    <Icon
      position={'absolute'}
      as={icon}
      boxSize={'20px'}
      color={'brand.300'}
    />
  );
};

const scoreFields = [
  {
    left: {
      label: 'Company',
      name: 'company',
      format: percentageFormat,
    },
    right: {
      label: 'Management',
      name: 'management',
      format: percentageFormat,
    },
  },
  {
    left: {
      label: 'Company Normalized',
      name: 'company_normalized',
      format: percentageFormat,
    },
    right: {
      label: 'Performance',
      name: 'performance',
      format: percentageFormat,
    },
  },
  {
    right: {
      label: 'Stability',
      name: 'stability',
      format: percentageFormat,
    },
  },
];

const businessInfoFields = [
  {
    label: 'Legal Name',
    name: 'legal_name',
    isConfidential: true,
  },
  {
    label: 'HQ',
    name: 'headquarters',
  },
  {
    label: 'Founded',
    name: 'founded_date',
    format: (date) => formatDate(date, 'MMMM DD, YYYY'),
  },
  {
    label: 'Employees',
    name: 'number_of_employees',
    format: numberFormat,
  },
  {
    label: 'Founders / mgmt.',
    name: 'founders',
    isConfidential: true,
  },
  {
    label: 'Raised to Date',
    name: 'total_raised_amount',
    format: currencyFormat,
  },
  {
    label: 'Last Round',
    name: 'last_round_date',
    format: (date) => formatDate(date, 'MMMM DD, YYYY'),
  },
  {
    label: 'VC Tier',
    name: 'vc_tier',
  },
];

const dealFields = [
  {
    titleRow: 'Highlights',
  },
  {
    left: {
      label: 'Underwriting',
      name: 'last_underwriting',
      format: (date) => formatDate(date, 'MM/DD/YY'),
      last: 'Last',
    },
    right: {
      label: 'Total Expected IRR',
      name: 'total_expected_irr',
      format: percentageFormat,
    },
  },
  {
    left: {
      label: '',
      name: 'next_underwriting',
      format: (date) => formatDate(date, 'MM/DD/YY'),
      last: 'Next',
    },
    right: {
      label: '# of Months to \n Max Exposure',
      name: 'months_to_max_exposure',
      format: numberFormat,
      isDoubleHeight: true,
    },
  },
  {
    left: {
      label: '%Cohort',
      name: 'cohort_founded',
      format: percentageFormat,
      last: 'Founded',
    },
  },
  {
    left: {
      label: '',
      name: 'cohort_repayment',
      format: percentageFormat,
      last: 'Repayment',
    },
    right: {
      label: 'Min Repayment',
      name: 'min_repayment',
      format: currencyFormat,
    },
  },
  {
    titleRow: 'Exposure',
  },
  {
    left: {
      label: 'Max Exposure',
      name: 'max_exposure',
      format: currencyFormat,
    },
    right: {
      label: 'Current Exposure',
      name: 'current_exposure',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: 'Time to Max \n Exposure',
      name: 'time_to_max_exposure',
      format: numberFormat,
      last: 'Months',
    },
    right: {
      label: 'Peak Exposure',
      name: 'peak_exposure',
      format: currencyFormat,
    },
  },
  {
    titleRow: 'Funding',
  },
  {
    left: {
      label: 'Cumulative Funding',
      name: 'cumulative_funding',
      format: currencyFormat,
    },
    right: {
      label: 'Next Funding \n Date',
      name: 'next_funding_date',
      format: (date) => formatDate(date, 'MM/DD/YY'),
      isDoubleHeight: true,
    },
  },
  {
    left: {
      label: 'Next Funding',
      name: 'next_funding',
      format: currencyFormat,
    },
  },
  {
    titleRow: 'Repayment',
  },
  {
    left: {
      label: 'Next Repayment',
      name: 'next_repayment',
      format: currencyFormat,
    },
    right: {
      label: 'Cumulative Repayment',
      name: 'cumulative_repayment',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: 'Next Repayment Date',
      name: 'next_repayment_date',
      format: (date) => formatDate(date, 'MM/DD/YY'),
    },
  },
];

const getMarketingFields = (date) => {
  const marketingFields = [
    {
      left: {
        label: 'Last Day Spend',
        name: 'last_day_spent',
        format: currencyFormat,
        last: moment(date).format('MMM DD, YYYY'),
      },
      right: {
        label: 'Total Marketing Spend',
        name: 'total_sales_and_marketing',
        format: currencyFormat,
        last: 'Last Month',
      },
    },
    {
      left: {
        label: 'Average Daily Spend',
        name: 'avg_daily_spend_per_last_7_days',
        format: currencyFormat,
        last: 'Last 7 Days',
      },
      right: {
        label: 'User Acquisition Spend',
        name: 'percent_ua_spend',
        format: percentageFormat,
      },
    },
    {
      left: {
        label: '',
        name: 'avg_daily_spend_per_last_30_days',
        format: currencyFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: 'Total Spend',
        name: 'total_spend_last_30_days',
        format: currencyFormat,
        last: 'Last 30 Days',
      },
    },
    {
      left: {
        label: 'Monthly Run Rate',
        name: 'monthly_run_rate_per_7_days',
        format: currencyFormat,
      },
      right: {
        label: '',
        name: 'last_month_ua_spend',
        format: currencyFormat,
        last: 'Last Month',
      },
    },
    {
      left: {
        label: 'Annual Run Rate',
        name: 'annually_run_rate_per_7_days',
        format: currencyFormat,
      },
    },
  ];

  return marketingFields;
};

const getCashFlowFields = (date) => {
  const cashFlowFields = [
    {
      titleRow: 'Highlights',
    },
    {
      left: {
        label: 'Rate of Change',
        name: 'cashflow.change_last_30_days',
        format: percentageFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: 'LTV',
        name: 'cohorts.ltv_3_months',
        format: currencyFormat,
        last: 'Last 3 Cohorts',
      },
    },
    {
      left: {
        label: '',
        name: 'cashflow.change_last_12_months',
        format: percentageFormat,
        last: 'Last 12 Months',
      },
      right: {
        label: '',
        name: 'cohorts.ltv_6_months',
        format: currencyFormat,
        last: 'Last 6 Cohorts',
      },
    },
    {
      left: {
        label: 'CAC',
        name: 'cohorts.cac_avg_3_months',
        format: currencyFormat,
        last: 'Last 3 Months',
      },
      right: {
        label: 'ROAS 100%',
        name: 'cohorts.roas_100',
        format: numberFormat,
        last: 'Months',
      },
    },
    {
      right: {
        label: 'Total Users',
        name: 'cohorts.total_last_30_days_users',
        format: numberFormat,
        last: 'Last 30 Days',
      },
    },
    {
      titleRow: 'Cashflow',
    },
    {
      left: {
        label: 'Total',
        name: 'cashflow.total_last_30_days',
        format: currencyFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: 'Annual Run Rate',
        name: 'cashflow.annual_last_1_day',
        format: currencyFormat,
        last: '1 Day',
      },
    },
    {
      left: {
        label: 'Daily Average',
        name: 'cashflow.avg_last_7_days',
        format: currencyFormat,
        last: 'Last 7 Days',
      },
      right: {
        label: '',
        name: 'cashflow.annual_last_7_days',
        format: currencyFormat,
        last: '7 Days',
      },
    },
    {
      left: {
        label: '',
        name: 'cashflow.avg_last_30_days',
        format: currencyFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: '',
        name: 'cashflow.annual_last_30_days',
        format: currencyFormat,
        last: '30 Days',
      },
    },
    {
      left: {
        label: 'Last Full Day',
        name: 'cashflow.last_1_day',
        format: currencyFormat,
        last: moment(date).clone().subtract(1, 'day').format('MMM DD, YYYY'),
      },
      right: {
        label: 'CAGR',
        name: 'cashflow.cagr_6_months',
        format: percentageFormat,
        last: '6 Months',
      },
    },
    {
      right: {
        label: 'ROAS 100% Duration',
        name: 'cohorts.duration_100',
        format: (value) => numberFormat(value, { maximumFractionDigits: 2 }),
        last: 'Months',
      },
    },
    {
      titleRow: 'Users',
    },
    {
      left: {
        label: 'Paying Users',
        name: 'cashflow.total_last_30_days_users',
        format: numberFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: 'Current ARPU',
        name: 'cohorts.current_arpu_avg_3_months',
        format: currencyFormat,
        last: 'Last 3 Months',
      },
    },
    {
      left: {
        label: '',
        name: 'cashflow.total_last_30_days_users_percent',
        format: percentageFormat,
        last: 'Last 30 Days',
      },
    },
  ];

  return cashFlowFields;
};

const getRevenueFields = (date) => {
  const revenueFields = [
    {
      titleRow: 'Highlights',
    },
    {
      left: {
        label: 'Current MRR',
        name: 'revenue.last_1_day',
        format: currencyFormat,
      },
      right: {
        label: 'LTV',
        name: 'cohorts.ltv_3_months',
        format: currencyFormat,
        last: 'Last 3 Cohorts',
      },
    },
    {
      left: {
        label: 'Current ARR',
        name: 'revenue.annual_run_rate_1_day',
        format: currencyFormat,
      },
      right: {
        label: '',
        name: 'cohorts.ltv_6_months',
        format: currencyFormat,
        last: 'Last 6 Cohorts',
      },
    },
    {
      left: {
        label: 'Rate of Change',
        name: 'revenue.change_in_mrr_last_30_days_percentage',
        format: percentageFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: 'ROAS 100%',
        name: 'cohorts.roas_100',
        format: numberFormat,
        last: 'Months',
      },
    },
    {
      left: {
        label: '',
        name: 'revenue.change_in_mrr_last_12_months_percentage',
        format: percentageFormat,
        last: 'Last 12 Months',
      },
      right: {
        label: 'Total Users',
        name: 'cohorts.total_last_30_days_users',
        format: numberFormat,
        last: 'Last 30 Days',
      },
    },
    {
      titleRow: 'Revenue',
    },
    {
      left: {
        label: 'Daily Average',
        name: 'revenue.avg_last_7_days',
        format: currencyFormat,
        last: 'Last 7 Days',
      },
      right: {
        label: 'Annual Run Rate',
        name: 'revenue.annual_run_rate_1_day',
        format: currencyFormat,
        last: '1 Day',
      },
    },
    {
      left: {
        label: '',
        name: 'revenue.avg_last_30_days',
        format: currencyFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: '',
        name: 'revenue.annual_run_rate_7_days',
        format: currencyFormat,
        last: '7 Days',
      },
    },
    {
      left: {
        label: 'Last Full Day',
        name: 'revenue.last_1_day',
        format: currencyFormat,
        last: moment(date).clone().subtract(1, 'day').format('MMM DD, YYYY'),
      },
      right: {
        label: '',
        name: 'revenue.annual_run_rate_30_days',
        format: currencyFormat,
        last: '30 Days',
      },
    },
    {
      left: {
        label: 'CAGR',
        name: 'revenue.cagr_6_months',
        format: percentageFormat,
        last: '6 Months',
      },
    },
    {
      titleRow: 'Users',
    },
    {
      left: {
        label: 'Paying Users',
        name: 'revenue.number_of_users',
        format: numberFormat,
        last: 'Last 30 Days',
      },
      right: {
        label: 'Current ARPU',
        name: 'revenue.percent_of_users',
        format: currencyFormat,
        last: 'Last 3 Months',
      },
    },
    {
      left: {
        label: '',
        name: 'cashflow.total_last_30_days_users_percent',
        format: percentageFormat,
        last: 'Last 30 Days',
      },
    },
  ];

  return revenueFields;
};

const financialFields = [
  {
    titleRow: 'Highlights',
  },
  {
    left: {
      label: 'Total Income',
      name: 'total_income',
      format: currencyFormat,
    },
    right: {
      label: 'Twelve Outstanding debt',
      name: 'twelve_outstanding_debt',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: '7 days Net Sales',
      name: 'avg_daily_net_last_seven_days',
      format: currencyFormat,
    },
    right: {
      label: 'Net ARPU',
      name: 'netArpu',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: 'Burn',
      name: 'gross_burn',
      format: currencyFormat,
      last: 'Gross',
    },
    right: {
      label: 'Net sales to runoff',
      name: 'net_sales_to_runoff',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: '',
      name: 'net_burn',
      format: currencyFormat,
      last: 'Net',
    },
  },
  {
    titleRow: 'Accounting',
  },
  {
    left: {
      label: 'Gross Profit',
      name: 'gross_profit',
      format: currencyFormat,
    },
    right: {
      label: 'Number of Purchases',
      name: 'number_of_purchases',
      format: numberFormat,
    },
  },
  {
    left: {
      label: '',
      name: 'gross_profit_margin',
      format: percentageFormat,
      last: 'Margin',
    },
    right: {
      label: 'Purchased From Existing \n Inventory',
      name: 'purchased_from_existing_inventory',
      format: currencyFormat,
      isDoubleHeight: true,
    },
  },
  {
    left: {
      label: 'Net Profit',
      name: 'net_profit',
      format: currencyFormat,
    },
    right: {
      isEmpty: true,
    },
  },
  {
    left: {
      label: '',
      name: 'net_profit_margin',
      format: percentageFormat,
      last: 'Margin',
    },
    right: {
      label: 'Contribution margin',
      name: 'contribution_margin',
      format: percentageFormat,
    },
  },
  {
    titleRow: 'Assets',
  },
  {
    left: {
      label: 'Inventory',
      name: 'inventory',
      format: currencyFormat,
    },
    right: {
      label: 'Cash',
      name: 'cash',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: 'Equity',
      name: 'equity',
      format: currencyFormat,
    },
    right: {
      label: 'Total Assets',
      name: 'total_assets',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: 'Inventory at MSRP',
      name: 'inventory_at_msrp',
      format: currencyFormat,
    },
    right: {
      label: 'Total liabilities to Assets',
      name: 'total_liabilities_to_assets',
      format: percentageFormat,
    },
  },
  {
    titleRow: 'Outstanding Debt',
  },
  {
    left: {
      label: 'Debt',
      name: 'debt',
      format: currencyFormat,
    },
    right: {
      label: 'Liability',
      name: 'liability',
      format: currencyFormat,
    },
  },
  {
    titleRow: 'Debt',
  },
  {
    left: {
      label: 'Debt to Liquid Assets',
      name: 'debt_to_liquid_assets_total',
      format: percentageFormat,
      last: 'Total',
    },
    right: {
      label: 'Debt to Cash',
      name: 'debt_to_cash_total',
      format: percentageFormat,
      last: 'Total',
    },
  },
  {
    left: {
      label: '',
      name: 'debt_to_liquid_assets_twelve',
      format: percentageFormat,
      last: 'Twelve',
    },
    right: {
      label: '',
      name: 'debt_to_cash_twelve',
      format: percentageFormat,
      last: 'Twelve',
    },
  },
  {
    left: {
      label: 'Debt to revenue run rate',
      name: 'debt_to_revenue_run_rate_total',
      format: percentageFormat,
      last: 'Total',
    },
    right: {
      label: 'Total Liabilities To Equity',
      name: 'total_liability_to_equity',
      format: percentageFormat,
    },
  },
  {
    titleRow: 'Expenses',
  },
  {
    left: {
      label: 'Total OPEX',
      name: 'total_opex',
      format: currencyFormat,
    },
    right: {
      label: 'COGS',
      name: 'cogs',
      format: currencyFormat,
    },
  },
  {
    left: {
      label: 'UA',
      name: 'ua',
      format: currencyFormat,
    },
  },
  {
    titleRow: 'Burn',
  },
  {
    left: {
      label: 'Net Sales to Runway',
      name: 'net_sales_to_runway',
      format: currencyFormat,
    },
    right: {
      label: 'Months to Runway',
      name: 'month_to_runway',
      format: numberFormat,
      last: 'Months',
    },
  },
  {
    left: {
      label: 'Net Sales to Runoff',
      name: 'net_sales_to_runoff',
      format: currencyFormat,
    },
    right: {
      label: 'Months to Runoff',
      name: 'months_to_runoff_without_customers',
      format: numberFormat,
      last: 'Without Customers',
    },
  },
  {
    right: {
      label: '',
      name: 'months_to_runoff_with_customers',
      format: numberFormat,
      last: 'With Customers',
    },
  },
];

const Cells = ({
  obj,
  label,
  name,
  format,
  last,
  isDoubleHeight,
  isLeft,
  isEmpty,
  isLabelBold,
}) => {
  if (isEmpty) {
    return <></>;
  }

  let attr = {};
  if (isDoubleHeight) {
    attr = { rowSpan: 2 };
  }

  const item = _get(obj, name);

  let value = '';
  switch (typeof item) {
    case 'boolean':
      value = item;
      break;
    case 'number':
      value = item;
      break;
    default:
      value = item ? item.value || 0 : null;
      break;
  }

  return (
    <>
      <Td
        py={'2px'}
        whiteSpace={'pre-line'}
        pl={isLeft ? '10px' : 0}
        fontWeight={isLabelBold ? '800' : ''}
        {...attr}
      >
        {label}
      </Td>
      <Td py={'2px'} px={'10px'} fontWeight={500} {...attr}>
        {value === null ? '' : format ? format(value) : value}
      </Td>
      <Td py={'2px'} color={'secondaryGray.600'} {...attr}>
        {typeof last === 'function' ? last(obj) : last}
      </Td>
    </>
  );
};

const SectionRows = ({ title, fields, obj }) => {
  if (!obj) {
    return null;
  }

  return (
    <>
      <Tr>
        <Td colSpan={7}>
          <Box pb={'20px'} pt={'25px'}>
            <Heading variant={'tearsheetSection'}>{title}</Heading>
          </Box>
        </Td>
      </Tr>
      {fields.map((field, index) => {
        if (field.titleRow) {
          return (
            <Tr key={index}>
              <Td
                colSpan={7}
                borderBottom={'1px solid #000'}
                p={index === 0 ? '0 0 5px 10px' : '20px 0 5px 10px'}
                fontWeight={800}
              >
                {field.titleRow}
              </Td>
            </Tr>
          );
        }
        return (
          <Tr key={index}>
            <Cells {...field.left} obj={obj} isLeft />
            <Td minW={75}></Td>
            <Cells {...field.right} obj={obj} />
          </Tr>
        );
      })}
    </>
  );
};

const ScoreCircle = ({ scorePct, scoreLetter }) => {
  const arcColor = useColorModeValue('#4318FF', '#7551FF');
  const arcBackgroundColor = useColorModeValue(
    '#F4F7FE',
    'rgba(255,255,255,0.1)'
  );

  if (!scorePct || !scoreLetter) {
    return null;
  }

  return (
    <Flex
      direction="column"
      align="center"
      alignSelf="center"
      textAlign="center"
      position="relative"
    >
      <CircularSlider
        startAngle={45}
        endAngle={315}
        handleSize={6}
        minValue={0}
        maxValue={1}
        size={200}
        arcColor={arcColor}
        arcBackgroundColor={arcBackgroundColor}
        handle1={{
          value: scorePct,
        }}
      />
      <Text fontSize="34px" fontWeight="bold" position="absolute" top="33%">
        {scoreLetter}
      </Text>
      <Card
        w={200}
        borderRadius="12px"
        p="10px 30px"
        flexDirection="row"
        mt="-60px"
      >
        <Text fontSize="lg" fontWeight="bold">
          F
        </Text>
        <Text ms="auto" fontSize="lg" fontWeight="bold">
          A
        </Text>
      </Card>
    </Flex>
  );
};

const Tearsheet = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    tearsheet: null,
    tearsheetDate: null,
    salesSettings: null,
  });
  const [isConfidentialMode, setIsConfidentialMode] = useState(true);
  const { customerId, tearsheetId } = useParams();
  const toast = useToast();

  const { tearsheet, tearsheetDate, salesSettings } = data;

  const isRevenue = salesSettings?.moneyType === MONEY_TYPE.REVENUE;

  useEffect(() => {
    getTearsheet();
  }, []);

  const getTearsheet = async () => {
    setLoading(true);
    try {
      const response = await getTearsheetApi({ customerId, tearsheetId });

      setData({
        tearsheet: response.tearsheet,
        tearsheetDate: moment(response.date),
        salesSettings: response.salesSettings,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to load tearsheet',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  const getPdfTargetElement = () => document.getElementById('box-pdf');

  return (
    <Box minW={854} maxW={'max'} fontSize={14} mt={-10}>
      <Flex justifyContent={'space-between'}>
        <BackLink
          text={'Back to tearsheet table'}
          path={`/customers/${customerId}/performance/tearsheet`}
        />
        <Box textAlign={'right'} mr={3}>
          <Flex align={'center'} gap={2}>
            <FormLabel m={0} htmlFor="isChecked">
              Confidential Mode
            </FormLabel>
            <Switch
              id="isChecked"
              isChecked={isConfidentialMode}
              onChange={(event) => {
                setIsConfidentialMode(event.target.checked);
              }}
            />
          </Flex>
          <Button
            variant={'outline'}
            mt={3}
            onClick={() =>
              generatePDF(getPdfTargetElement, {
                filename: `${tearsheet.business_info.legal_name.value.toLowerCase()}_${moment(
                  tearsheetDate
                ).format('YYYY_DD_MM')}.pdf`,
                page: {
                  margin: Margin.SMALL,
                },
              })
            }
          >
            Download PDF
          </Button>
        </Box>
      </Flex>
      <Card mt={'100px'}>
        <Box id={'box-pdf'}>
          <Heading
            w={'full'}
            variant={'sectionHeader'}
            borderColor={'twelve.green.300'}
          >
            Tearsheet
          </Heading>
          <Text mt={2}>{moment(tearsheetDate).format('MMMM DD, YYYY')}</Text>
          <Flex mt={'30px'} gap={5}>
            <Box w={'50%'}>
              <Heading variant={'tearsheetSection'}>Company Info</Heading>
              <Table
                variant={'noPadding'}
                mx={'10px'}
                mt={'20px'}
                sx={{ borderCollapse: 'separate', borderSpacing: '2px' }}
                letterSpacing={0}
              >
                <Tbody>
                  {businessInfoFields.map((field) => (
                    <Tr key={field.name}>
                      <Td w={165}>{field.label}</Td>
                      <Td fontWeight={500}>
                        {field.isConfidential && isConfidentialMode
                          ? '[Confidential]'
                          : field.format
                          ? field.format(
                              tearsheet.business_info[field.name]?.value
                            )
                          : tearsheet.business_info[field.name]?.value}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Box w={'50%'}>
              <Heading variant={'tearsheetSection'}>Score</Heading>
              <ScoreCircle
                scorePct={tearsheet?.score?.company_normalized}
                scoreLetter={tearsheet?.score?.company_rank}
              />
            </Box>
          </Flex>
          <Box mt={'30px'}>
            <MaxExposureSection data={tearsheet.score.max_exposure} />
          </Box>
          <Box mt={'10px'}>
            <Table variant={'noPadding'} letterSpacing={0}>
              <Tbody>
                <SectionRows
                  title={
                    <Text>
                      Company Score <CompanyScoreDetails scoreData={tearsheet.score} />
                    </Text>
                  }
                  fields={scoreFields}
                  obj={tearsheet.score}
                />
                <SectionRows
                  title={'Deal'}
                  fields={dealFields}
                  obj={tearsheet.deal_info}
                />
                <SectionRows
                  title={'Marketing'}
                  fields={getMarketingFields(tearsheetDate)}
                  obj={tearsheet.marketing}
                />
                <SectionRows
                  title={'Sales'}
                  fields={
                    isRevenue
                      ? getRevenueFields(tearsheetDate)
                      : getCashFlowFields(tearsheetDate)
                  }
                  obj={tearsheet.sales}
                />
                <SectionRows
                  title={'Financial'}
                  fields={financialFields}
                  obj={tearsheet.finance}
                />
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

Tearsheet.propTypes = {};

export default Tearsheet;
