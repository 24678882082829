import { Box } from '@chakra-ui/react';
import { TwDatePicker } from 'components/DatePicker';
import WhiteBox from 'components/WhiteBox';
import { useState } from 'react';

function Test() {
  const [value, setValue] = useState('2024-06-01');

  const onChange = (value) => {
    setValue(value);
  };

  return (
    <WhiteBox maxW={1300}>

      <Box w={200}>
      <TwDatePicker value={value} onChange={onChange} />
      </Box>
    </WhiteBox>
  );
}

export default Test;
