import { IconButton, Image } from '@chakra-ui/react';
import chevronRightSvg from 'assets/svg/chevron-right.svg';
import PropTypes from 'prop-types';

const DrawerCloseButton = ({onClose}) => {
  return (
    <IconButton
      boxSize={10}
      borderRadius={'12px'}
      p={'2px'}
      ml={'16px'}
      mt={'20px'}
      _hover={{
        bg: 'twelve.green.200',
      }}
      onClick={onClose}
    >
      <Image src={chevronRightSvg}></Image>
    </IconButton>
  );
};

DrawerCloseButton.propTypes = {
    onClose: PropTypes.func,
};

export default DrawerCloseButton;
