
import { getTwelveBankAccountsApi } from 'api/dealApi';
import { useEffect, useState } from 'react';

export function useTwelveBankAccounts() {
  const [bankAccountOptions, setBankAccountOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        setLoading(true);
        const bankAccounts = await getTwelveBankAccountsApi();
        const options = bankAccounts.map((bankAccount) => ({
          label: `${bankAccount.name} - ${bankAccount.accountNumber}`,
          value: bankAccount.id,
        }));
        setBankAccountOptions(options);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBankAccounts();
  }, []);

  return { bankAccountOptions, isTwelveBankAccountsLoading: loading};
}
