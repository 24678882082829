import { getCompanyRankListApi } from 'api/adminApi';
import { useEffect, useState } from 'react';

export function useCompanyRankOptions() {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCompanyRankOptions = async () => {
      try {
        setLoading(true);
        const companyRankOptions = await getCompanyRankListApi();
        const options = companyRankOptions.sort().map((companyRankOption) => ({
          label: companyRankOption,
          value: companyRankOption,
        }));
        setOptions(options);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyRankOptions();
  }, []);

  return { companyRankOptions: options, isCompanyRankOptionsLoading: loading };
}