import { Box, Center, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import { Number, numberFormat, percentageFormat } from 'components/Number';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import SalesSectionCard from './components/SalesSectionCard';

const CohortHistogramChart = ({ data, withPrediction }) => {
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (data) {
      const _chartData = getChartData();
      setChartData(_chartData);
    }
  }, [data, withPrediction]);

  const getChartOptions = ({ dates, xAxisCategories, maxTotal }) => {
    const yAxisColumn = {
      min: 0,
      max: (max) => {
        return maxTotal;
      },
      labels: {
        formatter: (value) => {
          return numberFormat(value);
        },
      },
    };

    let yaxis = [
      {
        name: 'Roas 100',
        title: {
          text: 'Months to ROAS 100',
          style: {
            color: '#b0bbd5',
          },
        },
        ...yAxisColumn,
      },
      {
        name: 'ROAS 100',
        ...yAxisColumn,
        show: false,
      },
    ];

    if (withPrediction) {
      yaxis.push({
        name: 'ROAS 100',
        ...yAxisColumn,
        show: false,
      });
      yaxis.push({
        name: 'ROAS 100',
        ...yAxisColumn,
        show: false,
      });
    }

    yaxis.push({
      name: 'ROAS 100 Pct',
      opposite: true,
      title: {
        text: '% ROAS 100',
        style: {
          color: '#b0bbd5',
        },
      },
      labels: {
        formatter: (value) => {
          return percentageFormat(value);
        },
      },
    });

    return {
      chart: {
        fontFamily: 'DM Sans',
        stacked: true,
        toolbar: {
          offsetX: -25,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        itemMargin: {
          horizontal: 15,
        },
        markers: {
          offsetX: -6,
          width: 10,
          height: 10,
        },
        fontSize: '14px',
        fontWeight: 500,
      },
      stroke: {
        width: 0,
      },
      markers: {
        size: 8,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        title: {
          text: 'Cohort Age',
          offsetY: -10,
          style: {
            color: '#b0bbd5',
          },
        },
        categories: xAxisCategories,
        tooltip: { enabled: false },
      },
      yaxis,
      dates,
    };
  };

  const getChartData = () => {
    const _data = data
      .filter((row) => row.period < 25)
      .map((row) => {
        return {
          period: row.period,
          roas100: row.actualOverPercentTarget || 0,
          nonRoas100: row.actualUnderPercentTarget || 0,
          roas100Predicted: row.predictedOverPercentTarget || 0,
          nonRoas100Predicted: row.predictedUnderPercentTarget || 0,
        };
      });

    let roas100Data = [];
    let nonRoas100Data = [];
    let roas100PctData = [];
    let roas100PredictedData = [];
    let nonRoas100PredictedData = [];
    let dates = [];
    let xAxisCategories = [];
    let maxTotalValue = 0;

    _data.forEach((row, index) => {
      xAxisCategories.push(row.period);
      roas100Data.push(row.roas100);
      nonRoas100Data.push(row.nonRoas100);
      roas100PredictedData.push(row.roas100Predicted);
      nonRoas100PredictedData.push(row.nonRoas100Predicted);
      dates.push(row.date);

      let total = row.roas100 + row.nonRoas100;
      if (withPrediction) {
        total += row.roas100Predicted + row.nonRoas100Predicted;
      }

      if (total > maxTotalValue) {
        maxTotalValue = total;
      }
    });

    roas100Data.forEach((value, index) => {
      let roas100Total = roas100Data[index];
      let nonRoas100Total = nonRoas100Data[index];

      if (withPrediction) {
        roas100Total += roas100PredictedData[index];
        nonRoas100Total += nonRoas100PredictedData[index];
      }

      roas100PctData.push(roas100Total / (roas100Total + nonRoas100Total));
    });

    const roas100Series = {
      name: 'ROAS 100',
      data: roas100Data,
      type: 'column',
      color: '#84D9FD',
    };

    const nonRoas100Series = {
      name: 'Non ROAS 100',
      data: nonRoas100Data,
      type: 'column',
      color: '#775FFC',
    };

    const roas100PredictedSeries = {
      name: 'ROAS 100 Predicted',
      data: roas100PredictedData,
      type: 'column',
      color: '#8F9BBA',
    };

    const nonRoas100PredictedSeries = {
      name: 'Non ROAS 100 Predicted',
      data: nonRoas100PredictedData,
      type: 'column',
      color: '#1B2559',
    };

    const roas100PctSeries = {
      name: 'ROAS 100 Pct',
      data: roas100PctData,
      type: 'line',
      color: '#C9FE37',
    };

    let series = [];

    if (withPrediction) {
      series = [
        roas100Series,
        roas100PredictedSeries,
        nonRoas100Series,
        nonRoas100PredictedSeries,
        roas100PctSeries,
      ];
    } else {
      series = [roas100Series, nonRoas100Series, roas100PctSeries];
    }

    return {
      series,
      options: getChartOptions({
        dates,
        xAxisCategories,
        maxTotal: maxTotalValue,
      }),
    };
  };

  if (!chartData) {
    return null;
  }

  return (
    <Box h={300} mt={50}>
      <Chart
        type={'line'}
        series={chartData.series}
        options={chartData.options}
        width={'100%'}
        height={'100%'}
      />
    </Box>
  );
};

const CohortSummary = ({ data }) => {

  const summaryData = {
    total : data?.total,
    totalRoas100 : data?.totalOver,
    totalNonRoas100 : data?.totalUnder,
    avgRoas100 : data?.avgOver,
    avgNonRoas100 : data?.avgUnder,
    stdDevRoas100 : data?.stdDevOver,
    stdDevNonRoas100 : data?.stdDevUnder,
  }

  return (
    <Table
      variant={'noPadding'}
      w={'max'}
      __css={{
        'td, th': {
          padding: '10px',
          border: '1px solid',
        },
      }}
    >
      <Tbody>
        <Tr>
          <Td>Total # Of Cohorts</Td>
          <Td>
            <Number value={summaryData?.total} />
          </Td>
          <Td></Td>
        </Tr>
        <Tr fontWeight={800}>
          <Td></Td>
          <Td>ROAS 100</Td>
          <Td>Non ROAS 100</Td>
        </Tr>
        <Tr>
          <Td># Of Cohorts</Td>
          <Td>
            <Number value={summaryData?.totalRoas100} />
          </Td>
          <Td>
            <Number value={summaryData?.totalNonRoas100} />
          </Td>
        </Tr>
        <Tr>
          <Td>Avg. Months</Td>
          <Td>
            {summaryData?.avgRoas100 ? <Number value={summaryData?.avgRoas100} /> : 'N/A' }
          </Td>
          <Td>
            {summaryData?.avgNonRoas100 ? <Number value={summaryData?.avgNonRoas100} /> : 'N/A' }
          </Td>
        </Tr>
        <Tr>
          <Td>Std. Deviation</Td>
          <Td>
            {summaryData?.stdDevRoas100 ? (
              <Number value={summaryData?.stdDevRoas100} />
            ) : (
              'N/A'
            )}
          </Td>
          <Td>
            {summaryData?.stdDevNonRoas100 ? (
              <Number value={summaryData?.stdDevNonRoas100} />
            ) : (
              'N/A'
            )}
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};



const CohortHistogram = ({ data, withPrediction }) => {

  return (
    <SalesSectionCard title={'Cohort Histogram'}>
      <CohortHistogramChart data={data.histogram} withPrediction={withPrediction} />
      <Center mt={8}>
        <CohortSummary data={data.cohortsSummary} />
      </Center>
    </SalesSectionCard>
  );
};

CohortHistogram.propTypes = {
  withPrediction: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      period: PropTypes.number,
      actualOverPercentTarget: PropTypes.number,
      actualUnderPercentTarget: PropTypes.number,
      predictedOverPercentTarget: PropTypes.number,
      predictedUnderPercentTarget: PropTypes.number,
    })
  ),
};

export default CohortHistogram;
