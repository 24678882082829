import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  getAmortizationApi,
  getCustomerInstallmentsOverviewApi,
} from 'api/dealApi';
import DataTable from 'components/DataTable';
import { Currency } from 'components/Number';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Card from 'components/card/Card';
import BackLink from 'components/links/BackLink';
import { REVENUE_RECOGNITION_TYPE } from 'constants/dealConstants';
import { CockpitCustomerContext } from 'context/CockpitCustomerContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AmortizationTable from './AmortizationTable';
import InstallmentStatusTag from './InstallmentStatusTag';
import OperationsKpis from './OperationsKpis';
import RevenueRecognitionSelect from './RevenueRecognitionSelect';

const columnHelper = createColumnHelper();

const getCurrencyCellValue = (info) => {
  const value = info.getValue();
  return value === null ? (
    'N/A'
  ) : (
    <Currency value={value} maximumFractionDigits={2} />
  );
};

const columns = [
  columnHelper.accessor('transferDate', {
    cell: (info) =>
      info.getValue() === null ? (
        'N/A'
      ) : (
        <Text whiteSpace={'nowrap'}>
          {moment(info.getValue()).format('MMM DD, YYYY')}
        </Text>
      ),
    header: 'Transfer Date',
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      const status = info.getValue();
      if (!status) {
        return 'N/A';
      }

      return <InstallmentStatusTag status={status} />;
    },
    header: 'Status',
  }),
  columnHelper.accessor('purchasePrice', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Purchase Price',
  }),
  columnHelper.accessor('totalSold', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Amount Sold',
  }),
  columnHelper.accessor('amountDueOutstanding', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Amount Due Outstanding',
  }),
  columnHelper.accessor('principalDueOutstanding', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Principal Due Outstanding',
  }),
  columnHelper.accessor('feeDueOutstanding', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Fee Due Outstanding',
  }),
  columnHelper.accessor('principalRepaid', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Principal Repaid',
  }),
  columnHelper.accessor('feeRepaid', {
    cell: (info) => getCurrencyCellValue(info),
    header: 'Fee Repaid',
  }),
  columnHelper.accessor('lastRepaymentAtMillis', {
    cell: (info) =>
      info.getValue() === null ? (
        'N/A'
      ) : (
        <Text whiteSpace={'nowrap'}>
          {moment(info.getValue()).format('MMM DD, YYYY')}
        </Text>
      ),
    header: 'Last Repayment Date',
  }),
  columnHelper.accessor('createdAtMillis', {
    cell: (info) =>
      info.getValue() === null ? (
        'N/A'
      ) : (
        <Text whiteSpace={'nowrap'}>
          {moment(info.getValue()).format('MMM DD, YYYY')}
        </Text>
      ),
    header: 'Date',
  }),
];

const defaultSorting = [{ id: 'createdAtMillis', desc: true }];

const OperationsCustomerOverview = () => {
  const [loading, setLoading] = useState(true);
  const [loadingAmortization, setLoadingAmortization] = useState(true);
  const [overviewData, setOverviewData] = useState(null);
  const [amortizationData, setAmortizationData] = useState(null);
  const [revenueRecognition, setRevenueRecognition] = useState(
    REVENUE_RECOGNITION_TYPE.INTEREST_FIRST
  );

  const toast = useToast();
  const navigate = useNavigate();

  const { customer, isCustomerLoading } = useContext(CockpitCustomerContext);

  const customerId = customer?.id;

  useEffect(() => {
    if (customer) {
      loadAmortizationData();
    }
  }, []);

  useEffect(() => {
    if (customer) {
      loadCustomerInstallmentsOverviewData();
    }
  }, [revenueRecognition]);

  const loadCustomerInstallmentsOverviewData = async () => {
    setLoading(true);
    try {
      const _data = await getCustomerInstallmentsOverviewApi({
        customerId,
        revenueRecognition,
      });
      setOverviewData(_data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch installments data',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const loadAmortizationData = async () => {
    try {
      setLoadingAmortization(true);
      const _data = await getAmortizationApi({ customerId });
      setAmortizationData(_data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch amortization data',
        status: 'error',
      });
      throw error;
    } finally {
      setLoadingAmortization(false);
    }
  };
  if (loading && !overviewData) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box maxW={'container.xl'} mt={-10}>
      <OverlaySpinnerWrapper show={loading || isCustomerLoading}>
        <BackLink text={'Back to customer list'} path={'/operations'} />
        <Box mt={100}>
          <Flex justifyContent={'space-between'} align={'start'}>
            <OperationsKpis {...overviewData.kpis} />
            <Flex alignItems={'center'}>
              <RevenueRecognitionSelect
                value={revenueRecognition}
                onChange={setRevenueRecognition}
              />
            </Flex>
          </Flex>

          <Card
            mt={'40px'}
            w={loadingAmortization ? 'full' : 'max'}
            maxW={'full'}
            minH={loadingAmortization ? 400 : 'auto'}
            display={
              !loadingAmortization && amortizationData?.length === 0
                ? 'none'
                : 'block'
            }
          >
            {loadingAmortization && (
              <AbsoluteCenter>
                {' '}
                <Spinner />
              </AbsoluteCenter>
            )}
            {amortizationData?.length > 0 && (
              <>
                <Heading size="md">Amortization Table</Heading>
                <Box mt={5} mx={-5} overflow={'auto'}>
                  <AmortizationTable data={amortizationData} />
                </Box>
              </>
            )}
          </Card>
          <Card mt={'40px'}>
            <Flex justify={'end'}>
              <Flex>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    w={220}
                    variant={'outline'}
                    onClick={() => {
                      navigate(`/operations/${customerId}/installment/new`);
                    }}
                  >
                    CREATE INSTALLMENT
                  </Button>
                  <Button
                    w={220}
                    variant={'brand'}
                    onClick={() => {
                      navigate(`/operations/${customerId}/repayment`);
                    }}
                  >
                    REPAYMENT
                  </Button>
                </Stack>
              </Flex>
            </Flex>
            <Box minH={300} mx={-5} mt={10}>
              {overviewData?.installments?.length > 0 && (
                <DataTable
                  columns={columns}
                  data={overviewData.installments}
                  hasPagination={true}
                  defaultSorting={defaultSorting}
                  onRowClick={(row) => {
                    navigate(`/operations/${customerId}/installment/${row.id}`);
                  }}
                  rowHeight={'100px'}
                />
              )}
            </Box>
          </Card>
        </Box>
      </OverlaySpinnerWrapper>
    </Box>
  );
};

OperationsCustomerOverview.propTypes = {};

export default OperationsCustomerOverview;
