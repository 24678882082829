import { Box } from '@chakra-ui/react';
import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from 'components/Number';
import KpiCollection from 'components/kpi/KpiCollection';
import KpiExpanded from 'components/kpi/KpiExpanded';
import KpiSectionHeader from 'components/kpi/KpiSectionHeader';
import { MONEY_TYPE } from 'constants/salesConstants';
import { KpiCardExpandContext } from 'context/KpiCardExpandContext';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import HighlightsCharts from './components/HighlightsCharts';
import SalesSectionCard from './components/SalesSectionCard';
import { parseKpiData } from './helpers/kpiParser';

const KPI = {
  CURRENT_MRR: 'currentMrr',
  CURRENT_ARR: 'currentArr',
  RATE_OF_CHANGE_30D: 'rateOfChange30d',
  RATE_OF_CHANGE_12M: 'rateOfChange12m',
  LTV_3_COHORTS: 'ltv3Cohorts',
  LTV_6_COHORTS: 'ltv6Cohorts',
  ROAS_100: 'roas100',
  ROAS_100_DURATION: 'roas100Duration',
  TOTAL_USERS: 'totalUsers',
  CAC: 'cac',
  CURRENT_ARPU: 'currentArpu',
};

const KPI_PARSE_CONFIG = {
  [KPI.CURRENT_MRR]: {
    [MONEY_TYPE.REVENUE]: 'last_1_day',
  },
  [KPI.CURRENT_ARR]: {
    [MONEY_TYPE.REVENUE]: 'annual_run_rate_1_day',
  },
  [KPI.RATE_OF_CHANGE_30D]: {
    [MONEY_TYPE.REVENUE]: 'change_in_mrr_last_30_days_percentage',
    [MONEY_TYPE.CASHFLOW]: 'change_last_30_days',
  },
  [KPI.RATE_OF_CHANGE_12M]: {
    [MONEY_TYPE.REVENUE]: 'change_in_mrr_last_12_months_percentage',
    [MONEY_TYPE.CASHFLOW]: 'change_last_12_months',
  },
  [KPI.LTV_3_COHORTS]: {
    cohortsProp: 'sum_ltv.arpu.ltv_3_months',
  },
  [KPI.LTV_6_COHORTS]: {
    cohortsProp: 'sum_ltv.arpu.ltv_6_months',
  },
  [KPI.ROAS_100]: {
    cohortsProp: 'roas_100.roas.arpu.avg_3_months',
  },
  [KPI.ROAS_100_DURATION]: {
    cohortsProp: 'duration.percent_100.arpu.ltv_3_months',
  },
  [KPI.TOTAL_USERS]: {
    [MONEY_TYPE.REVENUE]: 'number_of_users',
    [MONEY_TYPE.CASHFLOW]: 'total_last_30_days_users',
    isUsers: true,
  },
  [KPI.CURRENT_ARPU]: {
    cohortsProp: 'current.arpu.avg_3_months',
  },
};

const KPI_CARD = {
  CURRENT_MRR: 'currentMrr',
  CURRENT_ARR: 'currentArr',
  RATE_OF_CHANGE: 'rateOfChange',
  LTV: 'ltv',
  ROAS_100: 'roas100',
  ROAS_100_DURATION: 'roas100Duration',
  TOTAL_USERS: 'totalUsers',
  CAC: 'cac',
  CURRENT_ARPU: 'currentArpu',
};

const REVENUE_KPI_CARDS = [
  [KPI_CARD.CURRENT_MRR, KPI_CARD.CURRENT_ARR, KPI_CARD.RATE_OF_CHANGE, KPI_CARD.CURRENT_ARPU],
  [
    KPI_CARD.LTV,
    KPI_CARD.ROAS_100,
    KPI_CARD.ROAS_100_DURATION,
    KPI_CARD.TOTAL_USERS,
  ],
];

const CASHFLOW_KPI_CARDS = [
  [KPI_CARD.RATE_OF_CHANGE, KPI_CARD.CAC, KPI_CARD.CURRENT_ARPU,],
  [
    KPI_CARD.LTV,
    KPI_CARD.ROAS_100,
    KPI_CARD.ROAS_100_DURATION,
    KPI_CARD.TOTAL_USERS,
  ],
];

const Highlights = ({
  settings,
  moneyTypeKpis,
  cohortsKpis,
  compareCohortsKpis,
  recurringRevenue,
  isSubscription,
}) => {
  const [data, setData] = useState({
    kpiData: null,
    compareKpiData: null,
  });

  useEffect(() => {
    if (moneyTypeKpis && cohortsKpis) {
      const _kpiData = parseKpiData({
        config: KPI_PARSE_CONFIG,
        settings,
        cohortsKpis,
        moneyTypeKpis: moneyTypeKpis.actual,
      });

      const _compareKpiData = parseKpiData({
        config: KPI_PARSE_CONFIG,
        settings,
        cohortsKpis: compareCohortsKpis,
        moneyTypeKpis: moneyTypeKpis.compare,
      });

      setData({
        kpiData: _kpiData,
        compareKpiData: _compareKpiData,
      });
    }
  }, [moneyTypeKpis, cohortsKpis, settings]);

  const createKpiItems = () => {
    const { kpiData, compareKpiData } = data;
    if (!settings || !kpiData) {
      return [];
    }

    const kpiCardConfig =
      settings.moneyType === MONEY_TYPE.REVENUE
        ? REVENUE_KPI_CARDS
        : CASHFLOW_KPI_CARDS;

    const kpiCardMap = kpiCardConfig.reduce((acc, row) => {
      row.forEach((kpiCardKey) => {
        acc[kpiCardKey] = true;
      });
      return acc;
    }, {});

    let kpiConfig = {};
    if (kpiCardMap[KPI_CARD.CURRENT_MRR]) {
      kpiConfig[KPI_CARD.CURRENT_MRR] = {
        id: KPI_CARD.CURRENT_MRR,
        title: 'Current MRR',
        items: [
          {
            value: kpiData.currentMrr.total,
            compareValue: compareKpiData.currentMrr.total,
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData.currentMrr.annually}
                compareAnnually={compareKpiData.currentMrr.annually}
                monthly={kpiData.currentMrr.monthly}
                compareMonthly={compareKpiData.currentMrr.monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.CURRENT_ARR]) {
      kpiConfig[KPI_CARD.CURRENT_ARR] = {
        title: 'Current ARR',
        id: KPI_CARD.CURRENT_ARR,
        items: [
          {
            value: kpiData.currentArr.total,
            compareValue: compareKpiData.currentArr.total,
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData.currentArr.annually}
                compareAnnually={compareKpiData.currentArr.annually}
                monthly={kpiData.currentArr.monthly}
                compareMonthly={compareKpiData.currentArr.monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.RATE_OF_CHANGE]) {
      kpiConfig[KPI_CARD.RATE_OF_CHANGE] = {
        id: KPI_CARD.RATE_OF_CHANGE,
        title:
          settings.moneyType === MONEY_TYPE.REVENUE
            ? 'Revenue Growth'
            : 'Cashflow Growth',
        items: [
          {
            value: kpiData.rateOfChange30d.total,
            label: 'Last 30 Days',
            format: percentageFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={percentageFormat}
                annually={kpiData.rateOfChange30d.annually}
                monthly={kpiData.rateOfChange30d.monthly}
              />
            ),
          },
          {
            value: kpiData.rateOfChange12m.total,
            label: 'Last 12 Months',
            format: percentageFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={percentageFormat}
                annually={kpiData.rateOfChange12m.annually}
                monthly={kpiData.rateOfChange12m.monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.CAC]) {
      kpiConfig[KPI_CARD.CAC] = {
        id: KPI_CARD.CAC,
        title: 'CAC',
        items: [
          {
            value: cohortsKpis['cac_avg_3_months'],
            compareValue: compareCohortsKpis['cac_avg_3_months'],
            label: 'Last 3 Months',
            format: currencyFormat,
            getCustomCompareDirection: (value) => {
              return value > 0 ? -1 : value < 0 ? 1 : 0;
            },
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.LTV]) {
      kpiConfig[KPI_CARD.LTV] = {
        id: KPI_CARD.LTV,
        title: 'LTV 12 Months',
        items: [
          {
            value: kpiData.ltv3Cohorts.total,
            compareValue: compareKpiData.ltv3Cohorts.total,
            label: 'Last 3 Cohorts Avg.',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData.ltv3Cohorts.annually}
                compareAnnually={compareKpiData.ltv3Cohorts.annually}
                monthly={kpiData.ltv3Cohorts.monthly}
                compareMonthly={compareKpiData.ltv3Cohorts.monthly}
              />
            ),
          },
          {
            value: kpiData.ltv6Cohorts.total,
            compareValue: compareKpiData.ltv6Cohorts.total,
            label: 'Last 6 cohorts',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData.ltv6Cohorts.annually}
                compareAnnually={compareKpiData.ltv6Cohorts.annually}
                monthly={kpiData.ltv6Cohorts.monthly}
                compareMonthly={compareKpiData.ltv6Cohorts.monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.ROAS_100]) {
      kpiConfig[KPI_CARD.ROAS_100] = {
        id: KPI_CARD.ROAS_100,
        title: 'ROAS 100%',
        items: [
          {
            value: kpiData.roas100.total,
            compareValue: compareKpiData.roas100.total,
            label: 'Last 3 cohorts',
            format: numberFormat,
            getCustomCompareDirection: (value) => {
              return value > 0 ? -1 : value < 0 ? 1 : 0;
            },
            getCustomCompareValue: (value, compareValue) => {
              return value - compareValue;
            },
            getCustomCompareValueFormatted: (value) => {
              return numberFormat(value);
            },
            expandedElement: isSubscription && (
              <KpiExpanded
                format={numberFormat}
                annually={kpiData.roas100.annually}
                compareAnnually={compareKpiData.roas100.annually}
                monthly={kpiData.roas100.monthly}
                compareMonthly={compareKpiData.roas100.monthly}
                getCustomCompareDirection={(value) => {
                  return value > 0 ? -1 : value < 0 ? 1 : 0;
                }}
                getCustomCompareValue={(value, compareValue) => {
                  return value - compareValue;
                }}
                getCustomCompareValueFormatted={(value) => {
                  return numberFormat(value);
                }}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.ROAS_100_DURATION]) {
      kpiConfig[KPI_CARD.ROAS_100_DURATION] = {
        id: KPI_CARD.ROAS_100_DURATION,
        title: 'ROAS 100% Duration',
        items: [
          {
            value: kpiData[KPI.ROAS_100_DURATION].total,
            compareValue: compareKpiData[KPI.ROAS_100_DURATION].total,
            label: 'Months',
            format: (value) =>
              numberFormat(value, { maximumFractionDigits: 2 }),
            expandedElement: isSubscription && (
              <KpiExpanded
                format={(value) =>
                  numberFormat(value, { maximumFractionDigits: 2 })
                }
                annually={kpiData[KPI.ROAS_100_DURATION].annually}
                compareAnnually={compareKpiData[KPI.ROAS_100_DURATION].annually}
                monthly={kpiData[KPI.ROAS_100_DURATION].monthly}
                compareMonthly={compareKpiData[KPI.ROAS_100_DURATION].monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.TOTAL_USERS]) {
      kpiConfig[KPI_CARD.TOTAL_USERS] = {
        id: KPI_CARD.TOTAL_USERS,
        title: 'Total Paid Users',
        items: [
          {
            value: kpiData.totalUsers.total,
            compareValue: compareKpiData.totalUsers.total,
            label: 'Last 30 days',
            format: numberFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={numberFormat}
                annually={kpiData.totalUsers.annually}
                compareAnnually={compareKpiData.totalUsers.annually}
                monthly={kpiData.totalUsers.monthly}
                compareMonthly={compareKpiData.totalUsers.monthly}
              />
            ),
          },
        ],
      };
    }

    if (kpiCardMap[KPI_CARD.CURRENT_ARPU]) {
      kpiConfig[KPI_CARD.CURRENT_ARPU] = {
        id: KPI_CARD.CURRENT_ARPU,
        title: 'Current ARPU',
        row: 1,
        items: [
          {
            value: kpiData[KPI.CURRENT_ARPU].total,
            compareValue: compareKpiData[KPI.CURRENT_ARPU].total,
            label: 'Cohorts Last 3 Months',
            format: currencyFormat,
            expandedElement: isSubscription && (
              <KpiExpanded
                format={currencyFormat}
                annually={kpiData[KPI.CURRENT_ARPU].annually}
                compareAnnually={compareKpiData[KPI.CURRENT_ARPU].annually}
                monthly={kpiData[KPI.CURRENT_ARPU].monthly}
                compareMonthly={compareKpiData[KPI.CURRENT_ARPU].monthly}
              />
            ),
          },
        ],
      };
    }

    let kpiCards = [];
    kpiCardConfig.forEach((row, rowIndex) => {
      row.forEach((kpiCardKey) => {
        let kpiCard = kpiConfig[kpiCardKey];
        kpiCard.row = rowIndex + 1;
        kpiCards.push(kpiCard);
      });
    });

    return kpiCards;
  };

  const kpis = useMemo(() => createKpiItems(), [data]);

  return (
    <SalesSectionCard title={'Sales Highlights'}>
      <Box mt={10}>
        <HighlightsCharts
          settings={settings}
          recurringRevenue={recurringRevenue}
        />
      </Box>
      <Box mt={50}>
        <KpiSectionHeader
          cardIds={Object.keys(KPI_CARD).map((key) => KPI_CARD[key])}
          context={KpiCardExpandContext}
          hideCollapse={!isSubscription}
        />
        <KpiCollection kpis={kpis} />
      </Box>
    </SalesSectionCard>
  );
};

Highlights.propTypes = {
  settings: PropTypes.object,
  moneyTypeKpis: PropTypes.object,
  cohortsKpis: PropTypes.object,
  compareCohortsKpis: PropTypes.object,
  recurringRevenue: PropTypes.object,
  isSubscription: PropTypes.bool,
};

export default Highlights;
