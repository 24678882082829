import {
  Box,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Currency } from 'components/Number';
import { DEAL_TYPE } from 'constants/dealConstants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { transformAmortizationData } from 'transformers/amortizationTransformer';

const AmortizationTable = ({ data }) => {
  const transformedData = transformAmortizationData(data);

  const nextRepaymentDateIndex = transformedData.dates.findIndex((date) => {
    const today = moment();
    return (
      moment(date).isAfter(today) ||
      moment(date).format('YYYY-MM-DD') === today.format('YYYY-MM-DD')
    );
  });

  const nextRepaymentCellBgColor = 'twelve.green.200';

  return (
    <Box p={4}>
      <Table
        variant={'noPadding'}
        w={'max'}
        __css={{
          'td, th': {
            padding: '10px',
            border: '1px solid',
          },

          'td.borderRight, th.borderRight': {
            borderRight: '4px solid',
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Transfer Date</Th>
            <Th>Purchase Price</Th>
            <Th className={'borderRight'}>Amount Sold</Th>
            {transformedData.dates.map((date, index) => (
              <Th
                key={date}
                bg={
                  index === nextRepaymentDateIndex && nextRepaymentCellBgColor
                }
              >
                {moment(date).format('MM/DD/YY')}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {transformedData.data.map((row, index) => (
            <Tr key={index}>
              <Td>{moment(row.transferDate).format('MM/DD/YY')}</Td>
              <Td>
                <Currency value={row.purchasePrice} maximumFractionDigits={2} />
              </Td>
              <Td className={'borderRight'}>
                <Currency value={row.amountSold} maximumFractionDigits={2} />
              </Td>
              {row.amortization.map((item, index) => {
                if (!item) {
                  return (
                    <Td
                      bg={
                        index === nextRepaymentDateIndex &&
                        nextRepaymentCellBgColor
                      }
                      key={index}
                    >
                      -
                    </Td>
                  );
                }

                const dealType = row.dealType;

                const isMinimumAmountToPay =
                  item.amountToPay === null ||
                  item.minAmount === item.amountToPay;

                const isRevenueShareAmountToPay =
                  item.amountToPay &&
                  item.revenueShareAmount === item.amountToPay;

                return (
                  <Td
                    key={index}
                    bg={
                      index === nextRepaymentDateIndex &&
                      nextRepaymentCellBgColor
                    }
                  >
                    <SimpleGrid columns={2} gap={1}>
                      {dealType === DEAL_TYPE.FIXED && (
                        <Currency
                          value={item.minAmount}
                          maximumFractionDigits={2}
                        />
                      )}
                      {dealType === DEAL_TYPE.ROAS && (
                        <>
                          <Text fontWeight={isMinimumAmountToPay && 800}>
                            Minimum:{' '}
                          </Text>
                          <Box fontWeight={isMinimumAmountToPay && 800}>
                            <Currency
                              value={item.minAmount}
                              maximumFractionDigits={2}
                            />
                          </Box>
                          <Text fontWeight={isRevenueShareAmountToPay && 800}>
                            Revenue:{' '}
                          </Text>
                          {item.revenueShareAmount !== null ? (
                            <Box fontWeight={isRevenueShareAmountToPay && 800}>
                              <Currency
                                value={item.revenueShareAmount}
                                maximumFractionDigits={2}
                              />
                            </Box>
                          ) : (
                            <Text>N/A</Text>
                          )}
                          <Text>
                            Predicted:{' '}
                          </Text>
                          {item.revenueSharePredictedAmount !== null ? (
                            <Box>
                              <Currency
                                value={item.revenueSharePredictedAmount}
                                maximumFractionDigits={2}
                              />
                            </Box>
                          ) : (
                            <Text>N/A</Text>
                          )}
                        </>
                      )}
                    </SimpleGrid>
                  </Td>
                );
              })}
            </Tr>
          ))}
          <Tr fontWeight={800} m={10}>
            <Td>Total</Td>
            <Td>
              <Currency
                value={transformedData.totals.purchasePrice}
                maximumFractionDigits={2}
              />
            </Td>
            <Td className={'borderRight'}>
              <Currency
                value={transformedData.totals.amountSold}
                maximumFractionDigits={2}
              />
            </Td>
            {transformedData.totals.amortization.map((total, index) => (
              <Td
                key={index}
                bg={
                  index === nextRepaymentDateIndex && nextRepaymentCellBgColor
                }
              >
                <Currency value={total.amount} maximumFractionDigits={2} />
              </Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

AmortizationTable.propTypes = {
  data: PropTypes.array,
};

export default AmortizationTable;
