import {
  calcCashflowModel,
  getCashflowModelDefaultsSettingsApi,
} from 'api/cashflowModelApi';
import { getAmortizationApi } from 'api/dealApi';
import Card from 'components/card/Card';
import CustomerSelect from 'components/cockpit/CustomerSelect';
import FileInput from 'components/inputs/FileInput';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from 'components/NumberInput';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Decimal from 'decimal.js';
import { renderInputElement } from 'helpers/formHelper';
import { hasObjectAllValues, isObjectEmpty } from 'helpers/objectHelper';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import Papa from 'papaparse';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { transformAmortizationData } from 'transformers/amortizationTransformer';
import DetailsCsv from './DetailsCsv';
import ResultsTable from './ResultsTable';

import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Link,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react';
import { updateCashflowModelDefaultsSettingsApi } from 'api/cashflowModelApi';
import ResultsKpis from './ResultsKpis';

const CashflowModel = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [parseError, setParseError] = useState(null);
  const [results, setResults] = useState(null);
  const [cohortFile, setCohortFile] = useState(null);

  const { handleSubmit, reset, control, watch, setValue } = useForm();

  const formValues = watch();

  const fields = [
    {
      name: 'customerId',
      isCustomField: true,
      required: !formValues.roasCohorts,
      element: (
        <>
          <Text>Customer</Text>
          <Box w={200}>
            <CustomerSelect
              customerId={formValues.customerId}
              onChange={(customerId) => {
                setValue('customerId', customerId);
                setCohortFile(null);
                setValue('roasCohorts', null);
              }}
            />
          </Box>
        </>
      ),
    },
    {
      name: 'roasCohorts',
      isCustomField: true,
      required: !formValues.customerId,
      element: (
        <>
          <Text>ROAS Cohorts CSV</Text>
          <Flex gap={2} align={'center'}>
            <FileInput
              initialFile={cohortFile}
              allowedFileTypes={['text/csv']}
              onSelectFiles={(files) => {
                setValue('customerId', null);
                setCohortFile(files[0]);
                parseCohortsCsv(files[0]);
              }}
            />
            <Link
              href="csv/cashflow-model-example.csv"
              fontSize={'xs'}
              variant={'underline'}
            >
              Example File
            </Link>
            {parseError && <Text color={'red.500'}>{parseError}</Text>}
          </Flex>
        </>
      ),
    },
    {
      label: 'UA Spend',
      name: 'uaSpend',
      element: CurrencyNumberInput,
      required: true,
    },
    {
      label: 'Opex',
      name: 'opex',
      element: CurrencyNumberInput,
      required: true,
    },
    {
      label: 'Initial Cash',
      name: 'initialCash',
      element: CurrencyNumberInput,
      required: true,
    },
    {
      label: 'COGS',
      name: 'cogs',
      element: PercentageNumberInput,
      required: true,
    },
    {
      title: 'Funding Twelve',
    },
    {
      label: 'Funding from UA',
      name: 'twelveFundingFromUa',
      element: PercentageNumberInput,
      required: true,
    },
    {
      label: 'Number Of Funding',
      name: 'numberOfFunding',
      element: TwNumberInput,
      required: true,
    },
    {
      label: 'Number Of Repayments',
      name: 'numberOfRepayments',
      element: TwNumberInput,
      required: true,
    },
    {
      label: 'Fee',
      name: 'fee',
      element: PercentageNumberInput,
      required: true,
    },
    {
      label: 'Grace',
      name: 'grace',
      element: TwNumberInput,
      required: true,
    },
    {
      label: 'Revenue Share',
      name: 'revenueShare',
      element: PercentageNumberInput,
    },
  ];

  useEffect(() => {
    const values = fields.reduce((acc, field) => {
      if (field.name) {
        acc[field.name] = null;
      }
      return acc;
    }, {});
    reset(values);
  }, []);

  useEffect(() => {
    if (formValues.customerId) {
      loadCustomerDefaultValues(formValues.customerId);
    }
  }, [formValues.customerId]);

  const loadCustomerDefaultValues = async (customerId) => {
    try {
      setLoading(true);
      let defaultSettings = await getCashflowModelDefaultsSettingsApi(
        customerId
      );

      if (!defaultSettings) {
        defaultSettings = fields.reduce((acc, field) => {
          if (field.name && field.name !== 'customerId') {
            acc[field.name] = null;
          }
          return acc;
        }, {});
      }
      
      const newValues = { ...formValues, ...defaultSettings };
      reset(newValues);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to get default settings.',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const parseCohortsCsv = (file) => {
    const onParseComplete = (results) => {
      let result = [];

      for (let i = 1; i < results.data.length; i++) {
        const row = results.data[i];

        if (row.every((cell) => cell === '')) {
          continue;
        }

        const mDate = moment(row[0], 'MMM-YY');
        const users = Number(row[1].replace(/,/g, ''));
        const totalUA = Number(row[2].replace(/,/g, '').replace('$', ''));

        if (!mDate.isValid()) {
          setParseError(`Invalid date on row ${i}`);
          return;
        }

        if (isNaN(users)) {
          setParseError(`Invalid value for users on row ${i}`);
          return;
        }

        if (isNaN(totalUA)) {
          setParseError(`Invalid value for total UA on row ${i}`);
          return;
        }

        const roasValues = row.slice(3).map((value, j) => {
          const roas = Number(value.replace(/,/g, '').replace('%', ''));
          if (isNaN(roas)) {
            setParseError(
              `Invalid value for ROAS on row ${i}, column ${j + 3}`
            );
            return;
          }

          return Decimal.div(roas, 100).toNumber();
        });

        result.push({
          date: mDate.format('YYYY-MM-DD'),
          users,
          ua: totalUA,
          values: roasValues,
        });

        setValue('roasCohorts', result);
      }
    };

    setParseError(null);
    setValue('roasCohorts', null);
    Papa.parse(file, { complete: onParseComplete });
  };

  const transformAmortizationInfo = (amortizationData) => {
    let repayments = [];

    const amortizationTotals = amortizationData.totals.amortization;

    const maxDate = moment.max(
      amortizationTotals.map((item) => moment(item.date))
    );

    const minDate = moment.min(
      amortizationTotals.map((item) => moment(item.date))
    );

    //get the diff in months
    const diff = maxDate.diff(minDate, 'months');
    let dates = [];
    if (diff > 0) {
      dates = Array.from({ length: diff + 1 }).map((_, index) =>
        minDate.clone().add(index, 'months')
      );
    }

    dates.forEach((date, index) => {
      const repaymentsForDate = amortizationTotals.filter(
        (item) =>
          moment(item.date).isSame(date, 'month') &&
          moment(item.date).isSame(date, 'year')
      );

      const sum =
        repaymentsForDate.length > 0
          ? Decimal.sum(
              ...repaymentsForDate.map((item) => item.amount)
            ).toNumber()
          : 0;
      repayments.push({
        date: date.format('YYYY-MM-DD'),
        amount: sum,
      });
    });

    return {
      totalPurchasePrice:
        amortizationData.totals?.purchasePrice.toNumber() || 0,
      totalAmountSold: amortizationData.totals?.amountSold.toNumber() || 0,
      repayments,
    };
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let amortizationData;

    if (data.customerId) {
      try {
        const amortizationResult = await getAmortizationApi({
          customerId: data.customerId,
        });
        amortizationData = transformAmortizationData(amortizationResult);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to get amortization.',
          status: 'error',
        });
        throw error;
      }
    }

    try {
      if (amortizationData) {
        data.amortizationInfo = transformAmortizationInfo(amortizationData);
      }

      const _results = await calcCashflowModel(data);
      setResults(_results);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to calculate model.',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onUpdateDefaultSettings = async () => {
    if (!formValues.customerId) {
      return;
    }

    const customerId = formValues.customerId;
    const settings = {
      uaSpend: formValues.uaSpend,
      opex: formValues.opex,
      initialCash: formValues.initialCash,
      cogs: formValues.cogs,
      twelveFundingFromUa: formValues.twelveFundingFromUa,
      numberOfFunding: formValues.numberOfFunding,
      numberOfRepayments: formValues.numberOfRepayments,
      fee: formValues.fee,
      grace: formValues.grace,
      revenueShare: formValues.revenueShare,
    };

    try {
      setLoading(true);
      await updateCashflowModelDefaultsSettingsApi({ customerId, settings });
      toast({
        title: 'Success',
        description: 'Default settings updated.',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update default settings.',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  function isFormFilled() {
    const values = cloneDeep(formValues);

    const notRequiredFields = fields
      .filter((field) => !field.required)
      .map((field) => field.name);

    notRequiredFields.forEach((field) => {
      delete values[field];
    });

    return !isObjectEmpty(formValues) && hasObjectAllValues(values);
  }

  return (
    <Box minW={'container.lg'} maxW={'max'}>
      <OverlaySpinnerWrapper show={loading} isFixed={true}>
        <Card mt={10}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <Box w={'max'}>
                <SimpleGrid
                  gridTemplateColumns={'min-content min-content'}
                  whiteSpace={'nowrap'}
                  justifyContent={'space-between'}
                  spacingY={4}
                  spacingX={8}
                  alignItems={'center'}
                  fontSize={'sm'}
                >
                  {fields.map((item, index) => {
                    if (item.isHidden) {
                      return null;
                    }

                    if (item.isCustomField) {
                      return <Fragment key={index}>{item.element}</Fragment>;
                    }

                    if (item.title) {
                      return (
                        <Fragment key={index}>
                          <Text
                            key={index}
                            fontWeight={800}
                            fontSize={'15px'}
                            mt={4}
                          >
                            {item.title}
                          </Text>
                          <Box></Box>
                        </Fragment>
                      );
                    }
                    return (
                      <Fragment key={index}>
                        <>
                          <Text>{item.label}</Text>
                          <Box w={200}>
                            <Controller
                              control={control}
                              name={item.name}
                              render={renderInputElement({
                                item,
                                styleProps: { fontWeight: 800, w: 200 },
                                showPrefix: true,
                              })}
                            />
                          </Box>
                        </>
                      </Fragment>
                    );
                  })}
                </SimpleGrid>
              </Box>
              <Flex justify={'end'} gap={4}>
                {formValues.customerId && (
                  <Button
                    variant={'outline'}
                    onClick={onUpdateDefaultSettings}
                    isDisabled={!isFormFilled()}
                  >
                    Save as Default
                  </Button>
                )}
                <Button
                  variant={'brand'}
                  type={'submit'}
                  isDisabled={!isFormFilled()}
                >
                  Calculate
                </Button>
              </Flex>
            </FormControl>
          </form>

          {results && (
            <Box mt={10}>
              <Divider borderColor={'secondaryGray.900'} />
              <Box mt={10}>
                <Flex>
                  <DetailsCsv
                    cohortsRawData={results.cohorts}
                    roasVector={results.result_items.roas_100_avg_6}
                  />
                </Flex>
                <Box mt={5}>
                  <ResultsKpis results={results.result_items} />
                </Box>
                <Box mt={10}>
                  <ResultsTable
                    results={results.result_items}
                    showExistingRepayments={formValues.customerId}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Card>
      </OverlaySpinnerWrapper>
    </Box>
  );
};

export default CashflowModel;
