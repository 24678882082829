import { CopyIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Tooltip, useToast } from '@chakra-ui/react';
import SelectInput from 'components/inputs/SelectInput';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { COHORTS_CONFIG } from '../constants/cohortsConfig';
import CohortTable from './CohortTable';

const CohortWidget = ({ cohortsData, cohortConfigKey }) => {
  const toast = useToast();
  const { cohorts, key } = COHORTS_CONFIG[cohortConfigKey];
  const [selectedCohort, setSelectedCohort] = useState(cohorts[0]);

  const cohortTypeOptions = useMemo(() =>
    cohorts.map((item) => ({
      label: item.label,
      value: item.cohortType,
    }))
  );

  const cohortData = useMemo(() => {
    if (!cohortsData) {
      return null;
    }

    return cohortsData.rows.map((item) => {
      return {
        prefixColumns: item.prefixColumns,
        postfixColumns: item.postfixColumns[selectedCohort.cohortType],
        data: item.data[selectedCohort.cohortType],
      };
    });
  }, [cohortsData, selectedCohort]);

  const vectorData = useMemo(() => {
    if (!cohortsData) {
      return null;
    }

    const { vectors } = cohortsData;

    return vectors[selectedCohort.cohortType];
  });

  const cohortHeader = useMemo(() => {
    return cohortsData.header[selectedCohort.cohortType];
  }, [cohortsData, selectedCohort]);

  if (!cohorts) {
    return null;
  }

  const getTableCsvText = () => {
    const rowLength = cohortData[0].data.length;

    const divider = '\t';
    const th = cohortHeader.join(divider)

    const tdData = cohortData
      .map((row) => {
        let data = [];
        for (let i = 0; i < rowLength; i++) {
          const item = row.data[i];
          if (!item) {
            data.push('');
          } else {
            data.push(selectedCohort.formatCell(item.value));
          }
        }
        
        return [...row.prefixColumns, ...data, ...row.postfixColumns || []].join(
          divider
        );
      })
      .join('\n');

    let td = tdData;
    if (vectorData) {
      td += '\n';
      td += '\n';

      vectorData.forEach((vector) => {
        td += cohortData[0].prefixColumns
          .map(() => {
            return '';
          })
          .join(divider);
        td += `${vector.label}${divider}`;
        td += vector.data
          .map((value) => selectedCohort.formatCell(value))
          .join(divider);
        td += '\n';
      });
    }

    return `${th}\n${td}`;
  };

  const onCopy = () => {
    if (!navigator.clipboard) {
      return;
    }
    const text = getTableCsvText();
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: `Copied ${selectedCohort.label} cohort to clipboard`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  return (
    <Box>
      <Flex justifyContent={'space-between'} align={'center'}>
        <Flex align={'center'}>
          <Text fontWeight={800}>Cohort</Text>
          <Tooltip label={'Copy to clipboard'} placement={'right'}>
            <CopyIcon boxSize={5} ml={2} cursor={'pointer'} onClick={onCopy} />
          </Tooltip>
        </Flex>
        <Flex gap={4} justifyContent={'end'}>
          <SelectInput
            value={selectedCohort.cohortType}
            options={cohortTypeOptions}
            onChange={(value) => {
              setSelectedCohort(cohorts.find((c) => c.cohortType === value));
            }}
            variant={'white'}
          />
        </Flex>
      </Flex>
      <Box mt={4}>
        {cohortData && (
          <CohortTable
            cohortData={cohortData}
            formatCell={selectedCohort.formatCell}
            aggRows={vectorData}
            header={cohortHeader}
          />
        )}
      </Box>
    </Box>
  );
};

CohortWidget.propTypes = {
  cohortsData: PropTypes.object,
  cohortConfigKey: PropTypes.string,
};

export default CohortWidget;
