import { Flex, FormLabel } from '@chakra-ui/react';
import SelectInput from 'components/inputs/SelectInput';
import { REVENUE_RECOGNITION_TYPE_META } from 'constants/dealConstants';
import { generateSelectOptions } from 'helpers/inputHelper';
import PropTypes from 'prop-types';

const RevenueRecognitionSelect = ({ value, onChange }) => {
  return (
    <Flex alignItems={'center'}>
      <FormLabel m={0} mr={2}>
        Revenue Recognition:
      </FormLabel>
      <SelectInput
        size={'lg'}
        w={200}
        boxShadow={'box'}
        value={value}
        onChange={onChange}
        options={generateSelectOptions({
          metaObj: REVENUE_RECOGNITION_TYPE_META,
        })}
      />
    </Flex>
  );
};

RevenueRecognitionSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default RevenueRecognitionSelect;
