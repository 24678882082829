import { Box, Flex, Text } from '@chakra-ui/react';
import { numberFormat } from 'components/Number';
import ChartTooltip from 'components/charts/ChartTooltip';
import { SUBSCRIPTION_TYPE } from 'constants/salesConstants';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';

const subscriptionTypePropMap = {
  [SUBSCRIPTION_TYPE.ALL]: 'total',
  [SUBSCRIPTION_TYPE.MONTHLY]: 'mrr',
  [SUBSCRIPTION_TYPE.ANNUALLY]: 'arr',
};

const refundPropMap = {
  true: 'with_refunds',
  false: 'without_refunds',
};

const getCategoryLabel = (mDate, index) => {
  if (index === 0 || mDate.month() === 0) {
    return [mDate.format('MMM'), mDate.format('YYYY')];
  }

  return mDate.format('MMM');
};

const UsersCharts = ({ cohortsData, settings, isSubscription }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (cohortsData && settings) {
      const totalUsersChartData = generateTotalUsersData();
      setChartData(totalUsersChartData);
    }
  }, [cohortsData, settings]);

  const getChartOptions = ({ dates, xAxisCategories, maxTotal }) => {
    return {
      chart: {
        fontFamily: 'DM Sans',
        stacked: true,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const total = series[0][dataPointIndex] + series[1][dataPointIndex];
          const series1 = w.config.series[0];
          const series2 = w.config.series[1];
          const title = w.config.dates[dataPointIndex].format('MMM YYYY');

          const items = [
            { label: 'Total', value: numberFormat(total) },
            {
              label: series1.name,
              value: numberFormat(series1.data[dataPointIndex]),
              color: series1.color,
            },
            {
              label: series2.name,
              value: numberFormat(series2.data[dataPointIndex]),
              color: series2.color,
            },
          ];

          return renderToString(<ChartTooltip title={title} items={items} />);
        },
      },
      xaxis: {
        categories: xAxisCategories,
      },
      yaxis: {
        min: 0,
        max: (max) => {
          return maxTotal;
        },
        labels: {
          formatter: (value) => {
            if (value === null) {
              return null;
            }
            return numberFormat(value);
          },
        },
      },
      dates,
    };
  };

  const generateTotalUsersData = () => {
    let newUsersData = [];
    let returningUsersData = [];
    let monthlyPayingUsersData = [];
    let annuallyPayingUsersData = [];
    let xAxisCategories = [];
    let dates = [];

    cohortsData.rows.forEach((row, index) => {
      const { date, metrics } = row;

      const subscriptionTypeProp =
        subscriptionTypePropMap[settings.subscriptionType];
      const refundProp = refundPropMap[settings.withRefunds];

      const usersItem = metrics.users[subscriptionTypeProp];
      
      newUsersData.push(usersItem.first_period_users?.[refundProp] || 0);
      returningUsersData.push(usersItem.returning_users?.[refundProp] || 0);

      const monthlyPayingUsersItem =
        metrics.users[subscriptionTypePropMap[SUBSCRIPTION_TYPE.MONTHLY]];
      const annuallyPayingUsersItem =
        metrics.users[subscriptionTypePropMap[SUBSCRIPTION_TYPE.ANNUALLY]];

      monthlyPayingUsersData.push(
        monthlyPayingUsersItem.paying_users?.[refundProp] || 0
      );

      annuallyPayingUsersData.push(
        annuallyPayingUsersItem.paying_users?.[refundProp] || 0
      );

      xAxisCategories.push(getCategoryLabel(date, index));
      dates.push(date);
    });

    const maxTotalUsers = newUsersData.reduce((max, item, index) => {
      return Math.max(max, item + returningUsersData[index]);
    }, 0);

    const maxTotalPayingUsers = monthlyPayingUsersData.reduce(
      (max, item, index) => {
        return Math.max(max, item + annuallyPayingUsersData[index]);
      },
      0
    );

    const seriesNewVsReturningUsers = [
      {
        name: 'New Users',
        data: newUsersData,
        type: 'column',
        color: '#775ffc',
      },
      {
        name: 'Returning Users',
        data: returningUsersData,
        type: 'column',
        color: '#84d9fd',
      },
    ];

    const seriesPayingUsers = [
      {
        name: 'Monthly',
        data: monthlyPayingUsersData,
        type: 'column',
        color: '#4318ff',
      },
      {
        name: 'Annually',
        data: annuallyPayingUsersData,
        type: 'column',
        color: '#84d9fd',
      },
    ];

    return {
      newVsReturningUsers: {
        series: seriesNewVsReturningUsers,
        options: getChartOptions({
          dates,
          xAxisCategories,
          maxTotal: maxTotalUsers,
        }),
      },
      payingUsers: {
        series: seriesPayingUsers,
        options: getChartOptions({
          dates,
          xAxisCategories,
          maxTotal: maxTotalPayingUsers,
        }),
      },
    };
  };

  return (
    <>
      {chartData && (
        <Flex>
          <Box w={'50%'}>
            <Text mb={30} fontWeight={800}>
              New & Returning Users
            </Text>
            <Chart
              type="bar"
              height={240}
              series={chartData.newVsReturningUsers.series}
              options={chartData.newVsReturningUsers.options}
            />
          </Box>
          {isSubscription && (
            <Box w={'50%'}>
              <Text mb={30} fontWeight={800}>
                Monthly vs. Annually Paying Users
              </Text>
              <Chart
                type="bar"
                height={240}
                series={chartData.payingUsers.series}
                options={chartData.payingUsers.options}
              />
            </Box>
          )}
        </Flex>
      )}
    </>
  );
};

UsersCharts.propTypes = {
  cohortsData: PropTypes.object,
  settings: PropTypes.object,
  isSubscription: PropTypes.bool,
};

export default UsersCharts;
