import axios from 'axios';

import {
  cohortsHistogramMockData,
  cohortsMockData,
  compareCohortsMockData,
  compareSalesKpisMockData,
  recurringRevenueMockData,
  salesKpisMockData
} from 'mocks/underwritingMocks';

export const getSalesSettingsApi = async ({ customerId }) => {
  const response = await axios.get(
    `admin/customer/${customerId}/sales-settings`
  );
  return response.data;
};

export const updateSalesSettingsApi = async ({ customerId, data }) => {
  const response = await axios.put(
    `admin/customer/${customerId}/sales-settings`,
    data
  );
  return response.data;
};

export const getSalesCohortsApi = async ({
  customerId,
  date,
  onlyKpis,
  timePeriod,
  withPrediction,
  currentMonthType,
  cohortsMonthsBack
}) => {
  if (process.env.REACT_APP_GET_MOCK_DATA === 'true') {
    return onlyKpis ? compareCohortsMockData : cohortsMockData;
  }

  const response = await axios.post(
    `admin/customer/${customerId}/sales-cohorts`,
    {
      date,
      onlyKpis,
      cohortsMonthsBack,
      settings: {
        timePeriod,
        withPrediction,
        currentMonthType,
      },
    }
  );
  return response.data;
};

export const getSalesKpisApi = async ({
  customerId,
  date,
  isCompare,
  onlyCashflow,
}) => {
  if (process.env.REACT_APP_GET_MOCK_DATA === 'true') {
    return isCompare ? compareSalesKpisMockData : salesKpisMockData;
  }

  const response = await axios.get(
    `admin/customer/${customerId}/sales-kpis`,
    {
      params: {
        date,
        onlyCashflow,
      },
    }
  );
  return response.data;
};

export const getSalesRecurringRevenueApi = async ({ customerId, date }) => {
  if (process.env.REACT_APP_GET_MOCK_DATA === 'true') {
    return recurringRevenueMockData;
  }

  const response = await axios.get(
    `admin/customer/${customerId}/sales-recurring-revenue`,
    {
      params: {
        date,
      },
    }
  );
  return response.data;
};

export const getSalesCohortsHistogramApi = async ({
  customerId,
  date,
  timePeriod,
  currentMonthType,
}) => {
  if (process.env.REACT_APP_GET_MOCK_DATA === 'true') {
    return cohortsHistogramMockData;
  }

  const response = await axios.post(
    `admin/customer/${customerId}/sales-cohorts-histogram`,
    {
      date,
      settings: {
        timePeriod,
        currentMonthType,
      },
    }
  );
  return response.data;
};
