import { Box, Button, Flex, Heading, Text, useToast } from '@chakra-ui/react';
import {
  getMarketingKpisApi,
  getMarketingKpisLatestApi,
  getMarketingSpendDataApi,
  updateMarketingKpisApi,
} from 'api/marketingApi';
import { currencyFormat, percentageFormat } from 'components/Number';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
} from 'components/NumberInput';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Card from 'components/card/Card';
import EditKpiCollection from 'components/underwriting/EditKpiCollection';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import DateInputHeader from '../DateInputHeader';
import SpendBarChart from './SpendBarChart';
import SpendSourcePieChart from './SpendSourcePieChart';

const getKpiDisplayConfig = ({ date }) => {
  const config = [
    {
      displayName: 'Last Day Spend',
      row: 1,
      items: [
        {
          kpiProp: 'lastDaySpent',
          label: moment(date).clone().format('MMM DD, YYYY'),
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
      ],
    },
    {
      displayName: 'Average Daily Spend',
      row: 1,
      items: [
        {
          kpiProp: 'avgDailySpend7Days',
          label: '7 Days',
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
        {
          kpiProp: 'avgDailySpend30Days',
          label: '30 Days',
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
      ],
    },
    {
      displayName: 'Marketing Monthly Run Rate',
      row: 1,
      items: [
        {
          kpiProp: 'monthlyRunRate',
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
      ],
    },
    {
      displayName: 'Marketing Annual Run Rate',
      row: 1,
      items: [
        {
          kpiProp: 'annualRunRate',
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
      ],
    },
    {
      displayName: 'Total Marketing Spend',
      row: 2,
      items: [
        {
          kpiProp: 'totalMarketingSpend',
          label: 'Last Full Month',
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
      ],
    },
    {
      displayName: 'User Acquisition Spend',
      row: 2,
      items: [
        {
          kpiProp: 'uaSpendPct',
          format: percentageFormat,
          fieldElement: PercentageNumberInput,
        },
      ],
    },
    {
      displayName: 'Total Spend',
      row: 2,
      items: [
        {
          kpiProp: 'totalSpendLast30Days',
          label: 'Last 30 Days',
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
        {
          kpiProp: 'totalSpendLastMonth',
          label: 'Last Full Month',
          format: currencyFormat,
          fieldElement: CurrencyNumberInput,
        },
      ],
    },
  ];
  return config;
};

const Marketing = ({ isPlayground }) => {
  const urlParams = useParams();
  let [searchParams] = useSearchParams();

  const customerId = isPlayground
    ? searchParams.get('customerId')
    : urlParams.customerId;

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [marketingKpis, setMarketingKpis] = useState(null);
  const [marketingSpendData, setMarketingSpendData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (!customerId) {
      return;
    }
    loadData();
  }, [date, customerId]);

  const loadData = async () => {
    setLoading(true);
    const promises = [
      getMarketingKpis({ isLatest: false }),
      getMarketingSpendData(),
    ];

    try {
      const [_marketingKpis, _marketingSpendData] = await Promise.all(promises);
      setMarketingKpis(_marketingKpis);
      setMarketingSpendData(_marketingSpendData);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getMarketingKpis = async ({ isLatest }) => {
    const apiFunc = isLatest ? getMarketingKpisLatestApi : getMarketingKpisApi;

    try {
      const _marketingKpis = await apiFunc({
        customerId,
        date,
        saveInputs: !isPlayground,
      });
      return _marketingKpis;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch marketing KPIs',
        status: 'error',
      });
      throw error;
    }
  };

  const getMarketingSpendData = async () => {
    try {
      const _marketingSpendData = await getMarketingSpendDataApi({
        customerId,
        date,
      });
      return _marketingSpendData;
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch marketing spend data',
        status: 'error',
      });
      throw error;
    }
  };

  const updateMarketingKpis = async (_marketingKpis) => {
    setLoading(true);
    try {
      await updateMarketingKpisApi({
        customerId,
        date,
        marketingKpis: _marketingKpis,
      });
      toast({
        title: 'Success',
        description: 'Marketing KPIs updated',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update marketing KPIs',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const onKpiSave = async ({ kpiProp, kpi }) => {
    let _marketingKpis = cloneDeep(marketingKpis);
    _marketingKpis[kpiProp] = kpi;

    await updateMarketingKpis(_marketingKpis);
    setMarketingKpis(_marketingKpis);
  };

  const onFetchLatestData = async () => {
    setLoading(true);
    try {
      const _marketingKpis = await getMarketingKpis({ isLatest: true });
      setMarketingKpis(_marketingKpis);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch latest marketing KPIs',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <OverlaySpinnerWrapper show={loading} isFixed={true}>
      <Box minW={'container.lg'} maxW={'max'}>
        <DateInputHeader
          date={date}
          onDateChange={setDate}
          showCustomerSelect={isPlayground}
          rightButtonElm={
            <Button variant={'brand'} onClick={onFetchLatestData}>
              Fetch Latest Data
            </Button>
          }
        />
        {marketingKpis && customerId && (
          <Card>
            {' '}
            <Heading variant={'sectionHeader'} borderColor={'pink.500'}>
              Marketing
            </Heading>
            <Flex mt={50} gap={150} h={300} justifyContent={'space-between'}>
              <Box flex={1}>
                <SpendBarChart data={marketingSpendData?.spend_vector} />
              </Box>
              <Box w={350}>
                <SpendSourcePieChart
                  data={marketingSpendData?.spend_distribution}
                />
              </Box>
            </Flex>
            <Box mt={50}>
              <Text fontWeight={800}>KPIs</Text>
              <EditKpiCollection
                kpis={marketingKpis}
                config={getKpiDisplayConfig({ date })}
                onKpiSave={onKpiSave}
                hasOriginalValues={true}
                hideEditButton={isPlayground}
              />
            </Box>
          </Card>
        )}
      </Box>
    </OverlaySpinnerWrapper>
  );
};

Marketing.propTypes = {
  isPlayground: PropTypes.bool,
};

export default Marketing;
