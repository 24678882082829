import ContentBox from 'components/ContentBox';
import { useParams } from 'react-router-dom';
import CustomerUsers from './CustomerUsers';

function CustomerConfiguration() {
  const { customerId } = useParams();

  return (
    <ContentBox minH={400}>
      <CustomerUsers customerId={customerId} />
    </ContentBox>
  );
}

export default CustomerConfiguration;
