import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  InputGroup,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { getCustomerProfilesApi } from 'api/adminApi';
import DataTable from 'components/DataTable';
import Card from 'components/card/Card';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddUserModal from './AddUserModal';

const columnHelper = createColumnHelper();

const createColumns = () => {
  const columns = [
    columnHelper.accessor('companyName', {
      cell: (info) => info.getValue(),
      header: 'Company Name',
    }),
    columnHelper.accessor('name', {
      cell: (info) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.display({
      id: 'email',
      cell: (info) => {
        const { users } = info.row.original;
        return (
          <>
            {users.map((user) => {
              return <Box key={user.id}>{user.email}</Box>;
            })}
          </>
        );
      },
      header: 'Email',
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
      header: 'Created At',
    }),
    columnHelper.accessor('lastLoginAt', {
      cell: (info) =>
        info.getValue() ? moment(info.getValue()).format('LLL') : ' - ',
      header: 'Last Login',
    }),
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'ID',
    }),
  ];

  return columns;
};

const defaultSorting = [{ id: 'createdAt', desc: true }];

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    loadCustomers();
  }, []);

  useEffect(() => {
    setColumns(createColumns(onAddUserCellClick));
  }, [customers]);

  const loadCustomers = () => {
    setLoading(true);
    getCustomerProfilesApi()
      .then((_customers) => {
        setCustomers(transformCustomers(_customers));
        setCompanyNames(_customers.map((c) => c.companyName));
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: 'Error loading customers',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const transformCustomers = (_customers) => {
    return _customers.map((customer) => {
      return {
        ...customer,
        createdAt: customer.createdAt ? new Date(customer.createdAt) : null,
        lastLoginAt: customer.lastLoginAt
          ? new Date(customer.lastLoginAt)
          : null,
      };
    });
  };

  const filterCustomers = (_customers) => {
    if (!searchValue) {
      return _customers;
    }

    return _customers.filter((customer) => {
      return (
        customer.companyName
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) !== -1 ||
        customer.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        customer.id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 ||
        customer.users.some((user) =>
          user.email.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    });
  };

  const onAddUserCellClick = (customerId) => {
    setSelectedCustomerId(customerId);
    setShowAddUserModal(true);
  };

  const navigateToConnectors = (customerId) => {
    navigate(`/customers/${customerId}/connectors`);
  };

  if (loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <Box maxW={'container.xl'}>
      <Card>
        <Flex justify={'space-between'}>
          <Flex gap={4}>
            <InputGroup mb={6} w={300}>
              {' '}
              <SearchBar
                h="44px"
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    const data = filterCustomers(customers);
                    if (data.length > 0) {
                      navigateToConnectors(data[0].id);
                    }
                  }
                }}
                w={{ lg: '390px' }}
                placeholder="Search by Company Name"
              />
            </InputGroup>
          </Flex>
          <Button
            variant="brand"
            minW="183px"
            fontSize="sm"
            fontWeight="500"
            ms="auto"
            onClick={() => {
              setSelectedCustomerId(null);
              setShowAddUserModal(true);
            }}
          >
            Create New Customer
          </Button>
        </Flex>

        <Box minH={300} mx={-5}>
          <DataTable
            data={filterCustomers(customers)}
            columns={columns}
            hasPagination={true}
            defaultSorting={defaultSorting}
            onRowClick={(row) => {
              navigateToConnectors(row.id);
            }}
            rowHeight={'100px'}
          />
        </Box>

        <AddUserModal
          show={showAddUserModal}
          header={'Create New Customer'}
          selectedCustomerId={selectedCustomerId}
          companyNames={companyNames}
          onSubmitSuccess={({ customerId }) => {
            navigate(`/customers/${customerId}/settings`);
          }}
          onModalClose={() => setShowAddUserModal(false)}
        />
      </Card>
    </Box>
  );
}

export default Customers;
