import { REPAYMENT_FREQUENCY_TYPE } from 'constants/dealConstants';
import moment from 'moment';
import { useEffect, useState } from 'react';

export function useRepaymentSchedule({ repaymentFrequency }) {
  const [repaymentDaysOptions, setRepaymentDaysOptions] = useState([]);
  const [daysBeforeEndOfMonthOptions, setDaysBeforeEndOfMonthOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let options = [];

    if (repaymentFrequency === REPAYMENT_FREQUENCY_TYPE.WEEKLY) {
      options = [1,2,3,4,5].map((i) => {
        {
          return {
            label: moment().day(i).format('dddd'),
            value: i,
          };
        }
      });
    } else {
      options = [...Array(30).keys()].map((i) => {
        {
          return {
            label: i + 1,
            value: i + 1,
          };
        }
      });

      options.push({
        label: 'EOM',
        value: -1,
      });
    }

    setRepaymentDaysOptions(options);
  }, [repaymentFrequency]);

  useEffect(() => {
    let options = [];

    options = [...Array(11).keys()].map((i) => {
      {
        return {
          label: i,
          value: i,
        };
      }
    });

    setDaysBeforeEndOfMonthOptions(options);
  } , []);

  const onRepaymentFrequencyFieldChange = ({
    repaymentFrequency,
    setValue,
    name,
    value,
  }) => {
    if (name !== 'repaymentFrequency') {
      return;
    }

    if (
      (value === REPAYMENT_FREQUENCY_TYPE.WEEKLY &&
        repaymentFrequency !== REPAYMENT_FREQUENCY_TYPE.WEEKLY) ||
      ([
        REPAYMENT_FREQUENCY_TYPE.MONTHLY,
        REPAYMENT_FREQUENCY_TYPE.BI_MONTHLY,
      ].includes(value) &&
        ![
          REPAYMENT_FREQUENCY_TYPE.MONTHLY,
          REPAYMENT_FREQUENCY_TYPE.BI_MONTHLY,
        ].includes(repaymentFrequency))
    ) {
      setValue('repaymentDays', null);
    }
  };

  return { repaymentDaysOptions, onRepaymentFrequencyFieldChange, daysBeforeEndOfMonthOptions };
}
