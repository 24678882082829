import { Box, Text } from '@chakra-ui/react';
import { currencyFormat } from 'components/Number';
import ChartTooltip from 'components/charts/ChartTooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { renderToString } from 'react-dom/server';

const SpendBarChart = ({ data }) => {
  const [chartData, setChartData] = useState();

  useEffect(() => {
    if (data) {
      const _chartData = getChartData();
      setChartData(_chartData);
    }
  }, [data]);

  const getCategoryLabel = (mDate, index) => {
    if (index === 0 || mDate.month() === 0) {
      return [mDate.format('MMM'), mDate.format('YYYY')];
    }

    return mDate.format('MMM');
  };

  const getChartOptions = ({ dates, xAxisCategories, maxTotal }) => {
    return {
      chart: {
        fontFamily: 'DM Sans',
        stacked: true,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const total = series[0][dataPointIndex] + series[1][dataPointIndex];
          const series1 = w.config.series[0];
          const series2 = w.config.series[1];
          const title = w.config.dates[dataPointIndex].format('MMM YYYY');

          const items = [
            { label: 'Total', value: currencyFormat(total) },
            {
              label: series1.name,
              value: currencyFormat(series1.data[dataPointIndex]),
              color: series1.color,
            },
            {
              label: series2.name,
              value: currencyFormat(series2.data[dataPointIndex]),
              color: series2.color,
            },
          ];

          return renderToString(<ChartTooltip title={title} items={items} />);
        },
      },
      xaxis: {
        categories: xAxisCategories,
      },
      yaxis: {
        min: 0,
        max: () => {
          return maxTotal;
        },
        labels: {
          formatter: (value) => {
            if (value === null) {
              return null;
            }
            return currencyFormat(value);
          },
        },
      },
      dates,
    };
  };

  const getChartData = () => {
    const _data = data.map((row) => {
      return {
        date: moment({
          year: row.date.year,
          month: row.date.month - 1,
          day: row.date.day,
        }),
        uaSpend: row.ua_spend || 0,
        nonUaSpend: row.non_ua_spend || 0,
        totalSpend: row.total_spend || 0,
      };
    });

    _data.sort((a, b) => a.date - b.date);

    let uaSpendData = [];
    let nonUaSpendData = [];
    let xAxisCategories = [];
    let dates = [];
    let maxTotalValue = 0;

    _data.forEach((row, index) => {
      uaSpendData.push(row.uaSpend);
      nonUaSpendData.push(row.nonUaSpend);
      xAxisCategories.push(getCategoryLabel(row.date, index));
      dates.push(row.date);
      if (row.totalSpend > maxTotalValue) {
        maxTotalValue = row.totalSpend;
      }
    });

    const series = [
      {
        name: 'Acquisition',
        data: uaSpendData,
        type: 'column',
        color: '#84D9FD',
      },
      {
        name: 'Other',
        data: nonUaSpendData,
        type: 'column',
        color: '#775FFC',
      },
    ];

    return {
      series,
      options: getChartOptions({
        dates,
        xAxisCategories,
        maxTotal: maxTotalValue,
      }),
    };
  };

  if (!chartData) {
    return null;
  }

  return (
    <Box>
      <Text fontWeight={800} mb={10}>
        Marketing Spend
      </Text>
      <Chart
        type="bar"
        series={chartData.series}
        options={chartData.options}
        width={'100%'}
        height={'100%'}
      />
    </Box>
  );
};

SpendBarChart.propTypes = {
  data: PropTypes.array,
};

export default SpendBarChart;
