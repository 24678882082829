import PropTypes from 'prop-types';

const ChartTooltip = ({ title, items, hideMarkers }) => {
  return (
    <div className="apexcharts-tooltip-custom">
      <div className="apexcharts-tooltip-title">{title}</div>
      {items.map((item, index) => (
        <div
          key={index}
          className="apexcharts-tooltip-series-group apexcharts-active"
          style={{ display: 'flex' }}
        >
          {!hideMarkers && (
            <span
              class="apexcharts-tooltip-marker"
              style={{ backgroundColor: item.color }}
            ></span>
          )}
          <div className="apexcharts-tooltip-text">
            <div className="apexcharts-tooltip-y-group">
              <span className="apexcharts-tooltip-text-y-label">
                {item.label}:
              </span>
              <span className="apexcharts-tooltip-text-y-value">
                {item.value}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

ChartTooltip.propTypes = {
  title: PropTypes.string,
  hideMarkers: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    })
  ),
};

export default ChartTooltip;
