import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import ContentBox from 'components/ContentBox';
import Banking from 'pages/Cockpit/BankingAndFinance/Banking';
import Sofr from 'pages/Cockpit/BankingAndFinance/Sofr';

function BankingAndFinance() {
  return (
    <ContentBox>
      <Tabs isLazy variant={'enclosed-colored'} mt={-4}>
        <TabList mb={4}>
          <Tab>Bank Accounts</Tab>
          <Tab>SOFR</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Banking />
          </TabPanel>
          <TabPanel>
            <Sofr />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ContentBox>
  );
}

export default BankingAndFinance;
