// Chakra imports
import {
  AbsoluteCenter,
  Box,
  Portal,
  Spinner,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import { BUSINESS_MODEL_TYPE_META } from 'constants/underwritingConstants';
import { CockpitCustomerContext } from 'context/CockpitCustomerContext';
import CockpitUsersProvider from 'context/CockpitUsersContext';
import { SidebarContext } from 'context/SidebarContext';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { createCockpitRoutes } from 'routes';

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const [routes, setRoutes] = useState([]);

  const location = useLocation();

  const { customer, isCustomerLoading } = useContext(CockpitCustomerContext);

  useEffect(() => {
    setRoutes(createCockpitRoutes({ location }));
  }, [location]);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (routes) => {
    let activeRoute = 'Main Dashboard';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (window.location.href.indexOf(routes[i].path) !== -1) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.path) {
        const mainRoute = (
          <Route path={route.path} element={route.component} key={key} />
        );

        const subRoutes = route.items ? getRoutes(route.items) : null;

        return (
          <Fragment key={key}>
            {mainRoute}
            {subRoutes}
          </Fragment>
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };

  const navbarTitle = useMemo(() => {
    
    if (isCustomerLoading) {
      return {
        main: '',
        secondary: '',
      };
      
    }

    if (customer) {
      const model = customer?.model;
      return {
        main: customer?.companyName,
        secondary: model ? BUSINESS_MODEL_TYPE_META[model].label : '',
      };
    }

    return {
      main: getActiveRoute(routes),
      secondary: getActiveNavbar(routes),
    };
  });

  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');


  return (
    <CockpitUsersProvider>
      <Box bg={bg} h="100vh" w="100vw">
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar mini={mini} routes={routes} display="none" {...rest} />
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            // overflow="auto"
            position="relative"
            maxHeight="100%"
            w={
              mini === false
                ? { base: '100%', xl: 'calc( 100% - 290px )' }
                : mini === true
                ? { base: '100%', xl: 'calc( 100% - 290px )' }
                : { base: '100%', xl: 'calc( 100% - 120px )' }
            }
            maxWidth={
              mini === false
                ? { base: '100%', xl: 'calc( 100% - 290px )' }
                : mini === true
                ? { base: '100%', xl: 'calc( 100% - 290px )' }
                : { base: '100%', xl: 'calc( 100% - 120px )' }
            }
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            {isCustomerLoading ? (
              <AbsoluteCenter>
                <Spinner />
              </AbsoluteCenter>
            ) : (
              <>
                <Portal>
                  <Box>
                    <Navbar
                      setMini={setMini}
                      mini={mini}
                      onOpen={onOpen}
                      logoText={'Twelve'}
                      brandText={navbarTitle.main}
                      secondary={navbarTitle.secondary}
                      theme={props.theme}
                      setTheme={props.setTheme}
                      fixed={false}
                      {...rest}
                    />
                  </Box>
                </Portal>
                {getRoute() ? (
                  <Box
                    mx="auto"
                    p={{ base: '20px', md: '30px' }}
                    pe="20px"
                    minH="100vh"
                    pt="50px"
                  >
                    <Box
                      pt={{ base: '130px', md: '130px', xl: '130px' }}
                      ml={'24px'}
                    >
                      <Routes>{getRoutes(routes)}</Routes>
                    </Box>
                  </Box>
                ) : null}
              </>
            )}
          </Box>
        </SidebarContext.Provider>
      </Box>
    </CockpitUsersProvider>
  );
}
