import {
  AbsoluteCenter,
  Box,
  Button,
  Heading,
  Spinner,
  VStack,
  useToast,
} from '@chakra-ui/react';
import {
  getDealKpisApi,
  getLatestDealKpisApi,
  updateDealKpisApi,
} from 'api/dealInfoApi';
import 'assets/scss/ScrollLinks.scss';
import { TwDatePicker } from 'components/DatePicker';
import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from 'components/Number';
import {
  CurrencyNumberInput,
  PercentageNumberInput,
  TwNumberInput,
} from 'components/NumberInput';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import Card from 'components/card/Card';
import EditKpiCollection from 'components/underwriting/EditKpiCollection';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import DateInputHeader from '../DateInputHeader';

const formatKpiDate = (date) => {
  if (!date) {
    return null;
  }
  return moment(date).format('MM/DD/YY');
};

const highlightsConfig = [
  {
    displayName: 'Underwriting',
    row: 1,
    items: [
      {
        kpiProp: 'lastUnderwritingDate',
        label: 'Last',
        format: formatKpiDate,
        fieldElement: TwDatePicker,
      },
      {
        kpiProp: 'nextUnderwritingDate',
        label: 'Next',
        format: formatKpiDate,
        fieldElement: TwDatePicker,
      },
    ],
  },
  {
    displayName: '%Cohort',
    row: 1,
    items: [
      {
        kpiProp: 'cohortFoundedPct',
        label: 'Founded',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
      {
        kpiProp: 'cohortRepaymentPct',
        label: 'Repayment',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: 'Total Expected IRR',
    row: 2,
    items: [
      {
        kpiProp: 'totalExpectedIrr',
        format: percentageFormat,
        fieldElement: PercentageNumberInput,
      },
    ],
  },
  {
    displayName: '# of Months to Max \n Exposure',
    row: 2,
    items: [
      {
        kpiProp: 'numOfMonthsToMaxExposure',
        format: numberFormat,
        fieldElement: TwNumberInput,
      },
    ],
  },
  {
    displayName: 'Min Repayment',
    row: 2,
    items: [
      {
        kpiProp: 'minRepayment',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
];

const exposureConfig = [
  {
    displayName: 'Max Exposure',
    row: 1,
    items: [
      {
        kpiProp: 'maxExposure',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Time to Max \n Exposure',
    row: 1,
    items: [
      {
        kpiProp: 'timeToMaxExposure',
        label: 'Months',
        format: numberFormat,
        fieldElement: TwNumberInput,
      },
    ],
  },
  {
    displayName: 'Current Exposure',
    row: 1,
    items: [
      {
        kpiProp: 'currentExposure',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Peak Exposure',
    row: 1,
    items: [
      {
        kpiProp: 'peakExposure',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
];

const fundingConfig = [
  {
    displayName: 'Cumulative Funding',
    row: 1,
    items: [
      {
        kpiProp: 'cumulativeFunding',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Next Funding',
    row: 1,
    items: [
      {
        kpiProp: 'nextFunding',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Next Funding Date',
    row: 1,
    items: [
      {
        kpiProp: 'nextFundingDate',
        format: formatKpiDate,
        fieldElement: TwDatePicker,
      },
    ],
  },
];

const repaymentConfig = [
  {
    displayName: 'Next Repayment',
    row: 1,
    items: [
      {
        kpiProp: 'nextRepayment',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
  {
    displayName: 'Next Repayment Date',
    row: 1,
    items: [
      {
        kpiProp: 'nextRepaymentDate',
        format: formatKpiDate,
        fieldElement: TwDatePicker,
      },
    ],
  },
  {
    displayName: 'Cumulative Repayment',
    row: 1,
    items: [
      {
        kpiProp: 'cumulativeRepayment',
        format: currencyFormat,
        fieldElement: CurrencyNumberInput,
      },
    ],
  },
];

const SectionCard = ({ title, kpis, config, onKpiSave }) => {
  return (
    <Card>
      <Heading variant={'sectionHeader'} borderColor={'green.500'}>
        {title}
      </Heading>
      <Box>
        <EditKpiCollection kpis={kpis} config={config} onKpiSave={onKpiSave} />
      </Box>
    </Card>
  );
};

const Deal = () => {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [dealKpis, setDealKpis] = useState();

  const toast = useToast();
  const { customerId } = useParams();

  useEffect(() => {
    getDealKpis();
  }, [date]);

  const getDealKpis = async () => {
    setLoading(true);
    try {
      const _dealKpis = await getDealKpisApi({
        customerId,
        date,
      });
      setDealKpis(_dealKpis);
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to fetch deal data',
        status: 'error',
      });
      setDealKpis(null);
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const getLatestDealKpis = async () => {
    setLoading(true);
    try {
      const _dealKpis = await getLatestDealKpisApi({ customerId });
      return _dealKpis;
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to fetch latest deal data',
        status: 'error',
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  const updateFinancialKpis = async (_dealKpis) => {
    setLoading(true);
    try {
      await updateDealKpisApi({
        customerId,
        date,
        dealKpis: _dealKpis,
      });
      toast({
        title: 'Success',
        description: 'Deal data updated successfully',
        status: 'success',
      });
    } catch (e) {
      toast({
        title: 'Error',
        description: 'Failed to update deal data',
        status: 'error',
      });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const onFetchLatestData = async () => {
    const _dealKpis = await getLatestDealKpis();
    setDealKpis(_dealKpis);

    if (dealKpis) {
      await updateFinancialKpis(_dealKpis);
    }
  };

  const onKpiSave = async ({ kpiProp, kpi }) => {
    let _dealKpis = cloneDeep(dealKpis);
    _dealKpis[kpiProp] = kpi;

    await updateFinancialKpis(_dealKpis);
    setDealKpis(_dealKpis);
  };

  if (!dealKpis && loading) {
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );
  }

  return (
    <OverlaySpinnerWrapper show={loading} isFixed={true}>
      <Box minW={'container.lg'} maxW={'max'}>
        <DateInputHeader
          date={date}
          onDateChange={setDate}
          rightButtonElm={
            <Button variant={'brand'} onClick={onFetchLatestData}>
              Fetch Latest Data
            </Button>
          }
        />

        {dealKpis && (
          <VStack spacing={50}>
            <Element name={'highlights'} className={'scroll-target-element'}>
              <SectionCard
                title={'Deal Highlights'}
                kpis={dealKpis}
                config={highlightsConfig}
                onKpiSave={onKpiSave}
              />
            </Element>

            <Element name={'exposure'} className={'scroll-target-element'}>
              <SectionCard
                title={'Exposure'}
                kpis={dealKpis}
                config={exposureConfig}
                onKpiSave={onKpiSave}
              />
            </Element>

            <Element name={'funding'} className={'scroll-target-element'}>
              <SectionCard
                title={'Funding'}
                kpis={dealKpis}
                config={fundingConfig}
                onKpiSave={onKpiSave}
              />
            </Element>

            <Element name={'repayment'} className={'scroll-target-element'}>
              <SectionCard
                title={'Repayment'}
                kpis={dealKpis}
                config={repaymentConfig}
                onKpiSave={onKpiSave}
              />
            </Element>
          </VStack>
        )}
      </Box>
    </OverlaySpinnerWrapper>
  );
};

Deal.propTypes = {};

export default Deal;
