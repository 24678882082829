import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import DrawerCloseButton from 'components/drawer/DrawerCloseButton';
import { renderInputElement } from 'helpers/formHelper';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';



const EditCustomerSettings = ({ fields, data, onSave }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSubmit = (_data) => {
    onSave(_data);
  };

  return (
    <>
      <Button variant={'brand'} onClick={onOpen}>
        Edit Settings
      </Button>
      <Drawer blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <DrawerContent maxW={{ base: 'calc(100vw - 32px)', md: '427px' }}>
              <DrawerCloseButton onClose={onClose} />
              <DrawerBody>
                <SimpleGrid
                  gridTemplateColumns={'min-content min-content'}
                  whiteSpace={'nowrap'}
                  justifyContent={'space-between'}
                  spacingY={4}
                  alignItems={'center'}
                  fontSize={'sm'}
                >
                  <Text fontSize={'16px'} fontWeight={800}>
                    Edit Settings
                  </Text>
                  <Box></Box>
                  {fields.map((item, index) => (
                    <Fragment key={index}>
                      <Text>{item.label}</Text>
                      <Flex
                        gap={'5px'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                      >
                        <Box w={200}>
                          <Controller
                            control={control}
                            name={item.name}
                            render={renderInputElement({
                              item,
                              styleProps: { fontWeight: 800, w: 200 },
                              showPrefix: true
                            })}
                          />
                        </Box>
                      </Flex>
                    </Fragment>
                  ))}
                </SimpleGrid>
              </DrawerBody>
              <DrawerFooter>
                <Flex justify={'end'}>
                  <Button type={'submit'} variant={'brand'} w={200}>
                    Save
                  </Button>
                </Flex>
              </DrawerFooter>
            </DrawerContent>
          </FormControl>
        </form>
      </Drawer>
    </>
  );
};

EditCustomerSettings.propTypes = {
  fields: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditCustomerSettings;
