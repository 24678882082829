import 'assets/scss/CohortTable.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useMemo, useState } from 'react';

function CohortTable({
  cohortData,
  formatCell,
  aggRows,
  header,
}) {
  const [hoverdPoint, setHoverdPoint] = useState({ x: null, y: null });

  const getWeightedValueMap = (isPredicted) => {
    const flattenedValues = cohortData.reduce((acc, row) => {
      return [
        ...acc,
        ...row.data
          .filter((item) => {
            return item.isPredicted == isPredicted;
          })
          .map((value) => value.value),
      ];
    }, []);

    flattenedValues.sort((a, b) => a - b);

    const weightedValueMap = flattenedValues.reduce((acc, value, index) => {
      return {
        ...acc,
        [value]: (index / flattenedValues.length) * 0.5,
      };
    }, {});

    return weightedValueMap;
  };

  const weightedValueMap = useMemo(() => {
    return getWeightedValueMap();
  }, [cohortData]);

  const weightedValueMapPredicted = useMemo(() => {
    return getWeightedValueMap(true);
  }, [cohortData]);

  const prefixColumnsNum = cohortData[0]?.prefixColumns?.length - 1 || 0;

  const getHoverdCellClass = ({ x, y }) => {
    if (x === hoverdPoint.x || y === hoverdPoint.y) {
      return 'tw-cohort__cell--hovered';
    }
    return '';
  };

  const Header = () => {
    return (
      <thead>
        <tr className="tw-cohort__row tw-cohort__header">
          {/* index == prefixColumnsNum */}
          {header.map((item, index) => {
            return (
              <Fragment key={index}>
                {index === prefixColumnsNum + 1 && <th></th>}
                <th
                  key={`headerCell${item}`}
                  className={classNames(getHoverdCellClass({ y: item }), {
                    'tw-cohort_cell__prefix_cell': index <= prefixColumnsNum,
                  })}
                >
                  {item}
                </th>
              </Fragment>
            );
          })}
          {/* seperator */}
        </tr>
      </thead>
    );
  };

  const AggregatedRow = ({ row }) => {
    if (!row) {
      return null;
    }

    const { data, label } = row;

    return (
      <>
        <tr className="tw-cohort__row tw-cohort__agg-row">
          <td colSpan={prefixColumnsNum + 1} className="tw-cohort__cell">
            {label}
          </td>
          <td className={'tw-cohort_cell__separator'}></td>
          {data.map((value, y) => {
            return (
              <td
                key={`aggRowCell${y}`}
                onMouseOver={() => setHoverdPoint({ x: null, y })}
                onMouseOut={() => setHoverdPoint({ x: null, y: null })}
                className={getHoverdCellClass({ y })}
              >
                <div> {formatCell ? formatCell(value) : value}</div>
              </td>
            );
          })}
        </tr>
      </>
    );
  };

  const rowLength = cohortData[0]?.data?.length || 0;

  return (
    <div className="tw-cohort">
      <table>
        <Header />
        <tbody>
          {cohortData.map((row, x) => {
            let isFirstPredicted = true;
            return (
              <tr key={`row${x}`} className={classNames('tw-cohort__row')}>
                {row.prefixColumns.map((value, y) => {
                  return (
                    <td
                      data-text={value}
                      key={`extraCell${x}${y}`}
                      className={classNames(
                        'tw-cohort_cell__prefix_cell',
                        getHoverdCellClass({ x })
                      )}
                    >
                      {value}
                    </td>
                  );
                })}
                <td className={'tw-cohort_cell__separator'}></td>
                {row.data &&
                  (() => {
                    const cells = [];
                    for (let y = 0; y < rowLength; y++) {

                      const cell = row.data[y];

                      if (!cell) {
                        cells.push(<td key={`cell${x}${y}`}></td>);
                        continue;
                      }
                      
                      const { value, isPredicted } = cell;

                      const alphaValue = isPredicted
                        ? weightedValueMapPredicted[value] + 0.1
                        : weightedValueMap[value] + 0.1;

                      const className = classNames(
                        getHoverdCellClass({ x, y })
                      );

                      if (isPredicted && isFirstPredicted) {
                        isFirstPredicted = false;
                      }

                      let style = {
                        background: isPredicted
                          ? `rgba(100, 100, 100, ${alphaValue})`
                          : `rgba(67, 24, 255, ${alphaValue})`,
                      };

                      cells.push(
                        <td
                          data-text={formatCell ? formatCell(value) : value}
                          key={`cell${x}${y}`}
                          onMouseOver={() => setHoverdPoint({ x, y })}
                          onMouseOut={() =>
                            setHoverdPoint({ x: null, y: null })
                          }
                          className={className}
                          style={style}
                        >
                          {formatCell ? formatCell(value) : value}
                        </td>
                      );
                    }
                    return cells;
                  })()}
                {row.postfixColumns?.map((value, y) => {
                  return (
                    <td
                      data-text={value}
                      key={`extraCell${x}${y}`}
                      className={classNames(
                        'tw-cohort_cell__postfix_cell',
                        getHoverdCellClass({ x })
                      )}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {
            // render aggregated rows
            <>
              <tr className="tw-cohort__row tw-cohort__row-seperator"></tr>
              {aggRows &&
                aggRows.map((row, index) => {
                  return <AggregatedRow key={`aggRow${index}`} row={row} />;
                })}
            </>
          }
        </tbody>
      </table>
    </div>
  );
}

CohortTable.propTypes = {
  cohortData: PropTypes.arrayOf(
    PropTypes.shape({
      prefixColumns: PropTypes.arrayOf(PropTypes.any),
      data: PropTypes.arrayOf(PropTypes.any),
    })
  ).isRequired,
  aggRows: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.any),
      label: PropTypes.string,
    })
  ),
  formatCell: PropTypes.func,
  header: PropTypes.arrayOf(PropTypes.any),
};

export default CohortTable;
