import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { HelperTooltip } from 'components/Tooltip';
import PropTypes from 'prop-types';
import { Fragment, cloneElement } from 'react';
import KpiChangeIndicator from './KpiChangeIndicator';

const KpiCard = ({ id, title, tooltip, items, minW, minH, twoLinesTitle }) => {
  const isMultiple = items.length > 1;

  const isValidValue = (value) => {
    return value !== null && value !== undefined;
  };

  return (
    <Card py={'20px'} px={'30px'} minW={minW} minH={minH}>
      <Flex gap={1}>
        <Text
          color={'secondaryGray.600'}
          fontSize={'sm'}
          textAlign={isMultiple ? 'center' : ''}
          h={twoLinesTitle ? 42 : null}
          mb={twoLinesTitle ? 0 : 2}
          whiteSpace={'pre-line'}
        >
          {title}
        </Text>
        {tooltip && (
          <Box mt={'2px'}>
            <HelperTooltip content={tooltip} />
          </Box>
        )}
      </Flex>

      <Flex
        direction="row"
        gap={10}
        justifyContent={isMultiple ? 'center' : ''}
      >
        {items.map((item, index) => {
          const {
            value,
            valueIcon,
            label,
            expandedElement,
            compareValue,
            format,
            getCustomCompareValue,
            getCustomCompareDirection,
            getCustomCompareValueFormatted,
          } = item;

          return (
            <Fragment key={index}>
              {index > 0 && (
                <Divider orientation="vertical" color={'red'} h={50} />
              )}
              <Box key={index} w={`${100 / items.length}%`}>
                <Flex direction={'column'} align={isMultiple ? 'center' : ''}>
                  <Box>
                    <Flex
                      gap={2}
                      align={'center'}
                      fontWeight={800}
                      whiteSpace={'nowrap'}
                    >
                      {!isValidValue(value) ? (
                        <Text fontSize={24} fontWeight={800}>
                          —
                        </Text>
                      ) : (
                        <>
                          <Box position={'relative'}>
                            <Text fontSize={24}>
                              {format
                                ? format(value, {
                                    maximumFractionDigits: item.precision || 1,
                                  })
                                : value}
                            </Text>
                            {valueIcon && valueIcon}
                          </Box>
                          <Box>
                            {isValidValue(item.compareValue) && (
                              <KpiChangeIndicator
                                value={value}
                                compareValue={compareValue}
                                getCustomCompareDirection={
                                  getCustomCompareDirection
                                }
                                getCustomCompareValue={getCustomCompareValue}
                                getCustomCompareValueFormatted={
                                  getCustomCompareValueFormatted
                                }
                              />
                            )}
                          </Box>
                        </>
                      )}
                      {item.buttonElm && item.buttonElm}
                    </Flex>
                    <Text
                      fontSize={12}
                      color={'secondaryGray.600'}
                      whiteSpace={'nowrap'}
                    >
                      {label || <>&nbsp;</>}
                    </Text>
                  </Box>
                </Flex>

                {expandedElement && isValidValue(value) && (
                  <Box mt={8}>
                    {cloneElement(expandedElement, {
                      id,
                      hideExpandButton: index > 0,
                    })}
                  </Box>
                )}
              </Box>
            </Fragment>
          );
        })}
      </Flex>
    </Card>
  );
};

KpiCard.propTypes = {
  title: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string,
  minW: PropTypes.number,
  minH: PropTypes.number,
  twoLinesTitle: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      valueIcon: PropTypes.element,
      expandedElement: PropTypes.element,
      compareValue: PropTypes.number,
      format: PropTypes.func,
      buttonElm: PropTypes.element,
    })
  ),
};

export default KpiCard;
