import axios from 'axios';
import { saveAs } from 'file-saver';

export const getTearsheetListApi = async ({ customerId }) => {
  const response = await axios.get(`admin/customer/${customerId}/tearsheet`);
  return response.data;
};

export const getTearsheetApi = async ({ customerId, tearsheetId }) => {
  const response = await axios.get(
    `admin/customer/${customerId}/tearsheet/${tearsheetId}`
  );
  return response.data;
};

export const createTearsheetApi = async ({ customerId, date }) => {
  const response = await axios.post(`admin/customer/${customerId}/tearsheet`, {
    date,
  });
  return response.data;
};

export const deleteTearsheetApi = async (customerId, tearsheetId) => {
  const response = await axios.delete(
    `admin/customer/${customerId}/tearsheet/${tearsheetId}`
  );
  return response.data;
};

export const createExternalTearsheetApi = async ({
  customerId,
  date,
  companyRank,
  file,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('date', date);
  formData.append('companyRank', companyRank);
  const response = await axios.post(
    `admin/customer/${customerId}/external-tearsheet`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
};

export const downloadExternalTearsheetApi = async ({
  customerId,
  tearsheetId,
}) => {
  const response = await axios.get(
    `admin/customer/${customerId}/external-tearsheet/${tearsheetId}/download`,
    {
      responseType: 'blob',
    }
  );
  const contentDisposition = response.headers['content-disposition'];
  let fileName = 'file';
  if (contentDisposition) {
    const fileNameSplit = contentDisposition.split('filename=');
    if (fileNameSplit.length === 2)
      fileName = fileNameSplit[1];
  }
  const blob = new Blob([response.data], { type: response.data.type });
  saveAs(blob, fileName);
};
