import { Box, Flex } from '@chakra-ui/react';
import { useContext, useEffect, useState } from 'react';
import SelectInput from '../inputs/SelectInput';

const KpiSectionHeader = ({ cardIds, context, hideCollapse }) => {
  const [kpisExtended, setKpisExtended] = useState(false);
  const { setGroupExpandState } = useContext(context);

  useEffect(() => {
    setGroupExpandState(cardIds, kpisExtended === 'true');
  }, [kpisExtended]);

  const onExtendSelectChange = (value) => {
    setKpisExtended(value);
  };

  return (
    <Flex justify={'space-between'} align={'center'}>
      <Box></Box>
      <Flex justify={'end'}>
        {!hideCollapse && (
          <SelectInput
            options={[
              { label: 'Collapsed', value: false },
              { label: 'Extended', value: true },
            ]}
            onChange={onExtendSelectChange}
            value={kpisExtended}
            variant={'white'}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default KpiSectionHeader;
