import { Box, Collapse, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import PropTypes from 'prop-types';

const SalesSectionCard = ({ title, children }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  return (
    <Card px={0} pb={isOpen ? 0 : 'auto'}>
      <Flex>
        <Heading
          mx={'20px'}
          variant={'sectionHeader'}
          borderColor={'twelve.green.300'}
          cursor={'pointer'}
          _hover={{ color: 'brand.200' }}
          onClick={onToggle}
        >
          {title}
        </Heading>
      </Flex>
      <Collapse in={isOpen}>
        <Box mx={'20px'} mb={isOpen ? '30px' : 0}>
          {children}
        </Box>
      </Collapse>
    </Card>
  );
};

SalesSectionCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SalesSectionCard;
