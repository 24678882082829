import { Flex } from '@chakra-ui/react';
import KpiCard from 'components/kpi/KpiCard';
import { numberFormat, percentageFormat } from 'components/Number';

const ResultsKpis = ({ results }) => {
  const config = [
    {
      label: 'Duration',
      propName: 'duration',
      format: (value) => numberFormat(value, { maximumFractionDigits: 2 }),
    },
    {
      label: 'XIRR',
      propName: 'xirr',
      format: (value) => percentageFormat(value, { maximumFractionDigits: 2 }),
    },
    {
      label: 'Max Exposure',
      propName: 'max_exposure',
      format: (value) => numberFormat(value, { maximumFractionDigits: 2 }),
    },
  ];

  return (
    <Flex gap={9} w={'max'}>
      {config.map((item, index) => (
        <KpiCard
          key={index}
          title={item.label}
          minW={200}
          items={[
            {
              value: results[item.propName],
              format: item.format,
            },
          ]}
        />
      ))}
    </Flex>
  );
};

ResultsKpis.propTypes = {};

export default ResultsKpis;
