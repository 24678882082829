import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { Number } from 'components/Number';
import moment from 'moment';
import PropTypes from 'prop-types';

const ResultsTable = ({ results, showExistingRepayments }) => {
  const borderColor = useColorModeValue('secondaryGray.900', 'whiteAlpha.100');

  const numberOfColumns = results.ua_spend.length;

  const config = [
    {
      isEmpty: true,
    },
    {
      label: 'Revenue from existing cohorts',
      propName: 'revenue_from_existing_cohorts',
    },
    {
      label: 'Revenue from new Cohorts',
      propName: 'revenue_from_new_cohorts',
    },
    {
      label: 'Total Revenue',
      propName: 'total_revenue',
      isTotal: true,
    },
    {
      isEmpty: true,
    },
    {
      label: 'Cost of revenue',
      propName: 'cost_of_revenue',
    },
    {
      label: 'Gross Profit',
      propName: 'gross_profit',
      isTotal: true,
    },
    {
      isEmpty: true,
    },
    {
      label: 'UA',
      propName: 'ua_spend',
    },
    {
      label: 'Gross Profit After UA',
      propName: 'contribution_profit',
      isTotal: true,
    },
    {
      isEmpty: true,
    },
    {
      label: 'Opex',
      propName: 'opex',
    },
    {
      label: 'EBITDA',
      propName: 'cash_from_operation',
    },
    {
      isEmpty: true,
    },
    {
      label: 'Beginning Cash Balance',
      propName: 'beginning_cash_balance',
    },
    {
      label: 'Existing Repayments',
      propName: 'existing_repayments',
      hide: !showExistingRepayments,
    },
    {
      label: 'Funding Twelve',
      propName: 'twelve_funding',
    },
    {
      label: 'Exposure to Twelve',
      propName: 'exposure_to_twelve',
    },
    {
      label: 'Repayment to Twelve',
      propName: 'repayment_to_twelve',
    },
    {
      label: 'EBITDA',
      propName: 'cash_from_operation',
    },
    {
      label: 'End Cash Balance',
      propName: 'end_cash_balance',
      isTotal: true,
    },
  ];

  const dates = Array.from({ length: numberOfColumns }).map((_, index) =>
    moment().clone().add(index, 'months')
  );

  const generateTableData = () => {
    let data = {};
    Object.keys(results).filter(key => config.some(item => item?.propName === key)).forEach((key) => {
      data[key] = results[key]
        .sort((a, b) => (a.period || 0) - (b.period || 0))
        .map((item, index) =>
          key == 'existing_repayments' && item.amount
            ? item.amount * -1
            : item.amount
        );
    });

    return data;
  };

  const tableData = generateTableData();

  const TableHeader = () => {
    const thStyle = {
      fontSize: '15px',
    };

    return (
      <Thead>
        <Tr>
          <Th {...thStyle}></Th>
          {Array.from({ length: numberOfColumns }).map((_, index) => (
            <Th key={index} {...thStyle} fontWeight={800}>
              {`MOB.${index}`}
            </Th>
          ))}
        </Tr>
        <Tr>
          <Th {...thStyle} textTransform={'capitalize'}>
            Month
          </Th>
          {dates.map((date, index) => (
            <Th key={index} {...thStyle}>
              {date.format('MM/YYYY')}
            </Th>
          ))}
        </Tr>
      </Thead>
    );
  };

  const TableBody = () => {
    return (
      <Tbody>
        {config.map((item, index) => {
          if (item.hide) {
            return null;
          }
          return (
            <TableRow
              key={index}
              label={item.label}
              propName={item.propName}
              isEmpty={item.isEmpty}
              isTotal={item.isTotal}
            />
          );
        })}
      </Tbody>
    );
  };

  const TableRow = ({ label, propName, isEmpty, isTotal }) => {
    if (isEmpty) {
      return (
        <Tr>
          <Td colSpan={numberOfColumns}></Td>
        </Tr>
      );
    }

    const tdStyle = {
      borderColor: borderColor,
      borderWidth: 0,
      borderTopWidth: isTotal ? 1 : 0,
      py: 2,
    };

    if (!tableData[propName]) {
      return null;
    }

    return (
      <Tr fontWeight={isTotal ? 800 : 400}>
        <Td {...tdStyle}>{label}</Td>
        {tableData[propName].map((amount, index) => (
          <Td key={index} {...tdStyle}>
            <Number value={amount} maximumFractionDigits={0} isAccountingFormat={true} />
          </Td>
        ))}
      </Tr>
    );
  };

  return (
    <Box>
      <Box mt={6}>
        <TableContainer>
          <Table variant={'simple'}>
            <TableHeader />
            <TableBody />
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

ResultsTable.propTypes = {
  results: PropTypes.object,
};

export default ResultsTable;
