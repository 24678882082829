import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  StackDivider,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import { Currency, currencyFormat } from 'components/Number';
import Card from 'components/card/Card';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';

const BankAccountsModal = ({ isOpen, onClose, bankAccountData }) => {
  if (!bankAccountData) {
    return null;
  }

  const { institution_name, accounts } = bankAccountData;
  
  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent w={'auto'} maxW={'auto'} minW={800} minH={'auto'}>
        <ModalHeader>
          <Flex gap={2} align={'center'}>
            <Box>{institution_name}</Box>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          maxH={'calc(100vh - 300px)'}
          overflowY={'auto'}
          position={'relative'}
        >
          <VStack
            divider={<StackDivider borderColor={'gray.main'} />}
            align="stretch"
            spacing={8}
            p={2}
            justifyContent={'flex-start'}
          >
            {accounts.map((account, index) => {
              const {
                account_number,
                name,
                official_name,
                type,
                available,
                current,
              } = account;

              const accountTypeText = official_name
                ? `${type} / ${official_name}`
                : type;

              return (
                <Box key={index}>
                  <Flex gap={1} align={'center'}>
                    <Box fontWeight={'bold'}>
                      {name ? `${name} - ${account_number}` : account_number}
                    </Box>
                    <Box color={'gray.main'} fontSize={'sm'}>
                      ({accountTypeText.toLowerCase()})
                    </Box>
                  </Flex>
                  <Box fontSize={'sm'}>
                    <Flex gap={1}>
                      <Text fontFamily={800}>Available Balance:</Text>{' '}
                      <Currency
                        value={available || 0}
                        maximumFractionDigits={2}
                      />
                    </Flex>
                    <Flex gap={1}>
                      <Text fontFamily={800}>Current Balance:</Text>{' '}
                      <Currency
                        value={current || 0}
                        maximumFractionDigits={2}
                      />
                    </Flex>
                  </Box>
                </Box>
              );
            })}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const BankAccounts = ({ data }) => {
  const { bank_accounts } = data;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);

  const onShowDetails = (index) => {
    setSelectedBankAccount(bank_accounts[index]);
    onOpen();
  };

  const onModalClose = () => {
    setSelectedBankAccount(null);
    onClose();
  };

  if (!bank_accounts || !bank_accounts.length) {
    return null;
  }

  return (
    <>
      <Card>
        <Heading variant={'sectionHeader'} borderColor={'brand.300'}>
          Banking
        </Heading>
        <Box>
          <Flex direction={'column'} gap={10}>
            {bank_accounts.length > 1 && (
              <Card py={'20px'} px={'30px'} minW={300} w={'max'}>
                <Text fontWeight={800}>Total</Text>
                <Text>{`Available Balance: ${currencyFormat(
                  data.total_available
                )}`}</Text>
                <Text>{`Current Balance: ${currencyFormat(
                  data.total_current
                )}`}</Text>
              </Card>
            )}
            <>
              {Array.from({ length: Math.ceil(bank_accounts.length / 4) }).map((_, rowIndex) => (
                <Flex key={rowIndex} gap={10}>
                  {bank_accounts.slice(rowIndex * 4, (rowIndex + 1) * 4).map((bankAccount, index) => {
                    const {
                      date,
                      institution_name,
                      total_available,
                      total_current,
                    } = bankAccount;
                    return (
                      <Card
                        key={index}
                        py={'20px'}
                        px={'30px'}
                        minH={150}
                        w={'max'}
                      >
                        <Flex
                          direction={'column'}
                          grow={1}
                          justifyContent={'space-between'}
                        >
                          <Box>
                            <Flex gap={1} alignItems={'baseline'}>
                              <Text fontWeight={800}>{institution_name}</Text>
                              <Button
                                color={'primary.main'}
                                variant={'link'}
                                fontSize={'xs'}
                                onClick={() => onShowDetails(index)}
                              >
                                (Details)
                              </Button>
                            </Flex>
                            <Text>{`Available Balance: ${currencyFormat(
                              total_available
                            )}`}</Text>

                            <Text>{`Current Balance: ${currencyFormat(
                              total_current
                            )}`}</Text>
                          </Box>
                          <Text fontSize={12}>{`Last Update: ${moment({
                            year: date.year,
                            month: date.month - 1,
                            day: date.day,
                          }).format(
                            'MM/DD/YYYY'
                          )}`}</Text>
                        </Flex>
                      </Card>
                    );
                  })}
                </Flex>
              ))}
            </>
          </Flex>
        </Box>
      </Card>
      <BankAccountsModal
        isOpen={isOpen}
        onClose={onModalClose}
        bankAccountData={selectedBankAccount}
      />
    </>
  );
};

BankAccounts.propTypes = {
  data: PropTypes.shape({
    total_available: PropTypes.number,
    total_current: PropTypes.number,
    bank_accounts: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.shape({
          day: PropTypes.number,
          month: PropTypes.number,
          year: PropTypes.number,
        }),
        institution_name: PropTypes.string,
        total_available: PropTypes.number,
        total_current: PropTypes.number,
        accounts: PropTypes.shape({
          account_number: PropTypes.string,
          name: PropTypes.string,
          official_name: PropTypes.string,
          type: PropTypes.string,
          total_available: PropTypes.number,
          total_current: PropTypes.number,
        }),
      })
    ),
  }),
};

export default BankAccounts;
