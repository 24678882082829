import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Collapse, Flex, Text } from '@chakra-ui/react';
import { KpiCardExpandContext } from 'context/KpiCardExpandContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import KpiChangeIndicator from './KpiChangeIndicator';

const Content = ({
  format,
  annually,
  compareAnnually,
  monthly,
  compareMonthly,
  getCustomCompareDirection,
  getCustomCompareValue,
  getCustomCompareValueFormatted,
}) => {
  return (
    <Flex gap={2} fontSize={'xs'} color={'secondaryGray.600'}>
      <Flex direction={'column'}>
        <Text>Monthly</Text>
        <Text>Annually</Text>
      </Flex>
      <Flex direction={'column'}>
        <Text>{format ? format(monthly, {maximumFractionDigits : 1}) : monthly}</Text>
        <Text>{format ? format(annually, {maximumFractionDigits : 1}) : annually}</Text>
      </Flex>
      <Flex direction={'column'}>
        <KpiChangeIndicator
          value={monthly}
          compareValue={compareMonthly}
          getCustomCompareDirection={getCustomCompareDirection}
          getCustomCompareValue={getCustomCompareValue}
          getCustomCompareValueFormatted={getCustomCompareValueFormatted}
        />
        <KpiChangeIndicator
          value={annually}
          compareValue={compareAnnually}
          getCustomCompareDirection={getCustomCompareDirection}
          getCustomCompareValue={getCustomCompareValue}
          getCustomCompareValueFormatted={getCustomCompareValueFormatted}
        />
      </Flex>
    </Flex>
  );
};

const KpiExpanded = ({
  format,
  annually,
  compareAnnually,
  monthly,
  compareMonthly,
  id,
  hideExpandButton,
  getCustomCompareDirection,
  getCustomCompareValue,
  getCustomCompareValueFormatted,
}) => {
  const { state, setExpandState } = useContext(KpiCardExpandContext);
  const isExpanded = !!state[id];
  return (
    <Box position={'relative'}>
      {!hideExpandButton && (
        <ChevronRightIcon
          position={'absolute'}
          left={-5}
          top={0}
          boxSize={5}
          color={'secondaryGray.600'}
          onClick={() => setExpandState(id)}
          _hover={{ cursor: 'pointer' }}
          transform={isExpanded ? 'rotate(90deg)' : 'rotate(0)'}
          transition="transform .25s ease"
        />
      )}
      <Collapse in={isExpanded}>
        <Content
          format={format}
          annually={annually}
          compareAnnually={compareAnnually}
          monthly={monthly}
          compareMonthly={compareMonthly}
          getCustomCompareDirection={getCustomCompareDirection}
          getCustomCompareValue={getCustomCompareValue}
          getCustomCompareValueFormatted={getCustomCompareValueFormatted}
        />
      </Collapse>
      <Box visibility={'hidden'} h={0}>
        <Content
          format={format}
          annually={annually}
          compareAnnually={compareAnnually}
          monthly={monthly}
          compareMonthly={compareMonthly}
        />
      </Box>
    </Box>
  );
};

KpiExpanded.propTypes = {
  monthly: PropTypes.number,
  annually: PropTypes.number,
  compareMonthly: PropTypes.number,
  compareAnnually: PropTypes.number,
  format: PropTypes.func,
  id: PropTypes.string,
};

export default KpiExpanded;
