import { Box, Flex } from '@chakra-ui/react';
import {
  currencyFormat,
  numberFormat,
  percentageFormat,
} from 'components/Number';
import SelectInput from 'components/inputs/SelectInput';
import cloneDeep from 'lodash.clonedeep';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import SalesSectionCard from './components/SalesSectionCard';

const CHART_COLORS = {
  ARPU: '#4318FF',
  LTV: '#70D4FF',
  ROAS: '#D1ED61',
  CAC: '#DF43F0',
  REVENUE_RETENTION: '#4318FF',
  USER_RETENTION: '#70D4FF',
  NUMBER_OF_USERS: '#DF43F0',
};

const CohortTrends = ({ cohortsData }) => {
  const [selectedCohortIndex, setSelectedCohortIndex] = useState(0);
  const [cohortRows, setCohortRows] = useState(null);

  useEffect(() => {
    if (!cohortsData) {
      return;
    }

    const _cohortRows = cloneDeep(cohortsData.rows);
    setCohortRows(_cohortRows.reverse());
  }, [cohortsData]);

  const options = useMemo(() => {
    if (!cohortRows) {
      return [];
    }

    return cohortRows.map((row, index) => ({
      label: row.dateStr,
      value: index,
    }));
  }, [cohortRows]);

  const chartData = useMemo(() => {
    if (!cohortRows) {
      return null;
    }

    let series = [];

    const data = cohortRows[selectedCohortIndex].data;

    series.push(
      {
        chart: 1,
        name: 'ARPU',
        data: data.arpu.map(({ value }) => value),
        color: CHART_COLORS.ARPU,
      },
      {
        chart: 1,
        name: 'LTV',
        data: data.cumulativeByArpu.map(({ value }) => value),
        color: CHART_COLORS.LTV,
      },
      {
        chart: 1,
        name: 'ROAS',
        data: data.roasByArpu.map((roas) => roas.value),
        color: CHART_COLORS.ROAS,
      },
      {
        chart: 1,
        name: 'CAC',
        data: cohortRows.map(() => data.cac),
        color: CHART_COLORS.CAC,
      },
      {
        chart: 2,
        name: 'Revenue Retention',
        data: data.revenueRetention.map(({ value }) => value),
        color: CHART_COLORS.REVENUE_RETENTION,
      },
      {
        chart: 2,
        name: 'User Retention',
        data: data.userRetention.map(({ value }) => value),
        color: CHART_COLORS.USER_RETENTION,
      },
      {
        chart: 2,
        name: '# of Users',
        data: data.users.map(({ value }) => value),
        color: CHART_COLORS.NUMBER_OF_USERS,
      }
    );

    return series;
  }, [cohortRows, selectedCohortIndex]);

  const xAxisCategories = useMemo(() => {
    if (!cohortRows) {
      return [];
    }

    let categories = [];
    const selectedDate = cohortRows[selectedCohortIndex].date;
    let date = selectedDate.clone();

    for (let i = 0; i < cohortRows.length; i++) {
      let category = '';

      if (i === 0 || date.month() === 0) {
        category = [date.format('MMM'), date.format('YYYY')];
      } else {
        category = date.format('MMM');
      }
      categories.push(category);
      date = date.add(1, 'months');
    }

    return categories;
  }, [cohortRows, selectedCohortIndex]);

  const forecastDataPointsCount = useMemo(() => {
    if (!cohortRows) {
      return 0;
    }

    const data = cohortRows[selectedCohortIndex].data;
    return data.arpu.filter((item) => item.isPredicted).length;
  }, [cohortRows, selectedCohortIndex]);

 

  const getChartOptions = (chartIndex) => {
    const chartOptions = {
      chart: {
        toolbar: {
          offsetX: -25,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      tooltip: {
        x: {
          formatter: function (value) {
            let selectedDate = cohortRows[selectedCohortIndex].date;
            const date = selectedDate.clone().add(value - 1, 'months');
            return [date.format('MMM'), date.format('YYYY')];
          },
        },
        y: {
          formatter: function (value, { seriesIndex, dataPointIndex }) {
            const currentChartData = chartData.filter(
              (item) => item.chart === chartIndex
            );

            const series = currentChartData[seriesIndex];
            const data = series.data[dataPointIndex];
  
            const maximumFractionDigits = 1
  
            if (series.name === 'ROAS') {
              return percentageFormat(data, {maximumFractionDigits});
            }
  
            if (series.name === 'Revenue Retention' || series.name === 'User Retention') {
              return percentageFormat(data, {maximumFractionDigits});
            }
  
            if (series.name === 'ARPU' || series.name === 'LTV' || series.name === 'CAC') {
              return currencyFormat(data, {maximumFractionDigits});
            }
  
            if (series.name === '# of Users') {
              return numberFormat(data, {maximumFractionDigits});
            }
  
            return data;
          },
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        itemMargin: {
          horizontal: 15,
        },
        markers: {
          offsetX: -6,
          width: 10,
          height: 10,
        },
        fontSize: '14px',
        fontWeight: 500,
      },
      forecastDataPoints: {
        count: forecastDataPointsCount,
      },
      stroke: {
        width: 2,
      },
      xaxis: {
        type: 'category',
        categories: xAxisCategories,
        labels: {
          minHeight: 50,
        },
        tooltip: { enabled: false },
      },
    };

    return chartOptions;
  }

  return (
    <SalesSectionCard title={'Cohort Trends'}>
      <Box mt={50}>
        <Flex gap={'60px'} alignItems={'center'}>
          <Box>Cohort Period</Box>
          <SelectInput
            options={options}
            value={selectedCohortIndex}
            onChange={setSelectedCohortIndex}
            w={200}
            variant={'main'}
            fontWeight={800}
          />
        </Flex>
      </Box>
      <Box w={'80%'}>
        <Box mt={50}>
          {chartData && (
            <Chart
              type={'line'}
              series={chartData.filter((item) => item.chart === 1)}
              height={300}
              options={{
                ...getChartOptions(1),
                yaxis: [
                  {
                    seriesName: 'ARPU',
                    labels: {
                      formatter: (value) => {
                        return currencyFormat(value);
                      },
                    },
                  },
                  {
                    seriesName: 'ARPU',
                    show: false,
                    labels: {
                      formatter: (value) => {
                        return currencyFormat(value);
                      },
                    },
                  },
                  {
                    seriesName: 'ROAS',
                    opposite: true,
                    labels: {
                      formatter: (value) => {
                        return percentageFormat(value);
                      },
                    },
                  },
                  {
                    seriesName: 'ARPU',
                    show: false,
                    labels: {
                      formatter: (value) => {
                        return currencyFormat(value);
                      },
                    },
                  },
                ],
              }}
            />
          )}
        </Box>
        <Box mt={50}>
          {chartData && (
            <Chart
              type={'line'}
              series={chartData.filter((item) => item.chart === 2)}
              height={300}
              options={{
                ...getChartOptions(2),
                yaxis: [
                  {
                    seriesName: 'Revenue Retention',
                    opposite: true,
                    labels: {
                      formatter: (value) => {
                        return percentageFormat(value);
                      },
                    },
                  },
                  {
                    seriesName: 'Revenue Retention',
                    opposite: true,
                    show: false,
                    labels: {
                      formatter: (value) => {
                        return percentageFormat(value);
                      },
                    },
                  },
                  {
                    seriesName: '# of Users',

                    labels: {
                      formatter: (value) => {
                        return numberFormat(value);
                      },
                    },
                  },
                ],
              }}
            />
          )}
        </Box>
      </Box>
    </SalesSectionCard>
  );
};

CohortTrends.propTypes = {
  cohortsData: PropTypes.object,
};

export default CohortTrends;
