import { Box, Flex } from '@chakra-ui/react';
import { TwDatePicker } from 'components/DatePicker';
import CustomerSelect from 'components/cockpit/CustomerSelect';
import FieldInput from 'components/fields/FieldInput';
import { dateFormat } from 'helpers/propTypesHelper';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
var momentPropTypes = require('react-moment-proptypes');
var moment = require('moment');

const DateInputHeader = ({
  date,
  onDateChange,
  rightButtonElm,
  extraInputs,
  showCustomerSelect,
}) => {
  return (
    <Flex w={'100%'} justify={'space-between'} alignItems={'end'} mb={35}>
      <Flex gap={5}>
        {showCustomerSelect && (
          <Box w={220}>
            <FieldInput label={'Customer'} id={'customer'}>
              <Box boxShadow={'box'}>
                <CustomerSelect isPlayground={true} />
              </Box>
            </FieldInput>
          </Box>
        )}
        <Box w={200}>
          <FieldInput label={'Date'} id={'date'}>
            <TwDatePicker
              label={'Date'}
              variant="white"
              onChange={onDateChange}
              value={date}
              maxDate={moment().subtract(1, 'days').format('YYYY-MM-DD')}
              inputProps={{ boxShadow: 'box', placeholder: 'Select Date' }}
            />
          </FieldInput>
        </Box>
        {extraInputs?.map((input, index) => (
          <Fragment key={index}>{input}</Fragment>
        ))}
      </Flex>
      {rightButtonElm}
    </Flex>
  );
};

DateInputHeader.propTypes = {
  date: dateFormat,
  onDateChange: PropTypes.func,
  rightButtonElm: PropTypes.element,
  extraInputs: PropTypes.arrayOf(PropTypes.element),
  showCustomerSelect: PropTypes.bool,
};

export default DateInputHeader;
